/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../../Apis/ReceiptManagmentService';
import {
  DateRangeFilter,
  FilterOption,
} from '../../../components/ExpenseClaim/FilterInput';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getDetailedReceiptsList,
  getReceiptsList,
  receiptState,
  setHistoryReceiptManagementFilter,
  setOpenViewProof,
  setReceiptDetailedData,
} from '../../../features/receiptManagmentSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  DetailedReceiptsListProps,
  ReceiptAction,
  ReceiptStatus,
} from '../../../types/receiptManagment';
import { GetReceiptsListRequest } from '../../../types/request/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import HistoryReceiptColumn from '../columns/ReceiptApprover/HistoryReceiptColumn';
import HistoryRecieptDetailsDialog from './Dialog/HistoryRecieptDetailsDialog';
import ReceiptFilterDrawer from './ReceiptFilterDrawer';
import ReciptFilterBy from './ReciptFilterBy';
import VegaColumnFilterSection, {
  ReceiptColumns,
} from '../../../components/common/VegaColumnFilterSection';
import ReceiptApproveDialog from './Dialog/ReceiptApproveDialog';
import ReceiptRejectDialog from './Dialog/ReceiptRejectDialog';
import { AgentTabType } from '../ReceiptAgent';
import CreateDepositDrawer from './Drawers/CreateDepositDrawer';
import CancelReceiptDialog from './Dialog/CancelReceiptDialog';
import { ApproverTabType } from '../ReceiptApprover';
import ReceiptCancellationRejectDialog from './Dialog/ReceiptCancellationRejectDialog';
import { setColumnFilters } from '../../../features/filterColumnSlice';
import HistoryReceiptSubmitDrawer from './Drawers/HistoryReceiptSubmitDrawer';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

interface IProps {
  selectedTab: number;
  isApprover?: boolean;
  module?: string;
}

enum SearchBy {
  LAN = 'LAN',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
}

export interface IToggleProps {
  open: boolean;
  onClose: () => void;
}

const HistoryReceiptManagment = ({
  selectedTab,
  isApprover = false,
  module,
}: IProps) => {
  const dispatch = useAppDispatch();
  const {
    receiptsDetailed,
    loadingOfReceiptDetailed,
    totalItemsOfReceiptDetailed,
  } = useAppSelector(receiptState);
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [openReceipt, setOpenReceipt] = useState<boolean>(false);
  const [openCreateDepositDrawer, setOpenCreateDepositDrawer] =
    useState<boolean>(false);
  const [openCancelReceiptDialog, setOpenCancelReceiptDialog] =
    useState<boolean>(false);
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>(SearchBy.LAN);
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });
  // const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
  //   startDate: TODAY.toISOString(),
  //   endDate: TOMORROW.toISOString(),
  // });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);
  const {
    isOpen: isRejectDialogOpen,
    open: openRejectDialog,
    close: closeRejectDialog,
    props: rejectDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isApproveDialogOpen,
    open: openApproveDialog,
    close: closeApproveDialog,
    props: approveDialogProps,
  } = useDrawer(false);
  const {
    isOpen: openDrawer,
    open: openSubmitDrawer,
    close: closeSubmitDrawer,
  } = useDrawer(false);
  const downloadReceipt = (receipt: DetailedReceiptsListProps) => {
    ReceiptManagmentService.downloadReceiptPDF(receipt.receiptId)
      .then(res => {
        window.open(res, '__BLANK__');
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const onAction = (receipt: DetailedReceiptsListProps, status: string) => {
    if (status === ReceiptStatus.APPROVED) {
      openApproveDialog({
        depositId: receipt.depositId,
        receiptNumber: receipt.receiptNumber,
      });
    } else if (status === ReceiptStatus.REJECTED) {
      openRejectDialog({ depositId: receipt.depositId });
    } else if (status === 'VIEW') {
      toggleDialog();
    } else if (status === ReceiptAction.CREATE) {
      toggleCreateDepositDrawer();
    } else if (status === ReceiptAction.CANCEL) {
      toggleCancelReceiptDialog();
    } else if (
      status === ReceiptAction.EDIT &&
      (receipt.status === ReceiptStatus.APPROVED ||
        receipt.status === ReceiptStatus.APPROVED_NOT_RECONCILED)
    ) {
      openSubmitDrawer();
    } else {
      dispatch(setOpenViewProof(true));
    }
    dispatch(setReceiptDetailedData(receipt));
  };

  const onClearAllClick = () => {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter('');
    dispatch(
      setHistoryReceiptManagementFilter({
        dateFilter: { endDate: '', startDate: '' },
        statusFilter: '',
      })
    );
    // closeFilterDrawer();
  };

  const onSelectFilterClick = (
    statusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
    dispatch(
      setHistoryReceiptManagementFilter({
        dateFilter: {
          endDate: dateRangeFilter.endDate,
          startDate: dateRangeFilter.startDate,
        },
        statusFilter: statusFilter,
      })
    );

    const filteredReceiptObj = {
      statusIn: statusFilter,
      page,
      size: pageSize,
    } as GetReceiptsListRequest;

    if (dateRangeFilter.startDate.length && dateRangeFilter.endDate.length) {
      filteredReceiptObj.toDate = dateRangeFilter.endDate;
      filteredReceiptObj.fromDate = dateRangeFilter.startDate;
    }
    if (selectedTab === 0) {
      filteredReceiptObj.statusIn = ReceiptStatus.PENDING_APPROVAL;
    } else if (selectedTab === 1 && isApprover) {
      filteredReceiptObj.statusIn = `${ReceiptStatus.APPROVED},${ReceiptStatus.REJECTED}`;
    } else if (selectedTab !== 1 && selectedTab !== 0) {
      filteredReceiptObj.statusIn = getTitleFromTab(selectedTab);
    }
    if (search.length) {
      if (searchBy === SearchBy.LAN) {
        filteredReceiptObj.loanAccountId = search;
      } else {
        filteredReceiptObj.payeeName = search;
      }
    }

    // dispatch(getDetailedReceiptsList(filteredReceiptObj));
  };

  const toggleDialog = (isDetailedDialogToggle = false) => {
    if (isDetailedDialogToggle) {
      setOpenReceipt(!openReceipt);
      fetchReceiptsList();
      return;
    }

    setOpenReceipt(!openReceipt);
  };

  const toggleCreateDepositDrawer = () => {
    setOpenCreateDepositDrawer(!openCreateDepositDrawer);
  };

  const toggleCancelReceiptDialog = () => {
    setOpenCancelReceiptDialog(!openCancelReceiptDialog);
  };

  const fetchReceiptsList = () => {
    const request = {
      page,
      // pageSize,
      size: pageSize,
    } as GetReceiptsListRequest;
    if (isApprover) {
      if (selectedTab === 0) {
        request.statusIn = ReceiptStatus.PENDING_APPROVAL;
      } else if (selectedTab === 1) {
        request.statusIn = `${ReceiptStatus.APPROVED},${ReceiptStatus.REJECTED}`;
      } else if (selectedTab === 2) {
        request.statusIn = `${ReceiptStatus.APPROVED_NOT_RECONCILED},${ReceiptStatus.APPROVED_AND_RECONCILED}`;
      } else {
        request.statusIn = getTitleFromTab(selectedTab);
      }
    } else {
      if (selectedTab === 0) {
        request.statusIn = ReceiptStatus.PENDING_APPROVAL;
      } else if (selectedTab === 8) {
        request.statusIn = 'APPROVED_NOT_RECONCILED,APPROVED_AND_RECONCILED';
      } else if (selectedTab !== 1 && selectedTab !== 0) {
        request.statusIn = getTitleFromTab(selectedTab);
      }
    }
    // if (selectedTab === 0) {
    //   request.statusIn = ReceiptStatus.PENDING_APPROVAL;
    // } else if (selectedTab === 1 && isApprover) {
    //   request.statusIn = `${ReceiptStatus.APPROVED},${ReceiptStatus.REJECTED}`;
    // } else if (selectedTab === 2 && isApprover) {
    //   request.statusIn = `${ReceiptStatus.APPROVED_NOT_RECONCILED},${ReceiptStatus.APPROVED_AND_RECONCILED}`;
    // } else if (selectedTab !== 1 && selectedTab !== 0) {
    //   request.statusIn = getTitleFromTab(selectedTab);
    // }

    if (statusFilter?.length) {
      request.statusIn = statusFilter;
    }

    if (dateFilter.startDate.length && dateFilter.endDate.length) {
      request.toDate = dateFilter.endDate;
      request.fromDate = dateFilter.startDate;
    }

    // if (selectedTab === 0) {
    //   request.statusIn = ReceiptStatus.PENDING_APPROVAL;
    // } else if (selectedTab === 8) {
    //   request.statusIn = 'APPROVED_NOT_RECONCILED,APPROVED_AND_RECONCILED';
    // } else if (selectedTab !== 1 && selectedTab !== 0) {
    //   request.statusIn = getTitleFromTab(selectedTab);
    // }

    // if (search.length) {
    //   request.loanAccountId = search;
    // }
    if (search.length) {
      if (searchBy === SearchBy.LAN) {
        request.loanAccountId = search;
      } else {
        request.payeeName = search;
      }
    }

    dispatch(getDetailedReceiptsList(request));
  };

  useEffect(() => {
    if (search.length) {
      const receiptList = setTimeout(() => {
        fetchReceiptsList();
      }, 300);
      return () => clearTimeout(receiptList);
    } else {
      fetchReceiptsList();
    }
  }, [page, pageSize, search, selectedTab]);

  const key = `_${module}_
    ${
      isApprover
        ? selectedTab === 0
          ? ApproverTabType.PENDING
          : ApproverTabType.HISTORY
        : getTitleFromTab(selectedTab)
    }`;

  const updateFilters = (options: string[]) => {
    dispatch(
      setColumnFilters({
        key,
        data: [...options],
      })
    );
    setSelectedOptions(options);
  };
  let allowedFilters = [FilterOption.DayFilter, FilterOption.StatusFilter];
  if ((isApprover && selectedTab == 2) || selectedTab === 8) {
    allowedFilters = [FilterOption.DayFilter];
  }
  useEffect(() => {
    if (localStorage.getItem(key)) {
      setSelectedOptions(JSON.parse(localStorage.getItem(key)));
    } else {
      setSelectedOptions([]);
    }
  }, [key]);

  useEffect(() => {
    fetchReceiptsList();
  }, [statusFilter, dayFilter, dateFilter]);

  useEffect(() => {
    onClearAllClick();
  }, [selectedTab]);

  return (
    <>
      <VegaPageHeader
        title={
          isApprover
            ? toLowerCase(getTitleFromTabForApprovar(selectedTab))
            : toLowerCase(getTitleFromTab(selectedTab))
        }
        renderRightView={() => (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaColumnFilterSection
              columns={ReceiptColumns}
              selectedOptions={selectedOptions}
              setSelectedOptions={updateFilters}
            />
            <Stack
              direction={'row'}
              gap={1}
              sx={{
                height: '35px',
              }}
            >
              <LmFilterSection
                onFilter={() => {
                  openFilterDrawer();
                }}
                search={search}
                hideFilter={selectedTab === 0 ? true : false}
                setSearch={e => {
                  setSearch(e);
                  setPage(0);
                }}
                serachByComponent={
                  <Box
                    sx={{
                      width: 150,
                    }}
                  >
                    <VegaSelect
                      options={getSearchByOptions()}
                      value={searchBy}
                      onChange={selected => {
                        const value = selected.target.value as string;
                        setSearchBy(value);
                        setSearch('');
                        setPage(0);
                      }}
                    />
                  </Box>
                }
              />
            </Stack>
          </Stack>
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={receiptsDetailed}
          loading={loadingOfReceiptDetailed}
          columns={HistoryReceiptColumn({
            onAction,
            downloadReceipt,
            selectedOptions,
            selectedTab,
          })}
          idColumn="receiptId"
          page={page}
          pageSize={pageSize}
          rowCount={totalItemsOfReceiptDetailed}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
        />
      </VegaPageContent>
      <ReceiptFilterDrawer
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <ReciptFilterBy
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={allowedFilters}
        />
      </ReceiptFilterDrawer>
      <HistoryRecieptDetailsDialog
        open={openReceipt}
        toggleDialog={toggleDialog}
        isApprover={isApprover}
      />
      <HistoryReceiptSubmitDrawer
        open={openDrawer}
        onClose={closeSubmitDrawer}
        onFetchList={fetchReceiptsList}
      />
      <ReceiptApproveDialog
        open={isApproveDialogOpen}
        depositId={approveDialogProps.depositId}
        receiptNumber={approveDialogProps.receiptNumber}
        onClose={function (): void {
          closeApproveDialog();
          fetchReceiptsList();
        }}
      />
      <ReceiptRejectDialog
        open={isRejectDialogOpen}
        depositId={rejectDialogProps.depositId}
        onClose={function (): void {
          closeRejectDialog();
          fetchReceiptsList();
        }}
      />
      <CreateDepositDrawer
        open={openCreateDepositDrawer}
        onClose={toggleCreateDepositDrawer}
        onFetchList={fetchReceiptsList}
      />

      <ReceiptCancellationRejectDialog
        open={openCancelReceiptDialog}
        onClose={toggleCancelReceiptDialog}
        onFetchList={fetchReceiptsList}
      />
    </>
  );
};

export default HistoryReceiptManagment;
export const getTitleFromTab = (tab: number) => {
  const value = Object.values(AgentTabType)[tab];
  return value;
};
const getTitleFromTabForApprovar = (tab: number) => {
  const value = Object.values(ApproverTabType)[tab];
  return value;
};

const getSearchByOptions = () => {
  const options = Object.keys(SearchBy).map(
    search =>
      ({
        label: toLowerCase(search),
        value: search,
      } as VegaSelectOption)
  );
  return options;
};
