import { useCallback, useEffect, useRef } from 'react';

function useControllableInterval() {
  const intervalRef = useRef<number | null>(null);

  const start = useCallback((callback: () => void, delay: number) => {
    // Clear any existing interval
    if (intervalRef.current) clearInterval(intervalRef.current);

    // Set up the new interval
    intervalRef.current = window.setInterval(callback, delay);
  }, []);

  const stop = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return { start, stop };
}

export default useControllableInterval;
