import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import {
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
  TimePeriodEnumForPenal,
  TimePeriodEnumForPenalMonthly,
  TimePeriodEnumForPenalYearly,
} from '../../../../../features/analyticsDashboardSlice';
import {
  BucketMonthlyPenalCollectionResultDto,
  BucketPenalCollectionResultDto,
  BucketYearlyPenalCollectionResultDto,
} from '../../../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { DateUtility } from '../../../../../utils/DateUtlility';
import { getMonthIndex } from '../../../components/RoleBaseSegmentPerformance';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import VegaText from '../../../../../components/common/VegaText';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../../../constants/commonFunction';

const PenalBucketPerformanceGraph = () => {
  const { calenderType, profitType } = useParams();
  const { setSnackbar } = useSnackbar();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<
      | BucketPenalCollectionResultDto[]
      | BucketMonthlyPenalCollectionResultDto[]
      | BucketYearlyPenalCollectionResultDto[]
    >
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const toDayseries = [
    {
      name: 'Target',
      data: analyticsQueryResponse?.map(value => {
        const dayValue = value as BucketPenalCollectionResultDto;
        return parseFloat(dayValue?.target?.toFixed(2)) ?? 0;
      }),
    },
    {
      name: 'Achieved',
      data: analyticsQueryResponse?.map(value => {
        const dayValue = value as BucketPenalCollectionResultDto;
        return parseFloat(dayValue?.penalCollection?.toFixed(2)) ?? 0;
      }),
    },
  ];
  const monthSeries = [
    {
      name: 'MTD',
      data: analyticsQueryResponse?.map(value => {
        const mtdValue = value as BucketMonthlyPenalCollectionResultDto;
        return parseFloat(mtdValue?.mtdPenalCollection?.toFixed(2)) ?? 0;
      }),
    },
    {
      name: 'LMSD',
      data: analyticsQueryResponse?.map(value => {
        const mtdValue = value as BucketMonthlyPenalCollectionResultDto;
        return parseFloat(mtdValue?.lmsdPenalCollection?.toFixed(2)) ?? 0;
      }),
    },
  ];
  const yearSeries = [
    {
      name: 'YTD',
      data: analyticsQueryResponse?.map(value => {
        const mtdValue = value as BucketYearlyPenalCollectionResultDto;
        return parseFloat(mtdValue?.ytdPenalCollection?.toFixed(2)) ?? 0;
      }),
    },
    {
      name: 'LYSD',
      data: analyticsQueryResponse?.map(value => {
        const mtdValue = value as BucketYearlyPenalCollectionResultDto;
        return parseFloat(mtdValue?.lysdPenalCollection?.toFixed(2)) ?? 0;
      }),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: analyticsQueryResponse?.map(value => value.bucket),
      title: {
        text: 'Rs. Crore',
      },
    },
  };

  const getTimePeriodOptions = () => {
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      const options = Object.keys(TimePeriodEnumForPenalMonthly).map(item => {
        return { value: item, label: toLowerCase(item) } as VegaSelectOption;
      });
      return options;
    }
    const options = Object.keys(TimePeriodEnumForPenalYearly).map(item => {
      const year = new Date().getFullYear();
      const label = `${toLowerCase(item)} (${year})`;
      return { value: item, label: label } as VegaSelectOption;
    });
    return options;
  };

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoading(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');

      setLoading(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);

    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.ONE_DAY:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
          };
        case TimePeriodEnumForPenal.FIVE_DAY:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              FIVE_DAY?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              FIRST_DAY?.toISOString()
            ),
          };
        case TimePeriodEnumForPenal.MTD:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              new Date(FIRST_DAY)?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
          };
        default:
          return null;
      }
    }
    if (calenderType === CelenderTypeEmun.YEAR) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.QUATER_1:
        case TimePeriodEnumForPenal.QUATER_2:
        case TimePeriodEnumForPenal.QUATER_3:
        case TimePeriodEnumForPenal.QUATER_4: {
          const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
          };
        }
        case TimePeriodEnumForPenal.JANUARY:
        case TimePeriodEnumForPenal.FEBRUARY:
        case TimePeriodEnumForPenal.MARCH:
        case TimePeriodEnumForPenal.APRIL:
        case TimePeriodEnumForPenal.MAY:
        case TimePeriodEnumForPenal.JUNE:
        case TimePeriodEnumForPenal.JULY:
        case TimePeriodEnumForPenal.AUGUST:
        case TimePeriodEnumForPenal.SEPTEMBER:
        case TimePeriodEnumForPenal.OCTOBER:
        case TimePeriodEnumForPenal.NOVEMBER:
        case TimePeriodEnumForPenal.DECEMBER: {
          const monthIndex = getMonthIndex(selectedTimePeriod);
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfMonth(
                CURRENT_YEAR,
                monthIndex
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
            ),
          };
        }
        case TimePeriodEnumForPenal.YTD:
          return { type: profitType };
      }
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      if (timePeriodParams !== null) {
        return {
          ...timePeriodParams,
          type: profitType,
        };
      }
    } else {
      return {
        ...timePeriodParams,
        type: profitType,
      };
    }
  };

  const getQueryType = () => {
    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
      case TimePeriodEnumForPenal.FIVE_DAY:
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4:
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER:
        return AnalyticsQueryTypeEnum.BUCKET_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.MTD:
        return AnalyticsQueryTypeEnum.BUCKET_MONTHLY_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.YTD:
        return AnalyticsQueryTypeEnum.BUCKET_YEARLY_PENAL_COLLECTION;
    }
  };

  const getPenalBucketCollectionQueries = () => {
    const params = getParams();
    const analyticsQueryType = getQueryType();

    const request = {
      analyticsQueryType,
      params,
    } as AnalyticsQueryRequest;
    getQueriesForEnum(request);
  };
  useEffect(() => {
    getPenalBucketCollectionQueries();
  }, [selectedTimePeriod]);

  return (
    <>
      <GraphWrapper
        title="Bucket Collection"
        rightComponent={
          <Stack width={150}>
            <VegaSelect
              options={getTimePeriodOptions()}
              value={selectedTimePeriod}
              onChange={select =>
                setSelectedTimePeriod(select.target.value as string)
              }
              ignoreLodash
            />
          </Stack>
        }
      >
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: -8,
                  top: 30,
                  height: '76%',
                  width: 40,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1' }}>{getPercentageValue(val)}</Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={
                  selectedTimePeriod === TimePeriodEnumForPenal.MTD
                    ? monthSeries
                    : calenderType === CelenderTypeEmun.YEAR &&
                      selectedTimePeriod === TimePeriodEnumForPenal.YTD
                    ? yearSeries
                    : toDayseries
                }
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default PenalBucketPerformanceGraph;

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
