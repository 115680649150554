import { ExpenseService } from '../Apis/ExpenseService';
import { UserService } from '../Apis/UserService';
import { ReapplyExpenseForm } from '../components/ExpenseClaim/ReapplyExpenseClaimForm';
import { useClientAuth } from '../providers/ClientProvider';
import {
  ExpenseClaim,
  ExpenseClaimViewModel,
  ExpenseDocument,
  ExpenseReportViewModel,
  VegaUser,
} from '../types/claim';
import {
  CreateClaimRequest,
  DeleteClaimDocumentsRequest,
  GetClaimRequest,
  GetClaimsRequest,
  GetExpenseReportsRequest,
  GetExpenseVisitDataRequest,
  SubmitExpenseReportRequest,
  UpdateClaimRequest,
} from '../types/request/claim';
import { PaginatedResponse } from '../utils/api';

export const useExpenseApi = () => {
  const { userId, userForId } = useClientAuth();
  async function fetchExpenseClaims(
    request: Partial<GetClaimsRequest>,
    filter?: { includeClaimaint: boolean }
  ): Promise<PaginatedResponse<ExpenseClaimViewModel>> {
    if (!request.endDateRange || request.endDateRange.length == 0)
      delete request.endDateRange;
    if (!request.startDateRange || request.startDateRange.length == 0)
      delete request.startDateRange;
    if (!request.status || request.status.length == 0) delete request.status;

    const response = await ExpenseService.getClaims(request);

    const claims: ExpenseClaim[] = response.records;

    const claimsViewModels: ExpenseClaimViewModel[] = [];
    const users = new Map<string, VegaUser>();

    for (const claim of claims) {
      const userId = claim.userId;
      let existingUser = users.get(userId);
      if (filter?.includeClaimaint == true) {
        if (!existingUser) {
          const userResponse = await UserService.getUserById(userId);
          users.set(userId, userResponse);
          existingUser = userResponse;
        }
      }
      const claimViewModel: ExpenseClaimViewModel = {
        ...claim,
        claimant: existingUser,
      };
      claimsViewModels.push(claimViewModel);
    }
    return { ...response, records: claimsViewModels };
  }

  async function fetchExpenseReports(
    request: Partial<GetExpenseReportsRequest>,
    filters?: {
      includeClaimantInClaims?: boolean;
      includeApprover?: boolean;
      includeClaimantInReport?: boolean;
      includeApproverInReport?: boolean;
    }
  ): Promise<PaginatedResponse<ExpenseReportViewModel>> {
    if (!request.endDateRange || request.endDateRange.length == 0)
      delete request.endDateRange;
    if (!request.startDateRange || request.startDateRange.length == 0)
      delete request.startDateRange;
    if (!request.status || request.status.length == 0) delete request.status;

    const response = await ExpenseService.getGetExpenseReports(request);

    const reports = response.records;

    const reportViewModels: ExpenseReportViewModel[] = [];

    for (const report of reports) {
      const claimsResponse = await fetchExpenseClaims(
        { reportId: report.id },
        { includeClaimaint: filters?.includeClaimantInClaims ?? false }
      );

      const claimaint: string = userForId(report.userId);
      const approver: string = userForId(report.userId);

      const existingReport: ExpenseReportViewModel = {
        ...report,
        claims: claimsResponse.records,
        claimant: claimaint,
        approver: approver,
      };
      reportViewModels.push(existingReport);
    }

    const modifiedResponse: PaginatedResponse<ExpenseReportViewModel> = {
      ...response,
      records: reportViewModels,
    };
    return modifiedResponse;
  }

  async function fetchReport(data: {
    reportId: string;
    includeApprover?: boolean;
  }): Promise<ExpenseReportViewModel> {
    const response = await ExpenseService.getReport(data.reportId);
    const approverId = response.approverId;
    var approver;
    if (data.includeApprover == true) {
      approver = userForId(approverId);
    }
    return { ...response, approver: approver, claims: [] };
  }

  async function createClaim(request: Partial<CreateClaimRequest>) {
    const response = await ExpenseService.createClaim(request);
    return response;
  }

  async function fetchClaimVisitData(
    request: Partial<GetExpenseVisitDataRequest>
  ) {
    const response = await ExpenseService.getExpenseVisitData(request);
    return response;
  }

  async function fetchExpenseClaim(
    request: GetClaimRequest,
    filter?: { includeClaimant: boolean }
  ): Promise<ExpenseClaimViewModel> {
    const response = await ExpenseService.getClaim(request);
    var claimant;
    if (filter?.includeClaimant == true) {
      claimant = userForId(response.userId);
    }
    return { ...response, claimant: claimant };
  }

  async function deleteExpenseClaim(id: string): Promise<ExpenseClaim> {
    return ExpenseService.deleteExpenseClaim(id);
  }

  async function updateExpenseClaim(request: Partial<UpdateClaimRequest>) {
    return ExpenseService.updateClaim(request);
  }

  async function deleteClaimSupportingDocuments(
    request: Partial<DeleteClaimDocumentsRequest>
  ) {
    return ExpenseService.deleteExpenseDocuments(request);
  }

  async function submitReport(request: Partial<SubmitExpenseReportRequest>) {
    return ExpenseService.submitExpenseReport(request);
  }

  async function reapplyClaim(
    claim: ExpenseClaim,
    formData: Partial<ReapplyExpenseForm>
  ) {
    const filesToBeDeleted = formData.documentsToBeDeleted ?? [];
    if (filesToBeDeleted.length > 0) {
      await ExpenseService.deleteExpenseDocuments({
        claimId: claim.id,
        supportingDocumentIds: filesToBeDeleted.map(item => item.id),
      });
    }

    const filesToBeUploaded = formData.documentsToBeUploaded ?? [];
    const files: File[] = [];

    filesToBeUploaded.forEach(item => {
      if (item.value) {
        files.push(item.value);
      }
    });

    const updatedAmount = formData.amount ?? 0;

    const updateRequest: Partial<UpdateClaimRequest> = {
      claimId: claim.id,
      amount: updatedAmount,
      documents: files,
    };

    if (updatedAmount <= 0) delete updateRequest.amount;
    if (files.length <= 0) delete updateRequest.documents;

    if (updateRequest.documents || updateRequest.amount) {
      await ExpenseService.updateClaim(updateRequest);
    }

    await ExpenseService.reapplyClaim({
      claimId: claim.id,
      reason: formData.description,
      userId: userId,
    });
  }

  async function getClaimDocumentsPublicUrl(
    claimId: string
  ): Promise<ExpenseDocument[]> {
    const claim = await ExpenseService.getClaimDocumentsPublicUrl(claimId);
    const metaData = claim.metaData;
    let supportingDocuments: ExpenseDocument[] = [];
    // eslint-disable-next-line no-prototype-builtins
    if (metaData.hasOwnProperty('supportingDocuments')) {
      supportingDocuments = metaData[
        'supportingDocuments'
      ] as ExpenseDocument[];
    }
    return supportingDocuments;
  }

  return {
    fetchExpenseClaims,
    fetchExpenseReports,
    fetchClaimVisitData,
    fetchReport,
    createClaim,
    fetchExpenseClaim,
    deleteExpenseClaim,
    deleteClaimSupportingDocuments,
    updateExpenseClaim,
    submitReport,
    reapplyClaim,
    getClaimDocumentsPublicUrl,
  };
};
