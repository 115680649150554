import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RoleServices } from '../Apis/RoleServices';
import { RootState } from '../store';
import { GetRoleAccessList, RoleResourceDto } from '../types/roleType';
import { VegaSelectOption } from '../components/common/VegaSelect';

export interface UserState {
  loading: boolean;
  roles: RoleResourceDto[];
  selectedRole: string;
  error: string | null;
}

const initialState: UserState = {
  loading: false,
  roles: [],
  error: null,
  selectedRole: '',
};

export class RoleAction {
  static fetchRoles = createAsyncThunk(
    'roleAccess/fetchRoles',
    async (request: Partial<GetRoleAccessList>) => {
      const response = await RoleServices.getRolesAccess(request);
      return response;
    }
  );
}

const roleAccessSlice = createSlice({
  name: 'roleAccess',
  initialState,
  reducers: {
    setSelectedRole: (state: UserState, action) => {
      const { payload } = action;
      state.selectedRole = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(RoleAction.fetchRoles.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RoleAction.fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        const roleResourceDtoList = [...action.payload.roleResourceDtoList];
        const formattedDto = roleResourceDtoList.map(
          (roleResources: RoleResourceDto) => ({
            ...roleResources,
            id: roleResources.roleDto.id,
          })
        );
        state.roles = formattedDto;
        if (formattedDto.length) {
          const roleDto = {
            label: formattedDto[0]?.roleDto?.name,
            value: formattedDto[0]?.id,
          } as VegaSelectOption;
          state.selectedRole = roleDto.value;
        }
      })
      .addCase(RoleAction.fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch users';
      });
  },
});
export const { setSelectedRole } = roleAccessSlice.actions;
export default roleAccessSlice;
export const getRoleAccess = (state: RootState) => state.roleAccess;
