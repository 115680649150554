import { Stack } from '@mui/material';
import { useState } from 'react';
import ListOfGeoVerification from '../../components/GeoVarification/ListOfGeoVerification';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';

export enum GeoTabType {
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
}

export enum GeoVarificationActionEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  RAISED = 'RAISED',
}

const GeoVarification = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onTabChange = (value: number) => {
    setSelectedTab(value);
  };

  return (
    <>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Geotagged Verification'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  onTabChange(selected);
                }}
              >
                {Object.keys(GeoTabType).map(tab => (
                  <VegaTabBarItem
                    key={tab}
                    label={toLowerCase(GeoTabType[tab])}
                  />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
      />

      <ListOfGeoVerification selectedTab={selectedTab} />
    </>
  );
};

export default GeoVarification;
