import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateRangeFilter } from '../components/ExpenseClaim/FilterInput';
import AddHolidayType from '../components/HolidayType/AddHolidayType';
import HolidayTypeFilterForm from '../components/HolidayType/HolidayTypeFilterForm';
import ListOfHolidayType from '../components/HolidayType/ListOfHolidayType';
import LmButton from '../components/common/LmButton';
import LmFilterSection from '../components/common/LmFilterSection';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaDrawerContent from '../components/common/VegaDrawerContent';
import VegaPageHeader from '../components/common/VegaPageHeader';
import { useDrawer } from '../hooks/useDrawer';
import { useHolidayType } from '../providers/HolidayTypeProvider';
import { ICreateHolidayTypeProps } from '../types/holiday';
import { DateUtility } from '../utils/DateUtlility';
import { HolidayTypeRequest } from '../types/request/holidayRequest';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

export enum HolidayTypeFilterOption {
  DayFilter,
  AuthoraizationStatusFilter,
  DepositionStatusFilter,
  IssuanceStatusFilter,
}

const LmHolidayType = () => {
  const {
    isAdd,
    loading,
    selectedHolidayType,
    holidayTypeState,
    setHolidayTypeState,
    setSearch,
    toggleAddHolidayTypeDrawer,
    onAddHolidayTypeSubmit,
    onUpdateHolidayTypeSubmit,
    setSelectedHolidayType,
    getHolidayTypeList,
    search,
    page,
    size,
  } = useHolidayType();

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const [dayFilter, setDayFilter] = useState<string>();
  const [authorizationStatusFilter, setAuthorizationStatusFilter] =
    useState<string>('');
  const [depositionStatusFilter, setDepositionStatusFilter] =
    useState<string>('');
  const [issuanceStatusFilter, setIssuanceStatusFilter] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });

  const isEmptyValue = holidayTypeState
    ? Object.values(holidayTypeState).some((type: any) => type === '')
    : true;

  const checkCondition = (selectedHolidayType: string) => {
    if (selectedHolidayType) {
      return {
        title: 'Update Holiday Type',
        buttonText: 'Update Details',
        buttonClick: () => {
          const newHolidayTypeObj = {
            ...holidayTypeState,
            id: selectedHolidayType,
          } as ICreateHolidayTypeProps;

          onUpdateHolidayTypeSubmit(newHolidayTypeObj);
        },
        loading: loading,
      };
    } else {
      return {
        title: 'Add Holiday Type',
        buttonText: 'Add Details',
        buttonClick: onAddHolidayTypeSubmit,
        loading: loading,
      };
    }
  };

  const onClearAllClick = () => {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setAuthorizationStatusFilter('');
    setDepositionStatusFilter('');
    setIssuanceStatusFilter('');
    closeFilterDrawer();
    getHolidayTypeList({});
  };

  const onSelectFilterClick = (
    authorizationStatusFilter: string,
    depositionStatusFilter: string,
    issuanceStatusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setAuthorizationStatusFilter(authorizationStatusFilter);
    setDepositionStatusFilter(depositionStatusFilter);
    setIssuanceStatusFilter(issuanceStatusFilter);
    setDateFilter(dateRangeFilter);

    const filteredReceiptObj = {
      // receiptAuthorization: authorizationStatusFilter,
      // receiptIssuance: issuanceStatusFilter,
      // receiptDeposition: depositionStatusFilter,
    } as HolidayTypeRequest;

    if (authorizationStatusFilter) {
      filteredReceiptObj.receiptAuthorization = authorizationStatusFilter;
    }
    if (issuanceStatusFilter) {
      filteredReceiptObj.receiptIssuance = issuanceStatusFilter;
    }
    if (depositionStatusFilter) {
      filteredReceiptObj.receiptDeposition = depositionStatusFilter;
    }
    if (dateRangeFilter.startDate.length && dateRangeFilter.endDate.length) {
      filteredReceiptObj.toDate = dateRangeFilter.endDate;
      filteredReceiptObj.fromDate = dateRangeFilter.startDate;
    }
    getHolidayTypeList({ page, size, ...filteredReceiptObj });
  };

  function queryBuilder() {
    const queryParams: Partial<HolidayTypeRequest> = {
      page,
      size,
    };
    if (search) {
      queryParams.name = search;
    }
    if (authorizationStatusFilter) {
      queryParams.receiptAuthorization = authorizationStatusFilter;
    }
    if (issuanceStatusFilter) {
      queryParams.receiptIssuance = issuanceStatusFilter;
    }
    if (depositionStatusFilter) {
      queryParams.receiptDeposition = depositionStatusFilter;
    }
    if (dateFilter.endDate && dateFilter.startDate) {
      queryParams.fromDate = dateFilter.startDate;
      queryParams.toDate = dateFilter.endDate;
    }
    return queryParams;
  }
  useEffect(() => {
    if (search.length) {
      const accountList = setTimeout(() => {
        getHolidayTypeList(queryBuilder());
      }, 200);
      return () => clearTimeout(accountList);
    } else {
      getHolidayTypeList(queryBuilder());
    }
  }, [search, page, size]);

  return (
    <>
      <VegaPageHeader
        title="Holiday Type"
        renderRightView={() => (
          <LmButton
            text="Add New Holiday Type"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setSelectedHolidayType(null);
              setHolidayTypeState(null);
              toggleAddHolidayTypeDrawer();
            }}
          />
        )}
      />
      <Box p={3}>
        <LmFilterSection
          setSearch={setSearch}
          onFilter={() => openFilterDrawer()}
        />
        <Box pt={3}>
          {' '}
          <ListOfHolidayType />{' '}
        </Box>
      </Box>
      <VegaDrawer
        title={checkCondition(selectedHolidayType?.id).title}
        open={isAdd}
        onClose={toggleAddHolidayTypeDrawer}
      >
        <VegaDrawerContent
          renderBottomView={() => (
            <LmButton
              text={checkCondition(selectedHolidayType?.id).buttonText}
              disabled={isEmptyValue}
              fontWeight={500}
              onClick={checkCondition(selectedHolidayType?.id).buttonClick}
              loading={checkCondition(selectedHolidayType?.id).loading}
            />
          )}
        >
          <AddHolidayType />
        </VegaDrawerContent>
      </VegaDrawer>
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <HolidayTypeFilterForm
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            authorizationStatusFilter: authorizationStatusFilter,
            depositionStatusFilter: depositionStatusFilter,
            issuanceStatusFilter: issuanceStatusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[
            HolidayTypeFilterOption.DayFilter,
            HolidayTypeFilterOption.AuthoraizationStatusFilter,
            HolidayTypeFilterOption.DepositionStatusFilter,
            HolidayTypeFilterOption.IssuanceStatusFilter,
          ]}
        />
      </VegaDrawer>
    </>
  );
};

export default LmHolidayType;
