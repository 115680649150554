import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnaltyticsService } from '../Apis/AnaltyticsService';
import { RootState } from '../store';
import { AnalyticsQueryResponse } from '../types/analytics';
import { AnalyticsQueryRequest } from '../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../utils/api';

export const defaultBucketQueries = {
  BUCKET3: {
    bucket: '61 to 90',
    target: 10,
    pos: 2,
    drr: 0,
    name: null,
    region: null,
    monthToDateDrr: 100,
    yearToDateDrr: 1000,
    lastMonthSameDateDrr: 50,
    lastYearSameDateDrr: 657,
    percentageChange: null,
  },
  BUCKET1: {
    bucket: '1 to 30',
    target: 10,
    pos: 7,
    drr: 0,
    name: null,
    region: null,
    monthToDateDrr: 100,
    yearToDateDrr: 1000,
    lastMonthSameDateDrr: 80,
    lastYearSameDateDrr: 500,
    percentageChange: null,
  },
  BUCKETX: {
    bucket: 'X',
    target: 10,
    pos: 5,
    drr: 0,
    name: null,
    region: null,
    monthToDateDrr: 100,
    yearToDateDrr: 1000,
    lastMonthSameDateDrr: 30,
    lastYearSameDateDrr: 359,
    percentageChange: null,
  },
  BUCKET2: {
    bucket: '31 to 60',
    target: 10,
    pos: 10,
    drr: 0,
    name: null,
    region: null,
    monthToDateDrr: 100,
    yearToDateDrr: 1000,
    lastMonthSameDateDrr: 70,
    lastYearSameDateDrr: 859,
    percentageChange: null,
  },
  NORMAL: {
    bucket: 'Normal',
    target: 10,
    pos: 9,
    drr: 0,
    name: null,
    region: null,
    monthToDateDrr: 100,
    yearToDateDrr: 1000,
    lastMonthSameDateDrr: 63,
    lastYearSameDateDrr: 756,
    percentageChange: null,
  },
  NPA: {
    bucket: 'NPA',
    target: 10,
    pos: 0,
    drr: 0,
    name: null,
    region: null,
    monthToDateDrr: 100,
    yearToDateDrr: 1000,
    lastMonthSameDateDrr: 50,
    lastYearSameDateDrr: 0,
    percentageChange: null,
  },
};

export enum CelenderViewType {
  TODAY = 'TODAY',
  THIS_YEAR = 'THIS_YEAR',
}
export enum CelenderTypeEmun {
  TODAY = 'TODAY',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
}
export enum CollectionProfitType {
  GROSS = 'GROSS',
  NET = 'NET',
}

export enum CollectionWiseType {
  REGION = 'REGION_PERFORMANCE',
  RCM = 'RCM',
  CCM = 'CCM',
  TELE_CALLER = 'TELE_CALLER',
  ACM_ASM = 'ACM_ASM',
}
export enum SegmentOptionType {
  RCM_INCLUDING_TELE_MANAGERS = 'RCM',
  CCM = 'CCM',
  TELE_CALLER = 'TELE_CALLER',
  ACM_ASM = 'ACM_ASM',
}

export enum RoleBasedPerformanceGraphSegmentOptionType {
  RCM_INCLUDING_TELEMANAGER = 'RCM',
  CCM = 'CCM',
  TELE_CALLER = 'TELE_COLLECTION_EXECUTIVE',
  ACM_ASM = 'ACM_ASM',
  TELE_MANAGER = 'TELE_COLLECTION_MANAGER',
}

export enum OrderByEnum {
  OVERALL = 'OVERALL',
  TOP_5 = 'TOP_5',
  BOTTOM_5 = 'BOTTOM_5',
}
export enum ResolutionEnum {
  OVERALL = 'OVERALL',
  RESTRUCTURED = 'RESTRUCTURED',
  NORMAL = 'NORMAL',
}
export enum TodayColletionOptionType {
  REGION = 'REGION_PERFORMANCE',
  CHANNEL = 'TODAY_COLLECTION_FIRST_CLICK_CHANNEL',
  BUCKET = 'TODAY_COLLECTION_FIRST_CLICK_BUCKET',
  RCM = 'TODAY_COLLECTION_FIRST_CLICK_RCM',
  CCM = 'TODAY_COLLECTION_FIRST_CLICK_CCM',
  TELE_CALLER = 'TODAY_COLLECTION_FIRST_CLICK_TELE_CALLER',
  ACM_ASM = 'TODAY_COLLECTION_FIRST_CLICK_ACM_ASM',
}
export enum CollectionValueAndVolumnOptionType {
  REGION = 'TODAY_COLLECTION_FIRST_CLICK_REGION',
  CHANNEL = 'TODAY_COLLECTION_FIRST_CLICK_CHANNEL',
  BUCKET = 'TODAY_COLLECTION_FIRST_CLICK_BUCKET',
  // TELE_CALLER = 'TODAY_COLLECTION_FIRST_CLICK_TELE_CALLER',
  // ACM_ASM = 'TODAY_COLLECTION_FIRST_CLICK_ACM_ASM',
}
export enum CollectionViewType {
  COLLECTION_VALUE = 'COLLECTION_VALUE',
  COLLECTION_TEAM = 'COLLECTION_TEAM',
  TELE_CALLERS = 'TELE_CALLERS',
  LOAN_METRICS = 'LOAN_METRICS',
  PENAL_CHARGES = 'PENAL_CHARGES',
  COLLECTION_VOLUME = 'COLLECTION_VOLUME',
  SEGMENT = 'SEGMENT',
}
export enum BucketEnumForAnalytics {
  NORMAL = 'Normal',
  BUCKETX = 'X',
  BUCKET1 = '1 to 30',
  BUCKET2 = '31 to 60',
  BUCKET3 = '61 to 90',
  NPA = 'NPA',
}
export enum MonthOnMonthBucketEnum {
  BUCKETX = 'X',
  BUCKET1 = '1 to 30',
  BUCKET2 = '31 to 60',
  BUCKET3 = '61 to 90',
  NPA = 'NPA',
}

export enum BucketEnumForYearAnalytics {
  OVERALL = 'OVERALL',
  NORMAL = 'Normal',
  BUCKETX = 'X',
  BUCKET1 = '1 to 30',
  BUCKET2 = '31 to 60',
  BUCKET3 = '61 to 90',
  NPA = 'NPA',
  NPA_GREATER_THAN_240 = 'NPA_GREATER_THAN_240',
}
export enum TimePeriodEnum {
  ALL = 'ALL',
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  MTD = 'MTD',
}
export enum TimePeriodEnumForPenal {
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  QUATER_1 = 'QUATER_1',
  QUATER_2 = 'QUATER_2',
  QUATER_3 = 'QUATER_3',
  QUATER_4 = 'QUATER_4',
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
  MTD = 'MTD',
  YTD = 'YTD',
}

export enum TimePeriodEnumForPenalMonthly {
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  MTD = 'MTD',
}

export enum TimePeriodEnumForPenalYearly {
  QUATER_1 = 'QUATER_1',
  QUATER_2 = 'QUATER_2',
  QUATER_3 = 'QUATER_3',
  QUATER_4 = 'QUATER_4',
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
  YTD = 'YTD',
}

export enum TodayTimePeriodEnum {
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  QUATER_1 = 'QUATER_1',
  QUATER_2 = 'QUATER_2',
  QUATER_3 = 'QUATER_3',
  QUATER_4 = 'QUATER_4',
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}
export enum Today_TimePeriodEnum {
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  MTD = 'MTD',
}
export enum Year_TimePeriodEnum {
  YTD = 'YTD',
  QUATER_1 = 'QUATER_1',
  QUATER_2 = 'QUATER_2',
  QUATER_3 = 'QUATER_3',
  QUATER_4 = 'QUATER_4',
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

export enum TimePeriodEnumForToday {
  ALL = 'ALL',
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  MTD = 'MTD',
}
export enum TimePeriodEnumForYear {
  ALL = 'ALL',
  QUATER_1 = 'QUATER_1',
  QUATER_2 = 'QUATER_2',
  QUATER_3 = 'QUATER_3',
  QUATER_4 = 'QUATER_4',
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

export enum OverallMetricsEnum {
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  NORMALISED = 'NORMALISED',
  RESOLUTION = 'RESOLUTION',
}
export enum BucketLoanEnum {
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  STABILISED = 'STABILISED',
}
export enum OverallMetricsEnum2 {
  NONE = 'NONE',
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  NORMALISED = 'NORMALISED',
}

export enum LoanStatusForAccountMovement {
  OVERALL = 'OVERALL',
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  NORMALISED = 'NORMALISED',
  STABILISED = 'STABILISED',
}
export interface AccountMovementSegmentResultDto {
  id: string;
  rank: number;
  name: string;
  region: string;
  percentage: number;
  focusBuckets: string;
}

export interface AccountMovementSegmentMTDResultDto {
  id: string;
  name: string;
  rank: number;
  region: string;
  mtdPercentage: number;
  lmsdPercentage: number;
  focusBuckets: string;
}
export interface RoleBaseAccountMovementResponse {
  id: string;
  rank: number;
  empCode: string;
  name: string;
  region: string;
  percentage: number;
  focusBuckets: string;
  mtdPercentage?: number;
  lmsdPercentage?: number;
  ytdPercentage?: number;
  lysdPercentage?: number;
  percentageChange?: number;
}

export interface AccountMovementSegmentYtdResultDto {
  id: string;
  name: string;
  rank: number;
  region: string;
  ytdPercentage: number;
  lysdPercentage: number;
  focusBuckets: string;
}

export enum AnalyticsQueryTypeEnum {
  TODAY_COLLECTION_BASE = 'TODAY_COLLECTION_BASE',
  TODAY_COLLECTION_FIRST_CLICK_REGION = 'TODAY_COLLECTION_FIRST_CLICK_REGION',
  TODAY_COLLECTION_FIRST_CLICK_CHANNEL = 'TODAY_COLLECTION_FIRST_CLICK_CHANNEL',
  TODAY_COLLECTION_CASES_BASE = 'TODAY_COLLECTION_CASES_BASE',
  MTD_COLLECTION_BUCKETWISE_BASE = 'MTD_COLLECTION_BUCKETWISE_BASE',
  TELE_COLLECTION_EXECUTIVE = 'TELE_COLLECTION_EXECUTIVE',
  CE = 'CE',
  BUCKET_PERFORMANCE = 'BUCKET_PERFORMANCE',
  REGION_PERFORMANCE = 'REGION_PERFORMANCE',
  SEGMENT_PERFORMANCE = 'SEGMENT_PERFORMANCE',
  RESOLUTION = 'RESOLUTION',
  ACCOUNT_MOVEMENT = 'ACCOUNT_MOVEMENT',
  YEAR_TO_DATE_BUCKET_LEVEL_PERFORMANCE = 'YEAR_TO_DATE_BUCKET_LEVEL_PERFORMANCE',
  MONTH_ON_MONTH_PENAL_COLLECTION_PERFORMANCE = 'MONTH_ON_MONTH_PENAL_COLLECTION_PERFORMANCE',
  OVERALL_PERFORMANCE = 'OVERALL_PERFORMANCE',
  OVERALL_MONTH_TO_DATE_PERFORMANCE = 'OVERALL_MONTH_TO_DATE_PERFORMANCE',
  OVERALL_YEAR_TO_DATE_PERFORMANCE = 'OVERALL_YEAR_TO_DATE_PERFORMANCE',
  OVERALL_MONTH_TO_DATE_ROLE_PERFORMANCE = 'OVERALL_MONTH_TO_DATE_ROLE_PERFORMANCE',
  OVERALL_ROLE_PERFORMANCE = 'OVERALL_ROLE_PERFORMANCE',
  OVERALL_YEAR_TO_DATE_ROLE_PERFORMANCE = 'OVERALL_YEAR_TO_DATE_ROLE_PERFORMANCE',
  COMMUNICATION_DETAILS = 'COMMUNICATION_DETAILS',
  OVERALL_MONTHLY_PENAL_COLLECTION = 'OVERALL_MONTHLY_PENAL_COLLECTION',
  OVERALL_YEARLY_PENAL_COLLECTION = 'OVERALL_YEARLY_PENAL_COLLECTION',
  FIELD_APP_DASHBOARD = 'FIELD_APP_DASHBOARD',
  DISPOSITION_CODE_COUNT = 'DISPOSITION_CODE_COUNT',
  DISPOSITION_CODE_VISIT_COUNT = 'DISPOSITION_CODE_VISIT_COUNT',
  VISIT_PER_DAY = 'VISIT_PER_DAY',
  DISTANCE_TRAVELLED_PER_DAY = 'DISTANCE_TRAVELLED_PER_DAY',
  DAY_WISE_PTP = 'DAY_WISE_PTP',
  VISIT_PER_AGENT = 'VISIT_PER_AGENT',
  DISTANCE_TRAVELLED_PER_AGENT = 'DISTANCE_TRAVELLED_PER_AGENT',
  REGION_MONTHLY_PENAL_COLLECTION = 'REGION_MONTHLY_PENAL_COLLECTION',
  REGION_YEARLY_PENAL_COLLECTION = 'REGION_YEARLY_PENAL_COLLECTION',
  REGION_PENAL_COLLECTION = 'REGION_PENAL_COLLECTION',
  CHANNEL_MONTHLY_PENAL_COLLECTION = 'CHANNEL_MONTHLY_PENAL_COLLECTION',
  CHANNEL_YEARLY_PENAL_COLLECTION = 'CHANNEL_YEARLY_PENAL_COLLECTION',
  CHANNEL_PENAL_COLLECTION = 'CHANNEL_PENAL_COLLECTION',
  BUCKET_MONTHLY_PENAL_COLLECTION = 'BUCKET_MONTHLY_PENAL_COLLECTION',
  BUCKET_YEARLY_PENAL_COLLECTION = 'BUCKET_YEARLY_PENAL_COLLECTION ',
  BUCKET_PENAL_COLLECTION = 'BUCKET_PENAL_COLLECTION',
  ACCOUNT_MOVEMENT_MONTH_TILL_DATE = 'ACCOUNT_MOVEMENT_MONTH_TILL_DATE',
  BUCKET_MONTH_TILL_DATE_ACCOUNT_MOVEMENT = 'BUCKET_MONTH_TILL_DATE_ACCOUNT_MOVEMENT',
  BUCKET_YEAR_TILL_DATE_ACCOUNT_MOVEMENT = 'BUCKET_YEAR_TILL_DATE_ACCOUNT_MOVEMENT',
  ACCOUNT_MOVEMENT_YEAR_TILL_DATE = 'ACCOUNT_MOVEMENT_YEAR_TILL_DATE',
  ACCOUNT_MOVEMENT_MONTH_ON_MONTH = 'ACCOUNT_MOVEMENT_MONTH_ON_MONTH',
  SEGMENT_MONTHLY_PENAL_COLLECTION = 'SEGMENT_MONTHLY_PENAL_COLLECTION',
  SEGMENT_YEARLY_PENAL_COLLECTION = 'SEGMENT_YEARLY_PENAL_COLLECTION',
  SEGMENT_PENAL_COLLECTION = 'SEGMENT_PENAL_COLLECTION',
  REGION_ACCOUNT_MOVEMENT = 'REGION_ACCOUNT_MOVEMENT',
  REGION_MONTH_TILL_DATE_ACCOUNT_MOVEMENT = 'REGION_MONTH_TILL_DATE_ACCOUNT_MOVEMENT',
  REGION_YEAR_TILL_DATE_ACCOUNT_MOVEMENT = 'REGION_YEAR_TILL_DATE_ACCOUNT_MOVEMENT',
  CHANNEL_ACCOUNT_MOVEMENT = 'CHANNEL_ACCOUNT_MOVEMENT',
  CHANNEL_MONTH_TILL_DATE_ACCOUNT_MOVEMENT = 'CHANNEL_MONTH_TILL_DATE_ACCOUNT_MOVEMENT',
  CHANNEL_YEAR_TILL_DATE_ACCOUNT_MOVEMENT = 'CHANNEL_YEAR_TILL_DATE_ACCOUNT_MOVEMENT',
  SEGMENT_ACCOUNT_MOVEMENT = 'SEGMENT_ACCOUNT_MOVEMENT',
  SEGMENT_MONTH_TILL_DATE_ACCOUNT_MOVEMENT = 'SEGMENT_MONTH_TILL_DATE_ACCOUNT_MOVEMENT',
  SEGMENT_YEAR_TILL_DATE_ACCOUNT_MOVEMENT = 'SEGMENT_YEAR_TILL_DATE_ACCOUNT_MOVEMENT',
  BUCKET_ACCOUNT_MOVEMENT = 'BUCKET_ACCOUNT_MOVEMENT',
  VOLUME_REGION_PERFORMANCE = 'VOLUME_REGION_PERFORMANCE',
  VOLUME_CHANNEL_PERFORMANCE = 'VOLUME_CHANNEL_PERFORMANCE',
  VOLUME_SEGMENT_PERFORMANCE = 'VOLUME_SEGMENT_PERFORMANCE',
  VOLUME_BUCKET_PERFORMANCE = 'VOLUME_BUCKET_PERFORMANCE',
  DIGITAL_COLLECTIONS = 'DIGITAL_COLLECTIONS',

  TELE_AGENT_PERFORMANCE = 'TELE_AGENT_PERFORMANCE',
  TELE_AGENT_MONTH_TO_DATE_PERFORMANCE = 'TELE_AGENT_MONTH_TO_DATE_PERFORMANCE',
  TELE_AGENT_YEAR_TO_DATE_PERFORMANCE = 'TELE_AGENT_YEAR_TO_DATE_PERFORMANCE',
}

export enum AnalyticsQueryDetailsTypeEnum {
  TODAY_VALUE_SUMMARY = 'TODAY_VALUE_SUMMARY',
  TODAY_VOLUME_SUMMARY = 'TODAY_VOLUME_SUMMARY',
  MTD_PENAL_SUMMARY = 'MTD_PENAL_SUMMARY',
  YTD_PENAL_SUMMARY = 'YTD_PENAL_SUMMARY',
  MTD_VALUE_SUMMARY = 'MTD_VALUE_SUMMARY ',
  YTD_VALUE_SUMMARY = 'YTD_VALUE_SUMMARY ',
}
export const getAnalyticsQueries = createAsyncThunk(
  'analyticsDashboard/getAnalyticsQueries',
  async (request: Partial<AnalyticsQueryRequest>, { rejectWithValue }) => {
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);
export type AllEnums =
  | typeof CollectionWiseType
  | typeof TodayColletionOptionType
  | typeof CollectionValueAndVolumnOptionType
  | typeof RegionPerformanceRoleEnum
  | typeof SegmentOptionType
  | typeof OverallMetricsEnum2
  | typeof BucketEnumForYearAnalytics
  | typeof BucketEnumForAnalytics
  | typeof LoanStatusForAccountMovement
  | typeof OrderByEnum
  | typeof ResolutionEnum
  | typeof RoleBasedPerformanceGraphSegmentOptionType;

export type AllTimePeriodEnums =
  | typeof Today_TimePeriodEnum
  | typeof Year_TimePeriodEnum;

export enum RegionPerformanceRoleEnum {
  CE = 'CE',
  TELE_COLLECTION_EXECUTIVE = 'TELE_COLLECTION_EXECUTIVE',
}
export interface AnalyticsDashboardSliceStateProps {
  selectedCalenderViewType: CelenderViewType;
  selectedCollectionProfitType: CollectionProfitType;
  selectedCollectionWiseType: string;
  selectedTimePeriod: TimePeriodEnum;
  selectedMetrics: string;
  selectedAccountMetric: string;
  selectedCollectionViewType: string;
  error: string;
  isCollectionViewType: boolean;
  loading: boolean;
  analyticsQueries: any[];
  analyticsQueryResponse: Partial<AnalyticsQueryResponse[]>;
  optionType: any;
  calenderTypeState: string;
  selectedYearCollectionTeamMetricBucket: string;
  selectedTeleCallerBucket: string;
}

const initialState: AnalyticsDashboardSliceStateProps = {
  selectedCalenderViewType: CelenderViewType.TODAY,
  selectedCollectionProfitType: CollectionProfitType.GROSS,
  selectedTimePeriod: TimePeriodEnum.ONE_DAY,
  isCollectionViewType: false,
  loading: false,
  error: '',
  selectedCollectionViewType: '',
  selectedMetrics: '',
  calenderTypeState: '',
  analyticsQueries: [],
  analyticsQueryResponse: [],
  optionType: CollectionWiseType,
  selectedCollectionWiseType: '',
  selectedAccountMetric: '',
  selectedYearCollectionTeamMetricBucket: '',
  selectedTeleCallerBucket: '',
};

const analyticsDashboardSlice = createSlice({
  name: 'analyticsDashboard',
  initialState,
  reducers: {
    setSelectedCalenderViewType: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedCalenderViewType = payload;
    },
    setSelectedCollectionProfitType: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedCollectionProfitType = payload;
    },

    setSelectedCollectionWiseType: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedCollectionWiseType = payload;
    },
    setSelectedCollectionViewType: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedCollectionViewType = payload;
    },
    setIsCollectionViewType: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.isCollectionViewType = payload;
    },
    setSelectedTimePeriod: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedTimePeriod = payload;
    },
    setSelectedMetrics: (state: AnalyticsDashboardSliceStateProps, action) => {
      const { payload } = action;
      state.selectedMetrics = payload;
    },
    setSelectedAccountMetric: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedAccountMetric = payload;
    },
    setSelectedYearTeleCallerBucket: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedTeleCallerBucket = payload;
    },
    setSelectedYearCollectionTeamMetricBucket: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.selectedYearCollectionTeamMetricBucket = payload;
    },
    setOptionType: (state: AnalyticsDashboardSliceStateProps, action) => {
      const { payload } = action;
      state.optionType = payload;
    },
    setCalenderTypeState: (
      state: AnalyticsDashboardSliceStateProps,
      action
    ) => {
      const { payload } = action;
      state.calenderTypeState = payload;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getAnalyticsQueries.pending, state => {
        state.loading = true;
        state.error = '';
        state.analyticsQueries = [];
      })
      .addCase(getAnalyticsQueries.fulfilled, (state, action) => {
        // const accountList = [...action.payload];
        const response = [...action.payload];
        state.loading = false;
        state.analyticsQueries = response;
        // state.analyticsQueryResponse = response;
        state.error = '';
      })
      .addCase(getAnalyticsQueries.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      });
  },
});
export const {
  setSelectedCalenderViewType,
  setSelectedCollectionProfitType,
  setSelectedCollectionWiseType,
  setIsCollectionViewType,
  setSelectedCollectionViewType,
  setOptionType,
  setSelectedMetrics,
  setSelectedTimePeriod,
  setCalenderTypeState,
  setSelectedAccountMetric,
  setSelectedYearCollectionTeamMetricBucket,
  setSelectedYearTeleCallerBucket,
} = analyticsDashboardSlice.actions;
export const getAnalyticsDashboardState = (state: RootState) =>
  state.analyticsDashboard;
export default analyticsDashboardSlice;
