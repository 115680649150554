import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AgentLevelIncentiveDetailsResponseDto,
  CollectionType,
  IncentivePolicyProps,
  ParameterDefinitionType,
  PayoutConfiguration,
  TempleteListProps,
  ViewIncentiesGamificationsModal,
} from '../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  IncentiveWriteOffRulesRequest,
} from '../../types/request/incentives';
import { WriteOffEnum } from '../../types/targets';
import { DateUtility } from '../../utils/DateUtlility';
import { incentivesExtraReducers } from './incentivesExtraReducers';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);
const getLocalPolicyTab = JSON.parse(localStorage.getItem('policyTab'));

export enum PayoutPolicyType {
  BASE = 'Base Incentive',
  BUMPER = 'Bumper Incentive',
  PANALTY = 'Penalty Incentive',
  // MIN_ELIGIBILITY = 'Minimum Eligibility Criteria',
}
export enum WriteOffPayoutPolicyType {
  INCENTIVE_PROPOSED = 'Incentive Proposed On Recovery',
  MAX_PAYOUT = 'Max Payout',
  INTEREST_CHARGES = 'Incentive % on Interest and Charges collection',
}
export enum IncentiveStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const MinimumEligibilityCriteriaRange = {
  parameter: '',
  minNo: 0,
  metric: '',
  computationBasis: '',
};

export const basePolicy = {
  parameterDefinitionType: '',
  parameterMin: 0,
  parameterMax: 0,
  parameterExact: null,
  payoutAmountValue: 0,
  payoutAmountMax: null,
  payoutAmountMin: null,
};

export const initialRangeBasePolicy = {
  parameterDefinitionType: 'RANGE',
  parameterMin: 0,
  parameterMax: 0,
  parameterExact: null,
  payoutAmountValue: 0,
  payoutAmountMax: null,
  payoutAmountMin: null,
};

export const initialChargeCollectedRangeBasePolicy = {
  parameterDefinitionType: 'FIXED',
  parameterMin: 0,
  parameterMax: 0,
  parameterExact: null,
  payoutAmountValue: 0,
  payoutAmountMax: null,
  payoutAmountMin: null,
};

export const initialRangeBasePolicyForNoOfCases = {
  parameterDefinitionType: 'RANGE',
  parameterMin: null,
  parameterMax: null,
  parameterExact: null,
  payoutAmountValue: 0,
  payoutAmountMax: 0,
  payoutAmountMin: 0,
};
export const initialFixedBasePolicy = {
  parameterDefinitionType: 'FIXED',
  parameterMin: null,
  parameterMax: null,
  parameterExact: 1,
  payoutAmountValue: 0,
  payoutAmountMax: null,
  payoutAmountMin: null,
};

export const DEFAULT_RULE: IncentivePolicyRulesRequest = {
  incentiveBasePolicyId: '',
  bucket: '',
  collectionType: CollectionType.RESOLUTION,
  payoutMetric: '',
  setMinimumEligibility: false,
  minimumEligibilityParameters: null,
  basePayoutRange: [{ ...initialRangeBasePolicy }],
  chargesCollectedPayoutRange: [{ ...initialChargeCollectedRangeBasePolicy }],
  penaltyRange: null,
  bumperPayoutRange: null,
};

export const initailFixedLanPayoutRange = {
  parameterDefinitionType: ParameterDefinitionType.FIXED,
  parameterExact: 1.0,
  payoutAmountValue: 8,
};
export const initailRangeLanPayoutRange = {
  parameterDefinitionType: ParameterDefinitionType.RANGE,
  parameterMin: 3.0,
  parameterMax: 100.0,
  payoutAmountValue: 12,
};
export const initialPosWaiverRange = {
  parameterDefinitionType: ParameterDefinitionType.RANGE,
  parameterMin: 0,
  parameterMax: 10.0,
};
export const initailIncentiveProposed = {
  posWaiverRange: initialPosWaiverRange,
  lanPayoutRange: [initailFixedLanPayoutRange],
};
export const DEFAULT_WRITE_OFF_RULE: IncentiveWriteOffRulesRequest = {
  incentiveBasePolicyId: '',
  writeOffIncentiveType: WriteOffEnum.TECHNICAL,
  incentiveInterestDto: {
    incentiveOnInterest: 0,
    maxPayoutForInterestIncentive: 0,
  },
  maxPolicyPayoutAmt: 0,
  rules: [initailIncentiveProposed],
};

export const DEFAULT_POLICY_DETAILS: IncentivePolicyProps = {
  policyName: '',
  policyType: '',
  vertical: '',
  caseType: [],
  chargeCollectedRules: [],
  channelType: '',
  incentiveType: '',
  policyStartDate: DateUtility.formatStringToYYYYMMDD(TODAY.toDateString()),
  policyEndDate: DateUtility.formatStringToYYYYMMDD(TOMORROW.toDateString()),
  accountType: [],
  payoutFrequency: '',
  maxPayoutPerPerson: 0,
  userType: [],
  splitPayoutForUsers: [],
  modeOfCommunication: [],
  bucketsHavingRules: [],
  rules: {},
};

export interface IncentivePolicySliceProps {
  policies: ViewIncentiesGamificationsModal[];
  templates: TempleteListProps[];
  viewAgentLevelData: AgentLevelIncentiveDetailsResponseDto[];
  totalItems: number;
  totalItemsForViewAgentLevel: number;
  selectedTab: number;
  selectedWriteOffTab: number;
  selectedPolicyTab: number;
  loading: boolean;
  loadingForViewAgentLevel: boolean;
  error: string | null;
  errorForViewAgentLevel: string | null;
  selectedPayoutPolicyType: string;
  selectedWriteOffPayoutPolicyType: string;
  errorPolicy: string;
  policyRuleState: IncentivePolicyRulesRequest | null;
  policyDetailsState: IncentivePolicyProps;
  policyEnumValues: { [key: string]: string[] };
  templatesRules: PayoutConfiguration | null;
  writeOffTemplatesRules: IncentiveWriteOffRulesRequest | null;
  writeOffruleState: IncentiveWriteOffRulesRequest;
  chargeCollectedRules: IncentivePolicyRulesRequest | null;
  countError: string;
}

const initialState: IncentivePolicySliceProps = {
  policies: [],
  templates: [],
  viewAgentLevelData: [],
  chargeCollectedRules: DEFAULT_RULE,
  loading: false,
  loadingForViewAgentLevel: false,
  error: null,
  totalItems: 0,
  totalItemsForViewAgentLevel: 0,
  selectedTab: 0,
  selectedWriteOffTab: 0,
  selectedPolicyTab: getLocalPolicyTab ? getLocalPolicyTab : 0,
  policyRuleState: DEFAULT_RULE,
  policyDetailsState: DEFAULT_POLICY_DETAILS,
  policyEnumValues: {},
  templatesRules: null,
  writeOffTemplatesRules: null,
  selectedPayoutPolicyType: PayoutPolicyType.BASE,
  selectedWriteOffPayoutPolicyType: WriteOffPayoutPolicyType.INCENTIVE_PROPOSED,
  errorPolicy: '',
  errorForViewAgentLevel: '',
  countError: '',
  writeOffruleState: DEFAULT_WRITE_OFF_RULE,
};

const IncentivesGamificationsSlice = createSlice({
  name: 'incentiesGamifications',
  initialState,
  reducers: {
    setSelectedPolicyTab: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedPolicyTab = payload;

      localStorage.setItem('policyTab', payload);
    },
    setSelectedTab: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedTab = payload;
    },
    setSelectedWriteOffTab: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedWriteOffTab = payload;
    },
    setErrorPolicy: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.errorPolicy = payload;
    },
    setCountError: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.countError = payload;
    },
    setPolicyRuleState: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.policyRuleState = payload;
    },
    setWriteOffruleState: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.writeOffruleState = payload;
    },
    setPolicyDetailsState: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.policyDetailsState = payload;
    },
    setSelectedPayoutPolicyType: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedPayoutPolicyType = payload;
    },
    setSelectedWriteOffPayoutPolicyType: (
      state: IncentivePolicySliceProps,
      action
    ) => {
      const { payload } = action;
      state.selectedWriteOffPayoutPolicyType = payload;
    },
    setTemplatesRules: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.templatesRules = payload;
    },
    setWriteOffTemplatesRules: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.writeOffTemplatesRules = payload;
    },
    setChargeCollectedRules: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.chargeCollectedRules = payload;
    },
  },
  extraReducers: incentivesExtraReducers,
});
export const {
  setPolicyRuleState,
  setPolicyDetailsState,
  setSelectedPayoutPolicyType,
  setSelectedTab,
  setSelectedWriteOffTab,
  setErrorPolicy,
  setSelectedPolicyTab,
  setTemplatesRules,
  setWriteOffruleState,
  setWriteOffTemplatesRules,
  setSelectedWriteOffPayoutPolicyType,
  setCountError,
} = IncentivesGamificationsSlice.actions;
export const getIncentivesGamificationsState = (state: RootState) =>
  state.incentiesGamifications;
export default IncentivesGamificationsSlice;
