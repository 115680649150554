import { Stack } from '@mui/material';
import { EscalationsServices } from '../../../Apis/EscalationsServices';
import ListOfLeaveRequest from '../../../components/Escalations/ListOfLeaveRequest';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaButton from '../../../components/common/VegaButton';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { EscalationTriggerType } from '../../../types/request/escalationsRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

const LeaveRequest = () => {
  const { setSnackbar } = useSnackbar();

  const triggerEscalation = () => {
    EscalationsServices.triggerEscalation({
      triggerType: EscalationTriggerType.LEAVE,
    })
      .then(res => {})
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack gap={1} width={'100%'}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <VegaBreadCrumb
                  items={[
                    { label: 'Escalation', link: ROUTES.ESCALATIONS },
                    { label: `Leave request` },
                  ]}
                />
                <VegaButton
                  text="Trigger Escalation"
                  onClick={triggerEscalation}
                />
              </Stack>
            </Stack>
          );
        }}
      />
      <ListOfLeaveRequest />
    </>
  );
};

export default LeaveRequest;
