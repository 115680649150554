import _ from 'lodash';

export interface Ots {
  id: string;
  status: OtsStatus;
  technicalReportsStatus: OtsReportsStatus;
  operationReportStatus: OtsReportsStatus;
  requestLetter: string;
  settlementAmount: number;
  wavierAmount: number;
  loanId: string;
  timeUnit: TimeUnit;
  tenure: number;
  dayOfRepayment: number;
  installments: number;
  valuationAmount: number;
  operationValuationReport: string;
  technicalValuationReport: string;
  foreclosureLetter: string;
  statementOfAccount: string;
  sanctionLetter: string;
  agendaCaseId: string;
  agendaId: string;
  principle: number;
  interest: number;
  others: number;
  generatedLetter: string;
  ackLetter: string;
  otsLetter: string;
  rcm: string;
  agent: string;
  agendaCreator: string;
  technicalTeam: string;
  operationTeam: string;
  ncm: string;
  hoc: string;
  acm: string;
  asm: string;
  cm: string;
  zcm: string;
  ccm: string;
  md: string;
  customerAckUpdateId: string;
  raisedBy: string;
  createdAt: string;
  updatedAt: string;
  rejectionCount: number;
  lastRejectionLevel: OtsStatus;
  commentIds: string[];
  additionalDocuments: string[];
  realisableValue: number;
  marketValue: number;
  propertyDocsAvailable: boolean;
  distressValue: number;
  distressedValuationAmount: number;
  npvOfDistressValue: number;
  tosBasedMaxValue: number;
  pvOfTosBasedMaxValue: number;
  propertyValueRealization: number;
  fixedRealisationCost: number;
  variableRealisationCost: number;
  totalRealisationCost: number;
  pvRealisationCost: number;
  discountedRoi: number;
  npvCalculated: boolean;
  saleCompletionPeriod: number;
  metaData: Record<string, any>;
  note: string;
  transitionedAt: string;
}

export type OtsUserComment = {
  id: string;
  otsId: string;
  userId: string;
  comment: string;
  createdAt: string;
};

export type OtsAdditionalDocument = {
  id: string;
  otsId: string;
  userId: string;
  documentUrl: string;
  createdAt: string;
  fileName: string;
};

export enum OtsMetaDataKey {
  RAISED_BY = 'raisedBy',
  RCM = 'RCM',
  NCM = 'NCM',
  HOC = 'HOC',
  AGENDA_CREATOR = 'AGENDA_CREATOR',
  MD = 'MD',
}

export interface OtsRequestAudit {
  id: string;
  otsId: string;
  userId: string;
  userName: string;
  action: OtsAction;
  auditType: OtsAuditType;
  description: string;
  timestamp: string;
  previousValue: string;
  currentValue: string;
}

export interface Agenda {
  id: string;
  agendaCases: string[];
  rejectedCases: string[];
  pendingApprovalAgendaCases: string[];
  committeeNumber: number;
  scheduledDateTime: string;
  chairPerson: string;
  status: AgendaStatus;
  agendaCreator: string;
  memberIds: string[];
  createdAt: string;
  updatedAt: string;
  metaData: Record<string, any>;
}

export enum AgendaMetaDataKeys {
  CHAIR = 'chairPerson',
  CREATOR = 'agendaCreator',
  MEMBERS = 'members',
}

export interface AgendaCase {
  id: string;
  otsId: string;
  loanId: string;
  agendaId: string;
  meetingStatus: AgendaCaseStatus;
  committeeDecisionStatus: AgendaCaseStatus;
  discussionStarted: boolean;
  otsChairDecisionStatus: AgendaCaseStatus;
  installments: number;
  committeeComment: string;
  dayOfRepayment: number;
  generatedNote: string;
  meetingExclusionReason: string;
  createdAt: string;
  updatedAt: string;
  metaData: Record<string, any>;
}

export enum AgendaCaseMetaDataKeys {
  Loan = 'loan',
}

export enum OtsAuditType {
  OTS_REQUEST = 'OTS_REQUEST',
  OPERATION_REPORT = 'OPERATION_REPORT',
  TECHNICAL_REPORT = 'TECHNICAL_REPORT',
}

export enum OtsAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  ON_HOLD = 'ON_HOLD',
  RE_OPEN = 'RE_OPEN',
  SUBMIT = 'SUBMIT',
  DEFERRED = 'DEFERRED',
}

export enum OtsUserRole {
  CE = 'CE',
  RCM = 'RCM',
  NCM = 'NCM',
  GROUP_COLLECTIONS_HEAD = 'GROUP_COLLECTIONS_HEAD',
  OPERATION_TEAM = 'OPERATION_TEAM',
  TECHNICAL_TEAM = 'TECHNICAL_TEAM',
  AGENDA_CREATOR = 'AGENDA_CREATOR',
  MANAGING_DIRECTOR = 'MANAGING_DIRECTOR',
  COMMITTEE_CHAIR = 'COMMITTEE_CHAIR',
}

export enum OtsStatus {
  PRE_RCM_APPROVAL = 'PRE_RCM_APPROVAL',
  POST_RCM_APPROVAL = 'POST_RCM_APPROVAL',

  PRE_RCM_ON_HOLD = 'PRE_RCM_ON_HOLD',
  POST_RCM_ON_HOLD = 'POST_RCM_ON_HOLD',

  PRE_RCM_REJECT = 'PRE_RCM_REJECT',
  POST_RCM_REJECT = 'POST_RCM_REJECT',

  PRE_NCM_APPROVAL = 'PRE_NCM_APPROVAL',
  POST_NCM_APPROVAL = 'POST_NCM_APPROVAL',

  PRE_NCM_ON_HOLD = 'PRE_NCM_ON_HOLD',
  POST_NCM_ON_HOLD = 'POST_NCM_ON_HOLD',

  PRE_NCM_REJECT = 'PRE_NCM_REJECT',
  POST_NCM_REJECT = 'POST_NCM_REJECT',

  PRE_HOC_APPROVAL = 'PRE_HOC_APPROVAL',
  POST_HOC_APPROVAL = 'POST_HOC_APPROVAL',

  PRE_HOC_REJECT = 'PRE_HOC_REJECT',
  POST_HOC_REJECT = 'POST_HOC_REJECT',

  PRE_HOC_ON_HOLD = 'PRE_HOC_ON_HOLD',
  POST_HOC_ON_HOLD = 'POST_HOC_ON_HOLD',

  PRE_AGENDA_CREATION = 'PRE_AGENDA_CREATION',
  POST_AGENDA_CREATION = 'POST_AGENDA_CREATION',

  PRE_CHAIR_APPROVAL = 'PRE_CHAIR_APPROVAL',
  PRE_COMMITTEE_APPROVAL = 'PRE_COMMITTEE_APPROVAL',

  PRE_HOC_POST_COMMITTEE_APPROVAL = 'PRE_HOC_POST_COMMITTEE_APPROVAL',
  HOC_POST_COMMITTEE_REJECT = 'HOC_POST_COMMITTEE_REJECT',
  POST_HOC_POST_COMMITTEE_APPROVAL = 'POST_HOC_POST_COMMITTEE_APPROVAL',

  PRE_MD_APPROVAL = 'PRE_MD_APPROVAL',
  MD_REJECT = 'MD_REJECT',
  POST_MD_APPROVAL_APPROVE = 'POST_MD_APPROVAL_APPROVE',
  POST_MD_APPROVAL_REJECT = 'POST_MD_APPROVAL_REJECT',

  PENDING_CUSTOMER_ACKNOWLEDGEMENT = 'PENDING_CUSTOMER_ACKNOWLEDGEMENT',
  POST_CUSTOMER_ACKNOWLEDGEMENT_APPROVE = 'POST_CUSTOMER_ACKNOWLEDGEMENT_APPROVE',

  PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL = 'PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL',
  POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL = 'POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL',
  POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_REJECT = 'POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_REJECT',

  OTS_APPROVED = 'OTS_APPROVED',
  OTS_REJECTED = 'OTS_REJECTED',
  OTS_BREACHED = 'OTS_BREACHED',
}

export enum OtsReportsStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum TimeUnit {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum OtsReportType {
  TECHNICAL = 'TECHNICAL',
  OPERATION = 'OPERATION',
}

export enum AgendaCaseStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING_CHAIR_APPROVAL = 'PENDING_CHAIR_APPROVAL',
  DEFERRED = 'DEFERRED',
}

export enum AgendaStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  CONCLUDED = 'CONCLUDED',
  PENDING_CHAIR_APPROVAL = 'PENDING_CHAIR_APPROVAL',
}

export function getOtsStatusDescription(status?: OtsStatus): string {
  if (!status) return '-';
  switch (status) {
    case OtsStatus.PRE_RCM_APPROVAL:
      return 'Pending RCM Approval';
    case OtsStatus.POST_RCM_APPROVAL:
      return 'Pending RCM Submission';
    case OtsStatus.POST_RCM_ON_HOLD:
      return 'On Hold By RCM';
    case OtsStatus.PRE_RCM_REJECT:
      return 'Pending Submission (Rejected)';
    case OtsStatus.POST_RCM_REJECT:
      return 'Rejected by RCM';

    case OtsStatus.PRE_NCM_APPROVAL:
      return 'Pending NCM Approval';
    case OtsStatus.POST_NCM_APPROVAL:
      return 'Pending NCM Submission';
    case OtsStatus.PRE_NCM_ON_HOLD:
      return 'On Hold By NCM';
    case OtsStatus.POST_NCM_ON_HOLD:
      return 'NCM On Hold After Approval';
    case OtsStatus.PRE_NCM_REJECT:
      return 'Pending Submission (NCM Rejected)';
    case OtsStatus.POST_NCM_REJECT:
      return 'Rejected by NCM';

    case OtsStatus.PRE_HOC_APPROVAL:
      return 'Pending HOC Approval';
    case OtsStatus.POST_HOC_APPROVAL:
      return 'Pending HOC Submission';
    case OtsStatus.PRE_HOC_ON_HOLD:
      return 'On Hold By HOC';
    case OtsStatus.POST_HOC_ON_HOLD:
      return 'HOC On Hold After Approval';
    case OtsStatus.PRE_HOC_REJECT:
      return 'Pending Submission (HOC Rejected)';
    case OtsStatus.POST_HOC_REJECT:
      return 'Rejected by HOC';

    case OtsStatus.PRE_AGENDA_CREATION:
      return 'Preparation for Agenda Creation';
    case OtsStatus.POST_AGENDA_CREATION:
      return 'Post Agenda Creation';

    case OtsStatus.PRE_CHAIR_APPROVAL:
      return 'Pending Chair Approval';
    case OtsStatus.PRE_COMMITTEE_APPROVAL:
      return 'Pending Committee Approval';
    case OtsStatus.PRE_HOC_POST_COMMITTEE_APPROVAL:
      return 'Pending HOC Post-Committee Approval';
    case OtsStatus.HOC_POST_COMMITTEE_REJECT:
      return 'HOC Rejected Post-Committee';
    case OtsStatus.POST_HOC_POST_COMMITTEE_APPROVAL:
      return 'HOC Approved Post-Committee';

    case OtsStatus.PRE_MD_APPROVAL:
      return 'Pending MD Approval';
    case OtsStatus.MD_REJECT:
      return 'MD Rejection';
    case OtsStatus.POST_MD_APPROVAL_APPROVE:
      return 'MD Approved pending Submission';
    case OtsStatus.POST_MD_APPROVAL_REJECT:
      return 'MD Rejected';

    case OtsStatus.PENDING_CUSTOMER_ACKNOWLEDGEMENT:
      return 'Pending Customer Acknowledgement';
    case OtsStatus.PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL:
      return 'Pre-Customer Acknowledgement Approval';
    case OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL:
      return 'Post Customer Acknowledgement Approval';
    case OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_REJECT:
      return 'Customer Acknowledgement Rejected';

    case OtsStatus.OTS_APPROVED:
      return 'OTS Approved';
    case OtsStatus.OTS_REJECTED:
      return 'OTS Rejected';

    default:
      return _.startCase(_.toLower(status));
  }
}

export function getValueFromRecord<T>(
  record: Record<string, any>,
  key: string
): T {
  if (!record) return;
  const value = record[key];
  return value !== undefined ? (value as T) : undefined;
}

export interface OtsInstallmentPlan {
  id: string;
  otsId: string;
  agendaCaseId: string;
  amount: string;
  date: string;
}

export interface OtsConfig {
  maxResubmissions: number;
  ncmTatDays: number;
  rcmTatDays: number;
  hocTatDays: number;
  mdTatDays: number;
  chairTatDays: number;
  opsTatDays: number;
  techTatDays: number;
  agentTatDays: number;
  customerAckTatDays: number;
  notificationFrequencyDays: number;
}
