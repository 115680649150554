import { Collapse, Grid } from '@mui/material';
import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  fetchMinVisitList,
  getMinVisitsState,
  setMinVisitsRuleState,
  setOpenCreateRuleDrawer,
  setSelectedRegion,
} from '../../../features/minVisitsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CreateMinVisitRuleProps } from '../../../types/minVisitsType';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import BranchSelector from './BranchSelector';
import RegionSelector from './RegionSelector';

const CreateRuleForFormDrawer = () => {
  const { minVisitsRuleState, openCreateRuleDrawer } =
    useAppSelector(getMinVisitsState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onClose = () => {
    dispatch(setOpenCreateRuleDrawer(false));
    dispatch(setMinVisitsRuleState(null));
    dispatch(setSelectedRegion(''));
  };
  const handleChange = (
    key: keyof CreateMinVisitRuleProps,
    value: string | number
  ) => {
    if (key === 'region') {
      const minVisitsObj = {
        ...minVisitsRuleState,
        [key]: value,
        branch: '',
      };
      dispatch(setMinVisitsRuleState(minVisitsObj));
    } else {
      const minVisitsObj = {
        ...minVisitsRuleState,
        [key]: value,
      };
      dispatch(setMinVisitsRuleState(minVisitsObj));
    }
  };

  const disabled =
    !minVisitsRuleState &&
    (!minVisitsRuleState?.branch?.length ||
      !minVisitsRuleState?.region?.length ||
      !minVisitsRuleState?.minVisits);

  const onCreateRule = () => {
    MinVisitsSevices.createMinVisitRule(minVisitsRuleState)
      .then(() => {
        setSnackbar('create rule successfully !!!');
        dispatch(fetchMinVisitList({ page: 0, pageSize: 10 }));
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  return (
    <VegaDrawer
      open={openCreateRuleDrawer}
      onClose={onClose}
      title="Create Rule"
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Create Rule'}
              disabled={disabled}
              onClick={onCreateRule}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'region'}>
              <RegionSelector
                selected={minVisitsRuleState?.region ?? []}
                handleChange={selected => handleChange('region', selected)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: minVisitsRuleState?.region?.length > 0 ? '1.5rem' : 0,
            }}
          >
            <Collapse in={minVisitsRuleState?.region?.length > 0}>
              <VegaFormInputField label={'branch'}>
                <BranchSelector
                  selected={minVisitsRuleState?.branch}
                  handleChange={selected => handleChange('branch', selected)}
                />
              </VegaFormInputField>
            </Collapse>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '1.5rem' }}>
            <VegaFormInputField label={'min no of visit'}>
              <LmTextField
                type="number"
                value={minVisitsRuleState?.minVisits}
                onChange={e => {
                  const value = e.target.value && parseInt(e.target.value);
                  handleChange('minVisits', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default CreateRuleForFormDrawer;
