import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import DailyCustomTarget from './modules/CustomeTarget/DailyCustomTarget';
import MonthlyCustomTarget from './modules/CustomeTarget/MonthlyCustomTarget';

enum CustomTargetType {
  PENAL_CHARGES = 'Monthly Target (Penal Charges)',
  TELE_COLLECTION_DAILY = 'Daily Target (Field & Tele Collection)',
}

const CustomTarget = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Penal Charge Targets'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                variant="scrollable"
                value={selectedTab}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                }}
              >
                {Object.keys(CustomTargetType).map(type => (
                  <VegaTabBarItem key={type} label={CustomTargetType[type]} />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      {selectedTab === 1 ? <DailyCustomTarget /> : <MonthlyCustomTarget />}
    </>
  );
};

export default CustomTarget;
