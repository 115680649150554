/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GREY } from '../../constants/style';
import { StringUtility } from '../../utils/StringUtility';
import LmDialog from '../common/LmDialog';
import VegaButton from '../common/VegaButton';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaText from '../common/VegaText';

type Props = {
  show: boolean;
  onClose: () => void;
  onConfirm: (auctionId: string, bidAmount: number) => void;
  bidAmount?: number;
  auctionId?: string;
};

function ConfirmBidDialog({
  show,
  onClose,
  onConfirm,
  auctionId,
  bidAmount,
}: Props) {
  function onConfirmClick() {
    if (auctionId && bidAmount) {
      onConfirm(auctionId, bidAmount);
    }
    onClose();
  }
  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={onClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            text="Cancel"
            fullWidth
            variant="outlined"
            onClick={onClose}
            sx={{ color: GREY.dark, borderColor: GREY.dark }}
            startIcon={<Close sx={{ color: GREY.dark }} />}
          />
          <VegaButton fullWidth text="Confirm" onClick={onConfirmClick} />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E7EDFC'}
        >
          <VegaText text="Are you sure?" fontWeight={600} fontSize={17} />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Stack direction={'column'} spacing="1rem">
          <VegaText
            color={'#1B1D22'}
            fontSize={'1.0625rem'}
            lineHeight={'120%'}
            fontWeight={600}
            mt={1}
            text={
              ' Are you sure, you want to place the bid of amount ' +
              StringUtility.formatToINR(bidAmount ?? 0) +
              '?'
            }
          ></VegaText>
        </Stack>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default ConfirmBidDialog;
