import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReallocateAllocationFormData } from '../../components/Allocation/ReallocateAllocationsDrawer';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
// import VegaTabBarItem from '../../components/common/VegaTabItem';
import { useDrawer } from '../../hooks/useDrawer';
import { AllocationTypes } from '../../types/allocations';
import AllocateLoanAccountsDrawer, {
  AllocateLoanAccountsFormData,
} from './modules/AllocateLoanAccountsDrawer';

import { AllocationService } from '../../Apis/AllocationService';
import { LoanService } from '../../Apis/LoanServices';
import {
  FilterAttributes,
  FilterOP,
  POS,
} from '../../components/Accounts/AccountFilterForm';
import LoanAccountsFilterForm from '../../components/Accounts/LoanAccountsFilterForm';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaColumnFilterSection, {
  LoanAccountColumns,
} from '../../components/common/VegaColumnFilterSection';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaSelect, {
  VegaSelectOption,
} from '../../components/common/VegaSelect';
import VegaTabBarItemWithNotification from '../../components/common/VegaTabBarItemWithNotification';
import { toLowerCase } from '../../constants/commonFunction';
import {
  getAccountList,
  getAccountState,
  setFilterForm,
} from '../../features/accountsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { GetExportAllocationMetaDataRequest } from '../../types/request/allocation';
import { LoanFilterProps, LoanListRequest } from '../../types/request/loan';
import { CountResponse, getErrorMessageFromErrorObj } from '../../utils/api';
import { LoanAccountColumn } from './column/LoanAccountColumn';
import DeallocateLoanAccountsDialog from './modules/DeallocateLoanAccountsDialog';
import ExecuteLoanAccountsDrawer, {
  ExecuteLoanAccountsFormData,
} from './modules/ExecuteLoanAccountsDrawer';
import ReallocateLoanAccountsDrawer, {
  ReallocateLoanAccountsFormData,
} from './modules/ReallocateLoanAccountsDrawer';
import { setColumnFilters } from '../../features/filterColumnSlice';
import { UiModule } from '../../router/routes';

enum SearchBy {
  LAN = 'LAN',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  AGENT_NAME = 'AGENT_NAME',
}

// enum AllocationTabType {
//   ALL,
//   Allocated,
//   Unallocated,
//   Normalized,
//   Stabilized,
//   Rollback,
//   Rollforward,
//   Collection,
//   Overdue,
// }

export enum AllocationTabType2 {
  ALL = 'ALL',
  ALLOCATED = 'ALLOCATED',
  UNALLOCATED = 'UNALLOCATED',
  NORMALISED = 'NORMALISED',
  STABILISED = 'STABILISED',
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  COLLECTED = 'COLLECTED',
  OVERDUE = 'OVERDUE',
  CANCELLED = 'CANCELLED',
}

function LoanAccounts() {
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { accounts, totalItems, loading, filterForm } =
    useAppSelector(getAccountState);
  const [searchBy, setSearchBy] = useState<string>(SearchBy.LAN);
  const [count, setCount] = useState<CountResponse>({
    ALLOCATED: 0,
    NORMALISED: 0,
    Null: 0,
    STABILISED: 0,
    UNALLOCATED: 0,
    All: 0,
    COLLECTED: 0,
    ROLL_BACK: 0,
    ROLL_FORWARD: 0,
    OVERDUE: 0,
    CANCELLED: 0,
  });
  const getRequestIdFromStorage = localStorage.getItem('requestId')
    ? localStorage.getItem('requestId')
    : '';
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [searchLoanId, setSearchLoanId] = useState<string>('');
  const [requestId, setRequestId] = useState<string>(getRequestIdFromStorage);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [exporting, setExporting] = useState<boolean>(false);
  const [countLoading, setCountLoading] = useState<boolean>(false);

  const {
    open: openAllocateDrawer,
    close: closeAllocateDrawer,
    isOpen: isAllocateDrawerOpen,
  } = useDrawer();

  const {
    open: openDeAllocationDrawer,
    close: closeDeAllocatioDrawer,
    isOpen: isDeAllocatioDrawerOpen,
  } = useDrawer();

  const {
    open: openReAllocationDrawer,
    close: closeReAllocationDrawer,
    isOpen: isReAllocationDrawerOpen,
  } = useDrawer();

  const {
    open: openExecutionDrawer,
    close: closeExecutionDrawer,
    isOpen: isExecutionDrawerOpen,
  } = useDrawer();

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const handleSelectionModelChange = (selectionModel: string[]) => {
    setSelectedRows(selectionModel);
  };

  function _onTabChange(value: number): void {
    setSelectedTab(value);
    setSelectedOptions([]);
    setSearchLoanId('');
    setPage(0);
    setSize(10);
    setSearchBy(SearchBy.LAN);
  }
  const getSearchByOptions = () => {
    const options = Object.keys(SearchBy).map(
      search =>
        ({
          label: toLowerCase(search),
          value: search,
        } as VegaSelectOption)
    );

    if (selectedTab !== 1) {
      return options.filter(option => option.value !== SearchBy.AGENT_NAME);
    }

    return options;
  };
  const getFilterArray = (
    attribute: FilterAttributes,
    op: FilterOP,
    value: string | number,
    filter: LoanFilterProps[]
  ) => {
    let newArray = [];
    const newObj = {
      attribute,
      op,
      value,
    };
    newArray = [...filter, newObj];
    return newArray;
  };
  const getFilterArrayForRegionBranch = (
    attribute: FilterAttributes,
    op: FilterOP,
    values: string[],
    filter: LoanFilterProps[]
  ) => {
    let newArray = [];
    const newObj = {
      attribute,
      op,
      values,
    };
    newArray = [...filter, newObj];
    return newArray;
  };

  const getBodyRequest = (
    request: LoanListRequest | GetExportAllocationMetaDataRequest
  ) => {
    if (selectedTab !== 0) {
      if (selectedTab == 1 || selectedTab == 2) {
        request.allocationStatus = getTitleFromTab(selectedTab);
      } else if (selectedTab == 7 || selectedTab == 8) {
        request.collectionStatus = getTitleFromTab(selectedTab);
      } else if (selectedTab == 9) {
        request.accountStatus = 'LOAN CANCELLED';
      } else {
        request.loanStatus = getTitleFromTab(selectedTab);
      }
    }
    if (searchLoanId.length) {
      if (searchBy === SearchBy.LAN) {
        request.searchPartialLoanId = searchLoanId.toUpperCase();
      } else if (searchBy === SearchBy.CUSTOMER_NAME) {
        request.customerName = searchLoanId;
      } else {
        request.agentName = searchLoanId;
      }
    }
    if (Object.keys(filterForm).length) {
      if (filterForm.pos) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        if (filterForm.pos === POS.Greater_Than) {
          request.filters = getFilterArray(
            FilterAttributes.POS,
            FilterOP.GTE,
            filterForm.posValue,
            preFilterValue
          );
        } else if (filterForm.pos === POS.Less_Than) {
          request.filters = getFilterArray(
            FilterAttributes.POS,
            FilterOP.LTE,
            filterForm.posValue,
            preFilterValue
          );
        } else if (filterForm.pos === POS.Range) {
          const greterThanArr = getFilterArray(
            FilterAttributes.POS,
            FilterOP.GTE,
            filterForm.posMinValue,
            preFilterValue
          );
          const lessThanArr = getFilterArray(
            FilterAttributes.POS,
            FilterOP.LTE,
            filterForm.posMaxValue,
            preFilterValue
          );
          request.filters = [...greterThanArr, ...lessThanArr];
        } else {
          request.filters = getFilterArray(
            FilterAttributes.POS,
            FilterOP.EQ,
            filterForm.posValue,
            preFilterValue
          );
        }
      }

      if (filterForm.bucket) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        const nonEmptyFilterValues = getFilterArrayForRegionBranch(
          FilterAttributes.BUCKET,
          FilterOP.IN,
          filterForm.bucket,
          preFilterValue
        ).filter(item => item.values.length > 0);

        request.filters = nonEmptyFilterValues;
      }

      if (filterForm.cycle) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        const nonEmptyFilterValues = getFilterArrayForRegionBranch(
          FilterAttributes.CYCLE,
          FilterOP.IN,
          filterForm.cycle,
          preFilterValue
        ).filter(item => item.values.length > 0);
        request.filters = nonEmptyFilterValues;
      }
      if (filterForm.branch) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        const nonEmptyFilterValues = getFilterArrayForRegionBranch(
          FilterAttributes.BRANCH,
          FilterOP.IN,
          filterForm.branch,
          preFilterValue
        ).filter(item => item.values.length > 0);
        request.filters = nonEmptyFilterValues;
      }
      if (filterForm.loanStatus) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        const nonEmptyFilterValues = getFilterArrayForRegionBranch(
          FilterAttributes.LOAN_STATUS,
          FilterOP.IN,
          filterForm.loanStatus,
          preFilterValue
        ).filter(item => item.values.length > 0);
        request.filters = nonEmptyFilterValues;
      }
      if (filterForm.region) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        const nonEmptyFilterValues = getFilterArrayForRegionBranch(
          FilterAttributes.REGION,
          FilterOP.IN,
          filterForm.region,
          preFilterValue
        ).filter(item => item.values.length > 0);
        request.filters = nonEmptyFilterValues;
      }
      if (filterForm.segment) {
        const preFilterValue = request.filters ? [...request.filters] : [];
        request.filters = getFilterArray(
          FilterAttributes.SEGMENT,
          FilterOP.EQ,
          filterForm.segment,
          preFilterValue
        );
      }
      if (filterForm.allocationType) {
        request.allocationTypes = AllocationTypes[filterForm.allocationType];
      }
    }
  };

  async function refresh() {
    try {
      setSelectedRows([]);
      // if (selectedTab != 0 && selectedTab != 1 && selectedTab != 2 && selectedTab != 3) return;

      const request = {
        page: 0,
        size: 10,
        includeCustomerDetails: true,
        includeMetaDataLoanAllocation: true,
        includeLoanOutstandingDetails: true,
        restrictedView: true,
      } as LoanListRequest;
      getBodyRequest(request);
      getCount();
      dispatch(getAccountList(request));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function _reallocateLoanAccounts(
    formData: Partial<ReallocateAllocationFormData>
  ) {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    try {
      await AllocationService.reallocate({
        loanIds: loanIds,
        agentId: formData.agentId,
        expiresAt: formData.expiryDate,
        allocationType: formData.allocationType,
      });
      setSnackbar('Loan Accounts Re-Allocated.');
      refresh();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  async function _allocateLoanAccounts(
    formData: Partial<AllocateLoanAccountsFormData>
  ) {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    const allocationType = formData.allocationType;
    if (!allocationType) {
      setSnackbar('Allocation Type not specified', 'error');
      return;
    }

    try {
      if (allocationType == AllocationTypes.FIELD) {
        await AllocationService.allocateField({
          loanIds: loanIds,
          agentId: formData.agentId,
          expiresAt: formData.expiryDate,
          allocationType: formData.allocationType,
        });
        setSnackbar('Loan Accounts Allocated.');
        refresh();
        return;
      } else if (allocationType == AllocationTypes.TELE) {
        await AllocationService.allocateTele({
          loanIds: loanIds,
          agentId: formData.agentId,
          expiresAt: formData.expiryDate,
          allocationType: formData.allocationType,
        });
        setSnackbar('Loan Accounts Allocated.');
        refresh();
        return;
      }
      setSnackbar('Something went wrong', 'error');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  async function _deAllocateLoanAccounts() {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    try {
      await AllocationService.deallocate({
        loanIds: loanIds,
      });
      refresh();
      setSnackbar('Loan Accounts De-Allocated.');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  async function _executeLoanAccounts(
    formData: Partial<ExecuteLoanAccountsFormData>
  ) {
    const loanIds = selectedRows ?? [];
    try {
      await AllocationService.executeCriteria({
        lanId: loanIds,
        agentId: formData.agentId,
        allocationTypes: formData.allocationType,
        criteriaId: formData.ruleId,
      });
      setSnackbar('Rule Executed On Loan Accounts.');
      refresh();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  const canEnableReallocateButton = () => {
    return selectedTab == 1 && selectedRows.length > 0;
  };

  const canEnableDeallocateButton = () => {
    return selectedTab == 1 && selectedRows.length > 0;
  };

  const canEnableAllocateButton = () => {
    return selectedTab == 2 && selectedRows.length > 0;
  };

  function onReallocateClick() {
    openReAllocationDrawer();
  }

  function onDeallocateClick() {
    openDeAllocationDrawer();
  }

  function onExecuteClick() {
    openExecutionDrawer();
  }

  function onAllocateClick() {
    openAllocateDrawer();
  }

  const getCount = async () => {
    setCountLoading(true);
    const request = {
      restrictedView: true,
    } as GetExportAllocationMetaDataRequest;
    getBodyRequest(request);
    try {
      const _count = await LoanService.getLoanListCount(request);

      setCount(_count);
      setCountLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setCountLoading(false);
    }
  };

  async function fetchData() {
    try {
      setSelectedRows([]);

      const request = {
        // allocationStatus: getTitleFromTab(selectedTab),
        page,
        size,
        includeCustomerDetails: true,
        includeMetaDataLoanAllocation: true,
        includeLoanOutstandingDetails: true,
        restrictedView: true,
      } as LoanListRequest;
      getBodyRequest(request);
      dispatch(getAccountList(request));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const getExportFile = async (requestId: string) => {
    try {
      const response = await AllocationService.exportByRequestId(requestId);
      if (response.isComplete) {
        setExporting(false);
        localStorage.removeItem('requestId');
        setRequestId('');
        const url = response.fileUrl;
        if (url) {
          window.open(url, '__blank__');
        }
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const exportMetaData = async (
    request: GetExportAllocationMetaDataRequest
  ) => {
    const response = await AllocationService.exportAllocationMetaData(request);

    if (response.isComplete) {
      setExporting(false);
      localStorage.removeItem('requestId');
      setRequestId('');
      const url = response.fileUrl;
      if (url) {
        window.open(url, '__blank__');
      }
    } else {
      localStorage.setItem('requestId', response.requestId);
      setRequestId(response.requestId);
      setExporting(true);
      getExportFile(response.requestId);
    }
  };

  async function exportLoansMetaData() {
    try {
      setExporting(true);
      const request = {
        restrictedView: true,
      } as GetExportAllocationMetaDataRequest;
      if (selectedRows.length) {
        request.loanIds = selectedRows;
      }

      getBodyRequest(request);
      try {
        await exportMetaData(request);
      } catch (error) {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setExporting(false);
    }
  }

  const onClear = async () => {
    const body = {
      includeLmsVariables: true,
      includeCustomerDetails: true,
      restrictedView: true,
      includeLoanOutstandingDetails: true,
      page: 0,
      size: 10,
    } as LoanListRequest;
    if (selectedTab !== 0) {
      if (selectedTab == 1 || selectedTab == 2) {
        body.allocationStatus = getTitleFromTab(selectedTab);
      } else if (selectedTab == 7 || selectedTab == 8) {
        body.collectionStatus = getTitleFromTab(selectedTab);
      } else if (selectedTab == 9) {
        body.accountStatus = 'LOAN CANCELLED';
      } else {
        body.loanStatus = getTitleFromTab(selectedTab);
      }
    }
    if (searchLoanId.length) {
      if (searchBy === SearchBy.LAN) {
        body.searchPartialLoanId = searchLoanId.toUpperCase();
      } else {
        body.customerName = searchLoanId;
      }
    }
    dispatch(getAccountList(body));
    dispatch(setFilterForm({}));
    setCountLoading(true);
    const request = {
      restrictedView: true,
    } as GetExportAllocationMetaDataRequest;
    try {
      const _count = await LoanService.getLoanListCount(request);

      setCount(_count);
      setCountLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setCountLoading(false);
    }
    closeFilterDrawer();
  };
  const onApply = () => {
    try {
      const request = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        restrictedView: true,
        includeLoanOutstandingDetails: true,
        page: 0,
        size: 10,
        filters: [],
      } as LoanListRequest;
      getBodyRequest(request);
      dispatch(getAccountList(request));
      getCount();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
    closeFilterDrawer();
  };

  useEffect(() => {
    if (searchLoanId.length) {
      const getCountList = setTimeout(getCount, 300);
      return () => clearTimeout(getCountList);
    } else {
      getCount();
    }
  }, [searchLoanId]);

  useEffect(() => {
    if (searchLoanId.length) {
      const getLoanData = setTimeout(fetchData, 300);
      return () => clearTimeout(getLoanData);
    } else {
      fetchData();
    }
  }, [page, size, selectedTab, searchLoanId]);

  useEffect(() => {
    if (requestId.length) {
      const exportingFile = setInterval(() => {
        getExportFile(requestId);
      }, 7500);
      return () => clearInterval(exportingFile);
    }
  }, [requestId]);
  const key = '_' + UiModule.ACCOUNT + '_' + getTitleFromTab(selectedTab);
  const updateFilters = (options: string[]) => {
    dispatch(
      setColumnFilters({
        key,
        data: [...options],
      })
    );
    setSelectedOptions(options);
  };
  useEffect(() => {
    if (localStorage.getItem(key)) {
      setSelectedOptions(JSON.parse(localStorage.getItem(key)));
    }
  }, [selectedTab]);

  useEffect(() => {
    return () => {
      onClear();
    };
  }, []);

  return (
    <>
      <VegaPageHeader
        title="Loan Account"
        sx={{ borderBottom: 'none' }}
        renderRightView={() => {
          if (selectedTab == 1) {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text={'Execute'}
                  onClick={onExecuteClick}
                  variant="outlined"
                />
                <VegaButton
                  text={'Deallocate'}
                  onClick={onDeallocateClick}
                  variant="outlined"
                  disabled={canEnableDeallocateButton() == false}
                />
                <VegaButton
                  text={'Reallocate'}
                  onClick={onReallocateClick}
                  disabled={canEnableReallocateButton() == false}
                />
                <VegaButton
                  text={exporting || requestId ? 'Exporting...' : 'Export'}
                  onClick={exportLoansMetaData}
                  loading={exporting || !!requestId}
                />
              </Stack>
            );
          } else if (selectedTab == 2) {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text={'Execute'}
                  onClick={onExecuteClick}
                  variant="outlined"
                />
                <VegaButton
                  text={'Allocate'}
                  onClick={onAllocateClick}
                  disabled={canEnableAllocateButton() == false}
                />
                <VegaButton
                  text={exporting || requestId ? 'Exporting...' : 'Export'}
                  onClick={exportLoansMetaData}
                  loading={exporting || !!requestId}
                />
              </Stack>
            );
          }
          return (
            <VegaButton
              text={exporting || requestId ? 'Exporting...' : 'Export'}
              onClick={exportLoansMetaData}
              loading={exporting || !!requestId}
            />
          );
        }}
      />
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedTab}
              variant="scrollable"
              onChange={(e, selected) => {
                _onTabChange(selected);
              }}
            >
              {Object.keys(AllocationTabType2).map(tab => (
                <VegaTabBarItemWithNotification
                  key={tab}
                  label={toLowerCase(tab)}
                  count={count[tab]}
                  loading={countLoading}
                />
              ))}
            </VegaTabBar>
          );
        }}
      />
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '1rem', bgcolor: 'transparent' }}
        title={toLowerCase(getTitleFromTab(selectedTab))}
        renderRightView={() => {
          return (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <VegaColumnFilterSection
                columns={LoanAccountColumns}
                selectedOptions={selectedOptions}
                setSelectedOptions={updateFilters}
              />
              <Stack
                direction={'row'}
                gap={1}
                sx={{
                  height: '35px',
                }}
              >
                <LmFilterSection
                  onFilter={() => {
                    openFilterDrawer();
                  }}
                  search={searchLoanId}
                  setSearch={e => {
                    setSearchLoanId(e);
                    setPage(0);
                  }}
                  serachByComponent={
                    <Box
                      sx={{
                        width: 150,
                      }}
                    >
                      <VegaSelect
                        options={getSearchByOptions()}
                        value={searchBy}
                        onChange={selected => {
                          const value = selected.target.value as string;
                          setSearchBy(value);
                          setSearchLoanId('');
                          setPage(0);
                        }}
                      />
                    </Box>
                  }
                />
              </Stack>
            </Stack>
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          page={page}
          pageSize={size}
          checkboxSelection
          rowCount={totalItems}
          onPageChange={page => {
            setPage(page);
          }}
          onPageSizeChange={size => setSize(size)}
          paginationMode="server"
          data={accounts}
          columns={LoanAccountColumn(selectedOptions)}
          loading={loading}
          idColumn="loanId"
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>

      <AllocateLoanAccountsDrawer
        open={isAllocateDrawerOpen}
        onClose={function (): void {
          closeAllocateDrawer();
        }}
        onSubmit={function (
          formData: Partial<AllocateLoanAccountsFormData>
        ): void {
          _allocateLoanAccounts(formData);
        }}
      />

      <DeallocateLoanAccountsDialog
        open={isDeAllocatioDrawerOpen}
        onClose={function (): void {
          closeDeAllocatioDrawer();
        }}
        onSubmit={function (): void {
          _deAllocateLoanAccounts();
        }}
      />
      <ExecuteLoanAccountsDrawer
        open={isExecutionDrawerOpen}
        onClose={function (): void {
          closeExecutionDrawer();
        }}
        onSubmit={function (
          formData: Partial<ExecuteLoanAccountsFormData>
        ): void {
          _executeLoanAccounts(formData);
        }}
      />
      <ReallocateLoanAccountsDrawer
        open={isReAllocationDrawerOpen}
        onClose={function (): void {
          closeReAllocationDrawer();
        }}
        onSubmit={function (
          formData: Partial<ReallocateLoanAccountsFormData>
        ): void {
          _reallocateLoanAccounts(formData);
        }}
      />
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={() => {
          closeFilterDrawer();
        }}
      >
        <LoanAccountsFilterForm
          onApply={onApply}
          onClear={onClear}
          selectedTab={selectedTab}
        />
      </VegaDrawer>
    </>
  );
}

export default LoanAccounts;

const getTitleFromTab = (tab: number) => {
  const value = Object.values(AllocationTabType2)[tab];
  return value;
};
