import { Grid } from '@mui/material';
import { useState } from 'react';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';

type Props = {
  open: boolean;
  onClose: () => void;
  otsId?: string;
  onSubmit: (otsId: string, data: Partial<UpdateOtsTenureFormData>) => void;
};

enum FormField {
  Tenure = 'tenure',
}

export type UpdateOtsTenureFormData = {
  tenure: number;
};

function UpdateOtsTenureDrawer({ otsId, open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<Partial<UpdateOtsTenureFormData>>(
    {}
  );

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function onSubmitClick() {
    if (!otsId) return;
    onSubmit(otsId, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
  }

  const parseNumberValue = (value: any) => {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    return isValidNumber ? parsedNumber : undefined;
  };

  const isInputValid = () => {
    return (formData.tenure ?? 0) > 0;
  };

  return (
    <VegaDrawer open={open} title={'Upload Tenure'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Submit'}
              disabled={isInputValid() == false}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Tenure'}>
              <LmTextField
                placeholder="Enter updated tenure"
                value={formData.tenure}
                onChange={e =>
                  updateFormData(
                    FormField.Tenure,
                    parseNumberValue(e.target.value)
                  )
                }
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default UpdateOtsTenureDrawer;
