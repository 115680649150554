import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { VegaSelectOption } from '../../../../components/common/VegaSelect';
import VegaSelectWithCheckbox from '../../../../components/common/VegaSelectWithCheckbox';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ConfigurationDto } from '../../../../types/configurationType';
import { BucketType } from '../../../../types/minVisitsType';

const BucketSelect = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const handleBucketSelect = (branch: string) => {
    const preConfigSubtype = [...configurations.subtypes];
    if (preConfigSubtype.includes(branch)) {
      const filterBranch = preConfigSubtype.filter(
        (selectedUser: string) => selectedUser !== branch
      );
      handleConfigChange('subtypes', filterBranch);
    } else {
      handleConfigChange('subtypes', [...preConfigSubtype, branch]);
    }
  };
  const handleBucketDelete = (index: number) => {
    const preConfigSubtype = [...configurations.subtypes];
    preConfigSubtype.splice(index, 1);
    handleConfigChange('subtypes', preConfigSubtype);
  };
  return (
    <>
      <VegaFormInputField label={'select Bucket'}>
        <VegaSelectWithCheckbox
          options={getBuckets()}
          onSelect={selected => handleBucketSelect(selected)}
          handleDelete={handleBucketDelete}
          selected={configurations.subtypes}
        />
      </VegaFormInputField>
    </>
  );
};

export default BucketSelect;

const getBuckets = () => {
  const options = Object.values(BucketType).map(
    (value: string) =>
      ({
        label: value,
        value,
      } as VegaSelectOption)
  );
  return options;
};
