import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AmountType, ConditionTypeForPenalty } from '../../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  PanaltyCondition,
  PanaltyPolicyRangeProps,
} from '../../../types/request/incentives';
import { DeleteIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';
import { conditionObj } from './PenaltyIncentiveComponent';
import { PenaltyIncentiveAttributeOperationType } from '../../../types/allocations';
import _ from 'lodash';

interface IBumperProps {
  penaltyIncentive: PanaltyPolicyRangeProps;
  index: number;
  onDelete: (index: number) => void;
}

const AddPenalty = ({ penaltyIncentive, index, onDelete }: IBumperProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId, edit } = useParams();

  const handlePenaltyIncentiveChange = (
    key: keyof PanaltyPolicyRangeProps,
    value: any
  ) => {
    const penaltyBumperIncentivePolicy = [...policyRuleState.penaltyRange];
    const penaltyIncentiveObj = {
      ...penaltyIncentive,
      [key]: value,
    };
    penaltyBumperIncentivePolicy.splice(index, 1, penaltyIncentiveObj);
    const basePoliciyRule = {
      ...policyRuleState,
      penaltyRange: penaltyBumperIncentivePolicy,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(basePoliciyRule));
  };

  const handleConditionChange = (
    key: keyof PanaltyCondition,
    value: string | number,
    index: number
  ) => {
    const conditions = [...penaltyIncentive.condition];
    const existingCodition = { ...penaltyIncentive.condition[index] };
    const updatedCondition = {
      ...existingCodition,
      [key]: value,
    };
    conditions.splice(index, 1, updatedCondition);
    handlePenaltyIncentiveChange('condition', conditions);
  };

  const addCondition = () => {
    const updateCondition = [...penaltyIncentive.condition, conditionObj];
    handlePenaltyIncentiveChange('condition', updateCondition);
  };
  const onDeleteCondition = (index: number) => {
    const existingConditions = [...penaltyIncentive.condition];
    existingConditions.splice(index, 1);
    handlePenaltyIncentiveChange('condition', existingConditions);
  };
  return (
    <Stack
      sx={{
        borderRadius: '12px',
        p: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        mb: 1,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
        {edit && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Stack mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <VegaFormInputField label={'amount type'}>
              <VegaSelect
                options={amountTypeOption()}
                value={penaltyIncentive.amountType}
                onChange={e => {
                  const value = e.target.value as string;
                  handlePenaltyIncentiveChange('amountType', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <VegaFormInputField label={'amount value'}>
              <LmTextField
                type="number"
                placeholder="Min"
                value={penaltyIncentive.amountValue}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handlePenaltyIncentiveChange('amountValue', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Stack>
      {penaltyIncentive.condition.map((condition: PanaltyCondition, index) => (
        <Stack
          key={index}
          sx={{
            borderRadius: '12px',
            p: 2,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            mb: 1,
          }}
        >
          <>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={1}
            >
              <VegaText
                text={`Condition ${index + 1}`}
                fontWeight={500}
                fontSize={13}
                color={'#202020'}
              />
              {!policyId && index !== 0 && (
                <IconButton onClick={() => onDeleteCondition(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
              {edit && index !== 0 && (
                <IconButton onClick={() => onDeleteCondition(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <VegaFormInputField label={'condition type'}>
                  <VegaSelect
                    options={conditionTypeOption()}
                    value={condition.conditionType}
                    onChange={e => {
                      const value = e.target.value as string;
                      handleConditionChange('conditionType', value, index);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <VegaFormInputField label={'value'}>
                  <LmTextField
                    type="number"
                    placeholder="Value"
                    value={condition.value}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleConditionChange('value', value, index);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <VegaFormInputField label={'op'}>
                  <VegaSelect
                    options={opOption()}
                    value={condition.op}
                    onChange={e => {
                      const value = e.target.value as string;
                      handleConditionChange('op', value, index);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'start'}>
                  <VegaButton text="Add condition" onClick={addCondition} />
                </Stack>
              </Grid>
            </Grid>
          </>
        </Stack>
      ))}
    </Stack>
  );
};

export default AddPenalty;

const conditionTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(ConditionTypeForPenalty).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const amountTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(AmountType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const opOption = () => {
  const options: VegaSelectOption[] = Object.keys(
    PenaltyIncentiveAttributeOperationType
  ).map((item: string) => ({
    value: PenaltyIncentiveAttributeOperationType[item],
    label: _.startCase(_.toLower(item)),
  }));
  return options;
};
