import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import LmTextField from '../../../components/common/LmTextField';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import {
  receiptState,
  setErrorState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  CashPayment,
  CashPaymentMeta,
  ChequePayment,
  DDPayment,
  ElectronicPayment,
  IssueReceiptProps,
  PaymentMode,
  ReceiptAmountBreakUpDto,
  Relationship,
} from '../../../types/receiptManagment';
import { totalAmountDto } from '../ReceiptValidation';

const cashPayment: CashPaymentMeta[] = [
  {
    denomination: 500,
    count: 0,
  },
  {
    denomination: 200,
    count: 0,
  },
  {
    denomination: 100,
    count: 0,
  },
  {
    denomination: 50,
    count: 0,
  },
  {
    denomination: 20,
    count: 0,
  },
  {
    denomination: 10,
    count: 0,
  },
  {
    denomination: 1,
    count: 0,
  },
];

const PayerDetails = () => {
  const { issueReceipt, errorState, loanAccounts, loanAccountloading } =
    useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const getCustomerDetails = () => {
    if (loanAccounts?.length) {
      const customerData = loanAccounts[0];
      return {
        customerName: customerData.name ?? '',
        loanId: customerData.lan ?? '',
        mobileNo: customerData.contactNumber ?? '',
        email: customerData.email ?? '',
      };
    }
    return {
      customerName: '',
      loanId: '',
      mobileNo: '',
      email: '',
    };
  };

  const onPayerDetailsChange = (
    name: keyof IssueReceiptProps,
    value: string
  ) => {
    const payerDetailsObj = {
      ...issueReceipt,
      [name]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(payerDetailsObj));
  };
  const onReceiptAmountChange = (
    name: keyof ReceiptAmountBreakUpDto,
    value: number
  ) => {
    const receiptAmountObj = { ...issueReceipt.receiptAmountBreakUp };
    const receiptAmount = {
      ...receiptAmountObj,
      [name]: value,
    };
    const payerDetailsObj = {
      ...issueReceipt,
      receiptAmountBreakUp: receiptAmount,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(payerDetailsObj));
  };

  const onPaymentModeChange = (
    name: keyof IssueReceiptProps,
    value: string
  ) => {
    const errorData = {
      error: {
        mobileNumber: '',
        email: '',
        chequeNumber: '',
        ifscCode: '',
        bankName: '',
        branchName: '',
        ddNumber: '',
        refNumber: '',
      },
      isValid: false,
    };
    dispatch(setErrorState(errorData));
    if (value === PaymentMode.CASH) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          cashPaymentMeta: cashPayment,
        } as CashPayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
    if (value === PaymentMode.CHEQUE) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          chequeNumber: '',
          chequeDate: new Date().toISOString(),
          // ifscCode: '',
          micr: '',
          bankName: '',
          branchName: '',
        } as ChequePayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
    if (value === PaymentMode.DD) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          ddNumber: '',
          micr: '',
          ddDate: new Date().toISOString(),
          ifscCode: '',
          bankName: '',
          branchName: '',
        } as DDPayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
    if (
      value === PaymentMode.ELECTRONIC_FUND_TRANSFER ||
      value === PaymentMode.NEFT
    ) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          refNumber: '',
          refDate: new Date().toISOString(),
        } as ElectronicPayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
  };
  const totalAmount = totalAmountDto(issueReceipt.receiptAmountBreakUp);

  useEffect(() => {
    dispatch(
      setIssueReceipt({
        agentId: '',
        loanId: getCustomerDetails().loanId,
        mobileNumber: getCustomerDetails().mobileNo,
        email: getCustomerDetails().email,
        paymentType: '',
        documentImage: null,
        payeeName: getCustomerDetails().customerName,
        relationshipWithCustomer: Relationship.SELF,
        totalAmount: 0,
        paymentMeta: {},
        receiptAmountBreakUp: {
          overDueAmount: 0,
          bounceCharges: 0,
          penalCharges: 0,
          legalCharges: 0,
          otherCharges: 0,
        },
        panCard: '',
        remarks: '',
      })
    );
  }, [loanAccounts]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="payer name" isMandatory>
          <LmTextField
            value={issueReceipt.payeeName}
            onChange={e => onPayerDetailsChange('payeeName', e.target.value)}
            loading={loanAccountloading}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Mobile number" isMandatory>
          <LmTextField
            type="number"
            value={issueReceipt.mobileNumber}
            onChange={e => onPayerDetailsChange('mobileNumber', e.target.value)}
            error={errorState.error.mobileNumber?.length > 0}
            helperText={errorState.error.mobileNumber}
            loading={loanAccountloading}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Email">
          <LmTextField
            value={issueReceipt.email}
            onChange={e => onPayerDetailsChange('email', e.target.value)}
            // error={errorState.error.email?.length > 0}
            // helperText={errorState.error.email}
            loading={loanAccountloading}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Relationship with customer" isMandatory>
          <VegaSelect
            options={reletionshipOption()}
            value={issueReceipt.relationshipWithCustomer}
            onChange={e =>
              onPayerDetailsChange('relationshipWithCustomer', e.target.value)
            }
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Pan Number">
          <LmTextField
            value={issueReceipt.panCard}
            onChange={e => onPayerDetailsChange('panCard', e.target.value)}
            loading={loanAccountloading}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Mode of payment" isMandatory>
          <VegaSelect
            options={paymentModeOption()}
            value={issueReceipt.paymentMeta?.paymentMode}
            onChange={e => onPaymentModeChange('paymentMeta', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
        <VegaFormInputField label="overDue Amount" isMandatory>
          <LmTextField
            type="number"
            value={issueReceipt.receiptAmountBreakUp?.overDueAmount}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              onReceiptAmountChange('overDueAmount', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
        <VegaFormInputField label="bounce Charges" isMandatory>
          <LmTextField
            type="number"
            value={issueReceipt.receiptAmountBreakUp?.bounceCharges}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              onReceiptAmountChange('bounceCharges', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
        <VegaFormInputField label="penal Charges" isMandatory>
          <LmTextField
            type="number"
            value={issueReceipt.receiptAmountBreakUp?.penalCharges}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              onReceiptAmountChange('penalCharges', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
        <VegaFormInputField label="legal Charges" isMandatory>
          <LmTextField
            type="number"
            value={issueReceipt.receiptAmountBreakUp?.legalCharges}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              onReceiptAmountChange('legalCharges', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
        <VegaFormInputField label="other Charges" isMandatory>
          <LmTextField
            type="number"
            value={issueReceipt.receiptAmountBreakUp?.otherCharges}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              onReceiptAmountChange('otherCharges', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <VegaFormInputField label="total amount" isMandatory>
          <LmTextField type="number" disabled value={totalAmount} />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <VegaFormInputField label="remark">
          <LmTextField
            value={issueReceipt.remarks}
            onChange={e => onPayerDetailsChange('remarks', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default PayerDetails;

const reletionshipOption = () =>
  Object.keys(Relationship).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const paymentModeOption = () =>
  Object.keys(PaymentMode)
    .filter(item => item !== PaymentMode.CASH_IN_HAND)
    .map(item => {
      const option: VegaSelectOption = {
        value: item,
        label: _.startCase(_.toLower(item)),
      };
      return option;
    });
