import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ConveyanceRate } from '../types/claim';

export interface ExpenseClaimSliceProps {
  expenseConveyanceRate: ConveyanceRate | null;
}

const initialState: ExpenseClaimSliceProps = {
  expenseConveyanceRate: null,
};

const expenseClaimSlice = createSlice({
  name: 'expenseClaim',
  initialState,
  reducers: {
    setExpenseConveyanceRate: (state: ExpenseClaimSliceProps, action) => {
      const { payload } = action;
      state.expenseConveyanceRate = payload;
    },
  },
});
export const { setExpenseConveyanceRate } = expenseClaimSlice.actions;
export const getExpenseClaim = (state: RootState) => state.expenseClaim;
export default expenseClaimSlice;
