import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import VegaText from '../../../../../components/common/VegaText';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import {
  AllEnums,
  AnalyticsQueryTypeEnum,
  BucketLoanEnum,
  CelenderTypeEmun,
  ResolutionEnum,
  TimePeriodEnumForPenal,
  TimePeriodEnumForPenalMonthly,
  TimePeriodEnumForPenalYearly,
} from '../../../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { AccountMovementBucketResultDto } from '../../../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { DateUtility } from '../../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import BarChart from '../../../components/BarChart';
import { getMonthIndex } from '../../../components/RoleBaseSegmentPerformance';
import { toLowerCase } from '../../../../../constants/commonFunction';

const AccountMovmentBucketPerformanceGraph = () => {
  const { calenderType, profitType } = useParams();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );
  const [loanStatus, setLoanStatus] = useState<string>(
    BucketLoanEnum.ROLL_BACK
  );

  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    AccountMovementBucketResultDto[]
  >([]);
  const [selectedResolution, setSelectedResolution] = useState<string>(
    ResolutionEnum.OVERALL
  );

  const [loader, setLoader] = useState<boolean>(false);
  const series = [
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'YTD'
          : selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'MTD'
          : 'Target',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            const details = value as AccountMovementBucketResultDto;
            if (selectedTimePeriod === TimePeriodEnumForPenal.MTD) {
              return details.mtdPercentage;
            } else {
              if (selectedTimePeriod === TimePeriodEnumForPenal.YTD) {
                return details.ytdPercentage;
              } else return details.percentage;
            }
          })
        : [],
    },
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'LYSD'
          : selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'LMSD'
          : 'Achieved',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            const details = value as AccountMovementBucketResultDto;
            if (selectedTimePeriod === TimePeriodEnumForPenal.MTD) {
              return details.lmsdPercentage;
            } else {
              if (selectedTimePeriod === TimePeriodEnumForPenal.YTD) {
                return details.lysdPercentage;
              } else return details.count;
            }
          })
        : [],
    },
  ];

  const regionCategories = analyticsQueryResponse?.length
    ? analyticsQueryResponse.map(value => value?.bucket)
    : [];

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoader(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);

    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.MTD:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date(FIRST_DAY)?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.ONE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date()?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.FIVE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(FIVE_DAY?.toISOString()),
          toDate: DateUtility.formatStringToYYYYMMDD(FIRST_DAY?.toISOString()),
        };
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4: {
        const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
        };
      }
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER: {
        const monthIndex = getMonthIndex(selectedTimePeriod);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
        };
      }
      default:
        return {};
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    return {
      ...timePeriodParams,
      ...(selectedResolution !== ResolutionEnum.OVERALL && {
        resolution: selectedResolution,
      }),
      type: profitType,
      loanStatus,
    };
  };

  const getPenalRegionCollectionQueries = () => {
    const params = getParams();

    const request = {
      analyticsQueryType:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? AnalyticsQueryTypeEnum.BUCKET_MONTH_TILL_DATE_ACCOUNT_MOVEMENT
          : selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? AnalyticsQueryTypeEnum.BUCKET_YEAR_TILL_DATE_ACCOUNT_MOVEMENT
          : AnalyticsQueryTypeEnum.BUCKET_ACCOUNT_MOVEMENT,
      params,
    } as AnalyticsQueryRequest;
    getQueriesForEnum(request);
  };

  const getTimePeriodOptions = () => {
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      const options = Object.keys(TimePeriodEnumForPenalMonthly).map(item => {
        return { value: item, label: toLowerCase(item) } as VegaSelectOption;
      });
      return options;
    }
    const options = Object.keys(TimePeriodEnumForPenalYearly).map(item => {
      const year = new Date().getFullYear();
      const label = `${toLowerCase(item)} (${year})`;
      return { value: item, label: label } as VegaSelectOption;
    });
    return options;
  };

  useEffect(() => {
    getPenalRegionCollectionQueries();
  }, [selectedTimePeriod, loanStatus, selectedResolution]);

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper
          title="Bucket Performance"
          rightComponent={
            <Stack direction={'row'} gap={1}>
              <Stack width={150}>
                <VegaSelect
                  options={getTimePeriodOptions()}
                  value={selectedTimePeriod}
                  onChange={select =>
                    setSelectedTimePeriod(select.target.value as string)
                  }
                />
              </Stack>
              <Stack width={150}>
                <VegaSelect
                  options={getLoanOptions()}
                  value={loanStatus}
                  onChange={select =>
                    setLoanStatus(select.target.value as string)
                  }
                />
              </Stack>
              <Stack width={150}>
                <VegaSelect
                  ignoreLodash
                  options={getViewOptions(ResolutionEnum)}
                  value={selectedResolution}
                  onChange={e =>
                    setSelectedResolution(e.target.value as string)
                  }
                />
              </Stack>
            </Stack>
          }
        >
          {loader ? (
            <LoadingPage height={400} />
          ) : (
            <Stack
              sx={{
                p: 3,
                bgcolor: 'white',
                borderRadius: '12px',
                border: BORDER_COLLECTION,
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -25,
                    height: 50,
                    width: '91%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                    }}
                  >
                    {analyticsQueryResponse.map((val, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ flex: '1', textAlign: 'center' }}>
                            {getPercentageValue(val)}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <BarChart
                  categories={regionCategories}
                  series={series}
                  yAxisTitle="Percentage"
                />
              </Box>
            </Stack>
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default AccountMovmentBucketPerformanceGraph;

const getLoanOptions = () => {
  const options = Object.keys(BucketLoanEnum).map(item => {
    return { value: item, label: item } as VegaSelectOption;
  });
  return options;
};
const getViewOptions = (type: AllEnums) => {
  const options = Object.keys(type).map(
    item =>
      ({
        value: type[item],
        label: toLowerCase(item),
      } as VegaSelectOption)
  );
  return options;
};

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
