import React from 'react';
import { Stack, Box, IconButton } from '@mui/material';
import VegaText from '../../../../components/common/VegaText';
import { COMMON, BORDER_COLLECTION } from '../../../../constants/style';
import { EditIcon } from '../../../../components/Icons/Icons';

interface IProps {
  text?: string;
  children: JSX.Element;
  showBorder?: boolean;
  editable?: boolean;
  onEditClick?: () => void;
}

const OtsDetailsWrapper = ({
  text,
  showBorder,
  children,
  editable = false,
  onEditClick,
}: IProps) => {
  return (
    <>
      <Stack gap={2}>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent={'space-between'}
        >
          {text && (
            <VegaText
              text={text}
              fontSize={'0.875rem'}
              fontWeight={500}
              color={COMMON.balck}
            />
          )}
          {editable && (
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          )}
        </Stack>
        <Box
          sx={{
            pb: '1rem',
            ...(showBorder && { borderBottom: BORDER_COLLECTION }),
          }}
        >
          {children}
        </Box>
      </Stack>
    </>
  );
};

export default OtsDetailsWrapper;
