import { Box, Stack } from '@mui/material';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  WriteOffPayoutPolicyType,
  getIncentivesGamificationsState,
  setSelectedWriteOffPayoutPolicyType,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import VegaPageContent from '../../common/VegaPageContent';
import VegaText from '../../common/VegaText';
import IncentiveProposedComponent from '../components/WriteOff/IncentiveProposedComponent';
import InterestChargesComponent from '../components/WriteOff/InterestChargesComponent';
import MaxPayoutComponent from '../components/WriteOff/MaxPayoutComponent';

const WriteOffComponent = () => {
  const { selectedWriteOffPayoutPolicyType } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();

  const onSelectPayoutPolicyType = (value: string) => {
    dispatch(setSelectedWriteOffPayoutPolicyType(value));
  };

  return (
    <VegaPageContent>
      <Stack
        sx={{
          borderRadius: '8px',
          border: BORDER_COLLECTION,
          bgcolor: 'white',
        }}
        direction={'row'}
      >
        <Stack
          sx={{
            maxWidth: '190px',
            borderRight: BORDER_COLLECTION,
          }}
        >
          {Object.keys(WriteOffPayoutPolicyType).map(
            (value: WriteOffPayoutPolicyType) => {
              return (
                <Box
                  key={value}
                  sx={{
                    p: 2,
                    borderBottom:
                      selectedWriteOffPayoutPolicyType ===
                      WriteOffPayoutPolicyType[value]
                        ? `1px solid #1047DC`
                        : BORDER_COLLECTION,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    onSelectPayoutPolicyType(WriteOffPayoutPolicyType[value])
                  }
                >
                  <VegaText
                    text={WriteOffPayoutPolicyType[value]}
                    fontWeight={500}
                    fontSize={12}
                    color={
                      selectedWriteOffPayoutPolicyType ===
                      WriteOffPayoutPolicyType[value]
                        ? '#1047DC'
                        : '#676B76'
                    }
                  />
                </Box>
              );
            }
          )}
        </Stack>
        <Stack sx={{ width: '100%' }}>
          {selectedWriteOffPayoutPolicyType ===
          WriteOffPayoutPolicyType.MAX_PAYOUT ? (
            <MaxPayoutComponent />
          ) : selectedWriteOffPayoutPolicyType ===
            WriteOffPayoutPolicyType.INTEREST_CHARGES ? (
            <InterestChargesComponent />
          ) : (
            <IncentiveProposedComponent />
          )}
        </Stack>
      </Stack>
    </VegaPageContent>
  );
};

export default WriteOffComponent;
