import { Stack, Grid } from '@mui/material';
import React from 'react';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import { LegalBaseResponse } from '../../../types/legal';

type Props = {
  item: Partial<LegalBaseResponse>;
  isLoading?: boolean;
};

function SarfesiMisDetails({ item, isLoading = false }: Props) {
  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            text="Hold / Live"
            value={item?.holdOrLive ?? '-'}
            loading={isLoading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="As On Date Remarks"
            value={item?.asOnDateRemarks ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Demand Notice Date"
            value={item?.demandNoticeDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Demand Notice Amount"
            value={item?.demandNoticeAmt ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Served / published"
            value={item?.servedOrPublished ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="DN Published date"
            value={item?.dnPublishedDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Newspaper Name (English)"
            value={item?.demandNoticeNewsPaperNameEnglish ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Newspaper Name (Vernacular)"
            value={item?.demandNoticeNewsPaperNameVernacular ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Possession Notice u/s 13(4)
            Pasting Date"
            value={item?.['possessionNoticeU/S13(4)pastingDate'] ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Possession Notice u/s 13(4)
            Publication Date"
            value={item?.['possessionNoticeU/S13(4)publicationDate'] ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Property Sold (Yes,No)"
            value={item?.propertySold ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Sale Month "
            value={item?.saleMonth ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Refused, Symbolic, Physical"
            value={item?.refusedSymbolicPhysical ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Date Of Filling Application "
            value={item?.['dateOfFillingApplicationU/S14'] ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="VENDOR NAME FOR FILLING"
            value={'-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="COURT NAME "
            value={item?.courtName ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="CASE NUMBER"
            value={item?.caseNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="CNR NUMBER"
            value={item?.cnrNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="NDOH"
            value={item?.ndoh ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="SEC 14 ORDER DATE"
            value={item?.sec14OrderDate ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="sec 14 order received date"
            value={item?.sec14OrderReceivedDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Vendor Name for Possession "
            value={item?.vendorNameforPossession ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Date Of Allocation"
            value={'-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Status Hold /Fresh "
            value={item?.statusHoldFresh ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Date/Month of Hold "
            value={item?.dateOrMonthOfHold ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Physical Possession Date "
            value={item?.physicalPossessionDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="With Order, Without Order "
            value={item?.withOrderOrWithoutOrder ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Publication Date"
            value={item?.publicationDate ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Purchaser Name "
            value={item?.purchaserName ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Sale CER. Date "
            value={item?.saleCERDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Valuation Date , Market"
            value={item?.valuationDateMarket ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="O.S AS ON Reserve Price Fixing"
            value={item?.oSAsOnReservePriceFixing ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Reserve Price"
            value={item?.reservePrice ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Sale Notice Pub  Date "
            value={item?.saleNoticePubDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="No of times"
            value={item?.noOfTimes ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Auction Date "
            value={item?.auctionDate ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Sale Amount"
            value={item?.saleAmount ?? '-'}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default SarfesiMisDetails;
