import React from 'react';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import VegaPageContent from '../../../components/common/VegaPageContent';

const ViewCallMetricsDetails = () => {
  const { metric } = useParams();
  return (
    <>
      <VegaPageHeader
        sx={{
          borderBottom: 'none',
        }}
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Tele Caling Metrics', link: -1 },
                { label: toLowerCase(metric) },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <VegaPageContent mt={'1rem'}>
        {/* <ListOfCallMetrics /> */}
      </VegaPageContent>
    </>
  );
};

export default ViewCallMetricsDetails;
