import { CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { TargetSevices } from '../../Apis/TargetSevices';
import NoResults from '../../components/common/NoResults';
import VegaButton from '../../components/common/VegaButton';
import VegaOutlineDatePicker from '../../components/common/VegaOutlineDatePicker';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import {
  getTargetList,
  getTargetState,
  setBuckets,
  setIsAddTarget,
  setOpenAddTargetDrawer,
  setOpenReRaiseTargetDialog,
  targetByBuckets,
} from '../../features/targetSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  TargetByBucketsProps,
  TargetListProps,
  TargetsStatus,
} from '../../types/targets';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import TargetBucketCard from './modules/TargetBucketCard';
import TargetForm from './modules/TargetForm';
import VegaDrawer from '../../components/common/VegaDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import ReRaiseTargetDialog from './modules/ReRaiseTargetDialog';

export interface IBucketInputsProps {
  name: string;
  value: number;
}

export interface IBucketsProps {
  bucket: string;
  bucketInput: IBucketInputsProps[];
}

const TODAY = new Date();

const TargetAgent = () => {
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const {
    targets,
    openAddTargetDrawer,
    isAddTarget,
    error,
    loading: loadingList,
  } = useAppSelector(getTargetState);
  const [dateValue, setDateValue] = useState<string>(
    DateUtility.formatStringToYYYYMMDD(new Date().toDateString())
  );
  const [targetId, setTargetId] = useState<string>('');
  const [status, setStatus] = useState<TargetsStatus>();
  const [hideButton, setHideButton] = useState<boolean>(false);
  const [initialBucketState, setInitialBucketState] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeDate = (e: any) => {
    const date = DateUtility.formatStringToYYYYMMDD(e);
    const toDay = DateUtility.formatStringToYYYYMMDD(TODAY.toISOString());
    if (toDay !== date) {
      setHideButton(true);
    } else {
      setHideButton(false);
    }
    setDateValue(date);
  };

  const onAddAndUpdateTarget = (
    buckets: TargetByBucketsProps[],
    modifyReason?: string
  ) => {
    dispatch(setOpenAddTargetDrawer(false));
    const newBuckets = buckets.map((bucket: TargetByBucketsProps) => ({
      bucket: bucket.bucket,
      posValue: bucket.posValue,
      noOfCases: bucket.noOfCases,
    }));
    setLoading(true);
    if (isAddTarget) {
      TargetSevices.createTarget({
        agentId: user.id,
        targetDate: dateValue,
        targetByBuckets: newBuckets,
      })
        .then(() => {
          setSnackbar('create target successfully!!');
          fetchTargetList();
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        })
        .finally(() => setLoading(false));
    } else {
      TargetSevices.updateTarget({
        targetId,
        targetDate: dateValue,
        targetByBuckets: newBuckets,
        modifyReason,
        status,
      })
        .then(() => {
          setSnackbar('update target successfully!!');
          fetchTargetList();
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        })
        .finally(() => setLoading(false));
    }
  };

  const modifyTarget = (buckets: TargetListProps, status: TargetsStatus) => {
    setTargetId(buckets.id);

    const modifyBuckets = targetByBuckets.map(
      (targetedBucket: TargetByBucketsProps) => {
        const dataItem = buckets.targetByBuckets.find(
          (dataItem: TargetByBucketsProps) =>
            dataItem.bucket === targetedBucket.bucket
        );
        return { ...targetedBucket, ...dataItem };
      }
    );
    dispatch(setBuckets(modifyBuckets));
    setInitialBucketState(modifyBuckets);
    setStatus(status);
    dispatch(setOpenAddTargetDrawer(true));
  };
  const fetchTargetList = () => {
    dispatch(
      getTargetList({
        agentId: user.id,
        targetDate: dateValue,
      })
    );
  };

  useEffect(() => {
    user && fetchTargetList();
  }, [dateValue, user]);

  useEffect(() => {
    if (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Targets'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} gap={1}>
              <VegaOutlineDatePicker
                onChange={handleChangeDate}
                value={dateValue}
                maxDate={TODAY}
              />
              {!hideButton && targets.length === 0 && (
                <VegaButton
                  text="Add Targets"
                  disabled={!!targets.length}
                  onClick={() => {
                    dispatch(setIsAddTarget(true));
                    dispatch(setOpenAddTargetDrawer(true));
                    dispatch(setBuckets(targetByBuckets));
                  }}
                />
              )}
            </Stack>
          );
        }}
      />

      {(loading == false || loadingList == false) && targets.length == 0 && (
        <NoResults sx={{ height: 'calc(100% - 142px)' }} />
      )}
      {(loading == false || loadingList == false) && targets.length > 0 && (
        <VegaPageContent sx={{ mt: '1rem' }}>
          <TargetBucketCard
            isBucketStatus
            bucketData={targets}
            renderButtons={(bucket: TargetListProps) => {
              return (
                <Stack
                  direction={'row'}
                  gap={2}
                  justifyContent={'end'}
                  mt={'1rem'}
                >
                  {/* {bucket.targetStatus === TargetsStatus.REJECTED && (
                    <VegaButton
                      text="Re raise"
                      variant="outlined"
                      onClick={() => {
                        modifyTarget(bucket, TargetsStatus.RE_RAISE);
                      }}
                    />
                  )} */}
                  {bucket.targetStatus === TargetsStatus.REJECTED && (
                    <VegaButton
                      text="Re Raise"
                      variant="outlined"
                      onClick={() => {
                        dispatch(setIsAddTarget(false));
                        modifyTarget(bucket, TargetsStatus.RE_RAISE);
                      }}
                    />
                  )}
                  {bucket.targetStatus === TargetsStatus.RAISED && (
                    <VegaButton
                      text="Modify Target"
                      variant="outlined"
                      onClick={() => {
                        dispatch(setIsAddTarget(false));
                        modifyTarget(bucket, TargetsStatus.RAISED);
                      }}
                    />
                  )}
                </Stack>
              );
            }}
          />
        </VegaPageContent>
      )}
      {(loading == true || loadingList == true) && (
        <VegaPageContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'white',
            borderRadius: '9px',
            m: 2,
            padding: 20,
          }}
        >
          <CircularProgress />
        </VegaPageContent>
      )}

      <VegaDrawer
        open={openAddTargetDrawer}
        title={isAddTarget ? 'Add Target' : 'Update Target'}
        onClose={() => dispatch(setOpenAddTargetDrawer(false))}
      >
        <TargetForm
          onAddAndUpdateTarget={onAddAndUpdateTarget}
          initialDate={dateValue}
          initialState={initialBucketState}
        />
      </VegaDrawer>
      <ReRaiseTargetDialog />
    </>
  );
};

export default TargetAgent;
