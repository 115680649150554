import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import DepositBankSelector from '../../../../components/BankDeposit/DepositBankSelector';
import LmDateField from '../../../../components/common/LmDateField';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { receiptState } from '../../../../features/receiptManagmentSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../../store';
import { DepositDto } from '../../../../types/receiptManagment';
import { DateUtility } from '../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
interface IProps {
  onFetchList?: () => void;
  open: boolean;
  onClose: () => void;
}
interface ISubmitDeposit {
  depositCentre: string;
  depositProof: VegaFileSelectOption[];
}
enum PaymentType {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  CASH = 'CASH',
}
const HistoryReceiptSubmitDrawer = ({ open, onClose, onFetchList }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { receiptDetailedData, loanAccounts } = useAppSelector(receiptState);
  const segment = loanAccounts[0]?.segment;
  const [depositState, setDepositState] = useState<ISubmitDeposit>({
    depositCentre: '',
    depositProof: [],
  });
  const [depositData, setDepositData] = useState<DepositDto | null>(null);
  const getDeposit = async depositId => {
    try {
      const response = await ReceiptManagmentService.getDepositByDepositId(
        depositId
      );
      setDepositData(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    setDepositState(prevState => {
      return {
        ...prevState,
        depositCentre: receiptDetailedData?.depositCentre ?? '',
        depositProof:
          depositData?.depositProofUrls.map(item => {
            const option: VegaFileSelectOption = {
              id: item,
              label: 'Deposit Proof',
            };
            return option;
          }) ?? [],
      };
    });
  }, [receiptDetailedData?.depositCentre, depositData?.depositProofUrls]);

  useEffect(() => {
    if (receiptDetailedData?.depositId) {
      getDeposit(receiptDetailedData?.depositId);
    }
  }, [receiptDetailedData?.depositId]);

  const handleDepositChange = (
    key: keyof ISubmitDeposit,
    value: string | VegaFileSelectOption[]
  ) => {
    setDepositState({ ...depositState, [key]: value });
  };

  const handleFileChange = (file: VegaFileSelectOption) => {
    const updateDeposit = [file];
    handleDepositChange('depositProof', updateDeposit);
  };

  const handleDeleteFile = () => {
    handleDepositChange('depositProof', []);
  };
  const onClear = () => {
    onClose();
    setDepositState({
      depositCentre: '',
      depositProof: [],
    });
  };
  const onSubmit = async () => {
    try {
      const data = await ReceiptManagmentService.updateStatus({
        depositId: receiptDetailedData?.depositId,
        depositCentre: depositState.depositCentre,
        depositProof: depositState.depositProof[0]?.value,
      });
      setSnackbar('Proof successfully submitted');
      onFetchList();
    } catch (err) {
      setSnackbar(getErrorMessageFromErrorObj(err), 'error');
    } finally {
      onClear();
    }
  };
  const disabled = () => {
    return (
      !depositState.depositCentre.length || !depositState.depositProof.length
    );
  };
  return (
    <VegaDrawer open={open} onClose={onClose} title="Submit Deposit Proof">
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Clear All"
                onClick={onClear}
                variant="text"
                sx={{
                  color: '#1047DC',
                }}
              />
              <VegaButton
                text="Submit Deposit"
                onClick={onSubmit}
                disabled={disabled()}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Customer Name'}>
              <LmTextField
                disabled
                type="text"
                value={receiptDetailedData?.payeeName}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Loan Account Number'}>
              <LmTextField
                type="text"
                disabled
                value={receiptDetailedData?.loanId}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Contact Number'}>
              <LmTextField
                type="text"
                disabled
                value={loanAccounts?.length && loanAccounts[0]?.contactNumber}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Allocation Date '}>
              <LmTextField
                disabled
                type="text"
                value={loanAccounts?.length && loanAccounts[0]?.allocationDate}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Receipt Number'}>
              <LmTextField
                type="text"
                value={receiptDetailedData?.receiptNumber}
                disabled
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Receipt Amount'}>
              <LmTextField
                type="text"
                value={receiptDetailedData?.totalAmount}
                disabled
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Receipt Date'}>
              <LmDateField
                disabled
                value={DateUtility.formatDateToDDMMYYYY(
                  new Date(receiptDetailedData?.receiptDate)
                )}
                onChange={() => {}}
              />
            </VegaFormInputField>
          </Grid>
          {receiptDetailedData.paymentMode !== PaymentType.PAYMENT_GATEWAY && (
            <Grid item xs={12}>
              <VegaFormInputField label={'deposit Center'} isMandatory>
                <DepositBankSelector
                  selected={depositState.depositCentre}
                  handleChange={selected =>
                    handleDepositChange('depositCentre', selected)
                  }
                  segment={segment}
                />
              </VegaFormInputField>
            </Grid>
          )}
          <Grid item xs={12}>
            <VegaFormInputField label={'Deposit Proof'}>
              <Stack rowGap={'0.75rem'}>
                {depositState.depositProof.length > 0 && (
                  <VegaFileSelect
                    value={depositState.depositProof[0]}
                    acceptedExtension={[
                      AcceptedExtension.JPG,
                      AcceptedExtension.JPEG,
                      AcceptedExtension.PNG,
                      AcceptedExtension.PDF,
                    ]}
                    downloadable
                    placeholder={'Upload Document '}
                    onFileSelect={file => {
                      handleFileChange(file);
                    }}
                    onDeleteClick={() => {
                      handleDeleteFile();
                    }}
                    onFileDownloadClick={file => {
                      const url = file.id ?? '';
                      if (url.length > 0) window.open(file.id, '__BLANK__');
                    }}
                  />
                )}
                {depositState.depositProof.length <= 0 && (
                  <VegaFileSelect
                    placeholder={'Upload Document '}
                    onFileSelect={file => {
                      handleFileChange(file);
                    }}
                  />
                )}
              </Stack>
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default HistoryReceiptSubmitDrawer;
