import { Stack } from '@mui/material';
import { EscalationsServices } from '../../../Apis/EscalationsServices';
import ListOfOts from '../../../components/Escalations/ListOfOts';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { EscalationTriggerType } from '../../../types/request/escalationsRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import VegaButton from '../../../components/common/VegaButton';

const Ots = () => {
  const { setSnackbar } = useSnackbar();

  const triggerEscalation = () => {
    EscalationsServices.triggerEscalation({
      triggerType: EscalationTriggerType.OTS,
    })
      .then(res => {})
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack gap={1} width={'100%'}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <VegaBreadCrumb
                  items={[
                    { label: 'Escalation', link: ROUTES.ESCALATIONS },
                    { label: `OTS` },
                  ]}
                />
                <VegaButton
                  text="Trigger Escalation"
                  onClick={triggerEscalation}
                />
              </Stack>
            </Stack>
          );
        }}
      />
      <ListOfOts />
    </>
  );
};

export default Ots;
