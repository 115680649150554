import { IErrorStateProps } from '../../features/receiptManagmentSlice';
import {
  AnyPaymentType,
  CashPayment,
  CashPaymentMeta,
  ChequePayment,
  DDPayment,
  ElectronicPayment,
  IssueReceiptProps,
  PaymentMode,
  ReceiptAmountBreakUpDto,
} from '../../types/receiptManagment';
import { StringUtility } from '../../utils/StringUtility';

export const isPaymentMeta = (paymentMeta: AnyPaymentType) => {
  if (!Object.keys(paymentMeta)?.length) {
    return true;
  }
  if (paymentMeta.paymentMode === PaymentMode.CHEQUE) {
    const checkPayment = paymentMeta as ChequePayment;
    return (
      !checkPayment.bankName?.length ||
      !checkPayment.branchName?.length ||
      !checkPayment.chequeNumber?.length ||
      !checkPayment.micr?.length
    );
  }
  if (paymentMeta.paymentMode === PaymentMode.DD) {
    const DDPayment = paymentMeta as DDPayment;
    return !DDPayment.ddNumber?.length;
  }
  if (
    paymentMeta.paymentMode === PaymentMode.NEFT ||
    paymentMeta.paymentMode === PaymentMode.ELECTRONIC_FUND_TRANSFER
  ) {
    const electronic = paymentMeta as ElectronicPayment;
    return !electronic.refNumber?.length;
  }
  if (paymentMeta.paymentMode === PaymentMode.CASH) {
    const cashPayment = paymentMeta as CashPayment;
    const isCheckCashPayment = cashPayment.cashPaymentMeta?.some(
      (cash: CashPaymentMeta) => cash.count > 0
    );
    return !isCheckCashPayment;
  }
};

export const isReceiptAmountBreakUpDto = (
  receiptAmountBreakUpDto: ReceiptAmountBreakUpDto
) => {
  const isCheckReceiptAmount = Object.values(receiptAmountBreakUpDto)?.some(
    (amount: number) => amount > 0
  );
  return !isCheckReceiptAmount;
};

export const disabledButton = (issueReceipt: IssueReceiptProps) => {
  const isReceiptAmountBreakUp = isReceiptAmountBreakUpDto(
    issueReceipt.receiptAmountBreakUp
  );
  const isPayment = isPaymentMeta(issueReceipt.paymentMeta);
  return (
    isReceiptAmountBreakUp ||
    isPayment ||
    !issueReceipt.payeeName?.length ||
    !issueReceipt.mobileNumber?.length ||
    !issueReceipt.relationshipWithCustomer?.length
  );
};

export const isValidInput = (issueReceipt: IssueReceiptProps) => {
  const errorObj = {
    error: {},
    isValid: true,
  } as IErrorStateProps;
  const isValidNumber = StringUtility.validatePhoneNumber(
    issueReceipt.mobileNumber
  );

  if (!isValidNumber) {
    errorObj.isValid = false;
    errorObj.error['mobileNumber'] = 'Mobile number should be 10 digits';
  }
  // if (issueReceipt.paymentMeta) {
  //   const paymentMeta = issueReceipt.paymentMeta as ChequePayment;

  //   if (
  //     paymentMeta.paymentMode === PaymentMode.CHEQUE ||
  //     paymentMeta.paymentMode === PaymentMode.DD
  //   ) {
  //     const isValidChequeNumber = StringUtility.validateChequeNumber(
  //       paymentMeta.chequeNumber
  //     );
  //     const isValidIFSCcode = StringUtility.validationIfscNumber(
  //       paymentMeta.ifscCode
  //     );
  //     const isValidBankName = paymentMeta.bankName?.length > 0;
  //     const isValidBranchName = paymentMeta.branchName?.length > 0;
  //     if (paymentMeta.paymentMode === PaymentMode.CHEQUE) {
  //       if (!isValidChequeNumber) {
  //         errorObj.isValid = false;
  //         errorObj.error['chequeNumber'] = 'Cheque number should be 15 digits';
  //       }
  //     }
  //     if (paymentMeta.paymentMode === PaymentMode.DD) {
  //       const ddPaymentMeta = issueReceipt.paymentMeta as DDPayment;

  //       const isDDNumber = ddPaymentMeta.ddNumber?.length > 0;
  //       if (!isDDNumber) {
  //         errorObj.isValid = false;
  //         errorObj.error['ddNumber'] = 'DD number is required';
  //       } else if (ddPaymentMeta.ddNumber?.length !== 6) {
  //         errorObj.isValid = false;
  //         errorObj.error['ddNumber'] = 'DD number should be 6 digits';
  //       }
  //     }
  //     if (!isValidIFSCcode) {
  //       errorObj.isValid = false;
  //       errorObj.error['ifscCode'] = 'IFSC code is required';
  //     } else if (paymentMeta.ifscCode?.length >= 15) {
  //       errorObj.isValid = false;
  //       errorObj.error['ifscCode'] = 'IFSC code should be 15 digits';
  //     }
  //     if (!isValidBankName) {
  //       errorObj.isValid = false;
  //       errorObj.error['bankName'] = 'Bank name is required';
  //     } else if (paymentMeta.bankName.length > 50) {
  //       errorObj.isValid = false;
  //       errorObj.error['bankName'] = 'Bank name is less then 50 characters';
  //     }
  //     if (!isValidBranchName) {
  //       errorObj.isValid = false;
  //       errorObj.error['branchName'] = 'Branch name is required';
  //     } else if (paymentMeta.branchName.length > 15) {
  //       errorObj.isValid = false;
  //       errorObj.error['branchName'] = 'Branch name is less then 15 characters';
  //     }
  //     if (!isValidIFSCcode) {
  //       errorObj.isValid = false;
  //       errorObj.error['ifscCode'] = 'IFSC code should be 15 digits';
  //     }
  //   }

  //   if (
  //     // paymentMeta.paymentMode === PaymentMode.ELECTRONIC_FUND_TRANSFER ||
  //     paymentMeta.paymentMode === PaymentMode.NEFT
  //   ) {
  //     const electronicPaymentMeta =
  //       issueReceipt.paymentMeta as ElectronicPayment;
  //     const isRefNumber = StringUtility.validationRefNumber(
  //       electronicPaymentMeta.refNumber
  //     );
  //     if (!isRefNumber) {
  //       errorObj.isValid = false;
  //       errorObj.error['refNumber'] =
  //         'Enter 16 digit reference number include uppercase alphabet and number';
  //     } else if (electronicPaymentMeta.refNumber.length <= 0) {
  //       errorObj.isValid = false;
  //       errorObj.error['refNumber'] =
  //         'Reference number is less then 15 characters';
  //     }
  //   }
  // }
  return errorObj;
};

export const totalAmountDto = (amountDto: ReceiptAmountBreakUpDto) => {
  const overDueAmount = amountDto.overDueAmount ? amountDto.overDueAmount : 0;
  const bounceCharges = amountDto.bounceCharges ? amountDto.bounceCharges : 0;
  const penalCharges = amountDto.penalCharges ? amountDto.penalCharges : 0;
  const legalCharges = amountDto.legalCharges ? amountDto.legalCharges : 0;
  const otherCharges = amountDto.otherCharges ? amountDto.otherCharges : 0;

  const amount =
    overDueAmount + bounceCharges + penalCharges + legalCharges + otherCharges;
  return amount;
};
