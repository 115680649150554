import axios from 'axios';

import {
  BnakCustomerRequest,
  ICreateCustomerBranchProps,
} from '../types/customerBank';

const CUSTOMER_BANK = {
  BANK: '/master/customer/branch/info',
  BANK_BY_ID: '/master/customer/branch/info/{customerBankId}',
  BANK_LIST: '/master/customer/branch/info/list',
  BANK_ALL_LIST: '/master/customer/branch/info/getAll',
  STATUS: '/status',
  EXPORT_RECEIPT: '/export',
};

/////////// Customer Bank APIs \\\\\\\\\\

export const fetchCustomerBanksList = async (
  request: Partial<BnakCustomerRequest>
) => {
  const response = await axios.get(CUSTOMER_BANK.BANK_LIST, {
    params: request,
  });
  return response.data;
};
export const fetchAllCustomerBanksList = async () => {
  const response = await axios.get(CUSTOMER_BANK.BANK_ALL_LIST);
  return response.data;
};

export const fetchCustomerBankById = async (customerBankId: string) => {
  const response = await axios.get(
    CUSTOMER_BANK.BANK_BY_ID.replace('{customerBankId}', customerBankId)
  );
  return response.data;
};

export const createCustomerBank = async (
  customerBranchState: ICreateCustomerBranchProps
) => {
  const response = await axios.post(CUSTOMER_BANK.BANK, customerBranchState);
  return response.data;
};
export const getCustomerBranchExportData = async request => {
  const endPoint = CUSTOMER_BANK.BANK + CUSTOMER_BANK.EXPORT_RECEIPT;
  const response = await axios.get(endPoint, { params: request });
  const { data } = response;
  return data;
};
export const updateCustomerBank = async (
  customerBranchState: ICreateCustomerBranchProps
) => {
  const response = await axios.put(CUSTOMER_BANK.BANK, customerBranchState);
  return response.data;
};
export const updateCustomerBankStatus = async (
  customerBranchState: ICreateCustomerBranchProps
) => {
  const endpoint = CUSTOMER_BANK.BANK + CUSTOMER_BANK.STATUS;
  const response = await axios.patch(endpoint, customerBranchState);
  return response.data;
};

export const deleteCustomerBank = async (customerBankId: string) => {
  const response = await axios.delete(
    CUSTOMER_BANK.BANK_BY_ID.replace('{customerBankId}', customerBankId)
  );
  return response.data;
};
