import { Stack, Box } from '@mui/material';
import { COMMON } from '../../../constants/style';
import VegaText from '../../common/VegaText';
import VegaTabBar from '../../common/VegaTabBar';
import VegaTabBarItem from '../../common/VegaTabItem';
import { useState } from 'react';
import _ from 'lodash';
import SarfesiMisDetails from './SarfesiMisDetails';
import SecDetails from './SecDetails';
import ArbitrationDetails from './ArbitrationDetails';
import { useSelector } from 'react-redux';
import { legalRequestSelector } from '../../../features/legalSlice';

enum TabType {
  SARFAESI_MIS = 'SARFAESI_MIS',
  Sec_138 = 'Sec_138',
  Arbitration = 'Arbitration',
}
const LegalActionDetails = () => {
  const { sarfaesiMisDeatils, arbitationDetails, secDetails } =
    useSelector(legalRequestSelector);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  function _onTabChange(value: number): void {
    setSelectedTab(value);
  }

  const getDetailsComponent = (selectedTab: number) => {
    switch (selectedTab) {
      case 0:
        return <SarfesiMisDetails item={sarfaesiMisDeatils} />;
      case 1:
        return <SecDetails item={arbitationDetails} />;
      case 2:
        return <ArbitrationDetails item={secDetails} />;
      default:
        return;
    }
  };

  return (
    <Stack gap={2}>
      <Stack columnGap={4} style={{ alignItems: 'start' }}>
        <VegaText
          text={'Details of Legal Action'}
          fontSize={'0.875rem'}
          fontWeight={500}
          color={COMMON.balck}
        />
        <VegaTabBar
          value={selectedTab}
          onChange={(e, selected) => {
            _onTabChange(selected);
          }}
          variant="standard"
          sx={{
            marginTop: 1,
            '& .MuiTabs-indicator': {
              height: 2,
              marginBottom: 1,
            },
            '& .MuiTab-root': {
              whiteSpace: 'nowrap',
            },
          }}
        >
          <VegaTabBarItem
            label={_.startCase(_.toLower(TabType.SARFAESI_MIS))}
          />
          <VegaTabBarItem label={_.startCase(_.toLower(TabType.Sec_138))} />
          <VegaTabBarItem label={_.startCase(_.toLower(TabType.Arbitration))} />
        </VegaTabBar>
      </Stack>
      <Box
        sx={{
          pb: '1rem',
        }}
      >
        {getDetailsComponent(selectedTab)}
      </Box>
    </Stack>
  );
};

export default LegalActionDetails;
