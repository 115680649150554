import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpenseService } from '../../Apis/ExpenseService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  ConveyanceRate,
  getDisplayTextForVehicleType,
} from '../../types/claim';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaKeyValueDisplay from '../common/VegaKeyValueDisplay';

type Props = { show: boolean; onCancelClick: () => void };
function CurrentConveyanceRateDialog({ show, onCancelClick }: Props) {
  const [rates, setRates] = useState<ConveyanceRate[]>();
  const [loading, setLoading] = useState<boolean>();
  const { setSnackbar } = useSnackbar();

  async function fetchCurrentRates() {
    try {
      setRates([]);
      setLoading(true);
      const response = await ExpenseService.getConveyanceRates({
        date: DateUtility.formatStringToYYYYMMDD(new Date().toISOString()),
      });
      setRates(response.records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onClose() {
    onCancelClick();
    cleanUp();
  }

  function cleanUp() {
    setRates([]);
  }

  useEffect(() => {
    if (show == true) {
      fetchCurrentRates();
    }
  }, [show]);

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={onClose}
      isCloseIcon
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Conveyance Rate"
            fontWeight={600}
            fontSize={'1.0625rem'}
          />
        </Stack>
      )}
      buttonComponent={function (): React.ReactNode {
        return <></>;
      }}
    >
      <VegaDialogContent>
        {loading && <LoadingView />}
        <Grid container rowGap={'2.5rem'}>
          {rates?.map((item, index) => {
            return (
              <Grid container rowGap={'1.5rem'} key={index}>
                <Grid item xs={12}>
                  <VegaText
                    text={getDisplayTextForVehicleType(item.vehicleType)}
                    style={{
                      color: '#222',
                      fontSize: '0.875rem',
                      fontWeight: 600,
                      lineHeight: '120%',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container rowGap={'0.75rem'}>
                    <Grid item xs={12}>
                      <VegaKeyValueDisplay
                        heading={'Per KM Rate'}
                        value={StringUtility.formatToINR(item.ratePerKm)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <VegaKeyValueDisplay
                        heading="Start Date"
                        value={DateUtility.formatStringToDDMMYYYY(
                          item.startDate
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <VegaKeyValueDisplay
                        heading="End Date"
                        value={DateUtility.formatStringToDDMMYYYY(item.endDate)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default CurrentConveyanceRateDialog;

const LoadingView = () => {
  return (
    <Grid container rowGap={'1.5rem'}>
      <Grid item xs={12}>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={12}>
            <VegaKeyValueDisplay heading={'Per KM Rate'} loading value={''} />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay heading="Start Date" loading value={''} />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay heading="End Date" loading value={''} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
