import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgendaService } from '../../../Apis/AgendaService';
import { OtsService } from '../../../Apis/OtsService';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { VegaUser } from '../../../types/claim';
import {
  AgendaCaseStatus,
  getOtsStatusDescription,
  getValueFromRecord,
  Ots,
  OtsMetaDataKey,
  OtsStatus,
} from '../../../types/ots';
import {
  AddAgendaCaseRequest,
  CreateAgendaRequest,
} from '../../../types/request/agenda';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../../utils/api';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import SkipOtsFromAgendaReasonDialog from '../pages/components/SkipOtsFromAgendaReasonDialog';
import CreateAgendaDrawer, { CreateAgendaFormData } from './CreateAgendaDrawer';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../constants/style';

const PENDING_STATUS: OtsStatus[] = [OtsStatus.PRE_AGENDA_CREATION];

type AddToAgendaFormData = {
  addToAgenda: Ots[];
  skipFromAgenda: Map<Ots, string>;
};

function AgendaCreatorOtsPage() {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<AddToAgendaFormData>>({
    addToAgenda: [],
    skipFromAgenda: new Map<Ots, string>(),
  });
  const [pendingOtsData, setPendingOtsData] = useState<PaginatedResponse<Ots>>({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });

  const {
    open: openReasonDialog,
    close: closeReasonDialog,
    isOpen: isReasonDialogOpen,
    props: reasonDialogProps,
  } = useDrawer();
  const {
    open: openCreateAgendaDrawer,
    close: closeCreateAgendaDrawer,
    isOpen: isCreateAgendaDrawerOpen,
  } = useDrawer();

  async function fetchData() {
    try {
      setLoading(true);
      const response = await OtsService.listOtsRequests({
        page: pendingOtsData.pageNumber,
        status: PENDING_STATUS.join(','),
        includeUserDetails: true,
        includeLoanDetails: true,
        agendaCreator: user?.id,
      });
      setPendingOtsData(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function addToAgenda(ots: Ots) {
    setFormData(data => {
      const updatedData = { ...data };
      const existingOts = updatedData.addToAgenda ?? [];
      const filteredOts = existingOts.filter(i => i.id == ots.id);
      if (filteredOts.length == 0) {
        existingOts.push(ots);
      }
      updatedData.addToAgenda = existingOts;
      const existingOtsForSkip = updatedData.skipFromAgenda ?? new Map();
      existingOtsForSkip.delete(ots);
      updatedData.skipFromAgenda = existingOtsForSkip;
      return updatedData;
    });
  }

  function skipFromAgenda(reason: string, otsRequest?: Ots) {
    if (!otsRequest) return;
    setFormData(data => {
      const updatedData = { ...data };
      const existingOts = updatedData.skipFromAgenda ?? new Map<Ots, string>();
      existingOts.set(otsRequest, reason);
      updatedData.skipFromAgenda = existingOts;
      const existingOtsForAdding = updatedData.addToAgenda ?? [];
      updatedData.addToAgenda = existingOtsForAdding.filter(
        i => i.id != otsRequest.id
      );
      return updatedData;
    });
  }

  async function onCreateAgendaClick(data: Partial<CreateAgendaFormData>) {
    try {
      setLoading(true);
      const cases: Partial<AddAgendaCaseRequest>[] = [];

      formData.addToAgenda?.map(i => {
        cases.push({ otsId: i.id, meetingStatus: AgendaCaseStatus.APPROVED });
      });

      formData.skipFromAgenda.forEach((k, v) => {
        cases.push({
          otsId: v.id,
          meetingStatus: AgendaCaseStatus.REJECTED,
          meetingExclusionReason: k,
        });
      });

      const request: Partial<CreateAgendaRequest> = {
        userId: user?.id,
        chairPerson: data.chairPerson?.id,
        scheduledDateTime: DateUtility.toISOWithoutTimezone(
          data.scheduledDateTime
        ),
        cases: cases,
        members: data.members?.map(i => i.id),
      };
      await AgendaService.createAgenda(request);
      fetchData();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const canCreateAgenda = () => {
    if (pendingOtsData.numberOfItems === 0) {
      return false;
    }
    const addedCasesLength = formData.addToAgenda?.length ?? 0;
    const rejectedCasesLength = formData.skipFromAgenda?.size ?? 0;
    return (
      addedCasesLength + rejectedCasesLength == pendingOtsData.numberOfItems
    );
  };

  function onPageChange(page: number) {
    setPendingOtsData(prev => {
      return {
        ...prev,
        pageNumber: page,
      };
    });
  }

  useEffect(() => {
    fetchData();
  }, [pendingOtsData.pageNumber]);

  return (
    <div>
      <VegaPageHeader title="Agenda Creator" />
      <VegaPageContent>
        <Box pt={3}>
          <VegaDataGrid
            data={pendingOtsData.records}
            loading={loading}
            columns={getPendingColumnDefinition({
              formData: formData,
              onAddClick: function (ots: Ots): void {
                addToAgenda(ots);
              },
              onSkipClick: function (ots: Ots): void {
                openReasonDialog({ ots: ots });
              },
              onViewDetailsClick: function (ots: Ots): void {
                navigate(ROUTES.OTS_DETAILS.replace(':otsId', ots.id));
              },
            })}
            idColumn="id"
            rowCount={pendingOtsData.numberOfItems}
            page={pendingOtsData.pageNumber}
            pageSize={10}
            onPageChange={onPageChange}
          />
        </Box>
        <Stack
          style={{
            alignItems: 'flex-end',
          }}
        >
          <VegaButton
            text={'Create Agenda'}
            disabled={canCreateAgenda() == false}
            onClick={() => openCreateAgendaDrawer()}
          ></VegaButton>
        </Stack>
      </VegaPageContent>
      <SkipOtsFromAgendaReasonDialog
        show={isReasonDialogOpen}
        ots={reasonDialogProps.ots}
        onClose={function (): void {
          closeReasonDialog();
        }}
        onReject={function (reason: string, ots: Ots): void {
          skipFromAgenda(reason, ots);
        }}
      />

      <CreateAgendaDrawer
        open={isCreateAgendaDrawerOpen}
        onClose={function (): void {
          closeCreateAgendaDrawer();
        }}
        onSubmit={function (formData: Partial<CreateAgendaFormData>): void {
          onCreateAgendaClick(formData);
        }}
      />
    </div>
  );
}

export default AgendaCreatorOtsPage;

const getPendingColumnDefinition = (data: {
  onAddClick: (ots: Ots) => void;
  onSkipClick: (ots: Ots) => void;
  onViewDetailsClick: (ots: Ots) => void;
  formData: Partial<AddToAgendaFormData>;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Ots Request Date',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const parsedDate = DateUtility.parseStringToDate(ots.createdAt);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentId',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 250,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const raisedBy = getValueFromRecord<VegaUser>(
          ots?.metaData,
          OtsMetaDataKey.RAISED_BY
        );
        const displayText = StringUtility.concatenateStrings(
          ' ',
          raisedBy?.firstName,
          raisedBy?.middleName,
          raisedBy?.lastName
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'Loan Id',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = ots.loanId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsStatus',
      headerName: 'Ots Status',
      flex: 0.7,
      minWidth: 250,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = getOtsStatusDescription(ots?.status);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'agendaStatus',
      headerName: 'Agenda Status',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const ots = getOtsForRow(props);
        var displayText = 'Pending Decision';
        var textColor = 'orange';
        const isRejected = data.formData?.skipFromAgenda?.has(ots);
        const isAccepted =
          (data.formData?.addToAgenda.filter(i => i.id == ots.id) ?? [])
            .length > 0;
        if (isRejected) {
          displayText = data.formData?.skipFromAgenda.get(ots);
          textColor = 'red';
        }
        if (isAccepted) {
          displayText = 'Added';
          textColor = 'green';
        }
        return (
          <VegaText
            text={displayText}
            fontWeight={500}
            fontSize={'0.875rem'}
            color={textColor}
          />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Add/Skip',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const isRejected = data.formData?.skipFromAgenda?.has(ots);
        const isAccepted =
          (data.formData?.addToAgenda.filter(i => i.id == ots.id) ?? [])
            .length > 0;
        const isPending = isRejected == false && isAccepted == false;
        const canShowAddButton = isRejected || isPending;
        const canShowSkipButton = isAccepted || isPending;
        return (
          <Stack direction={'row'}>
            {canShowAddButton && (
              <VegaButton
                text="Add"
                variant="text"
                sx={{ px: 0.5 }}
                onClick={() => {
                  data.onAddClick(ots);
                }}
              />
            )}
            {canShowSkipButton && (
              <VegaButton
                text="Skip"
                variant="text"
                sx={{ px: 0.5, color: 'red' }}
                onClick={() => {
                  data.onSkipClick(ots);
                }}
              />
            )}
          </Stack>
        );
      },
    },

    {
      field: 'details',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const ots = getOtsForRow(props);
        return (
          <Stack direction={'row'} gap={'0.5rem'}>
            {/* <VegaButton
              onClick={() => {
                data.onViewDetailsClick(ots);
              }}
              text={'View Details'}
            /> */}
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => {
                data.onViewDetailsClick(ots);
              }}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getOtsForRow = (props: any) => props.row as Ots;
