/* eslint-disable react/jsx-key */
import { Stack, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { BidService } from '../../../../Apis/BidService';
import { OtsService } from '../../../../Apis/OtsService';
import LmDialog from '../../../../components/common/LmDialog';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import VegaDialogItem from '../../../../components/common/VegaDialogItem';
import VegaText from '../../../../components/common/VegaText';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { Bid, Possession } from '../../../../types/auction';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { StringUtility } from '../../../../utils/StringUtility';
import OtsFileDisplay from '../../../ots/pages/components/OtsFileDisplay';

type Props = {
  open: boolean;
  onClose: () => void;
  possession?: Possession;
};

const AuctionDetailsModal = ({ open, onClose, possession }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [bid, setBid] = useState<Bid>();

  async function fetchWinningBid(id: string) {
    try {
      setLoading(true);
      const response = await BidService.getBid(id);
      setBid(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function downloadAuctionReport() {
    try {
      const reportUrl = possession?.metaData?.auction?.report;
      if (!reportUrl) {
        setSnackbar('Report not found', 'error');
        return;
      }
      setLoading(true);
      const response = await OtsService.getPublicUrl({ url: reportUrl });
      window.open(response, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getReservePrice = () => {
    return possession?.metaData?.auction?.reservePrice ?? 0;
  };

  useEffect(() => {
    const winningBidId = possession?.highestBidId;
    if (open == true && winningBidId) {
      fetchWinningBid(winningBidId);
    }
  }, [open, possession]);

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      isCloseIcon
      handleClose={onClose}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Auction details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Stack gap="0.5rem">
          <VegaDialogItem
            heading={'Reserve Price'}
            value={StringUtility.formatToINR(getReservePrice())}
          />
          <VegaDialogItem
            heading={'Winning Bid'}
            value={StringUtility.formatToINR(bid?.bidAmount)}
            loading={loading}
          />
          <VegaDialogItem
            heading={'Name of the winner'}
            value={bid?.userName}
            loading={loading}
          />
          <VegaDialogItem
            heading={'Auction Report'}
            renderValue={() => (
              <OtsFileDisplay
                value={'Report'}
                onClick={() => {
                  downloadAuctionReport();
                }}
              />
            )}
          />
        </Stack>
      </VegaDialogContent>
    </LmDialog>
  );
};

export default AuctionDetailsModal;
