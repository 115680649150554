import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BORDER_COLLECTION, COMMON } from '../../../../constants/style';
import VegaText from '../../../common/VegaText';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { AthenaQueryResponse } from '../../../../types/performanceType';
import { PerformanceServices } from '../../../../Apis/PerformanceServices';
import { VISITS } from '../../../../constants/athenaQueryId';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { toLowerCase } from '../../../../constants/commonFunction';
import { useAppSelector } from '../../../../store';
import { getPerformanceDashboard } from '../../../../features/performanceDashboardSlice';

const VisitsGraph = () => {
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const { setSnackbar } = useSnackbar();
  const [visitsState, setVisitsState] = useState<AthenaQueryResponse[]>([]);
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusWhenStacked: 'last',
        horizontal: true,
        barHeight: 24,
      },
    },

    xaxis: {
      categories: visitsState?.map(
        (athena: AthenaQueryResponse) =>
          athena.disposition_code && toLowerCase(athena.disposition_code)
      ),
    },

    colors: ['#1047DC'],
    series: [
      {
        data: visitsState?.map(
          (athena: AthenaQueryResponse) =>
            athena.unique_value_count && parseInt(athena.unique_value_count)
        ),
      },
    ],
  };

  const getAthenaQuery = async (queryId: string) => {
    try {
      const response = await PerformanceServices.getAthenaQueries({
        queryId,
        params: filterData,
      });
      return response;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getVisits = async () => {
    const visitsValue = await getAthenaQuery(VISITS);
    setVisitsState(visitsValue);
  };

  useEffect(() => {
    getVisits();
  }, []);

  return (
    <Stack
      sx={{
        p: 3,
        bgcolor: 'white',
        borderRadius: '12px',
        border: BORDER_COLLECTION,
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <VegaText
          text={'Visits'}
          fontSize={17}
          fontWeight={600}
          color={COMMON.balck}
        />
      </Stack>
      <Chart options={options} series={options.series} type="bar" />
    </Stack>
  );
};

export default VisitsGraph;
