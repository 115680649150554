import { createSlice } from '@reduxjs/toolkit';
import {
  bulkAllocate,
  bulkDeAllocate,
  fetchAllocationRules,
  fetchAllocationStatus,
  fetchAllocations,
  fetchAllocationsMetaData,
  fetchBulkAllocations,
  fetchBulkDeAllocations,
  fetchCriteriaAttributes,
  fetchCriteriaByCriteriaId,
  fetchExecutionAllocation,
  reallocateAllocations,
} from '../actions/AllocationActions';
import { RootState } from '../store';
import {
  Allocation,
  AllocationMetaData,
  AllocationRule,
  AllocationRuleViewModel,
  AllocationStatusViewModel,
  AllocationViewModel,
  BulkAllocationMeta,
  ExecutionAllocations,
  RuleCriteriaAttribute,
} from '../types/allocations';
import { FilteredRoleDto } from '../types/claim';

export interface AllocationState {
  loading: boolean;
  loadingBulkAllocation: boolean;
  loadingExecution: boolean;
  loadingStatus: boolean;
  loadingBulkAllocations: boolean;
  loadingCriteriaAttributes: boolean;
  loadingAllocationRules: boolean;
  loadingOfViewCriteria: boolean;
  criteriaData: Partial<AllocationRule>;
  uploadingBulkFile: boolean;
  selectedRows: string[];
  allocations: Allocation[];
  filteredRoles: FilteredRoleDto[];
  executionAllocations: ExecutionAllocations[];
  allocationsMetaData: AllocationMetaData[];
  criteriaAttributes: RuleCriteriaAttribute[];
  allocationRuleViewModels: AllocationRuleViewModel[];
  bulkAllocations: BulkAllocationMeta[];
  bulkDeAllocations: BulkAllocationMeta[];
  allocationViewModels: AllocationViewModel[];
  allocationStatus: AllocationStatusViewModel;
  page: number;
  allocationRulePage: number;
  totalItems: number;
  totalItemsOfExecution: number;
  totalItemsOfMetaData: number;
  totalItemsOfBulkAllocation: number;
  totalItemsOfBulkDeAllocation: number;
  allocationRuleTotalItems: number;
  pageSize: number;
  allocationRulePageSize: number;
  error: string | null;
  errorBulkAllocation: string | null;
  errorExecutionAllocation: string | null;
}

const initialState: AllocationState = {
  allocations: [],
  executionAllocations: [],
  allocationsMetaData: [],
  allocationViewModels: [],
  allocationRuleViewModels: [],
  filteredRoles: [],
  allocationStatus: {},
  criteriaData: {},
  loading: false,
  loadingExecution: false,
  loadingBulkAllocation: false,
  loadingStatus: false,
  loadingAllocationRules: false,
  loadingOfViewCriteria: false,
  uploadingBulkFile: false,
  error: null,
  errorBulkAllocation: null,
  errorExecutionAllocation: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
  totalItemsOfExecution: 0,
  totalItemsOfMetaData: 0,
  allocationRulePage: 0,
  allocationRuleTotalItems: 0,
  allocationRulePageSize: 0,
  totalItemsOfBulkAllocation: 0,
  totalItemsOfBulkDeAllocation: 0,
  loadingCriteriaAttributes: false,
  criteriaAttributes: [],
  loadingBulkAllocations: false,
  bulkAllocations: [],
  bulkDeAllocations: [],
  selectedRows: [],
};

const allocationSlice = createSlice({
  name: 'allocation',
  initialState,
  reducers: {
    setSelectedRows: (state: AllocationState, action) => {
      const { payload } = action;
      state.selectedRows = payload;
    },
    setFilteredRoles: (state: AllocationState, action) => {
      const { payload } = action;
      state.filteredRoles = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllocations.pending, state => {
        state.allocations = [];
        state.allocationViewModels = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllocations.fulfilled, (state, action) => {
        state.loading = false;
        state.allocations = [...action.payload];
        state.allocationViewModels = mapAllocationsToViewModels(action.payload);
        // state.page = action.payload.pageNumber;
        // state.pageSize = action.payload.numberOfItems;
        // state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchAllocations.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || 'Failed to fetch Allocations';
      })
      .addCase(fetchAllocationsMetaData.pending, state => {
        state.allocationsMetaData = [];
        state.totalItemsOfMetaData = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllocationsMetaData.fulfilled, (state, action) => {
        state.loading = false;
        state.allocationsMetaData = [...action.payload.records];
        state.totalItemsOfMetaData = action.payload.totalItems;
        // state.page = action.payload.pageNumber;
        // state.pageSize = action.payload.numberOfItems;
      })
      .addCase(fetchAllocationsMetaData.rejected, (state, action) => {
        state.allocationsMetaData = [];
        state.totalItemsOfMetaData = 0;
        state.loading = false;
        state.error =
          (action.payload as string) || 'Failed to fetch Allocations';
      })
      .addCase(reallocateAllocations.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reallocateAllocations.fulfilled, (state, action) => {
        state.loading = false;
        const updatedAllocations = state.allocations.map(existingAllocation => {
          const updatedAllocation = action.payload.find(updatedAllocation => {
            return updatedAllocation.id === existingAllocation.id;
          });
          return updatedAllocation ? updatedAllocation : existingAllocation;
        });
        state.allocationViewModels =
          mapAllocationsToViewModels(updatedAllocations);
      })
      .addCase(reallocateAllocations.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || 'Failed to reallocate Allocations';
      })
      .addCase(fetchAllocationStatus.pending, state => {
        state.loadingStatus = true;
        state.error = null;
      })
      .addCase(fetchAllocationStatus.fulfilled, (state, action) => {
        state.loadingStatus = false;
        state.error = null;
        state.allocationStatus = action.payload;
      })
      .addCase(fetchAllocationStatus.rejected, (state, action) => {
        state.loadingStatus = false;
        state.error =
          (action.payload as string) || 'Failed to fetch allocation status';
      })
      .addCase(fetchAllocationRules.pending, state => {
        state.allocationRuleViewModels = [];
        state.loadingAllocationRules = true;
        state.error = null;
      })
      .addCase(fetchAllocationRules.fulfilled, (state, action) => {
        state.loadingAllocationRules = false;
        state.allocationRuleViewModels = [...action.payload.records];
        state.allocationRulePage = action.payload.pageNumber;
        state.allocationRulePageSize = action.payload.numberOfItems;
        state.allocationRuleTotalItems = action.payload.totalItems;
      })
      .addCase(fetchAllocationRules.rejected, (state, action) => {
        state.loadingAllocationRules = false;
        state.error =
          (action.payload as string) || 'Failed to fetch Allocations';
      })
      .addCase(fetchCriteriaByCriteriaId.pending, state => {
        state.loadingOfViewCriteria = true;
      })
      .addCase(fetchCriteriaByCriteriaId.fulfilled, (state, action) => {
        state.loadingOfViewCriteria = false;
        state.criteriaData = action.payload;
      })
      .addCase(fetchCriteriaByCriteriaId.rejected, (state, action) => {
        state.loadingOfViewCriteria = false;
        state.error =
          (action.payload as string) || 'Failed to fetch Allocations';
      })
      .addCase(fetchCriteriaAttributes.pending, state => {
        state.criteriaAttributes = [];
        state.loadingCriteriaAttributes = true;
        state.error = null;
      })
      .addCase(fetchCriteriaAttributes.fulfilled, (state, action) => {
        state.criteriaAttributes = action.payload;
        state.loadingCriteriaAttributes = false;
      })
      .addCase(fetchCriteriaAttributes.rejected, (state, action) => {
        state.loadingCriteriaAttributes = false;
        state.error =
          (action.payload as string) || 'Failed to fetch attributes';
      })

      .addCase(fetchBulkAllocations.pending, state => {
        state.loadingBulkAllocation = true;
        state.errorBulkAllocation = null;
        state.bulkAllocations = [];
      })
      .addCase(fetchBulkAllocations.fulfilled, (state, action) => {
        state.loadingBulkAllocation = false;
        state.bulkAllocations = [...action.payload.records];
        state.totalItemsOfBulkAllocation = action.payload.totalItems;
      })
      .addCase(fetchBulkAllocations.rejected, (state, action) => {
        state.loadingBulkAllocation = false;
        state.errorBulkAllocation =
          (action.payload as string) || 'Failed to fetch allocation';
      })
      .addCase(fetchBulkDeAllocations.pending, state => {
        state.bulkDeAllocations = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBulkDeAllocations.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkDeAllocations = [...action.payload.records];
        state.totalItemsOfBulkDeAllocation = action.payload.totalItems;
      })
      .addCase(fetchBulkDeAllocations.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Failed to Create Rule';
      })
      .addCase(bulkAllocate.pending, state => {
        state.loading = true;
        state.uploadingBulkFile = true;
        state.error = null;
      })
      .addCase(bulkAllocate.fulfilled, state => {
        state.loading = false;
        state.uploadingBulkFile = false;
      })
      .addCase(bulkAllocate.rejected, (state, action) => {
        state.loading = false;
        state.uploadingBulkFile = false;
        state.error = (action.payload as string) || 'Failed to Bulk Allocate';
      })
      .addCase(bulkDeAllocate.pending, state => {
        state.loading = true;
        state.uploadingBulkFile = true;
        state.error = null;
      })
      .addCase(bulkDeAllocate.fulfilled, state => {
        state.loading = false;
        state.uploadingBulkFile = false;
      })
      .addCase(bulkDeAllocate.rejected, (state, action) => {
        state.loading = false;
        state.uploadingBulkFile = false;
        state.error = (action.payload as string) || 'Failed to Bulk Allocate';
      })
      .addCase(fetchExecutionAllocation.pending, state => {
        state.loadingExecution = true;
        state.errorExecutionAllocation = null;
      })
      .addCase(fetchExecutionAllocation.fulfilled, (state, action) => {
        state.loadingExecution = false;

        state.executionAllocations = [...action.payload.records];
        state.totalItemsOfExecution = action.payload.totalItems;
      })
      .addCase(fetchExecutionAllocation.rejected, (state, action) => {
        state.loadingExecution = false;
        state.errorExecutionAllocation =
          (action.payload as string) || 'Failed to Execute Allocate';
      });
  },
});

export default allocationSlice;
export const { setSelectedRows, setFilteredRoles } = allocationSlice.actions;
export const getAllocations = (state: RootState) => state.allocations;

function mapAllocationsToViewModels(
  allocations: Allocation[]
): AllocationViewModel[] {
  const viewModels: AllocationViewModel[] = allocations.map(item => {
    const viewModel: AllocationViewModel = {
      id: item.id,
      loanAccountNumber: item.loanAccountId,
      customerName: '-',
      contactNumber: '-',
      address: '-',
      agentName: item.agentCode,
      branchName: item.branch,
    };
    return viewModel;
  });

  return viewModels;
}
