import { Box, Grid, Stack } from '@mui/material';
import DatesAndDepositWiseAccount from '../../components/Perfomance/modules/Graphs/DatesAndDepositWiseAccount';
import TotalReceiptsIssueAmountGraph from '../../components/Perfomance/modules/Graphs/TotalReceiptsIssueAmountGraph';
import TotalReceiptsIssueCountGraph from '../../components/Perfomance/modules/Graphs/TotalReceiptsIssueCountGraph';
import VisitsGraph from '../../components/Perfomance/modules/Graphs/VisitsGraph';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import CollectedVsCollectabelContainer from '../../components/Perfomance/CollectedVsCollectabelContainer';

const PerformanceSummaryGraph = () => {
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <VegaBreadCrumb
                items={[
                  { label: 'Performance Dashboard', link: -1 },
                  { label: `Performance Summary` },
                ]}
              />
            </Stack>
          );
        }}
      />
      <Box
        sx={{
          height: 'calc(100% - 68px)',
          overflow: 'auto',
        }}
      >
        <VegaPageContent sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TotalReceiptsIssueCountGraph />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TotalReceiptsIssueAmountGraph />
            </Grid>
          </Grid>
        </VegaPageContent>
        <VegaPageContent sx={{ mt: 2 }}>
          <CollectedVsCollectabelContainer />
        </VegaPageContent>
        <VegaPageContent sx={{ my: 2 }}>
          <VisitsGraph />
        </VegaPageContent>
        <VegaPageContent sx={{ my: 2 }}>
          <DatesAndDepositWiseAccount />
        </VegaPageContent>
      </Box>
    </>
  );
};

export default PerformanceSummaryGraph;
