import { Stack } from '@mui/material';
import LmDialog from '../../../../components/common/LmDialog';
import VegaText from '../../../../components/common/VegaText';
import ReceiptDetails from '../ReceiptDetails';

interface IProps {
  open: boolean;
  toggleDialog: (isDetailedDialogToggle?: boolean) => void;
  isApprover?: boolean;
}

const HistoryRecieptDetailsDialog = ({
  open,
  toggleDialog,
  isApprover = false,
}: IProps) => {
  const handleDialogChange = value => () => {
    toggleDialog(value);
  };

  const renderHeader = () => (
    <Stack
      direction={'row'}
      alignItems={'center'}
      px={4}
      py={2.5}
      gap={2}
      bgcolor={'#E5F7FC'}
    >
      <VegaText
        text="Receipt Details"
        fontWeight={600}
        fontSize={'1.0625rem'}
        color={'black'}
      />
    </Stack>
  );

  return (
    <LmDialog
      maxWidth="xs"
      open={open}
      isCloseIcon
      handleClose={handleDialogChange(false)}
      buttonComponent={() => <></>}
      headerComponent={renderHeader}
    >
      <ReceiptDetails
        isAgent
        closeDialog={handleDialogChange(true)}
        isApprover={isApprover}
      />
    </LmDialog>
  );
};

export default HistoryRecieptDetailsDialog;
