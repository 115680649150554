import React from 'react';
import { LoanData } from '../../types/loan';
import VegaDataGrid from '../common/VegaDataGrid';
import { RollForwardReportColumn } from './modules/columns/RollForwardReportColumn';

interface IProps {
  page: number;
  size: number;
  totalItems: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  loanList: LoanData[];
  loading: boolean;
}

const ListOfRollForwardReport = ({
  page,
  size,
  totalItems,
  loading,
  loanList,
  setPage,
  setSize,
}: IProps) => {
  return (
    <VegaDataGrid
      idColumn="loanId"
      columns={RollForwardReportColumn()}
      data={loanList}
      loading={loading}
      page={page}
      pageSize={size}
      rowCount={totalItems}
      onPageChange={page => setPage(page)}
      onPageSizeChange={size => setSize(size)}
      paginationMode="server"
    />
  );
};

export default ListOfRollForwardReport;
