import { Stack, Grid } from '@mui/material';
import VegaButton from '../../../../components/common/VegaButton';
import { useOts } from '../../../../providers/OtsProvider';
import { StringUtility } from '../../../../utils/StringUtility';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';

import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsNpvDetails() {
  const { loading, ots, downloadFile } = useOts();

  return (
    <OtsDetailsWrapper text="NPV Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Realisable Value "
              value={StringUtility.formatToINR(ots?.realisableValue ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Market Value"
              value={StringUtility.formatToINR(ots?.marketValue ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Property Docs available"
              value={`${ots?.propertyDocsAvailable ?? 'N/A'}`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Distress Value"
              value={StringUtility.formatToINR(ots?.distressValue ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Npv Of Distress Value"
              value={StringUtility.formatToINR(ots?.npvOfDistressValue ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Tos Based Max Value"
              value={StringUtility.formatToINR(ots?.tosBasedMaxValue ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Pv Tos Based Max Value"
              value={StringUtility.formatToINR(ots?.pvOfTosBasedMaxValue ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Property Value Realisation"
              value={StringUtility.formatToINR(
                ots?.propertyValueRealization ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Fixed Realisation Cost"
              value={StringUtility.formatToINR(ots?.fixedRealisationCost ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Variable Realisation Cost"
              value={StringUtility.formatToINR(
                ots?.variableRealisationCost ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Total Realisation Cost"
              value={StringUtility.formatToINR(ots?.totalRealisationCost ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Pv-Realisation Cost"
              value={StringUtility.formatToINR(ots?.pvRealisationCost ?? 0)}
              loading={loading}
            />
          </Grid>
        </Grid>
        {ots?.note && (
          <Stack
            direction={'row'}
            alignItems="flex-end"
            justifyContent={'flex-end'}
          >
            <VegaButton
              variant="text"
              text="View Case Note"
              onClick={() => {
                downloadFile(ots.note);
              }}
            ></VegaButton>
          </Stack>
        )}
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsNpvDetails;
