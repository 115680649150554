import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VegaButton from '../../components/common/VegaButton';
import VegaText from '../../components/common/VegaText';

function NoAccessPage() {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ height: '100%' }}
      >
        <Stack gap={1} textAlign={'center'}>
          <VegaText
            fontWeight={700}
            text={'Access Denied'}
            sx={{
              color: 'red',
              fontSize: 30,
            }}
          />
          <VegaText
            text={'You dont have permission to view this page.'}
            sx={{
              fontSize: 20,
            }}
          />
          <VegaButton
            variant="text"
            text="Go back"
            onClick={() => navigate(-1)}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default NoAccessPage;
