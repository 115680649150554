import { VisibilityOutlined } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { fetchProcessList } from '../../../actions/TeleAllocationAction';
import { PRIMARY } from '../../../constants/style';
import {
  getTeleAllocation,
  setOpenViewProcessDialog,
  setProcessDetials,
} from '../../../features/teleAllocationSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GetProcessListApiRequest } from '../../../types/request/teleAllocation';
import {
  ProcessType,
  TeleAllocationProcessViewModal,
} from '../../../types/teleAllocation';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmFilterSection from '../../common/LmFilterSection';
import VegaBulkUploadModal from '../../common/VegaBulkUploadModal';
import VegaDataGrid from '../../common/VegaDataGrid';
import VegaDrawer from '../../common/VegaDrawer';
import { VegaFileSelectOption } from '../../common/VegaFileSelect';
import VegaIconButton from '../../common/VegaIconButton';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';
import ProcessFilterForm from './ProcessFilterForm';
import ViewProcessDetailsDialog from './ViewProcessDetailsDialog';

const ListOfProcessCreation = () => {
  const { processes, processDetials, processTotalItems, loading } =
    useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const {
    isOpen: isUploadModalOpen,
    open: openUploadModal,
    close: closeUploadModal,
  } = useDrawer();

  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  async function uploadMapAgentFile(selectedFile: File) {
    try {
      const fileObj: VegaFileSelectOption = {
        value: selectedFile,
        label: selectedFile.name,
        id: selectedFile.name,
      };
      const response = await TeleAllocationService.createBulkProcess({
        processId: processDetials.slashRtcProcessId,
        file: fileObj,
      });
      const result = response.result;
      const statusCode = result.statusCode;
      const message = result.msg;
      if (result && statusCode && message) {
        setSnackbar(message, 'error');
      } else {
        setSnackbar('Allocate Successfully !!!');
        dispatch(fetchProcessList({ page, size }));
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function downloadSampleFile() {
    try {
      const url = await TeleAllocationService.downloadSampleProcessFile();
      window.open(url, '__blank__');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const getProcessList = () => {
    const request = {
      page,
      size,
    } as GetProcessListApiRequest;
    if (search.length) {
      request.name = search;
    }
    if (selectedStatus.length) {
      request.callingMode = selectedStatus === ProcessType.AUTO ? 3 : 1;
    }
    dispatch(fetchProcessList(request));
  };

  useEffect(() => {
    if (search.length) {
      const getLoanData = setTimeout(getProcessList, 300);
      return () => clearTimeout(getLoanData);
    } else {
      getProcessList();
    }
  }, [dispatch, page, size, search]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Process '}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection
              search={search}
              setSearch={setSearch}
              onFilter={() => openFilterDrawer()}
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={processes}
          idColumn="processId"
          paginationMode="server"
          columns={getColumnDefinition({
            onViewDetails: (process: TeleAllocationProcessViewModal) => {
              dispatch(setProcessDetials(process));
              dispatch(setOpenViewProcessDialog(true));
            },
            onMapAgent: (process: TeleAllocationProcessViewModal) => {
              openUploadModal();
              dispatch(setProcessDetials(process));
            },
          })}
          page={page}
          pageSize={size}
          rowCount={processTotalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
          loading={loading}
        />
      </VegaPageContent>
      <ViewProcessDetailsDialog />
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <ProcessFilterForm
          onClose={closeFilterDrawer}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
          page={page}
          size={size}
        />
      </VegaDrawer>
      <VegaBulkUploadModal
        open={isUploadModalOpen}
        title={'Map Agents'}
        onClose={function (): void {
          closeUploadModal();
        }}
        onSampleDownloadClick={downloadSampleFile}
        onUploadClick={uploadMapAgentFile}
      />
    </>
  );
};

export default ListOfProcessCreation;

const getColumnDefinition = (data: {
  onViewDetails: (process: TeleAllocationProcessViewModal) => void;
  onMapAgent: (process: TeleAllocationProcessViewModal) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'slashRtcProcessId',
      headerName: 'Process Id',
      flex: 0.5,
    },
    {
      field: 'processName',
      headerName: 'Process Name',
      flex: 0.7,
    },

    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      flex: 0.7,
    },
    {
      field: 'processType',
      headerName: 'Process Type',
      flex: 0.7,
    },
    {
      field: 'startDate',
      headerName: 'Process Start Date',
      flex: 0.7,
    },
    {
      field: 'endDate',
      headerName: 'Process End Date',
      flex: 0.7,
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const process = props.row as TeleAllocationProcessViewModal;
        return (
          // <VegaButton
          //   text={'View Details'}
          //   variant="text"
          //   onClick={() => data.onViewDetails(process)}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => data.onViewDetails(process)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Map Agent',
      flex: 0.7,
      renderCell: props => {
        const process = props.row as TeleAllocationProcessViewModal;
        return (
          // <VegaButton
          //   text={'Upload'}
          //   sx={{
          //     bgcolor: '#E7EDFC',
          //     color: PRIMARY.darkBlue,
          //     ':hover': {
          //       bgcolor: '#E7EDFC',
          //       color: PRIMARY.darkBlue,
          //     },
          //   }}
          //   onClick={() => data.onMapAgent(process)}
          // />
          <VegaIconButton
            tooltipTitle="Upload"
            onClick={() => data.onMapAgent(process)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={<UploadIcon sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
