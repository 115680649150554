import { createAsyncThunk } from '@reduxjs/toolkit';
import { TeleAllocationService } from '../Apis/TeleAllocationServices';
import {
  GetCampaignListApiRequest,
  GetLeadsetListApiRequest,
  GetProcessListApiRequest,
} from '../types/request/teleAllocation';

export const fetchCampaignList = createAsyncThunk(
  'teleAllocation/fetchCampaignList',
  async (params: Partial<GetCampaignListApiRequest>) => {
    const response = await TeleAllocationService.getCampaignList(params);
    return response;
  }
);
export const fetchProcessList = createAsyncThunk(
  'teleAllocation/fetchProcessList',
  async (params: Partial<GetProcessListApiRequest>) => {
    const response = await TeleAllocationService.getProcessList(params);
    return response;
  }
);
export const fetchLeadsetList = createAsyncThunk(
  'teleAllocation/fetchLeadsetList',
  async (params: Partial<GetLeadsetListApiRequest>) => {
    const response = await TeleAllocationService.getLeadsetList(params);
    return response;
  }
);
