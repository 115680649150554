/* eslint-disable no-empty */
import { useEffect, useState } from 'react';

import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { setSelectedRegion } from '../../../features/minVisitsSlice';
import { useAppDispatch } from '../../../store';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';
import VegaAutoComplete from '../../../components/common/VegaAutoComplete';

interface IProps {
  selected: string | string[];
  handleChange: (value: string) => void;
  handleDelete?: (index: number) => void;
  onClear?: () => void;
  label?: string;
  disabled?: boolean;
  isMultiSelect?: boolean;
}

const RegionSelector = ({
  selected,
  handleChange,
  handleDelete,
  onClear,
  isMultiSelect = false,
  label,
  disabled,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [regionList, setRegionList] = useState<VegaSelectOption[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const getAreaRegionList = async () => {
    setLoading(true);
    try {
      const response = await MinVisitsSevices.getRegionList();
      const filterdRegion = response.filter(
        (region: string) => region !== null
      );
      const regionListOption = await filterdRegion.map((area: string) => {
        return {
          value: area,
          label: area,
        };
      });
      setRegionList(regionListOption);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filterRegion = () => {
    if (searchValue.length) {
      const searchLowerCase = searchValue.toLowerCase();
      const filteredResults = regionList.filter(agent => {
        if (searchValue === '') {
          return agent;
        } else if (agent.label.toLowerCase().includes(searchLowerCase)) {
          return agent;
        }
      });
      return filteredResults;
    } else {
      return regionList;
    }
  };

  const onChange = (value: string) => {
    const filterdRegion = regionList.find(
      (region: VegaSelectOption) => region.value === value
    );
    dispatch(setSelectedRegion(filterdRegion.label));
    handleChange(value);
  };

  useEffect(() => {
    getAreaRegionList();
  }, []);
  return (
    <>
      {!isMultiSelect ? (
        onClear ? (
          <VegaAutoComplete
            options={filterRegion()}
            searchValue={searchValue}
            handleChange={e => {
              setSearchValue(e.target.value);
            }}
            onClear={onClear}
            onSelect={selected => handleChange(selected)}
            selected={selected}
            placeHolder="Select Region"
          />
        ) : (
          <VegaSelect
            ignoreLodash
            placeholder={label ?? 'Select Region'}
            options={regionList}
            onChange={e => onChange(e.target.value)}
            value={selected as string}
            disabled={disabled}
            loading={loading}
          />
        )
      ) : (
        <VegaSelectWithCheckbox
          options={filterRegion()}
          onSelect={selected => onChange(selected)}
          handleDelete={handleDelete}
          handleChange={e => setSearchValue(e.target.value)}
          searchValue={searchValue}
          selected={selected as string[]}
          disabled={disabled}
          loading={loading}
        />
      )}
    </>
  );
};

export default RegionSelector;
