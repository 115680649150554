import { Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import {
  getIncentivesGamificationsState,
  setCountError,
  setErrorPolicy,
  setPolicyDetailsState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentivePolicyProps,
  IncentivePolicyType,
} from '../../../types/incentives';
import { PolicyDetailsRequest } from '../../../types/request/incentives';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import VegaButton from '../../common/VegaButton';
import { VegaSelectOption } from '../../common/VegaSelect';
import VegaTitleCard from '../../common/VegaTitleCard';
import NewPolicyContainer from './NewPolicyContainer';
import PolicyDetailsForm from './PolicyDetailsForm';

interface IProps {
  loading: boolean;
}

const PolicyDetails = ({ loading }: IProps) => {
  const { policyId, policyType } = useParams();

  const navigate = useNavigate();
  const { policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedPolicies, setSelectedPolicies] = useState<VegaSelectOption[]>(
    []
  );
  const [showPolicyDetails, setShowPolicyDetails] = useState<boolean>(false);

  const disabled =
    !policyDetailsState?.policyName?.length ||
    !policyDetailsState?.vertical?.length ||
    !policyDetailsState?.channelType?.length ||
    !policyDetailsState?.incentiveType?.length ||
    !policyDetailsState?.policyStartDate?.length ||
    !policyDetailsState?.policyEndDate?.length ||
    !policyDetailsState?.accountType?.length ||
    !policyDetailsState?.classType?.length ||
    !policyDetailsState?.payoutFrequency?.length ||
    !policyDetailsState?.maxPayoutPerPerson ||
    !policyDetailsState?.userType?.length ||
    !policyDetailsState?.modeOfCommunication?.length;

  const addPolicy = (request: PolicyDetailsRequest) => {
    IncentiveServices.addPolicy(request)
      .then(res => {
        setSnackbar('Add policy successfully !!!');
        navigate(
          ROUTES.INCENTIVES_GAMIFICATIONS_EDIT.replace(':edit', 'edit').replace(
            ':policyId',
            res.id
          )
        );
        // navigate(`${ROUTES.INCENTIVES_GAMIFICATIONS_ADD}?id=${res.id}`);
        dispatch(setErrorPolicy(''));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const onSavePolicyDetails = () => {
    const request = {
      ...policyDetailsState,
      policyType,
      replacementPolicyIds: selectedPolicies.map(
        (selectedPolicy: VegaSelectOption) => selectedPolicy.value
      ),
    } as PolicyDetailsRequest;
    if (request?.splitPayoutForUsersMap) {
      const splitPayoutForUsersMap = request.splitPayoutForUsersMap;
      const totalValue = Object.values(splitPayoutForUsersMap).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      if (totalValue > 100) {
        dispatch(
          setCountError('Total of % split must be less than or equal to 100')
        );
      } else {
        dispatch(setCountError(''));
        addPolicy(request);
      }
    } else {
      addPolicy(request);
    }
  };
  const onCreateNewPolicy = () => {
    setShowPolicyDetails(true);
    const policyDetails = {
      ...policyDetailsState,
      userType: selectedUsers,
    } as IncentivePolicyProps;
    dispatch(setPolicyDetailsState(policyDetails));
  };

  const getButtonEnum = () => {
    if (showPolicyDetails || policyType === IncentivePolicyType.ADD_ON_POLICY) {
      return {
        text: 'Save Policy Details',
        Fun: onSavePolicyDetails,
        disabled,
      };
    } else {
      return {
        text: 'Replace and Create New Policy',
        Fun: onCreateNewPolicy,
        disabled: !selectedUsers?.length || !selectedPolicies?.length,
      };
    }
  };

  return (
    <>
      {policyType === IncentivePolicyType.NEW_POLICY ? (
        <NewPolicyContainer
          show={showPolicyDetails}
          setShow={setShowPolicyDetails}
          loading={loading}
          selectedUsers={selectedUsers}
          selectedPolicies={selectedPolicies}
          setSelectedPolicies={setSelectedPolicies}
          setSelectedUsers={setSelectedUsers}
        />
      ) : (
        <VegaTitleCard title="Policy Details">
          <PolicyDetailsForm loading={loading} />
        </VegaTitleCard>
      )}
      {!policyId && (
        <Box
          sx={{
            textAlign: 'end',
            mt: '1rem',
          }}
        >
          <VegaButton
            text={getButtonEnum().text}
            onClick={getButtonEnum().Fun}
            disabled={getButtonEnum().disabled}
          />
        </Box>
      )}
    </>
  );
};

export default PolicyDetails;
