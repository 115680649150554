import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ParameterDefinitionType } from '../../../types/incentives';
import {
  BasePolicyRangeProps,
  IncentivePolicyRulesRequest,
} from '../../../types/request/incentives';
import { DeleteIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';

interface IBaseIncentiveProps {
  baseIncentive: BasePolicyRangeProps;
  index: number;
  onDelete?: (index: number) => void;
}

const BaseIncentiveForChargeCollected = ({
  baseIncentive,
  index,
  onDelete,
}: IBaseIncentiveProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId, edit } = useParams();

  const handleBaseIncentiveChange = (
    key: keyof BasePolicyRangeProps,
    value: string | number
  ) => {
    if (
      key === 'parameterDefinitionType' &&
      value === ParameterDefinitionType.FIXED
    ) {
      const baseIncentiveObj = {
        ...baseIncentive,
        [key]: value,
        chargePayoutMetric: policyRuleState.payoutMetric,
      } as BasePolicyRangeProps;
      const basePoliciyRule = {
        ...policyRuleState,
        chargesCollectedPayoutRange: [baseIncentiveObj],
      } as IncentivePolicyRulesRequest;
      dispatch(setPolicyRuleState(basePoliciyRule));
    } else {
      const spreadBaseIncentive = [
        ...policyRuleState.chargesCollectedPayoutRange,
      ];
      const baseIncentiveObj = {
        ...baseIncentive,
        [key]: value,
        chargePayoutMetric: policyRuleState.payoutMetric,
      } as BasePolicyRangeProps;
      spreadBaseIncentive.splice(index, 1, baseIncentiveObj);
      const basePoliciyRule = {
        ...policyRuleState,
        chargesCollectedPayoutRange: spreadBaseIncentive,
      } as IncentivePolicyRulesRequest;
      dispatch(setPolicyRuleState(basePoliciyRule));
    }
  };
  return (
    <Stack sx={{ borderBottom: BORDER_COLLECTION, py: '1rem' }} gap={2}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && index !== 0 && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
        {edit && index !== 0 && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <VegaFormInputField label={'parameter type'}>
            <VegaSelect
              options={parameterTypeOption()}
              value={baseIncentive?.parameterDefinitionType ?? ''}
              onChange={e => {
                const value = e.target.value as string;
                handleBaseIncentiveChange('parameterDefinitionType', value);
              }}
            />
          </VegaFormInputField>
        </Grid>
        {(baseIncentive.parameterDefinitionType === 'RANGE_AMOUNT' ||
          baseIncentive.parameterDefinitionType === 'RANGE_PERCENTAGE') && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Stack
              sx={{
                width: '100%',
              }}
              direction={'row'}
              gap={1}
            >
              <Stack sx={{ width: '50%' }}>
                <VegaFormInputField label={'min value'}>
                  <LmTextField
                    type="number"
                    placeholder="Min"
                    value={baseIncentive.parameterMin}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBaseIncentiveChange('parameterMin', value);
                    }}
                  />
                </VegaFormInputField>
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <VegaFormInputField label={'max value'}>
                  <LmTextField
                    type="number"
                    placeholder="Max"
                    value={baseIncentive.parameterMax}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBaseIncentiveChange('parameterMax', value);
                    }}
                  />
                </VegaFormInputField>
              </Stack>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
          <VegaFormInputField label={'value'}>
            <LmTextField
              type="number"
              placeholder="Max"
              value={baseIncentive?.payoutAmountValue ?? 0}
              onChange={e => {
                let value = e.target.value && parseFloat(e.target.value);
                if (value > 100) {
                  value = 100;
                }
                handleBaseIncentiveChange('payoutAmountValue', value);
              }}
              endAdornment={<>%</>}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
          <VegaFormInputField label={'Max Payout'}>
            <LmTextField
              type="number"
              value={baseIncentive?.payoutAmountMax ?? 0}
              onChange={e => {
                const value = e.target.value && parseFloat(e.target.value);
                handleBaseIncentiveChange('payoutAmountMax', value);
              }}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BaseIncentiveForChargeCollected;

const parameterTypeOption = () => {
  const options: VegaSelectOption[] = [
    'FIXED',
    'RANGE_AMOUNT',
    'RANGE_PERCENTAGE',
  ].map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
