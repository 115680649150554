import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Params } from '../types/request/performanceRequest';
import { PaymentBucketPerformanceRequest } from '../types/request/loan';
import { LoanService } from '../Apis/LoanServices';

export type PaymentPerformance = {
  name: string;
  oneToThirty: string;
  thirtyOneToSixty: string;
  sixtyOneToNinety: string;
  npaMinus: string;
  npaPlus: string;
  total: string;
  loanList: string[];
};

export interface PaymentType {
  [type: string]: number;
}

export interface PaymentDto {
  id: string;
  bucketType: PaymentType[];
}

export type BucketValue = {
  bucket: string;
  value: string;
};

export type PaymentNewDto = {
  stabilised: Partial<PaymentPerformance>;
  rollForward: Partial<PaymentPerformance>;
  rollBackData: Partial<PaymentPerformance>;
  normalizedData: Partial<PaymentPerformance>;
};

export interface PerformanceDashboardSliceProps {
  filterData: Partial<Params>;
  filterRiskBasePerformance: Partial<PaymentBucketPerformanceRequest>;
  payments: PaymentNewDto;
  buckets: PaymentDto[];
  paymentError: string | null;
  bucketError: string | null;
  paymentLoading: boolean;
  bucketLoading: boolean;
}

export const getPaymentRiskbase = createAsyncThunk(
  'performanceDashboard/getPaymentRiskbase',
  async (request: Partial<PaymentBucketPerformanceRequest>) => {
    const response = await LoanService.getPaymentPerformance(request);
    return response;
  }
);
export const getPaymentBucketRiskbase = createAsyncThunk(
  'performanceDashboard/getPaymentBucketRiskbase',
  async (request: Partial<PaymentBucketPerformanceRequest>) => {
    const response = await LoanService.getPaymentBucketPerformance(request);
    return response;
  }
);

const initialPayment = {
  stabilised: {
    name: '',
    oneToThirty: '',
    thirtyOneToSixty: '',
    sixtyOneToNinety: '',
    npaMinus: '',
    npaPlus: '',
    total: '',
    loanList: [],
  },
  rollForward: {
    name: '',
    oneToThirty: '',
    thirtyOneToSixty: '',
    sixtyOneToNinety: '',
    npaMinus: '',
    npaPlus: '',
    total: '',
    loanList: [],
  },
  rollBackData: {
    name: '',
    oneToThirty: '',
    thirtyOneToSixty: '',
    sixtyOneToNinety: '',
    npaMinus: '',
    npaPlus: '',
    total: '',
    loanList: [],
  },
  normalizedData: {
    name: '',
    oneToThirty: '',
    thirtyOneToSixty: '',
    sixtyOneToNinety: '',
    npaMinus: '',
    npaPlus: '',
    total: '',
    loanList: [],
  },
};
// const initialPayment = [
//   {
//     id: 'NORMALISED',
//     bucketType: [],
//   },
//   {
//     id: 'ROLL_BACK',
//     bucketType: [],
//   },
//   {
//     id: 'ROLL_FORWARD',
//     bucketType: [],
//   },
//   {
//     id: 'STABILISED',
//     bucketType: [],
//   },
// ];
const initialBucket = [
  {
    id: 'X',
    bucketType: [],
  },
  {
    id: 'Normal',
    bucketType: [],
  },
  {
    id: '1 to 30',
    bucketType: [],
  },
  {
    id: '31 to 60',
    bucketType: [],
  },
  {
    id: '61 to 90',
    bucketType: [],
  },
  {
    id: 'NPA',
    bucketType: [],
  },
];

const initialState: PerformanceDashboardSliceProps = {
  filterData: {},
  filterRiskBasePerformance: {},
  payments: initialPayment,
  buckets: [],
  paymentError: null,
  bucketError: null,
  paymentLoading: false,
  bucketLoading: false,
};

function mapResponseToPayment(response: any, name: string) {
  const row: Partial<PaymentPerformance> = {
    name: name,
  };
  if (response) {
    response.map(i => {
      const key = Object.keys(i).at(0);
      const value = Object.values(i).at(0);
      if (key == '1 to 30') {
        row.oneToThirty = value.toString();
      } else if (key == '31 to 60') {
        row.thirtyOneToSixty = value.toString();
      } else if (key == '61 to 90') {
        row.sixtyOneToNinety = value.toString();
      } else if (key == 'NPA_240_MINUS') {
        row.npaMinus = value.toString();
      } else if (key == 'NPA_240_PLUS') {
        row.npaPlus = value.toString();
      } else if (key == 'TOTAL') {
        row.total = value.toString();
      }
    });
  }

  return row;
}

const performanceDashboardSlice = createSlice({
  name: 'performanceDashboard',
  initialState,
  reducers: {
    setFilterData: (state: PerformanceDashboardSliceProps, action) => {
      const { payload } = action;
      state.filterData = payload;
    },
    setFilterRiskBasePerformance: (
      state: PerformanceDashboardSliceProps,
      action
    ) => {
      const { payload } = action;
      state.filterRiskBasePerformance = payload;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getPaymentRiskbase.pending, state => {
        state.paymentLoading = true;
        state.payments = initialPayment;
        state.paymentError = null;
      })
      .addCase(getPaymentRiskbase.fulfilled, (state, action) => {
        const payload = action.payload;
        const stablisedData = mapResponseToPayment(
          payload?.STABILISED?.bucketWise,
          'STABILISED'
        );
        const rollForwardData = mapResponseToPayment(
          payload?.ROLL_FORWARD?.bucketWise,
          'ROLL_FORWARD'
        );
        const rollBackData = mapResponseToPayment(
          payload?.ROLL_BACK?.bucketWise,
          'ROLL_BACK'
        );
        const normalizedData = mapResponseToPayment(
          payload?.NORMALISED?.bucketWise,
          'NORMALISED'
        );
        const updatedPayments = {
          stabilised: {
            ...stablisedData,
            loanList: payload?.STABILISED?.loanIdList ?? [],
            name: stablisedData.name ?? '',
          },
          rollForward: {
            ...rollForwardData,
            loanList: payload?.ROLL_FORWARD?.loanIdList ?? [],
            name: rollForwardData.name ?? '',
          },
          rollBackData: {
            ...rollBackData,
            loanList: payload?.ROLL_BACK?.loanIdList ?? [],
            name: rollBackData.name ?? '',
          },
          normalizedData: {
            ...normalizedData,
            loanList: payload?.NORMALISED?.loanIdList ?? [],
            name: normalizedData.name ?? '',
          },
        };

        state.payments = updatedPayments;
        state.paymentLoading = false;
      })
      .addCase(getPaymentRiskbase.rejected, (state, action) => {
        state.paymentLoading = false;
        state.payments = initialPayment;
        state.paymentError = action.error.message || 'Failed to create bidder';
      })
      .addCase(getPaymentBucketRiskbase.pending, state => {
        state.bucketLoading = true;
        state.buckets = [];
        state.bucketError = null;
      })
      .addCase(getPaymentBucketRiskbase.fulfilled, (state, action) => {
        const paymentDto = action.payload;
        if (Object.keys(paymentDto).length) {
          const modifyPayment = Object.keys(paymentDto).map(
            value =>
              ({ id: value, bucketType: paymentDto[value] } as PaymentDto)
          );
          const updatedBuckets = initialBucket.map(value => {
            const isValue = getPaymentObj(value.id, modifyPayment);
            if (isValue) {
              return {
                id: value.id,
                bucketType: paymentDto[value.id],
              } as PaymentDto;
            } else {
              return { id: value.id, bucketType: [] } as PaymentDto;
            }
          });
          state.buckets = updatedBuckets;
        } else {
          state.buckets = initialBucket;
        }
        state.bucketLoading = false;
      })
      .addCase(getPaymentBucketRiskbase.rejected, (state, action) => {
        state.bucketLoading = false;
        state.buckets = [];
        state.bucketError = action.error.message || 'Failed to create bidder';
      });
  },
});
export const { setFilterData, setFilterRiskBasePerformance } =
  performanceDashboardSlice.actions;
export const getPerformanceDashboard = (state: RootState) =>
  state.performanceDashboard;
export default performanceDashboardSlice;

const getPaymentObj = (value: string, payments: PaymentDto[]) => {
  const isPayment = payments.some(initialValue => initialValue.id === value);
  return isPayment;
};
