import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { getEscalationsState } from '../../../features/escalationsSlice';
import { FilterProps } from '../../../pages/Escalations/Escalations';
import { useAppSelector } from '../../../store';
import { DateUtility } from '../../../utils/DateUtlility';
import VegaButton from '../../common/VegaButton';
import VegaDatePicker from '../../common/VegaDatePicker';
import VegaDrawer from '../../common/VegaDrawer';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: () => void;
  onClear: () => void;
  formData: FilterProps;
  setFormData: React.Dispatch<React.SetStateAction<FilterProps>>;
}

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const EscalationFilterDrawer = ({
  open,
  onClose,
  onFilter,
  onClear,
  formData,
  setFormData,
}: IProps) => {
  const { loading } = useAppSelector(getEscalationsState);

  const handleDateChange = (key: string, date: any) => {
    const dateToString = DateUtility.parseDateFromDatePicker(date);

    if (key === 'fromDate') {
      const toDate = DateUtility.parseStringToDate(formData.toDate);
      if (toDate && dateToString) {
        if (DateUtility.isAfter(toDate, dateToString)) {
          setFormData({
            fromDate: dateToString?.toISOString(),
            toDate: DateUtility.addDays(dateToString, 1)?.toISOString(),
          });
        }
      }
    } else {
      setFormData({
        ...formData,
        [key]: dateToString?.toISOString(),
      });
    }
  };

  useEffect(() => {
    if (open) {
      setFormData({
        fromDate: formData.fromDate.length
          ? formData.fromDate
          : TODAY.toISOString(),
        toDate: formData.toDate.length
          ? formData.toDate
          : TOMORROW.toISOString(),
      });
    }
  }, [open]);

  const disabled = !formData.fromDate.length || !formData.toDate.length;
  return (
    <>
      <VegaDrawer open={open} onClose={onClose} title="Create Deposit">
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Clear All"
                  onClick={onClear}
                  variant="text"
                  sx={{
                    color: '#1047DC',
                  }}
                />
                <VegaButton
                  text="Filter"
                  onClick={onFilter}
                  loading={loading}
                  disabled={disabled}
                />
              </Stack>
            );
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <VegaFormInputField label={'Start Date'} isMandatory>
                <VegaDatePicker
                  value={formData.fromDate ?? ''}
                  onChange={date => handleDateChange('fromDate', date)}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'End Date'} isMandatory>
                <VegaDatePicker
                  value={formData.toDate ?? ''}
                  onChange={date => handleDateChange('toDate', date)}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default EscalationFilterDrawer;
