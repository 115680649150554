import { BoxProps, styled } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { DeleteIcon, FileUploadIcon } from '../Icons/Icons';
import VegaText from './VegaText';

export enum AcceptedExtension {
  PNG = '.png',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PDF = '.pdf',
  DOCX = '.docx',
  GIF = '.gif',
  BMP = '.bmp',
  TIFF = '.tiff',
  TXT = '.txt',
  CSV = '.csv',
  XLSX = '.xlsx',
  PPTX = '.pptx',
  MP3 = '.mp3',
  MP4 = '.mp4',
}

export type VegaFileSelectOption = {
  id: string;
  label?: string;
  value?: File;
};

interface LosFileSelectProps {
  acceptedExtension?: AcceptedExtension[];
  placeholder: string;
  onFileSelect?: (file: VegaFileSelectOption) => void;
  onDeleteClick?: () => void;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  disabled?: boolean;
  // value?: File;
  value?: VegaFileSelectOption;
  onFileInputRef?: (ref: any) => void;
  onFileDownloadClick?: (file: VegaFileSelectOption) => void;
  downloadable?: boolean;
}

interface StyledSelectFieldProps extends BoxProps {
  isFileSelected?: boolean;
}

const StyledSelectField = styled(Box)<StyledSelectFieldProps>(
  ({ isFileSelected = false }) => {
    return {
      border: '1px dashed rgba(16, 71, 220, 1)',
      borderRadius: '0.375rem',
      padding: '0.5rem 1rem 0.5rem 0.75rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      maxHeight: '40px',
      minHeight: '40px',
      textOverflow: 'ellipsis',
      cursour: 'pointer',
      backgroundColor: 'rgba(247, 247, 247, 1)',
      ...(isFileSelected == true && {
        backgroundColor: 'rgba(231, 237, 252, 1)',
      }),
    };
  }
);

function VegaFileSelect({
  onFileSelect,
  endIcon,
  placeholder,
  acceptedExtension,
  startIcon,
  disabled = false,
  onDeleteClick,
  value,
  onFileInputRef,
  onFileDownloadClick,
  downloadable = false,
}: LosFileSelectProps) {
  const [filename, setFilename] = useState('');
  const inputId = `file-input-${Math.random().toString(36).substring(7)}`;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: any) => {
    if (disabled == true) return;
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect?.({ label: file.name, value: file, id: file.name });
    }
  };

  const handleEndIconClick = () => {
    if (filename.length > 0) {
      onDeleteClick?.();
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getEndIcon = () => {
    if (endIcon) return endIcon;
    if (filename.length > 0) return <DeleteIcon />;
    return <FileUploadIcon />;
  };

  const getAcceptedExtensions = () => {
    if (acceptedExtension) {
      return acceptedExtension.join(',');
    }
    return Object.values(AcceptedExtension).join(',');
  };

  useEffect(() => {
    if (value?.label) {
      setFilename(value.label);
    } else {
      setFilename('');
    }
  }, [value]);

  return (
    <StyledSelectField isFileSelected={filename.length > 0}>
      <label
        htmlFor={inputId}
        style={{
          flex: 1,
          fontFamily: 'Aspekta !important',
          textOverflow: 'ellipsis',
          msTextOverflow: 'ellipsis',
          overflow: 'hidden',
          maxHeight: '40px',
          minHeight: '40px',
          cursor: 'pointer',
        }}
      >
        <div
          onClick={() => {
            if (downloadable == true) {
              onFileDownloadClick(value);
            }
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            maxHeight: '40px',
            minHeight: '40px',
            width: '100%',
            height: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {startIcon && (
            <div
              style={{
                padding: '4px',
                height: '32px',
                width: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {startIcon}
            </div>
          )}
          <VegaText
            text={filename || placeholder}
            fontSize={'0.8125rem'}
            lineHeight={'120%'}
            textOverflow="ellipsis"
            overflow="hidden"
            sx={{
              height: '100%',
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color:
                filename.length > 0
                  ? 'rgba(103, 107, 118, 1)'
                  : 'rgba(16, 71, 220, 1)',
            }}
          />
        </div>
      </label>
      <div onClick={handleEndIconClick}>{getEndIcon()}</div>
      <input
        id={inputId}
        // ref={fileInputRef}
        ref={el => {
          fileInputRef.current = el;
          onFileInputRef?.(el); // Set the ref in the parent component
        }}
        type="file"
        disabled={disabled || downloadable}
        accept={getAcceptedExtensions()}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </StyledSelectField>
  );
}

export default VegaFileSelect;
