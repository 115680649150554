import { Collapse, Grid, Skeleton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentivePolicyProps,
  IncentivePolicyType,
} from '../../../types/incentives';
import { DateUtility } from '../../../utils/DateUtlility';
import { RupeeIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaDatePicker from '../../common/VegaDatePicker';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaSelectWithCheckbox from '../../common/VegaSelectWithCheckbox';
import VegaText from '../../common/VegaText';
import AccountTypeSelect from './AccountTypeSelect';
import CaseTypeSelect from './CaseTypeSelect';
import UserTypeSelector from './UserTypeSelector';

interface IProps {
  loading: boolean;
}

enum PolicyDetailsVerticalType {
  CGCL = 'CGCL',
  CGHFL = 'CGHFL',
  CGCL_AND_CGHFL = 'CGCL_CGHFL',
}

const TODAY = new Date();

const PolicyDetailsForm = ({ loading }: IProps) => {
  const { policyId, policyType } = useParams();

  const { policyDetailsState, policyEnumValues, countError } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();

  const handlePolicyChange = (key: keyof IncentivePolicyProps, value: any) => {
    const policyDetails = {
      ...policyDetailsState,
      [key]: value,
    } as IncentivePolicyProps;
    dispatch(setPolicyDetailsState(policyDetails));
  };

  const handleDateChange = (key: keyof IncentivePolicyProps, date: any) => {
    const dateToString =
      DateUtility.parseDateFromDatePicker(date)?.toISOString();
    handlePolicyChange(key, DateUtility.formatStringToYYYYMMDD(dateToString));
  };

  const filterderSplitUsers = (user: string) => {
    const splitPayoutForUsersMap = policyDetailsState?.splitPayoutForUsersMap;
    if (splitPayoutForUsersMap) {
      const updatedUsers = {
        ...splitPayoutForUsersMap,
        [user]: 0,
      };
      return updatedUsers;
    } else {
      const updatedUsers = {
        [user]: 0,
      };
      return updatedUsers;
    }
  };

  const filterderSplitUsersObj = (userType: string[]) => {
    const splitPayoutForUsersMap = policyDetailsState?.splitPayoutForUsersMap;
    const filteredObject = userType.reduce((acc, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (splitPayoutForUsersMap?.hasOwnProperty(key)) {
        acc[key] = splitPayoutForUsersMap[key];
      }
      return acc;
    }, {});
    return filteredObject;
  };

  const onUserSelect = (user: string) => {
    const userType = policyDetailsState?.userType ?? [];
    if (userType?.includes(user)) {
      const filteredUser = userType.filter(
        (selectedUser: string) => selectedUser !== user
      );
      const policyDetails = {
        ...policyDetailsState,
        userType: filteredUser,
        splitPayoutForUsersMap: filterderSplitUsers(user),
      } as IncentivePolicyProps;
      dispatch(setPolicyDetailsState(policyDetails));
    } else {
      const policyDetails = {
        ...policyDetailsState,
        userType: [...userType, user],
        splitPayoutForUsersMap: filterderSplitUsers(user),
      } as IncentivePolicyProps;
      dispatch(setPolicyDetailsState(policyDetails));
    }
  };
  const onUserDelete = (index: number) => {
    const userType = policyDetailsState?.userType ?? [];
    const users = [...userType];
    users.splice(index, 1);
    const policyDetails = {
      ...policyDetailsState,
      userType: users,
      splitPayoutForUsersMap: filterderSplitUsersObj(users),
    } as IncentivePolicyProps;
    dispatch(setPolicyDetailsState(policyDetails));
  };
  const onCommunicationTypeSelect = (user: string) => {
    const modeOfCommunication = policyDetailsState?.modeOfCommunication ?? [];
    if (modeOfCommunication?.includes(user)) {
      const filteredCommunication = modeOfCommunication.filter(
        (selectedUser: string) => selectedUser !== user
      );

      handlePolicyChange('modeOfCommunication', filteredCommunication);
    } else {
      handlePolicyChange('modeOfCommunication', [...modeOfCommunication, user]);
    }
  };
  const onCommunicationTypeDelete = (index: number) => {
    const modeOfCommunication = policyDetailsState?.modeOfCommunication ?? [];
    const communications = [...modeOfCommunication];
    communications.splice(index);
    handlePolicyChange('modeOfCommunication', communications);
  };

  const handleChange = (user: string, value: number) => {
    const splitPayoutForUsersMap = policyDetailsState?.splitPayoutForUsersMap
      ? { ...policyDetailsState?.splitPayoutForUsersMap }
      : {};
    splitPayoutForUsersMap[user] = value;
    handlePolicyChange('splitPayoutForUsersMap', splitPayoutForUsersMap);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'policy name'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.policyName),
                loading
              )
            ) : (
              <LmTextField
                value={policyDetailsState?.policyName}
                onChange={e => {
                  const value = e.target.value;
                  handlePolicyChange('policyName', value);
                }}
                placeholder="Enter policy name"
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'policy type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.policyType)),
                loading
              )
            ) : (
              <VegaSelect
                options={getOptionFromEnum(policyEnumValues?.POLICY_TYPE)}
                value={policyType}
                // onChange={selected => {
                //   handlePolicyChange(
                //     'policyType',
                //     selected.target.value as string
                //   );
                // }}
                disabled
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'vertical'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.vertical),
                loading
              )
            ) : (
              <VegaSelect
                placeholder="Select vertical"
                options={verticalOption()}
                value={policyDetailsState?.vertical}
                onChange={selected => {
                  handlePolicyChange(
                    'vertical',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'channel type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.channelType)),
                loading
              )
            ) : (
              <VegaSelect
                placeholder="Select channel type"
                options={getOptionFromEnum(policyEnumValues?.CHANNEL_TYPE)}
                value={policyDetailsState?.channelType}
                onChange={selected => {
                  handlePolicyChange(
                    'channelType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'incentive type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.incentiveType)),
                loading
              )
            ) : (
              <VegaSelect
                placeholder="Select incentive type"
                options={getOptionFromEnum(policyEnumValues?.INCENTIVE_TYPE)}
                value={policyDetailsState?.incentiveType}
                onChange={selected => {
                  handlePolicyChange(
                    'incentiveType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Start Date'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.policyStartDate),
                loading
              )
            ) : (
              <VegaDatePicker
                value={policyDetailsState.policyStartDate}
                onChange={date => handleDateChange('policyStartDate', date)}
                disabled={!!policyId}
                minDate={TODAY}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'End Date'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.policyEndDate),
                loading
              )
            ) : (
              <VegaDatePicker
                value={policyDetailsState.policyEndDate}
                onChange={date => handleDateChange('policyEndDate', date)}
                disabled={!!policyId}
                minDate={DateUtility.addDays(
                  new Date(policyDetailsState.policyStartDate),
                  1
                )}
              />
            )}
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Payout Frequency'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.payoutFrequency)),
                loading
              )
            ) : (
              <VegaSelect
                placeholder="Select payout frequency"
                options={getOptionFromEnum(policyEnumValues?.PAYOUT_FREQUENCY)}
                value={policyDetailsState?.payoutFrequency}
                onChange={selected => {
                  handlePolicyChange(
                    'payoutFrequency',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Max Payout'}>
            {policyId ? (
              getTextLoadingWrapper(
                `₹ ${getPolicyValue(policyDetailsState.maxPayoutPerPerson)}`,
                loading
              )
            ) : (
              <LmTextField
                type="number"
                startAdornment={<RupeeIcon />}
                value={policyDetailsState?.maxPayoutPerPerson}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handlePolicyChange('maxPayoutPerPerson', value);
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'account type'}>
            {policyId ? (
              <>
                {policyDetailsState?.accountType
                  ? getTextLoadingWrapper(
                      getPolicyValue(
                        policyDetailsState?.accountType?.join(', ')
                      ),
                      loading
                    )
                  : '--'}
              </>
            ) : (
              <AccountTypeSelect />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'user type'}>
            {policyId ? (
              <>
                {policyDetailsState?.userType
                  ? getTextLoadingWrapper(
                      getPolicyValue(policyDetailsState?.userType?.join(', ')),
                      loading
                    )
                  : '--'}
              </>
            ) : (
              <UserTypeSelector
                placeholder="Select User type"
                onSelect={selected => onUserSelect(selected)}
                onDelete={selectedIndex => onUserDelete(selectedIndex)}
                selected={policyDetailsState.userType}
                disabled={policyType === IncentivePolicyType.NEW_POLICY}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'case type'}>
            {policyId ? (
              <>
                {policyDetailsState?.caseType
                  ? getTextLoadingWrapper(
                      getPolicyValue(policyDetailsState?.caseType?.join(', ')),
                      loading
                    )
                  : '--'}
              </>
            ) : (
              <CaseTypeSelect />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Class Type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState?.classType)),
                loading
              )
            ) : (
              <VegaSelect
                placeholder="Select class type"
                options={getOptionFromEnum(policyEnumValues?.CLASS_TYPES)}
                value={policyDetailsState?.classType}
                onChange={selected => {
                  handlePolicyChange(
                    'classType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        {policyDetailsState?.classType &&
          policyDetailsState?.classType === 'NPA' && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Collapse in={policyDetailsState?.classType === 'NPA'}>
                <VegaFormInputField label={'NPA Type'}>
                  {policyId ? (
                    getTextLoadingWrapper(
                      toLowerCase(getPolicyValue(policyDetailsState?.npaType)),
                      loading
                    )
                  ) : (
                    <VegaSelect
                      placeholder="Select NPA type"
                      options={getOptionFromEnum([
                        'NCM_DPD_240_MINUS',
                        'NCM_DPD_240_PLUS',
                      ])}
                      value={policyDetailsState?.npaType}
                      onChange={selected => {
                        handlePolicyChange(
                          'npaType',
                          selected.target.value as string
                        );
                      }}
                    />
                  )}
                </VegaFormInputField>
              </Collapse>
            </Grid>
          )}

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Mode of Communication'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(
                  getPolicyValue(
                    policyDetailsState?.modeOfCommunication?.join(', ')
                  )
                ),
                loading
              )
            ) : (
              <VegaSelectWithCheckbox
                options={getOptionFromEnum(
                  policyEnumValues?.MODE_OF_COMMUNICATION
                )}
                onSelect={selected => onCommunicationTypeSelect(selected)}
                handleDelete={selectedIndex =>
                  onCommunicationTypeDelete(selectedIndex)
                }
                selected={policyDetailsState?.modeOfCommunication}
                placeHolder="Select Communication"
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Collapse in={policyDetailsState?.userType?.length > 0}>
            <Stack
              gap={1}
              sx={{
                borderRadius: '12px',
                p: 2,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                mb: 1,
              }}
            >
              {Boolean(countError.length) && (
                <VegaText
                  text={countError}
                  fontWeight={500}
                  fontSize={14}
                  sx={{
                    color: 'red',
                  }}
                />
              )}
              <Grid container spacing={2}>
                {Boolean(policyDetailsState?.userType.length) &&
                  policyDetailsState?.userType.map((user, index) => {
                    return (
                      <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                        <VegaFormInputField label={`split for ${user && user}`}>
                          {policyId ? (
                            getTextLoadingWrapper(
                              policyDetailsState?.splitPayoutForUsersMap
                                ? getPolicyValue(
                                    policyDetailsState?.splitPayoutForUsersMap[
                                      user
                                    ]
                                  )
                                : 'NA',
                              loading
                            )
                          ) : (
                            <LmTextField
                              type="number"
                              value={
                                policyDetailsState?.splitPayoutForUsersMap
                                  ? policyDetailsState?.splitPayoutForUsersMap[
                                      user
                                    ]
                                  : 0
                              }
                              onChange={e => {
                                let value =
                                  e.target.value && parseFloat(e.target.value);
                                if (value > 100) {
                                  value = 100;
                                }
                                handleChange(user, value);
                              }}
                            />
                          )}
                        </VegaFormInputField>
                      </Grid>
                    );
                  })}
              </Grid>
            </Stack>

            {/* <VegaFormInputField label={'split for users'}>
              {policyId ? (
                getTextLoadingWrapper(
                  getPolicyValue(
                    policyDetailsState?.splitPayoutForUsers?.join(', ')
                  ),
                  loading
                )
              ) : (
                <SplitForUsersSelect />
              )}
            </VegaFormInputField> */}
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default PolicyDetailsForm;

const getPolicyValue = (policyValue: string | number) => {
  const value =
    typeof policyValue === 'number' ? policyValue.toString() : policyValue;
  if (!value || !value?.length) return '--';
  if (value?.length) {
    return value;
  } else {
    return '--';
  }
};

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};

const verticalOption = () => {
  const options: VegaSelectOption[] = Object.entries(
    PolicyDetailsVerticalType
  ).map(([key, value]) => ({
    value: value,
    label: toLowerCase(key),
  }));
  return options;
};

const getOptionFromEnum = (enumType: string[]) => {
  if (enumType) {
    const options: VegaSelectOption[] = enumType?.map((item: string) => ({
      value: item,
      label: item,
    }));
    return options;
  }
};
