import { Grid, Stack, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../Apis/ReceiptManagmentService';
import { setSendPaymentLinkState } from '../../features/accountsSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { LoanData } from '../../types/loan';
import { SendPaymentLinkRequest } from '../../types/request/receiptManagment';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { RupeeIcon } from '../Icons/Icons';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import { IActionProps } from './AccountActionContainer';
import {
  PaymentStatus,
  getPaymentStatus,
  receiptState,
  setLoanId,
  setOrderId,
} from '../../features/receiptManagmentSlice';
import { COLOR } from '../../utils/ColorUtility';
import { VegaPill } from '../common/VegaPill';
import { useParams } from 'react-router-dom';
import { AnimatedInputWrapper } from '../ExpenseClaim/CreateClaimForm';
import { ReceiptAmountBreakUpDto } from '../../types/receiptManagment';
import {
  isReceiptAmountBreakUpDto,
  totalAmountDto,
} from '../../pages/ReceiptManagment/ReceiptValidation';

interface IProps extends IActionProps {
  loanData: LoanData[];
}

interface IErrorProps {
  mobileNumber: string;
}

interface IErrorStateProps {
  error: IErrorProps;
  isValid: boolean;
}

const SendPaymentLinkFormDrawer = ({ open, setOpen, loanData }: IProps) => {
  const { loanAccountId } = useParams();
  const {
    paymentStatusError,
    orderId,
    paymentStatusData,
    loanId: localLoanId,
  } = useAppSelector(receiptState);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [amountDetails, setAmountDetails] = useState<ReceiptAmountBreakUpDto>({
    overDueAmount: 0,
    bounceCharges: 0,
    penalCharges: 0,
    legalCharges: 0,
    otherCharges: 0,
  });
  const [sendPaymentState, setSendPaymentState] = useState<{
    mobileNumber: string;
    email: string;
  }>({
    mobileNumber: '',
    email: '',
  });
  const [errorState, setErrorState] = useState<IErrorStateProps>({
    error: {
      mobileNumber: '',
    },
    isValid: false,
  });

  const { userId } = useClientAuth();

  const onClose = () => {
    setOpen(false);
    dispatch(setSendPaymentLinkState(null));
  };

  const loanId = loanData[0]?.loanId;

  const payeeName =
    (loanData[0]?.customerDetails &&
      loanData[0]?.customerDetails[0]?.customerName) ??
    '-';
  const relationshipWithCustomer = 'SELF';

  const handleChange = (key: keyof ReceiptAmountBreakUpDto, value: number) => {
    setAmountDetails({ ...amountDetails, [key]: value });
  };

  const amount = totalAmountDto(amountDetails);

  const onSendLink = () => {
    const sendPaymentLinkState = {
      agentId: userId,
      amount,
      loanId,
      mobileNumber: sendPaymentState.mobileNumber,
      payeeName,
      relationshipWithCustomer,
      overDueAmount: amountDetails.overDueAmount,
      bounceCharges: amountDetails.bounceCharges,
      penalCharges: amountDetails.penalCharges,
      legalCharges: amountDetails.legalCharges,
      otherCharges: amountDetails.otherCharges,
    } as SendPaymentLinkRequest;
    if (sendPaymentState.email.length) {
      sendPaymentLinkState.email = sendPaymentState.email;
    }
    const errorObj = isValidField(sendPaymentLinkState);
    setErrorState(errorObj);
    if (errorObj.isValid) {
      ReceiptManagmentService.createSendPaymentLink(sendPaymentLinkState)
        .then(response => {
          dispatch(setOrderId(response.orderId));
          dispatch(setLoanId(response.loanId));
          setSnackbar('Send payment link successfully !!!');
          dispatch(getPaymentStatus(response.orderId));
          // onClose();
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
          onClose();
        });
    }
  };

  const isReceiptAmountBreakUp = isReceiptAmountBreakUpDto(amountDetails);

  useEffect(() => {
    if (loanData[0]?.customerDetails) {
      const mobileNo = loanData[0]?.customerDetails[0]?.mobileNo ?? '';
      const email = loanData[0]?.customerDetails[0]?.email ?? '';
      setSendPaymentState({ ...sendPaymentState, mobileNumber: mobileNo });
      setSendPaymentState({ ...sendPaymentState, email: email });
    }
  }, [loanData]);

  function isSubmitButtonDisabled() {
    return (
      isReceiptAmountBreakUp ||
      !sendPaymentState.mobileNumber.length ||
      !sendPaymentState.email.length ||
      !(amount >= 1) ||
      !(sendPaymentState.mobileNumber.length === 10) ||
      (paymentStatusData === PaymentStatus.PENDING &&
        loanAccountId === localLoanId)
    );
  }

  useEffect(() => {
    if (loanAccountId === localLoanId) {
      orderId.length && dispatch(getPaymentStatus(orderId));
    }
  }, [dispatch]);

  useEffect(() => {
    paymentStatusError &&
      setSnackbar(getErrorMessageFromErrorObj(paymentStatusError), 'error');
  }, [paymentStatusError]);

  return (
    <>
      <VegaDrawer open={open} title={'Send Payment Link'} onClose={onClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Send Link"
                  disabled={isSubmitButtonDisabled()}
                  onClick={onSendLink}
                />
              </Stack>
            );
          }}
        >
          <Grid container spacing={'1.25rem'}>
            <Grid item xs={12}>
              <VegaFormInputField label={'overDue Amount'}>
                <LmTextField
                  type="number"
                  placeholder="Enter OverDue Amount"
                  value={amountDetails.overDueAmount}
                  startAdornment={<RupeeIcon />}
                  onChange={e => {
                    const value = e.target.value
                      ? parseFloat(e.target.value)
                      : null;
                    handleChange('overDueAmount', value);
                  }}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>

            <Grid item xs={12}>
              <VegaFormInputField label={'bounce Charges'}>
                <LmTextField
                  type="number"
                  placeholder="Enter Bounce Charges"
                  value={amountDetails.bounceCharges}
                  startAdornment={<RupeeIcon />}
                  onChange={e => {
                    const value = e.target.value
                      ? parseFloat(e.target.value)
                      : null;
                    handleChange('bounceCharges', value);
                  }}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'penal Charges'}>
                <LmTextField
                  type="number"
                  placeholder="Enter Penal Charges"
                  value={amountDetails.penalCharges}
                  startAdornment={<RupeeIcon />}
                  onChange={e => {
                    const value = e.target.value
                      ? parseFloat(e.target.value)
                      : null;
                    handleChange('penalCharges', value);
                  }}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'Legal Charges'}>
                <LmTextField
                  type="number"
                  placeholder="Enter Legal Charges"
                  value={amountDetails.legalCharges}
                  startAdornment={<RupeeIcon />}
                  onChange={e => {
                    const value = e.target.value
                      ? parseFloat(e.target.value)
                      : null;
                    handleChange('legalCharges', value);
                  }}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'Other Charges'}>
                <LmTextField
                  type="number"
                  placeholder="Enter Other Charges"
                  value={amountDetails.otherCharges}
                  startAdornment={<RupeeIcon />}
                  onChange={e => {
                    const value = e.target.value
                      ? parseFloat(e.target.value)
                      : null;
                    handleChange('otherCharges', value);
                  }}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'Amount'}>
                <LmTextField
                  type="number"
                  placeholder="Enter Amount"
                  value={amount}
                  startAdornment={<RupeeIcon />}
                  disabled
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'email'} isMandatory>
                <LmTextField
                  placeholder="Enter Email"
                  value={sendPaymentState?.email ?? ''}
                  onChange={e => {
                    const value = e.target.value;
                    setSendPaymentState({ ...sendPaymentState, email: value });
                  }}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'contact number'} isMandatory>
                <LmTextField
                  type="number"
                  placeholder="Enter Contact Number"
                  value={sendPaymentState?.mobileNumber ?? ''}
                  onChange={e => {
                    const value = e.target.value;
                    setSendPaymentState({
                      ...sendPaymentState,
                      mobileNumber: value,
                    });
                  }}
                  error={errorState.error.mobileNumber?.length > 0}
                  helperText={errorState.error.mobileNumber}
                  disabled={
                    paymentStatusData === PaymentStatus.PENDING &&
                    loanAccountId === localLoanId
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'loan account number'}>
                <LmTextField
                  placeholder="Enter Contact Number"
                  value={loanId}
                  disabled
                />
              </VegaFormInputField>
            </Grid>
            <AnimatedInputWrapper
              show={paymentStatusData?.length && loanAccountId === localLoanId}
              marginTop="0rem"
            >
              <Box
                width={'100%'}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <VegaPill
                  text={paymentStatusData}
                  backgroundColor={getColorForStatus(paymentStatusData)?.LIGHT}
                  textColor={getColorForStatus(paymentStatusData)?.DARK}
                />
              </Box>
            </AnimatedInputWrapper>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default SendPaymentLinkFormDrawer;

const isValidField = (formData: Partial<SendPaymentLinkRequest>) => {
  const errorObj = {
    error: {},
    isValid: true,
  } as IErrorStateProps;
  const alternateContactString =
    typeof formData.mobileNumber === 'number'
      ? formData.mobileNumber.toString()
      : formData.mobileNumber;
  const isValidNumber = StringUtility.validatePhoneNumber(
    alternateContactString
  );

  if (!isValidNumber) {
    errorObj.isValid = false;
    errorObj.error['mobileNumber'] = 'Mobile number should be 10 digits';
  }
  return errorObj;
};

const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case PaymentStatus.SUCCESS:
      return COLOR.GREEN;

    case PaymentStatus.FAILED:
      return COLOR.RED;
    case PaymentStatus.PENDING:
      return COLOR.ORANGE;
  }
};
