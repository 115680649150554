import { Box, Divider, Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrailService } from '../../Apis/TrailService';
import { GREY, PRIMARY } from '../../constants/style';
import { setOpenContactDetailsDrawer } from '../../features/teleAgentSlice';
import { VisitRequestSelector } from '../../features/visitSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { CustomerDetail, LoanData } from '../../types/loan';
import {
  ContactStatus,
  CustomerInteraction,
  DispositionCode,
  DispositionType,
  NonPaymentReason,
  TrailNextAction,
  VisitLocationTagType,
  dispositionCodeMap,
  dispositionTypeForNonContactedCases,
  getEnumValueFromString,
} from '../../types/trail';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { AnimatedInputWrapper } from '../ExpenseClaim/CreateClaimForm';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import LmDialog from '../common/LmDialog';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../common/VegaFileSelect';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import VegaText from '../common/VegaText';
import { GeoVerificationService } from '../../Apis/GeoVerificationService';
import { VisitService } from '../../Apis/VisitService';

enum PtpErrorType {
  nextAction_Date_should_be_before_2_days = 'nextAction Date should be before 2 days',
  nextAction_date_is_after_5_days_add_reason_for_that = 'nextAction date is after 5 days add reason for that',
}

export type CreateTrailFormData = {
  loanId: string;
  agentId: string;
  visitId: string;
  customerName: string;
  primaryContact: string;
  alternateContact: number | string;
  date: string;
  contactStatus: string;
  dispositionType: string;
  dispositionCode: string;
  nextActionDate: string;
  nextAction: string;
  nextActionRemarks: string;
  actionComment: string;
  dispositionCodeRemarks: string;
  otherNonPaymentReason: string;
  locationTagType: string;
  customerImageFile: VegaFileSelectOption;
  visitReason: string;
  nonPaymentReason: string;
  amountCollected: number;
  latitude: string;
  agentRemarksForNonGeoVisit: string;
  longitude: string;
  customerInteraction: string;
  agentRemarks: string;
  trackVisit: string;
};

interface IErrorProps {
  alternateContact: string;
}

interface IErrorStateProps {
  error: IErrorProps;
  isValid: boolean;
}

enum InputType {
  PrimaryContact = 'primaryContact',
  AlternateContact = 'alternateContact',
  Date = 'date',
  ContactStatus = 'contactStatus',
  DispositionType = 'dispositionType',
  DispositionCode = 'dispositionCode',
  NextActionDate = 'nextActionDate',
  NextAction = 'nextAction',
  ActionComment = 'actionComment',
  OtherNonPaymentReason = 'otherNonPaymentReason',
  DispositionCodeRemarks = 'dispositionCodeRemarks',
  NextActionRemarks = 'nextActionRemarks',
  VisitType = 'locationTagType',
  CustomerImage = 'customerImageFile',
  AmountCollected = 'amountCollected',
  AgentRemarksForNonGeoVisit = 'agentRemarksForNonGeoVisit',
  latitude = 'latitude',
  longitude = 'longitude',
  NonPaymentReason = 'nonPaymentReason',
  agentRemarks = 'agentRemarks',
  CustomerInteraction = 'customerInteraction',
}
type Props = {
  open: boolean;
  onClose: () => void;
  fetchTrail?: () => void;
  loanDetail?: LoanData;
};

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

function UpdateTrailDrawer({ open, onClose, loanDetail, fetchTrail }: Props) {
  const { user } = useClientAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { visit } = useAppSelector(VisitRequestSelector);
  const dispatch = useAppDispatch();
  const { loanAccountId } = useParams();
  const [formData, setFormData] = useState<Partial<CreateTrailFormData>>({});
  const [showDispositionType, setShowDispositionType] =
    useState<boolean>(false);
  const [showDispositionCode, setShowDispositionCode] =
    useState<boolean>(false);
  const [showNextActionDate, setShowNextActionDate] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { setSnackbar } = useSnackbar();

  const [showNextAction, setShowNextAction] = useState<boolean>(false);
  const [showActionComment, setShowActionComment] = useState<boolean>(false);
  const [showVisitType, setShowVisitType] = useState<boolean>(false);
  const [showVisitReason, setShowVisitReason] = useState<boolean>(false);
  const [showCustomerImage, setShowCustomerImage] = useState<boolean>(false);
  // const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<IErrorStateProps>({
    error: {
      alternateContact: '',
    },
    isValid: false,
  });
  const [showAmountCollected, setShowAmountCollected] =
    useState<boolean>(false);
  const [showNonPaymentReason, setShowNonPaymentReason] =
    useState<boolean>(false);
  const customerDetail = getFirstItemIfExists<CustomerDetail>(
    loanDetail?.customerDetails
  );
  const {
    isOpen: isDialogOpen,
    open: openDialog,
    close: closeDialog,
    props: dialogProps,
  } = useDrawer(false);

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  async function fetchTodayVisits() {
    const visitResponse = await VisitService.getVisitList({
      loanId: loanAccountId,
      agentId: user?.id,
      visitDateOn: DateUtility.formatStringToYYYYMMDD(new Date().toISOString()),
    });
  }

  useEffect(() => {
    if (loanAccountId) {
      fetchTodayVisits();
    }
  }, [loanAccountId, open]);

  function onContactStatusChange(status) {
    delete formData.dispositionType;
    delete formData.dispositionCode;
    delete formData.nextAction;
    updateFormData(InputType.ContactStatus, status.target.value);
    setShowDispositionType(
      status.target.value == ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT
    );
    setShowDispositionCode(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowNextAction(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowNextActionDate(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowActionComment(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT
    );
    setShowCustomerImage(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowVisitType(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.THIRD_PARTY_CONTACT ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowNonPaymentReason(
      status.target.value !== ContactStatus.NON_CONTACTED &&
        formData.dispositionType === DispositionType.NON_PAYMENT
    );
  }
  function updateDispositionCode(value) {
    if (value !== DispositionCode.OTHERS) {
      delete formData.dispositionCodeRemarks;
    }
    updateFormData(InputType.DispositionCode, value);
  }
  function updateDispositionType(value) {
    if (value === DispositionType.PAYMENT) {
      delete formData.nonPaymentReason;
    }
    updateFormData(InputType.DispositionType, value);
    setShowNonPaymentReason(value == DispositionType.NON_PAYMENT);
    setShowAmountCollected(value === DispositionType.PAYMENT);
  }

  function updateNextAction(value) {
    if (value !== TrailNextAction.OTHERS) {
      delete formData.nextActionRemarks;
    }
    updateFormData(InputType.NextAction, value);
  }

  function updateVisitType(value) {
    updateFormData(InputType.VisitType, value);
    setShowVisitReason(value === VisitLocationTagType.NON_GEO_TAGGED_VISIT);
    if (value === VisitLocationTagType.GEO_TAGGED_VISIT) {
      updateFormData(InputType.latitude, '70');
      updateFormData(InputType.longitude, '71');
    }
  }
  function updateAgentRemarksForNonGeoVisit(value) {
    updateFormData(InputType.AgentRemarksForNonGeoVisit, value);
  }

  function updateAgentRemark(value: string) {
    updateFormData(InputType.agentRemarks, value);
  }

  function onFileChange(file: VegaFileSelectOption) {
    updateFormData(InputType.CustomerImage, file);
  }

  function onFileAdd(file: VegaFileSelectOption) {
    onFileChange(file);
  }
  function updateNonPaymentReason(value) {
    if (value !== NonPaymentReason.OTHERS) {
      delete formData.otherNonPaymentReason;
    }
    updateFormData(InputType.NonPaymentReason, value);
  }
  function onFileDelete() {
    updateFormData(InputType.CustomerImage, '');
  }
  function updateAmountCollected(value: string) {
    updateFormData(InputType.AmountCollected, value);
  }
  function onPrimaryContactDetailChange(value) {
    updateFormData(InputType.PrimaryContact, value);
  }
  function onAlternateContactDetailChange(value) {
    if (value.length > 10) {
      return;
    }

    updateFormData(InputType.AlternateContact, value);
  }

  const onUpdateTrailClick = async (formData: Partial<CreateTrailFormData>) => {
    try {
      setIsLoading(true);
      await TrailService.updateTrail(formData);
      const visitResponse = await VisitService.getVisitList({
        loanId: loanAccountId,
        agentId: user?.id,
        status: 'SCHEDULED',
        visitDateOn: DateUtility.formatStringToYYYYMMDD(
          new Date().toISOString()
        ),
      });
      const visits = visitResponse.records ?? [];
      if (visits.length > 0) {
        const scheduledVisit = visits[0];
        await GeoVerificationService.submitForVerification({
          visitId: scheduledVisit.visit.id,
          agentRemarks: formData.agentRemarksForNonGeoVisit,
        });
      }
      onClose();
      setFormData({});
      setShowActionComment(false);
      setShowCustomerImage(false);
      setShowDispositionCode(false);
      setShowDispositionType(false);
      setShowNextAction(false);
      setShowNextActionDate(false);
      setShowVisitReason(false);
      setShowVisitType(false);
      setError(null);
      setSnackbar('Update Trail Successfully !!!');
      if (fetchTrail) {
        fetchTrail();
      }
      closeDialog();
    } catch (error) {
      if (formData.dispositionCode === DispositionCode.PTP) {
        const errorMsg = getErrorMessageFromErrorObj(error);
        switch (errorMsg) {
          case PtpErrorType.nextAction_Date_should_be_before_2_days:
            setError(errorMsg);
            break;
          case PtpErrorType.nextAction_date_is_after_5_days_add_reason_for_that:
            openDialog({ errorMsg: errorMsg });
            break;
          default:
            break;
        }
        return;
      } else if (formData.dispositionCode === DispositionCode.REVISIT) {
        const errorMsg = getErrorMessageFromErrorObj(error);
        openDialog({ errorMsg: errorMsg });
        return;
      } else {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  function OnSumbitClick() {
    const errorObj = isValidField(formData);
    setErrorState(errorObj);
    const updatedData = { ...formData };
    updatedData.date = DateUtility.formatStringToYYYYMMDD(formData.date);
    updatedData.nextActionDate = DateUtility.formatStringToYYYYMMDD(
      formData.nextActionDate
    );
    updatedData.trackVisit = 'NON_FLOW_VISIT';
    onUpdateTrailClick(updatedData);
    // if (errorObj.isValid) {
    // }
  }
  function onDrawerClose() {
    onClose();
    setFormData({});
    setShowActionComment(false);
    setShowCustomerImage(false);
    setShowDispositionCode(false);
    setShowDispositionType(false);
    setShowNextAction(false);
    setShowNextActionDate(false);
    setShowVisitReason(false);
    setShowVisitType(false);
    setShowNonPaymentReason(false);
  }

  function isFormValid() {
    if (!formData.contactStatus) {
      return false;
    }

    if (formData.dispositionType === DispositionType.NON_PAYMENT) {
      if (!formData.nonPaymentReason) {
        return false;
      }
    }

    if (InputType.NextActionDate in formData) {
      if (!(InputType.NextAction in formData)) {
        return false;
      } else if (
        InputType.NextAction in formData &&
        formData.nextAction === TrailNextAction.OTHERS
      ) {
        if (
          !(InputType.NextActionRemarks in formData) ||
          formData?.nextActionRemarks?.trim().length === 0
        ) {
          return false;
        }
      }
    }
    if (InputType.NextAction in formData) {
      if (!(InputType.NextActionDate in formData)) {
        return false;
      } else if (formData.nextAction === TrailNextAction.OTHERS) {
        if (
          !(InputType.NextActionRemarks in formData) ||
          formData?.nextActionRemarks?.trim().length === 0
        ) {
          return false;
        }
      }
    }

    const isNonContacted =
      formData.contactStatus == ContactStatus.NON_CONTACTED;
    if (!formData.dispositionCode) {
      return false;
    }

    if (isNonContacted == false && !formData.dispositionType) {
      return false;
    }

    const dispositionCodeValue = formData.dispositionCode;
    const dispositionTypeValue = formData.dispositionType;

    if (
      dispositionCodeValue === DispositionCode.PTP ||
      dispositionCodeValue === DispositionCode.CALLBACK ||
      dispositionCodeValue === DispositionCode.REVISIT
    ) {
      if (
        formData.nextActionDate &&
        // isNextActionRemarksValid &&
        // formData.customerImageFile &&
        formData.locationTagType
      ) {
        if (
          formData.locationTagType ===
            VisitLocationTagType.NON_GEO_TAGGED_VISIT &&
          formData.visitReason
        ) {
          return true;
        }
        if (
          formData.locationTagType === VisitLocationTagType.GEO_TAGGED_VISIT
        ) {
          return true;
        }
      } else {
        return false;
      }
    }

    if (
      dispositionTypeValue === DispositionType.NON_PAYMENT &&
      dispositionCodeValue === DispositionCode.BROKEN_PTP
    ) {
      if (
        formData.nextActionDate &&
        // formData.customerImageFile &&
        formData.nonPaymentReason &&
        formData.locationTagType
      ) {
        if (formData.nonPaymentReason === NonPaymentReason.OTHERS) {
          if (
            !(InputType.OtherNonPaymentReason in formData) ||
            formData?.otherNonPaymentReason?.trim().length === 0
          ) {
            return false;
          }
        }
        if (
          formData.locationTagType ===
            VisitLocationTagType.NON_GEO_TAGGED_VISIT &&
          formData.agentRemarksForNonGeoVisit
        ) {
          return true;
        }
        if (
          formData.locationTagType === VisitLocationTagType.GEO_TAGGED_VISIT
        ) {
          return true;
        }
      } else {
        return false;
      }
    }
    if (
      dispositionTypeValue === DispositionType.NON_PAYMENT &&
      dispositionCodeValue === DispositionCode.REFUSE_TO_PAY
    ) {
      if (
        // formData.customerImageFile &&
        formData.nonPaymentReason &&
        formData.locationTagType
      ) {
        if (formData.nonPaymentReason === NonPaymentReason.OTHERS) {
          if (
            !(InputType.OtherNonPaymentReason in formData) ||
            formData?.otherNonPaymentReason?.trim().length === 0
          ) {
            return false;
          }
        }
        if (
          formData.locationTagType ===
            VisitLocationTagType.NON_GEO_TAGGED_VISIT &&
          formData.agentRemarksForNonGeoVisit
        ) {
          return true;
        }
        if (
          formData.locationTagType === VisitLocationTagType.GEO_TAGGED_VISIT
        ) {
          return true;
        }
      } else {
        return false;
      }
    }

    if (
      InputType.NextAction in formData &&
      formData.nextAction === TrailNextAction.OTHERS
    ) {
      if (
        !(InputType.NextActionRemarks in formData) ||
        formData?.nextActionRemarks?.trim().length === 0
      ) {
        return false;
      }
    }

    if (dispositionCodeValue === DispositionCode.OTHERS) {
      if (
        !(InputType.DispositionCodeRemarks in formData) ||
        formData?.dispositionCodeRemarks?.trim().length === 0 ||
        !formData.nextAction ||
        (formData.nextAction === TrailNextAction.OTHERS &&
          !formData.nextActionRemarks)
      ) {
        return false;
      }
    }

    if (formData.nonPaymentReason === NonPaymentReason.OTHERS) {
      if (
        !(InputType.OtherNonPaymentReason in formData) ||
        formData?.otherNonPaymentReason?.trim().length === 0 ||
        !formData.nextAction ||
        (formData.nextAction === TrailNextAction.OTHERS &&
          !formData.nextActionRemarks)
      ) {
        return false;
      }
    }

    // if (formData.customerImageFile && formData.locationTagType) {
    if (formData.locationTagType) {
      if (
        formData.locationTagType ===
          VisitLocationTagType.NON_GEO_TAGGED_VISIT &&
        formData.agentRemarksForNonGeoVisit
      ) {
        return true;
      }
      if (formData.locationTagType === VisitLocationTagType.GEO_TAGGED_VISIT) {
        return true;
      }
    }

    return false;
  }

  const isNonPaymentReasonRequired = useMemo(() => {
    if (formData.dispositionType === DispositionType.NON_PAYMENT) {
      return true;
    } else {
      return false;
    }
  }, [formData.dispositionType]);

  const isNextActionDateAndNextActionRequired = useMemo(() => {
    const dispositionCodeValue = formData.dispositionCode;
    if (!dispositionCodeValue) {
      return false;
    }

    if (
      dispositionCodeValue === DispositionCode.PTP ||
      dispositionCodeValue === DispositionCode.CALLBACK ||
      dispositionCodeValue === DispositionCode.REVISIT ||
      dispositionCodeValue === DispositionCode.BROKEN_PTP
    ) {
      return true;
    } else if (InputType.NextActionDate in formData) {
      return true;
    } else if (InputType.NextAction in formData) {
      return true;
    }
  }, [formData]);

  useEffect(() => {
    if (open == false) return;
    setFormData({
      // nextActionDate: TOMORROW?.toISOString(),
      loanId: loanAccountId,
      agentId: user?.id,
      visitId: visit?.id,
      customerName: customerDetail?.customerName,
      primaryContact: customerDetail?.mobileNo,
    });
  }, [open, user]);

  useEffect(() => {
    setError(null);
  }, [formData.dispositionCode, formData.nextActionDate]);

  return (
    <>
      <VegaDrawer open={open} title={'Update Trail'} onClose={onDrawerClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <LmButton
                text="Update Trail & Submit for Geo Verification"
                onClick={OnSumbitClick}
                disabled={!isFormValid()}
                loading={isLoading}
              />
            );
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Loan Account Number">
                <LmTextField
                  disabled
                  value={formData?.loanId}
                  onChange={e => onPrimaryContactDetailChange(e.target.value)}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Customer Name">
                <LmTextField
                  disabled
                  value={formData?.customerName}
                  onChange={e => onPrimaryContactDetailChange(e.target.value)}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Primary Contact Number">
                <LmTextField
                  disabled
                  value={formData?.primaryContact}
                  onChange={e => onPrimaryContactDetailChange(e.target.value)}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <Box sx={{ position: 'relative' }}>
                <VegaFormInputField label="Alternate Contact Number">
                  <LmTextField
                    type="number"
                    value={formData?.alternateContact}
                    onChange={e =>
                      onAlternateContactDetailChange(e.target.value)
                    }
                    error={errorState.error.alternateContact?.length > 0}
                    helperText={errorState.error.alternateContact}
                  />
                </VegaFormInputField>
                <VegaText
                  sx={{
                    position: 'absolute',
                    bottom: -20,
                    right: 0,
                    cursor: 'pointer',
                  }}
                  fontColor={PRIMARY.darkBlue}
                  fontWeight={600}
                  fontSize={14}
                  text={'Update Contact'}
                  onClick={() => {
                    onDrawerClose();
                    dispatch(setOpenContactDetailsDrawer(true));
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Customer Interaction">
                <VegaSelect
                  options={customerInteractionOptions()}
                  value={formData?.customerInteraction}
                  onChange={e => {
                    updateFormData(
                      InputType.CustomerInteraction,
                      e.target.value
                    );
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Date">
                <LmDateField disabled value={new Date()} onChange={e => {}} />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField
                label="Customer Contact Status"
                isMandatory={true}
              >
                <VegaSelect
                  options={contactStatusOptions()}
                  value={formData?.contactStatus}
                  onChange={e => {
                    onContactStatusChange(e);
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <AnimatedInputWrapper
              show={showDispositionType}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField label="Disposition Type" isMandatory={true}>
                  <VegaSelect
                    value={formData?.dispositionType ?? ''}
                    onChange={e => {
                      updateDispositionType(e.target.value as string);
                    }}
                    options={dispositionTypeOptions()}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={showNonPaymentReason}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label="Non Payment Reason"
                  isMandatory={isNonPaymentReasonRequired}
                >
                  <VegaSelect
                    value={formData?.nonPaymentReason}
                    onChange={e => {
                      updateNonPaymentReason(e.target.value as string);
                    }}
                    options={nonPaymentReasonOption()}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={formData?.nonPaymentReason === NonPaymentReason.OTHERS}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label="Other Non-Payment reason description"
                  isMandatory={
                    formData?.nonPaymentReason === NonPaymentReason.OTHERS
                  }
                >
                  <LmTextField
                    type="text"
                    value={formData?.otherNonPaymentReason ?? ''}
                    onChange={e =>
                      updateFormData(
                        InputType.OtherNonPaymentReason,
                        e.target.value
                      )
                    }
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={showDispositionCode}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField label="Disposition Code" isMandatory={true}>
                  <VegaSelect
                    value={formData?.dispositionCode ?? ''}
                    onChange={e =>
                      updateDispositionCode(e.target.value as string)
                    }
                    options={dispositionCodeOptions(formData)}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={formData.dispositionCode === DispositionCode.OTHERS}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label="Description"
                  isMandatory={
                    formData.dispositionCode === DispositionCode.OTHERS
                  }
                >
                  <LmTextField
                    type="text"
                    value={formData?.dispositionCodeRemarks ?? ''}
                    onChange={e =>
                      updateFormData(
                        InputType.DispositionCodeRemarks,
                        e.target.value
                      )
                    }
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={showAmountCollected}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField label="Amount">
                  <LmTextField
                    value={formData.amountCollected}
                    onChange={e => {
                      updateAmountCollected(e.target.value);
                    }}
                    type="number"
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={showNextActionDate}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label={'Next Action Date'}
                  isMandatory={isNextActionDateAndNextActionRequired}
                >
                  <VegaDatePicker
                    minDate={TOMORROW.toISOString()}
                    value={formData?.nextActionDate ?? ''}
                    onChange={e => {
                      const parseDate = DateUtility.parseDateFromDatePicker(e);
                      const dateValue = parseDate?.toISOString();
                      updateFormData(InputType.NextActionDate, dateValue);
                    }}
                  />
                </VegaFormInputField>
                <AnimatedInputWrapper
                  show={error !== null}
                  marginTop={'0.5rem'}
                >
                  <Stack
                    direction={'column'}
                    style={{
                      backgroundColor: COLOR.RED.LIGHT,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 6,
                    }}
                  >
                    <VegaText
                      style={{ color: COLOR.RED.DARK }}
                      text={'Cannot Submit !'}
                    />
                    <VegaText
                      style={{ color: COLOR.RED.DARK }}
                      text={_.startCase(
                        _.toLower(
                          PtpErrorType.nextAction_Date_should_be_before_2_days
                        )
                      )}
                    />
                  </Stack>
                </AnimatedInputWrapper>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper show={showNextAction} marginTop={'0.5rem'}>
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label="Next Action"
                  isMandatory={isNextActionDateAndNextActionRequired}
                >
                  <VegaSelect
                    value={formData?.nextAction ?? ''}
                    onChange={e => updateNextAction(e.target.value)}
                    options={nextActionOptions()}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={formData?.nextAction === TrailNextAction.OTHERS}
              marginTop={'0.5rem'}
            >
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label="Next Action Remarks"
                  isMandatory={formData.nextAction === TrailNextAction.OTHERS}
                >
                  <LmTextField
                    type="text"
                    value={formData?.nextActionRemarks ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      updateFormData(InputType.NextActionRemarks, value);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper show={showCustomerImage} marginTop={'0.5rem'}>
              <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
                <Stack rowGap={'0.75rem'}>
                  {formData.customerImageFile && (
                    <VegaFileSelect
                      key={formData.customerImageFile?.id}
                      value={formData.customerImageFile}
                      acceptedExtension={[
                        AcceptedExtension.JPG,
                        AcceptedExtension.JPEG,
                        AcceptedExtension.PNG,
                        AcceptedExtension.PDF,
                      ]}
                      placeholder={'Upload Document'}
                      onFileSelect={file => {
                        onFileChange(file);
                      }}
                      onDeleteClick={() => {
                        onFileDelete();
                      }}
                    />
                  )}

                  <VegaFileSelect
                    placeholder={'Upload Customer Image'}
                    acceptedExtension={[
                      AcceptedExtension.JPG,
                      AcceptedExtension.JPEG,
                      AcceptedExtension.PNG,
                      AcceptedExtension.PDF,
                    ]}
                    onFileSelect={file => {
                      onFileAdd(file);
                    }}
                  />
                </Stack>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper show={showVisitType} marginTop={'0.5rem'}>
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <Divider sx={{ borderStyle: 'dotted' }} />
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper show={showVisitType} marginTop={'0.5rem'}>
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField label="Visit Type" isMandatory>
                  <VegaSelect
                    value={formData.locationTagType}
                    onChange={e => updateVisitType(e.target.value)}
                    options={visitTypeOptions()}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
            <AnimatedInputWrapper show={showVisitReason} marginTop={'0.5rem'}>
              <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
                <VegaFormInputField
                  label="Agent Remarks For Non Geo Visit"
                  isMandatory={
                    formData.locationTagType ===
                    VisitLocationTagType.NON_GEO_TAGGED_VISIT
                  }
                >
                  <LmTextField
                    placeholder="Enter Remark"
                    value={formData.agentRemarksForNonGeoVisit}
                    onChange={e => {
                      updateAgentRemarksForNonGeoVisit(e.target.value);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
            </AnimatedInputWrapper>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
      {/* <LmDialog
        fullWidth
        maxWidth="xs"
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'end',
          },
        }}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="No"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                  borderColor: GREY.dark,
                  bgcolor: 'white',
                  ':hover': {
                    color: GREY.dark,
                    borderColor: GREY.dark,
                    bgcolor: 'white',
                  },
                }}
                onClick={() => setOpenDialog(false)}
              />
              <VegaButton fullWidth text={'Yes'} onClick={onYesAction} />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text={'Are you sure ?'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={''}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </VegaDialogContent>
      </LmDialog> */}
      <LmDialog
        fullWidth
        maxWidth="xs"
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'end',
          },
        }}
        open={isDialogOpen}
        handleClose={() => closeDialog()}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="No"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                  borderColor: GREY.dark,
                  bgcolor: 'white',
                  ':hover': {
                    color: GREY.dark,
                    borderColor: GREY.dark,
                    bgcolor: 'white',
                  },
                }}
                onClick={() => closeDialog()}
              />
              <VegaButton fullWidth text={'Yes'} onClick={OnSumbitClick} />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text={'Are you sure ?'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <Grid container>
            <Grid item xs={12}>
              <VegaText
                text={dialogProps.errorMsg}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <VegaFormInputField label="Remark">
                <LmTextField
                  placeholder="Enter Remark"
                  value={formData.agentRemarks}
                  onChange={e => {
                    updateAgentRemark(e.target.value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDialogContent>
      </LmDialog>
    </>
  );
}

export default UpdateTrailDrawer;
const visitTypeOptions = () =>
  [
    VisitLocationTagType.GEO_TAGGED_VISIT,
    VisitLocationTagType.NON_GEO_TAGGED_VISIT,
  ].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const contactStatusOptions = () =>
  Object.values(ContactStatus).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
const dispositionTypeOptions = () =>
  Object.keys(DispositionType).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
const customerInteractionOptions = () =>
  Object.keys(CustomerInteraction).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
const dispositionCodeOptions = (formData: Partial<CreateTrailFormData>) => {
  const contactStatus = formData.contactStatus;

  if (!contactStatus) {
    return [];
  }
  if (contactStatus === ContactStatus.NON_CONTACTED) {
    return dispositionTypeForNonContactedCases().map(item => {
      const option: VegaSelectOption = {
        label: _.startCase(_.toLower(item)),
        value: item,
      };
      return option;
    });
  }
  const dispositionType = formData.dispositionType;
  if (!dispositionType) {
    return [];
  }
  const type = getEnumValueFromString(DispositionType, dispositionType);
  if (!type) {
    return [];
  }
  return dispositionCodeMap[type].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
};

const nextActionOptions = () =>
  Object.keys(TrailNextAction).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
const nonPaymentReasonOption = () =>
  Object.keys(NonPaymentReason).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const isValidField = (formData: Partial<CreateTrailFormData>) => {
  const errorObj = {
    error: {},
    isValid: true,
  } as IErrorStateProps;
  const alternateContactString =
    typeof formData.alternateContact === 'number'
      ? formData.alternateContact.toString()
      : formData.alternateContact;
  const isValidNumber = StringUtility.validatePhoneNumber(
    alternateContactString
  );

  // if (!isValidNumber) {
  //   errorObj.isValid = false;
  //   errorObj.error['alternateContact'] = 'Mobile number should be 10 digits';
  // }
  return errorObj;
};
