import React, { useEffect, useState } from 'react';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaText from '../common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { getHeaderText } from '../../pages/IncentivesGamifications/IncentivesGamifications';
import VegaPageContent from '../common/VegaPageContent';
import VegaDataGrid from '../common/VegaDataGrid';
import { useAppDispatch, useAppSelector } from '../../store';
import { getIncentivesGamificationsState } from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { TempleteColumn } from './column/TempleteColumn';
import { useDrawer } from '../../hooks/useDrawer';
import VegaDeleteDialog from '../common/VegaDeleteDialog';
import { TempleteListProps } from '../../types/incentives';
import { IncentiveServices } from '../../Apis/IncentivesService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { ROUTES } from '../../router/routes';
import { useNavigate } from 'react-router-dom';
import { listOfTempletes } from '../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';

interface IProps {
  selectedPolicyTab: number;
}

const ListOfTempletes = ({ selectedPolicyTab }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { templates, loading } = useAppSelector(
    getIncentivesGamificationsState
  );
  const { setSnackbar } = useSnackbar();
  const [templateData, setTemplateData] = useState<Partial<TempleteListProps>>(
    {}
  );
  const {
    open: onDeleteOpen,
    close: onDeleteClose,
    isOpen: isDeleteOpen,
  } = useDrawer();

  const onDeleteTemplete = () => {
    if (templateData) {
      const templateId = templateData.templateId?.toString();
      IncentiveServices.deleteTemplate(templateId)
        .then(() => {
          setSnackbar(`delete template successfully !!!`);
          dispatch(listOfTempletes(''));
          onDeleteClose();
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
          onDeleteClose();
        });
    }
  };

  useEffect(() => {
    dispatch(listOfTempletes(''));
  }, []);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={toLowerCase(getHeaderText(selectedPolicyTab))}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={templates}
          idColumn="templateId"
          loading={loading}
          paginationMode="client"
          columns={TempleteColumn({
            onAction: (template: TempleteListProps, action: string) => {
              if (action === 'DELETE') {
                onDeleteOpen();
                if (template) {
                  setTemplateData(template);
                }
              } else {
                const templateId = template.templateId.toString();
                navigate(
                  ROUTES.TEMPLATE_VIEW.replace(':templateId', templateId)
                );
              }
            },
          })}
        />
      </VegaPageContent>
      <VegaDeleteDialog
        text={`Are you sure delete this ${templateData?.templateName} ?`}
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onDelete={onDeleteTemplete}
      />
    </>
  );
};

export default ListOfTempletes;
