import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { DistanceTravelledAgentWiseDataType } from './FieldAppDashboard';
import { useClientAuth } from '../../providers/ClientProvider';

type PropTypes = {
  data: DistanceTravelledAgentWiseDataType[];
  loading: boolean;
};
const DistanceTravelledAgentWiseGraph = ({ data, loading }: PropTypes) => {
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
      colors: ['#492c66', '#492c66'],
    },
    legend: {
      markers: {
        fillColors: ['#492c66', '#492c66'],
      },
    },
    xaxis: {
      categories: data.map(data => getUserName(data?.agentId)),
    },
    yaxis: {
      title: {
        text: 'Distance',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
  };

  const yearSeries = [
    {
      name: 'Distance',
      data: data.map(data => ({
        x: getUserName(data?.agentId),
        y: data?.distance,
        fillColor: '#492c66',
      })),
    },
  ];

  return (
    <GraphWrapper title="Distance Travelled Agent Wise (in Km)">
      {loading ? (
        <LoadingPage height={350} />
      ) : (
        <Stack sx={{ height: '21.875rem' }}>
          <Chart
            options={options}
            series={yearSeries}
            type="bar"
            height={'100%'}
          />
        </Stack>
      )}
    </GraphWrapper>
  );
};

export default DistanceTravelledAgentWiseGraph;
