import { Grid, Stack } from '@mui/material';
import { useOts } from '../../../../providers/OtsProvider';
import { NumberUtility } from '../../../../utils/NumberUtility';
import { StringUtility } from '../../../../utils/StringUtility';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import OtsFileDisplay from './OtsFileDisplay';

type Props = {
  onEditClick?: () => void;
  canEdit?: boolean;
};
function OtsWavierDetails({ onEditClick, canEdit }: Props) {
  const { ots, loading, downloadFile, loan } = useOts();

  const getPrincipleWavierPercent = () => {
    return (
      ((ots?.principle ?? 0) / (loan?.lmsVariables?.outstandingAmount ?? 0)) *
      100
    );
  };

  const getInterestWavierPercent = () => {
    return (
      ((ots?.interest ?? 0) / (loan?.lmsVariables?.interestOutStanding ?? 0)) *
      100
    );
  };

  const getOthersWavierPercent = () => {
    return ((ots?.principle ?? 0) / (loan?.lmsVariables?.chargeDue ?? 0)) * 100;
  };

  const getTotalWavierPercent = () => {
    return (
      ((ots?.wavierAmount ?? 0) /
        (loan?.lmsVariables?.outstandingAmount ?? 0)) *
      100
    );
  };

  return (
    <OtsDetailsWrapper
      text="Waiver Details"
      showBorder
      editable={canEdit}
      onEditClick={onEditClick}
    >
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Total Waiver Amount"
              value={StringUtility.formatToINR(ots?.wavierAmount)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Total Waiver %"
              value={
                NumberUtility.formatToDecimal(getTotalWavierPercent(), 2) +
                '%'
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Total Settlement Amount"
              value={StringUtility.formatToINR(ots?.settlementAmount)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Principal"
              value={StringUtility.formatToINR(ots?.principle)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Principal Waiver %"
              value={
                NumberUtility.formatToDecimal(getPrincipleWavierPercent(), 2) +
                '%'
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Interest"
              value={StringUtility.formatToINR(ots?.interest)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Interest Waiver %"
              value={
                NumberUtility.formatToDecimal(getInterestWavierPercent(), 2) +
                '%'
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Others"
              value={StringUtility.formatToINR(ots?.others)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Others Waiver %"
              value={
                NumberUtility.formatToDecimal(getOthersWavierPercent(), 2) + '%'
              }
              loading={loading}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Request Letter"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Request Letter'}
                    onClick={() => {
                      downloadFile(ots?.requestLetter);
                    }}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsWavierDetails;
