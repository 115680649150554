import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RoleServices } from '../../../Apis/RoleServices';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaMultiSelect from '../../../components/common/VegaMultiSelect';
import { VegaPill } from '../../../components/common/VegaPill';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ResourceDtoObj } from '../../../types/claim';
import { RoleResourceDto } from '../../../types/roleType';
import { COLOR } from '../../../utils/ColorUtility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

interface IProps {
  selectedResources: any;
  setSelectedResources: React.Dispatch<React.SetStateAction<any>>;
  roleDetails: RoleResourceDto;
}

const AddScreenForm = ({
  setSelectedResources,
  selectedResources,
  roleDetails,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [resources, setResources] = useState<VegaSelectOption[]>([]);
  const getAllResources = () => {
    setLoading(true);
    RoleServices.getResources({ type: 'UI' })
      .then(res => {
        const resourcesList = res.length
          ? res?.map(
              (resource: ResourceDtoObj) =>
                ({
                  label: `${toLowerCase(resource.module)} -> ${toLowerCase(
                    resource.subModule
                  )}`,
                  value: resource.id,
                } as VegaSelectOption)
            )
          : [];
        setResources(resourcesList);
        setLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllResources();
  }, []);
  return (
    <>
      <Grid container spacing={'1.25rem'}>
        <Grid item xs={12}>
          <VegaFormInputField label={'Assigned Resources'}>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
              {roleDetails.resourceDtos.length ? (
                roleDetails.resourceDtos.map((resource: ResourceDtoObj) => {
                  const label = `${toLowerCase(
                    resource.module
                  )} -> ${toLowerCase(resource.subModule)}`;
                  return (
                    <VegaPill
                      key={resource.id}
                      text={label}
                      backgroundColor={getColorForStatus(label)?.LIGHT}
                      textColor={getColorForStatus(label)?.DARK}
                    />
                  );
                })
              ) : (
                <VegaText text={'No Assign roles for this user'} />
              )}
            </Stack>
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12}>
          <VegaFormInputField label={'Resources'}>
            <VegaMultiSelect
              isLoading={loading}
              options={getFitteredResources(
                resources,
                roleDetails.resourceDtos
              )}
              value={selectedResources}
              onChange={selected => {
                setSelectedResources(selected);
              }}
              placeholder="Select Resource"
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </>
  );
};

export default AddScreenForm;

const getColorForStatus = (status?: string) => {
  if (!status) return;

  return COLOR.RED;
};

const getFitteredResources = (
  resources: VegaSelectOption[],
  selectedResourceDtos: ResourceDtoObj[]
) => {
  const matchingLabels = selectedResourceDtos.map(
    (selectedResource: ResourceDtoObj) => {
      const label = `${toLowerCase(selectedResource.module)} -> ${toLowerCase(
        selectedResource.subModule
      )}`;
      return label;
    }
  );
  const filteredResources = resources.filter(
    (resource: VegaSelectOption) => !matchingLabels.includes(resource.label)
  );

  return filteredResources;
};
