import { Grid } from '@mui/material';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsCollectionsHierarchy() {
  const { userForId } = useClientAuth();
  const { ots, loading } = useOts();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  return (
    <OtsDetailsWrapper text="Collections Hierarchy" showBorder>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Collections Executive"
            value={getUserName(ots?.raisedBy)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Assistant Collection Manager"
            value={getUserName(ots?.asm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Collections Manager"
            value={getUserName(ots?.cm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Area Collections Manager"
            value={getUserName(ots?.acm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Cluster Collections Manager"
            value={getUserName(ots?.ccm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Regional Collections Manager"
            value={getUserName(ots?.rcm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Zonal Collections Manager"
            value={getUserName(ots?.zcm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="National Collections Manager"
            value={getUserName(ots?.ncm)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Head Of Collections"
            value={getUserName(ots?.hoc)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Managing Director"
            value={getUserName(ots?.md)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Agenda Creator"
            value={getUserName(ots?.agendaCreator)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Technical Team"
            value={getUserName(ots?.technicalTeam)}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <OtsKeyValueDisplay
            text="Operation Team"
            value={getUserName(ots?.operationTeam)}
            loading={loading}
          />
        </Grid>
      </Grid>
    </OtsDetailsWrapper>
  );
}

export default OtsCollectionsHierarchy;
