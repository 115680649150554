import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { AnaltyticsService } from '../../Apis/AnaltyticsService';
import { PerformanceServices } from '../../Apis/PerformanceServices';
import AgentSelector from '../../components/AgentSelector';
import {
  DueDateTypes,
  VerticalTypes,
} from '../../components/Perfomance/PerformanceFilterSection';
import MultiSelectAgentSelector from '../../components/TeleAgent/MultiSelectAgentSelector';
import VegaAutoComplete from '../../components/common/VegaAutoComplete';
import VegaButton from '../../components/common/VegaButton';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaSelectOption } from '../../components/common/VegaSelect';
import VegaText from '../../components/common/VegaText';
import { TOTAL_BRANCH, TOTAL_CITIES } from '../../constants/athenaQueryId';
import { toLowerCase } from '../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../constants/style';
import { AnalyticsQueryTypeEnum } from '../../features/analyticsDashboardSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { BucketOption } from '../../types/minVisitsType';
import {
  AnalyticsQueryParamsProps,
  AnalyticsQueryRequest,
} from '../../types/request/analyticsRequest';
import {
  DispositionCode,
  DispositionType,
  NonPaymentReason,
} from '../../types/trail';
import { DateUtility } from '../../utils/DateUtlility';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import RegionSelector from '../MinVisits/modules/RegionSelector';
import { PerformanceFilterInputType } from '../Performance/Performance';
import { ModelParserPreditionOptions } from '../PortFolio/PortFolio';
import AccountAndDispositionCodeGraph from './AccountAndDispositionCodeGraph';
import DistanceTravelledAgentWiseGraph from './DistanceTravelledAgentWiseGraph';
import DistanceTravelledDayWiseGraph from './DistanceTravelledDayWiseGraph';
import FieldAppDashboardSummary from './FieldAppDashboardSummary';
import GeoAndNonGeoBreakupGraph from './GeoAndNonGeoBreakupGraph';
import NoOfAccountsPerAgentGraph from './NoOfAccountsPerAgentGraph';
import NoOfVisitsPerDispositionGroup from './NoOfVisitsPerDispositionGroup';
import NoOfVisitsPerAgentGraph from './NoOfVisitsPerAgentGraph';
import NumberOfVisitsDayWiseGraph from './NumberOfVisitsDayWiseGraph';
import AllBranchSelector from '../MinVisits/modules/AllBranchSelector';
import { LoanStatus } from '../../components/Accounts/AccountFilterForm';

enum BucketType {
  BUCKETX = 'BUCKETX',
  BUCKET1 = 'BUCKET1',
  BUCKET2 = 'BUCKET2',
  BUCKET3 = 'BUCKET3',
  NPA = 'NPA',
  NORMAL = 'NORMAL',
}

type FilterType = {
  from_date: string;
  to_date: string;
  disposition_Code: string;
  agentId: string;
  disposition_Type: string;
  segment: string;
  bucket: string;
  cycle: string;
  branch: string;
  city: string;
  non_Payment_Reason: string;
  model_Parser_Allocation_Type: string;
  region: string;
  actionType: string;
  modeOfCommunication: string;
  collectionModelPrediction: string;
};

enum FilterInputType {
  from_date = 'from_date',
  to_date = 'to_date',
  disposition_Code = 'disposition_Code',
  agentId = 'agentId',
  disposition_type = 'disposition_Type',
  segment = 'segment',
  bucket = 'bucket',
  cycle = 'cycle',
  branch = 'branch',
  non_Payment_Reason = 'non_Payment_Reason',
  model_Parser_Allocation_Type = 'model_Parser_Allocation_Type',
  region = 'region',
  actionType = 'actionType',
  modeOfCommunication = 'modeOfCommunication',
  collectionModelPrediction = 'collectionModelPrediction',
}

export interface VisitStatistics {
  noOfVisits: number;
  totalAccount: number;
  contactedCount: number;
  totalDistance: number;
}

export interface DispositionCodeDataType {
  dispositionCode: string;
  count: number;
}
export interface VisitsPerDayType {
  date: string;
  count: number;
}
export interface DistanceTravelledPerDayType {
  date: string;
  distance: number;
}

export interface GeoAndNonGeoTaggedBreakupType {
  date: string;
  totalPtp: number;
  geoTaggedPtp: number;
  nonGeoTaggedPtp: number;
}

export interface AgentWiseDataFiltersType {
  agentIds: string[];
  fromDate: string;
  toDate: string;
}

export interface NumberOfVisitAndNumberOfAccountsAgentWiseDataType {
  date: string;
  agentId: string;
  count: number;
}

export interface DistanceTravelledAgentWiseDataType {
  date: string;
  agentId: string;
  distance: string;
}

enum ActionType {
  TELECALLING_REFERRALS = 'TELECALLING_REFERRALS',
  FIELD_VISITED_ACCOUNTS = 'FIELD_VISITED_ACCOUNTS',
}

enum ModeOfCommunication {
  SMS = 'SMS_SENT',
  IVR = 'IVR_SENT',
  WHATSAPP = 'WHATSAPP_SENT',
  EMAIL = 'EMAIL_SENT',
}

const TODAY = new Date();
const FIRST_DATE_OF_MONTH = new Date(TODAY.getFullYear(), TODAY.getMonth(), 1);
function FieldAppDashboard() {
  const [filterData, SetFilterData] = useState<Partial<FilterType>>({
    to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
    from_date: DateUtility.formatStringToYYYYMMDD(
      FIRST_DATE_OF_MONTH.toISOString()
    ),
    disposition_Code: '',
    agentId: '',
    disposition_Type: '',
    branch: '',
    bucket: '',
    city: '',
    cycle: '',
    non_Payment_Reason: '',
    segment: '',
    region: '',
    model_Parser_Allocation_Type: '',
    actionType: '',
    modeOfCommunication: '',
    collectionModelPrediction: '',
  });
  const [agentWiseDataFilters, setAgentWiseDataFilters] =
    useState<AgentWiseDataFiltersType>({
      agentIds: [],
      toDate: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      fromDate: DateUtility.formatStringToYYYYMMDD(
        FIRST_DATE_OF_MONTH.toISOString()
      ),
    });
  const [visitStatisticsSummaryData, setVisitStatisticsSummaryData] =
    useState<VisitStatistics>();
  const [dispositionCodeData, setDispositionCodeData] = useState<
    DispositionCodeDataType[]
  >([]);
  const [visitsPerDispositionCode, setVisitsPerDispositionCode] = useState<
    DispositionCodeDataType[]
  >([]);
  const [
    numberOfVisitAndNumberOfAccountsAgentWiseData,
    setNumberOfVisitAndNumberOfAccountsAgentWiseData,
  ] = useState<NumberOfVisitAndNumberOfAccountsAgentWiseDataType[]>([]);
  const [distanceTravelledAgentWiseData, setDistanceTravelledAgentWiseData] =
    useState<DistanceTravelledAgentWiseDataType[]>([]);
  const [visitsPerDay, setVisitsPerDay] = useState<VisitsPerDayType[]>([]);
  const [distanceTravelledPerDay, setDistanceTravelledPerDay] = useState<
    DistanceTravelledPerDayType[]
  >([]);
  const [geoAndNonGeoTaggedBreakUp, setGeoAndNonGeoTaggedBreakUp] = useState<
    GeoAndNonGeoTaggedBreakupType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  function updateFormData(type: FilterInputType, value: string) {
    SetFilterData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  const onAgentDelete = (index: number) => {
    const users = [...agentWiseDataFilters.agentIds];
    users.splice(index, 1);
    setAgentWiseDataFilters(prev => {
      return { ...prev, agentIds: users };
    });
  };

  const onAgentSelect = (user: string) => {
    if (agentWiseDataFilters.agentIds.length > 9) {
      return;
    }
    if (agentWiseDataFilters.agentIds.includes(user)) {
      const filteredUser = agentWiseDataFilters.agentIds.filter(
        (selectedUser: string) => selectedUser !== user
      );
      setAgentWiseDataFilters(prev => {
        return { ...prev, agentIds: filteredUser };
      });
    } else {
      setAgentWiseDataFilters(prev => {
        return { ...prev, agentIds: [...agentWiseDataFilters.agentIds, user] };
      });
    }
  };

  function updateFromDate(e) {
    const formattedDate = DateUtility.parseDateFromDatePicker(e).toISOString();

    updateFormData(
      FilterInputType.from_date,
      DateUtility.formatStringToYYYYMMDD(formattedDate)
    );
  }

  function updateAgentWiseFromDate(e) {
    const formattedDate = DateUtility.parseDateFromDatePicker(e).toISOString();
    setAgentWiseDataFilters(prev => {
      return {
        ...prev,
        fromDate: DateUtility.formatStringToYYYYMMDD(formattedDate),
      };
    });
  }

  function updateToDate(e) {
    const formattedDate = DateUtility.parseDateFromDatePicker(e).toISOString();

    updateFormData(
      FilterInputType.to_date,
      DateUtility.formatStringToYYYYMMDD(formattedDate)
    );
  }

  function updateAgentWiseToDate(e) {
    const formattedDate = DateUtility.parseDateFromDatePicker(e).toISOString();

    setAgentWiseDataFilters(prev => {
      return {
        ...prev,
        toDate: DateUtility.formatStringToYYYYMMDD(formattedDate),
      };
    });
  }

  function getParams() {
    const params: Partial<AnalyticsQueryParamsProps> = {};
    if (!filterData) {
      return params;
    }
    if (filterData.from_date) {
      params.fromDate = filterData.from_date;
    }
    if (filterData.to_date) {
      params.toDate = filterData.to_date;
    }
    if (filterData.disposition_Code) {
      params.dispositionCode = filterData.disposition_Code;
    }
    if (filterData.disposition_Type) {
      params.dispositionType = filterData.disposition_Type;
    }
    if (filterData.agentId) {
      params.agentId = filterData.agentId;
    }
    if (filterData.branch) {
      params.branch = filterData.branch;
    }
    if (filterData.bucket) {
      params.bucket = filterData.bucket;
    }
    if (filterData.city) {
      params.city = filterData.city;
    }
    if (filterData.segment) {
      params.segment = filterData.segment;
    }
    if (filterData.cycle) {
      params.cycle = filterData.cycle;
    }
    if (filterData.non_Payment_Reason) {
      params.nonPaymentReason = filterData.non_Payment_Reason;
    }
    if (filterData.model_Parser_Allocation_Type) {
      params.modelParserAllocationType =
        filterData.model_Parser_Allocation_Type;
    }
    if (filterData.region) {
      params.region = filterData.region;
    }
    if (filterData.actionType) {
      params.actionType = filterData.actionType;
    }
    if (filterData.modeOfCommunication) {
      params.modeOfCommunication = filterData.modeOfCommunication;
    }
    if (filterData.collectionModelPrediction) {
      params.collectionModelPrediction = filterData.collectionModelPrediction;
    }
    return params;
  }

  async function getAgentWiseGraphData() {
    const numberOfVisitAndNumberOfAccountsAgentWiseRequest: AnalyticsQueryRequest =
      {
        analyticsQueryType: AnalyticsQueryTypeEnum.VISIT_PER_AGENT,
        params: {
          agentId: agentWiseDataFilters.agentIds.join(','),
          fromDate: agentWiseDataFilters.fromDate,
          toDate: agentWiseDataFilters.toDate,
        },
      };

    const distancetravelledAgentWise: AnalyticsQueryRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISTANCE_TRAVELLED_PER_AGENT,
      params: {
        agentId: agentWiseDataFilters.agentIds.join(','),
        fromDate: agentWiseDataFilters.fromDate,
        toDate: agentWiseDataFilters.toDate,
      },
    };

    const noOfVisits = await AnaltyticsService.getAnalyticsQueries(
      numberOfVisitAndNumberOfAccountsAgentWiseRequest
    );
    const distanceTravelled = await AnaltyticsService.getAnalyticsQueries(
      distancetravelledAgentWise
    );
    setNumberOfVisitAndNumberOfAccountsAgentWiseData(noOfVisits);
    setDistanceTravelledAgentWiseData(distanceTravelled);
  }

  async function getVisitsStatisticsDetails(request: AnalyticsQueryRequest) {
    try {
      setLoading(true);

      const response: VisitStatistics =
        await AnaltyticsService.getAnalyticsQueries(request);

      setVisitStatisticsSummaryData(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getDispositionCodeDetails(request: AnalyticsQueryRequest) {
    try {
      setLoading(true);

      const response: DispositionCodeDataType[] =
        await AnaltyticsService.getAnalyticsQueries(request);

      setDispositionCodeData(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getVisitsPerDispositionCodeDetails(
    request: AnalyticsQueryRequest
  ) {
    try {
      setLoading(true);

      const response: DispositionCodeDataType[] =
        await AnaltyticsService.getAnalyticsQueries(request);

      setVisitsPerDispositionCode(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getVisitsPerDay(request: AnalyticsQueryRequest) {
    try {
      setLoading(true);

      const response: VisitsPerDayType[] =
        await AnaltyticsService.getAnalyticsQueries(request);

      setVisitsPerDay(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getDistancetravelledPerDay(request: AnalyticsQueryRequest) {
    try {
      setLoading(true);

      const response: DistanceTravelledPerDayType[] =
        await AnaltyticsService.getAnalyticsQueries(request);

      setDistanceTravelledPerDay(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function getGeoAndNonGeoTagBreakupData(request: AnalyticsQueryRequest) {
    try {
      setLoading(true);

      const response: GeoAndNonGeoTaggedBreakupType[] =
        await AnaltyticsService.getAnalyticsQueries(request);

      setGeoAndNonGeoTaggedBreakUp(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function fetchData() {
    const dispositionCodeDetailsRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISPOSITION_CODE_VISIT_COUNT,
      params: getParams(),
    } as AnalyticsQueryRequest;

    //TODO: need to change the analytic query type for dispositionCodePerVisitRequest
    const dispositionCodePerVisitRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISPOSITION_CODE_COUNT,
      params: getParams(),
    } as AnalyticsQueryRequest;

    const visitStatisticsDetailRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.FIELD_APP_DASHBOARD,
      params: getParams(),
    } as AnalyticsQueryRequest;

    const visitsPerDayRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.VISIT_PER_DAY,
      params: getParams(),
    } as AnalyticsQueryRequest;

    const distanceTravelledPerDayRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISTANCE_TRAVELLED_PER_DAY,
      params: getParams(),
    } as AnalyticsQueryRequest;

    const geoAndNonGeoTaggedBreakUpRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DAY_WISE_PTP,
      params: getParams(),
    } as AnalyticsQueryRequest;

    getVisitsStatisticsDetails(visitStatisticsDetailRequest);
    getDispositionCodeDetails(dispositionCodeDetailsRequest);
    getVisitsPerDispositionCodeDetails(dispositionCodePerVisitRequest);
    getVisitsPerDay(visitsPerDayRequest);
    getDistancetravelledPerDay(distanceTravelledPerDayRequest);
    getGeoAndNonGeoTagBreakupData(geoAndNonGeoTaggedBreakUpRequest);
  }

  function fetchDataOnClearAllClick() {
    const dispositionCodeDetailsRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISPOSITION_CODE_VISIT_COUNT,
      params: {
        to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        from_date: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    } as AnalyticsQueryRequest;

    //TODO: need to change the analytic query type for dispositionCodePerVisitRequest
    const dispositionCodePerVisitRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISPOSITION_CODE_COUNT,
      params: {
        to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        from_date: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    } as AnalyticsQueryRequest;

    const visitStatisticsDetailRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.FIELD_APP_DASHBOARD,
      params: {
        to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        from_date: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    } as AnalyticsQueryRequest;

    const visitsPerDayRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.VISIT_PER_DAY,
      params: {
        to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        from_date: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    } as AnalyticsQueryRequest;

    const distanceTravelledPerDayRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISTANCE_TRAVELLED_PER_DAY,
      params: {
        to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        from_date: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    } as AnalyticsQueryRequest;

    const geoAndNonGeoTaggedBreakUpRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DAY_WISE_PTP,
      params: {
        to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        from_date: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    } as AnalyticsQueryRequest;

    getVisitsStatisticsDetails(visitStatisticsDetailRequest);
    getDispositionCodeDetails(dispositionCodeDetailsRequest);
    getVisitsPerDispositionCodeDetails(dispositionCodePerVisitRequest);
    getVisitsPerDay(visitsPerDayRequest);
    getDistancetravelledPerDay(distanceTravelledPerDayRequest);
    getGeoAndNonGeoTagBreakupData(geoAndNonGeoTaggedBreakUpRequest);
  }

  async function clearFilter() {
    SetFilterData({
      to_date: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      from_date: DateUtility.formatStringToYYYYMMDD(
        FIRST_DATE_OF_MONTH.toISOString()
      ),
      disposition_Code: '',
      agentId: '',
      disposition_Type: '',
      branch: '',
      bucket: '',
      city: '',
      cycle: '',
      non_Payment_Reason: '',
      segment: '',
      model_Parser_Allocation_Type: '',
      region: '',
      actionType: '',
      modeOfCommunication: '',
    });
    fetchDataOnClearAllClick();
  }

  async function onAgentWiseFilterClearClick() {
    setAgentWiseDataFilters(prev => ({
      ...prev,
      agentIds: [],
      toDate: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      fromDate: DateUtility.formatStringToYYYYMMDD(
        FIRST_DATE_OF_MONTH.toISOString()
      ),
    }));
    const numberOfVisitAndNumberOfAccountsAgentWiseRequest: AnalyticsQueryRequest =
      {
        analyticsQueryType: AnalyticsQueryTypeEnum.VISIT_PER_AGENT,
        params: {
          // agentId: agentWiseDataFilters.agentIds.join(','),
          toDate: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
          fromDate: DateUtility.formatStringToYYYYMMDD(
            FIRST_DATE_OF_MONTH.toISOString()
          ),
        },
      };

    const distancetravelledAgentWise: AnalyticsQueryRequest = {
      analyticsQueryType: AnalyticsQueryTypeEnum.DISTANCE_TRAVELLED_PER_AGENT,
      params: {
        // agentId: agentWiseDataFilters.agentIds.join(','),
        toDate: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
        fromDate: DateUtility.formatStringToYYYYMMDD(
          FIRST_DATE_OF_MONTH.toISOString()
        ),
      },
    };
    const noOfVisits = await AnaltyticsService.getAnalyticsQueries(
      numberOfVisitAndNumberOfAccountsAgentWiseRequest
    );
    const distanceTravelled = await AnaltyticsService.getAnalyticsQueries(
      distancetravelledAgentWise
    );
    setNumberOfVisitAndNumberOfAccountsAgentWiseData(noOfVisits);
    setDistanceTravelledAgentWiseData(distanceTravelled);
  }

  async function onApplyFilterClick() {
    fetchData();
  }

  async function onAgentWiseFilterApplyClick() {
    getAgentWiseGraphData();
  }

  useEffect(() => {
    fetchData();
    getAgentWiseGraphData();
  }, []);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Field App Dashboard'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        sx={{
          border: 'none',
          marginBottom: '1rem',
        }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{ border: BORDER_COLLECTION, borderRadius: '12px', p: 3 }}
            >
              <Grid container direction={'row'} gap={1} rowGap={2}>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.from_date)}
                  >
                    <VegaDatePicker
                      value={filterData?.from_date ?? null}
                      onChange={updateFromDate}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.to_date)}
                  >
                    <VegaDatePicker
                      value={filterData?.to_date ?? null}
                      onChange={updateToDate}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.disposition_Code)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getDispositionCodeValues())}
                      onClear={() =>
                        updateFormData(FilterInputType.disposition_Code, '')
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.disposition_Code,
                          selected
                        )
                      }
                      selected={filterData?.disposition_Code ?? ''}
                      placeHolder="Select Disposition Code"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.disposition_type)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getDispositionTypeValues())}
                      onClear={() =>
                        updateFormData(FilterInputType.disposition_type, '')
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.disposition_type,
                          selected
                        )
                      }
                      selected={filterData?.disposition_Type ?? ''}
                      placeHolder="Select Disposition Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType?.agentId)}
                  >
                    <AgentSelector
                      selected={filterData?.agentId ?? ''}
                      onSelected={selected =>
                        updateFormData(
                          FilterInputType.agentId,
                          selected as string
                        )
                      }
                      onClear={() =>
                        updateFormData(FilterInputType.agentId, '')
                      }
                      ignoreLodash
                      ignoreAgentCode={false}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(PerformanceFilterInputType.SEGMENT)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getVerticalValues())}
                      onClear={() =>
                        updateFormData(FilterInputType.segment, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.segment, selected)
                      }
                      selected={filterData?.segment ?? ''}
                      placeHolder="Select Segmet"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(PerformanceFilterInputType.BUCKET)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getBucketValue()}
                      onClear={() => updateFormData(FilterInputType.bucket, '')}
                      onSelect={selected =>
                        updateFormData(FilterInputType.bucket, selected)
                      }
                      selected={filterData?.bucket ?? ''}
                      placeHolder="Select bucket"
                      ignoreLodash
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(PerformanceFilterInputType.DUE_DATE)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getDueDateValues())}
                      onClear={() => updateFormData(FilterInputType.cycle, '')}
                      onSelect={selected =>
                        updateFormData(FilterInputType.cycle, selected)
                      }
                      selected={filterData.cycle ?? ''}
                      placeHolder="Select Due date"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(PerformanceFilterInputType.BRANCH)}
                  >
                    <AllBranchSelector
                      selected={filterData?.branch ?? ('' as string)}
                      handleChange={selected =>
                        updateFormData(FilterInputType.branch, selected)
                      }
                      onClear={() => updateFormData(FilterInputType.branch, '')}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType?.non_Payment_Reason)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getNonPaymentreasonValues())}
                      onClear={() =>
                        updateFormData(FilterInputType.non_Payment_Reason, '')
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.non_Payment_Reason,
                          selected
                        )
                      }
                      selected={filterData?.non_Payment_Reason ?? ''}
                      placeHolder="Select Non Payment Reason"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Model Parser Allocation Type'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(
                        Object.values(ModelParserPreditionOptions)
                      )}
                      onClear={() =>
                        updateFormData(
                          FilterInputType.model_Parser_Allocation_Type,
                          ''
                        )
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.model_Parser_Allocation_Type,
                          selected
                        )
                      }
                      selected={filterData.model_Parser_Allocation_Type ?? ''}
                      placeHolder="Select Model Parser Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Region'}>
                    <RegionSelector
                      selected={filterData?.region ?? ''}
                      handleChange={selected =>
                        updateFormData(FilterInputType.region, selected)
                      }
                      onClear={() => updateFormData(FilterInputType.region, '')}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Action Type'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getActionTypeOptions()}
                      onClear={() =>
                        updateFormData(FilterInputType.actionType, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.actionType, selected)
                      }
                      selected={toLowerCase(filterData?.actionType) ?? ''}
                      placeHolder="Select Action Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Mode Of Communication'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getModeOfCommunicationOptions()}
                      onClear={() =>
                        updateFormData(FilterInputType.modeOfCommunication, '')
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.modeOfCommunication,
                          selected
                        )
                      }
                      selected={
                        toLowerCase(filterData?.modeOfCommunication) ?? ''
                      }
                      placeHolder="Select Mode Of Communication"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Model Prediction'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getModelPredictionOptions()}
                      onClear={() =>
                        updateFormData(
                          FilterInputType.collectionModelPrediction,
                          ''
                        )
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.collectionModelPrediction,
                          selected
                        )
                      }
                      selected={
                        toLowerCase(filterData?.collectionModelPrediction) ?? ''
                      }
                      placeHolder="Select Model prediction"
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
              <Grid
                container
                direction={'row'}
                gap={1}
                sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
              >
                <VegaButton
                  text="Clear Filter"
                  onClick={() => clearFilter()}
                  variant="outlined"
                  sx={{
                    ':hover': {
                      bgcolor: 'transparent',
                      color: '#1047DC',
                    },
                  }}
                />
                <VegaButton text="Apply Filter" onClick={onApplyFilterClick} />
              </Grid>
            </Grid>
          );
        }}
      />
      <VegaPageContent sx={{ mt: 2 }}>
        <FieldAppDashboardSummary
          data={visitStatisticsSummaryData}
          loading={loading}
        />
      </VegaPageContent>
      <VegaPageContent
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 3,
            bgcolor: 'white',
            borderRadius: '12px',
            border: BORDER_COLLECTION,
          }}
        >
          <AccountAndDispositionCodeGraph
            data={visitsPerDispositionCode}
            loading={loading}
          />
        </Box>
      </VegaPageContent>
      <VegaPageContent
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 3,
            bgcolor: 'white',
            borderRadius: '12px',
            border: BORDER_COLLECTION,
          }}
        >
          <NoOfVisitsPerDispositionGroup
            data={dispositionCodeData}
            loading={loading}
          />
        </Box>
      </VegaPageContent>
      <VegaPageContent sx={{ mt: 2 }}>
        <Stack
          direction={'row'}
          sx={{
            p: 3,
            bgcolor: 'white',
            borderRadius: '12px',
            border: BORDER_COLLECTION,
          }}
        >
          <Stack width={'50%'} sx={{ paddingRight: 4 }}>
            <NumberOfVisitsDayWiseGraph data={visitsPerDay} loading={loading} />
          </Stack>
          <Stack width={'50%'}>
            <DistanceTravelledDayWiseGraph
              data={distanceTravelledPerDay}
              loading={loading}
            />
          </Stack>
        </Stack>
      </VegaPageContent>
      <VegaPageContent sx={{ mt: 2 }}>
        <GeoAndNonGeoBreakupGraph
          data={geoAndNonGeoTaggedBreakUp}
          loading={loading}
        />
      </VegaPageContent>
      <VegaPageContent sx={{ mt: 2, marginBottom: 2 }}>
        <GraphWrapper
          sx={{
            p: 3,
            bgcolor: 'white',
            borderRadius: '12px',
            border: BORDER_COLLECTION,
          }}
          title=""
          rightComponent={
            <Grid
              container
              sx={{
                border: BORDER_COLLECTION,
                borderRadius: '12px',
                p: 3,
                justifyContent: 'space-between',
              }}
              direction={'row'}
            >
              <Grid container direction={'row'} gap={1} rowGap={2}>
                <Grid xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Select Agents'}>
                    <MultiSelectAgentSelector
                      ignoreAgentCode={false}
                      ignoreLodash={true}
                      selected={agentWiseDataFilters.agentIds}
                      onSelect={selected => onAgentSelect(selected)}
                      onDelete={selectedIndex => onAgentDelete(selectedIndex)}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.from_date)}
                  >
                    <VegaDatePicker
                      value={agentWiseDataFilters.fromDate ?? ''}
                      onChange={updateAgentWiseFromDate}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.to_date)}
                  >
                    <VegaDatePicker
                      value={agentWiseDataFilters.toDate ?? ''}
                      onChange={updateAgentWiseToDate}
                      minDate={agentWiseDataFilters.fromDate}
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
              <Grid
                container
                direction={'row'}
                gap={1}
                sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
              >
                <VegaButton
                  text="Clear Filter"
                  onClick={onAgentWiseFilterClearClick}
                  variant="outlined"
                  sx={{
                    ':hover': {
                      bgcolor: 'transparent',
                      color: '#1047DC',
                    },
                  }}
                />
                <VegaButton
                  text="Apply Filter"
                  onClick={onAgentWiseFilterApplyClick}
                />
              </Grid>
            </Grid>
          }
        >
          <Grid container gap={2.6}>
            <Grid item xs={6} sm={6} md={4} lg={6}>
              <NoOfVisitsPerAgentGraph
                data={numberOfVisitAndNumberOfAccountsAgentWiseData}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={5.8}>
              <NoOfAccountsPerAgentGraph
                data={numberOfVisitAndNumberOfAccountsAgentWiseData}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={12}>
              <DistanceTravelledAgentWiseGraph
                data={distanceTravelledAgentWiseData}
                loading={loading}
              />
            </Grid>
          </Grid>
        </GraphWrapper>
      </VegaPageContent>
    </>
  );
}

export default FieldAppDashboard;

const getDispositionCodeValues = () =>
  Object.values(DispositionCode).map(item => {
    return item;
  });
const getDispositionTypeValues = () =>
  Object.values(DispositionType).map(item => {
    return item;
  });
const getNonPaymentreasonValues = () =>
  Object.values(NonPaymentReason).map(item => {
    return item;
  });
const getOptions = (options: string[]) => {
  const modifyOptions = options.map(
    (option: string) =>
      ({
        label: toLowerCase(option),
        value: option,
      } as VegaSelectOption)
  );
  return modifyOptions;
};
const getVerticalValues = () =>
  Object.values(VerticalTypes).map(item => {
    return item;
  });
const getBucketValue = () => {
  return Object.entries(BucketOption).map(([value, label]) => ({
    value,
    label,
  }));
};
const getDueDateValues = () =>
  Object.values(DueDateTypes).map(item => {
    return item;
  });

const getActionTypeOptions = () => {
  return Object.entries(ActionType).map(([value, label]) => ({
    value,
    label,
  }));
};

const getModeOfCommunicationOptions = () => {
  return Object.entries(ModeOfCommunication).map(([value, label]) => ({
    value,
    label,
  }));
};

const getModelPredictionOptions = () => {
  return Object.entries(LoanStatus).map(([value, label]) => ({
    value,
    label,
  }));
};

const filteredOptions = (searchValue: string, list: VegaSelectOption[]) => {
  if (searchValue.length) {
    const searchLowerCase = searchValue.toLowerCase();
    const filteredResults = list.filter(value => {
      if (searchValue === '') {
        return value;
      } else if (value.label.toLowerCase().includes(searchLowerCase)) {
        return value;
      }
    });
    return filteredResults;
  } else {
    return list;
  }
};
