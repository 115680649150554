import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TeleAgentService } from '../../Apis/TeleAgentServices';
import VegaText from '../../components/common/VegaText';
import { isCapriUATEnv, isCapriProdEnv } from '../../constants/url';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { TeleAgentListProps } from '../../types/teleAgent';
import { getErrorMessageFromErrorObj } from '../../utils/api';

function SlashRtcView() {
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [agentDetails, setAgentDetails] = useState<TeleAgentListProps | null>(
    null
  );

  async function getTeleAgentByuserId() {
    try {
      setAgentDetails(null);
      setLoading(true);
      const res = await TeleAgentService.getTeleAgentList({
        userId: user.id,
      });
      const agent = res.records[0];
      setAgentDetails(agent);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getSlashRtcUrl = () => {
    const UAT_URL = 'https://uatcapriglobal.slashrtc.in';
    const DEV_URL = 'https://uatcapriglobal.slashrtc.in';
    const PROD_URL = 'https://capriglobalcollection.slashrtc.in';
    var BASE_URL = '';

    if (isCapriProdEnv) {
      BASE_URL = PROD_URL;
    } else {
      BASE_URL = UAT_URL;
    }

    const url =
      BASE_URL +
      `/index.php/ssoLogin?1=1&crmUniqueId=${agentDetails.encryptedCrmId}&usernameId=${agentDetails.username}`;

    return url;
  };

  useEffect(() => {
    user && getTeleAgentByuserId();
  }, [user]);

  return (
    <div
      style={{
        height: '100%',
        padding: '1rem',
        flex: 1,
      }}
    >
      {loading && <CircularProgress />}
      {loading == false && agentDetails == null && (
        <div
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VegaText text={'No Agent Found'}></VegaText>
        </div>
      )}
      {loading == false && agentDetails != null && (
        <iframe
          src={getSlashRtcUrl()}
          width="100%"
          height="100%"
          allow="camera;microphone"
        />
      )}
    </div>
  );
}

export default SlashRtcView;
