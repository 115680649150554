export class NumberUtility {
  /**
   * Formats a number to a specified number of decimal places.
   * @param value The number to format.
   * @param decimals The number of decimal places.
   * @returns The formatted number as a string.
   */
  static formatToDecimal(value: number, decimals: number): string {
    if(!isFinite(value)){
      return "-"
    }
    return value.toFixed(decimals);
  }

  /**
   * Generates a random integer between min and max (inclusive).
   * @param min The minimum value (inclusive).
   * @param max The maximum value (inclusive).
   * @returns A random integer between min and max.
   */
  static randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
