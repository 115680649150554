import { UserService } from '../../Apis/UserService';
import { EscalationStatus } from '../../types/request/escalationsRequest';
import { COLOR } from '../../utils/ColorUtility';
import { StringUtility } from '../../utils/StringUtility';

export const getColorForLevel = (status?: string) => {
  if (!status) return;
  switch (status) {
    case 'LEVEL_1':
      return COLOR.GREEN;

    case 'LEVEL_2':
      return COLOR.ORANGE;
    case 'LEVEL_3':
      return COLOR.RED;
  }
};
export const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case EscalationStatus.RESOLVED:
      return COLOR.GREEN;

    case EscalationStatus.ESCALATED:
      return COLOR.ORANGE;
    case EscalationStatus.REJECTED:
      return COLOR.RED;
  }
};

export const getUserInfo = async (id: string) => {
  if (id) {
    try {
      const response = await UserService.getUserById(id);
      const displayText = StringUtility.concatenateStrings(
        response.firstName,
        response.middleName,
        response.lastName
      );
      return displayText;
    } catch (error) {
      return error;
    }
  }
};
