import { Cancel } from '@mui/icons-material';
import { Grid, IconButton, Skeleton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RoleServices } from '../../../Apis/RoleServices';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaMultiSelect from '../../../components/common/VegaMultiSelect';
import { VegaPill } from '../../../components/common/VegaPill';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import VegaTooltip from '../../../components/common/VegaTooltip';
import { UserActions, getUAM } from '../../../features/UAMSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AssignRoleProps, RoleDto, RolesProps } from '../../../types/roleType';
import { COLOR } from '../../../utils/ColorUtility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import AllBranchSelector from '../../MinVisits/modules/AllBranchSelector';
import RegionSelector from '../../MinVisits/modules/RegionSelector';
import { AssignRoleStateProps } from '../modules/AssignRoleDrawer';

interface IProps {
  assignRoleState: AssignRoleStateProps;
  selectedRole: any;
  setAssignRoleState: React.Dispatch<
    React.SetStateAction<AssignRoleStateProps>
  >;
  setSelectedRole: React.Dispatch<React.SetStateAction<any>>;
}

const AssignRoleForm = ({
  assignRoleState,
  selectedRole,
  setAssignRoleState,
  setSelectedRole,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const {
    roles: roleList,
    loadingOfRoles,
    userDetails,
  } = useAppSelector(getUAM);
  const dispatch = useAppDispatch();
  const [roles, setRoles] = useState<VegaSelectOption[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (key: keyof AssignRoleProps, value: any) => {
    setAssignRoleState({ ...assignRoleState, [key]: value });
  };

  const getRolesList = () => {
    setLoading(true);
    RoleServices.getRoles()
      .then(res => {
        const rolesList = res.length
          ? res?.map(
              (role: RolesProps) =>
                ({
                  label: role.name,
                  value: role.id,
                } as VegaSelectOption)
            )
          : [];
        setRoles(rolesList);
        setLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setLoading(false);
      });
  };
  const removeRole = (role: RoleDto) => {
    const request = {
      userId: role.userId,
      roleIds: [role.roleDto.id],
      branchList: [role.branch],
      regionList: [role.region],
    } as AssignRoleProps;

    RoleServices.unAssignRole(request)
      .then(() => {
        dispatch(UserActions.fetchUsers({ page: 0, size: 10 }));
        dispatch(UserActions.fetchRoleForUsers(role.userId));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const onRegionSelect = (region: string) => {
    const preRegion = [...assignRoleState.regionList];
    if (preRegion.includes(region)) {
      const filterRegion = preRegion.filter(
        (selectedRegion: string) => selectedRegion !== region
      );
      handleChange('regionList', filterRegion);
    } else {
      handleChange('regionList', [...preRegion, region]);
    }
  };
  const onRegionDelete = (index: number) => {
    const preRegion = [...assignRoleState.regionList];
    preRegion.splice(index, 1);
    handleChange('regionList', preRegion);
  };
  const onBranchSelect = (branch: string) => {
    const preBranch = [...assignRoleState.branchList];
    if (preBranch.includes(branch)) {
      const filterRegion = preBranch.filter(
        (selectedBranch: string) => selectedBranch !== branch
      );
      handleChange('branchList', filterRegion);
    } else {
      handleChange('branchList', [...preBranch, branch]);
    }
  };
  const onBranchDelete = (index: number) => {
    const preBranch = [...assignRoleState.branchList];
    preBranch.splice(index, 1);
    handleChange('branchList', preBranch);
  };

  useEffect(() => {
    getRolesList();
  }, []);

  useEffect(() => {
    userDetails && dispatch(UserActions.fetchRoleForUsers(userDetails?.id));
  }, [userDetails]);

  return (
    <>
      <Grid container spacing={'1.25rem'}>
        <Grid item xs={12}>
          <VegaFormInputField label={'Assigned Or Remove Role'}>
            {loadingOfRoles ? (
              <Skeleton />
            ) : (
              <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {roleList.length ? (
                  roleList.map((role: RoleDto) => (
                    <div
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      key={role.id}
                      onMouseEnter={() => setSelectedRoleId(role.roleDto.id)}
                      onMouseLeave={() => setSelectedRoleId('')}
                    >
                      <VegaPill
                        text={role.roleDto?.name}
                        backgroundColor={
                          getColorForStatus(role.roleDto?.name)?.LIGHT
                        }
                        textColor={getColorForStatus(role.roleDto?.name)?.DARK}
                      />
                      {role.roleDto.id === selectedRoleId && (
                        <VegaTooltip
                          title={`Remove ${role.roleDto?.name} Role`}
                        >
                          <IconButton
                            sx={{
                              position: 'absolute',
                              right: -20,
                              top: -10,
                              zIndex: 9999,
                            }}
                            onClick={() => removeRole(role)}
                          >
                            <Cancel
                              sx={{
                                color: COLOR.RED.DARK,
                              }}
                            />
                          </IconButton>
                        </VegaTooltip>
                      )}
                    </div>
                  ))
                ) : (
                  <VegaText text={'No Assign roles for this user'} />
                )}
              </Stack>
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12}>
          <VegaFormInputField label={'Role'}>
            <VegaMultiSelect
              isLoading={loading}
              options={getFitteredRoles(roles, roleList)}
              value={selectedRole}
              onChange={selected => {
                setSelectedRole(selected);
              }}
              placeholder="Select role"
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12}>
          <VegaFormInputField label={'Region'}>
            <RegionSelector
              isMultiSelect
              selected={assignRoleState.regionList}
              handleChange={selected => onRegionSelect(selected)}
              handleDelete={index => onRegionDelete(index)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12}>
          <VegaFormInputField label={'Branch'}>
            <AllBranchSelector
              isMultiSelect
              selected={assignRoleState.branchList}
              handleChange={selected => onBranchSelect(selected)}
              handleDelete={index => onBranchDelete(index)}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </>
  );
};

export default AssignRoleForm;

const getColorForStatus = (status?: string) => {
  if (!status) return;

  return COLOR.RED;
};

const getFitteredRoles = (
  roles: VegaSelectOption[],
  selectedRoles: RoleDto[]
) => {
  const matchingLabels = selectedRoles.map(
    (selectedRole: RoleDto) => selectedRole.roleDto.name
  );
  const filteredRoles = roles.filter(
    (role: VegaSelectOption) => !matchingLabels.includes(role.label)
  );

  return filteredRoles;
};
