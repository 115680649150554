import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import { ChargeCollectionType } from '../../../types/incentives';
import { BasePolicyRangeProps } from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  rules: BasePolicyRangeProps[];
}
const ViewBaseIncentiveForChargeCollected = ({ loading, rules }: IProps) => {
  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Collection type'}>
            {getTextLoadingWrapper(
              `${toLowerCase(ChargeCollectionType.CHARGES_COLLECTED)}`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Payout metric'}>
            {getTextLoadingWrapper(
              `${toLowerCase(
                getFirstItemIfExists(rules)?.chargePayoutMetric ?? ''
              )}`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {rules?.length
        ? rules?.map((baseIncentive: BasePolicyRangeProps, index: number) => {
            return (
              <Stack gap={1} key={index}>
                <VegaText
                  text={`Perameter ${index + 1}`}
                  fontWeight={500}
                  fontSize={'0.75rem'}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <VegaFormInputField label={'parameter type'}>
                      {getTextLoadingWrapper(
                        `${toLowerCase(baseIncentive.parameterDefinitionType)}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  {(baseIncentive.parameterDefinitionType === 'RANGE_AMOUNT' ||
                    baseIncentive.parameterDefinitionType ===
                      'RANGE_PERCENTAGE') && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Stack
                        sx={{
                          width: '100%',
                        }}
                        direction={'row'}
                        gap={1}
                      >
                        <Stack sx={{ width: '50%' }}>
                          <VegaFormInputField label={'min value'}>
                            {getTextLoadingWrapper(
                              `${baseIncentive.parameterMin}`,
                              loading
                            )}
                          </VegaFormInputField>
                        </Stack>
                        <Stack sx={{ width: '50%' }}>
                          <VegaFormInputField label={'max value'}>
                            {getTextLoadingWrapper(
                              `${baseIncentive.parameterMax}`,
                              loading
                            )}
                          </VegaFormInputField>
                        </Stack>
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
                    <VegaFormInputField label={'value'}>
                      {getTextLoadingWrapper(
                        ` ${baseIncentive.payoutAmountValue}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
                    <VegaFormInputField label={'Max Payout'}>
                      {getTextLoadingWrapper(
                        `₹ ${baseIncentive.payoutAmountMax}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                </Grid>
              </Stack>
            );
          })
        : ''}
    </Stack>
  );
};

export default ViewBaseIncentiveForChargeCollected;

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return (
      <VegaText text={value ?? '--'} fontWeight={500} fontSize={'0.875rem'} />
    );
  }
};
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
