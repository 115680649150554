import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { LoanService } from '../../Apis/LoanServices';
import LmDialog from '../../components/common/LmDialog';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaText from '../../components/common/VegaText';
import { LmsVariable, LoanData } from '../../types/loan';
import { LoanListRequest } from '../../types/request/loan';
import { useClientAuth } from '../../providers/ClientProvider';

type props = {
  loanIds: string[];
  isUniqueLoanAccountDialogOpen: boolean;
  closeUniqueLoanAccountDialog: () => void;
};
function UniqueLoanAccountsDialog({
  loanIds,
  closeUniqueLoanAccountDialog,
  isUniqueLoanAccountDialogOpen,
}: props) {
  const [loanDetails, setLoanDetails] = useState<LoanData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  async function getLoanListDeatils() {
    const ids = loanIds.length ? loanIds : [];
    const request = {
      page,
      size: 10,
      loanIdList: ids,
      includeCustomerDetails: true,
      includeMetaDataLoanAllocation: true,
      includeLoanOutstandingDetails: true,
      restrictedView: true,
    } as LoanListRequest;

    const response = await LoanService.getLoanList(request);
    setLoanDetails(response.records);
    setTotalItems(response.totalItems);
  }

  useEffect(() => {
    if (isUniqueLoanAccountDialogOpen) {
      getLoanListDeatils();
    }
  }, [page, isUniqueLoanAccountDialogOpen]);

  return (
    <div>
      <LmDialog
        fullScreen
        maxWidth="lg"
        isCloseIcon
        open={isUniqueLoanAccountDialogOpen}
        handleClose={closeUniqueLoanAccountDialog}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Unique Accounts"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaPageContent sx={{ marginTop: 4, marginBottom: 4 }}>
          <VegaDataGrid
            data={loanDetails}
            columns={ColumnDef()}
            idColumn={'loanId'}
            page={page}
            pageSize={10}
            rowCount={totalItems}
            onPageChange={page => setPage(page)}
          />
        </VegaPageContent>
      </LmDialog>
    </div>
  );
}

export default UniqueLoanAccountsDialog;
function ColumnDef() {
  const { agentIdToManager, userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const getManagerName = (userId: string) => {
    if (!userId) return 'NA';
    const manager = agentIdToManager(userId);
    if (!manager) return 'NA';
    return manager;
  };

  const COL_DEF: GridColumns = [
    {
      field: 'loanId',
      headerName: 'Loan Account Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const userName = loan?.lmsVariables?.loanId
          ? loan?.lmsVariables?.loanId
          : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const userName = loan?.lmsVariables?.customerName
          ? loan?.lmsVariables?.customerName
          : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const customerDetails = getFirstItemIfExists(loan?.customerDetails);
        const display = customerDetails ? customerDetails.mobileNo : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'address',
      headerName: 'Customer Address',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const customerDetails = getFirstItemIfExists(loan?.customerDetails);
        const display = customerDetails ? customerDetails.customerAddress : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'managerName',
      headerName: 'Manager Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const lmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (lmsVariable.agentId) {
          displayText = `${getManagerName(lmsVariable.agentId)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentName',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? getUserName(loan.lmsVariables.agentId) : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'dpd',
      headerName: 'DPD',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.dpd : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'overdueAmount',
      headerName: 'Overdue Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.overdueAmount : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.bucket : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'cycleDate',
      headerName: 'Cycle Date',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.cycleDate : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'branchCode',
      headerName: 'Branch Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.branchCode : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'modelPrediction',
      headerName: 'Model prediction',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan
          ? loan.lmsVariables?.modelParserAllocationType
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.segment : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.loanMaster.loanProduct : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
}

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
