import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { IncentiveServices } from '../../../../Apis/IncentivesService';
import LmFilterSection from '../../../../components/common/LmFilterSection';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import VegaTabBar from '../../../../components/common/VegaTabBar';
import VegaTabBarItemWithNotification from '../../../../components/common/VegaTabBarItemWithNotification';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import { getIncentivesGamificationsState } from '../../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { getPolicies } from '../../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useDrawer } from '../../../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  IncentivePolicyReviewStatus,
  ViewIncentiesGamificationsModal,
} from '../../../../types/incentives';
import {
  FetchWirteOffPolicyListRequest,
  IncentiveApprovalStatus,
  IncentivePolicyApprovalStatus,
  WriteOffAgentViewListRequest,
  WriteOffAgentViewListResponseDto,
  WriteOffRewardRecordsResponseDto,
  WriteOffSplitData,
} from '../../../../types/request/incentives';
import SetSplitDrawer, { SetSplitFormData } from './SetSplitDrawer';
import ViewSplitDrawer from './ViewSplitDrawer';
import { AgentsWriteOffColumn } from './AgentsWriteOffColumn';
import {
  PaginatedResponse,
  getErrorMessageFromErrorObj,
} from '../../../../utils/api';
import { WriteOffPolicyPayoutColumn } from './WriteOffPolicyPayoutColumn';
import { StorageKeys, StorageUtility } from '../../../../utils/StorageUtility';
import {
  LongRunningTask,
  LongRunningTaskStatus,
} from '../../../../types/allocations';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { AllocationService } from '../../../../Apis/AllocationService';
import { StringUtility } from '../../../../utils/StringUtility';

export enum WriteOffPolicyPayoutTabs {
  DRAFT = 'DRAFT',
  PENDING = 'IN_REVIEW',
  SPLIT_APPROVED = 'SPLIT_APPROVED',
  REJECTED = 'REJECTED',
}

export interface CountResponse {
  DRAFT: number;
  IN_REVIEW: number;
  SPLIT_APPROVED: number;
  REJECTED: number;
}

export enum ViewType {
  VIEW_OF_AGENTS = 'VIEW_OF_AGENTS',
  VIEW_OF_LOAN_ACCOUNTS = 'VIEW_OF_LOAN_ACCOUNTS',
}

const getRequestId = StorageUtility.getItem<string>(
  StorageKeys.SPLIT_APPROVED_REQUEST_ID
)
  ? StorageUtility.getItem<string>(StorageKeys.SPLIT_APPROVED_REQUEST_ID)
  : '';

function WriteOffPolicyPayout() {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedPolicy, setSelectedPolicy] = useState<string>('');
  const { policies } = useAppSelector(getIncentivesGamificationsState);
  const [viewType, setViewType] = useState<ViewType>(ViewType.VIEW_OF_AGENTS);
  const [requestId, setRequestId] = useState<string>(getRequestId);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [searchLoanId, setSearchLoanId] = useState<string>('');
  const dispatch = useAppDispatch();
  const [writeOffData, setWirteOffData] = useState<
    WriteOffRewardRecordsResponseDto[]
  >([]);
  const [agentsWriteOffData, setAgentsWriteOffData] = useState<
    WriteOffAgentViewListResponseDto[]
  >([]);
  // const [splitApprovedDownloadLoading, setSplitApprovedDownloadLoading] =
  //   useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [count, setCount] = useState<CountResponse>({
    DRAFT: 0,
    IN_REVIEW: 0,
    SPLIT_APPROVED: 0,
    REJECTED: 0,
  });
  const {
    open: openSetSplitDrawer,
    close: closeSetSplitDrawer,
    isOpen: isSetSplitDrawerOpen,
    props: setSplitDrawerProps,
  } = useDrawer();
  const {
    open: openViewSplitDrawer,
    close: closeViewSplitDrawer,
    isOpen: isViewSplitDrawerOpen,
    props: viewSplitDrawerProps,
  } = useDrawer();

  function _onTabChange(value: number): void {
    setSelectedTab(value);
    setSearchLoanId('');
    setPage(0);
    setSize(10);
  }

  const onAction = (wirteOffData: WriteOffRewardRecordsResponseDto) => {
    switch (wirteOffData.approvalStatus) {
      case IncentivePolicyApprovalStatus.IN_REVIEW:
        {
          console.log(writeOffData);
          openViewSplitDrawer({ writeOffData: wirteOffData });
        }
        break;
      case IncentivePolicyApprovalStatus.APPROVED:
        {
          openViewSplitDrawer({ writeOffData: wirteOffData });
        }
        break;
      case IncentivePolicyApprovalStatus.REJECTED:
        {
          openSetSplitDrawer({ writeOffData: wirteOffData });
        }
        break;
      case IncentivePolicyApprovalStatus.DRAFT:
        {
          openSetSplitDrawer({ writeOffData: wirteOffData });
        }
        break;
    }
  };

  const getWriteOffPolicies = async () => {
    try {
      setLoading(true);

      if (viewType === ViewType.VIEW_OF_LOAN_ACCOUNTS) {
        await fetchWriteOffPoliciesForLoanAccounts();
      } else {
        await fetchWriteOffPolicyAgentView();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchWriteOffPolicyAgentView = async () => {
    const request: Partial<WriteOffAgentViewListRequest> = {
      policyId: selectedPolicy,
    };
    if (searchLoanId?.length) {
      request.agentName = searchLoanId;
    }
    const response = await IncentiveServices.writeOffAgentViewList(request);
    setAgentsWriteOffData(response.records);
    setTotalItems(response?.totalItems);
  };

  const fetchWriteOffPoliciesForLoanAccounts = async () => {
    const status = Object.keys(IncentivePolicyApprovalStatus)[selectedTab];
    const request = createRequestObject(status);

    const response = await IncentiveServices.writeOffList(request);
    handleResponse(response);
  };

  const createRequestObject = status => {
    const request: Partial<FetchWirteOffPolicyListRequest> = {
      policyId: selectedPolicy,
      approvalStatuses: [status],
      page,
      size,
    };

    if (searchLoanId?.length) {
      request.loanId = searchLoanId.toUpperCase();
    }

    return request;
  };

  const handleResponse = (
    response: PaginatedResponse<WriteOffRewardRecordsResponseDto>
  ) => {
    setWirteOffData(response.records);
    setTotalItems(response.totalItems);

    if (searchLoanId?.length) {
      updateCount(response.totalItems);
    } else {
      getCount();
    }
  };

  const updateCount = totalItems => {
    setCount(prev => {
      const tabValue = Object.values(WriteOffPolicyPayoutTabs).at(selectedTab);
      return {
        ...prev,
        [tabValue]: totalItems,
      };
    });
  };

  const transformToWriteOffSplitData = (
    data: SetSplitFormData,
    writeOffData: WriteOffRewardRecordsResponseDto
  ): Record<string, WriteOffSplitData> => {
    const totalAmount = writeOffData.finalLoanIncentiveAmount;

    const createWriteOffSplitData = (
      roleName: string,
      amount: number
    ): WriteOffSplitData => ({
      roleName,
      splitPercentage: amount,
      splitAmount: (amount / 100) * totalAmount,
    });

    return {
      NCM: createWriteOffSplitData('NCM', data.NCM),
      ZCM: createWriteOffSplitData('ZCM', data.ZCM),
      RCM: createWriteOffSplitData('RCM', data.RCM),
      CCM: createWriteOffSplitData('CCM', data.CCM),
      ACM: createWriteOffSplitData('ACM', data.ACM),
      CM: createWriteOffSplitData('CM', data.CM),
      CE: createWriteOffSplitData('CE', data.CE),
    };
  };

  const onSetSplitClick = async (
    data: SetSplitFormData,
    writeOffData: WriteOffRewardRecordsResponseDto
  ) => {
    try {
      await IncentiveServices.updateWriteOffSplit({
        id: writeOffData.id,
        splitData: transformToWriteOffSplitData(data, writeOffData),
        approvalStatus: IncentivePolicyApprovalStatus.IN_REVIEW,
      });
      if (selectedPolicy?.length > 0) {
        getWriteOffPolicies();
      }
    } catch (error) {}
  };

  const onDownloadApprovedSplit = async () => {
    setDownloadInProgress(true);
    try {
      const response = await getDownloadUrl(selectedPolicy);
      if (response.status === 'COMPLETED') {
        setDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SPLIT_APPROVED_REQUEST_ID);
        setRequestId('');
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
      } else {
        StorageUtility.setItem(
          StorageKeys.SPLIT_APPROVED_REQUEST_ID,
          response.id
        );
        setRequestId(response.id);
        setDownloadInProgress(true);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getDownloadStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SPLIT_APPROVED_REQUEST_ID);
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
        setRequestId('');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SPLIT_APPROVED_REQUEST_ID);
        setSnackbar(response.result, 'error');
        setRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getDownloadUrl = async (policyId: string): Promise<LongRunningTask> => {
    if (viewType === ViewType.VIEW_OF_LOAN_ACCOUNTS) {
      return await IncentiveServices.downloadSplitApproved({
        policyId,
        approvalStatuses: [IncentiveApprovalStatus.APPROVED],
      });
    } else {
      return await IncentiveServices.downloadAgentWriteOffPolicyList({
        policyId,
      });
    }
  };

  const getCount = async () => {
    const response = await IncentiveServices.getWriteOffCount({
      policyId: selectedPolicy,
    });
    setCount(() => {
      return {
        ...response,
        SPLIT_APPROVED: response.APPROVED,
        PENDING: response.IN_REVIEW,
      };
    });
  };

  const getColumn = () => {
    if (viewType === ViewType.VIEW_OF_LOAN_ACCOUNTS) {
      return WriteOffPolicyPayoutColumn(onAction);
    } else {
      return AgentsWriteOffColumn();
    }
  };

  const refresh = () => {
    setPage(0);
    setSize(10);
    setSelectedTab(0);
  };

  const getColumnData = () => {
    if (viewType == ViewType.VIEW_OF_LOAN_ACCOUNTS) {
      return writeOffData;
    } else {
      return agentsWriteOffData;
    }
  };

  const getMaxCapping = () => {
    if (writeOffData?.length > 0) {
      return StringUtility.formatNumberToIndianNumberFormat(
        writeOffData[0]?.policyCapping
      );
    } else {
      return 0;
    }
  };

  const getSearchPlaceholder = () => {
    if (viewType === ViewType.VIEW_OF_AGENTS) {
      return 'Search By Agent Name';
    } else {
      return 'Search By LAN';
    }
  };

  useEffect(() => {
    if (policies?.length > 0) {
      setSelectedPolicy(policies[0]?.id);
    }
  }, [policies]);

  useEffect(() => {
    if (searchLoanId?.length) {
      const getWriteOffPoliciesData = setTimeout(getWriteOffPolicies, 300);
      return () => clearTimeout(getWriteOffPoliciesData);
    } else {
      if (selectedPolicy?.length > 0) {
        getWriteOffPolicies();
      }
    }
  }, [selectedPolicy, selectedTab, page, size, searchLoanId, viewType]);

  useEffect(() => {
    if (viewType === ViewType.VIEW_OF_LOAN_ACCOUNTS) {
      getCount();
      refresh();
    } else {
      refresh();
    }
  }, [viewType]);

  useEffect(() => {
    if (requestId.length) {
      const downloadingFile = setInterval(() => {
        getDownloadStatus(requestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [requestId]);

  useEffect(() => {
    dispatch(
      getPolicies({
        page: 0,
        pageSize: 1000,
        status: IncentivePolicyReviewStatus.ACTIVE,
        classTypes: ['WRITE_OFF'],
      })
    );
    setViewType(ViewType.VIEW_OF_AGENTS);
  }, []);

  return (
    <div>
      <VegaPageHeader
        title="Write - Off Policy Payout"
        sx={{ borderBottom: 'none' }}
        renderRightView={() => {
          return (
            <Grid container gap={2} direction={'row'}>
              <Grid item>
                <VegaSelect
                  options={getViewTypeOptions()}
                  value={viewType ?? ''}
                  onChange={e => {
                    setViewType(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <VegaSelect
                  options={getOptions(policies)}
                  value={selectedPolicy ?? ''}
                  onChange={e => {
                    setSelectedPolicy(e.target.value);
                  }}
                />
              </Grid>
              {(selectedTab === 2 || viewType === ViewType.VIEW_OF_AGENTS) && (
                <Grid item>
                  <VegaButton
                    text={'Download'}
                    onClick={onDownloadApprovedSplit}
                    // loading={splitApprovedDownloadLoading}
                    loading={downloadInProgress}
                  />
                </Grid>
              )}
            </Grid>
          );
        }}
      />
      {viewType === ViewType.VIEW_OF_LOAN_ACCOUNTS && (
        <VegaPageHeader
          isTabPresent
          renderRightView={() => {
            return (
              <VegaText
                text={`Max Capping per LAN:- ${getMaxCapping()}`}
                fontWeight={700}
                fontSize="13px"
                lineHeight="15.6px"
              />
            );
          }}
          renderLeftView={() => {
            return (
              <VegaTabBar
                value={selectedTab}
                variant="scrollable"
                onChange={(e, selected) => {
                  _onTabChange(selected);
                }}
              >
                {Object.keys(WriteOffPolicyPayoutTabs).map(tab => (
                  <VegaTabBarItemWithNotification
                    key={tab}
                    label={toLowerCase(tab)}
                    count={count[tab]}
                    // loading={countLoading}
                  />
                ))}
              </VegaTabBar>
            );
          }}
        />
      )}

      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '1rem', bgcolor: 'transparent' }}
        renderRightView={() => {
          return (
            <LmFilterSection
              hideFilter
              search={searchLoanId}
              setSearch={setSearchLoanId}
              searchPlaceHolder={getSearchPlaceholder()}
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => {
            setPage(page);
          }}
          onPageSizeChange={size => setSize(size)}
          paginationMode="client"
          data={getColumnData() ?? []}
          columns={getColumn()}
          loading={loading}
          idColumn="id"
        />
      </VegaPageContent>
      <SetSplitDrawer
        open={isSetSplitDrawerOpen}
        onClose={closeSetSplitDrawer}
        onSubmit={onSetSplitClick}
        data={setSplitDrawerProps?.writeOffData}
      />
      <ViewSplitDrawer
        open={isViewSplitDrawerOpen}
        onClose={closeViewSplitDrawer}
        data={viewSplitDrawerProps?.writeOffData}
      />
    </div>
  );
}

export default WriteOffPolicyPayout;

const getOptions = (options: ViewIncentiesGamificationsModal[]) => {
  const modifyOptions = options.map(
    (option: ViewIncentiesGamificationsModal) =>
      ({
        label: toLowerCase(option.policyName),
        value: option.id,
      } as VegaSelectOption)
  );
  return modifyOptions;
};

const getViewTypeOptions = () => {
  return Object.entries(ViewType).map(([value, label]) => ({
    value,
    label,
  }));
};
