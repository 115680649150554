/* eslint-disable no-undef */
import axios from 'axios';
import { PaginatedResponse } from '../utils/api';
import { TeleAgentListProps, leadRemainCount } from '../types/teleAgent';
import {
  CreateTeleAgentRequest,
  TeleAgentListRequest,
} from '../types/request/teleAgent';

const Endpoint = {
  BASE: '/master/capri',
  USER: '/user',
  TELE_AGENT: '/teleagent',
  _TELE_AGENT: '/teleAgent',
  LIST: '/list',
  TELE_AGENT_BY_ID: '/process/:id',
  LEAD_COUNT_BY_PROCESS_ID: '/process/leadCount/:processId',
  EXPORT_AUDIT: '/user/audit/export',
};

const TELE_AGENT_URL = Endpoint.BASE + Endpoint.USER;

export class TeleAgentService {
  static async getTeleAgentList(
    request: Partial<TeleAgentListRequest>
  ): Promise<PaginatedResponse<TeleAgentListProps>> {
    const endpoint = TELE_AGENT_URL + Endpoint.TELE_AGENT + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getNoOfTeleAgent(id: number): Promise<TeleAgentListProps[]> {
    const convertedId = typeof id === 'number' ? id.toString() : id;
    const endpoint =
      TELE_AGENT_URL +
      Endpoint._TELE_AGENT +
      Endpoint.TELE_AGENT_BY_ID.replace(':id', convertedId);
    const response = await axios.post(endpoint);
    return response.data;
  }
  static async getLeadCount(processId: number): Promise<leadRemainCount> {
    const convertProcessId =
      typeof processId === 'number' ? processId.toString() : processId;
    const endpoint =
      Endpoint.BASE +
      Endpoint.LEAD_COUNT_BY_PROCESS_ID.replace(':processId', convertProcessId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getExportAuditLink(data: { agentId: string }) {
    const endpoint = Endpoint.BASE + Endpoint.EXPORT_AUDIT;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
  static async createTeleAgent(
    request: Partial<CreateTeleAgentRequest>
  ): Promise<any> {
    const endpoint = TELE_AGENT_URL + Endpoint._TELE_AGENT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
