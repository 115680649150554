import React, { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { RoleServices } from '../../../../Apis/RoleServices';
import { VegaUser } from '../../../../types/claim';
import { StringUtility } from '../../../../utils/StringUtility';
import { VegaSelectOption } from '../../../../components/common/VegaSelect';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import {
  ConfigValueProps,
  ConfigurationDto,
} from '../../../../types/configurationType';
import VegaAutoComplete from '../../../../components/common/VegaAutoComplete';
import { Skeleton } from '@mui/material';

const UserSelect = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<VegaSelectOption[]>([]);
  const [selectedUser, setSelectedUser] = useState<VegaSelectOption>({
    value: '',
    label: '',
  });
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== users.length) {
        setPage(page + 1);
      }
    }
  };
  const getUsersList = async () => {
    setLoading(true);
    try {
      const response = await RoleServices.getUsersFromRoleName({
        roleNames: ['NCM'],
      });
      const agentOptionList = response.records.map((agent: VegaUser) => {
        const agentName = StringUtility.concatenateStrings(
          ' ',
          agent.firstName,
          agent.middleName,
          agent.lastName
        );
        return {
          value: agent.id,
          label: agentName,
        };
      });
      if (page === 0) {
        setUsers(agentOptionList);
      } else {
        setUsers([...users, ...agentOptionList]);
      }
      setTotalItem(response.totalItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const handleSelect = (value: string) => {
    const filterdUser = users.find(
      (region: VegaSelectOption) => region.value === value
    );
    const config = {
      agentId: filterdUser.value,
      agentName: filterdUser.label,
    } as ConfigValueProps;
    const updateConfig = {
      ...configurations,
      config: config,
      subtypes: [filterdUser.label],
    } as ConfigurationDto;
    dispatch(setConfigurations(updateConfig));
    setSelectedUser({
      value: config.agentId,
      label: config.agentName,
    });
  };

  useEffect(() => {
    getUsersList();
    const ncmDpdConfig = { ...configurations?.config };
    const agentId = ncmDpdConfig?.agentId?.length ? ncmDpdConfig?.agentId : '';
    const agentName = ncmDpdConfig?.agentName?.length
      ? ncmDpdConfig?.agentName
      : '';
    if (agentId.length && agentName.length) {
      setSelectedUser({
        value: agentId,
        label: agentName,
      });
    }
  }, []);
  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <VegaAutoComplete
          options={users}
          onClear={() => {
            setSelectedUser({
              value: '',
              label: '',
            });
          }}
          handleChange={() => {}}
          onScroll={loadMoreItems}
          onSelect={selected => handleSelect(selected)}
          selected={selectedUser.value}
          placeHolder="Select User"
        />
      )}
    </>
  );
};

export default UserSelect;
