import { Box, Grid } from '@mui/material';
import LmTextField from '../common/LmTextField';
import { useBankDeposit } from '../../providers/BankDepositProvider';
import { fetchDepositBankById } from '../../Apis/depositBank';
import { useEffect, useState } from 'react';
import VegaFormInputField from '../common/VegaFormInputField';

const AddNewBankDeposit = () => {
  const {
    depositeBankState,
    selectedId,
    setDepositeBankState,
    handleDepositBankChange,
  } = useBankDeposit();
  const [loading, setLoading] = useState<boolean>(false);

  const getDepositBankById = async (id: string) => {
    try {
      const response = await fetchDepositBankById(id);
      setDepositeBankState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      setLoading(true);
      getDepositBankById(selectedId);
    }
  }, [selectedId]);
  return (
    <>
      <Box mb={2}>
        <VegaFormInputField label={'bank name'} isMandatory>
          <LmTextField
            loading={loading}
            placeholder="Enter Bank Name"
            value={depositeBankState?.bankName}
            onChange={e => {
              const value = e.target.value;
              handleDepositBankChange('bankName', value);
            }}
          />
        </VegaFormInputField>
      </Box>

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'Deposit Account Number'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter Account Number"
                value={depositeBankState?.accountNumber}
                onChange={e => {
                  const value = e.target.value;
                  handleDepositBankChange('accountNumber', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'Deposit Bank Code'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter Bank Code"
                value={depositeBankState?.bankCode}
                onChange={e => {
                  const value = e.target.value;
                  handleDepositBankChange('bankCode', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'Branch Name'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter Branch Name"
                value={depositeBankState?.branchName}
                onChange={e => {
                  const value = e.target.value;
                  handleDepositBankChange('branchName', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'Branch Code'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter Branch Code"
                value={depositeBankState?.branchCode}
                onChange={e => {
                  const value = e.target.value;
                  handleDepositBankChange('branchCode', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'IFSC'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter IFSC"
                value={depositeBankState?.ifsc}
                onChange={e => {
                  const value = e.target.value;
                  handleDepositBankChange('ifsc', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'MICR'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter MICR"
                value={depositeBankState?.micr}
                onChange={e => {
                  const value = e.target.value;
                  handleDepositBankChange('micr', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <VegaFormInputField label={'Segment'} isMandatory>
          <LmTextField
            loading={loading}
            placeholder="Enter Segment"
            value={depositeBankState?.segment}
            onChange={e => {
              const value = e.target.value;
              handleDepositBankChange('segment', value);
            }}
          />
        </VegaFormInputField>
      </Box>
    </>
  );
};

export default AddNewBankDeposit;
