import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  initialFixedBasePolicy,
  initialRangeBasePolicy,
  initialRangeBasePolicyForNoOfCases,
  setErrorPolicy,
  setPolicyDetailsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ChargeCollectionType,
  CollectionType,
  ParameterDefinitionType,
} from '../../../types/incentives';
import {
  BasePolicyRangeProps,
  IncentivePolicyRulesRequest,
} from '../../../types/request/incentives';
import {
  BucketEnum,
  BucketEnumForNPA,
  BucketEnumForNonNPA,
} from '../../../types/targets';
import { getOrdinalNumber } from '../../../utils/DateUtlility';
import { PlusCircleIcon } from '../../Icons/Icons';
import VegaButton from '../../common/VegaButton';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';
import BaseIncentive from './BaseIncentive';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import BaseIncentiveForChargeCollected from './BaseIncentiveForChargeCollected';

interface IProps {
  scrollTop: () => void;
}

const BaseIncentiveComponent = ({ scrollTop }: IProps) => {
  const {
    policyRuleState,
    policyEnumValues,
    selectedTab,
    loading,
    policyDetailsState,
  } = useAppSelector(getIncentivesGamificationsState);
  const { policyId } = useParams();
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const selectedBucketTab = getKeyFromTab(
    policyDetailsState.classType,
    selectedTab
  );

  const isChargeCollectedTab =
    selectedBucketTab === BucketEnumForNonNPA.CHARGES_COLLECTION;

  const handlePolicyRule = (
    key: keyof IncentivePolicyRulesRequest,
    value: any
  ) => {
    if (isChargeCollectedTab) {
      const chargesCollectedPayoutRange =
        policyRuleState?.chargesCollectedPayoutRange.map(item => {
          return { ...item, chargePayoutMetric: value };
        }) ?? [];

      const policyRuleStateObj = {
        ...policyRuleState,
        chargesCollectedPayoutRange: chargesCollectedPayoutRange,
        [key]: value,
      } as IncentivePolicyRulesRequest;

      dispatch(setPolicyRuleState(policyRuleStateObj));
    } else {
      if (key === 'collectionType') {
        const policyRuleStateObj = {
          ...policyRuleState,
          basePayoutRange:
            value === CollectionType.RESOLUTION
              ? [{ ...initialRangeBasePolicy }]
              : value === CollectionType.NO_OF_CASES_NORMALIZED
              ? [{ ...initialRangeBasePolicyForNoOfCases }]
              : [{ ...initialFixedBasePolicy }],
          [key]: value,
        } as IncentivePolicyRulesRequest;
        dispatch(setPolicyRuleState(policyRuleStateObj));
      } else {
        const policyRuleStateObj = {
          ...policyRuleState,
          [key]: value,
        } as IncentivePolicyRulesRequest;
        dispatch(setPolicyRuleState(policyRuleStateObj));
      }
    }
  };
  const onAddBaseIncentive = () => {
    if (isChargeCollectedTab) {
      if (
        policyRuleState.chargesCollectedPayoutRange[
          policyRuleState?.chargesCollectedPayoutRange?.length - 1
        ]?.parameterDefinitionType === 'FIXED'
      ) {
        const updatedPolicy = {
          ...initialRangeBasePolicy,
          parameterDefinitionType: 'FIXED',
          chargePayoutMetric: policyRuleState.payoutMetric,
        } as BasePolicyRangeProps;
        handlePolicyRule('chargesCollectedPayoutRange', [updatedPolicy]);
      } else {
        const updatedPolicy = {
          ...initialRangeBasePolicy,
          parameterDefinitionType: '',
          chargePayoutMetric: policyRuleState.payoutMetric,
          parameterMin:
            policyRuleState.chargesCollectedPayoutRange[
              policyRuleState.chargesCollectedPayoutRange?.length - 1
            ].parameterMax + 0.01,
        } as BasePolicyRangeProps;
        handlePolicyRule('chargesCollectedPayoutRange', [
          ...policyRuleState.chargesCollectedPayoutRange,
          updatedPolicy,
        ]);
      }
    } else {
      if (policyRuleState.collectionType === CollectionType.RESOLUTION) {
        const updatedPolicy = {
          ...initialRangeBasePolicy,
          parameterMin:
            policyRuleState.basePayoutRange[
              policyRuleState.basePayoutRange?.length - 1
            ].parameterMax + 0.01,
        } as BasePolicyRangeProps;
        handlePolicyRule('basePayoutRange', [
          ...policyRuleState.basePayoutRange,
          updatedPolicy,
        ]);
      } else if (
        policyRuleState.collectionType ===
          CollectionType.NO_OF_CASES_NORMALIZED &&
        policyDetailsState?.classType === BucketEnum.NPA
      ) {
        if (
          policyRuleState.basePayoutRange[
            policyRuleState?.basePayoutRange?.length - 1
          ]?.parameterDefinitionType === 'FIXED'
        ) {
          const updatedPolicy = {
            ...initialRangeBasePolicyForNoOfCases,
            parameterDefinitionType: 'FIXED',
            parameterExact:
              policyRuleState.basePayoutRange[
                policyRuleState.basePayoutRange?.length - 1
              ].parameterExact + 1,
          } as BasePolicyRangeProps;
          handlePolicyRule('basePayoutRange', [
            ...policyRuleState.basePayoutRange,
            updatedPolicy,
          ]);
        } else {
          const updatedPolicy = {
            ...initialRangeBasePolicyForNoOfCases,
            parameterDefinitionType: 'RANGE',
            parameterMin:
              policyRuleState.basePayoutRange[
                policyRuleState.basePayoutRange?.length - 1
              ].parameterMax + 0.01,
          } as BasePolicyRangeProps;
          handlePolicyRule('basePayoutRange', [
            ...policyRuleState.basePayoutRange,
            updatedPolicy,
          ]);
        }
      } else {
        const updatedPolicy = {
          ...initialFixedBasePolicy,
          parameterExact:
            policyRuleState.basePayoutRange[
              policyRuleState.basePayoutRange?.length - 1
            ].parameterExact + 1,
        } as BasePolicyRangeProps;
        handlePolicyRule('basePayoutRange', [
          ...policyRuleState.basePayoutRange,
          updatedPolicy,
        ]);
      }
    }
  };

  const onDeleteBaseIncentive = (index: number) => {
    const spreadBasePoicy = [...policyRuleState.basePayoutRange];
    spreadBasePoicy.splice(index, 1);
    handlePolicyRule('basePayoutRange', spreadBasePoicy);
  };
  const onDeleteChargeCollectedBaseIncentive = (index: number) => {
    const spreadBasePoicy = [...policyRuleState.chargesCollectedPayoutRange];
    spreadBasePoicy.splice(index, 1);
    handlePolicyRule('chargesCollectedPayoutRange', spreadBasePoicy);
  };

  const isEmptyOrUndefined = (value: any) => {
    return (
      typeof value === 'undefined' ||
      value === null ||
      value === '' ||
      value === 0
    );
  };
  const isNullOrUndefined = (value: any) => {
    return typeof value === 'undefined' || value === null || value === '';
  };

  const isParameterValue = () => {
    if (isChargeCollectedTab) {
      const isEmptyValue =
        policyRuleState.chargesCollectedPayoutRange?.length &&
        policyRuleState?.chargesCollectedPayoutRange.some(
          (range: BasePolicyRangeProps) => {
            if (range.parameterDefinitionType === 'FIXED') {
              return (
                isEmptyOrUndefined(range.payoutAmountValue) ||
                isEmptyOrUndefined(range.payoutAmountMax)
              );
            } else {
              return (
                isNullOrUndefined(range.parameterMin) ||
                isEmptyOrUndefined(range.parameterMax) ||
                isEmptyOrUndefined(range.payoutAmountMax) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            }
          }
        );
      return isEmptyValue;
    } else {
      const isEmptyValue =
        policyRuleState.basePayoutRange?.length &&
        policyRuleState?.basePayoutRange.some((range: BasePolicyRangeProps) => {
          if (
            policyRuleState.collectionType ===
              CollectionType.NO_OF_CASES_NORMALIZED &&
            policyDetailsState?.classType === BucketEnum.NPA
          ) {
            if (range.parameterDefinitionType === 'FIXED') {
              return (
                isEmptyOrUndefined(range.parameterExact) ||
                isEmptyOrUndefined(range.payoutAmountValue) ||
                isEmptyOrUndefined(range.payoutAmountMax) ||
                isEmptyOrUndefined(range.payoutAmountMin)
              );
            } else {
              return (
                isNullOrUndefined(range.parameterMin) ||
                isEmptyOrUndefined(range.parameterMax) ||
                isEmptyOrUndefined(range.payoutAmountMax) ||
                isEmptyOrUndefined(range.payoutAmountMin) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            }
          } else {
            if (
              policyRuleState.collectionType ===
              CollectionType.NO_OF_CASES_NORMALIZED
            ) {
              return (
                isEmptyOrUndefined(range.parameterExact) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            } else {
              return (
                isEmptyOrUndefined(range.parameterMin) ||
                isEmptyOrUndefined(range.parameterMax) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            }
          }
        });
      return isEmptyValue;
    }
  };

  const disabled = !policyRuleState?.payoutMetric?.length || isParameterValue();
  const onSubmitPolicy = () => {
    const getPolicyId = policyId ?? idFromURL;
    if (getPolicyId) {
      IncentiveServices.addRulesForPolicy({
        ...policyRuleState,
        ...(!isChargeCollectedTab ? { bucket: getBucket() } : { bucket: null }),
        incentiveBasePolicyId: getPolicyId,
      })
        .then(response => {
          dispatch(setPolicyDetailsState(response));
          setSnackbar('Add base incentive successfully !!!');
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      scrollTop();
      dispatch(setErrorPolicy('First fill or Update policy details'));
    }
  };

  const getDisabledButton = () => {
    if (isChargeCollectedTab) {
      if (policyRuleState?.chargesCollectedPayoutRange) {
        if (policyRuleState?.chargesCollectedPayoutRange?.length) {
          const range =
            policyRuleState?.chargesCollectedPayoutRange[
              policyRuleState?.chargesCollectedPayoutRange?.length - 1
            ];
          if (range.parameterDefinitionType === 'FIXED') {
            return (
              isEmptyOrUndefined(range.payoutAmountValue) ||
              isEmptyOrUndefined(range.payoutAmountMax)
            );
          } else {
            return (
              isNullOrUndefined(range.parameterMin) ||
              isEmptyOrUndefined(range.parameterMax) ||
              isEmptyOrUndefined(range.payoutAmountMax) ||
              isEmptyOrUndefined(range.payoutAmountValue)
            );
          }
        }
      }
    } else {
      if (policyRuleState?.basePayoutRange) {
        if (policyRuleState?.basePayoutRange?.length) {
          const range =
            policyRuleState?.basePayoutRange[
              policyRuleState?.basePayoutRange?.length - 1
            ];
          if (
            policyRuleState.collectionType ===
              CollectionType.NO_OF_CASES_NORMALIZED &&
            policyDetailsState?.classType === BucketEnum.NPA
          ) {
            if (range.parameterDefinitionType === 'FIXED') {
              return (
                isEmptyOrUndefined(range.parameterExact) ||
                isEmptyOrUndefined(range.payoutAmountValue) ||
                isEmptyOrUndefined(range.payoutAmountMax) ||
                isEmptyOrUndefined(range.payoutAmountMin)
              );
            } else {
              return (
                isNullOrUndefined(range.parameterMin) ||
                isEmptyOrUndefined(range.parameterMax) ||
                isEmptyOrUndefined(range.payoutAmountMax) ||
                isEmptyOrUndefined(range.payoutAmountMin) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            }
          } else {
            if (
              policyRuleState.collectionType ===
              CollectionType.NO_OF_CASES_NORMALIZED
            ) {
              return (
                isEmptyOrUndefined(range.parameterExact) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            } else {
              return (
                isEmptyOrUndefined(range.parameterMin) ||
                isEmptyOrUndefined(range.parameterMax) ||
                isEmptyOrUndefined(range.payoutAmountValue)
              );
            }
          }
        }
      }
    }
  };

  const getBucket = () => {
    if (policyDetailsState.classType === BucketEnum.NPA) {
      return BucketEnum.NPA;
    } else {
      if (selectedTab === 1) {
        return BucketEnum.BUCKET1;
      } else if (selectedTab === 2) {
        return BucketEnum.BUCKET2;
      } else if (selectedTab === 3) {
        return BucketEnum.BUCKET3;
      } else {
        return BucketEnum.BUCKETX;
      }
    }
  };

  const isChargeCollectionFixed =
    policyRuleState?.chargesCollectedPayoutRange &&
    policyRuleState.chargesCollectedPayoutRange.some(
      policyBase =>
        policyBase.parameterDefinitionType === ParameterDefinitionType.FIXED
    );
  return (
    <Stack
      gap={2}
      sx={{
        width: '100%',
        p: '1.5rem',
      }}
    >
      <VegaText
        text={'Base Incentive'}
        fontWeight={600}
        fontSize={'1.0625rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Collection type'}>
            {loading ? (
              <Skeleton />
            ) : (
              <VegaSelect
                options={collectionTypeOption(isChargeCollectedTab)}
                value={policyRuleState && policyRuleState.collectionType}
                onChange={selected => {
                  handlePolicyRule(
                    'collectionType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Payout metric'}>
            {loading ? (
              <Skeleton />
            ) : (
              <VegaSelect
                options={payoutMetrixOption(
                  isChargeCollectedTab,
                  policyEnumValues?.CALCULATION_METRICS
                )}
                value={policyRuleState && policyRuleState.payoutMetric}
                onChange={selected => {
                  handlePolicyRule(
                    'payoutMetric',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {policyRuleState?.basePayoutRange &&
        selectedBucketTab !== BucketEnumForNonNPA.CHARGES_COLLECTION &&
        policyRuleState.basePayoutRange.map(
          (baseIncentive: BasePolicyRangeProps, index: number) => {
            return (
              <BaseIncentive
                key={index}
                index={index}
                baseIncentive={baseIncentive}
                onDelete={onDeleteBaseIncentive}
              />
            );
          }
        )}
      {isChargeCollectedTab &&
        policyRuleState.chargesCollectedPayoutRange.map(
          (baseIncentive: BasePolicyRangeProps, index: number) => {
            return (
              <BaseIncentiveForChargeCollected
                key={index}
                index={index}
                baseIncentive={baseIncentive}
                onDelete={onDeleteChargeCollectedBaseIncentive}
              />
            );
          }
        )}
      {!isChargeCollectionFixed && (
        <Box>
          <VegaButton
            variant="text"
            text={`Add ${
              policyRuleState?.basePayoutRange?.length + 1
            }${getOrdinalNumber(
              policyRuleState?.basePayoutRange?.length + 1
            )} parameter`}
            startIcon={<PlusCircleIcon />}
            onClick={onAddBaseIncentive}
            disabled={getDisabledButton()}
          />
        </Box>
      )}
      <Box textAlign={'end'}>
        <VegaButton
          text={`Save Base incentive`}
          onClick={onSubmitPolicy}
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
};

export default BaseIncentiveComponent;

const collectionTypeOption = (isChargeCollectedTab: boolean) => {
  if (isChargeCollectedTab) {
    const options: VegaSelectOption[] = Object.keys(ChargeCollectionType).map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  } else {
    const options: VegaSelectOption[] = Object.keys(CollectionType).map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  }
};
const payoutMetrixOption = (
  isChargeCollectedTab: boolean,
  payoutMetrixEnums: string[]
) => {
  if (isChargeCollectedTab) {
    const options: VegaSelectOption[] = ['PER_CASE', 'OVERALL'].map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  } else {
    if (payoutMetrixEnums) {
      const options: VegaSelectOption[] = [...payoutMetrixEnums, 'OVERALL'].map(
        (item: string) => ({
          value: item,
          label: toLowerCase(item),
        })
      );
      return options;
    }
  }
};

const getKeyFromTab = (classType: string, tab: number) => {
  if (classType === BucketEnum.NPA) {
    const value = Object.keys(BucketEnumForNPA)[tab];
    return value;
  } else {
    const value = Object.keys(BucketEnumForNonNPA)[tab];
    return value;
  }
};
