import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import { ApproveIcon } from '../../../../components/Icons/Icons';
import LmDialog from '../../../../components/common/LmDialog';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import VegaText from '../../../../components/common/VegaText';
import { GREY } from '../../../../constants/style';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { ReceiptStatus } from '../../../../types/receiptManagment';
import { UpdateReceiptParams } from '../../../../types/request/receiptManagment';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useState } from 'react';

interface IProps {
  open: boolean;
  onClose: () => void;
  depositId?: string;
  receiptNumber?: string;
  onApproveClose?: () => void;
}

const ReceiptApproveDialog = ({
  open,
  onClose,
  depositId,
  receiptNumber,
  onApproveClose,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const onApprove = async () => {
    setLoading(true);
    const depositStatusObj = {
      depositId: depositId,
      status: ReceiptStatus.APPROVED,
    } as UpdateReceiptParams;
    await ReceiptManagmentService.updateStatusForReceipt(depositStatusObj)
      .then(() => {
        setSnackbar('Approve successfully !!');
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    onClose();
    if (onApproveClose) {
      onApproveClose();
    }
  };

  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={open}
        handleClose={() => onClose()}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="Cancel"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                }}
                onClick={() => onClose()}
                startIcon={<Close sx={{ color: GREY.dark }} />}
                disabled={loading}
              />
              <VegaButton
                fullWidth
                text="Appove"
                startIcon={<ApproveIcon strokeColor="white" />}
                onClick={onApprove}
                loading={loading}
              />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Are you sure?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={`You are approving the Receipt ${receiptNumber}`}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ReceiptApproveDialog;
