import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../../../Apis/IncentivesService';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setWriteOffruleState,
} from '../../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { IncentiveInterestDto } from '../../../../types/request/incentives';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import LmTextField from '../../../common/LmTextField';
import VegaButton from '../../../common/VegaButton';
import VegaFormInputField from '../../../common/VegaFormInputField';

const InterestChargesComponent = () => {
  const { policyId } = useParams();
  const { writeOffruleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitInterestIncentive = async () => {
    setLoading(true);
    try {
      const response = await IncentiveServices.addWriteOffPolicy({
        ...writeOffruleState,
        incentiveBasePolicyId: policyId,
      });
      dispatch(setPolicyDetailsState(response));
      setSnackbar('Add interest successfully !!!');
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  const handleInterestChargesChange = (
    key: keyof IncentiveInterestDto,
    value: number
  ) => {
    const preInterestCharges = { ...writeOffruleState.incentiveInterestDto };
    const updatedInterestChargesDto = {
      ...preInterestCharges,
      [key]: value,
    } as IncentiveInterestDto;
    dispatch(
      setWriteOffruleState({
        ...writeOffruleState,
        incentiveInterestDto: updatedInterestChargesDto,
      })
    );
  };

  const disabled =
    !writeOffruleState?.incentiveInterestDto?.maxPayoutForInterestIncentive;
  return (
    <Stack
      gap={2}
      sx={{
        width: '100%',
        p: '1.5rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <VegaFormInputField label={'Interest (Percentage)'}>
            <LmTextField
              type="number"
              placeholder="Enter Interest"
              value={
                writeOffruleState?.incentiveInterestDto?.incentiveOnInterest
              }
              onChange={e => {
                const value = e.target.value && parseFloat(e.target.value);
                handleInterestChargesChange('incentiveOnInterest', value);
              }}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <VegaFormInputField label={'Max Payout For Interest'}>
            <LmTextField
              type="number"
              placeholder="Enter Max Payout For Interest"
              value={
                writeOffruleState?.incentiveInterestDto
                  ?.maxPayoutForInterestIncentive
              }
              onChange={e => {
                const value = e.target.value && parseFloat(e.target.value);
                handleInterestChargesChange(
                  'maxPayoutForInterestIncentive',
                  value
                );
              }}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
      <Box textAlign={'end'}>
        <VegaButton
          text={`Save Interest`}
          onClick={onSubmitInterestIncentive}
          disabled={disabled}
          loading={loading}
        />
      </Box>
    </Stack>
  );
};

export default InterestChargesComponent;
