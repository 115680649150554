import axios from 'axios';
import { ApiService, GetCount } from '../types/api';
import {
  BucketPerformanceResponse,
  CustomerAddressProps,
  CustomerCommunicationDetail,
  CustomerDataWithToken,
  LoanData,
  LoanTransactionData,
  PortfoliioDetailsDto,
  TotalOverdueLoanAmountProps,
} from '../types/loan';
import {
  FetchContactDetailsRequest,
  FetchLoanListSnapshotRequest,
  FetchPortfolioDetailsRequest,
  GetCustomerLinkedAccountsRequest,
  LoanListRequest,
  LoanTransactionFilterListRequest,
  LoanTransactionListRequest,
  PaymentBucketPerformanceRequest,
  TotalOverDueLoanAmountRequeset,
  UpdateContactRequest,
} from '../types/request/loan';
import {
  CountResponse,
  EscalationCountResponse,
  PaginatedResponse,
} from '../utils/api';
import { GetExportAllocationMetaDataRequest } from '../types/request/allocation';

const Endpoint = {
  BASE: '/loan',
  LIST: '/list',
  TRANSACTIONS: '/transactions',
  CUSTOMER: '/customer',
  ADDRESS: '/address/all/:loanId',
  TOKEN: '/token/:token',
  FILTER: '/filter/:criteriaId',
  COUNT: '/count',
  TOTAL: '/total',
  ESCALATIONCOUNT: '/escalation/count',
  CUSTOMER_ACC_BY_CUSTOMER_NO: '/accounts',
  PATMENT: '/payment',
  BUCKET: '/payment/bucket',
  LINKED_ACCOUNTS: '/customer/accounts',
  CONTACT_DETAILS: '/filter/customer',
  PORTFOLIO_DETAILS: '/snapshot/portfolio',
  LOAN_LIST: '/snapshot/get/list',
};

export class LoanService extends ApiService {
  static async verifyToken(
    request: Partial<UpdateContactRequest>
  ): Promise<CustomerDataWithToken> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.CUSTOMER +
      Endpoint.TOKEN.replace(':token', request.token);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async updateContact(
    request: Partial<UpdateContactRequest>
  ): Promise<PaginatedResponse<LoanData>> {
    const endpoint = Endpoint.BASE + Endpoint.CUSTOMER;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getAddressFormLoanId(
    customerNo: string
  ): Promise<CustomerAddressProps[]> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.CUSTOMER +
      Endpoint.ADDRESS.replace(':loanId', customerNo);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getLoanList(
    request: Partial<LoanListRequest>
  ): Promise<PaginatedResponse<LoanData>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getLoanListCount(
    request: Partial<GetExportAllocationMetaDataRequest>
  ): Promise<CountResponse> {
    const endpoint = Endpoint.BASE + Endpoint.COUNT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getEscalationCount(
    request: Partial<GetCount>
  ): Promise<EscalationCountResponse> {
    const endpoint = Endpoint.ESCALATIONCOUNT;
    const response = await axios.get(endpoint, {
      params: { managerId: request.id },
    });
    return response.data;
  }
  static async getLoanFilterList(
    request: Partial<LoanTransactionFilterListRequest>
  ): Promise<PaginatedResponse<LoanData>> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.FILTER.replace(':criteriaId', request.criteriaId);
    const response = await axios.get(endpoint, {
      params: { page: request.page, size: request.size },
    });
    return response.data;
  }
  static async getLoanTransactionListByLoanId(
    request: Partial<LoanTransactionListRequest>
  ): Promise<PaginatedResponse<LoanTransactionData>> {
    const endpoint = Endpoint.BASE + Endpoint.TRANSACTIONS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getTotalOverdueAmountByAgentId(
    request: Partial<TotalOverDueLoanAmountRequeset>
  ): Promise<TotalOverdueLoanAmountProps> {
    const endpoint = Endpoint.BASE + Endpoint.TOTAL;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getLinkedAccountByCustomerNo(
    customerId: string
  ): Promise<LoanData[]> {
    const endpoint =
      Endpoint.BASE + Endpoint.CUSTOMER + Endpoint.CUSTOMER_ACC_BY_CUSTOMER_NO;
    const response = await axios.get(endpoint, { params: { customerId } });
    return response.data;
  }
  static async getPaymentPerformance(
    request: Partial<PaymentBucketPerformanceRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.PATMENT;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getPaymentBucketPerformance(
    request: Partial<PaymentBucketPerformanceRequest>
  ): Promise<BucketPerformanceResponse> {
    const endpoint = Endpoint.BASE + Endpoint.BUCKET;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getLinkedCustomerAccounts(
    data: Partial<GetCustomerLinkedAccountsRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.LINKED_ACCOUNTS;
    const response = await axios.get(endpoint, { params: data });
    return response.data;
  }
  static async getContactDetails(
    request: Partial<FetchContactDetailsRequest>
  ): Promise<PaginatedResponse<CustomerCommunicationDetail>> {
    const endpoint = Endpoint.BASE + Endpoint.CONTACT_DETAILS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getPortfolioDetails(
    request: Partial<FetchPortfolioDetailsRequest>
  ): Promise<PortfoliioDetailsDto[]> {
    const endpoint = Endpoint.BASE + Endpoint.PORTFOLIO_DETAILS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getSnapshotLoanList(
    request: Partial<FetchLoanListSnapshotRequest>
  ): Promise<{ loanIds: string[] }> {
    const endpoint = Endpoint.BASE + Endpoint.LOAN_LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
