/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AddPropertiesProps, PropertiesViewModel } from '../types/properties';

export interface PropertiesState {
  proppertiesState: Partial<AddPropertiesProps>;
  propertyDetails: Partial<PropertiesViewModel>;
  propertyImages: string[];
  loading: boolean;
  openAddPropertyDrawer: boolean;
  openMinRaiseModal: boolean;
  openImageModal: boolean;
  openCoolDownTimeModal: boolean;
  openStartAuctionDrawer: boolean;
  openPropertyDetailsModal: boolean;
  openBulkPropertyDialog: boolean;
  page: number;
  totalItems: number;
  pageSize: number;
  error: string | null;
}

const initialState: PropertiesState = {
  proppertiesState: {},
  propertyDetails: {},
  propertyImages: [],
  loading: false,
  openMinRaiseModal: false,
  openAddPropertyDrawer: false,
  openCoolDownTimeModal: false,
  openStartAuctionDrawer: false,
  openImageModal: false,
  openPropertyDetailsModal: false,
  openBulkPropertyDialog: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setOpenAddPropertyDrawer: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openAddPropertyDrawer = payload;
    },
    setOpenStartAuctionDrawer: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openStartAuctionDrawer = payload;
    },
    setOpenBulkPropertyDialog: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openBulkPropertyDialog = payload;
    },
    setOpenMinRaiseModal: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openMinRaiseModal = payload;
    },
    setOpenCoolDownTimeModal: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openCoolDownTimeModal = payload;
    },
    setOpenImageModal: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openImageModal = payload;
    },
    setOpenPropertyDetailsModal: (state: PropertiesState, action) => {
      const { payload } = action;
      state.openPropertyDetailsModal = payload;
    },
    setPropertyImages: (state: PropertiesState, action) => {
      const { payload } = action;
      state.propertyImages = payload;
    },
    setProppertiesState: (state: PropertiesState, action) => {
      const { payload } = action;
      state.proppertiesState = payload;
    },
    setPropertyDetails: (state: PropertiesState, action) => {
      const { payload } = action;
      state.propertyDetails = payload;
    },
  },
});
export const {
  setOpenAddPropertyDrawer,
  setOpenBulkPropertyDialog,
  setOpenStartAuctionDrawer,
  setOpenMinRaiseModal,
  setOpenCoolDownTimeModal,
  setOpenImageModal,
  setOpenPropertyDetailsModal,
  setPropertyImages,
  setProppertiesState,
  setPropertyDetails,
} = propertiesSlice.actions;
export const getPropertiesState = (state: RootState) => state.properties;
export default propertiesSlice;
