/** @format */

import { createContext, useContext, useState, useEffect } from 'react';
import { ICreateHolidayProps } from '../types/holiday';
import {
  createNewHoliday,
  deleteHoliday,
  fetchHolidayList,
  updateHoliday,
} from '../Apis/holiday';
import { useSnackbar } from './SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { HolidayNameRequest } from '../types/request/holidayRequest';
const today = new Date().toISOString();
const maxEndDate = new Date(today);
maxEndDate.setDate(maxEndDate.getDate() + 1);

export interface IHolidayNameListProps {
  id: number | string;
  name: string;
  holidayType: string;
  startDate: string;
  endDate: string;
  createdBy: string;
}

export const holidayObj: ICreateHolidayProps = {
  name: '',
  startDateTime: today,
  endDateTime: maxEndDate.toISOString(),
  holidayType: '',
  // holidayTimeline: "",
};

export interface IHoliayListStateProps {
  loading: boolean;
  records: ICreateHolidayProps[];
}

export interface HolidayNameContextType {
  search: string;
  isAdd: boolean;
  listLoading: boolean;
  page: number;
  size: number;
  totalItems: number;
  createHolidayLoader: boolean;
  holidayState: ICreateHolidayProps;
  holidayListState: IHoliayListStateProps;
  selectedHoliday: ICreateHolidayProps | null;
  setSelectedHoliday: React.Dispatch<
    React.SetStateAction<ICreateHolidayProps | null>
  >;
  setHolidayState: React.Dispatch<React.SetStateAction<ICreateHolidayProps>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  toggleAddHolidayNameDrawer: () => void;
  onAddHolidaySubmit: (holiday: ICreateHolidayProps) => void;
  onUpdateHolidaySubmit: (updatedHoliday: ICreateHolidayProps) => void;
  getHolidayList: (request: Partial<HolidayNameRequest>) => void;
  onDeleteHoliday: () => void;
  handleAddHolidayChange: (
    name: keyof ICreateHolidayProps,
    value: string | Date
  ) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export const HolidayNameContext = createContext<HolidayNameContextType | null>(
  null
);

const HolidayNameProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(10);
  const [createHolidayLoader, setCreateHolidayLoader] =
    useState<boolean>(false);
  const [selectedHoliday, setSelectedHoliday] =
    useState<ICreateHolidayProps | null>(null);
  const [holidayState, setHolidayState] = useState<ICreateHolidayProps>({
    ...holidayObj,
  });
  const [holidayListState, setHolidayListState] =
    useState<IHoliayListStateProps>({
      loading: false,
      records: [],
    });

  const toggleAddHolidayNameDrawer = () => {
    setIsAdd(!isAdd);
  };

  const onAddHolidaySubmit = async (holiday: ICreateHolidayProps) => {
    setCreateHolidayLoader(true);
    try {
      await createNewHoliday(holiday);
      toggleAddHolidayNameDrawer();
      setSnackbar('Holiday  Added');
      getHolidayList({});
      setCreateHolidayLoader(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Holiday '),
        'error'
      );
      setCreateHolidayLoader(false);
    }
  };

  const onUpdateHolidaySubmit = async (
    updatedHolidayState: ICreateHolidayProps
  ) => {
    setCreateHolidayLoader(true);
    try {
      await updateHoliday(updatedHolidayState);
      toggleAddHolidayNameDrawer();
      setSelectedHoliday(null);
      setSnackbar('Holiday  Updated');
      getHolidayList({});
      setCreateHolidayLoader(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to update Holiday '),
        'error'
      );
      setCreateHolidayLoader(false);
    }
  };

  const onDeleteHoliday = async () => {
    try {
      if (!selectedHoliday) return;
      await deleteHoliday(selectedHoliday.id);
      setSelectedHoliday(null);
      setSnackbar('Holiday  deleted');
      getHolidayList({});
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to dalete Holiday'),
        'error'
      );
    }
  };

  const handleAddHolidayChange = (
    name: keyof ICreateHolidayProps,
    value: string | Date
  ) => {
    setHolidayState({ ...holidayState, [name]: value });
  };

  const updateHolidayListState = (
    key: keyof IHoliayListStateProps,
    value: boolean | ICreateHolidayProps[]
  ) => {
    setHolidayListState({ ...holidayListState, [key]: value });
  };

  const getHolidayList = async (request: Partial<HolidayNameRequest>) => {
    setListLoading(true);
    try {
      const response = await fetchHolidayList(request);
      setListLoading(false);
      updateHolidayListState('records', response.records);
      setTotalItems(response.totalItems);
    } catch (error) {
      setListLoading(false);
    }
  };

  useEffect(() => {
    if (search.length) {
      const accountList = setTimeout(() => {
        getHolidayList({ name: search, page, size });
      }, 200);
      return () => clearTimeout(accountList);
    } else {
      getHolidayList({ page, size });
    }
  }, [search, page, size]);

  return (
    <HolidayNameContext.Provider
      value={{
        search,
        listLoading,
        isAdd,
        page,
        size,
        totalItems,
        createHolidayLoader,
        holidayState,
        holidayListState,
        selectedHoliday,
        setSelectedHoliday,
        setPage,
        setSize,
        setHolidayState,
        handleAddHolidayChange,
        onAddHolidaySubmit,
        onUpdateHolidaySubmit,
        onDeleteHoliday,
        toggleAddHolidayNameDrawer,
        getHolidayList,
        setSearch,
      }}
    >
      {children}
    </HolidayNameContext.Provider>
  );
};

export const useHolidayName = () =>
  useContext(HolidayNameContext) as HolidayNameContextType;

export default HolidayNameProvider;
