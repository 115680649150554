import { Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';
import VegaText from '../common/VegaText';
import { BORDER_COLLECTION } from '../../constants/style';
import VegaFormInputField from '../common/VegaFormInputField';

interface MonthlyProgressCardprops {
  trendIcon?: () => ReactNode;
  trendPercentage?: string;
  achievementCount?: string;
  achievmentName?: string;
  trendDescription?: string;
  percentageColor?: string;
  renderRightView?: () => ReactNode;
}
const MonthlyProgressCard = ({
  trendIcon,
  trendPercentage,
  trendDescription,
  achievementCount,
  achievmentName,
  percentageColor,
  renderRightView,
}: MonthlyProgressCardprops) => {
  return (
    <Stack
      sx={{
        border: BORDER_COLLECTION,
        borderRadius: '0.75rem',
        px: 2,
        py: 1.5,
        minHeight: '100px',
        bgcolor: 'white',
        justifyContent: 'center',
      }}
    >
      <Stack
        direction={'row'}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack gap={1}>
          <Grid container>
            <Grid item xs={12}>
              <VegaFormInputField label={achievmentName}>
                <VegaText
                  sx={{ mt: '0px !important' }}
                  fontSize={'1.5rem'}
                  fontWeight={600}
                  text={achievementCount}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Stack direction={'row'} spacing={'0.3rem'}>
              {trendIcon?.()}
              {trendPercentage && (
                <VegaText text={trendPercentage} color={percentageColor} />
              )}
              {trendDescription && (
                <VegaText text={trendDescription} color="#999DA8" />
              )}
            </Stack>
          </Grid> */}
        </Stack>
        <div>{renderRightView?.()}</div>
      </Stack>
    </Stack>
  );
};
export default MonthlyProgressCard;
