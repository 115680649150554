import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth } from '../features/authSlice';
import LoadingPage from '../pages/LoadingPage/LoadingPage';
import { useAppSelector } from '../store';
import { AUTH_ROUTES } from './routes';

interface IProps {
  children: ReactNode;
}

const PrivateRoutes = ({ children }: IProps) => {
  const { isAuth, loader } = useAppSelector(getAuth);

  if (isAuth)
    return loader ? <LoadingPage height={'100vh'} /> : <>{children}</>;

  return <Navigate to={AUTH_ROUTES.LOGIN} replace />;
};

export default PrivateRoutes;
