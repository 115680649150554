import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoanService } from '../../Apis/LoanServices';
import {
  DueDateTypes,
  VerticalTypes,
} from '../../components/Perfomance/PerformanceFilterSection';
import VegaAutoComplete from '../../components/common/VegaAutoComplete';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaSelectOption } from '../../components/common/VegaSelect';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../constants/style';
import { PortfoliioDetailsDto } from '../../types/loan';
import { FetchPortfolioDetailsRequest } from '../../types/request/loan';
import { DateUtility } from '../../utils/DateUtlility';
import AllBranchSelector from '../MinVisits/modules/AllBranchSelector';
import RegionSelector from '../MinVisits/modules/RegionSelector';
import PortFolioColumn from './PortFolioColumn';
import { useDrawer } from '../../hooks/useDrawer';
import PortfolioDetailDialog from './PortfolioDetailDialog';

type FilterType = {
  from: string;
  to: string;
  product: string;
  branch: string;
  segment: string;
  region: string;
  cycleDate: number;
  modelParserAllocationType: string;
  actionType: string;
};

enum FilterInputType {
  from = 'from',
  to = 'to',
  product = 'product',
  branch = 'branch',
  segment = 'segment',
  region = 'region',
  cycleDate = 'cycleDate',
  modelParserAllocationType = 'modelParserAllocationType',
  actionType = 'actionType',
}
export enum ModelParserPreditionOptions {
  FIELD = 'FIELD',
  TELE = 'TELE',
}
enum ActionTypes {
  SMS = 'SMS_SENT',
  WHATSAPP = 'WHATSAPP_SENT',
  IVR = 'IVR_SENT',
}
const TODAY = new Date();
function PortFolio() {
  const [portfolioDetails, setPortfolioDetails] = useState<
    PortfoliioDetailsDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterData, SetFilterData] = useState<Partial<FilterType>>({
    from: TODAY.toISOString(),
    to: TODAY.toISOString(),
  });
  const {
    open: openViewDetailDialog,
    close: closeViewDetailDialog,
    isOpen: isViewDetailDialogOpen,
    props: viewDetailsDialogProps,
  } = useDrawer();

  function updateFormData(type: FilterInputType, value: string | number) {
    SetFilterData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function updateFromDate(e) {
    const formattedDate = DateUtility.parseDateFromDatePicker(e);
    updateFormData(FilterInputType.from, formattedDate.toISOString());
  }

  function updateToDate(e) {
    const formattedDate = DateUtility.parseDateFromDatePicker(e);
    updateFormData(FilterInputType.to, formattedDate.toISOString());
  }

  function clearFilter() {
    SetFilterData(() => {
      return {
        segment: '',
        from: TODAY.toISOString(),
        to: TODAY.toISOString(),
        region: '',
      };
    });
    getPortfolioDetails({
      fromDate: TODAY.toISOString(),
      toDate: TODAY.toISOString(),
    });
  }

  async function getPortfolioDetails(
    request: Partial<FetchPortfolioDetailsRequest>
  ) {
    setLoading(true);
    try {
      const response = await LoanService.getPortfolioDetails(request);
      setPortfolioDetails(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function onApplyFilterClick() {
    const request = {} as Partial<FetchPortfolioDetailsRequest>;

    if (filterData.from && filterData.to) {
      (request.fromDate = filterData.from), (request.toDate = filterData.to);
    }
    if (filterData.segment) {
      request.segment = filterData.segment;
    }
    if (filterData.region) {
      request.region = filterData.region;
    }
    if (filterData.modelParserAllocationType) {
      request.modelParserAllocationType = filterData.modelParserAllocationType;
    }
    if (filterData.actionType) {
      request.actionDispositionCode = filterData.actionType;
    }
    if (filterData.cycleDate) {
      request.cycleDate = filterData.cycleDate;
    }
    if (filterData.branch) {
      request.branch = filterData.branch;
    }
    getPortfolioDetails(request);
  }

  useEffect(() => {
    onApplyFilterClick();
  }, []);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Portfolio'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '1rem', bgcolor: '' }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{
                border: BORDER_COLLECTION,
                borderRadius: '12px',
                p: 3,
                justifyContent: 'space-between',
              }}
              direction={'row'}
            >
              <Grid container direction={'row'} gap={1} rowGap={2}>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={toLowerCase(FilterInputType.from)}>
                    <VegaDatePicker
                      value={filterData?.from}
                      onChange={updateFromDate}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={toLowerCase(FilterInputType.to)}>
                    <VegaDatePicker
                      value={filterData?.to}
                      onChange={updateToDate}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.segment)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getVerticalOptions(VerticalTypes)}
                      onClear={() =>
                        updateFormData(FilterInputType.segment, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.segment, selected)
                      }
                      selected={filterData?.segment ?? ''}
                      placeHolder="Select Segment"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Branch'}>
                    <AllBranchSelector
                      selected={filterData?.branch ?? ('' as string)}
                      handleChange={selected =>
                        updateFormData(FilterInputType.branch, selected)
                      }
                      onClear={() => updateFormData(FilterInputType.branch, '')}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Region'}>
                    <RegionSelector
                      selected={filterData?.region ?? ''}
                      handleChange={selected =>
                        updateFormData(FilterInputType.region, selected)
                      }
                      onClear={() => updateFormData(FilterInputType.region, '')}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Cycle'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getDueDateValues())}
                      onClear={() =>
                        updateFormData(FilterInputType.cycleDate, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.cycleDate, +selected)
                      }
                      selected={filterData.cycleDate ?? ''}
                      placeHolder="Select Cycle"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Model Parser Allocation Type'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(
                        Object.values(ModelParserPreditionOptions)
                      )}
                      onClear={() =>
                        updateFormData(
                          FilterInputType.modelParserAllocationType,
                          ''
                        )
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.modelParserAllocationType,
                          selected
                        )
                      }
                      selected={filterData.modelParserAllocationType ?? ''}
                      placeHolder="Select Model Parser Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Action Type'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getActionTypeOptions(ActionTypes)}
                      onClear={() =>
                        updateFormData(FilterInputType.actionType, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.actionType, selected)
                      }
                      selected={filterData.actionType ?? ''}
                      placeHolder="Select Action Type"
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
              <Grid
                container
                direction={'row'}
                gap={1}
                sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
              >
                <VegaButton
                  text="Clear Filter"
                  onClick={clearFilter}
                  variant="outlined"
                  sx={{
                    ':hover': {
                      bgcolor: 'transparent',
                      color: '#1047DC',
                    },
                  }}
                />
                <VegaButton text="Apply Filter" onClick={onApplyFilterClick} />
              </Grid>
            </Grid>
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          idColumn="bucket"
          columns={PortFolioColumn({
            onViewDetailClick: loanIds => {
              openViewDetailDialog({ loanIds });
            },
          })}
          data={portfolioDetails}
          pageSize={10}
          rowCount={portfolioDetails?.length}
          loading={loading}
        />
      </VegaPageContent>
      <PortfolioDetailDialog
        closePortfolioDialog={closeViewDetailDialog}
        isPortfolioDialogOpen={isViewDetailDialogOpen}
        loanIds={viewDetailsDialogProps.loanIds}
      />
    </>
  );
}

export default PortFolio;

const getVerticalOptions = (type: typeof VerticalTypes) => {
  const options = Object.keys(type).map(
    item => ({ value: item, label: toLowerCase(item) } as VegaSelectOption)
  );
  return options;
};
const getOptions = (options: string[]) => {
  const modifyOptions = options.map(
    (option: string) =>
      ({
        label: toLowerCase(option),
        value: option,
      } as VegaSelectOption)
  );
  return modifyOptions;
};
const getActionTypeOptions = (type: typeof ActionTypes) => {
  const options = Object.entries(type).map(
    ([key, value]) => ({ value, label: key } as VegaSelectOption)
  );
  return options;
};
const getDueDateValues = () =>
  Object.values(DueDateTypes).map(item => {
    return item;
  });
