import { Grid } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentiveSimulatorState,
  setSimulateFormData,
} from '../../../features/IncentivesGamificationsSlice/incentiveSimulatorSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { SimulatorCriteriaData } from '../../../types/incentiveSimulatorType';
import { SimulatorCollectionType } from '../../../types/incentives';

enum CriteriaTwo {
  HIGHER = 'HIGHER',
  LOWER = 'LOWER',
}

enum CriteraFour {
  PERCENTAGE = 'PERCENTAGE',
  ABSOLUTE = 'ABSOLUTE',
}

const CriteriaContainer = () => {
  const { simulateFormData } = useAppSelector(getIncentiveSimulatorState);
  const dispatch = useAppDispatch();

  const handleSimulateCriteria = (
    key: keyof SimulatorCriteriaData,
    value: string
  ) => {
    const preCriteria = { ...simulateFormData.simulatorCriteria };
    const updateCriteria = {
      ...preCriteria,
      [key]: value,
    };
    dispatch(
      setSimulateFormData({
        ...simulateFormData,
        simulatorCriteria: updateCriteria,
      })
    );
  };

  return (
    <>
      <Grid container spacing={2} alignItems="end">
        <Grid item xs={12} sm={6} lg={2}>
          <VegaFormInputField label="Collection Type" isMandatory>
            <VegaSelect
              options={getCollectionsTypeOptions()}
              value={simulateFormData.simulatorCriteria.incentiveCollectionType}
              onChange={e =>
                handleSimulateCriteria(
                  'incentiveCollectionType',
                  e.target.value as string
                )
              }
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <VegaFormInputField label="Criteria 2" isMandatory>
            <VegaSelect
              options={getCriteriaTwoTypeOptions()}
              value={simulateFormData.simulatorCriteria.condition}
              onChange={e =>
                handleSimulateCriteria('condition', e.target.value as string)
              }
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <VegaFormInputField label="Criteria 3" isMandatory>
            <LmTextField
              type="number"
              value={simulateFormData.simulatorCriteria.value}
              onChange={e =>
                handleSimulateCriteria('value', e.target.value as string)
              }
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <VegaFormInputField label="Criteria 4" isMandatory>
            <VegaSelect
              options={getCriteriaFourTypeOptions()}
              value={simulateFormData.simulatorCriteria.metricType}
              onChange={e =>
                handleSimulateCriteria('metricType', e.target.value as string)
              }
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </>
  );
};

export default CriteriaContainer;

const getCollectionsTypeOptions = () => {
  return Object.keys(SimulatorCollectionType).map(i => {
    const option: VegaSelectOption = {
      value: i,
      label: toLowerCase(i),
    };
    return option;
  });
};

const getCriteriaTwoTypeOptions = () => {
  return Object.keys(CriteriaTwo).map(i => {
    const option: VegaSelectOption = {
      value: i,
      label: toLowerCase(i),
    };
    return option;
  });
};

const getCriteriaFourTypeOptions = () => {
  return Object.keys(CriteraFour).map(i => {
    const option: VegaSelectOption = {
      value: i,
      label: toLowerCase(i),
    };
    return option;
  });
};
