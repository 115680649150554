import { useState } from 'react';
import VegaButton from '../../components/common/VegaButton';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import {
  getConfigurationState,
  initialAcrConfig,
  initialAgentAlertNotifyConfig,
  initialExpenseManagConfig,
  initialGeoVerifyConfig,
  initialLeaveRequestConfig,
  initialMinMaxVisitsConfig,
  initialNpaDpdNcmConfig,
  initialOtsConfig,
  initialRouteDeviationConfig,
  initialSupervisorAlertNotifyConfig,
  setConfigurations,
  setSelectedConfigurationsTab,
} from '../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  ConfigValueProps,
  ConfigurationDto,
  ConfigurationType,
  ConfigurationsListProps,
} from '../../types/configurationType';
import ExpenseManagementConfig from './modules/ExpenseManagementConfig';
import GeoVarificationConfig from './modules/GeoVarificationConfig';
import LeaveRequestConfig from './modules/LeaveRequestConfig';
import ListOfConfiguration from './modules/ListOfConfiguration';
import MinMaxVisitsConfig from './modules/MinMaxVisitsConfig';
// import OtsConfig from './modules/OtsConfig';
import AlertNotifyConfigAgent from './modules/AlertNotifyConfigAgent';
import AlertNotifyConfigSupervisor from './modules/AlertNotifyConfigSupervisor';
import RouteDeviationConfig from './modules/RouteDeviationConfig';
import { DateIndicateEnum, DateUtility } from '../../utils/DateUtlility';
import NcmDpdConfig from './modules/NcmDpdConfig';
import ACRConfig from './modules/ACRConfig';
import OtsConfig from './modules/OtsConfig';

export interface IConfigActionProps {
  onClose: () => void;
}

const Configurations = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { configurations, selectedConfigurationsTab } = useAppSelector(
    getConfigurationState
  );
  const dispatch = useAppDispatch();

  const addConfig = (selected: number) => {
    const configType = getKeyFromTab(selected);
    if (configType === ConfigurationType.LEAVE_REQUEST) {
      dispatch(setConfigurations(initialLeaveRequestConfig));
    } else if (configType === ConfigurationType.MIN_MAX_VISITS) {
      dispatch(setConfigurations(initialMinMaxVisitsConfig));
    } else if (configType === ConfigurationType.ROUTE_DEVIATION) {
      dispatch(setConfigurations(initialRouteDeviationConfig));
    } else if (configType === ConfigurationType.EXPENSE_MANAGEMENT) {
      dispatch(setConfigurations(initialExpenseManagConfig));
    } else if (configType === ConfigurationType.GEO_VERIFICATION) {
      dispatch(setConfigurations(initialGeoVerifyConfig));
    } else if (configType === ConfigurationType.ALERT_NOTIFICATIONS_AGENT) {
      dispatch(setConfigurations(initialAgentAlertNotifyConfig));
    } else if (configType === ConfigurationType.NCM_DPD) {
      dispatch(setConfigurations(initialNpaDpdNcmConfig));
    } else if (configType === ConfigurationType.ALLOCATION_ACR) {
      dispatch(setConfigurations(initialAcrConfig));
    } else if (configType === ConfigurationType.OTS) {
      dispatch(setConfigurations(initialOtsConfig));
    } else {
      dispatch(setConfigurations(initialSupervisorAlertNotifyConfig));
    }

    setIsOpen(true);
  };

  const editConfig = (config: ConfigurationsListProps) => {
    const configDto = { ...config.config };

    const updateAgentConfigDto = {
      reminderPeriod: configDto?.reminderPeriod,
      startTime: DateUtility.toISOString(
        configDto?.startTime,
        DateIndicateEnum.MINUS
      ),
      endTime: DateUtility.toISOString(
        configDto?.endTime,
        DateIndicateEnum.MINUS
      ),
      targetEntryDeadline: DateUtility.toISOString(
        configDto?.targetEntryDeadline,
        DateIndicateEnum.MINUS
      ),
      targetReasonDeadline: DateUtility.toISOString(
        configDto?.targetReasonDeadline,
        DateIndicateEnum.MINUS
      ),
    } as ConfigValueProps;

    const updateSupervisorConfigDto = {
      expenseApprovalDeadline: DateUtility.toISOString(
        configDto?.expenseApprovalDeadline,
        DateIndicateEnum.MINUS
      ),
      targetDeviationClosureDeadline: DateUtility.toISOString(
        configDto?.targetDeviationClosureDeadline,
        DateIndicateEnum.MINUS
      ),
      minVisitBreachDeadline: DateUtility.toISOString(
        configDto?.minVisitBreachDeadline,
        DateIndicateEnum.MINUS
      ),
    } as ConfigValueProps;
    const updateConfig = {
      name: config.name,
      type: config.type,
      config:
        config.name === ConfigurationType.ALERT_NOTIFICATIONS_AGENT
          ? updateAgentConfigDto
          : config.name === ConfigurationType.ALERT_NOTIFICATIONS_SUPERVISOR
          ? updateSupervisorConfigDto
          : configDto,
      subtypes: [config.subtype],
    } as ConfigurationDto;

    dispatch(setConfigurations(updateConfig));
    setIsOpen(true);
  };

  const onClose = () => {
    dispatch(setConfigurations({}));
    setIsOpen(false);
  };
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Configurations'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <VegaButton
              text={`${toLowerCase(
                getKeyFromTab(selectedConfigurationsTab)
              )} Configuration`}
              onClick={() => addConfig(selectedConfigurationsTab)}
            />
          );
        }}
      />
      <VegaPageHeader
        sx={{ paddingBottom: 0 }}
        isTabPresent
        renderLeftView={() => {
          return (
            <VegaTabBar
              variant="scrollable"
              value={selectedConfigurationsTab}
              onChange={(e, selected) => {
                dispatch(setSelectedConfigurationsTab(selected));
              }}
            >
              {Object.keys(ConfigurationType).map((tab: string) => {
                if (tab === ConfigurationType.NCM_DPD) {
                  return (
                    <VegaTabBarItem
                      key={tab}
                      label={`${toLowerCase('NPA_DPD_NCM')}`}
                    />
                  );
                } else {
                  return (
                    <VegaTabBarItem key={tab} label={`${toLowerCase(tab)}`} />
                  );
                }
              })}{' '}
            </VegaTabBar>
          );
        }}
      />
      <ListOfConfiguration editConfig={editConfig} />
      <VegaDrawer
        title={`${toLowerCase(configurations.name)} Configuration`}
        open={isOpen}
        onClose={onClose}
      >
        {getConfigContainer(configurations.name, onClose)}
      </VegaDrawer>
    </>
  );
};

export default Configurations;

const getKeyFromTab = (tab: number) => {
  const value = Object.keys(ConfigurationType)[tab];
  return value;
};

const getConfigContainer = (configName: string, onClose: () => void) => {
  switch (configName) {
    case ConfigurationType.MIN_MAX_VISITS:
      return <MinMaxVisitsConfig onClose={onClose} />;
    case ConfigurationType.ROUTE_DEVIATION:
      return <RouteDeviationConfig onClose={onClose} />;
    case ConfigurationType.EXPENSE_MANAGEMENT:
      return <ExpenseManagementConfig onClose={onClose} />;
    case ConfigurationType.GEO_VERIFICATION:
      return <GeoVarificationConfig onClose={onClose} />;
    case ConfigurationType.ALERT_NOTIFICATIONS_AGENT:
      return <AlertNotifyConfigAgent onClose={onClose} />;
    case ConfigurationType.NCM_DPD:
      return <NcmDpdConfig onClose={onClose} />;
    case ConfigurationType.ALERT_NOTIFICATIONS_SUPERVISOR:
      return <AlertNotifyConfigSupervisor onClose={onClose} />;
    case ConfigurationType.ALLOCATION_ACR:
      return <ACRConfig onClose={onClose} />;
    case ConfigurationType.OTS:
      return <OtsConfig onClose={onClose} />;
    case ConfigurationType.LEAVE_REQUEST:
      return <LeaveRequestConfig onClose={onClose} />;
  }
};

export const isEmptyOrUndefined = (value: any) => {
  return (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    value === 0
  );
};
export const isWriteOfEmptyOrUndefined = (value: any) => {
  return typeof value === 'undefined' || value === null || value === '';
};
