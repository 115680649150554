/* eslint-disable no-empty */
import { useEffect, useState } from 'react';

import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { getMinVisitsState } from '../../../features/minVisitsSlice';
import { useAppSelector } from '../../../store';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';

interface IProps {
  selected: any;
  handleChange: (value: string) => void;
  handleDelete?: (index: number) => void;
  label?: string;
  disabled?: boolean;
  isMultiSelect?: boolean;
}

const BranchSelector = ({
  selected,
  handleChange,
  handleDelete,
  isMultiSelect = false,
  label,
  disabled,
}: IProps) => {
  const { selectedRegion } = useAppSelector(getMinVisitsState);
  const [branchList, setBranchList] = useState<VegaSelectOption[]>([]);

  const getAreaBranchList = async (region: string) => {
    try {
      const response = await MinVisitsSevices.getBranchList(region);
      const filterdBranch = response.filter(
        (branch: string) => branch !== null
      );
      const branchListOption = await filterdBranch.map((area: string) => {
        return {
          value: area,
          label: area,
        };
      });
      setBranchList(branchListOption);
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedRegion.length) {
      getAreaBranchList(selectedRegion);
    }
  }, [selectedRegion]);
  return (
    <>
      {!isMultiSelect ? (
        <VegaSelect
          ignoreLodash
          placeholder={label ?? 'Select Branch'}
          options={branchList}
          onChange={e => handleChange(e.target.value)}
          value={selected}
          disabled={disabled}
        />
      ) : (
        <VegaSelectWithCheckbox
          options={branchList}
          onSelect={selected => handleChange(selected)}
          handleDelete={handleDelete}
          selected={selected}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default BranchSelector;
