import { Grid, Rating, Stack } from '@mui/material';
import { useState } from 'react';
import { WebHookServices } from '../../../../Apis/WebHookServices';
import { fetchTeleAgentAudit } from '../../../../actions/TeleAgentAction';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { teleAgentAuditSelector } from '../../../../features/teleAgentAuditSlice';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { FeedbackRequest } from '../../../../types/request/webhook';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const FeedbackDrawer = ({ open, onClose }: IProps) => {
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();
  const { callDispose } = useAppSelector(teleAgentAuditSelector);
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [feedbackDto, setFeedbackDto] = useState<{
    comment: string;
    rating: number;
  }>({
    comment: '',
    rating: 1,
  });
  const onClear = () => {
    setFeedbackDto({ comment: '', rating: 1 });
  };
  const onSubmitFeedback = async () => {
    setLoading(true);
    setLoading(false);
    try {
      const request = {
        agentId: callDispose?.agentId,
        callDisposeEventId: callDispose?.id,
        managerId: user?.id,
        comment: feedbackDto.comment,
        rating: feedbackDto.rating,
      } as FeedbackRequest;
      await WebHookServices.postFeedback(request);
      setSnackbar('Feedback submitted !!!');
      dispatch(
        fetchTeleAgentAudit({
          agentId: user?.id,
          page: 0,
          size: 10,
        })
      );
      setLoading(false);
      onClear();
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  const disabled = !feedbackDto.comment.length;
  return (
    <VegaDrawer open={open} onClose={onClose} title="Feedback">
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Clear All"
                onClick={onClear}
                variant="text"
                sx={{
                  color: '#1047DC',
                }}
              />
              <VegaButton
                text="Submit Feedback"
                onClick={onSubmitFeedback}
                loading={loading}
                disabled={disabled}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Comment'} isMandatory>
              <>
                <LmTextField
                  isTextArea
                  rows={2}
                  multiline
                  value={feedbackDto.comment}
                  onChange={e =>
                    setFeedbackDto({ ...feedbackDto, comment: e.target.value })
                  }
                />
              </>
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Rating'} isMandatory>
              <>
                <Rating
                  name="half-rating"
                  value={feedbackDto.rating}
                  precision={0.5}
                  onChange={(e, newValue) => {
                    setFeedbackDto({
                      ...feedbackDto,
                      rating: newValue,
                    });
                  }}
                />
              </>
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default FeedbackDrawer;
