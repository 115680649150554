import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { fetchLoanAccountList } from '../../../actions/TeleAgentAction';
import { useAppDispatch } from '../../../store';
import VegaPageContent from '../../common/VegaPageContent';
import AccountDetailsForTeleAgent from '../TeleAgentManual/AccountDetailsForTeleAgent';
import AdditionDetails from '../TeleAgentManual/AdditionDetails';
import AddressDetails from '../TeleAgentManual/AddressDetails';
import ContactDetails from '../TeleAgentManual/ContactDetails';
import LoanDetails from '../TeleAgentManual/LoanDetails';
import PaymentHistoryDetails from '../TeleAgentManual/PaymentHistoryDetails';
import PendingDuesDetails from '../TeleAgentManual/PendingDuesDetails';
import TeleAgentAction from '../TeleAgentManual/TeleAgentAction';

type Props = {
  loanId?: string;
  onViewTrailClick?: () => void;
  onCreateReceiptClick?: () => void;
};

function LoanAccountDetails({
  loanId,
  onViewTrailClick,
  onCreateReceiptClick,
}: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loanId) {
      dispatch(
        fetchLoanAccountList({
          loanId: loanId,
          includeCustomerDetails: true,
          includeLmsVariables: true,
          includeLoanOutstandingDetails: true,
          includeLoanTransactions: true,
          includeLoanPdcDetails: true,
        })
      );
    }
  }, [dispatch, open, loanId]);

  return (
    <div>
      <AccountDetailsForTeleAgent />
      <TeleAgentAction
        loanAccountId={loanId}
        onViewTrailClick={onViewTrailClick}
        onCreateReceiptClick={onCreateReceiptClick}
      />
      <VegaPageContent>
        <Box
          sx={{
            p: '1rem',
            bgcolor: 'white',
            borderRadius: '0.75rem',
            border: '1px solid #E1E4EB',
            my: '1rem',
          }}
        >
          <Stack gap={2}>
            <LoanDetails />
            <PaymentHistoryDetails />
            <PendingDuesDetails />
            <AddressDetails />
            <ContactDetails />
            <AdditionDetails />
          </Stack>
        </Box>
      </VegaPageContent>
    </div>
  );
}

export default LoanAccountDetails;
