import { Stack } from '@mui/material';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import {
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
  TimePeriodEnumForPenal,
  TimePeriodEnumForPenalMonthly,
  TimePeriodEnumForPenalYearly,
} from '../../../../../features/analyticsDashboardSlice';
import { DateUtility } from '../../../../../utils/DateUtlility';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import VegaText from '../../../../../components/common/VegaText';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import LmFilterSection from '../../../../../components/common/LmFilterSection';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import VegaDataGrid from '../../../../../components/common/VegaDataGrid';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { getMonthIndex } from '../../../components/RoleBaseSegmentPerformance';

enum BucketForOverAllPerformance {
  BucketX = 'X',
  Bucket1 = '1-30',
  Bucket2 = '31-60',
  Bucket3 = '61-90',
  BucketNpa = 'Npa',
  BucketNormal = 'Normal',
}
interface OverallPerformanceResultDto {
  brnRegion: string;
  totalOutstanding: number;
  totalTargetValue: number;
  totalResolutionPercentage: number;
  totalMtdResolutionPercentage: number;
  totalLmsdResolutionPercentage: number;
  totalYtdResolutionPercentage: number;
  totalLysdResolutionPercentage: number;
  totalOutstandingBucketNormal: number;
  targetBucketNormal: number;
  resolutionPercentageBucketNormal: number;
  mtdResolutionPercentageBucketNormal: number;
  lmsdResolutionPercentageBucketNormal: number;
  ytdResolutionPercentageBucketNormal: number;
  lysdResolutionPercentageBucketNormal: number;
  totalOutstandingBucketX: number;
  targetBucketX: number;
  resolutionPercentageBucketX: number;
  mtdResolutionPercentageBucketX: number;
  lmsdResolutionPercentageBucketX: number;
  ytdResolutionPercentageBucketX: number;
  lysdResolutionPercentageBucketX: number;
  totalOutstandingBucket1: number;
  targetBucket1: number;
  resolutionPercentageBucket1: number;
  mtdResolutionPercentageBucket1: number;
  lmsdResolutionPercentageBucket1: number;
  ytdResolutionPercentageBucket1: number;
  lysdResolutionPercentageBucket1: number;
  totalOutstandingBucket2: number;
  targetBucket2: number;
  resolutionPercentageBucket2: number;
  mtdResolutionPercentageBucket2: number;
  lmsdResolutionPercentageBucket2: number;
  ytdResolutionPercentageBucket2: number;
  lysdResolutionPercentageBucket2: number;
  totalOutstandingBucket3: number;
  targetBucket3: number;
  resolutionPercentageBucket3: number;
  mtdResolutionPercentageBucket3: number;
  lmsdResolutionPercentageBucket3: number;
  ytdResolutionPercentageBucket3: number;
  lysdResolutionPercentageBucket3: number;
  totalOutstandingBucketNpa: number;
  targetBucketNpa: number;
  resolutionPercentageBucketNpa: number;
  mtdResolutionPercentageBucketNpa: number;
  lmsdResolutionPercentageBucketNpa: number;
  ytdResolutionPercentageBucketNpa: number;
  lysdResolutionPercentageBucketNpa: number;
}

export enum TimePeriod {
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  MTD = 'MTD',
}

function OverallPerformance() {
  const { calenderType, profitType } = useParams();
  const { setSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    OverallPerformanceResultDto[]
  >([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );
  const [search, setSearch] = useState<string>('');

  function buildAnalyticsQueryRequest(): Partial<AnalyticsQueryRequest> {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const queryType: Partial<AnalyticsQueryRequest> = {};

    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      if (selectedTimePeriod === TimePeriod.MTD) {
        queryType.analyticsQueryType =
          AnalyticsQueryTypeEnum.OVERALL_MONTH_TO_DATE_PERFORMANCE;
        queryType.params = {
          type: profitType,
        };
        return queryType;
      } else if (selectedTimePeriod === TimePeriod.ONE_DAY) {
        const currentDate = DateUtility.formatStringToYYYYMMDD(
          new Date()?.toISOString()
        );
        queryType.analyticsQueryType =
          AnalyticsQueryTypeEnum.OVERALL_PERFORMANCE;
        queryType.params = {
          fromDate: currentDate,
          toDate: currentDate,
          type: profitType,
        };
        return queryType;
      } else if (selectedTimePeriod === TimePeriod.FIVE_DAY) {
        const currentDate = new Date();
        const formattedCurrentDate = DateUtility.formatStringToYYYYMMDD(
          currentDate?.toISOString()
        );
        const fiveDaysBefore = DateUtility.formatStringToYYYYMMDD(
          DateUtility.subtractDays(new Date(), 4).toISOString()
        );
        queryType.analyticsQueryType =
          AnalyticsQueryTypeEnum.OVERALL_PERFORMANCE;
        queryType.params = {
          fromDate: fiveDaysBefore,
          toDate: formattedCurrentDate,
          type: profitType,
        };
        return queryType;
      }
    }

    if (calenderType === CelenderTypeEmun.YEAR) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.QUATER_1:
        case TimePeriodEnumForPenal.QUATER_2:
        case TimePeriodEnumForPenal.QUATER_3:
        case TimePeriodEnumForPenal.QUATER_4: {
          const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
          queryType.params = {
            type: profitType,
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
          };
          queryType.analyticsQueryType =
            AnalyticsQueryTypeEnum.OVERALL_PERFORMANCE;
          return queryType;
        }
        case TimePeriodEnumForPenal.JANUARY:
        case TimePeriodEnumForPenal.FEBRUARY:
        case TimePeriodEnumForPenal.MARCH:
        case TimePeriodEnumForPenal.APRIL:
        case TimePeriodEnumForPenal.MAY:
        case TimePeriodEnumForPenal.JUNE:
        case TimePeriodEnumForPenal.JULY:
        case TimePeriodEnumForPenal.AUGUST:
        case TimePeriodEnumForPenal.SEPTEMBER:
        case TimePeriodEnumForPenal.OCTOBER:
        case TimePeriodEnumForPenal.NOVEMBER:
        case TimePeriodEnumForPenal.DECEMBER: {
          const monthIndex = getMonthIndex(selectedTimePeriod);
          queryType.params = {
            type: profitType,
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfMonth(
                CURRENT_YEAR,
                monthIndex
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
            ),
          };
          queryType.analyticsQueryType =
            AnalyticsQueryTypeEnum.OVERALL_PERFORMANCE;
          return queryType;
        }
        case TimePeriodEnumForPenal.YTD:
          queryType.params = { type: profitType };
          queryType.analyticsQueryType =
            AnalyticsQueryTypeEnum.OVERALL_YEAR_TO_DATE_PERFORMANCE;
          return queryType;
      }
    }
    // else {
    //   queryType.analyticsQueryType =
    //     AnalyticsQueryTypeEnum.OVERALL_YEAR_TO_DATE_PERFORMANCE;
    //   queryType.params = {
    //     type: profitType,
    //   };
    // }

    // return queryType;
  }

  const getTimePeriodOptions = () => {
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      const options = Object.keys(TimePeriodEnumForPenalMonthly).map(item => {
        return { value: item, label: toLowerCase(item) } as VegaSelectOption;
      });
      return options;
    }
    const options = Object.keys(TimePeriodEnumForPenalYearly).map(item => {
      const year = new Date().getFullYear();
      const label = `${toLowerCase(item)} (${year})`;
      return { value: item, label: label } as VegaSelectOption;
    });
    return options;
  };

  const getQueriesForEnum = async () => {
    setLoading(true);
    try {
      const request = buildAnalyticsQueryRequest();

      const response = await AnaltyticsService.getAnalyticsQueries(request);

      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  function getFirstColumnName(value: string) {
    if (selectedTimePeriod === TimePeriod.MTD) {
      return `${value} MTD Res%`;
    } else if (selectedTimePeriod === TimePeriodEnumForPenalYearly.YTD) {
      return `${value} YTD Res%`;
    } else {
      return `${value} Target`;
    }
  }

  function getSecondColumnName(value: string) {
    if (selectedTimePeriod === TimePeriod.MTD) {
      return `${value} LMSD%`;
    } else if (selectedTimePeriod === TimePeriodEnumForPenalYearly.YTD) {
      return `${value} LYSD%`;
    } else {
      return `${value} Actual`;
    }
  }

  function columnToDisplay() {
    if (selectedTimePeriod === TimePeriod.MTD) {
      return MTD_OverAllColumn_DEF;
    } else if (selectedTimePeriod === TimePeriodEnumForPenalYearly.YTD) {
      return Year_OVERALL_COL_DEF;
    } else {
      return TODAY_AND_FIVE_DAY_OVERALLCOLUMN_DEF;
    }
  }

  const getMtdColumns = (bucket: string): GridColumns => {
    return [
      {
        field: `mtdResolutionPercentage${bucket}`,
        headerName: getFirstColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`mtdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          ).toString();

          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `lmsdResolutionPercentage${bucket}`,
        headerName: getSecondColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`lmsdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `percentageChange${bucket}`,
        headerName: `Bucket-${BucketForOverAllPerformance[bucket]}-% Change`,
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`percentageChange${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
    ];
  };

  const getYearColumn = (bucket: string): GridColumns => {
    return [
      {
        field: `ytdResolutionPercentage${bucket}`,
        headerName: getFirstColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`ytdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `lysdResolutionPercentage${bucket}`,
        headerName: getSecondColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`lysdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `percentageChange${bucket}`,
        headerName: `Bucket-${BucketForOverAllPerformance[bucket]}-% Change`,
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`percentageChange${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
    ];
  };

  const getTodayOrFiveDayColumns = (bucket: string): GridColumns => {
    return [
      {
        field: `target${bucket}`,
        headerName: getFirstColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`target${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `totalOutstanding${bucket}`,
        headerName: getSecondColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`totalOutstanding${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `percentageChange${bucket}`,
        headerName: `Bucket-${BucketForOverAllPerformance[bucket]}-% Change`,
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`percentageChange${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
    ];
  };

  const MTD_OverAllColumn_DEF: GridColumns = [
    {
      field: 'brnRegion',
      headerName: 'Regions',
      flex: 1,
      minWidth: 200,
      renderCell: (props: GridCellParams<OverallPerformanceResultDto>) => {
        const overallPerformance = props.row as OverallPerformanceResultDto;
        const display = overallPerformance?.brnRegion ?? '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    ...getMtdColumns('BucketX'),
    ...getMtdColumns('Bucket1'),
    ...getMtdColumns('Bucket2'),
    ...getMtdColumns('Bucket3'),
    ...getMtdColumns('BucketNpa'),
    ...getMtdColumns('BucketNormal'),
  ];

  const TODAY_AND_FIVE_DAY_OVERALLCOLUMN_DEF: GridColumns = [
    {
      field: 'brnRegion',
      headerName: 'Regions',
      flex: 1,
      minWidth: 200,
      renderCell: (props: GridCellParams<OverallPerformanceResultDto>) => {
        const overallPerformance = props.row as OverallPerformanceResultDto;
        const display = overallPerformance?.brnRegion ?? '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    ...getTodayOrFiveDayColumns('BucketX'),
    ...getTodayOrFiveDayColumns('Bucket1'),
    ...getTodayOrFiveDayColumns('Bucket2'),
    ...getTodayOrFiveDayColumns('Bucket3'),
    ...getTodayOrFiveDayColumns('BucketNpa'),
    ...getTodayOrFiveDayColumns('BucketNormal'),
  ];

  const Year_OVERALL_COL_DEF: GridColumns = [
    {
      field: 'brnRegion',
      headerName: 'Regions',
      flex: 1,
      minWidth: 200,
      renderCell: (props: GridCellParams<OverallPerformanceResultDto>) => {
        const overallPerformance = props.row as OverallPerformanceResultDto;
        const display = overallPerformance?.brnRegion ?? '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    ...getYearColumn('BucketX'),
    ...getYearColumn('Bucket1'),
    ...getYearColumn('Bucket2'),
    ...getYearColumn('Bucket3'),
    ...getYearColumn('BucketNpa'),
    ...getYearColumn('BucketNormal'),
  ];

  const filterData = () => {
    if (search.length) {
      const searchLowerCase = search.toLowerCase();
      const filteredResults = analyticsQueryResponse.filter(query => {
        if (search === '') {
          return query;
        } else if (query.brnRegion.toLowerCase().includes(searchLowerCase)) {
          return query;
        }
      });
      return filteredResults;
    } else {
      return analyticsQueryResponse;
    }
  };

  useEffect(() => {
    getQueriesForEnum();
  }, [calenderType, selectedTimePeriod, profitType]);

  return (
    <>
      <Stack
        gap={2}
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          p: 2,

          bgcolor: 'white',
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <VegaText
            text={'Overall Performance'}
            fontWeight={600}
            fontSize={17}
            color={'black'}
          />
          <Stack direction={'row'} gap={1}>
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
            <Stack minWidth={150}>
              <VegaSelect
                value={selectedTimePeriod}
                options={getTimePeriodOptions()}
                onChange={select => {
                  setSelectedTimePeriod(select.target.value);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <VegaDataGrid
          idColumn="brnRegion"
          data={filterData()}
          columns={columnToDisplay()}
          loading={loading}
          paginationMode="client"
          pageSize={5}
        />
      </Stack>
    </>
  );
}

export default OverallPerformance;
