import './App.css';
import Router from './router';
import { configureAxiosDefaults } from './Apis/axisConfig';
import { Amplify } from 'aws-amplify';
import { DEV_COGNITO, PROD_COGNITO, UAT_COGNITO } from './aws';
import { isCapriUATEnv, isCapriProdEnv, isCapriDevEnv } from './constants/url';

function App() {
  configureAxiosDefaults();

  const cognito = isCapriProdEnv
    ? PROD_COGNITO
    : isCapriUATEnv || isCapriDevEnv
    ? UAT_COGNITO
    : DEV_COGNITO;

  Amplify.configure({
    aws_cognito_region: cognito.REGION,
    aws_user_pools_id: cognito.USER_POOL_ID,
    aws_user_pools_web_client_id: cognito.APP_CLIENT_ID,
  });

  return (
    // <Provider store={store}>
    <Router />
    // </Provider>
  );
}

export default App;
