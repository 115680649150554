import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LoanData } from '../types/loan';
import {
  LoanListRequest,
  LoanTransactionListRequest,
} from '../types/request/loan';
import { LoanService } from '../Apis/LoanServices';

interface LoanRequestActionState {
  page: number;
  totalItems: number;
  totalItemsOfTransaction: number;
  pageSize: number;
  loading: boolean;
  loadingOfTransaction: boolean;
  loans: LoanData[];
  transactions: any[];
  error: string | null;
}

export const fetchLoanList = createAsyncThunk(
  'loans/fetchLoans',
  async (request: Partial<LoanListRequest>) => {
    return await LoanService.getLoanList(request);
  }
);
export const fetchLoanTransactioinList = createAsyncThunk(
  'loans/fetchLoanTransactioinList',
  async (request: Partial<LoanTransactionListRequest>) => {
    return await LoanService.getLoanTransactionListByLoanId(request);
  }
);

const initialState: LoanRequestActionState = {
  loading: false,
  loadingOfTransaction: false,
  page: 0,
  totalItems: 0,
  totalItemsOfTransaction: 0,
  pageSize: 0,
  loans: [],
  transactions: [],
  error: null,
};

const loanRequestSlice = createSlice({
  name: 'loanaction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLoanList.pending, state => {
        state.loading = true;
      })
      .addCase(fetchLoanList.fulfilled, (state, action) => {
        state.loading = false;
        state.loans = mapLoanToLoanViewModels(action.payload.records);
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchLoanList.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchLoanTransactioinList.pending, state => {
        state.loadingOfTransaction = true;
      })
      .addCase(fetchLoanTransactioinList.fulfilled, (state, action) => {
        state.loadingOfTransaction = false;
        state.transactions = action.payload.records;
        state.totalItemsOfTransaction = action.payload.totalItems;
      })
      .addCase(fetchLoanTransactioinList.rejected, state => {
        state.loadingOfTransaction = false;
      });
  },
});
export default loanRequestSlice;

export const loanRequestSelector = (state: RootState) =>
  state.loanRequestAction;
function mapLoanToLoanViewModels(leaves?: LoanData[]): LoanData[] {
  const viewModels: LoanData[] = leaves?.map(item => {
    const viewModel: LoanData = {
      loanId: item.loanId,
      loanMaster: { ...item.loanMaster },
      lmsVariables: { ...item.lmsVariables },
      loanPdcDetails: { ...item.loanPdcDetails },
      loanOutstandingDetails: { ...item.loanOutstandingDetails },
      loanTransactions: { ...item.loanTransactions },
      customerDetails: item.customerDetails,
      metaDataLoanAllocationDto: item.metaDataLoanAllocationDto,
    };
    return viewModel;
  });

  return viewModels ?? [];
}
