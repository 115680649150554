import { Stack } from '@mui/material';
import { MinVisitsSevices } from '../../Apis/MinVisitsServices';
import ListOfAccounts from '../../components/Accounts/ListOfAccounts';
import VegaButton from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import {
  getAccountList,
  getAccountState,
  setSelectedRows,
} from '../../features/accountsSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { CreateVisits } from '../../types/minVisitsType';
import { LoanListRequest } from '../../types/request/loan';
import { getErrorMessageFromErrorObj } from '../../utils/api';

const Accounts = () => {
  const { selectedRows } = useAppSelector(getAccountState);
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const onMoveToTodayClick = () => {
    const visits = selectedRows.map(
      (loanId: string) =>
        ({
          agentId: user?.id,
          loanId,
          location: {
            latitude: 123.0,
            longitude: 123.0,
          },
        } as CreateVisits)
    );
    MinVisitsSevices.createMinVisitBulk({ visits })
      .then(() => {
        setSnackbar('Move to today Successfully !!!');
        const body = {
          includeLmsVariables: true,
          includeCustomerDetails: true,
          includeLoanOutstandingDetails: true,
          page: 0,
          pageSize: 10,
          agentId: user?.id,
        } as LoanListRequest;
        dispatch(getAccountList(body));
        dispatch(setSelectedRows([]));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Accounts'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <VegaButton
              text="Move To Today"
              onClick={onMoveToTodayClick}
              disabled={!selectedRows.length}
            />
          );
        }}
      />
      <ListOfAccounts />
    </>
  );
};

export default Accounts;
