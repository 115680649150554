import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import HistoryReceiptManagment, {
  getTitleFromTab,
} from './module/HistoryReceiptManagment';
import ListOfAccountsAgent from './module/ListOfAccountsAgent';
import { UiModule } from '../../router/routes';
import VegaButton from '../../components/common/VegaButton';
import { GetReceiptsListRequest } from '../../types/request/receiptManagment';
import { ReceiptManagmentService } from '../../Apis/ReceiptManagmentService';
import { ReceiptStatus } from '../../types/receiptManagment';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { receiptState } from '../../features/receiptManagmentSlice';
import { useAppSelector } from '../../store';
import VegaTabBarItemWithNotification from '../../components/common/VegaTabBarItemWithNotification';
import { getAccountState } from '../../features/accountsSlice';

export enum AgentTabType {
  ACCOUNTS = 'ACCOUNTS',
  RECEIPTS = 'RECEIPTS',
  PENDING_DEPOSIT = 'PENDING_DEPOSIT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  CANCELLED = 'CANCELLED',
  CASH_IN_HAND = 'CASH_IN_HAND',
}

export type CountResponse = {
  ACCOUNTS: number;
  RECEIPTS: number;
  PENDING_DEPOSIT: number;
  PENDING_APPROVAL: number;
  REJECTED: number;
  APPROVED: number;
  CANCELLATION_REQUESTED: number;
  CANCELLED: number;
  CASH_IN_HAND: number;
};

const ReceiptAgent = () => {
  const [count, setCount] = useState<CountResponse>({
    ACCOUNTS: 0,
    RECEIPTS: 0,
    PENDING_DEPOSIT: 0,
    PENDING_APPROVAL: 0,
    REJECTED: 0,
    APPROVED: 0,
    CANCELLATION_REQUESTED: 0,
    CANCELLED: 0,
    CASH_IN_HAND: 0,
  });
  const { totalItemsOfReceiptDetailed } = useAppSelector(receiptState);
  const { totalItems } = useAppSelector(getAccountState);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { setSnackbar } = useSnackbar();
  const onTabChange = (value: number) => {
    setSelectedTab(value);
  };
  const { historyReceiptManagementFilter } = useAppSelector(receiptState);
  const [exporting, setExporting] = useState<boolean>(false);
  const exportMetaData = async (request: GetReceiptsListRequest) => {
    const url = await ReceiptManagmentService.getReceiptExportData(request);
    window.open(url, '__blank__');
  };
  const exportReceiptMetaData = async () => {
    const request = {
      page: 0,
      size: 10,
    } as GetReceiptsListRequest;
    if (selectedTab === 0) {
      request.statusIn = ReceiptStatus.PENDING_APPROVAL;
    } else if (selectedTab === 8) {
      request.statusIn = 'APPROVED_NOT_RECONCILED,APPROVED_AND_RECONCILED';
    } else if (selectedTab === 1) {
      if (historyReceiptManagementFilter.dateFilter) {
        request.fromDate = historyReceiptManagementFilter.dateFilter.startDate;
        request.toDate = historyReceiptManagementFilter.dateFilter.endDate;
      }
      if (historyReceiptManagementFilter.statusFilter) {
        request.statusIn = historyReceiptManagementFilter.statusFilter;
      }
    } else if (selectedTab !== 1 && selectedTab !== 0) {
      if (
        historyReceiptManagementFilter.dateFilter.startDate &&
        historyReceiptManagementFilter.dateFilter.endDate
      ) {
        request.fromDate = historyReceiptManagementFilter.dateFilter.startDate;
        request.toDate = historyReceiptManagementFilter.dateFilter.endDate;
      }
      request.statusIn = getTitleFromTab(selectedTab);
    }
    try {
      setExporting(true);
      await exportMetaData(request);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setExporting(false);
    }
  };

  async function fetchInitialCount() {
    const response = await ReceiptManagmentService.getReceiptTabCount();
    setCount(prev => {
      return {
        ...response,
        ACCOUNTS: totalItems,
      };
    });
  }

  useEffect(() => {
    const tabValue = Object.keys(AgentTabType).at(selectedTab);
    setCount(prev => {
      return {
        ...prev,
        [tabValue]: selectedTab == 0 ? totalItems : totalItemsOfReceiptDetailed,
      };
    });
  }, [totalItemsOfReceiptDetailed, selectedTab, totalItems]);

  useEffect(() => {
    fetchInitialCount();
  }, []);

  return (
    <>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Receipt Management'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  onTabChange(selected);
                }}
              >
                {Object.keys(AgentTabType).map(tab => (
                  <VegaTabBarItemWithNotification
                    key={tab}
                    label={toLowerCase(tab)}
                    count={count[tab]}
                  />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            selectedTab !== 0 && (
              <VegaButton
                text={exporting ? 'Exporting...' : 'Export'}
                onClick={exportReceiptMetaData}
                loading={exporting}
              />
            )
          );
        }}
      />
      {selectedTab !== 0 ? (
        <HistoryReceiptManagment
          selectedTab={selectedTab}
          module={UiModule.RECEIPT_AGENT}
        />
      ) : (
        <ListOfAccountsAgent module={UiModule.RECEIPT_AGENT} />
      )}
    </>
  );
};

export default ReceiptAgent;
