/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LmTextField from '../components/common/LmTextField';
import VegaButton from '../components/common/VegaButton';
import { PRIMARY } from '../constants/style';
import { useClientAuth } from '../providers/ClientProvider';
import { useAppSelector } from '../store';
import { getAuth } from '../features/authSlice';

interface IProps {
  forgotPasswordHandler: (state: string) => void;
}

const LoginContainer = ({ forgotPasswordHandler }: IProps) => {
  const { login } = useClientAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { loader } = useAppSelector(getAuth);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const isDisabled = () => {
    if (isValidEmail) {
      if (!email.length) {
        return true;
      } else if (!password.length) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!email.length) {
      setIsValidEmail(false);
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '400px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={17} className="font-aspekta-600">
            Login with your email ID
          </Typography>
          <Box
            my={3}
            component={'form'}
            onSubmit={e => {
              e.preventDefault();
              // dispatch(setIsAuth(true));
              login(e, email, password);
            }}
          >
            <Box mb={2}>
              <LmTextField
                header="email id"
                type="email"
                error={email.length > 0 ? !isValidEmail : undefined}
                value={email}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setEmail(inputValue);

                  setIsValidEmail(validateEmail(inputValue));
                }}
              />
            </Box>
            <Box mb={3}>
              <LmTextField
                header="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setPassword(inputValue);
                }}
              />
              <Box
                sx={{
                  textAlign: 'end',
                }}
              >
                <Typography
                  fontSize={12}
                  className="font-aspekta-600"
                  sx={{
                    color: PRIMARY.darkBlue,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  component={'span'}
                  onClick={() => forgotPasswordHandler('FORGOT')}
                  textAlign={'center'}
                >
                  Forgot Password ?
                </Typography>
              </Box>
            </Box>
            <Stack spacing={2}>
              <VegaButton
                type="submit"
                text="Login"
                disabled={isDisabled()}
                loading={loader}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginContainer;
