import { Stack } from '@mui/material';
import { ConfigurationServices } from '../../../Apis/ConfigurationServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import {
  fetchConfigListSlice,
  getConfigurationState,
  initialSupervisorAlertNotifyConfig,
  setConfigurations,
} from '../../../features/configurationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ConfigurationDto,
  ConfigurationType,
} from '../../../types/configurationType';
import { ConfigListRequest } from '../../../types/request/cofigurationRequest';
import { DateIndicateEnum, DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IConfigActionProps, isEmptyOrUndefined } from '../Configurations';
import AlertNotifySupervisorForm from '../components/Forms/AlertNotifySupervisorForm';
interface IProps extends IConfigActionProps {}

const AlertNotifyConfigSupervisor = ({ onClose }: IProps) => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      const updateConfig = {
        name: configurations?.name,
        config: {
          expenseApprovalDeadline: DateUtility.toISOString(
            configurations.config.expenseApprovalDeadline,
            DateIndicateEnum.PLUS
          ),
          targetDeviationClosureDeadline: DateUtility.toISOString(
            configurations.config.targetDeviationClosureDeadline,
            DateIndicateEnum.PLUS
          ),
          minVisitBreachDeadline: DateUtility.toISOString(
            configurations.config.minVisitBreachDeadline,
            DateIndicateEnum.PLUS
          ),
        },
      } as ConfigurationDto;
      await ConfigurationServices.addConfig(updateConfig);

      setSnackbar('Add Config Successfully');
      const request = {
        page: 0,
        size: 10,
        names: [ConfigurationType.ALERT_NOTIFICATIONS_SUPERVISOR],
      } as ConfigListRequest;
      dispatch(fetchConfigListSlice(request));
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const onClear = () => {
    dispatch(setConfigurations(initialSupervisorAlertNotifyConfig));
  };

  const disabled =
    isEmptyOrUndefined(configurations?.config?.expenseApprovalDeadline) ||
    isEmptyOrUndefined(
      configurations?.config?.targetDeviationClosureDeadline
    ) ||
    isEmptyOrUndefined(configurations?.config?.minVisitBreachDeadline);

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton text="Submit" onClick={onSubmit} disabled={disabled} />
          </Stack>
        );
      }}
    >
      <AlertNotifySupervisorForm />
    </VegaDrawerContent>
  );
};

export default AlertNotifyConfigSupervisor;
