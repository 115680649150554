import { Grid, Stack } from '@mui/material';
import React from 'react';
import LmDialog from '../../common/LmDialog';
import VegaText from '../../common/VegaText';
import VegaDialogContent from '../../common/VegaDialogContent';
import { RED } from '../../../constants/style';

type Props = {
  show: boolean;
  onClose: () => void;
  reSubmissionReason: string;
};

function ViewPolicyResubmissionReason({
  show,
  onClose,
  reSubmissionReason,
}: Props) {
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="sm"
        open={show}
        isCloseIcon
        handleClose={onClose}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <VegaText
              text="Resubmission Reason"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <Grid container rowGap={'2.5rem'}>
            <Grid item xs={12}>
              <Grid container rowGap={'0.75rem'}>
                <Grid item xs={12}>
                  <VegaText
                    text={reSubmissionReason}
                    style={{
                      color: '#222',
                      fontSize: '0.875rem',
                      fontWeight: 600,
                      lineHeight: '120%',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
}

export default ViewPolicyResubmissionReason;
