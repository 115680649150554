import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import PerformanceDashboard from './PerformanceDashboard';
import RiskBasePerformance from './RiskBasePerformance';
export enum PerformanceFilterInputType {
  MONTH_AND_YEAR_OF_ALLOCATION = 'MONTH_AND_YEAR_OF_ALLOCATION',
  BUCKET = 'BUCKET',
  CITY = 'CITY',
  BRANCH = 'BRANCH',
  AGENT_ID = 'AGENT_ID',
  MANAGER_ID = 'MANAGER_ID',
  PRODUCT = 'PRODUCT',
  SEGMENT = 'SEGMENT',
  DISPOSITION_CODE = 'DISPOSITION_CODE',
  DUE_DATE = 'DUE_DATE',
  LOAN_STATUS = 'LOAN_STATUS',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  BRANCH_CODE = 'BRANCH_CODE',
}

const Performance = () => {
  const [selecetedTab, setSelecetedTab] = useState<number>(0);
  return (
    <>
      <VegaPageHeader
        sx={{ paddingBottom: 0 }}
        renderLeftView={() => {
          return (
            <Stack gap={1}>
              <VegaText
                text={'Performance Dashboard'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selecetedTab}
                onChange={(e, selected) => {
                  setSelecetedTab(selected);
                }}
              >
                <VegaTabBarItem label={`Dashboard`} />
                <VegaTabBarItem label={`Risk Based Performance`} />
              </VegaTabBar>
            </Stack>
          );
        }}
      />

      {selecetedTab === 0 ? <PerformanceDashboard /> : <RiskBasePerformance />}
    </>
  );
};

export default Performance;
