import { useState } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import VegaButton from '../../../../components/common/VegaButton';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaFileSelect, {
  AcceptedExtension,
} from '../../../../components/common/VegaFileSelect';
import LmTextField from '../../../../components/common/LmTextField';
import { OtsService } from '../../../../Apis/OtsService';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import { useDrawer } from '../../../../hooks/useDrawer';
import RejectOtsDialog from './RejectOtsDialog';
import PutOnHoldOtsDialog from './PutOnHoldOtsDialog';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { OtsAction, OtsStatus } from '../../../../types/ots';
import { VegaFileSelectOption } from '../../../../components/Allocation/BulkAllocationFileSelect';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import ReopenOtsDialog from './ReopenOtsDialog';
import OtsUploadCustomerAckLetterDrawer, {
  SubmitAckLetterFormData,
} from './OtsUploadCustomerAckLetterDrawer';
import OtsNpvCalculations, { ComputeNpvFormData } from './OtsNpvCalculations';
import UpdateOtsTenureDrawer, {
  UpdateOtsTenureFormData,
} from './UpdateOtsTenureDrawer';
import { UpdateOtsRequest } from '../../../../types/request/ots';

enum FormField {
  AdditionalDocument = 'additionDocument',
  Comment = 'comment',
}

export type UpdateOtsForm = {
  additionDocument: VegaFileSelectOption;
  comment: string;
};

const ACCEPTED_EXTENSIONS = [
  AcceptedExtension.JPG,
  AcceptedExtension.JPEG,
  AcceptedExtension.PNG,
  AcceptedExtension.PDF,
];

enum ActionType {
  Accept,
  Reject,
  OnHold,
  Submit,
  Resubmit,
  Reopen,
  UploadAck,
  ComputeNpv,
  EditTenure,
}

const BUTTON_CONFIG: { [key in OtsStatus]?: ActionType[] } = {
  // RCM
  [OtsStatus.PRE_RCM_APPROVAL]: [
    ActionType.Accept,
    ActionType.Reject,
    ActionType.OnHold,
  ],
  [OtsStatus.POST_RCM_APPROVAL]: [ActionType.Submit],
  [OtsStatus.PRE_RCM_REJECT]: [ActionType.Submit],
  [OtsStatus.PRE_RCM_ON_HOLD]: [ActionType.Submit],
  [OtsStatus.POST_RCM_ON_HOLD]: [ActionType.Reopen],
  [OtsStatus.POST_RCM_REJECT]: [ActionType.Resubmit],

  [OtsStatus.POST_CUSTOMER_ACKNOWLEDGEMENT_APPROVE]: [ActionType.UploadAck],
  [OtsStatus.PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL]: [
    ActionType.Accept,
    ActionType.Reject,
  ],
  [OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL]: [ActionType.Submit],
  [OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_REJECT]: [ActionType.Submit],

  // NCM
  [OtsStatus.PRE_NCM_APPROVAL]: [
    ActionType.Accept,
    ActionType.Reject,
    ActionType.OnHold,
  ],
  [OtsStatus.POST_NCM_APPROVAL]: [ActionType.Submit, ActionType.ComputeNpv],
  [OtsStatus.PRE_NCM_REJECT]: [ActionType.Submit],
  [OtsStatus.POST_NCM_REJECT]: [ActionType.Resubmit],
  [OtsStatus.POST_NCM_ON_HOLD]: [ActionType.Reopen],
  [OtsStatus.PRE_NCM_ON_HOLD]: [ActionType.Submit],

  // HOC
  [OtsStatus.PRE_HOC_APPROVAL]: [
    ActionType.Accept,
    ActionType.Reject,
    ActionType.OnHold,
  ],
  [OtsStatus.POST_HOC_APPROVAL]: [ActionType.Submit],
  [OtsStatus.PRE_HOC_REJECT]: [ActionType.Submit],
  [OtsStatus.POST_HOC_REJECT]: [ActionType.Resubmit],
  [OtsStatus.POST_HOC_ON_HOLD]: [ActionType.Reopen],
  [OtsStatus.PRE_HOC_ON_HOLD]: [ActionType.Submit],

  // post committee hoc
  [OtsStatus.PRE_HOC_POST_COMMITTEE_APPROVAL]: [
    ActionType.Accept,
    ActionType.Reject,
  ],
  [OtsStatus.POST_HOC_POST_COMMITTEE_APPROVAL]: [ActionType.Submit],
  [OtsStatus.HOC_POST_COMMITTEE_REJECT]: [ActionType.Submit],

  // md
  [OtsStatus.PRE_MD_APPROVAL]: [ActionType.Accept, ActionType.Reject],
  [OtsStatus.POST_MD_APPROVAL_APPROVE]: [ActionType.Submit],
  [OtsStatus.POST_MD_APPROVAL_REJECT]: [ActionType.Submit],
  [OtsStatus.MD_REJECT]: [ActionType.Accept, ActionType.Reject],

  // agent
  [OtsStatus.PENDING_CUSTOMER_ACKNOWLEDGEMENT]: [ActionType.UploadAck],

  // breached
  [OtsStatus.OTS_BREACHED]: [ActionType.EditTenure],
};

function OtsActionView() {
  const { setSnackbar } = useSnackbar();
  const { ots, fetchOts } = useOts();
  const { user } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<UpdateOtsForm>>({});

  const {
    open: openRejectionDialog,
    close: closeRejectionDialog,
    isOpen: isRejectionDialogOpen,
  } = useDrawer();
  const {
    open: openReopenialog,
    close: closeReopenDialog,
    isOpen: isReopenDialogOpen,
  } = useDrawer();
  const {
    open: openOnHoldDialog,
    close: closeOnHoldDialog,
    isOpen: isOnHoldDialogOpen,
  } = useDrawer();
  const {
    open: openAckDrawer,
    close: closeAckDrawer,
    isOpen: isAckDrawerOpen,
    props: ackDrawerProps,
  } = useDrawer();
  const {
    open: openNpvModal,
    close: closeNpvModal,
    isOpen: isNpvModalOpen,
    props: npvModalProps,
  } = useDrawer();
  const {
    open: openUpdateTenureModal,
    close: closeUpdateTenureModal,
    isOpen: isUpdateTenureModalOpen,
    props: updateTenureModalProps,
  } = useDrawer();

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function updateOts() {
    try {
      const documents = [];
      const comments = [];
      if (formData?.additionDocument?.value) {
        documents.push(formData.additionDocument.value);
      }
      if (formData.comment) {
        comments.push(formData.comment);
      }
      if (comments.length <= 0 && documents.length <= 0) {
        fetchOts(ots.id);
        return;
      }

      const request: Partial<UpdateOtsRequest> = {
        id: ots.id,
        additionalDocuments: documents,
        comments: comments,
        userId: user?.id,
        updatingSettlement: false,
      };

      await OtsService.updateOtsRequest(request);

      setFormData({
        comment: undefined,
        additionDocument: undefined,
      });
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function updateOtsTenure(
    otsId: string,
    data: Partial<UpdateOtsTenureFormData>
  ) {
    try {
      await OtsService.updateOtsTenure({
        id: otsId,
        userId: user?.id,
        tenure: data.tenure,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function onAcceptClick() {
    try {
      setLoading(true);
      await updateOts();
      await OtsService.takeAction({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.APPROVE,
      });
      setSnackbar('Accepted');
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onRejectConfirm(reason: string) {
    try {
      setLoading(true);
      await updateOts();
      await OtsService.takeAction({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.REJECT,
        description: reason,
      });
      setSnackbar('Rejected');
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onPutOnHoldConfirm(reason: string) {
    try {
      setLoading(true);
      await updateOts();
      await OtsService.takeAction({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.ON_HOLD,
        description: reason,
      });
      setSnackbar('Requested put on hold');
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onSubmitClick() {
    try {
      setLoading(true);
      await updateOts();
      await OtsService.takeAction({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.SUBMIT,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onReopenConfirm(reason: string) {
    try {
      setLoading(true);
      await updateOts();
      await OtsService.takeAction({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.RE_OPEN,
        description: reason,
      });
      setSnackbar('Requested re-opened');
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onAckLetterSubmit(
    otsId: string,
    formData: Partial<SubmitAckLetterFormData>
  ) {
    try {
      setLoading(true);
      await updateOts();
      await OtsService.submitAckLetter({
        id: otsId,
        userId: user?.id,
        ackLetter: formData.letter?.value,
      });
      setSnackbar('Submitted acknowledgement letter');
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onNpvDataSubmit(
    otsId: string,
    formData: Partial<ComputeNpvFormData>
  ) {
    try {
      setLoading(true);
      await updateOts();
      const propertyAvailable =
        formData.propertyDocsAvailable == 'YES' ? true : false;
      await OtsService.submitNpvData({
        id: otsId,
        userId: user?.id,
        realisableValue: formData.realisableValue,
        marketValue: formData.marketValue,
        propertyDocsAvailable: propertyAvailable,
        distressValue: formData.distressValue,
        npvOfDistressValue: formData.npvOfDistressValue,
        tosBasedMaxValue: formData.tos,
        pvOfTosBasedMaxValue: formData.pvOfTos,
        propertyValueRealization: formData.propertyValueRealisation,
        fixedRealisationCost: formData.fixedRealisationCost,
        variableRealisationCost: formData.variableRealisationCost,
        totalRealisationCost: formData.totalRealisationCost,
        pvRealisationCost: formData.pvRealisationCost,
        discountedRoi: formData.discountedRoi,
        saleCompletionPeriod: formData.saleCompletionPeriod,
      });
      setSnackbar('NPV Data Submitted');
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function determineButtonVisiblity(type: ActionType) {
    const canShow = BUTTON_CONFIG[ots?.status]?.includes(type);
    return canShow;
  }

  const canShowNpvButton = () => {
    const isPostNcmApproval = ots?.status == OtsStatus.POST_NCM_APPROVAL;
    const isNcmComputed = (ots?.npvCalculated ?? false) == true;
    return isPostNcmApproval && isNcmComputed == false;
  };

  return (
    <>
      <OtsDetailsWrapper>
        <Stack>
          <Grid container columnGap={2} paddingBottom={2}>
            <Grid item xs={4}>
              <VegaFormInputField label={'Additional documents'}>
                <VegaFileSelect
                  placeholder={'Select any document'}
                  value={formData.additionDocument}
                  acceptedExtension={ACCEPTED_EXTENSIONS}
                  onFileSelect={file => {
                    updateFormData(FormField.AdditionalDocument, file);
                  }}
                  onDeleteClick={() => {
                    updateFormData(FormField.AdditionalDocument, undefined);
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={4}>
              <VegaFormInputField label={'Comment'}>
                <LmTextField
                  placeholder="Write your comment here"
                  value={formData.comment}
                  onChange={e =>
                    updateFormData(FormField.Comment, e.target.value)
                  }
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
          <AnimatedInputWrapper show={loading} marginTop={'0rem'}>
            <Stack direction="row" justifyContent={'end'}>
              <CircularProgress size={30} />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false &&
              determineButtonVisiblity(ActionType.Submit) == false
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              {determineButtonVisiblity(ActionType.OnHold) && (
                <VegaButton
                  variant="outlined"
                  text={'Put On-Hold'}
                  onClick={() => openOnHoldDialog()}
                />
              )}
              {determineButtonVisiblity(ActionType.Reject) && (
                <VegaButton
                  variant="outlined"
                  text={'Reject'}
                  onClick={() => openRejectionDialog()}
                />
              )}
              {determineButtonVisiblity(ActionType.Accept) && (
                <VegaButton text={'Accept'} onClick={onAcceptClick} />
              )}
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false &&
              determineButtonVisiblity(ActionType.Submit) == true &&
              canShowNpvButton() == false
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton text={'Submit'} onClick={onSubmitClick} />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false && determineButtonVisiblity(ActionType.Resubmit)
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton text={'Resubmit'} onClick={onSubmitClick} />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false && determineButtonVisiblity(ActionType.Reopen)
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton text={'Reopen'} onClick={() => openReopenialog()} />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false && determineButtonVisiblity(ActionType.UploadAck)
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton
                text={'Upload Customer Ack letter'}
                onClick={() => openAckDrawer({ otsId: ots.id })}
              />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false &&
              determineButtonVisiblity(ActionType.ComputeNpv) &&
              canShowNpvButton()
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton
                text={'Compute Npv'}
                onClick={() => openNpvModal({ otsId: ots?.id })}
              />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={
              loading == false &&
              determineButtonVisiblity(ActionType.EditTenure)
            }
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton
                text={'Edit Tenure'}
                onClick={() => {
                  openUpdateTenureModal({ otsId: ots?.id });
                }}
              />
            </Stack>
          </AnimatedInputWrapper>
        </Stack>
      </OtsDetailsWrapper>
      <RejectOtsDialog
        show={isRejectionDialogOpen}
        onClose={function (): void {
          closeRejectionDialog();
        }}
        onReject={function (reason: string): void {
          onRejectConfirm(reason);
        }}
      />
      <PutOnHoldOtsDialog
        show={isOnHoldDialogOpen}
        onClose={function (): void {
          closeOnHoldDialog();
        }}
        onReject={function (reason: string): void {
          onPutOnHoldConfirm(reason);
        }}
      />
      <ReopenOtsDialog
        show={isReopenDialogOpen}
        onClose={function (): void {
          closeReopenDialog();
        }}
        onReject={function (reason: string): void {
          onReopenConfirm(reason);
        }}
      />
      <OtsUploadCustomerAckLetterDrawer
        open={isAckDrawerOpen}
        otsId={ackDrawerProps.otsId}
        onClose={function (): void {
          closeAckDrawer();
        }}
        onSubmit={function (otsId, data): void {
          onAckLetterSubmit(otsId, data);
        }}
      />
      <OtsNpvCalculations
        open={isNpvModalOpen}
        otsId={npvModalProps.otsId}
        onClose={function (): void {
          closeNpvModal();
        }}
        onSubmit={onNpvDataSubmit}
      />

      <UpdateOtsTenureDrawer
        open={isUpdateTenureModalOpen}
        onClose={function (): void {
          closeUpdateTenureModal();
        }}
        otsId={updateTenureModalProps.otsId}
        onSubmit={function (otsId, data): void {
          updateOtsTenure(otsId, data);
        }}
      />
    </>
  );
}

export default OtsActionView;
