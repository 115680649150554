import { useEffect, useState } from 'react';
import { TeleAgentService } from '../../../Apis/TeleAgentServices';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { TeleAgentListProps } from '../../../types/teleAgent';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import VegaDataGrid from '../../common/VegaDataGrid';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';
import { GridColumns } from '@mui/x-data-grid';
import { DateUtility } from '../../../utils/DateUtlility';

function ListOfTeleAgents() {
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [teleAgentDetails, setTeleAgentDetails] = useState<
    Partial<TeleAgentListProps[]>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);

  const getTeleAgent = async () => {
    try {
      setLoading(true);
      const res = await TeleAgentService.getTeleAgentList({
        // userId: user.id,
        page: page,
        size: pageSize,
      });
      const agent = res.records;
      setTotalItems(res.totalItems);
      setTeleAgentDetails(agent);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    user && getTeleAgent();
  }, [page, user]);

  return (
    <>
      <VegaPageHeader sx={{ borderBottom: 'none' }} />
      <VegaPageContent>
        <VegaDataGrid
          loading={loading}
          data={teleAgentDetails}
          idColumn="id"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
          columns={COL_DEF}
        />
      </VegaPageContent>
    </>
  );
}

export default ListOfTeleAgents;

const COL_DEF: GridColumns = [
  {
    field: 'userName',
    headerName: 'Agent Name',
    flex: 0.7,
    renderCell: props => {
      const teleAgent = props.row as TeleAgentListProps;
      const userName = teleAgent?.username ?? '';
      return (
        <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'slashrtcUserId',
    headerName: 'Slash RTC User Id',
    flex: 0.7,
    renderCell: props => {
      const teleAgent = props.row as TeleAgentListProps;
      const userName = teleAgent?.slashrtcUserId ?? '';
      return (
        <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'crmId',
    headerName: 'CRM ID',
    flex: 0.7,
    renderCell: props => {
      const teleAgent = props.row as TeleAgentListProps;
      const userName = teleAgent?.crmId ?? '';
      return (
        <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 0.7,
    renderCell: props => {
      const teleAgent = props.row as TeleAgentListProps;
      const userName =
        DateUtility.formatStringToDDMMYYYY(teleAgent?.createdAt) ?? '';
      return (
        <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
];
