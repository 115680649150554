/** @format */

export const DEV_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_hig6m6bb3',
  APP_CLIENT_ID: '4h9nk7tl0nfp421j13gjlir1og',
};

export const PROD_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_V71Kf087g3',
  APP_CLIENT_ID: '53s53smuhsmqhl2uckhhain34mu1cmuhsmqhl2uckhhain34mu1c',
};

export const UAT_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_BOltDB7qL',
  APP_CLIENT_ID: '6b177qsc2gjvl0fnctdnq3b12f',
};
