/* eslint-disable no-empty */

import { VegaRadioOption } from '../../../components/common/VegaRadioGroup';
import VegaSelect from '../../../components/common/VegaSelect';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';
import { toLowerCase } from '../../../constants/commonFunction';

interface IProps {
  selected: any;
  handleChange: (value: string) => void;
  handleDelete?: (index: number) => void;
  label?: string;
  disabled?: boolean;
  isMultiSelect?: boolean;
}

const CycleSelector = ({
  selected,
  handleChange,
  handleDelete,
  isMultiSelect = false,
  label,
  disabled,
}: IProps) => {
  const onChange = (value: string) => {
    handleChange(value);
  };

  return (
    <>
      {!isMultiSelect ? (
        <VegaSelect
          ignoreLodash
          placeholder={label ?? 'Select Cycle'}
          options={getCycleOption()}
          onChange={e => onChange(e.target.value)}
          value={selected}
          disabled={disabled}
        />
      ) : (
        <VegaSelectWithCheckbox
          options={getCycleOption()}
          onSelect={selected => onChange(selected)}
          handleDelete={handleDelete}
          selected={selected}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default CycleSelector;
const getCycleOption = () => {
  const options = ['1', '5', '10', '15'].map(
    (comparison: string, index: number) => {
      const arr = ['1', '5', '10', '15'];
      const comparisonType = arr[index];
      return {
        label: toLowerCase(comparison),
        value: comparisonType,
      } as VegaRadioOption;
    }
  );
  return options;
};
