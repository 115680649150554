import { Grid } from '@mui/material';
import VegaButton from '../../../../components/common/VegaButton';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaTitleCard from '../../../../components/common/VegaTitleCard';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  AgentType,
  MonthSelection,
  TypeForTeleAgent,
  TypeForfieldAgent,
  getLeaderboardIncentiveState,
  setLeaderFilterState,
} from '../../../../features/IncentivesGamificationsSlice/incentiveLeaderboardSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { LeaderFilterProps } from '../../../../types/incentiveLeaderType';
import { isEmptyOrUndefined } from '../../../Configurations/Configurations';

interface IProps {
  onApply: (leaderFilterState: LeaderFilterProps) => void;
}

const FilterLeaderForm = ({ onApply }: IProps) => {
  const { leaderFilterState } = useAppSelector(getLeaderboardIncentiveState);
  const dispatch = useAppDispatch();

  const handleLeaderForm = (
    key: keyof LeaderFilterProps,
    value: string | number
  ) => {
    if (key === 'agentType') {
      dispatch(
        setLeaderFilterState({
          ...leaderFilterState,
          [key]: value,
          resultsType: '',
        })
      );
    } else {
      dispatch(setLeaderFilterState({ ...leaderFilterState, [key]: value }));
    }
  };

  const disabled = isEmptyOrUndefined(leaderFilterState.resultsType);
  return (
    <VegaTitleCard title="Apply Filter">
      <Grid container spacing={2} alignItems="end">
        <Grid item xs={12} sm={6} lg={2}>
          <VegaFormInputField label="Agent Type" isMandatory>
            <VegaSelect
              options={getOption(AgentType)}
              value={leaderFilterState.agentType}
              onChange={e =>
                handleLeaderForm('agentType', e.target.value as string)
              }
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VegaFormInputField label="Type" isMandatory>
            <VegaSelect
              options={getOption(
                leaderFilterState.agentType === AgentType.FIELD_AGENT
                  ? TypeForfieldAgent
                  : TypeForTeleAgent
              )}
              value={leaderFilterState.resultsType}
              onChange={e =>
                handleLeaderForm('resultsType', e.target.value as string)
              }
            />
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <VegaButton
            text={'Apply'}
            onClick={() => onApply(leaderFilterState)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </VegaTitleCard>
  );
};

export default FilterLeaderForm;

const getOption = (
  type:
    | typeof AgentType
    | typeof TypeForTeleAgent
    | typeof TypeForfieldAgent
    | typeof MonthSelection
) => {
  const options = Object.keys(type).map(value => ({
    label: toLowerCase(value),
    value,
  }));
  return options;
};
