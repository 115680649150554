import { Stack, Grid } from '@mui/material';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import {
  OtsReportsStatus,
  OtsReportType,
  OtsStatus,
  OtsUserRole,
} from '../../../../types/ots';
import { getEnumValueFromString } from '../../../../types/trail';
import { StringUtility } from '../../../../utils/StringUtility';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import OtsFileDisplay from './OtsFileDisplay';
import OtsReportActionView from './OtsReportActionView';

function OtsTechnicalDetails() {
  const { ots, loading, downloadFile } = useOts();
  const { roles } = useClientAuth();

  const canShowActionView = () => {
    const mappedRoles: OtsUserRole[] = roles
      .map(i => getEnumValueFromString(OtsUserRole, i.roleDto.name))
      .filter(i => i != undefined);

    return (
      ots?.status != OtsStatus.OTS_REJECTED &&
      ots?.technicalReportsStatus == OtsReportsStatus.PENDING &&
      mappedRoles.some(i => i == OtsUserRole.RCM)
    );
  };
  return (
    <OtsDetailsWrapper text="Technical Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Valuation Report"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Valuation Report'}
                    onClick={() => downloadFile(ots?.technicalValuationReport)}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Current Valuation Amount"
              value={StringUtility.formatToINR(ots?.valuationAmount ?? 0)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Distressed Valuation Amount"
              value={StringUtility.formatToINR(
                ots?.distressedValuationAmount ?? 0
              )}
              loading={loading}
            />
          </Grid>
        </Grid>
        <AnimatedInputWrapper show={canShowActionView()} marginTop={'0rem'}>
          <OtsReportActionView reportType={OtsReportType.TECHNICAL} />
        </AnimatedInputWrapper>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsTechnicalDetails;
