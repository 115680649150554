import { Stack } from '@mui/material';
import { useState } from 'react';
import { RoleServices } from '../../../Apis/RoleServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { UserActions, getUAM } from '../../../features/UAMSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AssignRoleProps } from '../../../types/roleType';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import AssignRoleForm from '../components/AssignRoleForm';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export interface AssignRoleStateProps {
  branchList: string[];
  regionList: string[];
}

const AssignRoleDrawer = ({ open, onClose }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { userDetails } = useAppSelector(getUAM);
  const dispatch = useAppDispatch();
  const [selectedRole, setSelectedRole] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [assignRoleState, setAssignRoleState] = useState<AssignRoleStateProps>({
    regionList: [],
    branchList: [],
  });

  const onClickClose = () => {
    onClose();
    setAssignRoleState({
      regionList: [],
      branchList: [],
    });
    setSelectedRole([]);
  };

  const onSubmit = () => {
    setLoading(true);
    const request = {
      userId: userDetails?.id,
      roleIds: selectedRole.map(role => role.value),
      regionList: assignRoleState.regionList,
    } as AssignRoleProps;

    if (assignRoleState.branchList.length !== 0) {
      request.branchList = assignRoleState.branchList;
    }

    RoleServices.assignRole(request)
      .then(() => {
        dispatch(UserActions.fetchUsers({ page: 0, size: 10 }));
        onClickClose();
        setLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setLoading(false);
      });
  };

  const disabled = !assignRoleState.regionList.length || !selectedRole.length;

  return (
    <div>
      <VegaDrawer open={open} title={'Assign Role'} onClose={onClickClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Assign"
                  onClick={onSubmit}
                  disabled={disabled}
                  loading={loading}
                />
              </Stack>
            );
          }}
        >
          <AssignRoleForm
            assignRoleState={assignRoleState}
            selectedRole={selectedRole}
            setAssignRoleState={setAssignRoleState}
            setSelectedRole={setSelectedRole}
          />
        </VegaDrawerContent>
      </VegaDrawer>
    </div>
  );
};

export default AssignRoleDrawer;
