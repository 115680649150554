import { Stack } from '@mui/material';
import VegaDateTimePicker from '../../../../components/common/VegaDateTimePicker';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  ConfigValueProps,
  ConfigurationDto,
} from '../../../../types/configurationType';
import { DateUtility } from '../../../../utils/DateUtlility';

const AlertNotifySupervisorForm = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const handleAlertNotificationChange = (
    key: keyof ConfigValueProps,
    value: number | string | any
  ) => {
    if (key === 'reminderPeriod') {
      const updateConfigValueObj = { ...configurations.config, [key]: value };
      handleConfigChange('config', updateConfigValueObj);
    } else if (key === 'startTime') {
      const parsedDate = DateUtility.parseDateFromDatePicker(value);

      var endTime = DateUtility.parseStringToDate(
        configurations.config?.endTime
      );
      if (endTime && parsedDate) {
        if (DateUtility.isBefore(endTime, parsedDate)) {
          endTime = DateUtility.addDays(parsedDate, 1);
        }
      }

      const updateConfigValueObj = {
        ...configurations.config,
        startTime: parsedDate?.toISOString(),
        endTime: endTime?.toISOString(),
      };
      handleConfigChange('config', updateConfigValueObj);
    } else {
      const parsedDate = DateUtility.parseDateFromDatePicker(value);
      const updateConfigValueObj = {
        ...configurations.config,
        [key]: parsedDate?.toISOString(),
      };
      handleConfigChange('config', updateConfigValueObj);
    }
  };

  return (
    <Stack gap={2}>
      <VegaFormInputField label={'Expense Approval Deadline'}>
        <VegaDateTimePicker
          onChange={date =>
            handleAlertNotificationChange('expenseApprovalDeadline', date)
          }
          value={configurations.config?.expenseApprovalDeadline}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'Target Deviation Closure Deadline'}>
        <VegaDateTimePicker
          onChange={date =>
            handleAlertNotificationChange(
              'targetDeviationClosureDeadline',
              date
            )
          }
          value={configurations.config?.targetDeviationClosureDeadline}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'Min Visit Breach Deadline'}>
        <VegaDateTimePicker
          onChange={date =>
            handleAlertNotificationChange('minVisitBreachDeadline', date)
          }
          value={configurations.config?.minVisitBreachDeadline}
        />
      </VegaFormInputField>
    </Stack>
  );
};

export default AlertNotifySupervisorForm;
