import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../../../components/common/VegaText';

export const LeaderColumn = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'index',
      headerName: 'Ranking',
      flex: 1,
      renderCell: props => {
        // const index = props.api.getRowIndexRelativeToVisibleRows(
        //   props.row.agentId
        // );
        const index = props.row.index + 1;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={index}
          />
        );
      },
    },
    {
      field: 'agentName',
      headerName: 'Name',
      flex: 1,
    },
    { field: 'totalAmountEarned', headerName: 'Amount', flex: 1 },
  ];
  return COL_DEF;
};
