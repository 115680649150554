import { useState } from 'react';
import CreateCampaignFormDrawer from '../../components/TeleAllocation/TeleBulkAllocation/CreateCampaignFormDrawer';
import CreateLeadsetFormDrawer from '../../components/TeleAllocation/TeleBulkAllocation/CreateLeadsetFormDrawer';
import CreateProcessFormDrawer from '../../components/TeleAllocation/TeleBulkAllocation/CreateProcessFormDrawer';
import ListOfCampaignCreation from '../../components/TeleAllocation/TeleBulkAllocation/ListOfCampaignCreation';
import ListOfLeadsetCreation from '../../components/TeleAllocation/TeleBulkAllocation/ListOfLeadsetCreation';
import ListOfProcessCreation from '../../components/TeleAllocation/TeleBulkAllocation/ListOfProcessCreation';
import VegaButton from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import {
  setOpenCreateCampaignDrawer,
  setOpenCreateLeadsetDrawer,
  setOpenCreateProcessDrawer,
} from '../../features/teleAllocationSlice';
import { useAppDispatch } from '../../store';

export enum TabType {
  CAMPAIGN = 'CAMPAIGN',
  LEADSET = 'LEADSET',
  PROCESS = 'PROCESS',
}

const TeleBulkAllocation = () => {
  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const createCampaignDrawer = (value: boolean) => {
    dispatch(setOpenCreateCampaignDrawer(value));
  };
  const createLeadsetDrawer = (value: boolean) => {
    dispatch(setOpenCreateLeadsetDrawer(value));
  };
  const createProcessDrawer = (value: boolean) => {
    dispatch(setOpenCreateProcessDrawer(value));
  };

  const buttonGroup = () => {
    if (selectedTab === 1) {
      return {
        text: 'Create Process',
        onClick: () => createProcessDrawer(true),
      };
    } else if (selectedTab === 2) {
      return {
        text: 'Create Leadset',
        onClick: () => createLeadsetDrawer(true),
      };
    } else {
      return {
        text: 'Create Campaign',
        onClick: () => createCampaignDrawer(true),
      };
    }
  };
  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Auto Allocation'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <VegaButton
              text={buttonGroup().text}
              onClick={buttonGroup().onClick}
            />
          );
        }}
      />
      <VegaPageHeader
        sx={{ paddingTop: 0 }}
        isTabPresent
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedTab}
              onChange={(e, selected) => {
                setSelectedTab(selected);
              }}
            >
              <VegaTabBarItem label={`${toLowerCase(TabType.CAMPAIGN)}`} />{' '}
              <VegaTabBarItem label={`${toLowerCase(TabType.PROCESS)}`} />
              <VegaTabBarItem label={`${toLowerCase(TabType.LEADSET)}`} />
            </VegaTabBar>
          );
        }}
      />
      {selectedTab === 1 ? (
        <ListOfProcessCreation />
      ) : selectedTab === 2 ? (
        <ListOfLeadsetCreation />
      ) : (
        <ListOfCampaignCreation />
      )}
      <CreateCampaignFormDrawer />
      <CreateProcessFormDrawer />
      <CreateLeadsetFormDrawer />
    </>
  );
};

export default TeleBulkAllocation;
