import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReferToFieldService } from '../Apis/ReferToFieldService';
import { RootState } from '../store';
import { ReferToFieldDTO } from '../types/referToField';
import { GetReferToFieldListRequest } from '../types/request/referToField';

interface ReferToFieldRequestActionState {
  referTofieldList: ReferToFieldDTO[];
  page: number;
  totalItems: number;
  pageSize: number;
  loading: boolean;
  error: string | null;
}
export const fetchReferToFieldList = createAsyncThunk(
  'allocation/referToFieldList',
  async (request: Partial<GetReferToFieldListRequest>) => {
    return await ReferToFieldService.getReferToFieldList(request);
  }
);

const initialState: ReferToFieldRequestActionState = {
  loading: false,
  page: 0,
  totalItems: 0,
  pageSize: 0,
  referTofieldList: [],
  error: null,
};

const referToFieldSlice = createSlice({
  name: 'referToFieldAction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchReferToFieldList.pending, state => {
        state.loading = true;
      })
      .addCase(fetchReferToFieldList.fulfilled, (state, action) => {
        state.loading = false;
        state.referTofieldList = action.payload.records;
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchReferToFieldList.rejected, state => {
        state.loading = false;
      });
  },
});
export const referToFieldRequestSelector = (state: RootState) =>
  state.referToField;
export default referToFieldSlice;
