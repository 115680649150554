import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  fetchLoanTransactioinList,
  loanRequestSelector,
} from '../../../features/loanSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { LoanTransactionData } from '../../../types/loan';
import { StringUtility } from '../../../utils/StringUtility';
import VegaDataGrid from '../../common/VegaDataGrid';
import VegaText from '../../common/VegaText';
import { DateUtility } from '../../../utils/DateUtlility';
import VegaIconButton from '../../common/VegaIconButton';

interface IProps {
  loanAccountId: string;
}

const ListOfPaymentHistory = ({ loanAccountId }: IProps) => {
  const { loadingOfTransaction, totalItemsOfTransaction, transactions } =
    useAppSelector(loanRequestSelector);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  useEffect(() => {
    dispatch(fetchLoanTransactioinList({ loanId: loanAccountId, page, size }));
  }, [loanAccountId, page, size]);

  return (
    <VegaDataGrid
      data={transactions}
      loading={loadingOfTransaction}
      idColumn="transactionId"
      paginationMode="server"
      onPageChange={page => setPage(page)}
      onPageSizeChange={pageSize => setSize(pageSize)}
      rowCount={totalItemsOfTransaction}
      columns={getColumnDefinition()}
    />
  );
};

export default ListOfPaymentHistory;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'transactionId',
      headerName: 'Transaction Id',
      flex: 0.7,
      minWidth: 129,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const transactionId = parseInt(transaction?.transactionId ?? '0');

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={transactionId}
          />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'Loan A/c No.',
      flex: 0.7,
      minWidth: 129,
    },

    {
      field: 'paymentMode',
      headerName: 'Payment Mode',
      flex: 0.7,
      minWidth: 129,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const paymentMode = transaction.paymentMode
          ? toLowerCase(transaction.paymentMode)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={paymentMode}
          />
        );
      },
    },
    {
      field: 'instrumentType',
      headerName: 'Instrument Type',
      flex: 0.7,
      minWidth: 129,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const instrumentType = transaction.instrumentType
          ? toLowerCase(transaction.instrumentType)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={instrumentType}
          />
        );
      },
    },
    {
      field: 'paymentPurpose',
      headerName: 'Payment Purpose',
      flex: 0.7,
      minWidth: 129,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const paymentPurpose = transaction.paymentPurpose
          ? toLowerCase(transaction.paymentPurpose)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={paymentPurpose}
          />
        );
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',
      flex: 0.7,
      minWidth: 129,
    },
    {
      field: 'transactionAmount',
      headerName: 'Transaction Amount',
      flex: 0.7,
      minWidth: 129,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const transactionAmount = transaction.transactionAmount
          ? StringUtility.formatToINR(transaction.transactionAmount)
          : StringUtility.formatToINR(0);
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={transactionAmount}
          />
        );
      },
    },
    {
      field: 'accountingDate',
      headerName: 'Payment Date',
      flex: 0.7,
      minWidth: 129,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const accountingDate = DateUtility.formatStringToDDMMYYYY(
          transaction.accountingDate
        );
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={accountingDate ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={accountingDate || '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },

    {
      field: 'transactionDescription',
      headerName: 'Description',
      flex: 0.7,
      minWidth: 200,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const transactionDescription = transaction.transactionDescription
          ? toLowerCase(transaction.transactionDescription)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={transactionDescription}
          />
        );
      },
    },
    {
      field: 'bounceReason',
      headerName: 'Bounce Reason',
      flex: 0.7,
      minWidth: 200,
      renderCell: props => {
        const transaction = props.row as LoanTransactionData;
        const bounceReason = transaction.bounceReason
          ? toLowerCase(transaction.bounceReason)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bounceReason}
          />
        );
      },
    },
    {
      field: 'instrumentStatus',
      headerName: 'Status',
      flex: 0.7,
      minWidth: 129,
    },
  ];
  return COL_DEF;
};
