import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../../../components/common/VegaText';
import { LmsVariable, LoanData, LoanMaster } from '../../../../types/loan';
// import { DateUtility } from '../../../../utils/DateUtlility';
// import { StringUtility } from '../../../../utils/StringUtility';
import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import { toLowerCase } from '../../../../constants/commonFunction';
import { PRIMARY } from '../../../../constants/style';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { ROUTES } from '../../../../router/routes';
import { StringUtility } from '../../../../utils/StringUtility';

export const RollForwardReportColumn = () => {
  const navigate = useNavigate();
  const { userForId, agentIdToManager } = useClientAuth();
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const getManagerName = (agentId: string) => {
    if (!agentId) return 'NA';
    const manager = agentIdToManager(agentId);
    if (!manager) return 'NA';
    return manager;
  };

  const COLUMN_DEF: GridColumns<LoanData> = [
    {
      field: 'UniqueReferanceNumber',
      headerName: 'Loan Account Number',

      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as LoanData;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={loan.loanId ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={loan.loanId ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'Name',
      headerName: 'Name',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.lmsVariables) {
          const getCustomerName = loan?.lmsVariables?.customerName;
          if (getCustomerName) {
            displayText = toLowerCase(getCustomerName);
          }
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'ContactNumber',
      headerName: 'Contact Number',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.mobileNo) {
            displayText = getCustomerName.mobileNo;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Address',
      headerName: 'Address',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.customerAddress) {
            displayText = toLowerCase(getCustomerName.customerAddress);
          }
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },

    {
      field: 'cycleDate',
      headerName: 'Cycle Date',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.cycleDate) {
          displayText = `${LmsVariable.cycleDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.segment) {
          displayText = `${LmsVariable.segment}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'DPD',
      headerName: 'DPD',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const lmsVariables = loan.lmsVariables as LmsVariable;

        return (
          <VegaText
            text={lmsVariables?.dpd ?? ''}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },

    {
      field: 'Agent',
      headerName: 'Agent',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;

        let displayText = '';
        if (LmsVariable.agentId) {
          displayText = getUserName(LmsVariable.agentId);
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'managerId',
      headerName: 'Manager',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;

        const lmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (lmsVariable.agentId) {
          displayText = `${getManagerName(lmsVariable.agentId)}`;
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'Product',
      headerName: 'Product',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const loanMaster = loan.loanMaster as LoanMaster;
        let displayText = '';
        if (loanMaster?.loanProduct) {
          displayText = loanMaster?.loanProduct;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'modelParserAllocationType',
      headerName: 'Model Parser AllocationType',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const lmsVariables = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (lmsVariables?.modelParserAllocationType) {
          displayText = lmsVariables?.modelParserAllocationType;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'Branch',
      headerName: 'Branch',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.branchCode) {
          displayText = `${toLowerCase(LmsVariable.branchCode)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Bucket',
      headerName: 'Bucket',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.bucket) {
          displayText = `${toLowerCase(LmsVariable.bucket)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Overdue Amount',
      headerName: 'Overdue Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.overdueAmount) {
          displayText = `${StringUtility.formatToINR(
            LmsVariable.overdueAmount
          )}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // <ReceiptIcon />
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      minWidth: 160,
      renderCell: props => {
        const loanAccount = props.row as LoanData;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Trail"
              onClick={() =>
                navigate(
                  ROUTES.Trail_Detail_Page.replace(
                    ':loanAccountId',
                    loanAccount.loanId
                  )
                )
              }
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
