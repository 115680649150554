import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/userSlice';
import allocationSlice from './features/allocationSlice';
import auctionSlice from './features/auctionSlice';
import biddersSlice from './features/biddersSlice';
import bidSlice from './features/bidSlice';
import websocketSlice from './features/websocketSlice';
import websocketSubscriptionSlice from './features/websocketSubscriptionSlice';
import liveAuctionDetailsSlice from './features/liveAuctionDetailsSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import propertiesSlice from './features/propertiesSlice';
import { propertiesApi } from './services/properties';
import leaveRequestSlice from './features/leaveRequestSlice';
import minVisitsSlice from './features/minVisitsSlice';
import receiptManagmentSlice from './features/receiptManagmentSlice';
import targetSlice from './features/targetSlice';
import teleAllocationSlice from './features/teleAllocationSlice';
import teleAgentSlice from './features/teleAgentSlice';
import loanRequestSlice from './features/loanSlice';
import trailRequestSlice from './features/trailSlice';
import monthlyProgressSlice from './features/monthlyProgressSlice';
import visitSlice from './features/visitSlice';
import IncentivesGamificationsSlice from './features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import calenderSlice from './features/calenderSlice';
import accountsSlice from './features/accountsSlice';
import teleAgentAuditSlice from './features/teleAgentAuditSlice';
import escalationsSlice from './features/escalationsSlice';
import referToFieldSlice from './features/referToFieldSlice';
import systemCollectionSlice from './features/systemCollectionSlice';
import expenseClaimSlice from './features/expenseClaimSlice';
import legalRequestSlice from './features/legalSlice';
import teleCallingMetricsSlice from './features/teleCallingMetricsSlice';
import UAMSlice from './features/UAMSlice';
import authSlice from './features/authSlice';
import roleAccessSlice from './features/roleAccessSlice';
import performanceDashboardSlice from './features/performanceDashboardSlice';
import configurationSlice from './features/configurationSlice';
import geoVerificationSlice from './features/geoVerificationSlice';
import analyticsDashboardSlice from './features/analyticsDashboardSlice';
import filterColumnSlice from './features/filterColumnSlice';
import incentiveSimulatorSlice from './features/IncentivesGamificationsSlice/incentiveSimulatorSlice';
import incentiveLeaderboardSlice from './features/IncentivesGamificationsSlice/incentiveLeaderboardSlice';
import incentiveScoreCardSlice from './features/IncentivesGamificationsSlice/incentiveScoreCardSlice';
import incentiveForMonthSlice from './features/IncentivesGamificationsSlice/incentiveForMonthSlice';

export const store = configureStore({
  reducer: {
    bidders: biddersSlice.reducer,
    websocket: websocketSlice.reducer,
    websocketSubscriptions: websocketSubscriptionSlice.reducer,
    auction: auctionSlice.reducer,
    bid: bidSlice.reducer,
    allocations: allocationSlice.reducer,
    user: userSlice.reducer,
    liveAuctionDetails: liveAuctionDetailsSlice.reducer,
    properties: propertiesSlice.reducer,
    minVisits: minVisitsSlice.reducer,
    receipt: receiptManagmentSlice.reducer,
    target: targetSlice.reducer,
    teleAllocation: teleAllocationSlice.reducer,
    teleAgent: teleAgentSlice.reducer,
    accountSlice: accountsSlice.reducer,
    [propertiesApi.reducerPath]: propertiesApi.reducer,
    leaveRequestAction: leaveRequestSlice.reducer,
    loanRequestAction: loanRequestSlice.reducer,
    trailRequestAction: trailRequestSlice.reducer,
    monthlyProgressAction: monthlyProgressSlice.reducer,
    visit: visitSlice.reducer,
    incentiesGamifications: IncentivesGamificationsSlice.reducer,
    calender: calenderSlice.reducer,
    teleAgentAudit: teleAgentAuditSlice.reducer,
    escalations: escalationsSlice.reducer,
    referToField: referToFieldSlice.reducer,
    systemCollection: systemCollectionSlice.reducer,
    expenseClaim: expenseClaimSlice.reducer,
    legalRequestAction: legalRequestSlice.reducer,
    uam: UAMSlice.reducer,
    auth: authSlice.reducer,
    roleAccess: roleAccessSlice.reducer,
    teleCallingMetrics: teleCallingMetricsSlice.reducer,
    performanceDashboard: performanceDashboardSlice.reducer,
    configuration: configurationSlice.reducer,
    geoVerification: geoVerificationSlice.reducer,
    analyticsDashboard: analyticsDashboardSlice.reducer,
    filterColumn: filterColumnSlice.reducer,
    incentiveSimulator: incentiveSimulatorSlice.reducer,
    incentiveLeaderboard: incentiveLeaderboardSlice.reducer,
    incentiveScoreCard: incentiveScoreCardSlice.reducer,
    incentiveForMonth: incentiveForMonthSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      propertiesApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
