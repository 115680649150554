import { Stack, SxProps, Theme, Skeleton } from '@mui/material';
import { ReactNode } from 'react';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import { COMMON } from '../../../constants/style';

type IProps = {
  text: string;
  value?: string | number;
  loading?: boolean;
  color?: string;
  sx?: SxProps<Theme>;
  renderValue?: () => ReactNode;
};

const OtsKeyValueDisplay = ({
  text,
  value,
  loading,
  color = COMMON.balck,
  sx,
  renderValue,
}: IProps) => {
  return (
    <Stack
      sx={{
        ...sx,
        pr: 1.5,
        position: 'relative',
      }}
    >
      <VegaFormInputField label={text}>
        {loading == true && <Skeleton />}
        {renderValue && renderValue()}
        {renderValue == null && (
          <VegaText
            text={value}
            sx={{ mt: '0.25rem !important' }}
            fontSize={'0.8125rem'}
            fontWeight={450}
            fontColor={color}
          />
        )}
      </VegaFormInputField>
    </Stack>
  );
};
export default OtsKeyValueDisplay;
