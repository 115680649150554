import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { OtsService } from '../../../../Apis/OtsService';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFileSelect, {
  VegaFileSelectOption,
  AcceptedExtension,
} from '../../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaText from '../../../../components/common/VegaText';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  OtsAuditType,
  Ots,
  OtsRequestAudit,
  OtsStatus,
  OtsAction,
} from '../../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { COLOR } from '../../../../utils/ColorUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  otsId?: string;
  onSubmit: (otsId: string, data: Partial<SubmitAckLetterFormData>) => void;
};

enum FormField {
  Letter = 'letter',
}

export type SubmitAckLetterFormData = {
  letter: VegaFileSelectOption;
};

const ACCEPTED_EXTENSIONS = [
  AcceptedExtension.JPG,
  AcceptedExtension.JPEG,
  AcceptedExtension.PNG,
  AcceptedExtension.PDF,
];
function OtsUploadCustomerAckLetterDrawer({
  otsId,
  open,
  onClose,
  onSubmit,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const [ots, setOts] = useState<Partial<Ots>>({});
  const [audit, setAudit] = useState<Partial<OtsRequestAudit>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<SubmitAckLetterFormData>>(
    {}
  );

  async function fetchData() {
    try {
      setLoading(true);
      const response = await OtsService.getOtsById(otsId);
      const auditResponse = await OtsService.getOtsAudit({
        otsId: otsId,
        auditType: OtsAuditType.OTS_REQUEST,
        userId: ots?.rcm,
        action: OtsAction.REJECT,
      });
      if (auditResponse.records.length > 0) {
        const latestAudit = auditResponse.records[0];
        setAudit(latestAudit);
      }
      setOts(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function onSubmitClick() {
    if (!otsId) return;
    onSubmit(otsId, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
    setAudit(undefined);
    setOts(undefined);
  }

  const isInputValid = () => {
    return formData.letter != null;
  };

  function prefillData(ots: Partial<Ots>) {
    const ackLetter = ots?.ackLetter;
    if (ackLetter) {
      setFormData({
        letter: {
          id: ackLetter,
          label: 'Ack Letter',
        },
      });
    }
  }

  const wasRejected = () => {
    return ots?.status == OtsStatus.POST_CUSTOMER_ACKNOWLEDGEMENT_APPROVE;
  };

  useEffect(() => {
    if (open && otsId) {
      fetchData();
    }
  }, [open, otsId]);

  useEffect(() => {
    if (ots) {
      prefillData(ots);
    }
  }, [ots]);

  return (
    <VegaDrawer open={open} title={'Upload Ack Letter'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Submit'}
              disabled={isInputValid() == false}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <AnimatedInputWrapper show={wasRejected()} marginTop={'0.25rem'}>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: COLOR.RED.LIGHT,
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                }}
              >
                <VegaText
                  text={audit?.description ?? '-'}
                  color={COLOR.RED.DARK}
                />
              </div>
            </Grid>
          </AnimatedInputWrapper>

          <Grid item xs={12}>
            <VegaFormInputField label={'Ack Letter'}>
              <VegaFileSelect
                value={formData.letter}
                acceptedExtension={ACCEPTED_EXTENSIONS}
                placeholder={'Upload Ack letter'}
                onFileSelect={file => {
                  updateFormData(FormField.Letter, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.Letter, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default OtsUploadCustomerAckLetterDrawer;
