/* eslint-disable no-unused-vars */
import { Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { liveAuctionDetailsState } from '../../features/liveAuctionDetailsSlice';
import { useAppSelector } from '../../store';
import { Auction, Bid, Possession } from '../../types/auction';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import LmDialog from '../common/LmDialog';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaText from '../common/VegaText';
import { MinusCircleIcon, PlusCircleIcon } from '../Icons/Icons';

type Props = {
  show: boolean;
  onClose: () => void;
  onSave: (auction: Auction, formData: Partial<CreateBidFormData>) => void;
  latestBid?: Bid;
};

export type CreateBidFormData = {
  bidAmount: number;
};

function MakeBidDialog({ show, onClose, onSave, latestBid }: Props) {
  const [formData, setFormData] = useState<Partial<CreateBidFormData>>({});
  const { auction } = useAppSelector(liveAuctionDetailsState);

  const isInputValid = () => {
    const minAmount = getMinBidAllowedAmount();
    return formData.bidAmount >= minAmount;
  };

  function onMakeBidClick() {
    if (!auction) return;
    onSave(auction, formData);
    closeDialog();
  }

  function cleanUp() {
    setFormData({});
  }

  function closeDialog() {
    onClose();
    cleanUp();
  }

  const getMinBidAllowedAmount = () => {
    const latestBidAmount = latestBid?.bidAmount ?? 0;
    const minIncrement = auction?.minIncrement ?? 0;
    var minAmount = latestBidAmount + minIncrement;
    const reservePrice = auction?.reservePrice ?? 0;

    if (minAmount == minIncrement) {
      minAmount += reservePrice;
    }
    return minAmount;
  };

  const getPossession = () => {
    if (!auction?.metaData) return;
    if (Object.hasOwn(auction.metaData, 'possessionItem')) {
      return auction.metaData['possessionItem'] as Possession;
    }
  };

  const getFirstImage = () => {
    const possession = getPossession();
    const images = possession?.images ?? [];
    if (images.length > 0) return images[0];
  };

  function onMinusClick() {
    var bidAmount = formData.bidAmount ?? 0;
    const newAmount = bidAmount - auction.minIncrement ?? 0;
    if (newAmount < getMinBidAllowedAmount()) return;
    setFormData(prev => {
      return {
        ...prev,
        bidAmount: newAmount,
      };
    });
  }

  function onPlusClick() {
    var bidAmount = formData.bidAmount ?? 0;
    if (bidAmount == 0) {
      bidAmount = auction.reservePrice;
    }
    const newAmount = bidAmount + auction.minIncrement ?? 0;
    setFormData(prev => {
      return {
        ...prev,
        bidAmount: newAmount,
      };
    });
  }

  useEffect(() => {
    if (open && latestBid) {
      setFormData(prev => {
        return {
          ...prev,
          bidAmount: getMinBidAllowedAmount(),
        };
      });
    }
  }, [open, latestBid]);

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      isCloseIcon
      handleClose={closeDialog}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Place a bid"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaText
              text={'Details'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '1.05rem',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: 'rgba(232, 245, 252, 1)',
                padding: '1rem',
                borderRadius: '0.5rem',
              }}
            >
              <Stack direction={'row'} spacing="1rem">
                <div
                  style={{
                    borderRadius: '1.25rem',
                    height: '6rem',
                    width: '6rem',
                  }}
                >
                  <img
                    src={getFirstImage()}
                    height={'100%'}
                    width={'100%'}
                    style={{ objectFit: 'cover', borderRadius: '1.25rem' }}
                  ></img>
                </div>
                <Stack spacing={'0.75rem'}>
                  <VegaText
                    text={'Property Name'}
                    style={{
                      color: '#1B1D22',
                      fontSize: '0.8125rem',
                      fontWeight: 500,
                      lineHeight: '120%',
                    }}
                  />
                  <VegaText
                    text={getPossession()?.address}
                    style={{
                      color: '#1B1D22',
                      fontSize: '0.8125rem',
                      fontWeight: 500,
                      lineHeight: '120%',
                    }}
                  />
                </Stack>
              </Stack>
            </div>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <VegaFormInputField label="Auction Ends At">
                <VegaText
                  text={`${DateUtility.formatStringToDDMMYYYYWithTime(
                    auction?.endDateTime
                  )}`}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={4}>
              <VegaFormInputField label="Highest Bid">
                <VegaText
                  text={`${StringUtility.formatToINR(latestBid?.bidAmount)}`}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Stack spacing={'0.5rem'}>
                <LmTextField
                  value={StringUtility.formatToINR(formData.bidAmount ?? 0)}
                />
                <VegaText
                  text={
                    'Min Raise: ' +
                    StringUtility.formatToINR(auction?.minIncrement)
                  }
                  fontWeight={500}
                  style={{
                    fontSize: '0.8125rem',
                    lineHeight: '120%',
                    color: '#676B76',
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'} gap={'0rem'}>
                <IconButton onClick={onMinusClick}>
                  <MinusCircleIcon />
                </IconButton>
                <IconButton onClick={onPlusClick}>
                  <PlusCircleIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <VegaButton
                text="Make Bid"
                sx={{
                  bgcolor: "'#1047DC'",
                }}
                onClick={onMakeBidClick}
                disabled={isInputValid() == false}
              />
            </Stack>
          </Grid>
        </Grid>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default MakeBidDialog;
