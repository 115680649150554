import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OtsService } from '../../../Apis/OtsService';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaText from '../../../components/common/VegaText';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { VegaUser } from '../../../types/claim';
import { LoanData } from '../../../types/loan';
import {
  getOtsStatusDescription,
  getValueFromRecord,
  Ots,
  OtsMetaDataKey,
  OtsStatus,
} from '../../../types/ots';
import { GetOtsRequests } from '../../../types/request/ots';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../../utils/api';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../constants/style';

const VALID_STATUS: OtsStatus[] = [
  OtsStatus.PRE_NCM_APPROVAL,
  OtsStatus.POST_NCM_APPROVAL,
  OtsStatus.PRE_NCM_REJECT,
  OtsStatus.PRE_NCM_ON_HOLD,
  OtsStatus.POST_HOC_REJECT,
  OtsStatus.MD_REJECT,
  OtsStatus.POST_RCM_ON_HOLD,
];
function NcmOtsPage() {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [otsData, setOtsData] = useState<PaginatedResponse<Ots>>({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });
  const [historyData, setHistoryData] = useState<PaginatedResponse<Ots>>({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });

  async function fetchData() {
    try {
      setLoading(true);
      var request: Partial<GetOtsRequests> = {};
      if (selectedTab == 0) {
        request = {
          page: otsData.pageNumber,
          pageSize: 10,
          status: VALID_STATUS.join(','),
          includeUserDetails: true,
          includeLoanDetails: true,
          ncm: user?.id,
        };
      }

      if (selectedTab == 1) {
        request = {
          page: historyData.pageNumber,
          pageSize: 10,
          ncm: user?.id,
          includeUserDetails: true,
          includeLoanDetails: true,
        };
      }

      const response = await OtsService.listOtsRequests(request);
      if (selectedTab == 0) {
        setOtsData(response);
      } else if (selectedTab == 1) {
        setHistoryData(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getData = () => {
    return selectedTab == 0 ? otsData : historyData;
  };

  useEffect(() => {
    fetchData();
  }, [otsData.pageNumber, historyData.pageNumber, selectedTab]);

  return (
    <div>
      <VegaPageContent>
        <VegaPageHeader
          sx={{
            borderBottom: 'none',
            bgcolor: 'transparent',
          }}
          renderLeftView={() => {
            return (
              <Stack spacing={'2rem'}>
                <VegaTabBar
                  value={selectedTab}
                  variant="scrollable"
                  scrollButtons={false}
                  onChange={(e, selected) => {
                    setSelectedTab(selected);
                  }}
                >
                  <VegaTabBarItem label="Pending" />
                  <VegaTabBarItem label="All" />
                </VegaTabBar>
              </Stack>
            );
          }}
        />
        <VegaPageContent>
          <VegaDataGrid
            data={getData().records}
            loading={loading}
            columns={getColumnDefinition({
              onViewDetailsClick: ots => {
                navigate(ROUTES.OTS_DETAILS.replace(':otsId', ots.id));
              },
            })}
            idColumn="id"
            rowCount={getData().numberOfItems}
            page={getData().pageNumber}
            pageSize={10}
          />
        </VegaPageContent>
      </VegaPageContent>
    </div>
  );
}

export default NcmOtsPage;

const getColumnDefinition = (data: {
  onViewDetailsClick: (ots: Ots) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Ots Request Date',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const parsedDate = DateUtility.parseStringToDate(ots.createdAt);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        let displayText = 'NA';
        const metadata = ots.metaData;
        if (metadata) {
          const loan = getValueFromRecord<LoanData>(metadata, 'loan');
          const customerDetails = loan?.customerDetails ?? [];
          if (customerDetails.length > 0) {
            displayText = customerDetails[0].customerName;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentId',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const raisedBy = getValueFromRecord<VegaUser>(
          ots?.metaData,
          OtsMetaDataKey.RAISED_BY
        );
        const displayText = StringUtility.concatenateStrings(
          ' ',
          raisedBy?.firstName,
          raisedBy?.middleName,
          raisedBy?.lastName
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'Loan Id',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = ots.loanId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsStatus',
      headerName: 'Ots Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = getOtsStatusDescription(ots?.status);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'technicalTeamStatus',
      headerName: 'Technical Team Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = _.startCase(_.toLower(ots.technicalReportsStatus));
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'operationTeamStatus',
      headerName: 'Operation Team Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = _.startCase(_.toLower(ots.operationReportStatus));
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const ots = getOtsForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(ots);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(ots);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getOtsForRow = (props: any) => props.row as Ots;
