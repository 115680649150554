/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { UserService } from '../../Apis/UserService';
import { useClientAuth } from '../../providers/ClientProvider';
import { VegaUser } from '../../types/claim';
import { StringUtility } from '../../utils/StringUtility';
import { VegaSelectOption } from '../common/VegaSelect';
import VegaSelectWithCheckbox, {
  VegaSelectorOptionTypeEnum,
} from '../common/VegaSelectWithCheckbox';

interface IProps {
  selected: string[];
  onSelect: (select: string) => void;
  onDelete: (select: number) => void;
  placeholder?: string;
  disabled?: boolean;
  ignoreAgentCode?: boolean;
  ignoreLodash?: boolean;
}

const MultiSelectAgentSelector = ({
  onSelect,
  selected,
  onDelete,
  placeholder,
  disabled,
  ignoreAgentCode = true,
  ignoreLodash = false,
}: IProps) => {
  const { user } = useClientAuth();
  const [searchValue, setSearchValue] = useState<string>('');
  const [agentList, setAgentList] = useState<VegaSelectOption[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== agentList.length) {
        setPage(page + 1);
      }
    }
  };

  const getAgentList = async () => {
    try {
      const userId = user?.id;
      if (!userId) return;
      // const response = await UserService.getAgentsForManager(userId);
      const response = await UserService.getUsers({
        managerId: userId,
        page,
        size: 10,
        ...(searchValue.length && { name: searchValue }),
      });
      if (response && response.records?.length) {
        const agentOptionList = response.records.map((agent: VegaUser) => {
          const agentName = ignoreAgentCode
            ? StringUtility.concatenateStrings(
                ' ',
                agent?.firstName,
                agent?.middleName,
                agent?.lastName
              )
            : `${StringUtility.concatenateStrings(
                ' ',
                agent?.firstName,
                agent?.middleName,
                agent?.lastName
              )} (${agent.employeeCode})`;
          return {
            value: agent.id,
            label: agentName,
          };
        });
        if (page === 0) {
          setAgentList(agentOptionList);
        } else {
          setAgentList([...agentList, ...agentOptionList]);
        }
        setTotalItem(response.totalItems);
      }
    } catch (error) {}
  };

  const mapAgentNameForAgentId = () => {
    return (
      selected?.map(item => {
        return getUserName(item);
      }) ?? []
    );
  };

  useEffect(() => {
    if (user) {
      const getList = setTimeout(() => {
        getAgentList();
      }, 400);
      return () => clearTimeout(getList);
    }
  }, [user, page, searchValue]);

  return (
    <VegaSelectWithCheckbox
      options={agentList}
      onSelect={selected => onSelect(selected)}
      handleDelete={onDelete}
      selected={mapAgentNameForAgentId()}
      placeHolder={placeholder}
      disabled={disabled}
      onScroll={loadMoreItems}
      handleChange={e => {
        setSearchValue(e.target.value);
        setPage(0);
      }}
      searchValue={searchValue}
      isIdSelector={VegaSelectorOptionTypeEnum.label}
      ignoreLodash={ignoreLodash}
    />
  );
};

export default MultiSelectAgentSelector;
