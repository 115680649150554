import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { OtsService } from '../../../../Apis/OtsService';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFileSelect, {
  VegaFileSelectOption,
  AcceptedExtension,
} from '../../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaText from '../../../../components/common/VegaText';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  OtsAuditType,
  OtsReportsStatus,
  Ots,
  OtsRequestAudit,
} from '../../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { COLOR } from '../../../../utils/ColorUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  otsId?: string;
  onSubmit: (otsId: string, data: Partial<SubmitTechnicalDataFormData>) => void;
};

enum FormField {
  ValuationReport = 'valuationReport',
  ValuationAmount = 'valuationAmount',
  DisressedValuationAmount = 'distressedValuationAmount',
}

export type SubmitTechnicalDataFormData = {
  valuationReport: VegaFileSelectOption;
  valuationAmount: number;
  distressedValuationAmount: number;
};

const ACCEPTED_EXTENSIONS = [
  AcceptedExtension.JPG,
  AcceptedExtension.JPEG,
  AcceptedExtension.PNG,
  AcceptedExtension.PDF,
];
function SubmitTechnicalReportDrawer({
  otsId,
  open,
  onClose,
  onSubmit,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const [ots, setOts] = useState<Partial<Ots>>({});
  const [audit, setAudit] = useState<Partial<OtsRequestAudit>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<
    Partial<SubmitTechnicalDataFormData>
  >({});

  function updateFormData(field: FormField, value: any) {
    if (isPendingApproval()) return;
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function fetchData() {
    try {
      setLoading(true);
      const response = await OtsService.getOtsById(otsId);
      const auditResponse = await OtsService.getOtsAudit({
        otsId: otsId,
        auditType: OtsAuditType.TECHNICAL_REPORT,
      });
      if (auditResponse.records.length > 0) {
        const latestAudit = auditResponse.records[0];
        setAudit(latestAudit);
      }
      setOts(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onSubmitClick() {
    if (!otsId) return;
    onSubmit(otsId, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
    setAudit(undefined);
    setOts(undefined);
  }

  const isInputValid = () => {
    if (isPendingApproval()) return false;
    const isValuationReportValid = formData.valuationReport != null;
    const isValuationAmountValid = (formData.valuationAmount ?? 0) > 0;
    const isDisressedValuationAmountValid =
      (formData.distressedValuationAmount ?? 0) > 0;
    return (
      isValuationReportValid &&
      isValuationAmountValid &&
      isDisressedValuationAmountValid
    );
  };
  function prefillData(ots: Partial<Ots>) {
    setFormData(prev => {
      var updatedData = { ...prev };
      const valuationReport = ots?.technicalValuationReport;

      if (valuationReport) {
        updatedData.valuationReport = {
          id: valuationReport,
          label: 'Valuation Report',
        };
      }
      updatedData.valuationAmount = parseNumberValue(ots?.valuationAmount);
      updatedData.distressedValuationAmount = parseNumberValue(
        ots?.distressedValuationAmount
      );

      return updatedData;
    });
  }

  const wasRejected = () => {
    return ots?.technicalReportsStatus == OtsReportsStatus.REJECTED;
  };

  const isPendingApproval = () => {
    return ots?.technicalReportsStatus == OtsReportsStatus.PENDING;
  };

  const parseNumberValue = (value: any) => {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    return isValidNumber ? parsedNumber : undefined;
  };

  useEffect(() => {
    if (open && otsId) {
      fetchData();
    }
  }, [open, otsId]);

  useEffect(() => {
    if (ots) {
      prefillData(ots);
    }
  }, [ots]);

  return (
    <VegaDrawer
      open={open}
      title={
        wasRejected() ? 'Update Technical detals' : 'Add Technical details'
      }
      onClose={handleClose}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={wasRejected() ? 'Resubmit' : 'Submit'}
              disabled={isInputValid() == false}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <AnimatedInputWrapper show={wasRejected()} marginTop={'0.25rem'}>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: COLOR.RED.LIGHT,
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                }}
              >
                <VegaText
                  text={audit?.description ?? '-'}
                  color={COLOR.RED.DARK}
                />
              </div>
            </Grid>
          </AnimatedInputWrapper>
          <Grid item xs={12}>
            <VegaFormInputField label={'Valuation Amount'}>
              <LmTextField
                value={formData.valuationAmount}
                disabled={isPendingApproval()}
                onChange={e => {
                  updateFormData(
                    FormField.ValuationAmount,
                    parseNumberValue(e.target.value)
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Disressed Valuation Amount'}>
              <LmTextField
                value={formData.distressedValuationAmount}
                disabled={isPendingApproval()}
                onChange={e => {
                  updateFormData(
                    FormField.DisressedValuationAmount,
                    parseNumberValue(e.target.value)
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Valuation Report'}>
              <VegaFileSelect
                value={formData.valuationReport}
                acceptedExtension={ACCEPTED_EXTENSIONS}
                placeholder={'Upload request letter'}
                disabled={isPendingApproval()}
                onFileSelect={file => {
                  updateFormData(FormField.ValuationReport, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.ValuationReport, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default SubmitTechnicalReportDrawer;
