import { createContext, useContext, useEffect, useState } from 'react';
import {
  createDepositBank,
  deleteDepositBank,
  fetchDepositBanksList,
  updateDepositBank,
  uploadBulkBankDeposit,
  updateDepositBankStatus,
} from '../Apis/depositBank';
import {
  BnakDepositRequest,
  ICreateDepositBankProps,
  IDepositBankListProps,
} from '../types/depositBank';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';

export interface IPastUploadListProps {
  id: number | string;
  requestedId: string;
  fileName: string;
  date: string;
  totalEntries: string;
  success: string;
  failure: string;
  status: string;
}
export const dummy_PastUpload: IPastUploadListProps[] = [
  {
    id: 1,
    requestedId: '#23113344',
    fileName: 'Branches.csv',
    date: '22/03/2023',
    totalEntries: '213123124',
    success: '1234555',
    failure: '345',
    status: 'IN_PROGRESS',
  },
  {
    id: 2,
    requestedId: '#23113344',
    fileName: 'Branches.csv',
    date: '22/03/2023',
    totalEntries: '213123124',
    success: '1234555',
    failure: '0',
    status: 'SUCCESS',
  },
];

export interface BankDepositContextType {
  depositeBankState: ICreateDepositBankProps | null;
  bankList: IDepositBankListProps[];
  search: string;
  bankListLoading: boolean;
  loading: boolean;
  isAdd: boolean;
  isBulkUpload: boolean;
  isPastUploadView: boolean;
  page: number;
  size: number;
  totalItems: number;
  selectedId: string | null;
  toggleAddBankDepositDrawer: () => void;
  toggleBulkUploadModal: () => void;
  togglePastUploadViewModal: () => void;
  onAddDepositBankSubmit: () => void;
  onDeleteDepositBank: () => void;

  onUpdateDepositBankSubmit: (
    updatedDepositBank: ICreateDepositBankProps
  ) => void;
  onUploadBulkBankDeposit: (file: File) => void;
  getDepositBank: (request: Partial<BnakDepositRequest>) => void;

  onUpdateDepositBankStatus: (
    updatedDepositBank: ICreateDepositBankProps
  ) => void;

  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
  setDepositeBankState: React.Dispatch<
    React.SetStateAction<ICreateDepositBankProps>
  >;
  handleDepositBankChange: (
    name: keyof ICreateDepositBankProps,
    value: string
  ) => void;
}

export const BankDepositContext = createContext<BankDepositContextType | null>(
  null
);

export const defaultDepositBankData: ICreateDepositBankProps = {
  bankName: '',
  accountNumber: '',
  bankCode: '',
  branchName: '',
  branchCode: '',
  ifsc: '',
  micr: '',
  segment: '',
};

const BankDepositProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [isPastUploadView, setIsPastUploadView] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [depositeBankState, setDepositeBankState] =
    useState<ICreateDepositBankProps>({ ...defaultDepositBankData });

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [bankList, setBankList] = useState<IDepositBankListProps[]>([]);
  const [bankListLoading, setBankListLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleAddBankDepositDrawer = () => {
    setIsAdd(!isAdd);
  };
  const toggleBulkUploadModal = () => {
    setIsBulkUpload(!isBulkUpload);
  };
  const togglePastUploadViewModal = () => {
    setIsPastUploadView(!isPastUploadView);
  };

  const onAddDepositBankSubmit = async () => {
    if (!depositeBankState) return;
    setLoading(true);
    try {
      await createDepositBank(depositeBankState);
      setSnackbar('Deposit bank Added');
      toggleAddBankDepositDrawer();
      getDepositBank({});
      setLoading(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Deposit bank'),
        'error'
      );
      setLoading(false);
    }
  };

  const onUploadBulkBankDeposit = async (file: File) => {
    if (!file) return;
    try {
      await uploadBulkBankDeposit(file);
      setSnackbar('File uploaded successfully !!');
      toggleBulkUploadModal();
      getDepositBank({});
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to upload file'),
        'error'
      );
    }
  };
  const onUpdateDepositBankSubmit = async (
    updatedDepositBank: ICreateDepositBankProps
  ) => {
    if (!updatedDepositBank) return;
    setLoading(true);
    try {
      await updateDepositBank(updatedDepositBank);
      setSnackbar('Deposit bank updated');
      toggleAddBankDepositDrawer();
      getDepositBank({});
      setLoading(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to updated Deposit bank'),
        'error'
      );
      setLoading(false);
    }
  };
  const onUpdateDepositBankStatus = async (
    updatedDepositBank: ICreateDepositBankProps
  ) => {
    if (!updatedDepositBank) return;
    try {
      await updateDepositBankStatus(updatedDepositBank);
      setSnackbar(' Status updated');
      getDepositBank({});
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to updated status'),
        'error'
      );
    }
  };

  const onDeleteDepositBank = async () => {
    if (!selectedId) return;
    try {
      await deleteDepositBank(selectedId);
      getDepositBank({});
      setSnackbar('Deposit bank deleted');
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to delete Deposit bank'),
        'error'
      );
    }
  };

  const handleDepositBankChange = (
    name: keyof ICreateDepositBankProps,
    value: string
  ) => {
    setDepositeBankState({ ...depositeBankState, [name]: value });
  };

  const getDepositBank = async (request: Partial<BnakDepositRequest>) => {
    setBankListLoading(true);
    try {
      const response = await fetchDepositBanksList(request);
      setBankListLoading(false);
      setBankList(response.records);
      setTotalItems(response.totalItems);
    } catch (error) {
      setBankListLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    getDepositBank({ bankName: search, page, size });
  }, [page, size, search]);

  return (
    <BankDepositContext.Provider
      value={{
        bankList,
        loading,
        page,
        size,
        totalItems,
        bankListLoading,
        depositeBankState,
        search,
        isAdd,
        isBulkUpload,
        isPastUploadView,
        selectedId,
        setPage,
        setSize,
        setTotalItems,
        getDepositBank,
        setSelectedId,
        setDepositeBankState,
        toggleAddBankDepositDrawer,
        toggleBulkUploadModal,
        togglePastUploadViewModal,
        onAddDepositBankSubmit,
        onUpdateDepositBankSubmit,
        onUpdateDepositBankStatus,
        onDeleteDepositBank,
        onUploadBulkBankDeposit,
        setSearch,
        handleDepositBankChange,
      }}
    >
      {children}
    </BankDepositContext.Provider>
  );
};

export const useBankDeposit = () =>
  useContext(BankDepositContext) as BankDepositContextType;

export default BankDepositProvider;
