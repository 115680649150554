import { Grid, Stack, Box } from '@mui/material';
import { useState } from 'react';
import LmDialog from '../../common/LmDialog';
import VegaButton from '../../common/VegaButton';
import VegaDialogContent from '../../common/VegaDialogContent';
import VegaText from '../../common/VegaText';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import ListOfPaymentHistory from './ListOfPaymentHistory';
import { useParams } from 'react-router-dom';

const PaymentHistoryDetails = () => {
  const { loanAccountId } = useParams();
  const [openPaymentHistoryDrawer, setOpenPaymentHistoryDrawer] =
    useState<boolean>(false);
  return (
    <>
      <TeleAgentDetailsCard text="Payment History Details" isBorder>
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <TeleAgentTextWrapper text="Bounce on Cycle Dates" value="NA" />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <TeleAgentTextWrapper text="Bounce Reason" value="NA" />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <TeleAgentTextWrapper
                text="Charges levied for bounce"
                value="NA"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <TeleAgentTextWrapper text="Other Charges" value="NA" />
            </Grid>
          </Grid>
          <Box textAlign={'end'}>
            <VegaButton
              variant="outlined"
              text="Payment History"
              onClick={() => setOpenPaymentHistoryDrawer(true)}
            />
          </Box>
        </Stack>
      </TeleAgentDetailsCard>

      <LmDialog
        fullScreen
        maxWidth="lg"
        open={openPaymentHistoryDrawer}
        handleClose={() => setOpenPaymentHistoryDrawer(false)}
        isCloseIcon
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Payment History"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <ListOfPaymentHistory loanAccountId={loanAccountId} />
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default PaymentHistoryDetails;
