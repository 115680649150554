import { getErrorMessageFromErrorObj } from '../utils/api';

export interface GetPaginatedApiRequest {
  page: number;
  pageSize: number;
  size: number;
  status: string;
}
export interface GetPaginatedApiRequestWithoutStatus {
  page: number;
  pageSize: number;
  size: number;
}
export interface GetCount {
  id: string;
}

export class ApiService {
  catchError(error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const serverErrorMessage = getErrorMessageFromErrorObj(
      error,
      'Something went wrong'
    );
    // TODO: throw custom exception
  }
}
