import axios from 'axios';
import { Possession } from '../types/auction';
import { GetPossessionListApiRequest } from '../types/request/possession';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/repossession',
  LIST: '/list',
  UPLOAD: '/upload/bulk',
  SAMPLE: '/sample',
  IMAGES: '/images',
};
const BASE_URL = Endpoint.BASE;
const UPLAOD_BULK__URL = Endpoint.BASE + Endpoint.UPLOAD;

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export class PossessionService {
  static async fetchPossessionList(
    request: Partial<GetPossessionListApiRequest>
  ): Promise<PaginatedResponse<Possession>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async downloadSampleFile() {
    const endpoint = UPLAOD_BULK__URL + Endpoint.SAMPLE;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async uploadBulkPropertiesFile(file: File) {
    const endpoint = UPLAOD_BULK__URL;
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(endpoint, formData, config);
    return response.data;
  }
  static async fetchImagesByLoanId(
    loanId: string
  ): Promise<{ imageUrls: string[] }> {
    const endpoint = BASE_URL + Endpoint.IMAGES;

    const response = await axios.get(endpoint, { params: { loanId } });
    return response.data;
  }
}
