import axios from 'axios';
import {
  DownloadMinVisitRequest,
  FetchVisitListByAgentIdRequest,
  FetchVisitListRequest,
  ListTrackingForAgentsRequestDto,
  TrackingResponseDto,
  UpdateVisitListByAgentIdRequest,
  VisitDetailsDto,
} from '../types/request/visit';
import { FetchVisitsListResponse } from '../types/visits';
import { PaginatedResponse } from '../utils/api';
import { LongRunningTask } from '../types/allocations';

const Endpoint = {
  BASE: '/visit',
  LIST: '/list',
  GET: '/list/withLoans',
  VISIT_LOCATION_TRACKING_LIST: '/tracking/list',
  DOWNLOAD_MIN_VISIT_LIST: '/tracking/export',
  DOWNLOAD_VISIT_LIST: '/agent/export',
};

const BASE_URL = Endpoint.BASE;
export class VisitService {
  static async getVisitList(
    request: Partial<FetchVisitListRequest>
  ): Promise<PaginatedResponse<FetchVisitsListResponse>> {
    const endpoint = BASE_URL + Endpoint.GET;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getVisitListByAgentId(
    request: Partial<FetchVisitListByAgentIdRequest>
  ): Promise<PaginatedResponse<any>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async updateVisitListByAgentId(
    request: Partial<UpdateVisitListByAgentIdRequest>
  ): Promise<PaginatedResponse<VisitDetailsDto>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getVisitLocationTrackingList(
    request: Partial<ListTrackingForAgentsRequestDto>
  ): Promise<TrackingResponseDto[]> {
    const endpoint = BASE_URL + Endpoint.VISIT_LOCATION_TRACKING_LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async downloadMinVisitList(
    request: Partial<DownloadMinVisitRequest>
  ): Promise<LongRunningTask> {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_MIN_VISIT_LIST;

    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async downloadVisitList(
    request: Partial<UpdateVisitListByAgentIdRequest>
  ): Promise<LongRunningTask> {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_VISIT_LIST;

    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
