import { Grid, Skeleton } from '@mui/material';
import _ from 'lodash';
import { getAllocations } from '../../features/allocationSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useAppSelector } from '../../store';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaText from '../common/VegaText';

const CriteriaDetails = () => {
  const { loadingOfViewCriteria, criteriaData } =
    useAppSelector(getAllocations);
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Criteria name'}>
            {getTextLoadingWrapper(
              getCriteriaValue(criteriaData?.name),
              loadingOfViewCriteria
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'description'}>
            {getTextLoadingWrapper(
              getCriteriaValue(criteriaData?.description),
              loadingOfViewCriteria
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'status'}>
            {getTextLoadingWrapper(
              getCriteriaValue(criteriaData?.status),
              loadingOfViewCriteria
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'created By'}>
            {getTextLoadingWrapper(
              getUserName(getCriteriaValue(criteriaData?.createdBy)),
              loadingOfViewCriteria
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
    </>
  );
};

export default CriteriaDetails;

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return (
      <VegaText
        text={_.startCase(_.toLower(value))}
        fontWeight={500}
        fontSize={'0.875rem'}
      />
    );
  }
};

const getCriteriaValue = (value: string) => {
  if (!value) return '--';
  if (value.length) {
    return value;
  } else {
    return '--';
  }
};
