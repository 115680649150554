import { Box, Stack } from '@mui/material';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  PayoutPolicyType,
  getIncentivesGamificationsState,
  setSelectedPayoutPolicyType,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import VegaPageContent from '../../common/VegaPageContent';
import VegaText from '../../common/VegaText';
import PenaltyIncentiveComponent from '../components/PenaltyIncentiveComponent';
import BumperIncentiveComponent from '../components/BumperIncentiveComponent';
import BaseIncentiveComponent from '../components/BaseIncentiveComponent';
import {
  BucketEnum,
  BucketEnumForNPA,
  BucketEnumForNonNPA,
} from '../../../types/targets';

interface IProps {
  scrollTop: () => void;
}

const BucketComponent = ({ scrollTop }: IProps) => {
  const { selectedPayoutPolicyType, selectedTab, policyDetailsState } =
    useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();

  const onSelectPayoutPolicyType = (value: string) => {
    dispatch(setSelectedPayoutPolicyType(value));
  };

  const getIncentivesComponents = () => {
    if (selectedPayoutPolicyType === PayoutPolicyType.BUMPER) {
      return <BumperIncentiveComponent />;
    } else if (selectedPayoutPolicyType === PayoutPolicyType.PANALTY) {
      return <PenaltyIncentiveComponent />;
    } else {
      return <BaseIncentiveComponent scrollTop={scrollTop} />;
    }
  };
  const getIncentivesTabs = (selected: number) => {
    const selectedBucketTab = getKeyFromTab(
      policyDetailsState?.classType,
      selected
    );

    if (selectedBucketTab === BucketEnumForNonNPA.CHARGES_COLLECTION) {
      return <></>;
    } else {
      return Object.keys(PayoutPolicyType).map((value: PayoutPolicyType) => {
        return (
          <Box
            key={value}
            sx={{
              p: 2,
              borderBottom:
                selectedPayoutPolicyType === PayoutPolicyType[value]
                  ? `1px solid #1047DC`
                  : BORDER_COLLECTION,
              cursor: 'pointer',
            }}
            onClick={() => onSelectPayoutPolicyType(PayoutPolicyType[value])}
          >
            <VegaText
              text={PayoutPolicyType[value]}
              fontWeight={500}
              fontSize={12}
              color={
                selectedPayoutPolicyType === PayoutPolicyType[value]
                  ? '#1047DC'
                  : '#676B76'
              }
            />
          </Box>
        );
      });
    }
  };

  return (
    <VegaPageContent>
      <Stack
        sx={{
          borderRadius: '8px',
          border: BORDER_COLLECTION,
          bgcolor: 'white',
        }}
        direction={'row'}
      >
        <Stack
          sx={{
            maxWidth: '190px',
            borderRight: BORDER_COLLECTION,
          }}
        >
          {getIncentivesTabs(selectedTab)}
        </Stack>
        <Stack sx={{ width: '100%' }}>{getIncentivesComponents()}</Stack>
      </Stack>
    </VegaPageContent>
  );
};

export default BucketComponent;

const getKeyFromTab = (classType: string, tab: number) => {
  if (classType === BucketEnum.NPA) {
    const value = Object.keys(BucketEnumForNPA)[tab];
    return value;
  } else {
    const value = Object.keys(BucketEnumForNonNPA)[tab];
    return value;
  }
};
