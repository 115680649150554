import { GridColumns } from '@mui/x-data-grid';
import React from 'react';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import { AgentRegionRankDetails } from '../../../../types/incentiveScorecardType';

const RankInRegionColumn = () => {
  const COL_DEF: GridColumns<AgentRegionRankDetails> = [
    {
      field: 'regionName',
      headerName: 'Region Name',
      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        return <VegaText text={toLowerCase(props.row?.regionName ?? '-')} />;
      },
    },
    {
      field: 'regionRank',
      headerName: 'Region Rank',
      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        return <VegaText text={props.row?.regionRank.toString() ?? '-'} />;
      },
    },
    {
      field: 'totalEligibleAgents',
      headerName: 'Total Eligible Agents',
      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        return (
          <VegaText text={props.row?.totalEligibleAgents.toString() ?? '-'} />
        );
      },
    },
  ];
  return COL_DEF;
};

export default RankInRegionColumn;
