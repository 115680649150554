import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import GraphWrapper from '../../components/GraphWrapper';
import { MonthOnMonthPenalCollectionResultDto } from '../../../../types/analytics';
import { AnalyticsQueryTypeEnum } from '../../../../features/analyticsDashboardSlice';
import { AnaltyticsService } from '../../../../Apis/AnaltyticsService';
import { AnalyticsQueryRequest } from '../../../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import LoadingPage from '../../../LoadingPage/LoadingPage';
import VegaText from '../../../../components/common/VegaText';

const PenalCollectionGraph = () => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const currentMonth = new Date().getMonth() + 1;
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    MonthOnMonthPenalCollectionResultDto[]
  >([]);

  const maxNumber = Math.max(
    ...analyticsQueryResponse.map(item => item.amount)
  );
  const minNumber = (): number => {
    const amount = [...analyticsQueryResponse.map(item => item.amount)];
    const nonZeroValues = amount.filter(value => value !== 0);

    if (nonZeroValues.length === 0) {
      return 0;
    }

    const minValue = Math.min(...nonZeroValues);
    return minValue;
  };

  const modifyAnalyticsQuery = analyticsQueryResponse.map(item => ({
    categories: ` ${getMonthName(item.month)}- ${item.year} `,
    series: {
      resolution: item?.amount,
    },
  }));

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: modifyAnalyticsQuery?.map(value => value.categories),
    },
  };

  const series = [
    {
      name: '',
      data: analyticsQueryResponse.map(item => ({
        x: getMonthName(item.month),
        y: parseFloat(item?.amount?.toFixed(2)),
        fillColor:
          item.month === currentMonth
            ? '#86B6F6'
            : item.amount === maxNumber
            ? '#65B741'
            : item.amount === minNumber()
            ? '#C70039'
            : '#dedede',
      })),
    },
  ];
  const getTeleCallerQueries = async () => {
    setLoading(true);
    try {
      const request: Partial<AnalyticsQueryRequest> = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.MONTH_ON_MONTH_PENAL_COLLECTION_PERFORMANCE,
      };

      const response = await AnaltyticsService.getAnalyticsQueries(request);

      if (response) {
        setAnalyticsQueryResponse(response);
      } else {
        // setAnalyticsQueryResponse(defaultBucketQueries);
      }
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      // setAnalyticsQueryResponse(defaultBucketQueries);
      setLoading(false);
    }
  };

  useEffect(() => {
    // const dummyData = generateDummyData();
    getTeleCallerQueries();
    // setAnalyticsQueryResponse(dummyData);
  }, []);

  return (
    <>
      <GraphWrapper title="Penal Collections" sx={{ paddingTop: '8px' }}>
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '23.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -10,
                  height: 50,
                  width: '85%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1', textAlign: 'center' }}>
                          {getPercentageValue(val)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={series}
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default PenalCollectionGraph;

export const getMonthName = (month: number): string => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[month - 1] || '';
};

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
