import { Skeleton, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import VegaDialogItem from '../../../components/common/VegaDialogItem';
import VegaPageContent from '../../../components/common/VegaPageContent';
import { VegaPill } from '../../../components/common/VegaPill';
import VegaText from '../../../components/common/VegaText';
import { UserActions, getUAM } from '../../../features/UAMSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { RoleDto } from '../../../types/roleType';
import { COLOR } from '../../../utils/ColorUtility';

const ViewUserDetails = () => {
  const { userDetails, roles, loadingOfRoles } = useAppSelector(getUAM);
  const dispatch = useAppDispatch();

  useEffect(() => {
    userDetails && dispatch(UserActions.fetchRoleForUsers(userDetails?.id));
  }, [userDetails]);
  return (
    <>
      <VegaPageContent>
        <Stack gap={'0.5rem'}>
          <VegaDialogItem
            heading={'Name'}
            value={getValue(userDetails?.firstName)}
          />
          <VegaDialogItem
            heading={'User ID'}
            value={getValue(userDetails?.employeeCode)}
          />
          <VegaDialogItem
            heading={'Email ID'}
            value={getValue(userDetails?.email)}
          />
          <VegaDialogItem
            heading={'designation'}
            value={getValue(userDetails?.designation)}
          />
          <VegaDialogItem
            heading={'Manager'}
            value={getValue(userDetails?.rm1Name)}
          />
          <VegaDialogItem
            heading={'branch Name'}
            value={getValue(userDetails?.branchName)}
          />
          <VegaDialogItem
            heading={'zone'}
            value={getValue(userDetails?.zone)}
          />
          <VegaDialogItem
            heading={'city'}
            value={getValue(userDetails?.city)}
          />
          <VegaDialogItem
            heading={'state'}
            value={getValue(userDetails?.region)}
          />
          <VegaDialogItem
            heading={'mobile No'}
            value={getValue(userDetails?.mobile)}
          />
          <VegaDialogItem
            heading={'Assigned Roles'}
            renderValue={() => {
              return loadingOfRoles ? (
                <Skeleton />
              ) : (
                <Stack direction={'row'} gap={1}>
                  {roles.length ? (
                    roles.map((role: RoleDto) => (
                      <VegaPill
                        key={role.id}
                        text={role.roleDto?.name}
                        backgroundColor={
                          getColorForStatus(role.roleDto?.name)?.LIGHT
                        }
                        textColor={getColorForStatus(role.roleDto?.name)?.DARK}
                      />
                    ))
                  ) : (
                    <VegaText text={'No Assign roles for this user'} />
                  )}
                </Stack>
              );
            }}
          />
          <VegaDialogItem
            heading={'Assigned Regions'}
            renderValue={() => {
              return loadingOfRoles ? (
                <Skeleton />
              ) : (
                <Stack direction={'row'} gap={1}>
                  {roles.length ? (
                    roles.map((role: RoleDto) => (
                      <VegaPill
                        key={role.id}
                        text={_.startCase(_.toLower(role?.region))}
                        backgroundColor={getColorForStatus(role?.region)?.LIGHT}
                        textColor={getColorForStatus(role?.region)?.DARK}
                        noWrap
                      />
                    ))
                  ) : (
                    <VegaText text={'No Assign Region for this user'} />
                  )}
                </Stack>
              );
            }}
          />
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default ViewUserDetails;

const getValue = (value: string | null) => {
  if (!value) return '--';
  return value;
};

const getColorForStatus = (status?: string) => {
  if (!status) return;

  return COLOR.RED;
};
