import { Collapse, Stack } from '@mui/material';
import LmTextField from '../../../../components/common/LmTextField';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  getConfigurationState,
  initialMinMaxVisitsConfig,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  ConfigValueProps,
  ConfigurationDto,
} from '../../../../types/configurationType';
import BranchSelect from '../Selector/BranchSelect';
import BucketSelect from '../Selector/BucketSelect';
import RegionSelect from '../Selector/RegionSelect';
import RoleSelect from '../Selector/RoleSelect';

export enum MinMaxFilterType {
  BUCKET = 'BUCKET',
  REGION = 'REGION',
  BRANCH = 'BRANCH',
  POS = 'POS',
  ROLE = 'ROLE',
}

const MinMaxVisitsForm = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const handleMinMaxChange = (
    key: keyof ConfigValueProps,
    value: number | string
  ) => {
    const updateConfigValueObj = { ...configurations.config, [key]: value };
    handleConfigChange('config', updateConfigValueObj);
  };
  return (
    <Stack gap={2}>
      <VegaFormInputField label={'select filter'}>
        <VegaSelect
          value={configurations.type}
          options={getFilterType()}
          onChange={select => {
            const updateConfig = {
              ...initialMinMaxVisitsConfig,
              name: configurations.name,
              type: select.target.value,
            } as ConfigurationDto;
            dispatch(setConfigurations(updateConfig));
          }}
        />
      </VegaFormInputField>

      <Collapse
        sx={isDisplay(configurations.type, MinMaxFilterType.BUCKET)}
        in={configurations.type === MinMaxFilterType.BUCKET}
      >
        <BucketSelect />
      </Collapse>
      {configurations.type === MinMaxFilterType.REGION && (
        <Collapse
          sx={isDisplay(configurations.type, MinMaxFilterType.REGION)}
          in={configurations.type === MinMaxFilterType.REGION}
        >
          <RegionSelect />
        </Collapse>
      )}
      {configurations.type === MinMaxFilterType.BRANCH && (
        <Collapse
          sx={isDisplay(configurations.type, MinMaxFilterType.BRANCH)}
          in={configurations.type === MinMaxFilterType.BRANCH}
        >
          <BranchSelect />
        </Collapse>
      )}
      {configurations.type === MinMaxFilterType.ROLE && (
        <Collapse
          sx={isDisplay(configurations.type, MinMaxFilterType.ROLE)}
          in={configurations.type === MinMaxFilterType.ROLE}
        >
          <RoleSelect />
        </Collapse>
      )}
      <Collapse
        sx={isDisplay(configurations.type, MinMaxFilterType.POS)}
        in={configurations.type === MinMaxFilterType.POS}
      >
        <Stack direction={'row'} gap={1}>
          <Stack sx={{ width: '50%' }}>
            <VegaFormInputField label={'min pos'}>
              <LmTextField
                type="number"
                value={configurations.config?.posMinValue ?? 0}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handleMinMaxChange('posMinValue', value);
                }}
              />
            </VegaFormInputField>
          </Stack>
          <Stack sx={{ width: '50%' }}>
            <VegaFormInputField label={'max pos'}>
              <LmTextField
                type="number"
                value={configurations.config?.posMaxValue ?? 0}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handleMinMaxChange('posMaxValue', value);
                }}
              />
            </VegaFormInputField>
          </Stack>
        </Stack>
      </Collapse>

      <VegaFormInputField label={'min no of visits'}>
        <LmTextField
          type="number"
          value={configurations.config?.minVisits ?? 0}
          onChange={e => {
            const value = e.target.value && parseInt(e.target.value);
            handleMinMaxChange('minVisits', value);
          }}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'max no of visits'}>
        <LmTextField
          type="number"
          value={configurations.config?.maxVisits ?? 0}
          onChange={e => {
            const value = e.target.value && parseInt(e.target.value);
            handleMinMaxChange('maxVisits', value);
          }}
        />
      </VegaFormInputField>
    </Stack>
  );
};

export default MinMaxVisitsForm;

const getFilterType = () => {
  const options = Object.values(MinMaxFilterType).map(
    (value: string) =>
      ({
        label: toLowerCase(value),
        value,
      } as VegaSelectOption)
  );
  return options;
};

const isDisplay = (selectedType: string, type: MinMaxFilterType) => {
  if (selectedType !== type) {
    return { display: 'none' };
  }
};
