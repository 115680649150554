/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountFilterForm, {
  AccountsFilterFormDataType,
  FilterAttributes,
  FilterOP,
  POS,
} from '../../../components/Accounts/AccountFilterForm';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaColumnFilterSection, {
  LoanAccountColumns,
} from '../../../components/common/VegaColumnFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getAccountList,
  getAccountState,
} from '../../../features/accountsSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { LoanFilterProps, LoanListRequest } from '../../../types/request/loan';
import { LoanAccountColumn } from '../../LoanAccount/column/LoanAccountColumn';
import { LmsVariable, LoanData } from '../../../types/loan';
import { StringUtility } from '../../../utils/StringUtility';
import { UiModule } from '../../../router/routes';
import { setColumnFilters } from '../../../features/filterColumnSlice';

export enum SearchBy {
  LAN = 'LAN',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
}
const ListOfAccountsAgent = ({ module }: { module: string }) => {
  const { user, userForId } = useClientAuth();
  const dispatch = useAppDispatch();
  const { accounts, loading, totalItems } = useAppSelector(getAccountState);
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchBy, setSearchBy] = useState<string>(SearchBy.LAN);
  const [search, setSearch] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);

  const [filterForm, setFilterForm] = useState<
    Partial<AccountsFilterFormDataType>
  >({});

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const getFilterArray = (
    attribute: FilterAttributes,
    op: FilterOP,
    value: string | number,
    filter: LoanFilterProps[]
  ) => {
    let newArray = [];
    const newObj = {
      attribute,
      op,
      value,
    };
    newArray = [...filter, newObj];
    return newArray;
  };
  const getFilterArrayForRegionBranch = (
    attribute: FilterAttributes,
    op: FilterOP,
    values: string[],
    filter: LoanFilterProps[]
  ) => {
    let newArray = [];
    const newObj = {
      attribute,
      op,
      values,
    };
    newArray = [...filter, newObj];
    return newArray;
  };

  const getLoanList = async () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        includeLoanOutstandingDetails: true,
        includeMetaDataLoanAllocation: true,
        page,
        size: pageSize,
        agentId: user.id,
      } as LoanListRequest;
      if (search.length) {
        if (searchBy === SearchBy.LAN) {
          body.searchPartialLoanId = search.toUpperCase();
        } else {
          body.customerName = search;
        }
      }
      if (Object.keys(filterForm).length) {
        if (filterForm.pos) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          if (filterForm.pos === POS.Greater_Than) {
            body.filters = getFilterArray(
              FilterAttributes.POS,
              FilterOP.GTE,
              filterForm.posValue,
              preFilterValue
            );
          } else if (filterForm.pos === POS.Less_Than) {
            body.filters = getFilterArray(
              FilterAttributes.POS,
              FilterOP.LTE,
              filterForm.posValue,
              preFilterValue
            );
          } else if (filterForm.pos === POS.Range) {
            const greterThanArr = getFilterArray(
              FilterAttributes.POS,
              FilterOP.GTE,
              filterForm.posMinValue,
              preFilterValue
            );
            const lessThanArr = getFilterArray(
              FilterAttributes.POS,
              FilterOP.LTE,
              filterForm.posMaxValue,
              preFilterValue
            );
            body.filters = [...greterThanArr, ...lessThanArr];
          } else {
            body.filters = getFilterArray(
              FilterAttributes.POS,
              FilterOP.EQ,
              filterForm.posValue,
              preFilterValue
            );
          }
        }

        if (filterForm.bucket) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.BUCKET,
            FilterOP.IN,
            filterForm.bucket,
            preFilterValue
          );
        }
        if (filterForm.cycle) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.CYCLE,
            FilterOP.IN,
            filterForm.cycle,
            preFilterValue
          );
        }
        if (filterForm.loanStatus) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.LOAN_STATUS,
            FilterOP.IN,
            filterForm.loanStatus,
            preFilterValue
          );
        }
        if (filterForm.branch) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.BRANCH,
            FilterOP.IN,
            filterForm.branch,
            preFilterValue
          );
        }
        if (filterForm.region) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.REGION,
            FilterOP.IN,
            filterForm.region,
            preFilterValue
          );
        }

        if (filterForm.segment) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArray(
            FilterAttributes.SEGMENT,
            FilterOP.EQ,
            filterForm.segment,
            preFilterValue
          );
        }
      }
      dispatch(getAccountList(body));
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      if (search.length) {
        const getList = setTimeout(() => {
          getLoanList();
        }, 300);
        return () => clearTimeout(getList);
      } else {
        getLoanList();
      }
    }
  }, [page, pageSize, user, search]);
  const key = `_${module}_ACCOUNTS`;
  useEffect(() => {
    if (localStorage.getItem(key)) {
      setSelectedOptions(JSON.parse(localStorage.getItem(key)));
    }
  }, []);
  const updateFilters = (options: string[]) => {
    dispatch(
      setColumnFilters({
        key,
        data: [...options],
      })
    );
    setSelectedOptions(options);
  };

  return (
    <>
      <VegaPageHeader
        title="Account"
        renderRightView={() => (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaColumnFilterSection
              columns={LoanAccountColumns}
              selectedOptions={selectedOptions}
              setSelectedOptions={updateFilters}
            />
            <LmFilterSection
              search={search}
              serachByComponent={
                <Box
                  sx={{
                    width: 150,
                  }}
                >
                  <VegaSelect
                    options={getSearchByOptions()}
                    value={searchBy}
                    onChange={selected => {
                      const value = selected.target.value as string;
                      setSearchBy(value);
                      setSearch('');
                      setPage(0);
                    }}
                  />
                </Box>
              }
              setSearch={setSearch}
              onFilter={() => {
                openFilterDrawer();
              }}
            />
          </Stack>
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={accounts}
          loading={loading}
          columns={LoanAccountColumn(selectedOptions, true)}
          idColumn="loanId"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
        />
      </VegaPageContent>
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={() => {
          closeFilterDrawer();
          setFilterForm({});
        }}
      >
        <AccountFilterForm
          onClose={closeFilterDrawer}
          setFilterForm={setFilterForm}
          filterForm={filterForm}
          search={search}
          searchBy={searchBy}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfAccountsAgent;

const getSearchByOptions = () => {
  const options = Object.keys(SearchBy).map(
    search =>
      ({
        label: toLowerCase(search),
        value: search,
      } as VegaSelectOption)
  );
  return options;
};
