import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { PerformanceServices } from '../../Apis/PerformanceServices';
import { MORE_DETAILS_TABLE_DATA } from '../../constants/athenaQueryId';
import { getPerformanceDashboard } from '../../features/performanceDashboardSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppSelector } from '../../store';
import { LoanInformation } from '../../types/performanceType';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaText from '../common/VegaText';

type Props = {
  clearFilter: boolean;
  applyFilter: boolean;
  setApplyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setClearFilter: React.Dispatch<React.SetStateAction<boolean>>;
};
const ListOfPerformance = ({
  applyFilter,
  clearFilter,
  setClearFilter,
  setApplyFilter,
}: Props) => {
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const [loading, setLoading] = useState<boolean>(false);
  const [loanData, setLoanData] = useState<LoanInformation[]>([]);
  const { setSnackbar } = useSnackbar();

  const getAthenaQuery = async (queryId: string) => {
    try {
      const response = await PerformanceServices.getAthenaQueriesForLoanQuery({
        queryId,
        params: filterData,
      });

      return response;
    } catch (error) {
      setLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getTotalReceiptIssuedCount = async () => {
    setLoading(true);
    const loansQuery = await getAthenaQuery(MORE_DETAILS_TABLE_DATA);

    if (loansQuery) {
      setLoanData(loansQuery);
    }
    setLoading(false);
    // settotalReceiptsIssueCountState(totalCount);
  };

  useEffect(() => {
    getTotalReceiptIssuedCount();
  }, []);

  useEffect(() => {
    if (applyFilter) {
      getTotalReceiptIssuedCount();
      setApplyFilter(false);
    }
  }, [applyFilter]);

  useEffect(() => {
    if (clearFilter) {
      getTotalReceiptIssuedCount();
      setClearFilter(false);
    }
  }, [clearFilter]);
  return (
    <div>
      <VegaDataGrid
        idColumn="loan_id"
        columns={getColumnDefinition()}
        data={loanData}
        loading={loading}
        paginationMode="client"
      />
    </div>
  );
};

export default ListOfPerformance;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'loan_id',
      headerName: 'LAN',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.loan_id ? loanData.loan_id : '--';
        return <VegaText text={displayText} />;
      },
    },

    {
      field: 'disposition_code',
      headerName: 'Code',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.disposition_code
          ? loanData.disposition_code
          : '--';
        return <VegaText text={displayText} />;
      },
    },

    {
      field: 'customer_name',
      headerName: 'Customer Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.customer_name
          ? loanData.customer_name
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'customer_address',
      headerName: 'Customer Address	',
      flex: 0.7,
      minWidth: 350,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.customer_address
          ? loanData.customer_address
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'mobile_no',
      headerName: 'Contact Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.mobile_no ? loanData.mobile_no : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'agent_name',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.agent_name ? loanData.agent_name : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'manager_name',
      headerName: 'Manager Name	',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.manager_name
          ? loanData.manager_name
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'ptp_amount',
      headerName: 'PTP Amount	',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanQuery = props.row as LoanInformation;
        const ptpAmount = loanQuery.ptp_amount
          ? StringUtility.formatToINR(loanQuery.ptp_amount)
          : 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={ptpAmount}
          />
        );
      },
    },
    // {
    //   field: 'agent_id	',
    //   headerName: 'Agent Id',
    //   flex: 0.7,
    //   minWidth: 150,
    // },
    {
      field: 'overdue_amount',
      headerName: 'Overdue Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanQuery = props.row as LoanInformation;
        const overDue = loanQuery.overdue_amount
          ? StringUtility.formatToINR(loanQuery.overdue_amount)
          : 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={overDue}
          />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.bucket ? loanData.bucket : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'cycle_date',
      headerName: 'Cycle Date	',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.cycle_date ? loanData.cycle_date : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'branch_name',
      headerName: 'Branch Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.branch_name ? loanData.branch_name : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.segment ? loanData.segment : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'product',
      headerName: 'product',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.product ? loanData.product : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'model_prediction',
      headerName: 'model Prediction	',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanQuery = props.row as LoanInformation;
        const prediction = loanQuery.model_prediction
          ? loanQuery.model_prediction
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={prediction}
          />
        );
      },
    },
    {
      field: 'receipt_amount',
      headerName: 'Receipt Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.receipt_amount
          ? loanData.receipt_amount
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'receipt_status',
      headerName: 'Receipt Status',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.receipt_status
          ? loanData.receipt_status
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'payment_mode',
      headerName: 'Payment Mode',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.payment_mode
          ? loanData.payment_mode
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'outstanding_status',
      headerName: 'Outstanding Status',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.outstanding_status
          ? loanData.outstanding_status
          : '--';
        return <VegaText text={displayText} />;
      },
    },
    {
      field: 'dpd',
      headerName: 'dpd',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loanData = props.row as LoanInformation;
        const displayText = loanData?.dpd ? loanData.dpd : '--';
        return <VegaText text={displayText} />;
      },
    },
  ];
  return COL_DEF;
};
