/* eslint-disable no-useless-catch */
import axios from 'axios';
import { ApiService } from '../types/api';
import {
  ConveyanceRate,
  ExpenseClaim,
  ExpenseClaimAudit,
  ExpenseReport,
  ExpenseVisitData,
} from '../types/claim';
import {
  CreateClaimRequest,
  CreateConveyanceReportRequest,
  DeleteClaimDocumentsRequest,
  GetClaimAuditsRequest,
  GetClaimRequest,
  GetClaimsRequest,
  GetConveyanceRateRequest,
  GetExpenseReportsRequest,
  GetExpenseVisitDataRequest,
  ReapplyClaimRequest,
  SettleClaimRequest,
  SubmitExpenseReportRequest,
  UpdateClaimRequest,
  UpdateConveyanceRequest,
} from '../types/request/claim';
import { objectToFormData, PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/expense',
  LIST_CLAIMS: '/claim/list',
  CREATE_CLAIM: '/claim',
  GET_CLAIM: '/claim/{id}',
  DELETE_EXPENSE_BY_ID: '/claim/{id}',
  DELETE_EXPENSE_DOCUMENTS: '/claim/delete/supportingDocument',
  UPDATE_CLAIM: '/claim',
  SETLLE_CLAIM: '/claim/settle',
  REAPPLY_CLAIM: '/claim/reapply',

  LIST_REPORTS: '/report/list',
  SUBMIT_REPORT: '/report/submit',
  GET_REPORT_BY_ID: '/report/{id}',
  GET_EXPENSE_VISIT_DATA: '/claim/visit-data',
  GET_CLAIM_AUDIT: '/claims/audit',
  CREATE_CONVEYANCE_RATE: '/conveyance',
  UPDATE_CONVEYANCE_RATE: '/conveyance',
  GET_CONVEYANCE_RATES: '/conveyance/list',
  GET_CLAIM_DOCUMENT_PUBLIC_URL: '/claim/{id}/supportingDocument',
};

const BASE_URL = Endpoint.BASE;
export class ExpenseService extends ApiService {
  static async createClaim(
    request: Partial<CreateClaimRequest>
  ): Promise<ExpenseClaim> {
    try {
      const endpoint = BASE_URL + Endpoint.CREATE_CLAIM;
      const formData = objectToFormData<Partial<CreateClaimRequest>>(request);
      const response = await axios.post(endpoint, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getClaims(
    request: Partial<GetClaimsRequest>
  ): Promise<PaginatedResponse<ExpenseClaim>> {
    try {
      const endpoint = BASE_URL + Endpoint.LIST_CLAIMS;
      const response = await axios.get(endpoint, { params: request });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getGetExpenseReports(
    request: Partial<GetExpenseReportsRequest>
  ): Promise<PaginatedResponse<ExpenseReport>> {
    try {
      const endpoint = BASE_URL + Endpoint.LIST_REPORTS;
      const response = await axios.get(endpoint, { params: request });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getClaimAudits(
    request: Partial<GetClaimAuditsRequest>
  ): Promise<PaginatedResponse<ExpenseClaimAudit>> {
    try {
      const endpoint = BASE_URL + Endpoint.GET_CLAIM_AUDIT;
      const response = await axios.get(endpoint, { params: request });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async submitExpenseReport(
    request: Partial<SubmitExpenseReportRequest>
  ): Promise<ExpenseReport> {
    try {
      const endpoint = BASE_URL + Endpoint.SUBMIT_REPORT;
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async settleClaim(
    request: Partial<SettleClaimRequest>
  ): Promise<ExpenseClaim> {
    try {
      const endpoint = BASE_URL + Endpoint.SETLLE_CLAIM;
      const response = await axios.put(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateClaim(
    request: Partial<UpdateClaimRequest>
  ): Promise<ExpenseClaim> {
    try {
      const endpoint = BASE_URL + Endpoint.UPDATE_CLAIM;
      const formData = objectToFormData<Partial<CreateClaimRequest>>(request);
      const response = await axios.put(endpoint, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async reapplyClaim(
    request: Partial<ReapplyClaimRequest>
  ): Promise<ExpenseClaim> {
    try {
      const endpoint = BASE_URL + Endpoint.REAPPLY_CLAIM;
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getClaim(
    request: Partial<GetClaimRequest>
  ): Promise<ExpenseClaim> {
    try {
      if (!request.id) {
        throw new Error('Id is required');
      }
      const endpoint =
        BASE_URL + Endpoint.GET_CLAIM.replace('{id}', request.id);
      delete request.id;
      const response = await axios.get(endpoint, { params: request });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getReport(id: string): Promise<ExpenseReport> {
    try {
      const endpoint =
        BASE_URL + Endpoint.GET_REPORT_BY_ID.replaceAll('{id}', id);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getExpenseVisitData(
    request: Partial<GetExpenseVisitDataRequest>
  ): Promise<ExpenseVisitData> {
    try {
      const endpoint = BASE_URL + Endpoint.GET_EXPENSE_VISIT_DATA;
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteExpenseClaim(id: string): Promise<ExpenseClaim> {
    try {
      const endpoint =
        BASE_URL + Endpoint.DELETE_EXPENSE_BY_ID.replace('{id}', id);
      const response = await axios.delete(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteExpenseDocuments(
    request: Partial<DeleteClaimDocumentsRequest>
  ) {
    try {
      const endpoint = BASE_URL + Endpoint.DELETE_EXPENSE_DOCUMENTS;
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createConveyanceRate(
    request: Partial<CreateConveyanceReportRequest>
  ): Promise<ConveyanceRate> {
    try {
      const endpoint = BASE_URL + Endpoint.CREATE_CONVEYANCE_RATE;
      const response = await axios.post(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateConveyanceRate(
    request: Partial<UpdateConveyanceRequest>
  ): Promise<ConveyanceRate> {
    try {
      const endpoint = BASE_URL + Endpoint.UPDATE_CONVEYANCE_RATE;
      const response = await axios.put(endpoint, request);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getConveyanceRates(
    request: Partial<GetConveyanceRateRequest>
  ): Promise<PaginatedResponse<ConveyanceRate>> {
    try {
      const endpoint = BASE_URL + Endpoint.GET_CONVEYANCE_RATES;
      const response = await axios.get(endpoint, { params: request });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getClaimDocumentsPublicUrl(id: string): Promise<ExpenseClaim> {
    try {
      const endpoint =
        BASE_URL + Endpoint.GET_CLAIM_DOCUMENT_PUBLIC_URL.replace('{id}', id);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
