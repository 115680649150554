import { Grid } from '@mui/material';
import AllocationFileUploadCard, {
  ViewState,
} from '../../../components/Allocation/AllocationFileUploadCard';
import { FileUploadIcon } from '../../../components/Icons/Icons';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  SimulateViewState,
  getIncentiveSimulatorState,
  setSimulateFormData,
  setSimulateViewState,
} from '../../../features/IncentivesGamificationsSlice/incentiveSimulatorSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

interface IProps {
  fileInputRef: any;
}

const UploadFileContainer = ({ fileInputRef }: IProps) => {
  const { simulateViewState, simulateFormData } = useAppSelector(
    getIncentiveSimulatorState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onFileSelect = (file: VegaFileSelectOption) => {
    dispatch(setSimulateFormData({ ...simulateFormData, file: file }));
    dispatch(setSimulateViewState(SimulateViewState.SelectedFile));
  };

  const uploadFile = () => {
    try {
      dispatch(setSimulateViewState(SimulateViewState.VerifyingFile));
      setTimeout(() => {
        dispatch(setSimulateViewState(SimulateViewState.Verified));
      }, 3000);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  return (
    <>
      <Grid
        item
        xs={3}
        style={{
          display:
            simulateViewState == SimulateViewState.Initial ||
            simulateViewState == SimulateViewState.Verified ||
            simulateViewState == SimulateViewState.Simulated
              ? ''
              : 'none',
        }}
      >
        <VegaFormInputField label="Upload File">
          <VegaFileSelect
            placeholder={'Upload File'}
            endIcon={<></>}
            startIcon={<FileUploadIcon />}
            acceptedExtension={[AcceptedExtension.XLSX]}
            onFileSelect={file => {
              onFileSelect(file);
            }}
            value={simulateFormData?.file}
            onFileInputRef={ref => {
              fileInputRef.current = ref;
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          flex: 1,
          marginTop: '0.8rem',
          display:
            simulateViewState == SimulateViewState.SelectedFile ||
            simulateViewState == SimulateViewState.VerifyingFile
              ? ''
              : 'none',
        }}
      >
        <AllocationFileUploadCard
          fileName={simulateFormData?.file?.label}
          state={
            simulateViewState == SimulateViewState.VerifyingFile
              ? ViewState.Uploading
              : ViewState.Inital
          }
          onUploadClick={function (): void {
            uploadFile();
          }}
          onCancelClick={function (): void {
            dispatch(
              setSimulateFormData({
                ...simulateFormData,
                selectedFile: undefined,
              })
            );
            fileInputRef.current.value = '';
          }}
        />
      </Grid>
    </>
  );
};

export default UploadFileContainer;
