import { GridColumns } from '@mui/x-data-grid';
import VegaButton from '../../../components/common/VegaButton';
import VegaText from '../../../components/common/VegaText';
import { BulkAllocationMeta } from '../../../types/allocations';
import { DateUtility } from '../../../utils/DateUtlility';
import VegaIconButton from '../../../components/common/VegaIconButton';

interface IProps {
  download: (allocation: BulkAllocationMeta) => void;
}

export const CustomTargetColumn = ({ download }: IProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 0.7,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const displayText = allocation.fileName;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                text={displayText}
                fontSize={'0.875rem'}
                fontWeight={500}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'requestId',
      headerName: 'Request Id',
      flex: 0.7,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const displayText = allocation.id;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'targetStatus',
      headerName: 'Date',
      flex: 0.5,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const displayText = DateUtility.formatStringToDDMMYYYY(
          allocation.createdAt
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalEntries',
      headerName: 'Total Entries',
      flex: 0.7,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const displayText = allocation.totalEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'success',
      headerName: 'Success',
      flex: 0.7,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const displayText = allocation.successEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'failure',
      headerName: 'Failure',
      flex: 0.7,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const displayText = allocation.failedEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Error Details',
      flex: 0.7,
      renderCell: props => {
        const allocation = props.row as BulkAllocationMeta;
        const hasErrorFile = !!allocation?.errorFile;
        if (hasErrorFile) {
          return (
            <VegaButton
              text={'Download'}
              variant="text"
              onClick={() => download(allocation)}
            />
          );
        }
        return <VegaButton disabled text={'No Errors'} variant="text" />;
      },
    },
  ];
  return COL_DEF;
};
