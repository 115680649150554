import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchHolidayTypeById } from '../../Apis/holiday';
import { useHolidayType } from '../../providers/HolidayTypeProvider';
import { AccessType } from '../../types/holiday';
import { COLOR } from '../../utils/ColorUtility';
import VegaKeyValueDisplay from '../common/VegaKeyValueDisplay';
import VegaText from '../common/VegaText';
import { DateUtility } from '../../utils/DateUtlility';

const ViewHolidayTypeDetails = () => {
  const { selectedHolidayType, setHolidayTypeState, holidayTypeState } =
    useHolidayType();
  const [loading, setLoading] = useState<boolean>(false);

  const getHolidayDetailsById = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchHolidayTypeById(id);
      setHolidayTypeState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHolidayType.id) {
      getHolidayDetailsById(selectedHolidayType.id);
    }
  }, [selectedHolidayType]);

  return (
    <div>
      <VegaText
        text="Details"
        fontWeight={600}
        fontSize={'0.875rem'}
        color={'#222222'}
        mb={2.5}
      />
      <Stack gap={1.5}>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={12} sm={6}>
            <VegaKeyValueDisplay
              heading="Name"
              value={holidayTypeState?.name ?? ''}
              loading={loading}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <VegaKeyValueDisplay
              heading="Description"
              value={holidayTypeState?.description ?? ''}
              loading={loading}
            />
          </Grid> */}
        </Grid>
      </Stack>
      <VegaText
        text="Timeline"
        fontWeight={600}
        fontSize={'0.875rem'}
        color={'#222222'}
        mb={2.5}
        mt={4}
      />
      <Grid container rowGap={'0.75rem'}>
        <Grid item xs={12} sm={6}>
          <VegaKeyValueDisplay
            heading="Date Created"
            value={DateUtility.formatStringToDDMMYYYY(
              holidayTypeState?.createdAt
            )}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <VegaKeyValueDisplay
            heading="Date Modified"
            value={DateUtility.formatStringToDDMMYYYY(
              holidayTypeState?.updatedAt
            )}
            loading={loading}
          />
        </Grid>
      </Grid>

      <VegaText
        text="Access Level"
        fontWeight={600}
        fontSize={'0.875rem'}
        color={'#222222'}
        mb={2.5}
        mt={4}
      />
      <Stack gap={1.5}>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={12} sm={6}>
            <VegaKeyValueDisplay
              heading="Receipt Issuance"
              renderValue={() => {
                const color = getColorForStatus(
                  holidayTypeState?.receiptIssuance
                );
                return (
                  <VegaText
                    text={holidayTypeState?.receiptIssuance ?? ''}
                    fontWeight={500}
                    fontSize={'0.8125rem'}
                    color={color?.DARK}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <VegaKeyValueDisplay
              heading="Receipt Deposition"
              renderValue={() => {
                const color = getColorForStatus(
                  holidayTypeState?.receiptDeposition
                );
                return (
                  <VegaText
                    text={holidayTypeState?.receiptDeposition ?? ''}
                    fontWeight={500}
                    fontSize={'0.8125rem'}
                    color={color?.DARK}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaKeyValueDisplay
              heading="Receipt Authorization"
              renderValue={() => {
                const color = getColorForStatus(
                  holidayTypeState?.receiptAuthorization
                );
                return (
                  <VegaText
                    text={holidayTypeState?.receiptAuthorization ?? ''}
                    fontWeight={500}
                    fontSize={'0.8125rem'}
                    color={color?.DARK}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default ViewHolidayTypeDetails;

const getColorForStatus = (status?: AccessType) => {
  if (!status) return;
  switch (status) {
    case AccessType.NON_RESTRICTED:
      return COLOR.GREEN;

    case AccessType.RESTRICTED:
      return COLOR.RED;
  }
};
