import { Grid, Stack } from '@mui/material';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { useAppSelector } from '../../../store';
import { StringUtility } from '../../../utils/StringUtility';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const PendingDuesDetails = () => {
  const { loanData, loading } = useAppSelector(getTeleAgent);
  const getLmsVariableData = () => {
    if (loanData.length && loanData[0].lmsVariables) {
      const lmsVariableData = loanData[0]?.lmsVariables;
      return {
        overdueAmount: lmsVariableData?.overdueAmount
          ? StringUtility.formatToINR(lmsVariableData?.overdueAmount)
          : 'NA',
        overdueCharges: lmsVariableData?.chargeDue
          ? StringUtility.formatToINR(lmsVariableData?.chargeDue)
          : 'NA',
        bounceChangeDue: lmsVariableData?.bounceChangeDue
          ? StringUtility.formatToINR(lmsVariableData?.bounceChangeDue)
          : 'NA',
        penalChargeDue: lmsVariableData?.penalChargeDue
          ? StringUtility.formatToINR(lmsVariableData?.penalChargeDue)
          : 'NA',
        otherChargeDue: lmsVariableData?.otherChargeDue
          ? StringUtility.formatToINR(lmsVariableData?.otherChargeDue)
          : 'NA',
      };
    }
    return {
      overdueAmount: 'NA',
      overdueCharges: 'NA',
      bounceChangeDue: 'NA',
      penalChargeDue: 'NA',
      otherChargeDue: 'NA',
    };
  };
  return (
    <TeleAgentDetailsCard text="Pending Dues Details" isBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Overdue Instalments"
              value={getLmsVariableData().overdueAmount}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Interest Till Date" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Bounce Charges"
              value={getLmsVariableData().bounceChangeDue}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Legal Charges" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Penal Charges"
              value={getLmsVariableData().penalChargeDue}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Other Charges"
              value={getLmsVariableData().otherChargeDue}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Late Payment Penalty overdue"
              value="NA"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Accrual Amount" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Other Overdue Charges"
              value={getLmsVariableData().overdueCharges}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default PendingDuesDetails;
