import { useEffect, useMemo, useState } from 'react';
import AllPerformanceSummary from '../../components/Perfomance/AllPerformanceSummary';
import PerformanceFilterSection from '../../components/Perfomance/PerformanceFilterSection';
import PerformanceSummary from '../../components/Perfomance/PerformanceSummary';
import VegaPageContent from '../../components/common/VegaPageContent';
import {
  getPerformanceDashboard,
  setFilterData,
} from '../../features/performanceDashboardSlice';
import { useAppDispatch, useAppSelector } from '../../store';

const PerformanceDashboard = () => {
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const dispatch = useAppDispatch();

  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [clearFilter, setClearFilter] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setFilterData({}));
  }, []);

  const performanceSummary = useMemo(() => {
    return (
      <PerformanceSummary
        filterData={filterData}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
        clearFilter={clearFilter}
        setClearFilter={setClearFilter}
      />
    );
  }, [applyFilter, clearFilter]);

  const allPerformanceSummary = useMemo(() => {
    return (
      <AllPerformanceSummary
        filterData={filterData}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
        clearFilter={clearFilter}
        setClearFilter={setClearFilter}
      />
    );
  }, [applyFilter, clearFilter]);

  return (
    <>
      <VegaPageContent sx={{ mt: 2 }}>{performanceSummary}</VegaPageContent>
      <VegaPageContent sx={{ mt: 2 }}>{allPerformanceSummary}</VegaPageContent>
      <VegaPageContent sx={{ my: 2 }}>
        <PerformanceFilterSection
          setApplyFilter={setApplyFilter}
          setClearFilter={setClearFilter}
          clearFilter={clearFilter}
          applyFilter={applyFilter}
        />
      </VegaPageContent>
    </>
  );
};

export default PerformanceDashboard;
