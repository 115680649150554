import { Stack } from '@mui/material';
import { ConfigurationServices } from '../../../Apis/ConfigurationServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import {
  fetchConfigListSlice,
  getConfigurationState,
  initialLeaveRequestConfig,
  setConfigurations,
} from '../../../features/configurationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ConfigurationDto,
  ConfigurationType,
} from '../../../types/configurationType';
import { ConfigListRequest } from '../../../types/request/cofigurationRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LeaveRequestForm from '../components/Forms/LeaveRequestForm';
import { isEmptyOrUndefined } from '../Configurations';

interface IProps {
  onClose: () => void;
}

const LeaveRequestConfig = ({ onClose }: IProps) => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      const updateConfig = {
        config: configurations?.config,
        name: configurations?.name,
      } as ConfigurationDto;
      await ConfigurationServices.addConfig(updateConfig);
      setSnackbar('Add Config Successfully');
      const request = {
        page: 0,
        size: 10,
        names: [ConfigurationType.LEAVE_REQUEST],
      } as ConfigListRequest;
      dispatch(fetchConfigListSlice(request));
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  const onClear = () => {
    dispatch(setConfigurations(initialLeaveRequestConfig));
  };

  const disabled =
    isEmptyOrUndefined(configurations?.config?.leaveRequestDeadline) ||
    isEmptyOrUndefined(configurations?.config?.reminderTimePeriod) ||
    isEmptyOrUndefined(configurations?.config?.approvalDeadline) ||
    isEmptyOrUndefined(configurations?.config?.escalationDeadline);
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton text="Submit" onClick={onSubmit} disabled={disabled} />
          </Stack>
        );
      }}
    >
      <LeaveRequestForm />
    </VegaDrawerContent>
  );
};

export default LeaveRequestConfig;
