import { Collapse, Stack } from '@mui/material';
import { useState } from 'react';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import LmDialog from '../../../../components/common/LmDialog';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import VegaText from '../../../../components/common/VegaText';
import { GREY, RED } from '../../../../constants/style';
import { receiptState } from '../../../../features/receiptManagmentSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../../store';
import { ReceiptCancellationCases } from '../../../../types/receiptManagment';
import { CancelReceiptRequest } from '../../../../types/request/receiptManagment';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFetchList: () => void;
}

const ReceiptCancellationRejectDialog = ({
  open,
  onClose,
  onFetchList,
}: IProps) => {
  const { receiptDetailedData } = useAppSelector(receiptState);
  const { setSnackbar } = useSnackbar();

  const [remarks, setRemarks] = useState<string>('');
  const [otherRemarks, setOtherRemarks] = useState<string>('');
  const [isOtherRemarks, setIsOtherRemarks] = useState<boolean>(false);

  const onClear = () => {
    setIsOtherRemarks(false);
    setOtherRemarks('');
    setRemarks('');
    onClose();
  };

  const onReject = async () => {
    const request = {
      receiptId: receiptDetailedData.receiptId,
      agentRemark: isOtherRemarks ? otherRemarks : remarks,
    } as CancelReceiptRequest;
    await ReceiptManagmentService.cancelReceipt(request)
      .then(() => {
        setSnackbar('Reject successfully !!');
        onFetchList();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
    onClear();
  };

  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={open}
        handleClose={() => onClear()}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <VegaButton
              fullWidth
              text="Cancel Receipt"
              sx={{
                bgcolor: RED.red,
                ':hover': {
                  bgcolor: RED.red,
                },
              }}
              onClick={onReject}
              disabled={isOtherRemarks ? !otherRemarks.length : !remarks.length}
            />
            <VegaButton
              text="Cancel"
              fullWidth
              variant="outlined"
              sx={{
                color: GREY.dark,
                ':hover': {
                  color: GREY.dark,
                  bgcolor: 'transparent',
                },
              }}
              onClick={() => onClear()}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <VegaText
              text="Cancel Receipt?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <Stack gap={1}>
            <VegaFormInputField label="Receipt Cancellation Cases">
              <VegaSelect
                options={getRejectionTypeOption()}
                value={remarks}
                onChange={e => {
                  const value = e.target.value as string;
                  if (value === ReceiptCancellationCases.OTHER) {
                    setIsOtherRemarks(true);
                  } else {
                    setIsOtherRemarks(false);
                    setOtherRemarks('');
                  }
                  setRemarks(value);
                }}
              />
            </VegaFormInputField>
            {isOtherRemarks && (
              <Collapse in={isOtherRemarks}>
                <VegaFormInputField label="Please enter reason for cancellation">
                  <LmTextField
                    isTextArea
                    rows={2}
                    multiline
                    value={otherRemarks}
                    onChange={e => setOtherRemarks(e.target.value)}
                  />
                </VegaFormInputField>
              </Collapse>
            )}
          </Stack>
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ReceiptCancellationRejectDialog;

const getRejectionTypeOption = () => {
  const options = Object.values(ReceiptCancellationCases).map(
    caseType => ({ label: caseType, value: caseType } as VegaSelectOption)
  );
  return options;
};
