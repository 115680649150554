import { Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { PerformanceServices } from '../../Apis/PerformanceServices';
import {
  ACCOUNTS,
  ACCOUNTS_VISITS,
  NO_OF_RECEIPTS,
  RECEIPTS_AMOUNT,
} from '../../constants/athenaQueryId';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { Params } from '../../types/request/performanceRequest';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import {
  LocationIcon,
  RecieptIcon,
  RupeeCircleIcon,
  UserIcon,
} from '../Icons/Icons';
import PerformanceCard from './components/PerformanceCard';
import { DateIndicateEnum, DateUtility } from '../../utils/DateUtlility';

export const filterKey = [
  'MONTH_OF_ALLOCATION',
  'YEAR_OF_ALLOCATION',
  'BUCKET',
  'CITY',
  'BRANCH',
  'REGION',
  'AGENT_ID',
  'MANAGER_ID',
  'PRODUCT',
  'DISPOSITION_CODE',
  'SEGMENT',
  'MONTH_AND_YEAR_OF_ALLOCATION',
  'DUE_DATE',
  'LOAN_STATUS',
  'START_DATE',
  'END_DATE',
  'CUSTOMER_NAME',
  'BRANCH_CODE',
];

type Props = {
  filterData: Partial<Params>;
  applyFilter: boolean;
  setApplyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilter: boolean;
  setClearFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const PerformanceSummary = ({
  filterData,
  applyFilter,
  setApplyFilter,
  clearFilter,
  setClearFilter,
}: Props) => {
  const { setSnackbar } = useSnackbar();
  const [accounts, setAccounts] = useState<string>('0');
  const [accountVisits, setAccountVisits] = useState<string>('0');
  const [noOfReceipts, setNoOfReceipts] = useState<string>('0');
  const [receiptsAmount, setReceiptsAmount] = useState<string>('0');
  const [accountLoading, setAccountsLoading] = useState<boolean>(false);
  const [accountVisitsLoading, setAccountVisitsLoading] =
    useState<boolean>(false);
  const [noOfReceiptsLoading, setNoOfReceiptLoading] = useState<boolean>(false);
  const [receiptsAmountLoading, setReceiptsAmountLoading] =
    useState<boolean>(false);
  const summaries = [
    {
      count: StringUtility.formatNumberToIndianNumberFormat(accounts),
      loading: accountLoading,
      text: 'Total Accounts',
      icon: <UserIcon />,
      bgColor: '#E7EDFC',
      color: '#3966E2',
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(accountVisits),
      loading: accountVisitsLoading,
      text: 'Accounts Visits',
      icon: <LocationIcon />,
      bgColor: '#FFF2E6',
      color: '#E87001',
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(noOfReceipts),
      loading: noOfReceiptsLoading,
      text: 'No. of Receipts',
      icon: <RecieptIcon />,
      bgColor: '#F9EBF5',
      color: '#C3329C',
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        receiptsAmount,
        2,
        2
      ),
      loading: receiptsAmountLoading,
      text: 'Receipts Amount',
      icon: <RupeeCircleIcon />,
      bgColor: '#EEEAFB',
      color: '#4925C7',
    },
  ];

  // const getAccounts = async () => {
  //   try {
  //     setAccountsLoading(true);
  //     const accountsQuery = await getAthenaQuery(ACCOUNTS);
  //     if (!accountsQuery) {
  //       return;
  //     }

  //     setAccounts(accountsQuery[0]?.total_count ?? '0');
  //     setAccountsLoading(false);
  //   } catch (error) {
  //     setAccountsLoading(false);
  //   }
  // };

  // const getAccountVisits = async () => {
  //   try {
  //     setAccountVisitsLoading(true);
  //     const accountsVisitsQuery = await getAthenaQuery(ACCOUNTS_VISITS);
  //     if (!accountsVisitsQuery) {
  //       return;
  //     }

  //     setAccountVisits(accountsVisitsQuery[0]?.total_count ?? '0');
  //     setAccountVisitsLoading(false);
  //   } catch (error) {
  //     setAccountVisitsLoading(false);
  //   }
  // };

  // const getNoOfReceipts = async () => {
  //   try {
  //     setNoOfReceiptLoading(true);
  //     const noOfReceipts = await getAthenaQuery(NO_OF_RECEIPTS);
  //     if (!noOfReceipts) {
  //       return;
  //     }

  //     setNoOfReceipts(noOfReceipts[0]?.total_count ?? '0');
  //     setNoOfReceiptLoading(false);
  //   } catch (error) {
  //     setNoOfReceiptLoading(false);
  //   }
  // };

  // const getReceiptsAmounts = async () => {
  //   try {
  //     setReceiptsAmountLoading(true);
  //     const receiptsAmountQuery = await getAthenaQuery(RECEIPTS_AMOUNT);
  //     if (!receiptsAmountQuery) {
  //       return;
  //     }

  //     setReceiptsAmount(receiptsAmountQuery[0]?.total_amount ?? '0');
  //     setReceiptsAmountLoading(false);
  //   } catch (error) {
  //     setReceiptsAmountLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getAccounts();
  //   getAccountVisits();
  //   getNoOfReceipts();
  //   getReceiptsAmounts();
  // }, []);

  const filteredParams: Partial<Params> = useMemo(() => {
    const params = { ...filterData };
    const filteredParams = {};

    filterKey.forEach(key => {
      if (params && params[key]?.length) {
        filteredParams[key] = params[key];
      }
    });

    return filteredParams;
  }, [filterData]);

  const getAthenaQuery = async (queryId: string) => {
    try {
      const response = await PerformanceServices.getAthenaQueries({
        queryId,
        params: {
          ...filteredParams,
          ...(Object.keys(filteredParams).length &&
            filteredParams?.MONTH_AND_YEAR_OF_ALLOCATION && {
              MONTH_AND_YEAR_OF_ALLOCATION: DateUtility.toISOString(
                filteredParams.MONTH_AND_YEAR_OF_ALLOCATION,
                DateIndicateEnum.PLUS
              ),
            }),
          ...(Object.keys(filteredParams).length &&
            filteredParams?.START_DATE && {
              START_DATE: DateUtility.toISOString(
                filteredParams.START_DATE,
                DateIndicateEnum.PLUS
              ),
            }),
          ...(Object.keys(filteredParams).length &&
            filteredParams?.END_DATE && {
              END_DATE: DateUtility.toISOString(
                filteredParams.END_DATE,
                DateIndicateEnum.PLUS
              ),
            }),
        },
      });
      return response;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const fetchData = async (isReRender?: boolean) => {
    !isReRender && setAccountsLoading(true);
    setAccountVisitsLoading(true);
    setNoOfReceiptLoading(true);
    setReceiptsAmountLoading(true);
    try {
      const [
        accountsQuery,
        accountsVisitsQuery,
        noOfReceipts,
        receiptsAmountQuery,
      ] = await Promise.all([
        !isReRender && getAthenaQuery(ACCOUNTS),
        getAthenaQuery(ACCOUNTS_VISITS),
        getAthenaQuery(NO_OF_RECEIPTS),
        getAthenaQuery(RECEIPTS_AMOUNT),
      ]);

      if (accountsQuery && !isReRender) {
        setAccounts(accountsQuery[0]?.total_count ?? '0');
      }
      if (accountsVisitsQuery) {
        setAccountVisits(accountsVisitsQuery[0]?.total_count ?? '0');
      }
      if (noOfReceipts) {
        setNoOfReceipts(noOfReceipts[0]?.total_count ?? '0');
      }
      if (receiptsAmountQuery) {
        setReceiptsAmount(receiptsAmountQuery[0]?.total_amount ?? '0');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setAccountsLoading(false);
      setAccountVisitsLoading(false);
      setNoOfReceiptLoading(false);
      setReceiptsAmountLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (applyFilter) {
      fetchData(applyFilter);
      setApplyFilter(false);
    }
  }, [applyFilter]);

  useEffect(() => {
    if (clearFilter) {
      fetchData(clearFilter);
      setClearFilter(false);
    }
  }, [clearFilter]);
  return (
    <Grid container spacing={2}>
      {summaries.map(summary => (
        <Grid key={summary.text} item xs={6} sm={6} md={4} lg={3}>
          <PerformanceCard
            count={summary.count}
            loading={summary.loading}
            text={summary.text}
            bgcolor={summary.bgColor}
            color={summary.color}
            icon={summary.icon}
            border={'none'}
            height={'120px'}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PerformanceSummary;
