import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { BORDER_COLLECTION, COMMON } from '../../../../constants/style';
import VegaText from '../../../common/VegaText';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { PerformanceServices } from '../../../../Apis/PerformanceServices';
import { TOTAL_DISPOSITION_COUNT } from '../../../../constants/athenaQueryId';
import { AthenaQueryResponse } from '../../../../types/performanceType';
import { DispositionCode } from '../../../../types/trail';
import _ from 'lodash';
import { getPerformanceDashboard } from '../../../../features/performanceDashboardSlice';
import { useAppSelector } from '../../../../store';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { DateUtility } from '../../../../utils/DateUtlility';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaButton from '../../../common/VegaButton';
import VegaDatePicker from '../../../common/VegaDatePicker';

type DateFilter = {
  startDate: string;
  endDate: string;
};

const END_DATE_DEFAULT = new Date();
const START_DATE_DEFAULT = DateUtility.subtractDays(END_DATE_DEFAULT, 10);

const DatesAndDepositWiseAccount = () => {
  const { setSnackbar } = useSnackbar();
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const [date, setDate] = useState<DateFilter>({
    startDate: START_DATE_DEFAULT.toISOString(),
    endDate: END_DATE_DEFAULT.toISOString(),
  });
  const [totalDispositionCount, setTotalDispositionCount] = useState<
    AthenaQueryResponse[]
  >([]);

  const getTotalDispositionCountAthenaQuery = async (filter: DateFilter) => {
    try {
      const totalCount = await PerformanceServices.getAthenaQueries({
        queryId: TOTAL_DISPOSITION_COUNT,
        params: {
          ...filterData,
          START_DATE: filter.startDate,
          END_DATE: filter.endDate,
        },
      });
      setTotalDispositionCount(totalCount);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const handleDateChange = (key: keyof DateFilter, datefilter: any) => {
    const dateToString = DateUtility.parseDateFromDatePicker(datefilter);

    if (key === 'startDate') {
      let endDate = DateUtility.parseStringToDate(date?.endDate);
      if (endDate && dateToString) {
        const maxEndDate = DateUtility.addDays(dateToString, 10);
        if (DateUtility.isBefore(maxEndDate, endDate)) {
          endDate = DateUtility.addDays(dateToString, 10);
        }
        if (DateUtility.isBefore(endDate, dateToString)) {
          endDate = DateUtility.addDays(dateToString, 1);
        }
      } else if (!endDate) {
        endDate = DateUtility.addDays(dateToString, 10);
      }
      setDate({
        ...date,
        startDate: dateToString?.toISOString(),
        endDate: endDate?.toISOString(),
      });
      return;
    } else {
      let startDate = DateUtility.parseStringToDate(date?.startDate);

      if (startDate && dateToString) {
        if (DateUtility.isBefore(dateToString, startDate)) {
          startDate = dateToString;
        }
      }
      setDate({
        ...date,
        [key]: dateToString?.toISOString(),
      });
      return;
    }
  };

  const onApplyFilterClick = async () => {
    const paramsRequest = {
      ...(date?.startDate && {
        startDate: date.startDate,
      }),
      ...(date?.endDate && {
        endDate: date.endDate,
      }),
    } as DateFilter;

    await getTotalDispositionCountAthenaQuery(paramsRequest);
  };

  const clearFilter = () => {
    const dateFilter: DateFilter = {
      startDate: START_DATE_DEFAULT.toISOString(),
      endDate: END_DATE_DEFAULT.toISOString(),
    };
    setDate(dateFilter);
    getTotalDispositionCountAthenaQuery(dateFilter);
  };

  useEffect(() => {
    getTotalDispositionCountAthenaQuery({
      startDate: START_DATE_DEFAULT.toISOString(),
      endDate: END_DATE_DEFAULT.toISOString(),
    });
  }, []);
  const transformData = (
    data: AthenaQueryResponse[],
    startDate: string,
    endDate: string
  ) => {
    const dispositionCodes = Object.values(DispositionCode);

    const allDates = [];
    const currentDate = new Date(startDate);
    const end = new Date(endDate);
    while (currentDate <= end) {
      allDates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const categories = allDates;

    const series = dispositionCodes.map(code => ({
      name: _.startCase(_.toLower(code)),
      data: categories.map(date => {
        const item = data.find(
          d => d.update_trail_date === date && d.disposition_code === code
        );
        return item ? +item.disposition_count : 0;
      }),
    }));

    return { series, categories };
  };

  const { series, categories } = transformData(
    totalDispositionCount,
    date.startDate,
    date.endDate
  );

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
        columnWidth: '25px',
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Dates',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Disposition Codes',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'top',
      markers: {
        radius: 50,
      },
    },
    fill: {
      opacity: 1,
    },
    colors: dispositionColors,
    series,
  };

  return (
    <Stack
      sx={{
        p: 3,
        bgcolor: 'white',
        borderRadius: '12px',
        border: BORDER_COLLECTION,
      }}
      gap={2}
    >
      <Grid
        container
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          <VegaText
            text={'Action Dates and Deposit wise Account Distribution'}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '12px',
          p: 3,
          justifyContent: 'space-between',
        }}
        direction={'row'}
      >
        <Grid container direction={'row'} gap={1} rowGap={2}>
          <Grid item xs={12} sm={6} md={3} lg={2.4}>
            <VegaFormInputField label={'From'}>
              <VegaDatePicker
                value={date?.startDate}
                onChange={date => handleDateChange('startDate', date)}
                maxDate={new Date()}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2.4}>
            <VegaFormInputField label={'To'}>
              <VegaDatePicker
                value={date?.endDate}
                onChange={date => handleDateChange('endDate', date)}
                maxDate={DateUtility.addDays(new Date(date.startDate), 10)}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={1}
          sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
        >
          <VegaButton
            text="Clear Filter"
            onClick={clearFilter}
            variant="outlined"
            sx={{
              ':hover': {
                bgcolor: 'transparent',
                color: '#1047DC',
              },
            }}
          />
          <VegaButton text="Apply Filter" onClick={onApplyFilterClick} />
        </Grid>
      </Grid>
      <Chart options={options} series={options.series} type="bar" />
    </Stack>
  );
};

export default DatesAndDepositWiseAccount;
const filterByDispositionCode = (
  data: AthenaQueryResponse[],
  dispositionCode: DispositionCode
): number[] => {
  return data
    .filter(item => item.disposition_code === dispositionCode)
    .map(item => +item.disposition_count || 0);
};

const dispositionColors: string[] = [
  '#FF5733',
  '#33FF57',
  '#FFD933',
  '#3366FF',
  '#FF33FF',
  '#33FFFF',
  '#B38F08',
  '#B30000',
  '#006600',
  '#B38F00',
  '#000066',
  '#660066',
  '#666666',
  '#800000',
  '#008080',
  '#800080',
  '#004d00',
  '#4d2600',
  '#660000',
  '#808080',
  '#ff6600',
  '#0080ff',
];
