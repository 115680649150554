import { Grid } from '@mui/material';
import React from 'react';
import AccountMovmentRegionPerformanceGraph from './components/AccountMovmentRegionPerformanceGraph';
import AccountMovmentBucketPerformanceGraph from './components/AccountMovmentBucketPerformanceGraph';
import AcoountMovementChannelPerfromance from './components/AcoountMovementChannelPerfromance';
import RoleBaseSegmentPerformance from '../../components/RoleBaseSegmentPerformance';
import { useParams } from 'react-router-dom';

const AccountMovementContainer = () => {
  const { calenderType, profitType } = useParams();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccountMovmentRegionPerformanceGraph />
        </Grid>
        <Grid item xs={12}>
          <AccountMovmentBucketPerformanceGraph />
        </Grid>
        <Grid item xs={12}>
          <AcoountMovementChannelPerfromance />
        </Grid>

        <Grid item xs={12}>
          <RoleBaseSegmentPerformance
            calenderType={calenderType}
            selectedCollectionProfitType={profitType}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountMovementContainer;
