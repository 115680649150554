import { Stack } from '@mui/material';
import React from 'react';
import ListOfHitsAndMisses from './ListOfHitsAndMisses';

const HitsAndMisses = () => {
  return (
    <>
      <Stack
        sx={{
          p: 1,
          borderRadius: '12px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
          bgcolor: 'white',
          textAlign: 'center',
        }}
      >
        <ListOfHitsAndMisses />
      </Stack>
    </>
  );
};

export default HitsAndMisses;
