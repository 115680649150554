import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AllocationTypes } from '../../types/allocations';
import { DateUtility } from '../../utils/DateUtlility';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import { VegaSelectOption } from '../common/VegaSelect';
import { VegaFileSelectOption } from './BulkAllocationFileSelect';
import MultiSelectAgentSelector from '../TeleAgent/MultiSelectAgentSelector';

type Props = {
  open: boolean;
  onClose: () => void;
  onAllocateClick: (formData: Partial<ExportPenalAndOverallFormData>) => void;
  loading: boolean;
};

export type ExportPenalAndOverallFormData = {
  agentId: string[];
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}

function ExportPenalAndRollbackDrawer({
  onAllocateClick,
  open,
  onClose,
  loading,
}: Props) {
  const [formData, setFormData] = useState<
    Partial<ExportPenalAndOverallFormData>
  >({
    expiryDate: getNextDay().toISOString(),
    agentId: [],
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function onSumbitClick() {
    onAllocateClick(formData);
    setFormData({
      expiryDate: '',
    });
  }

  function onHandleClose() {
    onClose();
    setFormData({
      agentId: [],
    });
  }

  function updateExpiryDate(value) {
    updateFormData(InputType.ExpiryDate, value);
  }

  const onAgentSelect = (user: string) => {
    if (formData.agentId.includes(user)) {
      const filteredUser = formData.agentId.filter(
        (selectedUser: string) => selectedUser !== user
      );
      setFormData(prev => {
        return { ...prev, agentId: filteredUser };
      });
    } else {
      setFormData(prev => {
        return {
          ...prev,
          agentId: [...formData.agentId, user],
        };
      });
    }
  };

  const onAgentDelete = (index: number) => {
    const users = [...formData.agentId];
    users.splice(index, 1);
    setFormData(prev => {
      return { ...prev, agentId: users };
    });
  };

  useEffect(() => {
    const isAgentIdValid = formData?.agentId?.length > 0;
    const isExpiryDateValid = !!formData.expiryDate;
    setIsSubmitDisabled(!(isAgentIdValid && isExpiryDateValid));
  }, [formData]);

  return (
    <VegaDrawer open={open} title={'Execute Rule'} onClose={onHandleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Submit"
              onClick={onSumbitClick}
              disabled={isSubmitDisabled}
              loading={loading}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent'}>
              <MultiSelectAgentSelector
                selected={formData.agentId}
                onSelect={selected => onAgentSelect(selected)}
                onDelete={selectedIndex => onAgentDelete(selectedIndex)}
              />
            </VegaFormInputField>
          </Grid>
          {/* <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid> */}
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              minDate={getNextDay()}
              header="Expiry Date"
              value={formData.expiryDate}
              onChange={e => {
                updateExpiryDate(DateUtility.parseDateFromDatePicker(e));
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ExportPenalAndRollbackDrawer;

const allocationTypeOptions = () =>
  Object.keys(AllocationTypes).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

function getNextDay() {
  const today = new Date();
  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);
  return nextDay;
}
