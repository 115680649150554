import { useEffect, useState } from 'react';
import { fetchDepositBanksList } from '../../Apis/depositBank';
import { IDepositBankListProps } from '../../types/depositBank';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

interface IProps {
  selected: string | number;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
  segment?: string;
}

const DepositBankSelector = ({
  selected,
  handleChange,
  label,
  disabled,
  segment,
}: IProps) => {
  const [bankDepositList, setBankDepositList] = useState<VegaSelectOption[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== bankDepositList.length) {
        setPage(page + 1);
      }
    }
  };

  const getDepositBankList = async () => {
    fetchDepositBanksList({ page, size: 10, segment }).then(res => {
      const newArr = res?.records?.map((depositBank: IDepositBankListProps) => {
        return {
          // value: depositBank.bankName,
          value: depositBank.bankCode,
          label: `${depositBank.bankName} - ${depositBank.branchName}`,
        } as VegaSelectOption;
      });

      if (page === 0) {
        setBankDepositList(newArr);
      } else {
        setBankDepositList([...bankDepositList, ...newArr]);
      }
      setTotalItem(res.totalItems);
    });
  };

  useEffect(() => {
    getDepositBankList();
  }, [page]);

  useEffect(() => {
    if (segment) {
      setPage(0);
      getDepositBankList();
    }
  }, [segment]);

  return (
    <VegaSelect
      placeholder={label ?? 'Select Deposit Center'}
      size="small"
      value={selected ?? ''}
      onChange={e => handleChange(e.target.value)}
      options={bankDepositList}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default DepositBankSelector;
