import { Stack } from '@mui/material';
import LmTextField from '../../../../components/common/LmTextField';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  ConfigValueProps,
  ConfigurationDto,
} from '../../../../types/configurationType';

const GeoVarificationForm = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const handleGeoVarifyChange = (
    key: keyof ConfigValueProps,
    value: number | string
  ) => {
    const updateConfigValueObj = { ...configurations.config, [key]: value };
    handleConfigChange('config', updateConfigValueObj);
  };

  return (
    <Stack gap={2}>
      <VegaFormInputField label={'Approval Deadline'}>
        <LmTextField
          type="number"
          value={configurations.config?.approvalDeadline ?? 0}
          onChange={e => {
            const value = e.target.value && parseFloat(e.target.value);
            handleGeoVarifyChange('approvalDeadline', value);
          }}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'Next level escalation deadline'}>
        <LmTextField
          type="number"
          value={configurations.config?.escalationDeadline ?? 0}
          onChange={e => {
            const value = e.target.value && parseFloat(e.target.value);
            handleGeoVarifyChange('escalationDeadline', value);
          }}
        />
      </VegaFormInputField>
    </Stack>
  );
};

export default GeoVarificationForm;
