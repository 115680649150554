import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { PerformanceServices } from '../../../../Apis/PerformanceServices';
import { COLLECTED_COLLECTIBLE_DATA } from '../../../../constants/athenaQueryId';
import { COMMON } from '../../../../constants/style';
import { getPerformanceDashboard } from '../../../../features/performanceDashboardSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../../store';
import { AthenaQueryResponse } from '../../../../types/performanceType';
import { DispositionCode } from '../../../../types/trail';
import { StringUtility } from '../../../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import VegaText from '../../../common/VegaText';
import { getMonthName } from '../../../../pages/AnalyticsDashboard/Graphs/MonthOnMonth/PenalCollectionGraph';

const CollectedVSCollectableGraph = () => {
  const { setSnackbar } = useSnackbar();
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const [collectedColletableCount, setCollectedColletableCount] = useState<
    AthenaQueryResponse[]
  >([]);

  const getCollectedCollectableCountQuery = async () => {
    try {
      const totalCount = await PerformanceServices.getAthenaQueries({
        queryId: COLLECTED_COLLECTIBLE_DATA,
        params: {},
      });
      setCollectedColletableCount(totalCount);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const modifyAnalyticsQuery = collectedColletableCount.map(item => ({
    categories: ` ${getMonthName(+item.month)}- ${item.year} `,
    series: {
      collected: parseFloat(item.total_collected_amount) || 0,
      collectible: parseFloat(item.total_collectible_amount) || 0,
    },
  }));

  const series = [
    {
      name: 'Collected',
      data: modifyAnalyticsQuery.map(item =>
        Number(StringUtility.formatToFixed(item.series?.collected ?? '0', 2))
      ),
    },
    {
      name: 'Collectable',
      data: modifyAnalyticsQuery.map(item =>
        Number(StringUtility.formatToFixed(item.series?.collectible ?? '0', 2))
      ),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
        columnWidth: '25px',
      },
    },
    yaxis: {
      title: {
        text: 'Total Amount (in Crores)',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    xaxis: {
      type: 'category',
      categories: modifyAnalyticsQuery.map(item => item.categories),
      title: {
        text: '',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'top',
      markers: {
        radius: 50,
      },
    },
    fill: {
      opacity: 1,
    },

    colors: dispositionColors,
  };

  const getTotalCollectedAmount = () => {
    var totalAmount = 0;
    collectedColletableCount.forEach(i => {
      totalAmount += Number(i.total_collected_amount);
    });
    const value = totalAmount.toFixed(2);
    return value;
  };

  const getTotalCollectibleAmount = () => {
    var totalAmount = 0;
    collectedColletableCount.forEach(i => {
      totalAmount += Number(i.total_collectible_amount);
    });
    const value = totalAmount.toFixed(2);
    return value;
  };

  useEffect(() => {
    COLLECTED_COLLECTIBLE_DATA.length && getCollectedCollectableCountQuery();
  }, []);

  return (
    <Stack
      sx={{
        bgcolor: 'white',
        borderRadius: '12px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
      }}
      gap={2}
    >
      <Stack
        p={3}
        sx={{
          bgcolor: '#E5F7FC',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
        direction={'row'}
        justifyContent={'space-around'}
      >
        <Stack gap={0.5}>
          <VegaText
            text={`${getTotalCollectibleAmount()} (in Crores)`}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
          <VegaText
            text={'Amount Collectable'}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
        </Stack>
        <Stack gap={0.5}>
          <VegaText
            text={`${getTotalCollectedAmount()} (in Crores)`}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
          <VegaText
            text={'Amount Collected'}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
        </Stack>
      </Stack>

      <Stack p={3}>
        <Chart options={options} series={series} type="bar" />
      </Stack>
    </Stack>
  );
};

export default CollectedVSCollectableGraph;

const filterByDispositionCode = (
  data: AthenaQueryResponse[],
  dispositionCode: DispositionCode
): number[] => {
  return data
    .filter(item => item.disposition_code === dispositionCode)
    .map(item => +item.disposition_count || 0);
};

const dispositionColors: string[] = ['#FF5733', '#33FF57'];
