import { Grid } from '@mui/material';
import React from 'react';
import OverallPerformance from './components/OverallPerformance';
import RegionPerformance from './components/RegionPerformance';
import { useParams } from 'react-router-dom';
import RoleBaseSegmentPerformance from '../../components/RoleBaseSegmentPerformance';

const CollectionTeamContainer = () => {
  const { calenderType, profitType } = useParams();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <OverallPerformance />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RegionPerformance />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RoleBaseSegmentPerformance
            calenderType={calenderType}
            selectedCollectionProfitType={profitType}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionTeamContainer;
