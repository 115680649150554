import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../../components/common/VegaText';
import { LmsVariable, LoanData, LoanMaster } from '../../../types/loan';
// import { DateUtility } from '../../../utils/DateUtlility';
// import { StringUtility } from '../../../utils/StringUtility';
import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ReceiptIcon } from '../../../components/Icons/Icons';
import { LoanAccountColumns } from '../../../components/common/VegaColumnFilterSection';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { ROUTES } from '../../../router/routes';
import { DispositionCode } from '../../../types/trail';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';

enum DispositionGroup {
  PAYMENT = 'PAYMENT',
  NON_PAYMENT = 'NON_PAYMENT',
}
export const LoanAccountColumn = (
  selectedOptions: string[],
  isReceiptIssue?: boolean
) => {
  const { userForId, agentIdToManager, collectorCodeForAgentId } =
    useClientAuth();
  const navigate = useNavigate();
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = `${userForId(userId)}(${collectorCodeForAgentId(userId)})`;
    if (!user) return 'NA';
    return user;
  };

  const getManagerName = (agentId: string) => {
    if (!agentId) return 'NA';
    const manager = `${agentIdToManager(agentId)}`;
    if (!manager) return 'NA';
    return manager;
  };

  const COLUMN_DEF: GridColumns<LoanData> = [
    {
      field: 'UniqueReferanceNumber',
      headerName: 'Loan Account Number',
      hide: selectedOptions.includes(LoanAccountColumns.LOAN_ACCOUNT_NUMBER),
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as LoanData;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={loan.loanId ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={loan.loanId ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onDoubleClick={() =>
                  navigate(
                    ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID.replace(
                      ':loanAccountId',
                      loan.loanId
                    )
                  )
                }
              />
            }
          />
        );
      },
    },
    {
      field: 'Name',
      headerName: 'Name',
      hide: selectedOptions.includes(LoanAccountColumns.NAME),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.lmsVariables) {
          const getCustomerName = loan?.lmsVariables?.customerName;
          if (getCustomerName) {
            displayText = toLowerCase(getCustomerName);
          }
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'ContactNumber',
      headerName: 'Contact Number',
      hide: selectedOptions.includes(LoanAccountColumns.CONTACT_NUMBER),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.mobileNo) {
            displayText = getCustomerName.mobileNo;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Address',
      headerName: 'Address',
      resizable: true,
      hide: selectedOptions.includes(LoanAccountColumns.ADDRESS),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.customerAddress) {
            displayText = toLowerCase(getCustomerName.customerAddress);
          }
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'Zipcode',
      headerName: 'Zipcode',
      hide: selectedOptions.includes(LoanAccountColumns.ZIPCODE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        // if (loan.customerDetails) {
        //   const getCustomerName = getFirstItemIfExists(loan.customerDetails);
        //   if (getCustomerName.pincode) {
        //     displayText = `${getCustomerName.pincode}`;
        //   }
        // }
        if (loan.lmsVariables) {
          displayText = loan?.lmsVariables?.pincode ?? '';
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Outstanding Payment',
      headerName: 'Outstanding Payment',
      hide: selectedOptions.includes(LoanAccountColumns.OUTSTANDING_PAYMENT),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.outstandingAmount) {
          displayText = `${StringUtility.formatToINR(
            LmsVariable.outstandingAmount
          )}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'interestDue',
      headerName: 'Interest Due',
      hide: selectedOptions.includes(LoanAccountColumns.INTEREST_DUE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.interestDue) {
          displayText = `${LmsVariable.interestDue}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'principalDue',
      headerName: 'Principal Due',
      hide: selectedOptions.includes(LoanAccountColumns.PRINCIPAL_DUE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.principalDue) {
          displayText = `${LmsVariable.principalDue}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'chargeDue',
      headerName: 'Charge Due',
      hide: selectedOptions.includes(LoanAccountColumns.CHARGE_DUE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.chargeDue) {
          displayText = `${LmsVariable.chargeDue}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Overdue Amount',
      headerName: 'Overdue Amount',
      hide: selectedOptions.includes(LoanAccountColumns.OVERDUE_PAYMENT),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.overdueAmount) {
          displayText = `${StringUtility.formatToINR(
            LmsVariable.overdueAmount
          )}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'brnRegion',
      headerName: 'Branch Region Code',
      hide: selectedOptions.includes(LoanAccountColumns.BRN_REGION),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.brnRegion) {
          displayText = `${LmsVariable.brnRegion}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'branchCode',
      headerName: 'Branch Code',
      hide: selectedOptions.includes(LoanAccountColumns.BRANCH_CODE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.branchCode) {
          displayText = `${LmsVariable.branchCode}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'EMI',
      headerName: 'EMI',
      hide: selectedOptions.includes(LoanAccountColumns.EMI),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.installment) {
          displayText = `${StringUtility.formatToINR(LmsVariable.installment)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Deliquency',
      headerName: 'Delinquency',
      hide: selectedOptions.includes(LoanAccountColumns.DELIQUENCY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.bucket) {
          displayText = `${LmsVariable.bucket}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'cycleDate',
      headerName: 'Cycle Date',
      hide: selectedOptions.includes(LoanAccountColumns.CYCLE_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.cycleDate) {
          displayText = `${LmsVariable.cycleDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',
      hide: selectedOptions.includes(LoanAccountColumns.SEGMENT),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.segment) {
          displayText = `${LmsVariable.segment}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'DPD',
      headerName: 'DPD',
      hide: selectedOptions.includes(LoanAccountColumns.DPD),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const lmsVariables = loan.lmsVariables as LmsVariable;

        return (
          <VegaText
            text={lmsVariables?.dpd ?? ''}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },

    {
      field: 'Agent',
      headerName: 'Agent',
      hide: selectedOptions.includes(LoanAccountColumns.AGENT),
      flex: 0.7,
      minWidth: 180,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;

        let displayText = '';
        if (LmsVariable.agentId) {
          displayText = getUserName(LmsVariable.agentId);
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'managerId',
      headerName: 'Manager',
      hide: selectedOptions.includes(LoanAccountColumns.MANAGER_NAME),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;

        const lmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (lmsVariable.agentId) {
          displayText = `${getManagerName(lmsVariable.agentId)}`;
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'Product',
      headerName: 'Product',
      hide: selectedOptions.includes(LoanAccountColumns.PRODUCT),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const loanMaster = loan.loanMaster as LoanMaster;
        let displayText = '';
        if (loanMaster?.loanProduct) {
          displayText = loanMaster?.loanProduct;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'modelParserAllocationType',
      headerName: 'Model Parser AllocationType',
      hide: selectedOptions.includes(
        LoanAccountColumns.MODEL_PARSER_ALLOCATION_TYPE
      ),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const lmsVariables = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (lmsVariables?.modelParserAllocationType) {
          displayText = lmsVariables?.modelParserAllocationType;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Conllection Category',
      headerName: 'Collection Category',
      hide: selectedOptions.includes(LoanAccountColumns.CONLLECTION_CATEGORY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;

        let displayText = '';
        if (LmsVariable.riskCategory) {
          displayText = LmsVariable.riskCategory;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Customer Type',
      headerName: 'Customer Type',
      hide: selectedOptions.includes(LoanAccountColumns.CUSTOMER_TYPE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.mobileNo) {
            displayText = '';
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'PTP Probablity',
      headerName: 'PTP Probablity',
      hide: selectedOptions.includes(LoanAccountColumns.PTP_PROBABLITY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.probableDateOfPayment) {
          displayText = getPTPProbablityStatus(
            LmsVariable.probableDateOfPayment
          );
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Predicted PTP date',
      headerName: 'Predicted PTP date',
      hide: selectedOptions.includes(LoanAccountColumns.PREDICTED_PTP_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.probableDateOfPayment) {
          displayText = LmsVariable.probableDateOfPayment;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Pending Settlement',
      headerName: 'Pending Settlement',
      hide: selectedOptions.includes(LoanAccountColumns.PENDING_SETTLEMENT),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.mobileNo) {
            displayText = `${0}`;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Promise To Pay',
      headerName: 'Promise To Pay',
      hide: selectedOptions.includes(LoanAccountColumns.PROMISE_TO_PAY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.ptpAmount) {
          displayText = `${LmsVariable.ptpAmount}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'Last Payment Received',
      headerName: 'Last Payment Received',
      hide: selectedOptions.includes(LoanAccountColumns.LAST_PAYMENT_RECIVED),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.mobileNo) {
            displayText = `${0}`;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'Branch',
      headerName: 'Branch',
      hide: selectedOptions.includes(LoanAccountColumns.BRANCH),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.branchCode) {
          displayText = `${toLowerCase(LmsVariable.branchCode)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'State',
      headerName: 'State',
      hide: selectedOptions.includes(LoanAccountColumns.STATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.state) {
          displayText = `${toLowerCase(LmsVariable.state)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'City',
      headerName: 'City',
      hide: selectedOptions.includes(LoanAccountColumns.CITY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.city) {
          displayText = `${toLowerCase(LmsVariable.city)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Disposition Date',
      headerName: 'Disposition Date',
      hide: selectedOptions.includes(LoanAccountColumns.DISPOSITION_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.dispositionDate) {
          displayText = `${LmsVariable.dispositionDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Disposition Group',
      headerName: 'Disposition Group',
      hide: selectedOptions.includes(LoanAccountColumns.DISPOSITION_GROUP),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.dispositionCode) {
          displayText = `${_.startCase(
            _.toLower(getDispositionGroup(LmsVariable.dispositionCode))
          )}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Disposition Code',
      headerName: 'Disposition Code',
      hide: selectedOptions.includes(LoanAccountColumns.DISPOSITION_CODE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.dispositionCode) {
          displayText = `${_.startCase(
            _.toLower(LmsVariable.dispositionCode)
          )}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'loanPredictedStatus',
      headerName: 'Loan Predicted Status',
      hide: selectedOptions.includes(LoanAccountColumns.LOAN_PREDICTED_STATUS),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.predictedLoanStatus) {
          displayText = `${LmsVariable.predictedLoanStatus}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'accountCloserDate',
      headerName: 'Account Closer Date',
      hide: selectedOptions.includes(LoanAccountColumns.ACCOUNT_CLOSER_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.accountCloserDate) {
          displayText = `${LmsVariable.accountCloserDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
      hide: selectedOptions.includes(LoanAccountColumns.ACCOUNT_STATUS),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.accountStatus) {
          displayText = `${LmsVariable.accountStatus}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'adjustedPrincipal',
      headerName: 'Adjusted Principal',
      hide: selectedOptions.includes(LoanAccountColumns.ADJUSTED_PRINCIPAL),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.adjustedPrincipal) {
          displayText = `${LmsVariable.adjustedPrincipal}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'assetQuality',
    //   headerName: 'Asset Quality',
    //   hide: selectedOptions.includes(LoanAccountColumns.ASSET_QUALITY),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.assetQuality) {
    //       displayText = `${LmsVariable.assetQuality}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'balanceTenor',
      headerName: 'Balance Tenor',
      hide: selectedOptions.includes(LoanAccountColumns.BALANCE_TENOR),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.balanceTenor) {
          displayText = `${LmsVariable.balanceTenor}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'prevBucket',
      headerName: 'Prev Bucket',
      hide: selectedOptions.includes(LoanAccountColumns.PREV_BUCKET),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.prevBucket) {
          displayText = `${LmsVariable.prevBucket}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date Of Birth',
      hide: selectedOptions.includes(LoanAccountColumns.DATE_OF_BIRTH),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.dateOfBirth) {
          displayText = `${LmsVariable.dateOfBirth}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'firstDisbursalDate',
      headerName: 'First Disbursal Date',
      hide: selectedOptions.includes(LoanAccountColumns.FIRST_DISBURSAL_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.firstDisbursalDate) {
          displayText = `${LmsVariable.firstDisbursalDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'lastDisbursalDate',
      headerName: 'Last Disbursal Date',
      hide: selectedOptions.includes(LoanAccountColumns.LAST_DISBURSAL_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.lastDisbursalDate) {
          displayText = `${LmsVariable.lastDisbursalDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'financeAmount',
      headerName: 'Finance Amount',
      hide: selectedOptions.includes(LoanAccountColumns.FINANCE_AMOUNT),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.financeAmount) {
          displayText = `${LmsVariable.financeAmount}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'gender',
      headerName: 'Gender',
      hide: selectedOptions.includes(LoanAccountColumns.GENDER),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.gender) {
          displayText = `${LmsVariable.gender}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    // {
    //   field: 'maritalStatus',
    //   headerName: 'Marital Status',
    //   hide: selectedOptions.includes(LoanAccountColumns.MARITAL_STATUS),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.maritalStatus) {
    //       displayText = `${LmsVariable.maritalStatus}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'noOfChildren',
    //   headerName: 'No Of Children',
    //   hide: selectedOptions.includes(LoanAccountColumns.MARITAL_STATUS),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.noOfChildren) {
    //       displayText = `${LmsVariable.noOfChildren}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'noOfDependents',
    //   headerName: 'No Of Dependents',
    //   hide: selectedOptions.includes(LoanAccountColumns.NO_OF_DEPENDENTS),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.noOfDependents) {
    //       displayText = `${LmsVariable.noOfDependents}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'occupation',
    //   headerName: 'Occupation',
    //   hide: selectedOptions.includes(LoanAccountColumns.OCCUPATION),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.occupation) {
    //       displayText = `${LmsVariable.occupation}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'optedForMoratorium',
    //   headerName: 'Opted For Moratorium',
    //   hide: selectedOptions.includes(LoanAccountColumns.OPTED_FOR_MORATORIAM),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.optedForMoratorium) {
    //       displayText = `${LmsVariable.optedForMoratorium}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'paymentMode',
    //   headerName: 'Payment Mode',
    //   hide: selectedOptions.includes(LoanAccountColumns.OPTED_FOR_MORATORIAM),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.paymentMode) {
    //       displayText = `${LmsVariable.paymentMode}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },

    // {
    //   field: 'repaymentFrequency',
    //   headerName: 'Repayment Frequency',
    //   hide: selectedOptions.includes(LoanAccountColumns.REPAYMENT_FREQUENCY),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.repaymentFrequency) {
    //       displayText = `${LmsVariable.repaymentFrequency}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'settlementReason',
    //   headerName: 'Settlement Reason',
    //   hide: selectedOptions.includes(LoanAccountColumns.SETTLEMENT_REASON),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.settlementReason) {
    //       displayText = `${LmsVariable.settlementReason}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'termInMonths',
      headerName: 'Term In Months',
      hide: selectedOptions.includes(LoanAccountColumns.TERM_IN_MONTHS),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.termInMonths) {
          displayText = `${LmsVariable.termInMonths}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'relationWithBorrower',
    //   headerName: 'Relationship With Borrower',
    //   hide: selectedOptions.includes(
    //     LoanAccountColumns.RELATIONSHIP_WITH_BORROWER
    //   ),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.relationWithBorrower) {
    //       displayText = `${LmsVariable.relationWithBorrower}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'applicationNo',
    //   headerName: 'Application Number',
    //   hide: selectedOptions.includes(LoanAccountColumns.APPLICATION_NUMBER),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.applicationNo) {
    //       displayText = `${LmsVariable.applicationNo}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'nextInstallmentDate',
      headerName: 'Next Installment Date',
      hide: selectedOptions.includes(LoanAccountColumns.NEXT_INSTALLMENT_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.nextInstallmentDate) {
          displayText = `${LmsVariable.nextInstallmentDate}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'preEmiAmount',
    //   headerName: 'Pre Emi Amount',
    //   hide: selectedOptions.includes(LoanAccountColumns.PRE_EMI_AMOUNT),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.preEmiAmount) {
    //       displayText = `${LmsVariable.preEmiAmount}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },

    {
      field: 'brnCity',
      headerName: 'Brn City',
      hide: selectedOptions.includes(LoanAccountColumns.BRN_CITY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.brnCity) {
          displayText = `${LmsVariable.brnCity}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'brnState',
      headerName: 'Brn State',
      hide: selectedOptions.includes(LoanAccountColumns.BRN_STATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.brnState) {
          displayText = `${LmsVariable.brnState}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'npaFlag',
      headerName: 'NPA Flag',
      hide: selectedOptions.includes(LoanAccountColumns.NPA_FLAG),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.npaFlag) {
          displayText = `${LmsVariable.npaFlag}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'isBounce',
      headerName: 'Is Bounce',
      hide: selectedOptions.includes(LoanAccountColumns.IS_BOUNCE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.isBounce) {
          displayText = `${LmsVariable.isBounce}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'paymentCategory',
      headerName: 'Payment Category',
      hide: selectedOptions.includes(LoanAccountColumns.PAYMENT_CATEGORY),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.paymentCategory) {
          displayText = `${LmsVariable.paymentCategory}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'allocationStatus',
    //   headerName: 'Allocation Status',
    //   hide: selectedOptions.includes(LoanAccountColumns.ALLOCATION_STATUS),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.allocationStatus) {
    //       displayText = `${LmsVariable.allocationStatus}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },

    {
      field: 'allocationDate',
      headerName: 'Allocation Date',
      hide: selectedOptions.includes(LoanAccountColumns.ALLOCATION_DATE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.allocationDate) {
          displayText = `${DateUtility.formatStringToDDMMYYYYWithTime(
            LmsVariable.allocationDate
          )}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'loadDate',
    //   headerName: 'Load Date',
    //   hide: selectedOptions.includes(LoanAccountColumns.LOAD_DATE),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.loadDate) {
    //       displayText = `${LmsVariable.loadDate}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'loanStatus',
      headerName: 'Loan Status',
      hide: selectedOptions.includes(LoanAccountColumns.LOAN_STATUS),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.loanStatus) {
          displayText = `${LmsVariable.loanStatus}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'nextAction',
      headerName: 'Next Action',
      hide: selectedOptions.includes(LoanAccountColumns.NEXT_ACTION),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.nextAction) {
          displayText = `${LmsVariable.nextAction}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'otsId',
    //   headerName: 'OTS Id',
    //   hide: selectedOptions.includes(LoanAccountColumns.OTS_ID),
    //   flex: 0.7,
    //   minWidth: 150,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;
    //     const LmsVariable = loan.lmsVariables as LmsVariable;
    //     let displayText = '';
    //     if (LmsVariable.otsId) {
    //       displayText = `${LmsVariable.otsId}`;
    //     }
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'otsRaised',
      headerName: 'OTS Raised',
      hide: selectedOptions.includes(LoanAccountColumns.OTS_RAISED),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.otsRaised) {
          displayText = `${LmsVariable.otsRaised}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'escalationType',
      headerName: 'Escalation Type',
      hide: selectedOptions.includes(LoanAccountColumns.ESCALATION_TYPE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.escalationType) {
          displayText = `${LmsVariable.escalationType}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'referToField',
      headerName: 'Refer To Field',
      hide: selectedOptions.includes(LoanAccountColumns.REFER_TO_FIELD),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.referToField) {
          displayText = `${LmsVariable.referToField}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'penalChargeDue',
      headerName: 'Penal Charge Due',
      hide: selectedOptions.includes(LoanAccountColumns.PENAL_CHARGE_DUE),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.penalChargeDue) {
          displayText = `${LmsVariable.penalChargeDue}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'mob',
      headerName: 'MOB',
      hide: selectedOptions.includes(LoanAccountColumns.MOB),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.mob) {
          displayText = `${LmsVariable.mob}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'legalStatus',
      headerName: 'Legal Status',
      hide: selectedOptions.includes(LoanAccountColumns.LEGAL_STATUS),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (LmsVariable.legalStatus) {
          displayText = `${LmsVariable.legalStatus}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsBreach',
      headerName: 'OTS Breached',
      hide: selectedOptions.includes(LoanAccountColumns.OTS_BREACHED),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = 'NA';
        if (LmsVariable.otsBreached) {
          displayText =
            (LmsVariable.otsBreached ?? false) == true ? 'Yes' : 'No';
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'datails',
      headerName: 'View Details',
      hide: isReceiptIssue,
      flex: 0.6,
      minWidth: 160,
      renderCell: props => {
        const loan = props.row as LoanData;

        return (
          // <VegaButton
          //   text={'View Details'}
          //   variant="text"
          //   onClick={() => {
          //     navigate(
          //       ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID.replace(
          //         ':loanAccountId',
          //         loan.loanId
          //       )
          //     );
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() =>
              navigate(
                ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID.replace(
                  ':loanAccountId',
                  loan.loanId
                )
              )
            }
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
    // <ReceiptIcon />
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      minWidth: 160,
      hide: !isReceiptIssue,
      renderCell: props => {
        const loanAccount = props.row as LoanData;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="Issue Receipt"
              onClick={() =>
                navigate(
                  ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT.replace(
                    ':loanId',
                    loanAccount.loanId
                  )
                )
              }
              sx={{ bgcolor: '#E7EDFC' }}
              icon={<ReceiptIcon strokeColor={PRIMARY.darkBlue} />}
            />
            {/* <VegaButton
              text="Issue Receipt"
              sx={{
                color: PRIMARY.darkBlue,
                fontSize: '0.875rem',
                bgcolor: '#E7EDFC',
                ':hover': {
                  color: PRIMARY.darkBlue,
                  bgcolor: '#E7EDFC',
                },
              }}
              onClick={() =>
                navigate(
                  ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT.replace(
                    ':loanId',
                    loanAccount.loanId
                  )
                )
              }
            /> */}
          </Stack>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}

function daysBetweenDates(date1: dayjs.Dayjs, date2: dayjs.Dayjs): number {
  const differenceInMilliseconds = date2.diff(date1);

  const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

  return Math.abs(differenceInDays);
}

function getPTPProbablityStatus(date: string): string {
  const currentDate = dayjs();
  const ptpDate = dayjs(date);
  if (dayjs(ptpDate).isBefore(currentDate)) {
    return 'HP';
  } else {
    const datesDifference = daysBetweenDates(currentDate, ptpDate);
    if (datesDifference > 3) {
      return 'LP';
    }
    if (datesDifference > 1 && datesDifference <= 3) {
      return 'MP';
    }
    if (datesDifference >= 0 && datesDifference <= 1) {
      return 'HP';
    }
  }
}

function getDispositionGroup(value: string) {
  if (value === null) {
    return null;
  }
  switch (value) {
    case DispositionCode.PTP:
      return DispositionGroup.PAYMENT;
    case DispositionCode.PAID:
      return DispositionGroup.PAYMENT;
    case DispositionCode.PARTIAL_PAYMENT:
      return DispositionGroup.PAYMENT;
    case DispositionCode.FORECLOSED:
      return DispositionGroup.PAYMENT;
    case DispositionCode.CHEQUE_PICKUP:
      return DispositionGroup.PAYMENT;
    case DispositionCode.THIRD_PARTY_PAYMENT:
      return DispositionGroup.PAYMENT;
    case DispositionCode.OTHERS:
      return DispositionGroup.PAYMENT;
    case DispositionCode.LATE_PTP:
      return DispositionGroup.PAYMENT;
    default:
      return DispositionGroup.NON_PAYMENT;
  }
}
