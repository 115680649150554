import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserActions } from '../../../features/userSlice';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useAppDispatch } from '../../../store';
import { CreateTeleAgentRequest } from '../../../types/request/teleAgent';
import AgentSelector from '../../AgentSelector';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';

type Props = {
  onSubmit: (formData: Partial<CreateTeleAgentRequest>) => void;
  loading: boolean;
};

enum InputType {
  username = 'username',
  firstname = 'firstname',
  lastname = 'lastname',
  password = 'password',
  remoteAgentNumber = 'remoteAgentNumber',
  crmId = 'crmId',
  employeeCode = 'employeeCode',
  userId = 'userId',
}

function CreateTeleAgentForm({ loading, onSubmit }: Props) {
  const { collectorCodeForAgentId } = useClientAuth();
  const { userForId } = useClientAuth();
  const [formData, setFormData] = useState<Partial<CreateTeleAgentRequest>>({});
  const dispatch = useAppDispatch();

  function updateFormData(field: InputType, value: string) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function isInputValid() {
    if (
      !formData.firstname ||
      !formData.lastname ||
      !formData.crmId ||
      !formData.remoteAgentNumber ||
      !formData.password ||
      !formData.employeeCode ||
      !formData.userId
    ) {
      return true;
    } else {
      return false;
    }
  }

  function onAgentSelect(select: string) {
    const employeeCode = getCollectorCode(select);
    setFormData(prev => {
      return {
        ...prev,
        employeeCode,
        userId: select,
      };
    });
  }

  function onClearAgentClick() {
    updateFormData(InputType.userId, '');
    updateFormData(InputType.userId, '');
  }

  const getCollectorCode = (agentId: string) => {
    if (!agentId) return 'NA';
    const collectorCode = collectorCodeForAgentId(agentId);
    if (!collectorCode) return 'NA';
    return collectorCode;
  };

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const getEmployeeCodeText = () => {
    if (formData.userId && formData.employeeCode) {
      return `${getUserName(formData?.userId)} - ${formData?.employeeCode}`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    dispatch(UserActions.fetchAgents());
  }, []);

  return (
    <>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              loading={loading}
              text="Create"
              disabled={isInputValid()}
              onClick={() => {
                onSubmit(formData);
              }}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              placeholder="First Name"
              header="First Name"
              value={formData.firstname}
              onChange={e => {
                updateFormData(InputType.firstname, e.target.value as string);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              placeholder="Last Name"
              header="Last Name"
              value={formData.lastname}
              onChange={e => {
                updateFormData(InputType.lastname, e.target.value as string);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              placeholder="Password"
              header="Password"
              value={formData.password}
              onChange={e => {
                updateFormData(InputType.password, e.target.value as string);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              placeholder="Crm Id"
              header="Crm Id"
              value={formData.crmId}
              onChange={e => {
                updateFormData(InputType.crmId, e.target.value as string);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Employee Code">
              <AgentSelector
                selected={getEmployeeCodeText() ?? ''}
                onSelected={onAgentSelect}
                onClear={onClearAgentClick}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              placeholder="Remote Agent Number"
              header="Remote Agent Number"
              value={formData.remoteAgentNumber}
              type="number"
              onChange={e => {
                updateFormData(
                  InputType.remoteAgentNumber,
                  e.target.value as string
                );
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </>
  );
}

export default CreateTeleAgentForm;
