import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import { getIncentiveForMonthState } from '../../../../features/IncentivesGamificationsSlice/incentiveForMonthSlice';
import { overallIncentivesList } from '../../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { MonthOnMonthBucketEnum } from '../../../../features/analyticsDashboardSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import LoadingPage from '../../../LoadingPage/LoadingPage';
import GraphWrapper from '../components/GraphWrapper';
import PayoutGraph from '../components/PayoutGraph';
import { IncentiveMonthDetailsDto } from '../../../../types/incentiveOfMonthType';
import { BucketOption } from '../../../../types/minVisitsType';

interface IProps {
  selectedRole: string[];
  roles: VegaSelectOption[];
}

const PayoutForMonthOnMonth = ({ selectedRole, roles }: IProps) => {
  const { loadingForCurrentMonth, incentiveForMonthData } = useAppSelector(
    getIncentiveForMonthState
  );
  const dispatch = useAppDispatch();

  const [bucket, setBucket] = useState<string>('BUCKETX');

  const seriesData = incentiveForMonthData.reduce(
    (
      accumulator: { [key: string]: number[] },
      monthData: IncentiveMonthDetailsDto
    ) => {
      monthData?.values.forEach(roleData => {
        if (!accumulator[roleData.role]) {
          accumulator[roleData.role] = [];
        }
        accumulator[roleData.role].push(roleData.value);
      });
      return accumulator;
    },
    {}
  );

  const series = Object.entries(seriesData).map(([role, data]) => ({
    name: role,
    data,
  }));

  const month = incentiveForMonthData.map(payout => payout.date);

  const getTitle = () => {
    // const findRole = roles.find(role => role.label === selectedRole);
    // const title = `${findRole?.label ?? ''} Pay-out`;
    const title = `Pay-out`;
    return title;
  };

  const currentMonthIncentive = () => {
    dispatch(overallIncentivesList({ role: selectedRole, bucket: [bucket] }));
  };

  useEffect(() => {
    currentMonthIncentive();
  }, [selectedRole, bucket]);
  return (
    <>
      <Stack
        gap={1}
        sx={{
          p: 1,
          borderRadius: '12px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <VegaText
            text={'Month-on-Month Pay-out'}
            fontWeight={600}
            fontSize={'1rem'}
            color={'black'}
            sx={{
              p: 1,
            }}
          />
          <Stack width={150}>
            <VegaSelect
              options={getBucketOptions()}
              value={bucket}
              onChange={e => setBucket(e.target.value as string)}
              ignoreLodash
            />
          </Stack>
        </Stack>

        <GraphWrapper title={getTitle()}>
          {loadingForCurrentMonth ? (
            <LoadingPage height={300} />
          ) : (
            <PayoutGraph yAxisTitle="" categories={month} series={series} />
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default PayoutForMonthOnMonth;

const getBucketOptions = () => {
  return Object.entries(BucketOption).map(([value, label]) => ({
    value,
    label,
  }));
};
