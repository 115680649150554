import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoanService } from '../../../Apis/LoanServices';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { CustomerAddressProps } from '../../../types/loan';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const AddressDetails = () => {
  const { loanAccountId } = useParams();

  const { setSnackbar } = useSnackbar();
  const [customerData, setCustomerData] = useState<CustomerAddressProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getAddress = async (loanId: string) => {
    setLoading(true);
    try {
      const response = await LoanService.getAddressFormLoanId(loanId);
      setCustomerData(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanAccountId) {
      getAddress(loanAccountId);
    }
  }, [loanAccountId]);

  return (
    <TeleAgentDetailsCard text="Address Details" isBorder>
      <Stack gap={2}>
        {customerData.length ? (
          customerData.map((address: CustomerAddressProps, index) => (
            <Stack
              key={index}
              sx={{
                borderRadius: '12px',
                p: 2,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TeleAgentTextWrapper
                    text={'Address Type'}
                    value={address.addressType}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TeleAgentTextWrapper
                    text={'Customer Type'}
                    value={address.customerType}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TeleAgentTextWrapper
                    text={'Address'}
                    value={address.address}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Stack>
          ))
        ) : (
          <TeleAgentTextWrapper text="Address" value={'--'} loading={loading} />
        )}
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default AddressDetails;
