import { CircularProgress, Stack } from '@mui/material';

interface IProps {
  height?: number | string;
}

function LoadingPage({ height = 'auto' }: IProps) {
  return (
    <>
      <Stack
        sx={{
          height: height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    </>
  );
}

export default LoadingPage;
