/* eslint-disable no-unused-vars */
import { Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ExpenseService } from '../../Apis/ExpenseService';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  ExpenseClaim,
  ExpenseClaimAction,
  ExpenseClaimAudit,
  ExpenseReportViewModel,
} from '../../types/claim';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import VegaDialogItem from '../common/VegaDialogItem';
import { AttachmentIcon } from '../Icons/Icons';
import VegaDialogContent from '../common/VegaDialogContent';
import { useClientAuth } from '../../providers/ClientProvider';

type Props = {
  show: boolean;
  onClose: () => void;
  claim?: ExpenseClaim;
  onReapplyClick: (claim: ExpenseClaim) => void;
  onAttachmentClick: (claim: ExpenseClaim) => void;
};

function ClaimDetailsDialog({
  show,
  onClose,
  claim,
  onAttachmentClick,
  onReapplyClick,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const { fetchReport: fetchReportById } = useExpenseApi();
  const [loadingAudits, setLoadingAudits] = useState<boolean>(false);
  const [latestAudit, setLatestAudit] = useState<ExpenseClaimAudit | null>(
    null
  );
  const [report, setReport] = useState<ExpenseReportViewModel | null>(null);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const { userId: loggedInUserId } = useClientAuth();

  async function fetchReport(reportId: string) {
    try {
      setLoadingReport(true);
      const response = await fetchReportById({
        reportId: reportId,
        includeApprover: true,
      });
      setReport(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingReport(false);
    }
  }

  async function fetchAudits(claimId: string) {
    try {
      setLoadingAudits(true);
      const response = await ExpenseService.getClaimAudits({
        claimId: claimId,
      });
      const audits = response.records;
      setLatestAudit(audits[audits.length - 1]);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingAudits(false);
    }
  }

  function closeDialog() {
    setLatestAudit(null);
    setReport(null);
    onClose();
  }

  function onReapply() {
    if (!claim) return;
    setLatestAudit(null);
    onReapplyClick(claim);
  }

  const getReportApproverName = () => {
    const approver = report?.approver;
    return approver;
    // StringUtility.concatenateStrings(
    //   approver?.firstName,
    //   approver?.middleName,
    //   approver?.lastName
    // );
  };

  const canShowReapplyButton = useMemo(() => {
    const isRejected = claim?.status == ExpenseClaimAction.REJECTED;
    const currentUserRaisedClaim = claim?.userId === loggedInUserId;
    return isRejected && currentUserRaisedClaim;
  }, [claim]);

  useEffect(() => {
    if (show == true && claim != null) {
      fetchAudits(claim.id);
      fetchReport(claim.reportId);
    }
  }, [show, claim]);

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={show}
      isCloseIcon
      handleClose={closeDialog}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="View Claim Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Stack gap={'0.5rem'}>
          <VegaDialogItem heading={'Expense Type'} value={claim?.expenseType} />
          <VegaDialogItem
            heading="Timestamp"
            value={DateUtility.formatStringToDDMMYYYY(claim?.createdAt)}
          />
          <VegaDialogItem heading="Total Amount" value={claim?.amount} />
          <VegaDialogItem
            heading="Expenditure Date"
            value={DateUtility.formatStringToDDMMYYYY(claim?.dateOfExpense)}
          />
          <VegaDialogItem
            heading="Status"
            renderValue={() => {
              const color = getColorForStatus(claim?.status);
              return (
                <VegaText
                  text={claim?.status ?? ''}
                  fontWeight={500}
                  fontSize={'0.8125rem'}
                  color={color?.DARK}
                />
              );
            }}
          />
          <VegaDialogItem
            heading="Document"
            renderValue={() => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!claim) return;
                    onAttachmentClick(claim);
                  }}
                >
                  <AttachmentIcon />
                  <VegaText
                    text={'Attachment'}
                    fontWeight={500}
                    color={'#1047DC'}
                    fontSize={'0.875rem'}
                  />
                </div>
              );
            }}
          />
          <VegaDialogItem
            heading="Approver Name"
            value={getReportApproverName()}
            loading={loadingReport}
          />
          <div
            style={{
              display:
                claim?.status == ExpenseClaimAction.REJECTED
                  ? 'default'
                  : 'none',
            }}
          >
            <VegaDialogItem
              heading="Reason"
              value={latestAudit?.actionDetails}
              loading={loadingAudits}
            />
          </div>
          <div
            style={{
              display: canShowReapplyButton == true ? 'default' : 'none',
            }}
          >
            <LmButton text={'Reapply'} onClick={onReapply} />
          </div>
        </Stack>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default ClaimDetailsDialog;

const getColorForStatus = (status?: ExpenseClaimAction) => {
  if (!status) return;
  switch (status) {
    case ExpenseClaimAction.ACCEPTED:
      return COLOR.GREEN;
    case ExpenseClaimAction.PENDING:
      return COLOR.ORANGE;
    case ExpenseClaimAction.REJECTED:
      return COLOR.RED;
  }
};
