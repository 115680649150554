import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AnaltyticsService } from '../../../Apis/AnaltyticsService';
import { LoanService } from '../../../Apis/LoanServices';
import LmDialog from '../../../components/common/LmDialog';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaText from '../../../components/common/VegaText';
import { AnalyticsQueryTypeEnum } from '../../../features/analyticsDashboardSlice';
import { useClientAuth } from '../../../providers/ClientProvider';
import { LmsVariable, LoanData } from '../../../types/loan';
import { AnalyticsQueryRequest } from '../../../types/request/analyticsRequest';
import { LoanListRequest } from '../../../types/request/loan';
import {
  Channel,
  CommunicationFilterType,
  ComunicationDetailsType,
} from '../CommunicationDetails';

type Props = {
  open: boolean;
  onClose: () => void;
  channel?: Channel;
  filterData: Partial<CommunicationFilterType>;
};

function CommunicationLoanDetails({
  open,
  onClose,
  channel,
  filterData,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [loanDetails, setLoanDetails] = useState<LoanData[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  async function getLoanListDeatils(loanIds: string[]) {
    try {
      setLoading(true);
      const request = {
        page,
        size: 10,
        loanIdList: loanIds,
        includeCustomerDetails: true,
        includeMetaDataLoanAllocation: true,
        includeLoanOutstandingDetails: true,
        restrictedView: true,
      } as LoanListRequest;

      const response = await LoanService.getLoanList(request);
      setLoanDetails(response.records);
      setTotalItems(response.totalItems);
    } finally {
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const request = {
        params: { ...filterData, actionType: mapChannel(channel) },
        analyticsQueryType: AnalyticsQueryTypeEnum.COMMUNICATION_DETAILS,
      } as unknown as AnalyticsQueryRequest;
      const response: ComunicationDetailsType[] =
        await AnaltyticsService.getAnalyticsQueries(request);
      const loandIds: string[] = [];
      response.forEach(i => {
        i.loanIds.forEach(loanId => {
          loandIds.push(loanId);
        });
      });
      if (loandIds.length > 0) {
        getLoanListDeatils(loandIds);
      }
    } finally {
      setLoading(false);
    }
  }

  function mapChannel(actionType: string) {
    switch (actionType) {
      case Channel.EMAIL:
        return 'EMAIL_SENT';
      case Channel.SMS:
        return 'SMS_SENT';
      case Channel.WHATSAPP:
        return 'WHATSAPP_SENT';
      case Channel.IVR:
        return 'IVR_SENT';
      case Channel.PUSH:
        return 'EMAIL_SENT';
    }
  }

  function cleanup() {
    setLoanDetails([]);
    setPage(0);
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  useEffect(() => {
    if (open && channel) {
      fetchData();
    }
  }, [open, page, channel]);

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      isCloseIcon
      open={open}
      handleClose={handleClose}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text={channel + ' Accounts'}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaPageContent sx={{ marginTop: 4, marginBottom: 4 }}>
        <VegaDataGrid
          data={loanDetails}
          loading={loading}
          columns={ColumnDef()}
          idColumn={'loanId'}
          page={page}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
        />
      </VegaPageContent>
    </LmDialog>
  );
}

export default CommunicationLoanDetails;

function ColumnDef() {
  const { agentIdToManager, userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const getManagerName = (userId: string) => {
    if (!userId) return 'NA';
    const manager = agentIdToManager(userId);
    if (!manager) return 'NA';
    return manager;
  };

  const COL_DEF: GridColumns = [
    {
      field: 'loanId',
      headerName: 'Loan Account Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const userName = loan?.lmsVariables?.loanId
          ? loan?.lmsVariables?.loanId
          : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const userName = loan?.lmsVariables?.customerName
          ? loan?.lmsVariables?.customerName
          : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const customerDetails = getFirstItemIfExists(loan?.customerDetails);
        const display = customerDetails ? customerDetails.mobileNo : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'address',
      headerName: 'Customer Address',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const customerDetails = getFirstItemIfExists(loan?.customerDetails);
        const display = customerDetails ? customerDetails.customerAddress : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'managerName',
      headerName: 'Manager Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const lmsVariable = loan.lmsVariables as LmsVariable;
        let displayText = '';
        if (lmsVariable.agentId) {
          displayText = `${getManagerName(lmsVariable.agentId)}`;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentName',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? getUserName(loan.lmsVariables.agentId) : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'dpd',
      headerName: 'DPD',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.dpd : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'overdueAmount',
      headerName: 'Overdue Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.overdueAmount : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.bucket : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'cycleDate',
      headerName: 'Cycle Date',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.cycleDate : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'branchCode',
      headerName: 'Branch Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.branchCode : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'modelPrediction',
      headerName: 'Model prediction',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan
          ? loan.lmsVariables?.modelParserAllocationType
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.lmsVariables.segment : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan.loanMaster.loanProduct : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
}
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
