import { Box, Stack } from '@mui/material';
import { RupeeIcon } from '../../../../components/Icons/Icons';
import LmButton from '../../../../components/common/LmButton';
import LmDialog from '../../../../components/common/LmDialog';
import VegaText from '../../../../components/common/VegaText';
import LmTextField from '../../../../components/common/LmTextField';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { GREY } from '../../../../constants/style';
import {
  getPropertiesState,
  setOpenMinRaiseModal,
  setPropertyDetails,
} from '../../../../features/propertiesSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useUpdateAuctionPropertiseMutation } from '../../../../services/properties';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { PropertiesViewModel } from '../../../../types/properties';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useEffect } from 'react';

type Props = {
  onUpdate: () => void;
};
const MinRaiseModal = ({ onUpdate }: Props) => {
  const { setSnackbar } = useSnackbar();
  const { openMinRaiseModal, propertyDetails } =
    useAppSelector(getPropertiesState);
  const dispatch = useAppDispatch();

  const [updateAuction, { error, isError, isSuccess, isLoading }] =
    useUpdateAuctionPropertiseMutation();

  const handleClose = () => {
    dispatch(setOpenMinRaiseModal(false));
  };

  const updateMinRaise = async () => {
    const body = {
      id: propertyDetails.auctionId,
      minIncrement: propertyDetails.minIncrement,
    };
    await updateAuction(body);
    onUpdate();
  };

  useEffect(() => {
    if (isSuccess) {
      setSnackbar('Update min raise Successfully');
    }
    if (isError) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [isError, isSuccess]);
  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={openMinRaiseModal}
      isCloseIcon
      handleClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '412px',
        },
      }}
      buttonComponent={() => (
        <>
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <LmButton
              text="Cancel"
              fullWidth
              variant="outlined"
              bgColor=""
              textColor={GREY.dark}
              onClick={() => {
                handleClose();
              }}
            />
            <LmButton
              fullWidth
              text="Confirm"
              loading={isLoading}
              disabled={propertyDetails.minIncrement === null}
              // startIcon={<EditIcon strokeColor="white" />}
              onClick={() => {
                updateMinRaise();
                handleClose();
              }}
            />
          </Stack>
        </>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Min Raise"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <Box px={4} pt={3} pb={4}>
        <VegaFormInputField label="Min Raise">
          <LmTextField
            startAdornment={<RupeeIcon />}
            placeholder="Enter Amount"
            type="number"
            value={propertyDetails?.minIncrement}
            onChange={e => {
              const value = e.target.value && parseInt(e.target.value);
              if (typeof value === 'number') {
                const newObj = {
                  ...propertyDetails,
                  minIncrement: value,
                } as PropertiesViewModel;
                dispatch(setPropertyDetails(newObj));
              } else {
                const newObj = {
                  ...propertyDetails,
                  minIncrement: null,
                } as PropertiesViewModel;
                dispatch(setPropertyDetails(newObj));
              }
            }}
          />
        </VegaFormInputField>
      </Box>
    </LmDialog>
  );
};

export default MinRaiseModal;
