import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BORDER_COLLECTION, COMMON } from '../../../constants/style';
import { getIncentivesGamificationsState } from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { ROUTES } from '../../../router/routes';
import { useAppSelector } from '../../../store';
import VegaButton from '../../common/VegaButton';
import VegaText from '../../common/VegaText';

const PolicyCard = () => {
  const { policies } = useAppSelector(getIncentivesGamificationsState);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        borderRadius: '0.5rem',
        border: BORDER_COLLECTION,
        p: '1.5rem',
        bgcolor: 'white',
      }}
    >
      <Stack direction={'row'} gap={1}>
        <VegaText text={'Policy'} fontWeight={600} fontSize={'1rem'} />
      </Stack>

      <Stack mt={'1rem'} gap={0}>
        <VegaText
          color={COMMON.balck}
          fontWeight={500}
          fontSize={14}
          text={'Incentive Policy Update '}
        />
        <VegaText
          color={'#676B76'}
          fontWeight={400}
          fontSize={12}
          text={
            'We have updated our incentive policy, have a look  to find out'
          }
          sx={{
            lineHeight: '15.6px',
          }}
        />
      </Stack>
      <Box textAlign={'end'} mt={'1rem'}>
        <VegaButton
          text="View More"
          onClick={() =>
            navigate(
              ROUTES.INCENTIVES_GAMIFICATIONS_VIEW.replace(
                ':policyId',
                policies[0]?.id
              )
            )
          }
        />
      </Box>
    </Box>
  );
};

export default PolicyCard;
