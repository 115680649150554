import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import ListOfRoleAccess from './modules/ListOfRoleAccess';
import ListOfUsers from './modules/ListOfUsers';

export enum ScreenOpenType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  VIEW = 'VIEW',
}

const UAM = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        title="User Access Management"
      />
      <VegaPageHeader
        sx={{ paddingTop: 0, paddingBottom: 0, minHeight: 'auto !important' }}
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedTab}
              onChange={(e, selected) => setSelectedTab(selected)}
            >
              <VegaTabBarItem label={`User List`} />
              <VegaTabBarItem label={`Role Access List`} />
            </VegaTabBar>
          );
        }}
      />

      {selectedTab === 0 ? <ListOfUsers /> : <ListOfRoleAccess />}
    </>
  );
};

export default UAM;
