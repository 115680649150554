import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { BORDER_COLLECTION } from '../../constants/style';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import LocationTrackingColumn from './LocationTrackingColumn';
import { VisitService } from '../../Apis/VisitService';
import { useClientAuth } from '../../providers/ClientProvider';
import { DateUtility } from '../../utils/DateUtlility';
import {
  DownloadMinVisitRequest,
  ListTrackingForAgentsRequestDto,
  TrackingResponseDto,
} from '../../types/request/visit';
import { StorageKeys, StorageUtility } from '../../utils/StorageUtility';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { AllocationService } from '../../Apis/AllocationService';
import { LongRunningTaskStatus } from '../../types/allocations';

export type LocationTrackingDetailType = {
  agentName: string;
  employeeCode: string;
  noOfVisits: number;
  minNoOfVisits: number;
  loanIds: string[];
};

const getMinVisitDownloadRequestId = StorageUtility.getItem<string>(
  StorageKeys.DOWNLOAD_MIN_VISIT_REQUEST_ID
)
  ? StorageUtility.getItem<string>(StorageKeys.DOWNLOAD_MIN_VISIT_REQUEST_ID)
  : '';

const TODAY = new Date().toISOString();
function LocationTracking() {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const [scheduledDate, setScheduledDate] = useState(TODAY);
  const [searchAgentName, setSearchAgentName] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [visitTrackingList, setVisitTrackingList] = useState<
    TrackingResponseDto[]
  >([]);
  const [minVisitRequestId, setMinVisitRequestId] = useState<string>(
    getMinVisitDownloadRequestId
  );
  const [isDownloadMinVisitInProgress, setIsDownloadMinVisitInProgress] =
    useState<boolean>();
  async function getAgentLocationTackingList() {
    try {
      const requestBody: Partial<ListTrackingForAgentsRequestDto> = {
        userId: user.id,
        visitedOn: scheduledDate,
      };
      if (searchAgentName) {
        requestBody.agentName = searchAgentName;
      }
      setLoading(true);
      const visitTrackingListData =
        await VisitService.getVisitLocationTrackingList(requestBody);
      setVisitTrackingList(visitTrackingListData);
      setLoading(false);
    } catch (error) {}
  }

  const onDownloadClick = async () => {
    setIsDownloadMinVisitInProgress(true);
    try {
      const request: Partial<DownloadMinVisitRequest> = {
        userId: user.id,
        visitedOn: scheduledDate,
      };
      if (searchAgentName) {
        request.agentName = searchAgentName;
      }
      const response = await VisitService.downloadMinVisitList(request);
      if (response.status === 'COMPLETED') {
        setIsDownloadMinVisitInProgress(false);
        StorageUtility.removeItem(StorageKeys.DOWNLOAD_MIN_VISIT_REQUEST_ID);
        setMinVisitRequestId('');
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
      } else {
        StorageUtility.setItem(
          StorageKeys.DOWNLOAD_MIN_VISIT_REQUEST_ID,
          response.id
        );
        setMinVisitRequestId(response.id);
        setIsDownloadMinVisitInProgress(true);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getDownloadFileStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsDownloadMinVisitInProgress(false);
        StorageUtility.removeItem(StorageKeys.DOWNLOAD_MIN_VISIT_REQUEST_ID);
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
        setMinVisitRequestId('');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsDownloadMinVisitInProgress(false);
        StorageUtility.removeItem(StorageKeys.DOWNLOAD_MIN_VISIT_REQUEST_ID);
        setSnackbar(response.result, 'error');
        setMinVisitRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const handleChange = (value: string) => {
    const futureDateString = DateUtility.parseDateFromDatePicker(value);
    setScheduledDate(futureDateString.toISOString());
  };

  useEffect(() => {
    if (searchAgentName?.length) {
      const getData = setTimeout(getAgentLocationTackingList, 300);
      return () => clearTimeout(getData);
    } else {
      getAgentLocationTackingList();
    }
  }, [searchAgentName, scheduledDate]);

  useEffect(() => {
    if (minVisitRequestId.length) {
      const downloadingFile = setInterval(() => {
        getDownloadFileStatus(minVisitRequestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [minVisitRequestId]);

  return (
    <>
      <VegaPageHeader
        title="Location Tracking"
        sx={{ borderBottom: 'none' }}
        renderRightView={() => (
          <VegaButton
            text={'Download'}
            onClick={onDownloadClick}
            loading={isDownloadMinVisitInProgress}
          />
        )}
      />
      <VegaPageHeader
        sx={{
          border: 'none',
          marginBottom: '1rem',
        }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{
                border: BORDER_COLLECTION,
                borderRadius: '12px',
                p: 3,
                justifyContent: 'space-between',
              }}
              direction={'row'}
            >
              <Grid
                container
                direction={'row'}
                gap={1}
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaDatePicker
                    value={scheduledDate}
                    onChange={date => {
                      handleChange(date);
                    }}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <LmFilterSection
                    hideFilter
                    search={searchAgentName}
                    setSearch={e => {
                      setSearchAgentName(e);
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          page={page}
          pageSize={size}
          rowCount={visitTrackingList?.length}
          onPageChange={page => {
            setPage(page);
          }}
          onPageSizeChange={size => setSize(size)}
          paginationMode="server"
          data={visitTrackingList}
          columns={LocationTrackingColumn({ selectedDate: scheduledDate })}
          loading={loading}
          idColumn="employeeCode"
        />
      </VegaPageContent>
    </>
  );
}

export default LocationTracking;
