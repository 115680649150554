import { useEffect, useState } from 'react';
import { fetchFeedbackList } from '../../../actions/TeleAgentAction';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import { teleAgentAuditSelector } from '../../../features/teleAgentAuditSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import FeedbackColumn from './columns/FeedbackColumn';

const ListOfFeedback = () => {
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { feedbacks, loading, totalItems, error, callDispose } = useAppSelector(
    teleAgentAuditSelector
  );
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    if (error.length) {
      setSnackbar(error, 'error');
    }
  }, [error]);
  useEffect(() => {
    dispatch(
      fetchFeedbackList({ page, size, callDisposeEventId: callDispose.id })
    );
  }, [page, size]);
  return (
    <VegaDataGrid
      bgColor="transperent"
      page={page}
      pageSize={size}
      rowCount={totalItems}
      paginationMode="server"
      onPageChange={page => {
        setPage(page);
      }}
      onPageSizeChange={size => setSize(size)}
      data={feedbacks}
      columns={FeedbackColumn()}
      loading={loading}
      idColumn="id"
    />
  );
};

export default ListOfFeedback;
