import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ConfigurationServices } from '../../../Apis/ConfigurationServices';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { VegaSwitch } from '../../../components/common/VegaSwitch';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  fetchConfigListSlice,
  getConfigurationState,
  setConfigActive,
  setConfigSearch,
  setConfigSearchBy,
  setConfigStatus,
  setConfigs,
} from '../../../features/configurationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ConfigurationType,
  ConfigurationsListProps,
} from '../../../types/configurationType';
import {
  ConfigListRequest,
  UpdateStatusConfigRequest,
} from '../../../types/request/cofigurationRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { ConfigurationColumn } from '../components/column/ConfigurationColumn';

interface IProps {
  editConfig: (config: ConfigurationsListProps) => void;
}

export enum MinMaxSearchFilterType {
  ALL = 'ALL',
  BUCKET = 'BUCKET',
  REGION = 'REGION',
  BRANCH = 'BRANCH',
  ROLE = 'ROLE',
}
export enum NcmDpdSearchFilterType {
  ALL = 'ALL',
  NCM_DPD_240_PLUS = 'NCM_DPD_240_PLUS',
  NCM_DPD_240_MINUS = 'NCM_DPD_240_MINUS',
}
export enum ACRSearchFilterType {
  ALL = 'ALL',
  FIELD_ALLOCATIONS = 'FIELD_ALLOCATIONS',
  TELE_ALLOCATIONS = 'TELE_ALLOCATIONS',
}

const ListOfConfiguration = ({ editConfig }: IProps) => {
  const {
    configs,
    selectedConfigurationsTab,
    loading,
    totalItems,
    configActive,
    configSearch,
    configSearchBy,
    configStatus,
  } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const updateStatusForConfig = async (
    config: ConfigurationsListProps,
    status: string
  ) => {
    const request = {
      ids: [config.id],
      status,
    } as UpdateStatusConfigRequest;
    try {
      await ConfigurationServices.updateStatusOfConfig(request);
      const preConfig = [...configs];
      const updatedStatusConfig = preConfig.map(
        (preConfig: ConfigurationsListProps) => {
          if (preConfig.id === config.id) {
            return {
              ...preConfig,
              status,
            };
          } else {
            return { ...preConfig };
          }
        }
      );
      dispatch(setConfigs(updatedStatusConfig));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getConfigList = () => {
    const request = {
      page,
      size,
      names: [getKeyFromTab(selectedConfigurationsTab)],
    } as ConfigListRequest;
    if (configStatus.length) {
      request.status = configStatus;
    }
    if (configSearch.length) {
      if (
        getKeyFromTab(selectedConfigurationsTab) ===
          ConfigurationType.NCM_DPD ||
        getKeyFromTab(selectedConfigurationsTab) ===
          ConfigurationType.MIN_MAX_VISITS
      ) {
        request.subtypes = [configSearch];
      }
    }
    if (configSearchBy.length && configSearchBy !== 'ALL') {
      if (
        getKeyFromTab(selectedConfigurationsTab) ===
          ConfigurationType.NCM_DPD ||
        getKeyFromTab(selectedConfigurationsTab) ===
          ConfigurationType.MIN_MAX_VISITS
      ) {
        request.types = [configSearchBy];
      }
    }
    dispatch(fetchConfigListSlice(request));
  };
  useEffect(() => {
    if (configSearch.length || configStatus.length) {
      const configList = setTimeout(() => {
        getConfigList();
      }, 300);
      return () => clearTimeout(configList);
    } else {
      getConfigList();
    }
  }, [
    selectedConfigurationsTab,
    page,
    size,
    configSearch,
    configStatus,
    configSearchBy,
  ]);

  useEffect(() => {
    dispatch(setConfigSearch(''));
    dispatch(setConfigStatus(''));
    dispatch(setConfigActive(false));
    if (
      getKeyFromTab(selectedConfigurationsTab) === ConfigurationType.NCM_DPD
    ) {
      dispatch(setConfigSearchBy(NcmDpdSearchFilterType.ALL));
    } else if (
      getKeyFromTab(selectedConfigurationsTab) ===
      ConfigurationType.MIN_MAX_VISITS
    ) {
      dispatch(setConfigSearchBy(MinMaxSearchFilterType.ALL));
    }
  }, [selectedConfigurationsTab]);

  return (
    <>
      <VegaPageHeader
        sx={{ border: 'none', bgcolor: 'transparent' }}
        title={toLowerCase(getKeyFromTab(selectedConfigurationsTab))}
        renderRightView={() => {
          return (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={1}
                sx={{
                  height: '35px',
                }}
              >
                <Stack direction={'row'} gap={1}>
                  <VegaText text="Inactive" />
                  <VegaSwitch
                    checked={configActive}
                    onChange={e => {
                      const isActive = e.target.checked;
                      dispatch(setConfigActive(isActive));
                      if (isActive) {
                        dispatch(setConfigStatus('ACTIVE'));
                      } else {
                        dispatch(setConfigStatus('INACTIVE'));
                      }
                    }}
                  />
                  <VegaText text="Active" />
                </Stack>
                {(getKeyFromTab(selectedConfigurationsTab) ===
                  ConfigurationType.NCM_DPD ||
                  getKeyFromTab(selectedConfigurationsTab) ===
                    ConfigurationType.MIN_MAX_VISITS) && (
                  <LmFilterSection
                    hideFilter
                    search={configSearch}
                    setSearch={value => dispatch(setConfigSearch(value))}
                    serachByComponent={
                      <>
                        {getKeyFromTab(selectedConfigurationsTab) ===
                          ConfigurationType.NCM_DPD ||
                        getKeyFromTab(selectedConfigurationsTab) ===
                          ConfigurationType.MIN_MAX_VISITS ? (
                          <Box
                            sx={{
                              width: 150,
                            }}
                          >
                            <VegaSelect
                              options={getSearchByOptions(
                                getKeyFromTab(selectedConfigurationsTab)
                              )}
                              value={configSearchBy}
                              onChange={selected => {
                                const value = selected.target.value as string;
                                dispatch(setConfigSearchBy(value));
                                dispatch(setConfigSearch(''));
                                setPage(0);
                              }}
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                )}
              </Stack>
            </Stack>
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={configs}
          columns={ConfigurationColumn({ updateStatusForConfig, editConfig })}
          idColumn="id"
          page={page}
          pageSize={size}
          rowCount={totalItems}
          loading={loading}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfConfiguration;

const getKeyFromTab = (tab: number) => {
  const value = Object.values(ConfigurationType)[tab];

  return value;
};

const getSearchByOptions = (tab: string) => {
  if (tab === ConfigurationType.NCM_DPD) {
    const options = Object.keys(NcmDpdSearchFilterType).map(
      search =>
        ({
          label: toLowerCase(search),
          value: search,
        } as VegaSelectOption)
    );
    return options;
  } else if (tab === ConfigurationType.ALLOCATION_ACR) {
    const options = Object.keys(ACRSearchFilterType).map(
      search =>
        ({
          label: toLowerCase(search),
          value: search,
        } as VegaSelectOption)
    );
    return options;
  } else {
    const options = Object.keys(MinMaxSearchFilterType).map(
      search =>
        ({
          label: toLowerCase(search),
          value: search,
        } as VegaSelectOption)
    );
    return options;
  }
};
