import { Box } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrawer } from '../../hooks/useDrawer';
import { AppDispatch, RootState, useAppSelector } from '../../store';
import { COLOR } from '../../utils/ColorUtility';
import { CheckBrokerIcon, CrossIcon } from '../Icons/Icons';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import AcceptLeaveRequestDialog from './AcceptLeaveRequestDialog';
import RejectLeaveRequestDialog from './RejectLeaveRequestDialog';

import { LeaveService } from '../../Apis/LeaveService';
import {
  fetchLeaveList,
  leaveRequestSelector,
} from '../../features/leaveRequestSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';
import {
  GetLeaveListRequest,
  UpdateLeaveStatus,
} from '../../types/request/leave';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmFilterSection from '../common/LmFilterSection';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaDrawer from '../common/VegaDrawer';
import FilterInput, {
  DateRangeFilter,
  FilterOption,
} from '../ExpenseClaim/FilterInput';
import { useClientAuth } from '../../providers/ClientProvider';

type props = {
  tabHeading: string;
};
const PendingLeaveRequest = ({ tabHeading }: props) => {
  const { setSnackbar } = useSnackbar();
  const {
    page: currentPage,
    totalItems,
    loading: isLoadingData,
  } = useAppSelector(leaveRequestSelector);
  const { user } = useClientAuth();

  const [page, setPage] = useState<number>(0);
  const [dayFilter, setDayFilter] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const dispatch = useDispatch<AppDispatch>();
  const leaveData = useSelector<RootState, LeaveViewModel[]>(
    state => state.leaveRequestAction.leaves
  );

  function onPageChange(pageNumber: number) {
    setPage(pageNumber);
  }

  const {
    open: openRejectLeaveDialog,
    close: closeRejectLeaveDialog,
    isOpen: isRejectLeaveDialogOpen,
    props: rejectLeaveDialogProps,
  } = useDrawer(false);
  const {
    open: openAcceptLeaveDialog,
    close: closeAcceptLeaveDialog,
    isOpen: isAcceptLeaveDialogOpen,
    props: acceptLeaveDialogProps,
  } = useDrawer(false);

  const onUpdateLeaveStatus = (
    leaveId: string,
    leaveStatus: LeaveStatus,
    rejectionReason?: string
  ) => {
    setLoading(true);
    const request = {
      leaveId,
      leaveStatus,
    } as UpdateLeaveStatus;
    if (rejectionReason && rejectionReason.length) {
      request.rejectionReason = rejectionReason;
    }
    LeaveService.updatePendingLeave(request)
      .then(() => {
        if (rejectionReason?.length) {
          setSnackbar('Rejected Successfully !!');
        } else {
          setSnackbar('Approved Successfully !!');
        }

        dispatch(
          fetchLeaveList({
            approverId: user.id,
            status: [LeaveStatus.APPLIED],
            page: page,
            size: 10,
          })
        );
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onClearAllClick() {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter([]);
    closeFilterDrawer();
    getLeaveList();
  }

  function onSelectFilterClick(
    statusFilter: string[],
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);

    const request = {
      approverId: user.id,
      status: [LeaveStatus.APPLIED],
      page: page,
      size: 10,
    } as GetLeaveListRequest;
    if (dateRangeFilter.startDate.length && dateRangeFilter.endDate.length) {
      request.endDate = dateRangeFilter.endDate;
      request.startDate = dateRangeFilter.startDate;
    }
    dispatch(fetchLeaveList(request));
  }

  const getLeaveList = async () => {
    try {
      setLoading(true);
      dispatch(
        fetchLeaveList({
          approverId: user.id,
          status: [LeaveStatus.APPLIED],
          page: page,
          size: 10,
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setLoading(true);
      getLeaveList();
    }
  }, [page, user]);

  return (
    <>
      <VegaPageHeader
        title={tabHeading}
        renderRightView={() => {
          return <LmFilterSection onFilter={() => openFilterDrawer()} />;
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaPageContent>
        <VegaDataGrid
          page={currentPage}
          pageSize={10}
          loading={isLoadingData}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={leaveData}
          columns={getColumnDefinition({
            onAcceptLeaveClick: (leave: LeaveViewModel) => {
              openAcceptLeaveDialog({ leave: leave });
            },
            onRejectLeaveClick: (leave: LeaveViewModel) => {
              openRejectLeaveDialog({ leave: leave });
            },
          })}
          idColumn="id"
        />
      </VegaPageContent>
      <RejectLeaveRequestDialog
        show={isRejectLeaveDialogOpen}
        leave={rejectLeaveDialogProps.leave}
        onClose={function (): void {
          closeRejectLeaveDialog();
        }}
        onReject={function (leave: LeaveViewModel, reason: string): void {
          onUpdateLeaveStatus(leave?.id, LeaveStatus.REJECTED, reason);
        }}
      />
      <AcceptLeaveRequestDialog
        leave={acceptLeaveDialogProps.leave}
        show={isAcceptLeaveDialogOpen}
        onAcceptClick={leave => {
          onUpdateLeaveStatus(leave?.id, LeaveStatus.APPROVED);
        }}
        onClose={function (): void {
          closeAcceptLeaveDialog();
        }}
      />
      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <FilterInput
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter]}
        />
      </VegaDrawer>
    </>
  );
};

const getColumnDefinition = (data: {
  onAcceptLeaveClick: (leave: LeaveViewModel) => void;
  onRejectLeaveClick: (leave: LeaveViewModel) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'AgentName',
      headerName: 'Agent Name',
      flex: 0.2,
      minWidth: 130,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.username))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'TypeofLeave',
      headerName: 'Type Of Leave',
      flex: 0.3,
      minWidth: 128,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.typesofLeave))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      flex: 0.3,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={DateUtility.formatStringToDDMMYYYY(Leave.startDate)}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.startDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      flex: 0.3,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={DateUtility.formatStringToDDMMYYYY(Leave.endDate)}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.endDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'Reason',
      headerName: 'Reason/Remark',
      flex: 0.6,
      minWidth: 411,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.reason))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },

    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 207,

      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <>
            <Box pr={2}>
              <VegaButton
                startIcon={<CheckBrokerIcon strokeColor="#318439" />}
                text="Approve"
                onClick={() => {
                  data.onAcceptLeaveClick(Leave);
                }}
                sx={{
                  backgroundColor: COLOR.GREEN.LIGHT,
                  color: COLOR.GREEN.DARK,
                  ':hover': {
                    backgroundColor: COLOR.GREEN.LIGHT,
                    color: COLOR.GREEN.DARK,
                  },
                }}
              />
            </Box>
            <VegaButton
              startIcon={<CrossIcon />}
              text="Reject"
              onClick={() => {
                data.onRejectLeaveClick(Leave);
              }}
              sx={{
                backgroundColor: COLOR.RED.LIGHT,
                color: COLOR.RED.DARK,
                ':hover': {
                  backgroundColor: COLOR.RED.LIGHT,
                  color: COLOR.RED.DARK,
                },
              }}
            />
          </>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

export default PendingLeaveRequest;
