import { Stack, Grid } from '@mui/material';
import { useOts } from '../../../../providers/OtsProvider';
import { StringUtility } from '../../../../utils/StringUtility';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';

import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsOutstandingDetails() {
  const { loading, loan } = useOts();

  const getNoOfEmis = () => {
    const balTenor = loan?.lmsVariables?.balanceTenor ?? 0;
    const overduePrincipal = loan?.lmsVariables?.principalDue ?? 0;
    const installment = loan?.lmsVariables?.installment ?? 0;
    return (balTenor + overduePrincipal) / installment;
  };

  return (
    <OtsDetailsWrapper text="Outstanding Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Total Outstanding Amount"
              value={StringUtility.formatToINR(
                loan?.lmsVariables?.outStandingTotal ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Current Principal Outstanding"
              value={StringUtility.formatToINR(
                loan?.loanOutstandingDetails?.principalOutstanding ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Interest Outstanding"
              value={loan?.lmsVariables?.interestDue ?? 0}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Total Overdue Amount"
              value={StringUtility.formatToINR(
                loan?.lmsVariables?.overdueAmount ?? 0
              )}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Other Charges"
              value={StringUtility.formatToINR(
                loan?.lmsVariables?.chargeDue ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="No of Emi's Outstanding"
              value={'Na'}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsOutstandingDetails;
