import React from 'react';
import LmDialog from '../../../components/common/LmDialog';
import {
  getTargetState,
  setViewTargetOpenDialog,
} from '../../../features/targetSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { Box, Grid, Stack } from '@mui/material';
import VegaText from '../../../components/common/VegaText';
import { COMMON } from '../../../constants/style';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import { TargetByBucketsProps } from '../../../types/targets';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import TargetCard from './TargetCard';
import TargetBucketStatusComponent from './TargetBucketStatusComponent';
import NoResults from '../../../components/common/NoResults';

interface IProps {
  selectedTab: number;
}

const ViewTargetDialog = ({ selectedTab }: IProps) => {
  const { viewTargetOpenDialog, bucketDetails } =
    useAppSelector(getTargetState);
  const dispatch = useAppDispatch();
  return (
    <>
      <LmDialog
        fullWidth
        maxWidth="md"
        isCloseIcon
        open={viewTargetOpenDialog}
        handleClose={() => dispatch(setViewTargetOpenDialog(false))}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="View Target"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={COMMON.balck}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <TargetCard>
            <>
              {selectedTab === 1 && (
                <Box>
                  <TargetBucketStatusComponent
                    status={bucketDetails.targetStatus}
                  />
                </Box>
              )}
              <Grid container spacing={2}>
                {bucketDetails?.targetByBuckets?.length ? (
                  bucketDetails.targetByBuckets.map(
                    (targetBucket: TargetByBucketsProps, index: number) => (
                      <>
                        <Grid item xs={12} sm={12} md={6} key={targetBucket.id}>
                          <Box
                            sx={{
                              ...(bucketDetails.targetByBuckets.length !==
                                index + 1 && {
                                borderBottom: '1px solid #E1E4EB',
                              }),
                              pb: '0.5rem',
                            }}
                          >
                            <Stack gap={2}>
                              <VegaText
                                text={targetBucket.bucket}
                                fontWeight={600}
                                fontSize={'0.875rem'}
                                color={COMMON.balck}
                              />
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                  <VegaFormInputField label="POS (in INR cr.)">
                                    <VegaText text={targetBucket.posValue} />
                                  </VegaFormInputField>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
                                  <VegaFormInputField label="no. of cases">
                                    <VegaText text={targetBucket.noOfCases} />
                                  </VegaFormInputField>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Box>
                        </Grid>
                      </>
                    )
                  )
                ) : (
                  <NoResults sx={{ height: 'calc(100% - 142px)' }} />
                )}
                {bucketDetails?.modifyReason && (
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <Stack gap={2}>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                          <Stack rowGap={1}>
                            <>
                              <VegaText
                                text={'Modify Reason'}
                                fontWeight={600}
                                fontSize={'0.875rem'}
                                color={COMMON.balck}
                              />
                              <VegaText text={bucketDetails.modifyReason} />
                            </>
                          </Stack>
                        </Grid>
                      </Stack>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          </TargetCard>
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default ViewTargetDialog;
