import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

export interface ISeriesProps {
  name: string;
  data: number[];
}

interface IProps {
  categories: string[];
  series: ISeriesProps[];
  yAxisTitle: string;
}

const BarChart = ({ categories, yAxisTitle, series }: IProps) => {
  const finalCategories = categories.filter(category => category !== undefined);
  const finalSeries = series
    .filter(
      seriesItem =>
        seriesItem !== undefined &&
        seriesItem.data !== undefined &&
        seriesItem.data.length > 0
    )
    .map(seriesItem => ({
      ...seriesItem,
      data: seriesItem.data.filter(value => value !== undefined),
    }));
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: finalCategories ? finalCategories : ['loading'],
    },
    yaxis: {
      title: {
        text: yAxisTitle,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
  };
  return (
    <>
      <Chart
        options={options}
        series={finalSeries ? finalSeries : [{ name: '', data: [] }]}
        type="bar"
        height={350}
      />
    </>
  );
};

export default BarChart;
