import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { YearToDateBucketPerformanceResultDto } from '../../../../../types/analytics';
import {
  AnalyticsQueryTypeEnum,
  BucketEnumForYearAnalytics,
} from '../../../../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import VegaText from '../../../../../components/common/VegaText';
import LoadingPage from '../../../../LoadingPage/LoadingPage';

interface IProps {
  selectedCollectionProfitType: string;
}

const MOMResolutionGraph = ({ selectedCollectionProfitType }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    YearToDateBucketPerformanceResultDto[]
  >([]);
  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    BucketEnumForYearAnalytics.OVERALL
  );
  const currentMonth = new Date().getMonth() + 1;

  const maxNumber = Math.max(
    ...analyticsQueryResponse.map(item => item.overallResolution)
  );

  const minNumber = (): number => {
    const overallResolution = [
      ...analyticsQueryResponse.map(item => item.overallResolution),
    ];
    const nonZeroValues = overallResolution.filter(value => value !== 0);

    if (nonZeroValues.length === 0) {
      return 0;
    }

    const minValue = Math.min(...nonZeroValues);
    return minValue;
  };

  const modifyAnalyticsQuery = analyticsQueryResponse.map(item => ({
    categories: ` ${getMonthName(item.month)}- ${item.year} `,
    series: {
      resolution: item?.overallResolution,
    },
  }));

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: modifyAnalyticsQuery?.map(value => value.categories),
    },
    yaxis: {
      title: {
        text: 'In Percentage',
      },
    },
  };

  const series = [
    {
      name: '',
      data: analyticsQueryResponse.map(item => ({
        x: getMonthName(item.month),
        y: item.overallResolution,
        fillColor:
          item.month === currentMonth
            ? '#86B6F6'
            : item.overallResolution === maxNumber
            ? '#65B741'
            : item.overallResolution === minNumber()
            ? '#C70039'
            : '#dedede',
      })),
    },
  ];

  const getQueriesForEnum = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.YEAR_TO_DATE_BUCKET_LEVEL_PERFORMANCE,
        params: {
          type: selectedCollectionProfitType,
        },
      } as Partial<AnalyticsQueryRequest>;

      if (selectedMetrics !== BucketEnumForYearAnalytics.OVERALL) {
        request.params = {
          ...request.params,
          bucket: selectedMetrics,
        };
      }

      const response = await AnaltyticsService.getAnalyticsQueries(request);
      const formattedResponse = response.map(item => ({
        ...item,
        overallResolution: parseFloat(item.overallResolution.toFixed(2)),
      }));
      setAnalyticsQueryResponse(formattedResponse);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    // setAnalyticsQueryResponse(generateDummyData());
    getQueriesForEnum();
  }, [selectedMetrics, selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper
        title="Resolution"
        rightComponent={
          <Stack width={150}>
            <VegaSelect
              options={getOptions(BucketEnumForYearAnalytics)}
              value={selectedMetrics}
              onChange={select =>
                setSelectedMetrics(select.target.value as string)
              }
            />
          </Stack>
        }
      >
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -10,
                  height: 50,
                  width: '95.3%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1', textAlign: 'center' }}>
                          {getPercentageValue(val)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={series}
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default MOMResolutionGraph;
const getOptions = (type: typeof BucketEnumForYearAnalytics) => {
  const options = Object.values(type).map(
    item =>
      ({
        value: item,
        label: item,
      } as VegaSelectOption)
  );
  return options;
};
const getMonthName = (month: number): string => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[month - 1] || '';
};

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
