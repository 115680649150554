import { GetPaginatedApiRequest } from '../api';
import { NotificationChannel } from '../notification';

export interface GetNotificationListRequest extends GetPaginatedApiRequest {
  recipientUserIds: string[];
  id: string;
  idList: string[];
  templateIds: string[];
  senderUserIds: string[];
  recipients: string;
  channel: NotificationChannel;
  status: NotificationStatus;
}

export interface UpdateNotificationStatusRequest {
  id: string;
  status: NotificationStatus;
}

export enum NotificationStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}
