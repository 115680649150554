/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { AddTask, Close, VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  DateRangeFilter,
  FilterOption,
} from '../../../components/ExpenseClaim/FilterInput';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaColumnFilterSection, {
  ReceiptCancellationColumn,
} from '../../../components/common/VegaColumnFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaIconButton from '../../../components/common/VegaIconButton';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { GREEN, PRIMARY, RED } from '../../../constants/style';
import { setColumnFilters } from '../../../features/filterColumnSlice';
import {
  getReceiptCancelList,
  receiptState,
  setCancelReceiptData,
} from '../../../features/receiptManagmentSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ReceiptAction,
  ReceiptCancelListProps,
  ReceiptStatus,
} from '../../../types/receiptManagment';
import { GetReceiptsCancelListRequest } from '../../../types/request/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';
import { ApproverTabType } from '../ReceiptApprover';
import { TabType } from '../ReceiptCancellation';
import CancelReceiptDialog from './Dialog/CancelReceiptDialog';
import ReceiptCancellationApproveDialog from './Dialog/ReceiptCancellationApproveDialog';
import ViewDetailsReceiptCancellation from './Dialog/ViewDetailsReceiptCancellation';
import ReceiptFilterDrawer from './ReceiptFilterDrawer';
import ReciptFilterBy from './ReciptFilterBy';
const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

interface IProps {
  selectedTab: number;
  module: string;
}

const ListOfReceiptCancellation = ({ selectedTab, module }: IProps) => {
  const { user, userForId } = useClientAuth();
  const dispatch = useAppDispatch();
  const tabTypes = Object.values(TabType);
  const { setSnackbar } = useSnackbar();
  const {
    cancelReceiptData,
    cancelReceipts,
    loadingOfReceiptCancel,
    totalItemsOfReceiptCancel,
  } = useAppSelector(receiptState);
  const [page, setPage] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [size, setSize] = useState<number>(10);
  const [openReceipt, setOpenReceipt] = useState<boolean>(false);
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const {
    isOpen: isRejectDialogOpen,
    open: openRejectDialog,
    close: closeRejectDialog,
  } = useDrawer(false);
  const {
    isOpen: isApproveDialogOpen,
    open: openApproveDialog,
    close: closeApproveDialog,
  } = useDrawer(false);

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const key = `_${module}_
  ${selectedTab === 0 ? ApproverTabType.PENDING : ApproverTabType.HISTORY}`;
  const updateFilters = (options: string[]) => {
    dispatch(
      setColumnFilters({
        key,
        data: [...options],
      })
    );
    setSelectedOptions(options);
  };
  useEffect(() => {
    if (localStorage.getItem(key)) {
      setSelectedOptions(JSON.parse(localStorage.getItem(key)));
    } else {
      setSelectedOptions([]);
    }
  }, [key]);

  const COL_DEF: GridColumns<ReceiptCancelListProps[]> = [
    {
      field: 'receiptId',
      headerName: 'Receipt Number',
      hide: selectedOptions.includes(ReceiptCancellationColumn.RECEIPT_NUMBER),

      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptCancelListProps;
        const receiptNumber =
          receipt?.receiptNumber === null
            ? 'NA'
            : receipt?.receiptNumber?.toString();
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={receiptNumber}
          />
        );
      },
    },
    {
      field: 'requestDate',
      headerName: 'Receipt Date',
      hide: selectedOptions?.includes(ReceiptCancellationColumn.RECEIPT_DATE),

      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptCancelListProps;
        const receiptDate = DateUtility.formatStringToDDMMYYYYWithTime(
          receipt.requestDate
        );
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={receiptDate ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={receiptDate || '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'LAN',
      hide: selectedOptions.includes(ReceiptCancellationColumn.LAN),

      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'agentId',
      headerName: 'Agent Name',
      hide: selectedOptions.includes(ReceiptCancellationColumn.CUSTOMER_NAME),
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptCancelListProps;

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={getUserName(receipt.agentId)}
          />
        );
      },
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'approverId',
      headerName: 'Approver name',
      hide: selectedOptions.includes(ReceiptCancellationColumn.APPROVER_NAME),
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptCancelListProps;

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={getUserName(receipt.approverId)}
          />
        );
      },
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'agentRemark',
      headerName: 'Agent remark',
      hide: selectedOptions.includes(ReceiptCancellationColumn.AGENT_REMARK),

      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'approverRemark',
      headerName: 'Approver remark',
      hide: selectedOptions.includes(ReceiptCancellationColumn.APPROVER_REMARK),
      flex: 0.7,
      minWidth: 150,
    },

    {
      field: 'status',
      headerName: 'Status',
      hide: selectedOptions.includes(ReceiptCancellationColumn.STATUS),

      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'action',
      headerName: 'Action',

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptCancelListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onAction(receipt, ReceiptAction.VIEW)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />

            {selectedTab === 0 && (
              <Stack direction={'row'} gap={1}>
                <VegaIconButton
                  tooltipTitle="Approve Receipt"
                  onClick={() => onAction(receipt, ReceiptAction.APPROVE)}
                  sx={{ bgcolor: '#E7EDFC' }}
                  icon={<AddTask sx={{ color: GREEN.dark, fontSize: 18 }} />}
                />
                <VegaIconButton
                  tooltipTitle="Cancel Receipt"
                  onClick={() => onAction(receipt, ReceiptAction.REJECT)}
                  sx={{ bgcolor: RED.light }}
                  icon={<Close sx={{ color: RED.red, fontSize: 18 }} />}
                />
              </Stack>
            )}
          </Stack>
        );
      },
    },
  ];

  const onAction = (cancelReceipt: ReceiptCancelListProps, action: string) => {
    if (action === ReceiptAction.APPROVE) {
      openApproveDialog();
    } else if (action === ReceiptAction.REJECT) {
      openRejectDialog();
    } else {
      toggleDialog();
    }
    dispatch(setCancelReceiptData(cancelReceipt));
  };
  const onClearAllClick = () => {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter('');
    closeFilterDrawer();
    const request = {
      page,
      size,
      status: [ReceiptStatus.APPROVED, ReceiptStatus.REJECTED],
    } as GetReceiptsCancelListRequest;
    dispatch(getReceiptCancelList(request));
  };

  const onSelectFilterClick = (
    statusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);

    const filteredReceiptObj = {
      status: [statusFilter],
      page,
      size,
    } as GetReceiptsCancelListRequest;

    if (dateRangeFilter.startDate.length && dateRangeFilter.endDate.length) {
      filteredReceiptObj.toDate = dateRangeFilter.endDate;
      filteredReceiptObj.fromDate = dateRangeFilter.startDate;
    }

    dispatch(getReceiptCancelList(filteredReceiptObj));
  };

  const toggleDialog = () => {
    setOpenReceipt(!openReceipt);
  };

  const fetchReceiptsList = () => {
    const request = {
      approverId: user.id,
      page,
      size,
      status:
        selectedTab === 0
          ? [ReceiptStatus.RAISED]
          : [ReceiptStatus.APPROVED, ReceiptStatus.REJECTED],
    } as GetReceiptsCancelListRequest;
    if (statusFilter.length) {
      request.status = [statusFilter];
    }
    if (dateFilter.startDate.length && dateFilter.endDate.length) {
      request.toDate = dateFilter.endDate;
      request.fromDate = dateFilter.startDate;
    }

    dispatch(getReceiptCancelList(request));
  };

  useEffect(() => {
    user && fetchReceiptsList();
  }, [page, size, user, selectedTab]);
  return (
    <>
      <VegaPageHeader
        title={_.startCase(_.toLower(tabTypes[selectedTab]))}
        renderRightView={() => (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaColumnFilterSection
              columns={ReceiptCancellationColumn}
              selectedOptions={selectedOptions}
              setSelectedOptions={updateFilters}
            />
            {selectedTab !== 0 && (
              <LmFilterSection
                setSearch={() => {}}
                onFilter={() => openFilterDrawer()}
              />
            )}
          </Stack>
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={cancelReceipts}
          loading={loadingOfReceiptCancel}
          columns={COL_DEF}
          idColumn="id"
          page={page}
          pageSize={size}
          rowCount={totalItemsOfReceiptCancel}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
      <ReceiptFilterDrawer
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <ReciptFilterBy
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter, FilterOption.StatusFilter]}
        />
      </ReceiptFilterDrawer>
      <ViewDetailsReceiptCancellation
        open={openReceipt}
        toggleDialog={toggleDialog}
      />
      <ReceiptCancellationApproveDialog
        open={isApproveDialogOpen}
        onClose={closeApproveDialog}
        onFetchList={fetchReceiptsList}
      />

      <CancelReceiptDialog
        open={isRejectDialogOpen}
        onClose={closeRejectDialog}
        onFetchList={fetchReceiptsList}
      />
    </>
  );
};

export default ListOfReceiptCancellation;
