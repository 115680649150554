import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  IncentiveDetailsCurrentMonthResponseDto,
  IncentiveMonthDetailsDto,
} from '../../types/incentiveOfMonthType';
import { BucketValueDto } from '../../types/incentives';
import {
  currentMonthIncentivesList,
  overallIncentivesList,
} from './incentivesAsyncThunk';

interface TransformedTotalPayoutData {
  bucket: string;
  value: number;
  role: string;
}
export interface IncentiveForMonthSliceProps {
  loadingForCurrentMonth: boolean;
  loadingForOverall: boolean;
  errorForOverall: '';
  errorForCurrentMonth: '';
  incentiveForMonthData: IncentiveMonthDetailsDto[];
  totalPayout: TransformedTotalPayoutData[];
  incentiveCoverage: TransformedTotalPayoutData[];
}

const initialState: IncentiveForMonthSliceProps = {
  loadingForCurrentMonth: false,
  loadingForOverall: false,
  errorForOverall: '',
  errorForCurrentMonth: '',
  incentiveForMonthData: [],
  totalPayout: [],
  incentiveCoverage: [],
};

const mapToTransformedData = (
  bucketValueDtoArray: BucketValueDto[],
  role: string
): TransformedTotalPayoutData[] => {
  return bucketValueDtoArray.map(item => ({
    bucket: item.bucket,
    value: item.value,
    role: role,
  }));
};

const incentiveForMonthSlice = createSlice({
  name: 'incentiveForMonth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(currentMonthIncentivesList.pending, state => {
        state.loadingForCurrentMonth = true;
        state.totalPayout = [];
        state.incentiveCoverage = [];
        state.errorForCurrentMonth = '';
      })
      .addCase(currentMonthIncentivesList.fulfilled, (state, action) => {
        state.loadingForCurrentMonth = false;
        state.totalPayout = [];
        state.incentiveCoverage = [];
        action.payload.forEach(item => {
          const totalPayoutData = mapToTransformedData(
            item.totalPayout,
            item.role
          );
          const incentiveCoverageData = mapToTransformedData(
            item.incentiveCoverage,
            item.role
          );
          state.totalPayout.push(...totalPayoutData);
          state.incentiveCoverage.push(...incentiveCoverageData);
        });
      })
      .addCase(currentMonthIncentivesList.rejected, state => {
        state.loadingForCurrentMonth = false;
        state.totalPayout = [];
        state.incentiveCoverage = [];
        state.errorForCurrentMonth = '';
      })
      .addCase(overallIncentivesList.pending, state => {
        state.loadingForOverall = true;
        state.incentiveForMonthData = [];
        state.errorForCurrentMonth = '';
      })
      .addCase(overallIncentivesList.fulfilled, (state, action) => {
        const response = action.payload.data;
        state.loadingForOverall = false;

        state.incentiveForMonthData = response;
      })
      .addCase(overallIncentivesList.rejected, state => {
        state.loadingForOverall = false;

        state.errorForOverall = '';
      });
  },
});
// export const {} = incentiveForMonthSlice.actions;
export const getIncentiveForMonthState = (state: RootState) =>
  state.incentiveForMonth;
export default incentiveForMonthSlice;
