import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IncentiveServices } from '../../Apis/IncentivesService';
import { toLowerCase } from '../../constants/commonFunction';
import {
  IncentiveStatus,
  getIncentivesGamificationsState,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useDrawer } from '../../hooks/useDrawer';
import {
  IncentivesActionEnum,
  getHeaderText,
} from '../../pages/IncentivesGamifications/IncentivesGamifications';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  IncentivePolicyReviewStatus,
  ViewIncentiesGamificationsModal,
} from '../../types/incentives';
import {
  CreateTempleteRequest,
  GetPolicyRequest,
  PolicyDeleteRequest,
  ReviewPolicyDetailsRequest,
} from '../../types/request/incentives';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmTextField from '../common/LmTextField';
import VegaApproveDialog from '../common/VegaApproveDialog';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaRejectDialog from '../common/VegaRejectDialog';
import VegaText from '../common/VegaText';
import { IncentiveColumn } from './column/IncentiveColumn';
import LmFilterSection from '../common/LmFilterSection';
import { getPolicies } from '../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import ViewPolicyRejectedReasonDialog from './components/ViewPolicyRejectedReasonDialog';
import ViewPolicyResubmissionReason from './components/ViewPolicyResubmissionReason';

interface IProps {
  selectedPolicyTab: number;
}

const getStatus = (selected: number) => {
  switch (selected) {
    case 1:
      return '';
    case 2:
      return `${IncentivePolicyReviewStatus.IN_REVIEW}`;
    case 3:
      return '';
    case 4:
      return '';

    default:
      return IncentivePolicyReviewStatus.ACTIVE;
  }
};

const ListOfPolicies = ({ selectedPolicyTab }: IProps) => {
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [reason, setReason] = useState<string>('');
  const { policies, loading, totalItems } = useAppSelector(
    getIncentivesGamificationsState
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>('');
  const [templateName, setTemplateName] = useState<string>('');
  const [policyData, setPolicyData] = useState<
    Partial<ViewIncentiesGamificationsModal>
  >({});

  const {
    open: onApproveOpen,
    close: onApproveClose,
    isOpen: isApproveOpen,
  } = useDrawer();
  const {
    open: onRejectOpen,
    close: onRejectClose,
    isOpen: isRejectOpen,
  } = useDrawer();
  const {
    open: onDeleteOpen,
    close: onDeleteClose,
    isOpen: isDeleteOpen,
  } = useDrawer();

  const {
    open: onActivatedOpen,
    close: onActivatedClose,
    isOpen: isActivatedOpen,
  } = useDrawer();
  const {
    open: onInActivateOpen,
    close: onInActivateClose,
    isOpen: isInActivateOpen,
  } = useDrawer();
  const {
    open: onSaveTemplateOpen,
    close: onSaveTemplateClose,
    isOpen: isSaveTemplateOpen,
  } = useDrawer();
  const {
    open: onRejectionReasonOpen,
    close: onRejectionReasonClose,
    isOpen: isRejectionReasonOpen,
    props: rejectionReasonProps,
  } = useDrawer();
  const {
    open: openResubmissionDialog,
    close: closeResubmissionDialog,
    isOpen: isResubmissionDialogOpen,
    props: resubmissionDialogProps,
  } = useDrawer();
  const {
    open: onResubmissionReasonOpen,
    close: onResubmissionReasonClose,
    isOpen: isResubmissionReasonOpen,
    props: resubmissionReasonProps,
  } = useDrawer();

  const onAction = (
    policy: ViewIncentiesGamificationsModal,
    action: IncentivesActionEnum
  ) => {
    setPolicyData(policy);
    if (action === IncentivesActionEnum.VIEW) {
      navigate(
        ROUTES.INCENTIVES_GAMIFICATIONS_VIEW.replace(':policyId', policy.id)
      );
    } else if (action === IncentivesActionEnum.EDIT) {
      navigate(
        ROUTES.INCENTIVES_GAMIFICATIONS_EDIT.replace(':edit', 'edit').replace(
          ':policyId',
          policy.id
        )
      );
    } else if (action === IncentivesActionEnum.APPROVE) {
      onApproveOpen();
    } else if (action === IncentivesActionEnum.REJECT) {
      onRejectOpen();
    } else if (action === IncentivesActionEnum.DELETE) {
      onDeleteOpen();
    } else if (action === IncentivesActionEnum.ACTIVATE) {
      onActivatedOpen();
    } else if (action === IncentivesActionEnum.DEACTIVATE) {
      onInActivateOpen();
    } else if (action === IncentivesActionEnum.SAVE_TEMPLATE) {
      onSaveTemplateOpen();
    } else if (action === IncentivesActionEnum.IN_REVIEW) {
      deletePolicy(IncentivesActionEnum.IN_REVIEW, () => {}, policy.id);
    } else if (action === IncentivesActionEnum.VIEW_REJECTED_REASON) {
      onRejectionReasonOpen({ remarks: policy?.remarks });
    } else if (action === IncentivesActionEnum.RE_SUBMIT) {
      openResubmissionDialog({ policy: policy });
    } else if (action === IncentivesActionEnum.VIEW_RESUBMISSION_REASON) {
      onResubmissionReasonOpen({ reason: policy.reason });
    } else {
      downloadPolicy(policy.id);
    }
  };

  const downloadPolicy = (policyId: string) => {
    IncentiveServices.downloanPolicyByPolicyId(policyId)
      .then(res => {
        window.open(res, '__blank__');
        setSnackbar(`Policy Download successfully !!!`);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  const reviewPolicy = (
    approvalStatus: string,
    remarks: string,
    onClose: () => void
  ) => {
    const request = {
      policyId: policyData.id,
      approverId: user?.id,
      approvalStatus,
      ...(remarks.length && { remarks }),
    } as ReviewPolicyDetailsRequest;

    IncentiveServices.reviewPolicy(request)
      .then(() => {
        setSnackbar(`${approvalStatus} successfully !!!`);
        onClose();
        getFilteredPoliciesList();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onClose();
      });
  };
  const deletePolicy = (
    status: string,
    onClose: () => void,
    policyId: string,
    reason?: string
  ) => {
    const request = {
      policyId,
      status,
      ...(reason?.length && { reason }),
    } as PolicyDeleteRequest;

    IncentiveServices.deleteRulesForPolicy(request)
      .then(() => {
        if (status === IncentivesActionEnum.IN_REVIEW) {
          setSnackbar(`Send for review successfully !!!`);
        } else if (status === IncentivesActionEnum.RE_SUBMIT) {
          setSnackbar(`Re Submitted successfully!!!`);
        } else {
          setSnackbar(`delete successfully !!!`);
        }
        onClose();
        getFilteredPoliciesList();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onClose();
      });
  };
  const onSaveTemplate = () => {
    const request = {
      policyId: policyData.id,
      templateName,
      type: policyData.classType,
    } as CreateTempleteRequest;

    IncentiveServices.createTemplate(request)
      .then(() => {
        setSnackbar(`save template successfully !!!`);
        onSaveTemplateClose();
        getFilteredPoliciesList();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onSaveTemplateClose();
      });
  };

  const getFilteredPoliciesList = () => {
    const request: Partial<GetPolicyRequest> = {
      page,
      pageSize,
    };
    if (searchValue.length) {
      request.name = searchValue;
    }
    if (selectedPolicyTab === 0) {
      request.status = getStatus(selectedPolicyTab);
    } else if (selectedPolicyTab === 2) {
      request.approvalStatus = getStatus(selectedPolicyTab);
      request.approverId = user?.id;
    } else if (selectedPolicyTab === 1) {
      request.startDateAfter = DateUtility.formatStringToYYYYMMDD(
        new Date().toISOString()
      );
    } else if (selectedPolicyTab === 3) {
      request.endDateBefore = DateUtility.formatStringToYYYYMMDD(
        new Date().toISOString()
      );
    }
    dispatch(getPolicies(request));
  };

  useEffect(() => {
    const getPolicies = setTimeout(getFilteredPoliciesList, 300);
    return () => clearTimeout(getPolicies);
  }, [dispatch, page, pageSize, selectedPolicyTab, searchValue]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={toLowerCase(getHeaderText(selectedPolicyTab))}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection
              hideFilter
              search={searchValue}
              setSearch={setSearchValue}
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={policies}
          idColumn="id"
          paginationMode="server"
          loading={loading}
          rowCount={totalItems}
          page={page}
          pageSize={pageSize}
          onPageChange={page => setPage(page)}
          onPageSizeChange={pageSize => setPageSize(pageSize)}
          columns={IncentiveColumn({
            selectedTab: selectedPolicyTab,
            onAction,
          })}
        />
      </VegaPageContent>
      <VegaApproveDialog
        header="Are you sure?"
        buttonText="Approve"
        open={isApproveOpen}
        onClose={onApproveClose}
        onApprove={() =>
          reviewPolicy(IncentiveStatus.APPROVED, '', onApproveClose)
        }
      >
        <VegaText
          text={`You are approving the Request of  ${policyData.policyName}`}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaApproveDialog
        header="Are you sure?"
        buttonText="Activate"
        open={isActivatedOpen}
        onClose={onActivatedClose}
        onApprove={() =>
          deletePolicy('ACTIVE', onActivatedClose, policyData.id)
        }
      >
        <VegaText
          text={`You are Active the Request of ${policyData.policyName} `}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaApproveDialog
        header="Are you sure?"
        buttonText="De-Activate"
        open={isInActivateOpen}
        onClose={onInActivateClose}
        onApprove={() =>
          deletePolicy('INACTIVE', onInActivateClose, policyData.id)
        }
      >
        <VegaText
          text={`You are De-Active the Request of ${policyData.policyName} `}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaApproveDialog
        header="Are you sure?"
        buttonText="Re-Submit"
        open={isResubmissionDialogOpen}
        onClose={() => {
          closeResubmissionDialog();
          setReason('');
        }}
        onApprove={() => {
          closeResubmissionDialog();
          deletePolicy(
            IncentivesActionEnum.IN_REVIEW,
            () => {},
            resubmissionDialogProps.policy.id,
            reason
          );
        }}
      >
        <VegaFormInputField label="What is your reason for Resubmission?">
          <LmTextField
            isTextArea
            rows={2}
            multiline
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </VegaFormInputField>
      </VegaApproveDialog>
      <VegaRejectDialog
        text="Reject Request ?"
        open={isRejectOpen}
        onClose={onRejectClose}
        onReject={remarks =>
          reviewPolicy(IncentiveStatus.REJECTED, remarks, onRejectClose)
        }
      />
      <VegaRejectDialog
        text="Reject Request ?"
        open={isDeleteOpen}
        onClose={onDeleteClose}
        onReject={() => deletePolicy('DELETED', onDeleteClose, policyData.id)}
      />
      <VegaApproveDialog
        header="Save as Template"
        buttonText="Save"
        open={isSaveTemplateOpen}
        onClose={onSaveTemplateClose}
        onApprove={onSaveTemplate}
      >
        <VegaFormInputField label="template name">
          <LmTextField
            isTextArea
            rows={2}
            multiline
            value={templateName}
            onChange={e => setTemplateName(e.target.value)}
          />
        </VegaFormInputField>
      </VegaApproveDialog>
      <ViewPolicyRejectedReasonDialog
        onClose={onRejectionReasonClose}
        rejectionReason={rejectionReasonProps?.remarks}
        show={isRejectionReasonOpen}
      />
      <ViewPolicyResubmissionReason
        onClose={onResubmissionReasonClose}
        reSubmissionReason={resubmissionReasonProps?.reason}
        show={isResubmissionReasonOpen}
      />
    </>
  );
};

export default ListOfPolicies;
