import { Grid, Stack } from '@mui/material';
import { COLOR } from '../../utils/ColorUtility';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaDialogItem from '../common/VegaDialogItem';
import { DateUtility } from '../../utils/DateUtlility';
import _ from 'lodash';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';

type Props = { open: boolean; onClose: () => void; leave?: LeaveViewModel };
function LeaveDetailsDialog({ open, onClose, leave }: Props) {
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => onClose()}
      isCloseIcon
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Leave Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Stack gap="0.5rem">
          <VegaDialogItem
            heading={'Type Of leave'}
            value={_.startCase(_.toLower(leave?.typesofLeave))}
          />

          <VegaDialogItem
            heading="Start Date"
            value={DateUtility.formatStringToDDMMYYYY(leave?.startDate)}
          />
          <VegaDialogItem
            heading="End Date"
            value={DateUtility.formatStringToDDMMYYYY(leave?.endDate)}
          />
          <VegaDialogItem heading="leave Reason" value={leave?.reason} />
          <VegaDialogItem
            heading="Status"
            renderValue={() => {
              return (
                <VegaText
                  text={getStatusValue(leave?.status)}
                  fontWeight={500}
                  fontSize={'0.8125rem'}
                  color={getColorForStatus(leave?.status)}
                />
              );
            }}
          ></VegaDialogItem>
          <VegaDialogItem
            heading="Start Time"
            value={_.startCase(_.toLower(leave?.startDateHalf))}
          />
          <VegaDialogItem
            heading="End Time"
            value={_.startCase(_.toLower(leave?.endDateHalf))}
          />
          {leave?.rejectionReason && (
            <VegaDialogItem
              heading="Reject Reason"
              value={leave?.rejectionReason}
            />
          )}
        </Stack>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default LeaveDetailsDialog;

const getColorForStatus = (status: string) => {
  switch (status) {
    case LeaveStatus.REJECTED:
      return COLOR.RED.DARK;
    case LeaveStatus.APPROVED:
      return COLOR.GREEN.DARK;
    case LeaveStatus.APPLIED:
      return COLOR.ORANGE.DARK;
  }
};

const getStatusValue = (status?: string) => {
  if (!status) return '';
  switch (status) {
    case LeaveStatus.REJECTED:
      return _.startCase(_.toLower(LeaveStatus.REJECTED));
    case LeaveStatus.APPROVED:
      return _.startCase(_.toLower(LeaveStatus.APPROVED));
    case LeaveStatus.APPLIED:
      return 'Pending';
  }
};
