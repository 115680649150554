import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { ExpenseService } from '../../Apis/ExpenseService';
import { BORDER_COLLECTION } from '../../constants/style';
import { fetchCalender, getCalenderState } from '../../features/calenderSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { ExpenseClaimAction } from '../../types/claim';
import { ICalenderRequest } from '../../types/request/CalenderRequest';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import CreateClaimForm, {
  CreateClaimFormData,
} from '../ExpenseClaim/CreateClaimForm';
import VegaButton from '../common/VegaButton';
import VegaDrawer from '../common/VegaDrawer';
import VegaFormInputField from '../common/VegaFormInputField';
import { VegaPill } from '../common/VegaPill';
import VegaText from '../common/VegaText';

interface IProps {
  date: string | null;
}

const ExpenseCard = ({ date }: IProps) => {
  const { expenseReport } = useAppSelector(getCalenderState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { userId } = useClientAuth();
  const [openAddExpenseDrawer, setOpenAddExpenseDrawer] =
    useState<boolean>(false);

  const onCreateClaimClick = async (formData: Partial<CreateClaimFormData>) => {
    const convertedDate = DateUtility.parseStringToDate(date).toISOString();
    try {
      setOpenAddExpenseDrawer(false);

      const files: File[] = [];

      formData.documents?.forEach(item => {
        if (item.value) {
          files.push(item.value);
        }
      });

      await ExpenseService.createClaim({
        userId: userId,
        dateOfExpense: convertedDate,
        amount: formData.amount,
        expenseType: formData.expenseType,
        documents: files,
        description: formData.description,
        vehicleType: formData.vehicleType,
      });

      const request = {
        agentId: userId,
        date: DateUtility.formatStringToYYYYMMDD(convertedDate),
      } as ICalenderRequest;
      dispatch(fetchCalender(request));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getClaimCount = (claim: number) => {
    if (!claim) return 0;
    return claim;
  };
  return (
    <>
      <Box
        sx={{
          borderRadius: '0.5rem',
          border: BORDER_COLLECTION,
          p: '1.5rem',
          bgcolor: 'white',
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={1}
        >
          <VegaText
            text={'Expense Report'}
            fontWeight={600}
            fontSize={'1rem'}
          />
          {expenseReport.length ? (
            <VegaPill
              text={expenseReport?.[0].status}
              backgroundColor={
                getColorForStatus(expenseReport?.[0]?.status)?.LIGHT
              }
              key={expenseReport?.[0].status}
              textColor={getColorForStatus(expenseReport?.[0]?.status)?.DARK}
            />
          ) : (
            ''
          )}
        </Stack>
        {expenseReport.length ? (
          <Stack mt={'1rem'}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <VegaFormInputField label={'Rejected'}>
                  <VegaText
                    text={getClaimCount(expenseReport[0]?.rejectedClaims)}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={6} sm={6}>
                <VegaFormInputField label={'Pending'}>
                  <VegaText
                    text={getClaimCount(expenseReport[0]?.pendingClaims)}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={6} sm={6}>
                <VegaFormInputField label={'Approved'}>
                  <VegaText
                    text={getClaimCount(expenseReport[0]?.acceptedClaims)}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={6} sm={6}>
                <VegaFormInputField label={'Total Claims'}>
                  <VegaText
                    text={
                      getClaimCount(expenseReport[0]?.rejectedClaims) +
                      getClaimCount(expenseReport[0]?.pendingClaims) +
                      getClaimCount(expenseReport[0]?.acceptedClaims)
                    }
                  />
                </VegaFormInputField>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <Stack mt={'1rem'} gap={2}>
            <VegaText
              color={'#676B76'}
              fontWeight={400}
              fontSize={12}
              text={
                'You can create your expense claims by using Add Expense button below'
              }
              sx={{
                lineHeight: '15.6px',
              }}
            />
            <Box textAlign={'end'}>
              <VegaButton
                text="Add Expense"
                onClick={() => setOpenAddExpenseDrawer(true)}
              />
            </Box>
          </Stack>
        )}
      </Box>
      <VegaDrawer
        open={openAddExpenseDrawer}
        title={'Add Expense'}
        onClose={function (): void {
          setOpenAddExpenseDrawer(false);
        }}
      >
        <CreateClaimForm onCreateClick={onCreateClaimClick} isHideDateField />
      </VegaDrawer>
    </>
  );
};

export default ExpenseCard;

const getColorForStatus = (status?: ExpenseClaimAction) => {
  if (!status) return;
  switch (status) {
    case ExpenseClaimAction.ACCEPTED:
      return COLOR.GREEN;
    case ExpenseClaimAction.PENDING:
      return COLOR.ORANGE;
    case ExpenseClaimAction.REJECTED:
      return COLOR.RED;
  }
};
