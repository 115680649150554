/* eslint-disable no-unused-vars */
import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ExpenseService } from '../../Apis/ExpenseService';
import ApproveConfirmationDialog from '../../components/ExpenseClaim/ApproveConfirmationDialog';
import ClaimDetailsDialog from '../../components/ExpenseClaim/ClaimDetailsDialog';
import CreateClaimForm, {
  CreateClaimFormData,
} from '../../components/ExpenseClaim/CreateClaimForm';
import DeleteExpenseConfirmationDialog from '../../components/ExpenseClaim/DeleteExpenseConfirmationDialog';
import FilterInput, {
  DateRangeFilter,
  FilterOption,
} from '../../components/ExpenseClaim/FilterInput';
import ReapplyExpenseClaimDrawer, {
  ReapplyExpenseForm,
} from '../../components/ExpenseClaim/ReapplyExpenseClaimForm';
import RejectConfirmationDialog from '../../components/ExpenseClaim/RejectConfirmationDialog';
import UpdateClaimForm, {
  UpdateClaimFormData,
} from '../../components/ExpenseClaim/UpdateClaimForm';
import {
  ApproveIcon,
  AttachmentIcon,
  CrossIcon,
  EditIcon,
  TrashIcon,
} from '../../components/Icons/Icons';
import LmButton from '../../components/common/LmButton';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';
import VegaText from '../../components/common/VegaText';
import { PRIMARY, RED } from '../../constants/style';
import { useDrawer } from '../../hooks/useDrawer';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { SUB_ROUTES } from '../../router/routes';
import {
  ExpenseClaim,
  ExpenseClaimAction,
  ExpenseDocument,
  ExpenseReport,
} from '../../types/claim';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { FileUtility } from '../../utils/FileUtility';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../components/common/VegaIconButton';

function ExpenseClaims() {
  const { reportId } = useParams();
  const location = useLocation();
  const isApprover = location.state?.isAdmin ?? false;
  const {
    fetchReport,
    fetchExpenseClaims,
    createClaim,
    deleteExpenseClaim,
    updateExpenseClaim,
    deleteClaimSupportingDocuments,
    submitReport,
    reapplyClaim,
    getClaimDocumentsPublicUrl,
  } = useExpenseApi();
  const navigate = useNavigate();
  const { userId } = useClientAuth();
  const [report, setReport] = useState<ExpenseReport | null>(null);
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [claims, setClaims] = useState<ExpenseClaim[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });
  const {
    isOpen: isCreateClaimDrawerOpen,
    open: openCreateClaimDrawer,
    close: closeCreateClaimDrawer,
  } = useDrawer(false);
  const {
    isOpen: isUpdateClaimDrawerOpen,
    open: openUpdateClaimDrawer,
    close: closeUpdateClaimDrawer,
    props: updateClaimDrawerProps,
  } = useDrawer(false);
  const {
    isOpen: isReapplyClaimDrawerOpen,
    open: openReapplyClaimDrawer,
    close: closeReapplyClaimDrawer,
    props: reapplyClaimDrawerProps,
  } = useDrawer(false);
  const {
    isOpen: isDeleteConfirmationDialogOpen,
    open: openDeleteConfirmationDialog,
    close: closeDeleteConfirmationDialog,
    props: deleteConfirmationDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isClaimDetailsDialogOpen,
    open: openClaimDetailsDialog,
    close: closeClaimDetailsDialog,
    props: claimDetailsDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);
  const {
    isOpen: isApproveConfimDialogOpen,
    open: openApproveConfimDialog,
    close: closeApproveConfimDialog,
    props: approveConfirmDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isRejectConfimDialogOpen,
    open: openRejectConfimDialog,
    close: closeRejectConfimDialog,
    props: rejectConfirmDialogProps,
  } = useDrawer(false);

  async function fetchReportById() {
    try {
      if (!reportId) return;
      setLoading(true);
      const response = await fetchReport({ reportId: reportId });
      setReport(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchClaims() {
    try {
      setLoading(true);
      let statusToApply;
      if (isApprover) {
        statusToApply = ExpenseClaimAction.PENDING;
      } else {
        statusToApply = statusFilter.join(',');
      }
      const response = await fetchExpenseClaims({
        reportId: reportId,
        status: statusToApply,
        startDateRange: dateFilter.startDate,
        endDateRange: dateFilter.endDate,
      });
      setClaims(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setClaims([]);
      setPage(0);
      setRowCount(0);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function deleteClaim(claimId: string) {
    try {
      setLoading(true);
      closeDeleteConfirmationDialog();
      await deleteExpenseClaim(claimId);
      fetchClaims();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getColumnDefinition = () => {
    if (!report) return [];
    if (isApprover) {
      return getApproverColDef({
        onApproverClick: claim => {
          openApproveConfimDialog({ claim: claim });
        },
        onRejectClick: claim => {
          openRejectConfimDialog({ claim: claim });
        },
        onAttachmentClick: claim => {
          downloadAttachment(claim);
        },
      });
    }
    if (report.status == ExpenseClaimAction.DRAFT) {
      return getAgentDraftColDef({
        onEditClick: claim => {
          openUpdateClaimDrawer({ claim: claim });
        },
        onDeleteClick: claim => {
          openDeleteConfirmationDialog({ claimId: claim.id });
        },
        onAttachmentClick: claim => {
          downloadAttachment(claim);
        },
      });
    }
    if (
      report.status == ExpenseClaimAction.PENDING ||
      report.status == ExpenseClaimAction.ACCEPTED ||
      report.status == ExpenseClaimAction.REJECTED
    ) {
      return getAgentSubmittedColDef({
        onViewDetailsClick: claim => {
          openClaimDetailsDialog({ claim: claim });
        },
        onAttachmentClick: claim => {
          downloadAttachment(claim);
        },
      });
    }
    return [];
  };

  async function onCreateClaimClick(formData: Partial<CreateClaimFormData>) {
    try {
      setLoading(true);
      closeCreateClaimDrawer();

      const files: File[] = [];

      formData.documents?.forEach(item => {
        if (item.value) {
          files.push(item.value);
        }
      });

      await createClaim({
        userId: userId,
        dateOfExpense: formData.date?.toISOString(),
        amount: formData.amount,
        expenseType: formData.expenseType,
        documents: files,
        description: formData.description,
        vehicleType: formData.vehicleType,
      });
      fetchClaims();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onUpdateClaim(
    claim: ExpenseClaim,
    formData: Partial<UpdateClaimFormData>
  ) {
    try {
      setLoading(true);
      closeUpdateClaimDrawer();

      await deleteClaimSupportingDocuments({
        claimId: claim.id,
        supportingDocumentIds: formData.documentsToBeDeleted?.map(
          item => item.id
        ),
      });

      const files: File[] = [];
      formData.documentsToBeUploaded?.forEach(item => {
        if (item.value) {
          files.push(item.value);
        }
      });

      await updateExpenseClaim({
        claimId: claim.id,
        amount: formData.amount,
        description: formData.description,
        documents: files,
      });

      fetchClaims();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onSubmitReportClick() {
    try {
      if (!reportId) return;
      setLoading(true);
      await submitReport({
        reportId: reportId,
        userId: userId,
      });
      navigate(SUB_ROUTES.EXPENSE_REPORT_DRAFT);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function approveClaim(claim: ExpenseClaim) {
    try {
      setLoading(true);
      closeApproveConfimDialog();
      await ExpenseService.settleClaim({
        claimId: claim.id,
        status: ExpenseClaimAction.ACCEPTED,
        approverId: report.approverId,
      });
      fetchClaims();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function rejectClaim(claim: ExpenseClaim, reason: string) {
    try {
      setLoading(true);
      closeRejectConfimDialog();
      await ExpenseService.settleClaim({
        claimId: claim.id,
        status: ExpenseClaimAction.REJECTED,
        approverId: report.approverId,
        remarks: reason,
      });
      fetchClaims();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function reapplyExpenseClaim(
    claim: ExpenseClaim,
    formData: Partial<ReapplyExpenseForm>
  ) {
    try {
      closeReapplyClaimDrawer();
      setLoading(true);
      await reapplyClaim(claim, formData);
      fetchClaims();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function downloadFiles(documens: ExpenseDocument[]) {
    for (const document of documens) {
      try {
        await FileUtility.downloadFile({
          url: document.url,
          filename: document.name,
        });
      } catch (error) {
        setSnackbar('Failed to download file: ' + document.name, 'error');
      }
    }
  }

  async function downloadAttachment(claim: ExpenseClaim) {
    try {
      const response = await getClaimDocumentsPublicUrl(claim.id);
      downloadFiles(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  function onClearAllClick() {
    setDayFilter(undefined);
    setStatusFilter([]);
    closeFilterDrawer();
  }

  function onSelectFilterClick(
    statusFilter: string[],
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
  }

  useEffect(() => {
    fetchReportById();
  }, []);

  useEffect(() => {
    fetchClaims();
  }, [report, dateFilter, dayFilter, statusFilter]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Expense Report', link: -1 },
                {
                  label: `#${StringUtility.extractLastNDigits(4, report?.id)}`,
                },
              ]}
            />
          );
        }}
        renderRightView={() => {
          if (report?.status == ExpenseClaimAction.DRAFT) {
            return (
              <Stack direction={'row'} columnGap="1rem">
                <LmButton
                  variant="outlined"
                  bgColor=""
                  textColor={'#1047DC'}
                  text="Add Expense"
                  fontWeight={500}
                  onClick={() => {
                    openCreateClaimDrawer();
                  }}
                />
                <LmButton
                  text="Submit Report"
                  fontWeight={500}
                  onClick={() => {
                    onSubmitReportClick();
                  }}
                />
              </Stack>
            );
          }
        }}
      />
      <Box p={3}>
        <LmFilterSection
          setSearch={() => {}}
          onFilter={() => openFilterDrawer()}
        />
        <Box pt={3}>
          <VegaDataGrid
            data={claims}
            loading={loading}
            columns={getColumnDefinition()}
            idColumn="id"
            rowCount={rowCount}
            page={page}
            pageSize={10}
          />
        </Box>
      </Box>
      <VegaDrawer
        open={isCreateClaimDrawerOpen}
        title={'Add Expense'}
        onClose={function (): void {
          closeCreateClaimDrawer();
        }}
      >
        <CreateClaimForm onCreateClick={onCreateClaimClick} />
      </VegaDrawer>

      <VegaDrawer
        open={isUpdateClaimDrawerOpen}
        title={'Update Expense'}
        onClose={function (): void {
          closeUpdateClaimDrawer();
        }}
      >
        <UpdateClaimForm
          value={updateClaimDrawerProps.claim}
          onUpdateClick={onUpdateClaim}
        />
      </VegaDrawer>

      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <FilterInput
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter, FilterOption.StatusFilter]}
        />
      </VegaDrawer>

      <DeleteExpenseConfirmationDialog
        show={isDeleteConfirmationDialogOpen}
        claimId={deleteConfirmationDialogProps.claimId}
        onDeleteClick={function (): void {
          deleteClaim(deleteConfirmationDialogProps.claimId);
        }}
        onCancelClick={function (): void {
          closeDeleteConfirmationDialog();
        }}
      />

      <ClaimDetailsDialog
        show={isClaimDetailsDialogOpen}
        claim={claimDetailsDialogProps.claim}
        onClose={function (): void {
          closeClaimDetailsDialog();
        }}
        onReapplyClick={function (claim: ExpenseClaim): void {
          closeClaimDetailsDialog();
          openReapplyClaimDrawer({ claimId: claim.id });
        }}
        onAttachmentClick={downloadAttachment}
      />

      <ApproveConfirmationDialog
        show={isApproveConfimDialogOpen}
        claim={approveConfirmDialogProps.claim}
        onApproveClick={approveClaim}
        onCancelClick={function (): void {
          closeApproveConfimDialog();
        }}
      />

      <RejectConfirmationDialog
        show={isRejectConfimDialogOpen}
        claim={rejectConfirmDialogProps.claim}
        onRejectClick={rejectClaim}
        onCancelClick={function (): void {
          closeRejectConfimDialog();
        }}
      />

      <ReapplyExpenseClaimDrawer
        show={isReapplyClaimDrawerOpen}
        claimId={reapplyClaimDrawerProps.claimId}
        onClose={function (): void {
          closeReapplyClaimDrawer();
        }}
        onSubmit={reapplyExpenseClaim}
      />
    </div>
  );
}

export default ExpenseClaims;

const getClaimForRow = (props: any) => props.row as ExpenseClaim;

const getPillColorForStatus = (status: ExpenseClaimAction) => {
  switch (status) {
    case ExpenseClaimAction.ACCEPTED:
      return COLOR.GREEN;
    case ExpenseClaimAction.PENDING:
      return COLOR.ORANGE;
    case ExpenseClaimAction.REJECTED:
      return COLOR.RED;
  }
};

const getAgentDraftColDef = (data: {
  onEditClick: (claim: ExpenseClaim) => void;
  onDeleteClick: (claim: ExpenseClaim) => void;
  onAttachmentClick: (claim: ExpenseClaim) => void;
}) => {
  const AGENT_DRAFT_COLUMN_DEF: GridColumns = [
    {
      field: 'date',
      headerName: 'Expenditure Date',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const parsedDate = DateUtility.parseStringToDate(claim.dateOfExpense);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimId',
      headerName: 'Claim ID',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const last4Digits = claim.id.substring(
          Math.max(0, claim.id.length - 4)
        );
        const displayText = `#${last4Digits}`;

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = claim.expenseType;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = claim.description ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalExepense',
      headerName: 'Total Exepense',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = StringUtility.formatToINR(claim.amount);

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              data.onAttachmentClick(claim);
            }}
          >
            <AttachmentIcon />
            <VegaText
              text={'Attachment'}
              fontWeight={500}
              color={'#1047DC'}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const claim = getClaimForRow(props);
        return (
          <Stack direction={'row'} gap={1}>
            <LmButton
              text=""
              startIcon={<EditIcon />}
              bgColor="#E7EDFC"
              textColor={PRIMARY.darkBlue}
              onClick={() => {
                data.onEditClick(claim);
              }}
            />
            <LmButton
              text=""
              startIcon={<TrashIcon />}
              onClick={() => data.onDeleteClick(claim)}
              bgColor="#F8E8E8"
              textColor={RED.red}
            />
          </Stack>
        );
      },
    },
  ];

  return AGENT_DRAFT_COLUMN_DEF;
};

const getAgentSubmittedColDef = (data: {
  onViewDetailsClick: (claim: ExpenseClaim) => void;
  onAttachmentClick: (claim: ExpenseClaim) => void;
}) => {
  const AGENT_SUBMITTED_COLUMN_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Expenditure Date',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const parsedDate = DateUtility.parseStringToDate(claim.dateOfExpense);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimId',
      headerName: 'Claim ID',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const last4Digits = claim.id.substring(
          Math.max(0, claim.id.length - 4)
        );
        const displayText = `#${last4Digits}`;

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = claim.expenseType;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = claim.description;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                text={displayText}
                fontWeight={500}
                fontSize={'0.875rem'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'totalExepense',
      headerName: 'Total Exepense',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = StringUtility.formatToINR(claim.amount);

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              data.onAttachmentClick(claim);
            }}
          >
            <AttachmentIcon />
            <VegaText
              text={'Attachment'}
              fontWeight={500}
              color={'#1047DC'}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const color = getPillColorForStatus(claim.status);
        return (
          <VegaPill
            text={claim.status}
            backgroundColor={color?.LIGHT}
            textColor={color?.DARK}
            borderColor={color?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const claim = getClaimForRow(props);
        return (
          // <LmButton
          //   text="View Details"
          //   variant="text"
          //   textColor={PRIMARY.darkBlue}
          //   fontWeight={500}
          //   sx={{ px: 0.5 }}
          //   fontSize={'0.875rem'}
          //   bgColor="transparant"
          //   onClick={() => {
          //     data.onViewDetailsClick(claim);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(claim);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];

  return AGENT_SUBMITTED_COLUMN_DEF;
};

const getApproverColDef = (data: {
  onApproverClick: (claim: ExpenseClaim) => void;
  onRejectClick: (claim: ExpenseClaim) => void;
  onAttachmentClick: (claim: ExpenseClaim) => void;
}) => {
  const AGENT_SUBMITTED_COLUMN_DEF: GridColumns = [
    {
      field: 'claimId',
      headerName: 'Claim ID',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const last4Digits = claim.id.substring(
          Math.max(0, claim.id.length - 4)
        );
        const displayText = `#${last4Digits}`;

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = claim.expenseType;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = claim.description;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalExepense',
      headerName: 'Total Exepense',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const displayText = StringUtility.formatToINR(claim.amount);

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              data.onAttachmentClick(claim);
            }}
          >
            <AttachmentIcon />
            <VegaText
              text={'Attachment'}
              fontWeight={500}
              color={'#1047DC'}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const claim = getClaimForRow(props);
        const color = getPillColorForStatus(claim.status);
        return (
          <VegaPill
            text={claim.status}
            backgroundColor={color?.LIGHT}
            textColor={color?.DARK}
            borderColor={color?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: props => {
        const claim = getClaimForRow(props);
        return (
          <Stack direction={'row'} spacing="0.5rem">
            <LmButton
              text="Approve"
              startIcon={<ApproveIcon />}
              bgColor="rgba(235, 244, 236, 1)"
              textColor={'rgba(49, 132, 57, 1)'}
              onClick={() => {
                data.onApproverClick(claim);
              }}
            />
            <LmButton
              text="Reject"
              startIcon={<CrossIcon />}
              onClick={() => {
                data.onRejectClick(claim);
              }}
              bgColor="#F8E8E8"
              textColor={'rgba(186, 28, 28, 1)'}
            />
          </Stack>
        );
      },
    },
    {
      field: 'leftMargin',
      headerName: '',
      flex: 0.1,
      renderCell: () => {
        return <></>;
      },
    },
  ];

  return AGENT_SUBMITTED_COLUMN_DEF;
};
