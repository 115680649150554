import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../../constants/commonFunction';
import { ParameterDefinitionType } from '../../../../types/incentives';
import {
  IncentiveWriteOffRule,
  LanPayoutRange,
} from '../../../../types/request/incentives';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaText from '../../../common/VegaText';

interface IProps {
  loading?: boolean;
  rules: IncentiveWriteOffRule;
  index: number;
}
const ViewIncentiveProposedComponent = ({ loading, rules, index }: IProps) => {
  return (
    <Stack
      gap={1}
      key={index}
      sx={{
        borderRadius: '12px',
        p: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        mb: 1,
      }}
    >
      <VegaText
        text={`Perameter ${index + 1}`}
        fontWeight={500}
        fontSize={'0.75rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'POS Waiver min'}>
            {getTextLoadingWrapper(rules.posWaiverRange?.parameterMin, loading)}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'POS Waiver max'}>
            {getTextLoadingWrapper(
              `${rules.posWaiverRange?.parameterMax}`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {rules?.lanPayoutRange.length
        ? rules?.lanPayoutRange.map(
            (lanPayout: LanPayoutRange, index: number) => (
              <Stack
                key={index}
                sx={{
                  borderRadius: '12px',
                  p: 2,
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                  mb: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'Defination Type'}>
                      {getTextLoadingWrapper(
                        `${toLowerCase(lanPayout.parameterDefinitionType)}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  {lanPayout.parameterDefinitionType ===
                    ParameterDefinitionType.FIXED && (
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <VegaFormInputField label={'Parameter Exact'}>
                        {getTextLoadingWrapper(
                          `${lanPayout.parameterExact}`,
                          loading
                        )}
                      </VegaFormInputField>
                    </Grid>
                  )}
                  {lanPayout.parameterDefinitionType ===
                    ParameterDefinitionType.RANGE && (
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <VegaFormInputField label={'Parameter Min'}>
                        {getTextLoadingWrapper(
                          `${lanPayout.parameterMin}`,
                          loading
                        )}
                      </VegaFormInputField>
                    </Grid>
                  )}
                  {lanPayout.parameterDefinitionType ===
                    ParameterDefinitionType.RANGE && (
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <VegaFormInputField label={'Parameter Max'}>
                        {getTextLoadingWrapper(
                          `${lanPayout.parameterMax}`,
                          loading
                        )}
                      </VegaFormInputField>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField
                      label={'Payout Amount Value (Percentage)'}
                    >
                      {getTextLoadingWrapper(
                        `${lanPayout.payoutAmountValue} %`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                </Grid>
              </Stack>
            )
          )
        : ''}
    </Stack>
  );
};

export default ViewIncentiveProposedComponent;

const getTextLoadingWrapper = (value: string | number, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return (
      <VegaText text={value ?? '--'} fontWeight={500} fontSize={'0.875rem'} />
    );
  }
};
