import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TeleCallingMetricsServices } from '../Apis/TeleCallingMetricsServices';
import { RootState } from '../store';
import {
  CallMetrics,
  FilterTeleCallingMetrics,
  MetricsSummary,
} from '../types/teleCallingMetricsType';
import { DateUtility } from '../utils/DateUtlility';

const TODAY = new Date();
const TOMORRW = DateUtility.addDays(TODAY, 1);

export const initialFilterCallingMetrics = {
  userId: '',
  startDate: TODAY.toISOString(),
  endDate: TOMORRW.toISOString(),
} as FilterTeleCallingMetrics;
export const initialMetricsSummary = {
  assuredCall: 0,
  notAssured: 0,
  callTime: 0,
  talkTime: 0,
  stateTime: 0,
} as MetricsSummary;

export const fetchAgentSummry = createAsyncThunk(
  'teleCallingMetrics/fetchAgentSummry',
  async (request: Partial<FilterTeleCallingMetrics>) => {
    const response = await TeleCallingMetricsServices.getAgentSummary(request);
    return response;
  }
);

interface TeleCallingMetricsSlice {
  filterCallingMetrics: FilterTeleCallingMetrics;
  agentCallMetrics: CallMetrics | null;
  loadingSummary: boolean;
  error: string | null;
}

const initialState: TeleCallingMetricsSlice = {
  filterCallingMetrics: initialFilterCallingMetrics,
  agentCallMetrics: null,
  loadingSummary: false,
  error: null,
};

const teleCallingMetricsSlice = createSlice({
  name: 'teleCallingMetrics',
  initialState,
  reducers: {
    setFilterCallingMetrics: (state: TeleCallingMetricsSlice, action) => {
      const { payload } = action;
      state.filterCallingMetrics = payload;
    },
    setMetricsSummary: (state: TeleCallingMetricsSlice, action) => {
      const { payload } = action;
      state.agentCallMetrics = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAgentSummry.pending, state => {
        state.loadingSummary = true;
        state.error = null;
      })
      .addCase(fetchAgentSummry.fulfilled, (state, action) => {
        state.loadingSummary = false;
        const agentCallMetricsDto = { ...action.payload.status };
        if (agentCallMetricsDto && Object.keys(agentCallMetricsDto).length) {
          const firstKey = Object.keys(agentCallMetricsDto)[0];
          const agentCallMetricsViewModel = agentCallMetricsDto[firstKey];
          state.agentCallMetrics = agentCallMetricsViewModel.CallMetrics;
        }
      })
      .addCase(fetchAgentSummry.rejected, (state, action) => {
        state.loadingSummary = false;
        state.error = action.error.message || 'Failed to fetch users';
      });
  },
});
export const { setFilterCallingMetrics, setMetricsSummary } =
  teleCallingMetricsSlice.actions;
export const getTeleCallingMetrics = (state: RootState) =>
  state.teleCallingMetrics;
export default teleCallingMetricsSlice;
