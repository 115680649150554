import { Stack } from '@mui/material';

import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLeaveList,
  leaveRequestSelector,
} from '../../features/leaveRequestSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { AppDispatch, RootState, useAppSelector } from '../../store';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';
import { GetLeaveListRequest } from '../../types/request/leave';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import LmDialog from '../common/LmDialog';
import LmFilterSection from '../common/LmFilterSection';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaDrawer from '../common/VegaDrawer';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import { VegaPill } from '../common/VegaPill';
import VegaText from '../common/VegaText';
import LeaveDetailsDialog from './LeaveDetailsDialog';
import LeaveMangementFilter from './LeaveMangementFilter';
import VegaIconButton from '../common/VegaIconButton';
import { VisibilityOutlined } from '@mui/icons-material';
import { PRIMARY } from '../../constants/style';

type props = {
  tabHeading: string;
};
const LeaveHistory = ({ tabHeading }: props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useClientAuth();
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const leaves = useSelector<RootState, LeaveViewModel[]>(
    state => state.leaveRequestAction.leaves
  );
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);
  const {
    open: openDetailDialog,
    close: closeDetailsDialog,
    isOpen: isDetailsDialogOpen,
    props: detailsDialogProps,
  } = useDrawer(false);

  const { page: currentPage, totalItems } =
    useAppSelector(leaveRequestSelector);
  const [page, setPage] = useState<number>(0);

  function onPageChange(pageNumber: number) {
    setPage(pageNumber);
  }
  const onClearAllClick = () => {
    closeFilterDrawer();
    setStatus(null);
  };
  const onfilterClick = (selectedStatusFilter: string) => {
    closeFilterDrawer();
    setStatus(selectedStatusFilter);
  };

  const getLeaveList = async () => {
    try {
      const request = {
        approverId: user.id,
        page: page,
        size: 10,
        status: [LeaveStatus.APPROVED, LeaveStatus.REJECTED],
      } as GetLeaveListRequest;

      if (status) {
        request.status = [status];
      }
      if (search.length) {
        request.leaveType = search;
      }

      await dispatch(fetchLeaveList(request));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setLoading(true);
      getLeaveList();
    }
  }, [page, status, search, user]);

  return (
    <>
      <VegaPageHeader
        title={tabHeading}
        renderRightView={() => {
          return (
            <LmFilterSection
              onFilter={() => {
                openFilterDrawer();
              }}
              setSearch={setSearch}
            />
          );
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <LeaveMangementFilter
          onfilterClick={onfilterClick}
          defaultValue={status}
          onClearAllClick={onClearAllClick}
        />
      </VegaDrawer>
      <VegaPageContent>
        <VegaDataGrid
          page={currentPage}
          pageSize={10}
          loading={loading}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={leaves}
          columns={getColumnDefinition({
            onViewDetailsClick: (leave: LeaveViewModel) => {
              openDetailDialog({ leave: leave });
            },
          })}
          idColumn="id"
        />
      </VegaPageContent>
      <LmDialog
        fullWidth
        maxWidth="sm"
        open={isDetailsDialogOpen}
        isCloseIcon
        handleClose={() => closeDetailsDialog()}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Leave Details"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <LeaveDetailsDialog
          open={isDetailsDialogOpen}
          onClose={function (): void {
            closeDetailsDialog();
          }}
          leave={detailsDialogProps.leave}
        />
      </LmDialog>
    </>
  );
};

const getColumnDefinition = (data: {
  onViewDetailsClick: (leave: LeaveViewModel) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'AgentName',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 130,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.username}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'TypeofLeave',
      headerName: 'Type Of Leave',
      flex: 0.7,
      minWidth: 128,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.typesofLeave))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      flex: 1,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={DateUtility.formatStringToDDMMYYYY(Leave.startDate)}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.startDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={DateUtility.formatStringToDDMMYYYY(Leave.endDate)}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.endDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'Reason',
      headerName: 'Reason/Remark',
      flex: 0.6,
      minWidth: 369,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.reason}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 130,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaPill
            text={getStatusValue(Leave.status)}
            backgroundColor={getBackgroundColorForStatus(Leave.status)}
            borderColor={getBorderandTextColorForStatus(Leave.status)}
            key={Leave.status}
            textColor={getBorderandTextColorForStatus(Leave.status)}
          />
        );
      },
    },

    {
      field: 'Details',
      headerName: 'Details',
      flex: 0.6,
      minWidth: 130,

      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          // <VegaButton
          //   text="View Details"
          //   onClick={() => data.onViewDetailsClick(Leave)}
          //   variant="text"
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => data.onViewDetailsClick(Leave)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

export default LeaveHistory;
const getBorderandTextColorForStatus = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return COLOR.RED.DARK;
    case 'APPROVED':
      return COLOR.GREEN.DARK;
    case 'APPLIED':
      return COLOR.ORANGE.DARK;
  }
};
const getBackgroundColorForStatus = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return COLOR.RED.LIGHT;
    case 'APPROVED':
      return COLOR.GREEN.LIGHT;
    case 'APPLIED':
      return COLOR.ORANGE.LIGHT;
  }
};

const getStatusValue = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return LeaveStatus.REJECTED;
    case 'APPROVED':
      return LeaveStatus.APPROVED;
    case 'APPLIED':
      return 'PENDING';
  }
};
