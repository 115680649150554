import {
  CustomerDetail,
  LmsVariable,
  LoadPDCDetails,
  LoanMaster,
  LoanOutstandingDetails,
  MetaDataLoanAllocationDto,
} from './loan';

export enum AllocationTypes {
  FIELD = 'FIELD',
  TELE = 'TELE',
}
export enum ExecuteStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum AllocationStatus {
  UNALLOCATED = 'UNALLOCATED',
  ALLOCATED = 'ALLOCATED',
  NORMALIZED = 'NORMALIZED',
  STABILIZED = 'STABILIZED',
  ROLLBACK = 'ROLLBACK',
  CANCELLED = 'CANCELLED',
}

export type Allocation = {
  id: string;
  loanAccountId: string;
  region: string;
  branch: string;
  agentCode: string;
  agencyCode: string;
  allocationType: AllocationTypes;
  status: AllocationStatus;
  createdAt: string;
  updatedAt: string;
  expiresAt: string;
};
export type ExecutionAllocations = {
  id: string | number;
  createdAt: string;
  updatedAt: string;
  criteriaId: string;
  applicableLanIdList: string[];
  teleAllocatedLoanIds: string[];
  fieldAllocatedLoanIds: string[];
  numberOfTeleAllocations: number;
  numberOfFieldAllocations: number;
  agentId: string;
  remarks: string | null;
  status: string | null;
};

export type TeleAllocationList = {
  id: string;
  phoneNumber: string;
  processId: string;
  leadsetId: string;
  loanId: string;
  teleAllocatonType: string;
  agentId: string;
  teleCallStatus: string;
  createdAt: string | null;
};

export type TeleAllocateBulk = {
  requestId: string;
  allocations: any[];
  errorFileUrl: string;
};

export type AllocationViewModel = {
  id: string;
  loanAccountNumber: string;
  customerName: string;
  contactNumber: string;
  address: string;
  agentName: string;
  branchName: string;
};

export type AllocationStatusViewModel = {
  [key: string]: number;
};

export type AllocationRule = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  expiresAt: string;
  description: string;
  criteriaList: Criteria[];
  status: string;
  agentId: string;
  createdBy: string;
};

export type AllocationRuleViewModel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  expiresAt: string;
  description: string;
  criteriaList: Criteria[];
  status: string;
  agentId: string;
  agentName?: string;
  createdBy: string;
};

export enum AttributeDataType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  LOCAL_DATE = 'LOCAL_DATE',
  LIST = 'LIST',
}

export enum AttributeOperationType {
  GreaterThen = 'GT',
  GreaterThenOrEqual = 'GTE',
  LessThen = 'LT',
  LessThenOrEqual = 'LTE',
  Equal = 'EQ',
  Like = 'LIKE',
  In = 'IN',
}
export enum PenaltyIncentiveAttributeOperationType {
  Greater_Then = 'GT',
  Greater_Then_Or_Equal = 'GTE',
  Less_Then = 'LT',
  Less_Then_Or_Equal = 'LTE',
  Equal = 'EQ',
  Like = 'LIKE',
}

export type Criteria = {
  attributeName: string;
  dataType: AttributeDataType;
  op: string;
  value: number | string | string[];
  entity: string;
  possibleValues: string[];
};

export type RuleCriteriaAttribute = {
  name: string;
  dataType: AttributeDataType;
  validOperators: AttributeOperationType[];
  entity: string;
  possibleValues: string[];
};

export type BulkAllocationMeta = {
  id: number;
  createdBy: string;
  fileName: string;
  totalEntries: number;
  successEntries: number;
  failedEntries: number;
  successfulAllocationIds: string[];
  errorFile: string;
  createdAt: string;
  updatedAt: string;
  allocationType: string;
  uploaderName: string;
  uploaderCode: string;
};

export interface LongRunningTask {
  id: string;
  userId: string;
  status: LongRunningTaskStatus;
  type: string;
  taskData: string;
  result: string;
  requestTime: Date;
  completedTime: Date;
}

export enum LongRunningTaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type AllocationMetaData = {
  loanId: string;
  loanMaster: LoanMaster;
  lmsVariables: LmsVariable;
  loanPdcDetails: LoadPDCDetails;
  loanOutstandingDetails: LoanOutstandingDetails;
  loanTransactions: any | null;
  customerDetails: CustomerDetail[];
  metaDataLoanAllocationDto: MetaDataLoanAllocationDto;
};
export type AllocationMetaDataExcelView = {
  name: string;
  loanAccountNumber: string;
  allocationType: string;
  pos: number;
  bucket: string;
  contactNumber: string;
  allocationDate: string;
};
export type AllocationMetaDataExport = {
  requestId: string;
  isComplete: boolean;
  fileUrl: string | null;
  returningCachedResult: boolean;
};

export type AgentStatsDto = {
  agentId: string;
  totalVisitCurrentMonth: number;
  pendingVisitCurrentMonth: number;
  ptpReceivedCurrentMonth: number;
  upcomingPTPCurrentMonth: number;
  totalVisitLastMonth: number;
  ptpReceivedLastMonth: number;
};

export type ExportPenalAndOverallResponse = {
  id: string;
  status: string;
  allocatedLoanCount: number;
};

export interface ExportPenalAndOverallResponseHistoryDto {
  id: string;
  status: string;
  executeBy: string;
  allocatedLoanCount: number;
  createdAt: string;
  updatedAt: string;
}
export interface RepeatLastMonthAllocationHistoryAllocation {
  createdAt: string;
  updatedAt: string;
  expiresAt: string;
  id: string;
  loanId: string;
  region: string;
  branch: string;
  agentId: string;
  phoneNumber: string;
  allocationType: string;
  status: string;
  requestId: string;
  allocationDate: string;
  processId: string;
  leadsetId: string;
  teleAllocatonType: string;
  teleCallStatus: string;
  allocatedBy: string;
  teleAllocationId: string;
}

export interface RepeatLastMonthAllocationHistoryDto {
  requestId: string;
  status: string;
  allocatedBy: string;
  allocatedByName: string;
  allocations: RepeatLastMonthAllocationHistoryAllocation[];
  allocationCount: number;
  allocatedByAdminUser: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface RepeatLastMonthAllocationsDto {
  requestId: string;
  status: string;
  allocatedBy: string;
  allocatedByName: string;
  allocations: string[];
  allocationCount: number;
  allocatedByAdminUser: boolean;
}

export interface ExecuteCriteriaResponse {
  acrResponse: boolean;
  agentBelowAcrLimit: AgentBelowAcrLimitType[];
  unAllocatedLoans: string[];
  id: number;
  createdAt: string | null;
  updatedAt: string | null;
  criteriaId: string;
  applicableLanIdList: string[];
  teleAllocatedLoanIds: string[];
  fieldAllocatedLoanIds: string[];
  numberOfTeleAllocations: number;
  numberOfFieldAllocations: number;
  agentId: string;
  executeBy: string | null;
  status: string;
  remarks: string | null;
}

export type AgentBelowAcrLimitType = {
  agentId: string;
  agentName: string;
  count: number;
};
