import {
  AddTask,
  Close,
  Download,
  VisibilityOutlined,
  WalletOutlined,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { ReceiptColumns } from '../../../../components/common/VegaColumnFilterSection';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import { VegaPill } from '../../../../components/common/VegaPill';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import { PRIMARY } from '../../../../constants/style';
import { useClientAuth } from '../../../../providers/ClientProvider';
import {
  DetailedReceiptsListProps,
  PaymentMode,
  ReceiptAction,
  ReceiptStatus,
  getPillColorForStatus,
} from '../../../../types/receiptManagment';
import { COLOR } from '../../../../utils/ColorUtility';
import { DateUtility } from '../../../../utils/DateUtlility';
import { StringUtility } from '../../../../utils/StringUtility';

interface IProps {
  onAction: (receipt: DetailedReceiptsListProps, status: string) => void;
  downloadReceipt: (receipt: DetailedReceiptsListProps) => void;
  selectedOptions: string[];
  selectedTab: number;
}

const HistoryReceiptColumn = ({
  onAction,
  downloadReceipt,
  selectedOptions,
  selectedTab,
}: IProps) => {
  const { collectorCodeForAgentId } = useClientAuth();
  const getCollectorCode = (agentId: string) => {
    if (!agentId) return 'NA';
    const collectorCode = collectorCodeForAgentId(agentId);
    if (!collectorCode) return 'NA';
    return collectorCode;
  };
  function getInstrumentDetails(receipt: DetailedReceiptsListProps) {
    switch (receipt?.paymentMeta?.paymentMode) {
      case PaymentMode.CHEQUE:
        if ('chequeNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.chequeNumber;
        }
        break;
      case PaymentMode.DD:
        if ('ddNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.ddNumber;
        }
        break;
      case 'PAYMENT_GATEWAY':
        if ('orderId' in receipt.paymentMeta) {
          return receipt.paymentMeta.orderId;
        }
        break;
      case PaymentMode.NEFT:
        if ('refNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.refNumber;
        }
        break;
      case PaymentMode.ELECTRONIC_FUND_TRANSFER:
        if ('refNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.refNumber;
        }
        break;
      default:
        return '--';
    }
  }

  const COL_DEF: GridColumns = [
    {
      field: 'receiptId',
      headerName: 'Receipt Number',
      hide: selectedOptions.includes(ReceiptColumns.RECEIPT_NUMBER),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const receiptNumber =
          receipt?.receiptNumber === null
            ? receipt?.receiptId
            : receipt?.receiptNumber?.toString();

        // const lastDigitId = StringUtility.extractLastNDigits(5, receiptNumber);
        return (
          <VegaIconButton
            sx={{
              width: '100%',
            }}
            tooltipTitle={receiptNumber}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={receiptNumber}
                onDoubleClick={() =>
                  onAction(
                    receipt,
                    receipt?.depositType === PaymentMode.CASH_IN_HAND &&
                      receipt.status === ReceiptStatus.APPROVED
                      ? ReceiptAction.EDIT
                      : ReceiptAction.VIEW
                  )
                }
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'receiptDate',
      headerName: 'Receipt Date',
      hide: selectedOptions?.includes(ReceiptColumns.RECEIPT_DATE),

      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const receiptDate = DateUtility.formatStringToDDMMMMYYYYWithTime(
          receipt.receiptDate
        );
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={receiptDate ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={receiptDate || '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'lan',
      headerName: 'LAN',
      hide: selectedOptions.includes(ReceiptColumns.LAN),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const lan = receipt.loanId ?? '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={lan}
          />
        );
      },
    },
    {
      field: 'agentName',
      headerName: 'Customer name',
      hide: selectedOptions.includes(ReceiptColumns.CUSTOMER_NAME),

      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const payeeName = receipt.payeeName
          ? toLowerCase(receipt.payeeName)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={payeeName}
          />
        );
      },
    },
    {
      field: 'depositeCentre',
      headerName: 'Deposit Centre',
      hide: selectedOptions.includes(ReceiptColumns.DEPOSITE_CENTER),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const depositCentre = receipt.depositCentre ?? '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={depositCentre}
          />
        );
      },
    },
    {
      field: 'depositeDate',
      headerName: 'Deposited Date',
      hide: selectedOptions.includes(ReceiptColumns.DEPOSITED_DATE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const depositDate = receipt.depositDate
          ? DateUtility.formatStringToDDMMMMYYYYWithTime(receipt.depositDate)
          : '--';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={depositDate ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={depositDate}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'depositReferenceNumber',
      headerName: 'Deposit Reference Number',
      hide: selectedOptions.includes(ReceiptColumns.DEPOSIT_REFRENCE_NUMBER),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const depositRefId = receipt.depositRefId ?? '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={depositRefId}
          />
        );
      },
    },
    {
      field: 'totalReceiptAmount',
      headerName: 'Total Receipt Amount',
      hide: selectedOptions.includes(ReceiptColumns.TOTAL_RECEIPT_AMOUNT),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const totalAmount = receipt.totalAmount
          ? StringUtility.formatToINR(receipt.totalAmount)
          : '';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={totalAmount}
          />
        );
      },
    },
    {
      field: 'paymentMode',
      headerName: 'Mode of Payment',
      hide: selectedOptions.includes(ReceiptColumns.MODE_OF_PAYMENT),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const paymentMode = receipt.paymentMode
          ? toLowerCase(receipt.paymentMode)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={paymentMode}
          />
        );
      },
    },

    {
      field: 'branch',
      headerName: 'Branch',
      hide: selectedOptions.includes(ReceiptColumns.BRANCH),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const branch = receipt.branch ? toLowerCase(receipt.branch) : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={branch}
          />
        );
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      hide: selectedOptions.includes(ReceiptColumns.REGION),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const region = receipt.region ? toLowerCase(receipt.region) : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={region}
          />
        );
      },
    },
    {
      field: 'paymentMessage',
      headerName: 'Payment Message',
      hide: selectedOptions.includes(ReceiptColumns.PAYMENT_MESSAGE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const remarks = receipt.remarks ?? '--';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
            }}
            tooltipTitle={remarks}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={remarks}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'rejectionRemarks',
      headerName: 'Rejection Remarks',
      hide:
        selectedOptions.includes(ReceiptColumns.REJECTION_REMARKS) ||
        (selectedTab !== 1 && selectedTab !== 4),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const rejectionRemarks = receipt?.rejectionRemarks ?? '--';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
            }}
            tooltipTitle={rejectionRemarks}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={rejectionRemarks}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      hide: selectedOptions.includes(ReceiptColumns.PAYMENT_STATUS),

      flex: 0.7,
      minWidth: 180,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const status = receipt.status;
        return (
          <VegaPill
            text={status}
            backgroundColor={getPillColorForStatus(status)?.LIGHT}
            textColor={getPillColorForStatus(status)?.DARK}
          />
        );
      },
    },
    {
      field: 'instumentNumber',
      headerName: 'Instrument Number',
      hide: selectedOptions.includes(ReceiptColumns.INSTRUMENT_NUMBER),
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const paymentMode = getInstrumentDetails(receipt);
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={paymentMode}
          />
        );
      },
    },
    // {
    //   field: 'instumentBankDetails',
    //   headerName: 'Instrument Bank Details',
    //   hide: selectedOptions.includes(ReceiptColumns.INSTRUMENT_BANK_DETAILS),

    //   flex: 0.7,
    //   minWidth: 150,
    // },
    {
      field: 'paymentType',
      headerName: 'Payment Type',
      hide: selectedOptions.includes(ReceiptColumns.PAYMENT_TYPE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const paymentMode = receipt.paymentMode
          ? toLowerCase(receipt.paymentMode)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={paymentMode}
          />
        );
      },
    },
    {
      field: 'overdueAmount',
      headerName: 'Overdue Amount',
      hide: selectedOptions.includes(ReceiptColumns.OVERDUE_AMOUNT),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const receiptAmountBreakUp = receipt.receiptAmountBreakUp
          ? receipt.receiptAmountBreakUp
          : null;
        let overDueAmount = '';
        if (receiptAmountBreakUp) {
          if (receiptAmountBreakUp.overDueAmount) {
            overDueAmount = StringUtility.formatToINR(
              receiptAmountBreakUp.overDueAmount
            );
          } else {
            overDueAmount = StringUtility.formatToINR(0);
          }
        } else {
          overDueAmount = StringUtility.formatToINR(0);
        }
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={overDueAmount}
          />
        );
      },
    },
    {
      field: 'bounceCharges',
      headerName: 'Bounce Charges',
      hide: selectedOptions.includes(ReceiptColumns.BOUNCE_CHARGE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const receiptAmountBreakUp = receipt.receiptAmountBreakUp
          ? receipt.receiptAmountBreakUp
          : null;
        let bounceCharges = '';
        if (receiptAmountBreakUp) {
          if (receiptAmountBreakUp.bounceCharges) {
            bounceCharges = StringUtility.formatToINR(
              receiptAmountBreakUp.bounceCharges
            );
          } else {
            bounceCharges = StringUtility.formatToINR(0);
          }
        } else {
          bounceCharges = StringUtility.formatToINR(0);
        }
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bounceCharges}
          />
        );
      },
    },
    {
      field: 'penalCharges',
      headerName: 'Penal Charges',
      hide: selectedOptions.includes(ReceiptColumns.PENAL_CHARGE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const receiptAmountBreakUp = receipt.receiptAmountBreakUp
          ? receipt.receiptAmountBreakUp
          : null;
        let penalCharges = '';
        if (receiptAmountBreakUp) {
          if (receiptAmountBreakUp.penalCharges) {
            penalCharges = StringUtility.formatToINR(
              receiptAmountBreakUp.penalCharges
            );
          } else {
            penalCharges = StringUtility.formatToINR(0);
          }
        } else {
          penalCharges = StringUtility.formatToINR(0);
        }
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={penalCharges}
          />
        );
      },
    },
    {
      field: 'otherCharges',
      headerName: 'Other Charges',
      hide: selectedOptions.includes(ReceiptColumns.OTHER_CHARGE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const receiptAmountBreakUp = receipt.receiptAmountBreakUp
          ? receipt.receiptAmountBreakUp
          : null;
        let otherCharges = '';
        if (receiptAmountBreakUp) {
          if (receiptAmountBreakUp.otherCharges) {
            otherCharges = StringUtility.formatToINR(
              receiptAmountBreakUp.otherCharges
            );
          } else {
            otherCharges = StringUtility.formatToINR(0);
          }
        } else {
          otherCharges = StringUtility.formatToINR(0);
        }
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={otherCharges}
          />
        );
      },
    },
    {
      field: 'collectorCodes',
      headerName: 'Collector Codes',
      hide: selectedOptions.includes(ReceiptColumns.COLLECTOR_CODES),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={`${getCollectorCode(receipt?.agentId)}`}
          />
        );
      },
    },
    {
      field: 'collectorName',
      headerName: 'Collector Name',
      hide: selectedOptions.includes(ReceiptColumns.COLLECTOR_NANE),

      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        const agentName = receipt.agentName
          ? toLowerCase(receipt.agentName)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={agentName}
          />
        );
      },
    },

    {
      field: 'approverName',
      headerName: 'Approver Name',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'approvalDate',
      headerName: 'Approval Date',
      flex: 1,
      minWidth: 250,
      renderCell: props => {
        const item = props.row as DetailedReceiptsListProps;
        const dateText = item?.approvalDate
          ? DateUtility.formatStringToDDMMYYYY(item?.approvalDate)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={dateText}
          />
        );
      },
    },
    {
      field: 'approverCode',
      headerName: 'Approver Code',
      flex: 1,
      minWidth: 250,
      renderCell: props => {
        const item = props.row as DetailedReceiptsListProps;
        const approverId = item?.approverId;
        const { collectorCodeForAgentId } = useClientAuth();
        const approverCode = approverId
          ? collectorCodeForAgentId(approverId)
          : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={approverCode}
          />
        );
      },
    },
    {
      field: 'rejectedBy',
      headerName: 'Rejected By',
      flex: 1,
      minWidth: 250,
      hide: selectedTab !== 4,
      renderCell: props => {
        const item = props.row as DetailedReceiptsListProps;
        const rejectedBy = item?.rejectedBy ?? '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={rejectedBy}
          />
        );
      },
    },
    {
      field: 'rejectedName',
      headerName: 'Rejected Name',
      flex: 1,
      minWidth: 250,
      hide: selectedTab !== 4,
      renderCell: props => {
        const item = props.row as DetailedReceiptsListProps;
        const rejectedName = item?.rejectedName ?? '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={rejectedName}
          />
        );
      },
    },
    {
      field: 'proof',
      headerName: 'Details',
      hide:
        selectedOptions.includes(ReceiptColumns.DETAILS) || selectedTab === 0,
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() =>
                onAction(
                  receipt,
                  receipt?.depositType === PaymentMode.CASH_IN_HAND &&
                    (receipt.status === ReceiptStatus.APPROVED ||
                      receipt.status === ReceiptStatus.APPROVED_NOT_RECONCILED)
                    ? ReceiptAction.EDIT
                    : ReceiptAction.VIEW
                )
              }
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />

            {(receipt.status === ReceiptStatus.APPROVED ||
              receipt.status === ReceiptStatus.APPROVED_NOT_RECONCILED ||
              receipt.status === ReceiptStatus.APPROVED_AND_RECONCILED) && (
              <VegaIconButton
                tooltipTitle="Download Receipt"
                onClick={() => downloadReceipt(receipt)}
                sx={{ bgcolor: '#E7EDFC' }}
                icon={
                  <Download sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />
                }
              />
            )}
            {receipt.status === ReceiptStatus.PENDING_DEPOSIT && (
              <VegaIconButton
                tooltipTitle="Create Deposit"
                onClick={() => onAction(receipt, ReceiptAction.CREATE)}
                sx={{ bgcolor: '#E7EDFC' }}
                icon={
                  <WalletOutlined
                    sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                  />
                }
              />
            )}
            {receipt.status === ReceiptStatus.PENDING_DEPOSIT && (
              <VegaIconButton
                tooltipTitle="Cancel Receipt"
                onClick={() => onAction(receipt, ReceiptAction.CANCEL)}
                sx={{ bgcolor: COLOR.RED.LIGHT }}
                icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
              />
            )}
          </Stack>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      hide:
        selectedOptions.includes(ReceiptColumns.ACTION) || selectedTab !== 0,
      flex: 1,
      minWidth: 250,
      renderCell: props => {
        const receipt = props.row as DetailedReceiptsListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onAction(receipt, ReceiptAction.VIEW)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
            <VegaIconButton
              tooltipTitle="Approve"
              sx={{ bgcolor: '#E7EDFC' }}
              icon={<AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />}
              onClick={() => {
                onAction(receipt, ReceiptStatus.APPROVED);
              }}
            />
            <VegaIconButton
              tooltipTitle="Reject"
              onClick={() => {
                onAction(receipt, ReceiptStatus.REJECTED);
              }}
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

export default HistoryReceiptColumn;
