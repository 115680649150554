import { AllocationStatus, AllocationTypes } from './allocations';
import { EscalationType } from './request/escalationsRequest';
import { DispositionCode, TrailNextAction } from './trail';

export interface LoanMaster {
  loanId: string;
  originBranch: string;
  usageOfLoan: string;
  loanProductType: string;
  natureOfLoan: string;
  valueOfSecurityOffered: number | null;
  natureOfInterest: string;
  presenceOfGuarantor: string;
  totalDisbursalMade: number;
  totalDisbursalFixed: number;
  orgterm: number;
  roi: number;
  emi: number;
  balanceTerm: number | null;
  industry: string | null;
  customerId: string;
  companyDate: string | null;
  lanStatus: string;
  loanProduct: string;
  loadDate: string;
}

export enum WriteOffStatus {
  TECHNICAL = 'TECHNICAL',
  ACTUAL = 'ACTUAL',
}
export interface LmsVariable {
  roi: number;
  emi: string;
  interestOutStanding: number;
  outStandingTotal: number;
  writeOffStatus: WriteOffStatus;
  creditAmount: number;
  debitAmount: number;
  modelParserAllocationType: string | null;
  accountCloserDate: string | null;
  accountStatus: string;
  allocationStatus: AllocationStatus;
  allocationDate: string;
  agentId: string;
  adjustedPrincipal: number | null;
  assetQuality: string;
  balanceTenor: number;
  bucket: string;
  branchCode: string;
  city: string;
  cycleDate: number;
  dateOfBirth: string;
  firstDisbursalDate: string;
  lastDisbursalDate: string;
  financeAmount: number;
  gender: string;
  installment: number;
  interestDue: number;
  maritalStatus: string;
  noOfChildren: number | null;
  noOfDependents: number;
  occupation: string;
  outstandingAmount: number;
  optedForMoratorium: string;
  overdueAmount: number;
  paymentMode: string;
  principalDue: number;
  repaymentFrequency: string;
  riskCategory: string;
  settlementReason: string | null;
  probableDateOfPayment: string | null;
  state: string;
  termInMonths: number;
  relationWithBorrower: string | null;
  applicationNo: string;
  loanId: string;
  nextInstallmentDate: string | null;
  preEmiAmount: number | null;
  chargeDue: number | null;
  brnCity: string | null;
  brnState: string | null;
  brnRegion: string | null;
  npaFlag: string | null;
  loadDate: string | null;
  prevBucket: string | null;
  bounceChangeDue: number | null;
  otherChargeDue: number | null;
  isBounce: boolean | null;
  paymentCategory: PaymentCategory;
  loanStatus: LoanStatus;
  escalationType: EscalationType;
  managerId: string | null;
  dispositionCode: DispositionCode;
  legalStatus: string | null;
  mob: number | null;
  referToField: boolean | null;
  ptpAmount: number;
  nextAction: TrailNextAction;
  penalChargeDue: number | null;
  nextActionDate: string;
  segment: string;
  otsRaised: boolean;
  otsId: string;
  predictedLoanStatus: string;
  otsBreached: boolean;
  dpd: number;
  pincode: string;
  customerName: string;
  dispositionDate: string | null;
}

export interface LoadPDCDetails {
  srno: string;
  loanId: string;
  bunchNo: string;
  ifscCode: string;
  bankName: string;
  branchName: string;
  micrCode: string;
  accountNumber: string;
  accountType: string;
  instrumentDate: string;
  instrumentNo: string;
  amount: number | string | null;
  location: string | null;
  pdcStatus: string;
  cancelReason: string | null;
  emiAmount: number;
  loadDate: string;
}

export type LoanOutstandingDetails = {
  loanId: string;
  principalOutstanding: number;
  daysPastDue: number;
  intPrinOverdue: number;
  loadDate: string | null;
};

export interface LoanData {
  loanId: string;
  loanMaster: LoanMaster;
  lmsVariables: LmsVariable | null;
  loanPdcDetails: LoadPDCDetails | null;
  loanOutstandingDetails: LoanOutstandingDetails | null;
  loanTransactions: any | null;
  customerDetails: CustomerDetail[];
  metaDataLoanAllocationDto: MetaDataLoanAllocationDto;
}
export interface CustomerAddressProps {
  loanId: string;
  customerNo: string;
  addressType: string;
  address: string;
  pincode: string;
  customerType: string;
}
export interface CustomerDataWithToken {
  loanId: string | null;
  customerName: string | null;
  customerNo: number;
  mobileNo: string;
  token: string;
}

export type LoanContactData = {
  loanId: string;
  contactNumber?: string;
  contactType?: string;
  contactHolderName?: string;
  // relationShipWithCustomer?: string
};
export interface LoanTransactionData {
  loanId: string;
  accountingDate: string;
  transactionId: string;
  subTransactionId: string;
  transactionAmount: number;
  transactionDescription: string;
  paymentMode: string;
  paymentPurpose: string;
  instrumentNo: string;
  instrumentStatus: string;
  instrumentType: string;
  bounceReason: string;
  yyyy: string | null;
  segment: string;
  loadDate: string;
}

export interface CustomerCommunicationDetail {
  id: number;
  loanId: string;
  customerNo: number;
  customerName: string;
  customerType: string;
  addressType: string;
  address: string;
  district: string;
  state: string;
  pincode: number;
  mobile1: string;
  mobile2: string;
  mobile3: string;
  email1: string;
  email2: string;
  latitude: number;
  longitude: number;
  segment: string;
  loadDate: Date;
}

export interface PortfoliioDetailsDto {
  bucket: string;
  count: number;
  totalOs: number;
  percentageOfTotal: number;
  normalizedCount: number;
  normalizedOs: number;
  normalizedPercentage: number;
  rollbackCount: number;
  rollbackOs: number;
  rollbackPercentage: number;
  stabilizedCount: number;
  stabilizedOs: number;
  stabilizedPercentage: number;
  rollForwardCount: number;
  rollForwardOs: number;
  rollForwardPercentage: number;
  loanIds: string[];
}
export interface TotalOverdueLoanAmountProps {
  agentId: string;
  sum: string;
  attribute: string;
  loanIds: string[];
  noOfLoanIds: number;
}

export interface LoanListData {
  loans: LoanData;
}

export interface MetaDataLoanAllocationDto {
  id: string;
  allocationId: string;
  requestId: string;
  region: string;
  branch: string;
  agentId: string;
  loanId: string;
  allocationTypeFromModelParser: string;
  status: string;
  allocationDate: string;
  allocationType: AllocationTypes;
  teleAllocatonType: string;
  expiresAt: string;
  teleCallStatus: string;
  processId: string;
  leadsetId: string;
  allocatedBy: string;
  teleAllocationId: string;
}
export interface CustomerDetail {
  id: string;
  borrowerKey: string;
  applicationNo: string;
  loanId: string;
  ucic: number;
  customerNo: number;
  applicantType: string;
  customerName: string;
  email: string | null;
  mobileNo: string;
  alternateNo: string | string[] | null;
  vertical: string;
  lanStatus: string;
  customerAddress: string;
  district: string;
  state: string;
  pincode: number;
  loadDate: string;
}

export interface BucketPerformanceResponse {
  '1 to 30': Item[];
  X: Item[];
  Normal: Item[];
  NPA: Item[];
}

export interface Item {
  [color: string]: number;
}

enum LoanStatus {
  NORMALISED = 'NORMALISED',
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  STABILISED = 'STABILISED',
}

enum PaymentCategory {
  CYCLE = 'CYCLE',
  CYCLE_70 = 'CYCLE_70',
  BALANCE_BUILDER = 'BALANCE_BUILDER',
  NORMAX = 'NORMAX',
}

export enum ContactType {
  COMMUNICATION = 'COMMUNICATION',
  DETAILS = 'DETAILS',
  ALTERNATE = 'ALTERNATE',
}

export enum TypeOfContact {
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  ALTERNATE_CONTACT = 'ALTERNATE_CONTACT',
}
