/** @format */

import { Avatar, Box, Grid, IconButton, Popover, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { ChevronDown, NotificationsIdle } from '../components/Icons/Icons';
import NotificationDrawer from '../components/NotificationDrawer';
import LmButton from '../components/common/LmButton';
import VegaText from '../components/common/VegaText';
import { useDrawer } from '../hooks/useDrawer';
import { useClientAuth } from '../providers/ClientProvider';
import { StringUtility } from '../utils/StringUtility';
import { useNotification } from '../providers/NotificationProvider';

const LmMainHeader = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { logout, user } = useClientAuth();
  const { totalNotifications } = useNotification();
  const {
    open: openDrawer,
    close: closeDrawer,
    isOpen: isDrawerOpen,
  } = useDrawer();

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userPreferredName = () => {
    var displayText = '';
    displayText = StringUtility.concatenateStrings(
      ' ',
      user?.firstName,
      user?.middleName,
      user?.lastName
    );
    if (user?.employeeCode) {
      displayText += ` (${user.employeeCode})`;
    }
    return displayText;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      ref={headerRef}
      sx={{
        top: 0,
        bgcolor: '#FFF',
        py: '0.75rem',
        px: '1.5rem',
        height: '4rem',
        borderBottom: '1px solid #DBDBDB',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item></Grid>
        <Grid item>
          <Stack direction={'row'} spacing="0.5rem" alignItems={'center'}>
            <IconButton onClick={() => openDrawer()}>
              <NotificationsIdle />
              {totalNotifications > 0 && (
                <VegaText text={totalNotifications}></VegaText>
              )}
            </IconButton>
            <Avatar sx={{ width: '2rem', height: '2rem' }} />
            <VegaText text={userPreferredName()} />
            <IconButton onClick={handleClick}>
              <ChevronDown />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ marginTop: 22 }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <Stack gap={1}>
            <VegaText text={userPreferredName()}></VegaText>
            <VegaText
              text={`Email: ${user?.email}`}
              sx={{ fontSize: 14 }}
            ></VegaText>
            <VegaText
              text={`Mobile: ${user?.mobile}`}
              sx={{ fontSize: 14 }}
            ></VegaText>
            <VegaText
              text={`Code: ${user?.employeeCode}`}
              sx={{ fontSize: 14 }}
            ></VegaText>
          </Stack>
          <Stack spacing={1} marginTop={1}>
            <LmButton
              sx={{ minWidth: 220 }}
              text={'Logout'}
              onClick={logout}
            ></LmButton>
          </Stack>
        </Box>
      </Popover>
      <NotificationDrawer
        open={isDrawerOpen}
        onClose={function (): void {
          closeDrawer();
        }}
      />
    </Box>
  );
};

export default LmMainHeader;
