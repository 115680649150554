/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, Skeleton, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoanService } from '../../Apis/LoanServices';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { PRIMARY } from '../../constants/style';
import {
  getAccountList,
  getAccountState,
  setSelectedRows,
} from '../../features/accountsSlice';
import { getAuth } from '../../features/authSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { AccountAgentViewModel } from '../../types/receiptManagment';
import {
  LoanFilterProps,
  LoanListRequest,
  TotalOverDueLoanAmountRequeset,
} from '../../types/request/loan';
import VegaDrawer from '../common/VegaDrawer';
import { VegaPill } from '../common/VegaPill';
import VegaText from '../common/VegaText';
import AccountFilterForm, {
  AccountsFilterFormDataType,
  FilterAttributes,
  FilterOP,
  POS,
} from './AccountFilterForm';
import { StringUtility } from '../../utils/StringUtility';
import { SearchBy } from '../../pages/ReceiptManagment/module/ListOfAccountsAgent';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import { toLowerCase } from '../../constants/commonFunction';
import { LoanAccountColumn } from '../../pages/LoanAccount/column/LoanAccountColumn';
import VegaColumnFilterSection, {
  AccountColumns,
  LoanAccountColumns,
} from '../common/VegaColumnFilterSection';
import { LoanData } from '../../types/loan';
import { setColumnFilters } from '../../features/filterColumnSlice';
import { UiModule } from '../../router/routes';
export interface FilterStateProps {
  posOp: string;
  posValue: string;
  bucket: string;
}
const key = '_' + UiModule.ACCOUNTS;
const ListOfAccounts = () => {
  const { routes } = useAppSelector(getAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, userForId } = useClientAuth();
  const { accounts, loading, totalItems, selectedRows } =
    useAppSelector(getAccountState);
  const [search, setSearch] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>(SearchBy.LAN);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [totalOverdueLoanAmount, setTotalOverdueLoanAmount] =
    useState<string>('0');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loadingForOverdueAmount, setLoadingForOverdueAmount] =
    useState<boolean>(false);
  const [filterForm, setFilterForm] = useState<
    Partial<AccountsFilterFormDataType>
  >({});
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const handleSelectionModelChange = (selectionModel: string[]) => {
    dispatch(setSelectedRows(selectionModel));
  };

  const getFilterArray = (
    attribute: FilterAttributes,
    op: FilterOP,
    value: string | number,
    filter: LoanFilterProps[]
  ) => {
    let newArray = [];
    const newObj = {
      attribute,
      op,
      value,
    };
    newArray = [...filter, newObj];
    return newArray;
  };
  const updateFilters = (options: string[]) => {
    dispatch(
      setColumnFilters({
        key,
        data: [...options],
      })
    );
    setSelectedOptions(options);
  };
  useEffect(() => {
    if (localStorage.getItem(key)) {
      setSelectedOptions(JSON.parse(localStorage.getItem(key)));
    }
  }, []);

  const getFilterArrayForRegionBranch = (
    attribute: FilterAttributes,
    op: FilterOP,
    values: string[],
    filter: LoanFilterProps[]
  ) => {
    let newArray = [];
    const newObj = {
      attribute,
      op,
      values,
    };
    newArray = [...filter, newObj];
    return newArray;
  };

  const fetchAccountsList = async () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        includeLoanOutstandingDetails: true,
        includeMetaDataLoanAllocation: true,
        page,
        size: pageSize,
        agentId: user?.id,
        applySorting: true,
      } as LoanListRequest;
      if (search.length) {
        if (searchBy === SearchBy.LAN) {
          body.searchPartialLoanId = search.toUpperCase();
        } else {
          body.customerName = search;
        }
      }

      if (Object.keys(filterForm).length) {
        if (filterForm.pos) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          if (filterForm.pos === POS.Greater_Than) {
            body.filters = getFilterArray(
              FilterAttributes.POS,
              FilterOP.GTE,
              filterForm.posValue,
              preFilterValue
            );
          } else if (filterForm.pos === POS.Less_Than) {
            body.filters = getFilterArray(
              FilterAttributes.POS,
              FilterOP.LTE,
              filterForm.posValue,
              preFilterValue
            );
          } else if (filterForm.pos === POS.Range) {
            const greterThanArr = getFilterArray(
              FilterAttributes.POS,
              FilterOP.GTE,
              filterForm.posMinValue,
              preFilterValue
            );
            const lessThanArr = getFilterArray(
              FilterAttributes.POS,
              FilterOP.LTE,
              filterForm.posMaxValue,
              preFilterValue
            );
            body.filters = [...greterThanArr, ...lessThanArr];
          } else {
            body.filters = getFilterArray(
              FilterAttributes.POS,
              FilterOP.EQ,
              filterForm.posValue,
              preFilterValue
            );
          }
        }
        if (filterForm.bucket) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.BUCKET,
            FilterOP.IN,
            filterForm.bucket,
            preFilterValue
          );
        }
        if (filterForm.cycle) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.CYCLE,
            FilterOP.IN,
            filterForm.cycle,
            preFilterValue
          );
        }
        if (filterForm.branch) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.BRANCH,
            FilterOP.IN,
            filterForm.branch,
            preFilterValue
          );
        }
        if (filterForm.region) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.REGION,
            FilterOP.IN,
            filterForm.region,
            preFilterValue
          );
        }
        if (filterForm.segment) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArray(
            FilterAttributes.SEGMENT,
            FilterOP.EQ,
            filterForm.segment,
            preFilterValue
          );
        }
        if (filterForm.loanStatus) {
          const preFilterValue = body.filters ? [...body.filters] : [];
          body.filters = getFilterArrayForRegionBranch(
            FilterAttributes.LOAN_STATUS,
            FilterOP.IN,
            filterForm.loanStatus,
            preFilterValue
          );
        }
      }
      dispatch(getAccountList(body));
    } catch (error) {}
  };

  const fetchTotalOverdueLoanAmount = async () => {
    setLoadingForOverdueAmount(true);
    const request = {
      attribute: 'OVERDUE',
      agentId: user.id,
    } as TotalOverDueLoanAmountRequeset;
    try {
      const response = await LoanService.getTotalOverdueAmountByAgentId(
        request
      );
      setTotalOverdueLoanAmount(response.sum);
      setLoadingForOverdueAmount(false);
    } catch (error) {
      setLoadingForOverdueAmount(false);
    }
  };

  useEffect(() => {
    if (user && routes) {
      if (search.length) {
        const accountList = setTimeout(() => {
          fetchAccountsList();
        }, 200);
        return () => clearTimeout(accountList);
      } else {
        fetchAccountsList();
      }
    }
  }, [page, pageSize, search, user]);

  useEffect(() => {
    user && fetchTotalOverdueLoanAmount();
  }, [user]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack direction={'row'} gap={2}>
              <VegaText
                text={'Total Overdue amount'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              {loadingForOverdueAmount ? (
                <Skeleton width={80} />
              ) : (
                <VegaPill
                  text={StringUtility.formatToINR(totalOverdueLoanAmount)}
                  backgroundColor={PRIMARY.lighter}
                  textColor={PRIMARY.darkBlue}
                />
              )}
            </Stack>
          );
        }}
        renderRightView={() => (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaColumnFilterSection
              columns={LoanAccountColumns}
              selectedOptions={selectedOptions}
              setSelectedOptions={updateFilters}
            />
            <LmFilterSection
              search={search}
              serachByComponent={
                <Box
                  sx={{
                    width: 150,
                  }}
                >
                  <VegaSelect
                    options={getSearchByOptions()}
                    value={searchBy}
                    onChange={selected => {
                      const value = selected.target.value as string;
                      setSearchBy(value);
                      setSearch('');
                      setPage(0);
                    }}
                  />
                </Box>
              }
              setSearch={setSearch}
              onFilter={() => {
                openFilterDrawer();
              }}
            />
          </Stack>
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={accounts}
          loading={loading}
          columns={LoanAccountColumn(selectedOptions)}
          idColumn="loanId"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
          checkboxSelection
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={() => {
          closeFilterDrawer();
          setFilterForm({});
        }}
      >
        <AccountFilterForm
          onClose={closeFilterDrawer}
          setFilterForm={setFilterForm}
          filterForm={filterForm}
          search={search}
          searchBy={searchBy}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfAccounts;

const getSearchByOptions = () => {
  const options = Object.keys(SearchBy).map(
    search =>
      ({
        label: toLowerCase(search),
        value: search,
      } as VegaSelectOption)
  );
  return options;
};
