/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import { BORDER_COLLECTION, PRIMARY } from '../../../constants/style';
import { ROUTES } from '../../../router/routes';
import { useAppSelector } from '../../../store';
import { AllocationMetaData } from '../../../types/allocations';
import { LmsVariable } from '../../../types/loan';
import { StringUtility } from '../../../utils/StringUtility';
import VegaText from '../../common/VegaText';
import { loanRequestSelector } from '../../../features/loanSlice';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../common/VegaIconButton';

interface IProps {
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

const ListOfTopCollectionAccount = ({
  page,
  pageSize,
  setPage,
  setPageSize,
}: IProps) => {
  const { loading, loans, totalItems } = useAppSelector(loanRequestSelector);
  const navigate = useNavigate();

  return (
    <>
      <Stack
        gap={2}
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          p: 2,

          bgcolor: 'white',
        }}
      >
        <VegaText
          text={'Top Collection Accounts'}
          fontWeight={600}
          fontSize={17}
          color={'black'}
        />

        <VegaDataGrid
          bgColor="white"
          data={loans}
          loading={loading}
          columns={getColumnDefinition({
            onViewDetails: loan => {
              navigate(
                ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID.replace(
                  ':loanAccountId',
                  loan.loanId
                )
              );
            },
          })}
          idColumn="loanId"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
        />
      </Stack>
    </>
  );
};

export default ListOfTopCollectionAccount;

const getColumnDefinition = (data: {
  onViewDetails: (account: AllocationMetaData) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.customerName) {
            displayText = getCustomerName.customerName;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'amountoverdue',
      headerName: 'Amount Overdue',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan?.lmsVariables?.overdueAmount ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Bucket',
      headerName: 'Bucket',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan?.lmsVariables?.bucket ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'nextActionDate',
      headerName: 'PTP Date',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan?.lmsVariables?.nextActionDate ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Loaction',
      headerName: 'Location',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan?.lmsVariables?.brnRegion ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'allocationexpirydate',
      headerName: 'Allocation Expiry Date',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return <VegaText text={'-'} fontWeight={500} fontSize={'0.875rem'} />;
      },
    },
    {
      field: 'loanId',
      headerName: 'Loan Account Number',
      flex: 0.7,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan.loanId ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'pos',
      headerName: 'POS',
      flex: 0.5,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        var displayText = '-';
        if (loan.lmsVariables) {
          const outstandingDetails = loan.lmsVariables as LmsVariable;
          if (outstandingDetails.outstandingAmount) {
            displayText = StringUtility.formatToINR(
              outstandingDetails.outstandingAmount
            );
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.9,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        return (
          <Stack direction={'row'} gap={1}>
            {/* <VegaButton
              text={'View Details'}
              variant="text"
              onClick={() => data.onViewDetails(loan)}
            /> */}
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => data.onViewDetails(loan)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
