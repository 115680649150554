import axios from 'axios';
import { ApiService } from '../types/api';
import {
  GenerateTokenApiRequest,
  GenerateTokenApiResponse,
  InvalidateTokenApiRequest,
  VerifyTokenApiRequest,
  VerifyTokenResponse,
} from '../types/client';
import { ResourceDto, VegaUser } from '../types/claim';
import { RoleDto } from '../types/roleType';

const Endpoint = {
  LOGIN: '/user/auth/capri',
  VERIFY_TOKEN: '/user/auth/verifyToken',
  INVALID_TOKEN: '/user/auth/invalidateToken',
  RESOURCE_ACCESS: '/rbac/user/resource/access',
  GET_USER_DETAILS: '/user/:id',
  GET_ROLES: '/rbac/user/roles',
};

export class ClientServices extends ApiService {
  static async login(
    request: Partial<GenerateTokenApiRequest>
  ): Promise<GenerateTokenApiResponse> {
    const endpoint = Endpoint.LOGIN;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getRolesByEmail(email: string): Promise<RoleDto[]> {
    const endpoint = Endpoint.GET_ROLES;
    const response = await axios.get(endpoint, { params: { email } });
    return response.data;
  }
  static async verifyToken(
    request: Partial<VerifyTokenApiRequest>
  ): Promise<VerifyTokenResponse> {
    const endpoint = Endpoint.VERIFY_TOKEN;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async inValidToken(
    request: Partial<InvalidateTokenApiRequest>
  ): Promise<InvalidateTokenApiRequest> {
    const endpoint = Endpoint.INVALID_TOKEN;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getResourcesForUser(userId: string): Promise<ResourceDto[]> {
    const endpoint = Endpoint.RESOURCE_ACCESS;
    const response = await axios.get(endpoint, {
      params: { resourceType: 'UI', userId: userId },
    });
    return response.data;
  }

  static async getUserDetails(id: string): Promise<VegaUser> {
    const endpoint = Endpoint.GET_USER_DETAILS.replace(':id', id);
    const response = await axios.get(endpoint);
    return response.data;
  }
}
