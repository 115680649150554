import { Box, Stack } from '@mui/material';
import React from 'react';
import ViewWriteOffComponent from '../../components/IncentivesGamifications/modules/ViewWriteOffComponent';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { getIncentivesGamificationsState } from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppSelector } from '../../store';

interface IProps {
  selectedWriteOffPolicy: string;
  setSelectedWriteOffPolicy: React.Dispatch<React.SetStateAction<string>>;
}

const WriteOffViewPolicy = ({
  selectedWriteOffPolicy,
  setSelectedWriteOffPolicy,
}: IProps) => {
  const { policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );
  return (
    <>
      {policyDetailsState?.writeOffRules &&
      Object.keys(policyDetailsState?.writeOffRules).length ? (
        <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
          <Stack direction={'row'} gap={2}>
            {Object.keys(policyDetailsState?.writeOffRules).map(
              (bucket: string) => (
                <Box
                  key={bucket}
                  sx={{
                    px: 1,
                    pb: 1,
                    cursor: 'pointer',
                    borderBottom:
                      selectedWriteOffPolicy === bucket
                        ? `1px solid #1047DC`
                        : '1px solid transparant',
                  }}
                  onClick={() => setSelectedWriteOffPolicy(bucket)}
                >
                  <VegaText
                    color={
                      selectedWriteOffPolicy === bucket ? '#1047DC' : '#676B76'
                    }
                    fontSize={12}
                    fontWeight={500}
                    text={toLowerCase(bucket)}
                  />
                </Box>
              )
            )}
          </Stack>
        </VegaPageContent>
      ) : (
        ''
      )}
      {policyDetailsState?.writeOffRules &&
        policyDetailsState?.writeOffRules[selectedWriteOffPolicy] && (
          <ViewWriteOffComponent
            rules={policyDetailsState?.writeOffRules[selectedWriteOffPolicy]}
          />
        )}
    </>
  );
};

export default WriteOffViewPolicy;
