import { Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../../constants/commonFunction';
import { PRIMARY } from '../../../../constants/style';
import {
  getIncentivesGamificationsState,
  initailFixedLanPayoutRange,
  initailRangeLanPayoutRange,
  setWriteOffruleState,
} from '../../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { isWriteOfEmptyOrUndefined } from '../../../../pages/Configurations/Configurations';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ParameterDefinitionType } from '../../../../types/incentives';
import {
  IncentiveWriteOffRule,
  IncentiveWriteOffRulesRequest,
  LanPayoutRange,
  PosWaiverRange,
} from '../../../../types/request/incentives';
import { DeleteIcon } from '../../../Icons/Icons';
import LmTextField from '../../../common/LmTextField';
import VegaButton from '../../../common/VegaButton';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaIconButton from '../../../common/VegaIconButton';
import { VegaPill } from '../../../common/VegaPill';
import VegaSelect, { VegaSelectOption } from '../../../common/VegaSelect';

interface RulesIncentiveProposedProps {
  rules: IncentiveWriteOffRule;
  index: number;
  onDelete?: (index: number) => void;
}

const RulesIncentiveProposed = ({
  rules,
  index,
  onDelete,
}: RulesIncentiveProposedProps) => {
  const { writeOffruleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId, edit } = useParams();

  const handlePosWaiverRangeChange = (
    key: keyof PosWaiverRange,
    value: any
  ) => {
    const rulesIncentiveObj = {
      ...rules.posWaiverRange,
      [key]: value,
    };
    handleWriteOffChange('posWaiverRange', rulesIncentiveObj);
  };
  const handleWriteOffChange = (
    key: keyof IncentiveWriteOffRule,
    value: any
  ) => {
    const spreadRulesIncentivePolicy = [...writeOffruleState.rules];
    const rulesIncentiveObj = {
      ...rules,
      [key]: value,
    };

    spreadRulesIncentivePolicy.splice(index, 1, rulesIncentiveObj);
    const basePoliciyRule = {
      ...writeOffruleState,
      rules: spreadRulesIncentivePolicy,
    } as IncentiveWriteOffRulesRequest;
    dispatch(setWriteOffruleState(basePoliciyRule));
  };
  const handleLanPayoutChange = (
    key: keyof LanPayoutRange,
    value: string | number,
    index
  ) => {
    const spreadLayoutRange = [...rules.lanPayoutRange];
    const spreadLayoutRangeObj = { ...rules.lanPayoutRange[index] };
    const layoutRangeObj = {
      ...spreadLayoutRangeObj,
      [key]: value,
    };

    spreadLayoutRange.splice(index, 1, layoutRangeObj);

    handleWriteOffChange('lanPayoutRange', spreadLayoutRange);
  };

  const addLanPayout = () => {
    const lastLanPayout = rules.lanPayoutRange[rules.lanPayoutRange.length - 1];
    if (
      lastLanPayout.parameterDefinitionType === ParameterDefinitionType.FIXED
    ) {
      const updateLanPayout = {
        ...initailFixedLanPayoutRange,
        parameterExact: lastLanPayout.parameterExact + 1,
      } as LanPayoutRange;
      const existingLanPayoutRange = [...rules.lanPayoutRange, updateLanPayout];
      handleWriteOffChange('lanPayoutRange', existingLanPayoutRange);
    } else {
      const updateLanPayout = {
        ...initailRangeLanPayoutRange,
        parameterMin: lastLanPayout.parameterMax + 1,
      } as LanPayoutRange;
      const existingLanPayoutRange = [...rules.lanPayoutRange, updateLanPayout];
      handleWriteOffChange('lanPayoutRange', existingLanPayoutRange);
    }
  };

  const onDeleteLanPayout = (index: number) => {
    const existingLanPayoutRange = [...rules.lanPayoutRange];
    existingLanPayoutRange.splice(index, 1);
    handleWriteOffChange('lanPayoutRange', existingLanPayoutRange);
  };

  const isLanPayoutRangeValue = () => {
    const isEmptyValue =
      writeOffruleState?.rules.length &&
      writeOffruleState?.rules?.every((range: IncentiveWriteOffRule) => {
        return range.lanPayoutRange.some((lanPayoutRange: LanPayoutRange) => {
          if (
            lanPayoutRange?.parameterDefinitionType ===
            ParameterDefinitionType.FIXED
          ) {
            return (
              isWriteOfEmptyOrUndefined(lanPayoutRange.payoutAmountValue) ||
              isWriteOfEmptyOrUndefined(lanPayoutRange.parameterExact)
            );
          } else {
            return (
              isWriteOfEmptyOrUndefined(lanPayoutRange.payoutAmountValue) ||
              isWriteOfEmptyOrUndefined(lanPayoutRange.parameterMax) ||
              isWriteOfEmptyOrUndefined(lanPayoutRange.parameterMin)
            );
          }
        });
      });
    return isEmptyValue;
  };

  return (
    <Stack
      sx={{
        borderRadius: '12px',
        p: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        mb: 1,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={'1rem'}
      >
        <VegaPill
          text={`Parameter ${index + 1}`}
          textColor={PRIMARY.darkBlue}
          backgroundColor={PRIMARY.lighter}
        />
        {!policyId && index !== 0 && (
          <VegaIconButton
            tooltipTitle="Delete"
            onClick={() => onDelete(index)}
            icon={<DeleteIcon />}
          />
        )}
        {edit && index !== 0 && (
          <VegaIconButton
            tooltipTitle="Delete"
            onClick={() => onDelete(index)}
            icon={<DeleteIcon />}
          />
        )}
      </Stack>
      <Stack mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <VegaFormInputField label={'Pos Waiver Min'}>
              <LmTextField
                type="number"
                placeholder="Min"
                value={rules.posWaiverRange?.parameterMin}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handlePosWaiverRangeChange('parameterMin', value);
                }}
              />
            </VegaFormInputField>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <VegaFormInputField label={'Pos Waiver Max'}>
              <LmTextField
                type="number"
                placeholder="Max"
                value={rules.posWaiverRange?.parameterMax}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handlePosWaiverRangeChange('parameterMax', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Stack>
      {rules.lanPayoutRange.map((lanPayout: LanPayoutRange, index) => (
        <Stack
          key={index}
          sx={{
            borderRadius: '12px',
            p: 2,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            mb: 1,
          }}
        >
          <>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={1}
            >
              <VegaPill
                text={`No of case ${index + 1}`}
                textColor={PRIMARY.darkBlue}
                backgroundColor={PRIMARY.lighter}
              />

              {!policyId && index !== 0 && (
                <VegaIconButton
                  tooltipTitle="Delete"
                  onClick={() => onDeleteLanPayout(index)}
                  icon={<DeleteIcon />}
                />
              )}
              {edit && index !== 0 && (
                <VegaIconButton
                  tooltipTitle="Delete"
                  onClick={() => onDeleteLanPayout(index)}
                  icon={<DeleteIcon />}
                />
              )}
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <VegaFormInputField label={'Defination Type'}>
                  <VegaSelect
                    options={parameterDefinitionTypeOption()}
                    value={lanPayout.parameterDefinitionType}
                    onChange={e => {
                      const value = e.target.value as string;
                      handleLanPayoutChange(
                        'parameterDefinitionType',
                        value,
                        index
                      );
                    }}
                  />
                </VegaFormInputField>
              </Grid>

              {lanPayout.parameterDefinitionType ===
              ParameterDefinitionType.FIXED ? (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <VegaFormInputField label={'Parameter Exact'}>
                    <LmTextField
                      type="number"
                      placeholder="Enter Exact Value"
                      value={lanPayout.parameterExact}
                      onChange={e => {
                        const value =
                          e.target.value && parseFloat(e.target.value);
                        handleLanPayoutChange('parameterExact', value, index);
                      }}
                    />
                  </VegaFormInputField>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'Parameter Min'}>
                      <LmTextField
                        type="number"
                        placeholder="Enter Min Value"
                        value={lanPayout.parameterMin}
                        onChange={e => {
                          const value =
                            e.target.value && parseFloat(e.target.value);
                          handleLanPayoutChange('parameterMin', value, index);
                        }}
                      />
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'Parameter Max'}>
                      <LmTextField
                        type="number"
                        placeholder="Enter Max Value"
                        value={lanPayout.parameterMax}
                        onChange={e => {
                          const value =
                            e.target.value && parseFloat(e.target.value);
                          handleLanPayoutChange('parameterMax', value, index);
                        }}
                      />
                    </VegaFormInputField>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <VegaFormInputField label={'Payout Amount Value (Percentage)'}>
                  <LmTextField
                    type="number"
                    placeholder="Enter Payout Amount Value"
                    value={lanPayout.payoutAmountValue}
                    onChange={e => {
                      let value = e.target.value && parseFloat(e.target.value);
                      if (value > 100) {
                        value = 100;
                      }
                      handleLanPayoutChange('payoutAmountValue', value, index);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
            </Grid>
          </>
        </Stack>
      ))}

      <Stack direction={'row'} justifyContent={'start'}>
        <VegaButton
          text="Add Case"
          onClick={addLanPayout}
          disabled={isLanPayoutRangeValue()}
        />
      </Stack>
    </Stack>
  );
};

export default RulesIncentiveProposed;
const parameterDefinitionTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(ParameterDefinitionType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};
