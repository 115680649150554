import { Stack } from '@mui/material';
import { useState } from 'react';
import { useHolidayName } from '../../providers/HolidayNameProvider';
import { DateUtility } from '../../utils/DateUtlility';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';

interface HolidayNameStateProps {
  startDate: string;
  endDate: string;
}
const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

interface IProps {
  onClose: () => void;
}

const HolidayNameFilterForm = ({ onClose }: IProps) => {
  const { getHolidayList } = useHolidayName();

  const [holidayNameFilter, setHolidayNameFilter] =
    useState<HolidayNameStateProps>({
      startDate: TODAY.toISOString(),
      endDate: TOMORROW.toISOString(),
    });

  const handleDateChange = (key: keyof HolidayNameStateProps, date: any) => {
    const dateToString =
      DateUtility.parseDateFromDatePicker(date)?.toISOString();
    setHolidayNameFilter({
      ...holidayNameFilter,
      [key]: dateToString,
    });
  };

  const onClearClick = () => {
    getHolidayList({});
    setHolidayNameFilter({
      startDate: TODAY.toISOString(),
      endDate: TOMORROW.toISOString(),
    });
    onClose();
  };
  const onSelectClick = () => {
    getHolidayList({
      fromDate: holidayNameFilter.startDate,
      toDate: holidayNameFilter.endDate,
    });
    onClose();
  };
  const disabled =
    holidayNameFilter.startDate === null ||
    holidayNameFilter.endDate === null ||
    !holidayNameFilter.startDate?.length ||
    !holidayNameFilter.endDate?.length;
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClearClick}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton
              text="Apply Filter"
              onClick={onSelectClick}
              disabled={disabled}
            />
          </Stack>
        );
      }}
    >
      <Stack gap={2}>
        <VegaFormInputField label={'Start Date'}>
          <VegaDatePicker
            value={holidayNameFilter.startDate}
            onChange={date => handleDateChange('startDate', date)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'End Date'}>
          <VegaDatePicker
            value={holidayNameFilter.endDate}
            onChange={date => handleDateChange('endDate', date)}
          />
        </VegaFormInputField>
      </Stack>
    </VegaDrawerContent>
  );
};

export default HolidayNameFilterForm;
