import { AddCircleOutline, VisibilityOutlined } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LeaveService } from '../../Apis/LeaveService';
import CreateLeaveDrawer from '../../components/Leave/CreateLeaveDrawer';
import LeaveDetailsDialog from '../../components/Leave/LeaveDetailsDialog';
import LeaveMangementFilter from '../../components/Leave/LeaveMangementFilter';
import LmButton from '../../components/common/LmButton';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaIconButton from '../../components/common/VegaIconButton';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';
import VegaText from '../../components/common/VegaText';
import { PRIMARY } from '../../constants/style';
import {
  fetchLeaveList,
  leaveRequestSelector,
} from '../../features/leaveRequestSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';
import { CreateLeaveRequest } from '../../types/request/leave';
import { COLOR } from '../../utils/ColorUtility';
import { DateIndicateEnum, DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';

const ApplyLeave = () => {
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { user } = useClientAuth();
  const {
    leaves,
    loading: gridLoading,
    page: currentPage,
    totalItems,
  } = useAppSelector(leaveRequestSelector);

  const [page, setPage] = useState<number>(0);
  const {
    open: openApplyLeaveDrawer,
    close: closeApplyLeaveDrawer,
    isOpen: isApplyLeaveDrawerOpen,
  } = useDrawer(false);
  const {
    open: openDetailDialog,
    close: closeDetailsDialog,
    isOpen: isDetailsDialogOpen,
    props: detailsDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  function onPageChange(pageNumber: number) {
    if (page == pageNumber) return;
    setPage(pageNumber);
  }

  const onfilterClick = (selectedStatusFilter: string) => {
    closeFilterDrawer();
    setStatus(selectedStatusFilter);
  };

  const onClearAllClick = () => {
    closeFilterDrawer();
    setStatus(null);
  };

  const onCreateLeave = async formData => {
    setLoading(true);
    const request: Partial<CreateLeaveRequest> = {
      userId: user.id,
      leaveType: formData.typeOfLeave,
      startDate: DateUtility.toISOString(
        formData.startDate,
        DateIndicateEnum.PLUS
      ),
      endDate: DateUtility.toISOString(formData.endDate, DateIndicateEnum.PLUS),
      startHalf: formData.startDateHalf,
      endHalf: formData.endDateHalf,
      reasonRemarks: formData.reason,
    };
    try {
      await LeaveService.createLeave(request);
      setSnackbar('Leave applied successfully!!!');
      dispatch(
        fetchLeaveList({
          userId: user.id,
          status: [status],
          page: page,
          size: 10,
        })
      );
      setLoading(false);
      closeApplyLeaveDrawer();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
      closeApplyLeaveDrawer();
    }
  };

  useEffect(() => {
    if (user) {
      status
        ? dispatch(
            fetchLeaveList({
              userId: user.id,
              status: [status],
              page: page,
              size: 10,
            })
          )
        : dispatch(fetchLeaveList({ userId: user.id, page: page, size: 10 }));
    }
  }, [page, status, user]);

  return (
    <div>
      <VegaPageHeader
        title="Leave Dashboard"
        renderRightView={() => (
          <LmButton
            text="Apply Leave"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              openApplyLeaveDrawer();
            }}
          />
        )}
      />

      <VegaPageHeader
        renderRightView={() => {
          return (
            <LmFilterSection
              onFilter={() => {
                openFilterDrawer();
              }}
            />
          );
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <LeaveMangementFilter
          onfilterClick={onfilterClick}
          defaultValue={status}
          onClearAllClick={onClearAllClick}
        />
      </VegaDrawer>
      <VegaPageContent>
        <VegaDataGrid
          page={currentPage}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          // data={leaves}
          data={leaves}
          columns={getColumnDefinition({
            onViewDetailsClick: (leave: LeaveViewModel) => {
              openDetailDialog({ leave: leave });
            },
          })}
          loading={gridLoading}
          idColumn="id"
        />
      </VegaPageContent>
      <CreateLeaveDrawer
        loading={loading}
        open={isApplyLeaveDrawerOpen}
        onClose={function (): void {
          closeApplyLeaveDrawer();
        }}
        onSubmit={onCreateLeave}
      />
      <LeaveDetailsDialog
        open={isDetailsDialogOpen}
        onClose={function (): void {
          closeDetailsDialog();
        }}
        leave={detailsDialogProps.leave}
      />
    </div>
  );
};
export default ApplyLeave;

const getColumnDefinition = (data: {
  onViewDetailsClick: (leave: LeaveViewModel) => void;
}) => {
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const COLUMN_DEF: GridColumns = [
    {
      field: 'TypeofLeave',
      headerName: 'Type Of Leave',
      flex: 0.7,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.typesofLeave}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      flex: 1,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={`${DateUtility.formatStringToDDMMYYYY(Leave?.startDate)}`}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.startDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={`${DateUtility.formatStringToDDMMYYYY(Leave?.endDate)}`}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.endDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'Reason',
      headerName: 'Reason/Remark',
      flex: 0.6,
      minWidth: 350,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.reason}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 130,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaPill
            text={getStatusValue(Leave.status)}
            backgroundColor={getColorForStatus(Leave.status).LIGHT}
            key={Leave.status}
            textColor={getColorForStatus(Leave.status).DARK}
          />
        );
      },
    },
    {
      field: 'ApproversName',
      headerName: 'Approvers Name',
      flex: 0.6,
      minWidth: 149,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        let displayText = '';
        if (Leave?.approversName) {
          displayText = getUserName(Leave.approversName);
        }
        return (
          <VegaText
            // text={Leave.approversName}
            text={displayText}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 0.6,
      minWidth: 119,

      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => data.onViewDetailsClick(Leave)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
          // <VegaButton
          //   text="View Details"
          //   onClick={() => data.onViewDetailsClick(Leave)}
          //   variant="text"
          // />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getColorForStatus = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return COLOR.RED;
    case 'APPROVED':
      return COLOR.GREEN;
    case 'APPLIED':
      return COLOR.ORANGE;
  }
};

const getStatusValue = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return LeaveStatus.REJECTED;
    case 'APPROVED':
      return LeaveStatus.APPROVED;
    case 'APPLIED':
      return 'PENDING';
  }
};
