import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentivePolicyRulesRequest,
  PanaltyCondition,
  PanaltyPolicyRangeProps,
} from '../../../types/request/incentives';
import { BucketEnum } from '../../../types/targets';
import { getOrdinalNumber } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { PlusCircleIcon } from '../../Icons/Icons';
import VegaButton from '../../common/VegaButton';
import VegaText from '../../common/VegaText';
import AddPenalty from './AddPenalty';
import { ConditionTypeForPenalty } from '../../../types/incentives';

export const conditionObj = {
  conditionType: ConditionTypeForPenalty.NORMALIZATION,
  conditionValue: 60.0,
  conditionCutoffDayOfMonth: 21,
};

const paneltyPolicy = {
  condition: [
    {
      ...conditionObj,
    },
  ],
  amountType: 'PERCENTAGE_OF_BASE_AMOUNT',
  amountValue: 15.0,
  amountMax: null,
  amountMin: null,
};

const PenaltyIncentiveComponent = () => {
  const { policyId } = useParams();
  const { policyRuleState, selectedTab, policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const isEmptyOrUndefined = (value: any) => {
    return (
      typeof value === 'undefined' ||
      value === null ||
      value === '' ||
      value === 0
    );
  };

  const isParameterValue = () => {
    const isEmptyValue =
      policyRuleState.penaltyRange?.length &&
      policyRuleState?.penaltyRange.some(
        (range: PanaltyPolicyRangeProps) =>
          isEmptyOrUndefined(range.amountType) ||
          isEmptyOrUndefined(range.amountValue)
      );
    return isEmptyValue;
  };
  const isConditionValue = () => {
    const isEmptyValue =
      policyRuleState.penaltyRange?.length &&
      policyRuleState?.penaltyRange.every((range: PanaltyPolicyRangeProps) => {
        return range.condition.some(
          (condition: PanaltyCondition) =>
            isEmptyOrUndefined(condition.op) ||
            isEmptyOrUndefined(condition.conditionType) ||
            isEmptyOrUndefined(condition.value)
        );
      });
    return isEmptyValue;
  };

  const disabled =
    policyRuleState?.penaltyRange && (isParameterValue() || isConditionValue());

  const handlePolicyRule = (
    key: keyof IncentivePolicyRulesRequest,
    value: any
  ) => {
    const policyRuleStateObj = {
      ...policyRuleState,
      [key]: value,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(policyRuleStateObj));
  };

  const onAddPenaltyIncentive = () => {
    handlePolicyRule('penaltyRange', [
      ...policyRuleState.penaltyRange,
      paneltyPolicy,
    ]);
  };
  const onDeletePenaltyIncentive = (index: number) => {
    const spreadPenaltyPoicy = [...policyRuleState.penaltyRange];
    spreadPenaltyPoicy.splice(index, 1);
    handlePolicyRule('penaltyRange', spreadPenaltyPoicy);
  };

  const onSubmitPolicy = () => {
    const getPolicyId = policyId ?? idFromURL;
    if (getPolicyId) {
      IncentiveServices.addRulesForPolicy({
        ...policyRuleState,
        bucket: getBucket(),
        incentiveBasePolicyId: getPolicyId,
      })
        .then(response => {
          dispatch(setPolicyDetailsState(response));
          setSnackbar('Add penalty incentive successfully !!!');
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      setSnackbar('No incentive base policy id for this bucket', 'error');
    }
  };

  const getBucket = () => {
    if (policyDetailsState.classType === BucketEnum.NPA) {
      return BucketEnum.NPA;
    } else {
      if (selectedTab === 1) {
        return BucketEnum.BUCKET1;
      } else if (selectedTab === 2) {
        return BucketEnum.BUCKET2;
      } else if (selectedTab === 3) {
        return BucketEnum.BUCKET3;
      } else {
        return BucketEnum.BUCKETX;
      }
    }
  };

  useEffect(() => {
    if (!policyRuleState.penaltyRange) {
      handlePolicyRule('penaltyRange', []);
    }
  }, []);

  return (
    <>
      <Stack
        gap={2}
        sx={{
          width: '100%',
          p: '1.5rem',
        }}
      >
        <VegaText
          text={'penalty Incentive'}
          fontWeight={600}
          fontSize={'1.0625rem'}
        />

        {policyRuleState?.penaltyRange &&
          policyRuleState.penaltyRange.map(
            (penaltyIncentive: PanaltyPolicyRangeProps, index: number) => (
              <AddPenalty
                key={index}
                penaltyIncentive={penaltyIncentive}
                index={index}
                onDelete={onDeletePenaltyIncentive}
              />
            )
          )}
        <Box>
          <VegaButton
            variant="text"
            text={`Add ${
              policyRuleState?.penaltyRange?.length + 1
            }${getOrdinalNumber(
              policyRuleState?.penaltyRange?.length + 1
            )} parameter`}
            startIcon={<PlusCircleIcon />}
            onClick={onAddPenaltyIncentive}
          />
        </Box>
        <Box textAlign={'end'}>
          <VegaButton
            text={`Save penalty incentive`}
            onClick={onSubmitPolicy}
            disabled={disabled}
          />
        </Box>
      </Stack>
    </>
  );
};

export default PenaltyIncentiveComponent;
