import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  fetchEscalations,
  getEscalationsState,
} from '../../features/escalationsSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { EscalationsDto } from '../../types/escalationsType';
import { EscalationType } from '../../types/request/escalationsRequest';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmFilterSection from '../common/LmFilterSection';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaText from '../common/VegaText';

const ListOfOts = () => {
  const { loading, error, escalations, totalItems } =
    useAppSelector(getEscalationsState);
  const dispatch = useAppDispatch();
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    dispatch(
      fetchEscalations({
        page,
        size,
        managerId: userId,
        escalationType: EscalationType.OTS,
      })
    );
  }, [page, size]);

  useEffect(() => {
    if (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);
  return (
    <>
      <VegaPageHeader
        sx={{
          pb: 0,
          bgcolor: 'transparent',
          borderBottom: 'none',
        }}
        renderLeftView={() => {
          return (
            <>
              <VegaText
                text={'OTS'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </>
          );
        }}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
      />
      <VegaPageContent mt={2}>
        <VegaDataGrid
          idColumn="id"
          data={escalations}
          columns={getColumnDefinition()}
          loading={loading}
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfOts;

const getColumnDefinition = () => {
  const { userForId } = useClientAuth();
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const COL_DEF: GridColumns = [
    {
      field: 'userId',
      headerName: 'Agent Name',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        let displayText = '';
        if (escalations?.userId) {
          displayText = getUserName(escalations.userId);
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'managerId',
      headerName: 'Supervisor',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        let displayText = '';
        if (escalations?.managerId) {
          displayText = getUserName(escalations.managerId);
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'triggeredDate',
      headerName: 'Start Date',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMMMYYYY(
              escalations.triggeredDate
            )}
          />
        );
      },
    },
    {
      field: 'expiryTime',
      headerName: 'End Date',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const endDate = escalations.expiryDate
          ? DateUtility.formatStringToDDMMMMYYYY(escalations.expiryDate)
          : '--';
        return <VegaText text={endDate} />;
      },
    },
    // {
    //   field: 'parentEscalationId',
    //   headerName: 'Supervisor',
    //   flex: 0.5,
    // },

    {
      field: 'escalationReason',
      headerName: 'Reason/Remarks',
      flex: 0.7,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: () => {
        return <VegaButton variant="text" text="View Details" />;
      },
    },
  ];
  return COL_DEF;
};
