import axios from 'axios';
import { ApiService } from '../types/api';
import { AnalyticsQueryRequest } from '../types/request/analyticsRequest';
const Endpoint = {
  BASE: '/master/reporting',
  ANALYTICS_QUERY: '/execute/analytics/query',
  COMMUNICATION_LIST_LOANS: '/communication/list-loans',
};

export class AnaltyticsService extends ApiService {
  static async getAnalyticsQueries(request: Partial<AnalyticsQueryRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.ANALYTICS_QUERY;
    const response = await axios.post(endpoint, request);

    return response.data;
  }

  static async getCommunicationLoanData(
    request: Partial<AnalyticsQueryRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.COMMUNICATION_LIST_LOANS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
