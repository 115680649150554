import { useEffect, useState } from 'react';
import { IncentiveServices } from '../../../../Apis/IncentivesService';
import LmFilterSection from '../../../../components/common/LmFilterSection';
import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import VegaTabBar from '../../../../components/common/VegaTabBar';
import VegaTabBarItemWithNotification from '../../../../components/common/VegaTabBarItemWithNotification';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import { getIncentivesGamificationsState } from '../../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { getPolicies } from '../../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useDrawer } from '../../../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  IncentivePolicyReviewStatus,
  ViewIncentiesGamificationsModal,
} from '../../../../types/incentives';
import {
  FetchWirteOffPolicyListRequest,
  IncentivePolicyApprovalStatus,
  WriteOffRewardRecordsResponseDto,
} from '../../../../types/request/incentives';
import { HocWriteOffPolicyPayoutColumn } from './HocWriteOffPolicyPayoutColumn';
import { PaginatedResponse } from '../../../../utils/api';
import ViewHocSplitDrawer from './ViewHocSplitDrawer';
import { StringUtility } from '../../../../utils/StringUtility';

export enum HocWriteOffPolicyPayoutTabs {
  HISTORY = 'HISTORY',
  PENDING = 'PENDING',
}

export interface CountResponse {
  HISTORY: number;
  PENDING: number;
}

function HocWriteoffPolicyPayout() {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [selectedPolicy, setSelectedPolicy] = useState<string>('');
  const { policies } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [writeOffData, setWirteOffData] = useState<
    WriteOffRewardRecordsResponseDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<CountResponse>({
    HISTORY: 0,
    PENDING: 0,
  });
  const [searchLoanId, setSearchLoanId] = useState<string>('');
  const {
    open: openViewSplitDrawer,
    close: closeViewSplitDrawer,
    isOpen: isViewSplitDrawerOpen,
    props: viewSplitDrawerProps,
  } = useDrawer();

  function _onTabChange(value: number): void {
    setSelectedTab(value);
    setSearchLoanId('');
    setPage(0);
    setSize(10);
  }

  const onAction = (wirteOffData: WriteOffRewardRecordsResponseDto) => {
    openViewSplitDrawer({ wirteOffData: wirteOffData });
  };

  const getStatus = () => {
    if (selectedTab === 0) {
      const status = [
        IncentivePolicyApprovalStatus.APPROVED,
        IncentivePolicyApprovalStatus.REJECTED,
      ];
      return status;
    } else if (selectedTab === 1) {
      const status = [IncentivePolicyApprovalStatus.IN_REVIEW];
      return status;
    }
  };

  const createRequestObject = status => {
    const request: Partial<FetchWirteOffPolicyListRequest> = {
      policyId: selectedPolicy,
      approvalStatuses: status,
      page,
      size,
    };

    if (searchLoanId.length) {
      request.loanId = searchLoanId.toUpperCase();
    }

    return request;
  };

  const getWriteOffPolicies = async () => {
    try {
      setLoading(true);
      await fetchWriteOffPoliciesForLoanAccounts();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchWriteOffPoliciesForLoanAccounts = async () => {
    const status = getStatus();
    const request = createRequestObject(status);

    const response = await IncentiveServices.writeOffList(request);
    handleResponse(response);
  };

  const handleResponse = (
    response: PaginatedResponse<WriteOffRewardRecordsResponseDto>
  ) => {
    setWirteOffData(response.records);
    setTotalItems(response.totalItems);

    if (searchLoanId.length) {
      updateCount(response.totalItems);
    } else {
      getCount();
    }
  };

  const updateCount = totalItems => {
    setCount(prev => {
      const tabValue = Object.keys(HocWriteOffPolicyPayoutTabs).at(selectedTab);
      return {
        ...prev,
        [tabValue]: totalItems,
      };
    });
  };

  const getCount = async () => {
    const response = await IncentiveServices.getWriteOffCount({
      policyId: selectedPolicy,
    });
    setCount(() => {
      return {
        HISTORY: response.APPROVED + response.REJECTED,
        PENDING: response.IN_REVIEW,
      };
    });
  };

  const onSubmitClick = async (
    status: IncentivePolicyApprovalStatus,
    data: WriteOffRewardRecordsResponseDto,
    reason?: string
  ) => {
    try {
      if (reason) {
        await IncentiveServices.updateWriteOffSplit({
          id: data.id,
          splitData: data.splitData,
          approvalStatus: status,
          remarks: reason,
        });
        closeViewSplitDrawer();
        getWriteOffPolicies();
      } else {
        await IncentiveServices.updateWriteOffSplit({
          id: data.id,
          splitData: data.splitData,
          approvalStatus: status,
        });
        closeViewSplitDrawer();
        getWriteOffPolicies();
      }
    } catch (error) {}
  };

  const getMaxCapping = () => {
    if (writeOffData?.length > 0) {
      return StringUtility.formatNumberToIndianNumberFormat(
        writeOffData[0]?.policyCapping
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    dispatch(
      getPolicies({
        page: 0,
        pageSize: 1000,
        status: IncentivePolicyReviewStatus.ACTIVE,
        classTypes: ['WRITE_OFF'],
      })
    );
  }, []);

  useEffect(() => {
    if (policies.length > 0) {
      setSelectedPolicy(policies[0].id);
    }
  }, [policies]);

  useEffect(() => {
    if (searchLoanId.length) {
      const getWriteOffPoliciesData = setTimeout(getWriteOffPolicies, 300);
      return () => clearTimeout(getWriteOffPoliciesData);
    } else {
      if (selectedPolicy.length > 0) {
        getWriteOffPolicies();
      }
    }
  }, [selectedPolicy, selectedTab, page, size, searchLoanId]);

  return (
    <div>
      <VegaPageHeader
        title="Write - Off Policy Payout"
        sx={{ borderBottom: 'none' }}
        renderRightView={() => {
          return (
            <VegaSelect
              options={getOptions(policies)}
              value={selectedPolicy ?? ''}
              onChange={e => {
                setSelectedPolicy(e.target.value);
              }}
            />
          );
        }}
      />
      <VegaPageHeader
        isTabPresent
        renderRightView={() => {
          return (
            <VegaText
              text={`Max Capping per LAN:- ${getMaxCapping()}`}
              fontWeight={700}
              fontSize="13px"
              lineHeight="15.6px"
            />
          );
        }}
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedTab}
              variant="scrollable"
              onChange={(e, selected) => {
                _onTabChange(selected);
              }}
            >
              {Object.keys(HocWriteOffPolicyPayoutTabs).map(tab => (
                <VegaTabBarItemWithNotification
                  key={tab}
                  label={toLowerCase(tab)}
                  count={count[tab]}
                  // loading={countLoading}
                />
              ))}
            </VegaTabBar>
          );
        }}
      />
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '1rem', bgcolor: 'transparent' }}
        renderRightView={() => {
          return (
            <LmFilterSection
              hideFilter
              search={searchLoanId}
              setSearch={setSearchLoanId}
              searchPlaceHolder="Search By LAN"
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => {
            setPage(page);
          }}
          onPageSizeChange={size => setSize(size)}
          paginationMode="server"
          data={writeOffData}
          columns={HocWriteOffPolicyPayoutColumn(selectedTab, onAction)}
          loading={loading}
          idColumn="id"
        />
      </VegaPageContent>

      <ViewHocSplitDrawer
        open={isViewSplitDrawerOpen}
        onClose={closeViewSplitDrawer}
        data={viewSplitDrawerProps.wirteOffData}
        onSubmit={onSubmitClick}
      />
    </div>
  );
}

export default HocWriteoffPolicyPayout;

const getOptions = (options: ViewIncentiesGamificationsModal[]) => {
  const modifyOptions = options.map(
    (option: ViewIncentiesGamificationsModal) =>
      ({
        label: toLowerCase(option.policyName),
        value: option.id,
      } as VegaSelectOption)
  );
  return modifyOptions;
};
