import { Box, Stack } from '@mui/material';
import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { BORDER_COLLECTION } from '../../constants/style';

interface CalenderViewProps {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

const CalenderView = ({ date, setDate }: CalenderViewProps) => {
  return (
    <div>
      <Box
        sx={{
          borderRadius: '0.5rem',
          border: BORDER_COLLECTION,
          p: '1.5rem',
          bgcolor: 'white',
        }}
      >
        <Stack>
          <Calendar
            onChange={value => {
              const stringValue = value.toString();
              if (!stringValue) return;
              const dateValue = new Date(stringValue);
              const isInValidDate = isNaN(dateValue.getTime());
              if (isInValidDate == true) {
                return;
              }
              setDate(dateValue.toISOString());
            }}
            value={date}
          />
        </Stack>
      </Box>
    </div>
  );
};

export default CalenderView;
