import { ReactNode } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { COMMON } from '../../constants/style';
import VegaText from './VegaText';
import _ from 'lodash';

type Props = {
  heading?: string;
  value?: string | number;
  loading?: boolean;
  renderValue?: () => ReactNode;
};
function VegaDialogDisplayItem({
  heading,
  value,
  loading = false,
  renderValue,
}: Props) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <VegaText
          text={_.startCase(_.toLower(heading ?? 'NA'))}
          fontWeight={450}
          fontSize={'0.8125rem'}
          sx={{ mt: '0.25rem !important' }}
          color={COMMON.light}
        />
      </Grid>
      <Grid item xs={6}>
        {loading == true && <Skeleton width={'100%'} />}
        {loading == false && renderValue?.()}
        {loading == false && renderValue == null && (
          <VegaText
            text={value ?? 'NA'}
            fontWeight={500}
            fontSize={'0.8125rem'}
            color={COMMON.balck}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default VegaDialogDisplayItem;
