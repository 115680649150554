import { Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import VegaText from '../../../../components/common/VegaText';
import { getIncentiveForMonthState } from '../../../../features/IncentivesGamificationsSlice/incentiveForMonthSlice';
import { currentMonthIncentivesList } from '../../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useAppDispatch, useAppSelector } from '../../../../store';
import LoadingPage from '../../../LoadingPage/LoadingPage';
import GraphWrapper from '../components/GraphWrapper';
import PayoutGraph from '../components/PayoutGraph';

interface IProps {
  selectedRole: string[];
}

interface ISeries {
  name: string;
  data: number[];
}
const PayoutForMonth = ({ selectedRole }: IProps) => {
  const { loadingForOverall, totalPayout, incentiveCoverage } = useAppSelector(
    getIncentiveForMonthState
  );
  const dispatch = useAppDispatch();

  const overallIncentive = () => {
    dispatch(currentMonthIncentivesList({ role: selectedRole }));
  };

  // const totalPayoutSeries = [
  //   {
  //     name: 'CE/CO',
  //     data: totalPayout.map(payout => payout.value),
  //   },
  // ];

  // const incentiveCoverageSeries = [
  //   {
  //     name: 'CE/CO',
  //     data: incentiveCoverage.map(payout => payout.value),
  //   },
  // ];

  const totalPayoutSeries: ISeries[] = useMemo(() => {
    const seriesData: { [key: string]: number[] } = {};
    totalPayout.forEach(payout => {
      const { bucket, role, value } = payout;
      if (!seriesData[role]) {
        seriesData[role] = Array(totalPayout.length).fill(0); // Initialize array with zeros
      }
      const index = totalPayout.findIndex(item => item.bucket === bucket);
      seriesData[role][index] = value;
    });
    return Object.entries(seriesData).map(([role, data]) => ({
      name: role,
      data,
    }));
  }, [totalPayout]);

  const incentiveCoverageSeries: ISeries[] = useMemo(() => {
    const seriesData: { [key: string]: number[] } = {};
    incentiveCoverage.forEach(payout => {
      const { bucket, role, value } = payout;
      if (!seriesData[role]) {
        seriesData[role] = Array(incentiveCoverage.length).fill(0); // Initialize array with zeros
      }
      const index = incentiveCoverage.findIndex(item => item.bucket === bucket);
      seriesData[role][index] = value;
    });
    return Object.entries(seriesData).map(([role, data]) => ({
      name: role,
      data,
    }));
  }, [incentiveCoverage]);

  useEffect(() => {
    overallIncentive();
  }, [selectedRole]);

  const totalPayoutBucket = totalPayout.map(payout => payout.bucket);
  return (
    <>
      <Stack
        gap={1}
        sx={{
          p: 1,
          borderRadius: '12px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        }}
      >
        <VegaText
          text={'Incentive Pay-out for Dec-22'}
          fontWeight={600}
          fontSize={'1rem'}
          color={'black'}
          sx={{
            p: 1,
          }}
        />
        <GraphWrapper title="Total Pay-out">
          {loadingForOverall ? (
            <LoadingPage height={300} />
          ) : (
            <PayoutGraph
              yAxisTitle="In Rs.Cr"
              categories={totalPayout.map(payout => payout.bucket)}
              series={totalPayoutSeries}
            />
          )}
        </GraphWrapper>
        <GraphWrapper title="Incentive Coverage">
          {loadingForOverall ? (
            <LoadingPage height={300} />
          ) : (
            <PayoutGraph
              yAxisTitle="In Percentage"
              categories={incentiveCoverage.map(payout => payout.bucket)}
              series={incentiveCoverageSeries}
            />
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default PayoutForMonth;
