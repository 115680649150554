/* eslint-disable no-unused-vars */
import { Box, Grid, Stack } from '@mui/material';
import { Bidder, BidderStatus } from '../../types/auction';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import VegaDialogItem from '../common/VegaDialogItem';

type Props = {
  show: boolean;
  onClose: () => void;
  bidder?: Bidder;
};

function BidderDetailsDialog({ show, onClose, bidder }: Props) {
  function cleanUp() {}

  function closeDialog() {
    onClose();
    cleanUp();
  }

  const getExpiryDate = () =>
    DateUtility.formatStringToDDMMYYYY(bidder?.endDate);

  const getExpiryTime = () => {
    const endDate = DateUtility.parseStringToDate(bidder?.endDate);
    const time = DateUtility.getTimeFromDate(endDate);
    return time;
  };

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={show}
      isCloseIcon
      handleClose={closeDialog}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="User Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <Box px={4} pt={3} pb={4}>
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaText
              text={'Details'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '1.05rem',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <VegaDialogItem heading={'Name'} value={bidder?.userName} />
              <VegaDialogItem heading="Email" value={bidder?.email} />
              <VegaDialogItem
                heading="Status"
                renderValue={() => {
                  const color = getColorForStatus(bidder?.status);
                  return (
                    <VegaText
                      text={bidder?.status ?? ''}
                      fontWeight={500}
                      fontSize={'0.8125rem'}
                      color={color?.DARK}
                    />
                  );
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <VegaText
              text={'Expiry Timeline'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '1.05rem',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <VegaDialogItem heading={'Expiry Date'} value={getExpiryDate()} />
              <VegaDialogItem heading="Expiry Time" value={getExpiryTime()} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </LmDialog>
  );
}

export default BidderDetailsDialog;

const getColorForStatus = (status?: BidderStatus) => {
  if (!status) return;
  switch (status) {
    case BidderStatus.ACTIVE:
      return COLOR.GREEN;
    case BidderStatus.DRAFT:
      return COLOR.ORANGE;
    case BidderStatus.EXPIRED:
      return COLOR.RED;
  }
};
