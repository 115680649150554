import { Stack } from '@mui/material';
import { ConfigurationServices } from '../../../Apis/ConfigurationServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import {
  fetchConfigListSlice,
  getConfigurationState,
  initialAgentAlertNotifyConfig,
  setConfigurations,
} from '../../../features/configurationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ConfigurationDto,
  ConfigurationType,
} from '../../../types/configurationType';
import { ConfigListRequest } from '../../../types/request/cofigurationRequest';
import { DateIndicateEnum, DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IConfigActionProps, isEmptyOrUndefined } from '../Configurations';
import AlertNotifyAgentForm from '../components/Forms/AlertNotifyAgentForm';
interface IProps extends IConfigActionProps {}

const AlertNotifyConfigAgent = ({ onClose }: IProps) => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      const updateConfig = {
        name: configurations?.name,
        config: {
          ...configurations.config,
          startTime: DateUtility.toISOString(
            configurations.config.startTime,
            DateIndicateEnum.PLUS
          ),
          endTime: DateUtility.toISOString(
            configurations.config.endTime,
            DateIndicateEnum.PLUS
          ),
          targetEntryDeadline: DateUtility.toISOString(
            configurations.config.targetEntryDeadline,
            DateIndicateEnum.PLUS
          ),
          targetReasonDeadline: DateUtility.toISOString(
            configurations.config.targetReasonDeadline,
            DateIndicateEnum.PLUS
          ),
        },
      } as ConfigurationDto;
      await ConfigurationServices.addConfig(updateConfig);

      setSnackbar('Add Config Successfully');
      const request = {
        page: 0,
        size: 10,
        names: [ConfigurationType.ALERT_NOTIFICATIONS_AGENT],
      } as ConfigListRequest;
      dispatch(fetchConfigListSlice(request));
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const onClear = () => {
    dispatch(setConfigurations(initialAgentAlertNotifyConfig));
  };

  const disabled =
    isEmptyOrUndefined(configurations?.config?.startTime) ||
    isEmptyOrUndefined(configurations?.config?.endTime) ||
    isEmptyOrUndefined(configurations?.config?.targetEntryDeadline) ||
    isEmptyOrUndefined(configurations?.config?.targetReasonDeadline) ||
    isEmptyOrUndefined(configurations?.config?.reminderPeriod);

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton text="Submit" onClick={onSubmit} disabled={disabled} />
          </Stack>
        );
      }}
    >
      <AlertNotifyAgentForm />
    </VegaDrawerContent>
  );
};

export default AlertNotifyConfigAgent;
