import { createAsyncThunk } from '@reduxjs/toolkit';
import { IncentiveServices } from '../../Apis/IncentivesService';
import {
  AgentLevelIncentiveDetailsRequestDto,
  FetchLeaderboardRequestDto,
  FetchScorecardDetailsRequestDto,
  GetPolicyRequest,
  IncentiveDetailsRequestDto,
} from '../../types/request/incentives';

export const getPolicies = createAsyncThunk(
  'incentiesGamifications/list',
  async (request: Partial<GetPolicyRequest>) => {
    // const navigate = useNavigate();
    try {
      const response = await IncentiveServices.getPolicies(request);
      return response;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
      }
    }
  }
);
export const listOfTempletes = createAsyncThunk(
  'incentiesGamifications/templetes',
  async (type: string) => {
    // const navigate = useNavigate();
    try {
      const response = await IncentiveServices.listTemplates(type);
      return response;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
      }
    }
  }
);
export const policyEnums = createAsyncThunk(
  'incentiesGamifications/policyEnums',
  async () => {
    try {
      const response = await IncentiveServices.getPolicyEnum();
      return response;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
      }
    }
  }
);
export const agentLevelList = createAsyncThunk(
  'incentiesGamifications/agentLevelList',
  async (request: Partial<AgentLevelIncentiveDetailsRequestDto>) => {
    const response = await IncentiveServices.agentLevelIncentive(request);
    return response;
  }
);
export const currentMonthIncentivesList = createAsyncThunk(
  'incentiesGamifications/currentMonth',
  async (request: Partial<IncentiveDetailsRequestDto>) => {
    const response = await IncentiveServices.currentMonthIncentive(request);
    return response;
  }
);
export const overallIncentivesList = createAsyncThunk(
  'incentiesGamifications/overall',
  async (request: Partial<IncentiveDetailsRequestDto>) => {
    const response = await IncentiveServices.overallIncentive(request);
    return response;
  }
);
export const scorecardIncentivesList = createAsyncThunk(
  'incentiesGamifications/scorecard',
  async (request: Partial<FetchScorecardDetailsRequestDto>) => {
    const response = await IncentiveServices.scorecardIncentive(request);
    return response;
  }
);
export const leaderboardIncentivesList = createAsyncThunk(
  'incentiesGamifications/leaderboard',
  async (request: Partial<FetchLeaderboardRequestDto>) => {
    const response = await IncentiveServices.leaderboardIncentive(request);
    return response;
  }
);
