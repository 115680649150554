export const COLOR = {
  BLACK: {
    DARK: '#1B1D22',
  },
  GREY: {
    100: 'rgba(103, 107, 118, 1)',
    50: 'rgba(97, 110, 127, 1)',
    DARK: 'rgba(123, 134, 148, 1)',
    LIGHT: 'rgba(235, 237, 239, 1)',
  },
  WHITE: {
    50: 'rgba(197, 200, 208, 1)',
    100: 'rgba(247, 247, 247, 1)',
  },
  RED: {
    LIGHT: 'rgba(248, 232, 232, 1)',
    DARK: 'rgba(169, 25, 25, 1)',
  },
  GREEN: {
    LIGHT: 'rgba(235, 244, 236, 1)',
    DARK: 'rgba(49, 132, 57, 1)',
  },
  ORANGE: {
    LIGHT: 'rgba(255, 242, 230, 1)',
    DARK: 'rgba(232, 112, 1, 1)',
  },
  BLUE: {
    300: 'rgba(16, 71, 220, 1)',
    50: '#E7EDFC',
  },
  SIDEBAR: {
    DEFAULT: '#EBEDEF',
    ACTIVE: '#C8A864',
  },
};
