import { Stack } from '@mui/material';
import React from 'react';
import VegaText from './VegaText';

interface LosFormInputFieldProps {
  label?: string | number;
  children: React.ReactNode;
  endAdornment?: React.ReactNode;
  isMandatory?: boolean;
}

function VegaFormInputField({
  label,
  children,
  endAdornment,
  isMandatory = false,
}: LosFormInputFieldProps) {
  return (
    <Stack spacing={'0.5rem'}>
      <Stack
        direction={'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems={'center'}
        maxHeight="0.75rem"
      >
        {label && (
          <Stack direction={'row'} gap={0.3}>
            <VegaText
              text={label}
              fontWeight={700}
              style={{
                fontSize: '0.625rem',
                lineHeight: '124%',
                letterSpacing: '0.04375rem',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
            />
            {isMandatory && (
              <Stack
                sx={{
                  fontSize: '0.9rem',
                  color: 'red',
                  maxHeight: '0.75rem',
                }}
              >
                *
              </Stack>
            )}
          </Stack>
        )}
        {endAdornment}
      </Stack>
      {children}
    </Stack>
  );
}

export default VegaFormInputField;
