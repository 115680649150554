export type TeleAgentManualAccountViewModel = {
  agentId: string;
  processId: string;
  id: string;
  name: string;
  lan: string;
  pos: string;
  bucket: string;
  contactNumber: string;
  allocationDate: string;
  address: string;
  teleAllocatonType: string;
  teleCallStatus: string;
};

export type TeleAgentListProps = {
  id: string;
  userId: string;
  username: string;
  crmId: string;
  encryptedCrmId: string | null;
  password: string;
  slashrtcUserId: string | null;
  createdAt: string;
  updatedAt: string;
};
export type leadRemainCount = {
  statusCode: number;
  success: boolean;
  msg: string;
  status: number;
  err: string;
};

export type CallStartEventDto = {
  id: string;
  direction: string;
  phone: string;
  callSessionId: string;
  callStartDateTime: string;
  userName: string;
  campaignName: string;
  processName: string;
  teleAllocationId: string;
  loanid: string;
};

export type CallDisposeEventDto = {
  id: string;
  username: string;
  phone: string;
  extension: string;
  employeeId: string;
  userFullName: string;
  extensionType: string;
  leadsetId: string;
  leadsetName: string;
  campaignId: string;
  campaignName: string;
  processId: string;
  processName: string;
  referenceId: string;
  modeOfCalling: string;
  callStartDateTime: string;
  callEndDateTime: string;
  customerName: string;
  disposeComment: string;
  firstDispose: string;
  secondDispose: string;
  thirdDispose: string;
  agentTalktimeSec: string;
  holdTime: string;
  muteTime: string;
  transferTime: string;
  conferenceTime: string;
  callRingtime: string;
  disconnectedBy: string;
  callBackDateTime: string;
  claimNo: string;
  mediaUrl: string;
  teleallocationid: string;
  loanid: string;
  loanviewurl: string;
};

export enum TeleAgentCallType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}
