import { Stack, Skeleton } from '@mui/material';
import { BORDER_COLLECTION } from '../../../constants/style';
import { TrendDownIcon, TrendUp } from '../../Icons/Icons';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';
import { COLOR } from '../../../utils/ColorUtility';
import React from 'react';

interface HomeCardProps {
  label: string;
  growth?: string;
  loading?: boolean;
  sameDayValue?: string | number;
  value?: string | number;
  onClick?: () => void;
  icon?: React.ReactElement;
}

const HomeSummariesCard = ({
  label,
  value,
  sameDayValue,
  growth,
  loading,
  onClick,
  icon,
}: HomeCardProps) => {
  return (
    <Stack
      sx={{
        border: BORDER_COLLECTION,
        borderRadius: '0.75rem',
        px: 2,
        py: 1.5,
        minHeight: '101px',
        bgcolor: 'white',
        justifyContent: 'space-between',
      }}
    >
      <VegaFormInputField label={label}>
        {loading ? (
          <Skeleton />
        ) : (
          <Stack
            direction={'row'}
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <VegaText
              sx={{ mt: '0px !important' }}
              fontSize={'1.5rem'}
              fontWeight={600}
              text={value}
            />
            {icon && (
              <div style={{ cursor: 'pointer' }} onClick={onClick}>
                {icon}
              </div>
            )}
          </Stack>
        )}
      </VegaFormInputField>
      {/* <Stack direction={'row'} gap={1} alignItems={'center'}>
        {growth === 'UP' ? <TrendUp /> : <TrendDownIcon />}
        <VegaText
          text={sameDayValue ?? ''}
          color={growth === 'UP' ? COLOR.GREEN.DARK : COLOR.RED.DARK}
        />
        <VegaText text={'on the same day last month'} color="#999DA8" />
      </Stack> */}
    </Stack>
  );
};

export default HomeSummariesCard;
