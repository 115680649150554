import { Tabs, TabsProps } from '@mui/material';
import { ReactNode } from 'react';

interface LosTabBarProps extends TabsProps {
  children: ReactNode;
}

function VegaTabBar({ children, ...rest }: LosTabBarProps) {
  const { color = 'rgba(16, 71, 220, 1)' } = rest;
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          backgroundColor: color,
          fontFamily: 'cursive',
        },
      }}
      sx={{
        minHeight: 'auto',
      }}
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export default VegaTabBar;
