import { Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTeleAgentAudit } from '../../actions/TeleAgentAction';
import SendPaymentLinkFormDrawer from '../../components/Accounts/SendPaymentLinkFormDrawer';
import UpdateContactDetailsFormDrawer from '../../components/Accounts/UpdateContactDetailsFormDrawer';
import { AnimatedInputWrapper } from '../../components/ExpenseClaim/CreateClaimForm';
import { SendLinkIcon, TagIcon } from '../../components/Icons/Icons';
import TrailCard from '../../components/Trail/TrailCard';
import UpdateTrailDrawer from '../../components/Trail/UpdateTrailDrawer';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { COMMON } from '../../constants/style';
import { fetchLoanList, loanRequestSelector } from '../../features/loanSlice';
import {
  setCallDispose,
  teleAgentAuditSelector,
} from '../../features/teleAgentAuditSlice';
import {
  fetchTrailList,
  trailRequestSelector,
} from '../../features/trailSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../store';
import { CustomerDetail, LoanData } from '../../types/loan';
import { getValueFromRecord } from '../../types/ots';
import { GetTrailDetailRequest } from '../../types/request/trail';
import {
  DispositionCode,
  TeleAgentAuditModal,
  VisitTrailDto,
} from '../../types/trail';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import VeiwCallDisposeDetailDialog from '../TeleAgentAudit/modules/Dialog/VeiwCallDisposeDetailDialog';
import FeedbackDrawer from '../TeleAgentAudit/modules/Drawer/FeedbackDrawer';
import { TeleAuditAction } from '../TeleAgentAudit/modules/ListOfTeleAudit';
import TeleAuditColumn from '../TeleAgentAudit/modules/columns/TeleAuditColumn';

function TrailDetailPage() {
  const { loanAccountId } = useParams();
  const [selectedDispositionCode, setSelectedDispositionCode] = useState<
    DispositionCode | string
  >();
  const {
    open: openUpdateTrailDialog,
    close: closeUpdateTrailDialog,
    isOpen: isUpdateTrailDialogOpen,
    props: updateTrailDialogProps,
  } = useDrawer(false);
  const {
    open: openPaymentLinkDialog,
    close: closePaymentLinkDialog,
    isOpen: isPaymentLinkDialogOpen,
  } = useDrawer(false);

  const dispatch = useAppDispatch();
  const { trails, loading: isTrailLoading } =
    useAppSelector(trailRequestSelector);
  const { audit, loading, totalItems } = useAppSelector(teleAgentAuditSelector);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [openFeedbackDrawer, setOpenFeedbackDrawer] = useState<boolean>(false);
  const [openViewDetailsModal, setOpenViewDetailsModal] =
    useState<boolean>(false);
  const { loans } = useAppSelector(loanRequestSelector);
  const loan = getFirstItemIfExists<LoanData>(loans);
  const customerDetail = getFirstItemIfExists<CustomerDetail>(
    loan?.customerDetails
  );

  function fetchTrail() {
    var request: Partial<GetTrailDetailRequest> = {
      loanId: loanAccountId,
      size: 10000,
    };
    if (selectedDispositionCode) {
      request.dispositionCode = selectedDispositionCode;
    } else {
      delete request.dispositionCode;
    }
    dispatch(fetchTrailList(request));
  }

  useEffect(() => {
    dispatch(
      fetchLoanList({
        includeCustomerDetails: true,
        includeLmsVariables: true,
        includeLoanOutstandingDetails: true,
        includeLoanPdcDetails: true,
        includeLoanTransactions: true,
        restrictedView: true,
        loanId: loanAccountId,
      })
    );
  }, []);

  const renderDescriptionView = (v: VisitTrailDto) => {
    const agentRemarks = v.agentRemarks ?? [];
    const metaData = v.metadata;

    if (agentRemarks.length > 0) {
      return (
        <Grid item xs={11}>
          <TrailCardKeyValue
            heading={'Comment'}
            value={v.agentRemarks ?? '-'}
          />
        </Grid>
      );
    }

    if (metaData != null) {
      const message = getValueFromRecord<string>(
        metaData,
        'notificationMessage'
      );
      return (
        <Grid item xs={11}>
          <TrailCardKeyValue heading={'Message'} value={message ?? '-'} />
        </Grid>
      );
    }
  };

  const renderAmountCollected = (v: VisitTrailDto) => {
    const amountCollected = v.amountCollected;
    if (amountCollected != null) {
      return (
        <Grid item xs={1}>
          <TrailCardKeyValue
            heading={'Amount'}
            value={StringUtility.formatToINR(v.amountCollected) ?? '-'}
          />
        </Grid>
      );
    }
  };

  function onDispositionCodeSelect(code: DispositionCode | string) {
    if (selectedDispositionCode == code) {
      setSelectedDispositionCode(undefined);
    } else {
      setSelectedDispositionCode(code);
    }
  }

  const onAction = (
    callDispose: TeleAgentAuditModal,
    action: TeleAuditAction
  ) => {
    if (action === TeleAuditAction.DOWNLOAD) {
      if (!callDispose?.recording) return;
      window.open(callDispose?.recording, '_blank');
    } else if (action === TeleAuditAction.FEEDBACK) {
      setOpenFeedbackDrawer(true);
    } else {
      setOpenViewDetailsModal(true);
    }
    dispatch(setCallDispose(callDispose));
  };

  async function fetchCallList() {
    if (!loan) {
      return;
    }
    dispatch(
      fetchTeleAgentAudit({
        searchPartialLoanId: loan?.loanId,
        page: 0,
        size: 10,
      })
    );
  }

  useEffect(() => {
    if (selectedDispositionCode === DispositionCode.CALLBACK) {
      fetchCallList();
    } else if (selectedDispositionCode === DispositionCode.OTHERS) {
      return;
    } else {
      fetchTrail();
    }
  }, [selectedDispositionCode]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Grid container>
              <Grid item xs={12}>
                <VegaBreadCrumb
                  items={[
                    { label: 'My Accounts', link: -1 },
                    { label: `Account Details` },
                  ]}
                ></VegaBreadCrumb>
              </Grid>
              <Grid xs={12}>
                <Stack direction="row" gap="2rem">
                  <TrailKeyValue
                    heading="Name"
                    value={customerDetail?.customerName}
                  />
                  <TrailKeyValue heading="LAN" value={loan?.loanId} />
                  <TrailKeyValue
                    heading="POS"
                    value={
                      loan?.lmsVariables?.outstandingAmount?.toString() ?? '-'
                    }
                  />
                  <TrailKeyValue
                    heading="Bucket"
                    value={loan?.lmsVariables?.bucket ?? '-'}
                  />
                  <TrailKeyValue
                    heading="Contact number"
                    value={customerDetail?.mobileNo?.toString()}
                  />
                  <TrailKeyValue
                    heading="Allocation Date"
                    value={
                      DateUtility.formatStringToDDMMYYYYWithTime(
                        loan?.lmsVariables?.allocationDate
                      ) ?? '-'
                    }
                  />
                  <TrailKeyValue
                    heading="Address"
                    value={customerDetail?.customerAddress}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  style={{ alignItems: 'center', marginTop: '1.70rem' }}
                  gap="1rem"
                >
                  <VegaText text={'Actions'} />
                  <VegaButton
                    text="Update Trail"
                    startIcon={<TagIcon strokeColor="#1047DC" />}
                    variant="outlined"
                    onClick={() => {
                      openUpdateTrailDialog({ loan: loan });
                    }}
                  />
                  <VegaButton
                    text="Send Payment Link"
                    variant="outlined"
                    startIcon={<SendLinkIcon />}
                    onClick={() => {
                      openPaymentLinkDialog();
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      />
      <Stack direction={'row'} gap="1rem" style={{ padding: '1rem' }}>
        <Chip
          label="All"
          variant={selectedDispositionCode == null ? 'filled' : 'outlined'}
          onClick={() => {
            onDispositionCodeSelect(undefined);
          }}
        />
        <Chip
          label="Sms"
          variant={
            selectedDispositionCode == DispositionCode.SMS_SENT
              ? 'filled'
              : 'outlined'
          }
          onClick={() => {
            onDispositionCodeSelect(DispositionCode.SMS_SENT);
          }}
        />
        <Chip
          label="Whatsapp"
          variant={
            selectedDispositionCode == DispositionCode.WHATSAPP_SENT
              ? 'filled'
              : 'outlined'
          }
          onClick={() => {
            onDispositionCodeSelect(DispositionCode.WHATSAPP_SENT);
          }}
        />
        <Chip
          label="IVR"
          variant={
            selectedDispositionCode == DispositionCode.IVR_SENT
              ? 'filled'
              : 'outlined'
          }
          onClick={() => {
            onDispositionCodeSelect(DispositionCode.IVR_SENT);
          }}
        />
        <Chip
          label="Email"
          variant={
            selectedDispositionCode == 'EMAIL_SENT' ? 'filled' : 'outlined'
          }
          onClick={() => {
            onDispositionCodeSelect('EMAIL_SENT');
          }}
        />
        <Chip
          label="CALL"
          variant={
            selectedDispositionCode == DispositionCode.CALLBACK
              ? 'filled'
              : 'outlined'
          }
          onClick={() => {
            onDispositionCodeSelect(DispositionCode.CALLBACK);
          }}
        />
      </Stack>
      <div
        style={{
          paddingLeft: '0.94rem',
          paddingRight: '0.94rem',
          marginTop: '1rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        {selectedDispositionCode === DispositionCode.CALLBACK ? (
          <VegaPageContent>
            <VegaDataGrid
              page={page}
              pageSize={size}
              rowCount={totalItems}
              paginationMode="server"
              onPageChange={page => setPage(page)}
              onPageSizeChange={size => setSize(size)}
              data={audit}
              columns={TeleAuditColumn({ onAction })}
              loading={loading}
              idColumn="id"
            />
            <FeedbackDrawer
              open={openFeedbackDrawer}
              onClose={() => setOpenFeedbackDrawer(false)}
            />
            <VeiwCallDisposeDetailDialog
              open={openViewDetailsModal}
              onClose={() => setOpenViewDetailsModal(false)}
            />
          </VegaPageContent>
        ) : selectedDispositionCode === DispositionCode.OTHERS ? (
          []
        ) : (
          <>
            {isTrailLoading && (
              <AnimatedInputWrapper show={isTrailLoading} marginTop={'5rem'}>
                <Stack direction="row" justifyContent={'center'}>
                  <CircularProgress size={30} />
                </Stack>
              </AnimatedInputWrapper>
            )}
            {!isTrailLoading &&
              trails?.map(v => (
                <TrailCard
                  key={v.id}
                  renderTopView={() => (
                    <Grid container>
                      <Grid item xs={11}>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          gap={'0.25rem'}
                        >
                          <TagIcon strokeColor="black" />
                          <Typography variant="subtitle1">
                            Trail Updated
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>
                        <VegaText
                          fontWeight={500}
                          fontSize={'0.8125rem'}
                          color={COMMON.light}
                          text={DateUtility.formatStringToDDMMYYYY(
                            v.updateTrailDate
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  renderMiddleView={() => (
                    <Grid container>
                      {renderDescriptionView(v)}
                      {renderAmountCollected(v)}
                    </Grid>
                  )}
                  renderBottomView={() => (
                    <Grid
                      container
                      sx={{ paddingLeft: '0.75rem', paddingRight: '0.75rem' }}
                    >
                      <Grid item xs={12} sm={6} md={4}>
                        <TrailCardKeyValue
                          heading={'Disposition Code'}
                          value={
                            _.startCase(_.toLower(v.dispositionCode)) ?? '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TrailCardKeyValue
                          heading={'Next Action'}
                          value={_.startCase(_.toLower(v.nextAction)) ?? '-'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TrailCardKeyValue
                          heading={'Next Action Date'}
                          value={
                            v.nextActionDate
                              ? DateUtility.formatStringToDDMMYYYY(
                                  v.nextActionDate
                                )
                              : '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TrailCardKeyValue
                          heading={'Location tag type'}
                          value={
                            _.startCase(_.toLower(v?.locationTagType)) ?? '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TrailCardKeyValue
                          heading={'Longitude'}
                          value={
                            v?.geoLocationDto?.longitude?.toString() || '-'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TrailCardKeyValue
                          heading={'Latitude'}
                          value={v?.geoLocationDto?.latitude?.toString() || '-'}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              ))}
          </>
        )}
      </div>
      ;
      <UpdateTrailDrawer
        onClose={closeUpdateTrailDialog}
        open={isUpdateTrailDialogOpen}
        loanDetail={updateTrailDialogProps.loan}
        fetchTrail={fetchTrail}
      />
      {/* <PaymentLinkDrawer
        onClose={closePaymentLinkDialog}
        open={isPaymentLinkDialogOpen}
      /> */}
      <UpdateContactDetailsFormDrawer />
      <SendPaymentLinkFormDrawer
        loanData={loans}
        open={isPaymentLinkDialogOpen}
        setOpen={closePaymentLinkDialog}
      />
    </div>
  );
}

export default TrailDetailPage;
type TrailKeyValueProps = {
  heading: string;
  value: string;
};

const TrailKeyValue = ({ heading, value }: TrailKeyValueProps) => {
  return (
    <Stack direction="column" marginTop="1.75rem">
      <VegaText
        text={heading}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.light}
      />
      <VegaText
        text={value}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.balck}
      />
    </Stack>
  );
};
type TrailCardKeyValueProps = {
  heading: string;
  value: string;
};
const TrailCardKeyValue = ({ heading, value }: TrailCardKeyValueProps) => {
  return (
    <Stack direction="column" marginY={'0.75rem'}>
      <VegaText
        text={heading}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.light}
      />
      <VegaText
        text={value}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.balck}
      />
    </Stack>
  );
};

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
