import { Stack, Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useOts } from '../../../../providers/OtsProvider';
import { AgendaCaseStatus } from '../../../../types/ots';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsCommitteeDetails() {
  const {
    ots,
    agendaCase,
    agenda,
    fetchAgendaCase,
    loadingAgendaCase: loading,
  } = useOts();

  const getIncludedInMeeting = () => {
    const meetingStatus = agendaCase?.meetingStatus;
    if (meetingStatus) {
      return meetingStatus == AgendaCaseStatus.APPROVED ? 'Yes' : 'No';
    }
    return 'NA';
  };

  useEffect(() => {
    const agendaCaseId = ots?.agendaCaseId;
    if (!agendaCaseId && !agendaCase) return;
    fetchAgendaCase({ id: agendaCaseId, fetchAgenda: true, fetchOts: false });
  }, [ots]);

  return (
    <OtsDetailsWrapper text="Committee Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Commitee Number"
              value={agenda?.committeeNumber ?? 'NA'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Included In Agenda"
              value={getIncludedInMeeting()}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Agenda Exlusion Reason"
              value={agendaCase?.meetingExclusionReason ?? 'NA'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Commitee Decision"
              value={_.startCase(
                _.toLower(agendaCase?.committeeDecisionStatus ?? 'NA')
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Chair Decision"
              value={_.startCase(
                _.toLower(agendaCase?.otsChairDecisionStatus ?? 'NA')
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Installments"
              value={agendaCase?.installments ?? 'NA'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Day of Repayment"
              value={agendaCase?.dayOfRepayment ?? 'NA'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Committee Comments"
              value={agendaCase?.committeeComment ?? 'na'}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsCommitteeDetails;
