import { Grid, Stack } from '@mui/material';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IssueReceiptProps } from '../../../types/receiptManagment';

const LoanDocumentUpload = () => {
  const { issueReceipt } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const onFileAdd = (file: VegaFileSelectOption) => {
    const documentObj = {
      ...issueReceipt,
      documentImage: file,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(documentObj));
  };

  const onFileDelete = () => {
    const documentObj = {
      ...issueReceipt,
      documentImage: null,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(documentObj));
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <VegaFormInputField label="Document Upload">
          <Stack rowGap={'0.75rem'}>
            {issueReceipt.documentImage && (
              <VegaFileSelect
                value={issueReceipt.documentImage}
                key={1}
                acceptedExtension={[
                  AcceptedExtension.JPG,
                  AcceptedExtension.JPEG,
                  AcceptedExtension.PNG,
                ]}
                placeholder={'Upload Document '}
                onDeleteClick={onFileDelete}
              />
            )}
            <VegaFileSelect
              placeholder={'Upload Document '}
              acceptedExtension={[
                AcceptedExtension.JPG,
                AcceptedExtension.JPEG,
                AcceptedExtension.PNG,
              ]}
              onFileSelect={file => {
                onFileAdd(file);
              }}
            />
          </Stack>
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default LoanDocumentUpload;
