import axios from 'axios';
import { ApiService } from '../types/api';
import { EscalationsDto } from '../types/escalationsType';
import {
  GetEscalationRequest,
  TriggerEscalationRequest,
  UpadteEscalationRequest,
} from '../types/request/escalationsRequest';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/escalation',
  ESCALATION_ALLOCATION: '/escalation-allocation',
  LIST: '/list',
  HISTORY: '/history',
  UPDATE: '/update',
};

export class EscalationsServices extends ApiService {
  static async getEscalations(
    request: Partial<GetEscalationRequest>
  ): Promise<PaginatedResponse<EscalationsDto>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async triggerEscalation(
    request: Partial<TriggerEscalationRequest>
  ): Promise<PaginatedResponse<EscalationsDto>> {
    const endpoint = Endpoint.ESCALATION_ALLOCATION;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateEscalation(
    request: Partial<UpadteEscalationRequest>
  ): Promise<EscalationsDto> {
    const endpoint = Endpoint.BASE + Endpoint.UPDATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async viewEscalationHistory(id: string) {
    const endpoint = Endpoint.BASE;
    const response = await axios.get(endpoint, { params: id });
    return response.data;
  }
}
