import {
  BasePolicyRangeProps,
  BumperPolicyRangeProps,
  IncentiveApprovalStatus,
  IncentiveWriteOffRulesRequest,
  MinimumEligibilityCriteriaProps,
  PanaltyPolicyRangeProps,
} from './request/incentives';
import { VegaUserStatus } from './user';

export enum IncentiveAccountType {
  ALL = 'ALL',
  NORMAL = 'NORMAL',
}

export enum IncentiveType {
  POINT_BASED = 'POINT_BASED',
  METRIC_BASED = 'METRIC_BASED',
}
export enum IncentivePolicyType {
  ADD_ON_POLICY = 'ADD_ON_POLICY',
  NEW_POLICY = 'NEW_POLICY',
}
export enum IncentiveChannelType {
  FIELD = 'FIELD',
}
export enum IncentivePayoutFrequencyType {
  FORTNIGHT = 'FORTNIGHT',
  MONTHLY = 'MONTHLY',
  QUATERLY = 'QUATERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
}

export enum IncentivePayoutType {
  PER_CASE = 'PER_CASE',
  PER_PERSON = 'PER_PERSON',
}
export enum CoditionComperisionType {
  GREATER_THEN = 'GT',
  GREATER_THEN_OR_EQUAL = 'GTE',
  LESS_THEN = 'LT',
  LESS_THEN_OR_EQUAL = 'LTE',
  EQUAL_TO = 'EQ',
}

export enum IncentiveCollectionType {
  RESOLUTION = 'RESOLUTION',
  NORMALIZATION = 'NORMALIZATION',
  ROLL_BACK = 'ROLL_BACK',
  PENALTY_COLLECTION = 'PENALTY_COLLECTION',
  NORMALIZATION_AND_ROLLBACK = 'NORMALIZATION_AND_ROLLBACK',
  NO_OF_CASES = 'NO_OF_CASES',
  AMOUNT_COLLECTED = 'AMOUNT_COLLECTED',
}

export enum SimulatorCollectionType {
  RESOLUTION = 'RESOLUTION',
  NORMALIZATION = 'NORMALIZATION',
  ROLLBACK = 'ROLLBACK',
  PENALTY_COLLECTION = 'PENALTY_COLLECTION',
  NORMALIZATION_ROLLBACK = 'NORMALIZATION_ROLLBACK',
  NO_OF_CASES = 'NO_OF_CASES',
  AMOUNT_COLLECTED = 'AMOUNT_COLLECTED',
}
export enum CollectionType {
  RESOLUTION = 'RESOLUTION',
  NO_OF_CASES_NORMALIZED = 'NO_OF_CASES_NORMALIZED',
  NO_OF_EMI_COLLECTED = 'NO_OF_EMI_COLLECTED',
  NORMALIZATION_AND_ROLLBACK = 'NORMALIZATION_AND_ROLLBACK ',
}

export enum ChargeCollectionType {
  CHARGES_COLLECTED = 'CHARGES_COLLECTED',
}
export enum ConditionType {
  RESOLUTION_PERCENTAGE = 'RESOLUTION_PERCENTAGE',
  NORMALIZATION_PERCENTAGE = 'NORMALIZATION_PERCENTAGE',
  ADDITIONAL_EMI_COLLECTED = 'ADDITIONAL_EMI_COLLECTED',
  NORMALIZATION_OR_ROLLBACK_PERCENTAGE = 'NORMALIZATION_OR_ROLLBACK_PERCENTAGE',
  ROLLBACK_PERCENTAGE = 'ROLLBACK_PERCENTAGE',
}
export enum ConditionTypeForPenalty {
  NORMALIZATION = 'NORMALIZATION',
  ROLLBACK = 'ROLLBACK',
}
export enum ParameterDefinitionType {
  FIXED = 'FIXED',
  RANGE = 'RANGE',
}
export enum AmountType {
  PERCENTAGE_OF_BASE_AMOUNT = 'PERCENTAGE_OF_BASE_AMOUNT',
  FLAT_AMOUNT = 'FLAT_AMOUNT',
}
export enum IncentiveComputationBasisType {
  POS = 'POS',
  OVERDUE_AMOUNT = 'OVERDUE_AMOUNT',
  NO_OF_CASES_ALLOCATED = 'NO_OF_CASES_ALLOCATED',
  CHARGES_DUE = 'CHARGES_DUE',
}

export enum IncentiveCalulationMetrixType {
  PER_EMI = 'PER_EMI',
  PER_CASE = 'PER_CASE',
  PERCENTAGE_AMOUNT_COLLECTED = 'PERCENTAGE_AMOUNT_COLLECTED',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE_INCENTIVE_EARNED = 'PERCENTAGE_INCENTIVE_EARNED',
}

export enum IncentivePayoutMetrixType {
  PERCENTAGE = 'PERCENTAGE',
  PER_CASE = 'PER_CASE',
  PER_EMI = 'PER_EMI',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
}

export enum IncentivePayoutPolicyType {
  BASE = 'BASE',
  BUMPER = 'BUMPER',
  PENALTY = 'PENALTY',
}

export enum IncentivePolicyReviewStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_REVIEW = 'IN_REVIEW',
  DELETED = 'DELETED',
}
export enum IncentiveModeOfCommunicationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WHATS_APP = 'WHATS_APP',
}
export enum IncentiveVerticalType {
  CGCL = 'CGCL',
}

export interface ViewIncentiesGamificationsModal {
  id: string;
  policyName: string;
  caseType: string;
  classType: string;
  vertical: string;
  channelType: string;
  userType: string[];
  payoutFrequency: string;
  startDate: string;
  endDate: string;
  status: string;
  approvalStatus: string;
  remarks?: string;
  reason?: string;
}

export interface RangeProps {
  id: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  parameter: string;
  parameterMin: number;
  parameterMax: number | null;
  parameterMetric: string;
  payoutValue: number;
  payoutMetric: string;
  payoutCapMin: number | null;
  payoutCapMax: number | null;
  payoutCapMetric: string | null;
  from: string | null;
  to: string | null;
  isSubjectToAchievingTarget: boolean | null;
  targetValue: number | null;
  incentivePayoutPolicyType: string;
  createdBy: string | null;
}

export interface RuleProps {
  id: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  bucket: string;
  collectionType: string | null;
  incentiveType: string | null;
  accountType: string[];
  splitPayoutForUsers: string[];
  caseType: string[];
  setMinimumEligibility: boolean;
  maximumPayoutAmount: number;
  maximumPayoutType: string;
  payoutMetric: string;
  minimumEligibilityParameters: Partial<MinimumEligibilityCriteriaProps> | null;
  basePayoutRange: BasePolicyRangeProps[] | null;
  chargeCollectedRules: BasePolicyRangeProps[] | null;
  bumperPayoutRange: BumperPolicyRangeProps[] | null;
  penaltyRange: PanaltyPolicyRangeProps[] | null;
}

export interface PayoutConfiguration {
  [key: string]: Partial<RuleProps>;
}
export interface WriteOffPayoutConfiguration {
  [key: string]: Partial<IncentiveWriteOffRulesRequest>;
}

export interface IncentivePolicyProps {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  policyName: string;
  vertical: string;
  channelType: string;
  policyType?: string;
  classType?: string;
  npaType?: string | null;
  caseType?: string[];
  incentiveType: string;
  accountType: string[];
  payoutFrequency: string;
  userType: string[];
  splitPayoutForUsers: string[];
  modeOfCommunication: string[];
  bucketsHavingRules: string[];
  createdBy?: string | null;
  approvedBy?: string | null;
  approvalStatus?: string | null;
  maxPayoutPerPerson: number;
  policyStartDate: string;
  policyEndDate: string;
  chargeCollectedRules: BasePolicyRangeProps[] | null;
  rules?: Partial<PayoutConfiguration>;
  writeOffRules?: Partial<IncentiveWriteOffRulesRequest>;
  splitPayoutForUsersMap?: Partial<{ [key: string]: number }>;
  status?: string;
  remarks?: string;
  reason?: string;
}

export type RankDetailDto = {
  rank: number;
  totalEligibleAgents: number;
  regionName: string;
  branchName: string;
  regionRank: number;
  branchRank: number;
  totalEligibleAgentsInBranch: number;
  totalEligibleAgentsInRegion: number;
};

export interface TempleteListProps {
  templateId: number;
  templateName: string;
}
export interface GetTempleteProps {
  templateId: number;
  templateName: string;
  createdBy: string | null;
  type: string | null;
  createdAt: string;
  rules?: Partial<PayoutConfiguration>;
  writeOffRules?: Partial<IncentiveWriteOffRulesRequest>;
  chargeCollectedRules?: BasePolicyRangeProps[];
}

export interface AgentPayoutDto {
  agentId: string;
  incentiveEarnedBuckets: string[];
  bucketWiseBreakdown: any;
  totalAmountEarned: number;
  totalBumperEarned: number;
  totalPenaltyDeduction: number;
  resolutionRate: number;
  normalizationRate: number;
  rollBackRate: number;
  stabilizationRate: number;
  chargeCollected: number;
  totalChargeOverdue: number;
  totalEligibleAmount: number;
}
export interface BucketValueDto {
  bucket: string;
  value: number;
}

export interface AgentLevelBucketDetails {
  amount: number;
  bucketDetails: BucketValueDto[];
}

export interface AgentLevelIncentiveDetailsResponseDto {
  earned: number;
  allIndiaRank: number | null;
  agentDesignation: string | null;
  agentRegion: string | null;
  collected: number;
  earnedAmountBucketX: number | null;
  earnedAmountBucket1: number | null;
  earnedAmountBucket2: number | null;
  earnedAmountBucket3: number | null;
  earnedAmountBucketNpa: number | null;
  agentName: string | null;
  earnedAmountBucketNormal: number | null;
  penaltyAmountDeductedBucketNormal: number | null;
  penaltyAmountDeductedBucketX: number | null;
  penaltyAmountDeductedBucket1: number | null;
  penaltyAmountDeductedBucket2: number | null;
  penaltyAmountDeductedBucket3: number | null;
  penaltyAmountDeductedBucketNpa: number | null;
  bumperAmountDeductedBucketNormal: number | null;
  bumperAmountDeductedBucketX: number | null;
  bumperAmountDeductedBucket1: number | null;
  bumperAmountDeductedBucket2: number | null;
  bumperAmountDeductedBucket3: number | null;
  bumperAmountDeductedBucketNpa: number | null;
}

export interface RoleMaxPayoutDto {
  entityConfigId: string;
  role: RoleName;
  maxCapping: number;
  updatedBy: string;
  status: VegaUserStatus;
  updatedAt: string;
}

export enum RoleName {
  CE = 'CE',
  CM = 'CM',
  ASM = 'ASM',
  ACM = 'ACM',
  CCM = 'CCM',
  TELE_COLLECTION_MANAGER = 'TELE_COLLECTION_MANAGER',
  TELE_COLLECTION_EXECUTIVE = 'TELE_COLLECTION_EXECUTIVE',
  TELE_LEAD = 'TELE_LEAD',
  RMROABM = 'RMROABM',
  DBMBM = 'DBMBM',
}

export interface OverallAgentIncentiveDetails {
  id: string;
  agentName: string;
  agentCode: string;
  status: IncentiveApprovalStatus;
  totalAmountCollected: number;
  totalChargeCollected: number;
  totalNoOfEmiCollected: number;
  agentId: string;
  regionRank: number;
  allIndiaRank: number;
  listOfPolicyIds: string;
  roleName: RoleName;
  cycleStartDate: string;
  cycleEndDate: string;
  region: string;
  overallBucketLevelIncentive: Record<string, number>;
  bucketLevelPenalty: Record<string, number>;
  bucketLevelBumper: Record<string, number>;
  totalIncentiveEarned: number;
  adjustedPayout: number;
  writeOffPayout: number;
  maxRolePayout: number;
  nominalPayout: number;
  finalPayout: number;
  reason: string;
}

export interface OverallPayoutStatusResponse {
  id: string;
  status: IncentiveApprovalStatus;
  remarks: string;
}
