import { GridColumns } from '@mui/x-data-grid';
import React from 'react';
import VegaText from '../../../../components/common/VegaText';
import { toLowerCase } from '../../../../constants/commonFunction';
import { Stack } from '@mui/material';

const HitsAndMissesColumn = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'id',
      headerName: '%',
      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        return <VegaText text={toLowerCase(props.row.id)} />;
      },
    },
    {
      field: 'milestone_minimum',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Milestone based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Minimum'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const milestoneMinimum =
          scoreCardDetails.milestoneBasedDetails.eligibleAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={milestoneMinimum}
          />
        );
      },
    },
    {
      field: 'milestone_achieved',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Milestone based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Achieved'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const milestoneAchieved =
          scoreCardDetails.milestoneBasedDetails.achievedAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={milestoneAchieved}
          />
        );
      },
    },
    {
      field: 'resolutions_minimum',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'resolutions based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Minimum'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const resolutionBasedMinimum =
          scoreCardDetails.resolutionBasedDetails.eligibleAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={resolutionBasedMinimum}
          />
        );
      },
    },
    {
      field: 'resolutions_achieved',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'resolutions based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Achieved'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const resolutionBasedAchieved =
          scoreCardDetails.resolutionBasedDetails.achievedAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={resolutionBasedAchieved}
          />
        );
      },
    },
    {
      field: 'double incentive_minimum',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'double incentive based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Minimum'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const doubleIncentiveMinimum =
          scoreCardDetails.doubleIncentiveDetails.eligibleAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={doubleIncentiveMinimum}
          />
        );
      },
    },
    {
      field: 'double incentive_achieved',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'double incentive based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Achieved'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const doubleIncentiveAchieved =
          scoreCardDetails.doubleIncentiveDetails.achievedAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={doubleIncentiveAchieved}
          />
        );
      },
    },
    {
      field: 'Normalizes & Rollback_minimum',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Normalizes & Rollback based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Minimum'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const normalizationAndRollbackMinimum =
          scoreCardDetails.normalizationAndRollbackDetails.eligibleAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={normalizationAndRollbackMinimum}
          />
        );
      },
    },
    {
      field: 'Normalizes & Rollback_achieved',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Normalizes & Rollback based '}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Achieved'}
            />
          </Stack>
        );
      },
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const scoreCardDetails = props.row;
        const normalizationAndRollbackAchieved =
          scoreCardDetails.normalizationAndRollbackDetails.achievedAmount;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={normalizationAndRollbackAchieved}
          />
        );
      },
    },
  ];
  return COL_DEF;
};

export default HitsAndMissesColumn;
