import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type IProps = {
  value?: number | string | any;
  onChange: (event: any) => void;
  key?: string;
  minDate?: number | string | any;
  maxDate?: number | string | any;
  height?: string;
  _shouldDisableDate?: (date: Date) => boolean;
} & TextFieldProps;

const VegaMonthPicker = ({
  value,
  maxDate,
  name,
  onChange,
  minDate,
  height,
  _shouldDisableDate,
  ...inputProps
}: IProps) => {
  return (
    <>
      <FormControl
        fullWidth
        sx={{ height: height ?? '35px', fontFamily: 'Aspekta !important' }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={''}
            value={value}
            onChange={onChange}
            views={['month', 'year']}
            openTo="month"
            inputFormat="MMMM"
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={(dateParam: any) => {
              const dayOfMonth = dateParam.$D;
              const month = dateParam.$M + 1;
              const year = dateParam.$y;
              if (_shouldDisableDate) {
                return _shouldDisableDate(new Date(year, month, dayOfMonth));
              }
              return false;
            }}
            renderInput={params => (
              <TextField
                size="medium"
                style={{
                  borderRadius: '15px',
                  height: height ?? '35px',
                  fontFamily: 'Aspekta !important',
                }}
                InputProps={{
                  style: {
                    borderRadius: '15px',
                    fontFamily: 'Aspekta !important',
                  },
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    fontFamily: `var(--font-Aspekta-450) !important`,
                    fontSize: '13px',
                    lineHeight: '120%',
                    color: '#1B1D22',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                    '&.Mui-focused fieldset': {
                      borderColor: '#C5C8D0',
                      borderWidth: '1px',
                      borderRadius: '6px',
                    },
                    ':hover fieldset': {
                      borderColor: '#C5C8D0',
                    },
                  },
                  ...inputProps.sx,
                }}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: '#AFAFAF',
                    textAlign: 'center',
                    fontFamily: 'Aspekta !important',
                  },
                }}
                {...params}
                className="font-aspekta-400"
                {...inputProps}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </>
  );
};

export default VegaMonthPicker;
