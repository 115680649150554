import React from 'react';
import LmDialog from '../../../../components/common/LmDialog';
import { Stack } from '@mui/material';
import VegaText from '../../../../components/common/VegaText';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import ListOfFeedback from '../ListOfFeedback';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const VeiwCallDisposeDetailDialog = ({ open, onClose }: IProps) => {
  return (
    <LmDialog
      fullWidth
      maxWidth="md"
      open={open}
      isCloseIcon
      handleClose={onClose}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="View Feedback"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <ListOfFeedback />
      </VegaDialogContent>
    </LmDialog>
  );
};

export default VeiwCallDisposeDetailDialog;
