import { Feedback, VisibilityOutlined, Voicemail } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import VegaText from '../../../../components/common/VegaText';
import { PRIMARY } from '../../../../constants/style';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { TeleAgentAuditModal } from '../../../../types/trail';
import { TeleAuditAction } from '../ListOfTeleAudit';
import { RoleDto } from '../../../../types/roleType';

interface IProps {
  onAction: (callDispose: TeleAgentAuditModal, action: string) => void;
}

const TeleAuditColumn = ({ onAction }: IProps) => {
  const { user, roles } = useClientAuth();
  const isTeleCollectionExecutive = roles.some(
    (role: RoleDto) => role?.roleDto.name === 'TELE_COLLECTION_EXECUTIVE'
  );
  const COLUMN_DEF: GridColumns = [
    {
      field: 'lan',
      headerName: 'LAN',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'callId',
      headerName: 'Call ID',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'callerId',
      headerName: 'Caller ID',
      flex: 0.6,
      minWidth: 129,
    },

    {
      field: 'agentNumber',
      headerName: 'Agent Number',
      flex: 0.6,
      minWidth: 129,
      renderCell: () => {
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={user?.mobile ?? ''}
          />
        );
      },
    },
    {
      field: 'customerNumber',
      headerName: 'Customer Number',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'dispositionCode',
      headerName: 'Disposition Code',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'status',
      headerName: 'Call Status',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'pickupTime',
      headerName: 'Pickup Time',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'hangUpTime',
      headerName: 'Hang Up Time',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'hangUpBy',
      headerName: 'Hang Up By',
      flex: 0.6,
      minWidth: 129,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const callDispose = props.row as TeleAgentAuditModal;
        const loanId =
          typeof callDispose.lan === 'number'
            ? callDispose.lan.toString()
            : callDispose.lan;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="Recording"
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <Voicemail sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />
              }
              onClick={() => onAction(callDispose, TeleAuditAction.DOWNLOAD)}
            />
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onAction(callDispose, TeleAuditAction.VIEW)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
            {!isTeleCollectionExecutive && (
              <VegaIconButton
                tooltipTitle="feedback"
                sx={{ bgcolor: '#E7EDFC' }}
                icon={
                  <Feedback sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />
                }
                onClick={() => onAction(callDispose, TeleAuditAction.FEEDBACK)}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

export default TeleAuditColumn;
