import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { downloadBulkErrorFile } from '../../actions/AllocationActions';
import {
  fetchSaarthiList,
  getSystemCollection,
} from '../../features/systemCollectionSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { GetSaarthiListRequest } from '../../types/request/systemCollectionRequest';
import { SystemCollectionListViewModel } from '../../types/systemCollectionType';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';

const ListOfSystemCollection = () => {
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { totalItems, loading, systemCollections } =
    useAppSelector(getSystemCollection);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const onDownloadClick = async (
    systemCollection: SystemCollectionListViewModel
  ) => {
    try {
      const requestId = systemCollection.requestId;
      const url = await dispatch(
        downloadBulkErrorFile({ requestId: requestId })
      ).unwrap();
      window.open(url);
      setSnackbar(' Bulk Error File Downloaded successfully');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const fetchData = () => {
    const request = {
      page,
      size,
    } as GetSaarthiListRequest;
    dispatch(fetchSaarthiList(request));
  };

  useEffect(() => {
    fetchData();
  }, [page, size]);

  return (
    <>
      <VegaPageContent mt={'1rem'}>
        <VegaDataGrid
          data={systemCollections}
          columns={getColumnDefinition({
            onDownloadClick: systemCollection => {
              onDownloadClick(systemCollection);
            },
          })}
          idColumn={'requestId'}
          paginationMode="server"
          loading={loading}
          rowCount={totalItems}
          page={page}
          pageSize={size}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfSystemCollection;

const getColumnDefinition = (data: {
  onDownloadClick: (systemCollection: SystemCollectionListViewModel) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 0.7,
    },
    // {
    //   field: 'allocationType',
    //   headerName: 'Allocation Type',
    //   flex: 0.7,
    // },
    {
      field: 'requestId',
      headerName: 'Request Id',
      flex: 0.7,
    },
    // {
    //   field: 'date',
    //   headerName: 'Date',
    //   flex: 0.7,
    // },
    {
      field: 'totalEntries',
      headerName: 'Total Entries',
      flex: 0.7,
    },
    {
      field: 'success',
      headerName: 'Success',
      flex: 0.7,
    },
    {
      field: 'failure',
      headerName: 'Failure',
      flex: 0.7,
    },
    {
      field: 'action',
      headerName: 'Error Details',
      flex: 0.7,
      renderCell: props => {
        const systemCollection = props.row as SystemCollectionListViewModel;
        const hasErrorFile = systemCollection?.failureReason?.length;
        if (hasErrorFile) {
          return (
            <VegaButton
              text={'Download'}
              variant="text"
              onClick={() => data.onDownloadClick(systemCollection)}
            />
          );
        }
        return <VegaButton disabled text={'No Errors'} variant="text" />;
      },
    },
  ];
  return COL_DEF;
};
