import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoanService } from '../Apis/LoanServices';
import { RootState } from '../store';
import { LoanData } from '../types/loan';
import { LoanListRequest } from '../types/request/loan';
import { SendPaymentLinkRequest } from '../types/request/receiptManagment';
import { AccountsFilterFormDataType } from '../components/Accounts/AccountFilterForm';

export interface ReceiptState {
  accounts: LoanData[];
  totalItems: number;
  loading: boolean;
  error: string | null;
  selectedRows: string[];
  sendPaymentLinkState: Partial<SendPaymentLinkRequest> | null;
  filterForm: Partial<AccountsFilterFormDataType>;
}

const initialState: ReceiptState = {
  accounts: [],
  selectedRows: [],
  loading: false,
  error: null,
  totalItems: 0,
  sendPaymentLinkState: null,
  filterForm: {},
};

export const getAccountList = createAsyncThunk(
  'accountSlice/loan/list',
  async (data: LoanListRequest) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);

const accountsSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    setSendPaymentLinkState: (state: ReceiptState, action) => {
      const { payload } = action;
      state.sendPaymentLinkState = payload;
    },

    setSelectedRows: (state: ReceiptState, action) => {
      const { payload } = action;
      state.selectedRows = payload;
    },
    setFilterForm: (state: ReceiptState, action) => {
      const { payload } = action;
      state.filterForm = payload;
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getAccountList.pending, state => {
        state.loading = true;
        state.error = null;
        state.accounts = [];
      })
      .addCase(getAccountList.fulfilled, (state, action) => {
        const accountList = [...action.payload.records];
        // const accountViewModalList: AccountAgentViewModel[] = accountList.map(
        //   (loan: LoanData) => {
        //     const id = loan.loanId;
        //     const name = loan.customerDetails
        //       ? loan.customerDetails[0]?.customerName
        //       : '-';
        //     const email = loan.customerDetails
        //       ? loan.customerDetails[0]?.email
        //       : '-';
        //     const product = loan.loanMaster?.loanProductType ?? '-';
        //     const emi = loan.loanMaster?.emi ?? 0;
        //     const overDue = loan.lmsVariables
        //       ? loan.lmsVariables?.overdueAmount ?? 0
        //       : 0;
        //     const total = loan.lmsVariables
        //       ? loan.lmsVariables?.chargeDue ?? 0
        //       : 0;
        //     const allowedAmount = loan.lmsVariables
        //       ? loan.lmsVariables?.overdueAmount ?? 0
        //       : 0;
        //     const lan = loan.loanId;
        //     const pos = loan.lmsVariables
        //       ? loan.lmsVariables?.outstandingAmount ?? 0
        //       : 0;
        //     const ptp = 0;
        //     const bounceCharge = 0;
        //     const penaltyCharge = 0;
        //     const legalCharge = 0;
        //     const other = 0;
        //     const bucket = loan.lmsVariables
        //       ? loan.lmsVariables?.bucket ?? '-'
        //       : '-';
        //     const contactNumber = loan.customerDetails
        //       ? loan.customerDetails[0]?.mobileNo
        //       : '-';
        //     const allocationDate = loan.lmsVariables
        //       ? loan.lmsVariables?.allocationDate
        //         ? DateUtility.formatStringToDDMMYYYY(
        //             loan.lmsVariables?.allocationDate
        //           )
        //         : '--'
        //       : '--';
        //     const address = loan.customerDetails
        //       ? loan.customerDetails[0]?.customerAddress
        //       : '-';
        //     return {
        //       id,
        //       name,
        //       email,
        //       product,
        //       emi,
        //       overDue,
        //       total,
        //       allowedAmount,
        //       lan,
        //       pos: StringUtility.formatToINR(pos),
        //       ptp,
        //       bounceCharge,
        //       penaltyCharge,
        //       legalCharge,
        //       other,
        //       bucket,
        //       contactNumber,
        //       allocationDate,
        //       address,
        //     };
        //   }
        // );
        state.loading = false;
        state.accounts = accountList;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAccountList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});
export const { setSelectedRows, setSendPaymentLinkState, setFilterForm } =
  accountsSlice.actions;
export const getAccountState = (state: RootState) => state.accountSlice;
export default accountsSlice;
