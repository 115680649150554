import { Collapse, Stack } from '@mui/material';
import { toLowerCase } from '../../constants/commonFunction';
import AllBranchSelector from '../../pages/MinVisits/modules/AllBranchSelector';
import LoanAccountSelector from '../../pages/MinVisits/modules/LoanAccountSelector';
import RegionSelector from '../../pages/MinVisits/modules/RegionSelector';
import { ComparisonType } from '../../types/accountType';
import { RupeeIcon } from '../Icons/Icons';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaRadioGroup, { VegaRadioOption } from '../common/VegaRadioGroup';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import { getAccountState, setFilterForm } from '../../features/accountsSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import BucketSelector from '../../pages/MinVisits/modules/BucketSelector';
import CycleSelector from '../../pages/MinVisits/modules/CycleSelector';
import { VerticalTypes } from '../Perfomance/PerformanceFilterSection';
import _ from 'lodash';

export enum FilterAttributes {
  POS = 'POS',
  BUCKET = 'BUCKET',
  BRANCH = 'BRANCH',
  REGION = 'REGION',
  CYCLE = 'CYCLE',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  LOAN_STATUS = 'LOAN_STATUS',
}

export enum POS {
  Greater_Than = 'Greater_than',
  Less_Than = 'Less_than',
  Equal_To = 'Equal_To',
  Range = 'Range',
}
export enum ProductTypeEnum {
  HOME_LOAN = 'Home Loan',
  SME_TL = 'SME-TL',
  BUSINESS_LOANS = 'Business Loans',
  PREAPPROVED_LOANS = 'PreApproved Loans',
  HOME_EQUITY = 'Home Equity',
  MSME_TL = 'MSME-TL',
}

enum AllocationType {
  FIELD = 'FIELD',
  TELE = 'TELE',
}

export type AccountsFilterFormDataType = {
  pos: string;
  posValue: string;
  posMinValue: string;
  posMaxValue: string;
  bucket: string[];
  branch: string[];
  cycle: string;
  segment: string;
  region: string[];
  loanStatus: string[];
};

enum InputType {
  pos = 'pos',
  posValue = 'posValue',
  bucket = 'bucket',
  branch = 'branch',
  posMinValue = 'posMinValue',
  posMaxValue = 'posMaxValue',
  cycle = 'cycle',
  segment = 'segment',
  region = 'region',
  loanStatus = 'loanStatus',
  allocationType = 'allocationType',
}

export enum FilterOP {
  GTE = 'GTE',
  LTE = 'LTE',
  EQ = 'EQ',
  IN = 'IN',
}
interface IProps {
  onApply: () => void;
  onClear: () => void;
  selectedTab?: number;
}

const LoanAccountsFilterForm = ({ onApply, onClear, selectedTab }: IProps) => {
  const dispatch = useAppDispatch();
  const { filterForm } = useAppSelector(getAccountState);
  function updateFormData(type: InputType, value: string | string[]) {
    const updateForm = { ...filterForm, [type]: value };
    dispatch(setFilterForm(updateForm));
  }

  const onRegionSelect = (region: string) => {
    const preRegion =
      filterForm.region && filterForm.region.length
        ? [...filterForm.region]
        : [];
    if (preRegion.includes(region)) {
      const filteredRegion = preRegion.filter(
        (selectedRegion: string) => selectedRegion !== region
      );
      updateFormData(InputType.region, filteredRegion);
    } else {
      updateFormData(InputType.region, [...preRegion, region]);
    }
  };
  const onRegionDelete = (index: number) => {
    const preRegion =
      filterForm.region && filterForm.region.length
        ? [...filterForm.region]
        : [];
    preRegion.splice(index, 1);
    updateFormData(InputType.region, preRegion);
  };

  const onBranchSelect = (branch: string) => {
    const preBranch =
      filterForm.branch && filterForm.branch.length
        ? [...filterForm.branch]
        : [];
    if (preBranch.includes(branch)) {
      const filteredBranch = preBranch.filter(
        (selectedRegion: string) => selectedRegion !== branch
      );
      updateFormData(InputType.branch, filteredBranch);
    } else {
      updateFormData(InputType.branch, [...preBranch, branch]);
    }
  };

  const onLoanStatusSelect = (status: string) => {
    const preLoanStatus =
      filterForm.loanStatus && filterForm.loanStatus.length
        ? [...filterForm.loanStatus]
        : [];
    if (preLoanStatus.includes(status)) {
      const filteredLoanStatus = preLoanStatus.filter(
        (selectedRegion: string) => selectedRegion !== status
      );
      updateFormData(InputType.loanStatus, filteredLoanStatus);
    } else {
      updateFormData(InputType.loanStatus, [...preLoanStatus, status]);
    }
  };

  const onBucketSelect = (status: string) => {
    const preBucketStatus =
      filterForm.bucket && filterForm.bucket.length
        ? [...filterForm.bucket]
        : [];
    if (preBucketStatus.includes(status)) {
      const filteredBucketStatus = preBucketStatus.filter(
        (selectedBucket: string) => selectedBucket !== status
      );
      updateFormData(InputType.bucket, filteredBucketStatus);
    } else {
      updateFormData(InputType.bucket, [...preBucketStatus, status]);
    }
  };

  const onCycleSelect = (status: string) => {
    const preCycleStatus =
      filterForm.cycle && filterForm.cycle.length ? [...filterForm.cycle] : [];
    if (preCycleStatus.includes(status)) {
      const filteredCycleStatus = preCycleStatus.filter(
        (selectedCycle: string) => selectedCycle !== status
      );
      updateFormData(InputType.cycle, filteredCycleStatus);
    } else {
      updateFormData(InputType.cycle, [...preCycleStatus, status]);
    }
  };

  const onCycleDelete = (index: number) => {
    const preCycle =
      filterForm.cycle && filterForm.cycle.length ? [...filterForm.cycle] : [];
    preCycle.splice(index, 1);
    updateFormData(InputType.cycle, preCycle);
  };

  const onBucketDelete = (index: number) => {
    const preBucket =
      filterForm.bucket && filterForm.bucket.length
        ? [...filterForm.bucket]
        : [];
    preBucket.splice(index, 1);
    updateFormData(InputType.bucket, preBucket);
  };

  const onBranchDelete = (index: number) => {
    const preBranch =
      filterForm.branch && filterForm.branch.length
        ? [...filterForm.branch]
        : [];
    preBranch.splice(index, 1);
    updateFormData(InputType.branch, preBranch);
  };

  const onLoanStatusDelete = (index: number) => {
    const preLoanStatus =
      filterForm.loanStatus && filterForm.loanStatus.length
        ? [...filterForm.loanStatus]
        : [];
    preLoanStatus.splice(index, 1);
    updateFormData(InputType.loanStatus, preLoanStatus);
  };

  const isFilterFormValid = () => {
    const isPosValid =
      filterForm.pos &&
      (filterForm.posValue ||
        (filterForm.posMaxValue && filterForm.posMinValue));
    const isBucketValid = filterForm.bucket;
    const isCycleValid = filterForm.cycle?.length;
    const isRegion = filterForm?.region?.length;
    const isBranch = filterForm?.branch?.length;
    const isProductTypeValid = filterForm.segment?.length;
    const isLoanStatusValid = filterForm?.loanStatus?.length;
    const isAllocationTypeValid =
      selectedTab === 1 && filterForm?.allocationType;
    return (
      !!isPosValid ||
      !!isBucketValid ||
      !!isCycleValid ||
      !!isProductTypeValid ||
      !!isRegion ||
      !!isBranch ||
      !!isLoanStatusValid ||
      !!isAllocationTypeValid
    );
  };
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton
              text="Apply Filter"
              onClick={onApply}
              disabled={!isFilterFormValid()}
            />
          </Stack>
        );
      }}
    >
      <Stack spacing={'1.5rem'}>
        <VegaFormInputField label={'pos'}>
          <VegaRadioGroup
            options={getPosOption()}
            value={filterForm.pos}
            onChange={e => {
              updateFormData(InputType.pos, e.target.value);
            }}
          />
        </VegaFormInputField>
        {filterForm.pos && filterForm.pos !== ComparisonType.Range && (
          <Collapse
            in={filterForm.pos && filterForm.pos !== ComparisonType.Range}
          >
            <VegaFormInputField label={'pos value'}>
              <LmTextField
                value={filterForm.posValue}
                onChange={e => {
                  updateFormData(InputType.posValue, e.target.value);
                }}
                startAdornment={<RupeeIcon />}
              />
            </VegaFormInputField>
          </Collapse>
        )}
        {filterForm.pos === ComparisonType.Range && (
          <Collapse in={filterForm.pos === ComparisonType.Range}>
            <Stack direction={'column'} spacing={2}>
              <VegaFormInputField label={'POS Max Value'}>
                <LmTextField
                  value={filterForm.posMaxValue}
                  onChange={e => {
                    updateFormData(InputType.posMaxValue, e.target.value);
                  }}
                  startAdornment={<RupeeIcon />}
                />
              </VegaFormInputField>
              <VegaFormInputField label={'POS Min Value'}>
                <LmTextField
                  value={filterForm.posMinValue}
                  onChange={e => {
                    updateFormData(InputType.posMinValue, e.target.value);
                  }}
                  startAdornment={<RupeeIcon />}
                />
              </VegaFormInputField>
            </Stack>
          </Collapse>
        )}
        <VegaFormInputField label={'bucket'}>
          <BucketSelector
            isMultiSelect
            selected={filterForm?.bucket ?? ''}
            handleChange={selected => onBucketSelect(selected)}
            handleDelete={index => onBucketDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Cycle'}>
          <CycleSelector
            isMultiSelect
            selected={filterForm?.cycle ?? ''}
            handleChange={selected => onCycleSelect(selected)}
            handleDelete={index => onCycleDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Segment'}>
          <VegaSelect
            options={getSegementOption()}
            value={filterForm.segment}
            onChange={e => {
              updateFormData(InputType.segment, e.target.value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Region'}>
          <RegionSelector
            isMultiSelect
            selected={filterForm.region ?? []}
            handleChange={selected => onRegionSelect(selected)}
            handleDelete={index => onRegionDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Branch'}>
          <AllBranchSelector
            isMultiSelect
            selected={filterForm?.branch ?? []}
            handleChange={selected => onBranchSelect(selected)}
            handleDelete={index => onBranchDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Loan Status'}>
          <LoanAccountSelector
            isMultiSelect
            selected={filterForm?.loanStatus ?? ''}
            handleChange={selected => onLoanStatusSelect(selected)}
            handleDelete={index => onLoanStatusDelete(index)}
          />
        </VegaFormInputField>
        {selectedTab === 1 && (
          <VegaFormInputField label={'Allocation Type'}>
            <VegaSelect
              options={allocationTypeOptions()}
              value={filterForm.allocationType}
              onChange={e => {
                updateFormData(InputType.allocationType, e.target.value);
              }}
            />
          </VegaFormInputField>
        )}
      </Stack>
    </VegaDrawerContent>
  );
};

export default LoanAccountsFilterForm;

const getPosOption = () => {
  const options = Object.keys(POS).map((comparison: string, index: number) => {
    const comparisonType = Object.values(POS)[index];
    return {
      label: toLowerCase(comparison),
      value: comparisonType,
    } as VegaRadioOption;
  });
  return options;
};
const getSegementOption = () => {
  const options = Object.values(VerticalTypes).map((segment: string) => {
    return {
      label: segment,
      value: segment,
    } as VegaSelectOption;
  });
  return options;
};

const allocationTypeOptions = () =>
  [AllocationType.TELE, AllocationType.FIELD].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
