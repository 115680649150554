import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllocationRules } from '../../actions/AllocationActions';
import { PRIMARY } from '../../constants/style';
import {
  getAllocations,
  setSelectedRows,
} from '../../features/allocationSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { AllocationRuleViewModel } from '../../types/allocations';
import UpdateRuleDrawer from '../Allocation/UpdateRuleDrawer';
import { EditIcon } from '../Icons/Icons';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaIconButton from '../common/VegaIconButton';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';

interface IProps {
  search: string;
}

const ListOfCriteria = ({ search }: IProps) => {
  const dispatch = useAppDispatch();
  const { userForId } = useClientAuth();
  const {
    allocationRuleViewModels,
    loadingAllocationRules,
    allocationRuleTotalItems,
    selectedRows,
    allocationRulePage,
  } = useAppSelector(getAllocations);
  const [size, setSize] = useState<number>(10);
  // const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    open: openViewRuleDrawer,
    close: closeViewRuleDrawer,
    isOpen: isViewRuleDrawer,
    props: viewRuleDrawerProps,
  } = useDrawer();

  const handleSelectionModelChange = (selectionModel: string[]) => {
    const selected = [...selectedRows];
    const filter = selectionModel.filter(row => !selected.includes(row));
    dispatch(setSelectedRows(filter));
  };

  const fetchData = () => {
    if (search.length) {
      dispatch(
        fetchAllocationRules({
          name: search,
          page: 0,
          size: 10,
        })
      );
    }
  };

  const preprocessAllocationData = (
    allocations: AllocationRuleViewModel[]
  ): AllocationRuleViewModel[] => {
    return allocations.map(allocation => {
      let agentName = 'NA';

      if (allocation.agentId) {
        const user = userForId(allocation.agentId);
        if (user) {
          agentName = user;
        }
      }

      return { ...allocation, agentName };
    });
  };

  function loadData(page: number) {
    dispatch(
      fetchAllocationRules({
        page: page,
        size: 10,
      })
    );
  }

  useEffect(() => {
    if (search.length) {
      const getLoanData = setTimeout(fetchData, 300);
      return () => clearTimeout(getLoanData);
    } else {
      loadData(allocationRulePage);
    }
  }, [size, search]);

  return (
    <>
      <VegaPageContent>
        <VegaDataGrid
          data={preprocessAllocationData(allocationRuleViewModels)}
          loading={loadingAllocationRules}
          columns={getColumnDefinition({
            onViewDetails: rule => {
              openViewRuleDrawer({ rule: rule });
            },
          })}
          idColumn="id"
          rowCount={allocationRuleTotalItems}
          page={allocationRulePage}
          checkboxSelection
          pageSize={size}
          paginationMode="server"
          onPageChange={page => loadData(page)}
          onPageSizeChange={size => setSize(size)}
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>
      <UpdateRuleDrawer
        onClose={closeViewRuleDrawer}
        open={isViewRuleDrawer}
        rule={viewRuleDrawerProps.rule}
      />
    </>
  );
};

export default ListOfCriteria;

const getColumnDefinition = (data: {
  onViewDetails: (allocation: AllocationRuleViewModel) => void;
}) => {
  const navigate = useNavigate();
  const COL_DEF: GridColumns = [
    {
      field: 'ruleName',
      headerName: 'Rule Name',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        const displayText = rule.name;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentId',
      headerName: 'Agent',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        const displayText = rule?.agentName;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'criteriaCount',
      headerName: 'Criteria Count',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        const displayText = rule.criteriaList.length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        return (
          <Stack direction={'row'} gap={1}>
            {/* <VegaButton
              text={'View Details'}
              variant="text"
              onClick={() => {
                navigate(
                  ROUTES.ALLOCATION_POLICY_VIEW.replace(':criteriaId', rule.id)
                );
              }}
            /> */}
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() =>
                navigate(
                  ROUTES.ALLOCATION_POLICY_VIEW.replace(':criteriaId', rule.id)
                )
              }
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
            <VegaButton
              text=""
              startIcon={<EditIcon />}
              sx={{
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
                ':hover': {
                  bgcolor: '#E7EDFC',
                  color: PRIMARY.darkBlue,
                },
              }}
              onClick={() => data.onViewDetails(rule)}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getAllocationRuleForRow = (props: any) =>
  props.row as AllocationRuleViewModel;
