import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaImagePreviewDialog from '../../components/common/VegaImagePreviewDialog';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';
import VegaText from '../../components/common/VegaText';
import { auctionState, fetchAuctions } from '../../features/auctionSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { Auction, AuctionStatus, Possession } from '../../types/auction';
import { DateUtility } from '../../utils/DateUtlility';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../components/common/VegaIconButton';
import { PRIMARY } from '../../constants/style';

function LiveAuctions() {
  const dispatch = useAppDispatch();
  const { auctions, loading, pageSize } = useAppSelector(auctionState);
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const {
    open: openImagePreviewDialog,
    close: closeImagePreviewDialog,
    props: imagePreviewDialogProps,
    isOpen: isImagePreviewDialogOpen,
  } = useDrawer();

  useEffect(() => {
    dispatch(fetchAuctions({ includePossessionDetails: true, page: page }));
  }, [page]);

  return (
    <div>
      <VegaPageHeader
        title="Live Auctions"
        renderRightView={() => (
          <LmFilterSection hideFilter search={search} setSearch={setSearch} />
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={auctions}
          loading={loading}
          columns={getColumnDefinition({
            onClick: auction => {
              navigate(
                ROUTES.LIVE_AUCTION_DETAILS.replace(':auctionId', auction.id)
              );
            },
            onPreviewClick: possession => {
              openImagePreviewDialog({ possession: possession });
            },
          })}
          idColumn="id"
          page={page}
          pageSize={10}
          rowCount={pageSize}
          onPageChange={page => {
            setPage(page);
          }}
        />
      </VegaPageContent>

      <VegaImagePreviewDialog
        open={isImagePreviewDialogOpen}
        onClose={function (): void {
          closeImagePreviewDialog();
        }}
        images={imagePreviewDialogProps?.possession?.images ?? []}
        lan={imagePreviewDialogProps?.possession?.lan}
      />
    </div>
  );
}

export default LiveAuctions;

const getColumnDefinition = (data: {
  onClick: (auction: Auction) => void;
  onPreviewClick: (possession: Possession) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'lanId',
      headerName: 'Lan Id',
      flex: 0.7,
      renderCell: props => {
        const possession = getPossession(props);

        const displayText = possession.lan;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      renderCell: props => {
        const possession = getPossession(props);
        const displayText = possession.address;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'currentBid',
    //   headerName: 'Current Bid',
    //   flex: 0.7,
    //   renderCell: props => {
    //     const auction = getAuctionForRow(props);
    //     const displayText = 'Name';
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'images',
      headerName: 'Image',
      flex: 0.7,
      renderCell: props => {
        const liveAuction = getPossession(props);
        return (
          <VegaButton
            text="Preview"
            variant="text"
            sx={{
              px: 0,
            }}
            onClick={() => data.onPreviewClick(liveAuction)}
          />
        );
      },
    },
    {
      field: 'type',
      headerName: 'Property Type',
      flex: 0.7,
      renderCell: props => {
        const possession = getPossession(props);
        const displayText = possession.type;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'auctionExpiry',
      headerName: 'Auction Expiry',
      flex: 0.7,
      renderCell: props => {
        const auction = getAuctionForRow(props);
        const displayText = DateUtility.formatStringToDDMMYYYYWithTime(
          auction.endDateTime
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const auction = getAuctionForRow(props);
        const status = auction?.status;
        return (
          <VegaPill
            text={status}
            textColor={
              status === AuctionStatus.IN_PROGRESS ? '#318439' : '#E87001'
            }
            borderColor={
              status === AuctionStatus.IN_PROGRESS ? '#318439' : '#E87001'
            }
            backgroundColor={
              status === AuctionStatus.IN_PROGRESS ? '#EBF4EC' : '#FFF2E6'
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      renderCell: props => {
        const auction = getAuctionForRow(props);
        return (
          // <VegaButton
          //   variant="text"
          //   text="View Details"
          //   onClick={() => data.onClick(auction)}
          // ></VegaButton>
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => data.onClick(auction)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COL_DEF;
};

const getAuctionForRow = (props: any) => props.row as Auction;

const getPossession = (props: any) => {
  const auction = getAuctionForRow(props);
  if (!auction?.metaData) return;
  if (Object.hasOwn(auction.metaData, 'possessionItem')) {
    return auction.metaData['possessionItem'] as Possession;
  }
};
