import axios from 'axios';
import { ApiService } from '../types/api';
import { LegalBaseResponse } from '../types/legal';
import {
  GetGeoVerifiedListRequest,
  SubmitForNonGeoVerificationRequest,
  UpdateStatusGeoVerifiedRequest,
} from '../types/request/geoVerificationRequest';
import { PaginatedResponse } from '../utils/api';
import { GeoVerificationResponse } from '../types/geoVerificationType';

const Endpoint = {
  BASE: '/visit',
  NON_GEO_VERIFIED: '/non-geo-verified',
  LIST: '/request/list',
  APPROVE: '/request/approve',
  SUBMIT_FOR_VERIFICATION: '/non-geo-verified/request',
};

const NON_GEO_VERIFIED_BASE_URL = Endpoint.BASE + Endpoint.NON_GEO_VERIFIED;

export class GeoVerificationService extends ApiService {
  static async getGeoVerifiedList(
    request: Partial<GetGeoVerifiedListRequest>
  ): Promise<PaginatedResponse<GeoVerificationResponse>> {
    const endpoint = NON_GEO_VERIFIED_BASE_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateStatusGeoVerified(
    request: Partial<UpdateStatusGeoVerifiedRequest>
  ): Promise<LegalBaseResponse> {
    const endpoint = NON_GEO_VERIFIED_BASE_URL + Endpoint.APPROVE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async submitForVerification(
    request: SubmitForNonGeoVerificationRequest
  ) {
    const endpoint =
      NON_GEO_VERIFIED_BASE_URL + Endpoint.SUBMIT_FOR_VERIFICATION;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
