import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CollectedVSCollectableGraph from './modules/Graphs/CollectedVSCollectableGraph';
import BucketGraph from './modules/Graphs/BucketGraph';
import { BORDER_COLLECTION, COMMON } from '../../constants/style';
import PrePostBounce from './modules/PrePostBounce';
import RollForwardAndAmountDue from './modules/RollForwardAndAmountDue';
import VegaText from '../common/VegaText';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppSelector } from '../../store';
import { AthenaQueryResponse } from '../../types/performanceType';
import { getPerformanceDashboard } from '../../features/performanceDashboardSlice';
import { PRE_POST_BOUNCE_POS_AMOUNTS } from '../../constants/athenaQueryId';
import { PerformanceServices } from '../../Apis/PerformanceServices';
import { getErrorMessageFromErrorObj } from '../../utils/api';

export interface IPosAmountsProps {
  posAmounts: AthenaQueryResponse[];
  loading: boolean;
}

const CollectedVsCollectabelContainer = () => {
  const { setSnackbar } = useSnackbar();
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const [loading, setLoading] = useState<boolean>(false);
  const [posAmounts, setPosAmounts] = useState<AthenaQueryResponse[]>([]);

  const getPrePostBounceCountQuery = async () => {
    setLoading(true);
    try {
      const totalCount = await PerformanceServices.getAthenaQueries({
        queryId: PRE_POST_BOUNCE_POS_AMOUNTS,
        params: filterData,
      });
      setPosAmounts(totalCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    PRE_POST_BOUNCE_POS_AMOUNTS.length && getPrePostBounceCountQuery();
  }, []);
  return (
    <>
      <Stack
        sx={{
          p: 3,
          bgcolor: 'white',
          borderRadius: '12px',
          border: BORDER_COLLECTION,
        }}
        gap={2}
      >
        <VegaText
          text={'Collected VS Collectable'}
          fontSize={17}
          fontWeight={600}
          color={COMMON.balck}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PrePostBounce posAmounts={posAmounts} loading={loading} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RollForwardAndAmountDue
              posAmounts={posAmounts}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CollectedVSCollectableGraph />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BucketGraph />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CollectedVsCollectabelContainer;
