import axios from 'axios';
import { ApiService } from '../types/api';
import { AreaListProps, RescheduleVisit } from '../types/minVisitsType';
import {
  AreaListRequest,
  CreateMinVisitRuleRequest,
  CreateVisitBulkRequest,
  CreateVisitRequest,
  MinVisitListProps,
  RescheduleVisitRequest,
} from '../types/request/minVisitsRequest';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/master',
  VISIT: '/visit',
  ALLOCATION: '/allocation',
  MIN_VISIT: '/minVisitRule',
  AREA: '/area',
  LIST: '/list',
  BULK: '/bulk',
  RESCHEDULE: '/reschedule',
  BRANCH_REGION: '/branchRegion',
  REGION: '/region',
  BRANCH: '/:regionCode/branch',
  ALL_BRANCH: '/branches',
};

const AREA_BASE_URL = Endpoint.BASE + Endpoint.AREA;
const MIN_VISIT_URL = Endpoint.VISIT + Endpoint.MIN_VISIT;
const BULK_VISIT_URL = Endpoint.VISIT + Endpoint.BULK;
const RESCHEDULE_VISIT_URL = Endpoint.VISIT + Endpoint.RESCHEDULE;
const REGION_URL =
  Endpoint.BASE + Endpoint.BRANCH_REGION + Endpoint.LIST + Endpoint.REGION;
const BRANCH_URL =
  Endpoint.BASE + Endpoint.BRANCH_REGION + Endpoint.LIST + Endpoint.BRANCH;
const ALL_BRANCH_URL =
  Endpoint.BASE + Endpoint.BRANCH_REGION + Endpoint.LIST + Endpoint.ALL_BRANCH;

export class MinVisitsSevices extends ApiService {
  static async getAreaList(
    request: Partial<AreaListRequest>
  ): Promise<PaginatedResponse<AreaListProps>> {
    const endpoint = AREA_BASE_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getRegionList(): Promise<string[]> {
    const endpoint = REGION_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getBranchList(regionCode: string) {
    const endpoint = BRANCH_URL.replace(':regionCode', regionCode);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getAllBranchList() {
    const endpoint = ALL_BRANCH_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getMinVisitRuleList(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<PaginatedResponse<MinVisitListProps>> {
    const endpoint = MIN_VISIT_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async createMinVisitRule(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = MIN_VISIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createMinVisit(request: Partial<CreateVisitRequest>) {
    const endpoint = Endpoint.VISIT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createMinVisitBulk(
    request: Partial<CreateVisitBulkRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = BULK_VISIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateMinVisitRule(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = MIN_VISIT_URL;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
  static async deleteMinVisitRule(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = MIN_VISIT_URL;
    const response = await axios.delete(`${endpoint}/${request.id}`);
    return response.data;
  }

  static async rescheduleVisit(
    request: Partial<RescheduleVisitRequest>
  ): Promise<RescheduleVisit> {
    const endpoint = RESCHEDULE_VISIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
