import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GREY } from '../../constants/style';
import { ApproveIcon } from '../Icons/Icons';
import LmDialog from './LmDialog';
import VegaButton from './VegaButton';
import VegaDialogContent from './VegaDialogContent';
import VegaText from './VegaText';

interface IApproveDialogProps {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  children: JSX.Element;
  header: string;
  buttonText: string;
}

const VegaApproveDialog = ({
  open,
  onClose,
  children,
  onApprove,
  header,
  buttonText,
}: IApproveDialogProps) => {
  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={open}
      handleClose={onClose}
      buttonComponent={() => (
        <>
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <VegaButton
              text="Cancel"
              fullWidth
              variant="outlined"
              sx={{
                color: GREY.dark,
                borderColor: GREY.dark,
                bgcolor: 'white',
                ':hover': {
                  color: GREY.dark,
                  borderColor: GREY.dark,
                  bgcolor: 'white',
                },
              }}
              onClick={onClose}
              startIcon={<Close sx={{ color: GREY.dark }} />}
            />
            <VegaButton
              fullWidth
              text={buttonText}
              startIcon={<ApproveIcon strokeColor="white" />}
              onClick={onApprove}
            />
          </Stack>
        </>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text={header}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>{children}</VegaDialogContent>
    </LmDialog>
  );
};

export default VegaApproveDialog;
