import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import React from 'react';

interface IProps extends TooltipProps {
  children: JSX.Element;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const VegaTooltip = ({ children, ...rest }: IProps) => {
  return <StyledTooltip {...rest}>{children}</StyledTooltip>;
};

export default VegaTooltip;
