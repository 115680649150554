import { Stack } from '@mui/material';
import { useState } from 'react';
import LmButton from '../../../../components/common/LmButton';
import LmDialog from '../../../../components/common/LmDialog';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaText from '../../../../components/common/VegaText';
import { RED } from '../../../../constants/style';

interface Props {
  show: boolean;
  onClose: () => void;
  onReject: (reason: string) => void;
}

const RejectOtsDialog = ({ show, onClose, onReject }: Props) => {
  const [reason, setReason] = useState<string>('');

  function onSubmit() {
    onReject(reason);
    handleClose();
  }

  function handleClose() {
    onClose();
    setReason('');
  }

  const isInputValid = () => {
    return reason.length > 0;
  };

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={handleClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <LmButton
            fullWidth
            text="Reject"
            bgColor={'rgba(186, 28, 28, 1)'}
            onClick={onSubmit}
            disabled={isInputValid() === false}
          />
          <VegaButton
            text="Cancel"
            fullWidth
            variant="outlined"
            onClick={handleClose}
            sx={{ borderColor: '#C5C8D0' }}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#F8E8E8'}
        >
          <VegaText
            text="Reject Ots request?"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={RED.red}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem">
        <VegaText
          text="What is your reason for rejection?"
          style={{
            color: 'rgba(103, 107, 118, 1)',
            fontSize: '0.625rem',
            fontWeight: 700,
            lineHeight: '124%',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
          }}
        />

        <LmTextField
          placeholder="Enter reason here"
          minRows={4}
          onChange={e => setReason(e.target.value)}
        />
      </Stack>
    </LmDialog>
  );
};

export default RejectOtsDialog;
