import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { PerformanceServices } from '../../../../Apis/PerformanceServices';
import { TOTAL_RECEIPT_ISSUED_COUNT } from '../../../../constants/athenaQueryId';
import { toLowerCase } from '../../../../constants/commonFunction';
import { BORDER_COLLECTION, COMMON } from '../../../../constants/style';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { AthenaQueryResponse } from '../../../../types/performanceType';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import VegaText from '../../../common/VegaText';
import { getPerformanceDashboard } from '../../../../features/performanceDashboardSlice';
import { useAppSelector } from '../../../../store';

const TotalReceiptsIssueCountGraph = () => {
  const { filterData } = useAppSelector(getPerformanceDashboard);

  const { setSnackbar } = useSnackbar();
  const [totalReceiptsIssueCountState, settotalReceiptsIssueCountState] =
    useState<AthenaQueryResponse[]>([]);
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
        columnWidth: '25px',
        distributed: true,
      },
    },
    yaxis: {
      title: {
        text: 'Count',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    xaxis: {
      categories: totalReceiptsIssueCountState?.length
        ? totalReceiptsIssueCountState?.map(
            (athena: AthenaQueryResponse) =>
              athena.status && toLowerCase(athena.status)
          )
        : [],

      labels: {
        style: {
          colors: ['#78B57E', '#FFA755', '#E8D15A', '#1047DC'],
        },
      },
      title: {
        text: 'Status of Receipts',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },

    colors: ['#78B57E', '#FFA755', '#E8D15A', '#1047DC'],
    series: [
      {
        data: totalReceiptsIssueCountState?.length
          ? totalReceiptsIssueCountState?.map((athena: AthenaQueryResponse) =>
              athena?.count ? parseInt(athena.count) : 0
            )
          : [],
      },
    ],
  };

  const getAthenaQuery = async (queryId: string) => {
    try {
      const response = await PerformanceServices.getAthenaQueries({
        queryId,
        params: filterData,
      });
      return response;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getTotalReceiptIssuedCount = async () => {
    const totalCount = await getAthenaQuery(TOTAL_RECEIPT_ISSUED_COUNT);
    settotalReceiptsIssueCountState(totalCount);
  };

  useEffect(() => {
    getTotalReceiptIssuedCount();
  }, []);

  return (
    <Stack
      sx={{
        p: 3,
        bgcolor: 'white',
        borderRadius: '12px',
        border: BORDER_COLLECTION,
      }}
      gap={2}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <VegaText
          text={'Total Receipts Issued Count'}
          fontSize={17}
          fontWeight={600}
          color={COMMON.balck}
        />
      </Stack>
      <Chart options={options} series={options.series} type="bar" />
    </Stack>
  );
};

export default TotalReceiptsIssueCountGraph;
