import { GetPaginatedApiRequest } from '../api';
import { RoleName } from '../incentives';
import { BucketEnum } from '../targets';
import { VegaUserStatus } from '../user';

export interface GetPolicyRequest extends GetPaginatedApiRequest {
  status: string;
  approverId: string;
  approvalStatus: string;
  startDateBefore: string;
  startDateAfter: string;
  endDateBefore: string;
  endDateAfter: string;
  name: string;
  classTypes?: string[];
}
export interface FetchLeaderboardRequestDto extends GetPaginatedApiRequest {
  resultsType: string;
  agentType: string;
}
export interface GetPolicyRequestForRole {
  userType: string;
}

export interface BasePolicyRangeProps {
  chargePayoutMetric?: string;
  parameterDefinitionType: string;
  parameterMin: number | null;
  parameterMax: number | null;
  parameterExact: number | null;
  payoutAmountValue: number | null;
  payoutAmountMax: number | null;
  payoutAmountMin: number | null;
}

export interface BumperCondition {
  conditionType: string;
  conditionValue: number;
  conditionCutoffDayOfMonth: number;
}

export interface BumperPolicyRangeProps {
  condition: BumperCondition[];
  amountType: string;
  amountValue: number;
  amountMax: number | null;
  amountMin: number | null;
}

export interface PanaltyCondition {
  conditionType: string;
  value: number;
  op: string;
}
export interface PanaltyPolicyRangeProps {
  condition: PanaltyCondition[];
  amountType: string;
  amountValue: number;
  amountMax: number | null;
  amountMin: number | null;
}
export interface MinimumEligibilityCriteriaProps {
  parameter: string;
  minNo: number;
  maxNo: number;
  metric: string;
  computationBasis: string;
}

export interface IncentivePolicyRulesRequest {
  incentiveBasePolicyId: string;
  bucket: BucketEnum | string;
  setMinimumEligibility: boolean;
  payoutMetric: string;
  collectionType: string | null;
  minimumEligibilityParameters: Partial<MinimumEligibilityCriteriaProps> | null;
  basePayoutRange: BasePolicyRangeProps[] | null;
  chargesCollectedPayoutRange: BasePolicyRangeProps[] | null;
  bumperPayoutRange: BumperPolicyRangeProps[] | null;
  penaltyRange: PanaltyPolicyRangeProps[] | null;
}

export interface PosWaiverRange {
  parameterDefinitionType: string;
  parameterMin: number;
  parameterMax: number;
}
export interface LanPayoutRange {
  parameterDefinitionType: string;
  parameterExact?: number;
  parameterMin?: number;
  parameterMax?: number;
  payoutAmountValue: number;
}

export interface IncentiveWriteOffRule {
  posWaiverRange: PosWaiverRange;
  lanPayoutRange: LanPayoutRange[];
}

export interface IncentiveInterestDto {
  incentiveOnInterest: number;
  maxPayoutForInterestIncentive: number;
}

export interface IncentiveWriteOffRulesRequest {
  incentiveBasePolicyId: string;
  writeOffIncentiveType: string;
  incentiveInterestDto: IncentiveInterestDto;
  maxPolicyPayoutAmt: number;
  rules: IncentiveWriteOffRule[];
}

export interface PolicyDeleteRequest {
  policyId: string;
  status: string;
  reason?: string;
}

export interface GetAgentIdRequest {
  agentId: string;
}
export interface CreateTempleteRequest {
  policyId: string;
  templateName: string;
  type: string;
}

export interface PolicyDetailsRequest {
  policyName: string;
  vertical: string;
  channelType: string;
  policyType: string;
  accountType: string[];
  caseType: string[];
  splitPayoutForUsers: string[];
  splitPayoutForUsersMap: Partial<{ [key: string]: number }>;
  incentiveType: string;
  payoutFrequency: string;
  userType: string[];
  replacementPolicyIds: string[];
  modeOfCommunication: string[];
  maxPayoutPerPerson: number;
  policyStartDate: string;
  policyEndDate: string;
}

export interface ReviewPolicyDetailsRequest {
  policyId: string;
  approvalStatus: string;
  approverId: string;
  remarks: string;
}

export interface GetAgentPayoutRequest {
  agentId: string;
}

export interface FetchScorecardDetailsRequestDto {
  role: string[];
  agentId: string;
}

export interface IncentiveDetailsRequestDto {
  role: string[];
  bucket: string[];
}
export interface AgentLevelIncentiveDetailsRequestDto
  extends GetPaginatedApiRequest {
  policyId: string;
  isPreviousCycle: boolean;
}

export enum IncentivePolicyApprovalStatus {
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface FetchWirteOffPolicyListRequest extends GetPaginatedApiRequest {
  loanId: string;
  policyId: string;
  writeOffStatus: string;
  approvalStatuses: string[];
}

export interface UpdateWriteOffSplit {
  id: string;
  approvalStatus: string;
  remarks: string;
  splitData: Record<string, WriteOffSplitData>;
}
export interface WriteOffSplitData {
  roleName: string;
  splitPercentage: number;
  splitAmount: number;
}

export interface WriteOffRewardRecordsResponseDto {
  id: string;
  cycleStartDate: string;
  cycleEndDate: string;
  createdAt: string;
  updatedAt: string;
  loanId: string;
  policyId: string;
  writeOffStatus: string;
  customerName: string;
  totalLoanIncentiveAmount: number;
  finalLoanIncentiveAmount: number;
  approvalStatus: IncentivePolicyApprovalStatus;
  splitData: Record<string, WriteOffSplitData>;
  remarks: string;
  policyCapping: number;
}

export interface WriteOffListCount {
  TOTAL: number;
  IN_REVIEW: number;
  DRAFT: number;
  APPROVED: number;
  REJECTED: number;
}

export interface WriteOffAgentViewListRequest {
  policyId: string;
  agentName: string;
}

export interface WriteOffCountRequest {
  policyId: string;
}
export interface WriteOffAgentViewListResponseDto {
  id: string;
  rewardRecordId: string;
  loanId: string;
  cycleStartDate: string; // Assuming ISO date string
  cycleEndDate: string; // Assuming ISO date string
  roleName: string;
  policyId: string;
  splitUserId: string;
  splitUserName: string;
  splitUserCode: string;
  splitPercentage: number;
  splitAmount: number;
  baseAmountEarned: number;
  incentiveInterestAmount: number;
  totalLoanIncentiveAmount: number;
  approvalStatus: string;
  createdAt: string; // Assuming ISO date string
  updatedAt: string; // Assuming ISO date string
}
export interface ListRoleMaxPayoutRequestDto extends GetPaginatedApiRequest {
  roleNameList: string[];
}

export interface UpdateRoleMaxPayoutIncentiveRequest {
  role: RoleName;
  maxCapping: number;
  updatedBy: string;
}

export interface UpdateConfigStatusRequest {
  ids: string[];
  status: VegaUserStatus;
}

export enum IncentiveApprovalStatus {
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
}

export interface OverallAgentIncentiveDetailsRequestDto {
  isPreviousCycle: boolean;
  page: number;
  size: number;
  status: IncentiveApprovalStatus[];
  agentName: string;
}

export interface SubmitOverallIncentivePayoutRequest {
  status: IncentiveApprovalStatus;
  remarks?: string;
}

export interface DownloadOverallPayoutPrevCycleRequest {
  isPreviousCycle: boolean;
}
