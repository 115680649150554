import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LegalBaseResponse } from '../types/legal';
import { FetchLegalCaseDetailRequest } from '../types/request/legal';
import { LegalServices } from '../Apis/LegalService';

interface LegalRequestActionState {
  loading: boolean;
  sarfaesiMisDeatils: Partial<LegalBaseResponse>;
  secDetails: Partial<LegalBaseResponse>;
  arbitationDetails: Partial<LegalBaseResponse>;
  error: string | null;
}

export const fetchSarfaesiMisDeatils = createAsyncThunk(
  'caseDetails/sarfaesi',
  async (request: Partial<FetchLegalCaseDetailRequest>) => {
    return await LegalServices.getLegalCaseDetail(request);
  }
);

export const fetchSecDeatils = createAsyncThunk(
  'caseDetails/sec',
  async (request: Partial<FetchLegalCaseDetailRequest>) => {
    return await LegalServices.getLegalCaseDetail(request);
  }
);

export const fetchArbitationDeatils = createAsyncThunk(
  'caseDetails/arbitation',
  async (request: Partial<FetchLegalCaseDetailRequest>) => {
    return await LegalServices.getLegalCaseDetail(request);
  }
);

const initialState: LegalRequestActionState = {
  loading: false,
  sarfaesiMisDeatils: {},
  secDetails: {},
  arbitationDetails: {},
  error: null,
};

const legalRequestSlice = createSlice({
  name: 'leaveaction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSarfaesiMisDeatils.pending, state => {
        state.loading = true;
        state.sarfaesiMisDeatils = {};
      })
      .addCase(fetchSarfaesiMisDeatils.fulfilled, (state, action) => {
        state.loading = false;
        state.sarfaesiMisDeatils = action.payload;
      })
      .addCase(fetchSarfaesiMisDeatils.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchSecDeatils.pending, state => {
        state.loading = true;
        state.secDetails = {};
      })
      .addCase(fetchSecDeatils.fulfilled, (state, action) => {
        state.loading = false;
        state.secDetails = action.payload;
      })
      .addCase(fetchSecDeatils.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchArbitationDeatils.pending, state => {
        state.loading = true;
        state.arbitationDetails = {};
      })
      .addCase(fetchArbitationDeatils.fulfilled, (state, action) => {
        state.loading = false;
        state.arbitationDetails = action.payload;
      })
      .addCase(fetchArbitationDeatils.rejected, state => {
        state.loading = false;
      });
  },
});
export default legalRequestSlice;

export const legalRequestSelector = (state: RootState) =>
  state.legalRequestAction;
