import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AllocationTypes } from '../../types/allocations';
import { DateUtility } from '../../utils/DateUtlility';
import AgentSelector from '../AgentSelector';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import { VegaFileSelectOption } from './BulkAllocationFileSelect';

type Props = {
  open: boolean;
  onClose: () => void;
  onAllocateClick: (formData: Partial<ExecuteAllocationFormData>) => void;
  loading: boolean;
};

export type ExecuteAllocationFormData = {
  agentId: string;
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}

function ExecuteAllocationRuleDrawer({
  onAllocateClick,
  open,
  onClose,
  loading,
}: Props) {
  const [formData, setFormData] = useState<Partial<ExecuteAllocationFormData>>({
    expiryDate: getNextDay().toISOString(),
    agentId: '',
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function onSumbitClick() {
    onAllocateClick(formData);
    setFormData({
      expiryDate: '',
    });
  }

  function onHandleClose() {
    onClose();
    setFormData({
      agentId: '',
    });
  }

  function onAllocationTypeChange(value) {
    updateFormData(InputType.AllocationType, value);
  }

  function updateExpiryDate(value) {
    updateFormData(InputType.ExpiryDate, value);
  }

  useEffect(() => {
    const isAllocationTypeValid = !!formData.allocationType;
    const isExpiryDateValid = !!formData.expiryDate;
    setIsSubmitDisabled(!(isAllocationTypeValid && isExpiryDateValid));
  }, [formData]);

  return (
    <VegaDrawer open={open} title={'Execute Rule'} onClose={onHandleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Submit"
              onClick={onSumbitClick}
              disabled={isSubmitDisabled}
              loading={loading}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent'}>
              <AgentSelector
                selected={formData.agentId}
                onSelected={selected =>
                  updateFormData(InputType.AgentId, selected)
                }
                onClear={() => {
                  updateFormData(InputType.AgentId, '');
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              minDate={getNextDay()}
              header="Expiry Date"
              value={formData.expiryDate}
              onChange={e => {
                updateExpiryDate(DateUtility.parseDateFromDatePicker(e));
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ExecuteAllocationRuleDrawer;

const allocationTypeOptions = () =>
  Object.keys(AllocationTypes).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

function getNextDay() {
  const today = new Date();
  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);
  return nextDay;
}
