import { AddTask, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { toLowerCase } from '../../../constants/commonFunction';
import { GeoVarificationActionEnum } from '../../../pages/GeoVarification/GeoVarification';
import { useClientAuth } from '../../../providers/ClientProvider';
import { GeoVerificationResponse } from '../../../types/geoVerificationType';
import { COLOR } from '../../../utils/ColorUtility';
import VegaIconButton from '../../common/VegaIconButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';

interface IColumnProps {
  selectedTab: number;

  onAction: (
    geo: GeoVerificationResponse,
    action: GeoVarificationActionEnum
  ) => void;
}

export const GeoVerificationColumn = ({
  selectedTab,
  onAction,
}: IColumnProps) => {
  const { userForId } = useClientAuth();
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const COL_DEF: GridColumns = [
    {
      field: 'customerAddress',
      headerName: 'Communication Address',
      flex: 1,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const address = geo.customerAddress
          ? toLowerCase(geo.customerAddress)
          : '--';
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle={address}
              icon={
                <VegaText
                  color={'#1B1D22'}
                  fontSize={14}
                  fontWeight={500}
                  text={address}
                />
              }
            />
          </Stack>
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 0.7,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const customerName = geo.customerName
          ? toLowerCase(geo.customerName)
          : '--';
        return (
          <Stack direction={'row'} gap={1}>
            <VegaText
              color={'#1B1D22'}
              fontSize={14}
              fontWeight={500}
              text={customerName}
            />
          </Stack>
        );
      },
    },
    {
      field: 'agentName',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 200,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const customerName = getUserName(geo.agentId);
        return (
          <Stack direction={'row'} gap={1}>
            <VegaText
              color={'#1B1D22'}
              fontSize={14}
              fontWeight={500}
              text={customerName}
            />
          </Stack>
        );
      },
    },
    {
      field: 'LAN',
      headerName: 'LAN',
      flex: 0.7,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const customerName = geo.loanId ?? '--';
        return (
          <Stack direction={'row'} gap={1}>
            <VegaText
              color={'#1B1D22'}
              fontSize={14}
              fontWeight={500}
              text={customerName}
            />
          </Stack>
        );
      },
    },
    {
      field: 'agentRemarks',
      headerName: 'Reason',
      flex: 0.7,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const reason = geo.agentRemarks ?? '--';
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              sx={{
                width: '100%',
                textAlign: 'start',
              }}
              tooltipTitle={reason}
              icon={
                <VegaText
                  color={'#1B1D22'}
                  fontSize={14}
                  fontWeight={500}
                  text={reason}
                />
              }
            />
          </Stack>
        );
      },
    },
    {
      field: 'loaction',
      headerName: 'Location',
      flex: 0.7,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const locationDto = { ...geo?.geoLocationDto };
        const latitude = locationDto
          ? locationDto.latitude
            ? locationDto.latitude
            : '--'
          : '--';
        const longitude = locationDto
          ? locationDto.longitude
            ? locationDto.longitude
            : '--'
          : '--';
        return (
          <Stack gap={0.5}>
            <VegaText
              color={'#1B1D22'}
              fontSize={14}
              fontWeight={500}
              text={`Latitude : ${latitude}`}
            />
            <VegaText
              color={'#1B1D22'}
              fontSize={14}
              fontWeight={500}
              text={`Longitude : ${longitude}`}
            />
          </Stack>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      hide: selectedTab === 0,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        const status = geo?.status;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaPill
              text={status}
              textColor={getColorForStatus(status).DARK}
              backgroundColor={getColorForStatus(status).LIGHT}
            />
          </Stack>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      minWidth: 150,
      hide: selectedTab === 1,
      renderCell: props => {
        const geo = props.row as GeoVerificationResponse;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="Approve"
              sx={{ bgcolor: '#E7EDFC' }}
              icon={<AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />}
              onClick={() => {
                onAction(geo, GeoVarificationActionEnum.APPROVED);
              }}
            />
            <VegaIconButton
              tooltipTitle="Reject"
              onClick={() => {
                onAction(geo, GeoVarificationActionEnum.REJECTED);
              }}
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case GeoVarificationActionEnum.APPROVED:
      return COLOR.GREEN;

    case GeoVarificationActionEnum.REJECTED:
      return COLOR.RED;
    case GeoVarificationActionEnum.RAISED:
      return COLOR.ORANGE;
  }
};
