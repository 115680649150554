import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OtsService } from '../../../../Apis/OtsService';
import LmDialog from '../../../../components/common/LmDialog';
import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import VegaText from '../../../../components/common/VegaText';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { ROUTES } from '../../../../router/routes';
import { getOtsStatusDescription, Ots } from '../../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { DateUtility } from '../../../../utils/DateUtlility';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../../constants/style';

type Props = {
  open: boolean;
  onClose: () => void;
  ots?: Ots;
};

function OtsHistoryModal({ open, onClose, ots }: Props) {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [otsHistory, setOtsHistory] = useState<Ots[]>([]);

  async function fetchHistory() {
    try {
      const loanId = ots?.loanId;
      if (!loanId) return;
      setLoading(true);
      const response = await OtsService.listOtsRequests({
        loanId: loanId,
      });
      const records = (response.records ?? []).filter(i => i.id != ots?.id);
      setOtsHistory(records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    onClose();
  }

  useEffect(() => {
    if (open == true && ots) {
      fetchHistory();
    }
  }, [ots, open]);

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => {
        handleClose();
      }}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText text="History" fontWeight={600} fontSize={'1.0625rem'} />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" gap={'1rem'}>
        <VegaDataGrid
          data={otsHistory}
          loading={loading}
          columns={getColumnDefinition({
            onViewDetailsClick: ots => {
              navigate(ROUTES.OTS_DETAILS.replace(':otsId', ots.id));
            },
          })}
          idColumn="id"
          rowCount={otsHistory.length}
          pageSize={10}
        />
      </Stack>
    </LmDialog>
  );
}

export default OtsHistoryModal;

const getColumnDefinition = (data: {
  onViewDetailsClick: (ots: Ots) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Ots Request Date',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const parsedDate = DateUtility.parseStringToDate(ots.createdAt);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsStatus',
      headerName: 'Ots Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = getOtsStatusDescription(ots?.status);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const ots = getOtsForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(ots);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(ots);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getOtsForRow = (props: any) => props.row as Ots;
