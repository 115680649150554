import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoanService } from '../../../Apis/LoanServices';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../store';
import { LoanData } from '../../../types/loan';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import VegaText from '../../common/VegaText';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import VegaButton from '../../common/VegaButton';
import LmDialog from '../../common/LmDialog';
import VegaDialogContent from '../../common/VegaDialogContent';
import ListOfPaymentHistory from './ListOfPaymentHistory';
import { StringUtility } from '../../../utils/StringUtility';

const LinkedAccountDetails = () => {
  const { loanData } = useAppSelector(getTeleAgent);

  const { setSnackbar } = useSnackbar();
  const [loans, setLoans] = useState<LoanData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loanId, setLoanId] = useState<string>('');
  const [openPaymentHistoryDrawer, setOpenPaymentHistoryDrawer] =
    useState<boolean>(false);

  const getLmsVariable = () => {
    if (loans.length && loans[0].lmsVariables) {
      const lmsVariables = loans[0]?.lmsVariables;

      return {
        bucket: lmsVariables?.bucket ? lmsVariables?.bucket : 'NA',
        pos: lmsVariables?.outstandingAmount
          ? StringUtility.formatToINR(lmsVariables?.outstandingAmount)
          : 'NA',
        emi: lmsVariables?.installment
          ? StringUtility.formatToINR(lmsVariables?.installment)
          : 'NA',
      };
    }
    return {
      bucket: 'NA',
      pos: 'NA',
      emi: 'NA',
    };
  };

  const paymentHistory = (loan: LoanData) => {
    setOpenPaymentHistoryDrawer(true);
    setLoanId(loan.loanId);
  };

  const getLinkAccount = async (customerNo: number) => {
    setLoading(true);
    try {
      const customerId =
        typeof customerNo === 'number' ? customerNo.toString() : customerNo;
      let response = await LoanService.getLinkedAccountByCustomerNo(customerId);

      response = response.filter(loan => loan?.loanId !== loanData[0]?.loanId);
      setLoans(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanData.length && loanData[0].customerDetails?.length) {
      const customerData = loanData[0].customerDetails[0];
      getLinkAccount(customerData.customerNo);
    }
  }, [loanData]);

  return (
    <>
      <TeleAgentDetailsCard text="Linked Account Details" isBorder>
        <Stack gap={2}>
          {loans.length ? (
            loans.map((loan: LoanData, index) => (
              <Stack
                key={index}
                sx={{
                  borderRadius: '12px',
                  p: 2,
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <VegaText
                      text={`Linked Account ${index + 1}`}
                      fontWeight={600}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TeleAgentTextWrapper
                      text={'LAN'}
                      value={loan.loanId}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TeleAgentTextWrapper
                      text={'EMI'}
                      value={getLmsVariable().emi}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TeleAgentTextWrapper
                      text={'Bucket'}
                      value={getLmsVariable().bucket}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TeleAgentTextWrapper
                      text={'POS'}
                      value={getLmsVariable().pos}
                      loading={loading}
                    />
                  </Grid>
                </Grid>
                <Box textAlign={'end'}>
                  <VegaButton
                    variant="outlined"
                    text="Payment History"
                    onClick={() => paymentHistory(loan)}
                  />
                </Box>
              </Stack>
            ))
          ) : (
            <VegaText text={`No Linked Account `} fontWeight={600} />
          )}
        </Stack>
      </TeleAgentDetailsCard>
      <LmDialog
        fullWidth
        maxWidth="sm"
        open={openPaymentHistoryDrawer}
        handleClose={() => {
          setLoanId('');
          setOpenPaymentHistoryDrawer(false);
        }}
        isCloseIcon
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Payment History"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <ListOfPaymentHistory loanAccountId={loanId} />
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default LinkedAccountDetails;
