import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GeoVerificationService } from '../Apis/GeoVerificationService';
import { RootState } from '../store';
import { GetGeoVerifiedListRequest } from '../types/request/geoVerificationRequest';
import { PaginatedResponse } from '../utils/api';
import { GeoVerificationResponse } from '../types/geoVerificationType';

export interface GeoVerificationSliceProps {
  loading: boolean;
  totalItems: number;
  error: string | null;
  geoVerifications: any[];
  geoVerifiedData: Partial<GeoVerificationResponse>;
}

const initialState: GeoVerificationSliceProps = {
  loading: false,
  error: null,
  totalItems: 0,
  geoVerifications: [],
  geoVerifiedData: {},
};

export const getGeoVerifiedList = createAsyncThunk(
  'geoVerification/getGeoVerifiedList',
  async (
    request: Partial<GetGeoVerifiedListRequest>
  ): Promise<PaginatedResponse<GeoVerificationResponse>> => {
    const response = await GeoVerificationService.getGeoVerifiedList(request);
    return response;
  }
);

const geoVerificationSlice = createSlice({
  name: 'geoVerification',
  initialState,
  reducers: {
    setGeoVerifiedData: (state: GeoVerificationSliceProps, action) => {
      const { payload } = action;
      state.geoVerifiedData = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getGeoVerifiedList.pending, state => {
        state.loading = true;
        state.error = null;
        state.geoVerifications = [];
      })
      .addCase(getGeoVerifiedList.fulfilled, (state, action) => {
        state.loading = false;
        state.geoVerifications = action.payload.records;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getGeoVerifiedList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch Geo';
      });
  },
});

export default geoVerificationSlice;
export const { setGeoVerifiedData } = geoVerificationSlice.actions;
export const getGeoVerificationState = (state: RootState) =>
  state.geoVerification;
