/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { Grid, IconButton, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AllocationService } from '../../Apis/AllocationService';
import {
  fetchAllocationRules,
  fetchCriteriaAttributes,
} from '../../actions/AllocationActions';
import { toLowerCase } from '../../constants/commonFunction';
import { getAllocations } from '../../features/allocationSlice';
import { UserActions } from '../../features/userSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  AttributeDataType,
  AttributeOperationType,
  Criteria,
  RuleCriteriaAttribute,
} from '../../types/allocations';
import { FilteredRoleDto } from '../../types/claim';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import AgentSelector from '../AgentSelector';
import { CrossIcon } from '../Icons/Icons';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import VegaSelectWithCheckbox from '../common/VegaSelectWithCheckbox';
import RegionSelector from '../../pages/MinVisits/modules/RegionSelector';
import AllBranchSelector from '../../pages/MinVisits/modules/AllBranchSelector';

type Props = {
  open: boolean;
  onClose: () => void;
};

export type CreateRuleFormData = {
  name: string;
  agentId: string;
  expiry: string;
  criterias: Partial<Criteria>[];
};

enum FormField {
  Name = 'name',
  Agent = 'agentId',
  Expiry = 'expiry',
}

const TODAY = new Date();
function CreateRuleDrawer({ open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { criteriaAttributes, loadingCriteriaAttributes, filteredRoles } =
    useAppSelector(getAllocations);
  const [loading, setLoading] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<Partial<Criteria>[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [formData, setFormData] = useState<Partial<CreateRuleFormData>>({
    expiry: TODAY.toISOString(),
  });
  function handleClose() {
    onClose();
    cleanUp();
  }

  function cleanUp() {
    setFormData({});
    setAttributes([]);
  }
  const isInputValid = () => {
    const isNameValid = (formData.name ?? '').length > 0;
    const isAgentValid = (formData.agentId ?? '').length > 0;
    const isExpirySelected = (formData.expiry ?? '').length > 0;
    const isExpiryValid =
      DateUtility.parseStringToDate(formData.expiry) != null;
    const hasRules = attributes.length > 0;
    return (
      isNameValid &&
      isAgentValid &&
      hasRules &&
      isExpiryValid &&
      isAgentValid &&
      isExpirySelected
    );
  };

  async function onCreateClick() {
    setLoading(true);
    const existingFormData = { ...formData };
    const modifyAttributes = attributes.map(attribute => {
      if (attribute.dataType === AttributeDataType.LOCAL_DATE) {
        return {
          ...attribute,
          value: DateUtility.formatStringToYYYYMMDD(attribute.value as string),
        };
      } else {
        return attribute;
      }
    });
    existingFormData.criterias = modifyAttributes;
    try {
      await AllocationService.createRule({
        criteriaList: existingFormData.criterias,
        name: existingFormData.name,
        agentId: existingFormData.agentId,
        expiresAt: existingFormData.expiry,
      });
      dispatch(fetchAllocationRules({ page: 0 }));
      setSnackbar('Create rule successfully !!');
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
    handleClose();
  }

  function onAddAttribute(value: string) {
    const attribute = getCriteriaAttributeOfType(value, criteriaAttributes);

    const criteria: Partial<Criteria> = {
      attributeName: attribute.name,
      dataType: attribute.dataType,
      entity: attribute.entity,
      possibleValues: attribute.possibleValues,
    };
    setAttributes(prev => {
      if (attribute.dataType !== AttributeDataType.NUMBER) {
        const isNumberAttribute = prev.some((value: Criteria) => {
          return value.dataType === attribute.dataType;
        });
        if (isNumberAttribute) {
          const filteredAttributes = prev.filter(
            (value: Criteria) => value.attributeName !== attribute.name
          );
          return [...filteredAttributes, criteria];
        } else {
          return [...prev, criteria];
        }
      } else {
        return [...prev, criteria];
      }
    });
  }

  function updateFormData(field: FormField, value: string) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function updateExpiryDate(e: any) {
    const date = DateUtility.parseDateFromDatePicker(e);
    if (date != null) {
      updateFormData(FormField.Expiry, date.toISOString());
    }
  }

  function onValueUpdate(criteria: Partial<Criteria>, value: any, index) {
    if (criteria.dataType === AttributeDataType.LOCAL_DATE) {
      const parsedDate = DateUtility.parseDateFromDatePicker(value);
      const filteredAttributes = [...attributes];
      const attributeObj = filteredAttributes[index];
      const updatedAttributes = {
        ...attributeObj,
        value: parsedDate?.toISOString(),
      } as Criteria;
      filteredAttributes.splice(index, 1, updatedAttributes);
      setAttributes(filteredAttributes);
    } else {
      const filteredAttributes = [...attributes];
      const attributeObj = filteredAttributes[index];
      const updatedAttributes = {
        ...attributeObj,
        value,
      } as Criteria;
      filteredAttributes.splice(index, 1, updatedAttributes);
      setAttributes(filteredAttributes);
    }
  }

  const handleChangeListEnum = (
    criteria: Partial<Criteria>,
    value: any,
    index
  ) => {
    const filteredAttributes = [...attributes];
    const criteriaValue = criteria.value as string[];
    const preCriteriaList = criteriaValue?.length ? [...criteriaValue] : [];

    if (preCriteriaList.includes(value)) {
      const filteredRegion = preCriteriaList.filter(
        (selectedRegion: string) => selectedRegion !== value
      );

      const updatedAttributes = {
        ...criteria,
        value: filteredRegion,
      } as Criteria;

      filteredAttributes.splice(index, 1, updatedAttributes);
      setAttributes(filteredAttributes);
    } else {
      const updatedAttributes = {
        ...criteria,
        value: [...preCriteriaList, value],
      } as Criteria;

      filteredAttributes.splice(index, 1, updatedAttributes);
      setAttributes(filteredAttributes);
    }
  };

  function onOperationUpdate(
    criteria: Partial<Criteria>,
    value: string,
    index
  ) {
    // const { attributeName } = criteria;
    // updateCriteria(attributeName, criteriaToUpdate => {
    //   criteriaToUpdate.op = value;
    // });
    const filteredAttributes = [...attributes];
    const attributeObj = filteredAttributes[index];
    const updatedAttributes = {
      ...attributeObj,
      op: value,
    } as Criteria;
    filteredAttributes.splice(index, 1, updatedAttributes);
    setAttributes(filteredAttributes);
  }

  function deleteCriteria(criteria: Partial<Criteria>, index) {
    setAttributes(prevAttributes =>
      prevAttributes.filter((_, i) => i !== index)
    );
  }

  const onDelete = (criteria: Partial<Criteria>, selectedIndex, index) => {
    const filteredAttributes = [...attributes];
    const criteriaValue = [...(criteria.value as string[])];
    criteriaValue.splice(selectedIndex, 1);
    const updatedAttributes = {
      ...criteria,
      value: criteriaValue,
    } as Criteria;
    filteredAttributes.splice(index, 1, updatedAttributes);
    setAttributes(filteredAttributes);
  };
  function cleanup() {
    setAttributes([]);
    setFormData({});
  }

  const filterValue = (list: VegaSelectOption[]) => {
    if (searchValue.length) {
      const searchLowerCase = searchValue.toLowerCase();
      const filteredResults = list.filter(listItem => {
        if (searchValue === '') {
          return listItem;
        } else if (listItem.label.toLowerCase().includes(searchLowerCase)) {
          return listItem;
        }
      });
      return filteredResults;
    } else {
      return list;
    }
  };

  const getAdminSelector = (
    item: Partial<Criteria>,
    index: number,
    isAdmin: boolean
  ) => {
    if (
      !isAdmin &&
      (item?.attributeName === 'brnRegion' ||
        item?.attributeName === 'branchCode')
    ) {
      return (
        <VegaSelectWithCheckbox
          options={filterValue(getRegionBranch(filteredRoles, item))}
          onSelect={selected => handleChangeListEnum(item, selected, index)}
          handleDelete={selectedIndex => onDelete(item, selectedIndex, index)}
          selected={item?.value as string[]}
          handleChange={e => setSearchValue(e.target.value)}
          searchValue={searchValue}
        />
      );
    } else if (isAdmin && item?.attributeName === 'brnRegion') {
      return (
        <RegionSelector
          isMultiSelect
          selected={(item?.value as string[]) ?? []}
          handleChange={selected => handleChangeListEnum(item, selected, index)}
          handleDelete={selectedIndex => onDelete(item, selectedIndex, index)}
        />
      );
    } else if (isAdmin && item?.attributeName === 'branchCode') {
      return (
        <AllBranchSelector
          isMultiSelect
          selected={(item?.value as string[]) ?? []}
          handleChange={selected => handleChangeListEnum(item, selected, index)}
          handleDelete={selectedIndex => onDelete(item, selectedIndex, index)}
        />
      );
    } else {
      return (
        <VegaSelectWithCheckbox
          options={filterValue(
            getOptionsForPossibleValues(item?.possibleValues ?? [])
          )}
          onSelect={selected => handleChangeListEnum(item, selected, index)}
          handleDelete={selectedIndex => onDelete(item, selectedIndex, index)}
          selected={item?.value as string[]}
          handleChange={e => setSearchValue(e.target.value)}
          searchValue={searchValue}
        />
      );
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchCriteriaAttributes());
      dispatch(UserActions.fetchAgents());
    }
  }, [open]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <VegaDrawer open={open} title={'Add Rule'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Create Rule'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
              loading={loading}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Rule Name'}>
              <LmTextField
                value={formData.name}
                onChange={e => updateFormData(FormField.Name, e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent/Agency'}>
              <AgentSelector
                selected={formData.agentId ?? ''}
                onSelected={selected =>
                  updateFormData(FormField.Agent, selected)
                }
                onClear={() => {
                  updateFormData(FormField.Agent, '');
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Expiry'}>
              <VegaDatePicker
                onChange={updateExpiryDate}
                value={formData.expiry}
                minDate={new Date()}
              />
            </VegaFormInputField>
          </Grid>
          {attributes.map((item, index) => {
            const options = item.possibleValues ?? [];
            const hasOptions = options.length > 0;
            const isLocalDateEnum =
              item.dataType === AttributeDataType.LOCAL_DATE;
            const isListEnum = item.dataType === AttributeDataType.LIST;
            const isAdmin = filteredRoles.some(
              (role: FilteredRoleDto) => role.isAdmin === true
            );
            return (
              <Grid container key={index}>
                <Grid item xs={12}>
                  <VegaFormInputField label={_.startCase(item.attributeName)}>
                    <Grid container alignItems={'center'} columnGap="0.5rem">
                      <Grid item xs={7}>
                        {hasOptions &&
                          (isListEnum ? (
                            <>{getAdminSelector(item, index, isAdmin)}</>
                          ) : (
                            <VegaSelect
                              fullWidth
                              value={item.value}
                              options={getOptionsForPossibleValues(options)}
                              onChange={e =>
                                onValueUpdate(item, e.target.value, index)
                              }
                            />
                          ))}
                        {hasOptions == false && !isLocalDateEnum && (
                          <LmTextField
                            value={item.value}
                            onChange={e =>
                              onValueUpdate(item, e.target.value, index)
                            }
                          />
                        )}
                        {isLocalDateEnum && (
                          <VegaDatePicker
                            value={item.value}
                            onChange={e => onValueUpdate(item, e, index)}
                          />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Stack>
                          <VegaSelect
                            fullWidth
                            ignoreLodash
                            options={getAttributeOperationTypes(item)}
                            value={item.op ?? ''}
                            onChange={e =>
                              onOperationUpdate(
                                item,
                                e.target.value as string,
                                index
                              )
                            }
                          />
                        </Stack>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ backgroundColor: COLOR.RED.LIGHT }}
                          onClick={() => deleteCriteria(item, index)}
                        >
                          <CrossIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </VegaFormInputField>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <VegaFormInputField label={'Filter'}>
              <VegaSelect
                onlyShowPlaceholder={true}
                placeholder="Select Filter"
                options={getCriteriaOptions(criteriaAttributes)}
                loading={loadingCriteriaAttributes}
                value={null}
                onChange={e => {
                  onAddAttribute(e.target.value as string);
                  setSearchValue('');
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default CreateRuleDrawer;

const getCriteriaOptions = (attributes: RuleCriteriaAttribute[]) => {
  const options = attributes.map(item => {
    const option: VegaSelectOption = {
      value: item.name,
      label: _.startCase(item.name),
    };
    return option;
  });

  return options;
};
const getRegionBranch = (
  roles: FilteredRoleDto[],
  criteria: Partial<Criteria>
) => {
  if (criteria.attributeName === 'brnRegion') {
    const options = roles.map(
      (role: FilteredRoleDto) =>
        ({
          label: toLowerCase(role.region),
          value: role.region,
        } as VegaSelectOption)
    );
    return options;
  } else {
    const options = roles.map(
      (role: FilteredRoleDto) =>
        ({
          label: toLowerCase(role.branch),
          value: role.branch,
        } as VegaSelectOption)
    );
    return options;
  }
};
// const getCriteriaOptions = (
//   attributes: RuleCriteriaAttribute[],
//   criterias: Partial<Criteria>[]
// ) => {
//   const existingAttributes = criterias.map(item => item.attributeName);
//   const options = attributes
//     .map(item => {
//       if (existingAttributes.includes(item.name) == false) {
//         const option: VegaSelectOption = {
//           value: item.name,
//           label: _.startCase(item.name),
//         };
//         return option;
//       }
//       return undefined;
//     })
//     .filter(option => option !== undefined);

//   return options;
// };

const getCriteriaAttributeOfType = (
  type: string,
  from: RuleCriteriaAttribute[]
) => {
  return from.find(item => item.name === type);
};

const getAttributeOptions = (attribute: Partial<Criteria>) => {
  if (attribute.dataType == AttributeDataType.NUMBER) {
    return [
      AttributeOperationType.GreaterThen,
      AttributeOperationType.GreaterThenOrEqual,
      AttributeOperationType.Equal,
      AttributeOperationType.LessThen,
      AttributeOperationType.LessThenOrEqual,
    ];
  } else if (attribute.dataType == AttributeDataType.STRING) {
    return [AttributeOperationType.Equal, AttributeOperationType.Like];
  } else if (attribute.dataType == AttributeDataType.ENUM) {
    return [AttributeOperationType.Equal];
  } else if (attribute.dataType == AttributeDataType.LOCAL_DATE) {
    return [AttributeOperationType.Equal];
  } else if (attribute.dataType == AttributeDataType.LIST) {
    return [AttributeOperationType.In];
  }
  return [];
};

const getOptionsForPossibleValues = (value: string[]) => {
  const options: VegaSelectOption[] = value.map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: item,
    };
    return option;
  });
  return options;
};

const getAttributeOperationTypes = (attribute: Partial<Criteria>) => {
  const availableOptions = getAttributeOptions(attribute);
  const options: VegaSelectOption[] = availableOptions.map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: getAliasForOperation(item),
    };
    return option;
  });
  return options;
};

const getAliasForOperation = (type: AttributeOperationType) => {
  switch (type) {
    case AttributeOperationType.Equal:
      return 'Equal (==)';
    case AttributeOperationType.Like:
      return 'Like';
    case AttributeOperationType.GreaterThen:
      return 'GT (>)';
    case AttributeOperationType.GreaterThenOrEqual:
      return 'GTE (>=)';
    case AttributeOperationType.LessThen:
      return 'LT (<)';
    case AttributeOperationType.LessThenOrEqual:
      return 'LTE (<=)';
    case AttributeOperationType.In:
      return 'In';
  }
};
