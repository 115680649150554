import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IncentiveServices } from '../../Apis/IncentivesService';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { PRIMARY } from '../../constants/style';
import { DEFAULT_POLICY_DETAILS } from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { PenaltyIncentiveAttributeOperationType } from '../../types/allocations';
import { ConditionType, IncentivePolicyProps } from '../../types/incentives';
import {
  BumperCondition,
  PanaltyCondition,
} from '../../types/request/incentives';
import { BucketEnum } from '../../types/targets';
import { getOrdinalNumber } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LoadingPage from '../LoadingPage/LoadingPage';
import VegaErrorIcon from '../../components/common/VegaErrorIcon';
import { StringUtility } from '../../utils/StringUtility';

const BucketViewPolicy_new = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [policyDetailsState, setPolicyDetailsState] = useState<
    Partial<IncentivePolicyProps>
  >({});

  const getPolicyById = (id: string) => {
    IncentiveServices.getPolicyByPolicyId(id)
      .then(res => {
        const policyDetails: Partial<IncentivePolicyProps> = {
          policyName: res.policyName,
          vertical: res.vertical,
          channelType: res.channelType,
          incentiveType: res.incentiveType,
          policyStartDate: res.policyStartDate,
          policyEndDate: res.policyEndDate,
          accountType: res.accountType,
          policyType: res.policyType,
          caseType: res.caseType,
          classType: res.classType,
          payoutFrequency: res.payoutFrequency,
          maxPayoutPerPerson: res.maxPayoutPerPerson,
          splitPayoutForUsersMap: res.splitPayoutForUsersMap,
          userType: res.userType,
          modeOfCommunication: res.modeOfCommunication,
          bucketsHavingRules: res.bucketsHavingRules.length
            ? res.bucketsHavingRules
            : [],
          rules: res.rules,
          writeOffRules: res.writeOffRules,
          chargeCollectedRules: res?.chargeCollectedRules,
          npaType: res.npaType,
        };

        setPolicyDetailsState(policyDetails);

        setLoading(false);
      })
      .catch(error => {
        setPolicyDetailsState(DEFAULT_POLICY_DETAILS);
        setLoading(false);
        setIsInvalidToken(true);
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setMessage(getErrorMessageFromErrorObj(error));
      });
  };

  useEffect(() => {
    if (idFromURL) {
      setLoading(true);
      getPolicyById(idFromURL);
    }
  }, []);

  const getCriteria = (conditions: BumperCondition[]) => {
    const uniqueConditions = conditions.reduce((acc, curr) => {
      const found = acc.some(item => item.conditionType === curr.conditionType);
      if (!found) {
        acc.push(curr);
      }
      return acc;
    }, []);

    return uniqueConditions;
  };
  const getPenaltyCriteria = (conditions: PanaltyCondition[]) => {
    const uniqueConditions = conditions.reduce((acc, curr) => {
      const found = acc.some(item => item.conditionType === curr.conditionType);
      if (!found) {
        acc.push(curr);
      }
      return acc;
    }, []);

    return uniqueConditions;
  };

  console.log(policyDetailsState.splitPayoutForUsersMap);

  return (
    <>
      {loading ? (
        <LoadingPage height={500} />
      ) : (
        <VegaPageContent>
          {isInvalidToken ? (
            <Box
              sx={{
                maxWidth: 400,
                margin: 'auto',
                p: 2,
                height: '100vh',
              }}
            >
              <Stack gap={2}>
                <VegaErrorIcon />
                <VegaText
                  text={message}
                  fontSize={20}
                  fontColor="#F1342F"
                  fontWeight={600}
                />
              </Stack>
            </Box>
          ) : (
            <Stack
              sx={{
                bgcolor: 'white',
                maxWidth: '1000px',
                padding: '15px',
                margin: '0 auto',
                border: '1px solid rgba(100, 100, 111, 0.2)',
                borderRadius: '10px',
              }}
            >
              <Stack
                gap={1}
                sx={{
                  overflowX: 'auto',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                }}
                className="customTable"
              >
                {policyDetailsState?.rules &&
                Object.keys(policyDetailsState?.rules)?.length ? (
                  <Table size="small" border={1}>
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: '#57257D',
                        }}
                      >
                        <TableCell sx={{ color: 'white' }} align="center">
                          Bucket
                        </TableCell>
                        <TableCell sx={{ color: 'white' }} align="center">
                          {policyDetailsState?.classType === 'NPA'
                            ? 'No. Of NPA Cases Normalised'
                            : 'Resolution Range'}
                        </TableCell>
                        <TableCell sx={{ color: 'white' }} align="center">
                          Amount Per Case
                        </TableCell>
                        {policyDetailsState?.classType === 'NPA' ? (
                          <>
                            <TableCell sx={{ color: 'white' }} align="center">
                              Min Payout
                            </TableCell>
                            <TableCell sx={{ color: 'white' }} align="center">
                              Max Payout
                            </TableCell>
                          </>
                        ) : (
                          <TableCell sx={{ color: 'white' }} align="center">
                            Bumper / Penalty
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(policyDetailsState?.rules).map(
                        (bucket: string) => {
                          const rule = policyDetailsState?.rules[bucket];
                          return (
                            <TableRow key={bucket}>
                              <TableCell
                                component="td"
                                sx={{
                                  fontSize: 10,
                                }}
                              >
                                {policyDetailsState?.classType === 'NPA'
                                  ? toLowerCase(
                                      policyDetailsState?.npaType ?? '--'
                                    )
                                  : getBucketLabel(bucket)}
                              </TableCell>
                              <TableCell component="td" padding="none">
                                <Table size="small">
                                  {rule.basePayoutRange.map((value, index) => {
                                    return (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        {value.parameterDefinitionType ===
                                        'FIXED' ? (
                                          <TableCell
                                            sx={{
                                              fontSize: 10,
                                            }}
                                            component="td"
                                          >
                                            {value.parameterExact}
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            sx={{
                                              fontSize: 10,
                                            }}
                                            component="td"
                                          >
                                            <Stack direction={'row'} gap={1}>
                                              <VegaText
                                                fontSize={10}
                                                text={` ${value.parameterMin} ${
                                                  policyDetailsState?.classType !==
                                                  'NPA'
                                                    ? '%'
                                                    : ''
                                                } `}
                                              />
                                              <VegaText
                                                fontSize={10}
                                                text={`-`}
                                              />
                                              <VegaText
                                                fontSize={10}
                                                text={`   ${
                                                  value.parameterMax
                                                } ${
                                                  policyDetailsState?.classType !==
                                                  'NPA'
                                                    ? '%'
                                                    : ''
                                                } `}
                                              />
                                            </Stack>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                  })}
                                </Table>
                              </TableCell>
                              <TableCell component="td" padding="none">
                                <Table size="small">
                                  {rule.basePayoutRange.map((value, index) => {
                                    return (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            fontSize: 10,
                                          }}
                                          component="td"
                                        >
                                          {`${StringUtility.formatToINR(
                                            value.payoutAmountValue ?? 0
                                          )}`}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </Table>
                              </TableCell>
                              {policyDetailsState?.classType === 'NPA' ? (
                                <>
                                  <TableCell component="td" padding="none">
                                    <Table size="small">
                                      {rule.basePayoutRange.map(
                                        (base, index) => {
                                          return (
                                            <TableRow
                                              key={index}
                                              sx={{
                                                '&:last-child td, &:last-child th':
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                sx={{
                                                  fontSize: 10,
                                                }}
                                                component="td"
                                              >
                                                {base?.payoutAmountMin ?? '--'}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}
                                    </Table>
                                  </TableCell>
                                  <TableCell component="td" padding="none">
                                    <Table size="small">
                                      {rule.basePayoutRange.map(
                                        (base, index) => {
                                          return (
                                            <TableRow
                                              key={index}
                                              sx={{
                                                '&:last-child td, &:last-child th':
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                sx={{
                                                  fontSize: 10,
                                                }}
                                                component="td"
                                              >
                                                {base?.payoutAmountMax ?? '--'}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}
                                    </Table>
                                  </TableCell>
                                </>
                              ) : (
                                <TableCell padding="none" component="td">
                                  {rule?.bumperPayoutRange && (
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            bgcolor: '#57257D',
                                          }}
                                        >
                                          <TableCell
                                            align="center"
                                            colSpan={12}
                                            sx={{ color: 'white' }}
                                          >
                                            Bumper Incentive
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            bgcolor: '#57257D',
                                          }}
                                        >
                                          <TableCell
                                            className="font-aspekta-700"
                                            sx={{
                                              fontSize: '10px',
                                              color: 'white',
                                            }}
                                          >
                                            Criteria
                                          </TableCell>
                                          <TableCell
                                            className="font-aspekta-700"
                                            sx={{
                                              fontSize: '10px',
                                              color: 'white',
                                            }}
                                          >
                                            Range
                                          </TableCell>
                                          <TableCell
                                            className="font-aspekta-700"
                                            sx={{
                                              fontSize: '10px',
                                              color: 'white',
                                            }}
                                          >
                                            Amount{' '}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      {rule?.bumperPayoutRange &&
                                        rule.bumperPayoutRange.map(
                                          (value, index) => {
                                            const conditions = value.condition;
                                            return (
                                              <TableRow
                                                key={index}
                                                sx={{
                                                  '&:last-child td, &:last-child th':
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                              >
                                                <TableCell component="td">
                                                  <Stack>
                                                    {getCriteria(conditions)
                                                      .length > 1
                                                      ? getCriteria(
                                                          conditions
                                                        ).map(
                                                          (
                                                            condition,
                                                            index
                                                          ) => {
                                                            return (
                                                              <Stack
                                                                key={index}
                                                              >
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={toLowerCase(
                                                                    condition.conditionType
                                                                  )}
                                                                />
                                                                {conditions.length !==
                                                                  index + 1 && (
                                                                  <VegaText
                                                                    sx={{
                                                                      fontSize:
                                                                        '10px',
                                                                      color:
                                                                        PRIMARY.blue,
                                                                    }}
                                                                    text={`&`}
                                                                  />
                                                                )}
                                                              </Stack>
                                                            );
                                                          }
                                                        )
                                                      : getCriteria(
                                                          conditions
                                                        ).map(
                                                          (
                                                            condition,
                                                            index
                                                          ) => {
                                                            return (
                                                              <Stack
                                                                key={index}
                                                              >
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={toLowerCase(
                                                                    condition.conditionType
                                                                  )}
                                                                />
                                                              </Stack>
                                                            );
                                                          }
                                                        )}
                                                  </Stack>
                                                </TableCell>
                                                <TableCell component="td">
                                                  <Stack
                                                    direction={'column'}
                                                    gap={1}
                                                  >
                                                    {conditions.length > 1
                                                      ? conditions.map(
                                                          (
                                                            condition,
                                                            index
                                                          ) => {
                                                            return (
                                                              <Stack
                                                                key={index}
                                                                direction={
                                                                  'row'
                                                                }
                                                                gap={0.5}
                                                              >
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${
                                                                    condition.conditionValue
                                                                  }${
                                                                    condition.conditionType !==
                                                                      ConditionType.ADDITIONAL_EMI_COLLECTED &&
                                                                    '%'
                                                                  }`}
                                                                />
                                                                {condition?.conditionCutoffDayOfMonth && (
                                                                  <>
                                                                    <VegaText
                                                                      fontSize={
                                                                        10
                                                                      }
                                                                      text={
                                                                        'by'
                                                                      }
                                                                    />
                                                                    <VegaText
                                                                      fontSize={
                                                                        10
                                                                      }
                                                                      text={`${
                                                                        condition.conditionCutoffDayOfMonth
                                                                      }${getOrdinalNumber(
                                                                        condition.conditionCutoffDayOfMonth
                                                                      )}`}
                                                                    />
                                                                  </>
                                                                )}
                                                                {conditions.length !==
                                                                  index + 1 && (
                                                                  <VegaText
                                                                    fontSize={
                                                                      10
                                                                    }
                                                                    sx={{
                                                                      fontWeight:
                                                                        'bold',
                                                                      fontSize:
                                                                        '10px',
                                                                      color:
                                                                        PRIMARY.blue,
                                                                    }}
                                                                    text={'&'}
                                                                  />
                                                                )}
                                                              </Stack>
                                                            );
                                                          }
                                                        )
                                                      : conditions.map(
                                                          (
                                                            condition,
                                                            index
                                                          ) => {
                                                            return (
                                                              <Stack
                                                                key={index}
                                                                direction={
                                                                  'row'
                                                                }
                                                                gap={0.5}
                                                              >
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${
                                                                    condition.conditionValue
                                                                  }${
                                                                    condition.conditionType !==
                                                                    ConditionType.ADDITIONAL_EMI_COLLECTED
                                                                      ? '%'
                                                                      : ''
                                                                  }`}
                                                                />
                                                                {condition?.conditionCutoffDayOfMonth && (
                                                                  <>
                                                                    <VegaText
                                                                      fontSize={
                                                                        10
                                                                      }
                                                                      text={
                                                                        'by'
                                                                      }
                                                                    />
                                                                    <VegaText
                                                                      fontSize={
                                                                        10
                                                                      }
                                                                      text={`${
                                                                        condition.conditionCutoffDayOfMonth
                                                                      }${getOrdinalNumber(
                                                                        condition.conditionCutoffDayOfMonth
                                                                      )}`}
                                                                    />
                                                                  </>
                                                                )}
                                                              </Stack>
                                                            );
                                                          }
                                                        )}
                                                  </Stack>
                                                </TableCell>
                                                <TableCell component="td">
                                                  <Stack
                                                    direction={'row'}
                                                    gap={1}
                                                  >
                                                    <VegaText
                                                      fontSize={10}
                                                      text={
                                                        value.amountType.includes(
                                                          'PERCENTAGE'
                                                        )
                                                          ? `${value.amountValue}%`
                                                          : StringUtility.formatToINR(
                                                              value.amountValue ??
                                                                0
                                                            )
                                                      }
                                                    />
                                                  </Stack>
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )}
                                    </Table>
                                  )}
                                  {rule?.penaltyRange && (
                                    <Table
                                      size="small"
                                      sx={{ borderCollapse: 'collapse' }}
                                    >
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            bgcolor: '#57257D',
                                          }}
                                        >
                                          <TableCell
                                            align="center"
                                            colSpan={12}
                                            sx={{ color: 'white' }}
                                          >
                                            Penalty Incentive
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            bgcolor: '#57257D',
                                          }}
                                        >
                                          <TableCell
                                            className="font-aspekta-700"
                                            sx={{
                                              fontSize: '10px',
                                              color: 'white',
                                            }}
                                          >
                                            Criteria
                                          </TableCell>
                                          <TableCell
                                            className="font-aspekta-700"
                                            sx={{
                                              fontSize: '10px',
                                              color: 'white',
                                            }}
                                          >
                                            Range
                                          </TableCell>

                                          <TableCell
                                            className="font-aspekta-700"
                                            sx={{
                                              fontSize: '10px',
                                              color: 'white',
                                            }}
                                          >
                                            Amount{' '}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      {rule?.penaltyRange &&
                                        rule.penaltyRange.map(
                                          (value, index) => {
                                            const conditions = value.condition;
                                            return (
                                              <TableRow
                                                key={index}
                                                sx={{
                                                  '&:last-child td, &:last-child th':
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                              >
                                                <TableCell component="td">
                                                  <Stack>
                                                    {getPenaltyCriteria(
                                                      conditions
                                                    ).length > 1
                                                      ? getPenaltyCriteria(
                                                          conditions
                                                        ).map(
                                                          (
                                                            condition,
                                                            index
                                                          ) => {
                                                            return (
                                                              <Stack
                                                                key={index}
                                                              >
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={toLowerCase(
                                                                    condition.conditionType
                                                                  )}
                                                                />
                                                                {conditions.length !==
                                                                  index + 1 && (
                                                                  <VegaText
                                                                    sx={{
                                                                      fontSize:
                                                                        '10px',
                                                                      color:
                                                                        PRIMARY.blue,
                                                                    }}
                                                                    text={`&`}
                                                                  />
                                                                )}
                                                              </Stack>
                                                            );
                                                          }
                                                        )
                                                      : getPenaltyCriteria(
                                                          conditions
                                                        ).map(
                                                          (
                                                            condition,
                                                            index
                                                          ) => {
                                                            return (
                                                              <Stack
                                                                key={index}
                                                              >
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={toLowerCase(
                                                                    condition.conditionType
                                                                  )}
                                                                />
                                                              </Stack>
                                                            );
                                                          }
                                                        )}
                                                  </Stack>
                                                </TableCell>

                                                <TableCell component="td">
                                                  <Stack
                                                    direction={'row'}
                                                    gap={1}
                                                  >
                                                    {conditions.map(
                                                      (condition, index) => {
                                                        return (
                                                          <Stack
                                                            key={index}
                                                            direction={'row'}
                                                            gap={1}
                                                          >
                                                            {condition.op ===
                                                            PenaltyIncentiveAttributeOperationType.Less_Then_Or_Equal ? (
                                                              <>
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={'<='}
                                                                />
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${condition.value}%`}
                                                                />
                                                              </>
                                                            ) : condition.op ===
                                                              PenaltyIncentiveAttributeOperationType.Less_Then ? (
                                                              <>
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={'<'}
                                                                />
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${condition.value}%`}
                                                                />
                                                              </>
                                                            ) : condition.op ===
                                                              PenaltyIncentiveAttributeOperationType.Greater_Then ? (
                                                              <>
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={'>'}
                                                                />
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${condition.value}%`}
                                                                />
                                                              </>
                                                            ) : condition.op ===
                                                              PenaltyIncentiveAttributeOperationType.Greater_Then_Or_Equal ? (
                                                              <>
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={'>='}
                                                                />
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${condition.value}%`}
                                                                />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={'='}
                                                                />
                                                                <VegaText
                                                                  fontSize={10}
                                                                  text={`${condition.value}%`}
                                                                />
                                                              </>
                                                            )}
                                                          </Stack>
                                                        );
                                                      }
                                                    )}
                                                  </Stack>
                                                </TableCell>
                                                <TableCell component="td">
                                                  <Stack
                                                    direction={'row'}
                                                    gap={1}
                                                  >
                                                    <VegaText
                                                      fontSize={10}
                                                      text={
                                                        value.amountType.includes(
                                                          'PERCENTAGE'
                                                        )
                                                          ? `${value.amountValue}%`
                                                          : StringUtility.formatToINR(
                                                              value.amountValue ??
                                                                0
                                                            )
                                                      }
                                                    />
                                                  </Stack>
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )}
                                    </Table>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  ''
                )}
                {policyDetailsState?.chargeCollectedRules &&
                policyDetailsState?.chargeCollectedRules.length ? (
                  <Table size="small" border={1}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          <Typography
                            style={{
                              textAlign: 'center',
                              fontSize: '1.0rem',
                              fontWeight: 500,
                              lineHeight: '1.5rem',
                              letterSpacing: '0.01071rem',
                            }}
                          >
                            Charge Collected
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: '#57257D',
                        }}
                      >
                        <TableCell sx={{ color: 'white' }} align="center">
                          Defination Type
                        </TableCell>
                        <TableCell sx={{ color: 'white' }} align="center">
                          Resolution Range
                        </TableCell>
                        <TableCell sx={{ color: 'white' }} align="center">
                          Amount Per Case
                        </TableCell>

                        <TableCell sx={{ color: 'white' }} align="center">
                          Min Payout
                        </TableCell>
                        <TableCell sx={{ color: 'white' }} align="center">
                          Max Payout
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {policyDetailsState?.chargeCollectedRules.map(
                        (charge, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  fontSize: 10,
                                }}
                                component="td"
                              >
                                {charge?.parameterDefinitionType
                                  ? toLowerCase(charge?.parameterDefinitionType)
                                  : '--'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 10,
                                }}
                                component="td"
                              >
                                <Stack direction={'row'} gap={1}>
                                  <VegaText
                                    fontSize={10}
                                    text={` ${charge?.parameterMin ?? '--'}  `}
                                  />
                                  <VegaText fontSize={10} text={`-`} />
                                  <VegaText
                                    fontSize={10}
                                    text={`   ${charge?.parameterMax ?? '--'} `}
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 10,
                                }}
                                component="td"
                              >
                                {`${charge?.payoutAmountValue}%` ?? '--'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 10,
                                }}
                                component="td"
                              >
                                {charge?.payoutAmountMin ?? '--'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 10,
                                }}
                                component="td"
                              >
                                {charge?.payoutAmountMax ?? '--'}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  ''
                )}
              </Stack>

              <div style={{ marginTop: '20px' }}></div>

              <Table size="small" border={1}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '1.0rem',
                          fontWeight: 500,
                          lineHeight: '1.5rem',
                          letterSpacing: '0.01071rem',
                        }}
                      >
                        Policy Split
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: '#57257D',
                    }}
                  >
                    <TableCell sx={{ color: 'white' }} align="center">
                      Role
                    </TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">
                      Payout
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policyDetailsState.splitPayoutForUsersMap &&
                    Object.entries(
                      policyDetailsState.splitPayoutForUsersMap
                    ).map(([key, value]) => {
                      return (
                        <TableRow>
                          <TableCell
                            component="td"
                            sx={{
                              fontSize: 10,
                            }}
                          >
                            {key}
                          </TableCell>
                          <TableCell
                            component="td"
                            sx={{
                              fontSize: 10,
                            }}
                          >
                            {value}%
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

              <TableRow style={{ marginTop: '2rem' }}>
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: '1rem',
                    padding: '0.5rem',
                  }}
                >
                  {`Max policy payout: ${StringUtility.formatToINR(
                    policyDetailsState.maxPayoutPerPerson ?? 0
                  )}`}
                </Typography>
              </TableRow>
            </Stack>
          )}
        </VegaPageContent>
      )}
    </>
  );
};

export default BucketViewPolicy_new;

const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketEnum.BUCKET1:
      return 'Bucket 1-30';
    case BucketEnum.BUCKET2:
      return 'Bucket 31-60';
    case BucketEnum.BUCKET3:
      return 'Bucket 61-90';
    case BucketEnum.NPA:
      return 'Bucket NPA';
    case 'CHARGES_COLLECTION':
      return 'Charges Collection';

    default:
      return 'Bucket X';
  }
};
