import React, { useEffect, useMemo, useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { ROUTES } from '../../router/routes';
import VegaPageContent from '../../components/common/VegaPageContent';
import { Box, Grid, Stack } from '@mui/material';
import {
  ApplyLeaveIcon,
  RupeeIcon,
  TargetsIcon,
  VisitIcon,
  WalletIcon,
} from '../../components/Icons/Icons';
import { useNavigate } from 'react-router-dom';
import { VegaPill } from '../../components/common/VegaPill';
import { COLOR } from '../../utils/ColorUtility';
import { toLowerCase } from '../../constants/commonFunction';
import { LoanService } from '../../Apis/LoanServices';
import { EscalationCountResponse } from '../../utils/api';
import { useClientAuth } from '../../providers/ClientProvider';

export enum EscalationStatusType {
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
}

export interface FilterProps {
  fromDate: string;
  toDate: string;
}

const Escalations = () => {
  const navigate = useNavigate();
  const { user } = useClientAuth();
  const [count, setcount] = useState<EscalationCountResponse>({
    TODAY: 0,
    MINIMUM_VISIT: 0,
    ROUTE: 0,
    RECEIPT: 0,
    TARGET: 0,
    EXPENSE: 0,
    LEAVE: 0,
    OTS: 0,
  });
  const getCount = async () => {
    const _count = await LoanService.getEscalationCount({
      id: user.id,
    });
    setcount(_count);
  };
  useEffect(() => {
    user && getCount();
  }, [user]);

  const escalations = useMemo(
    () => [
      {
        path: ROUTES.ESCALATIONS_TODAY,
        title: 'Today',
        status: 'PENDING',
        count: count?.TODAY,
        icon: <ApplyLeaveIcon strokeColor="black" width="30" height="30" />,
      },
      {
        path: ROUTES.ESCALATIONS_LEAVE_REQUEST,
        title: 'Leave Request',
        status: 'PENDING',
        count: count.LEAVE,
        icon: <ApplyLeaveIcon strokeColor="black" width="30" height="30" />,
      },
      {
        path: ROUTES.ESCALATIONS_MINIMUM_VISITS,
        title: 'Minimum Visits',
        status: 'PENDING',
        count: count.MINIMUM_VISIT,
        icon: <VisitIcon strokeColor="black" width="30" height="30" />,
      },
      {
        path: ROUTES.ESCALATIONS_DAILY_TARGET,
        title: 'Daily Target',
        status: 'PENDING',
        count: count.TARGET,
        icon: <TargetsIcon strokeColor="black" width="30" height="30" />,
      },
      {
        path: ROUTES.ESCALATIONS_ROUTE_DEVIATION,
        title: 'Route Deviation',
        status: 'PENDING',
        count: count.ROUTE,
        icon: <RupeeIcon strokeColor="black" width="30" height="30" />,
      },
      {
        path: ROUTES.ESCALATIONS_EXPENSE_MANAGEMENT,
        title: 'Expense Management',
        status: 'PENDING',
        count: count.EXPENSE,
        icon: <WalletIcon strokeColor="black" width="30" height="30" />,
      },
      {
        path: ROUTES.ESCALATIONS_OTS,
        title: 'OTS',
        status: 'PENDING',
        count: count.OTS,
        icon: <TargetsIcon strokeColor="black" width="30" height="30" />,
      },
    ],
    [count]
  );

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Escalations'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />
      <VegaPageContent mt={2}>
        <Grid container spacing={2}>
          {escalations.map(escalation => (
            <Grid key={escalation.path} item xs={12} sm={6} md={4} lg={3}>
              <Stack
                sx={{
                  bgcolor: 'white',
                  borderRadius: '6px',
                  p: 2,
                  cursor: 'pointer',
                  gap: 1,
                }}
                onClick={() => navigate(escalation.path)}
              >
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                  <Box>{escalation.icon}</Box>
                  <VegaText
                    text={escalation.title}
                    fontWeight={600}
                    fontSize={'1.0625rem'}
                    color={'black'}
                  />
                </Stack>
                <Stack width={'fit-content'}>
                  <VegaPill
                    text={`${toLowerCase(escalation.status)} ${
                      escalation.count
                    }`}
                    backgroundColor={COLOR.ORANGE.LIGHT}
                    textColor={COLOR.ORANGE.DARK}
                  />
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </VegaPageContent>
    </>
  );
};

export default Escalations;
