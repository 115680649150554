import axios from 'axios';
import { ApiService } from '../types/api';
import {
  ConfigurationDto,
  ConfigurationsListProps,
} from '../types/configurationType';
import {
  ConfigListRequest,
  UpdateStatusConfigRequest,
} from '../types/request/cofigurationRequest';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/allocation',
  CONFIG: '/config',
  LIST: '/list',
  STATUS: '/status',
};
const BASE_URL = Endpoint.BASE + Endpoint.CONFIG;
export class ConfigurationServices extends ApiService {
  static async getConfigList(
    request: Partial<ConfigListRequest>
  ): Promise<PaginatedResponse<ConfigurationsListProps>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async addConfig(
    request: Partial<ConfigurationDto>
  ): Promise<PaginatedResponse<ConfigurationsListProps>> {
    const endpoint = BASE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateStatusOfConfig(
    request: Partial<UpdateStatusConfigRequest>
  ): Promise<boolean> {
    const endpoint = BASE_URL + Endpoint.STATUS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
