import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { EscalationsServices } from '../../Apis/EscalationsServices';
import { toLowerCase } from '../../constants/commonFunction';
import {
  fetchEscalations,
  getEscalationsState,
} from '../../features/escalationsSlice';
import { useDrawer } from '../../hooks/useDrawer';
import {
  EscalationStatusType,
  FilterProps,
} from '../../pages/Escalations/Escalations';
import { RouteDeviationType } from '../../pages/Escalations/modules/RouteDeviation';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { EscalationsDto } from '../../types/escalationsType';
import {
  EscalationStatus,
  UpadteEscalationRequest,
} from '../../types/request/escalationsRequest';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmFilterSection from '../common/LmFilterSection';
import VegaApproveDialog from '../common/VegaApproveDialog';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaRejectDialog from '../common/VegaRejectDialog';
import VegaTabBar from '../common/VegaTabBar';
import VegaTabBarItem from '../common/VegaTabItem';
import VegaText from '../common/VegaText';
import { RouteDeviationColumn } from './Columns/RouteDeviationColumn';
import EscalationFilterDrawer from './Drawer/EscalationFilterDrawer';

interface IProps {
  selectedTab: number;
}

const ListOfRouteDeviation = ({ selectedTab }: IProps) => {
  const { loading, error, escalations, totalItems } =
    useAppSelector(getEscalationsState);
  const dispatch = useAppDispatch();
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [escalationData, setEscalationData] = useState<Partial<EscalationsDto>>(
    {}
  );

  const [formData, setFormData] = useState<FilterProps>({
    fromDate: '',
    toDate: '',
  });

  const {
    open: onApproveOpen,
    close: onApproveClose,
    isOpen: isApproveOpen,
  } = useDrawer();
  const {
    open: onRejectOpen,
    close: onRejectClose,
    isOpen: isRejectOpen,
  } = useDrawer();
  const {
    open: onOpenFilterDrawer,
    close: onCloseFilterDrawer,
    isOpen: isOpenFilterDrawer,
  } = useDrawer();

  const onAction = (escalation: EscalationsDto, action: string) => {
    if (action === EscalationStatus.RESOLVED) {
      onApproveOpen();
    } else {
      onRejectOpen();
    }
    setEscalationData(escalation);
  };

  const updateEscalation = (
    escalationStatus: EscalationStatus,
    rejectedReason: string,
    onClose: () => void
  ) => {
    const request = {
      id: escalationData.id,
      escalationStatus,
      ...(rejectedReason.length && { rejectedReason }),
    } as UpadteEscalationRequest;

    EscalationsServices.updateEscalation(request)
      .then(() => {
        setSnackbar(`${escalationStatus} successfully !!!`);
        onClose();
        dispatch(
          fetchEscalations({
            page,
            size,
            managerId: user?.id,
            escalationType: getKeyFromTab(selectedTab),
          })
        );
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onClose();
      });
  };

  const onClear = () => {
    setFormData({
      fromDate: '',
      toDate: '',
    });
    onCloseFilterDrawer();
    dispatch(
      fetchEscalations({
        page,
        size,
        managerId: user?.id,
        escalationType: getKeyFromTab(selectedTab),
        ...(search.length && { userName: search }),
        ...(selectedStatus === 0
          ? {
              escalationStatus: [EscalationStatus.RAISED],
            }
          : {
              escalationStatus: [
                EscalationStatus.RESOLVED,
                EscalationStatus.REJECTED,
                EscalationStatus.ESCALATED,
              ],
            }),
      })
    );
  };

  const getEscalationList = () => {
    dispatch(
      fetchEscalations({
        page,
        size,
        managerId: user?.id,
        escalationType: getKeyFromTab(selectedTab),
        ...(search.length && { userName: search }),
        ...(formData.fromDate.length && { fromDate: formData.fromDate }),
        ...(formData.toDate.length && { toDate: formData.toDate }),
        ...(selectedStatus === 0
          ? {
              escalationStatus: [EscalationStatus.RAISED],
            }
          : {
              escalationStatus: [
                EscalationStatus.RESOLVED,
                EscalationStatus.REJECTED,
                EscalationStatus.ESCALATED,
              ],
            }),
      })
    );
  };

  useEffect(() => {
    if (user) {
      if (search.length) {
        const getEscalation = setTimeout(getEscalationList, 500);
        return () => clearTimeout(getEscalation);
      } else {
        getEscalationList();
      }
    }
  }, [page, size, selectedStatus, search, user]);

  useEffect(() => {
    if (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);
  return (
    <>
      <VegaPageHeader
        sx={{
          pb: 0,
          bgcolor: 'transparent',
          borderBottom: 'none',
        }}
        renderLeftView={() => {
          return (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaTabBar
                value={selectedStatus}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedStatus(selected);
                }}
              >
                {Object.keys(EscalationStatusType).map((type: string) => (
                  <VegaTabBarItem key={type} label={toLowerCase(type)} />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection
              setSearch={setSearch}
              search={search}
              onFilter={onOpenFilterDrawer}
            />
          );
        }}
      />
      <VegaPageContent mt={2}>
        <VegaDataGrid
          idColumn="id"
          data={escalations}
          columns={RouteDeviationColumn({
            selectedTab: selectedStatus,
            onAction,
          })}
          loading={loading}
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
      <VegaApproveDialog
        header="Are you sure?"
        buttonText="Approve"
        open={isApproveOpen}
        onClose={onApproveClose}
        onApprove={() =>
          updateEscalation(EscalationStatus.RESOLVED, '', onApproveClose)
        }
      >
        <VegaText
          text={`You are approving the request of agent ${escalationData.userName}`}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaRejectDialog
        text="Reject Request?"
        open={isRejectOpen}
        onClose={onRejectClose}
        onReject={(reason: string) =>
          updateEscalation(EscalationStatus.REJECTED, reason, onRejectClose)
        }
      />
      <EscalationFilterDrawer
        open={isOpenFilterDrawer}
        onClose={onCloseFilterDrawer}
        formData={formData}
        setFormData={setFormData}
        onFilter={getEscalationList}
        onClear={onClear}
      />
    </>
  );
};

export default ListOfRouteDeviation;

const getKeyFromTab = (tab: number) => {
  const value = Object.keys(RouteDeviationType)[tab];
  return value;
};
