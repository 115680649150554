import _ from 'lodash';
import VegaText from '../../../../components/common/VegaText';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import {
  Ots,
  OtsReportsStatus,
  OtsStatus,
  OtsUserRole,
} from '../../../../types/ots';
import { getEnumValueFromString } from '../../../../types/trail';
import { COLOR } from '../../../../utils/ColorUtility';
import { DateUtility } from '../../../../utils/DateUtlility';

function OtsHelperTextView() {
  const { userForId, roles } = useClientAuth();
  const { ots, otsConfig } = useOts();

  const isReportsApproved = ots =>
    ots?.operationReportStatus === OtsReportsStatus.APPROVED &&
    ots?.technicalReportsStatus === OtsReportsStatus.APPROVED;

  const getReportStatusText = (status?: OtsReportsStatus) => {
    switch (status) {
      case OtsReportsStatus.PENDING:
        return 'Submitted, Pending approval from RCM.';
      case OtsReportsStatus.APPROVED:
        return 'Approved.';
      case OtsReportsStatus.REJECTED:
        return 'Rejected. Please resubmit.';
      default:
        return 'Not submitted.';
    }
  };

  function getOtsReportHelperText(ots: Ots): string {
    const operationText = `Operation Report: ${getReportStatusText(
      ots.operationReportStatus
    )}`;
    const technicalText = `Technical Report: ${getReportStatusText(
      ots.technicalReportsStatus
    )}`;

    return `${operationText} | ${technicalText}`;
  }

  const isOtsPendingOnOpsTechTeam = (ots: Ots, isOps: boolean) => {
    const reportsStatus = isOps
      ? ots.operationReportStatus
      : ots.technicalReportsStatus;
    const isDataNotSubmittedYet = reportsStatus == null;
    const wasDataRejected = reportsStatus == OtsReportsStatus.REJECTED;
    return isDataNotSubmittedYet || wasDataRejected;
  };

  const isOtsPendingOnRcm = (ots: Ots) => {
    const techReportStatus = ots.technicalReportsStatus;
    const opsReportStatus = ots.operationReportStatus;
    return (
      techReportStatus == OtsReportsStatus.PENDING ||
      opsReportStatus == OtsReportsStatus.PENDING
    );
  };

  const getUserPendingOn = () => {
    const pendingOn = otsPendingOn();
    if (!pendingOn) return;
    const userId = getUserIdForRole(pendingOn);
    if (!userId) return;
    const user = userForId(userId);
    if (!user) return;
    const name = user;
    return name;
  };

  const getUserIdForRole = (role: OtsUserRole) => {
    switch (role) {
      case OtsUserRole.CE:
        return ots?.agent;
      case OtsUserRole.RCM:
        return ots?.rcm;
      case OtsUserRole.NCM:
        return ots?.ncm;
      case OtsUserRole.GROUP_COLLECTIONS_HEAD:
        return ots?.hoc;
      case OtsUserRole.AGENDA_CREATOR:
        return ots?.agendaCreator;
      case OtsUserRole?.OPERATION_TEAM:
        return ots?.operationTeam;
      case OtsUserRole?.TECHNICAL_TEAM:
        return ots?.technicalTeam;
    }
  };

  const otsPendingOn = () => {
    const status = ots?.status;
    if (!status) return;
    switch (ots.status) {
      case OtsStatus.PRE_RCM_APPROVAL:
      case OtsStatus.PRE_RCM_REJECT:
      case OtsStatus.PRE_RCM_ON_HOLD:
      case OtsStatus.POST_NCM_REJECT:
      case OtsStatus.PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL:
      case OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL:
      case OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_REJECT:
        return OtsUserRole.RCM;
      case OtsStatus.POST_RCM_APPROVAL:
        if (isOtsPendingOnOpsTechTeam(ots, false)) {
          return OtsUserRole.OPERATION_TEAM;
        }
        if (isOtsPendingOnOpsTechTeam(ots, true)) {
          return OtsUserRole.OPERATION_TEAM;
        }
        if (isOtsPendingOnRcm(ots)) {
          return OtsUserRole.RCM;
        }
        return;
      case OtsStatus.PRE_NCM_APPROVAL:
      case OtsStatus.POST_NCM_APPROVAL:
      case OtsStatus.PRE_NCM_ON_HOLD:
      case OtsStatus.PRE_NCM_REJECT:
        return OtsUserRole.NCM;
      case OtsStatus.PRE_HOC_APPROVAL:
      case OtsStatus.POST_HOC_APPROVAL:
      case OtsStatus.PRE_HOC_ON_HOLD:
      case OtsStatus.PRE_HOC_REJECT:
      case OtsStatus.PRE_HOC_POST_COMMITTEE_APPROVAL:
      case OtsStatus.HOC_POST_COMMITTEE_REJECT:
      case OtsStatus.POST_HOC_POST_COMMITTEE_APPROVAL:
        return OtsUserRole.GROUP_COLLECTIONS_HEAD;
      case OtsStatus.PRE_MD_APPROVAL:
      case OtsStatus.POST_MD_APPROVAL_APPROVE:
      case OtsStatus.POST_MD_APPROVAL_REJECT:
        return OtsUserRole.MANAGING_DIRECTOR;
      case OtsStatus.PENDING_CUSTOMER_ACKNOWLEDGEMENT:
        return OtsUserRole.CE;
      case OtsStatus.PRE_CHAIR_APPROVAL:
        return OtsUserRole.COMMITTEE_CHAIR;
    }
  };

  function generateRCMMessage(ots: Ots): string {
    let displayText = 'RCM Needs to add ';
    const commentsPresent = (ots.commentIds ?? []).length > 0;
    if (!commentsPresent) {
      displayText += 'case background Comment. Also ';
    } else {
      displayText += 'Also ';
    }
    const missingWaivers = [];
    if (!ots.principle) {
      missingWaivers.push('Principal Waiver');
    }
    if (!ots.interest) {
      missingWaivers.push('Interest Waiver');
    }
    if (!ots.others) {
      missingWaivers.push('Other Waiver');
    }
    if (missingWaivers.length > 0) {
      displayText += missingWaivers.join(', ') + '.';
    }
    return displayText;
  }

  const getHelperText = () => {
    const status = ots?.status;
    if (status == OtsStatus.PRE_RCM_APPROVAL) {
      let displayText = generateRCMMessage(ots);
      return displayText == 'RCM Needs to add '
        ? 'Pending RCM Approval.'
        : displayText;
    } else if (status == OtsStatus.POST_RCM_APPROVAL) {
      return isReportsApproved(ots) == false
        ? getOtsReportHelperText(ots)
        : 'Pending Rcm Submission';
    } else if (status == OtsStatus.PRE_NCM_APPROVAL) {
      return 'Pending NCM Approval';
    } else if (status == OtsStatus.POST_NCM_APPROVAL) {
      return (ots?.npvCalculated ?? false) == true
        ? 'Pending NCM Submission'
        : 'Pending NPV Calculations';
    } else if (status == OtsStatus.PRE_HOC_APPROVAL) {
      return 'Pending HOC Approval';
    } else if (status == OtsStatus.POST_HOC_APPROVAL) {
      return 'Pending HOC Submission';
    } else if (status == OtsStatus.PRE_AGENDA_CREATION) {
      return 'Submitted to agenda creator for including in committee discussion';
    } else if (status == OtsStatus.POST_AGENDA_CREATION) {
      return 'Agenda Creator has added case to upcoming agenda';
    } else if (status == OtsStatus.PRE_COMMITTEE_APPROVAL) {
      return 'Pending Committee approval ';
    } else if (status == OtsStatus.PRE_CHAIR_APPROVAL) {
      return 'Pending Chair Approval';
    } else if (status == OtsStatus.PRE_HOC_POST_COMMITTEE_APPROVAL) {
      return 'Approved by Committee and Chair. Pending HOC Approval';
    } else if (status == OtsStatus.POST_HOC_POST_COMMITTEE_APPROVAL) {
      return 'Approved by Committee and Chair. Pending HOC Submission';
    } else if (status == OtsStatus.PRE_MD_APPROVAL) {
      return 'Pending Md Approval';
    } else if (status == OtsStatus.POST_MD_APPROVAL_APPROVE) {
      return 'Pending Md Submission';
    } else if (status == OtsStatus.PENDING_CUSTOMER_ACKNOWLEDGEMENT) {
      return 'Agent required to upload Signed Customer acknowledgement letter';
    } else if (status == OtsStatus.PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL) {
      return 'Pending RCM Approval on Customer acknowledgement letter';
    } else if (status == OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL) {
      return 'Pending RCM Submission on Customer acknowledgement letter';
    }

    return _.startCase(_.toLower(status));
  };

  const getDelayedText = () => {
    const pendingOn = getUserPendingOn();
    const deyaledDays = getDelayedDays();
    var displayText = `Delayed by ${deyaledDays} days. `;
    if (pendingOn) {
      displayText += ` Pending on ${getUserPendingOn()}`;
    }
    return displayText;
  };

  const mapRoles = roles =>
    roles
      .map(i => getEnumValueFromString(OtsUserRole, i.roleDto.name))
      .filter(i => i !== undefined);

  const getTatTimeFoRole = (role: OtsUserRole) => {
    if (!otsConfig) return -1;
    switch (role) {
      case OtsUserRole.COMMITTEE_CHAIR:
        return otsConfig.chairTatDays;
      case OtsUserRole.MANAGING_DIRECTOR:
        return otsConfig.mdTatDays;
      case OtsUserRole.NCM:
        return otsConfig.ncmTatDays;
      case OtsUserRole.GROUP_COLLECTIONS_HEAD:
        return otsConfig.hocTatDays;
      case OtsUserRole.RCM:
        return otsConfig.rcmTatDays;
      case OtsUserRole.OPERATION_TEAM:
        return otsConfig.opsTatDays;
      case OtsUserRole.TECHNICAL_TEAM:
        return otsConfig.techTatDays;
      case OtsUserRole.CE:
        return otsConfig.agentTatDays;
    }
    return -1;
  };

  const getDelayedDays = () => {
    const today = new Date();
    const lastTransitioned = DateUtility.parseStringToDate(ots?.updatedAt);
    if (!today || !lastTransitioned) {
      return '';
    }
    return DateUtility.differenceInDays(today, lastTransitioned);
  };

  const isDelayed = () => {
    const mappedRoles: OtsUserRole[] = mapRoles(roles);
    const tatTimes: number[] = mappedRoles
      .map(getTatTimeFoRole)
      .filter(i => i > 0);
    if (tatTimes.length <= 0) return false;
    const days = getDelayedDays();
    return days > tatTimes[0];
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <div
        style={{
          border: `0.5px solid ${COLOR.GREY[100]}`,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          display: isDelayed() ? 'flex' : 'none',
          backgroundColor: COLOR.ORANGE.LIGHT,
        }}
      >
        <VegaText text={getDelayedText()} />
      </div>
      <div
        style={{
          border: `0.5px solid ${COLOR.GREY[100]}`,
          padding: '0.5rem',
          borderRadius: '0.5rem',
        }}
      >
        <VegaText text={getHelperText()} />
      </div>
    </div>
  );
}

export default OtsHelperTextView;
