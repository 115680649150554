import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import VegaText from '../../../../../components/common/VegaText';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { BucketCollectionVolumeResultDto } from '../../../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import BarChart from '../../../components/BarChart';

const BucketPerformance = () => {
  const { profitType } = useParams();

  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    BucketCollectionVolumeResultDto[]
  >([]);

  const [loader, setLoader] = useState<boolean>(false);
  const series = [
    {
      name: 'Receipt',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            const details = value as BucketCollectionVolumeResultDto;
            return details.receipts;
          })
        : [],
    },
    {
      name: 'Target',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            const details = value as BucketCollectionVolumeResultDto;
            return details.target;
          })
        : [],
    },
  ];

  const regionCategories = analyticsQueryResponse?.length
    ? analyticsQueryResponse.map(value => value?.bucket)
    : [];

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoader(false);
    }
  };

  const getRequest = () => {
    const request = {
      analyticsQueryType: AnalyticsQueryTypeEnum.VOLUME_BUCKET_PERFORMANCE,
      params: {
        type: profitType,
      },
    } as AnalyticsQueryRequest;
    return request;
  };

  const getPenalRegionCollectionQueries = () => {
    const request = getRequest();
    getQueriesForEnum(request);
  };
  useEffect(() => {
    getPenalRegionCollectionQueries();
  }, []);

  return (
    <>
      <GraphWrapper title="Bucket Performance">
        {loader ? (
          <LoadingPage height={400} />
        ) : (
          <Stack
            sx={{
              p: 3,
              bgcolor: 'white',
              borderRadius: '12px',
              border: BORDER_COLLECTION,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -25,
                  height: 50,
                  width: '91%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1', textAlign: 'center' }}>
                          {getPercentageValue(val)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <BarChart
                categories={regionCategories}
                series={series}
                yAxisTitle="No of Cases"
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default BucketPerformance;
const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
