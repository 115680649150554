import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IncentivePolicyProps } from '../../../types/incentives';
import { VegaSelectOption } from '../../common/VegaSelect';
import VegaSelectWithCheckbox from '../../common/VegaSelectWithCheckbox';

const CaseTypeSelect = () => {
  const { policyDetailsState, policyEnumValues } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();

  const handlePolicyChange = (
    key: keyof IncentivePolicyProps,
    value: string | number | string[]
  ) => {
    const policyDetails = {
      ...policyDetailsState,
      [key]: value,
    } as IncentivePolicyProps;
    dispatch(setPolicyDetailsState(policyDetails));
  };

  const onSelectCaseType = (type: string) => {
    const caseType = policyDetailsState?.caseType ?? [];
    if (caseType?.includes(type)) {
      const filteredCaseType = caseType.filter(
        (selectedUser: string) => selectedUser !== type
      );

      handlePolicyChange('caseType', filteredCaseType);
    } else {
      handlePolicyChange('caseType', [...caseType, type]);
    }
  };
  const onDeleteCaseType = (index: number) => {
    const caseType = policyDetailsState?.caseType ?? [];
    const caseTypes = [...caseType];
    caseTypes.splice(index);
    handlePolicyChange('caseType', caseTypes);
  };
  return (
    <>
      {policyEnumValues?.CASE_TYPES?.length && (
        <VegaSelectWithCheckbox
          options={caseTypeOption(policyEnumValues?.CASE_TYPES)}
          onSelect={selected => onSelectCaseType(selected)}
          handleDelete={selectedIndex => onDeleteCaseType(selectedIndex)}
          selected={policyDetailsState?.caseType}
          placeHolder="Select case type"
        />
      )}
    </>
  );
};

export default CaseTypeSelect;

const caseTypeOption = (caseTypeEnums: string[]) => {
  if (caseTypeEnums) {
    const options: VegaSelectOption[] = caseTypeEnums.map((item: string) => ({
      value: item,
      label: item,
    }));
    return options;
  }
};
