import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { AlertCircleIcon } from '../../../../components/Icons/Icons';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaText from '../../../../components/common/VegaText';
import {
  IncentivePolicyApprovalStatus,
  WriteOffRewardRecordsResponseDto,
  WriteOffSplitData,
} from '../../../../types/request/incentives';
import { COLOR } from '../../../../utils/ColorUtility';
import { StringUtility } from '../../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    formData: Partial<SetSplitFormData>,
    writeOffData: WriteOffRewardRecordsResponseDto
  ) => void;
  data: WriteOffRewardRecordsResponseDto;
};

export interface SetSplitFormData {
  NCM: number;
  ZCM: number;
  RCM: number;
  CCM: number;
  ACM: number;
  CM: number;
  CE: number;
}

enum FormFields {
  NCM = 'NCM',
  ZCM = 'ZCM',
  RCM = 'RCM',
  ACM = 'ACM',
  CM = 'CM',
  CE = 'CE',
  CCM = 'CCM',
}
function SetSplitDrawer({ open, onClose, onSubmit, data }: Props) {
  const [formData, setFormData] = useState<SetSplitFormData>({
    NCM: 0,
    ZCM: 0,
    RCM: 0,
    CCM: 0,
    ACM: 0,
    CM: 0,
    CE: 0,
  });

  function updateFormData(field: FormFields, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function cleanup() {
    setFormData({
      NCM: 0,
      ZCM: 0,
      RCM: 0,
      CCM: 0,
      ACM: 0,
      CM: 0,
      CE: 0,
    });
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function onSubmitClick() {
    onSubmit(formData, data);
    handleClose();
  }

  function isValid() {
    const percentages = Object.values(formData).map(parseFloat);
    const totalPercentage = percentages.reduce((acc, curr) => acc + curr, 0);
    return totalPercentage === 100;
  }

  function isErrorTextDisplayed() {
    const percentages = Object.values(formData).map(parseFloat);
    const totalPercentage = percentages.reduce((acc, curr) => acc + curr, 0);
    return totalPercentage > 100;
  }

  function calculatePercentageOfPayout(x: number): number {
    if (x === 0) {
      return 0;
    }
    return (x / 100) * data.finalLoanIncentiveAmount;
  }

  const transformAndSetFormData = (
    splitData: Record<string, WriteOffSplitData>
  ) => {
    const transformedData: SetSplitFormData = {
      NCM: splitData.NCM?.splitPercentage || 0,
      ZCM: splitData.ZCM?.splitPercentage || 0,
      RCM: splitData.RCM?.splitPercentage || 0,
      CCM: splitData.CCM?.splitPercentage || 0,
      ACM: splitData.ACM?.splitPercentage || 0,
      CM: splitData.CM?.splitPercentage || 0,
      CE: splitData.CE?.splitPercentage || 0,
    };

    setFormData(transformedData);
  };

  useEffect(() => {
    if (data?.approvalStatus === IncentivePolicyApprovalStatus.REJECTED) {
      transformAndSetFormData(data.splitData);
    }
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Set Split'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Submit'}
              disabled={!isValid()}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        <Grid container direction={'column'} gap={1}>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaText
                text="LAN: "
                fontWeight={600}
                fontSize="14px"
                lineHeight="19.6px"
              />
              <VegaText
                text={data?.loanId ?? '-'}
                fontWeight={600}
                fontSize="14px"
                lineHeight="19.6px"
              />
            </Stack>
          </Grid>
          <Grid>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaText
                text="Payout: "
                fontWeight={700}
                fontSize="10px"
                lineHeight="12.4px"
                color={COLOR.GREY[50]}
              />
              <VegaText
                text={StringUtility.formatNumberToIndianNumberFormat(
                  data?.finalLoanIncentiveAmount ?? '-'
                )}
                fontWeight={400}
                fontSize="12px"
                lineHeight="15.6px"
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container direction={'row'} gap={3} marginTop={4}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="Role"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
              color={COLOR.GREY[100]}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="Set Percentage"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
              color={COLOR.GREY[100]}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="Amount"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
              color={COLOR.GREY[100]}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="NCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.NCM}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.NCM, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData?.NCM ?? 0)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="ZCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.ZCM}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.ZCM, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData.ZCM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="RCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.RCM}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.RCM, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData.RCM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="CCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.CCM}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.CCM, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData.CCM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <VegaText
              text="ACM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.ACM}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.ACM, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData.ACM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <VegaText
              text="CM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.CM}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.CM, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData.CM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <VegaText
              text="CE"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Percentage"
              type="number"
              value={formData?.CE}
              onChange={e => {
                const value = e.target.value
                  ? parseFloat(e.target.value)
                  : null;
                updateFormData(FormFields.CE, value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(formData.CE)}
            />
          </Grid>
          {isErrorTextDisplayed() && (
            <Grid item>
              <Stack direction="row" alignItems="center" gap={1}>
                <AlertCircleIcon strokeColor="#A91919" height="16" width="16" />
                <VegaText
                  text={'Total split amount is not equal to Payout amount'}
                  color="#BA1C1C"
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default SetSplitDrawer;
