import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../../components/common/VegaText';
import { OverallAgentIncentiveDetails } from '../../../types/incentives';
import { StringUtility } from '../../../utils/StringUtility';

export const OverallPayoutColumn: GridColumns = [
  {
    field: 'agentName',
    headerName: 'Agent Name',
    flex: 1,
    minWidth: 150,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={overallPayout?.agentName}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'agentCode',
    headerName: 'Agent Code',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={overallPayout?.agentCode}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'designation',
    headerName: 'Designation',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={overallPayout.roleName ?? '-'}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={overallPayout?.region}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'regionRank',
    headerName: 'Region Rank',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={overallPayout?.regionRank}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'air',
    headerName: 'Air',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={overallPayout?.allIndiaRank}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Bucket X',
    headerName: 'Bucket X',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      const displayText = StringUtility.formatNumberToIndianNumberFormat(
        overallPayout?.overallBucketLevelIncentive['Bucket X'] ?? 0
      );
      return (
        <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'Bucket 1-30',
    headerName: 'Bucket 1-30',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      const displayText = StringUtility.formatNumberToIndianNumberFormat(
        overallPayout?.overallBucketLevelIncentive['Bucket 1-30'] ?? 0
      );
      return (
        <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'Bucket 31-60',
    headerName: 'Bucket 31-60',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      const displayText = StringUtility.formatNumberToIndianNumberFormat(
        overallPayout?.overallBucketLevelIncentive['Bucket 31-60'] ?? 0
      );
      return (
        <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'Bucket 61-90',
    headerName: 'Bucket 61-90',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      const displayText = StringUtility.formatNumberToIndianNumberFormat(
        overallPayout?.overallBucketLevelIncentive['Bucket 61-90'] ?? 0
      );
      return (
        <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'NPA 240-',
    headerName: 'NPA 240-',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      const displayText = StringUtility.formatNumberToIndianNumberFormat(
        overallPayout?.overallBucketLevelIncentive['NPA 240-'] ?? 0
      );
      return (
        <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'NPA 240+',
    headerName: 'NPA 240+',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      const displayText = StringUtility.formatNumberToIndianNumberFormat(
        overallPayout?.overallBucketLevelIncentive['NPA 240+'] ?? 0
      );
      return (
        <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'chargeCollection',
    headerName: 'Charge Collection',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={StringUtility.formatNumberToIndianNumberFormat(
            overallPayout?.totalChargeCollected ?? 0
          )}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'adjustedPayout',
    headerName: 'Adjusted Payout',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={StringUtility.formatNumberToIndianNumberFormat(
            overallPayout?.adjustedPayout ?? 0
          )}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'maxPayout',
    headerName: 'Max Payout',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={StringUtility.formatNumberToIndianNumberFormat(
            overallPayout?.maxRolePayout ?? 0
          )}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'nominalPayout',
    headerName: 'Nominal Payout',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={StringUtility.formatNumberToIndianNumberFormat(
            overallPayout?.nominalPayout ?? 0
          )}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'writeOffPayout',
    headerName: 'Write Off Payout',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={StringUtility.formatNumberToIndianNumberFormat(
            overallPayout?.writeOffPayout ?? 0
          )}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'finalpayout',
    headerName: 'Final Payout',
    flex: 0.7,
    minWidth: 120,
    renderCell: props => {
      const overallPayout = props.row as OverallAgentIncentiveDetails;
      return (
        <VegaText
          text={StringUtility.formatNumberToIndianNumberFormat(
            overallPayout?.finalPayout ?? 0
          )}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];
