import { Stack } from '@mui/material';
import React, { useState } from 'react';
import {
  getTeleAgent,
  setOpenContactDetailsDrawer,
} from '../../features/teleAgentSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { BagIcon, SendLinkIcon, TagIcon } from '../Icons/Icons';
import VegaButton from '../common/VegaButton';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import MoveToFutureFormDrawer from './MoveToFutureFormDrawer';
import MoveToTodayDialog from './MoveToTodayDialog';
import SendPaymentLinkFormDrawer from './SendPaymentLinkFormDrawer';
import UpdateContactDetailsFormDrawer from './UpdateContactDetailsFormDrawer';
import UpdateTrailFormDrawer from './UpdateTrailFormDrawer';
import RaiseOtsDrawer from '../../pages/ots/modules/RaiseOtsDrawer';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { AllocationTypes } from '../../types/allocations';
import ReferToFieldConfirmationDialog from '../ReferToField/ReferToFieldConfirmationDialog';
import { ReferToFieldService } from '../../Apis/ReferToFieldService';
import { TeleAgentManualAccountViewModel } from '../../types/teleAgent';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useDrawer } from '../../hooks/useDrawer';
import { MetaDataLoanAllocationDto } from '../../types/loan';

export interface IActionProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountActionContainer = () => {
  const { user } = useClientAuth();
  const { loanData, loading } = useAppSelector(getTeleAgent);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [openMoveToFutureDateDrawer, setOpenMoveToFutureDateDrawer] =
    useState<boolean>(false);
  const [openMoveToTodayDialog, setOpenMoveToTodayDialog] =
    useState<boolean>(false);
  const [openPaymentLinkDrawer, setOpenPaymentLinkDrawer] =
    useState<boolean>(false);
  const [openUpdateTrialDrawer, setOpenUpdateTrialDrawer] =
    useState<boolean>(false);
  const [openOtsDrawer, setOpenOtsDrawer] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    open: openReferToFieldConfirmationDialog,
    close: closeReferToFieldConfirmationDialog,
    isOpen: isReferToFieldConfirmationDialogOpen,
  } = useDrawer(false);

  const hasOtsPending = () => {
    if (loanData.length <= 0) {
      return false;
    }
    const loan = loanData[0];
    return loan?.lmsVariables?.otsRaised;
  };

  function onOtsClick() {
    if (hasOtsPending()) {
      const otsId = loanData[0].lmsVariables.otsId;
      navigate(ROUTES.OTS_DETAILS.replace(':otsId', otsId));
      return;
    }
    setOpenOtsDrawer(true);
  }

  async function onAcceptReferToFieldClick(
    allocationData: TeleAgentManualAccountViewModel | MetaDataLoanAllocationDto,
    agentRemark: string
  ) {
    try {
      if (allocationData && agentRemark) {
        await ReferToFieldService.createReferToField({
          teleAllocationId:
            (allocationData as MetaDataLoanAllocationDto).teleAllocationId ??
            (allocationData as TeleAgentManualAccountViewModel).id,
          agentId: allocationData.agentId,
          agentRemark: agentRemark,
          approverId: user?.id,
        });
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  function onViewTrailClick() {
    if (loanData.length <= 0) {
      return;
    }
    const loan = loanData[0];
    navigate(ROUTES.Trail_Detail_Page.replace(':loanAccountId', loan?.loanId));
  }
  function isTeleAgent() {
    if (!loanData?.[0]) {
      return;
    }
    return (
      loanData[0]?.metaDataLoanAllocationDto?.allocationType ===
      AllocationTypes.TELE
    );
  }

  return (
    <>
      <VegaPageContent sx={{ pt: '1.5rem' }}>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <VegaText
            fontWeight={700}
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.625rem',
              letterSpacing: '0.07em',
              color: '#676B76',
            }}
            text={'action'}
          />
          <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
            <VegaButton
              variant="outlined"
              text="Send Payment Link"
              startIcon={<SendLinkIcon />}
              onClick={() => setOpenPaymentLinkDrawer(true)}
            />

            <VegaButton
              variant="outlined"
              text="Update Trail"
              startIcon={<TagIcon strokeColor="#1047DC" />}
              onClick={() => setOpenUpdateTrialDrawer(true)}
            />
            <VegaButton
              variant="outlined"
              text="View Trail"
              onClick={() => {
                onViewTrailClick();
              }}
            />
            <VegaButton
              variant="outlined"
              text={hasOtsPending() ? 'View OTS' : 'Raise OTS'}
              startIcon={<TagIcon strokeColor="#1047DC" />}
              onClick={() => onOtsClick()}
            />
            <VegaButton
              variant="outlined"
              text="Update Contact Details"
              onClick={() => dispatch(setOpenContactDetailsDrawer(true))}
            />
            {!isTeleAgent() && !loading && (
              <>
                <VegaButton
                  variant="outlined"
                  text="Move to Today"
                  startIcon={<BagIcon />}
                  onClick={() => setOpenMoveToTodayDialog(true)}
                />
                <VegaButton
                  variant="outlined"
                  text="Move to Future Date"
                  startIcon={<BagIcon />}
                  onClick={() => setOpenMoveToFutureDateDrawer(true)}
                />
              </>
            )}
            {isTeleAgent() && (
              <VegaButton
                variant="outlined"
                text="Refer To Field"
                onClick={() => openReferToFieldConfirmationDialog()}
              />
            )}
          </Stack>
        </Stack>
      </VegaPageContent>
      <SendPaymentLinkFormDrawer
        loanData={loanData}
        open={openPaymentLinkDrawer}
        setOpen={setOpenPaymentLinkDrawer}
      />
      <MoveToTodayDialog
        open={openMoveToTodayDialog}
        setOpen={setOpenMoveToTodayDialog}
      />
      <MoveToFutureFormDrawer
        open={openMoveToFutureDateDrawer}
        setOpen={setOpenMoveToFutureDateDrawer}
      />
      <UpdateTrailFormDrawer
        open={openUpdateTrialDrawer}
        setOpen={setOpenUpdateTrialDrawer}
      />
      <UpdateContactDetailsFormDrawer />
      <RaiseOtsDrawer
        open={openOtsDrawer}
        onClose={function (): void {
          setOpenOtsDrawer(false);
        }}
      />
      <ReferToFieldConfirmationDialog
        onAcceptClick={(allocationData, agentRemark) => {
          onAcceptReferToFieldClick(allocationData, agentRemark);
        }}
        onClose={closeReferToFieldConfirmationDialog}
        show={isReferToFieldConfirmationDialogOpen}
        allocationData={loanData?.[0]?.metaDataLoanAllocationDto}
      />
    </>
  );
};

export default AccountActionContainer;
