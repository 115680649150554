import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgendaService } from '../../../Apis/AgendaService';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaText from '../../../components/common/VegaText';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { Agenda, AgendaStatus } from '../../../types/ots';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../../utils/api';
import { DateUtility } from '../../../utils/DateUtlility';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../constants/style';

function CommitteeOtsPage() {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingAgendas, setPendingAgendas] = useState<
    PaginatedResponse<Agenda>
  >({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });
  const [publishedAgendas, setPublishedAgendas] = useState<
    PaginatedResponse<Agenda>
  >({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });
  const [allAgendas, setAllAgendas] = useState<PaginatedResponse<Agenda>>({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const getStatusForSelectedTab = () => {
    if (selectedTab == 0) {
      return AgendaStatus.DRAFT;
    } else if (selectedTab == 1) {
      return AgendaStatus.PUBLISHED;
    }
  };

  const getDataForSelectedTab = () => {
    if (selectedTab == 0) {
      return pendingAgendas;
    } else if (selectedTab == 1) {
      return publishedAgendas;
    } else if (selectedTab == 2) {
      return allAgendas;
    }
  };

  async function fetchData() {
    try {
      setLoading(true);
      const status = getStatusForSelectedTab();
      const page = getDataForSelectedTab().pageNumber;
      const response = await AgendaService.getAgendaList({
        page: page,
        pageSize: 10,
        status: status,
      });
      if (selectedTab == 0) {
        setPendingAgendas(response);
      } else if (selectedTab == 1) {
        setPublishedAgendas(response);
      } else if (selectedTab == 2) {
        setAllAgendas(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function setPage(page: number) {
    if (selectedTab == 0) {
      setPendingAgendas(prev => {
        return {
          ...prev,
          pageNumber: page,
        };
      });
    } else if (selectedTab == 1) {
      setPublishedAgendas(prev => {
        return {
          ...prev,
          pageNumber: page,
        };
      });
    } else if (selectedTab == 2) {
      setAllAgendas(prev => {
        return {
          ...prev,
          pageNumber: page,
        };
      });
    }
  }

  function refresh() {
    setPage(0);
    fetchData();
  }

  async function onPublishClick(agenda: Agenda) {
    try {
      setLoading(true);
      await AgendaService.publishAgenda(agenda.id);
      refresh();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onViewDetailsClick(agenda: Agenda) {
    navigate(
      ROUTES.OTS_AGENDA_CREATOR_COMMITTEE_AGENT_ID.replace(
        ':agendaId',
        agenda.id
      )
    );
  }

  useEffect(() => {
    fetchData();
  }, [
    pendingAgendas.pageNumber,
    allAgendas.pageNumber,
    publishedAgendas.pageNumber,
    selectedTab,
  ]);

  return (
    <div>
      <VegaPageHeader title="Ots Committee" />
      <VegaPageHeader
        sx={{
          borderBottom: 'none',
          bgcolor: 'transparent',
        }}
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaTabBar
                value={selectedTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                }}
              >
                <VegaTabBarItem label="Pending" />
                <VegaTabBarItem label="Published" />
                <VegaTabBarItem label="All" />
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      <VegaPageContent>
        <Box pt={3}>
          <VegaDataGrid
            data={getDataForSelectedTab().records}
            loading={loading}
            columns={getColumnDefinition({
              onViewDetailsClick: function (agenda: Agenda): void {
                onViewDetailsClick(agenda);
              },
              onPublishClick: function (agenda: Agenda): void {
                onPublishClick(agenda);
              },
            })}
            idColumn="id"
            rowCount={getDataForSelectedTab().numberOfItems}
            page={getDataForSelectedTab().pageNumber}
            pageSize={10}
            onPageChange={number => setPage(number)}
          />
        </Box>
      </VegaPageContent>
    </div>
  );
}

export default CommitteeOtsPage;

const getColumnDefinition = (data: {
  onViewDetailsClick: (agenda: Agenda) => void;
  onPublishClick: (agenda: Agenda) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'committeeNumber',
      headerName: 'Committee No',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = agenda.committeeNumber ?? 0;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'scheduled',
      headerName: 'Scheduled Date',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = DateUtility.formatStringToDDMMYYYYWithTime(
          agenda.scheduledDateTime
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'approvedCases',
      headerName: 'Approved Cases',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = (agenda.agendaCases ?? []).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'rejectedCases',
      headerName: 'Rejected Cases',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = (agenda.rejectedCases ?? []).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'pendingCases',
      headerName: 'Pending Chair Approval',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = (agenda.pendingApprovalAgendaCases ?? []).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'members',
      headerName: 'Member',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = (agenda.memberIds ?? []).length ?? 0;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'Status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        const displayText = _.startCase(_.toLower(agenda.status));
        const isDraft = agenda.status == AgendaStatus.DRAFT;
        if (isDraft) {
          return (
            <VegaButton
              text={'Publish'}
              onClick={() => {
                data.onPublishClick(agenda);
              }}
            ></VegaButton>
          );
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const agenda = getAgendaForRow(props);
        return (
          // <VegaButton
          //   variant="outlined"
          //   text={'View Details'}
          //   onClick={() => {
          //     data.onViewDetailsClick(agenda);
          //   }}
          // ></VegaButton>
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(agenda);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getAgendaForRow = (props: any) => props.row as Agenda;
