import { Stack, Grid } from '@mui/material';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import {
  OtsReportsStatus,
  OtsReportType,
  OtsStatus,
  OtsUserRole,
} from '../../../../types/ots';
import { getEnumValueFromString } from '../../../../types/trail';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import OtsFileDisplay from './OtsFileDisplay';
import OtsReportActionView from './OtsReportActionView';

function OtsOperationDetails() {
  const { ots, loading, downloadFile } = useOts();
  const { roles } = useClientAuth();

  const canShowActionView = () => {
    const mappedRoles: OtsUserRole[] = roles
      .map(i => getEnumValueFromString(OtsUserRole, i.roleDto.name))
      .filter(i => i != undefined);

    return (
      ots?.status != OtsStatus.OTS_REJECTED &&
      ots?.operationReportStatus == OtsReportsStatus.PENDING &&
      mappedRoles.some(i => i == OtsUserRole.RCM)
    );
  };

  return (
    <OtsDetailsWrapper text="Operation Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Valuation Report"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Valuation Report'}
                    onClick={() => downloadFile(ots?.operationValuationReport)}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Statement of Account"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Statement of Account'}
                    onClick={() => downloadFile(ots?.statementOfAccount)}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Foreclosure Letter"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Foreclosure Letter'}
                    onClick={() => downloadFile(ots?.foreclosureLetter)}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Sanction Letter"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Sanction Letter'}
                    onClick={() => downloadFile(ots?.sanctionLetter)}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
        </Grid>
        <AnimatedInputWrapper show={canShowActionView()} marginTop={'0rem'}>
          <OtsReportActionView reportType={OtsReportType.OPERATION} />
        </AnimatedInputWrapper>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsOperationDetails;
