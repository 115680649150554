import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import {
  UserActions,
  getUAM,
  setUserDetails,
} from '../../../features/UAMSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { VegaUser } from '../../../types/claim';
import { GetUserListApiRequest } from '../../../types/request/user';
import { ScreenOpenType } from '../UAM';
import { UserListColumn } from '../columns/UserListColumn';
import AssignRoleDrawer from './AssignRoleDrawer';
import ViewUserDetailsDialog from './ViewUserDetailsDialog';

const ListOfUsers = () => {
  const { loading, users, totalItems } = useAppSelector(getUAM);
  const dispatch = useAppDispatch();
  const [openViewDetailsModal, setOpenViewDetailsModal] =
    useState<boolean>(false);
  const [openAssignDrawer, setOpenAssignDrawer] = useState<boolean>(false);
  const [openRemoveDrawer, setOpenRemoveDrawer] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const toggleViewDetailsModal = () => {
    setOpenViewDetailsModal(!openViewDetailsModal);
  };
  const toggleAssignRoleDrawer = () => {
    setOpenAssignDrawer(!openAssignDrawer);
  };
  const toggleRemoveRoleDrawer = () => {
    setOpenRemoveDrawer(!openRemoveDrawer);
  };

  const onAction = (user: VegaUser, openType: ScreenOpenType) => {
    if (openType === ScreenOpenType.VIEW) {
      toggleViewDetailsModal();
    } else if (openType === ScreenOpenType.ADD) {
      toggleAssignRoleDrawer();
    } else {
      toggleRemoveRoleDrawer();
    }
    dispatch(setUserDetails(user));
  };

  const getUserList = () => {
    const request = {
      page,
      size,
    } as GetUserListApiRequest;
    if (search.length) {
      request.name = search;
    }
    dispatch(UserActions.fetchUsers(request));
  };

  useEffect(() => {
    if (search.length) {
      const userList = setTimeout(() => {
        getUserList();
      }, 300);
      return () => clearTimeout(userList);
    } else {
      getUserList();
    }
  }, [page, size, search]);

  return (
    <>
      <VegaPageContent>
        <Stack my={'1rem'} direction={'row'} justifyContent={'end'}>
          <LmFilterSection hideFilter search={search} setSearch={setSearch} />
        </Stack>
        <VegaDataGrid
          idColumn="id"
          data={users}
          loading={loading}
          columns={UserListColumn({ onAction })}
          paginationMode="server"
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>

      <ViewUserDetailsDialog
        open={openViewDetailsModal}
        onClose={toggleViewDetailsModal}
      />
      <AssignRoleDrawer
        open={openAssignDrawer}
        onClose={toggleAssignRoleDrawer}
      />
    </>
  );
};

export default ListOfUsers;
