import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { GeoAndNonGeoTaggedBreakupType } from './FieldAppDashboard';

type PropTypes = {
  data: GeoAndNonGeoTaggedBreakupType[];
  loading: boolean;
};

const GeoAndNonGeoBreakupGraph = ({ data, loading }: PropTypes) => {
  const yearSeries = [
    {
      name: 'Geo-Tagged',
      data: data.map(item => item.geoTaggedPtp),
    },
    {
      name: 'Non-Geo-Tagged',
      data: data.map(item => item.nonGeoTaggedPtp),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    xaxis: {
      categories: data.map(item => item.date),
    },
    yaxis: {
      title: {
        text: 'Total PTP Count',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    legend: {
      position: 'top',
    },
  };

  return (
    <GraphWrapper title="">
      {loading ? (
        <LoadingPage height={350} />
      ) : (
        <Stack sx={{ height: '21.875rem' }}>
          <Chart
            options={options}
            series={yearSeries}
            type="bar"
            height={'100%'}
          />
        </Stack>
      )}
    </GraphWrapper>
  );
};

export default GeoAndNonGeoBreakupGraph;
