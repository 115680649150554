import { Grid, Stack } from '@mui/material';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { useAppSelector } from '../../../store';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const AdditionDetails = () => {
  const { loanData, loading } = useAppSelector(getTeleAgent);

  const getDate = () => {
    if (loanData.length && loanData[0].lmsVariables) {
      const lmsVariables = loanData[0]?.lmsVariables;

      return {
        ptpDate: lmsVariables?.nextActionDate
          ? lmsVariables?.nextActionDate
          : 'NA',
        ptpMonth: lmsVariables?.nextActionDate
          ? lmsVariables?.nextActionDate
          : 'NA',
      };
    }
    return {
      ptpDate: 'NA',
      ptpMonth: 'NA',
    };
  };
  return (
    <TeleAgentDetailsCard text="Additional Details" isBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="PTP date"
              value={getDate().ptpDate}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Date of payment" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Probability to pay (Month)"
              value={getDate().ptpMonth}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Probability to pay (PTP Date) "
              value={getDate().ptpDate}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TeleAgentTextWrapper text="Details of legal actions" value="NA" />
          </Grid>
        </Grid>
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default AdditionDetails;
