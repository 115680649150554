import { Stack } from '@mui/system';
import { liveAuctionDetailsState } from '../../features/liveAuctionDetailsSlice';
import { useAppSelector } from '../../store';
import { Possession } from '../../types/auction';
import { useEffect, useState } from 'react';
import { PossessionService } from '../../Apis/PossessionService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaText from '../../components/common/VegaText';
import LoadingPage from '../LoadingPage/LoadingPage';

function PossessionImagePreview() {
  const { setSnackbar } = useSnackbar();
  const { auction } = useAppSelector(liveAuctionDetailsState);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);

  // const getPossession = () => {
  //   if (!auction?.metaData) return;
  //   if (Object.hasOwn(auction.metaData, 'possessionItem')) {
  //     return auction.metaData['possessionItem'] as Possession;
  //   }
  // };

  const getFirstImage = () => {
    if (images.length > 0) return images[selectedImg];
  };

  const getImagesByLoanId = async (loanId: string) => {
    setLoading(true);
    try {
      const images = await PossessionService.fetchImagesByLoanId(loanId);
      setImages(images.imageUrls);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auction) {
      const metaData = auction.metaData['possessionItem'] as Possession;
      getImagesByLoanId(metaData.lan);
    }
  }, [auction]);

  return (
    <div>
      <Stack spacing={'1rem'}>
        {loading ? (
          <LoadingPage height={500} />
        ) : (
          <>
            <div
              style={{
                height: '17rem',
                minHeight: '9rem',
                minWidth: '15rem',
                width: '26rem',
                borderRadius: '0.25rem',
              }}
            >
              <img
                src={getFirstImage()}
                height={'100%'}
                width={'100%'}
                style={{ objectFit: 'cover', borderRadius: '0.25rem' }}
              ></img>
            </div>
            <Stack direction={'row'} spacing="0.5rem">
              {images.length ? (
                images.map((item, index) => {
                  return (
                    <div
                      key={item}
                      style={{
                        height: '4.15rem',
                        width: '6.25rem',
                        borderRadius: '0.25rem',
                      }}
                      onClick={() => setSelectedImg(index)}
                    >
                      <SecondaryImagePreview url={item} />
                    </div>
                  );
                })
              ) : (
                <Stack
                  gap={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <VegaText
                    text={'No Images !!'}
                    fontSize={20}
                    fontColor="black"
                    fontWeight={600}
                  />
                </Stack>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </div>
  );
}

export default PossessionImagePreview;

const SecondaryImagePreview = (data: { url: string }) => {
  return (
    <img
      src={data.url}
      height={'100%'}
      width={'100%'}
      style={{ objectFit: 'cover', borderRadius: '0.25rem', cursor: 'pointer' }}
    ></img>
  );
};
