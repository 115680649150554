/* eslint-disable react/prop-types */
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  fetchErrorFileById,
  fetchpastUploadList,
} from '../../Apis/depositBank';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { PastUploadListProps } from '../../types/depositBank';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaText from '../common/VegaText';
import VegaButton from '../common/VegaButton';

const ListOfPastUploads = () => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [bulkList, setBulkList] = useState<PastUploadListProps[]>([]);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(5);
  const [totalItems, setTotalItems] = useState<number>(0);

  const fetchBulkList = async () => {
    setLoading(true);
    try {
      const response = await fetchpastUploadList({ page, size });
      setBulkList(response.records);
      setTotalItems(response.totalItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'somethink went wrong'),
        'error'
      );
    }
  };
  const fetchError = async id => {
    try {
      const url = await fetchErrorFileById(id.toString());
      window.open(url, '__blank');
    } catch (err) {
      setSnackbar(getErrorMessageFromErrorObj(err), 'error');
    }
  };
  useEffect(() => {
    fetchBulkList();
  }, [page, size]);

  const COLUMN_DEF: GridColumns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      minWidth: 150,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'id',
      headerName: 'Request Id',
      flex: 0.7,
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'date',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const pastUpload = props.row as PastUploadListProps;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMYYYY(pastUpload.createdAt)}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'totalEntries',
      headerName: 'Total Entries',
      flex: 0.7,
      minWidth: 120,
    },
    {
      field: 'successEntries',
      headerName: 'Success',
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'failedEntries',
      headerName: 'Failure',
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'action',
      headerName: 'Error Details',
      flex: 0.7,
      renderCell: props => {
        const { failedEntries, id } = props?.row;
        if (failedEntries) {
          return (
            <VegaButton
              text={'Download'}
              variant="text"
              onClick={() => fetchError(id)}
            />
          );
        }
        return <VegaButton disabled text={'No Errors'} variant="text" />;
      },
    },
  ];

  return (
    <>
      <VegaDataGrid
        bgColor="transpatent"
        data={bulkList}
        columns={COLUMN_DEF}
        idColumn="id"
        paginationMode="server"
        loading={loading}
        rowCount={totalItems}
        page={page}
        pageSize={size}
        onPageChange={page => setPage(page)}
        onPageSizeChange={size => setSize(size)}
      />
    </>
  );
};

export default ListOfPastUploads;
