import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getIncentivesGamificationsState } from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { agentLevelList } from '../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useAppDispatch, useAppSelector } from '../../store';
import VegaDataGrid from '../common/VegaDataGrid';
import ViewAgentLevelColumn from './column/ViewAgentLevelColumn';
import { OverallPayoutTabType } from '../../pages/IncentivesGamifications/OverallPayout/OverallPayout';
interface Props {
  selectedTab: OverallPayoutTabType;
}
const ListOfViewAgentLevel = ({ selectedTab }: Props) => {
  const { policyId } = useParams();
  const dispatch = useAppDispatch();
  const {
    totalItemsForViewAgentLevel,
    loadingForViewAgentLevel,
    viewAgentLevelData,
  } = useAppSelector(getIncentivesGamificationsState);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  function fetchData(page: number, size: number) {
    const request = {
      policyId,
      page: page,
      size: size,
      isPreviousCycle:
        selectedTab === OverallPayoutTabType.Previous_Cycle ? true : false,
    };
    dispatch(agentLevelList(request));
  }

  useEffect(() => {
    fetchData(page, size);
  }, [page, size, selectedTab]);

  useEffect(() => {
    setPage(0);
    setSize(10);
  }, [selectedTab]);

  return (
    <>
      <VegaDataGrid
        data={viewAgentLevelData}
        idColumn="id"
        loading={loadingForViewAgentLevel}
        columns={ViewAgentLevelColumn()}
        paginationMode="server"
        page={page}
        pageSize={size}
        rowCount={totalItemsForViewAgentLevel}
        onPageChange={page => setPage(page)}
        onPageSizeChange={size => setSize(size)}
      />
    </>
  );
};

export default ListOfViewAgentLevel;
