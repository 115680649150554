import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
  TimePeriodEnumForPenal,
  TimePeriodEnumForPenalMonthly,
  TimePeriodEnumForPenalYearly,
} from '../../../../../features/analyticsDashboardSlice';
import {
  RegionPenalMonthlyCollectionResultDto,
  RegionYearlyPenalCollectionResultDto,
} from '../../../../../types/analytics';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useParams } from 'react-router-dom';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { DateUtility } from '../../../../../utils/DateUtlility';
import { getMonthIndex } from '../../../components/RoleBaseSegmentPerformance';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import BarChart from '../../../components/BarChart';
import VegaText from '../../../../../components/common/VegaText';
import { toLowerCase } from '../../../../../constants/commonFunction';

const PenalRegionPerformanceGraph = () => {
  const { calenderType, profitType } = useParams();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );

  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    | RegionPenalMonthlyCollectionResultDto[]
    | RegionYearlyPenalCollectionResultDto[]
  >([]);

  const [loader, setLoader] = useState<boolean>(false);
  const series = [
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'MTD'
          : calenderType === CelenderTypeEmun.YEAR &&
            selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'YTD'
          : 'Target',

      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (calenderType === CelenderTypeEmun.YEAR) {
              const yearValue = value as RegionYearlyPenalCollectionResultDto;
              return parseFloat(yearValue?.ytdCollection?.toFixed(2));
            } else {
              const monthValue = value as RegionPenalMonthlyCollectionResultDto;
              if (selectedTimePeriod === TimePeriodEnumForPenal.MTD)
                return parseFloat(monthValue?.mtdCollection?.toFixed(2));
              else return parseFloat(monthValue?.regionPenalTarget?.toFixed(2));
            }
          })
        : [],
    },
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'LMSD'
          : calenderType === CelenderTypeEmun.YEAR &&
            selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'LYSD'
          : 'Achieved',

      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (calenderType === CelenderTypeEmun.YEAR) {
              const yearValue = value as RegionYearlyPenalCollectionResultDto;
              return parseFloat(yearValue?.lysdCollection?.toFixed(2));
            } else {
              const monthValue = value as RegionPenalMonthlyCollectionResultDto;
              if (selectedTimePeriod === TimePeriodEnumForPenal.MTD)
                return parseFloat(monthValue?.lmsdCollection?.toFixed(2));
              else
                return parseFloat(
                  monthValue?.regionPenalCollection?.toFixed(2)
                );
            }
          })
        : [],
    },
  ];

  const regionCategories = analyticsQueryResponse?.length
    ? analyticsQueryResponse.map(value => value?.region ?? '')
    : [];

  const getTimePeriodOptions = () => {
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      const options = Object.keys(TimePeriodEnumForPenalMonthly).map(item => {
        return { value: item, label: toLowerCase(item) } as VegaSelectOption;
      });
      return options;
    }
    const options = Object.keys(TimePeriodEnumForPenalYearly).map(item => {
      const year = new Date().getFullYear();
      const label = `${toLowerCase(item)} (${year})`;
      return { value: item, label: label } as VegaSelectOption;
    });
    return options;
  };

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');

      setLoader(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);
    if (
      calenderType === CelenderTypeEmun.MONTH ||
      calenderType === CelenderTypeEmun.TODAY
    ) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.ONE_DAY:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
          };
        case TimePeriodEnumForPenal.FIVE_DAY:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              FIVE_DAY?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              FIRST_DAY?.toISOString()
            ),
          };
        case TimePeriodEnumForPenal.MTD:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              new Date(FIRST_DAY)?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
          };

        default:
          return null;
      }
    }
    if (calenderType === CelenderTypeEmun.YEAR) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.QUATER_1:
        case TimePeriodEnumForPenal.QUATER_2:
        case TimePeriodEnumForPenal.QUATER_3:
        case TimePeriodEnumForPenal.QUATER_4: {
          const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
          };
        }
        case TimePeriodEnumForPenal.JANUARY:
        case TimePeriodEnumForPenal.FEBRUARY:
        case TimePeriodEnumForPenal.MARCH:
        case TimePeriodEnumForPenal.APRIL:
        case TimePeriodEnumForPenal.MAY:
        case TimePeriodEnumForPenal.JUNE:
        case TimePeriodEnumForPenal.JULY:
        case TimePeriodEnumForPenal.AUGUST:
        case TimePeriodEnumForPenal.SEPTEMBER:
        case TimePeriodEnumForPenal.OCTOBER:
        case TimePeriodEnumForPenal.NOVEMBER:
        case TimePeriodEnumForPenal.DECEMBER: {
          const monthIndex = getMonthIndex(selectedTimePeriod);
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfMonth(
                CURRENT_YEAR,
                monthIndex
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
            ),
          };
        }
        default:
          return {
            type: profitType,
          };
      }
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      if (timePeriodParams !== null) {
        return {
          ...timePeriodParams,
          type: profitType,
        };
      }
    } else {
      return {
        ...timePeriodParams,
        type: profitType,
      };
    }
  };

  const getQueryType = () => {
    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
      case TimePeriodEnumForPenal.FIVE_DAY:
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4:
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER:
        return AnalyticsQueryTypeEnum.REGION_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.MTD:
        return AnalyticsQueryTypeEnum.REGION_MONTHLY_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.YTD:
        return AnalyticsQueryTypeEnum.REGION_YEARLY_PENAL_COLLECTION;
    }
  };

  const getPenalRegionCollectionQueries = () => {
    const params = getParams();
    const analyticsQueryType = getQueryType();

    const request = {
      analyticsQueryType,
      params,
    } as AnalyticsQueryRequest;
    getQueriesForEnum(request);
  };
  useEffect(() => {
    getPenalRegionCollectionQueries();
  }, [selectedTimePeriod, calenderType, calenderType]);

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper
          title="Region Performance"
          rightComponent={
            <Stack width={150}>
              <VegaSelect
                options={getTimePeriodOptions()}
                value={selectedTimePeriod}
                onChange={select =>
                  setSelectedTimePeriod(select.target.value as string)
                }
                ignoreLodash
              />
            </Stack>
          }
        >
          {loader ? (
            <LoadingPage height={400} />
          ) : (
            <Stack
              sx={{
                p: 3,
                bgcolor: 'white',
                borderRadius: '12px',
                border: BORDER_COLLECTION,
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -25,
                    height: 50,
                    width: '94.4%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                    }}
                  >
                    {analyticsQueryResponse.map((val, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ flex: '1', textAlign: 'center' }}>
                            {getPercentageValue(val)}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <BarChart
                  categories={regionCategories}
                  series={series}
                  yAxisTitle={'Rs. Crore'}
                />
              </Box>
            </Stack>
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default PenalRegionPerformanceGraph;

const getPercentageValue = (
  query:
    | RegionPenalMonthlyCollectionResultDto
    | RegionYearlyPenalCollectionResultDto
) => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  }
};
