import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../components/common/VegaSelect';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import {
  CelenderViewType,
  CollectionProfitType,
} from '../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../types/request/analyticsRequest';
import AnalyticsContainer from './modules/AnalyticsContainer';
// import AnalyticsContainer from './modules/AnalyticsContainer';
// import CollectionsDetails from './CollectionsDetails';

export enum GraphTypeEnum {
  COLLECTION_VALUE = 'collection-value',
  COLLECTION_VOLUME = 'collection-volume',
  COLLECTION_TEAM = 'collection-team',
  TELE_CALLERS = 'tele-callers',
  ACCOUNT_MOVEMENT = 'account-movement',
  RESOLUTION = 'resolution',
  PENAL_COLLECTION = 'penal-collection',
  ROLE_BASE = 'role-base',
}

export interface CollectionCommonProps {
  request: AnalyticsQueryRequest;
}
export interface ProfitTypeProps {
  selectedCollectionProfitType: CollectionProfitType;
}
export interface CalenderTypeProps {
  selectedCalenderViewType: CollectionProfitType;
}

const AnalyticsDashboard = () => {
  const [selectedCalenderViewType, setSelectedCalenderViewType] =
    useState<CelenderViewType>(CelenderViewType.TODAY);
  const [selectedCollectionProfitType, setSelectedCollectionProfitType] =
    useState<CollectionProfitType>(CollectionProfitType.GROSS);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Analytics Dashboard'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ minWidth: 150 }}>
                <VegaSelect
                  options={getOptions(CelenderViewType)}
                  value={selectedCalenderViewType}
                  onChange={selected =>
                    setSelectedCalenderViewType(
                      selected.target.value as CelenderViewType
                    )
                  }
                />
              </Stack>
              <Stack sx={{ minWidth: 150 }}>
                <VegaSelect
                  options={getOptions(CollectionProfitType)}
                  value={selectedCollectionProfitType}
                  onChange={selected =>
                    setSelectedCollectionProfitType(
                      selected.target.value as CollectionProfitType
                    )
                  }
                />
              </Stack>
            </Stack>
          );
        }}
      />
      <AnalyticsContainer
        selectedCalenderViewType={selectedCalenderViewType}
        selectedCollectionProfitType={selectedCollectionProfitType}
      />
    </>
  );
};

export default AnalyticsDashboard;

const getOptions = (
  type: typeof CelenderViewType | typeof CollectionProfitType
) => {
  const options = Object.keys(type).map(
    item => ({ value: item, label: toLowerCase(item) } as VegaSelectOption)
  );
  return options;
};
