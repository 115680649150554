import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../Apis/IncentivesService';
import ViewBucketComponent from '../../components/IncentivesGamifications/modules/ViewBucketComponent';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import {
  getIncentivesGamificationsState,
  setTemplatesRules,
  setWriteOffTemplatesRules,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  BucketEnum,
  BucketEnumForNonNPA,
  ClassTypeEnum,
} from '../../types/targets';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LoadingPage from '../LoadingPage/LoadingPage';
import { toLowerCase } from '../../constants/commonFunction';
import ViewWriteOffComponent from '../../components/IncentivesGamifications/modules/ViewWriteOffComponent';
import {
  BasePolicyRangeProps,
  IncentiveWriteOffRulesRequest,
} from '../../types/request/incentives';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import ViewBaseIncentiveForChargeCollected from '../../components/IncentivesGamifications/components/ViewBaseIncentiveForChargeCollected';

const ViewTemplates = () => {
  const { templateId } = useParams();
  const dispatch = useAppDispatch();
  const { templatesRules, writeOffTemplatesRules } = useAppSelector(
    getIncentivesGamificationsState
  );

  const { setSnackbar } = useSnackbar();
  const [selectedBucket, setSelectedBucket] = useState<string>('');
  const [selectedWriteOffType, setSelectedWriteOffType] = useState<string>('');
  const [templeteType, setTempleteType] = useState<string>('');
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [chargeCollecedTemplate, setChargeCollecedTemplate] = useState<
    BasePolicyRangeProps[]
  >([]);

  const getTemplateByTemplateId = () => {
    setTemplateLoading(true);
    IncentiveServices.getTemplate(templateId)
      .then(res => {
        const type = res?.type;
        if (type) {
          setTempleteType(type);
          if (type === ClassTypeEnum.WRITE_OFF) {
            const rules = res?.writeOffRules;
            if (rules && Object.keys(rules).length) {
              const firstKey = Object.keys(rules)[0];
              setSelectedWriteOffType(firstKey);
              dispatch(setWriteOffTemplatesRules(rules));
            }
          } else {
            const rules = res?.rules;
            const chargeCollectedRules = res?.chargeCollectedRules ?? [];

            setChargeCollecedTemplate(chargeCollectedRules);
            if (rules && Object.keys(rules).length) {
              const firstKey = Object.keys(rules)[0];
              setSelectedBucket(firstKey);
              dispatch(setTemplatesRules(rules));
            }
          }
        } else {
          const rules = res?.rules;
          const chargeCollectedRules = res?.chargeCollectedRules ?? [];
          setChargeCollecedTemplate(chargeCollectedRules);
          if (rules && Object.keys(rules).length) {
            const firstKey = Object.keys(rules)[0];
            setSelectedBucket(firstKey);
            dispatch(setTemplatesRules(rules));
          }
        }

        setTemplateLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setTemplateLoading(false);
      });
  };
  useEffect(() => {
    templateId && getTemplateByTemplateId();
  }, [templateId]);

  const getComponent = () => {
    return (
      <>
        <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
          <Stack direction={'row'} gap={2}>
            {templatesRules && Object.keys(templatesRules).length
              ? Object.keys(templatesRules).map((bucket: string) => (
                  <Box
                    key={bucket}
                    sx={{
                      px: 1,
                      pb: 1,
                      cursor: 'pointer',
                      borderBottom:
                        selectedBucket === bucket
                          ? `1px solid #1047DC`
                          : '1px solid transparant',
                    }}
                    onClick={() => setSelectedBucket(bucket)}
                  >
                    <VegaText
                      color={selectedBucket === bucket ? '#1047DC' : '#676B76'}
                      fontSize={12}
                      fontWeight={500}
                      text={getBucketLabel(bucket)}
                    />
                  </Box>
                ))
              : ''}
            {chargeCollecedTemplate && chargeCollecedTemplate?.length ? (
              <Box
                sx={{
                  px: 1,
                  pb: 1,
                  cursor: 'pointer',
                  borderBottom:
                    selectedBucket === BucketEnumForNonNPA.CHARGES_COLLECTION
                      ? `1px solid #1047DC`
                      : '1px solid transparant',
                }}
                onClick={() =>
                  setSelectedBucket(BucketEnumForNonNPA.CHARGES_COLLECTION)
                }
              >
                <VegaText
                  color={
                    selectedBucket === BucketEnumForNonNPA.CHARGES_COLLECTION
                      ? '#1047DC'
                      : '#676B76'
                  }
                  fontSize={12}
                  fontWeight={500}
                  text={toLowerCase(BucketEnumForNonNPA.CHARGES_COLLECTION)}
                />
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </VegaPageContent>
        {}
        {selectedBucket === BucketEnumForNonNPA.CHARGES_COLLECTION ? (
          <>
            <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
              <VegaTitleCard title="Base Incentive">
                <Stack gap={2}>
                  <ViewBaseIncentiveForChargeCollected
                    rules={chargeCollecedTemplate}
                  />
                </Stack>
              </VegaTitleCard>
            </VegaPageContent>
          </>
        ) : (
          templatesRules &&
          templatesRules[selectedBucket] && (
            <ViewBucketComponent rules={templatesRules[selectedBucket]} />
          )
        )}
      </>
    );
  };
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: `Template`,
                  link: -1,
                },
                {
                  label: `View template`,
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <Box
        height={`calc(100% - 77px)`}
        sx={{
          overflow: 'auto',
        }}
      >
        {templateLoading ? (
          <LoadingPage height={500} />
        ) : (
          <>
            {templeteType.length ? (
              <>
                {templeteType === ClassTypeEnum.WRITE_OFF ? (
                  <>
                    {writeOffTemplatesRules &&
                    Object.keys(writeOffTemplatesRules).length ? (
                      <VegaPageContent
                        sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}
                      >
                        <Stack direction={'row'} gap={2}>
                          {Object.keys(writeOffTemplatesRules).map(
                            (bucket: string) => (
                              <Box
                                key={bucket}
                                sx={{
                                  px: 1,
                                  pb: 1,
                                  cursor: 'pointer',
                                  borderBottom:
                                    selectedWriteOffType === bucket
                                      ? `1px solid #1047DC`
                                      : '1px solid transparant',
                                }}
                                onClick={() => setSelectedWriteOffType(bucket)}
                              >
                                <VegaText
                                  color={
                                    selectedWriteOffType === bucket
                                      ? '#1047DC'
                                      : '#676B76'
                                  }
                                  fontSize={12}
                                  fontWeight={500}
                                  text={toLowerCase(bucket)}
                                />
                              </Box>
                            )
                          )}
                        </Stack>
                      </VegaPageContent>
                    ) : (
                      ''
                    )}
                    {writeOffTemplatesRules &&
                      writeOffTemplatesRules[selectedWriteOffType] && (
                        <ViewWriteOffComponent
                          rules={
                            writeOffTemplatesRules[
                              selectedWriteOffType
                            ] as IncentiveWriteOffRulesRequest
                          }
                        />
                      )}
                  </>
                ) : (
                  getComponent()
                )}
              </>
            ) : (
              getComponent()
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ViewTemplates;

const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketEnum.BUCKET1:
      return 'Bucket 1-30';
    case BucketEnum.BUCKET2:
      return 'Bucket 31-60';
    case BucketEnum.BUCKET3:
      return 'Bucket 61-90';
    case BucketEnum.NPA:
      return 'Bucket NPA';
    case 'CHARGES_COLLECTION':
      return 'Charges Collection';

    default:
      return 'Bucket X';
  }
};
