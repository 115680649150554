import { Grid } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import AgentSelector from '../../../components/AgentSelector';
import LmButton from '../../../components/common/LmButton';
import LmDateField from '../../../components/common/LmDateField';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { VegaFileSelectOption } from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { DateUtility } from '../../../utils/DateUtlility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<ReallocateLoanAccountsFormData>) => void;
};

export type ReallocateLoanAccountsFormData = {
  agentId: string;
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}
const TODAY = new Date();
function ReallocateLoanAccountsDrawer({ open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<
    Partial<ReallocateLoanAccountsFormData>
  >({
    expiryDate: TODAY.toISOString(),
  });

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }
  function onSumbitClick() {
    onSubmit(formData);
    onClose();
  }

  function onHandleClose() {
    onClose();
    setFormData({});
  }

  const isInputValid = () => {
    const isAgentIdValid = (formData.agentId ?? '').length > 0;
    const isAllocationTypeValid = (formData.allocationType ?? '').length > 0;
    return isAgentIdValid && isAllocationTypeValid;
  };

  function onAllocationTypeChange(value: string) {
    updateFormData(InputType.AllocationType, value);
  }
  function updateExpiryDate(value: any) {
    updateFormData(
      InputType.ExpiryDate,
      DateUtility.parseDateFromDatePicker(value)
    );
  }

  return (
    <VegaDrawer open={open} title={'ReAllocate'} onClose={onHandleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Submit"
              onClick={onSumbitClick}
              disabled={isInputValid() == false}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent'}>
              <AgentSelector
                selected={formData?.agentId}
                onSelected={selected =>
                  updateFormData(InputType.AgentId, selected)
                }
                onClear={() => updateFormData(InputType.AgentId, '')}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData?.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value as string);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              header="Expiry Date"
              minDate={TODAY}
              value={formData?.expiryDate}
              onChange={e => {
                updateExpiryDate(e);
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ReallocateLoanAccountsDrawer;
enum AllocationType {
  FIELD = 'FIELD',
  TELE = 'TELE',
}
const allocationTypeOptions = () =>
  [AllocationType.TELE, AllocationType.FIELD].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
