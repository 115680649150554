import { createContext, useContext, useEffect, useState } from 'react';
import { TeleAgentService } from '../Apis/TeleAgentServices';
import { TeleAgentListProps } from '../types/teleAgent';
import { useClientAuth } from './ClientProvider';
import { collection, Firestore, getDocs, onSnapshot } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { firebaseApp } from '../firebaseConfig';
import { useSnackbar } from './SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { RoleName } from '../types/roleType';

export enum TeleAgentCallStatus {
  STARTED = 'STARTED',
  ENDED = 'ENDED',
}

export type TeleAgentCallEvent = {
  status: string;
  loanId: string;
};

type TeleAgentCallEventsContextType = {
  callEvent: TeleAgentCallEvent;
};

const TELE_ROLES: string[] = [
  RoleName.TELE_COLLECTION_EXECUTIVE,
  RoleName.TELE_COLLECTION_MANAGER,
  RoleName.TELE_COLLECTION_SENIOR_EXECUTIVE,
  RoleName.TELE_COORDINATOR,
];
const TeleAgentCallEventsContext =
  createContext<TeleAgentCallEventsContextType | null>(null);

export const useTeleAgentCallEvents = () =>
  useContext(TeleAgentCallEventsContext) as TeleAgentCallEventsContextType;

export const TeleAgentCallEventsProvider = ({ children }: any) => {
  const { userId, roles } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [agentDetails, setAgentDetails] = useState<TeleAgentListProps | null>(
    null
  );
  const [callEvent, setCallEvent] = useState<TeleAgentCallEvent>();
  const firestore: Firestore = getFirestore(firebaseApp);

  async function fetchCallDetails(userName: string) {
    const querySnapshot = await getDocs(
      collection(firestore, 'tele-agent-call')
    );
    const agentReferences = querySnapshot.docs.filter(
      item => item.id == userName
    );
    if (agentReferences.length <= 0) return;
    const currentAgentRef = agentReferences[0];
    onSnapshot(currentAgentRef.ref, snapshot => {
      const loanId = snapshot.get('loanId') as string;
      const callStatus = snapshot.get('callStatus') as string;

      setCallEvent({ loanId: loanId, status: callStatus });
    });
  }

  async function getTeleAgentByuserId(userId: string) {
    setAgentDetails(null);
    await TeleAgentService.getTeleAgentList({
      userId: userId,
    })
      .then(res => {
        const agent = res.records[0];
        setAgentDetails(agent);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  }

  const hasTeleRole = () => {
    if (roles.length <= 0) return false;
    const teleRoles = roles.filter(i => {
      const roleDto = i.roleDto;
      return TELE_ROLES.includes(roleDto.name);
    });
    return teleRoles.length > 0;
  };

  useEffect(() => {
    if (!userId) return;
    if (hasTeleRole() == false) return;
    getTeleAgentByuserId(userId);
  }, [userId, roles]);

  useEffect(() => {
    const userName = agentDetails?.username;
    if (!userName) return;
    fetchCallDetails(userName);
  }, [agentDetails]);

  return (
    <TeleAgentCallEventsContext.Provider value={{ callEvent: callEvent }}>
      {children}
    </TeleAgentCallEventsContext.Provider>
  );
};
