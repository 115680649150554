import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ConfigurationDto } from '../../../../types/configurationType';
import RegionSelector from '../../../MinVisits/modules/RegionSelector';

const RegionSelect = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const onRegionSelect = (region: string) => {
    const preConfigSubtype = [...configurations.subtypes];
    if (preConfigSubtype.includes(region)) {
      const filterBranch = preConfigSubtype.filter(
        (selectedUser: string) => selectedUser !== region
      );
      handleConfigChange('subtypes', filterBranch);
    } else {
      handleConfigChange('subtypes', [...preConfigSubtype, region]);
    }
  };
  const onRegionDelete = (index: number) => {
    const preConfigSubtype = [...configurations.subtypes];
    preConfigSubtype.splice(index, 1);
    handleConfigChange('subtypes', preConfigSubtype);
  };
  return (
    <>
      <VegaFormInputField label={'select Region'}>
        <RegionSelector
          isMultiSelect
          selected={configurations.subtypes ?? []}
          handleChange={selected => onRegionSelect(selected)}
          handleDelete={index => onRegionDelete(index)}
        />
      </VegaFormInputField>
    </>
  );
};

export default RegionSelect;
