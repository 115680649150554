/* eslint-disable no-unused-vars */
import { AddCircleOutline, VisibilityOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmButton from '../../components/common/LmButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaText from '../../components/common/VegaText';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';
import CreateClaimForm, {
  CreateClaimFormData,
} from '../../components/ExpenseClaim/CreateClaimForm';
import FilterInput, {
  DateRangeFilter,
  FilterOption,
} from '../../components/ExpenseClaim/FilterInput';
import { PRIMARY } from '../../constants/style';
import { useDrawer } from '../../hooks/useDrawer';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES, SUB_ROUTES } from '../../router/routes';
import {
  ExpenseReportViewModel,
  ExpenseClaimAction,
  getPillColorForStatus,
} from '../../types/claim';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import { useClientAuth } from '../../providers/ClientProvider';
import VegaIconButton from '../../components/common/VegaIconButton';

function SubmittedExpenseReport() {
  const { userId } = useClientAuth();
  const [openAddExpenseDrawer, setOpenAddExpenseDrawer] =
    useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { createClaim, fetchExpenseReports } = useExpenseApi();
  const [reports, setReports] = useState<ExpenseReportViewModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  async function onCreateClaimClick(formData: Partial<CreateClaimFormData>) {
    try {
      setLoading(true);
      setOpenAddExpenseDrawer(false);

      const files: File[] = [];

      formData.documents?.forEach(item => {
        if (item.value) {
          files.push(item.value);
        }
      });

      const response = await createClaim({
        userId: userId,
        dateOfExpense: formData.date?.toISOString(),
        amount: formData.amount,
        expenseType: formData.expenseType,
        documents: files,
        description: formData.description,
        vehicleType: formData.vehicleType,
      });

      fetchReports();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchReports() {
    try {
      setLoading(true);
      const statusFilter = `${ExpenseClaimAction.PENDING},${ExpenseClaimAction.REJECTED},${ExpenseClaimAction.ACCEPTED}`;
      const response = await fetchExpenseReports(
        {
          status: statusFilter,
          startDateRange: dateFilter.startDate,
          endDateRange: dateFilter.endDate,
        },
        { includeApproverInReport: true }
      );
      setReports(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onClearAllClick() {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter([]);
    closeFilterDrawer();
  }

  function onSelectFilterClick(
    statusFilter: string[],
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
  }

  useEffect(() => {
    fetchReports();
  }, [dateFilter, dayFilter, statusFilter]);

  return (
    <div>
      <VegaPageHeader
        renderRightView={() => (
          <LmButton
            text="Add Expense"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setOpenAddExpenseDrawer(true);
            }}
          />
        )}
      />
      <Box p={3}>
        <Stack
          direction={'row'}
          alignItems="end"
          justifyContent={'space-between'}
        >
          <VegaText
            text="Submitted Expense Reports"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        </Stack>
        <Box pt={3}>
          <VegaDataGrid
            data={reports}
            loading={loading}
            columns={getColumnDefinition({
              onViewDetailsClick: report => {
                navigate(
                  SUB_ROUTES.EXPENSE_REPORT_HISTORY_BY_ID.replace(
                    ':reportId',
                    report.id
                  ),
                  {
                    state: { isAdmin: false },
                  }
                );
              },
            })}
            idColumn="id"
            rowCount={rowCount}
            page={page}
            pageSize={10}
          />
        </Box>
        <VegaDrawer
          open={openAddExpenseDrawer}
          title={'Add Expense'}
          onClose={function (): void {
            setOpenAddExpenseDrawer(false);
          }}
        >
          <CreateClaimForm onCreateClick={onCreateClaimClick} />
        </VegaDrawer>
        <VegaDrawer
          open={isFilterDrawerOpen}
          title={'Filter By'}
          onClose={function (): void {
            closeFilterDrawer();
          }}
        >
          <FilterInput
            onClearAllClick={onClearAllClick}
            onSelectFilterClick={onSelectFilterClick}
            value={{
              dayFilter: dayFilter,
              statusFilter: statusFilter,
              dateRangeFilter: dateFilter,
            }}
            allowedFilters={[FilterOption.DayFilter]}
          />
        </VegaDrawer>
      </Box>
    </div>
  );
}

export default SubmittedExpenseReport;

const getReportForRow = (props: any) => props.row as ExpenseReportViewModel;

const getColumnDefinition = (data: {
  onViewDetailsClick: (report: ExpenseReportViewModel) => void;
}) => {
  const AGENT_SUBMITTED_COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Date Of Report',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const parsedDate = DateUtility.parseStringToDate(report.dateOfExpense);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'approverName',
      headerName: 'Approver Name',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const approver = report.approver;
        const displayText = approver;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimsApproved',
      headerName: 'Claims Approved',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.filter(
          item => item.status == ExpenseClaimAction.ACCEPTED
        ).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimsRejected',
      headerName: 'Claims Rejected',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.filter(
          item => item.status == ExpenseClaimAction.REJECTED
        ).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimsPending',
      headerName: 'Claims Pending',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.filter(
          item => item.status == ExpenseClaimAction.PENDING
        ).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalExpense',
      headerName: 'Total Expense',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        var totalExpense = 0;
        report.claims.forEach(item => (totalExpense += item.amount));
        const displayText = StringUtility.formatToINR(totalExpense);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.status;
        const color = getPillColorForStatus(report.status);
        return (
          <VegaPill
            text={displayText}
            backgroundColor={color?.LIGHT}
            borderColor={color?.DARK}
            textColor={color?.DARK}
          />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const report = getReportForRow(props);
        return (
          // <LmButton
          //   text="View Details"
          //   variant="text"
          //   textColor={PRIMARY.darkBlue}
          //   fontWeight={500}
          //   sx={{ px: 0.5 }}
          //   fontSize={'0.875rem'}
          //   bgColor="transparant"
          //   onClick={() => {
          //     data.onViewDetailsClick(report);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(report);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return AGENT_SUBMITTED_COLUMN_DEF;
};
