import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AgentLeaderboardResponseDto,
  LeaderFilterProps,
} from '../../types/incentiveLeaderType';
import { leaderboardIncentivesList } from './incentivesAsyncThunk';

export enum AgentType {
  TELE_AGENT = 'TELE_AGENT',
  FIELD_AGENT = 'FIELD_AGENT',
}

export enum TypeForfieldAgent {
  HIGHEST_EARNERS = 'HIGHEST_EARNERS',
  NORMALIZED_ROLLBACK_STARS = 'NORMALIZED_ROLLBACK_STARS',
  MILESTONE_BASED_INCENTIVE_ACHIEVERS = 'MILESTONE_BASED_INCENTIVE_ACHIEVERS',
  PENAL_COLLECTION = 'PENAL_COLLECTION',
}
export enum TypeForTeleAgent {
  HIGHEST_EARNERS = 'HIGHEST_EARNERS',
  PENAL_COLLECTION = 'PENAL_COLLECTION',
  HIGHEST_IMPROVEMENT_SHOWN = 'HIGHEST_IMPROVEMENT_SHOWN',
}
export enum MonthSelection {
  CURRENT = 'CURRENT_MONTH',
  PREVIOUS = 'PREVIOUS_MONTH',
}

export const initialLeaderFilterState = {
  agentType: AgentType.TELE_AGENT,
  resultsType: '',
} as LeaderFilterProps;

export interface IncentiveLeaderboardSliceProps {
  leaderFilterState: LeaderFilterProps;
  showLeaders: boolean;
  loading: boolean;
  totalItems: number;
  error: '';
  leaderData: AgentLeaderboardResponseDto[];
}
4;

const initialState: IncentiveLeaderboardSliceProps = {
  leaderFilterState: initialLeaderFilterState,
  showLeaders: false,
  loading: false,
  error: '',
  totalItems: 0,
  leaderData: [],
};

const incentiveLeaderboardSlice = createSlice({
  name: 'incentiveLeaderboard',
  initialState,
  reducers: {
    setLeaderFilterState: (state: IncentiveLeaderboardSliceProps, action) => {
      const { payload } = action;
      state.leaderFilterState = payload;
    },
    setShowLeaders: (state: IncentiveLeaderboardSliceProps, action) => {
      const { payload } = action;
      state.showLeaders = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(leaderboardIncentivesList.pending, state => {
        state.loading = true;
        state.showLeaders = false;
        state.leaderData = [];
        state.error = null;
      })
      .addCase(leaderboardIncentivesList.fulfilled, (state, action) => {
        const leaderData = action.payload;
        const leaderRecords = leaderData.records?.length
          ? leaderData.records
          : [];
        const formateRecords = leaderRecords.map((data, index) => ({
          ...data,
          index,
        }));
        state.loading = false;
        state.showLeaders = true;
        state.leaderData = formateRecords;
        state.totalItems = leaderData.totalItems;
      })
      .addCase(leaderboardIncentivesList.rejected, state => {
        state.loading = false;
        state.showLeaders = false;
        state.leaderData = [];
        state.error = '';
      });
  },
});
export const { setLeaderFilterState, setShowLeaders } =
  incentiveLeaderboardSlice.actions;
export const getLeaderboardIncentiveState = (state: RootState) =>
  state.incentiveLeaderboard;
export default incentiveLeaderboardSlice;
