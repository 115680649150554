import React, { useEffect, useState } from 'react';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaPageContent from '../common/VegaPageContent';
import VegaDataGrid from '../common/VegaDataGrid';
import { GeoVerificationColumn } from './Columns/GeoVerificationColumn';
import {
  GeoTabType,
  GeoVarificationActionEnum,
} from '../../pages/GeoVarification/GeoVarification';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getGeoVerificationState,
  getGeoVerifiedList,
  setGeoVerifiedData,
} from '../../features/geoVerificationSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { GeoVerificationResponse } from '../../types/geoVerificationType';
import VegaApproveDialog from '../common/VegaApproveDialog';
import VegaText from '../common/VegaText';
import { GeoVerificationService } from '../../Apis/GeoVerificationService';
import {
  GetGeoVerifiedListRequest,
  UpdateStatusGeoVerifiedRequest,
} from '../../types/request/geoVerificationRequest';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaRejectDialog from '../common/VegaRejectDialog';

interface IProps {
  selectedTab: number;
}

const ListOfGeoVerification = ({ selectedTab }: IProps) => {
  const { geoVerifications, loading, totalItems, geoVerifiedData } =
    useAppSelector(getGeoVerificationState);
  const dispatch = useAppDispatch();
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [openApproveGeoDialog, setOpenApproveGeoDialog] =
    useState<boolean>(false);
  const [openRejectGeoDialog, setOpenRejectGeoDialog] =
    useState<boolean>(false);

  const toggleApprove = () => {
    setOpenApproveGeoDialog(!openApproveGeoDialog);
  };
  const toggleReject = () => {
    setOpenRejectGeoDialog(!openRejectGeoDialog);
  };
  const onAction = (
    geo: GeoVerificationResponse,
    action: GeoVarificationActionEnum
  ) => {
    if (action === GeoVarificationActionEnum.APPROVED) {
      toggleApprove();
    } else {
      toggleReject();
    }
    dispatch(setGeoVerifiedData(geo));
  };

  const onUpdateStatus = async (
    status: string,
    onClose: () => void,
    approverRemark: string
  ) => {
    try {
      const request = {
        requestId: geoVerifiedData.id,
        approverId: user?.id,
        status,
      } as UpdateStatusGeoVerifiedRequest;
      if (approverRemark && approverRemark.length) {
        request.approverRemark = approverRemark;
      }
      await GeoVerificationService.updateStatusGeoVerified(request);
      setSnackbar('Update status successfully !!!');
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      onClose();
    }
  };

  useEffect(() => {
    if (user) {
      const request = {
        approverId: user.id,
        page,
        size,
      } as GetGeoVerifiedListRequest;
      if (selectedTab === 0) {
        request.listOfStatus = [GeoVarificationActionEnum.RAISED];
      }
      if (selectedTab === 1) {
        request.listOfStatus = [
          GeoVarificationActionEnum.APPROVED,
          GeoVarificationActionEnum.REJECTED,
        ];
      }
      dispatch(getGeoVerifiedList(request));
    }
  }, [page, size, user, selectedTab]);

  return (
    <>
      <VegaPageHeader
        title={getTitleFromTab(selectedTab)}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={geoVerifications}
          loading={loading}
          columns={GeoVerificationColumn({ selectedTab, onAction })}
          idColumn="id"
          paginationMode="server"
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
      <VegaApproveDialog
        header="Are you sure ?"
        buttonText="Approve"
        open={openApproveGeoDialog}
        onClose={toggleApprove}
        onApprove={() =>
          onUpdateStatus(GeoVarificationActionEnum.APPROVED, toggleApprove, '')
        }
      >
        <VegaText
          text={`You are approving the Request of Geo Varification ID is ${geoVerifiedData.id}  `}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaRejectDialog
        text="Reject Geo Verification?"
        open={openRejectGeoDialog}
        onClose={toggleReject}
        onReject={(reason: string) =>
          onUpdateStatus(
            GeoVarificationActionEnum.REJECTED,
            toggleReject,
            reason
          )
        }
      />
    </>
  );
};

export default ListOfGeoVerification;
const getTitleFromTab = (tab: number) => {
  const value = Object.values(GeoTabType)[tab];
  return value;
};
