import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { toLowerCase } from '../../constants/commonFunction';
import { PRIMARY } from '../../constants/style';
import {
  PaymentNewDto,
  PaymentType,
} from '../../features/performanceDashboardSlice';
import { useDrawer } from '../../hooks/useDrawer';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaIconButton from '../common/VegaIconButton';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import ListOfPaymentPerformanceDetailDialog from './ListOfPaymentPerformanceDetailDialog';

interface IProps {
  loading: boolean;
  data: PaymentNewDto;
}

interface ColumnProps {
  onViewDetailClick: (loanStatus: string, loanIdList: string[]) => void;
}

const ListOfPaymentPerformance = ({ data, loading }: IProps) => {
  const {
    open: openViewDetailDialog,
    close: closeViewDetailDialog,
    isOpen: isViewDetailDialogOpen,
    props: viewDetailsDialogProps,
  } = useDrawer();

  return (
    <VegaPageContent my={'1rem'}>
      <Stack
        sx={{
          p: 2,
          bgcolor: 'white',
        }}
      >
        <Stack mb={'1rem'}>
          <VegaText
            text={'Payment Performance'}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
        <VegaDataGrid
          idColumn="id"
          columns={getColumnDefinition({
            onViewDetailClick: (loanStatus, loanIdList) => {
              openViewDetailDialog({ loanStatus, loanList: loanIdList });
            },
          })}
          data={transformPaymentsToArray(data)}
          loading={loading}
          pageSize={10}
          paginationMode="client"
          bgColor="transparent"
        />
      </Stack>
      <ListOfPaymentPerformanceDetailDialog
        closeListOfPaymentPerformance={closeViewDetailDialog}
        isListOfPaymentPerformanceOpen={isViewDetailDialogOpen}
        loanStatus={viewDetailsDialogProps.loanStatus}
        loanIdList={viewDetailsDialogProps.loanList}
      />
    </VegaPageContent>
  );
};

export default ListOfPaymentPerformance;

const getColumnDefinition = ({ onViewDetailClick }: ColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'id',
      headerName: 'Name',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const value = props.row;

        const displayText = value?.data?.name ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={toLowerCase(displayText)}
          />
        );
      },
    },
    {
      field: '1 to 30',
      headerName: '1 to 30',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const value = props.row;

        const displayText = value?.data?.oneToThirty ?? '0';
        // const findValue = bucketType.find(
        //   (value: PaymentType) => Object.keys(value)[0] === '1 to 30'
        // );
        // const value = findValue ? findValue['1 to 30'] : 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={displayText}
          />
        );
      },
    },
    {
      field: '31 to 60',
      headerName: '31 to 60',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const displayText = props?.row?.data?.thirtyOneToSixty ?? '0';
        // const bucketType = props.row.bucketType as PaymentType[];
        // const findValue = bucketType.find(
        //   (value: PaymentType) => Object.keys(value)[0] === '31 to 60'
        // );
        // const value = findValue ? findValue['31 to 60'] : 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={displayText}
          />
        );
      },
    },
    {
      field: '61 to 90',
      headerName: '61 to 90',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row;
        const displayText = bucketType?.data?.sixtyOneToNinety ?? '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={displayText}
          />
        );
      },
    },
    {
      field: 'NPA_240_MINUS',
      headerName: 'NPA < 240',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row;
        const displayText = bucketType?.data?.npaMinus ?? '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={displayText}
          />
        );
      },
    },
    {
      field: 'NPA_240_PLUS',
      headerName: 'NPA >= 240',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row;
        const displayText = bucketType?.data?.npaPlus ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={displayText}
          />
        );
      },
    },

    {
      field: 'Total',
      headerName: 'Total',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row;
        const displayText = bucketType?.data?.total ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={displayText}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Details',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const bucketType = props.row;
        const name = bucketType?.data?.name ?? '--';
        const loanList = bucketType?.data?.loanList ?? [];
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => {
                onViewDetailClick(name, loanList);
              }}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
export function transformPaymentsToArray(payments: PaymentNewDto) {
  return [
    { id: 'STABILISED', data: { ...payments.stabilised } || {} },
    { id: 'ROLL_FORWARD', data: { ...payments.rollForward } || {} },
    { id: 'ROLL_BACK', data: { ...payments.rollBackData } || {} },
    { id: 'NORMALISED', data: { ...payments.normalizedData } || {} },
  ];
}
