/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import { useState } from 'react';
import { DateUtility } from '../../utils/DateUtlility';
import LmButton from '../common/LmButton';
import LmTextField from '../common/LmTextField';
import VegaDateTimePicker from '../common/VegaDateTimePicker';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import { StringUtility } from '../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<CreateBidderFormData>) => void;
};

export type CreateBidderFormData = {
  userName: string;
  email: string;
  startDate: string;
  endDate: string;
  mobile: string;
};

enum FormField {
  Username = 'userName',
  Email = 'email',
  StartDate = 'startDate',
  StartTime = 'startTime',
  EndDate = 'endDate',
  EndTime = 'endTime',
  Mobile = 'mobile',
}

const TODAY = new Date();
function CreateBidderDrawer({ open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<Partial<CreateBidderFormData>>({
    startDate: TODAY.toISOString(),
    endDate: TODAY.toISOString(),
  });

  function handleClose() {
    onClose();
    cleanup();
  }

  function updateFormData(field: FormField, value: string) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  const isInputValid = () => {
    const isNameValid = (formData.userName ?? '').length > 0;
    const isEmailValid = StringUtility.validateEmail(formData.email);
    const isMobileValid = StringUtility.validatePhoneNumber(
      formData.mobile ?? ''
    );
    const isStartDateValid =
      DateUtility.parseStringToDate(formData.startDate) != null;
    const isEndDateValid =
      DateUtility.parseStringToDate(formData.endDate) != null;
    return (
      isNameValid &&
      isEmailValid &&
      isStartDateValid &&
      isEndDateValid &&
      isMobileValid
    );
  };

  // function onStartDateChange(e: any) {
  //   const parsedDate = DateUtility.parseDateFromDatePicker(e);
  //   let endDate = DateUtility.parseStringToDate(formData.endDate);
  //   if (endDate && parsedDate) {
  //     if (DateUtility.isBefore(endDate, parsedDate)) {
  //       endDate = DateUtility.addDays(parsedDate, 0);
  //     }
  //   }

  //   setFormData(prev => {
  //     return {
  //       ...prev,
  //       startDate: parsedDate?.toISOString(),
  //       endDate: endDate?.toISOString(),
  //     };
  //   });
  // }
  function onStartDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);

    let endDate = DateUtility.parseStringToDate(formData.endDate);

    if (endDate && DateUtility.isBefore(endDate, parsedDate)) {
      endDate = parsedDate;
    }

    setFormData(prev => ({
      ...prev,
      startDate: parsedDate.toISOString(),
      endDate: endDate?.toISOString(),
    }));
  }

  // function onEndDateChange(e: any) {
  //   const parsedDate = DateUtility.parseDateFromDatePicker(e);
  //   setFormData(prev => {
  //     return {
  //       ...prev,
  //       endDate: parsedDate?.toISOString(),
  //     };
  //   });
  // }

  function onEndDateChange(e: any) {
    let parsedDate = DateUtility.parseDateFromDatePicker(e);

    const startDate = DateUtility.parseStringToDate(formData.startDate);

    if (startDate && DateUtility.isBefore(parsedDate, startDate)) {
      parsedDate = startDate;
    }

    setFormData(prev => ({
      ...prev,
      endDate: parsedDate.toISOString(),
    }));
  }

  function onCreateClick() {
    const transformedFormData: Partial<CreateBidderFormData> = {
      ...formData,
      startDate: DateUtility.convertToISTDateString(formData.startDate),
      endDate: DateUtility.convertToISTDateString(formData.endDate),
    };

    onSubmit(transformedFormData);
    handleClose();
  }

  function cleanup() {
    setFormData({
      startDate: TODAY.toISOString(),
      endDate: TODAY.toISOString(),
    });
  }

  return (
    <VegaDrawer open={open} title={'Add Bidder'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text={'Create Bidder'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'User Name'}>
              <LmTextField
                value={formData.userName}
                onChange={e =>
                  updateFormData(FormField.Username, e.target.value)
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Email'}>
              <LmTextField
                value={formData.email}
                onChange={e => updateFormData(FormField.Email, e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Mobile'}>
              <LmTextField
                value={formData.mobile}
                type="number"
                onChange={e => updateFormData(FormField.Mobile, e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Enter Validity Period'}>
              <Grid container columnGap={'1rem'} rowGap="1.5rem" columns={12}>
                <Grid item xs={12}>
                  <VegaFormInputField label={'Start Date'}>
                    <VegaDateTimePicker
                      onChange={onStartDateChange}
                      value={formData.startDate}
                      minDate={TODAY}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12}>
                  <VegaFormInputField label={'End Date'}>
                    <VegaDateTimePicker
                      onChange={onEndDateChange}
                      value={formData.endDate}
                      minDate={formData.startDate}
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default CreateBidderDrawer;
