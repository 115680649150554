import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OtsService } from '../../Apis/OtsService';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { VegaUser } from '../../types/claim';
import {
  getOtsStatusDescription,
  getValueFromRecord,
  Ots,
  OtsMetaDataKey,
} from '../../types/ots';
import {
  PaginatedResponse,
  getErrorMessageFromErrorObj,
} from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../components/common/VegaIconButton';
import { PRIMARY } from '../../constants/style';

function OtsHomePage() {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [otsData, setOtsData] = useState<PaginatedResponse<Ots>>({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });

  async function fetchData() {
    try {
      setLoading(true);
      const response = await OtsService.listOtsRequests({
        page: otsData.pageNumber,
        pageSize: 10,
        includeUserDetails: true,
        includeLoanDetails: true,
      });
      setOtsData(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [otsData.pageNumber]);

  return (
    <div>
      <VegaPageHeader />
      <Box p={3}>
        <Stack
          direction={'row'}
          alignItems="end"
          justifyContent={'space-between'}
        >
          <VegaText
            text="OTS"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
        <Box pt={3}>
          <VegaDataGrid
            data={otsData.records}
            loading={loading}
            columns={getColumnDefinition({
              onViewDetailsClick: ots => {
                navigate(ROUTES.OTS_DETAILS.replace(':otsId', ots.id));
              },
            })}
            idColumn="id"
            rowCount={otsData.numberOfItems}
            page={otsData.pageNumber}
            pageSize={10}
          />
        </Box>
      </Box>
    </div>
  );
}

export default OtsHomePage;

const getColumnDefinition = (data: {
  onViewDetailsClick: (ots: Ots) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Ots Request Date',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const parsedDate = DateUtility.parseStringToDate(ots.createdAt);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentId',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const raisedBy = getValueFromRecord<VegaUser>(
          ots?.metaData,
          OtsMetaDataKey.RAISED_BY
        );
        const displayText = StringUtility.concatenateStrings(
          ' ',
          raisedBy?.firstName,
          raisedBy?.middleName,
          raisedBy?.lastName
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'Loan Id',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = ots.loanId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsStatus',
      headerName: 'Ots Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = getOtsStatusDescription(ots?.status);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'technicalTeamStatus',
      headerName: 'Technical Team Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = _.startCase(_.toLower(ots.technicalReportsStatus));
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'operationTeamStatus',
      headerName: 'Operation Team Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = _.startCase(_.toLower(ots.operationReportStatus));
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const ots = getOtsForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(ots);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(ots);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getOtsForRow = (props: any) => props.row as Ots;
