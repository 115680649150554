import { createContext, useContext, useEffect, useState } from 'react';
import { AgendaService } from '../Apis/AgendaService';
import { ConfigurationServices } from '../Apis/ConfigurationServices';
import { LoanService } from '../Apis/LoanServices';
import { OtsService } from '../Apis/OtsService';
import { ConfigurationType } from '../types/configurationType';
import { LoanData } from '../types/loan';
import { Agenda, AgendaCase, Ots, OtsConfig } from '../types/ots';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';

type OTSContextType = {
  ots?: Ots;
  loan?: LoanData;
  agendaCase?: AgendaCase;
  agenda?: Agenda;
  otsConfig?: OtsConfig;
  loadingAgenda?: boolean;
  loadingAgendaCase?: boolean;
  loadingLoan?: boolean;
  loading?: boolean;
  linkedAccounts: LoanData[];
  fetchAgendaCase: (data: {
    id: string;
    fetchOts: boolean;
    fetchAgenda: boolean;
  }) => void;
  fetchAgenda?: (id: string) => void;
  fetchOts: (id: string) => void;
  downloadFile: (url: string) => void;
};

const OTSContext = createContext<OTSContextType | null>(null);

export const useOts = () => useContext(OTSContext) as OTSContextType;

export const OtsProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [ots, setOts] = useState<Ots>();
  const [agendaCase, setAgendaCase] = useState<AgendaCase>();
  const [agenda, setAgenda] = useState<Agenda>();
  const [loan, setLoan] = useState<LoanData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAgenda, setLoadingAgenda] = useState<boolean>(false);
  const [loadingAgendaCase, setLoadingAgendacase] = useState<boolean>(false);
  const [config, setConfig] = useState<OtsConfig>();
  const [linkedAccounts, setLinkedAccounts] = useState<LoanData[]>([]);

  async function _fetchLinkedAccounts(loanId: string) {
    const response = await LoanService.getLinkedCustomerAccounts({
      loanId: loanId,
    });
    setLinkedAccounts(response);
  }

  async function _fetchOtsConfig() {
    const configResponse = await ConfigurationServices.getConfigList({
      names: [ConfigurationType.OTS],
    });
    const configs = configResponse.records ?? [];
    if (configs.length <= 0) return;
    const otsConfig = configs[0].config as unknown as OtsConfig;
    setConfig(otsConfig);
  }

  async function _fetchOts(id: string) {
    try {
      setLoading(true);
      const response = await OtsService.getOtsById(id);
      setOts(response);
      const loanId = response?.loanId;
      const loanResponse = await LoanService.getLoanList({
        loanId: loanId,
        size: 1,
      });
      if (loanResponse.records.length > 0) {
        setLoan(loanResponse.records[0]);
      }
      _fetchLinkedAccounts(loanId);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _fetchAgendaCase(data: {
    id: string;
    fetchOts: boolean;
    fetchAgenda: boolean;
  }) {
    try {
      setLoadingAgendacase(true);
      const response = await AgendaService.getAgendaCase(data.id);
      setAgendaCase(response);
      const otsId = response?.otsId;
      const agendaId = response?.agendaId;
      if (agendaId && data.fetchAgenda == true) {
        _fetchAgenda(agendaId);
      }
      if (otsId && data.fetchOts == true) {
        _fetchOts(otsId);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingAgendacase(false);
    }
  }

  async function _fetchAgenda(id: string) {
    try {
      setLoadingAgenda(true);
      const response = await AgendaService.getAgenda(id);
      setAgenda(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingAgenda(false);
    }
  }

  async function _downloadFile(url: string) {
    try {
      const response = await OtsService.getPublicUrl({ url: url });
      window.open(response, '__blank__');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    _fetchOtsConfig();
  }, []);

  return (
    <OTSContext.Provider
      value={{
        loan: loan,
        fetchOts: _fetchOts,
        fetchAgendaCase: _fetchAgendaCase,
        agendaCase: agendaCase,
        agenda: agenda,
        ots: ots,
        otsConfig: config,
        loading: loading,
        downloadFile: _downloadFile,
        fetchAgenda: _fetchAgenda,
        loadingAgenda: loadingAgenda,
        linkedAccounts: linkedAccounts,
        loadingAgendaCase: loadingAgendaCase,
      }}
    >
      {children}
    </OTSContext.Provider>
  );
};
