import { Stack } from '@mui/material';
import LmTextField from '../../../../components/common/LmTextField';
import VegaDateTimePicker from '../../../../components/common/VegaDateTimePicker';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  ConfigValueProps,
  ConfigurationDto,
} from '../../../../types/configurationType';
import { DateUtility } from '../../../../utils/DateUtlility';

const TODAY = new Date();

const AlertNotifyAgentForm = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const handleAlertNotificationChange = (
    key: keyof ConfigValueProps,
    value: number | string | any
  ) => {
    if (key === 'reminderPeriod') {
      const updateConfigValueObj = { ...configurations.config, [key]: value };
      handleConfigChange('config', updateConfigValueObj);
    } else if (key === 'startTime') {
      const parsedDate = DateUtility.parseDateFromDatePicker(value);

      var endTime = DateUtility.parseStringToDate(
        configurations.config?.endTime
      );
      if (endTime && parsedDate) {
        if (DateUtility.isBefore(endTime, parsedDate)) {
          endTime = DateUtility.addDays(parsedDate, 1);
        }
      }

      const updateConfigValueObj = {
        ...configurations.config,
        startTime: parsedDate?.toISOString(),
        endTime: endTime?.toISOString(),
      };
      handleConfigChange('config', updateConfigValueObj);
    } else {
      const parsedDate = DateUtility.parseDateFromDatePicker(value);
      const updateConfigValueObj = {
        ...configurations.config,
        [key]: parsedDate?.toISOString(),
      };
      handleConfigChange('config', updateConfigValueObj);
    }
  };

  return (
    <Stack gap={2}>
      <VegaFormInputField label={'Start Time'}>
        <VegaDateTimePicker
          onChange={date => handleAlertNotificationChange('startTime', date)}
          value={configurations.config?.startTime}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'End Time'}>
        <VegaDateTimePicker
          onChange={date => handleAlertNotificationChange('endTime', date)}
          value={configurations.config?.endTime}
          minDate={TODAY}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'Target Entry Deadline'}>
        <VegaDateTimePicker
          onChange={date =>
            handleAlertNotificationChange('targetEntryDeadline', date)
          }
          value={configurations.config?.targetEntryDeadline}
          minDate={TODAY}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'Target Reason Deadline'}>
        <VegaDateTimePicker
          onChange={date =>
            handleAlertNotificationChange('targetReasonDeadline', date)
          }
          value={configurations.config?.targetReasonDeadline}
          minDate={TODAY}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'Reminder Period'}>
        <LmTextField
          type="number"
          value={configurations.config?.reminderPeriod ?? 0}
          onChange={e => {
            const value = e.target.value && parseFloat(e.target.value);
            handleAlertNotificationChange('reminderPeriod', value);
          }}
        />
      </VegaFormInputField>
    </Stack>
  );
};

export default AlertNotifyAgentForm;
