import { Box, Collapse, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { fetchTeleAgentAllocation } from '../../../actions/TeleAgentAction';
import { PRIMARY } from '../../../constants/style';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import SlashRtcView from '../../../pages/TeleAgent/SlashRtcView';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  TeleAgentCallStatus,
  useTeleAgentCallEvents,
} from '../../../providers/TeleAgentCallEventsProvider';
import { ROUTES } from '../../../router/routes';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GetTeleAllocationsListRequest } from '../../../types/request/allocation';
import { GetClickToCallRequest } from '../../../types/request/teleAllocation';
import {
  TeleAgentCallType,
  TeleAgentListProps,
  TeleAgentManualAccountViewModel,
} from '../../../types/teleAgent';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmFilterSection from '../../common/LmFilterSection';
import VegaButton from '../../common/VegaButton';
import VegaDataGrid from '../../common/VegaDataGrid';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';
import LoanAccountDetails from '../components/LoanAccountDetails';
import LoanAccountDetailsModal from '../components/LoanAccountDetailsModal';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../common/VegaIconButton';

interface IProps {
  agentDetails: Partial<TeleAgentListProps | null>;
}

const ListOfTeleAgentManualAccount = ({ agentDetails }: IProps) => {
  const { user } = useClientAuth();
  const { allocations, totalItems } = useAppSelector(getTeleAgent);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const { callEvent } = useTeleAgentCallEvents();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string>('');

  const onClickToCall = (account: TeleAgentManualAccountViewModel) => {
    TeleAllocationService.getProcessByProcessId(account.processId)
      .then(response => {
        if (!agentDetails) return;
        const body: GetClickToCallRequest = {
          agenTptId: agentDetails.crmId,
          customerNumber: account.contactNumber,
          tokenId: response.processTokenId,
          insertLead: true,
          customerInfo: {
            teleallocationid: account.id,
          },
        };
        TeleAllocationService.getClickToCall(body)
          .then(res => {
            const clickToCall = res;
            if (clickToCall.OUTPUT === 'CLICK_TO_CALL_GENERATED') {
              setSnackbar(clickToCall.OUTPUT);
            } else {
              setSnackbar(clickToCall.OUTPUT, 'error');
            }
          })
          .catch(error => {
            setSnackbar(getErrorMessageFromErrorObj(error), 'error');
          });
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const getTeleAllocation = () => {
    const request = {
      agentId: user.id,
      page,
      pageSize: pageSize,
      teleAllocatonType: TeleAgentCallType.MANUAL,
    } as GetTeleAllocationsListRequest;
    if (search.length) {
      request.loanId = search;
    }
    dispatch(fetchTeleAgentAllocation(request));
  };

  useEffect(() => {
    user && getTeleAllocation();
  }, [dispatch, page, search, user]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'My Account'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
          );
        }}
      />
      <VegaPageContent>
        <Stack
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <Stack>
            <Collapse
              in={callEvent?.status == TeleAgentCallStatus.STARTED}
              timeout={300}
            >
              <LoanAccountDetails loanId={callEvent?.loanId} />
            </Collapse>
            <Collapse
              in={callEvent?.status == TeleAgentCallStatus.ENDED}
              timeout={300}
            >
              <Box
                sx={{
                  p: '1rem',
                  bgcolor: 'white',
                  borderRadius: '0.75rem',
                  border: '1px solid #E1E4EB',
                  my: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack>
                  <VegaText text={'LAN'}></VegaText>
                  <VegaText text={callEvent?.loanId}></VegaText>
                </Stack>
                <VegaButton
                  text={'View Details'}
                  onClick={() => setShowModal(true)}
                ></VegaButton>
              </Box>
            </Collapse>
            <VegaDataGrid
              data={allocations}
              idColumn="id"
              paginationMode="server"
              onPageChange={page => setPage(page)}
              onPageSizeChange={pageSize => setPageSize(pageSize)}
              rowCount={totalItems}
              columns={getColumnDefinition({
                onViewDetails: account => {
                  navigate(
                    ROUTES.TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID.replace(
                      ':loanAccountId',
                      account.lan
                    )
                  );
                },
                clickToCall: account => {
                  onClickToCall(account);
                },
              })}
            />
          </Stack>
          <div style={{ flexBasis: '35%', height: '70vh' }}>
            <SlashRtcView />
          </div>
        </Stack>
        <LoanAccountDetailsModal
          open={showModal}
          loanId={callEvent?.loanId}
          onClose={function (): void {
            setShowModal(false);
          }}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfTeleAgentManualAccount;

const getColumnDefinition = (data: {
  onViewDetails: (account: TeleAgentManualAccountViewModel) => void;
  clickToCall: (account: TeleAgentManualAccountViewModel) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'lan',
      headerName: 'Loan A/c No.',
      flex: 0.7,
    },

    {
      field: 'teleAllocatonType',
      headerName: 'Allocation Type',
      flex: 0.7,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const account = props.row as TeleAgentManualAccountViewModel;
        return (
          <VegaText
            fontWeight={500}
            text={_.startCase(_.toLower(account.teleCallStatus))}
          ></VegaText>
        );
      },
    },

    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const account = props.row as TeleAgentManualAccountViewModel;
        return (
          // <VegaButton
          //   text={'View Details'}
          //   variant="text"
          //   onClick={() => data.onViewDetails(account)}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => data.onViewDetails(account)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      renderCell: props => {
        const account = props.row as TeleAgentManualAccountViewModel;
        return (
          <VegaButton
            text={'Click to call'}
            sx={{
              bgcolor: '#E7EDFC',
              color: PRIMARY.darkBlue,
              ':hover': {
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
              },
            }}
            onClick={() => data.clickToCall(account)}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
