import axios from 'axios';
import {
  Ots,
  OtsAdditionalDocument,
  OtsRequestAudit,
  OtsUserComment,
} from '../types/ots';
import { FileDownloadRequest } from '../types/request/agenda';
import {
  GetOtsAuditRequest,
  GetOtsMisReportRequest,
  GetOtsMisResponse,
  GetOtsRequests,
  OtsActionRequest,
  OtsReportActionRequest,
  RaiseOtsRequest,
  SubmitAckLetterRequest,
  SubmitNpvDataRequest,
  SubmitOperationReportRequest,
  SubmitTechnicalReportRequest,
  TriggerDelayRequest,
  UpdateOtsRequest,
  UpdateOtsTenureRequest,
} from '../types/request/ots';
import { objectToFormData, PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/ots',
  RAISE_OTS: '/create',
  UPDATE_OTS: '/update',
  LIST: '/list',
  LIST_AUDIT: '/audit/list',
  GET: '/:id',
  TAKE_ACTION: '/action',
  TAKE_ACTION_ON_REPORT: '/report/action',
  SUBMIT_REPORT: '/submitReport',
  SUBMIT_ACK_LETTER: '/customer/ack',
  SUBMIT_NPV_DATA: '/submitNpv',
  DOWNLOAD_FILE: '/downloadFile',
  EXPORT_MIS_REPORT: '/export',
  UPDATE_TENURE: '/update-tenure',
  GET_COMMENTS: '/comments/:id',
  GET_DOCUMENTSS: '/additional-documents/:id',
  ACK_CUSTOMER_LETTER: '/customer/token/:id',
  TRIGGER_DELAY: '/check-for-delayed-records',
};

export class OtsService {
  static async raiseOtsRequest(request: Partial<RaiseOtsRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.RAISE_OTS;
    const formData = objectToFormData<Partial<RaiseOtsRequest>>(request);
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async getComments(otsId: string): Promise<OtsUserComment[]> {
    const endpoint =
      Endpoint.BASE + Endpoint.GET_COMMENTS.replace(':id', otsId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getDocuments(otsId: string): Promise<OtsAdditionalDocument[]> {
    const endpoint =
      Endpoint.BASE + Endpoint.GET_DOCUMENTSS.replace(':id', otsId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getPublicUrl(request: Partial<FileDownloadRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.DOWNLOAD_FILE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateOtsRequest(request: Partial<UpdateOtsRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.UPDATE_OTS;
    const formData = objectToFormData<Partial<RaiseOtsRequest>>(request);
    const response = await axios.put(endpoint, formData);
    return response.data;
  }

  static async updateOtsTenure(request: Partial<UpdateOtsTenureRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.UPDATE_TENURE;
    const response = await axios.put(endpoint, request);
    return response.data;
  }

  static async listOtsRequests(
    request: Partial<GetOtsRequests>
  ): Promise<PaginatedResponse<Ots>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getOtsById(id: string): Promise<Ots> {
    const endpoint = Endpoint.BASE + Endpoint.GET.replace(':id', id);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async takeAction(request: Partial<OtsActionRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.TAKE_ACTION;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async submitTechnicalReport(
    request: Partial<SubmitTechnicalReportRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.SUBMIT_REPORT;
    const formData =
      objectToFormData<Partial<SubmitTechnicalReportRequest>>(request);
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async submitOperationReport(
    request: Partial<SubmitOperationReportRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.SUBMIT_REPORT;
    const formData =
      objectToFormData<Partial<SubmitOperationReportRequest>>(request);
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async submitAckLetter(request: Partial<SubmitAckLetterRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.SUBMIT_ACK_LETTER;
    const formData = objectToFormData<Partial<SubmitAckLetterRequest>>(request);
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async takeActionOnReport(request: Partial<OtsReportActionRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.TAKE_ACTION_ON_REPORT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getOtsAudit(
    request: Partial<GetOtsAuditRequest>
  ): Promise<PaginatedResponse<OtsRequestAudit>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST_AUDIT;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async submitNpvData(request: Partial<SubmitNpvDataRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.SUBMIT_NPV_DATA;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getMisFile(
    request: Partial<GetOtsMisReportRequest>
  ): Promise<GetOtsMisResponse> {
    const endpoint = Endpoint.BASE + Endpoint.EXPORT_MIS_REPORT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async ackCustomerLetter(token: string) {
    const endpoint =
      Endpoint.BASE + Endpoint.ACK_CUSTOMER_LETTER.replace(':id', token);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async triggerOtsDelay(request: Partial<TriggerDelayRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.TRIGGER_DELAY;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
