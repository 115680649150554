import { Collapse, FormControl } from '@mui/material';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LmDateField from '../../../components/common/LmDateField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaRadioGroup, {
  VegaRadioOption,
} from '../../../components/common/VegaRadioGroup';
import VegaText from '../../../components/common/VegaText';
import { ReceiptStatus } from '../../../types/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';

export enum FilterOption {
  DayFilter,
  StatusFilter,
}

enum DayFilter {
  PAST_7_DAYS = 'PAST_7_DAYS',
  PAST_14_DAYS = 'PAST_14_DAYS',
  CUSTOM = 'CUSTOM',
}

export type DateRangeFilter = {
  startDate: string;
  endDate: string;
};

const getDayFilterOptions = () =>
  Object.values(DayFilter).map(item => {
    const option: VegaRadioOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const getStatusFilterOptions = () =>
  Object.values(ReceiptStatus)
    .filter(
      item => item === ReceiptStatus.APPROVED || item === ReceiptStatus.REJECTED
    )
    .map(item => {
      const option: VegaRadioOption = {
        label: _.startCase(_.toLower(item)),
        value: item,
      };
      return option;
    });

type Props = {
  onClearAllClick: () => void;
  allowedFilters: FilterOption[];
  onSelectFilterClick: (
    statusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => void;
  value: {
    dayFilter?: string;
    statusFilter?: string;
    dateRangeFilter: DateRangeFilter;
  };
};

const dayFilters = getDayFilterOptions();
const statusFilters = getStatusFilterOptions();

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const ReciptFilterBy = ({
  onClearAllClick,
  onSelectFilterClick,
  value,
  allowedFilters,
}: Props) => {
  const [selectedDayFilter, setSelectedDayFilter] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRangeFilter>({
    startDate: TODAY.toISOString(),
    endDate: TOMORROW.toISOString(),
  });

  const onStartDateChange = (e: any) => {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    if (!parsedDate) return;
    setDateRange(prev => {
      const endDate = DateUtility.parseStringToDate(prev.endDate);
      if (endDate) {
        const isStartDateAfterEndDate = DateUtility.isAfter(
          parsedDate,
          endDate
        );
        if (isStartDateAfterEndDate) {
          return {
            ...prev,
            startDate: parsedDate.toISOString(),
            endDate: parsedDate.toISOString(),
          };
        }
      }
      return { ...prev, startDate: parsedDate.toISOString() };
    });
  };

  const onEndDateChange = (e: any) => {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    if (!parsedDate) return;
    setDateRange(prev => {
      return { ...prev, endDate: parsedDate.toISOString() };
    });
  };

  const onSelectClick = () => {
    let dateRangeValue: DateRangeFilter;
    const today = new Date();
    if (selectedDayFilter == DayFilter.PAST_7_DAYS) {
      const sevenDaysBefore = DateUtility.subtractDays(today, 7);
      dateRangeValue = {
        startDate: sevenDaysBefore.toISOString(),
        endDate: today.toISOString(),
      };
    } else if (selectedDayFilter == DayFilter.PAST_14_DAYS) {
      const fourteenDaysBefore = DateUtility.subtractDays(today, 14);
      dateRangeValue = {
        startDate: fourteenDaysBefore.toISOString(),
        endDate: today.toISOString(),
      };
    } else {
      dateRangeValue = dateRange;
    }
    if (selectedDayFilter) {
      onSelectFilterClick(selectedStatus, dateRangeValue, selectedDayFilter);
    } else {
      onSelectFilterClick(selectedStatus, { startDate: '', endDate: '' }, '');
    }
  };

  const onClearClick = () => {
    onClearAllClick();
  };

  useEffect(() => {
    setDateRange(value.dateRangeFilter);
    setSelectedDayFilter(value.dayFilter);
    setSelectedStatus(value.statusFilter);
  }, [value]);

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClearClick}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton text="Apply Filter" onClick={onSelectClick} />
          </Stack>
        );
      }}
    >
      <Stack
        spacing={'1.5rem'}
        style={{
          display: allowedFilters.includes(FilterOption.DayFilter)
            ? ''
            : 'none',
        }}
      >
        <FormControl
          style={{
            display: allowedFilters.includes(FilterOption.StatusFilter)
              ? ''
              : 'none',
          }}
        >
          <VegaText
            text={'Status'}
            marginBottom="1.25rem"
            style={{
              fontSize: '0.625rem',
              fontWeight: 700,
              lineHeight: '124%',
              letterSpacing: '0.04375rem',
              color: '#999DA8',
              textTransform: 'uppercase',
            }}
          />
          <VegaRadioGroup
            options={statusFilters}
            value={selectedStatus}
            onChange={e => {
              setSelectedStatus(e.target.value);
            }}
          />
        </FormControl>
        <VegaRadioGroup
          heading="Day"
          options={dayFilters}
          value={selectedDayFilter}
          onChange={e => {
            setSelectedDayFilter(e.target.value);
          }}
        />
        <Collapse in={selectedDayFilter == DayFilter.CUSTOM} timeout={300}>
          <Stack direction={'row'} columnGap="1rem">
            <LmDateField
              header="Start Date"
              onChange={onStartDateChange}
              value={dateRange.startDate}
              disabled
            />
            <LmDateField
              header="End Date"
              onChange={onEndDateChange}
              minDate={dateRange.startDate}
              value={dateRange.endDate}
              disabled
            />
          </Stack>
        </Collapse>
      </Stack>
    </VegaDrawerContent>
  );
};

export default ReciptFilterBy;
