import { Box, Grid, Stack } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { getTargetState, setBuckets } from '../../../features/targetSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TargetByBucketsProps } from '../../../types/targets';
import { useState } from 'react';
import { VegaPill } from '../../../components/common/VegaPill';
import { COLOR } from '../../../utils/ColorUtility';

interface ITargetFormProps {
  initialDate?: string;
  initialState?: TargetByBucketsProps[];
  onAddAndUpdateTarget: (
    buckets: TargetByBucketsProps[],
    modifyReason?: string
  ) => void;
}

const TargetForm = ({
  onAddAndUpdateTarget,
  initialDate,
}: ITargetFormProps) => {
  const dispatch = useAppDispatch();
  const [modifyReason, setModifyReason] = useState<string>('');
  const { buckets, isAddTarget } = useAppSelector(getTargetState);

  const handleBucketValue = (index: number, field: string, value: number) => {
    const newBuckets = [...buckets];
    const updatedBucket = { ...newBuckets[index] };
    updatedBucket[field] = value;
    newBuckets[index] = updatedBucket;
    dispatch(setBuckets(newBuckets));
  };

  const disabled = () => {
    if (isAddTarget) {
      return !buckets.some(
        bucket => bucket.posValue > 0 && bucket.noOfCases > 0
      );
    } else {
      const isModifyReasonValid = modifyReason !== '';
      return !(
        isModifyReasonValid &&
        buckets.some(bucket => bucket.posValue > 0 && bucket.noOfCases > 0)
      );
    }
  };

  function updateModifyReasonValue(value: string) {
    setModifyReason(value);
  }

  function onSumbitPress() {
    if (isAddTarget) {
      onAddAndUpdateTarget(buckets);
    } else {
      onAddAndUpdateTarget(buckets, modifyReason);
    }
  }

  // const initialDisabled = buckets.some(bucketValue => {
  //   const initialBucketValue = initialState.find(
  //     item => item.bucket === bucketValue.bucket
  //   );
  //   if (initialBucketValue) {
  //     if (bucketValue.noOfCases !== 0 && bucketValue.posValue !== 0) {
  //       if (
  //         bucketValue.noOfCases === initialBucketValue.noOfCases &&
  //         bucketValue.posValue === initialBucketValue.posValue
  //       ) {
  //         return true;
  //       }
  //     }
  //   }

  //   return false;
  // });
  return (
    <>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text={isAddTarget ? 'Save Details' : 'Update Details'}
                onClick={onSumbitPress}
                disabled={disabled()}
              />
            </Stack>
          );
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <VegaPill
            text={'Please enter your targets for the day below'}
            backgroundColor={COLOR.BLUE[50]}
            textColor={COLOR.BLUE[300]}
            borderColor={COLOR.BLUE[300]}
            isDot={false}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <VegaFormInputField label={'Date'}>
              <LmTextField disabled value={initialDate} />
            </VegaFormInputField>
          </Grid>
          {!isAddTarget && (
            <Grid item xs={12} sm={12}>
              <VegaFormInputField label={'Modify Target Reason'}>
                <LmTextField
                  value={modifyReason}
                  onChange={e => updateModifyReasonValue(e.target.value)}
                />
              </VegaFormInputField>
            </Grid>
          )}
          {buckets.map((bucket: TargetByBucketsProps, index: number) => (
            <>
              <Grid key={bucket.bucket} item xs={12} sm={6}>
                <VegaFormInputField label={bucket.posName}>
                  <LmTextField
                    type="number"
                    value={bucket.posValue}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBucketValue(index, 'posValue', value);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid key={bucket.bucket} item xs={12} sm={6}>
                <VegaFormInputField label={bucket.caseName}>
                  <LmTextField
                    type="number"
                    value={bucket.noOfCases}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBucketValue(index, 'noOfCases', value);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
            </>
          ))}
        </Grid>
      </VegaDrawerContent>
    </>
  );
};

export default TargetForm;
