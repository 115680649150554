import React from 'react';
import VegaText from '../../common/VegaText';
import { Box, Grid } from '@mui/material';
import TeleAgentTextWrapper from '../../TeleAgent/components/TeleAgentTextWrapper';

const dummy = [
  {
    level: '3',
    name: 'John Wick',
    escalationDate: '22/09/2023',
  },
  {
    level: '2',
    name: 'John Wick',
    escalationDate: '22/09/2023',
  },
  {
    level: '1',
    name: 'John Wick',
    escalationDate: '22/09/2023',
  },
];

const EscalationHistory = () => {
  return (
    <>
      <VegaText text={'Escalation History'} fontWeight={600} fontSize={14} />
      {dummy.map(escalation => (
        <Box
          key={escalation.level}
          sx={{
            p: '1rem',
            bgcolor: 'white',
            borderRadius: '0.75rem',
            border: '1px solid #E1E4EB',
            mt: '1rem',
          }}
        >
          <VegaText
            text={`Level - ${escalation.level}`}
            fontWeight={600}
            fontSize={14}
          />
          <Box mt={'1rem'}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <TeleAgentTextWrapper
                  text="name"
                  value={'John wick'}
                  sx={{
                    minWidth: '65px',
                  }}
                  loading={false}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <TeleAgentTextWrapper
                  text="Esacalation date"
                  value={'Sept 10, 2023'}
                  sx={{
                    minWidth: '65px',
                  }}
                  loading={false}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default EscalationHistory;
