import { Box } from '@mui/material';
import LmTextField from '../common/LmTextField';
import { useBankCustomer } from '../../providers/CustomerBankProvider';
import { fetchCustomerBankById } from '../../Apis/customerBank';
import { useEffect, useState } from 'react';
import VegaFormInputField from '../common/VegaFormInputField';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaText from '../common/VegaText';
import { PRIMARY } from '../../constants/style';
import { StringUtility } from '../../utils/StringUtility';
interface IProps {
  isEditable: boolean;
}

const AddNewBranchInfo = ({ isEditable }: IProps) => {
  const {
    customerBankState,
    selectedId,
    setCustomerBankState,
    handleCustomerBankChange,
  } = useBankCustomer();

  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const getValidationItem = (isValue: boolean, text: string) => {
    return (
      isValue && (
        <VegaText
          text={text}
          fontColor={PRIMARY.darkBlue}
          fontSize={'0.75rem'}
        />
      )
    );
  };
  const getCustomerBankById = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchCustomerBankById(id);
      setCustomerBankState(response);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(
          error,
          'Failed to fetch customer bank details'
        ),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      getCustomerBankById(selectedId);
    }
  }, [selectedId]);
  return (
    <>
      <Box mb={2}>
        <VegaFormInputField label={'bank name'} isMandatory>
          <LmTextField
            loading={loading}
            placeholder="Enter Bank Name"
            value={customerBankState?.bankName}
            onChange={e => {
              const value = e.target.value;
              handleCustomerBankChange('bankName', value);
            }}
          />
        </VegaFormInputField>
      </Box>

      {/* <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'IFSC'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter IFSC"
                disabled={isEditable}
                value={customerBankState?.ifsc}
                onChange={e => {
                  const value = e.target.value;
                  handleCustomerBankChange('ifsc', value);
                }}
              />
              {getValidationItem(
                !StringUtility.validationIfscNumber(customerBankState?.ifsc),
                `* Ifsc code should be 11 digit AlphaNumeric characters.`
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VegaFormInputField label={'MICR'} isMandatory>
              <LmTextField
                loading={loading}
                isInfo
                placeholder="Enter MICR"
                disabled={isEditable}
                value={customerBankState?.micr}
                onChange={e => {
                  const value = e.target.value;
                  handleCustomerBankChange('micr', value);
                }}
              />
              {getValidationItem(
                !StringUtility.validationMicrNumber(customerBankState?.micr),
                `* Micr should be 9 digits numeric characters.`
              )}
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Box> */}

      <Box mb={2}>
        <VegaFormInputField label={'Branch Name'} isMandatory>
          <LmTextField
            loading={loading}
            placeholder="Enter Branch Name"
            value={customerBankState?.branchName}
            onChange={e => {
              const value = e.target.value;
              handleCustomerBankChange('branchName', value);
            }}
          />
        </VegaFormInputField>
      </Box>
      <Box mb={2}>
        <VegaFormInputField label={'MICR'} isMandatory>
          <LmTextField
            loading={loading}
            isInfo
            placeholder="Enter MICR"
            disabled={isEditable}
            value={customerBankState?.micr}
            onChange={e => {
              const value = e.target.value;
              handleCustomerBankChange('micr', value);
            }}
          />
          {getValidationItem(
            !StringUtility.validationMicrNumber(customerBankState?.micr),
            `* Micr should be 9 digits numeric characters.`
          )}
        </VegaFormInputField>
      </Box>
      {/* <Box mb={2}>
        <VegaFormInputField label={'Branch Code'} isMandatory>
          <LmTextField
            loading={loading}
            placeholder="Enter Branch Code"
            value={customerBankState?.branchCode}
            onChange={e => {
              const value = e.target.value;
              handleCustomerBankChange('branchCode', value);
            }}
          />
          {getValidationItem(
            !StringUtility.validationbranchCode(customerBankState?.branchCode),
            `* Branch Code should be numeric characters.`
          )}
        </VegaFormInputField>
      </Box> */}
    </>
  );
};

export default AddNewBranchInfo;
