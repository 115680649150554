import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SystemCollectionServices } from '../Apis/SystemCollectionServices';
import { RootState } from '../store';
import { GetSaarthiListRequest } from '../types/request/systemCollectionRequest';
import { PaginatedResponse } from '../utils/api';
import {
  SystemCollectionList,
  SystemCollectionListViewModel,
} from '../types/systemCollectionType';

interface ReferToFieldRequestActionState {
  systemCollections: SystemCollectionListViewModel[];
  totalItems: number;
  loading: boolean;
  error: string | null;
}
export const fetchSaarthiList = createAsyncThunk(
  'systemCollection/fetchSaarthiList',
  async (
    request: Partial<GetSaarthiListRequest>
  ): Promise<PaginatedResponse<SystemCollectionList>> => {
    return await SystemCollectionServices.getSaarthiList(request);
  }
);

const initialState: ReferToFieldRequestActionState = {
  loading: false,
  totalItems: 0,
  systemCollections: [],
  error: null,
};

const systemCollectionSlice = createSlice({
  name: 'systemCollection',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSaarthiList.pending, state => {
        state.loading = true;
      })
      .addCase(fetchSaarthiList.fulfilled, (state, action) => {
        state.loading = false;

        const systemCollectionData = action.payload.records;
        const systemCollectionDataModel: SystemCollectionListViewModel[] =
          systemCollectionData.map(systemCollection => ({
            fileName: systemCollection.id,
            requestId: systemCollection.requestId,
            failure: systemCollection.failedToInsert,
            success: systemCollection.successfullyInserted,
            failureReason: systemCollection.failureReason,
            status: systemCollection.status,
            totalEntries:
              systemCollection.failedToInsert +
              systemCollection.successfullyInserted,
          }));

        state.systemCollections = systemCollectionDataModel;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchSaarthiList.rejected, state => {
        state.loading = false;
        state.error = 'Failed to fetch list';
      });
  },
});
export const getSystemCollection = (state: RootState) => state.systemCollection;
export default systemCollectionSlice;
