import { Box, Grid, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { ArrowRightIcon } from '@mui/x-date-pickers-pro';
import { useEffect, useState } from 'react';
import { AllocationService } from '../../../Apis/AllocationService';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import AdminNotifications from '../../../components/Home/AdminHome/AdminNotifications';
import OverviewChart from '../../../components/Home/AdminHome/OverviewChart';
import QuickAccess from '../../../components/Home/AdminHome/QuickAccess';
import HomeSummariesCard from '../../../components/Home/components/HomeSummariesCard';
import { TrendDownIcon, TrendUp } from '../../../components/Icons/Icons';
import MonthlyProgressCard from '../../../components/MonthlyProgress/MonthlyProgressCard';
import LmDialog from '../../../components/common/LmDialog';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaOutlineDatePicker from '../../../components/common/VegaOutlineDatePicker';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch } from '../../../store';
import { AgentStatsDto } from '../../../types/allocations';
import { AgentPayoutDto, RankDetailDto } from '../../../types/incentives';
import { VegaUserStatus } from '../../../types/user';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { StringUtility } from '../../../utils/StringUtility';
import { EscalationsServices } from '../../../Apis/EscalationsServices';
import { LoanService } from '../../../Apis/LoanServices';
import { UserService } from '../../../Apis/UserService';
import { GetExportAllocationMetaDataRequest } from '../../../types/request/allocation';
import DownloadIcon from '@mui/icons-material/Download';
import { getPolicies } from '../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
enum TrendDescription {
  Increase_Over_The_Last_Month = 'increase over the last month',
  Decrease_Over_The_Last_Month = 'decrease over the last month',
}

const AdminHomePage = () => {
  const [agentRankDetails, setAgentRankDetails] = useState<RankDetailDto>();
  const [agentPayout, setAgentPayout] = useState<AgentPayoutDto>();
  const [accountNumber, setAccountNumber] = useState<number>(0);
  const [reportees, setReportees] = useState<number>(0);
  const [escalations, setEscalations] = useState<number>(0);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { user } = useClientAuth();
  const [agentStats, setAgentStats] = useState<Partial<AgentStatsDto>>();
  const [dateValue, setDateValue] = useState<string>(
    DateUtility.formatStringToYYYYMMDD(new Date().toDateString())
  );

  const handleChangeDate = (e: any) => {
    const date = DateUtility.formatStringToYYYYMMDD(e);
    setDateValue(date);
  };
  const {
    open: openDetailDialog,
    close: closeDetailsDialog,
    isOpen: isDetailsDialogOpen,
  } = useDrawer(false);
  const {
    open: openUpcomingPTPsDialog,
    close: closeUpcomingPTPsDialog,
    isOpen: isUpcomingPTPsDialogOpen,
  } = useDrawer(false);

  function getChangeInPtp(): MonthPercentageChange {
    if (!agentStats) {
      return;
    }

    return calculateMonthPercentageChange(
      agentStats?.ptpReceivedCurrentMonth,
      agentStats?.ptpReceivedLastMonth
    );
  }

  const fetchAgentStatsApi = async () => {
    if (!user.id) {
      return;
    }
    try {
      const response = await AllocationService.getAgentStats({
        agentId: user?.id,
      });
      setAgentStats(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const fetchAgentRankApi = async () => {
    if (!user?.id) {
      return;
    }

    try {
      const response = await IncentiveServices.getAgentRank({
        agentId: user?.id,
      });
      setAgentRankDetails(response);
    } catch (error) {
      // setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  async function fetchAgentPayout() {
    try {
      const response = await IncentiveServices.getAgentPayout({
        agentId: user?.id,
      });
      setAgentPayout(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function fetchAgentAllocations() {
    try {
      const request = {
        restrictedView: true,
      } as GetExportAllocationMetaDataRequest;
      const _count = await LoanService.getLoanListCount(request);
      // const response = await LoanService.getLoanList({
      //   // agentId: user.id,
      //   restrictedView: true,
      //   size: 1,
      //   // allocationStatus: AllocationStatus.ALLOCATED,
      // });
      // setAccountNumber(response.totalItems);

      setAccountNumber(_count.ALL);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function fetchReportees() {
    try {
      const userId = user?.id;
      if (!userId) return;
      const response = await UserService.getAgentsForManager(userId);
      setReportees(response.length);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }
  const fetchReporteesExportData = async () => {
    try {
      const userId = user?.id;
      if (!userId) return;
      const url = await UserService.getReporteesData(userId);
      window.open(url, '__blank__');
    } catch (err) {
      setSnackbar(getErrorMessageFromErrorObj(err), 'error');
    }
  };
  async function fetchEscalations() {
    try {
      const response = await EscalationsServices.getEscalations({
        userId: user.id,
      });
      setEscalations(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const monthlyProgressDummyData = [
    {
      achievmentName: 'All India Ranking',
      achievementCount: agentRankDetails?.rank?.toString() ?? '-',
      percentageColor: COLOR.GREEN.DARK,
    },
    {
      achievmentName: 'Overall & Bucket',
      achievementCount: '10',
      trendIcon: () => <TrendDownIcon />,
      trendPercentage: '12%',
      trendDescription: 'decrease over the last month',
      percentageColor: COLOR.RED.DARK,
      renderRightView: () => (
        <div
          onClick={() => {
            openDetailDialog();
          }}
        >
          <ArrowRightIcon />
        </div>
      ),
    },
    {
      achievmentName: 'PTPs Received',
      achievementCount: agentStats?.ptpReceivedCurrentMonth?.toString() ?? '-',
      trendIcon: () => getChangeInPtp()?.trendIcon ?? <TrendUp />,
      trendPercentage: getChangeInPtp()?.changePercentage?.toString() ?? '-',
      trendDescription: getChangeInPtp()?.trendDescription ?? '-',
      percentageColor: COLOR.GREEN.DARK,
    },
    {
      achievementCount: agentStats?.pendingVisitCurrentMonth?.toString() ?? '-',
      achievmentName: 'Pending Visits',
      percentageColor: COLOR.RED.DARK,
    },
    {
      achievmentName: 'Upcoming PTPs',
      achievementCount: agentStats?.upcomingPTPCurrentMonth?.toString() ?? '-',
      renderRightView: () => (
        <div
          onClick={() => {
            openUpcomingPTPsDialog();
          }}
        >
          <ArrowRightIcon />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getPolicies({ status: VegaUserStatus.ACTIVE }));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      fetchAgentStatsApi();
      fetchAgentRankApi();
      fetchAgentPayout();
      fetchEscalations();
      fetchAgentAllocations();
      fetchReportees();
    }
  }, [user]);

  return (
    <Box height={'100%'}>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={`Hi, ${user?.firstName ?? 'Admin'}`}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} gap={1}>
              <VegaOutlineDatePicker
                onChange={handleChangeDate}
                value={dateValue}
                maxDate={new Date()}
              />
            </Stack>
          );
        }}
      />
      <Box height={'calc(100% - 95px)'} sx={{ overflow: 'scroll' }}>
        <VegaPageContent mt={'1rem'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <HomeSummariesCard
                label={'Incentive'}
                value={StringUtility.formatToINR(
                  agentPayout?.totalAmountEarned ?? 0
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <HomeSummariesCard
                label={'No Of Accounts'}
                value={accountNumber ?? 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <HomeSummariesCard
                label={'My reportees'}
                value={reportees ?? 0}
                onClick={fetchReporteesExportData}
                icon={<DownloadIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <HomeSummariesCard
                label={'No. of Escalation'}
                value={escalations}
              />
            </Grid>

            {monthlyProgressDummyData.map((data, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <MonthlyProgressCard
                  key={index}
                  trendIcon={data.trendIcon}
                  trendPercentage={data.trendPercentage}
                  trendDescription={data.trendDescription}
                  achievementCount={data.achievementCount}
                  achievmentName={data.achievmentName}
                  percentageColor={data.percentageColor}
                  renderRightView={data.renderRightView}
                />
              </Grid>
            ))}
          </Grid>
        </VegaPageContent>
        <VegaPageContent mt={'1rem'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Stack gap={2}>
                <OverviewChart />
                <QuickAccess />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} height={'auto'}>
              <AdminNotifications />
            </Grid>
          </Grid>
        </VegaPageContent>
      </Box>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={isDetailsDialogOpen}
        handleClose={closeDetailsDialog}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Overall & Bucket Wise"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaDataGrid
            idColumn="id"
            columns={OVERALL_AND_BUCKET_COLUMN_DEF}
            data={[]}
          />
        </VegaDialogContent>
      </LmDialog>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={isUpcomingPTPsDialogOpen}
        handleClose={closeUpcomingPTPsDialog}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Overall & Bucket Wise"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaDataGrid
            idColumn="id"
            columns={UPCOMING_PTPS_COLUMN_DEF}
            data={[]}
          />
        </VegaDialogContent>
      </LmDialog>
    </Box>
  );
};

export default AdminHomePage;
const OVERALL_AND_BUCKET_COLUMN_DEF: GridColumns = [
  {
    field: 'Bucket',
    headerName: 'Bucket',
    flex: 0.5,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Bucket}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Target',
    headerName: 'Taget(in INR cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Target}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Achieved',
    headerName: 'Achieved (in INR Cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Achieved}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: '%Achieved',
    headerName: '% Achieved',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.perAchieved}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];
const UPCOMING_PTPS_COLUMN_DEF: GridColumns = [
  {
    field: 'UserName',
    headerName: 'User Name',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.UserName}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'LoanAccountNumber',
    headerName: 'Loan Account Number',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.LoanAccountNumber}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'POS',
    headerName: 'POS (In INR Cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText text={tableData.POS} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'EMI',
    headerName: 'EMI',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText text={tableData.EMI} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'LastVisitDate',
    headerName: 'Last Visit Date',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.LastVisitDate}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'PTPDate',
    headerName: 'PTP Date',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.PTPDate}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];

type MonthPercentageChange = {
  changePercentage: number;
  trendDescription: TrendDescription;
  trendIcon: JSX.Element;
};

const calculateMonthPercentageChange = (
  currentMonthData: number,
  previousMonthData: number
): MonthPercentageChange => {
  if (previousMonthData === 0) {
    return currentMonthData === 0
      ? {
          changePercentage: 0,
          trendDescription: TrendDescription.Increase_Over_The_Last_Month,
          trendIcon: <TrendUp />,
        }
      : {
          changePercentage: 100,
          trendDescription: TrendDescription.Increase_Over_The_Last_Month,
          trendIcon: <TrendUp />,
        };
  }

  const changePercentage =
    ((currentMonthData - previousMonthData) / previousMonthData) * 100;
  const trendDescription =
    changePercentage > 0
      ? TrendDescription.Increase_Over_The_Last_Month
      : TrendDescription.Decrease_Over_The_Last_Month;

  const trendIcon =
    trendDescription === TrendDescription.Increase_Over_The_Last_Month ? (
      <TrendUp />
    ) : (
      <TrendDownIcon />
    );
  return { changePercentage, trendDescription, trendIcon };
};
