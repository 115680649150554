import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EscalationsServices } from '../Apis/EscalationsServices';
import { RootState } from '../store';
import { EscalationsDto } from '../types/escalationsType';
import { GetEscalationRequest } from '../types/request/escalationsRequest';

export interface EscalationsSliceProps {
  escalations: EscalationsDto[];
  totalItems: number;
  loading: boolean;
  error: string | null;
}

const initialState: EscalationsSliceProps = {
  escalations: [],
  loading: false,
  error: null,
  totalItems: 0,
};

export const fetchEscalations = createAsyncThunk(
  'escalations/list',
  async (request: Partial<GetEscalationRequest>) => {
    const response = await EscalationsServices.getEscalations(request);
    return response;
  }
);

const escalationsSlice = createSlice({
  name: 'escalations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(fetchEscalations.pending, state => {
        state.loading = true;
        state.escalations = [];
        state.error = null;
      })
      .addCase(fetchEscalations.fulfilled, (state, action) => {
        const escalationsList = [...action.payload.records];

        state.loading = false;
        state.escalations = escalationsList;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchEscalations.rejected, (state, action) => {
        state.loading = false;
        state.escalations = [];
        state.totalItems = 0;
        state.error = action.error.message;
      });
  },
});

export const getEscalationsState = (state: RootState) => state.escalations;
export default escalationsSlice;
