import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import RegionPerformance from './components/RegionPerformance';
import ChannelPerformance from './components/ChannelPerformance';
import BucketPerformance from './components/BucketPerformance';
import RoleBaseSegmentPerformance from '../../components/RoleBaseSegmentPerformance';
import { useParams } from 'react-router-dom';
import { GraphTypeEnum } from '../../AnalyticsDashboard';
import CollectionTeamGraph from '../../components/CollectionTeamGraph';
import {
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
} from '../../../../features/analyticsDashboardSlice';
import { DateUtility } from '../../../../utils/DateUtlility';

const CollectionValueContainer = () => {
  const { viewType, calenderType, profitType } = useParams();

  const collectionTeamGraph = useMemo(() => {
    const request = {
      analyticsQueryType: AnalyticsQueryTypeEnum.BUCKET_PERFORMANCE,
      params: {
        fromDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        isMonthToDate: 'false',
        isYearToDate: 'false',
        type: profitType,
      },
    };
    return (
      <CollectionTeamGraph
        request={request}
        selectedCollectionProfitType={profitType}
        calenderType={calenderType as CelenderTypeEmun}
        headerText="Bucket Performance"
      />
    );
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {viewType === GraphTypeEnum.COLLECTION_VALUE ? (
            collectionTeamGraph
          ) : (
            <BucketPerformance />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChannelPerformance />
        </Grid>

        <Grid item xs={12}>
          <RegionPerformance />
        </Grid>
        <Grid item xs={12}>
          <RoleBaseSegmentPerformance
            selectedCollectionProfitType={profitType}
            calenderType={calenderType}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionValueContainer;
