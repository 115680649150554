import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import { getIncentivesGamificationsState } from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppSelector } from '../../../store';
import { CollectionType, RuleProps } from '../../../types/incentives';
import { BasePolicyRangeProps } from '../../../types/request/incentives';
import { BucketEnum } from '../../../types/targets';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  rules: Partial<RuleProps>;
}
const ViewBaseIncentive = ({ loading, rules }: IProps) => {
  const { policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );
  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Collection type'}>
            {rules?.collectionType
              ? getTextLoadingWrapper(
                  toLowerCase(getPolicyValue(rules.collectionType)),
                  loading
                )
              : '--'}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Payout metric'}>
            {rules?.payoutMetric
              ? getTextLoadingWrapper(
                  toLowerCase(getPolicyValue(rules.payoutMetric)),
                  loading
                )
              : '--'}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Case type'}>
            {rules?.caseType
              ? getTextLoadingWrapper(
                  getPolicyValue(rules?.caseType?.join(', ')),
                  loading
                )
              : '--'}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Account type'}>
            {rules?.accountType
              ? getTextLoadingWrapper(
                  getPolicyValue(rules?.accountType?.join(', ')),
                  loading
                )
              : '--'}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {rules.basePayoutRange.length
        ? rules?.basePayoutRange.map(
            (baseIncentive: BasePolicyRangeProps, index: number) => (
              <Stack gap={1} key={index}>
                <VegaText
                  text={`Perameter ${index + 1}`}
                  fontWeight={500}
                  fontSize={'0.75rem'}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5} lg={5}>
                    {rules?.collectionType === CollectionType.RESOLUTION ? (
                      <Stack direction={'row'} gap={1}>
                        <Stack sx={{ width: '50%' }}>
                          <VegaFormInputField label={'min value'}>
                            {getTextLoadingWrapper(
                              `${baseIncentive.parameterMin}`,
                              loading
                            )}
                          </VegaFormInputField>
                        </Stack>
                        <Stack sx={{ width: '50%' }}>
                          <VegaFormInputField label={'max value'}>
                            {getTextLoadingWrapper(
                              `${baseIncentive.parameterMax}`,
                              loading
                            )}
                          </VegaFormInputField>
                        </Stack>
                      </Stack>
                    ) : rules?.collectionType ===
                        CollectionType.NO_OF_CASES_NORMALIZED &&
                      policyDetailsState?.bucketsHavingRules?.includes(
                        BucketEnum.NPA
                      ) ? (
                      <Stack direction={'row'} gap={1} width={'100%'}>
                        <Stack sx={{ width: '33.33%' }}>
                          <VegaFormInputField label={'parameter type'}>
                            {getTextLoadingWrapper(
                              `${baseIncentive.parameterDefinitionType}`,
                              loading
                            )}
                          </VegaFormInputField>
                        </Stack>

                        <Stack
                          sx={{
                            width: '66.66%',
                            display:
                              baseIncentive.parameterDefinitionType !==
                                'FIXED' && 'none',
                          }}
                        >
                          <VegaFormInputField label={'value'}>
                            {getTextLoadingWrapper(
                              `${baseIncentive.parameterExact}`,
                              loading
                            )}
                          </VegaFormInputField>
                        </Stack>

                        <Stack
                          sx={{
                            width: '66.66%',
                            display:
                              baseIncentive.parameterDefinitionType !==
                                'RANGE' && 'none',
                          }}
                          direction={'row'}
                          gap={1}
                        >
                          <Stack sx={{ width: '50%' }}>
                            <VegaFormInputField label={'min value'}>
                              {getTextLoadingWrapper(
                                `${baseIncentive.parameterMin}`,
                                loading
                              )}
                            </VegaFormInputField>
                          </Stack>
                          <Stack sx={{ width: '50%' }}>
                            <VegaFormInputField label={'max value'}>
                              {getTextLoadingWrapper(
                                `${baseIncentive.parameterMax}`,
                                loading
                              )}
                            </VegaFormInputField>
                          </Stack>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack sx={{ width: '100%' }}>
                        <VegaFormInputField label={'Value'}>
                          {getTextLoadingWrapper(
                            `${baseIncentive.parameterExact}`,
                            loading
                          )}
                        </VegaFormInputField>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField
                      label={
                        rules.collectionType ===
                          CollectionType.NO_OF_CASES_NORMALIZED &&
                        policyDetailsState?.bucketsHavingRules?.includes(
                          BucketEnum.NPA
                        )
                          ? '% of pos'
                          : 'Incentive Amount'
                      }
                    >
                      {getTextLoadingWrapper(
                        ` ${baseIncentive.payoutAmountValue}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  {rules.collectionType ===
                    CollectionType.NO_OF_CASES_NORMALIZED &&
                    policyDetailsState?.bucketsHavingRules?.includes(
                      BucketEnum.NPA
                    ) && (
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Stack direction={'row'} gap={1}>
                          <Stack sx={{ width: '50%' }}>
                            <VegaFormInputField label={'payout Min'}>
                              {getTextLoadingWrapper(
                                `₹ ${baseIncentive.payoutAmountMin}`,
                                loading
                              )}
                            </VegaFormInputField>
                          </Stack>
                          <Stack sx={{ width: '50%' }}>
                            <VegaFormInputField label={'payout Max'}>
                              {getTextLoadingWrapper(
                                `₹ ${baseIncentive.payoutAmountMax}`,
                                loading
                              )}
                            </VegaFormInputField>
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                </Grid>
              </Stack>
            )
          )
        : ''}
    </Stack>
  );
};

export default ViewBaseIncentive;

const getPolicyValue = (policyValue: string | number) => {
  const value =
    typeof policyValue === 'number' ? policyValue.toString() : policyValue;
  if (!value) return '--';
  if (value.length) {
    return value;
  } else {
    return '--';
  }
};

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return (
      <VegaText text={value ?? '--'} fontWeight={500} fontSize={'0.875rem'} />
    );
  }
};
