/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import { TrashIcon } from '../../../components/Icons/Icons';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaDeleteDialog from '../../../components/common/VegaDeleteDialog';
import { VegaSwitch } from '../../../components/common/VegaSwitch';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import { RED } from '../../../constants/style';
import {
  fetchMinVisitList,
  getMinVisitsState,
  setOpenDeleteDialog,
} from '../../../features/minVisitsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  CreateMinVisitRuleRequest,
  MinVisitListProps,
} from '../../../types/request/minVisitsRequest';
import { VegaUserStatus } from '../../../types/user';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import CreateRuleForFormDrawer from './CreateRuleForFormDrawer';

const ListOfRules = () => {
  const { openDeleteDialog, loading, totalItems, minVisits } =
    useAppSelector(getMinVisitsState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [ruleDetails, setRuleDetails] = useState<Partial<MinVisitListProps>>(
    {}
  );

  const onDeleteRule = (rule: MinVisitListProps) => {
    setRuleDetails(rule);
    dispatch(setOpenDeleteDialog(true));
  };

  const updateStatusForRule = (rule: MinVisitListProps) => {
    const ruleObj = {
      id: rule.id,
      status:
        rule.status === VegaUserStatus.ACTIVE
          ? VegaUserStatus.INACTIVE
          : VegaUserStatus.ACTIVE,
    } as CreateMinVisitRuleRequest;
    MinVisitsSevices.updateMinVisitRule(ruleObj)
      .then(() => {
        setSnackbar('update rule successfully !!!');
        dispatch(fetchMinVisitList({ page: 0, pageSize: 10 }));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  const deleteMinVisitsRule = () => {
    const ruleObj = {
      id: ruleDetails.id,
      status:
        ruleDetails.status === VegaUserStatus.ACTIVE
          ? VegaUserStatus.INACTIVE
          : VegaUserStatus.ACTIVE,
    } as CreateMinVisitRuleRequest;
    MinVisitsSevices.deleteMinVisitRule(ruleObj)
      .then(() => {
        setSnackbar('Rule deleted Successfully !!');
        dispatch(fetchMinVisitList({ page: 0, pageSize: 10 }));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  useEffect(() => {
    dispatch(fetchMinVisitList({ page, size: pageSize }));
  }, [dispatch, page, pageSize]);

  return (
    <>
      <VegaDataGrid
        data={minVisits}
        columns={getColumnDefinition({
          onDeleteRule,
          updateStatusForRule,
        })}
        idColumn="id"
        loading={loading}
        paginationMode="server"
        onPageChange={page => setPage(page)}
        onPageSizeChange={pageSize => setPageSize(pageSize)}
        rowCount={totalItems}
      />
      <CreateRuleForFormDrawer />
      <VegaDeleteDialog
        text="Are you sure want to delete this Rule?"
        open={openDeleteDialog}
        onClose={() => dispatch(setOpenDeleteDialog(false))}
        onDelete={() => {
          deleteMinVisitsRule();
          dispatch(setOpenDeleteDialog(false));
        }}
      />
    </>
  );
};

export default ListOfRules;

const getColumnDefinition = (data: {
  onDeleteRule: (rule: MinVisitListProps) => void;
  updateStatusForRule: (rule: MinVisitListProps) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'branch',
      headerName: 'Branch',
      flex: 1,
      minWidth: 100,
      renderCell: props => {
        const rule = props.row as MinVisitListProps;
        return (
          <VegaText
            fontSize={14}
            fontWeight={500}
            text={toLowerCase(rule.branch)}
          />
        );
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const rule = props.row as MinVisitListProps;
        return (
          <VegaText
            fontSize={14}
            fontWeight={500}
            text={toLowerCase(rule.region)}
          />
        );
      },
    },

    {
      field: 'minVisits',
      headerName: 'No. of Visits',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const rule = props.row as MinVisitListProps;
        return (
          <VegaSwitch
            checked={rule.status === VegaUserStatus.ACTIVE ? true : false}
            onClick={() => data.updateStatusForRule(rule)}
          />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const rule = props.row as MinVisitListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text=""
              startIcon={<TrashIcon />}
              sx={{
                bgcolor: '#F8E8E8',
                color: RED.red,
                ':hover': {
                  bgcolor: '#F8E8E8',
                  color: RED.red,
                },
              }}
              onClick={() => data.onDeleteRule(rule)}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
