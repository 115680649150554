import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoanService } from '../../Apis/LoanServices';
import {
  getTeleAgent,
  setOpenContactDetailsDrawer,
} from '../../features/teleAgentSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { UpdateContactRequest } from '../../types/request/loan';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmButton from '../common/LmButton';
import LmTextField from '../common/LmTextField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import { StringUtility } from '../../utils/StringUtility';

interface UpdateContactDtoProps {
  mobileNo: string;
  customerNo: string | number;
  customerName: string;
  loanId: string;
}

// interface IProps extends IActionProps {}

const UpdateContactDetailsFormDrawer = () => {
  const { loanData, openContactDetailsDrawer } = useAppSelector(getTeleAgent);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [contactDetails, setContactDetails] =
    useState<UpdateContactDtoProps | null>(null);

  const handleChange = (key: keyof UpdateContactDtoProps, value: string) => {
    setContactDetails({ ...contactDetails, [key]: value });
  };

  const handleContactNumberChange = (value: string) => {
    const numberRegex = /^\d*$/;

    if (value === '' || (numberRegex.test(value) && value.length <= 10)) {
      handleChange('mobileNo', value);
    }
  };

  const onUpdateContact = async () => {
    setLoading(true);
    try {
      await LoanService.updateContact(contactDetails as UpdateContactRequest);
      setSnackbar('Update Contact Successfully !!!');
      setLoading(false);
      dispatch(setOpenContactDetailsDrawer(false));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
      dispatch(setOpenContactDetailsDrawer(false));
    }
  };

  useEffect(() => {
    if (
      openContactDetailsDrawer &&
      loanData.length &&
      loanData[0]?.customerDetails
    ) {
      const customerNo = loanData[0]?.customerDetails[0]?.customerNo ?? '';
      const loanId = loanData[0]?.customerDetails[0]?.loanId ?? '';
      const mobileNo = loanData[0]?.customerDetails[0]?.mobileNo ?? '';
      const customerName = loanData[0]?.customerDetails[0]?.customerName ?? '';
      setContactDetails({
        customerName,
        mobileNo,
        loanId,
        customerNo,
      } as UpdateContactDtoProps);
    }
  }, [openContactDetailsDrawer, loanData]);

  return (
    <VegaDrawer
      open={openContactDetailsDrawer}
      title={'Update Contact Detail'}
      onClose={() => {
        setContactDetails(null);
        dispatch(setOpenContactDetailsDrawer(false));
      }}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Update"
              onClick={onUpdateContact}
              disabled={
                !StringUtility.validatePhoneNumber(contactDetails?.mobileNo)
              }
              loading={loading}
            />
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LmTextField
              header="Loan id"
              disabled
              value={contactDetails?.loanId}
            />
          </Grid>
          <Grid item xs={12}>
            <LmTextField
              header="name"
              disabled
              value={contactDetails?.customerName}
            />
          </Grid>
          <Grid item xs={12}>
            <LmTextField
              header="Contact Number"
              value={contactDetails?.mobileNo}
              type="number"
              onChange={e => handleContactNumberChange(e.target.value)}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default UpdateContactDetailsFormDrawer;
