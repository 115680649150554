import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { NumberOfVisitAndNumberOfAccountsAgentWiseDataType } from './FieldAppDashboard';
import { useClientAuth } from '../../providers/ClientProvider';

type PropTypes = {
  data: NumberOfVisitAndNumberOfAccountsAgentWiseDataType[];
  loading: boolean;
};
const NoOfVisitsPerAgentGraph = ({ data, loading }: PropTypes) => {
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const modifiedData = Object.values(data).map(
    (value: NumberOfVisitAndNumberOfAccountsAgentWiseDataType) => ({
      agentId: getUserName(value.agentId),
      count: value?.count,
      // noOfAccounts: value?.numberofaccount,
    })
  );

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: modifiedData.map(value => value.agentId),
    },
    yaxis: {
      title: {
        text: 'No Of Visits',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
  };

  const seriesData = [
    {
      name: 'No of Visits',
      data: modifiedData.map(value => value.count),
    },
    // {
    //   name: 'No of Accounts',
    //   data: modifiedData.map(value => value.noOfVisits),
    // },
  ];

  return (
    <>
      <GraphWrapper title="No of Visits Per Agent">
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Chart
              options={options}
              series={seriesData}
              type="bar"
              height={'100%'}
            />
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default NoOfVisitsPerAgentGraph;
