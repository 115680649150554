import { Stack } from '@mui/material';
import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import VegaText from '../../../../components/common/VegaText';
import {
  getIncentiveScoreCard,
  initialBucketWiseDetails,
} from '../../../../features/IncentivesGamificationsSlice/incentiveScoreCardSlice';
import { useAppSelector } from '../../../../store';
import BucketWiseIncentiveColumn from '../columns/BucketWiseIncentiveColumn';

const ListOfBucketWiseIncentive = () => {
  const { scoreCardDetails, loading } = useAppSelector(getIncentiveScoreCard);

  return (
    <>
      <Stack
        sx={{
          bgcolor: 'white',
        }}
      >
        <Stack mb={'1rem'}>
          <VegaText
            text={'Bucket-wise Incentive'}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
        <VegaDataGrid
          idColumn="bucket"
          columns={BucketWiseIncentiveColumn()}
          data={
            scoreCardDetails?.bucketWiseDetails ?? [...initialBucketWiseDetails]
          }
          loading={loading}
          hideFooter
        />
      </Stack>
    </>
  );
};

export default ListOfBucketWiseIncentive;
