import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import {
  EscalationsDto,
  ExpenseEscalationDto,
} from '../../../types/escalationsType';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import { AttachmentIcon } from '../../Icons/Icons';
import VegaButton from '../../common/VegaButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';
import {
  getColorForLevel,
  getColorForStatus,
} from '../EscalationCommonFunctions';

interface IColumnProps {
  selectedStatus: number;
  onApproveDialogOpen?: (escalations: EscalationsDto) => void;
  onRejectDialogOpen?: (escalations: EscalationsDto) => void;
}

export const ExpenseManagementColumn = ({
  selectedStatus,
  onApproveDialogOpen,
  onRejectDialogOpen,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'reportId',
      headerName: 'Report ID',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const expenseEscalation =
          escalations.escalationObject as ExpenseEscalationDto;
        const last4Digits = expenseEscalation?.reportId?.substring(
          Math.max(0, expenseEscalation?.reportId?.length - 4)
        );
        const displayText = `#${last4Digits ?? ''}`;

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'dateOfExpense',
      headerName: 'Date of Expense',
      hide: selectedStatus === 1,
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const expenseEscalation =
          escalations.escalationObject as ExpenseEscalationDto;
        const dateOfExpense = expenseEscalation.dateOfExpense
          ? DateUtility.formatStringToDDMMYYYY(expenseEscalation.dateOfExpense)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={dateOfExpense}
          />
        );
      },
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const expenseEscalation =
          escalations.escalationObject as ExpenseEscalationDto;
        const expenseType = expenseEscalation.expenseType
          ? expenseEscalation.expenseType
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={expenseType}
          />
        );
      },
    },

    {
      field: 'description',
      headerName: 'Description',
      flex: 0.8,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const expenseEscalation =
          escalations.escalationObject as ExpenseEscalationDto;
        const description = expenseEscalation.description
          ? expenseEscalation.description
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={description}
          />
        );
      },
    },
    {
      field: 'totalExpense',
      headerName: 'Total Expense',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const expenseEscalation =
          escalations.escalationObject as ExpenseEscalationDto;
        const amount = expenseEscalation.amount
          ? StringUtility.formatToINR(expenseEscalation.amount)
          : StringUtility.formatToINR(0);
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={amount}
          />
        );
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      flex: 0.7,
      renderCell: () => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <AttachmentIcon />
            <VegaText
              text={'Attachment'}
              fontWeight={500}
              color={'#1047DC'}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'escalationLevel',
      headerName: 'Escalation Level',
      hide: selectedStatus === 1,
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaPill
            text={escalations.escalationLevel}
            backgroundColor={
              getColorForLevel(escalations.escalationLevel)?.LIGHT
            }
            textColor={getColorForLevel(escalations.escalationLevel)?.DARK}
            borderColor={getColorForLevel(escalations.escalationLevel)?.DARK}
          />
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      hide: selectedStatus === 0,
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaPill
            text={escalations.escalationStatus}
            backgroundColor={
              getColorForStatus(escalations.escalationStatus)?.LIGHT
            }
            textColor={getColorForStatus(escalations.escalationStatus)?.DARK}
            borderColor={getColorForStatus(escalations.escalationStatus)?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      hide: selectedStatus === 1,
      flex: 1,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <Stack direction={'row'} gap={2}>
            <VegaButton
              text="Approve"
              sx={{
                backgroundColor: COLOR.GREEN.LIGHT,
                color: COLOR.GREEN.DARK,
                ':hover': {
                  backgroundColor: COLOR.GREEN.LIGHT,
                  color: COLOR.GREEN.DARK,
                },
              }}
              onClick={() => onApproveDialogOpen(escalations)}
            />
            <VegaButton
              text="Reject"
              sx={{
                backgroundColor: COLOR.RED.LIGHT,
                color: COLOR.RED.DARK,
                ':hover': {
                  backgroundColor: COLOR.RED.LIGHT,
                  color: COLOR.RED.DARK,
                },
              }}
              onClick={() => onRejectDialogOpen(escalations)}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
