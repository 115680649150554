import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../components/common/VegaText';
import { VisitDetailsDto } from '../../types/request/visit';
import { DateUtility } from '../../utils/DateUtlility';

export function LocationTrackingVisitListColumn() {
  const COL_DEF: GridColumns<VisitDetailsDto> = [
    {
      field: 'srNo',
      headerName: 'sr No',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail?.srNo ?? ''}
          />
        );
      },
    },

    {
      field: 'lan',
      headerName: 'LAN',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail?.loanId ?? ''}
          />
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 0.7,
      minWidth: 300,
      renderCell: props => {
        const locationTrackingDetail = props.row;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail?.customerName ?? ''}
          />
        );
      },
    },
    {
      field: 'trailUpdated',
      headerName: 'Trail Updated',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={DateUtility.getTimeFromDate(
              new Date(locationTrackingDetail?.visitDate ?? '')
            )}
          />
        );
      },
    },
  ];
  return COL_DEF;
}

export default LocationTrackingVisitListColumn;
