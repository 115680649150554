import { Divider, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import LmDialog from '../../../../components/common/LmDialog';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect from '../../../../components/common/VegaSelect';
import VegaText from '../../../../components/common/VegaText';
import { useOts } from '../../../../providers/OtsProvider';
import { StringUtility } from '../../../../utils/StringUtility';
import { NpvCalculator } from '../../components/NpvCalculator';

type Props = {
  open: boolean;
  onClose: () => void;
  otsId?: string;
  onSubmit: (otsId: string, npvData: Partial<ComputeNpvFormData>) => void;
};

export type ComputeNpvFormData = {
  name: string;
  lan: string;
  outstanding: number;
  principal: number;
  roi: number;
  settlement: number;
  realisableValue: number;
  marketValue: number;
  propertyDocsAvailable: string;
  distressValue: number;
  npvOfDistressValue: number;
  tos: number;
  pvOfTos: number;
  propertyValueRealisation: number;
  fixedRealisationCost: number;
  variableRealisationCost: number;
  totalRealisationCost: number;
  pvRealisationCost: number;
  discountedRoi: number;
  saleCompletionPeriod: number;
};

enum FormField {
  MarketValue = 'marketValue',
  RealisableValue = 'realisableValue',
  PropertyDocsAvailable = 'propertyDocsAvailable',
}

function OtsNpvCalculations({ open, onClose, onSubmit, otsId }: Props) {
  const { ots, loan } = useOts();
  const [formData, setFormData] = useState<Partial<ComputeNpvFormData>>({});

  function onSubmitClick() {
    if (!otsId) return;
    onSubmit(otsId, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    setFormData({});
  }

  const getCustomerDetails = () => {
    if (loan?.customerDetails?.length > 0) {
      return loan?.customerDetails[0];
    }
  };

  function updateFormData(type: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  const parseNumberValue = (value: any) => {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    return isValidNumber ? parsedNumber : undefined;
  };

  function computeValues() {
    const computed = new NpvCalculator(formData).calculate();

    setFormData(prev => {
      return {
        ...prev,
        distressValue: computed.distressValue,
        npvOfDistressValue: computed.npvOfDistressValue,
        tos: computed.tosBasedMaxValue,
        pvOfTos: computed.pvOfTosBasedMaxValue,
        propertyValueRealisation: computed.propertyValueRealization,
        fixedRealisationCost: computed.realisationCosts.fixed,
        variableRealisationCost: computed.realisationCosts.variable,
        totalRealisationCost: computed.realisationCosts.total,
        pvRealisationCost: computed.realisationCosts.pv,
      };
    });
  }

  const canEnableSubmit = () => {
    const isDistressValueValid = (formData.distressValue ?? 0) > 0;
    // const isNpvOfDistressValueValid = (formData.npvOfDistressValue ?? 0) > 0;
    // const isTosValid = (formData.tos ?? 0) > 0;
    // const isPvOfTosBasedMaxValueValid = (formData.pvOfTos ?? 0) > 0;
    // const isPropertyValueRealisationValid =
    //   (formData.propertyValueRealisation ?? 0) > 0;
    // const isFixedRealisationCostValid =
    //   (formData.fixedRealisationCost ?? 0) > 0;
    // const isVariableRealisationCostValid =
    //   (formData.variableRealisationCost ?? 0) > 0;
    // const isTotalRealisationCostValid =
    //   (formData.totalRealisationCost ?? 0) > 0;
    // const isPvRealisationCostValid = (formData.pvRealisationCost ?? 0) > 0;

    return isDistressValueValid;
    //    && isNpvOfDistressValueValid
    //    &&
    //   isTosValid &&
    //   isPvOfTosBasedMaxValueValid &&
    //   isPropertyValueRealisationValid &&
    //   isFixedRealisationCostValid &&
    //   isVariableRealisationCostValid &&
    //   isTotalRealisationCostValid &&
    //   isPvRealisationCostValid
  };

  useEffect(() => {
    if (ots && loan && open == true) {
      setFormData(prev => {
        return {
          ...prev,
          name: getCustomerDetails()?.customerName,
          lan: loan?.loanId,
          outstanding: loan?.lmsVariables?.outstandingAmount ?? 0,
          principal: loan?.lmsVariables?.principalDue ?? 0,
          roi: loan?.loanMaster?.roi ?? 0,
          settlement: ots?.settlementAmount ?? 0,
          realisableValue: ots?.distressedValuationAmount ?? 0,
          marketValue: ots?.valuationAmount ?? 0,
          discountedRoi: 0.145,
          fixedRealisationCost: 30000,
          saleCompletionPeriod: 30,
        };
      });
    }
  }, [ots, loan, open]);

  return (
    <LmDialog
      fullWidth
      maxWidth="md"
      open={open}
      handleClose={() => {
        handleClose();
      }}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            variant="outlined"
            fullWidth
            text="Compute"
            onClick={() => computeValues()}
          />
          <VegaButton
            disabled={canEnableSubmit() == false}
            text="Submit"
            fullWidth
            onClick={onSubmitClick}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Compute NPV"
            fontWeight={600}
            fontSize={'1.0625rem'}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" gap={'1rem'}>
        <Grid container gap={2}>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Name'}>
              <LmTextField disabled value={formData.name} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'LAN'}>
              <LmTextField disabled value={formData.lan} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Total Outstanding'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.outstanding)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Principal Outstanding'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.principal)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'ROI'}>
              <LmTextField disabled value={formData.roi} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Settlement Amount'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.settlement)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Realisable Value'}>
              <LmTextField
                value={formData.realisableValue}
                onChange={e =>
                  updateFormData(
                    FormField.RealisableValue,
                    parseNumberValue(e.target.value)
                  )
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Market Value'}>
              <LmTextField
                value={formData.marketValue}
                onChange={e =>
                  updateFormData(
                    FormField.MarketValue,
                    parseNumberValue(e.target.value)
                  )
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Property Docs available'}>
              <VegaSelect
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                value={formData.propertyDocsAvailable}
                onChange={e => {
                  updateFormData(
                    FormField.PropertyDocsAvailable,
                    e.target.value as boolean
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>

        <Divider />

        <Grid container gap={2}>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Distress Value'}>
              <LmTextField disabled value={formData.distressValue} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Npv Of Distress Value'}>
              <LmTextField disabled value={formData.npvOfDistressValue} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Tos Based Max Value'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.tos)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Pv Tos Based Max Value'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.pvOfTos)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Property Value Realisation'}>
              <LmTextField disabled value={formData.roi} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Fixed Realisation Cost'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.fixedRealisationCost)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Variable Realisation Cost'}>
              <LmTextField
                value={StringUtility.formatToINR(
                  formData.variableRealisationCost
                )}
                disabled
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Total Realisation Cost'}>
              <LmTextField
                value={StringUtility.formatToINR(formData.totalRealisationCost)}
                disabled
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={3.5}>
            <VegaFormInputField label={'Pv-Realisation Cost'}>
              <LmTextField
                disabled
                value={StringUtility.formatToINR(formData.pvRealisationCost)}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Stack>
    </LmDialog>
  );
}

export default OtsNpvCalculations;
