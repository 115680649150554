import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { PerformanceServices } from '../../../../Apis/PerformanceServices';
import { BUCKET_MOVEMENT } from '../../../../constants/athenaQueryId';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { BucketMovementCountResponse } from '../../../../types/performanceType';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

type DataPoint = {
  x: string;
  y: string;
};

type HEATMAP = {
  name: string;
  data: DataPoint[];
};

enum BucketValue {
  X = 'X',
  OneToThirty = '1 to 30',
  ThirtyOneToSixty = '31 to 60',
  SixtyOneToNinety = '61 to 90',
  Normal = 'Normal',
  NPA = 'NPA',
}

const CollectedVSCollectableGraph = () => {
  const { setSnackbar } = useSnackbar();
  const [bucketMovementCount, setBucketMovementCount] = useState<HEATMAP[]>([]);

  const updateHeatmapData = (
    movement: BucketMovementCountResponse[],
    heatmapData: HEATMAP
  ) => {
    movement.forEach(i => {
      const index = heatmapData.data.findIndex(
        element => element.x === i.prev_bucket
      );
      if (index !== -1) {
        heatmapData.data[index].y = i.movement_count.toString();
      }
    });
  };

  const filterMovementResponse = (
    response: BucketMovementCountResponse[],
    bucketValue
  ) => {
    return response.filter(i => i.bucket === bucketValue);
  };

  const generateBucketHeatmapData = (bucketName: BucketValue): HEATMAP => {
    return {
      name: bucketName,
      data: [
        { x: BucketValue.X, y: '0' },
        { x: BucketValue.OneToThirty, y: '0' },
        { x: BucketValue.ThirtyOneToSixty, y: '0' },
        { x: BucketValue.SixtyOneToNinety, y: '0' },
        { x: BucketValue.NPA, y: '0' },
        { x: BucketValue.Normal, y: '0' },
      ],
    };
  };

  const getBucketMovementCountAthenaQuery = async () => {
    try {
      const response = await PerformanceServices.getAthenaQueries({
        queryId: BUCKET_MOVEMENT,
        params: {},
      });
      const bucketMovementResponse = response as BucketMovementCountResponse[];

      const bucketXMovement = filterMovementResponse(
        bucketMovementResponse,
        BucketValue.X
      );
      const bucket1To30Movement = filterMovementResponse(
        bucketMovementResponse,
        BucketValue.OneToThirty
      );
      const bucket31To60Movement = filterMovementResponse(
        bucketMovementResponse,
        BucketValue.ThirtyOneToSixty
      );
      const bucket61to90Movement = filterMovementResponse(
        bucketMovementResponse,
        BucketValue.SixtyOneToNinety
      );
      const bucketNpaMovement = filterMovementResponse(
        bucketMovementResponse,
        BucketValue.NPA
      );
      const bucketNormalMovement = filterMovementResponse(
        bucketMovementResponse,
        BucketValue.Normal
      );

      const bucketXHeatmapData: HEATMAP = generateBucketHeatmapData(
        BucketValue.X
      );
      const bucket1to30HeatmapData: HEATMAP = generateBucketHeatmapData(
        BucketValue.OneToThirty
      );
      const bucket31to60HeatmapData: HEATMAP = generateBucketHeatmapData(
        BucketValue.ThirtyOneToSixty
      );
      const bucket61to90HeatmapData: HEATMAP = generateBucketHeatmapData(
        BucketValue.SixtyOneToNinety
      );
      const bucketNPAHeatmapData: HEATMAP = generateBucketHeatmapData(
        BucketValue.NPA
      );
      const bucketNormalHeatmapData: HEATMAP = generateBucketHeatmapData(
        BucketValue.Normal
      );

      updateHeatmapData(bucketXMovement, bucketXHeatmapData);
      updateHeatmapData(bucket1To30Movement, bucket1to30HeatmapData);
      updateHeatmapData(bucket31To60Movement, bucket31to60HeatmapData);
      updateHeatmapData(bucket61to90Movement, bucket61to90HeatmapData);
      updateHeatmapData(bucketNpaMovement, bucketNPAHeatmapData);
      updateHeatmapData(bucketNormalMovement, bucketNormalHeatmapData);

      const finalBucketMovementCount = [
        bucketXHeatmapData,
        bucket1to30HeatmapData,
        bucket31to60HeatmapData,
        bucket61to90HeatmapData,
        bucketNPAHeatmapData,
        bucketNormalHeatmapData,
      ];

      setBucketMovementCount(finalBucketMovementCount);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.1,
        colorScale: {
          ranges: [
            { from: 0, to: 20000, name: 'very low', color: '#c0e0c3' },
            { from: 20001, to: 40000, name: 'low', color: '#90c695' },
            { from: 40001, to: 60000, name: 'medium', color: '#50bccd' },
            { from: 60001, to: 80000, name: 'high', color: '#fba969' },
            { from: 80001, to: Infinity, name: 'very high', color: '#e08283' },
          ],
        },
      },
    },
    dataLabels: { enabled: true, style: { colors: ['#104c6d'] } },
    legend: {
      show: false,
    },
    yaxis: { title: { text: 'Opening Bucket' } },
    xaxis: {
      title: { text: 'Closing Bucket' },
      labels: {
        show: true,
        trim: true,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: '12px',
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  useEffect(() => {
    BUCKET_MOVEMENT.length && getBucketMovementCountAthenaQuery();
  }, []);
  return (
    <Stack
      p={3}
      sx={{
        bgcolor: 'white',
        borderRadius: '12px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
      }}
      gap={2}
    >
      <Chart options={options} series={bucketMovementCount} type="heatmap" />
    </Stack>
  );
};

export default CollectedVSCollectableGraph;
