import { useState } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import VegaButton from '../../../../components/common/VegaButton';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaFileSelect, {
  AcceptedExtension,
} from '../../../../components/common/VegaFileSelect';
import LmTextField from '../../../../components/common/LmTextField';
import { OtsService } from '../../../../Apis/OtsService';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import { useDrawer } from '../../../../hooks/useDrawer';
import RejectOtsDialog from './RejectOtsDialog';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { AgendaCaseStatus, OtsAction } from '../../../../types/ots';
import { VegaFileSelectOption } from '../../../../components/Allocation/BulkAllocationFileSelect';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import { AgendaService } from '../../../../Apis/AgendaService';

enum FormField {
  AdditionalDocument = 'additionDocument',
  Comment = 'comment',
}

export type UpdateOtsForm = {
  additionDocument: VegaFileSelectOption;
  comment: string;
};

const ACCEPTED_EXTENSIONS = [
  AcceptedExtension.JPG,
  AcceptedExtension.JPEG,
  AcceptedExtension.PNG,
  AcceptedExtension.PDF,
];

function OtsPostCommitteActionView() {
  const { setSnackbar } = useSnackbar();
  const { agendaCase, fetchAgendaCase, ots } = useOts();
  const { user } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<UpdateOtsForm>>({});
  const {
    open: openRejectionDialog,
    close: closeRejectionDialog,
    isOpen: isRejectionDialogOpen,
  } = useDrawer();

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function updateOts() {
    try {
      const documents = [];
      const comments = [];
      if (formData?.additionDocument?.value) {
        documents.push(formData.additionDocument.value);
      }
      if (formData.comment) {
        comments.push(formData.comment);
      }
      await OtsService.updateOtsRequest({
        id: ots.id,
        additionalDocuments: documents,
        comments: comments,
        userId: user?.id,
      });
      setFormData({
        comment: undefined,
        additionDocument: undefined,
      });
      fetchAgendaCase({ id: agendaCase.id, fetchAgenda: true, fetchOts: true });
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function onAcceptClick() {
    try {
      setLoading(true);
      await AgendaService.takeActionOnCaseByChair({
        caseId: agendaCase.id,
        userId: user?.id,
        action: OtsAction.APPROVE,
      });
      onSubmitClick();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onRejectConfirm(reason: string) {
    try {
      setLoading(true);
      await AgendaService.takeActionOnCaseByChair({
        caseId: agendaCase.id,
        userId: user?.id,
        action: OtsAction.REJECT,
        description: reason,
      });
      onSubmitClick();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onSubmitClick() {
    try {
      setLoading(true);
      await AgendaService.submitCaseToHoc({
        caseIds: [agendaCase.id],
      });
      updateOts();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const canShowSubmitButton = () => {
    const submissionStatuses = [
      AgendaCaseStatus.APPROVED,
      AgendaCaseStatus.REJECTED,
    ];
    return submissionStatuses.some(
      item => item == agendaCase?.otsChairDecisionStatus
    );
  };

  return (
    <>
      <OtsDetailsWrapper>
        <Stack>
          <Grid container columnGap={2}>
            <Grid item xs={4}>
              <VegaFormInputField label={'Additional documents'}>
                <VegaFileSelect
                  placeholder={'Select any document'}
                  value={formData.additionDocument}
                  acceptedExtension={ACCEPTED_EXTENSIONS}
                  onFileSelect={file => {
                    updateFormData(FormField.AdditionalDocument, file);
                  }}
                  onDeleteClick={() => {
                    updateFormData(FormField.AdditionalDocument, undefined);
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={4}>
              <VegaFormInputField label={'Comment'}>
                <LmTextField
                  placeholder="Write your comment here"
                  value={formData.comment}
                  onChange={e =>
                    updateFormData(FormField.Comment, e.target.value)
                  }
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
          <AnimatedInputWrapper show={loading} marginTop={'0rem'}>
            <Stack direction="row" justifyContent={'end'}>
              <CircularProgress size={30} />
            </Stack>
          </AnimatedInputWrapper>

          <AnimatedInputWrapper
            show={loading == false && canShowSubmitButton() == false}
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton
                variant="outlined"
                text={'Reject'}
                onClick={() => openRejectionDialog()}
              />
              <VegaButton text={'Accept'} onClick={onAcceptClick} />
            </Stack>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={loading == false && canShowSubmitButton()}
          >
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton text={'Submit'} onClick={onSubmitClick} />
            </Stack>
          </AnimatedInputWrapper>
        </Stack>
      </OtsDetailsWrapper>
      <RejectOtsDialog
        show={isRejectionDialogOpen}
        onClose={function (): void {
          closeRejectionDialog();
        }}
        onReject={function (reason: string): void {
          onRejectConfirm(reason);
        }}
      />
    </>
  );
}

export default OtsPostCommitteActionView;
