export interface SendPaymentLinkBodyProps {
  amount: number;
  mobileNumber: string;
  email: string;
  payeeName: string;
  loanId: string;
  agentId: string;
  relationshipWithCustomer: string;
}

export enum ComparisonType {
  GREATER_THEN = 'GT',
  LESS_THEN = 'LT',
  EQUAL_TO = 'EQ',
  Range = 'Range',
}
