import React, { useEffect, useState } from 'react';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import { useAppSelector } from '../../../store';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import {
  ContactType,
  LoanContactData,
  TypeOfContact,
} from '../../../types/loan';
import { useParams } from 'react-router-dom';
import { LoanService } from '../../../Apis/LoanServices';
import { Stack, Grid } from '@mui/material';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import _ from 'lodash';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';

const ContactDetails = () => {
  const { loanAccountId } = useParams();
  const { setSnackbar } = useSnackbar();
  const { loading } = useAppSelector(getTeleAgent);
  const [contactDetails, setContactDetails] = useState<LoanContactData[]>([]);

  async function fetchData() {
    try {
      const response = await LoanService.getContactDetails({
        loanId: loanAccountId,
        type: ContactType.COMMUNICATION,
      });

      const loanData = await LoanService.getLoanList({
        loanId: loanAccountId,
        includeCustomerDetails: true,
        includeLmsVariables: true,
        includeLoanOutstandingDetails: true,
        includeLoanTransactions: true,
        includeLoanPdcDetails: true,
        restrictedView: true,
      });
      const primaryContacts = loanData.records
        .map((item, index) => {
          const data: LoanContactData = {
            loanId: item.loanId,
            contactNumber: item?.customerDetails[index]?.mobileNo,
            contactHolderName: item.customerDetails[index]?.customerName,
            contactType: TypeOfContact.PRIMARY_CONTACT,
          };
          return data;
        })
        .filter(
          item => item.contactNumber != null && item.contactNumber.length > 0
        );

      const alternateContacts = response?.records
        .map(item => {
          const arr: LoanContactData[] = [];

          if (
            item.mobile1 &&
            item.mobile1 !== primaryContacts[0].contactNumber
          ) {
            const data: LoanContactData = {
              loanId: item.loanId,
              contactNumber: item.mobile1,
              contactHolderName: item.customerName,
              contactType: TypeOfContact.ALTERNATE_CONTACT,
            };
            arr.push(data);
          }

          if (
            item.mobile2 &&
            item.mobile2 !== primaryContacts[0].contactNumber
          ) {
            const data: LoanContactData = {
              loanId: item.loanId,
              contactNumber: item.mobile2,
              contactHolderName: item.customerName,
              contactType: TypeOfContact.ALTERNATE_CONTACT,
            };
            arr.push(data);
          }

          if (
            item.mobile3 &&
            item.mobile3 !== primaryContacts[0].contactNumber
          ) {
            const data: LoanContactData = {
              loanId: item.loanId,
              contactNumber: item.mobile3,
              contactHolderName: item.customerName,
              contactType: TypeOfContact.ALTERNATE_CONTACT,
            };
            arr.push(data);
          }
          return arr;
        })
        .flat()
        .filter(
          item => item.contactNumber != null && item.contactNumber.length > 0
        );

      const uniqueContactNumbers = new Set();
      const uniqueAlternateContacts = alternateContacts.filter(contact => {
        const isDuplicate = uniqueContactNumbers.has(contact.contactNumber);
        uniqueContactNumbers.add(contact.contactNumber);
        return !isDuplicate;
      });

      setContactDetails([...primaryContacts, ...uniqueAlternateContacts]);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  useEffect(() => {
    if (!loanAccountId) return;
    fetchData();
  }, []);

  return (
    <TeleAgentDetailsCard text="Contact Details" isBorder>
      <Stack gap={2}>
        {contactDetails.map((contact, index) => {
          return (
            <Stack
              key={index}
              sx={{
                borderRadius: '12px',
                p: 2,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TeleAgentTextWrapper
                    text={'Contact Type'}
                    value={_.startCase(_.toLower(contact.contactType))}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TeleAgentTextWrapper
                    text={'Contact Holder Name'}
                    value={contact.contactHolderName}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TeleAgentTextWrapper
                    text={'Contact Number'}
                    value={contact.contactNumber}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Stack>
          );
        })}
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default ContactDetails;
