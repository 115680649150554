import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import VegaText from '../../../../../components/common/VegaText';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import {
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
  TimePeriodEnumForPenal,
  TimePeriodEnumForPenalMonthly,
  TimePeriodEnumForPenalYearly,
} from '../../../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import {
  ChannelMonthlyPenalCollectionResultDto,
  ChannelPenalCollectionResultDto,
  ChannelYearlyPenalCollectionResultDto,
} from '../../../../../types/analytics';
import {
  AnalyticsQueryParamsProps,
  AnalyticsQueryRequest,
} from '../../../../../types/request/analyticsRequest';
import { DateUtility } from '../../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import BarChart from '../../../components/BarChart';

const PenalChannelPerformanceGraph = () => {
  const { calenderType, profitType } = useParams();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );

  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    | ChannelPenalCollectionResultDto[]
    | ChannelMonthlyPenalCollectionResultDto[]
    | ChannelYearlyPenalCollectionResultDto[]
  >([]);

  const [loader, setLoader] = useState<boolean>(false);
  const series = [
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'MTD'
          : calenderType === CelenderTypeEmun.YEAR &&
            selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'YTD'
          : 'Target',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (selectedTimePeriod === TimePeriodEnumForPenal.YTD) {
              const yearValue = value as ChannelYearlyPenalCollectionResultDto;
              return parseFloat(yearValue?.ytdPenalCollection?.toFixed(2));
            } else if (selectedTimePeriod === TimePeriodEnumForPenal.MTD) {
              const monthValue =
                value as ChannelMonthlyPenalCollectionResultDto;
              return parseFloat(monthValue?.mtdPenalCollection?.toFixed(2));
            } else {
              const dayValue = value as ChannelPenalCollectionResultDto;
              return parseFloat(dayValue?.penalTarget?.toFixed(2));
            }
          })
        : [],
    },
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'LMSD'
          : calenderType === CelenderTypeEmun.YEAR &&
            selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'LYSD'
          : 'Achieved',

      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (selectedTimePeriod === TimePeriodEnumForPenal.YTD) {
              const yearValue = value as ChannelYearlyPenalCollectionResultDto;
              return parseFloat(yearValue?.lysdPenalCollection?.toFixed(2));
            } else if (selectedTimePeriod === TimePeriodEnumForPenal.MTD) {
              const monthValue =
                value as ChannelMonthlyPenalCollectionResultDto;
              return parseFloat(monthValue?.lmsdPenalCollection?.toFixed(2));
            } else {
              const dayValue = value as ChannelPenalCollectionResultDto;
              return parseFloat(dayValue?.penalCollection?.toFixed(2));
            }
          })
        : [],
    },
  ];

  const regionCategories = analyticsQueryResponse?.length
    ? analyticsQueryResponse.map(value => value && toLowerCase(value?.channel))
    : [];

  const getTimePeriodOptions = () => {
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      const options = Object.keys(TimePeriodEnumForPenalMonthly).map(item => {
        return { value: item, label: toLowerCase(item) } as VegaSelectOption;
      });
      return options;
    }
    const options = Object.keys(TimePeriodEnumForPenalYearly).map(item => {
      const year = new Date().getFullYear();
      const label = `${toLowerCase(item)} (${year})`;
      return { value: item, label: label } as VegaSelectOption;
    });
    return options;
  };

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);

      setAnalyticsQueryResponse(response);

      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      const dummyObj = {} as any;
      setAnalyticsQueryResponse([dummyObj]);
      setLoader(false);
    }
  };

  const getParams = () => {
    const FIRST_DAY = new Date();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);
    const CURRENT_YEAR = FIRST_DAY.getFullYear();

    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      if (selectedTimePeriod === TimePeriodEnumForPenal.ONE_DAY) {
        const oneDayParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date()?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return oneDayParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.FIVE_DAY) {
        const fiveDayParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(FIVE_DAY?.toISOString()),
          toDate: DateUtility.formatStringToYYYYMMDD(FIRST_DAY?.toISOString()),
          type: profitType,
        } as AnalyticsQueryParamsProps;
        return fiveDayParams;
      } else {
        const allParams = {
          type: profitType,
        } as AnalyticsQueryParamsProps;
        return allParams;
      }
    } else {
      if (selectedTimePeriod === TimePeriodEnumForPenal.QUATER_1) {
        const quaterOneParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(CURRENT_YEAR, 1).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(CURRENT_YEAR, 1).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return quaterOneParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.QUATER_2) {
        const quaterTwoParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(CURRENT_YEAR, 2).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(CURRENT_YEAR, 2).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return quaterTwoParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.QUATER_3) {
        const quaterThreeParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(CURRENT_YEAR, 3).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(CURRENT_YEAR, 3).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return quaterThreeParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.QUATER_4) {
        const quaterFourParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(CURRENT_YEAR, 4).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(CURRENT_YEAR, 4).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return quaterFourParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.JANUARY) {
        const januaryParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 1).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 1).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return januaryParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.FEBRUARY) {
        const februaryParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 2).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 2).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return februaryParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.MARCH) {
        const marchParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 3).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 3).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return marchParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.APRIL) {
        const aprilParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 4).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 4).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return aprilParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.MAY) {
        const mayParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 5).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 5).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return mayParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.JUNE) {
        const juneParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 6).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 6).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return juneParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.JULY) {
        const julyParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 7).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 7).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return julyParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.AUGUST) {
        const augustParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 8).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 8).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return augustParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.SEPTEMBER) {
        const septemberParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 9).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 9).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return septemberParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.OCTOBER) {
        const octoberParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 10).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 10).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return octoberParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.NOVEMBER) {
        const novemberParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 11).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 11).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return novemberParams;
      } else if (selectedTimePeriod === TimePeriodEnumForPenal.DECEMBER) {
        const decemberParams = {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, 12).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, 12).toISOString()
          ),
          type: profitType,
        } as AnalyticsQueryParamsProps;

        return decemberParams;
      } else {
        const yearParams = {
          type: profitType,
        } as AnalyticsQueryParamsProps;
        return yearParams;
      }
    }
  };

  const getQueryType = () => {
    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
      case TimePeriodEnumForPenal.FIVE_DAY:
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4:
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER:
        return AnalyticsQueryTypeEnum.CHANNEL_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.MTD:
        return AnalyticsQueryTypeEnum.CHANNEL_MONTHLY_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.YTD:
        return AnalyticsQueryTypeEnum.CHANNEL_YEARLY_PENAL_COLLECTION;
    }
  };

  const getPenalChannelCollectionQueries = () => {
    const params = getParams();
    const analyticsQueryType = getQueryType();

    const request = {
      analyticsQueryType: analyticsQueryType,
      params,
    } as AnalyticsQueryRequest;
    getQueriesForEnum(request);
  };
  useEffect(() => {
    getPenalChannelCollectionQueries();
  }, [selectedTimePeriod, calenderType]);

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper
          title="Penal Channel Performance"
          rightComponent={
            <Stack width={150}>
              <VegaSelect
                options={getTimePeriodOptions()}
                value={selectedTimePeriod}
                onChange={select =>
                  setSelectedTimePeriod(select.target.value as string)
                }
                ignoreLodash
              />
            </Stack>
          }
        >
          {loader ? (
            <LoadingPage height={400} />
          ) : (
            <Stack
              sx={{
                p: 3,
                bgcolor: 'white',
                borderRadius: '12px',
                border: BORDER_COLLECTION,
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -25,
                    height: 50,
                    width: '94.5%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                    }}
                  >
                    {analyticsQueryResponse.map((val, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ flex: '1', textAlign: 'center' }}>
                            {getPercentageValue(val)}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <BarChart
                  categories={regionCategories}
                  series={series}
                  yAxisTitle={'Rs. Crore'}
                />
              </Box>
            </Stack>
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default PenalChannelPerformanceGraph;

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
