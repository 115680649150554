import axios from 'axios';
import { VegaNotification } from '../types/notification';
import {
  GetNotificationListRequest,
  UpdateNotificationStatusRequest,
} from '../types/request/notification';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/notification',
  LIST: '/list',
  UPDATE_STATUS: '/status',
};

export class NotificationService {
  static async getNotificationList(
    request: Partial<GetNotificationListRequest>
  ): Promise<PaginatedResponse<VegaNotification>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateStatus(data: UpdateNotificationStatusRequest) {
    const endpoint = Endpoint.BASE + Endpoint.UPDATE_STATUS;
    const response = await axios.put(endpoint, data);
    return response.data;
  }
}
