import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { scorecardIncentivesList } from './incentivesAsyncThunk';
import {
  FetchScorecardDetailsResponseDto,
  IndividualBucketDetails,
  OverallPerformanceScorecardDetails,
} from '../../types/incentiveScorecardType';

export interface PaymentType {
  [type: string]: string;
}

export interface PaymentDto {
  id: string;
  bucketType: PaymentType[];
}

const initialBucket = [
  {
    id: 'X',
    bucketType: [],
  },

  {
    id: '1 to 30',
    bucketType: [],
  },
  {
    id: '31 to 60',
    bucketType: [],
  },
  {
    id: '61 to 90',
    bucketType: [],
  },
  {
    id: 'NPA',
    bucketType: [],
  },
];

export const initialOverallPerformanceDetails: OverallPerformanceScorecardDetails =
  {
    region: '',
    allIndiaRank: 0,
    totalAgentCount: 0,
    regionRank: 0,
    regionAgentCount: 0,
    totalEligibleIncentive: 0,
    actualIncentiveEarned: 0,
    regionRankDetails: [],
  };

export const initialBucketWiseDetails: IndividualBucketDetails[] = [
  {
    bucket: 'x',
    achievedAmount: '0',
    eligibleAmount: '0',
  },
  {
    bucket: '1 to 30',
    achievedAmount: '0',
    eligibleAmount: '0',
  },
  {
    bucket: '31 to 60',
    achievedAmount: '0',
    eligibleAmount: '0',
  },
  {
    bucket: '61 to 90',
    achievedAmount: '0',
    eligibleAmount: '0',
  },
  {
    bucket: 'NPA',
    achievedAmount: '0',
    eligibleAmount: '0',
  },
];

export const initialScoreCardDetails: FetchScorecardDetailsResponseDto = {
  overallPerformanceDetails: initialOverallPerformanceDetails,
  bucketWiseDetails: initialBucketWiseDetails,
  milestoneBasedDetails: initialBucketWiseDetails,
  resolutionBasedDetails: initialBucketWiseDetails,
  doubleIncentiveDetails: initialBucketWiseDetails,
  normalizationAndRollbackDetails: initialBucketWiseDetails,
};

export interface incentiveScoreCardSliceProps {
  scoreCardDetails: Partial<FetchScorecardDetailsResponseDto>;
  hitsMisses: any[];
  loading: boolean;
  error: string;
}
const initialState: incentiveScoreCardSliceProps = {
  scoreCardDetails: {},
  hitsMisses: [],
  loading: false,
  error: '',
};

const incentiveScoreCardSlice = createSlice({
  name: 'incentiveScoreCard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(scorecardIncentivesList.pending, state => {
        state.loading = true;
        state.hitsMisses = [];
        state.scoreCardDetails = {};
        state.error = null;
      })
      .addCase(scorecardIncentivesList.fulfilled, (state, action) => {
        const scoreCardDetails = { ...action.payload };
        const hitsMissesData = getHitsMissesData(scoreCardDetails);
        state.loading = false;
        state.hitsMisses = hitsMissesData;
        state.scoreCardDetails = scoreCardDetails;
      })
      .addCase(scorecardIncentivesList.rejected, state => {
        const hitsMissesData = getHitsMissesData(initialScoreCardDetails);
        state.loading = false;
        state.hitsMisses = hitsMissesData;
        state.scoreCardDetails = { ...initialScoreCardDetails };
        state.error = '';
      });
  },
});
// export const {} = incentiveScoreCardSlice.actions;
export const getIncentiveScoreCard = (state: RootState) =>
  state.incentiveScoreCard;
export default incentiveScoreCardSlice;

const getHitsMissesData = (
  scoreCardDetails: FetchScorecardDetailsResponseDto
) => {
  return initialBucket.map(scoreCard => {
    const id = scoreCard.id;
    const milestoneBasedDetails = getBucketDetails(
      'milestoneBasedDetails',
      scoreCard.id,
      scoreCardDetails
    );
    const resolutionBasedDetails = getBucketDetails(
      'resolutionBasedDetails',
      scoreCard.id,
      scoreCardDetails
    );
    const doubleIncentiveDetails = getBucketDetails(
      'doubleIncentiveDetails',
      scoreCard.id,
      scoreCardDetails
    );
    const normalizationAndRollbackDetails = getBucketDetails(
      'normalizationAndRollbackDetails',
      scoreCard.id,
      scoreCardDetails
    );
    return {
      id,
      milestoneBasedDetails,
      resolutionBasedDetails,
      doubleIncentiveDetails,
      normalizationAndRollbackDetails,
    };
  });
};

const getBucketDetails = (
  bucketType: string,
  bucket: string,
  data: FetchScorecardDetailsResponseDto
) => {
  const isMatchBucket = Object.keys(data).some(value => value === bucketType);
  if (isMatchBucket) {
    const individualBucketDetails = data[
      bucketType
    ] as IndividualBucketDetails[];
    const findBucketDetail = individualBucketDetails?.find(
      value => value.bucket === bucket
    );

    return {
      achievedAmount: findBucketDetail?.achievedAmount ?? '0',
      eligibleAmount: findBucketDetail?.eligibleAmount ?? '0',
    };
  }
};
