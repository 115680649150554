/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Add, VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AuctionService } from '../../../Apis/AuctionService';
import { PossessionService } from '../../../Apis/PossessionService';
import { AuctionIcon, MoneyLoanIcon } from '../../../components/Icons/Icons';
import LmButton from '../../../components/common/LmButton';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaImagePreviewDialog from '../../../components/common/VegaImagePreviewDialog';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { VegaPill } from '../../../components/common/VegaPill';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaText from '../../../components/common/VegaText';
import VegaTooltip from '../../../components/common/VegaTooltip';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY, YELLOW } from '../../../constants/style';
import {
  getPropertiesState,
  setOpenAddPropertyDrawer,
  setOpenBulkPropertyDialog,
  setOpenMinRaiseModal,
  setPropertyDetails,
} from '../../../features/propertiesSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AuctionStatus, Possession } from '../../../types/auction';
import { PropertiesViewModel } from '../../../types/properties';
import { StringUtility } from '../../../utils/StringUtility';
import {
  PaginatedResponse,
  getErrorMessageFromErrorObj,
} from '../../../utils/api';
import AddBiddersToAuctionDrawer, {
  AddBiddersFormData,
} from './modules/AddBiddersToAuctionDrawer';
import AddNewPossession from './modules/AddNewPossession';
import AuctionDetailsModal from './modules/AuctionDetailsModal';
import BulkPropertiesModal from './modules/BulkPropertiesModal';
import MinRaiseModal from './modules/MinRaiseModal';
import PropertyDetailsModal from './modules/PropertyDetailsModal';
import StartAuctionDrawer from './modules/StartAuctionDrawer';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaIconButton from '../../../components/common/VegaIconButton';

export enum TabType {
  ONGOING = 'ONGOING',
  DRAFT = 'DRAFT',
}

function AuctionPossessions() {
  const { setSnackbar } = useSnackbar();
  const [draftSearch, setDraftSearch] = useState<string>('');
  const [ongoingSearch, setOngoingSearch] = useState<string>('');
  const { openAddPropertyDrawer, openBulkPropertyDialog } =
    useAppSelector(getPropertiesState);
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [ongoingData, setOngoingData] = useState<PaginatedResponse<Possession>>(
    {
      records: [],
      pageNumber: 0,
      totalPages: 0,
      numberOfItems: 0,
      totalItems: 0,
    }
  );
  const [draftData, setDraftData] = useState<PaginatedResponse<Possession>>({
    records: [],
    pageNumber: 0,
    totalPages: 0,
    numberOfItems: 0,
    totalItems: 0,
  });

  const {
    open: openStartAuctionDrawer,
    close: closeStartAuctionDrawer,
    props: startAuctionDrawerProps,
    isOpen: isOpenStartAuctionDrawer,
  } = useDrawer();
  const {
    open: openPreviewDialog,
    close: closePreviewDialog,
    props: previewDialogProps,
    isOpen: isPreviewDialogOpen,
  } = useDrawer();

  const {
    open: openDetailsDialog,
    close: closeDetailsDialog,
    props: detailsDialogProps,
    isOpen: isDetailsDialogOpen,
  } = useDrawer();

  const {
    open: openBiddersDrawer,
    close: closeBiddersDrawer,
    isOpen: isBiddersDrawerOpen,
    props: bidderDrawerProps,
  } = useDrawer();

  function _onTabChange(value: number) {
    setSelectedTab(value);
  }

  function toggleOpenAddNewProperty() {
    dispatch(setOpenAddPropertyDrawer(!openAddPropertyDrawer));
  }

  function toggleBulkProperty() {
    dispatch(setOpenBulkPropertyDialog(!openBulkPropertyDialog));
  }

  function setSearch(text: string) {
    if (selectedTab == 0) {
      setOngoingSearch(text);
    } else if (selectedTab == 1) {
      setDraftSearch(text);
    }
  }

  function onPageChange(page: number) {
    if (selectedTab == 0) {
      setOngoingData(prev => {
        return {
          ...prev,
          pageNumber: page,
        };
      });
    } else if (selectedTab == 1) {
      setDraftData(prev => {
        return {
          ...prev,
          pageNumber: page,
        };
      });
    }
  }

  async function onDownloadReportClick(possession: Possession) {
    try {
      setLoading(true);
      const auction = possession?.metaData?.auction;
      const url = auction?.report;
      window.open(url, '__blank__');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getData = () => {
    if (selectedTab == 0) {
      return ongoingData;
    } else if (selectedTab == 1) {
      return draftData;
    }
  };

  async function fetchData() {
    try {
      setLoading(true);
      const status = selectedTab == 0 ? 'LISTED' : 'NOT_LISTED';
      const page = getData().pageNumber;
      const includeDetails = selectedTab == 0 ? true : false;
      const search = selectedTab == 0 ? ongoingSearch : draftSearch;
      const response = await PossessionService.fetchPossessionList({
        auctionStatus: status,
        page: page,
        size: 10,
        includeAuctionDetails: includeDetails,
        lan: search,
      });
      if (selectedTab == 0) {
        setOngoingData(response);
      } else if (selectedTab == 1) {
        setDraftData(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function addBiddersToProperty(
    property: Possession,
    formData: Partial<AddBiddersFormData>
  ) {
    try {
      setLoading(true);
      await AuctionService.updateAuction({
        id: property.auctionId,
        bidders: formData.bidders.map(i => i.id),
      });
      fetchData();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getColumnDefinition = () => {
    if (selectedTab == 0) {
      return getOngoingColumnDefinition({
        onPreviewClick: function (property: Possession): void {
          openPreviewDialog({ property: property });
        },
        onMinRaiseClick: function (property: Possession): void {
          dispatch(setPropertyDetails(mapPossessionToProperty(property)));
          dispatch(setOpenMinRaiseModal(true));
        },
        onAddBidderClick: function (property: Possession): void {
          openBiddersDrawer({ property: property });
        },
        onDownloadReportClick: function (property: Possession): void {
          onDownloadReportClick(property);
        },
        onViewDetailsClick: function (property: Possession): void {
          openDetailsDialog({ possession: property });
        },
      });
    } else if (selectedTab == 1) {
      return getDraftColumnDefinition({
        onPreviewClick: p => {
          openPreviewDialog({ property: p });
        },
        onStartAuctionClick: p => {
          openStartAuctionDrawer({ auctionItemId: p.id });
        },
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    ongoingData.pageNumber,
    draftData.pageNumber,
    ongoingSearch,
    draftSearch,
    selectedTab,
  ]);

  return (
    <>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Auction Properties'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  _onTabChange(selected);
                }}
              >
                <VegaTabBarItem label={toLowerCase(TabType.ONGOING)} />
                <VegaTabBarItem label={toLowerCase(TabType.DRAFT)} />
              </VegaTabBar>
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} gap={1}>
              <LmButton
                variant="outlined"
                text="Bulk Property"
                bgColor=""
                textColor={PRIMARY.darkBlue}
                onClick={toggleBulkProperty}
              />
              <LmButton
                text="Add New Property"
                onClick={toggleOpenAddNewProperty}
              />
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        title="Draft"
        renderRightView={() => (
          <LmFilterSection
            hideFilter
            search={selectedTab == 0 ? ongoingSearch : draftSearch}
            setSearch={setSearch}
          />
        )}
        sx={{
          borderBottom: 'none',
          paddingBottom: '1rem',
          marginTop: '1.5rem',
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={getData().records}
          loading={loading}
          columns={getColumnDefinition()}
          idColumn="id"
          page={getData().pageNumber}
          pageSize={10}
          rowCount={getData().totalItems}
          paginationMode="server"
          onPageChange={onPageChange}
        />
      </VegaPageContent>

      <VegaDrawer
        open={openAddPropertyDrawer}
        title={'Add Property'}
        onClose={toggleOpenAddNewProperty}
      >
        <AddNewPossession
          onPossessionCreate={function () {
            fetchData();
          }}
        />
      </VegaDrawer>
      <BulkPropertiesModal
        onModalClose={function (): void {
          fetchData();
        }}
      />
      <StartAuctionDrawer
        auctionItemId={startAuctionDrawerProps.auctionItemId}
        open={isOpenStartAuctionDrawer}
        onClose={() => {
          closeStartAuctionDrawer();
          fetchData();
        }}
      />
      <VegaImagePreviewDialog
        open={isPreviewDialogOpen}
        onClose={function (): void {
          closePreviewDialog();
        }}
        images={previewDialogProps?.property?.images ?? []}
        lan={previewDialogProps?.property?.lan}
      />
      <AddBiddersToAuctionDrawer
        open={isBiddersDrawerOpen}
        property={bidderDrawerProps.property}
        onClose={function (): void {
          closeBiddersDrawer();
        }}
        onSubmit={addBiddersToProperty}
      />
      <AuctionDetailsModal
        open={isDetailsDialogOpen}
        possession={detailsDialogProps.possession}
        onClose={function (): void {
          closeDetailsDialog();
        }}
      />
      <MinRaiseModal
        onUpdate={function (): void {
          fetchData();
        }}
      />
      <PropertyDetailsModal />
    </>
  );
}

export default AuctionPossessions;

const getDraftColumnDefinition = (data: {
  onPreviewClick: (data: PropertiesViewModel) => void;
  onStartAuctionClick: (data: PropertiesViewModel) => void;
}) => {
  const COL_DEF: GridColumns<PropertiesViewModel[]> = [
    {
      field: 'lan',
      headerName: 'LAN',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        return <VegaText text={properties.lan} />;
      },
    },
    {
      field: 'Borrower',
      headerName: 'Borrower',
      flex: 0.7,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return (
          <VegaText text={possession?.borrowerName} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'type',
      headerName: 'Property Type',
      flex: 0.7,
    },
    {
      field: 'images',
      headerName: 'Image',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        const images = properties.images;
        return (
          <LmButton
            text="Preview"
            variant="text"
            textColor={PRIMARY.darkBlue}
            fontWeight={500}
            sx={{
              px: 0,
            }}
            fontSize={'0.875rem'}
            bgColor="transparant"
            onClick={() => {
              data.onPreviewClick(mapPossessionToProperty(properties));
            }}
          />
        );
      },
    },

    {
      field: 'address',
      headerName: 'Property Address',
      flex: 1,
    },
    {
      field: 'Region',
      headerName: 'Region',
      flex: 0.7,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return <VegaText text={possession?.region} fontSize={'0.875rem'} />;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        return (
          <LmButton
            text="Start Auction"
            startIcon={<AuctionIcon strokeColor={PRIMARY.darkBlue} />}
            bgColor="#E7EDFC"
            textColor={PRIMARY.darkBlue}
            onClick={() => {
              data.onStartAuctionClick(mapPossessionToProperty(properties));
            }}
          />
        );
      },
    },
  ];
  return COL_DEF;
};

const getOngoingColumnDefinition = (data: {
  onPreviewClick: (property: Possession) => void;
  onMinRaiseClick: (property: Possession) => void;
  onAddBidderClick: (property: Possession) => void;
  onDownloadReportClick: (property: Possession) => void;
  onViewDetailsClick: (property: Possession) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'LAN',
      headerName: 'LAN',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        return <VegaText text={properties.lan} />;
      },
    },
    {
      field: 'Borrower',
      headerName: 'Borrower',
      flex: 0.7,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return (
          <VegaText text={possession?.borrowerName} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.7,
    },
    {
      field: 'images',
      headerName: 'Image',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        return (
          <LmButton
            text="Preview"
            variant="text"
            textColor={PRIMARY.darkBlue}
            fontWeight={500}
            sx={{
              px: 0,
            }}
            fontSize={'0.875rem'}
            bgColor="transparant"
            onClick={() => {
              data.onPreviewClick(properties);
            }}
          />
        );
      },
    },
    {
      field: 'reservePrice',
      headerName: 'Reserve Price',
      flex: 0.7,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return (
          <VegaText
            text={StringUtility.formatToINR(
              possession?.metaData?.auction?.reservePrice ?? 0
            )}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return <VegaText text={possession?.address} fontSize={'0.875rem'} />;
      },
    },
    {
      field: 'Region',
      headerName: 'Region',
      flex: 0.7,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return <VegaText text={possession?.region} fontSize={'0.875rem'} />;
      },
    },
    {
      field: 'totalBidder',
      headerName: 'Total Bidder',
      flex: 0.7,
      renderCell: props => {
        const possession = getPropertiesRow(props);
        return (
          <VegaText
            text={possession?.metaData?.auction?.bidders?.length ?? 0}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        const status = properties?.metaData?.auction?.status;
        return (
          <VegaPill
            text={status}
            textColor={
              status === AuctionStatus.IN_PROGRESS ? '#318439' : '#E87001'
            }
            borderColor={
              status === AuctionStatus.IN_PROGRESS ? '#318439' : '#E87001'
            }
            backgroundColor={
              status === AuctionStatus.IN_PROGRESS ? '#EBF4EC' : '#FFF2E6'
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      renderCell: props => {
        const properties = getPropertiesRow(props);
        const status = properties?.metaData?.auction?.status;
        if (status == AuctionStatus.COMPLETED) {
          return (
            <Stack direction={'row'} gap={1}>
              {/* <VegaButton
                text="View Details"
                variant="outlined"
                onClick={() => data.onViewDetailsClick(properties)}
              ></VegaButton> */}
              <VegaIconButton
                tooltipTitle="View Details"
                onClick={() => data.onViewDetailsClick(properties)}
                sx={{ bgcolor: '#E7EDFC' }}
                icon={
                  <VisibilityOutlined
                    sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                  />
                }
              />
            </Stack>
          );
        }
        return (
          <Stack direction={'row'} gap={1}>
            <VegaTooltip id={properties.id} title="Min Raise">
              <LmButton
                text=""
                startIcon={<MoneyLoanIcon />}
                bgColor="#FFF2E6"
                textColor={YELLOW.dark}
                onClick={() => {
                  data.onMinRaiseClick(properties);
                }}
              />
            </VegaTooltip>
            <VegaTooltip id={properties.id} title="Add Bidders">
              <LmButton
                text=""
                startIcon={<Add />}
                bgColor="#E7EDFC"
                textColor={PRIMARY.darkBlue}
                onClick={() => {
                  data.onAddBidderClick(properties);
                }}
              />
            </VegaTooltip>
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getPropertiesRow = (props: any) => props.row as Possession;

const mapPossessionToProperty = (item: Possession) => {
  const viewModel: PropertiesViewModel = {
    id: item.id,
    auctionId: item.auctionId,
    propertyName: item.id,
    type: item.type,
    images: item.images,
    reservePrice: item.metaData ? item.metaData.auction.reservePrice : 0,
    address: item.address,
    totalBidder: item.numberOfBidders || 0,
    status: item.metaData ? item.metaData.auction.status : '',
    minIncrement: item.metaData ? item.metaData.auction.minIncrement : 0,
  };
  return viewModel;
};
