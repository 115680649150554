import { GridColumns } from '@mui/x-data-grid';
import { PortfoliioDetailsDto } from '../../types/loan';
import VegaText from '../../components/common/VegaText';
import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import VegaIconButton from '../../components/common/VegaIconButton';
import { PRIMARY } from '../../constants/style';
import { StringUtility } from '../../utils/StringUtility';
interface ColumnProps {
  onViewDetailClick: (loanIds: string[]) => void;
}
export function PortFolioColumn({ onViewDetailClick }: ColumnProps) {
  const COL_DEF: GridColumns = [
    {
      field: 'bucket',
      headerName: 'Risk Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = portfolioDetails.bucket ?? '0';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'count',
      headerName: 'Total Count',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails?.count ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalOs',
      headerName: 'Total OS Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.totalOs ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'percentageOfTotal',
      headerName: 'Per Of Total',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails?.percentageOfTotal ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'normalizedCount',
      headerName: 'Normalized Count',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.normalizedCount ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'normalizedOs',
      headerName: 'Normalized OS Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.normalizedOs ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'normalizedPercentage',
      headerName: 'Normalized Percentage Of Total',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.normalizedPercentage ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'rollbackCount',
      headerName: 'Rollback Count',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.rollbackCount ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'rollbackOs',
      headerName: 'Rollback OS Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.rollbackOs ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'rollbackPercentage',
      headerName: 'Rollback Percentage Of Total',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.rollbackPercentage ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'stabilizedCount',
      headerName: 'Stablized Count',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.stabilizedCount ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'stabilizedOs',
      headerName: 'Stablized OS Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.stabilizedOs ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'stabilizedPercentage',
      headerName: 'Stabilized Percentage Of Total',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.stabilizedPercentage ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'rollForwardCount',
      headerName: 'Roll Forward Count',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.rollForwardCount ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'rollForwardOs',
      headerName: 'Roll Forward OS Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.rollForwardOs ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'rollForwardPercentage',
      headerName: 'Roll Forward Percentage Of Total',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const portfolioDetails = props.row as PortfoliioDetailsDto;
        const display = StringUtility.formatToFixed(
          portfolioDetails.rollForwardPercentage ?? '0',
          2
        );
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Details',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const details = props.row as PortfoliioDetailsDto;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onViewDetailClick(details.loanIds)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
}

export default PortFolioColumn;
