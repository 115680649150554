import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ConfigurationDto } from '../../../../types/configurationType';
import AllBranchSelector from '../../../MinVisits/modules/AllBranchSelector';

const BranchSelect = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const onBranchSelect = (branch: string) => {
    const preConfigSubtype = [...configurations.subtypes];
    if (preConfigSubtype.includes(branch)) {
      const filterBranch = preConfigSubtype.filter(
        (selectedUser: string) => selectedUser !== branch
      );
      handleConfigChange('subtypes', filterBranch);
    } else {
      handleConfigChange('subtypes', [...preConfigSubtype, branch]);
    }
  };
  const handleUserDelete = (index: number) => {
    const preConfigSubtype = [...configurations.subtypes];
    preConfigSubtype.splice(index, 1);
    handleConfigChange('subtypes', preConfigSubtype);
  };
  return (
    <>
      <VegaFormInputField label={'select Branch'}>
        <AllBranchSelector
          isMultiSelect
          selected={configurations?.subtypes ?? []}
          handleChange={selected => onBranchSelect(selected)}
          handleDelete={index => handleUserDelete(index)}
        />
      </VegaFormInputField>
    </>
  );
};

export default BranchSelect;
