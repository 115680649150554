import { Skeleton, Stack } from '@mui/material';
import { COMMON } from '../../../constants/style';
import { StringUtility } from '../../../utils/StringUtility';
import VegaText from '../../common/VegaText';
import { IPosAmountsProps } from '../CollectedVsCollectabelContainer';

const PrePostBounce = ({ posAmounts, loading }: IPosAmountsProps) => {
  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 3,
          bgcolor: 'white',
          borderRadius: '12px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        }}
      >
        <Stack gap={2}>
          {loading ? (
            <Skeleton />
          ) : (
            <VegaText
              text={
                posAmounts.length
                  ? `${getAmountValue(
                      posAmounts[0]?.PRE_BOUNCE_POS
                    )} (in Crores)`
                  : `0 (in Crores)`
              }
              fontSize={17}
              fontWeight={600}
              color={COMMON.balck}
            />
          )}
          <VegaText
            text={'Pre Bounce'}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
        </Stack>
        <Stack gap={2}>
          {loading ? (
            <Skeleton />
          ) : (
            <VegaText
              text={
                posAmounts.length
                  ? `${getAmountValue(
                      posAmounts[0]?.POST_BOUNCE_POS
                    )} (in Crores)`
                  : `0 (in Crores)`
              }
              fontSize={17}
              fontWeight={600}
              color={COMMON.balck}
            />
          )}
          <VegaText
            text={'Post Bounce'}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PrePostBounce;

const getAmountValue = (posAmount: string) => {
  if (posAmount) {
    const value =
      typeof posAmount === 'string' ? parseFloat(posAmount) : posAmount;
    if (!isNaN(value)) {
      var numberInCrores = value / 1e7;
      return numberInCrores.toFixed(2);
    } else {
      return 0;
    }
  } else {
    return StringUtility.formatToINR(0);
  }
};
