import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import VegaText from '../../../../../components/common/VegaText';
import {
  AnalyticsQueryTypeEnum,
  OverallMetricsEnum2,
} from '../../../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { AccountMovementMonthOnMonthResultDto } from '../../../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';

interface IProps {
  selectedCollectionProfitType: string;
}

const MOMAccountMovmentGraph = ({ selectedCollectionProfitType }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    OverallMetricsEnum2.NONE
  );
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    AccountMovementMonthOnMonthResultDto[]
  >([]);
  const currentMonth = new Date().getMonth() + 1;

  const maxNumber = Math.max(
    ...analyticsQueryResponse.map(item => item.percentage)
  );

  const minNumber = (): number => {
    const percentage = [...analyticsQueryResponse.map(item => item.percentage)];
    const nonZeroValues = percentage.filter(value => value !== 0);

    if (nonZeroValues.length === 0) {
      return 0;
    }

    const minValue = Math.min(...nonZeroValues);
    return minValue;
  };
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: analyticsQueryResponse.map(item => item.displayMonth),
    },
    fill: {
      opacity: 1,
      colors: ['#ff0000', '#00ff00', '#0000ff'],
    },
    tooltip: {},
    yaxis: {
      title: {
        text: 'In Percentage',
      },
    },
  };
  const series = [
    {
      name: '',
      data: analyticsQueryResponse.map(item => ({
        x: item.displayMonth,
        y: item.percentage,
        fillColor:
          item.month === currentMonth
            ? '#86B6F6'
            : item.percentage === maxNumber
            ? '#65B741'
            : item.percentage === minNumber()
            ? '#C70039'
            : '#dedede',
      })),
    },
  ];

  const getQueriesForEnum = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.ACCOUNT_MOVEMENT_MONTH_ON_MONTH,
        params: {
          ...(selectedMetrics !== OverallMetricsEnum2.NONE && {
            loanStatus: selectedMetrics,
          }),
          type: selectedCollectionProfitType,
        },
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      const dummyObj = {} as any;
      setAnalyticsQueryResponse([dummyObj]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getQueriesForEnum();
  }, [selectedCollectionProfitType, selectedMetrics]);

  return (
    <>
      <GraphWrapper
        title="Account Movements"
        rightComponent={
          <Stack width={150}>
            <VegaSelect
              options={getOptions()}
              value={selectedMetrics}
              onChange={select =>
                setSelectedMetrics(select.target.value as string)
              }
            />
          </Stack>
        }
      >
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -10,
                  height: 50,
                  width: '95.3%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1', textAlign: 'center' }}>
                          {getPercentageValue(val)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={series}
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default MOMAccountMovmentGraph;

const getOptions = () => {
  const options = Object.values(OverallMetricsEnum2).map(
    item =>
      ({
        value: item,
        label: item,
      } as VegaSelectOption)
  );
  return options;
};
const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
