import { Collapse, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { IncentivePolicyProps } from '../../../types/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import { VegaSelectOption } from '../../common/VegaSelect';
import VegaSelectWithCheckbox from '../../common/VegaSelectWithCheckbox';
import VegaTitleCard from '../../common/VegaTitleCard';
import UserTypeSelector from './UserTypeSelector';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

interface IProps {
  selectedUsers: string[];
  selectedPolicies: VegaSelectOption[];
  setSelectedPolicies: React.Dispatch<React.SetStateAction<VegaSelectOption[]>>;
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

const NewPolicyForm = ({
  selectedPolicies,
  selectedUsers,
  setSelectedPolicies,
  setSelectedUsers,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [policies, setPolicies] = useState<VegaSelectOption[]>([]);

  const onUserSelect = (user: string) => {
    if (selectedUsers.includes(user)) {
      const filteredUser = selectedUsers.filter(
        (selectedUser: string) => selectedUser !== user
      );
      setSelectedUsers(filteredUser);
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };
  const onUserDelete = (index: number) => {
    const users = [...selectedUsers];
    users.splice(index, 1);
    setSelectedUsers(users);
  };
  const onPolicySelect = (selectedPolicy: string) => {
    const findPolicy = policies.find(
      (policy: VegaSelectOption) => policy.value === selectedPolicy
    );
    const selected = selectedPolicies.map(
      (select: VegaSelectOption) => select.value
    );
    if (selected.includes(findPolicy.value)) {
      const filteredUser = selectedPolicies.filter(
        (selectedUser: VegaSelectOption) =>
          selectedUser.value !== findPolicy.value
      );
      setSelectedPolicies(filteredUser);
    } else {
      setSelectedPolicies([...selectedPolicies, findPolicy]);
    }
  };
  const onPolicyDelete = (index: number) => {
    const policies = [...selectedPolicies];
    policies.splice(index, 1);
    setSelectedPolicies(policies);
  };

  useEffect(() => {
    selectedUsers.length &&
      IncentiveServices.getPoliciesForRole({
        userType: selectedUsers.join(','),
      })
        .then(res => {
          const policies = res.length
            ? res?.map(
                (role: IncentivePolicyProps) =>
                  ({
                    label: role.policyName,
                    value: role.id,
                  } as VegaSelectOption)
              )
            : [];
          setPolicies(policies);
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
  }, [selectedUsers]);

  return (
    <>
      <VegaTitleCard title="New Policy">
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <VegaFormInputField label={'user type'}>
                <UserTypeSelector
                  selected={selectedUsers}
                  onSelect={selected => onUserSelect(selected)}
                  onDelete={selectedIndex => onUserDelete(selectedIndex)}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
          <Collapse in={selectedUsers.length > 0}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <VegaFormInputField label={'Policy Type'}>
                  <VegaSelectWithCheckbox
                    options={policies}
                    onSelect={selected => onPolicySelect(selected)}
                    handleDelete={selectedIndex =>
                      onPolicyDelete(selectedIndex)
                    }
                    selected={selectedPolicies.map(
                      (selectedPolicy: VegaSelectOption) => selectedPolicy.label
                    )}
                  />
                </VegaFormInputField>
              </Grid>
            </Grid>
          </Collapse>
        </Stack>
      </VegaTitleCard>
    </>
  );
};

export default NewPolicyForm;
