import { Stack } from '@mui/material';
import { useState } from 'react';
import ExpenseCard from '../../components/Calender/ExpenseCard';
import ListOfAccountCalender from '../../components/Calender/ListOfAccountCalender';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import CalenderView from './CalenderView';

const TODAY = new Date();

const Calender = () => {
  const [date, setDate] = useState<string>(TODAY.toISOString());

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Calendar'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />
      <VegaPageContent>
        <Stack
          direction={'row'}
          sx={{ width: '100%', mt: '1rem' }}
          gap={2}
          alignItems={'start'}
        >
          <ListOfAccountCalender date={date} />
          <Stack gap={1} sx={{ maxWidth: '320px' }}>
            <CalenderView date={date} setDate={setDate} />
            <ExpenseCard date={date} />
          </Stack>
        </Stack>
      </VegaPageContent>
    </div>
  );
};

export default Calender;
