import { Box, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { AnaltyticsService } from '../../Apis/AnaltyticsService';
import VegaButton from '../../components/common/VegaButton';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { BORDER_COLLECTION } from '../../constants/style';
import { AnalyticsQueryTypeEnum } from '../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../types/request/analyticsRequest';
import { DateUtility } from '../../utils/DateUtlility';
import CommunicationDetailsSummary from './CommunicationDetailsSummary';
import ExecutedDistributionGraph from './ExecutedDistributionGraph';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaAutoComplete from '../../components/common/VegaAutoComplete';
import {
  CLProductType,
  DueDateTypes,
  HLProductType,
  ProductType,
  RiskCategory,
  VerticalTypes,
} from '../../components/Perfomance/PerformanceFilterSection';
import { toLowerCase } from '../../constants/commonFunction';
import AllBranchSelector from '../MinVisits/modules/AllBranchSelector';
import { VegaSelectOption } from '../../components/common/VegaSelect';
import { BucketOption } from '../../types/minVisitsType';

type ParamsType = {
  from: string;
  to: string;
  action: string;
  actionType: string;
  product: string;
  branch: string;
  segment: string;
  bucket: string;
  collectionRiskCategory: string;
  cycleDate: string;
};
export interface ChannelCount {
  IVR: number;
  SMS: number;
  WHATSAPP: number;
  EMAIL: number;
  PUSH: number;
}

export enum Channel {
  IVR = 'IVR',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
}

export type CommunicationFilterType = {
  from: string;
  to: string;
  product: string;
  branch: string;
  segment: string;
  bucket: string;
  cycleDate: number;
  action: string;
  collectionRiskCategory: string;
  actionType: string;
};

enum FilterInputType {
  from = 'from',
  to = 'to',
  action = 'action',
  actionType = 'actionType',
  product = 'product',
  branch = 'branch',
  segment = 'segment',
  bucket = 'bucket',
  collectionRiskCategory = 'collectionRiskCategory',
  cycleDate = 'cycleDate',
}

enum ActionTypes {
  SMS = 'SMS_SENT',
  WHATSAPP = 'WHATSAPP_SENT',
  IVR = 'IVR_SENT',
}

export interface ComunicationDetailsType {
  channelCount: ChannelCount;
  date?: string;
  loanIds: string[];
}

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

function CommunicationDetails() {
  const { setSnackbar } = useSnackbar();

  const [
    comunicationDetailsQueryResponse,
    setComunicationDetailsQueryResponse,
  ] = useState<ComunicationDetailsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [channelCount, setChannelCount] = useState<Partial<ChannelCount>>({});
  const [filterData, SetFilterData] = useState<
    Partial<CommunicationFilterType>
  >({
    from: TODAY.toISOString(),
    to: TODAY.toISOString(),
  });

  function updateFormData(type: FilterInputType, value: string | number) {
    SetFilterData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  const handleDateChange = (key: keyof ParamsType, date: any) => {
    const dateToString = DateUtility.parseDateFromDatePicker(date);

    if (key === 'from') {
      let endDate = DateUtility.parseStringToDate(filterData?.to);
      if (endDate && dateToString) {
        const maxEndDate = DateUtility.addDays(dateToString, 30);
        endDate = DateUtility.isBefore(maxEndDate, endDate)
          ? maxEndDate
          : endDate;
        if (DateUtility.isBefore(endDate, dateToString)) {
          endDate = DateUtility.addDays(dateToString, 1);
        }
      }
      SetFilterData(prev => {
        return {
          ...prev,
          from: dateToString?.toISOString(),
          to: endDate?.toISOString(),
        };
      });
    } else {
      let startDate = DateUtility.parseStringToDate(filterData?.from);
      if (startDate && dateToString) {
        if (DateUtility.isBefore(dateToString, startDate)) {
          startDate = dateToString;
        }
      }
      SetFilterData(prev => {
        return {
          ...prev,
          to: dateToString?.toISOString(),
        };
      });
    }
  };

  function transformData(
    allDates: string[],
    data: ComunicationDetailsType[]
  ): ComunicationDetailsType[] {
    const transformedData: ComunicationDetailsType[] = allDates.map(date => {
      const backendDatum = data.find(item => item.date === date);
      if (backendDatum) {
        return {
          channelCount: backendDatum.channelCount,
          date: DateUtility.formatDateToDDMMYY(new Date(backendDatum.date)),
          loanIds: backendDatum.loanIds,
        };
      } else {
        return {
          channelCount: {
            IVR: 0,
            SMS: 0,
            WHATSAPP: 0,
            EMAIL: 0,
            PUSH: 0,
          },
          date: DateUtility.formatDateToDDMMYY(new Date(date)),
          loanIds: [],
        };
      }
    });
    return transformedData;
  }

  const getCommunicationDetails = async (params: Partial<ParamsType>) => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.COMMUNICATION_DETAILS,
        params,
      } as AnalyticsQueryRequest;
      const response: ComunicationDetailsType[] =
        await AnaltyticsService.getAnalyticsQueries(request);
      const allDates = getDates(params.from, params.to);
      const transformedData = transformData(allDates, response);

      setComunicationDetailsQueryResponse(transformedData);

      const totalCounts = response.reduce(
        (preCount, channelData) => {
          preCount.SMS += channelData?.channelCount?.SMS ?? 0;
          preCount.WHATSAPP += channelData?.channelCount?.WHATSAPP ?? 0;
          preCount.EMAIL += channelData?.channelCount?.EMAIL ?? 0;
          preCount.PUSH += channelData?.channelCount?.PUSH ?? 0;
          return preCount;
        },
        {
          IVR: 0,
          SMS: 0,
          WHATSAPP: 0,
          EMAIL: 0,
          PUSH: 0,
        }
      );

      setChannelCount(totalCounts);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const clearFilter = () => {
    SetFilterData({
      from: TODAY.toISOString(),
      to: TODAY.toISOString(),
    });
    getCommunicationDetails({
      from: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      to: DateUtility.formatStringToYYYYMMDD(TOMORROW.toISOString()),
    });
  };

  const onApplyFilterClick = async () => {
    const paramsRequest = {
      ...filterData,
      from: DateUtility.formatStringToYYYYMMDD(filterData.from),
      to: DateUtility.formatStringToYYYYMMDD(filterData.to),
    } as unknown as ParamsType;
    await getCommunicationDetails(paramsRequest);
  };

  const communicationDetailsSummary = useMemo(() => {
    return (
      <CommunicationDetailsSummary
        channelCount={channelCount}
        loading={loading}
        filterData={filterData}
      />
    );
  }, [channelCount, loading, filterData]);

  const executedDistributionGraph = useMemo(() => {
    return (
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '12px',
          border: BORDER_COLLECTION,
        }}
      >
        <ExecutedDistributionGraph
          comunicationDetailsQueryResponse={comunicationDetailsQueryResponse}
          loading={loading}
        />
      </Box>
    );
  }, [comunicationDetailsQueryResponse, loading]);

  useEffect(() => {
    getCommunicationDetails({
      from: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      to: DateUtility.formatStringToYYYYMMDD(TOMORROW.toISOString()),
    });
  }, []);
  return (
    <>
      <VegaPageHeader title="Communication Details" />
      <VegaPageHeader
        sx={{
          border: 'none',
          marginBottom: '1rem',
        }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{
                border: BORDER_COLLECTION,
                borderRadius: '12px',
                p: 3,
                justifyContent: 'space-between',
              }}
              direction={'row'}
            >
              <Grid container direction={'row'} gap={1} rowGap={2}>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'From'}>
                    <VegaDatePicker
                      value={filterData.from}
                      onChange={date => handleDateChange('from', date)}
                      maxDate={new Date()}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'To'}>
                    <VegaDatePicker
                      value={filterData.to}
                      onChange={date => handleDateChange('to', date)}
                      maxDate={DateUtility.addDays(new Date(), 1)}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Action'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getActionTypeOptions(ActionTypes)}
                      onClear={() =>
                        updateFormData(FilterInputType.actionType, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.actionType, selected)
                      }
                      selected={filterData.actionType ?? ''}
                      placeHolder="Select Action Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField
                    label={toLowerCase(FilterInputType.segment)}
                  >
                    <VegaAutoComplete
                      isHideSearch
                      options={getVerticalOptions(VerticalTypes)}
                      onClear={() =>
                        updateFormData(FilterInputType.segment, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.segment, selected)
                      }
                      selected={filterData?.segment ?? ''}
                      placeHolder="Select Segment"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Product'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getProductTypeOptions(filterData.segment)}
                      onClear={() =>
                        updateFormData(FilterInputType.product, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.product, selected)
                      }
                      selected={filterData?.product ?? ''}
                      placeHolder="Select Product"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Branch'}>
                    <AllBranchSelector
                      selected={filterData?.branch ?? ('' as string)}
                      handleChange={selected =>
                        updateFormData(FilterInputType.branch, selected)
                      }
                      onClear={() => updateFormData(FilterInputType.branch, '')}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Bucket'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getBucketOptions()}
                      onClear={() => updateFormData(FilterInputType.bucket, '')}
                      onSelect={selected =>
                        updateFormData(FilterInputType.bucket, selected)
                      }
                      selected={filterData?.bucket ?? ''}
                      placeHolder="Select Bucket"
                      ignoreLodash
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Collection Risk Category'}>
                    <VegaAutoComplete
                      isHideSearch
                      ignoreLodash
                      options={getOptions(getRiskCategoryValues())}
                      onClear={() =>
                        updateFormData(
                          FilterInputType.collectionRiskCategory,
                          ''
                        )
                      }
                      onSelect={selected =>
                        updateFormData(
                          FilterInputType.collectionRiskCategory,
                          selected
                        )
                      }
                      selected={filterData.collectionRiskCategory ?? ''}
                      placeHolder="Select Risk Category"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Cycle Date'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getDueDateValues())}
                      onClear={() =>
                        updateFormData(FilterInputType.cycleDate, '')
                      }
                      onSelect={selected =>
                        updateFormData(FilterInputType.cycleDate, +selected)
                      }
                      selected={filterData.cycleDate ?? ''}
                      placeHolder="Select Cycle Date"
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
              <Grid
                container
                direction={'row'}
                gap={1}
                sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
              >
                <VegaButton
                  text="Clear Filter"
                  onClick={clearFilter}
                  variant="outlined"
                  sx={{
                    ':hover': {
                      bgcolor: 'transparent',
                      color: '#1047DC',
                    },
                  }}
                />
                <VegaButton text="Apply Filter" onClick={onApplyFilterClick} />
              </Grid>
            </Grid>
          );
        }}
      />
      <VegaPageContent sx={{ mt: 2, backgroundColor: '' }}>
        {communicationDetailsSummary}
      </VegaPageContent>
      <VegaPageContent sx={{ mt: 2, mb: 2 }}>
        {/* <AccountAndDispositionCodeGraph /> */}
        {executedDistributionGraph}
      </VegaPageContent>
    </>
  );
}

export default CommunicationDetails;
function getDates(startDate: string, endDate: string): string[] {
  const dates: string[] = [];
  const currentDate: Date = new Date(startDate);
  const end: Date = new Date(endDate);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

const getProductTypeOptions = (segment: string) => {
  if (segment === VerticalTypes.CGHFL) {
    const options = getOptions(getProductValues(HLProductType));
    return options;
  } else {
    const options = getOptions(getProductValues(CLProductType));
    return options;
  }
};

const getProductValues = (
  enumType: typeof HLProductType | typeof CLProductType | typeof ProductType
) =>
  Object.values(enumType).map(item => {
    return item;
  });

const getRiskCategoryValues = () => Object.keys(RiskCategory);
const getVerticalOptions = (type: typeof VerticalTypes) => {
  const options = Object.keys(type).map(
    item => ({ value: item, label: toLowerCase(item) } as VegaSelectOption)
  );
  return options;
};
const getOptions = (options: string[]) => {
  const modifyOptions = options.map(
    (option: string) =>
      ({
        label: toLowerCase(option),
        value: option,
      } as VegaSelectOption)
  );
  return modifyOptions;
};
const getActionTypeOptions = (type: typeof ActionTypes) => {
  const options = Object.entries(type).map(
    ([key, value]) => ({ value, label: key } as VegaSelectOption)
  );
  return options;
};
const getDueDateValues = () =>
  Object.values(DueDateTypes).map(item => {
    return item;
  });

const getBucketOptions = () => {
  return Object.entries(BucketOption).map(([value, label]) => ({
    value,
    label,
  }));
};
