import { AddTask, Close, VisibilityOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReferToFieldService } from '../../Apis/ReferToFieldService';
import AcceptReferToFieldDialog from '../../components/ReferToField/AcceptReferToFieldDialog';
import RejectReferToFieldDialog from '../../components/ReferToField/RejectReferToFieldDialog';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaIconButton from '../../components/common/VegaIconButton';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { PRIMARY } from '../../constants/style';
import {
  fetchReferToFieldList,
  referToFieldRequestSelector,
} from '../../features/referToFieldSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { ReferToFieldDTO, ReferToFieldStatus } from '../../types/referToField';
import { GetReferToFieldListRequest } from '../../types/request/referToField';
import { COLOR } from '../../utils/ColorUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';

function ReferToFieldPage() {
  const { user } = useClientAuth();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    referTofieldList,
    page: currentPage,
    totalItems,
  } = useAppSelector(referToFieldRequestSelector);
  const {
    open: openAcceptReferToFieldDialog,
    close: closeAcceptReferToFieldDialog,
    isOpen: isAcceptReferToFieldDialogOpen,
    props: acceptReferToFieldDialogProps,
  } = useDrawer(false);
  const {
    open: openRejectReferToFieldDialog,
    close: closeRejectReferToFieldDialog,
    isOpen: isRejectReferToFieldDialogOpen,
    props: rejectReferToFieldDialogProps,
  } = useDrawer(false);

  function onPageChange(pageNumber: number) {
    setPage(pageNumber);
  }
  function refresh() {
    setPage(0);
    dispatch(
      fetchReferToFieldList({
        approverId: user.id,
        status: ReferToFieldStatus.RAISED,
        page: 0,
        size: 10,
      })
    );
  }
  async function onAcceptReferToFieldClick(fieldData: ReferToFieldDTO) {
    try {
      await ReferToFieldService.updateReferToFieldStatus({
        referToFieldRequestId: fieldData.id,
        status: ReferToFieldStatus.APPROVED,
        approverId: fieldData.approverId,
      });
      refresh();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }
  async function onRejectReferToFieldClick(
    fieldData: ReferToFieldDTO,
    reason: string
  ) {
    try {
      await ReferToFieldService.updateReferToFieldStatus({
        referToFieldRequestId: fieldData.id,
        status: ReferToFieldStatus.REJECTED,
        approverId: fieldData.approverId,
        approverRemark: reason,
      });
      refresh();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const getReferToField = () => {
    const request = {
      approverId: user.id,
      status: ReferToFieldStatus.RAISED,
      page: page,
      size: 10,
    } as GetReferToFieldListRequest;

    if (search.length) {
      request.loanId = search;
    }

    dispatch(fetchReferToFieldList(request));
  };

  useEffect(() => {
    user && getReferToField();
  }, [page, search, user]);

  return (
    <div>
      <VegaPageHeader title="Field Reference" />
      <VegaPageHeader
        renderRightView={() => {
          return (
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
          );
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaPageContent>
        <VegaDataGrid
          page={currentPage}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={referTofieldList}
          columns={getColumnDefinition({
            onAcceptReferToFieldClick: (fieldData: ReferToFieldDTO) => {
              openAcceptReferToFieldDialog({ fieldData: fieldData });
            },
            onRejectReferToFieldClick: (fieldData: ReferToFieldDTO) => {
              openRejectReferToFieldDialog({ fieldData: fieldData });
            },
            onViewDetailClick: (fieldData: ReferToFieldDTO) => {
              navigate(
                ROUTES.Refer_To_Field_Detail_Page.replace(
                  ':loanAccountId',
                  fieldData.loanId
                )
              );
            },
          })}
          idColumn="id"
        />
        <AcceptReferToFieldDialog
          fieldData={acceptReferToFieldDialogProps.fieldData}
          show={isAcceptReferToFieldDialogOpen}
          onClose={() => {
            closeAcceptReferToFieldDialog();
          }}
          onAcceptClick={(fieldData: ReferToFieldDTO) => {
            onAcceptReferToFieldClick(fieldData);
            closeAcceptReferToFieldDialog();
          }}
        />
        <RejectReferToFieldDialog
          fieldData={rejectReferToFieldDialogProps.fieldData}
          show={isRejectReferToFieldDialogOpen}
          onReject={(fieldData: ReferToFieldDTO, reason: string) => {
            onRejectReferToFieldClick(fieldData, reason);
            closeRejectReferToFieldDialog();
          }}
          onClose={() => {
            closeRejectReferToFieldDialog();
          }}
        />
      </VegaPageContent>
    </div>
  );
}

export default ReferToFieldPage;
const getColumnDefinition = (data: {
  onAcceptReferToFieldClick: (fieldData: ReferToFieldDTO) => void;
  onRejectReferToFieldClick: (fieldData: ReferToFieldDTO) => void;
  onViewDetailClick: (fieldData: ReferToFieldDTO) => void;
}) => {
  const { userForId } = useClientAuth();
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const COLUMN_DEF: GridColumns = [
    {
      field: 'Name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: props => {
        const fieldData = props.row as ReferToFieldDTO;
        return (
          <VegaIconButton
            tooltipTitle={getUserName(fieldData?.agentId)}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={getUserName(fieldData?.agentId)}
              />
            }
          />
        );
      },
    },
    {
      field: 'LoanAccountNumber',
      headerName: 'Loan Account Number',
      flex: 0.7,
      renderCell: props => {
        const fieldData = props.row as ReferToFieldDTO;
        return (
          <VegaText
            text={fieldData?.loanId ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Remarks',
      headerName: 'Remarks',
      flex: 0.5,
      minWidth: 200,
      renderCell: props => {
        const fieldData = props.row as ReferToFieldDTO;
        return (
          <VegaIconButton
            tooltipTitle={fieldData?.agentRemark}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={fieldData?.agentRemark}
              />
            }
          />
        );
      },
    },
    {
      field: 'ReferDate',
      headerName: 'Refer Date',
      flex: 0.7,
      renderCell: props => {
        const fieldData = props.row as ReferToFieldDTO;
        return (
          <VegaText
            text={fieldData?.referDate ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: props => {
        const fieldData = props.row as ReferToFieldDTO;
        return (
          <>
            <Box pr={2}>
              <VegaIconButton
                tooltipTitle="Approve"
                sx={{ bgcolor: '#E7EDFC' }}
                icon={
                  <AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />
                }
                onClick={() => {
                  if (fieldData) {
                    data.onAcceptReferToFieldClick(fieldData);
                  }
                }}
              />
            </Box>
            <VegaIconButton
              tooltipTitle="Reject"
              onClick={() => {
                if (fieldData) {
                  data.onRejectReferToFieldClick(fieldData);
                }
              }}
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
            />
          </>
        );
      },
    },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 0.6,
      renderCell: props => {
        const fieldData = props.row as ReferToFieldDTO;
        return (
          // <VegaButton
          //   text="View Details"
          //   onClick={() => {
          //     if (fieldData) {
          //       data.onViewDetailClick(fieldData);
          //     }
          //   }}
          //   variant="text"
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              if (fieldData) {
                data.onViewDetailClick(fieldData);
              }
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};
