import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Collapse, MenuItem, Stack } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { COMMON } from '../../constants/style';
import { VegaSelectOption } from './VegaSelect';
import VegaText from './VegaText';

type Props = {
  searchValue?: string;
  value?: any;
  placeHolder?: string;
  isHideSearch?: boolean;
  options: VegaSelectOption[];
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (selected: string) => void;
  onClear: () => void;
  onScroll?: (e: any) => void;
  selected: string | VegaSelectOption | any;
  specificDisplay?: boolean;
  ignoreLodash?: boolean;
};

function VegaAutoComplete({
  searchValue,
  handleChange,
  onSelect,
  onClear,
  onScroll,
  options,
  selected,
  placeHolder,
  isHideSearch,
  specificDisplay = false,
  ignoreLodash = false,
}: Props) {
  const [show, setShow] = useState<boolean>(false);
  const getLabelForSelectedValue = (selected: string | VegaSelectOption) => {
    const selectedOption =
      options?.filter(item => item.value == selected) ?? [];
    if (selectedOption.length > 0) {
      return selectedOption[0].label;
    }
    return selected;
  };

  const onChange = (selectValue: string | VegaSelectOption) => {
    if (typeof selectValue === 'string') {
      onSelect(selectValue);
    } else {
      onSelect(selectValue.value);
    }
  };

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  return (
    <div ref={domNode} style={{ position: 'relative' }}>
      <Stack
        sx={{
          borderRadius: '9px',
          border: '1px solid #C5C8D0',
          px: 2,
          transition: 'all 0.3s ease-in-out',
          height: '35px',
          cursor: 'pointer',
        }}
        onClick={() => setShow(!show)}
      >
        <Stack
          direction={'row'}
          minHeight={32}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {selected ? (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                flex: 1,
                cursor: 'pointer',
              }}
            >
              <VegaText
                text={
                  specificDisplay
                    ? selected
                    : getLabelForSelectedValue(selected)
                }
              />
              <Close
                onClick={onClear}
                sx={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  cursor: 'pointer',
                  fontSize: 16,
                }}
              />
            </Stack>
          ) : (
            <VegaText text={placeHolder} color={'#C5C8D0'} />
          )}

          <KeyboardArrowDown
            onClick={() => setShow(!show)}
            sx={{
              color: 'rgba(0, 0, 0, 0.54)',
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
              fontSize: 16,
              ...(show ? { rotate: '180deg' } : ''),
            }}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bgcolor: 'white',
          zIndex: 9999,
          width: '100%',
        }}
      >
        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: '6px',
          }}
          in={show}
        >
          <Box sx={{ py: 1 }}>
            {!isHideSearch && (
              <Box
                sx={{
                  px: 1,
                }}
              >
                {' '}
                <input
                  className="VegaInput"
                  placeholder="search..."
                  type="text"
                  style={{
                    height: '35px',
                    width: '100%',
                    borderRadius: '9px',
                    border: '1px solid #C5C8D0',
                    paddingLeft: '10px',
                  }}
                  value={searchValue}
                  onChange={handleChange}
                />
              </Box>
            )}

            <div
              onScroll={onScroll}
              style={{
                maxHeight: 200,
                padding: '10px',
                overflow: 'auto',
              }}
              className=""
            >
              {options.length ? (
                options.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={typeof option === 'string' ? option : option.value}
                    onClick={() => {
                      onChange(option);
                      setShow(false);
                    }}
                  >
                    <Stack
                      direction={'row'}
                      gap={2}
                      color={COMMON.gray}
                      alignItems="center"
                      className="font-aspekta-400"
                    >
                      {typeof option !== 'string' && option.icon && option.icon}
                      {typeof option === 'string'
                        ? _.startCase(_.toLower(option))
                        : ignoreLodash
                        ? option.label
                        : _.startCase(_.toLower(option.label))}
                    </Stack>
                  </MenuItem>
                ))
              ) : (
                <VegaText text={'No Data'} />
              )}
            </div>
          </Box>
        </Collapse>
      </Box>
    </div>
  );
}

export default VegaAutoComplete;

const useClickOutSide = (handler: any) => {
  const domNode = useRef<any>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return domNode;
};
