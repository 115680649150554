import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { PaymentDto } from '../../features/performanceDashboardSlice';
import { PaymentType } from '../../types/receiptManagment';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';

interface IProps {
  loading: boolean;
  data: PaymentDto[];
}

const ListOfBucketPerformance = ({ data, loading }: IProps) => {
  return (
    <VegaPageContent my={'1rem'}>
      <Stack
        sx={{
          p: 2,
          bgcolor: 'white',
        }}
      >
        <Stack mb={'1rem'}>
          <VegaText
            text={'Bucket Performance'}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
        <VegaDataGrid
          idColumn="id"
          columns={getColumnDefinition()}
          data={data}
          loading={loading}
          pageSize={10}
          paginationMode="client"
          bgColor="transparent"
        />
      </Stack>
    </VegaPageContent>
  );
};

export default ListOfBucketPerformance;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'id',
      headerName: 'Bucket Name',
      flex: 0.5,
      minWidth: 80,
    },

    {
      field: 'dark_green',
      headerName: 'Dark Green',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row.bucketType as PaymentType[];
        const findValue = bucketType.find(
          (value: PaymentType) => Object.keys(value)[0] === 'dark_green'
        );
        const value = findValue ? findValue['dark_green'] : '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={value}
          />
        );
      },
    },
    {
      field: 'green',
      headerName: 'Green',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row.bucketType as PaymentType[];
        const findValue = bucketType.find(
          (value: PaymentType) => Object.keys(value)[0] === 'green'
        );
        const value = findValue ? findValue['green'] : '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={value}
          />
        );
      },
    },
    {
      field: 'yellow',
      headerName: 'Yellow',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row.bucketType as PaymentType[];
        const findValue = bucketType.find(
          (value: PaymentType) => Object.keys(value)[0] === 'yellow'
        );
        const value = findValue ? findValue['yellow'] : '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={value}
          />
        );
      },
    },
    {
      field: 'amber',
      headerName: 'Amber',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row.bucketType as PaymentType[];
        const findValue = bucketType.find(
          (value: PaymentType) => Object.keys(value)[0] === 'amber'
        );
        const value = findValue ? findValue['amber'] : '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={value}
          />
        );
      },
    },
    {
      field: 'red',
      headerName: 'Red',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row.bucketType as PaymentType[];
        const findValue = bucketType.find(
          (value: PaymentType) => Object.keys(value)[0] === 'red'
        );
        const value = findValue ? findValue['red'] : '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={value}
          />
        );
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const bucketType = props.row.bucketType as PaymentType[];
        const findValue = bucketType.find(
          (value: PaymentType) => Object.keys(value)[0] === 'TOTAL'
        );
        const value = findValue ? findValue['TOTAL'] : '0';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={value}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
