import { Menu } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoanService } from '../../Apis/LoanServices';
import { useClickOutSide } from '../../constants/commonFunction';
import { COMMON } from '../../constants/style';
import { LoanAccountColumn } from '../../pages/LoanAccount/column/LoanAccountColumn';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { LoanData } from '../../types/loan';
import { LoanTransactionFilterListRequest } from '../../types/request/loan';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaCheckbox from '../common/VegaCheckbox';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaText from '../common/VegaText';

const ListOfLoanAccount = () => {
  const { criteriaId } = useParams();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [allocationsData, setAllocationsData] = useState<LoanData[]>([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const handleSelectionChange = (option: string) => {
    const updatedSelection = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedSelection);
  };

  async function fetchData() {
    setLoading(true);
    try {
      const request = {
        criteriaId: criteriaId,
        page,
        size,
      } as LoanTransactionFilterListRequest;

      const response = await LoanService.getLoanFilterList(request);
      setAllocationsData(response.records);
      setTotalItems(response.totalItems);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const getLoanData = setTimeout(fetchData, 200);
    return () => clearTimeout(getLoanData);
  }, [page, size]);
  return (
    <>
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '1rem', bgcolor: 'transparent' }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <div ref={domNode} style={{ position: 'relative' }}>
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setShow(!show)}
                >
                  <Menu sx={{ color: COMMON.light }} />
                  <VegaText
                    text={'Columns'}
                    fontWeight={600}
                    fontSize={14}
                    color={COMMON.light}
                  />
                </Stack>
                <Box
                  sx={{
                    position: 'absolute',
                    bgcolor: 'white',
                    zIndex: 9999,
                    width: '200px',
                  }}
                >
                  <Collapse
                    sx={{
                      boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                      borderRadius: '6px',
                    }}
                    in={show}
                  >
                    <Box sx={{ py: 1 }}>
                      {Object.keys(Columns).map(column => {
                        return (
                          <Stack
                            key={column}
                            direction={'row'}
                            alignItems={'center'}
                            gap={1}
                          >
                            <VegaCheckbox
                              checked={
                                !selectedOptions.includes(Columns[column])
                              }
                              onChange={() =>
                                handleSelectionChange(Columns[column])
                              }
                            />
                            <VegaText text={Columns[column]} />
                          </Stack>
                        );
                      })}
                    </Box>
                  </Collapse>
                </Box>
              </div>
            </Stack>
          );
        }}
      />
      <VegaDataGrid
        page={page}
        pageSize={size}
        checkboxSelection
        rowCount={totalItems}
        onPageChange={page => {
          setPage(page);
        }}
        paginationMode="server"
        onPageSizeChange={size => setSize(size)}
        data={allocationsData}
        columns={LoanAccountColumn(selectedOptions)}
        loading={loading}
        idColumn="loanId"
      />
    </>
  );
};

export default ListOfLoanAccount;

enum Columns {
  NAME = 'Name',
  LAN = 'Loan Account Number',
  ALLOCATION_TYPE = 'Allocation Type',
  POS = 'POS',
  BUCKET = 'Bucket',
  CONTACT_NUMBER = 'Contact Number',
  ALLOCATION_DATE = 'Allocation Date',
}
