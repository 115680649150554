import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import {
  EscalationsDto,
  TargetEscalationDto,
} from '../../../types/escalationsType';
import VegaButton from '../../common/VegaButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';
import {
  getColorForLevel,
  getColorForStatus,
} from '../EscalationCommonFunctions';

interface IColumnProps {
  selectedTab: number;
  onReolveDialogOpen: (escalation: EscalationsDto) => void;
}

export const DailyTargetColumn = ({
  selectedTab,
  onReolveDialogOpen,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'userName',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const userName = escalations?.userName ? escalations?.userName : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'reason',
      headerName: 'Reason/Remarks',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalations?.escalationReason ?? 'Not filled by agent'}
          />
        );
      },
    },
    {
      field: 'actualTarget',
      headerName: 'Actual Target',
      flex: 0.6,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const targetEscalation =
          escalations?.escalationObject as TargetEscalationDto;
        const actualTarget = targetEscalation?.actualTarget ?? 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={actualTarget}
          />
        );
      },
    },
    {
      field: 'achievedTarget',
      headerName: 'Achieved Target',
      flex: 0.6,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const targetEscalation =
          escalations?.escalationObject as TargetEscalationDto;
        const achievedTarget = targetEscalation?.achievedTarget ?? 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={achievedTarget}
          />
        );
      },
    },
    {
      field: 'targetDeviation',
      headerName: 'Target Deviation',
      flex: 0.6,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const targetEscalation =
          escalations?.escalationObject as TargetEscalationDto;
        const targetDeviation = targetEscalation?.targetDeviation ?? 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={targetDeviation}
          />
        );
      },
    },

    {
      field: 'escalationLevel',
      headerName: 'Escalation Level',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaPill
            text={escalations.escalationLevel}
            backgroundColor={
              getColorForLevel(escalations.escalationLevel)?.LIGHT
            }
            textColor={getColorForLevel(escalations.escalationLevel)?.DARK}
            borderColor={getColorForLevel(escalations.escalationLevel)?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return selectedTab === 0 ? (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text="resolve"
              onClick={() => {
                onReolveDialogOpen(escalations);
              }}
              style={{
                backgroundColor: '#1047DC1A',
                color: PRIMARY.darkBlue,
              }}
            />
          </Stack>
        ) : (
          <VegaPill
            isDot={false}
            text={toLowerCase(escalations.escalationStatus)}
            backgroundColor={
              getColorForStatus(escalations.escalationStatus)?.LIGHT
            }
            textColor={getColorForStatus(escalations.escalationStatus)?.DARK}
            borderColor={getColorForStatus(escalations.escalationStatus)?.DARK}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
