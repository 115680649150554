import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  SimulateFormData,
  SimulatorCriteriaData,
} from '../../types/incentiveSimulatorType';
import { DateUtility } from '../../utils/DateUtlility';

export enum SimulateViewState {
  Simulating,
  Simulated,
  SelectedFile,
  VerifyingFile,
  Verified,
  Initial,
}
const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);
export const initialCriteriaData = {
  incentiveCollectionType: '',
  condition: '',
  value: '',
  metricType: '',
} as SimulatorCriteriaData;

export const initialSimulatorFormData = {
  policyId1: '',
  policyId2: '',
  simulatorCriteria: initialCriteriaData,
  startDate: TODAY?.toISOString(),
  endDate: TOMORROW?.toISOString(),
} as SimulateFormData;

export interface IncentiveSimulatorSliceProps {
  simulateFormData: SimulateFormData;
  simulateViewState: SimulateViewState;
}

const initialState: IncentiveSimulatorSliceProps = {
  simulateFormData: initialSimulatorFormData,
  simulateViewState: SimulateViewState.Initial,
};

const incentiveSimulatorSlice = createSlice({
  name: 'incentiveSimulator',
  initialState,
  reducers: {
    setSimulateFormData: (state: IncentiveSimulatorSliceProps, action) => {
      const { payload } = action;
      state.simulateFormData = payload;
    },
    setSimulateViewState: (state: IncentiveSimulatorSliceProps, action) => {
      const { payload } = action;
      state.simulateViewState = payload;
    },
  },
});
export const { setSimulateFormData, setSimulateViewState } =
  incentiveSimulatorSlice.actions;
export const getIncentiveSimulatorState = (state: RootState) =>
  state.incentiveSimulator;
export default incentiveSimulatorSlice;
