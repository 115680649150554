import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import DepositBankSelector from '../../../../components/BankDeposit/DepositBankSelector';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import LmDateField from '../../../../components/common/LmDateField';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect from '../../../../components/common/VegaSelect';
import {
  getLoanAccountList,
  receiptState,
} from '../../../../features/receiptManagmentSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { LoanListRequest } from '../../../../types/request/loan';
import { CraeteDepositRequest } from '../../../../types/request/receiptManagment';
import { DateUtility } from '../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { IToggleProps } from '../HistoryReceiptManagment';

interface IProps extends IToggleProps {
  onFetchList: () => void;
}

enum PaymentType {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  CASH = 'CASH',
}
interface ICreateDeposit {
  depositCentre: string;
  depositRefId: string;
  depositProof: VegaFileSelectOption[];
}

const CreateDepositDrawer = ({ open, onClose, onFetchList }: IProps) => {
  const { receiptDetailedData, loanAccounts } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [depositState, setDepositState] = useState<ICreateDeposit>({
    depositCentre: '',
    depositRefId: '',
    depositProof: [],
  });
  const [depositType, setDepositType] = useState<string>(
    DepositType.NORMAL_DEPOSIT
  );
  const getLoanList = async () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        searchPartialLoanId: receiptDetailedData?.loanId,
      } as LoanListRequest;
      dispatch(getLoanAccountList(body));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLoanList();
  }, [receiptDetailedData?.loanId]);
  const handleDepositChange = (
    key: keyof ICreateDeposit,
    value: string | VegaFileSelectOption[]
  ) => {
    setDepositState({ ...depositState, [key]: value });
  };

  const handleFileChange = (file: VegaFileSelectOption) => {
    const updateDeposit = [file];
    handleDepositChange('depositProof', updateDeposit);
  };

  const handleDeleteFile = () => {
    handleDepositChange('depositProof', []);
  };
  const onClear = () => {
    onClose();
    setDepositState({
      depositCentre: '',
      depositRefId: '',
      depositProof: [],
    });
  };
  const onCreate = async () => {
    setLoading(true);
    try {
      const request = {
        ...depositState,
        receiptId: receiptDetailedData.receiptId,
        depositProof: depositState.depositProof[0]?.value,
        depositType: depositType,
      } as CraeteDepositRequest;

      if (receiptDetailedData.paymentMode === PaymentType.PAYMENT_GATEWAY) {
        delete request.depositCentre;
      }

      if (!depositState.depositProof) {
        delete request.depositProof;
      }
      const isCashInHand =
        receiptDetailedData?.paymentMode == PaymentType.CASH &&
        request.depositType == DepositType.CASH_IN_HAND;

      if (isCashInHand == true) {
        delete request.depositCentre;
        delete request.depositRefId;
        delete request.depositProof;
        delete request.depositCentre;
      } else {
        delete request.depositType;
      }
      await ReceiptManagmentService.createDeposit(request);
      setLoading(false);
      setSnackbar('Create deposit successfully !!!');
      onClear();
      onFetchList();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  const disabled = () => {
    if (depositType === DepositType.CASH_IN_HAND) {
      return false;
    } else {
      if (receiptDetailedData.paymentMode === PaymentType.PAYMENT_GATEWAY) {
        return !(depositState.depositRefId.length < 50);
      }
      return (
        !depositState.depositCentre.length ||
        !(depositState.depositRefId.length < 50)
      );
    }
  };
  const options = useCallback(() => {
    return Object.values(DepositType).map(item => {
      return {
        label: _.startCase(_.toLower(item)),
        value: item,
      };
    });
  }, []);

  return (
    <VegaDrawer open={open} onClose={onClear} title="Create Deposit">
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Clear All"
                onClick={onClear}
                variant="text"
                sx={{
                  color: '#1047DC',
                }}
              />
              <VegaButton
                text="Create Deposit"
                onClick={onCreate}
                loading={loading}
                disabled={disabled()}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Customer Name'}>
              <LmTextField
                disabled
                type="text"
                value={receiptDetailedData?.payeeName}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Loan Account Number'}>
              <LmTextField
                type="text"
                disabled
                value={receiptDetailedData?.loanId}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Contact Number'}>
              <LmTextField
                type="text"
                disabled
                value={loanAccounts?.length && loanAccounts[0]?.contactNumber}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Allocation Date '}>
              <LmTextField
                disabled
                type="text"
                value={loanAccounts?.length && loanAccounts[0]?.allocationDate}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Receipt Number'}>
              <LmTextField
                type="text"
                value={receiptDetailedData?.receiptNumber}
                disabled
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Receipt Amount'}>
              <LmTextField
                type="text"
                value={receiptDetailedData?.totalAmount}
                disabled
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Receipt Date'}>
              <LmDateField
                disabled
                value={DateUtility.formatStringToDDMMMMYYYY(
                  receiptDetailedData?.receiptDate
                )}
                onChange={() => {}}
              />
            </VegaFormInputField>
          </Grid>
          {receiptDetailedData?.paymentMode === PaymentType.CASH && (
            <Grid item xs={12}>
              <VegaFormInputField label={'Deposit Type'}>
                <VegaSelect
                  value={_.startCase(_.toLower(depositType))}
                  options={options()}
                  onChange={e => {
                    const value = e.target.value as string;
                    setDepositType(value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
          )}
          <AnimatedInputWrapper
            show={depositType === DepositType.NORMAL_DEPOSIT}
          >
            {receiptDetailedData.paymentMode !==
              PaymentType.PAYMENT_GATEWAY && (
              <Grid item xs={12}>
                <VegaFormInputField label={'deposit Center'} isMandatory>
                  <DepositBankSelector
                    selected={depositState.depositCentre}
                    segment={loanAccounts?.length && loanAccounts[0]?.segment}
                    handleChange={selected =>
                      handleDepositChange('depositCentre', selected)
                    }
                  />
                </VegaFormInputField>
              </Grid>
            )}
            <Grid item xs={12} marginTop={2}>
              <VegaFormInputField
                label={'Deposit Reference Number'}
                isMandatory
              >
                <LmTextField
                  type="text"
                  value={depositState.depositRefId}
                  onChange={e => {
                    handleDepositChange('depositRefId', e.target.value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <VegaFormInputField label={'Deposit Proof'}>
                <Stack rowGap={'0.75rem'}>
                  {depositState.depositProof.length ? (
                    <VegaFileSelect
                      value={depositState.depositProof[0]}
                      acceptedExtension={[
                        AcceptedExtension.JPG,
                        AcceptedExtension.JPEG,
                        AcceptedExtension.PNG,
                        AcceptedExtension.PDF,
                      ]}
                      placeholder={'Upload Document '}
                      onFileSelect={file => {
                        handleFileChange(file);
                      }}
                      onDeleteClick={() => {
                        handleDeleteFile();
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <VegaFileSelect
                    placeholder={'Upload Document '}
                    onFileSelect={file => {
                      handleFileChange(file);
                    }}
                  />
                </Stack>
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default CreateDepositDrawer;
enum DepositType {
  NORMAL_DEPOSIT = 'NORMAL_DEPOSIT',
  CASH_IN_HAND = 'CASH_IN_HAND',
}
