import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import VegaText from '../../../../../components/common/VegaText';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import {
  AnalyticsQueryTypeEnum,
  BucketEnumForYearAnalytics,
} from '../../../../../features/analyticsDashboardSlice';
import { RoleName } from '../../../../../types/roleType';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { YearToDateBucketPerformanceResultDto } from '../../../../../types/analytics';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import LoadingPage from '../../../../LoadingPage/LoadingPage';

interface IProps {
  selectedCollectionProfitType: string;
}
const MOMTeleCallerGraph = ({ selectedCollectionProfitType }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<YearToDateBucketPerformanceResultDto[]>
  >([]);
  const [selectedTeleCallerBucket, setSelectedTeleCallerBucket] =
    useState<string>(BucketEnumForYearAnalytics.OVERALL);
  const [loading, setLoading] = useState<boolean>(false);
  const getMonthName = (month: number): string => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthNames[month - 1] || '';
  };
  const modifyAnalyticsQuery = analyticsQueryResponse.map(item => ({
    categories: ` ${getMonthName(item.month)}- ${item.year} `,
    series: {
      resolution: item?.overallResolution,
    },
  }));

  const maxOverallResolution = Math.max(
    ...analyticsQueryResponse.map(item => item.overallResolution)
  );
  const minNumber = (): number => {
    const overallResolution = [
      ...analyticsQueryResponse.map(item => item.overallResolution),
    ];
    const nonZeroValues = overallResolution.filter(value => value !== 0);

    if (nonZeroValues.length === 0) {
      return 0;
    }

    const minValue = Math.min(...nonZeroValues);
    return minValue;
  };

  const currentMonth = new Date().getMonth();
  const monthData = analyticsQueryResponse.map(item => ({
    month: item.month,
    year: item.year,
    resolution: item?.overallResolution,
  }));
  const yearSeries = [
    {
      name: 'Overall Resolution',
      data: monthData.map(item => ({
        x: ` ${getMonthName(item.month)}- ${item.year} `,
        y: parseFloat(item?.resolution.toFixed(2)),
        fillColor:
          item.month === currentMonth
            ? '#86B6F6'
            : item.resolution === maxOverallResolution
            ? '#65B741'
            : item.resolution === minNumber()
            ? '#C70039'
            : '#dedede',
      })),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: modifyAnalyticsQuery?.map(value => value.categories),
    },
  };

  const getTeleCallerQueries = async () => {
    setLoading(true);
    try {
      const request: AnalyticsQueryRequest = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.YEAR_TO_DATE_BUCKET_LEVEL_PERFORMANCE,
        params: {
          role: RoleName.TELE_COLLECTION_EXECUTIVE,
          type: selectedCollectionProfitType,
        },
      };

      if (selectedTeleCallerBucket !== BucketEnumForYearAnalytics.OVERALL) {
        request.params = {
          ...request.params,
          bucket: selectedTeleCallerBucket,
        };
      }
      const response = await AnaltyticsService.getAnalyticsQueries(request);

      if (response) {
        setAnalyticsQueryResponse(response);
      }
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeleCallerQueries();
    // const dummyData = generateDummyData();
    // setAnalyticsQueryResponse(dummyData);
  }, [selectedTeleCallerBucket, selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper
        title="Tele Caller (in %)"
        rightComponent={
          <Stack width={150}>
            {
              <VegaSelect
                options={getOptions(BucketEnumForYearAnalytics)}
                value={selectedTeleCallerBucket}
                onChange={select =>
                  setSelectedTeleCallerBucket(select.target.value as string)
                }
              />
            }
          </Stack>
        }
      >
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: -8,
                  top: 30,
                  height: '81%',
                  width: 40,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {modifyAnalyticsQuery.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1' }}>
                          {getPercentageValue(val.series)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={yearSeries}
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default MOMTeleCallerGraph;
const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
const getOptions = (type: typeof BucketEnumForYearAnalytics) => {
  const options = Object.values(type).map(
    item =>
      ({
        value: item,
        label: item,
      } as VegaSelectOption)
  );
  return options;
};
