import { Stack } from '@mui/material';
import { useState } from 'react';
import { RoleServices } from '../../../Apis/RoleServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { RoleAction } from '../../../features/roleAccessSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch } from '../../../store';
import {
  AddAccessScreen,
  GetRoleAccessList,
  RoleResourceDto,
} from '../../../types/roleType';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import AddScreenForm from '../components/AddScreenForm';

interface IProps {
  open: boolean;
  onClose: () => void;
  roleDetails: RoleResourceDto;
}

const AddScreenDrawer = ({ open, onClose, roleDetails }: IProps) => {
  const { setSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const [selectedResources, setSelectedResources] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onClickClose = () => {
    onClose();

    setSelectedResources([]);
  };
  const onSubmit = () => {
    setLoading(true);
    const request = {
      resourceIds: selectedResources.map(role => role.value),
      role: roleDetails.id,
    } as AddAccessScreen;
    RoleServices.addAccessScreen(request)
      .then(() => {
        const request = { clientId: 'capri', type: 'UI' } as GetRoleAccessList;
        dispatch(RoleAction.fetchRoles(request));
        onClickClose();
        setLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setLoading(false);
      });
  };

  return (
    <div>
      <VegaDrawer open={open} title={'Assign Resource'} onClose={onClickClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Add Screen"
                  onClick={onSubmit}
                  disabled={!selectedResources.length}
                  loading={loading}
                />
              </Stack>
            );
          }}
        >
          <AddScreenForm
            selectedResources={selectedResources}
            setSelectedResources={setSelectedResources}
            roleDetails={roleDetails}
          />
        </VegaDrawerContent>
      </VegaDrawer>
    </div>
  );
};

export default AddScreenDrawer;
