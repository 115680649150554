import { Box, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { PRIMARY } from '../../constants/style';
import LmBulkUpload from './LmBulkUpload';
import LmButton from './LmButton';
import LmDialog from './LmDialog';
import VegaText from './VegaText';

type Props = {
  open: boolean;
  title?: string;
  onClose: () => void;
  onSampleDownloadClick: () => void;
  onUploadClick: (file: File) => void;
};
const VegaBulkUploadModal = ({
  open,
  onClose,
  onSampleDownloadClick,
  onUploadClick,
  title = 'Upload',
}: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 10) {
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 10MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setUploadProgress(0);
    onClose();
  };

  function handleUploadClick() {
    if (!selectedFile) return;
    onUploadClick(selectedFile);
    handleClose();
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      isCloseIcon
      handleClose={handleClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          borderTop={'1px solid #E1E4EB'}
          justifyContent={'space-between'}
          px={4}
          py={3}
          gap={1}
        >
          <LmButton
            text="Download Sample File"
            variant="text"
            bgColor=""
            textColor={PRIMARY.darkBlue}
            onClick={onSampleDownloadClick}
          />
          <LmButton
            text="Upload"
            disabled={!selectedFile || loading}
            onClick={handleUploadClick}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text={title}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <Box px={4} pt={3} pb={4}>
        <LmBulkUpload
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          error={error}
          uploadProgress={uploadProgress}
          loading={loading}
        />
      </Box>
    </LmDialog>
  );
};

export default VegaBulkUploadModal;
