import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../components/common/VegaIconButton';
import { PRIMARY } from '../../constants/style';
import { LocationTrackingDetailType } from '../LocationTracking/LocationTracking';
interface IColumnProps {
  onViewDetailClick: (loanId: string[]) => void;
}

export function DigitalCollectionColumn({ onViewDetailClick }: IColumnProps) {
  const COL_DEF: GridColumns<LocationTrackingDetailType> = [
    {
      field: 'segment',
      headerName: 'Segment',
      flex: 0.7,
      minWidth: 150,
    },

    {
      field: 'channel',
      headerName: 'Channel',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'actionStatus',
      headerName: 'ActionStatus',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'uniqueAccounts',
      headerName: 'Unique Account',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'count',
      headerName: 'Count',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'action',
      headerName: 'View Unique Accounts',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const details = props.row as LocationTrackingDetailType;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onViewDetailClick(details.loanIds)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
}

export default DigitalCollectionColumn;
