import { Box, Collapse, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PerformanceServices } from '../../Apis/PerformanceServices';
import {
  BRANCH_CODE,
  TOTAL_BRANCH,
  TOTAL_CITIES,
} from '../../constants/athenaQueryId';
import { toLowerCase } from '../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../constants/style';
import {
  getPerformanceDashboard,
  setFilterData,
} from '../../features/performanceDashboardSlice';
import { PerformanceFilterInputType } from '../../pages/Performance/Performance';
import { useClientAuth } from '../../providers/ClientProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { DispositionCode } from '../../types/trail';
import { DateUtility } from '../../utils/DateUtlility';
import AgentSelector from '../AgentSelector';
import LmTextField from '../common/LmTextField';
import VegaAutoComplete from '../common/VegaAutoComplete';
import VegaButton from '../common/VegaButton';
import VegaDateTimePicker from '../common/VegaDateTimePicker';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaMonthPicker from '../common/VegaMonthPicker';
import { VegaSelectOption } from '../common/VegaSelect';
import VegaText from '../common/VegaText';
import ListOfPerformance from './ListOfPerformance';
import { Params } from '../../types/request/performanceRequest';
import { BucketOption } from '../../types/minVisitsType';

enum BucketType {
  BUCKETX = 'BUCKETX',
  BUCKET1 = 'BUCKET1',
  BUCKET2 = 'BUCKET2',
  BUCKET3 = 'BUCKET3',
  NPA = 'NPA',
}

export enum ProductType {
  HOME_LOAN = 'Home Loan',
  SME_TL = 'SME-TL',
  BUSINESS_LOANS = 'Business Loans',
  PRE_APPROVED_LOANS = 'PreApprovedLoans',
  HOME_EQUITY = 'Home Equity',
  MSME_TL = 'MSME-TL',
  PRE_APPROVED_LOANS_2 = 'Preapproved Loans',
  HOME_EQUITY_2 = 'Home EQuity',
}

export enum HLProductType {
  HOME_LOAN = 'Home Loan',
  HOME_EQUITY = 'Home Equity',
  PRE_APPROVED_LOANS = 'PreApprovedLoans',
  MICRO_HE_PORTFOLIO = 'Sahaj Uannati Micro HE Portfolio',
}

export enum RiskCategory {
  VLP = 'VLP',
  HP = 'HP',
  VHP = 'VHP',
  MP = 'MP',
  LP = 'LP',
}

export enum CLProductType {
  MSME_TL = 'MSME-TL',
  SME_TL = 'SME-TL',
  BUSINESS_LOANS = 'Business Loans',
  EQUIPMENT_LOANS = 'Equipment Loan',
  PRE_APPROVED_LOANS = 'Preapproved Loan',
  MICRO_LOAN = 'Sahaj Unnati Micro Loan',
}

export enum DueDateTypes {
  CYCLE_DATE_1 = '1',
  CYCLE_DATE_5 = '5',
  CYCLE_DATE_10 = '10',
  CYCLE_DATE_15 = '15',
}

enum LoanStatusTypes {
  COLLECTED = 'COLLECTED',
  OVERDUE = 'OVERDUE',
}

export enum VerticalTypes {
  CGCL = 'CGCL',
  CGHFL = 'CGHFL',
}

type Props = {
  setApplyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setClearFilter: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilter: boolean;
  applyFilter: boolean;
};

const PerformanceFilterSection = ({
  setApplyFilter,
  setClearFilter,
  clearFilter,
  applyFilter,
}: Props) => {
  const { userId } = useClientAuth();
  const { filterData } = useAppSelector(getPerformanceDashboard);
  const dispatch = useAppDispatch();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [searchBranch, setSearchBranch] = useState<string>('');
  const [searchCity, setSearchCity] = useState<string>('');
  const [branchOptions, setbranchOptions] = useState<string[]>([]);
  const isAppliedFilterDisabled = () => {
    return Object.keys(filterData).length > 0 ? true : false;
  };

  async function getBranchList(queryId: string) {
    const body = {
      queryId,
      params: {
        AGENT_ID: userId,
      },
    };
    const response = await PerformanceServices.getAthenaQueries(body);
    if (!response) {
      setCityOptions(['ALL']);
    }
    const options = Object.values(response).map(item => {
      return item.brn_city;
    });
    setbranchOptions(options);
  }

  async function getCityList(queryId: string) {
    const body = {
      queryId,
      params: {
        AGENT_ID: userId,
      },
    };
    const response = await PerformanceServices.getAthenaQueries(body);
    if (!response) {
      setCityOptions(['ALL']);
    }
    const bankArray = Object.values(response).map(item => {
      return item.branch_code;
    });

    setCityOptions(bankArray);
  }

  const handleDateChange = (value: string, fieldHeader: string) => {
    const futureDateString = DateUtility.parseDateFromDatePicker(value);
    const updateFilterData = {
      ...filterData,
      [fieldHeader]: futureDateString?.toISOString(),
    };
    dispatch(setFilterData(updateFilterData));
  };
  const handleStartDateChange = (value: string, fieldHeader: string) => {
    const parsedDate = DateUtility.parseDateFromDatePicker(value);

    var endDateTime = DateUtility.parseStringToDate(filterData?.END_DATE);
    if (endDateTime && parsedDate) {
      if (DateUtility.isBefore(endDateTime, parsedDate)) {
        endDateTime = DateUtility.addDays(parsedDate, 1);
      }
    }
    const updateFilterData = {
      ...filterData,
      [fieldHeader]: parsedDate?.toISOString(),
      END_DATE: endDateTime?.toISOString(),
    };
    dispatch(setFilterData(updateFilterData));
  };

  const handleChange = (
    type: PerformanceFilterInputType | string,
    value: string
  ) => {
    const updateFilterData = { ...filterData, [type]: value };
    dispatch(setFilterData(updateFilterData));
  };

  function handleSegmentChange(segmentValue: string, productValue: string) {
    const updateFilterData: Partial<Params> = {
      ...filterData,
      SEGMENT: segmentValue,
      PRODUCT: productValue,
    };
    dispatch(setFilterData(updateFilterData));
  }

  const getProductTypeOptions = () => {
    if (filterData.SEGMENT === VerticalTypes.CGHFL) {
      const options = getOptions(getProductValues(HLProductType));
      return options;
    } else {
      const options = getOptions(getProductValues(CLProductType));
      return options;
    }
  };

  useEffect(() => {
    getCityList(BRANCH_CODE);
    getBranchList(TOTAL_BRANCH);
  }, []);

  return (
    <Box
      sx={{
        border: BORDER_COLLECTION,
        borderRadius: '12px',
        p: 3,
      }}
    >
      <VegaText
        text={'Filter'}
        fontWeight={600}
        fontSize={'1.0625rem'}
        color={'black'}
      />
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.AGENT_ID)}
          >
            <AgentSelector
              selected={filterData?.AGENT_ID ?? ''}
              onSelected={selected =>
                handleChange(PerformanceFilterInputType.AGENT_ID, selected)
              }
              onClear={() =>
                handleChange(PerformanceFilterInputType.AGENT_ID, '')
              }
              ignoreLodash
              ignoreAgentCode={false}
            />
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.MANAGER_ID)}
          >
            <AgentSelector
              selected={filterData?.MANAGER_ID ?? ''}
              onSelected={selected =>
                handleChange(PerformanceFilterInputType.MANAGER_ID, selected)
              }
              onClear={() =>
                handleChange(PerformanceFilterInputType.MANAGER_ID, '')
              }
              ignoreLodash
              ignoreAgentCode={false}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.CUSTOMER_NAME)}
          >
            <LmTextField
              value={filterData?.CUSTOMER_NAME ?? ''}
              onChange={e =>
                handleChange(
                  PerformanceFilterInputType.CUSTOMER_NAME,
                  e.target.value
                )
              }
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(
              PerformanceFilterInputType.MONTH_AND_YEAR_OF_ALLOCATION
            )}
          >
            <VegaMonthPicker
              value={filterData?.MONTH_AND_YEAR_OF_ALLOCATION ?? ''}
              onChange={e => {
                handleDateChange(
                  e,
                  PerformanceFilterInputType.MONTH_AND_YEAR_OF_ALLOCATION
                );
              }}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.START_DATE)}
          >
            <VegaDateTimePicker
              value={filterData?.START_DATE ?? ''}
              onChange={e => {
                handleStartDateChange(e, PerformanceFilterInputType.START_DATE);
              }}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.END_DATE)}
          >
            <VegaDateTimePicker
              value={filterData?.END_DATE ?? ''}
              onChange={e => {
                handleDateChange(e, PerformanceFilterInputType.END_DATE);
              }}
              minDate={filterData?.START_DATE}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.SEGMENT)}
          >
            <VegaAutoComplete
              isHideSearch
              options={getOptions(getVerticalValues())}
              onClear={() => {
                handleSegmentChange('', '');
              }}
              onSelect={selected => {
                handleSegmentChange(selected, '');
              }}
              selected={filterData?.SEGMENT ?? ''}
              placeHolder="Select Segmet"
            />
            {/* <VegaSelect
              options={getOptions(getVerticalValues())}
              onChange={e => {
                handleChange(
                  PerformanceFilterInputType.SEGMENT,
                  e.target.value
                );
              }}
              value={filterData?.SEGMENT ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.PRODUCT)}
          >
            <VegaAutoComplete
              isHideSearch
              options={getProductTypeOptions()}
              onClear={() =>
                handleChange(PerformanceFilterInputType.PRODUCT, '')
              }
              onSelect={selected =>
                handleChange(PerformanceFilterInputType.PRODUCT, selected)
              }
              selected={filterData?.PRODUCT ?? ''}
              placeHolder="Select Product"
            />
            {/* <VegaSelect
              options={getOptions(getProductValues())}
              onChange={e => {
                handleChange(
                  PerformanceFilterInputType.PRODUCT,
                  e.target.value
                );
              }}
              value={filterData?.PRODUCT ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.DISPOSITION_CODE)}
          >
            <VegaAutoComplete
              isHideSearch
              options={getOptions(getDispositionCodeValues())}
              onClear={() =>
                handleChange(PerformanceFilterInputType.DISPOSITION_CODE, '')
              }
              onSelect={selected =>
                handleChange(
                  PerformanceFilterInputType.DISPOSITION_CODE,
                  selected
                )
              }
              selected={filterData?.DISPOSITION_CODE ?? ''}
              placeHolder="Select Diposition Code"
            />
            {/* <VegaSelect
              options={getOptions(getDispositionCodeValues())}
              onChange={e => {
                handleChange(
                  PerformanceFilterInputType.DISPOSITION_CODE,
                  e.target.value
                );
              }}
              value={filterData?.DISPOSITION_CODE ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.DUE_DATE)}
          >
            <VegaAutoComplete
              isHideSearch
              options={getOptions(getDueDateValues())}
              onClear={() =>
                handleChange(PerformanceFilterInputType.DUE_DATE, '')
              }
              onSelect={selected =>
                handleChange(PerformanceFilterInputType.DUE_DATE, selected)
              }
              selected={filterData?.DUE_DATE ?? ''}
              placeHolder="Select Due date"
            />
            {/* <VegaSelect
              options={getOptions(getDueDateValues())}
              onChange={e => {
                handleChange(
                  PerformanceFilterInputType.DUE_DATE,
                  e.target.value
                );
              }}
              value={filterData?.DUE_DATE ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.LOAN_STATUS)}
          >
            <VegaAutoComplete
              isHideSearch
              options={getOptions(getLoanStatusValues())}
              onClear={() =>
                handleChange(PerformanceFilterInputType.LOAN_STATUS, '')
              }
              onSelect={selected =>
                handleChange(PerformanceFilterInputType.LOAN_STATUS, selected)
              }
              selected={filterData?.LOAN_STATUS ?? ''}
              placeHolder="Select loan status"
            />
            {/* <VegaSelect
              options={getOptions(getLoanStatusValues())}
              onChange={e => {
                handleChange(
                  PerformanceFilterInputType.LOAN_STATUS,
                  e.target.value
                );
              }}
              value={filterData?.LOAN_STATUS ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.BUCKET)}
          >
            <VegaAutoComplete
              isHideSearch
              options={getBucketOptions()}
              onClear={() =>
                handleChange(PerformanceFilterInputType.BUCKET, '')
              }
              onSelect={selected =>
                handleChange(PerformanceFilterInputType.BUCKET, selected)
              }
              selected={filterData?.BUCKET ?? ''}
              placeHolder="Select bucket"
              ignoreLodash
            />
            {/* <VegaSelect
              options={getOptions(getBucketValue())}
              onChange={e => {
                handleChange(PerformanceFilterInputType.BUCKET, e.target.value);
              }}
              value={filterData?.BUCKET ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            label={toLowerCase(PerformanceFilterInputType.BRANCH)}
          >
            <VegaAutoComplete
              searchValue={searchCity}
              handleChange={e => setSearchCity(e.target.value)}
              options={filteredOptions(
                searchCity,
                getBranchOptions(cityOptions)
              )}
              onClear={() =>
                handleChange(PerformanceFilterInputType.BRANCH_CODE, '')
              }
              onSelect={selected =>
                handleChange(PerformanceFilterInputType.BRANCH_CODE, selected)
              }
              selected={filterData?.BRANCH_CODE ?? ''}
              placeHolder="Select branch"
            />
            {/* <VegaSelect
              options={getOptions(cityOptions)}
              onChange={e => {
                handleChange(PerformanceFilterInputType.CITY, e.target.value);
              }}
              value={filterData?.CITY ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4}>
          <VegaFormInputField
            // label={toLowerCase(PerformanceFilterInputType.BRANCH)}
            label={toLowerCase(PerformanceFilterInputType.CITY)}
          >
            <VegaAutoComplete
              searchValue={searchBranch}
              handleChange={e => setSearchBranch(e.target.value)}
              options={filteredOptions(searchBranch, getOptions(branchOptions))}
              onClear={() =>
                handleChange(PerformanceFilterInputType.BRANCH, '')
              }
              onSelect={selected =>
                handleChange(PerformanceFilterInputType.BRANCH, selected)
              }
              selected={filterData?.BRANCH ?? ''}
              placeHolder="Select city"
            />
            {/* <VegaSelect
              options={getOptions(branchOptions)}
              onChange={e => {
                handleChange(PerformanceFilterInputType.BRANCH, e.target.value);
              }}
              value={filterData?.BRANCH ?? ''}
            /> */}
          </VegaFormInputField>
        </Grid>
      </Grid>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <VegaButton
          variant="text"
          text={showDetails ? 'Hide Details' : 'View Details'}
          onClick={() => setShowDetails(!showDetails)}
        />
        <Stack direction={'row'} gap={1}>
          <VegaButton
            variant="outlined"
            text="Clear Filter"
            sx={{
              ':hover': {
                bgcolor: 'transparent',
                color: '#1047DC',
              },
            }}
            onClick={() => {
              dispatch(setFilterData({}));
              setClearFilter(true);
            }}
          />
          {/* <VegaButton
            variant="outlined"
            text="Summary"
            sx={{
              ':hover': {
                bgcolor: 'transparent',
                color: '#1047DC',
              },
            }}
            onClick={() => navigate(ROUTES.PERFOMANCE_SUMMARY)}
          /> */}

          <VegaButton
            text="Apply Filter"
            onClick={() => setApplyFilter(true)}
            disabled={!isAppliedFilterDisabled()}
          />
        </Stack>
      </Stack>
      <Collapse in={showDetails}>
        <Box mt={2}>
          <hr
            style={{
              borderTop: BORDER_COLLECTION,
              marginBottom: '16px',
            }}
          />
          <ListOfPerformance
            applyFilter={applyFilter}
            clearFilter={clearFilter}
            setClearFilter={setClearFilter}
            setApplyFilter={setApplyFilter}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default PerformanceFilterSection;

const getOptions = (options: string[]) => {
  const modifyOptions = options.map(
    (option: string) =>
      ({
        label: toLowerCase(option),
        value: option,
      } as VegaSelectOption)
  );
  return modifyOptions;
};

const getBranchOptions = (options: string[]) => {
  const modifyOptions = options.map(
    (option: string) =>
      ({
        label: option,
        value: option,
      } as VegaSelectOption)
  );
  return modifyOptions;
};
const getBucketOptions = () => {
  return Object.entries(BucketOption).map(([value, label]) => ({
    value,
    label,
  }));
};
const getDispositionCodeValues = () =>
  Object.values(DispositionCode).map(item => {
    return item;
  });

const getDueDateValues = () =>
  Object.values(DueDateTypes).map(item => {
    return item;
  });

const getLoanStatusValues = () =>
  Object.values(LoanStatusTypes).map(item => {
    return item;
  });

const getProductValues = (
  enumType: typeof HLProductType | typeof CLProductType | typeof ProductType
) =>
  Object.values(enumType).map(item => {
    return item;
  });
const getVerticalValues = () =>
  Object.values(VerticalTypes).map(item => {
    return item;
  });

const filteredOptions = (searchValue: string, list: VegaSelectOption[]) => {
  if (searchValue.length) {
    const searchLowerCase = searchValue.toLowerCase();
    const filteredResults = list.filter(value => {
      if (searchValue === '') {
        return value;
      } else if (value.label.toLowerCase().includes(searchLowerCase)) {
        return value;
      }
    });
    return filteredResults;
  } else {
    return list;
  }
};
