import { createContext, useContext, useEffect, useState } from 'react';
import {
  createCustomerBank,
  deleteCustomerBank,
  fetchCustomerBanksList,
  updateCustomerBank,
  updateCustomerBankStatus,
} from '../Apis/customerBank';
import {
  BnakCustomerRequest,
  ICreateCustomerBranchProps,
  ICustomerBankListProps,
} from '../types/customerBank';

import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';

export interface IPastUploadListProps {
  id: number | string;
  requestedId: string;
  fileName: string;
  date: string;
  totalEntries: string;
  success: string;
  failure: string;
  status: string;
}
export interface BankCustomerContextType {
  customerBankState: ICreateCustomerBranchProps | null;
  bankList: ICustomerBankListProps[];
  search: string;
  bankListLoading: boolean;
  loading: boolean;
  isAdd: boolean;
  isBulkUpload: boolean;
  isPastUploadView: boolean;
  page: number;
  size: number;
  totalItems: number;
  selectedId: string | null;
  toggleAddBankBranchDrawer: () => void;
  toggleBulkUploadModal: () => void;
  togglePastUploadViewModal: () => void;
  onAddCustomerBankSubmit: () => void;
  onDeleteCustomerBank: () => void;

  onUpdateCustomerBankSubmit: (
    updatedCustomerBank: ICreateCustomerBranchProps
  ) => void;
  getCustomerBank: (request: Partial<BnakCustomerRequest>) => void;

  onUpdateCustomerBankStatus: (
    updatedCustomerBank: ICreateCustomerBranchProps
  ) => void;

  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
  setCustomerBankState: React.Dispatch<
    React.SetStateAction<ICreateCustomerBranchProps>
  >;
  handleCustomerBankChange: (
    name: keyof ICreateCustomerBranchProps,
    value: string
  ) => void;
}

export const BankCustomerContext =
  createContext<BankCustomerContextType | null>(null);

export const defaultCustomerBankData: ICreateCustomerBranchProps = {
  bankName: '',
  // ifsc: '',
  micr: '',
  branchName: '',
};

const CustomerBankProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [isPastUploadView, setIsPastUploadView] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [customerBankState, setCustomerBankState] =
    useState<ICreateCustomerBranchProps>({ ...defaultCustomerBankData });

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [bankList, setBankList] = useState<ICustomerBankListProps[]>([]);
  const [bankListLoading, setBankListLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleAddBankBranchDrawer = () => {
    setIsAdd(!isAdd);
  };
  const toggleBulkUploadModal = () => {
    setIsBulkUpload(!isBulkUpload);
  };
  const togglePastUploadViewModal = () => {
    setIsPastUploadView(!isPastUploadView);
  };

  const onAddCustomerBankSubmit = async () => {
    if (!customerBankState) return;
    setLoading(true);
    try {
      await createCustomerBank(customerBankState);
      setSnackbar('Customer Branch Added');
      toggleAddBankBranchDrawer();
      getCustomerBank({});
      setLoading(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Customer Bank'),
        'error'
      );
      setLoading(false);
    }
  };

  const onUpdateCustomerBankSubmit = async (
    updatedCustomerBank: ICreateCustomerBranchProps
  ) => {
    if (!updatedCustomerBank) return;
    setLoading(true);
    try {
      await updateCustomerBank(updatedCustomerBank);
      setSnackbar('Customer bank updated');
      toggleAddBankBranchDrawer();
      getCustomerBank({});
      setLoading(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to updated Customer bank'),
        'error'
      );
      setLoading(false);
    }
  };
  const onUpdateCustomerBankStatus = async (
    updatedCustomerBank: ICreateCustomerBranchProps
  ) => {
    if (!updatedCustomerBank) return;
    try {
      await updateCustomerBankStatus(updatedCustomerBank);
      setSnackbar(' Status updated');
      getCustomerBank({});
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to updated status'),
        'error'
      );
    }
  };

  const onDeleteCustomerBank = async () => {
    if (!selectedId) return;
    try {
      await deleteCustomerBank(selectedId);
      getCustomerBank({});
      setSnackbar('Customer Branch deleted');
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to delete Customer Branch'),
        'error'
      );
    }
  };

  const handleCustomerBankChange = (
    name: keyof ICreateCustomerBranchProps,
    value: string
  ) => {
    setCustomerBankState({ ...customerBankState, [name]: value });
  };

  const getCustomerBank = async (request: Partial<BnakCustomerRequest>) => {
    setBankListLoading(true);
    try {
      const response = await fetchCustomerBanksList(request);
      setBankListLoading(false);
      setBankList(response.records);
      setTotalItems(response.totalItems);
    } catch (error) {
      setBankListLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    getCustomerBank({ bankName: search, page, size });
  }, [page, size, search]);

  return (
    <BankCustomerContext.Provider
      value={{
        bankList,
        loading,
        page,
        size,
        totalItems,
        bankListLoading,
        customerBankState,
        search,
        isAdd,
        isBulkUpload,
        isPastUploadView,
        selectedId,
        setPage,
        setSize,
        setTotalItems,
        getCustomerBank,
        setSelectedId,
        setCustomerBankState,
        toggleAddBankBranchDrawer,
        toggleBulkUploadModal,
        togglePastUploadViewModal,
        onAddCustomerBankSubmit,
        onUpdateCustomerBankSubmit,
        onUpdateCustomerBankStatus,
        onDeleteCustomerBank,
        setSearch,
        handleCustomerBankChange,
      }}
    >
      {children}
    </BankCustomerContext.Provider>
  );
};

export const useBankCustomer = () =>
  useContext(BankCustomerContext) as BankCustomerContextType;

export default CustomerBankProvider;
