import { Stack } from '@mui/material';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AnaltyticsService } from '../../../Apis/AnaltyticsService';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaIconButton from '../../../components/common/VegaIconButton';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  AllEnums,
  AnalyticsQueryTypeEnum,
  BucketEnumForYearAnalytics,
  CelenderViewType,
  CollectionWiseType,
  OverallMetricsEnum2,
  SegmentOptionType,
  TimePeriodEnumForPenal,
  getAnalyticsDashboardState,
} from '../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../store';
import {
  SegmentDto,
  SegmentPerformanceresponse,
} from '../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../types/request/analyticsRequest';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LoadingPage from '../../LoadingPage/LoadingPage';
import GraphWrapper from '../components/GraphWrapper';
import { TimePeriod } from './OverAllPerformanceTable';
import LmFilterSection from '../../../components/common/LmFilterSection';

const RoleBasedPerformance = () => {
  const { loading, selectedCollectionProfitType, selectedCalenderViewType } =
    useAppSelector(getAnalyticsDashboardState);
  const [selectedCollectionWiseType, setSelectedCollectionWiseType] =
    useState<string>(CollectionWiseType.RCM);
  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    BucketEnumForYearAnalytics.OVERALL
  );
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    TimePeriodEnumForPenal.ONE_DAY
  );
  const [selectedResolution, setSelectedResolution] = useState<string>(
    OverallMetricsEnum2.NONE
  );
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    SegmentPerformanceresponse[]
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);

      setAnalyticsQueryResponse(response);
      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoader(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);

    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date()?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.FIVE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(FIVE_DAY?.toISOString()),
          toDate: DateUtility.formatStringToYYYYMMDD(FIRST_DAY?.toISOString()),
        };
      case TimePeriodEnumForPenal.MTD:
        return {
          isMonthToDate: 'true',
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date(FIRST_DAY)?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4: {
        const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
        };
      }
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER: {
        const monthIndex = getMonthIndex(selectedTimePeriod);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
        };
      }
      default:
        return null;
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    if (selectedCalenderViewType === CelenderViewType.TODAY) {
      if (timePeriodParams !== null) {
        return {
          ...timePeriodParams,
          role: selectedCollectionWiseType,
          ...(selectedResolution !== OverallMetricsEnum2.NONE && {
            loanStatus: selectedResolution,
          }),
          ...(selectedMetrics !== BucketEnumForYearAnalytics.OVERALL && {
            bucket: selectedMetrics,
          }),
        };
      }
    } else {
      return {
        ...(selectedResolution !== OverallMetricsEnum2.NONE && {
          loanStatus: selectedResolution,
        }),
        ...(selectedMetrics !== BucketEnumForYearAnalytics.OVERALL && {
          bucket: selectedMetrics,
        }),
        role: selectedCollectionWiseType,
        isYearToDate: 'true',
      };
    }
  };

  const getQueries = () => {
    const request = {
      analyticsQueryType: AnalyticsQueryTypeEnum.SEGMENT_PERFORMANCE,
      params: { ...getParams(), type: selectedCollectionProfitType },
    } as AnalyticsQueryRequest;

    getQueriesForEnum(request);
  };

  const getTimePeriodOptions = () => {
    const options = Object.keys(TimePeriodEnumForPenal).map(
      item => ({ value: item, label: item } as VegaSelectOption)
    );
    return options;
  };

  const COL_DEF: GridColumns = [
    {
      field: 'rank',
      headerName: 'Rank',
      flex: 1,
      minWidth: 150,
      renderCell: (props: GridCellParams<SegmentDto>) => {
        const response = props.row as SegmentDto;
        const rank = response?.rank ? response.rank : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={rank}
          />
        );
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const region = response?.region ? response.region : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={region}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const managerName = response?.managerName ? response.managerName : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={managerName}
          />
        );
      },
    },
    {
      field: 'collected',
      headerName: 'POS Collected',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriod.MTD ||
        selectedCalenderViewType === CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const pos = response?.pos ? response.pos?.toFixed(2) : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={pos}
          />
        );
      },
    },
    {
      field: 'totalTarget',
      headerName: 'Target',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriod.MTD ||
        selectedCalenderViewType === CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.totalTarget
          ? response.totalTarget?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },

    {
      field: 'mtdResolutionPercentage',
      headerName: 'MTD Resolution %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod !== TimePeriod.MTD ||
        selectedCalenderViewType === CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const mtdResolutionPercentage = response?.mtdResolutionPercentage
          ? response.mtdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={mtdResolutionPercentage}
          />
        );
      },
    },
    {
      field: 'lmsdResolutionPercentage',
      headerName: 'LMSD Resolution %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod !== TimePeriod.MTD ||
        selectedCalenderViewType === CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.lmsdResolutionPercentage
          ? response.lmsdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'ytdResolutionPercentage',
      headerName: 'YTD Resolution %',
      flex: 1,
      minWidth: 150,
      hide: selectedCalenderViewType !== CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const mtdResolutionPercentage = response?.ytdResolutionPercentage
          ? response.ytdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={mtdResolutionPercentage}
          />
        );
      },
    },
    {
      field: 'lysdResolutionPercentage',
      headerName: 'LYSD Resolution %',
      flex: 1,
      minWidth: 150,
      hide: selectedCalenderViewType !== CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.lysdResolutionPercentage
          ? response.lysdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'drr',
      headerName: 'DRR %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriod.MTD ||
        selectedCalenderViewType === CelenderViewType.THIS_YEAR,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response.drr ? response.drr?.toFixed(2) : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'percentage',
      headerName: '% Change',
      flex: 1,
      minWidth: 150,

      renderCell: props => {
        const response = props.row as SegmentDto;
        const percentage = response?.percentageChange
          ? response.percentageChange?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={percentage}
          />
        );
      },
    },
    {
      field: 'focusBuckets',
      headerName: 'Focus Bucket',
      flex: 1,
      minWidth: 300,
      hide: selectedMetrics !== BucketEnumForYearAnalytics.OVERALL,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const focusBuckets = response?.focusBuckets
          ? response.focusBuckets
          : 'NA';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={focusBuckets}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={focusBuckets}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      flex: 1,
      minWidth: 300,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const lastActivity = response?.lastActivity
          ? response.lastActivity
          : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={lastActivity}
            sx={{
              width: '90%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
        );
      },
    },
  ];

  const filterData = () => {
    if (search.length) {
      const searchLowerCase = search.toLowerCase();
      const filteredResults = analyticsQueryResponse.filter(query => {
        if (search === '') {
          return query;
        } else if (query.managerName.toLowerCase().includes(searchLowerCase)) {
          return query;
        }
      });
      return filteredResults;
    } else {
      return analyticsQueryResponse;
    }
  };

  useEffect(() => {
    selectedCollectionWiseType.length && getQueries();
  }, [
    selectedMetrics,
    selectedTimePeriod,
    selectedCollectionWiseType,
    selectedCollectionProfitType,
    selectedResolution,
    selectedCalenderViewType,
  ]);

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper
          title="Role Based Performance"
          rightComponent={
            <Stack direction={'row'} gap={1}>
              <Stack direction={'row'} gap={1}>
                <LmFilterSection
                  hideFilter
                  search={search}
                  setSearch={setSearch}
                />
                <Stack sx={{ minWidth: 150 }}>
                  <VegaSelect
                    ignoreLodash
                    options={getViewOptions(SegmentOptionType)}
                    value={selectedCollectionWiseType}
                    onChange={selected =>
                      setSelectedCollectionWiseType(
                        selected.target.value as string
                      )
                    }
                  />
                </Stack>
              </Stack>
              {selectedCalenderViewType === CelenderViewType.TODAY && (
                <Stack sx={{ minWidth: 150 }}>
                  <VegaSelect
                    options={getTimePeriodOptions()}
                    value={selectedTimePeriod}
                    onChange={select =>
                      setSelectedTimePeriod(select.target.value as string)
                    }
                  />
                </Stack>
              )}
              <Stack sx={{ minWidth: 150 }}>
                <VegaSelect
                  ignoreLodash
                  options={getViewOptions(OverallMetricsEnum2)}
                  value={selectedResolution}
                  onChange={e =>
                    setSelectedResolution(e.target.value as string)
                  }
                />
              </Stack>
              <Stack sx={{ minWidth: 150 }}>
                <VegaSelect
                  options={getOptions(BucketEnumForYearAnalytics)}
                  value={selectedMetrics}
                  onChange={select =>
                    setSelectedMetrics(select.target.value as string)
                  }
                />
              </Stack>
            </Stack>
          }
        >
          {loading || loader ? (
            <LoadingPage height={400} />
          ) : (
            <VegaDataGrid
              // getRowId={index => index}
              data={analyticsQueryResponse ? filterData() : []}
              columns={COL_DEF}
              idColumn="id"
              paginationMode="client"
              pageSize={5}
            />
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default RoleBasedPerformance;
const getViewOptions = (type: AllEnums) => {
  const options = Object.keys(type).map(
    item =>
      ({
        value: type[item],
        label: toLowerCase(item),
      } as VegaSelectOption)
  );
  return options;
};
const getOptions = (type: AllEnums) => {
  const options = Object.values(type).map(
    item =>
      ({
        value: item,
        label: item,
      } as VegaSelectOption)
  );
  return options;
};
export const getMonthIndex = (
  timePeriod: TimePeriodEnumForPenal
): number | null => {
  switch (timePeriod) {
    case TimePeriodEnumForPenal.JANUARY:
      return 1;
    case TimePeriodEnumForPenal.FEBRUARY:
      return 2;
    case TimePeriodEnumForPenal.MARCH:
      return 3;
    case TimePeriodEnumForPenal.APRIL:
      return 4;
    case TimePeriodEnumForPenal.MAY:
      return 5;
    case TimePeriodEnumForPenal.JUNE:
      return 6;
    case TimePeriodEnumForPenal.JULY:
      return 7;
    case TimePeriodEnumForPenal.AUGUST:
      return 8;
    case TimePeriodEnumForPenal.SEPTEMBER:
      return 9;
    case TimePeriodEnumForPenal.OCTOBER:
      return 10;
    case TimePeriodEnumForPenal.NOVEMBER:
      return 11;
    case TimePeriodEnumForPenal.DECEMBER:
      return 12;
    default:
      return null;
  }
};
