/* eslint-disable no-unused-vars */
import { Grid, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MakeBidDialog from '../../components/Auction/MakeBidDialog';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaText from '../../components/common/VegaText';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';

import {
  closeMakeBidDialog,
  fetchAuctionAndBids,
  liveAuctionDetailsState,
} from '../../features/liveAuctionDetailsSlice';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { AuctionStatus, Bid, Possession } from '../../types/auction';

import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import AuctionMakeBidCard from './AuctionMakeBidCard';
import PossessionImagePreview from './PossessionImagePreview';
import useControllableInterval from '../../hooks/useControllableInterval';
import { BidService } from '../../Apis/BidService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useClientAuth } from '../../providers/ClientProvider';
import { useDrawer } from '../../hooks/useDrawer';
import ConfirmBidDialog from '../../components/Auction/ConfirmBidDialog';

function LiveAuctionDetails() {
  const { auctionId } = useParams();
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [bids, setBids] = useState<Bid[]>([]);
  const [latestBid, setLatestBid] = useState<Bid>();
  const { auction, isMakeBidDialogOpen } = useAppSelector(
    liveAuctionDetailsState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { start: startPolling, stop: stopPolling } = useControllableInterval();
  const {
    open: openConfirmDialog,
    close: closeConfirmDialog,
    isOpen: isConfirmDialogOpen,
    props: confirmDialogProps,
  } = useDrawer();

  async function fetchBids() {
    setLoading(true);
    const response = await BidService.getBidList({
      auctionId: auctionId,
      pageSize: 5,
    });

    const bids = response.records ?? [];
    setBids(bids);
    if (bids.length > 0) {
      setLatestBid(bids[0]);
    }
    setLoading(false);
  }

  async function createBid(auctionId: string, bidAmount: number) {
    try {
      setLoading(true);
      await BidService.createBid({
        userId: user?.id,
        auctionId: auctionId,
        bidAmount: bidAmount,
      });
      fetchBids();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getPossession = () => {
    if (!auction?.metaData) return;
    if (Object.hasOwn(auction.metaData, 'possessionItem')) {
      return auction.metaData['possessionItem'] as Possession;
    }
  };

  useEffect(() => {
    if (auctionId) {
      startPolling(fetchBids, 5000);
    }
    return () => {
      stopPolling();
    };
  }, [auctionId]);

  useEffect(() => {
    if (!auctionId) return;
    dispatch(fetchAuctionAndBids(auctionId));
    fetchBids();
  }, [auctionId]);

  useEffect(() => {
    const isCompleted = auction?.status == AuctionStatus.COMPLETED;
    if (isCompleted) {
      stopPolling();
    }
  }, [auction]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Auctions', link: ROUTES.LIVE_AUCTIONS },
                { label: `Live` },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />

      <VegaPageContent>
        <div
          style={{ display: 'flex', flexDirection: 'row', columnGap: '2.5rem' }}
        >
          <div style={{ flex: 1, height: '100%' }}>
            <Stack>
              <Grid container marginTop={'3.25rem'} marginBottom={'2rem'}>
                <Grid item xs={8}>
                  <div>
                    <VegaText
                      text={'Property Name'}
                      style={{
                        color: '#000',
                        fontSize: '1.0625rem',
                        fontWeight: 600,
                        lineHeight: '120%',
                      }}
                    />
                    <VegaText
                      text={getPossession()?.type}
                      style={{
                        marginTop: '1rem',
                        color: '#000',
                        fontSize: '0.825rem',
                        fontWeight: 500,
                        lineHeight: '130%',
                      }}
                    />
                    <VegaText
                      text={getPossession()?.address}
                      style={{
                        marginTop: '0.5rem',
                        color: '#000',
                        fontSize: '0.825rem',
                        fontWeight: 500,
                        lineHeight: '130%',
                      }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <VegaPill
                    text={auction?.status ?? '-'}
                    textColor={
                      auction?.status === AuctionStatus.IN_PROGRESS
                        ? '#318439'
                        : '#E87001'
                    }
                    borderColor={
                      auction?.status === AuctionStatus.IN_PROGRESS
                        ? '#318439'
                        : '#E87001'
                    }
                    backgroundColor={
                      auction?.status === AuctionStatus.IN_PROGRESS
                        ? '#EBF4EC'
                        : '#FFF2E6'
                    }
                  />
                </Grid>
              </Grid>

              <AuctionMakeBidCard auction={auction} latestBid={latestBid} />

              <VegaDataGrid
                data={bids}
                columns={getColumnDefinition({
                  userId: user?.id,
                })}
                idColumn={'id'}
                loading={loading}
              />
            </Stack>
          </div>
          <div
            style={{
              flex: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
              }}
            >
              <PossessionImagePreview />
            </div>
          </div>
        </div>
      </VegaPageContent>

      <MakeBidDialog
        show={isMakeBidDialogOpen}
        latestBid={latestBid}
        onClose={function (): void {
          dispatch(closeMakeBidDialog());
        }}
        onSave={function (auction, formData): void {
          openConfirmDialog({
            auctionId: auction.id,
            bidAmount: formData.bidAmount,
          });
        }}
      />

      <ConfirmBidDialog
        show={isConfirmDialogOpen}
        auctionId={confirmDialogProps?.auctionId}
        bidAmount={confirmDialogProps?.bidAmount}
        onClose={function (): void {
          closeConfirmDialog();
        }}
        onConfirm={function (auctionId: string, bidAmount: number): void {
          createBid(auctionId, bidAmount);
        }}
      />
    </div>
  );
}

export default LiveAuctionDetails;

const getColumnDefinition = (data: { userId: string }) => {
  const COL_DEF: GridColumns = [
    {
      field: 'bidFrom',
      headerName: 'Bid From',
      flex: 0.7,
      renderCell: props => {
        const bid = getBidForRow(props);
        const username = bid.userName;
        const bidUserId = bid.userId;
        var displayText = '-';
        if (bidUserId != data.userId) {
          if (username.length <= 2) {
            displayText = username;
          }
          const firstLetter = username.charAt(0);
          const lastLetter = username.charAt(username.length - 1);
          displayText = `${firstLetter}***${lastLetter}`;
        } else {
          displayText = username;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bidPlaced',
      headerName: 'Bid Placed',
      flex: 0.7,
      renderCell: props => {
        const bid = getBidForRow(props);
        const displayText = StringUtility.formatToINR(bid.bidAmount);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bidPlacedTime',
      headerName: 'Bid Placed Time',
      flex: 0.7,
      renderCell: props => {
        const bid = getBidForRow(props);
        const displayText = DateUtility.formatStringToDDMMYYYYWithTime(
          bid.createdAt
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
};

const getBidForRow = (props: any) => props.row as Bid;
