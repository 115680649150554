import { Box } from '@mui/material';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import ListOfMaxRolePayout from './ListOfMaxRolePayout';

function MaxRolePayout() {
  return (
    <div>
      <VegaPageHeader title="Role Max Payout" />
      <Box p={3}>
        <ListOfMaxRolePayout />
      </Box>
    </div>
  );
}

export default MaxRolePayout;
