import { Stack } from '@mui/material';
import { useState } from 'react';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import LmDialog from '../../../../components/common/LmDialog';
import LmTextField from '../../../../components/common/LmTextField';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaText from '../../../../components/common/VegaText';
import { GREY, RED } from '../../../../constants/style';
import { receiptState } from '../../../../features/receiptManagmentSlice';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useAppSelector } from '../../../../store';
import { ReceiptStatus } from '../../../../types/receiptManagment';
import { UpdateReceiptCancellationStatusRequest } from '../../../../types/request/receiptManagment';
import { IToggleProps } from '../HistoryReceiptManagment';

interface IProps extends IToggleProps {
  onFetchList: () => void;
}

const CancelReceiptDialog = ({ open, onClose, onFetchList }: IProps) => {
  const { user } = useClientAuth();
  const { cancelReceiptData } = useAppSelector(receiptState);
  const [loading, setLoading] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>('');

  const onClear = () => {
    onClose();
    setRemarks('');
  };

  const onReject = async () => {
    setLoading(true);
    try {
      const depositStatusObj = {
        receiptCancelRequestId: cancelReceiptData.id,
        status: ReceiptStatus.REJECTED,
        approverId: user?.id,
        approverRemark: remarks,
      } as UpdateReceiptCancellationStatusRequest;
      await ReceiptManagmentService.updateReceiptCancellationStatus(
        depositStatusObj
      );

      setLoading(false);
      onClear();
      onFetchList();
    } catch (error) {
      // setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={open}
        handleClose={onClose}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <VegaButton
              fullWidth
              text="Cancel Receipt"
              sx={{
                bgcolor: RED.red,
              }}
              onClick={onReject}
              loading={loading}
              disabled={!remarks.length}
            />
            <VegaButton
              text="Close"
              fullWidth
              variant="outlined"
              sx={{
                color: GREY.dark,
              }}
              onClick={onClose}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <VegaText
              text="Cancel Receipt?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaFormInputField label="Please enter reason for rejection">
            <LmTextField
              isTextArea
              rows={2}
              multiline
              value={remarks}
              onChange={e => setRemarks(e.target.value)}
            />
          </VegaFormInputField>
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default CancelReceiptDialog;
