import { AddCircleOutline, VisibilityOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmButton from '../../components/common/LmButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaText from '../../components/common/VegaText';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import CreateClaimForm, {
  CreateClaimFormData,
} from '../../components/ExpenseClaim/CreateClaimForm';
import FilterInput, {
  DateRangeFilter,
  FilterOption,
} from '../../components/ExpenseClaim/FilterInput';
import { PRIMARY } from '../../constants/style';
import { useDrawer } from '../../hooks/useDrawer';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES, SUB_ROUTES } from '../../router/routes';
import { ExpenseClaimAction, ExpenseReportViewModel } from '../../types/claim';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import { useClientAuth } from '../../providers/ClientProvider';
import VegaIconButton from '../../components/common/VegaIconButton';

function DraftExpenseReport() {
  const { userId } = useClientAuth();
  const [openAddExpenseDrawer, setOpenAddExpenseDrawer] =
    useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { createClaim, fetchExpenseReports } = useExpenseApi();
  const [reports, setReports] = useState<ExpenseReportViewModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const AGENT_DRAFT_COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Date Of Report',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const parsedDate = DateUtility.parseStringToDate(report.dateOfExpense);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'reportId',
      headerName: 'Report ID',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const last4Digits = report.id.substring(
          Math.max(0, report.id.length - 4)
        );
        const displayText = `#${last4Digits}`;

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalClaims',
      headerName: 'Total Claims',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalExpense',
      headerName: 'Total Expense',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        var totalExpense = 0;
        report.claims.forEach(item => (totalExpense += item.amount));
        const displayText = totalExpense;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const report = getReportForRow(props);
        return (
          // <LmButton
          //   text="View Details"
          //   variant="text"
          //   textColor={PRIMARY.darkBlue}
          //   fontWeight={500}
          //   sx={{ px: 0.5 }}
          //   fontSize={'0.875rem'}
          //   bgColor="transparant"
          //   onClick={() => {
          //     navigate(ROUTES.CLAIMS.replace(':reportId', report.id), {
          //       state: { isAdmin: false },
          //     });
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              navigate(
                SUB_ROUTES.EXPENSE_REPORT_HISTORY_BY_ID.replace(
                  ':reportId',
                  report.id
                ),
                {
                  state: { isAdmin: false },
                }
              );
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];

  async function onCreateClaimClick(formData: Partial<CreateClaimFormData>) {
    try {
      setLoading(true);
      setOpenAddExpenseDrawer(false);

      const files: File[] = [];

      formData.documents?.forEach(item => {
        if (item.value) {
          files.push(item.value);
        }
      });

      await createClaim({
        userId: userId,
        dateOfExpense: DateUtility.formatStringToYYYYMMDD(
          formData.date?.toISOString()
        ),
        amount: formData.amount,
        expenseType: formData.expenseType,
        documents: files,
        description: formData.description,
        vehicleType: formData.vehicleType,
      });

      fetchReports();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchReports() {
    try {
      setLoading(true);
      const response = await fetchExpenseReports({
        status: ExpenseClaimAction.DRAFT,
      });
      setReports(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onClearAllClick() {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter([]);
    closeFilterDrawer();
  }

  function onSelectFilterClick(
    statusFilter: string[],
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
  }

  useEffect(() => {
    fetchReports();
  }, [dateFilter, dayFilter, statusFilter]);

  useEffect(() => {
    fetchReports();
  }, [page]);

  return (
    <div>
      <VegaPageHeader
        renderRightView={() => (
          <LmButton
            text="Add Expense"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setOpenAddExpenseDrawer(true);
            }}
          />
        )}
      />
      <Box p={3}>
        <Stack
          direction={'row'}
          alignItems="end"
          justifyContent={'space-between'}
        >
          <VegaText
            text="Draft Expense Reports"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        </Stack>
        <Box pt={3}>
          <VegaDataGrid
            data={reports}
            loading={loading}
            columns={AGENT_DRAFT_COLUMN_DEF}
            idColumn="id"
            rowCount={rowCount}
            page={page}
            pageSize={10}
          />
        </Box>
      </Box>
      <VegaDrawer
        open={openAddExpenseDrawer}
        title={'Add Expense'}
        onClose={function (): void {
          setOpenAddExpenseDrawer(false);
        }}
      >
        <CreateClaimForm onCreateClick={onCreateClaimClick} />
      </VegaDrawer>
      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <FilterInput
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter]}
        />
      </VegaDrawer>
    </div>
  );
}

export default DraftExpenseReport;

const getReportForRow = (props: any) => props.row as ExpenseReportViewModel;
