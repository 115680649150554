import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoanService } from '../Apis/LoanServices';
import { RootState } from '../store';
import { LoanListRequest } from '../types/request/loan';
import { LmSidebarListItemType } from '../router';

export interface AuthSliceProps {
  isAuth: boolean;
  loader: boolean;
  isPathnameMatch: boolean;
  loginRoute: string;
  routes: LmSidebarListItemType[];
  firstRoute: Partial<LmSidebarListItemType>;
  routesSet: Set<string>;
}
const getUserId = localStorage.getItem('userId');
const initialState: AuthSliceProps = {
  isAuth: getUserId ? true : false,
  loader: false,
  isPathnameMatch: false,
  routes: [],
  firstRoute: {},
  loginRoute: 'LOGIN',
  routesSet: new Set(),
};

export const getAccountList = createAsyncThunk(
  'accountSlice/loan/list',
  async (data: LoanListRequest) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state: AuthSliceProps, action) => {
      const { payload } = action;
      state.isAuth = payload;
    },
    setRoutes: (state: AuthSliceProps, action) => {
      const { payload } = action;
      state.routes = payload;
      var paths = new Set<string>();
      payload.forEach(element => {
        const path = element.path;
        if (path && path.length > 0) {
          paths.add(path);
        }
        element?.children?.forEach(i => {
          const childPath = i.path;
          if (childPath && childPath.length > 0) {
            paths.add(childPath);
          }
        });
      });
      state.routesSet = paths;
    },
    setLoginRoute: (state: AuthSliceProps, action) => {
      const { payload } = action;
      state.loginRoute = payload;
    },
    setLoader: (state: AuthSliceProps, action) => {
      const { payload } = action;
      state.loader = payload;
    },
    setIsPathnameMatch: (state: AuthSliceProps, action) => {
      const { payload } = action;
      state.isPathnameMatch = payload;
    },
    setFirstRoute: (state: AuthSliceProps, action) => {
      const { payload } = action;
      state.firstRoute = payload;
    },
  },
});
export const {
  setIsAuth,
  setRoutes,
  setLoader,
  setIsPathnameMatch,
  setFirstRoute,
  setLoginRoute,
} = authSlice.actions;
export const getAuth = (state: RootState) => state.auth;
export default authSlice;
