import { Grid, Stack } from '@mui/material';
import React from 'react';
import VegaText from '../../../../components/common/VegaText';
import ListOfBucketWiseIncentive from './ListOfBucketWiseIncentive';
import { useAppSelector } from '../../../../store';
import { getIncentiveScoreCard } from '../../../../features/IncentivesGamificationsSlice/incentiveScoreCardSlice';

const OverallPerformance = () => {
  const { scoreCardDetails } = useAppSelector(getIncentiveScoreCard);
  const overallPerformance = scoreCardDetails.overallPerformanceDetails;
  const ranking = [
    {
      getNumber: overallPerformance?.allIndiaRank ?? 0,
      totalNumber: overallPerformance?.totalAgentCount ?? 0,
      text: 'All India Ranking',
    },
    // {
    //   getNumber: overallPerformance?.regionRank ?? 0,
    //   totalNumber: overallPerformance?.regionAgentCount ?? 0,
    //   text: 'Rank in Region',
    // },
  ];

  const dummyIncentive = [
    {
      count: overallPerformance?.totalEligibleIncentive ?? 0,
      text: 'Total Eligible Incentive',
    },
    {
      count: overallPerformance?.actualIncentiveEarned ?? 0,
      text: 'Actual Incentive Earned',
    },
  ];
  return (
    <Stack p={1}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12} sm={6} md={5}>
          {ranking.map((rank, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Stack
                gap={1}
                sx={{
                  p: 1,
                  borderRadius: '12px',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                  bgcolor: 'white',
                  textAlign: 'center',
                }}
              >
                <VegaText
                  text={`${rank.getNumber} / ${rank.totalNumber}`}
                  sx={{
                    fontWeight: 700,
                    fontSize: 30,
                    color: 'gold',
                  }}
                />
                <VegaText
                  text={`${rank.text} `}
                  sx={{
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                />
              </Stack>
            </Grid>
          ))}
          {dummyIncentive.map((rank, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Stack
                gap={1}
                sx={{
                  p: 1,
                  borderRadius: '12px',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                  bgcolor: 'white',
                  textAlign: 'center',
                }}
              >
                <VegaText
                  text={`Rs ${rank.count} `}
                  sx={{
                    fontWeight: 700,
                    fontSize: 30,
                    color: 'gold',
                  }}
                />
                <VegaText
                  text={`${rank.text} `}
                  sx={{
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <Stack
            gap={1}
            sx={{
              p: 1,
              borderRadius: '12px',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
              bgcolor: 'white',
              textAlign: 'center',
            }}
          >
            <ListOfBucketWiseIncentive />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default OverallPerformance;
