import { Stack } from '@mui/material';
import { MinVisitsSevices } from '../../Apis/MinVisitsServices';
import { fetchLoanAccountList } from '../../actions/TeleAgentAction';
import { GREY } from '../../constants/style';
import { getTeleAgent } from '../../features/teleAgentSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { CreateVisitRequest } from '../../types/request/minVisitsRequest';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { ApproveIcon } from '../Icons/Icons';
import LmDialog from '../common/LmDialog';
import VegaButton from '../common/VegaButton';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaText from '../common/VegaText';
import { IActionProps } from './AccountActionContainer';

const MoveToTodayDialog = ({ open, setOpen }: IActionProps) => {
  const { loanData } = useAppSelector(getTeleAgent);
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const onClose = () => {
    setOpen(false);
  };

  const onMoveToTodayClick = () => {
    const visits = {
      agentId: userId,
      loanId: loanData[0].loanId,
      location: {
        latitude: 123.0,
        longitude: 123.0,
      },
    } as CreateVisitRequest;
    MinVisitsSevices.createMinVisit(visits)
      .then(() => {
        setSnackbar('Move to today Successfully !!!');

        dispatch(
          fetchLoanAccountList({
            loanId: loanData[0].loanId,
            includeCustomerDetails: true,
            includeLmsVariables: true,
            includeLoanOutstandingDetails: false,
            includeLoanTransactions: true,
            includeLoanPdcDetails: true,
          })
        );
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const getCustomerDetails = () => {
    if (loanData.length && loanData[0].customerDetails) {
      const customerData = loanData[0].customerDetails[0];
      return {
        customerName: customerData.customerName,
      };
    }
    return {
      customerName: '--',
    };
  };
  return (
    <>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={open}
        handleClose={onClose}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="Cancel"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                  borderColor: GREY.dark,
                  ':hover': {
                    color: GREY.dark,
                    bgcolor: 'transparent',
                    borderColor: GREY.dark,
                  },
                }}
                onClick={onClose}
              />
              <VegaButton
                fullWidth
                text="Yes"
                startIcon={<ApproveIcon strokeColor="white" />}
                onClick={onMoveToTodayClick}
              />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Are you sure?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={`You want move ${getCustomerDetails().customerName} to Today`}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default MoveToTodayDialog;
