import React from 'react';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import {
  ChequePayment,
  IssueReceiptProps,
} from '../../../types/receiptManagment';
import { BankCustomerRequest } from '../../../types/customerBank';

import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaAutoComplete from '../../../components/common/VegaAutoComplete';

import { fetchCustomerBanksList } from '../../../Apis/customerBank';
import { ICustomerBankListProps } from '../../../types/customerBank';
import VegaDatePicker from '../../../components/common/VegaDatePicker';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { StringUtility } from '../../../utils/StringUtility';
const ChequePaymentDetails = () => {
  const { issueReceipt, errorState } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const chequeDetails = issueReceipt?.paymentMeta as ChequePayment;
  const onReceiptChange = (
    key: keyof IssueReceiptProps,
    value: ChequePayment
  ) => {
    const paymentDetailsObj = {
      ...issueReceipt,
      [key]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(paymentDetailsObj));
  };

  const onChequePaymentChange = (key: keyof ChequePayment, value: string) => {
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const chequePaymentDetailsObj = {
      ...paymentSpread,
      [key]: value,
    } as ChequePayment;
    onReceiptChange('paymentMeta', chequePaymentDetailsObj);
  };
  const onChequeMicrPaymentChange = (data: any) => {
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const { ifsc, ...dataWithoutIfsc } = data;

    const chequePaymentDetailsObj = {
      ...paymentSpread,
      ...dataWithoutIfsc,
      ifscCode: data.ifsc,
    } as ChequePayment;
    onReceiptChange('paymentMeta', chequePaymentDetailsObj);
  };
  const onChequeDateChange = (key: keyof ChequePayment, value: Date) => {
    const dateToIsoString = value.toISOString();
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const chequePaymentDetailsObj = {
      ...paymentSpread,
      [key]: dateToIsoString,
    } as ChequePayment;
    onReceiptChange('paymentMeta', chequePaymentDetailsObj);
  };
  const [bankDepositList, setBankDepositList] = useState<VegaSelectOption[]>(
    []
  );
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [bankResult, setBankResult] = useState([]);
  const [totalItem, setTotalItem] = useState<number>(0);
  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== bankDepositList.length) {
        setPage(page + 1);
      }
    }
  };
  const getCustomerBank = async (request: Partial<BankCustomerRequest>) => {
    try {
      const response = await fetchCustomerBanksList(request);

      // setBankResult(response?.records);
      const newArr = response?.records?.map(
        (customerBank: ICustomerBankListProps) => {
          return {
            value: customerBank.micr,
            label: `${customerBank.micr} - ${customerBank.bankName} - ${customerBank.branchName}`,
          } as VegaSelectOption;
        }
      );
      // setBankDepositList(newArr);
      if (page === 0) {
        setBankDepositList(newArr);
        setBankResult(response?.records);
      } else {
        setBankDepositList([...bankDepositList, ...newArr]);
        setBankResult([...bankResult, ...response?.records]);
      }
      setTotalItem(response?.totalItems);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to get Customer Bank List'),
        'error'
      );
    }
  };

  useEffect(() => {
    getCustomerBank({ micr: search, page, size });
  }, [page, size, search]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Cheque number'} isMandatory>
          <LmTextField
            type="text"
            value={chequeDetails?.chequeNumber}
            onChange={e => {
              const value = e.target.value;
              if (!StringUtility.isNumber(value)) {
                return;
              }
              onChequePaymentChange('chequeNumber', value);
            }}
            error={errorState.error.chequeNumber?.length > 0}
            helperText={errorState.error.chequeNumber}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Cheque date'}>
          <VegaDatePicker
            type="text"
            value={chequeDetails?.chequeDate}
            onChange={e => {
              const value = e;
              onChequeDateChange('chequeDate', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'MICR'} isMandatory>
          <VegaAutoComplete
            searchValue={search}
            handleChange={e => {
              setSearch(e.target.value);
              setPage(0);
            }}
            options={bankDepositList}
            onClear={() => onChequeMicrPaymentChange('')}
            onSelect={e => {
              const data = bankResult.filter(item => item.micr === e);
              onChequeMicrPaymentChange(data[0]);
            }}
            selected={chequeDetails?.micr ?? ''}
            onScroll={loadMoreItems}
            placeHolder="Select MICR"
            ignoreLodash
            specificDisplay
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Bank Name'}>
          <LmTextField
            type="text"
            disabled
            value={chequeDetails?.bankName}
            error={errorState.error.bankName?.length > 0}
            helperText={errorState.error.bankName}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Branch nAME'}>
          <LmTextField
            type="text"
            disabled
            value={chequeDetails?.branchName}
            error={errorState.error.branchName?.length > 0}
            helperText={errorState.error.branchName}
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default ChequePaymentDetails;
