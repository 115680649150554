/* eslint-disable no-unused-vars */
export interface Bidder {
  id: string;
  userId: string;
  auctionId: string;
  userName: string;
  mobile: string;
  email: string;
  startDate: string;
  endDate: string;
  status: BidderStatus;
  createdAt: string;
  updatedAt: string;
}

export interface Bid {
  id: string;
  bidderId: string;
  auctionId: string;
  bidAmount: number;
  userId: string;
  userName: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export enum BidderStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface Auction {
  id: string;
  auctionItemId: string;
  minDenomination: number;
  reservePrice: number;
  minIncrement: number;
  startDateTime: string;
  endDateTime: string;
  status: AuctionStatus;
  bidders: string[];
  createdAt: string;
  updatedAt: string;
  metaData: Object;
  report: string;
}

export interface MetaData {
  auction: Auction;
}

export interface Possession {
  id: string;
  auctionId: string;
  type: string;
  address: string;
  possessionDateTime: string;
  images: string[];
  saleStatus: SaleStatus;
  lan: string;
  dateOfSuccessfulAuction: string;
  auctionStatus: PossessionAuctionStatus;
  numberOfAuctions: string;
  highestBidId: string;
  lowestBidId: string;
  numberOfBidders: number;
  metaData: MetaData;
  frequencyOfBid: number;
  amountOfSale: number;
  inventoryReleaseDate: string;
  authorizedOfficerId: string;
  createdAt: string;
  updatedAt: string;
  borrowerName: string;
  region: string;
}

export enum SaleStatus {
  SOLD = 'SOLD',
  PROVISIONALLY_SOLD = 'PROVISIONALLY_SOLD',
  REVERSED = 'REVERSED',
}

export enum PossessionAuctionStatus {
  NOT_LISTED = 'NOT_LISTED',
  LISTED = 'LISTED',
  ACTIVE = 'ACTIVE',
  SOLD = 'SOLD',
  NOT_SOLD = 'NOT_SOLD',
}

export enum AuctionStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
