import React, { useEffect, useState } from 'react';
import VegaButton from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { Grid, Stack } from '@mui/material';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import { BORDER_COLLECTION } from '../../constants/style';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaText from '../../components/common/VegaText';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LocationTrackingVisitListColumn from './LocationTrackingVisitListColumn';
import { useParams } from 'react-router-dom';
import {
  DownloadMinVisitRequest,
  ListTrackingForAgentsRequestDto,
  TrackingResponseDto,
  UpdateVisitListByAgentIdRequest,
  VisitDetailsDto,
} from '../../types/request/visit';
import { VisitService } from '../../Apis/VisitService';
import { useClientAuth } from '../../providers/ClientProvider';
import { DateUtility } from '../../utils/DateUtlility';
import { StorageUtility, StorageKeys } from '../../utils/StorageUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { AllocationService } from '../../Apis/AllocationService';
import { LongRunningTaskStatus } from '../../types/allocations';

export interface LocationTrackingVisit {
  srNo: number;
  lan: string;
  customerName: string;
  trailUpdated: string;
}

const getAgentLevelVisitDownloadRequestId = StorageUtility.getItem<string>(
  StorageKeys.DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID
)
  ? StorageUtility.getItem<string>(
      StorageKeys.DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID
    )
  : '';

function ViewTrajectoryDetails() {
  const { setSnackbar } = useSnackbar();
  const { agentName, selectedDate, agentId } = useParams();

  const { user } = useClientAuth();
  const [scheduledDate, setScheduledDate] = useState(selectedDate);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [visitTrackingList, setVisitTrackingList] = useState<
    TrackingResponseDto[]
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [visitList, setVisitList] = useState<VisitDetailsDto[]>();
  const [agentVisitRequestId, setAgentVisitRequestId] = useState<string>(
    getAgentLevelVisitDownloadRequestId
  );
  const [
    isDownloadAgentLevelVisitInProgress,
    setIsDownloadAgentLevelVisitInProgress,
  ] = useState<boolean>(false);

  async function getAgentLocationTackingList() {
    try {
      const requestBody: Partial<ListTrackingForAgentsRequestDto> = {
        userId: user.id,
        visitedOn: scheduledDate,
        agentId: agentId ?? '',
      };

      const visitTrackingListData =
        await VisitService.getVisitLocationTrackingList(requestBody);
      setVisitTrackingList(visitTrackingListData);
    } catch (error) {}
  }

  async function getVisitListByAgentIdApi() {
    try {
      const requestBody: Partial<UpdateVisitListByAgentIdRequest> = {
        agentId: [agentId] ?? [],
        visitDateOn: scheduledDate ?? '',
      };

      const visitListData = await VisitService.updateVisitListByAgentId(
        requestBody
      );

      const visitListWithSrNo = visitListData.records.map((item, index) => ({
        ...item,
        srNo: index + 1 + page * size,
      }));

      setVisitList(visitListWithSrNo);
      setTotalItems(visitListData.totalItems);
    } catch (error) {}
  }

  const onDownloadClick = async () => {
    setIsDownloadAgentLevelVisitInProgress(true);
    try {
      const request: Partial<UpdateVisitListByAgentIdRequest> = {
        agentId: [agentId],
        visitDateOn: scheduledDate,
      };

      const response = await VisitService.downloadVisitList(request);
      if (response.status === 'COMPLETED') {
        setIsDownloadAgentLevelVisitInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID
        );
        setAgentVisitRequestId('');
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
      } else {
        StorageUtility.setItem(
          StorageKeys.DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID,
          response.id
        );
        setAgentVisitRequestId(response.id);
        setIsDownloadAgentLevelVisitInProgress(true);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getDownloadFileStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsDownloadAgentLevelVisitInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID
        );
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
        setAgentVisitRequestId('');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsDownloadAgentLevelVisitInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID
        );
        setSnackbar(response.result, 'error');
        setAgentVisitRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const handleChange = (value: string) => {
    const futureDateString = DateUtility.parseDateFromDatePicker(value);
    setScheduledDate(futureDateString.toISOString());
  };

  useEffect(() => {
    if (agentId && agentName) {
      getAgentLocationTackingList();
      getVisitListByAgentIdApi();
    }
  }, [agentId && agentName, scheduledDate]);

  useEffect(() => {
    if (page !== 0) {
      getVisitListByAgentIdApi();
    }
  }, [page, size]);

  useEffect(() => {
    if (agentVisitRequestId.length) {
      const downloadingFile = setInterval(() => {
        getDownloadFileStatus(agentVisitRequestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [agentVisitRequestId]);

  return (
    <>
      <VegaPageHeader
        title="Location Tracking"
        sx={{ borderBottom: 'none' }}
        renderRightView={() => (
          <VegaButton
            text={'Download'}
            onClick={onDownloadClick}
            loading={isDownloadAgentLevelVisitInProgress}
          />
        )}
      />
      <VegaPageHeader
        sx={{
          marginBottom: '1rem',
        }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{
                justifyContent: 'flex-end',
              }}
              direction={'row'}
            >
              <Grid
                container
                direction={'row'}
                gap={1}
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'Select Date'}>
                    <VegaDatePicker
                      value={scheduledDate}
                      onChange={date => {
                        handleChange(date);
                      }}
                      maxDate={new Date()}
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      />
      <VegaPageContent>
        <Stack direction="column" spacing={'20px'} paddingBottom={'20px'}>
          <Grid
            container
            sx={{
              border: BORDER_COLLECTION,
              borderRadius: '8px',
              p: 3,
              justifyContent: 'flex-start',
              flex: 1,
              backgroundColor: '#FFFFFF',
            }}
          >
            <Grid item>
              <VegaText text={'Agent Details'} style={{ fontWeight: 600 }} />
            </Grid>
            <Grid container direction={'row'} spacing={'20px'}>
              <Grid item>
                <Grid
                  container
                  direction={'column'}
                  marginTop={'20px'}
                  rowSpacing="20px"
                >
                  <Grid item>
                    <VegaText
                      text={'Name'}
                      style={{ fontWeight: 450, color: '#676B76' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={'Employee Code'}
                      style={{ fontWeight: 450, color: '#676B76' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={'Minimum Visits'}
                      style={{ fontWeight: 450, color: '#676B76' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={'Distance Travelled'}
                      style={{ fontWeight: 450, color: '#676B76' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={'First Activity At'}
                      style={{ fontWeight: 450, color: '#676B76' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={'Last Activity At'}
                      style={{ fontWeight: 450, color: '#676B76' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction={'column'}
                  marginTop={'20px'}
                  rowSpacing="20px"
                >
                  <Grid item>
                    <VegaText
                      text={visitTrackingList?.[0]?.agentName ?? ''}
                      style={{ fontWeight: 450, color: '#1B1D22' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={visitTrackingList?.[0]?.employeeCode ?? ''}
                      style={{ fontWeight: 450, color: '#1B1D22' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={visitTrackingList?.[0]?.minVisits ?? ''}
                      style={{ fontWeight: 450, color: '#1B1D22' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={visitTrackingList?.[0]?.distance ?? ''}
                      style={{ fontWeight: 450, color: '#1B1D22' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={DateUtility.formatStringToDDMMMMYYYYWithTime(
                        visitTrackingList?.[0]?.firstVisitTime ?? ''
                      )}
                      style={{ fontWeight: 450, color: '#1B1D22' }}
                    />
                  </Grid>
                  <Grid item>
                    <VegaText
                      text={DateUtility.formatStringToDDMMMMYYYYWithTime(
                        visitTrackingList?.[0]?.lastVisitTime ?? ''
                      )}
                      style={{ fontWeight: 450, color: '#1B1D22' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              border: BORDER_COLLECTION,
              borderRadius: '8px',
              p: 3,
              justifyContent: 'flex-start',
              flex: 1,
              backgroundColor: '#FFFFFF',
            }}
            direction="column"
          >
            <Grid item marginBottom={'20px'}>
              <VegaText text={'Visits List'} style={{ fontWeight: 600 }} />
            </Grid>
            <Grid item pl={'16px'} pr={'16px'}>
              <VegaDataGrid
                page={page}
                pageSize={size}
                rowCount={totalItems}
                onPageChange={page => {
                  setPage(page);
                }}
                onPageSizeChange={size => setSize(size)}
                paginationMode="server"
                data={visitList ?? []}
                columns={LocationTrackingVisitListColumn()}
                idColumn="srNo"
              />
            </Grid>
          </Grid>
        </Stack>
      </VegaPageContent>
    </>
  );
}

export default ViewTrajectoryDetails;
