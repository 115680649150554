/* eslint-disable no-unused-vars */
import {
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  Stack,
} from '@mui/material';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import {
  ConveyanceVehicleType,
  ExpenseType,
  ExpenseVisitData,
} from '../../types/claim';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../utils/api';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import LmTextField from '../common/LmTextField';
import VegaCheckbox from '../common/VegaCheckbox';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../common/VegaFileSelect';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import VegaText from '../common/VegaText';
import { useClientAuth } from '../../providers/ClientProvider';
import { VisitService } from '../../Apis/VisitService';
import { FetchVisitsListResponse } from '../../types/visits';
import { useSnackbar } from '../../providers/SnackbarProvider';

type Props = {
  onCreateClick: (formData: Partial<CreateClaimFormData>) => void;
  isHideDateField?: boolean;
};

enum FormField {
  Date = 'date',
  ExpenseType = 'expenseType',
  Amount = 'amount',
  Description = 'description',
  Documents = 'documents',
  VehicleType = 'vehicleType',
}

export type CreateClaimFormData = {
  date: Date;
  expenseType: string;
  amount: number;
  description: string;
  documents: VegaFileSelectOption[];
  vehicleType: string;
};

const yesterDayDate = DateUtility.subtractDays(new Date(), 1);
function CreateClaimForm({ onCreateClick, isHideDateField }: Props) {
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const { fetchClaimVisitData } = useExpenseApi();
  const [formData, setFormData] = useState<Partial<CreateClaimFormData>>({
    date: yesterDayDate,
    documents: [],
  });
  const [visitDataError, setVisitDataError] = useState<string>();
  const [showDescriptionInput, setShowDescriptionInput] =
    useState<boolean>(false);
  const [showVehicleInput, setShowVehicleInput] = useState<boolean>(false);
  const [showAmountInput, setShowAmountInput] = useState<boolean>(false);
  const [showSystemGeneratedAmount, setShowSystemGeneratedAmount] =
    useState<boolean>(false);
  const [showDeviationAmountInput, setShowDeviationAmountInput] =
    useState<boolean>(false);
  const [loadingVisitData, setLoadingVisitData] = useState<boolean>(false);
  const [visitData, setVisitData] = useState<Partial<ExpenseVisitData>>({});
  const [visits, setVisits] = useState<
    PaginatedResponse<FetchVisitsListResponse>
  >({
    records: [],
    pageNumber: 0,
    numberOfItems: 0,
    totalPages: 0,
    totalItems: 0,
  });
  const [loadingVisits, setLoadingVisits] = useState<boolean>(false);

  const canShowVisitDataError = () => {
    if (formData.expenseType !== ExpenseType.CONVEYANCE) return false;
    if (
      formData.vehicleType === ConveyanceVehicleType.SELF_FOUR_WHEELER ||
      formData.vehicleType === ConveyanceVehicleType.SELF_TWO_WHEELER
    ) {
      if (visitDataError != null && visitDataError.length > 0) return true;
    }
    return false;
  };

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function onDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    updateFormData(FormField.Date, parsedDate);
  }

  function onExpenseTypeChange(value: string) {
    updateFormData(FormField.ExpenseType, value);
    setShowVehicleInput(value == ExpenseType.CONVEYANCE);
    setShowAmountInput(
      value == ExpenseType.OTHERS || value == ExpenseType.MEALS
    );
    setShowDescriptionInput(value == ExpenseType.OTHERS);
    const isConveyanceExpense = value === ExpenseType.CONVEYANCE;
    const isTwoWheelerOrFourWheeler =
      value === ConveyanceVehicleType.SELF_FOUR_WHEELER ||
      value === ConveyanceVehicleType.SELF_TWO_WHEELER;
    setShowSystemGeneratedAmount(
      isConveyanceExpense && isTwoWheelerOrFourWheeler
    );
    setShowDeviationAmountInput(
      isConveyanceExpense && isTwoWheelerOrFourWheeler
    );
    onVehicleTypeChange(undefined);
  }

  function onVehicleTypeChange(value: string) {
    updateFormData(FormField.VehicleType, value);
    if (
      value === ConveyanceVehicleType.SELF_FOUR_WHEELER ||
      value === ConveyanceVehicleType.SELF_TWO_WHEELER
    ) {
      setShowSystemGeneratedAmount(true);
      setShowAmountInput(false);
    } else {
      setShowSystemGeneratedAmount(false);
      setShowAmountInput(true);
    }
  }

  function onAmountChange(value: string) {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    updateFormData(FormField.Amount, isValidNumber ? parsedNumber : undefined);
  }

  function onDescriptionChange(value: string) {
    updateFormData(FormField.Description, value);
  }

  function onFileChange(index: number, file: VegaFileSelectOption) {
    const existingDocuments = formData.documents ?? [];
    if (index >= 0 && index < existingDocuments.length) {
      existingDocuments[index] = file;
    } else {
      existingDocuments.push(file);
    }
    updateFormData(FormField.Documents, existingDocuments);
  }

  function onFileAdd(file: VegaFileSelectOption) {
    const size = (formData.documents ?? []).length;
    onFileChange(size, file);
  }

  function onFileDelete(index: number) {
    const existingDocuments = formData.documents ?? [];
    if (existingDocuments.length <= 0) return;
    if (index >= 0 && index < existingDocuments.length) {
      existingDocuments.splice(index, 1);
    }
    updateFormData(FormField.Documents, existingDocuments);
  }

  const isInputValid = () => {
    const isDateValid = formData.date != null;
    const isTypeOfExpenseValid = formData.expenseType != null;
    let isVehicleValid = true;
    let isSystemGeneratedAmountValid = true;
    let isKmsTravelledValid = true;
    let isAmountValid = true;
    let isDescriptionValid = true;
    const isDocumentValid =
      formData.documents != null && formData.documents.length > 0;

    if (
      formData.expenseType == ExpenseType.CONVEYANCE &&
      formData.vehicleType != ConveyanceVehicleType.RENTED
    ) {
      isVehicleValid = formData.vehicleType != null;
      isSystemGeneratedAmountValid = visitData.projectedPrice != null;
      isKmsTravelledValid = visitData.distance != null;
      if (showDeviationAmountInput == true) {
        isAmountValid = formData.amount != null && formData.amount > 0;
        isDescriptionValid =
          formData.description != null && formData.description.length > 0;
      }
    } else if (formData.expenseType == ExpenseType.OTHERS) {
      isDescriptionValid = formData.description != null;
    } else {
      isAmountValid = formData.amount != null && formData.amount > 0;
    }

    return (
      isDateValid &&
      isTypeOfExpenseValid &&
      isAmountValid &&
      isKmsTravelledValid &&
      isVehicleValid &&
      isSystemGeneratedAmountValid &&
      isDescriptionValid &&
      isDocumentValid
    );
  };

  async function getVisitData() {
    try {
      setVisitData({});
      const vehicleType = formData.vehicleType;
      if (!vehicleType) return;
      setVisitDataError(undefined);
      setLoadingVisitData(true);
      const response = await fetchClaimVisitData({
        claimantId: user?.id,
        dateOfExpense: formData.date?.toISOString(),
        vehicleType: formData.vehicleType,
      });
      setVisitData(response);
    } catch (e) {
      setVisitDataError(getErrorMessageFromErrorObj(e));
    } finally {
      setLoadingVisitData(false);
    }
  }
  async function getVisits() {
    try {
      setLoadingVisits(true);
      setVisits({
        records: [],
        pageNumber: 0,
        numberOfItems: 0,
        totalPages: 0,
        totalItems: 0,
      });
      const response = await VisitService.getVisitList({
        visitDateOn: DateUtility.formatStringToYYYYMMDD(
          formData?.date?.toISOString()
        ),
        includeCustomerDetails: true,
        agentId: user?.id,
        status: 'VISITED',
        size: 100,
      });
      setVisits(response);
    } catch (e) {
      setSnackbar(getErrorMessageFromErrorObj(e), 'error');
    } finally {
      setLoadingVisits(false);
    }
  }

  useEffect(() => {
    if (
      formData.expenseType == ExpenseType.CONVEYANCE &&
      formData.vehicleType !== ConveyanceVehicleType.RENTED
    ) {
      getVisitData();
    }
  }, [formData.date, formData.vehicleType, formData.expenseType]);

  useEffect(() => {
    getVisits();
  }, [formData.date]);

  async function onSaveClick() {
    onCreateClick(formData);
  }

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <LmButton
            text="Save Details"
            disabled={isInputValid() == false}
            onClick={onSaveClick}
          />
        );
      }}
    >
      <Grid container>
        {!isHideDateField && (
          <Grid item xs={12}>
            <LmDateField
              header="Date"
              onChange={onDateChange}
              maxDate={yesterDayDate}
              value={formData.date ?? yesterDayDate}
            />
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Type Of Expense">
            <VegaSelect
              options={expenseOptions()}
              value={formData.expenseType ?? ''}
              onChange={e => {
                onExpenseTypeChange(e.target.value as string);
              }}
            />
          </VegaFormInputField>
        </Grid>

        <AnimatedInputWrapper show={showDescriptionInput} marginTop={'1.25rem'}>
          <LmTextField
            placeholder="Enter Description"
            header="Description"
            value={formData.description ?? ''}
            onChange={e => onDescriptionChange(e.target.value)}
          />
        </AnimatedInputWrapper>

        <AnimatedInputWrapper show={showVehicleInput} marginTop={'1.25rem'}>
          <VegaFormInputField label="Type Of Vehicle">
            <VegaSelect
              options={vehicleOptions()}
              value={formData.vehicleType ?? ''}
              onChange={e => {
                onVehicleTypeChange(e.target.value as string);
              }}
            />
          </VegaFormInputField>
        </AnimatedInputWrapper>
        <AnimatedInputWrapper show={showAmountInput} marginTop={'1.25rem'}>
          <LmTextField
            placeholder="Amount"
            header="Amount"
            value={formData.amount ?? ''}
            onChange={e => onAmountChange(e.target.value)}
          />
        </AnimatedInputWrapper>

        <AnimatedInputWrapper
          show={showSystemGeneratedAmount}
          marginTop={'1.25rem'}
        >
          <LmTextField
            header="Kms Travelled"
            value={`${visitData.distance ?? '-'} KMS`}
            disabled
            loading={loadingVisitData}
          />
        </AnimatedInputWrapper>

        <Grid
          item
          xs={12}
          style={{ marginTop: showSystemGeneratedAmount ? '1.25rem' : '0rem' }}
        >
          <Collapse in={showSystemGeneratedAmount} timeout={300}>
            <Stack>
              <LmTextField
                placeholder="System Generated Amount"
                header="System Generated Amount"
                value={StringUtility.formatToINR(visitData.projectedPrice)}
                disabled
                loading={loadingVisitData}
              />
              <FormControlLabel
                control={
                  <VegaCheckbox
                    checked={showDeviationAmountInput}
                    onChange={(e, checked) => {
                      setShowDeviationAmountInput(checked);
                    }}
                  />
                }
                label={<VegaText text={'Enter your value'} />}
              />
              <Collapse in={showDeviationAmountInput} timeout={300}>
                <LmTextField
                  placeholder="Amount"
                  onChange={e => onAmountChange(e.target.value)}
                  value={formData.amount ?? ''}
                />
                <div style={{ height: '1.25rem' }}></div>
                <LmTextField
                  placeholder="Enter Reason"
                  header="Reason"
                  value={formData.description ?? ''}
                  onChange={e => onDescriptionChange(e.target.value)}
                />
              </Collapse>
            </Stack>
          </Collapse>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <Stack rowGap={'0.75rem'}>
            {formData.documents?.map((item, index) => {
              return (
                <VegaFileSelect
                  key={index}
                  value={item}
                  acceptedExtension={[
                    AcceptedExtension.JPG,
                    AcceptedExtension.JPEG,
                    AcceptedExtension.PNG,
                    AcceptedExtension.PDF,
                  ]}
                  placeholder={'Upload Document ' + index}
                  onFileSelect={file => {
                    onFileChange(index, file);
                  }}
                  onDeleteClick={() => {
                    onFileDelete(index);
                  }}
                />
              );
            })}
            <VegaFileSelect
              placeholder={'Upload Document '}
              onFileSelect={file => {
                onFileAdd(file);
              }}
            />
          </Stack>
        </Grid>

        <AnimatedInputWrapper
          show={canShowVisitDataError()}
          marginTop={'1.25rem'}
        >
          <div
            style={{
              backgroundColor: COLOR.RED.LIGHT,
              paddingLeft: '1rem',
              paddingRight: '1rem',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            <VegaText text={visitDataError} color={COLOR.RED.DARK} />
          </div>
        </AnimatedInputWrapper>
        <AnimatedInputWrapper
          show={formData.expenseType == ExpenseType.CONVEYANCE}
          marginTop={'1.25rem'}
        >
          <div>
            <VegaText
              text={'Visits'}
              style={{ marginBottom: '1rem' }}
            ></VegaText>
            {loadingVisits && <CircularProgress />}
            {visits?.records?.map(i => {
              return (
                <div
                  key={i.loanId}
                  style={{
                    backgroundColor: COLOR.GREEN.LIGHT,
                    padding: '0.5rem',
                    marginTop: '0.5rem',
                  }}
                >
                  <VegaText
                    text={`${
                      i?.loans?.customerDetails?.at(0).customerName ?? 'NA'
                    }   (${i.loanId})`}
                  ></VegaText>
                </div>
              );
            })}
          </div>
        </AnimatedInputWrapper>
      </Grid>
    </VegaDrawerContent>
  );
}

export default CreateClaimForm;

type AnimatedInputWrapperProps = {
  show: boolean;
  marginTop?: string;
  children: ReactNode;
};

export const AnimatedInputWrapper = ({
  show,
  marginTop,
  children,
}: AnimatedInputWrapperProps) => {
  return (
    <Grid item xs={12} style={{ marginTop: show ? marginTop : '0rem' }}>
      <Collapse in={show} timeout={300}>
        {children}
      </Collapse>
    </Grid>
  );
};

const expenseOptions = () =>
  Object.values(ExpenseType).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const vehicleOptions = () =>
  Object.values(ConveyanceVehicleType).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
