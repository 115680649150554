import { createSlice } from '@reduxjs/toolkit';
import {
  fetchFeedbackList,
  fetchTeleAgentAudit,
} from '../actions/TeleAgentAction';
import { RootState } from '../store';
import { TeleAgentAudit, TeleAgentAuditModal } from '../types/trail';

interface TeleAgentAuditState {
  page: number;
  totalItems: number;
  pageSize: number;
  loading: boolean;
  audit: TeleAgentAuditModal[];
  feedbacks: any[];
  callDispose: Partial<TeleAgentAuditModal>;
  error: string;
}

const initialState: TeleAgentAuditState = {
  loading: false,
  page: 0,
  totalItems: 0,
  pageSize: 0,
  audit: [],
  feedbacks: [],
  error: '',
  callDispose: {},
};

const teleAgentAuditSlice = createSlice({
  name: 'loanaction',
  initialState,
  reducers: {
    setCallDispose: (state: TeleAgentAuditState, action) => {
      const { payload } = action;
      state.callDispose = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTeleAgentAudit.pending, state => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchTeleAgentAudit.fulfilled, (state, action) => {
        const callAudits = [...action.payload.records];
        const viewCallAuditModel = callAudits.map(
          (audit: TeleAgentAudit) =>
            ({
              id: audit.id,
              agentId: audit.agentId,
              lan: audit.loanid,
              callId: audit.referenceId,
              callerId: audit.campaignId,
              customerNumber: audit.Phone,
              pickupTime: audit.callStartDateTime,
              duration: audit.agentTalktimeSec,
              hangUpTime: audit.callEndDateTime,
              hangUpBy: audit.disconnectedBy,
              dispositionCode: audit?.firstDispose,
              recording: audit?.mediaUrl,
            } as TeleAgentAuditModal)
        );
        state.loading = false;
        state.audit = viewCallAuditModel;
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchTeleAgentAudit.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      })
      .addCase(fetchFeedbackList.pending, state => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchFeedbackList.fulfilled, (state, action) => {
        const feedbackData = [...action.payload.records];

        state.loading = false;
        state.feedbacks = feedbackData;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchFeedbackList.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      });
  },
});
export const { setCallDispose } = teleAgentAuditSlice.actions;
export default teleAgentAuditSlice;

export const teleAgentAuditSelector = (state: RootState) =>
  state.teleAgentAudit;
