import { Stack } from '@mui/material';
import { useState } from 'react';
import { RoleServices } from '../../../Apis/RoleServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { RoleAction } from '../../../features/roleAccessSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch } from '../../../store';
import {
  AddAccessScreen,
  GetRoleAccessList,
  RoleResourceDto,
} from '../../../types/roleType';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import RemoveScreenForm from '../components/RemoveScreenForm';
import { VegaSelectOption } from '../../../components/common/VegaSelect';

interface IProps {
  open: boolean;
  onClose: () => void;
  roleDetails: RoleResourceDto;
}

const RemoveScreenDrawer = ({ open, onClose, roleDetails }: IProps) => {
  const { setSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const [removedResources, setRemovedResources] = useState<VegaSelectOption[]>(
    []
  );

  const onClickClose = () => {
    onClose();

    setRemovedResources([]);
  };
  const onSubmit = () => {
    const request = {
      resourceIds: removedResources.map(role => role.value),
      role: roleDetails.id,
    } as AddAccessScreen;
    RoleServices.removeAccessScreen(request)
      .then(() => {
        const request = { clientId: 'capri', type: 'UI' } as GetRoleAccessList;
        dispatch(RoleAction.fetchRoles(request));
        onClickClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  return (
    <div>
      <VegaDrawer open={open} title={'Remove Resource'} onClose={onClickClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Remove Screen"
                  onClick={onSubmit}
                  disabled={!removedResources.length}
                />
              </Stack>
            );
          }}
        >
          <RemoveScreenForm
            removedResources={removedResources}
            setRemovedResources={setRemovedResources}
            roleDetails={roleDetails}
          />
        </VegaDrawerContent>
      </VegaDrawer>
    </div>
  );
};

export default RemoveScreenDrawer;
