/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import BidderDetailsDialog from '../../components/Bidder/BidderDetailsDialog';
import CreateBidderDrawer, {
  CreateBidderFormData,
} from '../../components/Bidder/CreateBidderDrawer';
import LmButton from '../../components/common/LmButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { fetchBidders, getBidders } from '../../features/biddersSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../store';
import { Bidder } from '../../types/auction';
import { DateUtility } from '../../utils/DateUtlility';
import VegaDrawer from '../../components/common/VegaDrawer';
import AuctionUserFilterForm from '../../components/Auction/AuctionUserFilterForm';
import { BidderService } from '../../Apis/BidderService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';

function AuctionUsers() {
  const dispatch = useAppDispatch();
  const { bidders, page: currentPage, totalItems } = useAppSelector(getBidders);
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const {
    open: openUserDetailsDialog,
    close: closeUserDetailsDialog,
    isOpen: isUserDetailsDialogOpen,
    props: userDetailsDialogProps,
  } = useDrawer();

  const {
    isOpen: isCreateDrawerOpen,
    open: openCreateDrawer,
    close: closeCreateDrawer,
  } = useDrawer();

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  async function onCreateBidderClick(formData: Partial<CreateBidderFormData>) {
    try {
      setLoading(true);
      await BidderService.createBidder({
        userName: formData.userName,
        email: formData.email,
        startDate: formData.startDate,
        endDate: formData.endDate,
        mobile: formData.mobile,
      });
      dispatch(fetchBidders({ page: page, pageSize: 10 }));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onPageChange(pageNumber: number) {
    if (page == pageNumber) return;
    setPage(pageNumber);
  }

  function onTableRowClick(params: any) {
    const user = getUserForRow(params);
    openUserDetailsDialog({ user: user });
  }

  useEffect(() => {
    dispatch(fetchBidders({ page: page, pageSize: 10, userName: search }));
  }, [page, search]);

  return (
    <div>
      <VegaPageHeader
        renderRightView={() => {
          return (
            <LmButton
              text="Create"
              onClick={() => {
                openCreateDrawer();
              }}
            />
          );
        }}
      />
      <VegaPageHeader
        title="Auction Users"
        renderRightView={() => (
          <LmFilterSection
            onFilter={() => {
              openFilterDrawer();
            }}
            setSearch={setSearch}
          />
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={bidders}
          loading={loading}
          columns={getColumnDefinition()}
          idColumn="id"
          page={currentPage}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          onRowClick={onTableRowClick}
        />
      </VegaPageContent>

      <CreateBidderDrawer
        open={isCreateDrawerOpen}
        onClose={function (): void {
          closeCreateDrawer();
        }}
        onSubmit={onCreateBidderClick}
      />

      <BidderDetailsDialog
        show={isUserDetailsDialogOpen}
        bidder={userDetailsDialogProps.user}
        onClose={function (): void {
          closeUserDetailsDialog();
        }}
      />

      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <AuctionUserFilterForm
          onClose={closeFilterDrawer}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
      </VegaDrawer>
    </div>
  );
}

export default AuctionUsers;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 0.7,
      renderCell: props => {
        const bidder = getUserForRow(props);
        const displayText = bidder.userName;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.7,
      renderCell: props => {
        const bidder = getUserForRow(props);
        const displayText = bidder.email;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      flex: 0.7,
      renderCell: props => {
        const bidder = getUserForRow(props);
        const displayText = bidder.mobile;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'expiry',
      headerName: 'Expiry',
      flex: 0.7,
      renderCell: props => {
        const bidder = getUserForRow(props);
        const displayText =
          DateUtility.formatStringToDDMMYYYYWithTime(bidder.endDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const bidder = getUserForRow(props);
        const displayText = bidder.status;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
};

const getUserForRow = (props: any) => props.row as Bidder;
