import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  PanaltyCondition,
  PanaltyPolicyRangeProps,
} from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  penaltyIncentive: PanaltyPolicyRangeProps;
  index: number;
}

const ViewPenaltyIncentive = ({ loading, penaltyIncentive, index }: IProps) => {
  const getTextLoadingWrapper = (value: string, loading: boolean) => {
    if (loading) {
      return <Skeleton />;
    } else {
      return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
    }
  };
  return (
    <Stack
      gap={1}
      key={index}
      sx={{
        borderRadius: '12px',
        p: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        mb: 1,
      }}
    >
      <VegaText
        text={`Perameter ${index + 1}`}
        fontWeight={500}
        fontSize={'0.75rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'amount type'}>
            {getTextLoadingWrapper(
              toLowerCase(penaltyIncentive.amountType),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'amount value'}>
            {getTextLoadingWrapper(`${penaltyIncentive.amountValue}`, loading)}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {penaltyIncentive?.condition.length
        ? penaltyIncentive.condition.map(
            (condition: PanaltyCondition, index: number) => (
              <Stack
                key={index}
                sx={{
                  borderRadius: '12px',
                  p: 2,
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                  mb: 1,
                }}
              >
                <Grid container spacing={2} key={index}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'condition type'}>
                      {getTextLoadingWrapper(
                        `${toLowerCase(condition.conditionType)}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'condition value'}>
                      {getTextLoadingWrapper(`${condition.value}`, loading)}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'op'}>
                      {getTextLoadingWrapper(`${condition.op}`, loading)}
                    </VegaFormInputField>
                  </Grid>
                </Grid>
              </Stack>
            )
          )
        : ''}
    </Stack>
  );
};

export default ViewPenaltyIncentive;
