import { Slider, SliderProps, styled } from '@mui/material';
import React from 'react';

interface IProps extends SliderProps {
  toolTipHeight?: number;
  toolTipWidth?: number;
  includePercentSign?: boolean;
}

const VegaRangeSlider = ({
  toolTipHeight = 32,
  toolTipWidth = 32,
  includePercentSign = false,
  ...rest
}: IProps) => {
  const formatValueLabel = (value: number) => {
    return includePercentSign ? `${value}%` : `${value}`;
  };
  const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 20,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 30,
      width: 30,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: toolTipWidth,
      height: toolTipHeight,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
      whiteSpace: 'pre-line',
    },
  });

  return (
    <PrettoSlider
      valueLabelDisplay="auto"
      aria-label="pretto slider"
      defaultValue={20}
      valueLabelFormat={formatValueLabel}
      {...rest}
    />
  );
};

export default VegaRangeSlider;
