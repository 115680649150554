import axios from 'axios';

import {
  BnakDepositRequest,
  BulkDepositBankRequest,
  ICreateDepositBankProps,
  PastUploadListProps,
} from '../types/depositBank';
import { PaginatedResponse } from '../utils/api';

const DEPOSIT_BANK = {
  BANK: '/master/bank',
  BANK_BY_ID: '/master/bank/{depositBankId}',
  BANK_LIST: '/master/bank/list',
  STATUS: '/status',
  BULK: '/bulk',
  LIST: '/list',
  ERROR_BY_ID: '/errorFile/:requestId',
};

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
/////////// Deposit Bank APIs \\\\\\\\\\

export const fetchDepositBanksList = async (
  request: Partial<BnakDepositRequest>
) => {
  const response = await axios.get(DEPOSIT_BANK.BANK_LIST, { params: request });
  return response.data;
};
export const fetchpastUploadList = async (
  request: Partial<BulkDepositBankRequest>
): Promise<PaginatedResponse<PastUploadListProps>> => {
  const endpoint = DEPOSIT_BANK.BANK + DEPOSIT_BANK.BULK + DEPOSIT_BANK.LIST;
  const response = await axios.get(endpoint, { params: request });
  return response.data;
};

export const fetchDepositBankById = async (depositBankId: string) => {
  const response = await axios.get(
    DEPOSIT_BANK.BANK_BY_ID.replace('{depositBankId}', depositBankId)
  );
  return response.data;
};

export const createDepositBank = async (
  depositeBankState: ICreateDepositBankProps
) => {
  const response = await axios.post(DEPOSIT_BANK.BANK, depositeBankState);
  return response.data;
};

export const updateDepositBank = async (
  depositeBankState: ICreateDepositBankProps
) => {
  const response = await axios.put(DEPOSIT_BANK.BANK, depositeBankState);
  return response.data;
};
export const updateDepositBankStatus = async (
  depositeBankState: ICreateDepositBankProps
) => {
  const endpoint = DEPOSIT_BANK.BANK + DEPOSIT_BANK.STATUS;
  const response = await axios.patch(endpoint, depositeBankState);
  return response.data;
};

export const uploadBulkBankDeposit = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const endpoint = DEPOSIT_BANK.BANK + DEPOSIT_BANK.BULK;
  const response = await axios.post(endpoint, formData, config);
  return response.data;
};

export const deleteDepositBank = async (depositBankId: string) => {
  const response = await axios.delete(
    DEPOSIT_BANK.BANK_BY_ID.replace('{depositBankId}', depositBankId)
  );
  return response.data;
};

export const fetchErrorFileById = async (requestId: string) => {
  const endPoint =
    DEPOSIT_BANK.BANK +
    DEPOSIT_BANK.BULK +
    DEPOSIT_BANK.ERROR_BY_ID.replace(':requestId', requestId);
  const response = await axios.get(endPoint);
  return response.data;
};
