import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { fetchProcessList } from '../../../actions/TeleAllocationAction';
import {
  defaultProcessState,
  getTeleAllocation,
  setCreateProcessState,
  setOpenCreateProcessDrawer,
} from '../../../features/teleAllocationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CreateProcess, ProcessType } from '../../../types/teleAllocation';
import { DateIndicateEnum, DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaDateTimePicker from '../../common/VegaDateTimePicker';
import VegaDrawer from '../../common/VegaDrawer';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import CampaignSelector from '../components/CampaignSelector';

const TODAY = new Date();
const CreateProcessFormDrawer = () => {
  const { createProcessState, openCreateProcessDrawer } =
    useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const disabled =
    !createProcessState.name.length ||
    !createProcessState.campaign ||
    !createProcessState.endDate ||
    !createProcessState.startDate ||
    !createProcessState.callingMode;

  const onClose = () => {
    dispatch(setOpenCreateProcessDrawer(false));
    dispatch(setCreateProcessState(defaultProcessState));
  };

  const handleChange = (key: keyof CreateProcess, value: string | number) => {
    const campaign = {
      ...createProcessState,
      [key]: value,
    };
    dispatch(setCreateProcessState(campaign));
  };

  function handleNameChange(value: string) {
    if (value.endsWith(' ')) {
      return;
    }
    handleChange('name', value);
  }

  function handleStartDateChange(e: Date) {
    handleChange('startDate', e?.toISOString());
  }

  function handleEndDateChange(e: Date) {
    handleChange('endDate', e?.toISOString());
  }

  function handleCallingModeChange(value: string) {
    switch (value) {
      case ProcessType.AUTO:
        handleChange('callingMode', 3);
        break;
      case ProcessType.MANUAL:
        handleChange('callingMode', 1);
        break;
      default:
        return;
    }
  }

  const onCreateProcess = async () => {
    setLoading(true);
    const resquest = {
      ...createProcessState,
      startDate: DateUtility.toISOString(
        createProcessState.startDate,
        DateIndicateEnum.PLUS
      ),
      endDate: DateUtility.toISOString(
        createProcessState.endDate,
        DateIndicateEnum.PLUS
      ),
    } as CreateProcess;
    await TeleAllocationService.createProcess(resquest)
      .then(() => {
        setSnackbar('Create Process Successfully !!!');
        dispatch(fetchProcessList({ page: 0, size: 10 }));
        onClose();
        setLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setLoading(false);
      });
  };

  return (
    <VegaDrawer
      title="Create Process"
      open={openCreateProcessDrawer}
      onClose={onClose}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Create Process"
                disabled={disabled}
                onClick={onCreateProcess}
                loading={loading}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={'1.25rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'process name'}>
              <LmTextField
                placeholder="Enter Process Name"
                value={createProcessState.name}
                onChange={e => handleNameChange(e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'campaign id'}>
              <CampaignSelector
                selected={createProcessState.campaign}
                handleChange={e => handleChange('campaign', e)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'process type'}>
              {/* <LmTextField placeholder="Leadset Name" value={'AUTO'} disabled /> */}
              <VegaSelect
                placeholder="Select Process Type"
                options={getProcessTypes()}
                onChange={e => {
                  handleCallingModeChange(e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>

          <Grid item xs={12}>
            <VegaFormInputField label={'Start Date'}>
              <VegaDateTimePicker
                onChange={e =>
                  handleStartDateChange(DateUtility.parseDateFromDatePicker(e))
                }
                value={createProcessState.startDate}
                minDate={TODAY}
                maxDate={createProcessState.endDate}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'End Date'}>
              <VegaDateTimePicker
                minDate={createProcessState.startDate}
                value={createProcessState.endDate}
                onChange={e =>
                  handleEndDateChange(DateUtility.parseDateFromDatePicker(e))
                }
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default CreateProcessFormDrawer;

const getProcessTypes = () =>
  Object.values(ProcessType).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
