import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import LmDialog from '../../../../components/common/LmDialog';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDateTimePicker from '../../../../components/common/VegaDateTimePicker';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaText from '../../../../components/common/VegaText';
import { Agenda, Ots } from '../../../../types/ots';
import { DateUtility } from '../../../../utils/DateUtlility';

type Props = {
  open: boolean;
  onClose: () => void;
  ots?: Ots;
  agenda?: Agenda;
  onTriggerClick: (triggerDate: Date) => void;
};

function OtsTriggerDelayModal({ open, onClose, ots, onTriggerClick }: Props) {
  const [triggerDate, setTriggerDate] = useState<Date>();

  function handleClose() {
    onClose();
  }

  function handleTriggerClick() {
    if (triggerDate) {
      onTriggerClick(triggerDate);
      handleClose();
    }
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => {
        handleClose();
      }}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'end'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            variant="outlined"
            fullWidth
            text="Close"
            onClick={() => handleClose()}
          />
          <VegaButton
            fullWidth
            text="Trigger"
            onClick={() => handleTriggerClick()}
            disabled={triggerDate == null}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText text="Documents" fontWeight={600} fontSize={'1.0625rem'} />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" gap={'1rem'}>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Trigger Date Time'}>
              <VegaDateTimePicker
                onChange={e => {
                  const parsedDate = DateUtility.parseDateFromDatePicker(e);
                  setTriggerDate(parsedDate);
                }}
                value={triggerDate}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Stack>
    </LmDialog>
  );
}

export default OtsTriggerDelayModal;
