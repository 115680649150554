import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import { getIncentiveScoreCard } from '../../../../features/IncentivesGamificationsSlice/incentiveScoreCardSlice';
import { useAppSelector } from '../../../../store';
import HitsAndMissesColumn from '../columns/HitsAndMissesColumn';

const ListOfHitsAndMisses = () => {
  const { hitsMisses } = useAppSelector(getIncentiveScoreCard);
  return (
    <>
      <VegaDataGrid
        idColumn="id"
        columns={HitsAndMissesColumn()}
        data={hitsMisses}
        loading={false}
        hideFooter
      />
    </>
  );
};

export default ListOfHitsAndMisses;
