import React from 'react';
import VegaPageContent from '../../common/VegaPageContent';
import { Stack } from '@mui/material';
import TeleAgentTextWrapper from '../../TeleAgent/components/TeleAgentTextWrapper';

const RouteDeviationDetailsContainer = () => {
  return (
    <>
      <VegaPageContent>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
          <TeleAgentTextWrapper
            text="Type of leave"
            value={'Paid Leave'}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={false}
          />
          <TeleAgentTextWrapper
            text="Start Date"
            value={'Sept 10, 2023'}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={false}
          />
          <TeleAgentTextWrapper
            text="start time"
            value={'First Half'}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={false}
          />
          <TeleAgentTextWrapper
            text="end date"
            value={'Sept 10, 2023'}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={false}
          />
          <TeleAgentTextWrapper
            text="end time"
            value={'First Half'}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={false}
          />
          <TeleAgentTextWrapper
            text="status"
            value={'Pending'}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={false}
          />

          <TeleAgentTextWrapper
            text="reason"
            value={'The reason for leave request is XYZ'}
            sx={{
              minWidth: '425px',
            }}
            loading={false}
          />
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default RouteDeviationDetailsContainer;
