import axios from 'axios';
import {
  CreateReferToFieldRequest,
  GetReferToFieldListRequest,
  UpdateReferToFieldStatusRequest,
} from '../types/request/referToField';

const Endpoint = {
  BASE: '/allocation',
  REFER_TO_FIELD_LIST: '/referToField/list',
  RAISE_REFER_TO_FIELD: '/raise/referToField',
  UPDATE_REFER_TO_FIELD_STATUS: '/approve/referToField',
};

const BASE_URL = Endpoint.BASE;
export class ReferToFieldService {
  static async getReferToFieldList(
    request: Partial<GetReferToFieldListRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.REFER_TO_FIELD_LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createReferToField(request: Partial<CreateReferToFieldRequest>) {
    const endpoint = BASE_URL + Endpoint.RAISE_REFER_TO_FIELD;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateReferToFieldStatus(
    request: Partial<UpdateReferToFieldStatusRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.UPDATE_REFER_TO_FIELD_STATUS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
