import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import { ApproveIcon } from '../../../../components/Icons/Icons';
import LmDialog from '../../../../components/common/LmDialog';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import VegaText from '../../../../components/common/VegaText';
import { GREY } from '../../../../constants/style';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { ReceiptStatus } from '../../../../types/receiptManagment';
import { UpdateReceiptCancellationStatusRequest } from '../../../../types/request/receiptManagment';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useAppSelector } from '../../../../store';
import { receiptState } from '../../../../features/receiptManagmentSlice';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFetchList: () => void;
}

const ReceiptCancellationApproveDialog = ({
  open,
  onClose,
  onFetchList,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const { cancelReceiptData } = useAppSelector(receiptState);

  const onApprove = async () => {
    const depositStatusObj = {
      receiptCancelRequestId: cancelReceiptData.id,
      approverId: user?.id,
      status: ReceiptStatus.APPROVED,
    } as UpdateReceiptCancellationStatusRequest;
    await ReceiptManagmentService.updateReceiptCancellationStatus(
      depositStatusObj
    )
      .then(() => {
        setSnackbar('Approve successfully !!');
        onFetchList();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
    onClose();
  };
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={open}
        handleClose={() => onClose()}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="Cancel"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                }}
                onClick={() => onClose()}
                startIcon={<Close sx={{ color: GREY.dark }} />}
              />
              <VegaButton
                fullWidth
                text="Appove"
                startIcon={<ApproveIcon strokeColor="white" />}
                onClick={onApprove}
              />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Are you sure?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={`You are approving the Receipt ${
              cancelReceiptData?.receiptNumber === null
                ? 'NA'
                : cancelReceiptData?.receiptNumber
            }`}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ReceiptCancellationApproveDialog;
