import { useState } from 'react';
import { TeleAgentService } from '../../Apis/TeleAgentServices';
import TeleAgentAuditFilterForm, {
  DateRangeFilter,
} from '../../components/TeleAgent/TeleAgentAuditFilterForm';
import VegaButton from '../../components/common/VegaButton';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import ListOfTeleAudit from './modules/ListOfTeleAudit';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const TeleAgentAuditPage = () => {
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();

  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: TODAY.toISOString(),
    endDate: TOMORROW.toISOString(),
  });

  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const onClearAllClick = () => {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter('');
    closeFilterDrawer();
  };

  const onSelectFilterClick = (
    statusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
  };

  const exportAudit = async () => {
    try {
      setLoadingExport(true);
      const response = await TeleAgentService.getExportAuditLink({
        agentId: user?.id,
      });
      const url = response.FILE_URL;
      if (url) {
        window.open(url, '__BLANK__');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <VegaPageHeader
        title="Trail Audit"
        renderRightView={() => (
          <VegaButton
            text={'Export'}
            onClick={exportAudit}
            loading={loadingExport}
          />
        )}
      />

      <ListOfTeleAudit
        onFilterOpen={function (): void {
          openFilterDrawer();
        }}
      />
      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <TeleAgentAuditFilterForm
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
        />
      </VegaDrawer>
    </>
  );
};

export default TeleAgentAuditPage;
