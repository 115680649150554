export type VegaNotification = {
  id: string;
  message: string;
  templateId: string;
  senderUserId: string;
  recipientUserId: string;
  recipient: string;
  channel: NotificationChannel;
  createdAt: string;
  updatedAt: string;
  metadata: Record<string, any>;
  expiryTime: string;
};

export enum NotificationChannel {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  IVR = 'IVR',
  PUSH = 'PUSH',
}

export enum NotificationType {
  TARGETS = 'TARGETS',
  NON_GEO_VERIFICATION_REQUEST = 'NON_GEO_VERIFICATION_REQUEST',
  EXPENSES = 'EXPENSES',
  MY_ACCOUNTS = 'MY_ACCOUNTS',
  LEAVES = 'LEAVES',
  OTS = 'OTS',
  ESCALATIONS = 'ESCALATIONS',
  MIN_VISIT_TRACKER = 'MIN_VISIT_TRACKER',
}
