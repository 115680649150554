import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { LoanService } from '../../Apis/LoanServices';
import LmDialog from '../../components/common/LmDialog';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { useClientAuth } from '../../providers/ClientProvider';
import { LoanData } from '../../types/loan';
import { LoanListRequest } from '../../types/request/loan';
import VegaIconButton from '../common/VegaIconButton';

type props = {
  isListOfPaymentPerformanceOpen: boolean;
  closeListOfPaymentPerformance: () => void;
  loanStatus: string;
  loanIdList: string[];
};
function ListOfPaymentPerformanceDetailDialog({
  closeListOfPaymentPerformance,
  isListOfPaymentPerformanceOpen,
  loanStatus,
  loanIdList,
}: props) {
  const [loanDetails, setLoanDetails] = useState<LoanData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  async function getLoanListDeatils() {
    const request = {
      page,
      size: 10,
      includeCustomerDetails: true,
      includeMetaDataLoanAllocation: true,
      includeLoanOutstandingDetails: true,
      restrictedView: true,
      // loanStatus: loanStatus,
      loanIdList: loanIdList.length > 0 ? loanIdList : [''],
    } as LoanListRequest;

    const response = await LoanService.getLoanList(request);

    setLoanDetails(response.records);
    setTotalItems(response.totalItems);
  }

  useEffect(() => {
    if (isListOfPaymentPerformanceOpen) {
      getLoanListDeatils();
    }
  }, [page, isListOfPaymentPerformanceOpen]);

  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={isListOfPaymentPerformanceOpen}
        handleClose={closeListOfPaymentPerformance}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Accounts"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaPageContent sx={{ marginTop: 4, marginBottom: 4 }}>
          <VegaDataGrid
            data={loanDetails}
            columns={ColumnDef()}
            idColumn={'loanId'}
            page={page}
            pageSize={10}
            rowCount={totalItems}
            onPageChange={page => setPage(page)}
          />
        </VegaPageContent>
      </LmDialog>
    </div>
  );
}

export default ListOfPaymentPerformanceDetailDialog;
function ColumnDef() {
  const { agentIdToManager, userForId } = useClientAuth();

  const getManagerName = (userId: string) => {
    if (!userId) return 'NA';
    const manager = agentIdToManager(userId);
    if (!manager) return 'NA';
    return manager;
  };
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const COL_DEF: GridColumns = [
    {
      field: 'loanId',
      headerName: 'Loan Account Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const userName = loan?.lmsVariables?.loanId
          ? loan?.lmsVariables?.loanId
          : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const userName = loan?.lmsVariables?.customerName
          ? loan?.lmsVariables?.customerName
          : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Agent',
      headerName: 'Agent',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const LmsVariable = loan?.lmsVariables;

        let displayText = '';
        if (LmsVariable.agentId) {
          displayText = getUserName(LmsVariable.agentId);
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'Address',
      headerName: 'Address',
      resizable: true,
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan?.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName?.customerAddress) {
            displayText = toLowerCase(getCustomerName?.customerAddress);
          }
        }
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const customerDetails = getFirstItemIfExists(loan?.customerDetails);
        const display = customerDetails ? customerDetails?.mobileNo : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'modelPrediction',
      headerName: 'Model prediction',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan?.lmsVariables?.modelParserAllocationType
          ? loan.lmsVariables?.modelParserAllocationType
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'managerName',
      headerName: 'Manager Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? getManagerName(loan?.lmsVariables?.agentId) : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'dpd',
      headerName: 'DPD',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan?.lmsVariables?.dpd ? loan.lmsVariables.dpd : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'overdueAmount',
      headerName: 'Overdue Amount',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan?.lmsVariables?.overdueAmount
          ? loan.lmsVariables.overdueAmount
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan?.lmsVariables?.bucket
          ? loan.lmsVariables.bucket
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'cycleDate',
      headerName: 'Cycle Date',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan?.lmsVariables?.cycleDate
          ? loan.lmsVariables.cycleDate
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'branchCode',
      headerName: 'Branch Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan?.lmsVariables?.branchCode
          ? loan.lmsVariables.branchCode
          : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'segment',
      headerName: 'Segment',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan?.lmsVariables?.segment : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 0.7,
      minWidth: 300,
      renderCell: props => {
        const loan = props.row as LoanData;
        const display = loan ? loan?.loanMaster?.loanProduct : '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
}

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
