import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { PRIMARY } from '../../../constants/style';
import { IncentivesActionEnum } from '../../../pages/IncentivesGamifications/IncentivesGamifications';
import { TempleteListProps } from '../../../types/incentives';
import { COLOR } from '../../../utils/ColorUtility';
import { DeleteIcon } from '../../Icons/Icons';
import VegaIconButton from '../../common/VegaIconButton';

interface IColumnProps {
  onAction: (templete: TempleteListProps, action: string) => void;
}

export const TempleteColumn = ({ onAction }: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'templateName',
      headerName: 'Template Name',
      flex: 1,
    },
    {
      field: 'templateId',
      headerName: 'Template Id',
      flex: 0.7,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: props => {
        const templete = props.row as TempleteListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Template"
              onClick={() => onAction(templete, IncentivesActionEnum.VIEW)}
              sx={{ backgroundColor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
            <VegaIconButton
              tooltipTitle="Delete Template"
              onClick={() => onAction(templete, IncentivesActionEnum.DELETE)}
              sx={{ backgroundColor: COLOR.RED.LIGHT }}
              icon={<DeleteIcon />}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
