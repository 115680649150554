import _ from 'lodash';

/* eslint-disable no-useless-escape */
export class StringUtility {
  static concatenateStrings(...strings: (string | undefined)[]): string {
    return strings.filter(str => str !== undefined).join(' ');
  }

  static formatToINR(value?: number | string) {
    if (value === 0 || value === '0') return '₹ 0';
    if (!value) return '-';

    const numberValue =
      typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
    if (isNaN(numberValue)) {
      return 'Invalid Number';
    }

    const absoluteValue = Math.abs(numberValue);

    // Use "en-IN" locale for Indian numbering system and currency formatting
    const formattedValue = absoluteValue.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    });
    return `${formattedValue}`;
  }

  static extractLastNDigits(n: number, value?: string): string {
    if (!value) return '';
    return value.substring(Math.max(0, value.length - n));
  }
  static validateEmail(email: string) {
    const emailRegex = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,63}){1,3})$/;
    return emailRegex.test(email);
  }
  static validatePhoneNumber(number: string) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  }
  static validateChequeNumber(number: string) {
    const phoneRegex = /^\d{15}$/;
    return phoneRegex.test(number);
  }
  static validationIfscNumber(number: string) {
    const ifscRegex = /^[A-Z]{4}\d{7}$/;
    return ifscRegex.test(number);
  }
  static validationbranchCode(number: string) {
    const CodeRegex = /^-?\d+$/;
    return CodeRegex.test(number);
  }

  static validateReferenceNumber(reference: string) {
    const referenceRegex = /^[a-zA-Z0-9]*$/;
    return referenceRegex.test(reference);
  }

  static validationMicrNumber(number: string) {
    const micrRegex = /^\d{9}$/;
    return micrRegex.test(number);
  }
  static validationRefNumber(number: string) {
    const RefRegex = /^[A-Z0-9]{16}$/;
    return RefRegex.test(number);
  }

  static formatNumberToIndianNumberFormat(
    number: number | string,
    minFraction = 0,
    maxFraction = 0
  ): string {
    const numericValue: number =
      typeof number === 'string' ? parseFloat(number) : number;

    if (isNaN(numericValue)) {
      return;
    }
    return numericValue.toLocaleString('en-IN', {
      maximumFractionDigits: maxFraction,
      minimumFractionDigits: minFraction,
      useGrouping: true,
    });
  }

  static camelCaseToTitle(value: string) {
    return _.join(
      _.map(_.words(value, /[^A-Z]+|[A-Z][^A-Z]+/g), _.capitalize),
      ' '
    );
  }
  static formatToFixed(value: string | number, decimalPlaces: number): string {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numericValue)) {
      return '-';
    }

    const formattedValue = numericValue.toFixed(decimalPlaces);

    return formattedValue;
  }

  static isNumber(value: string): boolean {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      return true;
    }
    return false;
  }
}
