import { CircularProgress, Grid, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AllocationService } from '../../Apis/AllocationService';
import {
  bulkAllocate,
  bulkDeAllocate,
  downloadBulkErrorFile,
  fetchBulkAllocations,
  fetchBulkDeAllocations,
} from '../../actions/AllocationActions';
import AllocationFileUploadCard, {
  ViewState,
} from '../../components/Allocation/AllocationFileUploadCard';
import {
  FileUploadIcon,
  HolidayMasterIcon,
} from '../../components/Icons/Icons';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../components/common/VegaFileSelect';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../components/common/VegaSelect';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { getAllocations } from '../../features/allocationSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  BulkAllocationMeta,
  LongRunningTaskStatus,
} from '../../types/allocations';
import { DateUtility } from '../../utils/DateUtlility';
import { StorageKeys, StorageUtility } from '../../utils/StorageUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaTooltip from '../../components/common/VegaTooltip';
import VegaIconButton from '../../components/common/VegaIconButton';

enum AllocationTabType {
  Allocation,
  DeAllocation,
}

const getBulkAllocationRequestId = StorageUtility.getItem<string>(
  StorageKeys.BULK_ALLOCATION_REQUEST_ID
)
  ? StorageUtility.getItem<string>(StorageKeys.BULK_ALLOCATION_REQUEST_ID)
  : '';

const getBulkDeAllocationRequestId = StorageUtility.getItem<string>(
  StorageKeys.BULK_DE_ALLOCATION_REQUEST_ID
)
  ? StorageUtility.getItem<string>(StorageKeys.BULK_DE_ALLOCATION_REQUEST_ID)
  : '';
function BulkAllocation() {
  const [allocationType, setAllocationType] = useState<AllocationType>(
    AllocationType.FIELD
  );
  const [isBulkAllocationInProgress, setIsBulkAllocationInProgress] =
    useState<boolean>(false);
  const [isBulkDeAllocationInProgress, setIsBulkDeAllocationInProgress] =
    useState<boolean>(false);
  const [bulkAllocationRequestId, setBulkAllocationRequestId] =
    useState<string>(getBulkAllocationRequestId);
  const [bulkDeAllocationRequestId, setBulkDeAllocationRequestId] =
    useState<string>(getBulkDeAllocationRequestId);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const {
    bulkAllocations,
    loadingBulkAllocation: loading,
    uploadingBulkFile,
    errorBulkAllocation,
    bulkDeAllocations,
    totalItemsOfBulkAllocation,
    totalItemsOfBulkDeAllocation,
  } = useAppSelector(getAllocations);
  const [selectedFile, setSelectedFile] = useState<VegaFileSelectOption>();
  const [selectedTab, setSelectedTab] = useState<number>(
    AllocationTabType.Allocation
  );
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const fileInputRef = useRef<HTMLInputElement>(null);

  function _onTabChange(value: number): void {
    setSelectedTab(value);
  }

  async function uploadFile() {
    try {
      if (selectedTab == AllocationTabType.Allocation) {
        const response = await dispatch(
          bulkAllocate({
            file: selectedFile.value,
            allocationType: allocationType,
          })
        ).unwrap();
        if (response.status === 'COMPLETED') {
          setIsBulkAllocationInProgress(false);
          StorageUtility.removeItem(StorageKeys.BULK_ALLOCATION_REQUEST_ID);
          setBulkAllocationRequestId('');
          setSnackbar('Loans Allocated successfully');
          fetchData();
        } else {
          StorageUtility.setItem(
            StorageKeys.BULK_ALLOCATION_REQUEST_ID,
            response.id
          );
          setBulkAllocationRequestId(response.id);
          setIsBulkAllocationInProgress(true);
          getBulkAllocationStatus(response.id);
        }
      } else if (selectedTab == AllocationTabType.DeAllocation) {
        const response = await dispatch(
          bulkDeAllocate({ file: selectedFile.value })
        ).unwrap();
        if (response.status === 'COMPLETED') {
          setIsBulkDeAllocationInProgress(false);
          StorageUtility.removeItem(StorageKeys.BULK_DE_ALLOCATION_REQUEST_ID);
          setBulkDeAllocationRequestId('');
          setSnackbar('Loans De-allocated successfully');
          fetchData();
        } else {
          StorageUtility.setItem(
            StorageKeys.BULK_DE_ALLOCATION_REQUEST_ID,
            response.id
          );
          setBulkDeAllocationRequestId(response.id);
          setIsBulkDeAllocationInProgress(true);
          getBulkDeAllocationStatus(response.id);
        }
      }
    } catch (error) {
      setSnackbar(error, 'error');
    }
  }

  const getBulkAllocationStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsBulkAllocationInProgress(false);
        StorageUtility.removeItem(StorageKeys.BULK_ALLOCATION_REQUEST_ID);
        setSnackbar('Loans Allocated successfully');
        setBulkAllocationRequestId('');
        fetchData();
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsBulkAllocationInProgress(false);
        StorageUtility.removeItem(StorageKeys.BULK_ALLOCATION_REQUEST_ID);
        setSnackbar(response.result, 'error');
        setBulkAllocationRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getBulkDeAllocationStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsBulkDeAllocationInProgress(false);
        StorageUtility.removeItem(StorageKeys.BULK_DE_ALLOCATION_REQUEST_ID);
        setSnackbar('Loans De-allocated successfully');
        setBulkDeAllocationRequestId('');
        fetchData();
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsBulkDeAllocationInProgress(false);
        StorageUtility.removeItem(StorageKeys.BULK_DE_ALLOCATION_REQUEST_ID);
        setSnackbar(response.result, 'error');
        setBulkDeAllocationRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getAllocationsList = () => {
    if (selectedTab == AllocationTabType.Allocation) return bulkAllocations;
    else if (selectedTab == AllocationTabType.DeAllocation)
      return bulkDeAllocations;
    return [];
  };

  async function onDownloadClick(allocation: BulkAllocationMeta) {
    try {
      const requestId = allocation.id;
      const url = await dispatch(
        downloadBulkErrorFile({ requestId: requestId })
      ).unwrap();
      window.open(url);
      setSnackbar(' Bulk Error File Downloaded successfully');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  function updateAllocationType(value) {
    setAllocationType(value);
  }

  async function onDownloadSampleFile() {
    if (selectedTab == AllocationTabType.Allocation) {
      if (allocationType === AllocationType.TELE) {
        const url = await AllocationService.downloadBulkAllocationSampleFile({
          bulkUploadActionType: bulkAllocationType.TELE,
        });
        window.open(url, '__blank');
      } else {
        const url = await AllocationService.downloadBulkAllocationSampleFile({
          bulkUploadActionType: bulkAllocationType.FIELD,
        });
        window.open(url, '__blank');
      }
    } else if (selectedTab == AllocationTabType.DeAllocation) {
      const url = await AllocationService.downloadBulkDeallocationSampleFile({
        bulkUploadActionType: bulkAllocationType.DEALLOCATION,
      });
      window.open(url, '__blank');
    }
  }

  function fetchData() {
    setSelectedFile(undefined);
    if (selectedTab == AllocationTabType.Allocation) {
      dispatch(fetchBulkAllocations({ page, size }));
    } else if (selectedTab == AllocationTabType.DeAllocation) {
      dispatch(fetchBulkDeAllocations({ page, size }));
    }
  }

  function getColumnDefinition() {
    if (selectedTab == 0) {
      return getAllocationColumnDefinition({
        onDownloadClick: onDownloadClick,
      });
    } else if (selectedTab == 1) {
      return getDeAllocationColumnDefinition({
        onDownloadClick: onDownloadClick,
      });
    }
    return [];
  }

  function getStatusText() {
    if (isBulkAllocationInProgress && isBulkDeAllocationInProgress) {
      return `De-allocation and ${toLowerCase(
        allocationType
      )} Allocation are in progress.`;
    }
    if (isBulkAllocationInProgress) {
      return `${toLowerCase(allocationType)} allocation is in progress.`;
    } else {
      return `De-allocation is in progress.`;
    }
  }

  useEffect(() => {
    fetchData();
    setSelectedFile(undefined);
    setPage(0);
    setSize(10);
  }, [selectedTab]);

  useEffect(() => {
    fetchData();
  }, [page, size]);

  useEffect(() => {
    if (uploadingBulkFile == false) {
      setSelectedFile(undefined);
    }
  }, [uploadingBulkFile]);

  useEffect(() => {
    if (errorBulkAllocation) setSnackbar(errorBulkAllocation, 'error');
  }, [errorBulkAllocation]);

  useEffect(() => {
    if (fileInputRef.current != null && selectedFile == undefined) {
      fileInputRef.current.value = '';
    }
  }, [selectedFile]);

  useEffect(() => {
    if (bulkAllocationRequestId.length) {
      const uploadingFile = setInterval(() => {
        getBulkAllocationStatus(bulkAllocationRequestId);
      }, 7000);
      return () => clearInterval(uploadingFile);
    }
  }, [bulkAllocationRequestId]);

  useEffect(() => {
    if (bulkDeAllocationRequestId.length) {
      const uploadingFile = setInterval(() => {
        getBulkDeAllocationStatus(bulkDeAllocationRequestId);
      }, 7000);
      return () => clearInterval(uploadingFile);
    }
  }, [bulkDeAllocationRequestId]);

  return (
    <div>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Bulk Allocation'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  _onTabChange(selected);
                }}
              >
                <VegaTabBarItem label="Allocation" />
                <VegaTabBarItem label="De-Allocation" />
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      <VegaPageContent sx={{ marginTop: '1.5rem' }}>
        <div
          style={{
            borderRadius: '0.5rem',
            border: '1px solid #E1E4EB ',
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            padding: '2rem',
            alignItems: 'start',
            position: 'relative',
          }}
        >
          <Grid container columnGap={'1rem'}>
            <Grid
              item
              xs={3}
              style={{
                display:
                  selectedTab === AllocationTabType.Allocation
                    ? 'block'
                    : 'none',
              }}
            >
              <VegaFormInputField label="Allocation Type">
                <VegaSelect
                  placeholder="Select Allocation Type"
                  options={allocationTypeOptions()}
                  value={allocationType}
                  onChange={e => {
                    updateAllocationType(e.target.value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={3}>
              <VegaFormInputField label="Upload File">
                <VegaFileSelect
                  placeholder={'Upload File'}
                  endIcon={<></>}
                  startIcon={<FileUploadIcon />}
                  acceptedExtension={[AcceptedExtension.XLSX]}
                  onFileSelect={file => {
                    setSelectedFile(file);
                  }}
                  onFileInputRef={ref => {
                    fileInputRef.current = ref;
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>

          {!!selectedFile && (
            <Grid container>
              <Grid item xs={12} style={{ flex: 1, marginTop: '0.8rem' }}>
                <AllocationFileUploadCard
                  fileName={selectedFile?.label}
                  state={
                    uploadingBulkFile ? ViewState.Uploading : ViewState.Inital
                  }
                  onUploadClick={function (): void {
                    uploadFile();
                  }}
                  onCancelClick={function (): void {
                    setSelectedFile(undefined);
                    fileInputRef.current.value = '';
                  }}
                />
              </Grid>
            </Grid>
          )}
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            {isBulkAllocationInProgress || isBulkDeAllocationInProgress ? (
              <Stack
                direction="row"
                sx={{ alignItems: 'center', padding: 2 }}
                gap={1}
              >
                <CircularProgress size="15px" />
                <VegaText text={getStatusText()} />
              </Stack>
            ) : (
              <VegaButton
                text="Download Template"
                variant="text"
                startIcon={<HolidayMasterIcon strokeColor="#1047DC" />}
                onClick={onDownloadSampleFile}
              />
            )}
          </div>
        </div>
      </VegaPageContent>
      {/* <VegaPageHeader
        renderRightView={() => {
          return <LmFilterSection />;
        }}
        sx={{ marginBottom: '2rem', marginTop: '2rem', borderBottom: 'none' }}
      /> */}
      <div style={{ margin: '1rem' }}></div>
      <VegaPageContent>
        <VegaDataGrid
          data={getAllocationsList()}
          columns={getColumnDefinition()}
          idColumn={'id'}
          loading={loading}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
          page={page}
          pageSize={size}
          rowCount={
            selectedTab == AllocationTabType.Allocation
              ? totalItemsOfBulkAllocation
              : totalItemsOfBulkDeAllocation
          }
        />
      </VegaPageContent>
    </div>
  );
}

export default BulkAllocation;

const getAllocationColumnDefinition = (data: {
  onDownloadClick: (allocation: BulkAllocationMeta) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.fileName;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'allocationType',
      headerName: 'Allocation Type',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.allocationType;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'requestId',
      headerName: 'Request Id',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.id;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = DateUtility.formatStringToDDMMYYYY(
          allocation.createdAt
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'uploaderName',
      headerName: 'Uploader Name',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.uploaderName;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'uploaderCode',
      headerName: 'Uploader Code',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.uploaderCode;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'totalEntries',
      headerName: 'Total Entries',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.totalEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'success',
      headerName: 'Success',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.successEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'failure',
      headerName: 'Failure',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.failedEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Error Details',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const hasErrorFile = !!allocation?.errorFile;
        if (hasErrorFile) {
          return (
            <VegaButton
              text={'Download'}
              variant="text"
              onClick={() => data.onDownloadClick(allocation)}
            />
          );
        }
        return <VegaButton disabled text={'No Errors'} variant="text" />;
      },
    },
  ];
  return COL_DEF;
};

const getDeAllocationColumnDefinition = (data: {
  onDownloadClick: (allocation: BulkAllocationMeta) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.fileName;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'requestId',
      headerName: 'Request Id',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.id;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = DateUtility.formatStringToDDMMYYYY(
          allocation.createdAt
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'uploaderName',
      headerName: 'Uploader Name',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.uploaderName;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'uploaderCode',
      headerName: 'Uploader Code',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.uploaderCode;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={displayText}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={displayText}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'totalEntries',
      headerName: 'Total Entries',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.totalEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'success',
      headerName: 'Success',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.successEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'failure',
      headerName: 'Failure',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const displayText = allocation.failedEntries;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Error Details',
      flex: 0.7,
      renderCell: props => {
        const allocation = getBulkAllocationForRow(props);
        const hasErrorFile = !!allocation?.errorFile;
        if (hasErrorFile) {
          return (
            <VegaButton
              text={'Download'}
              variant="text"
              onClick={() => data.onDownloadClick(allocation)}
            />
          );
        }
        return <VegaButton disabled text={'No Errors'} variant="text" />;
      },
    },
  ];
  return COL_DEF;
};

const getBulkAllocationForRow = (props: any) => props.row as BulkAllocationMeta;
enum AllocationType {
  FIELD = 'FIELD',
  TELE = 'TELE',
}
const allocationTypeOptions = () =>
  [AllocationType.TELE, AllocationType.FIELD].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
enum bulkAllocationType {
  FIELD = 'ALLOCATION',
  TELE = 'TELE_ALLOCATION',
  DEALLOCATION = 'DEALLOCATION',
}
