import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

export interface ISeriesProps {
  name: string;
  data: number[];
}

interface IProps {
  categories: string[];
  series: ISeriesProps[];
  yAxisTitle: string;
}

// const dataPercentageLabels = {
//   enabled: true,
//   formatter: function (val) {
//     return val + '%';
//   },
//   offsetY: -20,
//   style: {
//     fontSize: '12px',
//     colors: ['#304758'],
//   },
// };
// const dataPayoutLabels = {
//   enabled: true,
//   offsetY: -20,
//   style: {
//     fontSize: '12px',
//     colors: ['#304758'],
//   },
// };

const SimulatorGraph = ({ categories, yAxisTitle, series }: IProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          customIcons: [],
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories ? categories : ['loading'],
    },
    yaxis: {
      title: {
        text: yAxisTitle,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
  };
  return (
    <>
      <Chart options={options} series={series} type="bar" />
    </>
  );
};

export default SimulatorGraph;

// const getCoverageGraphOptions = (title: string): ApexOptions => {
//   return {
//     title: {
//       text: title,
//     },
//     chart: {
//       type: 'bar',
//       height: 150,
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '55%',
//         dataLabels: {
//           position: 'top',
//         },
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       formatter: function (val) {
//         return val + '%';
//       },
//       offsetY: -20,
//       style: {
//         fontSize: '12px',
//         colors: ['#304758'],
//       },
//     },
//     xaxis: {
//       categories: ['1-30', '31-60', '61-90', 'NPA'],
//     },
//     yaxis: {
//       title: {
//         text: 'Percentage',
//       },
//     },
//     legend: {
//       position: 'top',
//       horizontalAlign: 'left',
//       offsetX: 40,
//     },
//   };
// };

// const getPayoutGraphOptions = (title: string): ApexOptions => {
//   return {
//     title: {
//       text: title,
//     },
//     chart: {
//       type: 'bar',
//       height: 150,
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '55%',
//         dataLabels: {
//           position: 'top',
//         },
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       offsetY: -20,
//       style: {
//         fontSize: '12px',
//         colors: ['#304758'],
//       },
//     },
//     xaxis: {
//       categories: ['Total', '1-30', '31-60', '61-90', 'NPA'],
//     },
//     legend: {
//       position: 'top',
//       horizontalAlign: 'left',
//       offsetX: 40,
//     },
//   };
// };
