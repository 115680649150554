import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { OtsService } from '../../../../Apis/OtsService';
import VegaText from '../../../../components/common/VegaText';
import { useOts } from '../../../../providers/OtsProvider';
import { OtsRequestAudit } from '../../../../types/ots';
import { PaginatedResponse } from '../../../../utils/api';
import { DateUtility } from '../../../../utils/DateUtlility';
import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsAuditView() {
  const { ots } = useOts();

  const [audit, setAudit] = useState<PaginatedResponse<OtsRequestAudit>>({
    records: [],
    pageNumber: 0,
    totalPages: 0,
    numberOfItems: 0,
    totalItems: 0,
  });

  async function fetchData(id: string) {
    const response = await OtsService.getOtsAudit({ otsId: id, size: 200 });
    setAudit(response);
  }

  const getDescription = (item: OtsRequestAudit) => {
    let description = `On ${DateUtility.formatStringToDDMMYYYYWithTime(
      item.timestamp
    )}: `;

    // Adding Username and UserID
    if (item.userId) {
      description += `${item.userName} took action: ${item.action}`;
    }

    // Description of the Action
    if (item.description) {
      description += `. Details: ${item.description}`;
    }

    return description;
  };

  useEffect(() => {
    const id = ots?.id;
    if (id) fetchData(id);
  }, [ots]);

  return (
    <OtsDetailsWrapper text="Audit">
      <Stack gap={1}>
        {audit.records.map(item => {
          return (
            <div
              key={item.id}
              style={{ backgroundColor: 'white', padding: '1rem' }}
            >
              <VegaText text={getDescription(item)}></VegaText>
            </div>
          );
        })}
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsAuditView;

// function compareOtsRequests(prev, current) {
//   try {
//     const changes = {};

//     Object.keys(current).forEach(key => {
//       if (current[key] !== prev[key]) {
//         changes[key] = { from: prev[key], to: current[key] };
//       }
//     });

//     return changes;
//   } catch (error) {
//     console.error(error);
//   }
// }
