import { useEffect, useState } from 'react';
import { fetchTeleAgentAudit } from '../../../actions/TeleAgentAction';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import {
  setCallDispose,
  teleAgentAuditSelector,
} from '../../../features/teleAgentAuditSlice';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GetCallDisposeRequest } from '../../../types/request/webhook';
import { TeleAgentAuditModal } from '../../../types/trail';
import FeedbackDrawer from './Drawer/FeedbackDrawer';
import TeleAuditColumn from './columns/TeleAuditColumn';
import VeiwCallDisposeDetailDialog from './Dialog/VeiwCallDisposeDetailDialog';

type Props = {
  onFilterOpen: () => void;
};

export enum TeleAuditAction {
  DOWNLOAD = 'DOWNLOAD',
  VIEW = 'VIEW',
  FEEDBACK = 'FEEDBACK',
}
const ListOfTeleAudit = ({ onFilterOpen }: Props) => {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();
  const { audit, loading, totalItems, error } = useAppSelector(
    teleAgentAuditSelector
  );
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const [openFeedbackDrawer, setOpenFeedbackDrawer] = useState<boolean>(false);
  const [openViewDetailsModal, setOpenViewDetailsModal] =
    useState<boolean>(false);

  const onAction = (
    callDispose: TeleAgentAuditModal,
    action: TeleAuditAction
  ) => {
    if (action === TeleAuditAction.DOWNLOAD) {
      if (!callDispose?.recording) return;
      window.open(callDispose?.recording, '_blank');
    } else if (action === TeleAuditAction.FEEDBACK) {
      setOpenFeedbackDrawer(true);
    } else {
      setOpenViewDetailsModal(true);
    }
    dispatch(setCallDispose(callDispose));
  };

  const fetchData = () => {
    const request: Partial<GetCallDisposeRequest> = {
      agentId: user?.id,
      page: page,
      size: size,
    };
    if (search.length > 0) {
      request.searchPartialLoanId = search;
      request.page = 0;
      request.size = 10;
    }
    dispatch(fetchTeleAgentAudit(request));
  };

  useEffect(() => {
    if (error.length) {
      setSnackbar(error, 'error');
    }
  }, [error]);

  useEffect(() => {
    user && fetchData();
  }, [page, size, user, search]);

  return (
    <>
      <VegaPageHeader
        sx={{ border: 'none' }}
        title={'Call'}
        renderRightView={() => {
          return (
            <LmFilterSection
              hideFilter
              onFilter={() => onFilterOpen()}
              search={search}
              setSearch={setSearch}
            />
          );
        }}
      />
      <VegaPageContent mt={'1.5rem'}>
        <VegaDataGrid
          page={page}
          pageSize={size}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => {
            setPage(page);
          }}
          onPageSizeChange={size => setSize(size)}
          data={audit}
          columns={TeleAuditColumn({ onAction })}
          loading={loading}
          idColumn="id"
        />
      </VegaPageContent>
      <FeedbackDrawer
        open={openFeedbackDrawer}
        onClose={() => setOpenFeedbackDrawer(false)}
      />
      <VeiwCallDisposeDetailDialog
        open={openViewDetailsModal}
        onClose={() => setOpenViewDetailsModal(false)}
      />
    </>
  );
};

export default ListOfTeleAudit;
