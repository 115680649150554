import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchLoanAccountList } from '../../actions/TeleAgentAction';
import {
  default as AdditionDetails,
  default as PendingDuesDetails,
} from '../../components/TeleAgent/TeleAgentManual/AdditionDetails';
import AddressDetails from '../../components/TeleAgent/TeleAgentManual/AddressDetails';
import ContactDetails from '../../components/TeleAgent/TeleAgentManual/ContactDetails';
import LoanDetails from '../../components/TeleAgent/TeleAgentManual/LoanDetails';
import PaymentHistoryDetails from '../../components/TeleAgent/TeleAgentManual/PaymentHistoryDetails';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { useAppDispatch } from '../../store';

const ReferToFieldDetailPage = () => {
  const { loanAccountId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loanAccountId) {
      dispatch(
        fetchLoanAccountList({
          loanId: loanAccountId,
          includeCustomerDetails: true,
          includeLmsVariables: true,
          includeLoanOutstandingDetails: false,
          includeLoanTransactions: true,
          includeLoanPdcDetails: true,
        })
      );
    }
  }, [dispatch]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none', bgcolor: 'transparent' }}
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'My Account', link: -1 },
                { label: `Account Details` },
              ]}
            />
          );
        }}
      />
      <VegaPageContent sx={{ pt: '1rem' }}>
        <Box
          sx={{
            p: '1rem',
            bgcolor: 'white',
            borderRadius: '0.75rem',
            border: '1px solid #E1E4EB',
            my: '1rem',
          }}
        >
          <Stack gap={2}>
            <LoanDetails />
            <PaymentHistoryDetails />
            <PendingDuesDetails />
            <AddressDetails />
            <ContactDetails />
            <AdditionDetails />
          </Stack>
        </Box>
      </VegaPageContent>
    </>
  );
};

export default ReferToFieldDetailPage;
