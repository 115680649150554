import { Grid, Stack, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import VegaText from './VegaText';

type Props = {
  title?: string;
  renderLeftView?: () => ReactNode;
  renderRightView?: () => ReactNode;
  sx?: SxProps<Theme>;
  isTabPresent?: boolean;
};
function VegaPageHeader({
  title,
  renderLeftView,
  renderRightView,
  sx,
  isTabPresent = false,
}: Props) {
  const defaultSxStyles = {
    paddingTop: '1.25rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.25rem',
    paddingLeft: '1.5rem',
    borderBottom: '1px solid #DBDBDB',
    bgcolor: '#FAFAFA',
    minHeight: '4rem',
  };
  const tabStyle = isTabPresent
    ? {
        minHeight: 'auto',
        paddingBottom: '0',
      }
    : {};
  const mergedSxStyles = { ...defaultSxStyles, ...sx, ...tabStyle };

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems="center"
      sx={mergedSxStyles}
    >
      <Grid item sx={renderRightView === undefined && { width: '100%' }}>
        <Stack direction={'row'} width={'100%'} alignItems="center">
          {title && (
            <VegaText
              text={title}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          )}
          {renderLeftView?.()}
        </Stack>
      </Grid>
      {renderRightView && <Grid item>{renderRightView?.()}</Grid>}
    </Grid>
  );
}

export default VegaPageHeader;
