import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { fetchExecutionAllocation } from '../../actions/AllocationActions';
import { getAllocations } from '../../features/allocationSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { ExecuteStatus, ExecutionAllocations } from '../../types/allocations';
import { DateUtility } from '../../utils/DateUtlility';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaDrawer from '../common/VegaDrawer';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import ExecutionHistoryFilterForm from './ExecutionHistoryFilterForm';
import { VegaPill } from '../common/VegaPill';
import { COLOR } from '../../utils/ColorUtility';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';

interface IProps {
  search: string;
  open: boolean;
  toggleFilterDrawer: () => void;
}

const ListOfExecutionHistory = ({
  search,
  open,
  toggleFilterDrawer,
}: IProps) => {
  const dispatch = useAppDispatch();
  const {
    executionAllocations,
    loadingExecution,
    totalItemsOfExecution,
    errorExecutionAllocation,
  } = useAppSelector(getAllocations);
  const { setSnackbar } = useSnackbar();
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const fetchExecutionHistoryData = () => {
    if (search.length) {
      dispatch(
        fetchExecutionAllocation({
          criteriaId: search,
          page: currentPage,
          size,
        })
      );
    } else {
      dispatch(
        fetchExecutionAllocation({
          page: currentPage,
          size,
        })
      );
    }
  };

  useEffect(() => {
    if (search.length) {
      const getLoanData = setTimeout(fetchExecutionHistoryData, 300);
      return () => clearTimeout(getLoanData);
    } else {
      fetchExecutionHistoryData();
    }
  }, [currentPage, size, search]);

  // useEffect(() => {
  //   if (errorExecutionAllocation) {
  //     setSnackbar(
  //       getErrorMessageFromErrorObj(errorExecutionAllocation),
  //       'error'
  //     );
  //   }
  // }, [errorExecutionAllocation]);
  return (
    <>
      <VegaPageContent>
        <VegaDataGrid
          data={executionAllocations}
          loading={loadingExecution}
          columns={getExecutionColumnDefinition()}
          idColumn="id"
          rowCount={totalItemsOfExecution}
          page={currentPage}
          pageSize={size}
          paginationMode="server"
          onPageChange={page => setCurrentPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>

      <VegaDrawer title={'Filter By'} open={open} onClose={toggleFilterDrawer}>
        <ExecutionHistoryFilterForm
          onClose={toggleFilterDrawer}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
      </VegaDrawer>
    </>
  );
};

export default ListOfExecutionHistory;

const getExecutionColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'executedAt',
      headerName: 'Date',
      flex: 0.7,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const formattedDate = executionHistory.createdAt
          ? DateUtility.formatStringToDDMMYYYY(executionHistory.createdAt)
          : '--';
        return (
          <VegaText
            text={formattedDate}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'criteriaId',
      headerName: 'Criteria ID',
      flex: 0.7,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const criteriaId = executionHistory.criteriaId
          ? executionHistory.criteriaId
          : '--';
        return (
          <VegaText text={criteriaId} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'noOfApplicableLoanIds',
      headerName: 'Applicable Loans ID',
      flex: 0.7,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const applicableLanIdList =
          executionHistory.applicableLanIdList?.length;
        return (
          <VegaText
            text={applicableLanIdList}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'numberOfTeleAllocations',
      headerName: 'No. of Tele Allocation',
      flex: 0.7,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const numberOfTeleAllocations =
          executionHistory.numberOfTeleAllocations ?? 0;
        return (
          <VegaText
            text={numberOfTeleAllocations}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'numberOfFieldAllocations',
      headerName: 'No. of Field Allocation',
      flex: 0.7,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const numberOfFieldAllocations =
          executionHistory.numberOfFieldAllocations ?? 0;
        return (
          <VegaText
            text={numberOfFieldAllocations}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const remarks = executionHistory.remarks ?? '--';
        return (
          <VegaText text={remarks} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const executionHistory = props.row as ExecutionAllocations;
        const status = executionHistory.status;
        return status ? (
          <VegaPill
            text={status}
            backgroundColor={getColorForStatus(status)?.LIGHT}
            textColor={getColorForStatus(status)?.DARK}
          />
        ) : (
          <>--</>
        );
      },
    },

    // {
    //   field: 'action',
    //   headerName: 'Details',
    //   flex: 0.7,
    //   renderCell: props => {
    //     const executionHistory = props.row as ExecutionAllocations;

    //     return (
    //       <VegaButton
    //         text={'View Details'}
    //         variant="text"
    //         onClick={() => data.onViewDetails(executionHistory)}
    //       />
    //     );
    //   },
    // },
  ];
  return COL_DEF;
};

const getColorForStatus = (status: string) => {
  if (!status) return;
  switch (status) {
    case ExecuteStatus.SUCCESS:
      return COLOR.GREEN;

    case ExecuteStatus.FAILED:
      return COLOR.RED;
  }
};
