import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import VegaText from '../../../../components/common/VegaText';
import { WriteOffAgentViewListResponseDto } from '../../../../types/request/incentives';
import { StringUtility } from '../../../../utils/StringUtility';

export const AgentsWriteOffColumn = () => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'agentName',
      headerName: 'Agent Name',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={data?.splitUserName ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={data?.splitUserName ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'employeeCode',
      headerName: 'Employee Code',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={data?.splitUserCode ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={data?.splitUserCode ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'lan',
      headerName: 'LAN',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={data?.loanId ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={data?.loanId ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'incentiveonPOSwaiver',
      headerName: 'Incentive on POS waiver',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={StringUtility.formatNumberToIndianNumberFormat(
              data?.baseAmountEarned?.toString() ?? '-'
            )}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={StringUtility.formatNumberToIndianNumberFormat(
                  data?.baseAmountEarned ?? '-'
                )}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   hide: selectedTab === 3,
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: props => {
    //     const data = props.row as WriteOffAgentViewListResponseDto;
    //     return (
    //       <VegaPill
    //         backgroundColor={getColorForStatus(data.status)?.LIGHT}
    //         textColor={getColorForStatus(data.status)?.DARK}
    //         text={data.status ?? '-'}
    //         borderColor={getColorForStatus(data.status)?.DARK}
    //       />
    //     );
    //   },
    // },
    {
      field: 'incentiveonInterestAndChargeCollection',
      headerName: 'Incentive on Interest and Charge Collection',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={StringUtility.formatNumberToIndianNumberFormat(
              data?.incentiveInterestAmount.toString() ?? '-'
            )}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={StringUtility.formatNumberToIndianNumberFormat(
                  data?.incentiveInterestAmount ?? '-'
                )}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'splitPercentage',
      headerName: 'Split percentage',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={`${data?.splitPercentage.toString()}%` ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={`${data?.splitPercentage}%` ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'finalPayout',
      headerName: 'Final Payout',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const data = props.row as WriteOffAgentViewListResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={StringUtility.formatNumberToIndianNumberFormat(
              data?.splitAmount.toString() ?? '-'
            )}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={StringUtility.formatNumberToIndianNumberFormat(
                  data?.splitAmount ?? '-'
                )}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};
