import React from 'react';
import { ProfitTypeProps } from '../../../AnalyticsDashboard';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { OverallCollectionVolumeResponseDto } from '../../../../../types/analytics';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import VegaRangeSlider from '../../../../../components/common/VegaRangeSlider';
import VegaText from '../../../../../components/common/VegaText';
interface IProps extends ProfitTypeProps {}

const CollectionVolumeGraph = ({ selectedCollectionProfitType }: IProps) => {
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<OverallCollectionVolumeResponseDto>
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();

  const resolutionPercentage = analyticsQueryResponse?.resolution ?? 0;
  const targetCases = analyticsQueryResponse?.target ?? 0;
  const collectedCases = analyticsQueryResponse?.achieved ?? 0;

  const getQueries = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.TODAY_COLLECTION_CASES_BASE,
        params: {
          type: selectedCollectionProfitType,
        },
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setAnalyticsQueryResponse({});
      setLoading(false);
    }
  };
  useEffect(() => {
    getQueries();
  }, [selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper title="Collection Volume (No. of cases)" onClick={() => {}}>
        {loading ? (
          <LoadingPage height={90} />
        ) : (
          <Stack pb={2} px={2}>
            <Stack>
              <VegaRangeSlider
                value={resolutionPercentage}
                includePercentSign
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <VegaText text={`Achieved (${collectedCases})`} />
                <VegaText text={`Total (${targetCases})`} />
              </Box>
            </Stack>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default CollectionVolumeGraph;
