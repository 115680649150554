export type ReferToFieldDTO = {
  name: string;
  allocationDate: string;
  referDate: string;
  id: string;
  teleAllocationId: string;
  loanId: string;
  agentId: string;
  approverId: string;
  agentRemark: string;
  status: string;
};

export enum ReferToFieldStatus {
  APPROVED = 'APPROVED',
  RAISED = 'RAISED',
  REJECTED = 'REJECTED',
}
