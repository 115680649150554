import { getTeleAgent } from '../../features/teleAgentSlice';
import { useAppSelector } from '../../store';
import UpdateTrailDrawer from '../Trail/UpdateTrailDrawer';
import { IActionProps } from './AccountActionContainer';

interface IProps extends IActionProps {}

const UpdateTrailFormDrawer = ({ open, setOpen }: IProps) => {
  const { loanData } = useAppSelector(getTeleAgent);
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <UpdateTrailDrawer
        loanDetail={loanData[0]}
        open={open}
        onClose={onClose}
      />
    </>
  );
};

export default UpdateTrailFormDrawer;
