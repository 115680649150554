import { Stack } from '@mui/material';
import ListOfDailyTarget from '../../../components/Escalations/ListOfDailyTarget';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { ROUTES } from '../../../router/routes';
import VegaTabBar from '../../../components/common/VegaTabBar';
import { useState } from 'react';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaButton from '../../../components/common/VegaButton';
import { EscalationsServices } from '../../../Apis/EscalationsServices';
import { EscalationTriggerType } from '../../../types/request/escalationsRequest';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

export enum TargetSubType {
  TARGET_REVIEW = 'Target Review',
  TARGET_NOT_FILLED = 'Target Not Filled',
}
const DailyTarget = () => {
  const { setSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const triggerEscalation = () => {
    EscalationsServices.triggerEscalation({
      triggerType: EscalationTriggerType.TARGET,
    })
      .then(res => {})
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <VegaPageHeader
        sx={{ pb: 0 }}
        renderLeftView={() => {
          return (
            <Stack gap={1} width={'100%'}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <VegaBreadCrumb
                  items={[
                    { label: 'Escalation', link: ROUTES.ESCALATIONS },
                    { label: `Daily Target` },
                  ]}
                />
                <VegaButton
                  text="Trigger Escalation"
                  onClick={triggerEscalation}
                />
              </Stack>
              <VegaTabBar
                value={selectedTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                  setSelectedStatus(0);
                }}
              >
                {Object.keys(TargetSubType).map((type: string) => (
                  <VegaTabBarItem key={type} label={TargetSubType[type]} />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      <ListOfDailyTarget
        selectedTab={selectedTab}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
    </>
  );
};

export default DailyTarget;
