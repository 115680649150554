import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { EditIcon } from '../../../components/Icons/Icons';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import { VegaSwitch } from '../../../components/common/VegaSwitch';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import { VegaUserStatus } from '../../../types/user';
import { useDrawer } from '../../../hooks/useDrawer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { StringUtility } from '../../../utils/StringUtility';
import { RoleMaxPayoutDto, RoleName } from '../../../types/incentives';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { useClientAuth } from '../../../providers/ClientProvider';
import { DateUtility } from '../../../utils/DateUtlility';
import SetMaxPayoutDrawer from './SetMaxPayoutDrawer';
import SaveChangesDialog from './SaveChangesDialog';

export type RoleMaxPayoutViewModel = {
  role: string;
  maxCapping: string;
  updatedAt: string;
  updatedBy: string;
  status: string;
};

function ListOfMaxRolePayout() {
  const { user, userForId } = useClientAuth();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { setSnackbar } = useSnackbar();
  const [roleMaxPayoutList, setRoleMaxPayoutList] = useState<
    RoleMaxPayoutDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    open: openEditRolePayoutDrawer,
    close: closeEditRolePayoutDrawer,
    isOpen: isEditRolePayoutDrawerOpen,
    props: editRolePayoutDrawerProps,
  } = useDrawer(false);

  const {
    open: openSaveChangesDialog,
    close: closeSaveChangesDialog,
    isOpen: isSaveChangesDialogOpen,
    props: saveChangesDialogProps,
  } = useDrawer(false);
  const [totalItem, setTotalItem] = useState<number>(0);

  function onSubmitRoleMaxPayoutClick(
    payoutDetails: RoleMaxPayoutDto,
    amount: string
  ) {
    closeEditRolePayoutDrawer();
    openSaveChangesDialog({ payoutDetail: payoutDetails, amount });
  }

  async function onSaveChangesClick(payoutDetails: RoleMaxPayoutDto) {
    try {
      await IncentiveServices.updateRoleMaxPayoutIncentive({
        role: payoutDetails.role,
        maxCapping: +saveChangesDialogProps.amount,
        updatedBy: getUserName(user.id),
      });
      closeSaveChangesDialog();
      getRoleMaxPayoutList();
      setSnackbar(
        `Max payout for  ${payoutDetails.role} role has been updated successfully.`
      );
    } catch (error) {
      setSnackbar('Unable to save changes. Please try again.', 'error');
    }
  }

  async function onStatusToggle(rolePayout: RoleMaxPayoutDto) {
    try {
      const updatedStatus =
        rolePayout.status === VegaUserStatus.ACTIVE
          ? VegaUserStatus.INACTIVE
          : VegaUserStatus.ACTIVE;

      await IncentiveServices.updateRoleMaxPayoutStatus({
        ids: [rolePayout.entityConfigId],
        status: updatedStatus,
      });

      setRoleMaxPayoutList(prevList =>
        prevList.map(item =>
          item.entityConfigId === rolePayout.entityConfigId
            ? { ...item, status: updatedStatus }
            : item
        )
      );

      setSnackbar(
        `Status for ${rolePayout.role} role has been updated successfully.`
      );
    } catch (error) {
      setSnackbar('Unable to update status. Please try again.', 'error');
    }
  }

  function getUserName(userId: string) {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  }

  async function getRoleMaxPayoutList() {
    try {
      setLoading(true);
      const response = await IncentiveServices.getRoleMaxPayoutList({
        // page,
        // size: pageSize,
        roleNameList: Object.keys(RoleName),
      });
      setRoleMaxPayoutList(response);
      setTotalItem(response.length);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const COLUMN_DEF: GridColumns = [
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      minWidth: 75,
      renderCell: props => {
        const rolePayout = props.row as RoleMaxPayoutDto;
        return (
          <VegaText
            text={rolePayout?.role}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'maxCapping',
      headerName: 'Max Capping',
      flex: 1,
      minWidth: 200,
      renderCell: props => {
        const rolePayout = props.row as RoleMaxPayoutDto;
        return (
          <VegaText
            text={StringUtility.formatToINR(rolePayout?.maxCapping)}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const rolePayout = props.row as RoleMaxPayoutDto;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMMMYYYYWithTime(
              rolePayout?.updatedAt
            )}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'updatedBy',
      headerName: 'updated By',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const rolePayout = props.row as RoleMaxPayoutDto;
        return (
          <VegaText
            text={rolePayout?.updatedBy}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const rolePayout = props.row as RoleMaxPayoutDto;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <VegaText text={toLowerCase(rolePayout?.status)} />
            <VegaSwitch
              checked={rolePayout?.status === VegaUserStatus.ACTIVE}
              onClick={() => onStatusToggle(rolePayout)}
            />
          </Stack>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const rolePayout = props.row as RoleMaxPayoutDto;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text=""
              startIcon={<EditIcon />}
              sx={{
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
                ':hover': {
                  bgcolor: '#E7EDFC',
                  color: PRIMARY.darkBlue,
                },
              }}
              onClick={() => {
                openEditRolePayoutDrawer({ payoutDetail: rolePayout });
              }}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getRoleMaxPayoutList();
  }, []);

  return (
    <>
      <VegaDataGrid
        data={roleMaxPayoutList}
        columns={COLUMN_DEF}
        idColumn="role"
        loading={loading}
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        rowCount={totalItem}
        onPageChange={page => setPage(page)}
        onPageSizeChange={size => setPageSize(size)}
      />
      <SetMaxPayoutDrawer
        open={isEditRolePayoutDrawerOpen}
        onClose={closeEditRolePayoutDrawer}
        payoutDetails={editRolePayoutDrawerProps.payoutDetail}
        onSubmitClick={onSubmitRoleMaxPayoutClick}
      />
      <SaveChangesDialog
        onClose={closeSaveChangesDialog}
        onSubmitClick={onSaveChangesClick}
        open={isSaveChangesDialogOpen}
        payoutDetails={saveChangesDialogProps.payoutDetail}
      />
    </>
  );
}

export default ListOfMaxRolePayout;
