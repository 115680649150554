import { Skeleton, Stack } from '@mui/material';
import VegaText from '../../common/VegaText';

interface IProps {
  count: number | string;
  text: string;
  bgcolor: string;
  color: string;
  border: string;
  height: string;
  loading?: boolean;
  icon?: JSX.Element;
}

const PerformanceCard = ({
  count,
  text,
  bgcolor,
  color,
  height,
  border,
  loading,
  icon,
}: IProps) => {
  return loading ? (
    <Skeleton sx={{ bgcolor }} height={height} />
  ) : (
    <Stack
      sx={{
        borderRadius: '12px',
        border,
        bgcolor,
        color,
        p: 2,
        height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <VegaText text={count} color={color} fontSize={28} fontWeight={600} />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <VegaText
          text={text}
          color={color}
          fontSize={13}
          fontWeight={600}
          lineHeight={'15.6px'}
        />
        {icon && <Stack>{icon}</Stack>}
      </Stack>
    </Stack>
  );
};

export default PerformanceCard;
