export enum TargetsStatus {
  RAISED = 'RAISED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  RE_RAISE = 'RE_RAISE',
}

export enum BucketEnum {
  BUCKETX = 'BUCKETX',
  BUCKET1 = 'BUCKET1',
  BUCKET2 = 'BUCKET2',
  BUCKET3 = 'BUCKET3',
  NPA = 'NPA',
}
export enum BucketEnumForNonNPA {
  BUCKETX = 'BUCKETX',
  BUCKET1 = 'BUCKET1',
  BUCKET2 = 'BUCKET2',
  BUCKET3 = 'BUCKET3',
  CHARGES_COLLECTION = 'CHARGES_COLLECTION',
}
export enum BucketEnumForNPA {
  NPA = 'NPA',
  CHARGES_COLLECTION = 'CHARGES_COLLECTION',
}
export enum WriteOffEnum {
  TECHNICAL = 'TECHNICAL',
  ACTUAL = 'ACTUAL',
}
export enum ClassTypeEnum {
  NPA = 'NPA',
  NON_NPA = 'NON_NPA',
  WRITE_OFF = 'WRITE_OFF',
}

export interface TargetByBucketsProps {
  id?: string;
  caseName?: string;
  posName?: string;
  bucket: string;
  bucketStart?: number;
  bucketEnd?: number;
  posValue: number;
  noOfCases: number;
}
export interface TargetListProps {
  id: string;
  agentId: string;
  agentName?: string;
  targetByBuckets: TargetByBucketsProps[];
  approverId: string;
  approverName?: string;
  targetStatus: string;
  targetDate: string;
  modifyReason: string;
  rejectedReason: string;
}
export interface BucketDetailsProps {
  id: string;
  agentId: string;
  targetByBuckets: TargetByBucketsProps[];
  approverId: string;
  targetStatus: string;
  targetDate: string;
}
