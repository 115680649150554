import { Grid } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import AgentSelector from '../../../components/AgentSelector';
import LmButton from '../../../components/common/LmButton';
import LmDateField from '../../../components/common/LmDateField';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { VegaFileSelectOption } from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { AllocationTypes } from '../../../types/allocations';
import { DateUtility } from '../../../utils/DateUtlility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<AllocateLoanAccountsFormData>) => void;
};

export type AllocateLoanAccountsFormData = {
  agentId: string;
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

function AllocateLoanAccountsDrawer({ open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<
    Partial<AllocateLoanAccountsFormData>
  >({});

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  const isInputValid = () => {
    const isAgentIdValid = (formData.agentId ?? '').length > 0;
    const isAllocationTypeValid = (formData.allocationType ?? '').length > 0;
    return isAgentIdValid && isAllocationTypeValid;
  };

  function onSumbitClick() {
    onSubmit(formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanUp();
  }

  function cleanUp() {
    setFormData({});
  }

  function onAllocationTypeChange(value: string) {
    updateFormData(InputType.AllocationType, value);
  }
  function updateExpiryDate(value: Date) {
    updateFormData(InputType.ExpiryDate, value);
  }

  return (
    <VegaDrawer open={open} title={'Allocate'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Submit"
              onClick={onSumbitClick}
              disabled={isInputValid() == false}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent'}>
              <AgentSelector
                selected={formData.agentId}
                onSelected={selected =>
                  updateFormData(InputType.AgentId, selected)
                }
                onClear={() => updateFormData(InputType.AgentId, '')}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              header="Expiry Date"
              value={formData.expiryDate}
              onChange={e => {
                updateExpiryDate(DateUtility.parseDateFromDatePicker(e));
              }}
              minDate={TOMORROW}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default AllocateLoanAccountsDrawer;

const allocationTypeOptions = () =>
  Object.keys(AllocationTypes).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
