import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import {
  EscalationsDto,
  VisitEscalationDto,
} from '../../../types/escalationsType';
import { DateUtility } from '../../../utils/DateUtlility';
import VegaButton from '../../common/VegaButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';
import {
  getColorForLevel,
  getColorForStatus,
} from '../EscalationCommonFunctions';

interface IColumnProps {
  selectedTab: number;
  onReolveDialogOpen: (escalation: EscalationsDto) => void;
}

export const MinVisitsColumn = ({
  selectedTab,
  onReolveDialogOpen,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'userId',
      headerName: 'Agent Name',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const userName = escalations?.userName ? escalations?.userName : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'escalationDate',
      headerName: 'escalationDate',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const escalationDate = escalations.triggeredDate
          ? DateUtility.formatStringToDDMMMMYYYY(escalations.triggeredDate)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalationDate}
          />
        );
      },
    },

    {
      field: 'reason',
      headerName: 'Reason/Remarks',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalations?.escalationReason ?? 'Not filled by agent'}
          />
        );
      },
    },
    {
      field: 'minVisit',
      headerName: 'Min. Visit Required',
      flex: 0.6,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const visitEscalation =
          escalations.escalationObject as VisitEscalationDto;
        const visitRequired = visitEscalation?.visitRequired ?? 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={visitRequired}
          />
        );
      },
    },
    {
      field: 'completed',
      headerName: 'Visit Completed',
      flex: 0.6,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const visitEscalation =
          escalations.escalationObject as VisitEscalationDto;
        const visitCompleted = visitEscalation?.visitCompleted ?? 0;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={visitCompleted}
          />
        );
      },
    },
    {
      field: 'escalationLevel',
      headerName: 'Escalation Level',
      flex: 0.7,
      renderCell: props => {
        const minVisitEscalation = props.row as EscalationsDto;
        return (
          <VegaPill
            text={minVisitEscalation.escalationLevel}
            backgroundColor={
              getColorForLevel(minVisitEscalation.escalationLevel)?.LIGHT
            }
            textColor={
              getColorForLevel(minVisitEscalation.escalationLevel)?.DARK
            }
            borderColor={
              getColorForLevel(minVisitEscalation.escalationLevel)?.DARK
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: props => {
        const minVisitEscalation = props.row as EscalationsDto;
        return selectedTab === 0 ? (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text="resolve"
              onClick={() => {
                onReolveDialogOpen(minVisitEscalation);
              }}
              style={{
                backgroundColor: '#1047DC1A',
                color: PRIMARY.darkBlue,
              }}
            />
          </Stack>
        ) : (
          <VegaPill
            isDot={false}
            text={toLowerCase(minVisitEscalation.escalationStatus)}
            backgroundColor={
              getColorForStatus(minVisitEscalation.escalationStatus)?.LIGHT
            }
            textColor={
              getColorForStatus(minVisitEscalation.escalationStatus)?.DARK
            }
            borderColor={
              getColorForStatus(minVisitEscalation.escalationStatus)?.DARK
            }
          />
        );
      },
    },
  ];
  return COL_DEF;
};
