import { Grid, Skeleton, Stack } from '@mui/material';
import { getIncentivesGamificationsState } from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppSelector } from '../../../store';
import {
  IncentiveWriteOffRule,
  IncentiveWriteOffRulesRequest,
} from '../../../types/request/incentives';
import VegaPageContent from '../../common/VegaPageContent';
import VegaTitleCard from '../../common/VegaTitleCard';
import ViewIncentiveProposedComponent from '../components/WriteOff/ViewIncentiveProposedComponent';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  rules: IncentiveWriteOffRulesRequest;
}

const ViewWriteOffComponent = ({ rules }: IProps) => {
  const { loading } = useAppSelector(getIncentivesGamificationsState);
  return (
    <>
      {rules?.rules?.length ? (
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Incentive Proposed">
            <>
              {rules?.rules.map(
                (rules: IncentiveWriteOffRule, index: number) => (
                  <ViewIncentiveProposedComponent
                    key={index}
                    loading={loading}
                    rules={rules}
                    index={index}
                  />
                )
              )}
            </>
          </VegaTitleCard>
        </VegaPageContent>
      ) : (
        ''
      )}
      {rules?.incentiveInterestDto ? (
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Interest & Charges">
            <Stack
              gap={1}
              sx={{
                borderRadius: '12px',
                p: 2,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                mb: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <VegaFormInputField label={'Interest (Percentage)'}>
                    {getTextLoadingWrapper(
                      rules?.incentiveInterestDto?.incentiveOnInterest,
                      loading
                    )}
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={5}>
                  <VegaFormInputField label={'Max Payout For Interest'}>
                    {getTextLoadingWrapper(
                      rules?.incentiveInterestDto
                        ?.maxPayoutForInterestIncentive,
                      loading
                    )}
                  </VegaFormInputField>
                </Grid>
              </Grid>
            </Stack>
          </VegaTitleCard>
        </VegaPageContent>
      ) : (
        ''
      )}
      {rules?.maxPolicyPayoutAmt ? (
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Max Payout">
            <Stack
              gap={1}
              sx={{
                borderRadius: '12px',
                p: 2,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                mb: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <VegaFormInputField label={'Max Policy Payout Amount'}>
                    {getTextLoadingWrapper(rules?.maxPolicyPayoutAmt, loading)}
                  </VegaFormInputField>
                </Grid>
              </Grid>
            </Stack>
          </VegaTitleCard>
        </VegaPageContent>
      ) : (
        ''
      )}
    </>
  );
};

export default ViewWriteOffComponent;

const getTextLoadingWrapper = (value: string | number, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return (
      <VegaText text={value ?? '--'} fontWeight={500} fontSize={'0.875rem'} />
    );
  }
};
