import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
interface initialState {
  [key: string]: string[];
}

const INITIAL_STATE = {};

const filterColumnSlice = createSlice({
  name: 'filterColumn',
  initialState: INITIAL_STATE,
  reducers: {
    setColumnFilters: (state, action) => {
      const { payload } = action;
      const { key, data } = payload;
      if (!state[key]) {
        state[key] = [];
      }
      state[key] = [...data];
      localStorage.setItem(key, JSON.stringify(state[key]));
      return state;
    },
  },
});
export const { setColumnFilters } = filterColumnSlice.actions;
export const getColumnState = (state: RootState) => state;
export default filterColumnSlice;
