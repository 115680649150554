import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FileUploadIcon } from '../../components/Icons/Icons';
import ListOfPolicies from '../../components/IncentivesGamifications/ListOfPolicies';
import VegaButton from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import {
  DEFAULT_POLICY_DETAILS,
  DEFAULT_RULE,
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
  setSelectedPolicyTab,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { IncentivePolicyType } from '../../types/incentives';
import ListOfTempletes from '../../components/IncentivesGamifications/ListOfTempletes';

export enum IncentivesTabType {
  LIVE = 'LIVE',
  FUTURE = 'FUTURE',
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
  ALL_POLICY = 'ALL',
  TEMPLATES = 'TEMPLATES',
}
export enum IncentivesActionEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  SAVE_TEMPLATE = 'SAVE_TEMPLATE',
  DOWNLOAD = 'DOWNLOAD',
  IN_REVIEW = 'IN_REVIEW',
  VIEW_REJECTED_REASON = 'VIEW_REJECTED_REASON',
  RE_SUBMIT = 'RE_SUBMIT',
  VIEW_RESUBMISSION_REASON = 'VIEW_RESUBMISSION_REASON',
}

const IncentivesGamifications = () => {
  const { selectedPolicyTab } = useAppSelector(getIncentivesGamificationsState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Incentives & Gamifications'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            selectedPolicyTab !== 5 && (
              <Stack direction={'row'} gap={1}>
                <VegaButton
                  text={'New Policy'}
                  startIcon={<FileUploadIcon strokeColor="white" />}
                  onClick={() => {
                    dispatch(setPolicyDetailsState(DEFAULT_POLICY_DETAILS));
                    dispatch(setPolicyRuleState(DEFAULT_RULE));
                    navigate(
                      ROUTES.INCENTIVES_GAMIFICATIONS_ADD.replace(
                        ':policyType',
                        IncentivePolicyType.NEW_POLICY
                      )
                    );
                  }}
                />
                <VegaButton
                  text={'Add on Policy'}
                  variant="outlined"
                  onClick={() => {
                    dispatch(setPolicyDetailsState(DEFAULT_POLICY_DETAILS));
                    dispatch(setPolicyRuleState(DEFAULT_RULE));
                    navigate(
                      ROUTES.INCENTIVES_GAMIFICATIONS_ADD.replace(
                        ':policyType',
                        IncentivePolicyType.ADD_ON_POLICY
                      )
                    );
                  }}
                />
              </Stack>
            )
          );
        }}
      />
      <VegaPageHeader
        sx={{ paddingTop: 0 }}
        isTabPresent
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedPolicyTab}
              onChange={(e, selected) => {
                dispatch(setSelectedPolicyTab(selected));
              }}
            >
              {Object.keys(IncentivesTabType).map((tab: string) => (
                <VegaTabBarItem key={tab} label={`${toLowerCase(tab)}`} />
              ))}{' '}
            </VegaTabBar>
          );
        }}
      />
      {selectedPolicyTab === 5 ? (
        <ListOfTempletes selectedPolicyTab={selectedPolicyTab} />
      ) : (
        <ListOfPolicies selectedPolicyTab={selectedPolicyTab} />
      )}
    </>
  );
};

export default IncentivesGamifications;

export const getHeaderText = (tab: number) => {
  if (tab === 1) {
    return IncentivesTabType.FUTURE;
  } else if (tab === 2) {
    return IncentivesTabType.PENDING;
  } else if (tab === 3) {
    return IncentivesTabType.HISTORY;
  } else if (tab === 4) {
    return IncentivesTabType.ALL_POLICY;
  } else if (tab === 5) {
    return IncentivesTabType.TEMPLATES;
  } else {
    return IncentivesTabType.LIVE;
  }
};
