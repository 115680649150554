import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReferToFieldService } from '../../../Apis/ReferToFieldService';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { TeleAgentManualAccountViewModel } from '../../../types/teleAgent';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import ReferToFieldConfirmationDialog from '../../ReferToField/ReferToFieldConfirmationDialog';
import VegaButton from '../../common/VegaButton';
import VegaPageContent from '../../common/VegaPageContent';
import VegaText from '../../common/VegaText';
import { MetaDataLoanAllocationDto } from '../../../types/loan';

type Props = {
  loanAccountId?: string;
  teleAllocationTotalItems?: number;
  allocationData?: TeleAgentManualAccountViewModel;
  onCreateReceiptClick?: (data?: TeleAgentManualAccountViewModel) => void;
  onViewTrailClick?: (data?: TeleAgentManualAccountViewModel) => void;
};

const TeleAgentAction = ({
  loanAccountId,
  teleAllocationTotalItems,
  allocationData,
  onCreateReceiptClick,
  onViewTrailClick,
}: Props) => {
  const navigate = useNavigate();
  const { user } = useClientAuth();
  const {
    open: openReferToFieldConfirmationDialog,
    close: closeReferToFieldConfirmationDialog,
    isOpen: isReferToFieldConfirmationDialogOpen,
  } = useDrawer(false);

  const { setSnackbar } = useSnackbar();

  async function onAcceptReferToFieldClick(
    allocationData: TeleAgentManualAccountViewModel | MetaDataLoanAllocationDto,
    agentRemark: string
  ) {
    try {
      if (allocationData && agentRemark) {
        await ReferToFieldService.createReferToField({
          teleAllocationId: allocationData.id,
          agentId: allocationData.agentId,
          agentRemark: agentRemark,
          approverId: user?.id,
        });
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  return (
    <VegaPageContent sx={{ pt: '1.5rem' }}>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <VegaText
          fontWeight={700}
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.625rem',
            letterSpacing: '0.07em',
            color: '#676B76',
          }}
          text={'action'}
        />
        {/* <VegaButton variant="outlined" text="Send Payment Link" /> */}
        <VegaButton
          variant="outlined"
          text="Create Receipt"
          onClick={() => {
            if (loanAccountId) {
              onCreateReceiptClick?.(allocationData);
              navigate(
                ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT.replace(
                  ':loanId',
                  loanAccountId
                )
              );
            }
          }}
        />
        <VegaButton
          variant="outlined"
          text="View Trail"
          onClick={() => {
            if (loanAccountId) {
              onViewTrailClick?.(allocationData);
              navigate(
                ROUTES.Trail_Detail_Page.replace(
                  ':loanAccountId',
                  loanAccountId
                )
              );
            }
          }}
        />
        {teleAllocationTotalItems > 0 && (
          <VegaButton
            variant="outlined"
            text="Refer To Field"
            onClick={() => openReferToFieldConfirmationDialog()}
          />
        )}
        <ReferToFieldConfirmationDialog
          onAcceptClick={(allocationData, agentRemark) => {
            onAcceptReferToFieldClick(allocationData, agentRemark);
          }}
          onClose={closeReferToFieldConfirmationDialog}
          show={isReferToFieldConfirmationDialogOpen}
          allocationData={allocationData}
        />
      </Stack>
    </VegaPageContent>
  );
};

export default TeleAgentAction;
