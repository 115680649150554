import React from 'react';
import { Grid, Stack, Box } from '@mui/material';
import VegaPageContent from '../../../components/common/VegaPageContent';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  fetchAgentSummry,
  getTeleCallingMetrics,
  setFilterCallingMetrics,
  setMetricsSummary,
} from '../../../features/teleCallingMetricsSlice';
import { FilterTeleCallingMetrics } from '../../../types/teleCallingMetricsType';
import { BORDER_COLLECTION } from '../../../constants/style';
import AgentSelector from '../../../components/AgentSelector';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaDatePicker from '../../../components/common/VegaDatePicker';
import { DateUtility } from '../../../utils/DateUtlility';
import VegaButton from '../../../components/common/VegaButton';
import { useClientAuth } from '../../../providers/ClientProvider';

const FilterTeleCallingMetricsSection = () => {
  const { filterCallingMetrics } = useAppSelector(getTeleCallingMetrics);
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();

  const handleFilterMetrics = (
    key: keyof FilterTeleCallingMetrics,
    value: string
  ) => {
    if (key === 'startDate') {
      const metricsFilterObj = {
        ...filterCallingMetrics,
        [key]: value,
        endDate: DateUtility.addDays(new Date(value), 1),
      };
      dispatch(setFilterCallingMetrics(metricsFilterObj));
    } else if (key === 'endDate') {
      const metricsFilterObj = {
        ...filterCallingMetrics,
        [key]: value,
        startDate: DateUtility.previousDays(new Date(value), 1),
      };
      dispatch(setFilterCallingMetrics(metricsFilterObj));
    } else {
      const metricsFilterObj = {
        ...filterCallingMetrics,
        [key]: value,
      };
      dispatch(setFilterCallingMetrics(metricsFilterObj));
    }
  };

  const filterMetrics = () => {
    const request = {
      ...filterCallingMetrics,
      endDate: DateUtility.formatStringToYYYYMMDD(filterCallingMetrics.endDate),
      startDate: DateUtility.formatStringToYYYYMMDD(
        filterCallingMetrics.startDate
      ),
    } as FilterTeleCallingMetrics;
    dispatch(
      fetchAgentSummry({
        ...request,
        userId: user?.id,
      })
    );
  };

  return (
    <>
      <VegaPageContent mt={'1rem'}>
        <Stack
          sx={{
            border: BORDER_COLLECTION,
            borderRadius: '0.75rem',
            px: 2,
            py: 1.5,
            minHeight: '101px',
            bgcolor: 'white',
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <VegaFormInputField label={'Agent Name'}>
                <AgentSelector
                  selected={filterCallingMetrics.userId}
                  onSelected={selected =>
                    handleFilterMetrics('userId', selected)
                  }
                  onClear={() => {
                    handleFilterMetrics('userId', '');
                    dispatch(setMetricsSummary(null));
                  }}
                />
              </VegaFormInputField>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <VegaFormInputField label={'start date'}>
                <VegaDatePicker
                  value={filterCallingMetrics.startDate}
                  onChange={e => {
                    const parseDate = DateUtility.parseDateFromDatePicker(e);
                    handleFilterMetrics('startDate', parseDate?.toISOString());
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <VegaFormInputField label={'end date'}>
                <VegaDatePicker
                  minDate={DateUtility.addDays(
                    new Date(filterCallingMetrics.startDate),
                    1
                  )}
                  value={filterCallingMetrics.endDate}
                  onChange={e => {
                    const parseDate = DateUtility.parseDateFromDatePicker(e);
                    handleFilterMetrics('endDate', parseDate?.toISOString());
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
          <Box
            sx={{
              textAlign: 'end',
              mt: '1rem',
            }}
          >
            <VegaButton
              text="Filter By"
              disabled={!filterCallingMetrics.userId.length}
              onClick={filterMetrics}
            />
          </Box>
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default FilterTeleCallingMetricsSection;
