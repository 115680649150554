/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { RolesProps } from '../../../types/roleType';
import { RoleServices } from '../../../Apis/RoleServices';

interface IProps {
  selected: any;
  onSelect: (select: string) => void;
  onDelete: (select: number) => void;
  placeholder?: string;
  disabled?: boolean;
}

const UserTypeSelector = ({
  onSelect,
  selected,
  onDelete,
  placeholder,
  disabled,
}: IProps) => {
  const [users, setUsers] = useState<VegaSelectOption[]>([]);
  const { setSnackbar } = useSnackbar();

  const getUserList = async () => {
    RoleServices.getRoles()
      .then(res => {
        const rolesList = res.length
          ? res?.map(
              (role: RolesProps) =>
                ({
                  label: role.name,
                  value: role.name,
                } as VegaSelectOption)
            )
          : [];
        setUsers(rolesList);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  useEffect(() => {
    getUserList();
  }, []);
  return (
    <VegaSelectWithCheckbox
      options={users}
      onSelect={selected => onSelect(selected)}
      handleDelete={onDelete}
      selected={selected}
      placeHolder={placeholder}
      disabled={disabled}
    />
  );
};

export default UserTypeSelector;
