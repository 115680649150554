import React, { useEffect, useState } from 'react';
import { RoleServices } from '../../../../Apis/RoleServices';
import { RolesProps } from '../../../../types/roleType';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { ConfigurationDto } from '../../../../types/configurationType';

const RoleSelect = () => {
  const { setSnackbar } = useSnackbar();
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const [roles, setRoles] = useState<VegaSelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRoleChange = (key: keyof ConfigurationDto, value: string) => {
    const updateConfig = { ...configurations, [key]: [value] };
    dispatch(setConfigurations(updateConfig));
  };

  const getRolesList = () => {
    setLoading(true);
    RoleServices.getRoles()
      .then(res => {
        const rolesList = res.length
          ? res?.map(
              (role: RolesProps) =>
                ({
                  label: role.name,
                  value: role.name,
                } as VegaSelectOption)
            )
          : [];
        setRoles(rolesList);
        setLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setLoading(false);
      });
  };
  useEffect(() => {
    getRolesList();
  }, []);
  return (
    <VegaFormInputField label={'select Role'}>
      <VegaSelect
        loading={loading}
        options={roles}
        value={configurations.subtypes}
        onChange={selected =>
          handleRoleChange('subtypes', selected.target.value as string)
        }
      />
    </VegaFormInputField>
  );
};

export default RoleSelect;
