import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import { useOts } from '../../../providers/OtsProvider';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<UpdateOtsFormData>) => void;
};

enum FormField {
  SettlementAmount = 'settlementAmount',
  Tenure = 'tenure',
  Principal = 'principal',
  Interest = 'interest',
  Others = 'others',
}

export type UpdateOtsFormData = {
  settlementAmount: number;
  tenure: number;
  wavierAmount: number;
  principal: number;
  interest: number;
  others: number;
};

function EditOtsDrawer({ open, onClose, onSubmit }: Props) {
  const { ots, loan } = useOts();
  const [formData, setFormData] = useState<Partial<UpdateOtsFormData>>({});

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function onSubmitClick() {
    formData.wavierAmount = getWavierAmount();
    onSubmit(formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
  }

  const isInputValid = () => {
    const isTenureValid = (formData.tenure ?? 0) > 0;
    const isAmountValid = (formData.settlementAmount ?? 0) > 0;
    return isTenureValid && isAmountValid;
  };

  const parseNumberValue = (value: any) => {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    return isValidNumber ? parsedNumber : undefined;
  };

  const getWavierAmount = () => {
    return (
      (loan?.lmsVariables?.outStandingTotal ?? 0) -
      (formData.settlementAmount ?? 0)
    );
  };

  useEffect(() => {
    if (!ots) return;
    if (open == false) return;
    setFormData(prev => {
      return {
        ...prev,
        settlementAmount: ots?.settlementAmount,
        tenure: ots?.tenure,
        interest: ots?.interest,
        principal: ots?.principle,
        others: ots?.others,
      };
    });
  }, [ots, open]);

  return (
    <VegaDrawer open={open} title={'Raise OTS Request'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Update OTS'}
              disabled={isInputValid() == false}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                gap: '10px',
                borderRadius: '8px',
                backgroundColor: '#F7F7F7',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems={'space-between'}
              >
                <VegaText text={'Total Outstanding Amount'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.outStandingTotal ?? 0
                  )}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="space-between">
                <VegaText text={'Current Principal Amount'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.outstandingAmount ?? 0
                  )}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="space-between">
                <VegaText text={'Interest Outstanding'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.interestOutStanding ?? 0
                  )}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="space-between">
                <VegaText text={'Other Charges'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.chargeDue ?? 0
                  )}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="space-between">
                <VegaText text={'Emis Pending'} />
                <VegaText text={'-'} />
              </Stack>
            </div>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Settlement Amount'}>
              <LmTextField value={formData.settlementAmount ?? ''} disabled />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Tenure (In Months)'}>
              <LmTextField value={formData.tenure ?? ''} disabled />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Principal'}>
              <LmTextField
                value={formData.principal ?? ''}
                onChange={e => {
                  updateFormData(
                    FormField.Principal,
                    parseNumberValue(e.target.value)
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Interest'}>
              <LmTextField
                value={formData.interest ?? ''}
                onChange={e => {
                  updateFormData(
                    FormField.Interest,
                    parseNumberValue(e.target.value)
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Others'}>
              <LmTextField
                value={formData.others ?? ''}
                onChange={e => {
                  updateFormData(
                    FormField.Others,
                    parseNumberValue(e.target.value)
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                gap: '10px',
                borderRadius: '8px',
                backgroundColor: '#EBF4EC',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems={'space-between'}
              >
                <VegaText text={'Total waiver amount will be'} />
                <VegaText text={StringUtility.formatToINR(getWavierAmount())} />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default EditOtsDrawer;
