import { Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { PerformanceServices } from '../../Apis/PerformanceServices';
import {
  NO_OF_ACCOUNTS_CALLED,
  NO_OF_ACCOUNTS_WITH_NO_ACTION,
  NO_OF_ACCOUNTS_WITH_RECEIPT_ISSUE,
  NO_OF_ALLOCATED_AGENCY,
  NO_OF_AVG_CALL_PER_CUSTOMER,
  NO_OF_AVG_VISIT_PER_CUSTOMER,
  NO_OF_UNALLOCATED_AGENCY,
  NO_OF_VISITS,
  NO__OF_CALLERS,
} from '../../constants/athenaQueryId';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { Params } from '../../types/request/performanceRequest';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { filterKey } from './PerformanceSummary';
import PerformanceCard from './components/PerformanceCard';
import { DateIndicateEnum, DateUtility } from '../../utils/DateUtlility';

type PerformanceType = {
  noOfVisits: string;
  noOfAllocatedAgency: string;
  noOfAccountsCalled: string;
  noOfAvgCallPerCustomer: string;
  noOfCallers: string;
  noOfAvgVisitPerCustomer: string;
  noOfAccountsWithNoAction: string;
  noOfAccountsWithReceiptIssue: string;
  noOfUnAllocatedAgency: string;
};

type PerformanceLoadingType = {
  noOfVisitsLoading: boolean;
  noOfAllocatedAgencyLoading: boolean;
  noOfAccountsCalledLoading: boolean;
  noOfAvgCallPerCustomerLoading: boolean;
  noOfCallersLoading: boolean;
  noOfAvgVisitPerCustomerLoading: boolean;
  noOfAccountsWithNoActionLoading: boolean;
  noOfAccountsWithReceiptIssueLoading: boolean;
  noOfUnAllocatedAgencyLoading: boolean;
};

enum PerformanceDataType {
  NoOfVisits = 'noOfVisits',
  NoOfAllocatedAgency = 'noOfAllocatedAgency',
  NoOfAccountsCalled = 'noOfAccountsCalled',
  NoOfAvgCallPerCustomer = 'noOfAvgCallPerCustomer',
  NoOfCallers = 'noOfCallers',
  NoOfAvgVisitPerCustomer = 'noOfAvgVisitPerCustomer',
  NoOfAccountsWithNoAction = 'noOfAccountsWithNoAction',
  NoOfAccountsWithReceiptIssue = 'noOfAccountsWithReceiptIssue',
  NoOfUnAllocatedAgency = 'noOfUnAllocatedAgency',
}

enum PerformanceDataTypeLoading {
  NoOfVisitsLoading = 'noOfVisitsLoading',
  NoOfAllocatedAgencyLoading = 'noOfAllocatedAgencyLoading',
  NoOfAccountsCalledLoading = 'noOfAccountsCalledLoading',
  NoOfAvgCallPerCustomerLoading = 'noOfAvgCallPerCustomerLoading',
  NoOfCallersLoading = 'noOfCallersLoading',
  NoOfAvgVisitPerCustomerLoading = 'noOfAvgVisitPerCustomerLoading',
  NoOfAccountsWithNoActionLoading = 'noOfAccountsWithNoActionLoading',
  NoOfAccountsWithReceiptIssueLoading = 'noOfAccountsWithReceiptIssueLoading',
  NoOfUnAllocatedAgencyLoading = 'noOfUnAllocatedAgencyLoading',
}

type Props = {
  filterData: Partial<Params>;
  applyFilter: boolean;
  setApplyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilter: boolean;
  setClearFilter: React.Dispatch<React.SetStateAction<boolean>>;
};
const AllPerformanceSummary = ({
  filterData,
  applyFilter,
  setApplyFilter,
  clearFilter,
  setClearFilter,
}: Props) => {
  const { setSnackbar } = useSnackbar();
  const [performanceData, setPerformanceData] = useState<PerformanceType>({
    noOfVisits: '0',
    noOfAllocatedAgency: '0',
    noOfAccountsCalled: '0',
    noOfAvgCallPerCustomer: '0',
    noOfCallers: '0',
    noOfAvgVisitPerCustomer: '0',
    noOfAccountsWithNoAction: '0',
    noOfAccountsWithReceiptIssue: '0',
    noOfUnAllocatedAgency: '0',
  });
  const [performanceDataLoading, setPerformanceDataLoading] =
    useState<PerformanceLoadingType>({
      noOfVisitsLoading: false,
      noOfAllocatedAgencyLoading: false,
      noOfAccountsCalledLoading: false,
      noOfAvgCallPerCustomerLoading: false,
      noOfCallersLoading: false,
      noOfAvgVisitPerCustomerLoading: false,
      noOfAccountsWithNoActionLoading: false,
      noOfAccountsWithReceiptIssueLoading: false,
      noOfUnAllocatedAgencyLoading: false,
    });

  const summaries = [
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfAllocatedAgency
      ),
      text: 'Allocated Agency',
      loading: performanceDataLoading.noOfAllocatedAgencyLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfUnAllocatedAgency
      ),
      text: 'Un-Allocated Agency',
      loading: performanceDataLoading.noOfUnAllocatedAgencyLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfAccountsCalled
      ),
      text: 'Accounts Called',
      loading: performanceDataLoading.noOfAccountsCalledLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfAccountsWithReceiptIssue
      ),
      text: 'Accounts with Receipt Issue',
      loading: performanceDataLoading.noOfAccountsWithReceiptIssueLoading,
    },
    {
      count: calculateDivision(
        performanceData.noOfAccountsWithReceiptIssue,
        performanceData.noOfAllocatedAgency
      ),
      text: 'Efficiency based on No. of Accounts',
      loading: performanceDataLoading.noOfAccountsWithReceiptIssueLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfAccountsWithNoAction
      ),
      text: 'Accounts with No Action',
      loading: performanceDataLoading.noOfAccountsWithNoActionLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfVisits
      ),
      text: 'No. of Visits',
      loading: performanceDataLoading.noOfVisitsLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfAvgVisitPerCustomer,
        3,
        3
      ),
      text: 'Avg Visits per Customer',
      loading: performanceDataLoading.noOfAvgVisitPerCustomerLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfCallers
      ),
      text: 'No. of Callers',
      loading: performanceDataLoading.noOfCallersLoading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        performanceData.noOfAvgCallPerCustomer,
        3,
        3
      ),
      text: 'Avg Call per Customer',
      loading: performanceDataLoading.noOfAvgCallPerCustomerLoading,
    },
  ];

  const filteredParams: Partial<Params> = useMemo(() => {
    const params = { ...filterData };
    const filteredParams = {};

    filterKey.forEach(key => {
      if (params && params[key]?.length) {
        filteredParams[key] = params[key];
      }
    });

    return filteredParams;
  }, [filterData]);

  const getAthenaQuery = async (queryId: string) => {
    try {
      const body = {
        queryId,
        params: {
          ...filteredParams,
          ...(Object.keys(filteredParams).length &&
            filteredParams?.MONTH_AND_YEAR_OF_ALLOCATION && {
              MONTH_AND_YEAR_OF_ALLOCATION: DateUtility.toISOString(
                filteredParams.MONTH_AND_YEAR_OF_ALLOCATION,
                DateIndicateEnum.PLUS
              ),
            }),
          ...(Object.keys(filteredParams).length &&
            filteredParams?.START_DATE && {
              START_DATE: DateUtility.toISOString(
                filteredParams.START_DATE,
                DateIndicateEnum.PLUS
              ),
            }),
          ...(Object.keys(filteredParams).length &&
            filteredParams?.END_DATE && {
              END_DATE: DateUtility.toISOString(
                filteredParams.END_DATE,
                DateIndicateEnum.PLUS
              ),
            }),
        },
      };

      const response = await PerformanceServices.getAthenaQueries(body);
      return response;
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  function updatePerformanceData(type: PerformanceDataType, value: string) {
    setPerformanceData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function updatePerformanceDataLoading(
    type: PerformanceDataTypeLoading,
    value: boolean
  ) {
    setPerformanceDataLoading(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  const fetchData = async () => {
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfVisitsLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfAllocatedAgencyLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfAccountsCalledLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfAvgCallPerCustomerLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfCallersLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfAvgVisitPerCustomerLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfAccountsWithNoActionLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfAccountsWithReceiptIssueLoading,
      true
    );
    updatePerformanceDataLoading(
      PerformanceDataTypeLoading.NoOfUnAllocatedAgencyLoading,
      true
    );
    try {
      const responses = await Promise.all([
        getAthenaQuery(NO_OF_VISITS),
        getAthenaQuery(NO_OF_ALLOCATED_AGENCY),
        getAthenaQuery(NO_OF_UNALLOCATED_AGENCY),
        getAthenaQuery(NO_OF_ACCOUNTS_CALLED),
        getAthenaQuery(NO_OF_ACCOUNTS_WITH_RECEIPT_ISSUE),
        getAthenaQuery(NO_OF_ACCOUNTS_WITH_NO_ACTION),
        getAthenaQuery(NO_OF_AVG_VISIT_PER_CUSTOMER),
        getAthenaQuery(NO__OF_CALLERS),
        getAthenaQuery(NO_OF_AVG_CALL_PER_CUSTOMER),
      ]);

      const [
        noOfVisits,
        allocatedAgency,
        unAllocatedAgency,
        accountsCalled,
        accountsWithReceiptIssue,
        accountsWithNoAction,
        avgVisitPerCustomer,
        noOfCallers,
        avgCallPerCustomer,
      ] = responses;

      updatePerformanceData(
        PerformanceDataType.NoOfVisits,
        noOfVisits[0]?.total_count ?? '0'
      );
      updatePerformanceData(
        PerformanceDataType.NoOfAllocatedAgency,
        allocatedAgency[0]?.total_count ?? '0'
      );
      updatePerformanceData(
        PerformanceDataType.NoOfUnAllocatedAgency,
        unAllocatedAgency[0]?.total_count ?? '0'
      );
      updatePerformanceData(
        PerformanceDataType.NoOfAccountsCalled,
        accountsCalled?.length.toString() ?? '0'
      );
      updatePerformanceData(
        PerformanceDataType.NoOfAccountsWithReceiptIssue,
        accountsWithReceiptIssue[0]?.total_count ?? '0'
      );
      updatePerformanceData(
        PerformanceDataType.NoOfAccountsWithNoAction,
        accountsWithNoAction[0]?.totalSum ?? '0'
      );
      const avgVisitPerCustomerInt =
        avgVisitPerCustomer?.[0]?.average_frequency ?? '0';
      updatePerformanceData(
        PerformanceDataType.NoOfAvgVisitPerCustomer,
        avgVisitPerCustomerInt
      );
      updatePerformanceData(
        PerformanceDataType.NoOfCallers,
        noOfCallers[0]?.total_count ?? '0'
      );
      const avgCallPerCustomerInt = avgCallPerCustomer?.[0]?.total_average ?? 0;
      updatePerformanceData(
        PerformanceDataType.NoOfAvgCallPerCustomer,
        avgCallPerCustomerInt
      );
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfVisitsLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfAllocatedAgencyLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfAccountsCalledLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfAvgCallPerCustomerLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfCallersLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfAvgVisitPerCustomerLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfAccountsWithNoActionLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfAccountsWithReceiptIssueLoading,
        false
      );
      updatePerformanceDataLoading(
        PerformanceDataTypeLoading.NoOfUnAllocatedAgencyLoading,
        false
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (applyFilter) {
      fetchData();
      setApplyFilter(false);
    }
  }, [applyFilter]);

  useEffect(() => {
    if (clearFilter) {
      fetchData();
      setClearFilter(false);
    }
  }, [clearFilter]);

  return (
    <Grid container spacing={2}>
      {summaries.map(summary => (
        <Grid key={summary.text} item xs={6} sm={6} md={3} lg={2.4}>
          <PerformanceCard
            count={summary.count}
            loading={summary.loading}
            text={summary.text}
            bgcolor={'transparant'}
            color={'black'}
            border={'1px solid #E1E4EB'}
            height={'100px'}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AllPerformanceSummary;
function convertFloatToInt(floatNumber: string) {
  if (!floatNumber) {
    return '0';
  }
  const integerPart = parseInt(floatNumber, 10);
  return integerPart.toString();
}
const getMonthFromDate = (dateString: string) => {
  const date = new Date(dateString);
  const monthNumber = date.getMonth() + 1;
  return monthNumber.toString();
};

const getYearFromDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear().toString();
  return year;
};
function calculateDivision(
  numerator: number | string,
  denominator: number | string
): number {
  const parsedNumerator = Number(numerator);
  const parsedDenominator = Number(denominator);

  if (
    isNaN(parsedNumerator) ||
    isNaN(parsedDenominator) ||
    parsedDenominator === 0 ||
    parsedNumerator === 0
  ) {
    return 0;
  }

  return +(parsedNumerator / parsedDenominator).toFixed(2);
}
