import { Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import MOMAccountMovmentGraph from './modules/MOMAccountMovmentGraph';
import MOMTeleCallerGraph from './modules/MOMTeleCallerGraph';
import { BORDER_COLLECTION, COMMON } from '../../../../constants/style';
import VegaText from '../../../../components/common/VegaText';
import MOMCollectionTeamGraph from './modules/MOMCollectionTeamGraph';
import MOMResolutionGraph from './modules/MOMResolutionGraph';
import MOMPenalCollectionGraph from './modules/MOMPenalCollectionGraph';
import RoleBaseSegmentPerformance from '../../components/RoleBaseSegmentPerformance';
import { CelenderTypeEmun } from '../../../../features/analyticsDashboardSlice';

interface IProps {
  selectedCollectionProfitType: string;
}

const Legend = () => {
  return (
    <div
      style={{
        marginTop: '10px',
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '15px',
        }}
      >
        <div
          style={{
            width: '14px',
            height: '14px',
            backgroundColor: '#86B6F6',
            marginRight: '5px',
            borderRadius: '25%',
          }}
        ></div>
        <VegaText text="Current Month" fontSize={12} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '15px',
        }}
      >
        <div
          style={{
            width: '14px',
            height: '14px',
            backgroundColor: '#65B741',
            marginRight: '5px',
            borderRadius: '25%',
          }}
        ></div>
        <VegaText text="Best" fontSize={12} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '15px',
        }}
      >
        <div
          style={{
            width: '14px',
            height: '14px',
            backgroundColor: '#C70039',
            marginRight: '5px',
            borderRadius: '25%',
          }}
        ></div>
        <VegaText text="Worst" fontSize={12} />
      </div>
      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '14px',
            height: '14px',
            backgroundColor: '#dedede',
            marginRight: '5px',
            borderRadius: '25%',
          }}
        ></div>
        <VegaText text="Default" fontSize={12} />
      </div> */}
    </div>
  );
};

const MonthOnMonthContainer = ({ selectedCollectionProfitType }: IProps) => {
  const collectionTeamGraph = useMemo(() => {
    return (
      <MOMCollectionTeamGraph
        selectedCollectionProfitType={selectedCollectionProfitType}
      />
    );
  }, [selectedCollectionProfitType]);

  const teleCallerGraph = useMemo(() => {
    return (
      <MOMTeleCallerGraph
        selectedCollectionProfitType={selectedCollectionProfitType}
      />
    );
  }, [selectedCollectionProfitType]);

  const resolutionGraph = useMemo(() => {
    return (
      <MOMResolutionGraph
        selectedCollectionProfitType={selectedCollectionProfitType}
      />
    );
  }, [selectedCollectionProfitType]);

  const accountMovmentForMom = useMemo(() => {
    return (
      <MOMAccountMovmentGraph
        selectedCollectionProfitType={selectedCollectionProfitType}
      />
    );
  }, [selectedCollectionProfitType]);

  const penalCollectionForMom = useMemo(() => {
    return <MOMPenalCollectionGraph />;
  }, []);
  const roleBasePerformanceMom = useMemo(() => {
    return (
      <RoleBaseSegmentPerformance
        selectedCollectionProfitType={selectedCollectionProfitType}
        calenderType={CelenderTypeEmun.MONTH}
      />
    );
  }, [selectedCollectionProfitType]);
  return (
    <>
      <Stack
        sx={{
          p: 3,
          bgcolor: 'white',
          borderRadius: '12px',
          border: BORDER_COLLECTION,
        }}
        gap={2}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <VegaText
            text={'Month on month'}
            fontSize={17}
            fontWeight={600}
            color={COMMON.balck}
          />
          <Legend />
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {resolutionGraph}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {accountMovmentForMom}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {penalCollectionForMom}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {collectionTeamGraph}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {teleCallerGraph}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {roleBasePerformanceMom}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default MonthOnMonthContainer;
