/* eslint-disable no-empty */
import { useEffect, useState } from 'react';

import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';
import VegaAutoComplete from '../../../components/common/VegaAutoComplete';

interface IProps {
  selected: string | string[];
  handleChange: (value: string) => void;
  onClear?: () => void;
  handleDelete?: (index: number) => void;
  label?: string;
  searchValue?: string;
  disabled?: boolean;
  isMultiSelect?: boolean;
}

const AllBranchSelector = ({
  selected,
  handleChange,
  handleDelete,
  onClear,
  isMultiSelect = false,
  label,
  disabled,
}: IProps) => {
  const [branchList, setBranchList] = useState<VegaSelectOption[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const getAreaBranchList = async () => {
    setLoading(true);
    try {
      const response = await MinVisitsSevices.getAllBranchList();
      const filterdBranch = response.filter(
        (branch: string) => branch !== null
      );
      const branchListOption = await filterdBranch.map((area: string) => {
        return {
          value: area,
          label: area,
        };
      });
      setBranchList(branchListOption);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filterBranch = () => {
    if (searchValue.length) {
      const searchLowerCase = searchValue.toLowerCase();
      const filteredResults = branchList.filter(agent => {
        if (searchValue === '') {
          return agent;
        } else if (agent.label.toLowerCase().includes(searchLowerCase)) {
          return agent;
        }
      });
      return filteredResults;
    } else {
      return branchList;
    }
  };

  useEffect(() => {
    getAreaBranchList();
  }, []);
  return (
    <>
      {!isMultiSelect ? (
        onClear ? (
          <VegaAutoComplete
            options={filterBranch()}
            searchValue={searchValue}
            handleChange={e => {
              setSearchValue(e.target.value);
            }}
            onClear={onClear}
            onSelect={selected => handleChange(selected)}
            selected={selected}
            placeHolder="Select Branch"
          />
        ) : (
          <VegaSelect
            ignoreLodash
            placeholder={label ?? 'Select Branch'}
            options={branchList}
            onChange={e => handleChange(e.target.value)}
            value={selected}
            disabled={disabled}
            loading={loading}
          />
        )
      ) : (
        <VegaSelectWithCheckbox
          options={filterBranch()}
          onSelect={selected => {
            handleChange(selected);
          }}
          handleDelete={handleDelete}
          handleChange={e => setSearchValue(e.target.value)}
          searchValue={searchValue}
          selected={selected as string[]}
          disabled={disabled}
          loading={loading}
        />
      )}
    </>
  );
};

export default AllBranchSelector;
