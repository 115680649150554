import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { getLeaderboardIncentiveState } from '../../../features/IncentivesGamificationsSlice/incentiveLeaderboardSlice';
import { leaderboardIncentivesList } from '../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { LeaderFilterProps } from '../../../types/incentiveLeaderType';
import { FetchLeaderboardRequestDto } from '../../../types/request/incentives';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { LeaderColumn } from './columns/LeaderColumn';
import FilterLeaderForm from './modules/FilterLeaderForm';

const LeaderBoard = () => {
  const { showLeaders, leaderData, loading, totalItems, leaderFilterState } =
    useAppSelector(getLeaderboardIncentiveState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onApply = useCallback(
    (leaderFilterState: LeaderFilterProps) => {
      const request = {
        ...leaderFilterState,
        page,
        size,
      } as FetchLeaderboardRequestDto;
      dispatch(leaderboardIncentivesList(request));
    },
    [page, size]
  );

  const onPublish = async () => {
    setIsLoading(true);
    try {
      await IncentiveServices.publishLeaderboard(leaderFilterState.agentType);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const listOfLeader = useMemo(() => {
    return (
      <>
        {showLeaders && (
          <VegaDataGrid
            data={leaderData}
            loading={loading}
            idColumn="index"
            columns={LeaderColumn()}
            rowCount={totalItems}
            page={page}
            pageSize={size}
            paginationMode="server"
            onPageChange={page => setPage(page)}
            onPageSizeChange={size => setSize(size)}
          />
        )}
      </>
    );
  }, [page, size, showLeaders]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Leaderboard'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />
      <VegaPageContent
        sx={{
          height: 'calc(100% - 78px)',
          overflow: 'auto',
        }}
        py={'1rem'}
      >
        <Stack gap={2}>
          <Stack
            direction={'row'}
            justifyContent={'end'}
            gap={1}
            sx={{
              p: 1,
              borderRadius: '12px',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            }}
          >
            <VegaButton
              text="Publish Leaderboardss"
              loading={isLoading}
              onClick={onPublish}
            />
          </Stack>
          <FilterLeaderForm onApply={onApply} />
          {listOfLeader}
        </Stack>
      </VegaPageContent>
    </div>
  );
};

export default LeaderBoard;
