import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { AnimatedInputWrapper } from '../../../components/ExpenseClaim/CreateClaimForm';
import { useOts } from '../../../providers/OtsProvider';
import OtsAccountDetails from './components/OtsAccountDetails';
import OtsDeliquencyDetails from './components/OtsDeliquencyDetails';
import OtsLoanApproverDetails from './components/OtsLoanApproverDetails';
import OtsTechnicalDetails from './components/OtsTechnicalDetails';
import OtsOutstandingDetails from './components/OtsOutstandingDetails';
import OtsOperationDetails from './components/OtsOperationDetails';
import OtsWavierDetails from './components/OtsWavierDetails';
import { AgendaCaseStatus, OtsStatus, TimeUnit } from '../../../types/ots';
import { useClientAuth } from '../../../providers/ClientProvider';
import OtsPostCommitteActionView from './components/OtsPostCommitteActionView';
import OtsCustomerAckLetter from './components/OtsCustomerAckLetter';
import OtsNpvDetails from './components/OtsNpvDetails';
import { useDrawer } from '../../../hooks/useDrawer';
import EditOtsDrawer, { UpdateOtsFormData } from '../modules/EditOtsDrawer';
import { OtsService } from '../../../Apis/OtsService';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import OtsCommitteeDetails from './components/OtsCommitteeDetails';
import OtsCollectionsHierarchy from './components/OtsCollectionsHierarchy';

function AgendaCaseDetailsPage() {
  const { fetchAgendaCase, agendaCase, ots, agenda, fetchOts, loan } = useOts();
  const { setSnackbar } = useSnackbar();
  const { caseId } = useParams();
  const { user } = useClientAuth();
  const {
    open: openEditDrawer,
    close: closeEditDrawer,
    isOpen: isEditDrawerOpen,
  } = useDrawer();

  const isPendingChairApproval = () => {
    const isOtsInPreChairApprovalState =
      ots?.status == OtsStatus.PRE_CHAIR_APPROVAL;
    if (isOtsInPreChairApprovalState == false) {
      return false;
    }
    const isPendingChairApproval =
      agendaCase?.otsChairDecisionStatus ==
      AgendaCaseStatus.PENDING_CHAIR_APPROVAL;
    const isRejectedByChair =
      agendaCase?.otsChairDecisionStatus == AgendaCaseStatus.REJECTED;
    const isApprovedByChair =
      agendaCase?.otsChairDecisionStatus == AgendaCaseStatus.APPROVED;
    if (isPendingChairApproval || isRejectedByChair || isApprovedByChair) {
      // return agenda?.chairPerson == user?.id;
      return true;
    }
    return false;
  };

  const canShowActionView = () => {
    if (isPendingChairApproval()) return true;
    return false;
  };
  const canShowAckLetterData = () => {
    return ots?.ackLetter != null;
  };

  async function updateOts(formData: Partial<UpdateOtsFormData>) {
    try {
      const loanId = loan.loanId;
      await OtsService.updateOtsRequest({
        id: ots.id,
        loanId: loanId,
        userId: user?.id,
        settlementAmount: formData.settlementAmount,
        tenure: formData.tenure,
        wavierAmount: formData.wavierAmount,
        timeUnit: TimeUnit.MONTH,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  useEffect(() => {
    if (!caseId) return;
    fetchAgendaCase({ id: caseId, fetchAgenda: true, fetchOts: true });
  }, [caseId]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[{ label: 'OTS', link: -1 }, { label: 'View Details' }]}
            />
          );
        }}
      />
      <VegaPageContent>
        <Box
          sx={{
            p: '1rem',
            bgcolor: 'white',
            borderRadius: '0.75rem',
            border: '1px solid #E1E4EB',
            my: '1rem',
          }}
        >
          <Stack gap={2}>
            <OtsAccountDetails />
            <OtsOutstandingDetails />
            <OtsWavierDetails
              canEdit={canShowActionView()}
              onEditClick={function (): void {
                openEditDrawer();
              }}
            />
            <OtsDeliquencyDetails />
            <OtsLoanApproverDetails />
            <OtsCollectionsHierarchy />
            <OtsOperationDetails />
            <OtsTechnicalDetails />
            <OtsNpvDetails />
            <OtsCommitteeDetails />
            <AnimatedInputWrapper
              show={canShowAckLetterData()}
              marginTop={'.5rem'}
            >
              <OtsCustomerAckLetter />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={canShowActionView()}
              marginTop={'.5rem'}
            >
              <OtsPostCommitteActionView />
            </AnimatedInputWrapper>
          </Stack>
        </Box>
        {/* <OtsAuditView /> */}
        <EditOtsDrawer
          open={isEditDrawerOpen}
          onClose={function (): void {
            closeEditDrawer();
          }}
          onSubmit={updateOts}
        />
      </VegaPageContent>
    </div>
  );
}

export default AgendaCaseDetailsPage;
