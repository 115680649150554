/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { UserService } from '../Apis/UserService';
import { useClientAuth } from '../providers/ClientProvider';
import { VegaUser } from '../types/claim';
import { StringUtility } from '../utils/StringUtility';
import VegaAutoComplete from './common/VegaAutoComplete';
import { VegaSelectOption } from './common/VegaSelect';

interface IProps {
  selected: any;
  onSelected: (select: string) => void;
  onClear?: () => void;
  ignoreLodash?: boolean;
  ignoreAgentCode?: boolean;
}

const AgentSelector = ({
  onSelected,
  selected,
  onClear,
  ignoreLodash = true,
  ignoreAgentCode = false,
}: IProps) => {
  const { user } = useClientAuth();
  const [searchValue, setSearchValue] = useState<string>('');
  const [agentList, setAgentList] = useState<VegaSelectOption[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== agentList.length) {
        setPage(page + 1);
      }
    }
  };

  const getAgentList = async () => {
    try {
      const userId = user?.id;
      if (!userId) return;
      // const response = await UserService.getAgentsForManager(userId);
      const response = await UserService.getUsers({
        managerId: userId,
        page,
        size: 10,
        ...(searchValue.length && { name: searchValue }),
      });
      if (response && response.records?.length) {
        const agentOptionList = response.records.map((agent: VegaUser) => {
          const agentName = ignoreAgentCode
            ? StringUtility.concatenateStrings(
                ' ',
                agent?.firstName,
                agent?.middleName,
                agent?.lastName
              )
            : `${StringUtility.concatenateStrings(
                ' ',
                agent?.firstName,
                agent?.middleName,
                agent?.lastName
              )} (${agent.employeeCode})`;
          return {
            value: agent.id,
            label: agentName,
          };
        });
        if (page === 0) {
          setAgentList(agentOptionList);
        } else {
          setAgentList([...agentList, ...agentOptionList]);
        }
        setTotalItem(response.totalItems);
      }
    } catch (error) {}
  };

  const handleSelect = (select: string) => {
    onSelected(select);
  };
  useEffect(() => {
    if (user) {
      const getList = setTimeout(() => {
        getAgentList();
      }, 400);
      return () => clearTimeout(getList);
    }
  }, [user, page, searchValue]);

  return (
    <VegaAutoComplete
      options={agentList}
      searchValue={searchValue}
      handleChange={e => {
        setSearchValue(e.target.value);
        setPage(0);
      }}
      onScroll={loadMoreItems}
      onClear={onClear}
      onSelect={selected => handleSelect(selected)}
      selected={selected}
      placeHolder="Select Agent"
      ignoreLodash={ignoreLodash}
    />
  );
};

export default AgentSelector;
