import { SkipNextOutlined, SkipPreviousOutlined } from '@mui/icons-material';
import { Grid, IconButton, Skeleton, Stack } from '@mui/material';
import { cleanup } from '@testing-library/react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from '../features/authSlice';
import { useNotification } from '../providers/NotificationProvider';
import { ROUTES, SUB_ROUTES } from '../router/routes';
import { useAppSelector } from '../store';
import { NotificationType, VegaNotification } from '../types/notification';
import { getValueFromRecord } from '../types/ots';
import { DateUtility } from '../utils/DateUtlility';
import VegaDrawer from './common/VegaDrawer';
import VegaDrawerContent from './common/VegaDrawerContent';
import VegaText from './common/VegaText';
import { RefreshIcon } from './Icons/Icons';

type Props = {
  open: boolean;
  onClose: () => void;
};

function NotificationDrawer({ open, onClose }: Props) {
  const { routesSet } = useAppSelector(getAuth);
  const {
    notifications,
    loading,
    updateNotificationStatus,
    fetchNotifications,
  } = useNotification();
  const navigate = useNavigate();

  function handleClose() {
    onClose();
    cleanup();
  }

  const getNotificationType = (notification: VegaNotification) => {
    const metaData = notification.metadata;
    const type = getValueFromRecord<string>(metaData, 'type');
    return type;
  };

  function onClick(notification: VegaNotification) {
    const type = getNotificationType(notification);
    const metaData = notification.metadata;
    if (!type) return;
    updateNotificationStatus(notification);
    handleClose();
    navigateToRouteForUser(type, metaData);
  }

  function navigateToRouteForUser(type: string, metaData: Record<string, any>) {
    let route;
    const otsId = getValueFromRecord<string>(metaData, 'OTS_ID');
    switch (type) {
      case NotificationType.EXPENSES:
        route = routeSetContainsAnyRoute({
          adminRoute: SUB_ROUTES.EXPENSE_REPORT_ONGOING,
          agentRoute: SUB_ROUTES.EXPENSE_REPORT_DRAFT,
        });
        break;
      case NotificationType.LEAVES:
        route = routeSetContainsAnyRoute({
          adminRoute: ROUTES.LEAVE_REQUEST_ACTIONS,
          agentRoute: ROUTES.LEAVE_APPLY,
        });
        break;
      case NotificationType.MY_ACCOUNTS:
        route = routeSetContainsAnyRoute({
          adminRoute: ROUTES.ACCOUNT,
          agentRoute: ROUTES.ACCOUNTS,
        });
        break;
      case NotificationType.NON_GEO_VERIFICATION_REQUEST:
        route = routeSetContainsAnyRoute({
          adminRoute: ROUTES.GEO_VERIFICATION,
          agentRoute: ROUTES.GEO_VERIFICATION,
        });
        break;
      case NotificationType.TARGETS:
        route = routeSetContainsAnyRoute({
          adminRoute: ROUTES.TARGETS_ADMIN,
          agentRoute: ROUTES.TARGETS,
        });
        break;
      case NotificationType.OTS:
        route = ROUTES.OTS_DETAILS.replace(':otsId', otsId);
        break;
      case NotificationType.ESCALATIONS:
        route = ROUTES.ESCALATIONS;
        break;
      case NotificationType.MIN_VISIT_TRACKER:
        route = ROUTES.LOCATION_TRACKING;
        break;
    }
    if (!route) return;
    navigate(route);
  }

  const routeSetContainsAnyRoute = (data: {
    adminRoute: string;
    agentRoute: string;
  }) => {
    if (routesSet.has(data.adminRoute)) {
      return data.adminRoute;
    } else if (routesSet.has(data.agentRoute)) {
      return data.agentRoute;
    }
  };

  function onPrevClick() {
    const currentPage = notifications.pageNumber ?? 0;
    if (currentPage <= 0) return;
    fetchNotifications(currentPage - 1);
  }

  function onNextClick() {
    const currentPage = notifications.pageNumber ?? 0;
    if (currentPage >= notifications.totalPages) return;
    fetchNotifications(currentPage + 1);
  }

  return (
    <VegaDrawer
      open={open}
      title={'Notification'}
      onClose={handleClose}
      renderRightActions={() => {
        return (
          <Stack>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              onClick={() => {
                fetchNotifications(0);
              }}
              sx={{
                color: '#222222',
              }}
            >
              <RefreshIcon strokeColor="black" />
            </IconButton>
          </Stack>
        );
      }}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'}>
              <IconButton onClick={onPrevClick}>
                <SkipPreviousOutlined />
              </IconButton>
              <IconButton onClick={onNextClick}>
                <SkipNextOutlined />
              </IconButton>
            </Stack>
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          {notifications?.records?.length == 0 && loading == false && (
            <Grid item xs={12}>
              <NoNotification />
            </Grid>
          )}

          {notifications?.records?.map(i => {
            return (
              <Grid item xs={12} key={i.id}>
                <Stack
                  sx={{
                    borderRadius: '12px',
                    cursor: 'pointer',
                    p: 2,
                    border: '0.5px solid rgba(100, 100, 111, 0.2)',
                  }}
                  onClick={() => {
                    onClick(i);
                  }}
                >
                  <Stack direction={'row'} justifyContent="space-between">
                    <VegaText
                      text={'Notification'}
                      style={{
                        fontWeight: 700,
                      }}
                    ></VegaText>
                    <VegaText
                      text={DateUtility.formatStringToDDMMYYYYWithTime(
                        i.createdAt
                      )}
                    ></VegaText>
                  </Stack>
                  <VegaText text={i.message}></VegaText>
                </Stack>
              </Grid>
            );
          })}
          {loading == true &&
            new Array(3).fill(0).map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton height={80} />
              </Grid>
            ))}
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default NotificationDrawer;

const NoNotification = () => {
  return (
    <Grid item xs={12}>
      <Stack
        sx={{
          borderRadius: '12px',
          p: 2,
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        }}
      >
        <VegaText text={'No Notification'}></VegaText>
      </Stack>
    </Grid>
  );
};
