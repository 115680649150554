import { useEffect, useState } from 'react';
import { TeleAgentService } from '../../Apis/TeleAgentServices';
import ListOfTeleAgentManualAccount from '../../components/TeleAgent/TeleAgentManual/ListOfTeleAgentManualAccount';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useClientAuth } from '../../providers/ClientProvider';
import { TeleAgentListProps } from '../../types/teleAgent';

const TeleAgentManual = () => {
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [agentDetails, setAgentDetails] =
    useState<Partial<TeleAgentListProps | null>>(null);

  const getTeleAgentByuserId = async () => {
    try {
      const res = await TeleAgentService.getTeleAgentList({
        userId: user.id,
      });
      const agent = res.records[0];
      setAgentDetails(agent);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    user && getTeleAgentByuserId();
  }, [user]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Manual Call'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />

      <ListOfTeleAgentManualAccount agentDetails={agentDetails} />
    </>
  );
};

export default TeleAgentManual;
