import { createAsyncThunk } from '@reduxjs/toolkit';
import { AllocationService } from '../Apis/AllocationService';
import { ReallocateAllocationFormData } from '../components/Allocation/ReallocateAllocationsDrawer';

import { AllocationStatusViewModel } from '../types/allocations';
import {
  BulkAllocateRequest,
  DownloadBulkAllocateFileRequest,
  ExecuteCriteriaRequest,
  GetAllocationMetaDataRequest,
  GetAllocationsListRequest,
  GetAutoAllocationCriteriaListRequest,
  GetAutoAllocationExecutionListRequest,
  GetBulkAllocationListMetaRequest,
  GetBulkDeAllocationListMetaRequest,
} from '../types/request/allocation';
import { getErrorMessageFromErrorObj } from '../utils/api';

// export class AllocationActions {

// }

export const fetchAllocations = createAsyncThunk(
  'allocations/fetch',
  async (request: Partial<GetAllocationsListRequest>) => {
    const response = await AllocationService.getAllocations(request);
    return response;
  }
);

export const fetchAllocationsMetaData = createAsyncThunk(
  'allocations/fetchAllocationsMetaData',
  async (request: Partial<GetAllocationMetaDataRequest>) => {
    const response = await AllocationService.getAllocationMetaData(request);
    return response;
  }
);

export const reallocateAllocations = createAsyncThunk(
  'allocations/reallocate',
  async (request: {
    loanIds: string[];
    formData: Partial<ReallocateAllocationFormData>;
  }) => {
    const response = await AllocationService.reallocate({
      loanIds: request.loanIds,
      agentId: request.formData.agentId,
      expiresAt: request.formData.expiryDate,
      allocationType: request.formData.allocationType,
    });
    return response;
  }
);

export const fetchAllocationStatus = createAsyncThunk(
  'allocations/statusCount',
  async () => {
    const response: AllocationStatusViewModel = {
      UNALLOCATED: 10,
      ALLOCATED: 220,
      NORMALIZED: 0,
      STABILIZED: 0,
      ROLLBACK: 0,
      CANCELLED: 0,
    };
    return response;
  }
);

export const fetchAllocationRules = createAsyncThunk(
  'allocations/fetchRules',
  async (request: Partial<GetAutoAllocationCriteriaListRequest>) => {
    const response = await AllocationService.getAutoAllocationCriteria(request);
    return response;
  }
);
export const fetchCriteriaByCriteriaId = createAsyncThunk(
  'allocations/fetchCriteriaByCriteriaId',
  async (criteriaId: string) => {
    const response =
      await AllocationService.getAutoAllocationCriteriaByCriteriaId(criteriaId);
    return response;
  }
);
export const fetchExecutionAllocation = createAsyncThunk(
  'allocations/fetchExecution',
  async (request: Partial<GetAutoAllocationExecutionListRequest>) => {
    const response = await AllocationService.getAutoAllocationExecution(
      request
    );
    return response;
  }
);

export const executeCriteria = createAsyncThunk(
  'allocations/executeCriteria',
  async (request: Partial<ExecuteCriteriaRequest>) => {
    const response = await AllocationService.executeCriteria(request);
    return response;
  }
);

export const fetchCriteriaAttributes = createAsyncThunk(
  'allocations/fetchAttributes',
  async (_, { rejectWithValue }) => {
    try {
      return await AllocationService.getAutoAllocationAttributes();
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);

export const fetchBulkAllocations = createAsyncThunk(
  'allocations/bulkAllocations',
  async (
    request: Partial<GetBulkAllocationListMetaRequest>,
    { rejectWithValue }
  ) => {
    try {
      const response = await AllocationService.getBulkAllocationListMeta(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);

export const bulkAllocate = createAsyncThunk(
  'allocations/bulkAllocate',
  async (request: Partial<BulkAllocateRequest>, { rejectWithValue }) => {
    try {
      const response = await AllocationService.bulkAllocate(request);
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);

// export const downloadBulkDeallocationSampleFile = createAsyncThunk(
//   'allocations/downloadBulkDeallocationSample',
//   async () => {
//     const response =
//       await AllocationService.downloadBulkDeallocationSampleFile();
//     return response;
//   }
// );

export const fetchBulkDeAllocations = createAsyncThunk(
  'allocations/bulkDeAllocations',
  async (
    request: Partial<GetBulkDeAllocationListMetaRequest>,
    { rejectWithValue }
  ) => {
    try {
      const response = await AllocationService.getBulkDeAllocationListMeta(
        request
      );
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);

export const bulkDeAllocate = createAsyncThunk(
  'allocations/bulkDeAllocate',
  async (request: Partial<BulkAllocateRequest>, { rejectWithValue }) => {
    try {
      const response = await AllocationService.bulkDeAllocate(request);
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);
export const downloadBulkErrorFile = createAsyncThunk(
  'allocations/downloadBulkErrorFile',
  async (request: Partial<DownloadBulkAllocateFileRequest>) => {
    const response = await AllocationService.downloadBulkErrorFile(request);
    return response;
  }
);
