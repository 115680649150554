import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import VegaText from '../../../../components/common/VegaText';
import { SegmentCollectionVolumeResultDto } from '../../../../types/analytics';

const RoleBaseForChannelPerformanceColumn = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'rank',
      headerName: 'Rank',
      flex: 0.3,
      minWidth: 150,
      renderCell: (props: GridCellParams<SegmentCollectionVolumeResultDto>) => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const rank = response?.rank ? response.rank : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={rank}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const managerName = response?.name ? response.name : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={managerName}
          />
        );
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const region = response?.region ? response.region : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={region}
          />
        );
      },
    },

    {
      field: 'receipts',
      headerName: 'Receipts',
      flex: 0.7,
      minWidth: 150,

      renderCell: props => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const receipts = response?.receipts
          ? response.receipts?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={receipts}
          />
        );
      },
    },

    {
      field: 'target',
      headerName: 'Target',
      flex: 0.7,
      minWidth: 150,

      renderCell: props => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const target = response?.target ? response.target?.toFixed(2) : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={target}
          />
        );
      },
    },

    {
      field: 'focusBuckets',
      headerName: 'Focus Bucket',
      flex: 1,
      minWidth: 300,
      renderCell: props => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const focusBuckets = response?.focusBuckets
          ? response.focusBuckets
          : 'NA';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={focusBuckets}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={focusBuckets}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'percentage',
      headerName: '% Change',
      flex: 0.7,
      minWidth: 150,

      renderCell: props => {
        const response = props.row as SegmentCollectionVolumeResultDto;
        const percentage = response?.percentageChange
          ? response?.percentageChange?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={percentage}
          />
        );
      },
    },
  ];
  return COL_DEF;
};

export default RoleBaseForChannelPerformanceColumn;
