import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../common/VegaText';
import { DateUtility } from '../../../utils/DateUtlility';
import { VegaPill } from '../../common/VegaPill';
import { Stack } from '@mui/material';
import VegaButton from '../../common/VegaButton';
import { CheckBrokerIcon, CrossIcon } from '../../Icons/Icons';
import { COLOR } from '../../../utils/ColorUtility';
import {
  EscalationsDto,
  LeaveManagementEscalations,
} from '../../../types/escalationsType';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getColorForLevel,
  getColorForStatus,
} from '../EscalationCommonFunctions';

interface IColumnProps {
  selectedTab: number;
  onApproveDialogOpen?: (escalations: EscalationsDto) => void;
  onRejectDialogOpen?: (escalations: EscalationsDto) => void;
}

export const LeaveRequestColumn = ({
  selectedTab,
  onApproveDialogOpen,
  onRejectDialogOpen,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'userName',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const userName = escalations?.userName ? escalations?.userName : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'triggeredDate',
      headerName: 'Start Date',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as LeaveManagementEscalations;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMMMYYYY(escalations.startDate)}
          />
        );
      },
    },
    {
      field: 'expiryTime',
      headerName: 'End Date',
      flex: 0.5,
      renderCell: props => {
        const escalations = props.row as LeaveManagementEscalations;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMMMYYYY(escalations.endDate)}
          />
        );
      },
    },

    {
      field: 'reason',
      headerName: 'Reason/Remarks',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalations.escalationReason ?? 'Not filled by agent'}
          />
        );
      },
    },
    {
      field: 'escalationLevel',
      headerName: 'Escalation Level',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return (
          <VegaPill
            text={escalations.escalationLevel}
            backgroundColor={
              getColorForLevel(escalations.escalationLevel)?.LIGHT
            }
            textColor={getColorForLevel(escalations.escalationLevel)?.DARK}
            borderColor={getColorForLevel(escalations.escalationLevel)?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        return selectedTab === 0 ? (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              startIcon={<CheckBrokerIcon strokeColor="#318439" />}
              text="Approve"
              onClick={() => {
                onApproveDialogOpen(escalations);
              }}
              sx={{
                backgroundColor: COLOR.GREEN.LIGHT,
                color: COLOR.GREEN.DARK,
                ':hover': {
                  backgroundColor: COLOR.GREEN.LIGHT,
                  color: COLOR.GREEN.DARK,
                },
              }}
            />
            <VegaButton
              startIcon={<CrossIcon />}
              text="Reject"
              onClick={() => {
                onRejectDialogOpen(escalations);
              }}
              sx={{
                backgroundColor: COLOR.RED.LIGHT,
                color: COLOR.RED.DARK,
                ':hover': {
                  backgroundColor: COLOR.RED.LIGHT,
                  color: COLOR.RED.DARK,
                },
              }}
            />
          </Stack>
        ) : (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaPill
              isDot={false}
              text={toLowerCase(escalations.escalationStatus)}
              backgroundColor={
                getColorForStatus(escalations.escalationStatus)?.LIGHT
              }
              textColor={getColorForStatus(escalations.escalationStatus)?.DARK}
              borderColor={
                getColorForStatus(escalations.escalationStatus)?.DARK
              }
            />
            <VegaButton variant="text" text="View Details" />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
