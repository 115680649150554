import { GridColumns } from '@mui/x-data-grid';

const BucketWiseIncentiveColumn = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.5,
      minWidth: 80,
    },
    {
      field: 'eligibleAmount',
      headerName: 'Total Eligible Amount',
      flex: 1,
      minWidth: 80,
    },

    {
      field: 'achievedAmount',
      headerName: 'Total Achieved Earned',
      flex: 1,
      minWidth: 80,
    },
  ];
  return COL_DEF;
};

export default BucketWiseIncentiveColumn;
