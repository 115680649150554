import { Box } from '@mui/material';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../Apis/IncentivesService';
import PolicyDetails from '../../components/IncentivesGamifications/components/PolicyDetails';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import {
  DEFAULT_RULE,
  DEFAULT_WRITE_OFF_RULE,
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
  setSelectedTab,
  setTemplatesRules,
  setWriteOffruleState,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import {
  listOfTempletes,
  policyEnums,
} from '../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  IncentivePolicyProps,
  IncentivePolicyType,
  PayoutConfiguration,
} from '../../types/incentives';
import { IncentiveWriteOffRulesRequest } from '../../types/request/incentives';
import { BucketEnum, ClassTypeEnum, WriteOffEnum } from '../../types/targets';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LoadingPage from '../LoadingPage/LoadingPage';
import BucketRule from './BucketRule';
import WriteOffRule from './WriteOffRule';

const AddOnPolicy = () => {
  const scrollToTopRef: RefObject<HTMLDivElement> = useRef(null);
  const { policyDetailsState, errorPolicy } = useAppSelector(
    getIncentivesGamificationsState
  );
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { policyId, policyType } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const filterBucketRule = (
    rules: Partial<PayoutConfiguration>,
    bucket: string
  ) => {
    if (Object.keys(rules).length !== 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (rules.hasOwnProperty(bucket)) {
        dispatch(setPolicyRuleState(rules[bucket]));
      } else {
        dispatch(setPolicyRuleState(DEFAULT_RULE));
      }
    } else {
      dispatch(setPolicyRuleState(DEFAULT_RULE));
    }
  };
  const filteredWriteOffRule = (
    rules: Partial<IncentiveWriteOffRulesRequest>,
    bucket: string
  ) => {
    // console.log('writeOffruleState', rules[bucket]);
    if (Object.keys(rules).length !== 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (rules.hasOwnProperty(bucket)) {
        dispatch(setWriteOffruleState(rules[bucket]));
      } else {
        dispatch(
          setWriteOffruleState({
            ...DEFAULT_WRITE_OFF_RULE,
            writeOffIncentiveType: bucket,
          } as IncentiveWriteOffRulesRequest)
        );
      }
    } else {
      dispatch(
        setWriteOffruleState({
          ...DEFAULT_WRITE_OFF_RULE,
          writeOffIncentiveType: bucket,
        } as IncentiveWriteOffRulesRequest)
      );
    }
  };

  const getPolicyById = (id: string) => {
    IncentiveServices.getPolicyByPolicyId(id)
      .then(res => {
        const policyDetails: Partial<IncentivePolicyProps> = {
          policyName: res.policyName,
          vertical: res.vertical,
          channelType: res.channelType,
          incentiveType: res.incentiveType,
          policyStartDate: res.policyStartDate,
          policyEndDate: res.policyEndDate,
          accountType: res.accountType,
          policyType: res.policyType,
          caseType: res.caseType,
          classType: res.classType,
          payoutFrequency: res.payoutFrequency,
          maxPayoutPerPerson: res.maxPayoutPerPerson,
          splitPayoutForUsersMap: res.splitPayoutForUsersMap,
          userType: res.userType,
          modeOfCommunication: res.modeOfCommunication,
          bucketsHavingRules: res.bucketsHavingRules.length
            ? res.bucketsHavingRules
            : [],
          rules: res.rules,
          writeOffRules: res.writeOffRules,
          chargeCollectedRules: res?.chargeCollectedRules,
          npaType: res.npaType,
        };

        dispatch(setPolicyDetailsState(policyDetails));
        if (policyDetails.classType) {
          if (policyDetails.classType === ClassTypeEnum.WRITE_OFF) {
            filteredWriteOffRule(
              policyDetails.writeOffRules,
              WriteOffEnum.TECHNICAL
            );
          } else {
            if (policyDetails?.classType === BucketEnum.NPA) {
              filterBucketRule(policyDetails.rules, BucketEnum.NPA);
            } else {
              filterBucketRule(policyDetails.rules, BucketEnum.BUCKETX);
            }
          }
        } else {
          if (policyDetails?.classType === BucketEnum.NPA) {
            filterBucketRule(policyDetails.rules, BucketEnum.NPA);
          } else {
            filterBucketRule(policyDetails.rules, BucketEnum.BUCKETX);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const getPolicyEnumList = () => {
    dispatch(setTemplatesRules(null));
    dispatch(setSelectedTab(0));
    dispatch(policyEnums());
  };

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      getPolicyById(policyId);
    }
    if (idFromURL) {
      setLoading(true);
      getPolicyById(idFromURL);
    }
  }, [policyId, idFromURL]);
  useEffect(() => {
    getPolicyEnumList();
  }, []);

  useEffect(() => {
    policyDetailsState?.classType
      ? dispatch(listOfTempletes(policyDetailsState?.classType))
      : dispatch(listOfTempletes(''));
  }, []);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: 'Live Policy',
                  link: ROUTES.INCENTIVES_GAMIFICATIONS_POLICY,
                },
                {
                  label: !policyId
                    ? policyType === IncentivePolicyType.NEW_POLICY
                      ? `New Policy`
                      : 'Add On Policy'
                    : policyDetailsState?.policyName,
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <Box
        height={`calc(100% - 77px)`}
        sx={{
          overflow: 'auto',
        }}
        ref={scrollToTopRef}
      >
        <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
          {errorPolicy.length ? (
            <Box
              sx={{
                borderRadius: '8px',
                border: '1px solid #BA1C1C',
                p: 2,
                bgcolor: '#F8E8E8',
                mb: '1rem',
              }}
            >
              <VegaText
                text={errorPolicy}
                fontWeight={500}
                fontSize={'0.75rem'}
                fontColor={'#BA1C1C'}
              />
            </Box>
          ) : (
            ''
          )}
          <PolicyDetails loading={loading} />
        </VegaPageContent>

        {(policyId || idFromURL) && (
          <>
            {' '}
            {loading ? (
              <LoadingPage height={500} />
            ) : policyDetailsState.classType === ClassTypeEnum.WRITE_OFF ? (
              <WriteOffRule
                filteredWriteOffRule={filteredWriteOffRule}
                scrollToTopRef={scrollToTopRef}
                // selectedTemplate={selectedTemplate}
                // setSelectedTemplate={setSelectedTemplate}
                // templateLoading={templateLoading}
              />
            ) : (
              <BucketRule
                scrollToTopRef={scrollToTopRef}
                // selectedTemplate={selectedTemplate}
                // setSelectedTemplate={setSelectedTemplate}
                // templateLoading={templateLoading}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AddOnPolicy;
