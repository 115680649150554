import { createAsyncThunk } from '@reduxjs/toolkit';
import { AllocationService } from '../Apis/AllocationService';
import { LoanService } from '../Apis/LoanServices';
import { WebHookServices } from '../Apis/WebHookServices';
import { GetTeleAllocationsListRequest } from '../types/request/allocation';
import { LoanListRequest } from '../types/request/loan';
import {
  FeedbackRequest,
  GetCallDisposeRequest,
} from '../types/request/webhook';
import { getErrorMessageFromErrorObj } from '../utils/api';

export const fetchLoanAccountList = createAsyncThunk(
  'teleAgent/fetchLoanAccountList',
  async (data: Partial<LoanListRequest>) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);

export const fetchTeleAgentAllocation = createAsyncThunk(
  'teleAgent/fetchTeleAgentAllocation',
  async (request: Partial<GetTeleAllocationsListRequest>) => {
    const response = await AllocationService.getTeleAllocations(request);
    return response;
  }
);

export const fetchTeleAgentAudit = createAsyncThunk(
  'teleAgent/fetchTeleAgentAudit',
  async (request: Partial<GetCallDisposeRequest>, { rejectWithValue }) => {
    try {
      const response = await WebHookServices.getCallDisposeList(request);
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);
export const fetchFeedbackList = createAsyncThunk(
  'teleAgent/fetchFeedbackList',
  async (request: Partial<FeedbackRequest>, { rejectWithValue }) => {
    try {
      const response = await WebHookServices.getFeedbackList(request);
      return response;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromErrorObj(error));
    }
  }
);
