import { Stack } from '@mui/material';
import { useState } from 'react';
import ListOfTodayEscalations from '../../../components/Escalations/ListOfTodayEscalations';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import { ROUTES } from '../../../router/routes';
import VegaButton from '../../../components/common/VegaButton';
import { EscalationsServices } from '../../../Apis/EscalationsServices';
import { EscalationTriggerType } from '../../../types/request/escalationsRequest';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

export enum EscalationSubType {
  NON_PTP = 'Non-PTP',
  BROKEN_PTP = 'Broken-PTP',
  UNSUCCESSFULL_FOLLOW_UP = 'Unsuccessfull Follow-Up',
  IRREGULAR_PTP = 'Irregular-PTP',
  REVISIT_ESCALATION = 'Revisit-Escalation',
  RTP_CASES = 'RTP-Cases',
  REFER_TO_SUPERVISOR = 'Refer to Supervisor',
  MISC = 'Misc',
}

const Today = () => {
  const { setSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const triggerEscalation = () => {
    EscalationsServices.triggerEscalation({
      triggerType: EscalationTriggerType.TODAY,
    })
      .then(res => {})
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  return (
    <>
      <VegaPageHeader
        sx={{
          pb: 0,
        }}
        renderLeftView={() => {
          return (
            <Stack gap={1} width={'100%'}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <VegaBreadCrumb
                  items={[
                    { label: 'Escalation', link: ROUTES.ESCALATIONS },
                    { label: `Today` },
                  ]}
                />
                <VegaButton
                  text="Trigger Escalation"
                  onClick={triggerEscalation}
                />
              </Stack>
              <VegaTabBar
                value={selectedTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                  setSelectedStatus(0);
                }}
              >
                {Object.keys(EscalationSubType).map((type: string) => (
                  <VegaTabBarItem key={type} label={EscalationSubType[type]} />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      <ListOfTodayEscalations
        selectedTab={selectedTab}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
    </>
  );
};

export default Today;
