/** @format */

import { createContext, useContext, useEffect, useState } from 'react';
import {
  createHolidayType,
  deleteHolidayType,
  fetchHolidayTypeList,
  updateHolidayType,
} from '../Apis/holiday';
import {
  ICreateHolidayTypeProps,
  IHolidayTypeListProps,
} from '../types/holiday';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';
import { HolidayTypeRequest } from '../types/request/holidayRequest';

export interface IHoliayTypeListStateProps {
  loading: boolean;
  records: IHolidayTypeListProps[];
}

export interface HolidayTypeContextType {
  search: string;
  selectedHolidayType: IHolidayTypeListProps | null;
  isAdd: boolean;
  loading: boolean;
  listLoading: boolean;
  page: number;
  size: number;
  totalItems: number;
  holidayTypeListState: IHoliayTypeListStateProps;
  holidayTypeState: ICreateHolidayTypeProps | null;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  toggleAddHolidayTypeDrawer: () => void;
  getHolidayTypeList: (request: Partial<HolidayTypeRequest>) => void;
  onAddHolidayTypeSubmit: () => void;
  onDeleteHolidayType: () => void;
  onUpdateHolidayTypeSubmit: (
    updatedHolidayTypeState: ICreateHolidayTypeProps
  ) => void;
  handleAddHolidayTypeChange: (
    name: keyof ICreateHolidayTypeProps,
    value: string | Date
  ) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedHolidayType: React.Dispatch<
    React.SetStateAction<IHolidayTypeListProps | null>
  >;
  setHolidayTypeState: React.Dispatch<
    React.SetStateAction<ICreateHolidayTypeProps | null>
  >;
}

export const HolidayTypeContext = createContext<HolidayTypeContextType | null>(
  null
);

const HolidayTypeProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [selectedHolidayType, setSelectedHolidayType] =
    useState<IHolidayTypeListProps | null>(null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [holidayTypeState, setHolidayTypeState] =
    useState<ICreateHolidayTypeProps | null>(null);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(10);
  const [holidayTypeListState, setHolidayTypeListState] =
    useState<IHoliayTypeListStateProps>({
      loading: false,
      records: [],
    });
  const [loading, setLoading] = useState<boolean>(false);
  const toggleAddHolidayTypeDrawer = () => {
    setIsAdd(!isAdd);
  };

  const onAddHolidayTypeSubmit = async () => {
    if (!holidayTypeState) return;
    setLoading(true);
    try {
      await createHolidayType(holidayTypeState);
      setSnackbar('Holiday type Added');
      toggleAddHolidayTypeDrawer();
      getHolidayTypeList({});
      setLoading(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Holiday type'),
        'error'
      );
      setLoading(false);
    }
  };
  const onUpdateHolidayTypeSubmit = async (
    updatedHolidayTypeState: ICreateHolidayTypeProps
  ) => {
    setLoading(true);
    try {
      await updateHolidayType(updatedHolidayTypeState);
      toggleAddHolidayTypeDrawer();
      setSelectedHolidayType(null);
      setSnackbar('Holiday type Updated');
      // getHolidayTypeList({ page: 0, size: 10 });
      setPage(0);
      setSize(10);
      setLoading(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to update Holiday type'),
        'error'
      );
      setLoading(false);
    }
  };

  const onDeleteHolidayType = async () => {
    try {
      if (!selectedHolidayType) return;
      await deleteHolidayType(selectedHolidayType.id);
      setSelectedHolidayType(null);
      setSnackbar('Holiday type deleted');
      getHolidayTypeList({});
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to dalete Holiday type'),
        'error'
      );
    }
  };

  const handleAddHolidayTypeChange = (
    name: keyof ICreateHolidayTypeProps,
    value: string | Date
  ) => {
    setHolidayTypeState({ ...holidayTypeState, [name]: value });
  };

  const updateHolidayTypeListState = (
    key: keyof IHoliayTypeListStateProps,
    value: boolean | IHolidayTypeListProps[]
  ) => {
    setHolidayTypeListState({ ...holidayTypeListState, [key]: value });
  };

  const getHolidayTypeList = async (request: Partial<HolidayTypeRequest>) => {
    setListLoading(true);
    try {
      const response = await fetchHolidayTypeList(request);

      setListLoading(false);
      updateHolidayTypeListState('records', response.records);
      setTotalItems(response.totalItems);
    } catch (error) {
      setListLoading(false);
    }
  };

  // useEffect(() => {
  //   if (search.length) {
  //     const accountList = setTimeout(() => {
  //       getHolidayTypeList({ name: search, page, size });
  //     }, 200);
  //     return () => clearTimeout(accountList);
  //   } else {
  //     getHolidayTypeList({ page, size });
  //   }
  // }, [search, page, size]);

  return (
    <HolidayTypeContext.Provider
      value={{
        listLoading,
        selectedHolidayType,
        search,
        isAdd,
        loading,
        holidayTypeListState,
        holidayTypeState,
        page,
        size,
        totalItems,
        setPage,
        setSize,
        handleAddHolidayTypeChange,
        toggleAddHolidayTypeDrawer,
        getHolidayTypeList,
        onDeleteHolidayType,
        onAddHolidayTypeSubmit,
        onUpdateHolidayTypeSubmit,
        setSearch,
        setSelectedHolidayType,
        setHolidayTypeState,
      }}
    >
      {children}
    </HolidayTypeContext.Provider>
  );
};

export const useHolidayType = () =>
  useContext(HolidayTypeContext) as HolidayTypeContextType;

export default HolidayTypeProvider;
