import axios from 'axios';
import { ApiService } from '../types/api';
import {
  AgentLevelIncentiveDetailsResponseDto,
  GetTempleteProps,
  IncentivePolicyProps,
  OverallAgentIncentiveDetails,
  OverallPayoutStatusResponse,
  RankDetailDto,
  RoleMaxPayoutDto,
  TempleteListProps,
} from '../types/incentives';
import {
  AgentLevelIncentiveDetailsRequestDto,
  CreateTempleteRequest,
  DownloadOverallPayoutPrevCycleRequest,
  FetchLeaderboardRequestDto,
  FetchScorecardDetailsRequestDto,
  FetchWirteOffPolicyListRequest,
  GetAgentIdRequest,
  GetAgentPayoutRequest,
  GetPolicyRequest,
  GetPolicyRequestForRole,
  IncentiveDetailsRequestDto,
  IncentivePolicyRulesRequest,
  IncentiveWriteOffRulesRequest,
  ListRoleMaxPayoutRequestDto,
  OverallAgentIncentiveDetailsRequestDto,
  PolicyDeleteRequest,
  PolicyDetailsRequest,
  ReviewPolicyDetailsRequest,
  SubmitOverallIncentivePayoutRequest,
  UpdateConfigStatusRequest,
  UpdateRoleMaxPayoutIncentiveRequest,
  UpdateWriteOffSplit,
  WriteOffAgentViewListRequest,
  WriteOffAgentViewListResponseDto,
  WriteOffCountRequest,
  WriteOffListCount,
  WriteOffRewardRecordsResponseDto,
} from '../types/request/incentives';
import { PaginatedResponse } from '../utils/api';
import {
  IncentiveDetailsCurrentMonthResponseDto,
  IncentiveOverallDetailsResponseDto,
} from '../types/incentiveOfMonthType';
import {
  FetchScorecardDetailsResponseDto,
  IncentiveSimulatorFinalResponseDto,
} from '../types/incentiveScorecardType';
import { AgentLeaderboardResponseDto } from '../types/incentiveLeaderType';
import { LongRunningTask } from '../types/allocations';
import { request } from 'http';

const Endpoint = {
  BASE: '/incentives',

  POLICY: '/policy',
  STATUS: '/status',
  LIST: '/list',
  RULES: '/rules',
  REVIEW: '/review',
  POLICY_BY_ID: '/:id',
  ENUM_LIST: '/enums/list/values',
  AGENT_RANK: '/payout/details/rankings',
  FOR_ROLE: '/forRole',
  TEMPLATE: '/template',
  WRITE_OFF: '/write-off',
  BY_TEMPLATE_ID: '/:templateId',
  PDF: '/pdf',
  AGENT_PAYOUT: '/payout/details/:id',
  AGENT_LEVEL: '/payout/agentDetails',
  LEADERBOARD: '/payout/details/leaderboard',
  CURRENT_MONTH: '/payout/details/currentMonth',
  OVERALL: '/payout/details/overall',
  SCORECARD: '/payout/details/scorecard',
  EXPORT_WRITE_OFF: '/payout/write-off/compute/export/:policyId',
  INSIGHTS: '/payout/insights',
  SIMULATE: '/payout/simulate',
  SAMPLE_FORMAT: '/payout/simulate/sample',
  PUBLISH_SCORECARD: '/payout/publish/scorecard',
  PUBLISH_LEADERBOARD: '/payout/publish/leaderboard',
  DOWNLOAD_SCORECARD: '/payout/download/scorecard',
  // DOWNLOAD_AGENT_LEVEL_FILE: '/agentDetails/export',
  // UPLOAD_AGENT_LEVEL_FILE: '/agentDetails/edit',
  WRITE_OFF_POLICY_LIST: '/payout/write-off/compute/list',
  UPDATE_WRITE_OFF_SPLIT: '/payout/write-off/compute/update',
  DOWNLOAD_APPROVED_SPLIT: '/payout/write-off/compute/download',
  WRITE_OFF_COUNT: '/payout/write-off/compute/count',
  WRITE_OFF_POLICY_AGENT_VIEW: '/payout/write-off/compute/agentView',
  DOWNLOAD_AGENT_VIEW_WRITE_OFF_POLICY:
    '/payout/write-off/compute/agentView/download',
  DOWNLOAD_AGENT_LEVEL_FILE: '/payout/agentDetails/export',
  UPLOAD_AGENT_LEVEL_FILE: '/payout/agentDetails/edit',
  ROLE_MAX_PAYOUT_LIST: '/payout/role-max-payout/list',
  UPDATE_ROLE_MAX_PAYOUT_INCENTIVE: '/payout/role-max-payout',
  UPDATE_ROLE_MAX_PAYOUT_INCENTIVE_STATUS: '/allocation/config/status',
  OVERALL_PAYOUT_LIST: '/payout/overallPayout',
  DOWNLOAD_OVERALL_PAYOUT_PREV_CYCLE: '/payout/overallPayout/export',
  SUBMIT_OVERALL_PAYOUT_PREV_CYCLE: '/payout/overallPayout/status',
};

const INCENTIVE_BASE_URL = Endpoint.BASE + Endpoint.POLICY;
const EXPORT_WRITE_OFF_POLICY = Endpoint.BASE + Endpoint.EXPORT_WRITE_OFF;
const SIMULATE_URL = Endpoint.BASE + Endpoint.SIMULATE;
const AGENT_LEVEL_URL = Endpoint.BASE + Endpoint.AGENT_LEVEL;
const LEADERBOARD_URL = Endpoint.BASE + Endpoint.LEADERBOARD;
const CURRENT_MONTH_URL = Endpoint.BASE + Endpoint.CURRENT_MONTH;
const OVERALL_URL = Endpoint.BASE + Endpoint.OVERALL;
const SCORECARD_URL = Endpoint.BASE + Endpoint.SCORECARD;
const SAMPLE_FORMAT_URL = Endpoint.BASE + Endpoint.SAMPLE_FORMAT;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PUBLISH_SCORECARD_URL = Endpoint.BASE + Endpoint.PUBLISH_SCORECARD;
const PUBLISH_LEADERBOARD_URL = Endpoint.BASE + Endpoint.PUBLISH_LEADERBOARD;
const DOWNLOAD_SCORECARD_URL = Endpoint.BASE + Endpoint.DOWNLOAD_SCORECARD;
const DOWNLOAD_AGENT_LEVEL_FILE_URL =
  Endpoint.BASE + Endpoint.DOWNLOAD_AGENT_LEVEL_FILE;
const UPLOAD_AGENT_LEVEL_FILE =
  Endpoint.BASE + Endpoint.UPLOAD_AGENT_LEVEL_FILE;
const WRITE_OFF_POLICY_LIST_URL =
  Endpoint.BASE + Endpoint.WRITE_OFF_POLICY_LIST;
const UPDATE_WRITE_OFF_SPLIT_URL =
  Endpoint.BASE + Endpoint.UPDATE_WRITE_OFF_SPLIT;
const DOWNLOAD_SPLIT_APPROVED_URL =
  Endpoint.BASE + Endpoint.DOWNLOAD_APPROVED_SPLIT;
const WRITE_OFF_COUNT_URL = Endpoint.BASE + Endpoint.WRITE_OFF_COUNT;
const WRITE_OFF_POLICY_AGENT_VIEW_URL =
  Endpoint.BASE + Endpoint.WRITE_OFF_POLICY_AGENT_VIEW;
const DOWNLOAD_AGENT_VIEW_WRITE_OFF_POLICY_URL =
  Endpoint.BASE + Endpoint.DOWNLOAD_AGENT_VIEW_WRITE_OFF_POLICY;
const ROLE_MAX_PAYOUT_LIST_URL = Endpoint.BASE + Endpoint.ROLE_MAX_PAYOUT_LIST;
const UPDATE_ROLE_MAX_PAYOUT_INCENTIVE_URL =
  Endpoint.BASE + Endpoint.UPDATE_ROLE_MAX_PAYOUT_INCENTIVE;
const UPDATE_ROLE_MAX_PAYOUT_INCENTIVE_STATUS_URL =
  Endpoint.UPDATE_ROLE_MAX_PAYOUT_INCENTIVE_STATUS;
const OVERALL_PAYOUT_LIST_URL = Endpoint.BASE + Endpoint.OVERALL_PAYOUT_LIST;
const DOWNLOAD_OVERALL_PAYOUT_PREV_CYCLE_URL =
  Endpoint.BASE + Endpoint.DOWNLOAD_OVERALL_PAYOUT_PREV_CYCLE;
const SUBMIT_OVERALL_PAYOUT_PREV_CYCLE_URL =
  Endpoint.BASE + Endpoint.SUBMIT_OVERALL_PAYOUT_PREV_CYCLE;

export class IncentiveServices extends ApiService {
  static async getPolicyEnum(): Promise<{ key: string[] }> {
    const endpoint = Endpoint.BASE + Endpoint.ENUM_LIST;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getPolicies(
    request: Partial<GetPolicyRequest>
  ): Promise<PaginatedResponse<IncentivePolicyProps>> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getPoliciesForRole(
    request: Partial<GetPolicyRequestForRole>
  ): Promise<IncentivePolicyProps[]> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.LIST + Endpoint.FOR_ROLE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getPolicyByPolicyId(
    policyId: string
  ): Promise<IncentivePolicyProps> {
    const endpoint =
      INCENTIVE_BASE_URL + Endpoint.POLICY_BY_ID.replace(':id', policyId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async downloanPolicyByPolicyId(policyId: string): Promise<string> {
    const endpoint =
      INCENTIVE_BASE_URL +
      Endpoint.PDF +
      Endpoint.POLICY_BY_ID.replace(':id', policyId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async addPolicy(
    request: Partial<PolicyDetailsRequest>
  ): Promise<IncentivePolicyProps> {
    const endpoint = INCENTIVE_BASE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async exportWriteOffPolicy(policyId: string): Promise<string> {
    const endpoint = EXPORT_WRITE_OFF_POLICY.replace(':policyId', policyId);
    const response = await axios.post(endpoint);
    return response.data;
  }
  static async reviewPolicy(request: Partial<ReviewPolicyDetailsRequest>) {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.REVIEW;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
  static async addRulesForPolicy(
    request: Partial<IncentivePolicyRulesRequest>
  ): Promise<IncentivePolicyProps> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.RULES;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async addWriteOffPolicy(
    request: Partial<IncentiveWriteOffRulesRequest>
  ): Promise<IncentivePolicyProps> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.RULES + Endpoint.WRITE_OFF;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async deleteRulesForPolicy(
    request: Partial<PolicyDeleteRequest>
  ): Promise<IncentivePolicyProps> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.STATUS;
    const response = await axios.patch(endpoint, request);
    return response.data;
  }

  static async getAgentRank(
    request: Partial<GetAgentIdRequest>
  ): Promise<RankDetailDto> {
    const endpoint =
      Endpoint.BASE + Endpoint.AGENT_RANK + `/${request.agentId}`;
    const response = await axios.get(endpoint);
    return response.data;
  }

  /////////////  templAte \\\\\\\\\\\\\
  static async createTemplate(
    request: Partial<CreateTempleteRequest>
  ): Promise<{ key: string[] }> {
    const endpoint = Endpoint.BASE + Endpoint.TEMPLATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async listTemplates(type: string): Promise<TempleteListProps[]> {
    const endpoint = Endpoint.BASE + Endpoint.TEMPLATE + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: { type } });
    return response.data;
  }
  static async getTemplate(templeteId: string): Promise<GetTempleteProps> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.TEMPLATE +
      Endpoint.BY_TEMPLATE_ID.replace(':templateId', templeteId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async deleteTemplate(templeteId: string): Promise<{ key: string[] }> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.TEMPLATE +
      Endpoint.BY_TEMPLATE_ID.replace(':templateId', templeteId);
    const response = await axios.delete(endpoint);
    return response.data;
  }

  static async getAgentPayout(data: GetAgentPayoutRequest) {
    const endpoint =
      Endpoint.BASE + Endpoint.AGENT_PAYOUT.replace(':id', data.agentId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  ///// SIMULATOR \\\\\\\\
  static async sampleSimulateFormat() {
    const endpoint = SAMPLE_FORMAT_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async simultateIncentive(
    request: Partial<FormData>
  ): Promise<IncentiveSimulatorFinalResponseDto> {
    const endpoint = SIMULATE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  ///// SCORECARD \\\\\\\\
  static async scorecardIncentive(
    request: Partial<FetchScorecardDetailsRequestDto>
  ): Promise<FetchScorecardDetailsResponseDto> {
    const endpoint = SCORECARD_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async publishScorecard(agentId: string) {
    const endpoint = PUBLISH_SCORECARD_URL;
    const response = await axios.post(endpoint, { agentId });
    return response.data;
  }

  static async downloadScoreCard(agentId: string): Promise<LongRunningTask> {
    const endpoint = DOWNLOAD_SCORECARD_URL;
    const response = await axios.post(endpoint, { agentId });
    return response.data;
  }

  ///// OVERALL \\\\\\\\
  static async overallIncentive(
    request: Partial<IncentiveDetailsRequestDto>
  ): Promise<IncentiveOverallDetailsResponseDto> {
    const endpoint = OVERALL_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  ///// CURRENTMONTH \\\\\\\\
  static async currentMonthIncentive(
    request: Partial<IncentiveDetailsRequestDto>
  ): Promise<IncentiveDetailsCurrentMonthResponseDto[]> {
    const endpoint = CURRENT_MONTH_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  ///// LEADERBOARD \\\\\\\\
  static async leaderboardIncentive(
    request: Partial<FetchLeaderboardRequestDto>
  ): Promise<PaginatedResponse<AgentLeaderboardResponseDto>> {
    const endpoint = LEADERBOARD_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async publishLeaderboard(agentType: string) {
    const endpoint = PUBLISH_LEADERBOARD_URL;
    const response = await axios.post(endpoint, { agentType });
    return response.data;
  }

  ///// AGENT_LEVEL \\\\\\\\
  static async agentLevelIncentive(
    request: Partial<AgentLevelIncentiveDetailsRequestDto>
  ): Promise<PaginatedResponse<AgentLevelIncentiveDetailsResponseDto>> {
    const endpoint = AGENT_LEVEL_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async downloadAgentLevelFile(
    policyId: string
  ): Promise<LongRunningTask> {
    const endpoint = DOWNLOAD_AGENT_LEVEL_FILE_URL;
    const response = await axios.post(endpoint, { policyId });
    return response.data;
  }

  static async uploadAgentlevelFile(request: {
    file: File;
    policyId: string;
  }): Promise<LongRunningTask> {
    const endpoint = UPLOAD_AGENT_LEVEL_FILE;
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
      formData.append('policyId', request.policyId);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  ///// Write off \\\\\\\\

  static async writeOffList(
    request: Partial<FetchWirteOffPolicyListRequest>
  ): Promise<PaginatedResponse<WriteOffRewardRecordsResponseDto>> {
    const endpoint = WRITE_OFF_POLICY_LIST_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateWriteOffSplit(request: Partial<UpdateWriteOffSplit>) {
    const endpoint = UPDATE_WRITE_OFF_SPLIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async downloadSplitApproved(
    request: Partial<FetchWirteOffPolicyListRequest>
  ) {
    const endpoint = DOWNLOAD_SPLIT_APPROVED_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getWriteOffCount(
    request: Partial<WriteOffCountRequest>
  ): Promise<WriteOffListCount> {
    const endpoint = WRITE_OFF_COUNT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async writeOffAgentViewList(
    request: Partial<WriteOffAgentViewListRequest>
  ): Promise<PaginatedResponse<WriteOffAgentViewListResponseDto>> {
    const endpoint = WRITE_OFF_POLICY_AGENT_VIEW_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async downloadAgentWriteOffPolicyList(
    request: Partial<FetchWirteOffPolicyListRequest>
  ) {
    const endpoint = DOWNLOAD_AGENT_VIEW_WRITE_OFF_POLICY_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  ///// Role Max Payout \\\\\\\\

  static async getRoleMaxPayoutList(
    request: Partial<ListRoleMaxPayoutRequestDto>
  ): Promise<RoleMaxPayoutDto[]> {
    const endpoint = ROLE_MAX_PAYOUT_LIST_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateRoleMaxPayoutIncentive(
    request: Partial<UpdateRoleMaxPayoutIncentiveRequest>
  ): Promise<PaginatedResponse<RoleMaxPayoutDto>> {
    const endpoint = UPDATE_ROLE_MAX_PAYOUT_INCENTIVE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateRoleMaxPayoutStatus(
    request: Partial<UpdateConfigStatusRequest>
  ) {
    const endpoint = UPDATE_ROLE_MAX_PAYOUT_INCENTIVE_STATUS_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  ///// Overall Payout \\\\\\\\

  static async getOverallPayoutList(
    request: Partial<OverallAgentIncentiveDetailsRequestDto>
  ): Promise<PaginatedResponse<OverallAgentIncentiveDetails>> {
    const endpoint = OVERALL_PAYOUT_LIST_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async downloadOverallPayoutPrevCycle(
    request: Partial<DownloadOverallPayoutPrevCycleRequest>
  ): Promise<LongRunningTask> {
    const endpoint = DOWNLOAD_OVERALL_PAYOUT_PREV_CYCLE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async submitOverallPayoutPrevCycle(
    request: Partial<SubmitOverallIncentivePayoutRequest>
  ) {
    const endpoint = SUBMIT_OVERALL_PAYOUT_PREV_CYCLE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getOverAllPayoutPrevCycleStatus(): Promise<OverallPayoutStatusResponse> {
    const endpoint = SUBMIT_OVERALL_PAYOUT_PREV_CYCLE_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }
}
