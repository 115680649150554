import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { OverallYearCollectionResultDto } from '../../../../../types/analytics';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import VegaText from '../../../../../components/common/VegaText';

interface IProps {
  selectedCollectionProfitType: string;
}

const YTDPenalCollection = ({ selectedCollectionProfitType }: IProps) => {
  const { setSnackbar } = useSnackbar();

  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<OverallYearCollectionResultDto>
  >({});
  const [loading, setLoading] = useState<boolean>(false);

  const series = [
    {
      name: 'Achieved',
      data: [
        analyticsQueryResponse?.ytdPenalCollection ?? 0,
        analyticsQueryResponse?.lysdPenalCollection ?? 0,
      ],
    },
    {
      name: 'Target',
      data: [
        analyticsQueryResponse?.ytdTarget ?? 0,
        analyticsQueryResponse?.lysdTarget ?? 0,
      ],
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,

      // custom: function ({ series, dataPointIndex, w }) {
      //   const dotStyle0 = {
      //     background: w.globals.fill.colors[0], // Set color dynamically
      //   };
      //   const dotStyle1 = {
      //     background: w.globals.fill.colors[1], // Set color dynamically
      //   };
      //   return (
      //     '<div class="custom-tooltip">' +
      //     '<div class="header font-aspekta-600">' +
      //     w.globals.labels[dataPointIndex] +
      //     '</div>' +
      //     '<ul>' +
      //     '<li >' +
      //     '<span class="dot" style="' +
      //     Object.keys(dotStyle0)
      //       .map(key => `${key}: ${dotStyle0[key]};`)
      //       .join(' ') +
      //     '"></span>' +
      //     '<span class="font-aspekta-500">' +
      //     w.globals.seriesNames[0] +
      //     '</span>' +
      //     '<span class="font-aspekta-500">' +
      //     ' : ' +
      //     '</span>' +
      //     '<span class="font-aspekta-600">' +
      //     series[0][dataPointIndex] +
      //     '</span>' +
      //     '</li>' +
      //     '<li >' +
      //     '<span class="dot" style="' +
      //     Object.keys(dotStyle1)
      //       .map(key => `${key}: ${dotStyle1[key]};`)
      //       .join(' ') +
      //     '"></span>' +
      //     '<span class="font-aspekta-500">' +
      //     w.globals.seriesNames[1] +
      //     '</span>' +
      //     '<span class="font-aspekta-500">' +
      //     ' : ' +
      //     '</span>' +
      //     '<span class="font-aspekta-600">' +
      //     series[1][dataPointIndex] +
      //     '</span>' +
      //     '</li>' +
      //     '</ul>' +
      //     '</div>'
      //   );
      // },
    },
    xaxis: {
      categories: ['YTD', 'LYTD'],
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
  };

  const getPenalCollectionQueries = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.OVERALL_YEARLY_PENAL_COLLECTION,
        params: {
          isYearToDate: 'true',
          type: selectedCollectionProfitType,
        },
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);

      setAnalyticsQueryResponse(response);

      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    getPenalCollectionQueries();
  }, [selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper
        title="Penal Charges Collection (Rs in crore)"
        rightComponent={getPercentageValue(analyticsQueryResponse)}
      >
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Chart
              options={options}
              series={series}
              type="bar"
              height={'100%'}
            />
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default YTDPenalCollection;

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
