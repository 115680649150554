import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import RoleBaseSegmentPerformance from '../../components/RoleBaseSegmentPerformance';
import OverallAgentPerformance from './components/OverallAgentPerformance';
import TeleAgentPerformance from './components/TeleAgentPerformance';

const TeleCallerContainer = () => {
  const { calenderType, profitType } = useParams();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <OverallAgentPerformance />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TeleAgentPerformance />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RoleBaseSegmentPerformance
            calenderType={calenderType}
            selectedCollectionProfitType={profitType}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TeleCallerContainer;
