import axios from 'axios';
import {
  FilterTeleCallingMetrics,
  UserSummaryResponse,
} from '../types/teleCallingMetricsType';

const Endpoint = {
  BASE: '/master',
  CAPRI: '/capri',
  AGENT_SUMMARY: '/user/agentSummary',
};

const AGENT_SUMMARY_URL =
  Endpoint.BASE + Endpoint.CAPRI + Endpoint.AGENT_SUMMARY;

export class TeleCallingMetricsServices {
  static async getAgentSummary(
    request: Partial<FilterTeleCallingMetrics>
  ): Promise<UserSummaryResponse> {
    const endpoint = AGENT_SUMMARY_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
