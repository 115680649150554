import { Collapse, Stack } from '@mui/material';
import { toLowerCase } from '../../constants/commonFunction';
import { getAccountList } from '../../features/accountsSlice';
import AllBranchSelector from '../../pages/MinVisits/modules/AllBranchSelector';
import LoanAccountSelector from '../../pages/MinVisits/modules/LoanAccountSelector';
import RegionSelector from '../../pages/MinVisits/modules/RegionSelector';
import { SearchBy } from '../../pages/ReceiptManagment/module/ListOfAccountsAgent';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch } from '../../store';
import { ComparisonType } from '../../types/accountType';
import { LoanListRequest } from '../../types/request/loan';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { RupeeIcon } from '../Icons/Icons';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaRadioGroup, { VegaRadioOption } from '../common/VegaRadioGroup';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import BucketSelector from '../../pages/MinVisits/modules/BucketSelector';
import CycleSelector from '../../pages/MinVisits/modules/CycleSelector';
import { VerticalTypes } from '../Perfomance/PerformanceFilterSection';

interface IProps {
  onClose: () => void;
  setFilterForm: React.Dispatch<
    React.SetStateAction<Partial<AccountsFilterFormDataType>>
  >;
  filterForm: Partial<AccountsFilterFormDataType>;
  search: string;
  searchBy: string;
}

export enum FilterAttributes {
  POS = 'POS',
  BUCKET = 'BUCKET',
  BRANCH = 'BRANCH',
  REGION = 'REGION',
  CYCLE = 'CYCLE',
  SEGMENT = 'SEGMENT',
  LOAN_STATUS = 'LOAN_STATUS',
  ALLOCATION_TYPE = 'ALLOCATION_TYPE',
}

export enum POS {
  Greater_Than = 'Greater_than',
  Less_Than = 'Less_than',
  Equal_To = 'Equal_To',
  Range = 'Range',
}
export enum ProductTypeEnum {
  HOME_LOAN = 'Home Loan',
  SME_TL = 'SME-TL',
  BUSINESS_LOANS = 'Business Loans',
  PREAPPROVED_LOANS = 'PreApproved Loans',
  HOME_EQUITY = 'Home Equity',
  MSME_TL = 'MSME-TL',
}

export type AccountsFilterFormDataType = {
  pos: string;
  posValue: string;
  posMinValue: string;
  posMaxValue: string;
  bucket: string[];
  branch: string[];
  cycle: string[];
  segment: string;
  region: string[];
  loanStatus: string[];
  allocationType: string;
};

enum InputType {
  pos = 'pos',
  posValue = 'posValue',
  bucket = 'bucket',
  branch = 'branch',
  posMinValue = 'posMinValue',
  posMaxValue = 'posMaxValue',
  cycle = 'cycle',
  segment = 'segment',
  region = 'region',
  loanStatus = 'loanStatus',
}

export enum FilterOP {
  GTE = 'GTE',
  LTE = 'LTE',
  EQ = 'EQ',
  IN = 'IN',
}

export enum LoanStatus {
  NORMALISED = 'NORMALISED',
  ROLL_BACK = 'ROLL_BACK',
  ROLL_FORWARD = 'ROLL_FORWARD',
  STABILISED = 'STABILISED',
}

const AccountFilterForm = ({
  onClose,
  setFilterForm,
  filterForm,
  search,
  searchBy,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  function updateFormData(type: InputType, value: string | string[]) {
    setFilterForm(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function applyFilter(
    queryParameter: Partial<LoanListRequest>,
    op: FilterOP,
    value: string
  ) {
    queryParameter.filters?.push({
      attribute: FilterAttributes.POS,
      op,
      value,
    });
  }

  function applyFilterForRange(
    queryParameter: Partial<LoanListRequest>,
    minValue: string,
    maxValue: string
  ) {
    applyFilter(queryParameter, FilterOP.GTE, minValue);
    applyFilter(queryParameter, FilterOP.LTE, maxValue);
  }

  const onClear = () => {
    const body = {
      includeLmsVariables: true,
      includeCustomerDetails: true,
      includeLoanOutstandingDetails: true,
      page: 0,
      size: 10,
      agentId: user?.id,
    } as LoanListRequest;
    if (search.length) {
      if (searchBy === SearchBy.LAN) {
        body.searchPartialLoanId = search.toUpperCase();
      } else {
        body.customerName = search;
      }
    }
    dispatch(getAccountList(body));
    setFilterForm({});
    // onClose();
  };
  const onApply = () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        includeLoanOutstandingDetails: true,
        page: 0,
        size: 10,
        filters: [],
        agentId: user?.id,
      } as LoanListRequest;
      if (search.length) {
        if (searchBy === SearchBy.LAN) {
          body.searchPartialLoanId = search.toUpperCase();
        } else {
          body.customerName = search;
        }
      }

      if (filterForm.pos) {
        switch (filterForm.pos) {
          case POS.Greater_Than:
            applyFilter(body, FilterOP.GTE, filterForm.posValue);
            break;
          case POS.Less_Than:
            applyFilter(body, FilterOP.LTE, filterForm.posValue);
            break;
          case POS.Equal_To:
            applyFilter(body, FilterOP.EQ, filterForm.posValue);
            break;
          case POS.Range:
            applyFilterForRange(
              body,
              filterForm.posMinValue,
              filterForm.posMaxValue
            );
            break;
        }
      }

      if (filterForm.bucket) {
        body.filters?.push({
          attribute: FilterAttributes.BUCKET,
          op: FilterOP.IN,
          values: filterForm.bucket,
        });
      }
      if (filterForm.cycle) {
        body.filters?.push({
          attribute: FilterAttributes.CYCLE,
          op: FilterOP.IN,
          values: filterForm.cycle,
        });
      }
      if (filterForm.branch) {
        body.filters?.push({
          attribute: FilterAttributes.BRANCH,
          op: FilterOP.IN,
          values: filterForm.branch,
        });
      }
      if (filterForm.region) {
        body.filters?.push({
          attribute: FilterAttributes.REGION,
          op: FilterOP.IN,
          values: filterForm.region,
        });
      }
      if (filterForm.loanStatus) {
        body.filters?.push({
          attribute: FilterAttributes.LOAN_STATUS,
          op: FilterOP.IN,
          values: filterForm.loanStatus,
        });
      }

      if (filterForm.segment) {
        body.filters?.push({
          attribute: FilterAttributes.SEGMENT,
          op: FilterOP.EQ,
          value: filterForm.segment,
        });
      }
      body.filters = body.filters?.filter(item => {
        if (item?.values) {
          return item.values.length > 0;
        }
        return true;
      });
      dispatch(getAccountList(body));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
    onClose();
  };

  const onRegionSelect = (region: string) => {
    const preRegion =
      filterForm.region && filterForm.region.length
        ? [...filterForm.region]
        : [];
    if (preRegion.includes(region)) {
      const filteredRegion = preRegion.filter(
        (selectedRegion: string) => selectedRegion !== region
      );
      updateFormData(InputType.region, filteredRegion);
    } else {
      updateFormData(InputType.region, [...preRegion, region]);
    }
  };

  const onBucketSelect = (status: string) => {
    const preBucketStatus =
      filterForm.bucket && filterForm.bucket.length
        ? [...filterForm.bucket]
        : [];
    if (preBucketStatus.includes(status)) {
      const filteredBucketStatus = preBucketStatus.filter(
        (selectedBucket: string) => selectedBucket !== status
      );
      updateFormData(InputType.bucket, filteredBucketStatus);
    } else {
      updateFormData(InputType.bucket, [...preBucketStatus, status]);
    }
  };

  const onCycleSelect = (status: string) => {
    const preCycleStatus =
      filterForm.cycle && filterForm.cycle.length ? [...filterForm.cycle] : [];
    if (preCycleStatus.includes(status)) {
      const filteredCycleStatus = preCycleStatus.filter(
        (selectedCycle: string) => selectedCycle !== status
      );
      updateFormData(InputType.cycle, filteredCycleStatus);
    } else {
      updateFormData(InputType.cycle, [...preCycleStatus, status]);
    }
  };

  const onCycleDelete = (index: number) => {
    const preCycle =
      filterForm.cycle && filterForm.cycle.length ? [...filterForm.cycle] : [];
    preCycle.splice(index, 1);
    updateFormData(InputType.cycle, preCycle);
  };

  const onBucketDelete = (index: number) => {
    const preBucket =
      filterForm.bucket && filterForm.bucket.length
        ? [...filterForm.bucket]
        : [];
    preBucket.splice(index, 1);
    updateFormData(InputType.bucket, preBucket);
  };

  const onRegionDelete = (index: number) => {
    const preRegion =
      filterForm.region && filterForm.region.length
        ? [...filterForm.region]
        : [];
    preRegion.splice(index, 1);
    updateFormData(InputType.region, preRegion);
  };

  const onBranchSelect = (branch: string) => {
    const preBranch =
      filterForm.branch && filterForm.branch.length
        ? [...filterForm.branch]
        : [];
    if (preBranch.includes(branch)) {
      const filteredBranch = preBranch.filter(
        (selectedRegion: string) => selectedRegion !== branch
      );
      updateFormData(InputType.branch, filteredBranch);
    } else {
      updateFormData(InputType.branch, [...preBranch, branch]);
    }
  };

  const onLoanStatusSelect = (status: string) => {
    const preLoanStatus =
      filterForm.loanStatus && filterForm.loanStatus.length
        ? [...filterForm.loanStatus]
        : [];
    if (preLoanStatus.includes(status)) {
      const filteredLoanStatus = preLoanStatus.filter(
        (selectedRegion: string) => selectedRegion !== status
      );
      updateFormData(InputType.loanStatus, filteredLoanStatus);
    } else {
      updateFormData(InputType.loanStatus, [...preLoanStatus, status]);
    }
  };

  const onBranchDelete = (index: number) => {
    const preBranch =
      filterForm.branch && filterForm.branch.length
        ? [...filterForm.branch]
        : [];
    preBranch.splice(index, 1);
    updateFormData(InputType.branch, preBranch);
  };

  const onLoanStatusDelete = (index: number) => {
    const preLoanStatus =
      filterForm.loanStatus && filterForm.loanStatus.length
        ? [...filterForm.loanStatus]
        : [];
    preLoanStatus.splice(index, 1);
    updateFormData(InputType.loanStatus, preLoanStatus);
  };

  const isFilterFormValid = () => {
    const isPosValid =
      filterForm.pos &&
      (filterForm.posValue ||
        (filterForm.posMaxValue && filterForm.posMinValue));
    const isBucketValid = filterForm.bucket;
    const isCycleValid = filterForm.cycle?.length;
    const isRegion = filterForm?.region?.length;
    const isBranch = filterForm?.branch?.length;
    const isProductTypeValid = filterForm.segment?.length;
    const isLoanStatusValid = filterForm?.loanStatus?.length;

    return (
      !!isPosValid ||
      !!isBucketValid ||
      !!isCycleValid ||
      !!isProductTypeValid ||
      !!isRegion ||
      !!isBranch ||
      !!isLoanStatusValid
    );
  };

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton
              text="Apply Filter"
              onClick={onApply}
              disabled={!isFilterFormValid()}
            />
          </Stack>
        );
      }}
    >
      <Stack spacing={'1.5rem'}>
        <VegaFormInputField label={'pos'}>
          <VegaRadioGroup
            options={getPosOption()}
            value={filterForm.pos}
            onChange={e => {
              updateFormData(InputType.pos, e.target.value);
            }}
          />
        </VegaFormInputField>
        {filterForm.pos && filterForm.pos !== ComparisonType.Range && (
          <Collapse
            in={filterForm.pos && filterForm.pos !== ComparisonType.Range}
          >
            <VegaFormInputField label={'pos value'}>
              <LmTextField
                value={filterForm.posValue}
                onChange={e => {
                  updateFormData(InputType.posValue, e.target.value);
                }}
                startAdornment={<RupeeIcon />}
              />
            </VegaFormInputField>
          </Collapse>
        )}
        {filterForm.pos === ComparisonType.Range && (
          <Collapse in={filterForm.pos === ComparisonType.Range}>
            <Stack direction={'column'} spacing={2}>
              <VegaFormInputField label={'POS Max Value'}>
                <LmTextField
                  value={filterForm.posMaxValue}
                  onChange={e => {
                    updateFormData(InputType.posMaxValue, e.target.value);
                  }}
                  startAdornment={<RupeeIcon />}
                />
              </VegaFormInputField>
              <VegaFormInputField label={'POS Min Value'}>
                <LmTextField
                  value={filterForm.posMinValue}
                  onChange={e => {
                    updateFormData(InputType.posMinValue, e.target.value);
                  }}
                  startAdornment={<RupeeIcon />}
                />
              </VegaFormInputField>
            </Stack>
          </Collapse>
        )}

        <VegaFormInputField label={'bucket'}>
          <BucketSelector
            isMultiSelect
            selected={filterForm?.bucket ?? ''}
            handleChange={selected => onBucketSelect(selected)}
            handleDelete={index => onBucketDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Cycle'}>
          <CycleSelector
            isMultiSelect
            selected={filterForm?.cycle ?? ''}
            handleChange={selected => onCycleSelect(selected)}
            handleDelete={index => onCycleDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Segment'}>
          <VegaSelect
            options={getSegementOption()}
            value={filterForm?.segment ?? ''}
            onChange={e => {
              updateFormData(InputType.segment, e.target.value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Region'}>
          <RegionSelector
            isMultiSelect
            selected={filterForm?.region ?? []}
            handleChange={selected => onRegionSelect(selected)}
            handleDelete={index => onRegionDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Branch'}>
          <AllBranchSelector
            isMultiSelect
            selected={filterForm?.branch ?? []}
            handleChange={selected => onBranchSelect(selected)}
            handleDelete={index => onBranchDelete(index)}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Loan Status'}>
          <LoanAccountSelector
            isMultiSelect
            selected={filterForm?.loanStatus ?? ''}
            handleChange={selected => onLoanStatusSelect(selected)}
            handleDelete={index => onLoanStatusDelete(index)}
          />
        </VegaFormInputField>
      </Stack>
    </VegaDrawerContent>
  );
};

export default AccountFilterForm;

const getPosOption = () => {
  const options = Object.keys(POS).map((comparison: string, index: number) => {
    const comparisonType = Object.values(POS)[index];
    return {
      label: toLowerCase(comparison),
      value: comparisonType,
    } as VegaRadioOption;
  });
  return options;
};

const getSegementOption = () => {
  const options = Object.values(VerticalTypes).map((segment: string) => {
    return {
      label: segment,
      value: segment,
    } as VegaSelectOption;
  });
  return options;
};
