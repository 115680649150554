import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import VegaText from '../../../../../components/common/VegaText';
import { AccountMovementMtdResultDto } from '../../../../../types/analytics';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
interface IProps {
  selectedCollectionProfitType: string;
}

const MTDAccountMovmentGraph = ({ selectedCollectionProfitType }: IProps) => {
  const { setSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    AccountMovementMtdResultDto[]
  >([]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: analyticsQueryResponse.map(item => item?.movement),
    },
    // yaxis: {
    //   title: {
    //     text: '',
    //   },
    // },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    tooltip: {},
    yaxis: {
      title: {
        text: 'In Percentage',
      },
    },
  };
  const series = [
    {
      name: 'LMSD',
      data: analyticsQueryResponse.map(item =>
        parseFloat(item?.lmsdPercentage?.toFixed(2))
      ),
    },
    {
      name: 'MTD',
      data: analyticsQueryResponse.map(item =>
        parseFloat(item?.mtdPercentage?.toFixed(2))
      ),
    },
  ];

  const getQueriesForEnum = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.ACCOUNT_MOVEMENT_MONTH_TILL_DATE,
        params: {
          type: selectedCollectionProfitType,
        },
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      const dummyObj = {} as any;
      setAnalyticsQueryResponse([dummyObj]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getQueriesForEnum();
  }, [selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper title="Account Movements">
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -10,
                  height: 50,
                  width: '100%',
                  paddingLeft: '40px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1', textAlign: 'center' }}>
                          {getPercentageValue(val)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={series}
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default MTDAccountMovmentGraph;

const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
