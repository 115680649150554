/* eslint-disable prettier/prettier */
/* eslint-disable no-empty */

enum StorageKeys {
  BULK_ALLOCATION_REQUEST_ID = 'BULK_ALLOCATION_REQUEST_ID',
  BULK_DE_ALLOCATION_REQUEST_ID = 'BULK_DE_ALLOCATION_REQUEST_ID',
  DAILY_CUSTOM_TARGET_REQUEST_ID = 'DAILY_CUSTOM_TARGET_REQUEST_ID',
  MONTHLY_CUSTOM_TARGET_REQUEST_ID = 'MONTHLY_CUSTOM_TARGET_REQUEST_ID',
  SCORECARD_REQUEST_ID = 'SCORECARD_REQUEST_ID',
  INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID = 'INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID',
  UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID = 'UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID',
  OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID = 'OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID',
  SPLIT_APPROVED_REQUEST_ID = 'SPLIT_APPROVED_REQUEST_ID',
  DOWNLOAD_MIN_VISIT_REQUEST_ID = 'DOWNLOAD_MIN_VISIT_REQUEST_ID',
  DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID = 'DOWNLOAD_AGENT_LEVEL_VISIT_REQUEST_ID',
}

class StorageUtility {
  static setItem<T>(key: StorageKeys, value: T): void {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (e) {}
  }

  static getItem<T>(key: StorageKeys): T | null {
    try {
      const jsonValue = localStorage.getItem(key);
      const value = jsonValue != null ? JSON.parse(jsonValue) : null;
      return value;
    } catch (e) {
      return null;
    }
  }

  static removeItem(key: StorageKeys): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {}
  }

  static clear(): void {
    try {
      localStorage.clear();
    } catch (error) {}
  }

  static getMultipleItems(
    keys: Array<StorageKeys>
  ): Record<StorageKeys, any> | undefined {
    try {
      const result = localStorage.multiGet(keys);
      const final = result.reduce((pre, curr) => {
        const val = curr[1] ? JSON.parse(curr[1]) : null;
        return {
          ...pre,
          [curr[0]]: val,
        };
      }, {} as Record<StorageKeys, any>);
      return final;
    } catch (err) {}
  }
}

export { StorageUtility, StorageKeys };
