import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { OverallPenalCollectionResultDto } from '../../../../../types/analytics';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import VegaRangeSlider from '../../../../../components/common/VegaRangeSlider';
import VegaText from '../../../../../components/common/VegaText';

interface IProps {
  selectedCollectionProfitType: string;
}

const PenalChargeCollectionGraph = ({
  selectedCollectionProfitType,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<OverallPenalCollectionResultDto>
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const resolutionPercent = analyticsQueryResponse
    ? analyticsQueryResponse?.mtdResolution ?? 0
    : 0;
  const collectedAmt = analyticsQueryResponse
    ? analyticsQueryResponse?.achieved ?? 0
    : 0;
  const totalAmount = analyticsQueryResponse
    ? analyticsQueryResponse?.target ?? 0
    : 0;
  const percentageChange = analyticsQueryResponse
    ? analyticsQueryResponse?.percentageChange ?? 0
    : 0;

  const getQueries = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.OVERALL_MONTHLY_PENAL_COLLECTION,
        params: {
          isMonthToDate: 'true',
          type: selectedCollectionProfitType,
        },
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  useEffect(() => {
    getQueries();
  }, [selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper title="Penal Charges Collection (Rs in crore)">
        {loading ? (
          <LoadingPage height={90} />
        ) : (
          <Stack pb={2} px={2}>
            <Stack>
              <VegaRangeSlider
                value={resolutionPercent}
                valueLabelDisplay="off"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <VegaText text={`Achieved (${collectedAmt})`} />
                <VegaText text={`Total (${totalAmount})`} />
              </Box>
              <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                paddingTop={2}
              >
                <VegaText
                  fontWeight={'600'}
                  fontSize={'0.6rem'}
                  text={` ${resolutionPercent} %`}
                />

                {getPercentageValue(percentageChange)}
              </Stack>
            </Stack>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default PenalChargeCollectionGraph;
const getPercentageValue = percentageChange => {
  const percentage = percentageChange ? Number(percentageChange.toFixed(2)) : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
