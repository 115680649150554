import { Stack } from '@mui/material';
import UploadSection from '../../components/SystemCollection/UploadSection';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import ListOfSystemCollection from '../../components/SystemCollection/ListOfSystemCollection';

const SystemCollection = () => {
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack>
              <VegaText
                text={'System Collection'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaText
                text={'Push data for IVR calling'}
                fontWeight={400}
                fontSize={12}
                color={'#676B76'}
              />
            </Stack>
          );
        }}
        sx={{ borderBottom: 'none' }}
      />
      <UploadSection />
      <ListOfSystemCollection />
    </>
  );
};

export default SystemCollection;
