import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import LmTextField from '../../../../components/common/LmTextField';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaText from '../../../../components/common/VegaText';
import {
  WriteOffRewardRecordsResponseDto,
  WriteOffSplitData,
} from '../../../../types/request/incentives';
import { COLOR } from '../../../../utils/ColorUtility';
import { SetSplitFormData } from './SetSplitDrawer';
import { StringUtility } from '../../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  data: WriteOffRewardRecordsResponseDto;
};

function ViewSplitDrawer({ open, onClose, data }: Props) {
  const [splitData, setSplitData] = useState<SetSplitFormData>({
    NCM: 0,
    ZCM: 0,
    RCM: 0,
    CCM: 0,
    ACM: 0,
    CM: 0,
    CE: 0,
  });

  function cleanup() {
    setSplitData({
      NCM: 0,
      ZCM: 0,
      RCM: 0,
      CCM: 0,
      ACM: 0,
      CM: 0,
      CE: 0,
    });
  }
  function handleClose() {
    onClose();
    cleanup();
  }

  function calculatePercentageOfPayout(x: number): number {
    if (x === 0) {
      return 0;
    }
    return (x / 100) * data?.finalLoanIncentiveAmount;
  }

  const transformAndSetFormData = (
    splitData: Record<string, WriteOffSplitData>
  ) => {
    const transformedData: SetSplitFormData = {
      NCM: splitData?.NCM?.splitPercentage || 0,
      ZCM: splitData?.ZCM?.splitPercentage || 0,
      RCM: splitData?.RCM?.splitPercentage || 0,
      CCM: splitData?.CCM?.splitPercentage || 0,
      ACM: splitData?.ACM?.splitPercentage || 0,
      CM: splitData?.CM?.splitPercentage || 0,
      CE: splitData?.CE?.splitPercentage || 0,
    };

    setSplitData(transformedData);
  };

  useEffect(() => {
    transformAndSetFormData(data?.splitData);
  }, [open]);

  return (
    <VegaDrawer open={open} title={'View Split'} onClose={handleClose}>
      <VegaDrawerContent>
        <Grid container direction={'column'} gap={1}>
          <Grid item xs={3}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaText
                text="LAN: "
                fontWeight={600}
                fontSize="14px"
                lineHeight="19.6px"
              />
              <VegaText
                text={data?.loanId ?? '-'}
                fontWeight={600}
                fontSize="14px"
                lineHeight="19.6px"
              />
            </Stack>
          </Grid>
          <Grid>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaText
                text="Payout: "
                fontWeight={700}
                fontSize="10px"
                lineHeight="12.4px"
                color={COLOR.GREY[50]}
              />
              <VegaText
                text={StringUtility.formatToINR(data?.finalLoanIncentiveAmount)}
                fontWeight={400}
                fontSize="12px"
                lineHeight="15.6px"
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container direction={'row'} gap={3} marginTop={4}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="Role"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
              color={COLOR.GREY[100]}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="Percentage"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
              color={COLOR.GREY[100]}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="Amount"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
              color={COLOR.GREY[100]}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="NCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField value={splitData?.NCM} disabled />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(splitData?.NCM ?? 0)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="ZCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField value={splitData?.ZCM} disabled />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              disabled
              value={calculatePercentageOfPayout(splitData.ZCM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="RCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField disabled value={splitData?.RCM} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(splitData.RCM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <VegaText
              text="CCM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField disabled value={splitData?.CCM} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(splitData.CCM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <VegaText
              text="ACM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <LmTextField value={splitData?.ACM} disabled />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(splitData.ACM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <VegaText
              text="CM"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <LmTextField disabled value={splitData?.CM} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(splitData.CM)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          gap={3}
          marginTop={3}
          alignItems={'center'}
        >
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <VegaText
              text="CE"
              fontWeight={500}
              fontSize="12px"
              lineHeight="15.6px"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField value={splitData?.CE} disabled />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <LmTextField
              placeholder="Amount"
              disabled
              value={calculatePercentageOfPayout(splitData.CE)}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ViewSplitDrawer;
