import { Box, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../Apis/IncentivesService';
import PolicyDetails from '../../components/IncentivesGamifications/components/PolicyDetails';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import { toLowerCase } from '../../constants/commonFunction';
import {
  DEFAULT_POLICY_DETAILS,
  getIncentivesGamificationsState,
  setPolicyDetailsState,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { IncentivePolicyProps } from '../../types/incentives';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import BucketViewPolicy from './BucketViewPolicy';
import { getHeaderText } from './IncentivesGamifications';
import { ClassTypeEnum } from '../../types/targets';
import WriteOffViewPolicy from './WriteOffViewPolicy';
import VegaButton from '../../components/common/VegaButton';
import LoadingPage from '../LoadingPage/LoadingPage';

const ViewPolicy = () => {
  const { policyDetailsState, selectedPolicyTab } = useAppSelector(
    getIncentivesGamificationsState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [selectedBucket, setSelectedBucket] = useState<string>('');
  const [selectedWriteOffPolicy, setSelectedWriteOffPolicy] =
    useState<string>('');
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { policyId } = useParams();

  const getPolicyById = (id: string) => {
    IncentiveServices.getPolicyByPolicyId(id)
      .then(res => {
        const policyDetails: Partial<IncentivePolicyProps> = {
          policyName: res.policyName,
          vertical: res.vertical,
          channelType: res.channelType,
          incentiveType: res.incentiveType,
          policyStartDate: res.policyStartDate,
          policyEndDate: res.policyEndDate,
          accountType: res.accountType,
          policyType: res.policyType,
          caseType: res.caseType,
          classType: res.classType,
          payoutFrequency: res.payoutFrequency,
          maxPayoutPerPerson: res.maxPayoutPerPerson,
          splitPayoutForUsersMap: res.splitPayoutForUsersMap,
          userType: res.userType,
          modeOfCommunication: res.modeOfCommunication,
          bucketsHavingRules: res.bucketsHavingRules.length
            ? res.bucketsHavingRules
            : [],
          rules: res.rules,
          writeOffRules: res.writeOffRules,
          chargeCollectedRules: res?.chargeCollectedRules,
          npaType: res.npaType,
        };
        const firstKey = Object.keys(policyDetails.rules)[0];
        const firstKeyOfWriteOff = Object.keys(policyDetails.writeOffRules)[0];
        dispatch(setPolicyDetailsState(policyDetails));
        if (policyDetails.classType === ClassTypeEnum.WRITE_OFF) {
          setSelectedWriteOffPolicy(firstKeyOfWriteOff);
        } else {
          setSelectedBucket(firstKey);
        }
        setLoading(false);
      })
      .catch(error => {
        dispatch(setPolicyDetailsState(DEFAULT_POLICY_DETAILS));
        setLoading(false);
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const exportWriteOffPolicy = async () => {
    setExportLoading(true);
    try {
      const url = await IncentiveServices.exportWriteOffPolicy(policyId);
      setExportLoading(false);
    } catch (error) {
      setExportLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  const writeOffExportView = () => {
    if (loading) {
      return () => {
        return <Skeleton width={80} height={35} />;
      };
    }
    if (
      policyDetailsState?.classType &&
      policyDetailsState.classType === ClassTypeEnum.WRITE_OFF
    ) {
      return () => {
        return (
          <VegaButton
            text="Export"
            onClick={exportWriteOffPolicy}
            loading={exportLoading}
          />
        );
      };
    } else {
      return () => {
        return <></>;
      };
    }
  };

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      getPolicyById(policyId);
    }
  }, [policyId]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: `${toLowerCase(
                    getHeaderText(selectedPolicyTab)
                  )} policy`,
                  link: -1,
                },
                {
                  label: !policyId
                    ? `Add On Policy`
                    : policyDetailsState?.policyName,
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
        renderRightView={writeOffExportView()}
      />
      <Box
        height={`calc(100% - 77px)`}
        sx={{
          overflow: 'auto',
        }}
      >
        <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
          <VegaTitleCard title="Policy Details">
            <PolicyDetails loading={loading} />
          </VegaTitleCard>
        </VegaPageContent>
        {loading ? (
          <LoadingPage height={500} />
        ) : policyDetailsState.classType ? (
          policyDetailsState.classType === ClassTypeEnum.WRITE_OFF ? (
            <WriteOffViewPolicy
              selectedWriteOffPolicy={selectedWriteOffPolicy}
              setSelectedWriteOffPolicy={setSelectedWriteOffPolicy}
            />
          ) : (
            <BucketViewPolicy
              selectedBucket={selectedBucket}
              setSelectedBucket={setSelectedBucket}
            />
          )
        ) : (
          <BucketViewPolicy
            selectedBucket={selectedBucket}
            setSelectedBucket={setSelectedBucket}
          />
        )}
      </Box>
    </>
  );
};

export default ViewPolicy;
