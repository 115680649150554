import { Skeleton, Stack } from '@mui/material';
import React, { RefObject, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WriteOffComponent from '../../components/IncentivesGamifications/modules/WriteOffComponent';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../components/common/VegaSelect';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import { toLowerCase } from '../../constants/commonFunction';
import {
  DEFAULT_WRITE_OFF_RULE,
  WriteOffPayoutPolicyType,
  getIncentivesGamificationsState,
  setSelectedWriteOffPayoutPolicyType,
  setSelectedWriteOffTab,
  setWriteOffTemplatesRules,
  setWriteOffruleState,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { TempleteListProps } from '../../types/incentives';
import { IncentiveWriteOffRulesRequest } from '../../types/request/incentives';
import { WriteOffEnum } from '../../types/targets';
import { IncentiveServices } from '../../Apis/IncentivesService';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useSnackbar } from '../../providers/SnackbarProvider';

interface IProps {
  scrollToTopRef: RefObject<HTMLDivElement>;
  filteredWriteOffRule: (
    rules: Partial<IncentiveWriteOffRulesRequest>,
    bucket: string
  ) => void;
}

const WriteOffRule = ({ filteredWriteOffRule }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const {
    selectedWriteOffTab,
    templates,
    writeOffTemplatesRules,
    selectedTab,
  } = useAppSelector(getIncentivesGamificationsState);
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const dispatch = useAppDispatch();
  const { policyId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');
  const { policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );

  const handleWriteOffRuleChange = (writeOffIncentiveType: WriteOffEnum) => {
    const writeOffRule = {
      ...DEFAULT_WRITE_OFF_RULE,
      writeOffIncentiveType,
    } as IncentiveWriteOffRulesRequest;
    dispatch(setWriteOffruleState(writeOffRule));
  };

  const onSelectRule = (selected: number) => {
    dispatch(setSelectedWriteOffTab(selected));
    dispatch(
      setSelectedWriteOffPayoutPolicyType(
        WriteOffPayoutPolicyType.INCENTIVE_PROPOSED
      )
    );
    if (!policyId && !idFromURL) {
      if (selected === 1) {
        handleWriteOffRuleChange(WriteOffEnum.ACTUAL);
      } else {
        handleWriteOffRuleChange(WriteOffEnum.TECHNICAL);
      }
    } else if (writeOffTemplatesRules) {
      if (selected === 1) {
        filteredWriteOffRule(writeOffTemplatesRules, WriteOffEnum.ACTUAL);
      } else {
        filteredWriteOffRule(writeOffTemplatesRules, WriteOffEnum.TECHNICAL);
      }
    } else {
      if (selected === 1) {
        filteredWriteOffRule(
          policyDetailsState.writeOffRules,
          WriteOffEnum.ACTUAL
        );
      } else {
        filteredWriteOffRule(
          policyDetailsState.writeOffRules,
          WriteOffEnum.TECHNICAL
        );
      }
    }
  };

  const getTemplateByTemplateId = () => {
    setTemplateLoading(true);
    IncentiveServices.getTemplate(selectedTemplate)
      .then(res => {
        const writeOffRules = res?.writeOffRules;
        if (writeOffRules && Object.keys(writeOffRules).length) {
          dispatch(setWriteOffTemplatesRules(writeOffRules));
          filteredWriteOffRule(writeOffRules, getWriteOffTab(selectedTab));
        }
        setTemplateLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setTemplateLoading(false);
      });
  };
  useEffect(() => {
    selectedTemplate.length && getTemplateByTemplateId();
  }, [selectedTemplate]);
  return (
    <>
      <VegaPageHeader
        sx={{ paddingTop: 0, borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <Stack
              direction={'row'}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <Stack>
                <VegaTabBar
                  value={selectedWriteOffTab}
                  onChange={(e, selected) => {
                    onSelectRule(selected);
                  }}
                >
                  {Object.keys(WriteOffEnum).map((writeOff: string) => (
                    <VegaTabBarItem
                      key={writeOff}
                      label={`${toLowerCase(writeOff)}`}
                    />
                  ))}
                </VegaTabBar>
              </Stack>
              <Stack width={200}>
                <VegaSelect
                  placeholder="Select template"
                  options={getTempleteOptions(templates)}
                  value={selectedTemplate}
                  onChange={e => setSelectedTemplate(e.target.value as string)}
                />
              </Stack>
            </Stack>
          );
        }}
      />
      {templateLoading ? (
        <VegaPageContent>
          <Skeleton height={500} />
        </VegaPageContent>
      ) : (
        <WriteOffComponent />
      )}
    </>
  );
};

export default WriteOffRule;

const getTempleteOptions = (templates: TempleteListProps[]) => {
  const options = templates.map(
    (template: TempleteListProps) =>
      ({
        label: template.templateName,
        value: template.templateId?.toString(),
      } as VegaSelectOption)
  );
  return options;
};

const getWriteOffTab = (tab: number) => {
  switch (tab) {
    case 1:
      return WriteOffEnum.ACTUAL;

    default:
      return WriteOffEnum.TECHNICAL;
  }
};
