export type VisitTrailDto = {
  id: string;
  loanId: string;
  agentId: string;
  customerName: string;
  primaryContact: string;
  alternateContact: string;
  updateTrailDate: string;
  contactStatus: ContactStatus;
  dispositionType: DispositionType;
  dispositionCode: DispositionCode;
  nextActionDate: string;
  nextAction: TrailNextAction;
  amountCollected: number;
  nonPaymentReason: NonPaymentReason;
  customerImageUrl: string;
  locationTagType: VisitLocationTagType;
  geoLocationDto: VisitLocationDto;
  agentRemarksForNonGeoVisit: string;
  agentRemarks: string;
  metadata?: Record<string, any>;
};

export enum DispositionCode {
  PTP = 'PTP',
  PAID = 'PAID',
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  FORECLOSED = 'FORECLOSED',
  CHEQUE_PICKUP = 'CHEQUE_PICKUP',
  THIRD_PARTY_PAYMENT = 'THIRD_PARTY_PAYMENT',
  OTHERS = 'OTHERS',
  BROKEN_PTP = 'BROKEN_PTP',
  REFUSE_TO_PAY = 'REFUSE_TO_PAY',
  FRAUD = 'FRAUD',
  POLICE_CASE = 'POLICE_CASE',
  SARFAESI_PASTING = 'SARFAESI_PASTING',
  REPOSSESSION = 'REPOSSESSION',
  DEATH_CASE = 'DEATH_CASE',
  CALLBACK = 'CALLBACK',
  REVISIT = 'REVISIT',
  STOP_FOLLOWUP = 'STOP_FOLLOWUP',
  REFER_TO_SUPERVISOR = 'REFER_TO_SUPERVISOR',
  ESCALATION_VISIT = 'ESCALATION_VISIT',
  CUSTOMER_SKIP = 'CUSTOMER_SKIP',
  LEFT_MESSAGE = 'LEFT_MESSAGE',
  RINGING = 'RINGING',
  SMS_SENT = 'SMS_SENT',
  WHATSAPP_SENT = 'WHATSAPP_SENT',
  IVR_SENT = 'IVR_SENT',
  LATE_PTP = 'LATE_PTP',
  INVALID_SWITCH_OFF = 'INVALID_SWITCH_OFF',
  UNANSWERED = 'UNANSWERED',
}

export type VisitLocationDto = {
  latitude: number;
  longitude: number;
};

export enum NonPaymentReason {
  FORGOT_TO_PAY = 'FORGOT_TO_PAY',
  LATE_SALARY = 'LATE_SALARY',
  MEDICAL_ISSUES = 'MEDICAL_ISSUES',
  DEATH_CASE = 'DEATH_CASE',
  LOSS_OF_JOB = 'LOSS_OF_JOB',
  FINANCIAL_LOSS = 'FINANCIAL_LOSS',
  OTHERS = 'OTHERS',
}

export enum VisitLocationTagType {
  NON_GEO_TAGGED_VISIT = 'NON_GEO_TAGGED_VISIT',
  GEO_TAGGED_VISIT = 'GEO_TAGGED_VISIT',
  PENDING = 'PENDING',
}

export enum ContactStatus {
  CONTACTED = 'CONTACTED',
  NON_CONTACTED = 'NON_CONTACTED',
  THIRD_PARTY_CONTACT = 'THIRD_PARTY_CONTACT',
}
export enum DispositionType {
  PAYMENT = 'PAYMENT',
  NON_PAYMENT = 'NON_PAYMENT',
}
export enum TrailNextAction {
  ESCALATE_TO_SUPERVISOR = 'ESCALATE_TO_SUPERVISOR',
  SEND_PAYMENT_LINK = 'SEND_PAYMENT_LINK',
  VISIT = 'VISIT',
  REVISIT = 'REVISIT',
  FOLLOW_UP_CALL = 'FOLLOW_UP_CALL',
  OTHERS = 'OTHERS',
}
export enum CustomerInteraction {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}
export const dispositionTypeForNonContactedCases = () => {
  return [
    DispositionCode.FRAUD,
    DispositionCode.POLICE_CASE,
    DispositionCode.REFER_TO_SUPERVISOR,
    DispositionCode.CUSTOMER_SKIP,
    DispositionCode.LEFT_MESSAGE,
    DispositionCode.RINGING,
    DispositionCode.OTHERS,
  ];
};

export const dispositionCodeMap: Record<DispositionType, DispositionCode[]> = {
  [DispositionType.PAYMENT]: [
    DispositionCode.PTP,
    DispositionCode.PAID,
    DispositionCode.PARTIAL_PAYMENT,
    DispositionCode.FORECLOSED,
    DispositionCode.CHEQUE_PICKUP,
    DispositionCode.THIRD_PARTY_PAYMENT,
    DispositionCode.OTHERS,
  ],
  [DispositionType.NON_PAYMENT]: [
    DispositionCode.BROKEN_PTP,
    DispositionCode.REFUSE_TO_PAY,
    DispositionCode.FRAUD,
    DispositionCode.POLICE_CASE,
    DispositionCode.SARFAESI_PASTING,
    DispositionCode.REPOSSESSION,
    DispositionCode.DEATH_CASE,
    DispositionCode.CALLBACK,
    DispositionCode.REVISIT,
    DispositionCode.STOP_FOLLOWUP,
    DispositionCode.REFER_TO_SUPERVISOR,
    DispositionCode.ESCALATION_VISIT,
    DispositionCode.OTHERS,
  ],
};

export function getEnumValueFromString<T>(
  enumObj: T,
  str?: string
): T[keyof T] | undefined {
  if (!str) {
    return;
  }
  return (enumObj as any)[str];
}

export type TeleAgentAudit = {
  id: string;
  agentId: string;
  employeeId: string;
  userFullName: string;
  extensionType: string;
  leadsetId: string;
  leadsetName: string;
  campaignId: string;
  campaignName: string | null;
  processId: string;
  processName: string;
  referenceId: string;
  modeOfCalling: string;
  callStartDateTime: string;
  callEndDateTime: string;
  customerName: string;
  disposeComment: string;
  firstDispose: string;
  secondDispose: string;
  thirdDispose: string;
  agentTalktimeSec: number;
  holdTime: number;
  muteTime: number;
  transferTime: number;
  conferenceTime: number;
  callRingtime: number;
  disconnectedBy: string;
  callBackDateTime: string;
  claimNo: string | null;
  mediaUrl: string;
  teleallocationid: string;
  loanid: string;
  loanviewurl: string;
  Username: string;
  Phone: string;
  Extension: string;
};

export interface TeleAgentAuditModal {
  id: string;
  agentId: string;
  lan: string | number;
  callId: string | number;
  callerId: string | number;
  customerNumber: string | number;
  status?: string;
  dispositionCode: string;
  pickupTime: string | number;
  duration: string | number;
  hangUpTime: string | number;
  hangUpBy: string | number;
  recording?: string;
}

export interface FeedbackResponse {
  id: string;
  agentId: string;
  callDisposeEventId: string;
  comment: string;
  managerId: string;
  rating: number;
  createdAt: string;
}
