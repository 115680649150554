import { Stack } from '@mui/material';
import _ from 'lodash';
import { fetchBidders } from '../../features/biddersSlice';
import { useAppDispatch } from '../../store';
import { BidderStatus } from '../../types/auction';
import VegaButton from '../common/VegaButton';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaRadioGroup, { VegaRadioOption } from '../common/VegaRadioGroup';

interface IProps {
  onClose: () => void;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  selectedStatus: string;
}

const AuctionUserFilterForm = ({
  onClose,
  selectedStatus,
  setSelectedStatus,
}: IProps) => {
  const dispatch = useAppDispatch();
  const onClearClick = () => {
    dispatch(fetchBidders({ page: 0, pageSize: 10 }));
    setSelectedStatus('');
    onClose();
  };
  const onSelectClick = () => {
    dispatch(fetchBidders({ page: 0, pageSize: 10, status: selectedStatus }));
    onClose();
  };
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClearClick}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton text="Apply Filter" onClick={onSelectClick} />
          </Stack>
        );
      }}
    >
      <Stack gap={2}>
        <VegaFormInputField label={'Status'}>
          <VegaRadioGroup
            options={getStatusOption()}
            value={selectedStatus}
            onChange={e => {
              setSelectedStatus(e.target.value);
            }}
          />
        </VegaFormInputField>
      </Stack>
    </VegaDrawerContent>
  );
};

export default AuctionUserFilterForm;

const getStatusOption = () =>
  Object.values(BidderStatus).map(item => {
    const option: VegaRadioOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
