import { Grid, Stack } from '@mui/material';
import LmDialog from '../../../components/common/LmDialog';
import VegaButton from '../../../components/common/VegaButton';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaText from '../../../components/common/VegaText';
import { RoleMaxPayoutDto } from '../../../types/incentives';

type Props = {
  open: boolean;
  onClose: () => void;
  payoutDetails: RoleMaxPayoutDto;
  onSubmitClick: (payoutDetails: RoleMaxPayoutDto) => void;
};

function SaveChangesDialog({
  open,
  onClose,
  onSubmitClick,
  payoutDetails,
}: Props) {
  return (
    <div>
      <LmDialog
        fullWidth
        open={open}
        handleClose={onClose}
        buttonComponent={() => (
          <>
            <Grid
              container
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              px={4}
              pb={4}
              gap={1}
            >
              <Grid item>
                <VegaButton
                  text="No, Thanks"
                  fullWidth
                  variant="text"
                  onClick={onClose}
                  sx={{
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '15.6px',
                  }}
                />
              </Grid>
              <Grid item>
                <VegaButton
                  fullWidth
                  text={'Yes, Save'}
                  onClick={() => onSubmitClick(payoutDetails)}
                  sx={{
                    fontWeight: 700,
                    fontSize: '12px',
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text={'Save Changes'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={`Are you sure you want to save changes to maximum payout for ${
              payoutDetails?.role ?? ''
            } role?`}
            fontWeight={500}
            fontSize={'0.875rem'}
            lineHeight={'1.225rem'}
          />
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
}

export default SaveChangesDialog;
