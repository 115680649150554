import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import VegaButton from '../../../../components/common/VegaButton';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import LmTextField from '../../../../components/common/LmTextField';
import { OtsService } from '../../../../Apis/OtsService';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

enum FormField {
  Comment = 'comment',
}

export type UpdateOtsForm = {
  comment: string;
};

function OtsRCMCommentView() {
  const { setSnackbar } = useSnackbar();
  const { ots, fetchOts } = useOts();
  const { user } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<UpdateOtsForm>>({});

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function updateOts() {
    try {
      setLoading(true);
      const comments = [];
      if (formData.comment) {
        comments.push(formData.comment);
      }
      if (comments.length <= 0) {
        fetchOts(ots.id);
        return;
      }
      await OtsService.updateOtsRequest({
        id: ots.id,
        comments: comments,
        userId: user?.id,
      });
      setFormData({
        comment: undefined,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <OtsDetailsWrapper>
        <Stack>
          <Grid
            container
            columnGap={2}
            paddingBottom={2}
            style={{ alignItems: 'end' }}
          >
            <Grid item xs={4}>
              <VegaFormInputField label={'RCM Comment'}>
                <LmTextField
                  placeholder="Write your comment here"
                  value={formData.comment}
                  onChange={e =>
                    updateFormData(FormField.Comment, e.target.value)
                  }
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={4}>
              <VegaButton
                text={'Add Comment'}
                loading={loading}
                onClick={updateOts}
              ></VegaButton>
            </Grid>
          </Grid>
        </Stack>
      </OtsDetailsWrapper>
    </>
  );
}

export default OtsRCMCommentView;
