import axios from 'axios';
import { ApiService } from '../types/api';
import { FetchLegalCaseDetailRequest } from '../types/request/legal';
import { LegalBaseResponse } from '../types/legal';

const Endpoint = {
  BASE: '/legal',
  CASE_DETAILS: '/case/details',
};

export class LegalServices extends ApiService {
  static async getLegalCaseDetail(
    request: Partial<FetchLegalCaseDetailRequest>
  ): Promise<LegalBaseResponse> {
    const endpoint = Endpoint.BASE + Endpoint.CASE_DETAILS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
}
