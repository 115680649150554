import { Stack } from '@mui/material';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import { RejectLeaveDialogCancelIcon } from '../Icons/Icons';
import { TeleAgentManualAccountViewModel } from '../../types/teleAgent';
import LmTextField from '../common/LmTextField';
import { useState } from 'react';
import VegaButton from '../common/VegaButton';
import { COLOR } from '../../utils/ColorUtility';
import { MetaDataLoanAllocationDto } from '../../types/loan';

interface ReferToFieldConfirmationDialogProps {
  show: boolean;
  onAcceptClick: (
    allocationData: TeleAgentManualAccountViewModel | MetaDataLoanAllocationDto,
    agentRemarks: string
  ) => void;
  onClose: () => void;
  allocationData?: TeleAgentManualAccountViewModel | MetaDataLoanAllocationDto;
}

const ReferToFieldConfirmationDialog = ({
  show,
  onAcceptClick,
  allocationData,
  onClose,
}: ReferToFieldConfirmationDialogProps) => {
  const [agentRemark, setAgentRemark] = useState<string>('');

  const isInputValid = () => {
    return agentRemark.length > 0;
  };

  function _onAcceptClick() {
    if (!allocationData) return;
    onAcceptClick(allocationData, agentRemark);
    _onCloseClick();
  }
  function _onCloseClick() {
    onClose();
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={_onCloseClick}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            variant="outlined"
            fullWidth
            text="Cancel"
            onClick={() => {
              _onCloseClick();
            }}
            style={{
              color: COLOR.GREY[100],
              backgroundColor: COLOR.WHITE[100],
              borderColor: COLOR.WHITE[50],
            }}
            startIcon={
              <RejectLeaveDialogCancelIcon strokeColor={COLOR.GREY[100]} />
            }
          />
          <VegaButton
            text="Yes,Refer To Field"
            fullWidth
            variant="contained"
            style={{
              color: COLOR.WHITE[100],
              backgroundColor: COLOR.BLUE[300],
              borderColor: COLOR.WHITE[50],
            }}
            onClick={() => {
              _onAcceptClick();
            }}
            disabled={isInputValid() === false}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E7EDFC'}
        >
          <VegaText
            text="Confirmation"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'#1B1D22'}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" pt="36px">
        <VegaText
          text={`Are you sure you want to Proceed `}
          style={{
            color: '#1B1D22',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '120%',
            fontStyle: 'normal',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
          }}
        />
        <LmTextField
          placeholder="Enter reason here"
          minRows={4}
          onChange={e => setAgentRemark(e.target.value)}
        />
      </Stack>
    </LmDialog>
  );
};
export default ReferToFieldConfirmationDialog;
