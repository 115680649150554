import axios from 'axios';
import { VegaUser } from '../types/claim';
import {
  GetUserForManagerRequest,
  GetUserListApiRequest,
  GetUserRoleRequest,
} from '../types/request/user';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/user',
  GET_USER_BY_ID: '/{id}',
  GET_AGENTS: '/agents',
  LITS_USERS: '/list',
  GET_USERS_FOR_MANAGER: '/:id/users',
  GET_USERS_FOR_MANAGER_NEW: '/manager/users',
  EXPORT_USERS: '/export',
  GET_ALL_MANAGERS: '/all/managers',
  GET_AGENT_CODE: '/all/codes',
  GET_AGENT_NAME: '/all/names',
  GET_RBAC_USERS: '/rbac/users',
};

const BASE_URL = Endpoint.BASE;
export class UserService {
  static async getUserById(userId: string): Promise<VegaUser> {
    const endpoint = BASE_URL + Endpoint.GET_USER_BY_ID.replace('{id}', userId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getAgents(): Promise<VegaUser[]> {
    const endpoint = BASE_URL + Endpoint.GET_AGENTS;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getUsers(
    request: Partial<GetUserListApiRequest>
  ): Promise<PaginatedResponse<VegaUser>> {
    const endpoint = BASE_URL + Endpoint.LITS_USERS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getUsersFromUserId(): Promise<Record<string, string>> {
    const endpoint = BASE_URL + Endpoint.GET_AGENT_NAME;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getAgentsForManager(userId: string): Promise<VegaUser[]> {
    const endpoint =
      BASE_URL + Endpoint.GET_USERS_FOR_MANAGER.replace(':id', userId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getAgentsForManagerPaginate(
    request: Partial<GetUserForManagerRequest>
  ): Promise<PaginatedResponse<VegaUser>> {
    const endpoint = BASE_URL + Endpoint.GET_USERS_FOR_MANAGER_NEW;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getReporteesData(userId: string) {
    const endpoint =
      BASE_URL +
      Endpoint.GET_USERS_FOR_MANAGER.replace(':id', userId) +
      Endpoint.EXPORT_USERS;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getAllManagers(): Promise<Record<string, string>> {
    const endpoint = BASE_URL + Endpoint.GET_ALL_MANAGERS;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getAgentCode(): Promise<Record<string, string>> {
    const endpoint = BASE_URL + Endpoint.GET_AGENT_CODE;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getUsersForRole(
    data: Partial<GetUserRoleRequest>
  ): Promise<PaginatedResponse<VegaUser>> {
    const endpoint = Endpoint.GET_RBAC_USERS;
    const response = await axios.post(endpoint, data);
    return response.data;
  }
}
