import { isCapriUATEnv, isCapriDevEnv } from '../constants/url';
export const APPROVER_ID: string =
  isCapriUATEnv || isCapriDevEnv
    ? 'e4cac2c1-bb34-4dbe-a44b-e4b211eaa53d'
    : '20d472c3-2089-4d49-95b0-4bae77cd1fce';
export const BIDDER_USER_ID: string = '54381e76-2f1a-4a5e-b1db-2714210d29b3';
export const CLIENT_ID: string = 'a0ac1cd2-54a2-11ee-8c99-0242ac120002';
export const LEAVE_APPROVER_ID: string =
  isCapriUATEnv || isCapriDevEnv
    ? 'e4cac2c1-bb34-4dbe-a44b-e4b211eaa53d'
    : '20d472c3-2089-4d49-95b0-4bae77cd1fce';
export const VISIT_ID: string = 'acd8f50e-25c8-4550-8d46-b7db559f7676';

export const IDS = {
  OTS: {
    AGENT: '07fa691e-f04c-4ed9-bad4-ad4d26f1f9e1',
    RCM: '087e8272-5c86-498f-9969-228e7a589910',
    NCM: '008f79fb-473b-4982-809c-7bdf2174929f',
    HOC: '01e3b584-8780-417c-8f8e-9bb46743477d',
    AGENDA_CREATOR: '036d55ac-e7da-4f01-ae3d-c7ff7b98b9e6',
    MD: '06c431a2-0238-4ddf-920c-8c00313169ff',
    TECHNICAL_TEAM: '064fcf7c-1348-4c06-badf-16da210ddea2',
    OPERATION_TEAM: '04fe41f4-a9bb-4c07-8c95-9d079385c874',
  },
};
