import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ExpenseService } from '../Apis/ExpenseService';
import { CalenderService } from '../Apis/calenderServices';
import { toLowerCase } from '../constants/commonFunction';
import { RootState } from '../store';
import {
  AccountForCalenderViewModal,
  ReceiptDtos,
} from '../types/CalenderType';
import { ExpenseReport } from '../types/claim';
import { ICalenderRequest } from '../types/request/CalenderRequest';
import { GetExpenseReportsRequest } from '../types/request/claim';
import { DispositionCode, VisitTrailDto } from '../types/trail';

export const fetchCalender = createAsyncThunk(
  'calender/fetchCalender',
  async (request: Partial<ICalenderRequest>) => {
    const response = await CalenderService.getAccountListForCalender(request);
    return response;
  }
);
export const fetchExpenseReport = createAsyncThunk(
  'calender/fetchExpenseReport',
  async (request: Partial<GetExpenseReportsRequest>) => {
    const response = await ExpenseService.getGetExpenseReports(request);
    return response;
  }
);

export type CombineType = VisitTrailDto | ReceiptDtos;

export interface CalenderSliceProps {
  accounts: AccountForCalenderViewModal[];
  expenseReport: ExpenseReport[];
  loading: boolean;
  error: string;
}

const initialState: CalenderSliceProps = {
  accounts: [],
  expenseReport: [],
  loading: false,
  error: '',
};

const calenderSlice = createSlice({
  name: 'calender',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCalender.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCalender.fulfilled, (state, action) => {
        const accountObj = { ...action.payload };
        const accountList = {
          ...accountObj,
          Dtos: [
            ...accountObj.loanVisitTrailDtos,
            ...accountObj.receiptsDtos,
            ...accountObj.scheduledVisits,
            ...accountObj.expenseClaimReportDtos,
          ],
        };
        const accountLoanVisit = accountList.Dtos.map((loanVisit: any) => {
          const AccountViewModal: AccountForCalenderViewModal = {
            id: loanVisit.id,
            name: loanVisit.customerName
              ? loanVisit.customerName
              : loanVisit?.payeeName,
            lan: loanVisit.loanId ?? '--',
            action: getActionDisplayText(loanVisit, accountObj.date),
            dispositionCode: loanVisit.dispositionCode
              ? toLowerCase(loanVisit.dispositionCode)
              : '--',
          };
          return AccountViewModal;
        });

        state.loading = false;
        state.accounts = accountLoanVisit;
      })
      .addCase(fetchCalender.rejected, (state, action) => {
        state.loading = false;
        state.accounts = [];
        state.error = action.error.message || 'Failed to fetch min visit rule';
      })
      .addCase(fetchExpenseReport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExpenseReport.fulfilled, (state, action) => {
        const expenses = [...action.payload.records];

        state.loading = false;
        state.expenseReport = expenses;
      })
      .addCase(fetchExpenseReport.rejected, (state, action) => {
        state.loading = false;
        state.expenseReport = [];
        state.error = action.error.message || 'Failed to fetch min visit rule';
      });
  },
});

export const getCalenderState = (state: RootState) => state.calender;
export default calenderSlice;

const getActionDisplayText = (Dtos: any, selectedDate: string) => {
  if (!Dtos) return '--';
  if (Dtos.visitStatus) {
    return 'Visit Schedule';
  } else if (Dtos.status) {
    return 'Receipt Created';
  } else {
    const dispositionCode = Dtos.dispositionCode;
    if (selectedDate == Dtos.nextActionDate) {
      return dispositionCode == DispositionCode.BROKEN_PTP
        ? 'Broken PTP Scheduled. '
        : 'PTP Scheduled';
    }
    return 'Trail Updated';
  }
};
