import { Collapse, Grid, InputAdornment, Stack } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { ExpenseService } from '../../Apis/ExpenseService';
import { RED } from '../../constants/style';
import { getExpenseClaim } from '../../features/expenseClaimSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppSelector } from '../../store';
import {
  ConveyanceVehicleType,
  getDisplayTextForVehicleType,
} from '../../types/claim';
import { DateUtility } from '../../utils/DateUtlility';
import { RupeeIcon } from '../Icons/Icons';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import LmTextField from '../common/LmTextField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import VegaText from '../common/VegaText';
import { UpdateConveyanceRequest } from '../../types/request/claim';

type Props = {
  open: boolean;
  onClose: () => void;
};

export type CreateConveyancePolicyFormData = {
  vehicleType: string;
  ratePerKm: number;
  startDate: string;
  endDate: string;
};

enum FormField {
  VehicleType = 'vehicleType',
  RatePerKm = 'ratePerKm',
  StartDate = 'startDate',
  endDate = 'endDate',
}

function ConveyancePolicyInputDrawer({ open, onClose }: Props) {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const { expenseConveyanceRate } = useAppSelector(getExpenseClaim);
  const [formData, setFormData] = useState<
    Partial<CreateConveyancePolicyFormData>
  >({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function onRateChange(value: string) {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    updateFormData(
      FormField.RatePerKm,
      isValidNumber ? parsedNumber : undefined
    );
  }

  function onStartDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    if (!parsedDate) return;
    setFormData(prev => {
      const endDate = DateUtility.parseStringToDate(prev.endDate);
      if (endDate) {
        const isStartDateAfterEndDate = DateUtility.isAfter(
          parsedDate,
          endDate
        );
        if (isStartDateAfterEndDate) {
          return {
            ...prev,
            startDate: parsedDate.toISOString(),
            endDate: parsedDate.toISOString(),
          };
        }
      }
      return { ...prev, startDate: parsedDate.toISOString() };
    });
  }

  function onEndDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    if (!parsedDate) return;
    setFormData(prev => {
      return { ...prev, endDate: parsedDate.toISOString() };
    });
  }

  async function onSaveDetailsClick() {
    try {
      setLoading(true);
      setError(undefined);
      if (expenseConveyanceRate != undefined) {
        await updateConveyance();
      } else {
        await createConveyance();
      }
      _onClose();
    } catch (error) {
      setError(`${(error as any).message}`);
    } finally {
      setLoading(false);
    }
  }

  async function createConveyance() {
    await ExpenseService.createConveyanceRate({
      userId: user?.id,
      ratePerKm: formData.ratePerKm,
      vehicleType: formData.vehicleType,
      startDate: formData.startDate,
      endDate: formData.endDate,
    });
    setSnackbar('Conveyance Rate Added');
  }

  async function updateConveyance() {
    const request: Partial<UpdateConveyanceRequest> = {
      userId: user?.id,
      id: expenseConveyanceRate.id,
      ratePerKm: formData.ratePerKm,
      vehicleType: formData.vehicleType,
      startDate: formData.startDate,
      endDate: formData.endDate,
    };
    const existingRate = expenseConveyanceRate.ratePerKm;
    const existingVehicleType = expenseConveyanceRate.vehicleType;
    const existingStartDate = expenseConveyanceRate.startDate;
    const existingEndDate = expenseConveyanceRate.endDate;

    if (existingRate == formData.ratePerKm) {
      delete request.ratePerKm;
    }

    if (existingVehicleType == formData.vehicleType) {
      delete request.vehicleType;
    }

    if (existingStartDate == formData.startDate) {
      delete request.startDate;
    }

    if (existingEndDate == formData.endDate) {
      delete request.endDate;
    }

    await ExpenseService.updateConveyanceRate(request);
    setSnackbar('Conveyance Rate Updated');
  }

  const isInputValid = () => {
    const isRateValid = (formData.ratePerKm ?? 0) > 0;
    const isVehicleTypeValid = (formData.vehicleType ?? '').length > 0;
    const isStartDateValid = (formData.startDate ?? '').length > 0;
    const isEndDateValid = (formData.endDate ?? '').length > 0;
    return (
      isRateValid && isVehicleTypeValid && isStartDateValid && isEndDateValid
    );
  };

  function _onClose() {
    onClose();
    cleanUp();
  }

  function cleanUp() {
    setFormData({});
  }

  useEffect(() => {
    if (expenseConveyanceRate) {
      const formData = {
        vehicleType: expenseConveyanceRate.vehicleType,
        ratePerKm: expenseConveyanceRate.ratePerKm,
        startDate: expenseConveyanceRate.startDate,
        endDate: expenseConveyanceRate.endDate,
      } as CreateConveyancePolicyFormData;
      setFormData(formData);
    }
  }, [expenseConveyanceRate]);

  return (
    <VegaDrawer open={open} title={'Set Conveyance Rate'} onClose={_onClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text={'Save Details'}
              disabled={isInputValid() == false}
              onClick={onSaveDetailsClick}
              loading={loading}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label="Type Of Vehicle">
              <VegaSelect
                loading={loading}
                options={vehicleOptions()}
                value={formData.vehicleType ?? ''}
                onChange={e => {
                  updateFormData(
                    FormField.VehicleType,
                    e.target.value as string
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
          <AnimatedInputWrapper
            show={formData.vehicleType != null}
            marginTop={'1.25rem'}
          >
            <LmTextField
              placeholder="Amount"
              header="Amount"
              value={formData.ratePerKm ?? ''}
              onChange={e => onRateChange(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <RupeeIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <VegaText text={'Per km'} />
                </InputAdornment>
              }
            />
          </AnimatedInputWrapper>

          <Grid item xs={12} marginTop={'1.25rem'}>
            <Collapse in={formData.vehicleType != null} timeout={300}>
              <Stack direction={'row'} columnGap="1rem">
                <LmDateField
                  header="Start Date"
                  onChange={onStartDateChange}
                  value={formData.startDate}
                  disabled
                />
                <LmDateField
                  header="End Date"
                  onChange={onEndDateChange}
                  minDate={formData.startDate}
                  value={formData.endDate}
                  disabled
                />
              </Stack>
            </Collapse>
          </Grid>
          <AnimatedInputWrapper show={error != null} marginTop={'1.25rem'}>
            <VegaText text={error ?? ''} color={RED.red} />
          </AnimatedInputWrapper>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ConveyancePolicyInputDrawer;
type AnimatedInputWrapperProps = {
  show: boolean;
  marginTop: string;
  children: ReactNode;
};

const AnimatedInputWrapper = ({
  show,
  marginTop,
  children,
}: AnimatedInputWrapperProps) => {
  return (
    <Grid item xs={12} style={{ marginTop: show ? marginTop : '0rem' }}>
      <Collapse in={show} timeout={300}>
        {children}
      </Collapse>
    </Grid>
  );
};

const vehicleOptions = () =>
  [
    ConveyanceVehicleType.SELF_TWO_WHEELER,
    ConveyanceVehicleType.SELF_FOUR_WHEELER,
  ].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: getDisplayTextForVehicleType(item),
    };
    return option;
  });
