/** @format */

export const URL = {
  CLIENT: 'www.client.vegapay.tech',
  PROGRAM: 'www.program.vegapay.tech',
  PROTOTYPE: 'www.simulator.vegapay.tech',
  BACK_OFFICE: 'www.back-office.vegapay.tech',
  DEV: 'www.dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',
  DEV_SOLFIN: 'solfin.vegapay.tech',
  PROD_SOLFIN: 'finance.solfin.co.in',
  CURRENT: window.location.hostname,
  LOCAL: 'localhost',
  FOREX_BRANCH: 'www.branch.vegapay.tech',
  FOREX_CORPORATE: 'www.corporate.vegapay.tech',
  COLLECTIONS_CAPRI_DEV: 'collectxpress-dev.capriglobal.in',
  COLLECTIONS_CAPRI_UAT: 'vegapay-collections-uat.capriglobal.in',
  COLLECTIONS_CAPRI_PROD: 'collectxpress.capriglobal.in',
};

export const HOSTNAME = {
  CLIENT: 'client.vegapay.tech',
  PROGRAM: 'program.vegapay.tech',
  PROTOTYPE: 'simulator.vegapay.tech',
  BACK_OFFICE: 'back-office.vegapay.tech',
  DEV: 'dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',
  CURRENT: window.location.hostname,
  LOCAL: 'localhost',
  FOREX_BRANCH: 'branch.vegapay.tech',
  FOREX_CORPORATE: 'corporate.vegapay.tech',
  SOLFIN: 'solfin.vegapay.tech',
  COLLECTIONS_CAPRI_UAT: 'vegapay-collections-uat.capriglobal.in',
};

export const ACCESS_MAP: any = {};

export const DATE_FORMATE = 'DD-MM-YYYY';

export type DashboardURLs = keyof typeof URL;
export const currentHost = window.location.hostname;
export const isDev = true;
export const isCapriDevEnv = URL.CURRENT === URL.COLLECTIONS_CAPRI_DEV;
export const isCapriUATEnv = URL.CURRENT === URL.COLLECTIONS_CAPRI_UAT;
export const isCapriProdEnv = URL.CURRENT === URL.COLLECTIONS_CAPRI_PROD;
// export const isCapriDevEnv = true;
export const isLocalPath = URL.CURRENT === URL.LOCAL;

export const isCurrentPathClient = URL.CURRENT === URL.CLIENT;

export const isCurrentPathPrototype = URL.CURRENT === URL.PROTOTYPE;

export const isCurrentPathProgram = URL.CURRENT === URL.PROGRAM;

export const isCurrentPathBackOffice = URL.CURRENT === URL.BACK_OFFICE;

export const getUrl = (hostname: DashboardURLs) => {
  return `https://${URL[hostname]}`;
};
