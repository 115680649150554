import dayjs from 'dayjs';

export enum DateIndicateEnum {
  PLUS = 'PLUS',
  MINUS = 'MINUS',
}

export class DateUtility {
  static parseStringToDate(dateString?: string): Date | undefined {
    if (!dateString) return;
    return new Date(dateString);
  }

  static toISOWithoutTimezone(date: Date) {
    const pad = number => (number < 10 ? '0' + number : number);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Construct ISO string without timezone information
    return (
      year +
      '-' +
      month +
      '-' +
      day +
      'T' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds
    );
  }

  static formatDateToDDMMYYYY(date?: Date): string | undefined {
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());

    return `${day}/${month}/${year}`;
  }
  static formatDateToDDMMYY(date?: Date): string | undefined {
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear()).slice(-2); // Take last two digits of the year

    return `${day}/${month}/${year}`;
  }
  static formatStringToDDMMMMYYYY(date?: string): string | undefined {
    if (!date) return '--';

    return dayjs(date).format('DD MMMM YYYY');
  }

  static formatStringToDDMMMMYYYYWithTime(date?: string): string | undefined {
    if (!date) return '--';

    return dayjs(date).format('DD MMMM YYYY HH:mm:ss');
  }

  static formatStringToDDMMYYYY(dateString?: string): string | undefined {
    if (!dateString) return;
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  }
  static formatStringToYYYYMMDD(dateString?: string): string | undefined {
    if (!dateString) return;
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  }

  static formatStringToDDMMYYYYWithTime(
    dateString?: string
  ): string | undefined {
    if (!dateString) return;
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0'); // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  static setTimeToStartOfDay(date?: Date): Date | undefined {
    if (!date) return;
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  static setTimeToEndOfDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  }

  static getTimeFromDate(date?: Date): string | undefined {
    if (!date) return;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  static getDateOnly(date: Date): Date {
    return new Date(date.toDateString());
  }

  static isAfter(date1: Date, date2: Date): boolean {
    return date1 > date2;
  }

  static isBefore(date1: Date, date2: Date): boolean {
    return date1 < date2;
  }

  static isEqual(date1: Date, date2: Date): boolean {
    return date1.getTime() === date2.getTime();
  }

  static parseDateFromDatePicker(e: any): Date | undefined {
    if (!e) {
      return;
    }
    const date = e.$d;
    const isInValidDate = isNaN(date.getTime());
    if (isInValidDate == true) {
      return;
    }
    return DateUtility.parseStringToDate(date);
  }
  static toISOString(
    date: any,
    indicate: DateIndicateEnum
  ): string | undefined {
    if (!date) {
      return;
    }
    const dateFormate = new Date(date);
    if (indicate === DateIndicateEnum.PLUS) {
      dateFormate.setMinutes(dateFormate.getMinutes() + 330);
    } else {
      dateFormate.setMinutes(dateFormate.getMinutes() - 330);
    }
    return dateFormate?.toISOString();
  }

  static parseTimeFromTimePicker(e: any): string | undefined {
    const date = DateUtility.parseDateFromDatePicker(e);
    return DateUtility.getTimeFromDate(date);
  }

  static addDays(date: Date, daysToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysToAdd);
    return newDate;
  }
  static previousDays(date: Date, daysToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - daysToAdd);
    return newDate;
  }

  static subtractDays(date: Date, daysToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - daysToSubtract);
    return newDate;
  }

  static addHours(date: Date, hoursToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setHours(date.getHours() + hoursToAdd);
    return newDate;
  }

  static subtractHours(date: Date, hoursToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setHours(date.getHours() - hoursToSubtract);
    return newDate;
  }

  static addMinutes(date: Date, minutesToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() + minutesToAdd);
    return newDate;
  }

  static subtractMinutes(date: Date, minutesToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - minutesToSubtract);
    return newDate;
  }

  static addMonths(date: Date, monthsToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + monthsToAdd);
    return newDate;
  }

  static subtractMonths(date: Date, monthsToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() - monthsToSubtract);
    return newDate;
  }

  static addYears(date: Date, yearsToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() + yearsToAdd);
    return newDate;
  }

  static subtractYears(date: Date, yearsToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() - yearsToSubtract);
    return newDate;
  }
  static financialYear() {
    const months = [
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    var startYear;
    var endYear;

    if (currentMonth < 3) {
      startYear = currentYear - 1;
      endYear = currentYear;
    } else {
      startYear = currentYear;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      endYear = currentYear + 1;
    }

    const financialYearMonths = [];
    for (let i = 0; i < months.length; i++) {
      financialYearMonths.push(
        `${months[i]}-${(startYear + Math.floor((i + 3) / 12))
          .toString()
          .slice(-2)}`
      );
    }

    return financialYearMonths;
  }

  static getDateWithMonthName(date: any): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day}${getOrdinalNumber(day)} ${month}, ${year}`;
  }

  static parseToLocalDate(dateString?: string): string | undefined {
    if (!dateString) {
      return;
    }
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) {
      return;
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  }

  static addAsiaKolkataTimeZoneDifference(date: string) {
    let updatedDate = this.addHours(this.parseStringToDate(date), 5);
    updatedDate = this.addMinutes(updatedDate, 30);
    return updatedDate;
  }
  static differenceInDays(date1: Date, date2: Date): number {
    return dayjs(date1).diff(dayjs(date2), 'day');
  }

  static getStartOfMonth(year?: number, month?: number): Date | undefined {
    if (!year || !month) return;
    const startDate = new Date(year, month - 1, 1);
    return startDate;
  }

  static getEndOfMonth(year?: number, month?: number): Date | undefined {
    if (!year || !month) return;
    const endDate = new Date(year, month, 0);
    return endDate;
  }
  static getStartOfQuarter(year: number, quarter: number): Date | undefined {
    if (quarter < 1 || quarter > 4) return;

    const startMonth = (quarter - 1) * 3 + 1;

    return new Date(year, startMonth - 1, 1);
  }
  static getEndOfQuarter(year: number, quarter: number): Date | undefined {
    if (quarter < 1 || quarter > 4) return;

    const endMonth = (quarter - 1) * 3 + 3;

    return new Date(year, endMonth, 0);
  }
  static convertToISTDateString(dateOrString: Date | string): string {
    let parsedDate: Date;

    if (dateOrString instanceof Date) {
      parsedDate = dateOrString;
    } else {
      parsedDate = new Date(dateOrString);

      if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date string');
      }
    }

    const istOffset = 5.5 * 60 * 60 * 1000;

    const istDate = new Date(parsedDate.getTime() + istOffset);

    const istDateString = istDate.toISOString();

    return istDateString;
  }
}
export const getOrdinalNumber = (day: number): string => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
