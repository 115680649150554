/* eslint-disable no-unused-vars */
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ClientServices } from '../Apis/ClientServices';
import { RoleServices } from '../Apis/RoleServices';
import { UserService } from '../Apis/UserService';
import { setFilteredRoles } from '../features/allocationSlice';
import {
  setIsAuth,
  setLoader,
  setLoginRoute,
  setRoutes,
} from '../features/authSlice';
import { LM_ROUTES } from '../router';
import { UiModule } from '../router/routes';
import { useAppDispatch } from '../store';
import { ResourceDto, VegaUser } from '../types/claim';
import { RoleDto } from '../types/roleType';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';

export type ClientContextType = {
  clientId: string | null;
  userId: string | null;
  user?: VegaUser;
  isAuthenticated: boolean;
  resources: ResourceDto[];
  allModules: Set<string>;
  collectionsUsers: Map<String, string>;

  roles: RoleDto[];
  login: (event: any, email: string, password: string) => void;
  logout: () => void;
  hasAccessToModuleOrSubmodule: (moduleName: UiModule) => boolean;
  completeNewPassword: (newPassword: string) => void;
  userForId: (userId: string) => string | undefined;
  agentIdToManager: (agentId: string) => string | undefined;
  collectorCodeForAgentId: (agentId: string) => string | undefined;
};

type Props = {
  children: React.ReactNode;
};

const ClientContext = React.createContext<ClientContextType | null>(null);
export const useClientAuth = () =>
  React.useContext(ClientContext) as ClientContextType;

const ClientAuthProvider = ({ children }: Props) => {
  const existingUserId = localStorage.getItem('userId');
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [resources, setResources] = useState<ResourceDto[]>([]);
  const [loginChallange, setLoginChallange] = useState<any>(null);
  const [allModules, setAllModules] = useState<Set<string>>(new Set<string>());
  const [user, setUser] = useState<VegaUser>();
  const [collectionUsers, setCollectionUsers] = useState<Map<string, string>>(
    new Map()
  );
  const [collectionAgentToManager, setCollectionAgentToManager] = useState<
    Map<string, string>
  >(new Map());
  const [agentIdToCollectorCode, setAgentIdToCollectorCode] = useState<
    Map<string, string>
  >(new Map());

  const [roles, setRoles] = useState<RoleDto[]>([]);

  const logout = () => {
    localStorage.clear();
    localStorage.clear();
    setUser(undefined);
    setCollectionUsers(new Map());
    setResources([]);
    dispatch(setIsAuth(false));
  };

  const bidderLogin = async (email: string, password: string) => {
    Auth.signIn(email, password)
      .then(async user => {
        setSnackbar(`Login successfull`);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoginChallange({ forceChange: true, user: user });
          dispatch(setLoginRoute('NEW_PASSWORD'));
        }
        const userId = user.signInUserSession.idToken.payload['custom:userId'];
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        // setRefreshToken(refreshToken);
        localStorage.setItem('accessToken', accessToken);
        await fetchUser(userId);
        dispatch(setIsAuth(true));
      })
      .catch(error => {
        setSnackbar(`${error.message}`, 'error');
        dispatch(setLoader(false));
      });
  };
  const collectionLogin = async (email: string, password: string) => {
    try {
      const response = await ClientServices.login({
        username: email,
        password,
        app: 'Collections',
        isApp: false,
      });
      const accessToken = response.accessToken;
      const userId = response.userId;

      localStorage.setItem('accessToken', accessToken);
      await fetchUser(userId);
      dispatch(setIsAuth(true));

      // dispatch(setAccessToken(accessToken));
    } catch (error) {
      setSnackbar('Email ID or Password is incorrect', 'error');
      dispatch(setLoader(false));
    }
  };

  const login = async (event: any, email: string, password: string) => {
    dispatch(setLoader(true));

    try {
      if (event != null) {
        event.preventDefault();
      }
      const response = await ClientServices.getRolesByEmail(email);
      if (response && response.length) {
        const roles = [...response];
        const arrayOfRoleName = roles.map((role: RoleDto) => role.roleDto.name);
        if (arrayOfRoleName.includes('BIDDER')) {
          await bidderLogin(email, password);
        } else {
          await collectionLogin(email, password);
        }
      } else {
        await collectionLogin(email, password);
        dispatch(setLoader(false));
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      dispatch(setLoader(false));
    }
  };

  async function fetchUser(userId: string) {
    dispatch(setLoader(true));
    try {
      const userResponse = await ClientServices.getUserDetails(userId);
      setUser(userResponse);
      const clientId = userResponse.clientId;
      localStorage.setItem('clientId', clientId);
      localStorage.setItem('userId', userId);
      await fetchResources(userId);
      await fetchRolesForUser(userResponse.id);
      await fetchAllUsers();
      await fetchAllUsersName();
      await fetchAllManagers();
      await fetchAllAgentCodes();
      dispatch(setLoader(false));
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        logout();
      }
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      dispatch(setLoader(false));
    }
  }

  async function fetchAllUsers() {
    const response = await UserService.getUsers({
      size: 1000,
    });
    const userMap = new Map<string, VegaUser>();
    (response.records ?? []).forEach(i => userMap.set(i.id, i));
    // setCollectionUsers(userMap);
  }
  async function fetchAllUsersName() {
    const response = await UserService.getUsersFromUserId();
    const agentToNameMap = new Map<string, string>();
    for (const [agentId, agentName] of Object.entries(response)) {
      agentToNameMap.set(agentId, agentName);
    }
    setCollectionUsers(agentToNameMap);
  }

  async function fetchAllManagers() {
    const response = await UserService.getAllManagers();

    const agentToManagerMap = new Map<string, string>();
    for (const [agentId, managerName] of Object.entries(response)) {
      agentToManagerMap.set(agentId, managerName);
    }
    setCollectionAgentToManager(agentToManagerMap);
  }

  async function fetchAllAgentCodes() {
    const response = await UserService.getAgentCode();
    const agentToCodeMap = new Map<string, string>();
    for (const [agentId, agentCode] of Object.entries(response)) {
      agentToCodeMap.set(agentId, agentCode);
    }
    setAgentIdToCollectorCode(agentToCodeMap);
  }

  function _agentIdToCollectorCode(agentId: string) {
    return agentIdToCollectorCode.get(agentId);
  }

  function _managerForAgent(agentId: string) {
    return collectionAgentToManager.get(agentId);
  }

  function _userForId(userId: string) {
    return collectionUsers.get(userId);
  }

  const checkIsAdmin = (roles: RoleDto[]) => {
    const isAdmin = roles.some(
      (role: RoleDto) => role?.roleDto?.name === 'ADMIN'
    );
    return isAdmin;
  };

  async function fetchRolesForUser(userId: string) {
    try {
      const response = await RoleServices.getRolesByUserId(userId);
      const newRolesSet = response.map((role: RoleDto) => {
        return {
          branch: role.branch,
          region: role.region,
          isAdmin: checkIsAdmin(response),
        };
      });

      const uniqueBranches = new Set();
      const filteredRoles = newRolesSet.filter(item => {
        const branch = item.branch;
        if (!uniqueBranches.has(branch)) {
          uniqueBranches.add(branch);
          return true;
        }
        return false;
      });
      dispatch(setFilteredRoles(filteredRoles));
      setRoles(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function fetchResources(userId: string) {
    try {
      const resources = await ClientServices.getResourcesForUser(userId);
      setResources(resources);
      mapResourcesToRoutes(resources);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const hasAccessToModuleOrSubmodule = (moduleName: UiModule) => {
    const hasAccess = allModules.has(moduleName);
    return hasAccess;
  };

  function mapResourcesToRoutes(resources: ResourceDto[]) {
    const moduleMap = new Map<string, Set<string>>();
    const moduleSet = new Set<string>();
    const allModules = new Set<string>();
    resources.forEach(i => {
      const module = i.resourceDto.module;
      const subModule = i.resourceDto.subModule;
      var existingSubModules = moduleMap.get(module) ?? new Set<string>();
      existingSubModules.add(subModule);
      moduleMap.set(module, existingSubModules);
      moduleSet.add(module);
      allModules.add(module);
      allModules.add(subModule);
    });
    setAllModules(allModules);

    const availableModules = LM_ROUTES.filter(item => {
      const hasModule = moduleSet.has(item.module);
      return hasModule;
    }).map(item => {
      const availableSubModules = moduleMap.get(item.module);
      const existingSubmodules = item?.children?.filter(item => {
        return availableSubModules.has(item.module);
      });
      const updatedModule = { ...item };
      updatedModule.children = existingSubmodules;
      return updatedModule;
    });
    dispatch(setRoutes(availableModules));
  }

  const completeNewPassword = async (newPassword: string) => {
    if (loginChallange?.forceChange) {
      try {
        let user = loginChallange.user;
        try {
          const { requiredAttributes } = user.challengeParam;
          user = await Auth.completeNewPassword(
            loginChallange.user as CognitoUser,
            newPassword,
            requiredAttributes
          );
          dispatch(setLoginRoute('LOGIN'));
        } catch (e) {
          window.console.log(e);
        }
      } catch (e) {
        window.console.log('Failed to update', e);
      }
    }
  };

  useEffect(() => {
    if (existingUserId) {
      fetchUser(existingUserId);
    }
  }, [existingUserId]);

  return (
    <ClientContext.Provider
      value={{
        clientId: user?.clientId,
        user: user,
        userId: user?.id,
        resources: resources,
        roles: roles,
        login: login,
        logout: logout,
        allModules: allModules,
        isAuthenticated: user?.id != null,
        hasAccessToModuleOrSubmodule: hasAccessToModuleOrSubmodule,
        completeNewPassword,
        collectionsUsers: collectionUsers,
        userForId: _userForId,
        agentIdToManager: _managerForAgent,
        collectorCodeForAgentId: _agentIdToCollectorCode,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientAuthProvider;
