export enum ConfigurationType {
  LEAVE_REQUEST = 'LEAVE_REQUEST',
  MIN_MAX_VISITS = 'MIN_MAX_VISITS',
  ROUTE_DEVIATION = 'ROUTE_DEVIATION',
  EXPENSE_MANAGEMENT = 'EXPENSE_MANAGEMENT',
  OTS = 'OTS',
  GEO_VERIFICATION = 'GEO_VERIFICATION',
  ALERT_NOTIFICATIONS_AGENT = 'ALERT_NOTIFICATIONS_AGENT',
  ALERT_NOTIFICATIONS_SUPERVISOR = 'ALERT_NOTIFICATIONS_SUPERVISOR',
  NCM_DPD = 'NCM_DPD',
  ALLOCATION_ACR = 'ALLOCATION_ACR',
}
export enum ConfigurationStatusType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface ConfigValueProps {
  minVisits: number;
  maxVisits: number;
  posMinValue: number;
  posMaxValue: number;
  leaveRequestDeadline: number;
  reminderTimePeriod: number;
  escalationDeadline: number;
  approvalDeadline: number;
  routeDeviationCount: number;
  breachReasonDeadline: number;
  reminderPeriod: number;
  fieldAcrLimit: number;
  teleAcrLimit: number;
  expenseApprovalDeadline: string;
  targetDeviationClosureDeadline: string;
  minVisitBreachDeadline: string;
  startTime: string;
  endTime: string;
  targetEntryDeadline: string;
  targetReasonDeadline: string;
  agentId: string;
  agentName: string;
  agentTatDays: number;
  maxResubmissions: number;
  ncmTatDays: number;
  rcmTatDays: number;
  hocTatDays: number;
  mdTatDays: number;
  chairTatDays: number;
  opsTatDays: number;
  techTatDays: number;
  customerAckTatDays: number;
  notificationFrequencyDays: number;
}

export interface ConfigurationDto {
  name: string;
  type: string;
  subtypes: string[];
  config: Partial<ConfigValueProps> | null;
}
export interface ConfigurationsListProps {
  id: string;
  name: string;
  type: string;
  subtype: string;
  status: string;
  config: {
    [key: string]: number;
  };
  updatedAt: string;
  createdAt: string;
}
