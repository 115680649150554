import { Stack } from '@mui/material';
import _ from 'lodash';
import { fetchExecutionAllocation } from '../../actions/AllocationActions';
import { useAppDispatch } from '../../store';
import { ExecuteStatus } from '../../types/allocations';
import VegaButton from '../common/VegaButton';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaRadioGroup, { VegaRadioOption } from '../common/VegaRadioGroup';

interface IProps {
  onClose: () => void;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  selectedStatus: string;
}

const ExecutionHistoryFilterForm = ({
  onClose,
  selectedStatus,
  setSelectedStatus,
}: IProps) => {
  const dispatch = useAppDispatch();

  const onClearClick = () => {
    onClose();
    setSelectedStatus('');
    dispatch(
      fetchExecutionAllocation({
        page: 0,
        size: 10,
      })
    );
  };
  const onSelectClick = () => {
    dispatch(
      fetchExecutionAllocation({
        page: 0,
        size: 10,
        status: selectedStatus,
      })
    );
    onClose();
  };
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClearClick}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton
              text="Apply Filter"
              onClick={onSelectClick}
              disabled={!selectedStatus.length}
            />
          </Stack>
        );
      }}
    >
      <Stack gap={2}>
        <VegaFormInputField label={'Status'}>
          <VegaRadioGroup
            options={getStatusOption()}
            value={selectedStatus}
            onChange={e => {
              setSelectedStatus(e.target.value);
            }}
          />
        </VegaFormInputField>
      </Stack>
    </VegaDrawerContent>
  );
};

export default ExecutionHistoryFilterForm;

const getStatusOption = () =>
  Object.values(ExecuteStatus).map(item => {
    const option: VegaRadioOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
