import React from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

export interface ISeriesProps {
  name: string;
  data: number[];
}

interface IProps {
  categories: string[];
  series: ISeriesProps[];
  yAxisTitle: string;
}

const PayoutGraph = ({ categories, yAxisTitle, series }: IProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories ? categories : ['loading'],
    },
    yaxis: {
      title: {
        text: yAxisTitle,
      },
      labels: {
        formatter: (value: number) => {
          return value.toFixed(2);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
  };
  return (
    <>
      <Chart options={options} series={series} type="bar" />
    </>
  );
};

export default PayoutGraph;
