import { Stack, SxProps, Theme } from '@mui/material';
import React from 'react';
import VegaText from '../../../components/common/VegaText';
import { PRIMARY } from '../../../constants/style';

interface IProps {
  title: string;
  children: JSX.Element;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  rightComponent?: JSX.Element;
}

const GraphWrapper = ({
  title,
  children,
  onClick,
  sx,
  rightComponent,
}: IProps) => {
  return (
    <Stack
      sx={{
        bgcolor: 'white',
        borderRadius: '12px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        height: '100%',
        p: 1,
      }}
      gap={1}
    >
      <Stack
        direction={'row'}
        justifyContent={title.length ? 'space-between' : 'end'}
        alignItems={'center'}
        px={title.length || rightComponent ? 1 : 0}
        pt={title.length || rightComponent ? 1 : 0}
      >
        {' '}
        {title.length ? (
          <VegaText
            text={title}
            fontSize={12}
            fontWeight={600}
            color={PRIMARY.darkBlue}
            sx={{
              cursor: 'pointer',
              ...sx,
            }}
            onClick={onClick}
          />
        ) : (
          ''
        )}
        {rightComponent && rightComponent}
      </Stack>
      {children}
    </Stack>
  );
};

export default GraphWrapper;
