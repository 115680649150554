import React from 'react';
import Select, { ActionMeta, Props, StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import { VegaSelectOption } from './VegaSelect';

const animatedComponents = makeAnimated();

export type IMultiSelectProps = {
  options: VegaSelectOption[];
  onChange: (
    option: readonly VegaSelectOption[],
    actionMeta: ActionMeta<VegaSelectOption>
  ) => void;
  value: any;
} & Props;

const colourStyles: StylesConfig<VegaSelectOption> = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    fontFamily: 'var(--font-Aspekta-400) !important',
    fontSize: 14,
    borderColor: '#C5C8D0',
    borderWidth: '1px',
    borderRadius: '6px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#C5C8D0',
      borderWidth: '1px',
      borderRadius: '6px',
    },
  }),
  option: styles => ({
    ...styles,
    fontFamily: 'var(--font-Aspekta-400) !important',
    fontSize: 14,
  }),
  placeholder: styles => ({
    ...styles,
    color: '#C5C8D0',
  }),
};

const VegaMultiSelect = ({
  options,
  onChange,
  value,
  ...rest
}: IMultiSelectProps) => {
  return (
    <>
      <Select
        {...rest}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={options}
        value={value}
        onChange={onChange}
        styles={colourStyles}
      />
    </>
  );
};

export default VegaMultiSelect;
