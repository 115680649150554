import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import AgentSelector from '../../../components/AgentSelector';
import VegaButton from '../../../components/common/VegaButton';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { scorecardIncentivesList } from '../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useAppDispatch } from '../../../store';
import HitsAndMisses from './modules/HitsAndMisses';
import OverallPerformance from './modules/OverallPerformance';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import ListOfAgentRegionRank from './modules/ListOfAgentRegionRank';
import { StorageUtility, StorageKeys } from '../../../utils/StorageUtility';
import { AllocationService } from '../../../Apis/AllocationService';
import { LongRunningTaskStatus } from '../../../types/allocations';

const getScoreCardRequestId = StorageUtility.getItem<string>(
  StorageKeys.SCORECARD_REQUEST_ID
)
  ? StorageUtility.getItem<string>(StorageKeys.SCORECARD_REQUEST_ID)
  : '';

const ScoreCard = () => {
  const { user } = useClientAuth();
  const [isScoreCardDownloadInProgress, setIsScoreCardDownloadInProgress] =
    useState<boolean>(false);
  const [scoreCardRequestId, setScoreCardRequestId] = useState<string>(
    getScoreCardRequestId
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [menegerId, setMenegerId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPublish = async () => {
    setIsLoading(true);
    try {
      await IncentiveServices.publishScorecard(
        menegerId.length ? menegerId : user.id
      );
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadScoreCard = async () => {
    setIsScoreCardDownloadInProgress(true);
    try {
      const response = await IncentiveServices.downloadScoreCard(user.id);
      if (response.status === 'COMPLETED') {
        setIsScoreCardDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SCORECARD_REQUEST_ID);
        setScoreCardRequestId('');
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
      } else {
        StorageUtility.setItem(StorageKeys.SCORECARD_REQUEST_ID, response.id);
        setScoreCardRequestId(response.id);
        setIsScoreCardDownloadInProgress(true);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getScoreCardDownloadStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsScoreCardDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SCORECARD_REQUEST_ID);
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
        setScoreCardRequestId('');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsScoreCardDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SCORECARD_REQUEST_ID);
        setSnackbar(response.result, 'error');
        setScoreCardRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    if (scoreCardRequestId.length) {
      const downloadingFile = setInterval(() => {
        getScoreCardDownloadStatus(scoreCardRequestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [scoreCardRequestId]);

  useEffect(() => {
    user &&
      dispatch(
        scorecardIncentivesList({
          agentId: menegerId.length ? menegerId : user.id,
        })
      );
  }, [user, menegerId]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Score Card'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />
      <VegaPageContent
        sx={{
          height: 'calc(100% - 78px)',
          overflow: 'auto',
        }}
        py={'1rem'}
      >
        <Stack gap={1}>
          <Stack
            direction={'row'}
            justifyContent={'end'}
            gap={1}
            sx={{
              p: 1,
              borderRadius: '12px',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            }}
          >
            <Stack width={400}>
              <AgentSelector
                selected={menegerId}
                onSelected={selected => setMenegerId(selected)}
                onClear={() => {
                  setMenegerId('');
                }}
              />
            </Stack>
            <VegaButton
              text="Publish Scorecard"
              loading={isLoading}
              onClick={onPublish}
            />
            <VegaButton
              text="Download"
              onClick={onDownloadScoreCard}
              loading={isScoreCardDownloadInProgress}
            />
          </Stack>
          <Stack
            gap={2}
            sx={{
              p: 1,
              borderRadius: '12px',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            }}
          >
            <VegaText
              text={'Overall Performance'}
              fontWeight={600}
              fontSize={'1rem'}
              color={'black'}
              sx={{
                p: 1,
              }}
            />
            <OverallPerformance />
          </Stack>
          <Stack
            gap={2}
            sx={{
              p: 1,
              borderRadius: '12px',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            }}
          >
            <VegaText
              text={'Rank in Region'}
              fontWeight={600}
              fontSize={'1rem'}
              color={'black'}
              sx={{
                p: 1,
              }}
            />
            <ListOfAgentRegionRank />
          </Stack>
          <Stack
            gap={2}
            sx={{
              p: 1,
              borderRadius: '12px',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            }}
          >
            <VegaText
              text={'Hits & Misses'}
              fontWeight={600}
              fontSize={'1rem'}
              color={'black'}
              sx={{
                p: 1,
              }}
            />
            <HitsAndMisses />
          </Stack>
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default ScoreCard;
