import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { fetchCampaignList } from '../../../actions/TeleAllocationAction';
import { getTeleAllocation } from '../../../features/teleAllocationSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GetCampaignListApiRequest } from '../../../types/request/teleAllocation';
import LmFilterSection from '../../common/LmFilterSection';
import VegaDataGrid from '../../common/VegaDataGrid';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';

const ListOfCampaignCreation = () => {
  const { campaigns, campaignTotalItems, loading } =
    useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const getCampaignList = () => {
    const request = {
      page,
      size,
    } as GetCampaignListApiRequest;
    if (search.length) {
      request.slashRtcCampaignName = search;
    }
    dispatch(fetchCampaignList(request));
  };

  useEffect(() => {
    if (search.length) {
      const getLoanData = setTimeout(getCampaignList, 300);
      return () => clearTimeout(getLoanData);
    } else {
      getCampaignList();
    }
  }, [dispatch, page, size, search]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Campaign '}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={campaigns}
          idColumn="campaignId"
          columns={getColumnDefinition()}
          paginationMode="server"
          rowCount={campaignTotalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
          page={page}
          pageSize={size}
          loading={loading}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfCampaignCreation;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Campaign Name',
      flex: 0.5,
    },
    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: 'Campaign Description',
      flex: 2,
    },
  ];
  return COL_DEF;
};
