/* eslint-disable prefer-const */
import { Box, Divider, Grid, Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../Apis/AnaltyticsService';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import {
  AnalyticsQueryDetailsTypeEnum,
  CelenderTypeEmun,
  RegionPerformanceRoleEnum,
} from '../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { AnalyticsSummaryResultDto } from '../../types/analytics';
import { AnalyticsQueryRequest } from '../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { GraphTypeEnum } from './AnalyticsDashboard';
import RoleBaseSegmentPerformance from './components/RoleBaseSegmentPerformance';
import AccountMovementContainer from './modules/DetailsPages/AccountMovementContainer';
import CollectionTeamContainer from './modules/DetailsPages/CollectionTeamContainer';
import CollectionValueContainer from './modules/DetailsPages/CollectionValueContainer';
import PenalContainer from './modules/DetailsPages/PenalContainer';
import TeleCallerContainer from './modules/DetailsPages/TeleCallerContainer';

const AnalyticsDetailsPage = () => {
  const { viewType, calenderType, profitType } = useParams();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<AnalyticsSummaryResultDto>
  >({});
  const [loading, setLoading] = useState<boolean>(false);

  const getValue = (value: number) => {
    if (!value) {
      return '0';
    }
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2).toString + ' Cr';
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2).toString + ' Lac';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2).toString + 'k';
    } else {
      return value.toFixed(2).toString();
    }
  };

  const getKeyValue = () => {
    let achievedKey = 'Achieved(CR)';
    let targetValue = '';
    let targetKey = '';
    let achievedValue = '';

    switch (viewType) {
      case GraphTypeEnum.COLLECTION_VALUE:
        targetKey = 'DRR Target(CR)';
        targetValue = `${getValue(analyticsQueryResponse?.target)}`;
        achievedValue = `${getValue(analyticsQueryResponse?.achieved)}`;
        break;
      case GraphTypeEnum.COLLECTION_VOLUME:
        achievedKey = 'Achieved';
        targetKey = 'DRR Target';
        targetValue = `${getValue(analyticsQueryResponse?.target)}`;
        achievedValue = `${getValue(analyticsQueryResponse?.achieved)}`;
        break;
      case GraphTypeEnum.PENAL_COLLECTION:
        achievedKey =
          calenderType === CelenderTypeEmun.MONTH
            ? 'Penal MTD(CR)'
            : 'Penal YTD(CR)';
        targetKey = 'Penal Target(CR)';
        targetValue = `${getValue(analyticsQueryResponse?.target)}`;
        achievedValue = `${getValue(analyticsQueryResponse?.achieved)}`;
        break;
      case GraphTypeEnum.COLLECTION_TEAM:
      case GraphTypeEnum.TELE_CALLERS:
        achievedKey = calenderType == CelenderTypeEmun.TODAY ? 'Achieved (CR)' : 'Achieved (%)';
        targetKey =
          calenderType === CelenderTypeEmun.MONTH
            ? 'LMSD'
            : calenderType === CelenderTypeEmun.YEAR
            ? 'LYSD'
            : 'DRR Target';
        targetValue =
          calenderType === CelenderTypeEmun.MONTH
            ? `${getValue(analyticsQueryResponse?.lmsdAchieved)} %`
            : calenderType === CelenderTypeEmun.YEAR
            ? `${getValue(analyticsQueryResponse?.lysdAchieved)} %`
            : getValue(analyticsQueryResponse?.target);
        achievedValue =
          calenderType === CelenderTypeEmun.MONTH
            ? `${getValue(analyticsQueryResponse?.mtdAchieved)} %`
            : calenderType === CelenderTypeEmun.YEAR
            ? `${getValue(analyticsQueryResponse?.ytdAchieved)} %`
            : getValue(analyticsQueryResponse?.achieved);

        break;
      default:
        break;
    }

    return {
      achievedKey,
      targetValue,
      targetKey,
      achievedValue,
    };
  };

  const getRequest = () => {
    if (viewType === GraphTypeEnum.COLLECTION_VALUE) {
      const request = {
        analyticsQueryType: AnalyticsQueryDetailsTypeEnum.TODAY_VALUE_SUMMARY,
        params: {
          type: profitType,
        },
      } as AnalyticsQueryRequest;
      return request;
    } else if (viewType === GraphTypeEnum.COLLECTION_VOLUME) {
      const request = {
        analyticsQueryType: AnalyticsQueryDetailsTypeEnum.TODAY_VOLUME_SUMMARY,
        params: {
          type: profitType,
        },
      } as AnalyticsQueryRequest;
      return request;
    } else if (viewType === GraphTypeEnum.PENAL_COLLECTION) {
      if (calenderType === CelenderTypeEmun.MONTH) {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.MTD_PENAL_SUMMARY,
          params: {
            type: profitType,
          },
        } as AnalyticsQueryRequest;
        return request;
      } else {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.YTD_PENAL_SUMMARY,
          params: {
            type: profitType,
          },
        } as AnalyticsQueryRequest;
        return request;
      }
    } else if (viewType === GraphTypeEnum.COLLECTION_TEAM) {
      if (calenderType === CelenderTypeEmun.MONTH) {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.MTD_VALUE_SUMMARY,
          params: {
            type: profitType,
            role: RegionPerformanceRoleEnum.CE,
          },
        } as AnalyticsQueryRequest;
        return request;
      } else if (calenderType === CelenderTypeEmun.YEAR) {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.YTD_VALUE_SUMMARY,
          params: {
            type: profitType,
            role: RegionPerformanceRoleEnum.CE,
          },
        } as AnalyticsQueryRequest;
        return request;
      } else {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.TODAY_VALUE_SUMMARY,
          params: {
            type: profitType,
            role: RegionPerformanceRoleEnum.CE,
          },
        } as AnalyticsQueryRequest;
        return request;
      }
    } else if (viewType === GraphTypeEnum.TELE_CALLERS) {
      if (calenderType === CelenderTypeEmun.MONTH) {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.MTD_VALUE_SUMMARY,
          params: {
            type: profitType,
            role: RegionPerformanceRoleEnum.TELE_COLLECTION_EXECUTIVE,
          },
        } as AnalyticsQueryRequest;
        return request;
      } else if (calenderType === CelenderTypeEmun.YEAR) {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.YTD_VALUE_SUMMARY,
          params: {
            type: profitType,
            role: RegionPerformanceRoleEnum.TELE_COLLECTION_EXECUTIVE,
          },
        } as AnalyticsQueryRequest;
        return request;
      } else {
        const request = {
          analyticsQueryType: AnalyticsQueryDetailsTypeEnum.TODAY_VALUE_SUMMARY,
          params: {
            type: profitType,
            role: RegionPerformanceRoleEnum.TELE_COLLECTION_EXECUTIVE,
          },
        } as AnalyticsQueryRequest;
        return request;
      }
    }
  };

  const getQueries = async () => {
    setLoading(true);
    try {
      const request = getRequest();
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setAnalyticsQueryResponse({});
      setLoading(false);
    }
  };
  useEffect(() => {
    if (
      viewType === GraphTypeEnum.COLLECTION_VALUE ||
      viewType === GraphTypeEnum.COLLECTION_VOLUME ||
      viewType === GraphTypeEnum.PENAL_COLLECTION ||
      viewType === GraphTypeEnum.COLLECTION_TEAM ||
      viewType === GraphTypeEnum.TELE_CALLERS
    ) {
      getQueries();
    }
  }, []);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaText
                text={'Analytics'}
                color={'rgba(27, 29, 34, 1)'}
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="0.975rem"
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => navigate(ROUTES.ANALYTICS)}
              />
              <div className="">/</div>
              <VegaText
                text={`${toLowerCase(viewType)}`}
                color={'#1047DC'}
                fontSize="0.75rem"
                fontWeight={500}
                lineHeight="0.975rem"
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <>
              {viewType === GraphTypeEnum.COLLECTION_VALUE ||
              viewType === GraphTypeEnum.COLLECTION_VOLUME ||
              viewType === GraphTypeEnum.PENAL_COLLECTION ||
              viewType === GraphTypeEnum.COLLECTION_TEAM ||
              viewType === GraphTypeEnum.TELE_CALLERS ? (
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Box
                    sx={{
                      border: '2px solid #9EBFFF',
                      py: 1,
                      minWidth: 230,
                      borderRadius: '50px',
                    }}
                  >
                    <Stack direction={'row'} gap={1}>
                      {getValueComponent(
                        getKeyValue().achievedKey,
                        getKeyValue().achievedValue,
                        loading
                      )}
                      <Divider
                        orientation="vertical"
                        sx={{
                          height: 'auto',
                        }}
                      />
                      {getValueComponent(
                        getKeyValue().targetKey,
                        getKeyValue().targetValue,
                        loading
                      )}
                    </Stack>
                  </Box>
                </Stack>
              ) : (
                ''
              )}
            </>
          );
        }}
      />
      <Box
        sx={{
          height: 'calc(100% - 76.49px)',
          overflow: 'auto',
        }}
      >
        <VegaPageContent my={'1rem'}>
          {viewType === GraphTypeEnum.COLLECTION_VALUE ||
          viewType === GraphTypeEnum.COLLECTION_VOLUME ? (
            <CollectionValueContainer />
          ) : viewType === GraphTypeEnum.COLLECTION_TEAM ? (
            <CollectionTeamContainer />
          ) : viewType === GraphTypeEnum.TELE_CALLERS ? (
            <TeleCallerContainer />
          ) : viewType === GraphTypeEnum.PENAL_COLLECTION ? (
            <PenalContainer />
          ) : viewType === GraphTypeEnum.ACCOUNT_MOVEMENT ? (
            <AccountMovementContainer />
          ) : viewType === GraphTypeEnum.ROLE_BASE ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RoleBaseSegmentPerformance
                  calenderType={calenderType}
                  selectedCollectionProfitType={profitType}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </VegaPageContent>
      </Box>
    </>
  );
};

export default AnalyticsDetailsPage;
const getValueComponent = (key: string, value: string, loading: boolean) => {
  return (
    <Stack
      gap={0.4}
      sx={{
        flex: 1,
        textAlign: 'center',
      }}
    >
      {loading ? (
        <Skeleton width={80} />
      ) : (
        <VegaText
          fontWeight={500}
          fontColor="#b77236"
          text={value}
          fontSize={'1rem'}
        />
      )}
      <VegaText
        text={key}
        color={'rgba(27, 29, 34, 1)'}
        fontSize="0.75rem"
        fontWeight={500}
        lineHeight="0.975rem"
      />
    </Stack>
  );
};
