import { Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserService } from '../../../Apis/UserService';
import { DeleteIcon } from '../../../components/Icons/Icons';
import UserSelector from '../../../components/UserSelector';
import VegaAutoComplete from '../../../components/common/VegaAutoComplete';
import VegaButton from '../../../components/common/VegaButton';
import VegaDateTimePicker from '../../../components/common/VegaDateTimePicker';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { VegaUser } from '../../../types/claim';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<CreateAgendaFormData>) => void;
};

enum FormField {
  Members = 'members',
  ChairPerson = 'chairPerson',
  ScheduledDateTime = 'scheduledDateTime',
}

export type CreateAgendaFormData = {
  members: IUserDto[];
  chairPerson: VegaUser;
  scheduledDateTime: Date;
};

interface IUserDto {
  id: string;
  name: string;
}

function CreateAgendaDrawer({ open, onClose, onSubmit }: Props) {
  const { collectionsUsers } = useClientAuth();
  const { user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [users, setUsers] = useState<IUserDto[]>([]);
  const [chairUsers, setChairUsers] = useState<VegaUser[]>([]);
  const [loadingChair, setLoadingChair] = useState<boolean>(false);
  const [chairSearchQuery, setChairSearchQuery] = useState<string>('');
  const [formData, setFormData] = useState<Partial<CreateAgendaFormData>>({
    scheduledDateTime: new Date(),
  });

  function getUserForId(userId: string) {
    const filtered = users.filter(i => i.id == userId) ?? [];
    if (filtered.length > 0) {
      return filtered[0];
    }
  }

  function updateFormData(field: FormField, value: any) {
    var updatedValue = { ...value };
    if (field == FormField.ChairPerson) {
      updatedValue = getUserForId(value);
    }
    setFormData(prev => {
      return {
        ...prev,
        [field]: updatedValue,
      };
    });
  }

  async function fetchUsers() {
    const usersArray = Array.from(collectionsUsers.entries());
    const userData = usersArray.reduce((map, [id, name]) => {
      map.push({ id, name });
      return map;
    }, []);
    setUsers(userData);
    fetchChairUsers();
  }

  async function fetchChairUsers() {
    try {
      setLoadingChair(true);
      const chairUsers = await UserService.getUsersForRole({
        roleNames: ['COMMITTEE_CHAIR'],
        // branch: user.branchCode,
        // region: user.region,
        size: 1000,
      });
      setChairUsers(chairUsers.records);
    } catch (error) {
      setSnackbar('Failed to fetch Chair persons', 'error');
    } finally {
      setLoadingChair(false);
    }
  }

  function addMember(userId: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingUsers = formData.members ?? [];
      const isAlreadyPresent = existingUsers.some(i => i.id == userId);
      const user = getUserForId(userId);
      if (isAlreadyPresent == false && user) {
        existingUsers.push(user);
      }
      updatedData.members = existingUsers;
      return updatedData;
    });
  }

  function removeMember(userId: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingUsers = formData.members ?? [];
      const isAlreadyPresent = existingUsers.some(i => i.id == userId);
      if (isAlreadyPresent) {
        const filteredUsers = existingUsers.filter(i => i.id != userId);
        updatedData.members = filteredUsers;
      }
      return updatedData;
    });
  }

  async function onCreateClick() {
    onSubmit(formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
    setChairSearchQuery('');
  }

  const filteredChairUsers = (searchQuery: string) => {
    const createOptionFromUser = (user: VegaUser) => {
      const fullName = StringUtility.concatenateStrings(
        ' ',
        user.firstName,
        user.middleName,
        user.lastName
      );
      const option: VegaSelectOption = {
        value: user.id,
        label: fullName,
      };
      return option;
    };

    if (searchQuery.length > 0) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      return chairUsers
        ?.filter(user => {
          const fullName = StringUtility.concatenateStrings(
            ' ',
            user.firstName,
            user.middleName,
            user.lastName
          ).toLowerCase();
          return fullName.includes(lowerCaseQuery);
        })
        .map(createOptionFromUser);
    }

    return chairUsers?.map(createOptionFromUser);
  };

  const isInputValid = () => {
    const isChairPersonValid = formData.chairPerson != null;
    const areMembersValid = (formData.members ?? []).length > 0;
    const isScheduledDateTimeValid = (formData.scheduledDateTime ?? '') != null;
    return isChairPersonValid && areMembersValid && isScheduledDateTimeValid;
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <VegaDrawer open={open} title={'Create Agenda'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Create Agenda'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Scheduled Date Time'}>
              <VegaDateTimePicker
                onChange={e => {
                  const parsedDate = DateUtility.parseDateFromDatePicker(e);
                  setFormData(prev => {
                    return {
                      ...prev,
                      scheduledDateTime: parsedDate,
                    };
                  });
                }}
                value={formData.scheduledDateTime}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Chair person'}>
              <VegaAutoComplete
                options={filteredChairUsers(chairSearchQuery)}
                handleChange={e => {
                  setChairSearchQuery(e.target.value);
                }}
                onSelect={selected => {
                  updateFormData(FormField.ChairPerson, selected);
                }}
                onClear={function (): void {
                  setChairSearchQuery('');
                }}
                selected={formData?.chairPerson?.id}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Add Members'}>
              <UserSelector
                selected={undefined}
                onSelected={selected => {
                  addMember(selected as string);
                }}
              />
              {/* <VegaAutoComplete
                options={users.map(
                  user =>
                    ({ value: user.id, label: user.name } as VegaSelectOption)
                )}
                handleChange={e => {
                  setUserSearchQuery(e.target.value);
                }}
                onSelect={selected => {
                  addMember(selected);
                }}
                onClear={function (): void {
                  setUserSearchQuery('');
                }}
                selected={undefined}
              /> */}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Members'}>
              <Stack gap={1}>
                {formData?.members?.map(i => {
                  return (
                    <div
                      key={i.id}
                      style={{
                        backgroundColor: COLOR.GREEN.LIGHT,
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <VegaText text={i.name} color={COLOR.GREEN.DARK} />
                      <IconButton onClick={() => removeMember(i.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                })}
              </Stack>
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default CreateAgendaDrawer;
