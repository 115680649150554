import { Box, Stack } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import LmDialog from '../../../../components/common/LmDialog';
import {
  getPropertiesState,
  setOpenBulkPropertyDialog,
} from '../../../../features/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import LmButton from '../../../../components/common/LmButton';
import VegaText from '../../../../components/common/VegaText';
import LmBulkUpload from '../../../../components/common/LmBulkUpload';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { PossessionService } from '../../../../Apis/PossessionService';
import { PRIMARY } from '../../../../constants/style';

type Props = {
  onModalClose: () => void;
};
const BulkPropertiesModal = ({ onModalClose }: Props) => {
  const { openBulkPropertyDialog } = useAppSelector(getPropertiesState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 10) {
          let uploadedSize = 0;
          setLoading(true);
          const interval = setInterval(() => {
            uploadedSize += 10000;
            const progress = (uploadedSize / file.size) * 100;
            setUploadProgress(progress);

            if (uploadedSize >= file.size) {
              clearInterval(interval);
              setLoading(false);
            }
          }, 1000);
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 10MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const onClose = () => {
    setSelectedFile(null);
    setUploadProgress(0);
    dispatch(setOpenBulkPropertyDialog(false));
    onModalClose();
  };

  const downloadSampleFile = async () => {
    try {
      const url = await PossessionService.downloadSampleFile();
      window.open(url, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const onUpload = async () => {
    setLoading(true);
    try {
      await PossessionService.uploadBulkPropertiesFile(selectedFile);
      setLoading(false);
      setSnackbar('Upload SuccessFull');
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={openBulkPropertyDialog}
      isCloseIcon
      handleClose={onClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          borderTop={'1px solid #E1E4EB'}
          justifyContent={'space-between'}
          px={4}
          py={3}
          gap={1}
        >
          <LmButton
            text="Download Sample File"
            variant="text"
            bgColor=""
            textColor={PRIMARY.darkBlue}
            onClick={downloadSampleFile}
          />
          <LmButton
            text="Upload"
            disabled={!selectedFile || loading}
            onClick={onUpload}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Bulk Upload"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <Box px={4} pt={3} pb={4}>
        <LmBulkUpload
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          error={error}
          uploadProgress={uploadProgress}
          loading={loading}
        />
      </Box>
    </LmDialog>
  );
};

export default BulkPropertiesModal;
