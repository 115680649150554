import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import HistoryReceiptManagment from './module/HistoryReceiptManagment';
import VegaButton from '../../components/common/VegaButton';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { GetReceiptsListRequest } from '../../types/request/receiptManagment';
import { ReceiptStatus } from '../../types/receiptManagment';
import { ReceiptManagmentService } from '../../Apis/ReceiptManagmentService';
import { getTitleFromTab } from './module/HistoryReceiptManagment';
import { UiModule } from '../../router/routes';
import { useAppSelector } from '../../store';
import { receiptState } from '../../features/receiptManagmentSlice';
export enum ApproverTabType {
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
  CASH_IN_HAND = 'CASH_IN_HAND',
}

const ReceiptApprover = () => {
  const { setSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [exporting, setExporting] = useState<boolean>(false);
  const onTabChange = (value: number) => {
    setSelectedTab(value);
  };
  const { historyReceiptManagementFilter } = useAppSelector(receiptState);

  const exportMetaData = async (request: GetReceiptsListRequest) => {
    const url = await ReceiptManagmentService.getReceiptExportData(request);
    window.open(url, '__blank__');
  };

  const exportReceiptMetaData = async () => {
    const request = {
      page: 0,
      size: 10,
    } as GetReceiptsListRequest;
    if (selectedTab === 0) {
      request.statusIn = ReceiptStatus.PENDING_APPROVAL;
    } else if (selectedTab === 1) {
      request.statusIn = `${ReceiptStatus.APPROVED},${ReceiptStatus.REJECTED}`;
      if (historyReceiptManagementFilter.dateFilter) {
        request.fromDate = historyReceiptManagementFilter.dateFilter.startDate;
        request.toDate = historyReceiptManagementFilter.dateFilter.endDate;
      }
      if (historyReceiptManagementFilter.statusFilter) {
        request.statusIn = historyReceiptManagementFilter.statusFilter;
      }

      // request.statusIn = `${ReceiptStatus.APPROVED},${ReceiptStatus.REJECTED}`;
    } else if (selectedTab === 2) {
      request.statusIn = `${ReceiptStatus.APPROVED_NOT_RECONCILED},${ReceiptStatus.APPROVED_AND_RECONCILED}`;
    } else if (selectedTab !== 1 && selectedTab !== 0) {
      request.statusIn = getTitleFromTab(selectedTab);
    }
    try {
      setExporting(true);
      await exportMetaData(request);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Receipt Management'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  onTabChange(selected);
                }}
              >
                <VegaTabBarItem label={toLowerCase(ApproverTabType.PENDING)} />
                <VegaTabBarItem label={toLowerCase(ApproverTabType.HISTORY)} />
                <VegaTabBarItem
                  label={toLowerCase(ApproverTabType.CASH_IN_HAND)}
                />
              </VegaTabBar>
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <VegaButton
              text={exporting ? 'Exporting...' : 'Export'}
              onClick={exportReceiptMetaData}
              loading={exporting}
            />
          );
        }}
      />
      <HistoryReceiptManagment
        selectedTab={selectedTab}
        isApprover
        module={UiModule.RECEIPT_APPROVER}
      />
    </>
  );
};

export default ReceiptApprover;
