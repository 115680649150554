export const NESTED_ROUTES = {
  CREATE: 'create',
  VIEW_DETAILS: 'view-details',
  EDIT: 'edit',
};

export const ROUTES = {
  ADMIN_HOME: '/admin-home',
  AGENT_HOME: '/agent-home',
  ANALYTICS: '/analytics',
  ANALYTICS_PARAMS: '/analytics/:viewType/:calenderType/:profitType',
  PORTFOLIO: '/portfolio',
  DIGITAL_COLLECTION: '/digital-collection',
  FIELD_APP_DASHBOARD: '/field-app-dashboard',
  COMMUNICATION_DETAILS: '/communication-details',
  VIEW_TRAJECTORY_DETAILS: '/view/:agentId/:selectedDate/:agentName',
  LOCATION_TRACKING: '/location-tracking',
  ACCOUNT: '/account',
  UAM: '/user-access-management',
  UAM_PARAMS: '/user-access-management/:params',
  UAM_PARAMS_ID: '/user-access-management/:params/:paramsId',
  CUSTOMER_BRANCH_INFO: '/customer-bank',
  DEPOSIT_BANK: '/deposit-bank',
  EXPENSE: '/expense',
  CLAIMS: '/expense/report/:reportId/claims',
  CONVEYANCE_POLICY: '/expense/conveyance-policy',
  AUCTION_USERS: '/auction/users',
  AUCTION_PROPERTIES: '/auction/properties',
  LIVE_AUCTION_DETAILS: '/auction/live/:auctionId',
  LIVE_AUCTIONS: '/auctions/live',
  ALLOCATION_POLICY: '/allocation/policy',
  ALLOCATION_POLICY_VIEW: '/allocation/policy/:criteriaId',
  ALLOCATION_BULK_ASSIGN: '/allocation/bulk-assign',
  ALLOCATION_ASSIGN: '/allocation/assign',
  LEAVE_APPLY: '/leave/apply',
  LEAVE_REQUEST_ACTIONS: '/leave/request-actions',
  RECEIPT_APPROVER: '/receipt-approver',
  RECEIPT_CANCELLATION: '/receipt-cancellation',
  RECEIPT_AGENT: '/receipt-agent',
  RECEIPT_AGENT_ISSUE_RECEIPT: '/receipt-agent/issue-receipt/:loanId',
  MONTHLY_PROGRESS: '/monthly-progress',
  MIN_NO_OF_VISITS: '/min-visits',
  TARGETS: '/targets',
  TARGETS_ADMIN: '/targets-admin',
  CUSTOM_TARGETS: '/custom-targets',
  TELE_ALLCATION_BULK: '/tele-allocation/bulk',
  TELE_ALLCATION_MANUAL: '/tele-allocation/manual',
  TELE_AGENT_MANUAL: '/tele-agent/manual',
  TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID: '/tele-agent/manual/:loanAccountId',
  TELE_AGENTS_LIST: '/tele-agent/list',
  TELE_AGENT_AUTO: '/tele-agent/auto',
  Trail_Page: '/trail',
  Trail_Detail_Page: '/trail/:loanAccountId',
  TELE_ALLOCATION_STATUS: '/tele-allocation/status',
  TELE_ALLOCATION_RULE_POLICY: '/tele-allocation/rule-policy',
  TELE_CALLING_METRICS: '/tele-calling-metrics',
  TELE_CALLING_METRICS_VIEW: '/tele-calling-metrics/:metric',
  TELE_ALLOCATION_BULK_LOAN_UPLOAD: '/tele-allocation/bulk-loan-upload',
  ACCOUNTS: '/accounts',
  ACCOUNT_BY_LOAN_ACCOUNT_ID: '/account/:loanAccountId',
  Refer_To_Field_Detail_Page: '/referToField/:loanAccountId',
  AGENTS: '/agents',
  CONFIGURATION: '/configuration',
  //// INCENTIVE \\\\\
  INCENTIVES_GAMIFICATIONS_POLICY: '/incentives-gamifications/policy',
  INCENTIVES_GAMIFICATIONS_FOR_MONTH: '/incentives-gamifications/for-month',
  INCENTIVES_GAMIFICATIONS_LEADERBOARD: '/incentives-gamifications/leaderboard',
  INCENTIVES_GAMIFICATIONS_SCORE_CARD: '/incentives-gamifications/score-card',
  INCENTIVES_GAMIFICATIONS_ADD: '/incentives-gamifications/policy/:policyType',
  INCENTIVES_GAMIFICATIONS_VIEW:
    '/incentives-gamifications/policy/view/:policyId',
  TEMPLATE_VIEW: '/incentives-gamifications/policy/template/view/:templateId',
  INCENTIVES_GAMIFICATIONS_EDIT:
    '/incentives-gamifications/policy/:edit/:policyId',
  //// INCENTIVE \\\\\
  INCENTIVES_GAMIFICATIONS_VIEW_AGENT_LEVEL:
    '/incentives-gamifications/policy/view-agent-level/:policyId',
  INCENTIVE_WRITEOFF_PAYOUT_ADMIN:
    '/incentives-gamifications/writeOffPolicyPayout',
  INCENTIVE_WRITEOFF_PAYOUT_HOC:
    '/incentives-gamifications/writeOffPolicyPayout-Hoc',
  INCENTIVE_MAX_ROLE_PAYOUT: '/incentives-gamifications/max-role-payout',
  INCENTIVE_OVERALL_PAYOUT_ADMIN: '/incentives-gamifications/overall-payout',
  INCENTIVE_OVERALL_PAYOUT_HOC: '/incentives-gamifications/overall-payout-hoc',
  CALENDER: '/calender',
  TELE_AGENT_AUDIT: '/tele-agent/audit',
  TELE_AGENT_AUDIT_HISTORY: '/tele-agent/audit/history/:loanAccountId',
  PERFOMANCE: '/perfomance',
  RISK_BASE_PERFOMANCE: '/risk-base-perfomance',
  PERFOMANCE_SUMMARY: '/perfomance/summary',
  ESCALATIONS: '/escalations',
  ESCALATIONS_TODAY: '/escalations/today',
  ESCALATIONS_LEAVE_REQUEST: '/escalations/leave-request',
  ESCALATIONS_MINIMUM_VISITS: '/escalations/minimum-visits',
  ESCALATIONS_DAILY_TARGET: '/escalations/daily-target',
  ESCALATIONS_ROUTE_DEVIATION: '/escalations/route-deviation',
  ESCALATIONS_ROUTE_DEVIATION_VIEW_BY_ID: '/escalations/route-deviation/:id',
  ESCALATIONS_EXPENSE_MANAGEMENT: '/escalations/expense-management',
  ESCALATIONS_OTS: '/escalations/ots',
  REFER_TO_FIELD: '/referToField',

  GEO_VERIFICATION: '/geo-verification',
  SYSTEM_COLLECTION: '/system-collection',
  OTS_HOME: '/ots-home',
  OTS_DETAILS: '/ots/:otsId',
  AGENDA_CASE_DETAILS: '/agenda-case/:caseId',
  OTS_AGENDA_CREATOR_PENDING: '/agenda-creator/pending',
  OTS_AGENDA_CREATOR_COMMITTEE: '/agenda-creator/committee',
  OTS_AGENDA_CREATOR_COMMITTEE_AGENT_ID:
    '/agenda-creator/committee/details/:agendaId',
  AGENDA_DETAILS: '/agenda-details/:agendaId',
  OTS_CHAIR: '/chair',
  NO_ACCESS: '/no-access',
  INCENTIVE_SIMULATOR: '/incentive-simulator',

  ROLL_FORWARD_REPORT: '/roll-forward-report',
};

export const SUB_ROUTES = {
  HOLIDAY_TYPE: '/holiday-type',
  HOLIDAY_NAME: '/holiday-name',
  EXPENSE_REPORT_DRAFT: '/expense-report-draft',
  EXPENSE_REPORT_ONGOING: '/expense-claim-ongoing',
  EXPENSE_REPORT_HISTORY: '/expense-claim-history',
  EXPENSE_REPORT_HISTORY_BY_ID: '/expense-claim-history/:reportId',
  EXPENSE_REPORT_SUBMITTED: '/expense-report-submitted',
  EXPENSE_CLAIM_DRAFT: '/expense-claim-draft',
  EXPENSE_CLAIM_SUBMITTED: '/expense-claim-submitted',
  OTS_AGENT: '/ots/agent',
  OTS_RCM: '/ots/rcm',
  OTS_NCM: '/ots/ncm',
  OTS_HOC: '/ots/hoc',
  OTS_TECHNICAL_TEAM: '/ots/technical-team',
  OTS_OPERATION_TEAM: '/ots/operation-team',
  OTS_MD: '/ots/md',
  OTS_MIS: '/ots/mis',
};

export const AUTH_ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password',
};

export enum UiModule {
  LOGIN = 'LOGIN',
  MASTER = 'MASTER',
  PROGRAM_DASHBORAD = 'PROGRAM_DASHBORAD',
  LEAVE_MANAGEMENT = 'LEAVE_MANAGEMENT',
  ADMIN_HOME = 'ADMIN_HOME',
  AGENT_HOME = 'AGENT_HOME',
  ANALYTICS = 'ANALYTICS',
  PORTFOLIO = 'PORTFOLIO',
  DIGITAL_COLLECTION = 'DIGITAL_COLLECTION',
  FIELD_APP_DASHBOARD = 'FIELD_APP_DASHBOARD',
  COMMUNICATION_DETAILS = 'COMMUNICATION_DETAILS',
  LOANS = 'LOANS',
  ACCOUNT = 'ACCOUNT',
  UAM = 'UAM',
  UAM_PARAMS = 'UAM_PARAMS',
  UAM_PARAMS_ID = 'UAM_PARAMS_ID',
  DEPOSIT_BANK = 'DEPOSIT_BANK',
  CUSTOMER_BRANCH_INFO = 'CUSTOMER_BRANCH_INFO',
  EXPENSE = 'EXPENSE',
  CLAIMS = 'CLAIMS',
  CONVEYANCE_POLICY = 'CONVEYANCE_POLICY',
  AUCTION_USERS = 'AUCTION_USERS',
  AUCTION_PROPERTIES = 'AUCTION_PROPERTIES',
  LIVE_AUCTION_DETAILS = 'LIVE_AUCTION_DETAILS',
  LIVE_AUCTIONS = 'LIVE_AUCTIONS',
  ALLOCATION_POLICY = 'ALLOCATION_POLICY',
  ALLOCATION_POLICY_VIEW = 'ALLOCATION_POLICY_VIEW',
  ALLOCATION_BULK_ASSIGN = 'ALLOCATION_BULK_ASSIGN',
  ALLOCATION_ASSIGN = 'ALLOCATION_ASSIGN',
  LEAVE_APPLY = 'LEAVE_APPLY',
  LEAVE_REQUEST_ACTIONS = 'LEAVE_REQUEST_ACTIONS',
  RECEIPT_APPROVER = 'RECEIPT_APPROVER',
  RECEIPT_CANCELLATION = 'RECEIPT_CANCELLATION',
  RECEIPT_AGENT = 'RECEIPT_AGENT',
  RECEIPT_AGENT_ISSUE_RECEIPT = 'RECEIPT_AGENT_ISSUE_RECEIPT',
  MONTHLY_PROGRESS = 'MONTHLY_PROGRESS',
  MIN_NO_OF_VISITS = 'MIN_NO_OF_VISITS',
  TARGETS = 'TARGETS',
  TARGETS_ADMIN = 'TARGETS_ADMIN',
  TELE_ALLCATION_BULK = 'TELE_ALLCATION_BULK',
  TELE_ALLCATION_MANUAL = 'TELE_ALLCATION_MANUAL',
  TELE_AGENT_MANUAL = 'TELE_AGENT_MANUAL',
  TELE_AGENT_CREATE = 'TELE_AGENT_CREATE',
  TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID = 'TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID',
  TELE_AGENT_AUTO = 'TELE_AGENT_AUTO',
  Trail_Page = 'Trail_Page',
  Trail_Detail_Page = 'Trail_Detail_Page',
  TELE_ALLOCATION_STATUS = 'TELE_ALLOCATION_STATUS',
  TELE_CALLING_METRICS = 'TELE_CALLING_METRICS',
  TELE_ALLOCATION_RULE_POLICY = 'TELE_ALLOCATION_RULE_POLICY',
  TELE_ALLOCATION_BULK_LOAN_UPLOAD = 'TELE_ALLOCATION_BULK_LOAN_UPLOAD',
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNT_BY_LOAN_ACCOUNT_ID = 'ACCOUNT_BY_LOAN_ACCOUNT_ID',
  Refer_To_Field_Detail_Page = 'Refer_To_Field_Detail_Page',
  AGENTS = 'AGENTS',
  INCENTIVES_GAMIFICATIONS = 'INCENTIVES_GAMIFICATIONS',
  INCENTIVES_GAMIFICATIONS_ADD = 'INCENTIVES_GAMIFICATIONS_ADD',
  INCENTIVES_GAMIFICATIONS_VIEW = 'INCENTIVES_GAMIFICATIONS_VIEW',
  TEMPLATE_VIEW = 'TEMPLATE_VIEW',
  INCENTIVES_GAMIFICATIONS_EDIT = 'INCENTIVES_GAMIFICATIONS_EDIT',
  CALENDER = 'CALENDAR',
  TELE_AGENT_AUDIT = 'TELE_AGENT_AUDIT',
  TELE_AGENT_AUDIT_HISTORY = 'TELE_AGENT_AUDIT_HISTORY',
  PERFOMANCE = 'PERFORMANCE',
  RISK_BASE_PERFOMANCE = 'RISK_BASE_PERFOMANCE',
  PERFOMANCE_SUMMARY = 'PERFORMANCE_SUMMARY',
  ESCALATIONS = 'ESCALATIONS',
  ESCALATIONS_TODAY = 'ESCALATIONS_TODAY',
  ESCALATIONS_LEAVE_REQUEST = 'ESCALATIONS_LEAVE_REQUEST',
  ESCALATIONS_MINIMUM_VISITS = 'ESCALATIONS_MINIMUM_VISITS',
  ESCALATIONS_DAILY_TARGET = 'ESCALATIONS_DAILY_TARGET',
  ESCALATIONS_ROUTE_DEVIATION = 'ESCALATIONS_ROUTE_DEVIATION',
  ESCALATIONS_ROUTE_DEVIATION_VIEW_BY_ID = 'ESCALATIONS_ROUTE_DEVIATION_VIEW_BY_ID',
  ESCALATIONS_EXPENSE_MANAGEMENT = 'ESCALATIONS_EXPENSE_MANAGEMENT',
  ESCALATIONS_BY_STATUS_AND_ID = 'ESCALATIONS_BY_STATUS_AND_ID',
  ESCALATIONS_OTS = 'ESCALATIONS_OTS',
  REFER_TO_FIELD = 'REFER_TO_FIELD',
  GEO_VERIFICATION = 'GEO_VERIFICATION',
  SYSTEM_COLLECTION = 'SYSTEM_COLLECTION',
  OTS_HOME = 'OTS_HOME',
  OTS_DETAILS = 'OTS_DETAILS',
  AGENDA_CASE_DETAILS = 'AGENDA_CASE_DETAILS',
  OTS_AGENDA_CREATOR_PENDING = 'OTS_AGENDA_CREATOR_PENDING',
  OTS_AGENDA_CREATOR_COMMITTEE = 'OTS_AGENDA_CREATOR_COMMITTEE',
  AGENDA_DETAILS = 'AGENDA_DETAILS',
  OTS_CHAIR = 'OTS_CHAIR',
  HOLIDAY_TYPE = 'HOLIDAY_TYPE',
  HOLIDAY_NAME = 'HOLIDAY_NAME',
  EXPENSE_REPORT_DRAFT = 'EXPENSE_REPORT_DRAFT',
  EXPENSE_REPORT_ONGOING = 'EXPENSE_REPORT_ONGOING',
  EXPENSE_REPORT_HISTORY = 'EXPENSE_REPORT_HISTORY',
  EXPENSE_REPORT_SUBMITTED = 'EXPENSE_REPORT_SUBMITTED',
  EXPENSE_CLAIM_DRAFT = 'EXPENSE_CLAIM_DRAFT',
  EXPENSE_CLAIM_SUBMITTED = 'EXPENSE_CLAIM_SUBMITTED',
  OTS_AGENT = 'OTS_AGENT',
  OTS_RCM = 'OTS_RCM',
  OTS_NCM = 'OTS_NCM',
  OTS_HOC = 'OTS_HOC',
  OTS_TECHNICAL_TEAM = 'OTS_TECHNICAL_TEAM',
  OTS_OPERATION_TEAM = 'OTS_OPERATION_TEAM',
  OTS_MD = 'OTS_MD',
  EXPENSE_CLAIM_AGENT = 'EXPENSE_CLAIM_AGENT',
  EXPENSE_CLAIM_ADMIN = 'EXPENSE_CLAIM_ADMIN',
  AUCTION = 'AUCTION',
  RECEIPT_MANAGEMENT = 'RECEIPT_MANAGEMENT',
  TELE_AGENT = 'TELE_AGENT',
  OTS = 'OTS',
  AGENDA_CREATOR = 'AGENDA_CREATOR',
  OTS_MIS = 'MIS',
  INCENTIVE_SIMULATOR = 'INCENTIVE_SIMULATOR',
  CENTRAL_DASHBOARDS = 'CENTRAL_DASHBOARDS',
  CONFIGURATIONS = 'CONFIGURATIONS',
  MIN_VISIT_TRACKER = 'MIN_VISIT_TRACKER',
}
