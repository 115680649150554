import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  BumperCondition,
  BumperPolicyRangeProps,
  IncentivePolicyRulesRequest,
} from '../../../types/request/incentives';
import { BucketEnum } from '../../../types/targets';
import { getOrdinalNumber } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { PlusCircleIcon } from '../../Icons/Icons';
import VegaButton from '../../common/VegaButton';
import VegaText from '../../common/VegaText';
import BumperIncentive from './BumperIncentive';

export const conditionObj = {
  conditionType: 'RESOLUTION_PERCENTAGE',
  conditionValue: 60.0,
  conditionCutoffDayOfMonth: 21,
};

const bumperPolicy = {
  condition: [
    {
      ...conditionObj,
    },
  ],
  amountType: 'PERCENTAGE_OF_BASE_AMOUNT',
  amountValue: 15.0,
  amountMax: null,
  amountMin: null,
};

const BumperIncentiveComponent = () => {
  const { policyId } = useParams();
  const { policyRuleState, selectedTab, policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );

  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const isEmptyOrUndefined = (value: any) => {
    return (
      typeof value === 'undefined' ||
      value === null ||
      value === '' ||
      value === 0
    );
  };

  const isParameterValue = () => {
    const isEmptyValue =
      policyRuleState.bumperPayoutRange?.length &&
      policyRuleState?.bumperPayoutRange.some(
        (range: BumperPolicyRangeProps) =>
          isEmptyOrUndefined(range.amountType) ||
          isEmptyOrUndefined(range.amountValue)
      );
    return isEmptyValue;
  };
  const isConditionValue = () => {
    const isEmptyValue =
      policyRuleState.bumperPayoutRange?.length &&
      policyRuleState?.bumperPayoutRange.every(
        (range: BumperPolicyRangeProps) => {
          return range.condition.some(
            (condition: BumperCondition) =>
              // isEmptyOrUndefined(condition.conditionCutoffDayOfMonth) ||
              isEmptyOrUndefined(condition.conditionType) ||
              isEmptyOrUndefined(condition.conditionValue)
          );
        }
      );
    return isEmptyValue;
  };

  const disabled =
    policyRuleState?.bumperPayoutRange &&
    (isParameterValue() || isConditionValue());

  const handlePolicyRule = (
    key: keyof IncentivePolicyRulesRequest,
    value: any
  ) => {
    const policyRuleStateObj = {
      ...policyRuleState,
      [key]: value,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(policyRuleStateObj));
  };

  const onAddBumberIncentive = () => {
    handlePolicyRule('bumperPayoutRange', [
      ...policyRuleState.bumperPayoutRange,
      bumperPolicy,
    ]);
  };
  const onDeleteBumperIncentive = (index: number) => {
    const spreadBumperPoicy = [...policyRuleState.bumperPayoutRange];
    spreadBumperPoicy.splice(index, 1);
    handlePolicyRule('bumperPayoutRange', spreadBumperPoicy);
  };

  const onSubmitPolicy = () => {
    const getPolicyId = policyId ?? idFromURL;
    if (getPolicyId) {
      IncentiveServices.addRulesForPolicy({
        ...policyRuleState,
        bucket: getBucket(),
        incentiveBasePolicyId: getPolicyId,
      })
        .then(response => {
          dispatch(setPolicyDetailsState(response));
          setSnackbar('Add bumper incentive successfully !!!');
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      setSnackbar('No incentive base policy id for this bucket', 'error');
    }
  };

  const getBucket = () => {
    if (policyDetailsState.classType === BucketEnum.NPA) {
      return BucketEnum.NPA;
    } else {
      if (selectedTab === 1) {
        return BucketEnum.BUCKET1;
      } else if (selectedTab === 2) {
        return BucketEnum.BUCKET2;
      } else if (selectedTab === 3) {
        return BucketEnum.BUCKET3;
      } else {
        return BucketEnum.BUCKETX;
      }
    }
  };

  useEffect(() => {
    if (!policyRuleState.bumperPayoutRange) {
      handlePolicyRule('bumperPayoutRange', []);
    }
  }, []);

  return (
    <>
      <Stack
        gap={2}
        sx={{
          width: '100%',
          p: '1.5rem',
        }}
      >
        <VegaText
          text={'Bumper Incentive'}
          fontWeight={600}
          fontSize={'1.0625rem'}
        />

        {policyRuleState?.bumperPayoutRange &&
          policyRuleState.bumperPayoutRange.map(
            (bumperInsentive: BumperPolicyRangeProps, index: number) => (
              <BumperIncentive
                key={index}
                bumperInsentive={bumperInsentive}
                index={index}
                onDelete={onDeleteBumperIncentive}
              />
            )
          )}
        <Box>
          <VegaButton
            variant="text"
            text={`Add ${
              policyRuleState?.bumperPayoutRange?.length + 1
            }${getOrdinalNumber(
              policyRuleState?.bumperPayoutRange?.length + 1
            )} parameter`}
            startIcon={<PlusCircleIcon />}
            onClick={onAddBumberIncentive}
          />
        </Box>
        <Box textAlign={'end'}>
          <VegaButton
            text={`Save Bumper incentive`}
            onClick={onSubmitPolicy}
            disabled={disabled}
          />
        </Box>
      </Stack>
    </>
  );
};

export default BumperIncentiveComponent;
