/* eslint-disable no-empty */
import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { VisitService } from '../../../Apis/VisitService';
import ExpenseCard from '../../../components/Calender/ExpenseCard';
import LeaveCard from '../../../components/Home/AgentHome/LeaveCard';
import ListOfTopCollectionAccount from '../../../components/Home/AgentHome/ListOfTopCollectionAccount';
import PolicyCard from '../../../components/Home/AgentHome/PolicyCard';
import HomeSummariesCard from '../../../components/Home/components/HomeSummariesCard';
import VegaOutlineDatePicker from '../../../components/common/VegaOutlineDatePicker';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { getIncentivesGamificationsState } from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import {
  getReceiptsList,
  receiptState,
} from '../../../features/receiptManagmentSlice';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { VegaUserStatus } from '../../../types/user';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { COLOR } from '../../../utils/ColorUtility';
import {
  ArrowRightIcon,
  TrendDownIcon,
  TrendUp,
} from '../../../components/Icons/Icons';
import MonthlyProgressCard from '../../../components/MonthlyProgress/MonthlyProgressCard';
import { useDrawer } from '../../../hooks/useDrawer';
import LmDialog from '../../../components/common/LmDialog';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import { GridColumns } from '@mui/x-data-grid';
import {
  fetchLoanList,
  loanRequestSelector,
} from '../../../features/loanSlice';
import { AgentStatsDto, AllocationStatus } from '../../../types/allocations';
import { AllocationService } from '../../../Apis/AllocationService';
import { AgentPayoutDto, RankDetailDto } from '../../../types/incentives';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { LoanService } from '../../../Apis/LoanServices';
import { StringUtility } from '../../../utils/StringUtility';
import { getPolicies } from '../../../features/IncentivesGamificationsSlice/incentivesAsyncThunk';

enum TrendDescription {
  Increase_Over_The_Last_Month = 'increase over the last month',
  Decrease_Over_The_Last_Month = 'decrease over the last month',
}

const AgentHomePage = () => {
  const { totalItems, loading } = useAppSelector(loanRequestSelector);
  const { totalItems: totalReceipts, loading: loadingReceipts } =
    useAppSelector(receiptState);
  const { policies } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { userId, user } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [agentPayout, setAgentPayout] = useState<AgentPayoutDto>();
  const [accountNumber, setAccountNumber] = useState<number>(0);
  const [totalVisits, setTotalVisits] = useState<number>(0);
  const [loadingVisits, setLoadingVisits] = useState<boolean>(false);
  const [agentStats, setAgentStats] = useState<Partial<AgentStatsDto>>();
  const [agentRankDetails, setAgentRankDetails] = useState<RankDetailDto>();
  const [dateValue, setDateValue] = useState<string>(
    DateUtility.formatStringToYYYYMMDD(new Date().toDateString())
  );
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const {
    open: openDetailDialog,
    close: closeDetailsDialog,
    isOpen: isDetailsDialogOpen,
  } = useDrawer(false);
  const {
    open: openUpcomingPTPsDialog,
    close: closeUpcomingPTPsDialog,
    isOpen: isUpcomingPTPsDialogOpen,
  } = useDrawer(false);

  function getChangeInPtp(): MonthPercentageChange {
    if (!agentStats) {
      return;
    }

    return calculateMonthPercentageChange(
      agentStats?.ptpReceivedCurrentMonth,
      agentStats?.ptpReceivedLastMonth
    );
  }

  const monthlyProgressDummyData = [
    {
      achievmentName: 'All India Ranking',
      achievementCount: agentRankDetails?.rank?.toString() ?? '-',
      percentageColor: COLOR.GREEN.DARK,
    },
    {
      achievmentName: 'Overall & Bucket',
      achievementCount: '10',
      trendIcon: () => <TrendDownIcon />,
      trendPercentage: '12%',
      trendDescription: 'decrease over the last month',
      percentageColor: COLOR.RED.DARK,
      renderRightView: () => (
        <div
          onClick={() => {
            openDetailDialog();
          }}
        >
          <ArrowRightIcon />
        </div>
      ),
    },
    {
      achievmentName: 'PTPs Received',
      achievementCount: agentStats?.ptpReceivedCurrentMonth?.toString() ?? '-',
      trendIcon: () => getChangeInPtp()?.trendIcon ?? <TrendUp />,
      trendPercentage: getChangeInPtp()?.changePercentage?.toString() ?? '-',
      trendDescription: getChangeInPtp()?.trendDescription ?? '-',
      percentageColor: COLOR.GREEN.DARK,
    },
    {
      achievementCount: agentStats?.pendingVisitCurrentMonth?.toString() ?? '-',
      achievmentName: 'Pending Visits',
      percentageColor: COLOR.RED.DARK,
    },
    {
      achievmentName: 'Upcoming PTPs',
      achievementCount: agentStats?.upcomingPTPCurrentMonth?.toString() ?? '-',
      renderRightView: () => (
        <div
          onClick={() => {
            openUpcomingPTPsDialog();
          }}
        >
          <ArrowRightIcon />
        </div>
      ),
    },
  ];

  const handleChangeDate = (e: any) => {
    const date = DateUtility.formatStringToYYYYMMDD(e);
    setDateValue(date);
  };
  const getVisits = () => {
    setLoadingVisits(true);
    VisitService.getVisitListByAgentId({ agentId: userId })
      .then(res => {
        setTotalVisits(res.totalItems);
        setLoadingVisits(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error'),
          setLoadingVisits(false);
      });
  };

  const fetchAgentStatsApi = async () => {
    try {
      const response = await AllocationService.getAgentStats({
        agentId: userId,
      });
      setAgentStats(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const fetchAgentRankApi = async () => {
    try {
      const response = await IncentiveServices.getAgentRank({
        agentId: userId,
      });
      setAgentRankDetails(response);
    } catch (error) {
      // setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  async function fetchAgentPayout() {
    try {
      const response = await IncentiveServices.getAgentPayout({
        agentId: user?.id,
      });
      setAgentPayout(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function fetchAgentAllocations() {
    try {
      const response = await LoanService.getLoanList({ agentId: user.id });
      setAccountNumber(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  useEffect(() => {
    dispatch(
      fetchLoanList({
        includeLmsVariables: true,
        includeLoanPdcDetails: true,
        includeLoanOutstandingDetails: false,
        includeLoanTransactions: false,
        includeCustomerDetails: true,
        includeMetaDataLoanAllocation: true,
        sort: {
          attribute: 'POS',
          order: 'DESC',
        },
        size: pageSize,
        page: page,
        allocationStatus: AllocationStatus.ALLOCATED,
      })
    );
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    getVisits();
    dispatch(getReceiptsList({ agentId: userId }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPolicies({ status: VegaUserStatus.ACTIVE }));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      fetchAgentStatsApi();
      fetchAgentRankApi();
      fetchAgentPayout();
      fetchAgentAllocations();
    }
  }, [user]);

  return (
    <>
      <Box height={'100%'}>
        <VegaPageHeader
          renderLeftView={() => {
            return (
              <Stack spacing={'2rem'}>
                <VegaText
                  text={`Hi, ${user?.firstName ?? 'User'}`}
                  fontWeight={600}
                  fontSize={'1.0625rem'}
                  color={'black'}
                />
              </Stack>
            );
          }}
          renderRightView={() => {
            return (
              <Stack direction={'row'} gap={1}>
                <VegaOutlineDatePicker
                  onChange={handleChangeDate}
                  value={dateValue}
                  maxDate={new Date()}
                />
              </Stack>
            );
          }}
        />
        <Box height={'calc(100% - 95px)'} sx={{ overflow: 'scroll' }}>
          <VegaPageContent mt={'1rem'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <HomeSummariesCard
                  label={'Incentive'}
                  value={StringUtility.formatToINR(
                    agentPayout?.totalAmountEarned ?? 0
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <HomeSummariesCard
                  label={'No Of Accounts'}
                  value={accountNumber ?? 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <HomeSummariesCard
                  label={'Visits'}
                  value={agentStats?.totalVisitCurrentMonth?.toString() ?? 'NA'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <HomeSummariesCard
                  label={'Receipts'}
                  value={totalReceipts ?? 0}
                  loading={loadingReceipts}
                />
              </Grid>

              {monthlyProgressDummyData.map((data, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <MonthlyProgressCard
                    key={index}
                    trendIcon={data.trendIcon}
                    trendPercentage={data.trendPercentage}
                    trendDescription={data.trendDescription}
                    achievementCount={data.achievementCount}
                    achievmentName={data.achievmentName}
                    percentageColor={data.percentageColor}
                    renderRightView={data.renderRightView}
                  />
                </Grid>
              ))}
            </Grid>
          </VegaPageContent>
          <VegaPageContent mt={'1rem'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <ListOfTopCollectionAccount
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Stack gap={2}>
                  <ExpenseCard date={dateValue} />
                  {policies.length ? <PolicyCard /> : ''}
                  <LeaveCard date={dateValue} />
                </Stack>
              </Grid>
            </Grid>
          </VegaPageContent>
        </Box>
      </Box>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={isDetailsDialogOpen}
        handleClose={closeDetailsDialog}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Overall & Bucket Wise"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaDataGrid
            idColumn="id"
            columns={OVERALL_AND_BUCKET_COLUMN_DEF}
            data={[]}
          />
        </VegaDialogContent>
      </LmDialog>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={isUpcomingPTPsDialogOpen}
        handleClose={closeUpcomingPTPsDialog}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Overall & Bucket Wise"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaDataGrid
            idColumn="id"
            columns={UPCOMING_PTPS_COLUMN_DEF}
            data={[]}
          />
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default AgentHomePage;
const OVERALL_AND_BUCKET_COLUMN_DEF: GridColumns = [
  {
    field: 'Bucket',
    headerName: 'Bucket',
    flex: 0.5,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Bucket}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Target',
    headerName: 'Taget(in INR cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Target}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Achieved',
    headerName: 'Achieved (in INR Cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Achieved}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: '%Achieved',
    headerName: '% Achieved',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.perAchieved}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];
const UPCOMING_PTPS_COLUMN_DEF: GridColumns = [
  {
    field: 'UserName',
    headerName: 'User Name',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.UserName}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'LoanAccountNumber',
    headerName: 'Loan Account Number',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.LoanAccountNumber}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'POS',
    headerName: 'POS (In INR Cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText text={tableData.POS} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'EMI',
    headerName: 'EMI',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText text={tableData.EMI} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'LastVisitDate',
    headerName: 'Last Visit Date',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.LastVisitDate}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'PTPDate',
    headerName: 'PTP Date',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.PTPDate}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];
type MonthPercentageChange = {
  changePercentage: number;
  trendDescription: TrendDescription;
  trendIcon: JSX.Element;
};

const calculateMonthPercentageChange = (
  currentMonthData: number,
  previousMonthData: number
): MonthPercentageChange => {
  if (previousMonthData === 0) {
    return currentMonthData === 0
      ? {
          changePercentage: 0,
          trendDescription: TrendDescription.Increase_Over_The_Last_Month,
          trendIcon: <TrendUp />,
        }
      : {
          changePercentage: 100,
          trendDescription: TrendDescription.Increase_Over_The_Last_Month,
          trendIcon: <TrendUp />,
        };
  }

  const changePercentage =
    ((currentMonthData - previousMonthData) / previousMonthData) * 100;
  const trendDescription =
    changePercentage > 0
      ? TrendDescription.Increase_Over_The_Last_Month
      : TrendDescription.Decrease_Over_The_Last_Month;

  const trendIcon =
    trendDescription === TrendDescription.Increase_Over_The_Last_Month ? (
      <TrendUp />
    ) : (
      <TrendDownIcon />
    );
  return { changePercentage, trendDescription, trendIcon };
};
