import { Grid, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { SystemCollectionServices } from '../../Apis/SystemCollectionServices';
import { fetchSaarthiList } from '../../features/systemCollectionSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch } from '../../store';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import AllocationFileUploadCard, {
  ViewState,
} from '../Allocation/AllocationFileUploadCard';
import { FileUploadIcon } from '../Icons/Icons';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../common/VegaFileSelect';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaPageContent from '../common/VegaPageContent';

const UploadSection = () => {
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<VegaFileSelectOption>();

  const onUploadFile = () => {
    SystemCollectionServices.pushdataToSaarthi(selectedFile.value)
      .then(() => {
        setSnackbar('Upload successfully !!');
        dispatch(fetchSaarthiList({ page: 0, size: 10 }));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <VegaPageContent>
        <Stack
          sx={{
            borderRadius: '0.5rem',
            border: '1px solid #E1E4EB ',
            p: '2rem',
            position: 'relative',
            bgcolor: 'white',
          }}
        >
          <Grid container columnGap={'1rem'}>
            <Grid item xs={3}>
              <VegaFormInputField label="Upload File">
                <VegaFileSelect
                  placeholder={'Upload File'}
                  endIcon={<></>}
                  startIcon={<FileUploadIcon />}
                  acceptedExtension={[AcceptedExtension.XLSX]}
                  onFileSelect={file => {
                    setSelectedFile(file);
                  }}
                  onFileInputRef={ref => {
                    fileInputRef.current = ref;
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>

          {!!selectedFile && (
            <Grid container>
              <Grid item xs={12} style={{ flex: 1, marginTop: '0.8rem' }}>
                <AllocationFileUploadCard
                  fileName={selectedFile?.label}
                  state={ViewState.Inital}
                  onUploadClick={function (): void {
                    onUploadFile();
                  }}
                  onCancelClick={function (): void {
                    setSelectedFile(undefined);
                    fileInputRef.current.value = '';
                  }}
                />
              </Grid>
            </Grid>
          )}
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            <a
              href={require('../../assets/files/Saarthi_Bulk.xlsx')}
              download
              className="font-aspekta-400"
              style={{
                textDecoration: 'none',
                fontSize: 12,
                color: '#1047DC',
              }}
            >
              Download Templete
            </a>
          </div>
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default UploadSection;
