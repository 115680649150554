import { useEffect, useState } from 'react';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { CampaignStatusListProps } from '../../../types/teleAllocation';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';

interface IProps {
  selected: string | number;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
}

const CampaignSelector = ({
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const [campaigns, setCampaigns] = useState<VegaSelectOption[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== campaigns.length) {
        setPage(page + 1);
      }
    }
  };

  const getCampaignList = async () => {
    TeleAllocationService.getCampaignList({ page, size: 10 }).then(res => {
      const newArr = res?.records?.map((campaign: CampaignStatusListProps) => {
        return {
          value: campaign.slashRtcCampaignId,
          label: campaign.slashRtcCampaignName,
        } as VegaSelectOption;
      });

      if (page === 0) {
        setCampaigns(newArr);
      } else {
        setCampaigns([...campaigns, ...newArr]);
      }
      setTotalItem(res.totalItems);
    });
  };

  useEffect(() => {
    getCampaignList();
  }, [page]);
  return (
    <VegaSelect
      placeholder={label ?? 'Select Campaign Id'}
      size="small"
      value={selected ?? ''}
      onChange={e => handleChange(e.target.value)}
      options={campaigns}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default CampaignSelector;
