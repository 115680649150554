import { Box, Stack } from '@mui/material';
import VegaErrorIcon from '../../components/common/VegaErrorIcon';
import VegaSuccessIcon from '../../components/common/VegaSuccessIcon';
import VegaText from '../../components/common/VegaText';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { LoanService } from '../../Apis/LoanServices';
import LoadingPage from '../LoadingPage/LoadingPage';
import { CustomerDataWithToken } from '../../types/loan';

const UpdateContact = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const tokenFromURL = searchParams.get('token');
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openApprove, setOpenApprove] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { setSnackbar } = useSnackbar();

  const updateContactwithToken = async (
    customerData: CustomerDataWithToken
  ) => {
    try {
      await LoanService.updateContact({
        mobileNo: customerData.mobileNo,
        token: customerData.token,
      });
      setLoading(false);
      setOpenApprove(true);
      setMessage('Contact Update Successfully !!');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setIsInvalidToken(true);
      setMessage(getErrorMessageFromErrorObj(error));
      setMessage(error);
      setLoading(false);
    }
  };

  const verifyToken = async (token: string) => {
    setLoading(true);
    try {
      const response = await LoanService.verifyToken({ token });
      await updateContactwithToken(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setIsInvalidToken(true);
      setMessage(getErrorMessageFromErrorObj(error));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tokenFromURL) {
      verifyToken(tokenFromURL);
    } else {
      setIsInvalidToken(true);
      setMessage('No token available');
    }
  }, [tokenFromURL]);

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        p: 2,
        height: '100vh',
      }}
    >
      {loading ? (
        <LoadingPage height={'100%'} />
      ) : (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            borderRadius: '10px',
            border: !isInvalidToken ? '1px solid #41d67c' : '1px solid #F1342F',
          }}
        >
          {openApprove ? (
            <Stack gap={2}>
              <VegaSuccessIcon />
              <VegaText
                text={message}
                fontSize={20}
                fontColor="#41d67c"
                fontWeight={600}
              />
            </Stack>
          ) : (
            <Stack gap={2}>
              <VegaErrorIcon />
              <VegaText
                text={message}
                fontSize={20}
                fontColor="#F1342F"
                fontWeight={600}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default UpdateContact;
