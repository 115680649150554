import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { CollectionOverallPosValueResponseDto } from '../../../../../types/analytics';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import VegaText from '../../../../../components/common/VegaText';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { ProfitTypeProps } from '../../../AnalyticsDashboard';

interface IProps extends ProfitTypeProps {}

const CollectionValueGraph = ({ selectedCollectionProfitType }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<CollectionOverallPosValueResponseDto>
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const resolutionPercent = analyticsQueryResponse?.resolution ?? 0;
  const collectedAmt = analyticsQueryResponse?.achievedTarget ?? 0;
  const totalAmount = analyticsQueryResponse?.target ?? 0;

  const getQueries = async () => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.TODAY_COLLECTION_BASE,
        params: {
          type: selectedCollectionProfitType,
        },
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setAnalyticsQueryResponse({});
      setLoading(false);
    }
  };
  useEffect(() => {
    getQueries();
  }, [selectedCollectionProfitType]);

  const series = [resolutionPercent];

  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },

    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
            formatter: function (val) {
              return val.toFixed(2) + '%';
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [],

    tooltip: {
      enabled: true,
      theme: 'light',
      custom: function () {
        return (
          `<pre style="background-color: #E7EDFC; padding: 10px;">` +
          `Collected Amount: ${collectedAmt}<br>` +
          `Total Amount: ${totalAmount}<br>` +
          `Resolution Percentage: ${resolutionPercent}%` +
          `</pre>`
        );
      },
    },
  };

  return (
    <>
      <GraphWrapper
        title="Collection Value (POS Basis)"
        sx={{ mb: '1rem' }}
        onClick={() => {}}
      >
        {loading ? (
          <LoadingPage height={150} />
        ) : (
          <Stack>
            <Chart options={options} series={series} type="radialBar" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingX: 3,
              }}
            >
              <VegaText text={`Achieved (${collectedAmt.toFixed(2)})`} />
              <VegaText text={`Total (${totalAmount.toFixed(2)})`} />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default CollectionValueGraph;
