import { KeyboardArrowDown } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { LmSidebarListItemType } from '../router';
import { useAppDispatch } from '../store';
import { setIsOpenIframe } from '../features/teleAgentSlice';
import { setSelectedPolicyTab } from '../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: 'afterChildren' },
  },
  show: {
    opacity: 1,
    height: 'auto',
    transition: {
      duration: 0.3,
      when: 'beforeChildren',
    },
  },
};

interface IProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  showAnimation: any;
  route: LmSidebarListItemType;
  children: any;
}

const LmSubMenu = ({ route, showAnimation, isOpen, setIsOpen }: IProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div
        className={`menu link ${
          route.routes &&
          route.routes.includes(pathname) &&
          !isMenuOpen &&
          'active'
        }`}
        onClick={toggleMenu}
      >
        <div className="menu_item">
          <div className="icon">
            {route.routes && route.routes.includes(pathname)
              ? route.activIcon
              : route.icon}
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className={`link_text font-aspekta-500`}
                style={{ fontSize: 13 }}
              >
                {route.label}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <motion.div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            animate={
              isMenuOpen
                ? {
                    rotate: -90,
                  }
                : { rotate: 0 }
            }
          >
            <KeyboardArrowDown fontSize="small" />
          </motion.div>
        )}
      </div>{' '}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="menu_container"
          >
            {route.children
              .filter(
                (subMenu: LmSidebarListItemType) => subMenu.label.length !== 0
              )
              .map((subMenu: LmSidebarListItemType, i: number) => {
                return (
                  <motion.div
                    key={i}
                    custom={i}
                    onClick={() => {
                      dispatch(setIsOpenIframe(false));
                      dispatch(setSelectedPolicyTab(0));
                    }}
                  >
                    <NavLink
                      to={subMenu.path}
                      className="link"
                      id={`link_${subMenu.path}`}
                    >
                      <div className="icon"></div>
                      <motion.div
                        style={{ fontSize: 13 }}
                        className="font-aspekta-500"
                      >
                        {subMenu.label}
                      </motion.div>
                    </NavLink>
                  </motion.div>
                );
              })}
          </motion.div>
        )}{' '}
      </AnimatePresence>
    </>
  );
};

export default LmSubMenu;
