/* eslint-disable no-empty */
import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaButton from '../../components/common/VegaButton';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import {
  receiptState,
  setErrorState,
  setIssueReceipt,
} from '../../features/receiptManagmentSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  disabledButton,
  isValidInput,
  totalAmountDto,
} from './ReceiptValidation';
import LoanDetails from './module/LoanDetails';
import LoanDocumentUpload from './module/LoanDocumentUpload';
import PayerDetails from './module/PayerDetails';
import PaymentDetails from './module/PaymentDetails';
import { IssueReceiptProps, Relationship } from '../../types/receiptManagment';
import { ReceiptManagmentService } from '../../Apis/ReceiptManagmentService';
import { getErrorMessageFromErrorObj } from '../../utils/api';

const ReceiptIssue = () => {
  const { user } = useClientAuth();
  const { issueReceipt } = useAppSelector(receiptState);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loanId } = useParams();
  const { setSnackbar } = useSnackbar();

  const totalAmount = totalAmountDto(issueReceipt.receiptAmountBreakUp);

  const disabled = disabledButton(issueReceipt);

  const onIssueReceipt = () => {
    const errorObj = isValidInput(issueReceipt);
    dispatch(setErrorState(errorObj));
    if (errorObj.isValid) {
      const newObj = {
        ...issueReceipt,
        totalAmount,
        loanId,
        agentId: user?.id,
      } as IssueReceiptProps;

      if (!newObj.email) {
        delete newObj.email;
      }
      if (!newObj.refNumber) {
        delete newObj.refNumber;
      }

      if (!newObj.documentImage) {
        delete newObj.documentImage;
      }

      setLoading(true);
      ReceiptManagmentService.createReceipt(newObj)
        .then(() => {
          setSnackbar('Issue receipt successfully!!');
          navigate(ROUTES.RECEIPT_AGENT);
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        setIssueReceipt({
          agentId: '',
          loanId: '',
          mobileNumber: '',
          email: '',
          paymentType: '',
          documentImage: null,
          payeeName: '',
          relationshipWithCustomer: Relationship.SELF,
          totalAmount: 0,
          paymentMeta: {},
          receiptAmountBreakUp: {
            overDueAmount: 0,
            bounceCharges: 0,
            penalCharges: 0,
            legalCharges: 0,
            otherCharges: 0,
          },
          panCard: '',
          remarks: '',
        })
      );
    };
  }, []);

  return (
    <>
      <VegaPageHeader
        sx={{
          borderBottom: 'none',
        }}
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Receipt Management', link: ROUTES.RECEIPT_AGENT },
                { label: `Issue Receipt` },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Loan Details">
          <LoanDetails />
        </VegaTitleCard>
      </VegaPageContent>
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Document Upload">
          <LoanDocumentUpload />
        </VegaTitleCard>
      </VegaPageContent>
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Payer Details">
          <PayerDetails />
        </VegaTitleCard>
      </VegaPageContent>
      <Collapse in={issueReceipt.paymentMeta?.paymentMode}>
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Payment Details">
            <PaymentDetails />
          </VegaTitleCard>
        </VegaPageContent>
      </Collapse>

      <VegaPageContent sx={{ marginBottom: '1.5rem', textAlign: 'end' }}>
        <VegaButton
          text="Issue Receipt"
          disabled={disabled}
          onClick={onIssueReceipt}
          loading={loading}
        />
      </VegaPageContent>
    </>
  );
};

export default ReceiptIssue;
