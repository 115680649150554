/* eslint-disable no-unused-vars */
import {
  FormControl,
  TextField,
  TextFieldProps,
  Stack,
  Box,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VegaText from './VegaText';
import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import LmDialog from './LmDialog';
import { COMMON } from '../../constants/style';

type IDateFieldProps = {
  value?: number | string | any;
  onChange: (event: any) => void;
  key?: string;
  minDate?: number | string | any;
  maxDate?: number | string | any;
  height?: string;
  header?: string;
  isInfo?: boolean;
  loading?: boolean;
} & TextFieldProps;

const LmDateField = ({
  value,
  maxDate,
  onChange,
  minDate,
  height,
  header,
  isInfo = false,
  ...inputProps
}: IDateFieldProps) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Box width={'100%'}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        {header && (
          <VegaText
            fontWeight={700}
            sx={{
              textTransform: 'capitalize',
              fontSize: '0.625rem',
              letterSpacing: '0.07em',
              color: '#676B76',
              mb: 0.5,
            }}
            text={header}
          />
        )}
        {isInfo && (
          <Box onClick={() => setShow(true)}>
            <InfoOutlined
              sx={{
                fontSize: '0.75rem',
                color: '#676B76',
                cursor: 'pointer',
              }}
            />
          </Box>
        )}
        <ShowInfoModal show={show} title={header ?? ''} setShow={setShow} />
      </Stack>
      <FormControl
        fullWidth
        sx={{
          height: height ?? '35px',
          fontFamily: `var(--font-Aspekta-500) !important`,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={''}
            value={value}
            onChange={onChange}
            inputFormat="DD-MM-YYYY"
            minDate={minDate}
            maxDate={maxDate}
            renderInput={params => (
              <TextField
                size="medium"
                style={{
                  borderRadius: '15px',
                  height: height ?? '35px',
                  fontFamily: 'Aspekta !important',
                }}
                InputProps={{
                  style: {
                    borderRadius: '15px',
                    fontFamily: 'Aspekta !important',
                  },
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    fontFamily: `var(--font-Aspekta-450) !important`,
                    fontSize: '13px',
                    lineHeight: '120%',
                    color: '#1B1D22',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                    '&.Mui-focused fieldset': {
                      borderColor: '#C5C8D0',
                      borderWidth: '1px',
                      borderRadius: '6px',
                    },
                    ':hover fieldset': {
                      borderColor: '#C5C8D0',
                    },
                  },
                  ...inputProps.sx,
                }}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: '#AFAFAF',
                    textAlign: 'center',
                    fontFamily: 'Aspekta !important',
                  },
                }}
                {...params}
                className="font-aspekta-400"
                {...inputProps}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </Box>
  );
};

export default LmDateField;

interface IProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const ShowInfoModal = ({ title, show, setShow }: IProps) => {
  return (
    <LmDialog
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'end',
        },
      }}
      maxWidth="xs"
      open={show}
      isCloseIcon
      handleClose={() => setShow(false)}
      buttonComponent={() => <></>}
      headerComponent={() => <></>}
    >
      <Box px={4} pt={3} pb={4}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Box>
            <InfoOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1rem',
              }}
            />
          </Box>
          <VegaText
            fontWeight={700}
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.8125rem',
              color: COMMON.balck,
              mb: 0.5,
            }}
            text={title}
          />
        </Stack>
      </Box>
    </LmDialog>
  );
};
