import { Collapse, ImageList, ImageListItem } from '@mui/material';
import { useEffect, useState } from 'react';

export enum VegaImagePreviewViewState {
  ImageList,
  SingleImage,
}

type Props = {
  images: string[];
  currentIndex?: number;
  onImageSelect: (index: number) => void;
  viewState: VegaImagePreviewViewState;
};
function VegaImagePreview({
  images,
  currentIndex,
  onImageSelect,
  viewState: currentViewState,
}: Props) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [viewState, setViewState] = useState<VegaImagePreviewViewState>(
    VegaImagePreviewViewState.ImageList
  );

  const onImageClick = (index: number) => {
    onImageSelect(index);
  };

  useEffect(() => {
    setSelectedImageIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    setViewState(currentViewState);
  }, [currentViewState]);

  return (
    <div>
      <Collapse in={viewState == VegaImagePreviewViewState.SingleImage}>
        <div style={{ height: 450 }}>
          <img
            src={images[selectedImageIndex]}
            loading="lazy"
            height={'100%'}
            width={'100%'}
            style={{ objectFit: 'fill' }}
          />
        </div>
      </Collapse>
      <Collapse in={viewState == VegaImagePreviewViewState.ImageList}>
        <ImageList
          sx={{
            height: 450,
          }}
        >
          {images.map((item, index) => (
            <ImageListItem key={`${item} + ${index}`}>
              <div>
                <img
                  src={`${item}`}
                  height="100%"
                  width={'100%'}
                  loading="lazy"
                  onClick={() => {
                    onImageClick(index);
                  }}
                />
              </div>
            </ImageListItem>
          ))}
        </ImageList>
      </Collapse>
    </div>
  );
}

export default VegaImagePreview;
