import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../components/common/VegaText';
import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import VegaIconButton from '../../components/common/VegaIconButton';
import { PRIMARY } from '../../constants/style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { TrackingResponseDto } from '../../types/request/visit';

export function LocationTrackingColumn({
  selectedDate,
}: {
  selectedDate: string;
}) {
  const navigate = useNavigate();
  const COL_DEF: GridColumns = [
    {
      field: 'agentName',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row as TrackingResponseDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail.agentName}
          />
        );
      },
    },

    {
      field: 'employeeCode',
      headerName: 'Employee Code',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row as TrackingResponseDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail.employeeCode}
          />
        );
      },
    },
    {
      field: 'noOfVisits',
      headerName: 'No. of Visits',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row as TrackingResponseDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail.visits}
          />
        );
      },
    },
    {
      field: 'minNoOfVisits',
      headerName: 'Min No. of Visits',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const locationTrackingDetail = props.row as TrackingResponseDto;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={locationTrackingDetail.minVisits}
          />
        );
      },
    },
    {
      field: 'viewTrajectory',
      headerName: 'View Trajectory',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const details = props.row as TrackingResponseDto;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() =>
                navigate(
                  ROUTES.VIEW_TRAJECTORY_DETAILS.replace(
                    ':agentId',
                    details.agentId
                  )
                    .replace(':selectedDate', selectedDate)
                    .replace(':agentName', details.agentName)
                )
              }
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
}

export default LocationTrackingColumn;
