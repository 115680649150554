import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpenseService } from '../../Apis/ExpenseService';
import ConveyancePolicyInputDrawer from '../../components/ExpenseClaim/ConveyancePolicyInputDrawer';
import CurrentConveyanceRateDialog from '../../components/ExpenseClaim/CurrentConveyanceRateDialog';
import FilterInput, {
  DateRangeFilter,
  FilterOption,
} from '../../components/ExpenseClaim/FilterInput';
import LmButton from '../../components/common/LmButton';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';
import VegaText from '../../components/common/VegaText';
import { PRIMARY } from '../../constants/style';
import { setExpenseConveyanceRate } from '../../features/expenseClaimSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES, SUB_ROUTES } from '../../router/routes';
import { useAppDispatch } from '../../store';
import {
  ConveyanceRate,
  ExpenseClaimAction,
  ExpenseReportViewModel,
  getPillColorForStatus,
} from '../../types/claim';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useClientAuth } from '../../providers/ClientProvider';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../components/common/VegaIconButton';

function OngoingExpenseReport() {
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const { fetchExpenseReports } = useExpenseApi();
  const [reports, setReports] = useState<ExpenseReportViewModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dayFilter, setDayFilter] = useState<string>();
  const [conveyanceRate, setConveyanceRate] = useState<ConveyanceRate[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: '',
    endDate: '',
  });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);
  const {
    isOpen: isRateDrawerOpen,
    open: openRateDrawer,
    close: closeRateDrawer,
  } = useDrawer(false);

  const {
    isOpen: isCurrentRateDialogOpen,
    open: openCurrentRateDialog,
    close: closeCurrentRateDialog,
  } = useDrawer(false);

  async function fetchReports() {
    try {
      setLoading(true);
      const response = await fetchExpenseReports(
        {
          status: ExpenseClaimAction.PENDING,
          startDateRange: dateFilter.startDate,
          endDateRange: dateFilter.endDate,
          approverId: user?.id,
        },
        { includeClaimantInReport: true }
      );
      setReports(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchConveyanceRates() {
    try {
      const response = await ExpenseService.getConveyanceRates({});
      setConveyanceRate(response.records);
    } finally {
      // setLoadingRates(false);
    }
  }

  function onClearAllClick() {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter([]);
    closeFilterDrawer();
  }

  function onSelectFilterClick(
    statusFilter: string[],
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
  }

  useEffect(() => {
    fetchConveyanceRates();
  }, []);

  useEffect(() => {
    user && fetchReports();
  }, [dateFilter, dayFilter, statusFilter, user]);

  return (
    <div>
      <VegaPageHeader
        renderRightView={() => {
          return (
            <Stack direction={'row'}>
              {conveyanceRate.length > 0 && (
                <LmButton
                  text={'Conveyance Policy'}
                  variant="text"
                  bgColor=""
                  textColor="rgba(16, 71, 220, 1)"
                  onClick={() => navigate(ROUTES.CONVEYANCE_POLICY)}
                />
              )}
              {conveyanceRate.length > 0 && (
                <LmButton
                  text={'Current Conveyance Rate'}
                  variant="text"
                  bgColor=""
                  textColor="rgba(16, 71, 220, 1)"
                  onClick={() => {
                    openCurrentRateDialog();
                  }}
                />
              )}
              <LmButton
                text={'Set Conveyance Rate'}
                onClick={() => {
                  openRateDrawer();
                  dispatch(setExpenseConveyanceRate(null));
                }}
              />
            </Stack>
          );
        }}
      />
      <Box p={3}>
        <Stack
          direction={'row'}
          alignItems="end"
          justifyContent={'space-between'}
        >
          <VegaText
            text="Ongoing Expense Reports"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        </Stack>

        <Box pt={3}>
          <VegaDataGrid
            data={reports}
            loading={loading}
            columns={getColumnDefinition({
              onViewDetailsClick: report => {
                navigate(
                  SUB_ROUTES.EXPENSE_REPORT_HISTORY_BY_ID.replace(
                    ':reportId',
                    report.id
                  ),
                  {
                    state: { isAdmin: true },
                  }
                );
              },
            })}
            idColumn="id"
            rowCount={rowCount}
            page={page}
            pageSize={10}
          />
        </Box>
      </Box>
      <ConveyancePolicyInputDrawer
        open={isRateDrawerOpen}
        onClose={function (): void {
          closeRateDrawer();
        }}
      />
      <CurrentConveyanceRateDialog
        show={isCurrentRateDialogOpen}
        onCancelClick={function (): void {
          closeCurrentRateDialog();
        }}
      />

      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <FilterInput
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter]}
        />
      </VegaDrawer>
    </div>
  );
}

export default OngoingExpenseReport;

const getColumnDefinition = (data: {
  onViewDetailsClick: (report: ExpenseReportViewModel) => void;
}) => {
  const APPROVER_ONGOING_COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfApplication',
      headerName: 'Date Of Application',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const parsedDate = DateUtility.parseStringToDate(report.createdAt);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'dateOfExpense',
      headerName: 'Date Of Expense',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const parsedDate = DateUtility.parseStringToDate(report.dateOfExpense);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'reportId',
      headerName: 'Report ID',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const last4Digits = report.id.substring(
          Math.max(0, report.id.length - 4)
        );
        const displayText = `#${last4Digits}`;

        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentName',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const claimant = report.claimant;
        const displayText = claimant;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimsApproved',
      headerName: 'Claims Approved',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.filter(
          item => item.status == ExpenseClaimAction.ACCEPTED
        ).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimsRejected',
      headerName: 'Claims Rejected',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.filter(
          item => item.status == ExpenseClaimAction.REJECTED
        ).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'claimsPending',
      headerName: 'Claims Pending',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const displayText = report.claims.filter(
          item => item.status == ExpenseClaimAction.PENDING
        ).length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'totalExpense',
      headerName: 'Total Expense',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        var totalExpense = 0;
        report.claims.forEach(item => (totalExpense += item.amount));
        const displayText = totalExpense;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const report = getReportForRow(props);
        const color = getPillColorForStatus(report.status);
        return (
          <VegaPill
            text={report.status}
            backgroundColor={color?.LIGHT}
            textColor={color?.DARK}
            borderColor={color?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const report = getReportForRow(props);
        return (
          // <LmButton
          //   text="View Details"
          //   variant="text"
          //   textColor={PRIMARY.darkBlue}
          //   fontWeight={500}
          //   sx={{ px: 0.5 }}
          //   fontSize={'0.875rem'}
          //   bgColor="transparant"
          //   onClick={() => {
          //     data.onViewDetailsClick(report);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(report);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return APPROVER_ONGOING_COLUMN_DEF;
};

const getReportForRow = (props: any) => props.row as ExpenseReportViewModel;
