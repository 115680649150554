import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { VisitsPerDayType } from './FieldAppDashboard';

type PropTypes = {
  data: VisitsPerDayType[];
  loading: boolean;
};
const NumberOfVisitsDayWiseGraph = ({ data, loading }: PropTypes) => {
  const modifyAnalyticsQuery = data.map(item => ({
    categories: item.date,
    series: {
      resolution: item?.count,
    },
  }));

  const yearSeries = [
    {
      name: 'Count',
      data: data.map(item => ({
        x: item.date,
        y: item.count,
        fillColor: '#492c66',
      })),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: modifyAnalyticsQuery?.map(value => value.categories),
    },
    yaxis: {
      title: {
        text: 'No Of Visits',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
  };

  return (
    <>
      <GraphWrapper title="No of Visits Day Wise">
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Chart
              options={options}
              series={yearSeries}
              type="bar"
              height={'100%'}
            />
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default NumberOfVisitsDayWiseGraph;
