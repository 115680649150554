import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { PerformanceServices } from '../../../../Apis/PerformanceServices';
import {
  TOTAL_BRANCH,
  TOTAL_STATES,
} from '../../../../constants/athenaQueryId';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  getPerformanceDashboard,
  setFilterRiskBasePerformance,
} from '../../../../features/performanceDashboardSlice';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { PaymentBucketPerformanceRequest } from '../../../../types/request/loan';
import VegaButton from '../../../common/VegaButton';
import VegaDrawerContent from '../../../common/VegaDrawerContent';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../../common/VegaSelect';
import { DueDateTypes, ProductType } from '../../PerformanceFilterSection';
import VegaAutoComplete from '../../../common/VegaAutoComplete';

enum SegmentType {
  CGCL = 'CGCL',
  CGHFL = 'CGHFL',
}

interface IProps {
  onClear: () => void;
  onApply: () => void;
}

const RiskBasePerformanceFilterDrawer = ({ onClear, onApply }: IProps) => {
  const { user } = useClientAuth();
  const { filterRiskBasePerformance } = useAppSelector(getPerformanceDashboard);
  const dispatch = useAppDispatch();
  const [branchOptions, setbranchOptions] = useState<VegaSelectOption[]>([]);
  const [searchBranch, setSearchBranch] = useState<string>('');
  const [stateOptions, setStateOptions] = useState<VegaSelectOption[]>([]);
  const [searchState, setSearchState] = useState<string>('');

  const handleChange = (
    key: keyof PaymentBucketPerformanceRequest,
    value: string
  ) => {
    dispatch(
      setFilterRiskBasePerformance({
        ...filterRiskBasePerformance,
        [key]: value,
      })
    );
  };
  const disabled =
    !!filterRiskBasePerformance?.dueDate?.length ||
    !!filterRiskBasePerformance?.state?.length ||
    !!filterRiskBasePerformance?.branchCity?.length ||
    !!filterRiskBasePerformance?.segments?.length ||
    !!filterRiskBasePerformance?.product?.length;

  const getBranchList = async (queryId: string) => {
    const body = {
      queryId,
      params: {
        AGENT_ID: user.id,
      },
    };
    const response = await PerformanceServices.getAthenaQueries(body);
    if (!response) {
      setbranchOptions([{ value: 'ALL', label: 'All' }]);
    }
    const options = Object.values(response).map(item => {
      return {
        label: toLowerCase(item.brn_city),
        value: item.brn_city,
      } as VegaSelectOption;
    });
    setbranchOptions(options);
  };

  const getStateList = async (queryId: string) => {
    const body = {
      queryId,
      // params: {
      //   AGENT_ID: user.id,
      // },
    };
    const response = await PerformanceServices.getAthenaQueries(body);
    if (!response) {
      setStateOptions([{ value: 'ALL', label: 'All' }]);
    }
    const options = Object.values(response).map(item => {
      return {
        label: toLowerCase(item.brn_state),
        value: item.brn_state,
      } as VegaSelectOption;
    });
    setStateOptions(options);
  };

  useEffect(() => {
    if (user) {
      getBranchList(TOTAL_BRANCH);
      getStateList(TOTAL_STATES);
    }
  }, [user]);

  return (
    <>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Clear All"
                onClick={onClear}
                variant="text"
                sx={{
                  color: '#1047DC',
                }}
              />
              <VegaButton
                text="Apply Filter"
                onClick={onApply}
                disabled={!disabled}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Due Date'}>
              <VegaSelect
                options={getCycleOptions()}
                value={filterRiskBasePerformance?.dueDate}
                onChange={e =>
                  handleChange('dueDate', e.target.value as string)
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Branch'}>
              <VegaAutoComplete
                searchValue={searchBranch}
                handleChange={e => setSearchBranch(e.target.value)}
                options={filteredOptions(searchBranch, branchOptions)}
                onClear={() => handleChange('branchCity', '' as string)}
                onSelect={selected =>
                  handleChange('branchCity', selected as string)
                }
                selected={filterRiskBasePerformance?.branchCity ?? ''}
                placeHolder="Select branch"
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Segment'}>
              <VegaSelect
                options={getSegmentOptions()}
                value={filterRiskBasePerformance?.segments}
                onChange={e =>
                  handleChange('segments', e.target.value as string)
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Product'}>
              <VegaSelect
                options={getProductOptions()}
                value={filterRiskBasePerformance?.product}
                onChange={e =>
                  handleChange('product', e.target.value as string)
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'state'}>
              <VegaAutoComplete
                searchValue={searchState}
                handleChange={e => setSearchState(e.target.value)}
                options={filteredOptions(searchState, stateOptions)}
                onClear={() => handleChange('state', '' as string)}
                onSelect={selected => handleChange('state', selected as string)}
                selected={filterRiskBasePerformance?.state ?? ''}
                placeHolder="Select state"
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </>
  );
};

export default RiskBasePerformanceFilterDrawer;

const getProductOptions = () => {
  const options = Object.values(ProductType).map(
    product =>
      ({ value: product, label: toLowerCase(product) } as VegaSelectOption)
  );
  return options;
};
const getSegmentOptions = () => {
  const options = Object.keys(SegmentType).map(
    product =>
      ({ value: product, label: toLowerCase(product) } as VegaSelectOption)
  );
  return options;
};
const getCycleOptions = () => {
  const options = Object.keys(DueDateTypes).map(
    product =>
      ({
        value: DueDateTypes[product],
        label: toLowerCase(product),
      } as VegaSelectOption)
  );
  return options;
};

const filteredOptions = (searchValue: string, list: VegaSelectOption[]) => {
  if (searchValue.length) {
    const searchLowerCase = searchValue.toLowerCase();
    const filteredResults = list.filter(value => {
      if (searchValue === '') {
        return value;
      } else if (value.label.toLowerCase().includes(searchLowerCase)) {
        return value;
      }
    });
    return filteredResults;
  } else {
    return list;
  }
};
