import { Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { BidderService } from '../../../../Apis/BidderService';
import VegaAutoComplete from '../../../../components/common/VegaAutoComplete';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { VegaSelectOption } from '../../../../components/common/VegaSelect';
import VegaText from '../../../../components/common/VegaText';
import { DeleteIcon } from '../../../../components/Icons/Icons';
import { Bidder, Possession } from '../../../../types/auction';
import { COLOR } from '../../../../utils/ColorUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    property: Possession,
    formData: Partial<AddBiddersFormData>
  ) => void;
  property?: Possession;
};

export type AddBiddersFormData = {
  bidders: Bidder[];
};

function AddBiddersToAuctionDrawer({
  open,
  onClose,
  onSubmit,
  property,
}: Props) {
  const [bidders, setBidders] = useState<Bidder[]>([]);
  const [userSearchQuery, setUserSearchQuery] = useState<string>('');
  const [formData, setFormData] = useState<Partial<AddBiddersFormData>>({});

  function getUserForId(userId: string) {
    const filtered = bidders.filter(i => i.id == userId) ?? [];
    if (filtered.length > 0) {
      return filtered[0];
    }
  }

  async function fetchBidders() {
    const response = await BidderService.getBidders({});
    setBidders(response.records);
  }

  function addMember(userId: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingUsers = formData.bidders ?? [];
      const isAlreadyPresent = existingUsers.some(i => i.id == userId);
      const user = getUserForId(userId);
      if (isAlreadyPresent == false && user) {
        existingUsers.push(user);
      }
      updatedData.bidders = existingUsers;
      return updatedData;
    });
  }

  function removeMember(userId: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingUsers = formData.bidders ?? [];
      const isAlreadyPresent = existingUsers.some(i => i.id == userId);
      if (isAlreadyPresent) {
        const filteredUsers = existingUsers.filter(i => i.id != userId);
        updatedData.bidders = filteredUsers;
      }
      return updatedData;
    });
  }

  async function onCreateClick() {
    if (!property) {
      return;
    }
    onSubmit(property, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
  }

  const filteredUsers = () => {
    if (userSearchQuery.length > 0) {
      const filteredUsers = bidders.filter(i => {
        const query = userSearchQuery.toLowerCase();
        const fullName = `${i.userName} (${i.mobile})`;
        return fullName.toLowerCase().includes(query);
      });
      return filteredUsers.map(i => {
        const fullName = i.userName;
        const option: VegaSelectOption = {
          value: i.id,
          label: `${fullName} (${i.mobile})`,
        };
        return option;
      });
    }

    return bidders.map(i => {
      const fullName = i.userName;
      const option: VegaSelectOption = {
        value: i.id,
        label: `${fullName} (${i.mobile})`,
      };
      return option;
    });
  };
  const isInputValid = () => {
    const areMembersValid = (formData.bidders ?? []).length > 0;
    return areMembersValid;
  };

  useEffect(() => {
    if (open) fetchBidders();
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Add members'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Update'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Add Members'}>
              <VegaAutoComplete
                options={filteredUsers()}
                handleChange={e => {
                  setUserSearchQuery(e.target.value);
                }}
                onSelect={selected => {
                  addMember(selected);
                }}
                onClear={function (): void {
                  setUserSearchQuery('');
                }}
                selected={undefined}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Members'}>
              <Stack gap={1}>
                {formData?.bidders?.map(i => {
                  return (
                    <div
                      key={i.id}
                      style={{
                        backgroundColor: COLOR.GREEN.LIGHT,
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <VegaText text={i.userName} color={COLOR.GREEN.DARK} />
                      <IconButton onClick={() => removeMember(i.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                })}
              </Stack>
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default AddBiddersToAuctionDrawer;
