import React from 'react';
import VegaTooltip from './VegaTooltip';
import { IconButton, ButtonProps } from '@mui/material';

interface IProps extends ButtonProps {
  tooltipTitle: string;
  icon: JSX.Element;
}

const VegaIconButton = ({ tooltipTitle, icon, ...rest }: IProps) => {
  return (
    <>
      <VegaTooltip title={tooltipTitle}>
        <IconButton
          {...rest}
          sx={{
            borderRadius: '8px',
            padding: '5px',
            ...rest.sx,
          }}
        >
          {icon}
        </IconButton>
      </VegaTooltip>
    </>
  );
};

export default VegaIconButton;
