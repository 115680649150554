import { ResourceDtoObj } from './claim';

export interface RolesProps {
  id: string;
  clientId: string;
  name: string;
  reporter: any | null;
  createdAt: string;
  updatedAt: string;
}
export interface AssignRoleProps {
  userId: string;
  roleIds: string[];
  branchList: string[];
  regionList: string[];
}
export interface AddAccessScreen {
  resourceIds: string[];
  role: string;
}
export interface GetUsersFromRoleNamesRequest {
  roleNames: string[];
}
export interface RoleAccess {
  type: string;
  interFace: string;
}
export interface GetRoleAccessList {
  type: string;
  clientId: string;
}
export interface RoleResourceDto {
  roleDto: RoleDtoObj;
  resourceDtos: ResourceDtoObj[];
  id?: string;
}
export interface RoleResourceDtoList {
  roleResourceDtoList: RoleResourceDto[];
}

export interface RoleDtoObj {
  id: string;
  clientId: string;
  name: string;
  reporterId: null | string;
  createdAt: string;
  updatedAt: string;
}

export interface RoleDto {
  id: null | string;
  userId: string;
  roleDto: RoleDtoObj;
  branch: string;
  region: string;
}
export interface RoleDtoUpdate {
  id: null | string;
  userId: string;
  roleDto: RoleDtoObj;
  branch: string[];
  region: string[];
}

export enum RoleName {
  ADMIN = 'ADMIN',
  ACM = 'ACM',
  TELE_COORDINATOR = 'TELE_COORDINATOR',
  COORDINATOR = 'COORDINATOR',
  NCM = 'NCM',
  ZCM = 'ZCM',
  CE = 'CE',
  ASM = 'ASM',
  CCM = 'CCM',
  RCM = 'RCM',
  CM = 'CM',
  ASSISTANT_COLLECTION_MANAGER = 'ASSISTANT_COLLECTION_MANAGER',
  COLLECTION_EXECUTIVE = 'COLLECTION_EXECUTIVE',
  COLLECTION_MANAGER = 'COLLECTION_MANAGER',
  COLLECTION_OFFICER = 'COLLECTION_OFFICER',
  CLUSTER_COLLECTION_MANAGER = 'CLUSTER_COLLECTION_MANAGER',
  REGIONAL_COLLECTION_MANAGER = 'REGIONAL_COLLECTION_MANAGER',
  TELE_COLLECTION_MANAGER = 'TELE_COLLECTION_MANAGER',
  TELE_COLLECTION_SENIOR_EXECUTIVE = 'TELE_COLLECTION_SENIOR_EXECUTIVE',
  COLLECTION_COORDINATOR = 'COLLECTION_COORDINATOR',
  AREA_COLLECTION_MANAGER = 'AREA_COLLECTION_MANAGER',
  ZONAL_COLLECTION_HEAD = 'ZONAL_COLLECTION_HEAD',
  TELE_COLLECTION_EXECUTIVE = 'TELE_COLLECTION_EXECUTIVE',
  EXECUTIVE = 'EXECUTIVE',
  ASSISTANT_MANAGER = 'ASSISTANT_MANAGER',
  MANAGER = 'MANAGER',
  NATIONAL_COLLECTION_STRATEGY_MANAGER = 'NATIONAL_COLLECTION_STRATEGY_MANAGER',
  SENIOR_EXECUTIVE = 'SENIOR_EXECUTIVE',
  DEPUTY_MANAGER = 'DEPUTY_MANAGER',
  ZONAL_COLLECTION_MANAGER = 'ZONAL_COLLECTION_MANAGER',
  NATIONAL_COLLECTION_MANAGER = 'NATIONAL_COLLECTION_MANAGER',
  ESCALATION_OFFICER = 'ESCALATION_OFFICER',
  GROUP_COLLECTIONS_HEAD = 'GROUP_COLLECTIONS_HEAD',
  DEPUTY_COLLECTION_MANAGER = 'DEPUTY_COLLECTION_MANAGER',
  MANAGER_DATA_ANALYTIC = 'MANAGER_DATA_ANALYTIC',
  AGENDA_CREATOR = 'AGENDA_CREATOR',
  MANAGING_DIRECTOR = 'MANAGING_DIRECTOR',
  OPERATION_TEAM = 'OPERATION_TEAM',
  TECHNICAL_TEAM = 'TECHNICAL_TEAM',
  BIDDER = 'BIDDER',
  COMMITTEE_CHAIR = 'COMMITTEE_CHAIR',
}
