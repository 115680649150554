import { Grid } from '@mui/material';
import React from 'react';
import PenalRoleBasedPerformance from '../../components/PenalRoleBasePerformance';
import PenalRegionPerformanceGraph from './components/PenalRegionPerformanceGraph';
import PenalBucketPerformanceGraph from './components/PenalBucketPerformanceGraph';
import PenalChannelPerformanceGraph from './components/PenalChannelPerformanceGraph';

const PenalContainer = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PenalRegionPerformanceGraph />
        </Grid>
        <Grid item xs={12}>
          <PenalBucketPerformanceGraph />
        </Grid>
        <Grid item xs={12}>
          <PenalChannelPerformanceGraph />
        </Grid>
        <Grid item xs={12}>
          <PenalRoleBasedPerformance />
        </Grid>
      </Grid>
    </>
  );
};

export default PenalContainer;
