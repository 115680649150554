import { useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import VegaButton from '../../../../components/common/VegaButton';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import { OtsService } from '../../../../Apis/OtsService';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useOts } from '../../../../providers/OtsProvider';
import { useDrawer } from '../../../../hooks/useDrawer';
import RejectOtsDialog from './RejectOtsDialog';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { OtsAction, OtsReportType } from '../../../../types/ots';

type Props = {
  reportType: OtsReportType;
};

function OtsReportActionView({ reportType }: Props) {
  const { setSnackbar } = useSnackbar();
  const { ots, fetchOts } = useOts();
  const { userId, user } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    open: openRejectionDialog,
    close: closeRejectionDialog,
    isOpen: isRejectionDialogOpen,
  } = useDrawer();

  async function onAcceptClick() {
    try {
      setLoading(true);
      await OtsService.takeActionOnReport({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.APPROVE,
        reportType: reportType,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onRejectConfirm(reason: string) {
    try {
      setLoading(true);
      await OtsService.takeActionOnReport({
        otsId: ots.id,
        userId: user?.id,
        action: OtsAction.REJECT,
        description: reason,
        reportType: reportType,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <OtsDetailsWrapper>
        <Stack>
          {loading && (
            <Stack direction="row" justifyContent={'end'}>
              <CircularProgress size={30} />
            </Stack>
          )}
          {loading == false && (
            <Stack gap={2} direction="row" justifyContent={'end'}>
              <VegaButton
                variant="outlined"
                text={'Reject'}
                onClick={() => openRejectionDialog()}
              />
              <VegaButton text={'Accept'} onClick={onAcceptClick} />
            </Stack>
          )}
        </Stack>
      </OtsDetailsWrapper>
      <RejectOtsDialog
        show={isRejectionDialogOpen}
        onClose={function (): void {
          closeRejectionDialog();
        }}
        onReject={function (reason: string): void {
          onRejectConfirm(reason);
        }}
      />
    </>
  );
}

export default OtsReportActionView;
