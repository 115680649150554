import { Stack } from '@mui/material';
import { getIncentivesGamificationsState } from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppSelector } from '../../../store';
import { RuleProps } from '../../../types/incentives';
import {
  BumperPolicyRangeProps,
  PanaltyPolicyRangeProps,
} from '../../../types/request/incentives';
import VegaPageContent from '../../common/VegaPageContent';
import VegaTitleCard from '../../common/VegaTitleCard';
import ViewBaseIncentive from '../components/ViewBaseIncentive';
import ViewBumperIncentive from '../components/ViewBumperIncentive';
import ViewPenaltyIncentive from '../components/ViewPenaltyIncentive';

interface IProps {
  rules: Partial<RuleProps>;
}

const ViewBucketComponent = ({ rules }: IProps) => {
  const { loading } = useAppSelector(getIncentivesGamificationsState);

  return (
    <>
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Base Incentive">
          <Stack gap={2}>
            <ViewBaseIncentive loading={loading} rules={rules} />
          </Stack>
        </VegaTitleCard>
      </VegaPageContent>

      {rules?.bumperPayoutRange?.length ? (
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Bumper Incentive">
            <>
              {rules?.bumperPayoutRange.map(
                (bumperIncentive: BumperPolicyRangeProps, index: number) => (
                  <ViewBumperIncentive
                    key={index}
                    loading={loading}
                    bumperIncentive={bumperIncentive}
                    index={index}
                  />
                )
              )}
            </>
          </VegaTitleCard>
        </VegaPageContent>
      ) : (
        ''
      )}
      {rules?.penaltyRange?.length ? (
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Penalty">
            <>
              {rules?.penaltyRange.map(
                (penaltyIncentive: PanaltyPolicyRangeProps, index: number) => (
                  <ViewPenaltyIncentive
                    key={index}
                    loading={loading}
                    penaltyIncentive={penaltyIncentive}
                    index={index}
                  />
                )
              )}
            </>
          </VegaTitleCard>
        </VegaPageContent>
      ) : (
        ''
      )}
      {/* {rules?.minimumEligibilityParameters && (
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Minimum Eligibility Criteria">
            <>
              <ViewEligibilityIncentive
                loading={loading}
                eligibility={rules?.minimumEligibilityParameters}
              />
            </>
          </VegaTitleCard>
        </VegaPageContent>
      )} */}
    </>
  );
};

export default ViewBucketComponent;
