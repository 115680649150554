import { Box, Collapse, Stack } from '@mui/material';
import React, { useState } from 'react';
import { COMMON } from '../../constants/style';
import VegaText from './VegaText';
import VegaCheckbox from './VegaCheckbox';
import { useClickOutSide } from '../../constants/commonFunction';
import { Menu } from '@mui/icons-material';

interface ColumnsProps {
  [key: string]: string;
}

interface IProps {
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  columns: ColumnsProps;
}

const VegaColumnFilterSection = ({
  columns,
  selectedOptions,
  setSelectedOptions,
}: IProps) => {
  const [show, setShow] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const handleSelectionChange = (option: string) => {
    const updatedSelection = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedSelection);
  };
  return (
    <>
      <div ref={domNode} style={{ position: 'relative' }}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={1}
          sx={{ cursor: 'pointer' }}
          onClick={() => setShow(!show)}
        >
          <Menu sx={{ color: COMMON.light, fontSize: '1rem' }} />
          <VegaText text="Columns" fontSize={'0.75rem'} color={'#676B76'} />
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            bgcolor: 'white',
            zIndex: 9999,
            width: '200px',
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: '6px',
            }}
            in={show}
          >
            <Box sx={{ py: 1, height: 300, overflow: 'auto' }}>
              {Object.keys(columns).map(column => {
                return (
                  <Stack
                    key={column}
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                  >
                    <VegaCheckbox
                      checked={!selectedOptions.includes(columns[column])}
                      onChange={() => handleSelectionChange(columns[column])}
                    />
                    <VegaText text={columns[column]} />
                  </Stack>
                );
              })}
            </Box>
          </Collapse>
        </Box>
      </div>
    </>
  );
};

export default VegaColumnFilterSection;

export enum LoanAccountColumns {
  // UNIQUE_REFRANCE_NUMBER = 'Unique Reference Number',
  LOAN_ACCOUNT_NUMBER = 'Loan Account Number',
  NAME = 'Name',
  CONTACT_NUMBER = 'Contact Number',
  ADDRESS = 'Address',
  ZIPCODE = 'Zipcode',
  BRN_REGION = 'Branch Region Code',
  BRANCH_CODE = 'Branch Code',
  PRODUCT = 'Product',
  MODEL_PARSER_ALLOCATION_TYPE = 'Model Parser AllocationType',
  CONLLECTION_CATEGORY = 'Collection Category',
  AGENT = 'Agent',
  MANAGER_NAME = 'Manager Name',
  CUSTOMER_TYPE = 'Customer Type',
  OUTSTANDING_PAYMENT = 'Outstanding Payment',
  OVERDUE_PAYMENT = 'Overdue Amount',
  PTP_PROBABLITY = 'PTP Probablity',
  PREDICTED_PTP_DATE = 'Predicted PTP date',
  PENDING_SETTLEMENT = 'Pending Settlement',
  PROMISE_TO_PAY = 'Promise To Pay',
  EMI = 'EMI',
  LAST_PAYMENT_RECIVED = 'Last Payment Received',
  DELIQUENCY = 'Delinquency',
  CYCLE_DATE = 'Cycle Date',
  SEGMENT = 'Segment',
  DPD = 'DPD',
  BRANCH = 'Branch',
  STATE = 'State',
  CITY = 'City',
  DISPOSITION_DATE = 'Disposition Date',
  DISPOSITION_GROUP = 'Disposition Group',
  DISPOSITION_CODE = 'Disposition Code',
  LOAN_PREDICTED_STATUS = 'Loan Predicted Status',
  ACCOUNT_CLOSER_DATE = 'Account Closer Date',
  ACCOUNT_STATUS = 'Account Status',
  ADJUSTED_PRINCIPAL = 'Adjusted Principal',
  BALANCE_TENOR = 'Balance Tenor',
  PREV_BUCKET = 'Prev Bucket',
  DATE_OF_BIRTH = 'Date Of Birth',
  FIRST_DISBURSAL_DATE = 'First Disbursal Dat',
  LAST_DISBURSAL_DATE = 'Last Disbursal Date',
  FINANCE_AMOUNT = 'Finance Amount',
  GENDER = 'Gender',
  INTEREST_DUE = 'Interest Due',
  PRINCIPAL_DUE = 'Principal Due',
  TERM_IN_MONTHS = 'Term In Months',
  NEXT_INSTALLMENT_DATE = 'Next Installment Date',
  CHARGE_DUE = 'Charge Due',
  BRN_CITY = 'Brn City',
  BRN_STATE = 'Brn State',
  NPA_FLAG = 'NPA Flag',
  IS_BOUNCE = 'Is Bounce',
  PAYMENT_CATEGORY = 'Payment Category',
  ALLOCATION_DATE = 'Allocation Date',
  LOAN_STATUS = 'Loan Status',
  NEXT_ACTION = 'Next Action',
  OTS_RAISED = 'OTS Raised',
  ESCALATION_TYPE = 'Escalation Type',
  REFER_TO_FIELD = 'Refer To Field',
  PENAL_CHARGE_DUE = 'Penal Charge Due',
  MOB = 'MOB',
  LEGAL_STATUS = 'Legal Status',
  OTS_BREACHED = 'Ots Breached',
}

export enum ReceiptColumns {
  RECEIPT_NUMBER = 'Receipt Number',
  RECEIPT_DATE = 'Receipt Date',
  LAN = 'LAN',
  CUSTOMER_NAME = 'Customer name',
  DEPOSITE_CENTER = 'Deposit Centre',
  DEPOSITED_DATE = 'Deposited Date',
  DEPOSIT_REFRENCE_NUMBER = 'Deposit Reference Number',
  TOTAL_RECEIPT_AMOUNT = 'Total Receipt Amount',
  MODE_OF_PAYMENT = 'Mode of Payment',
  BRANCH = 'Branch',
  REGION = 'Region',
  PAYMENT_MESSAGE = 'Payment Message',
  REJECTION_REMARKS = 'Rejection Remarks',
  PAYMENT_STATUS = 'Payment Status',
  INSTRUMENT_NUMBER = 'Instrument Number',
  INSTRUMENT_BANK_DETAILS = 'Instrument Bank Details',
  PAYMENT_TYPE = 'Payment Type',
  OVERDUE_AMOUNT = 'Overdue Amount',
  BOUNCE_CHARGE = 'Bounce Charges',
  PENAL_CHARGE = 'Penal Charges',
  OTHER_CHARGE = 'Other Charges',
  COLLECTOR_CODES = 'Collector Codes',
  COLLECTOR_NANE = 'Collector Name',
  DEPOSIT_CENTER_NAME = 'Deposit Centre Name',
  DETAILS = 'Details',
  ACTION = 'Action',
}

export enum AccountColumns {
  NAME = 'Name',
  LOAN_ACCOUNT_NUMBER = 'Loan Account Number',
  POS = 'POS',
  BUCKET = 'Bucket',
  CONTACT_NUMBER = 'Contact number',
  ALLOCATION_DATE = 'Allocation Date',
  ADDRESS = 'Address',
  ACTION = 'Action',
}
export enum ReceiptCancellationColumn {
  RECEIPT_NUMBER = 'Receipt Number',
  RECEIPT_DATE = 'Receipt Date',
  LAN = 'LAN',
  CUSTOMER_NAME = 'Customer name',
  APPROVER_NAME = 'Approver name',
  AGENT_REMARK = 'Agent remark',
  APPROVER_REMARK = 'Approver remark',
  STATUS = 'Status',
}
