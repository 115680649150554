/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import { ArrowBackIos } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import LmTextField from '../components/common/LmTextField';
import VegaButton from '../components/common/VegaButton';
import usePassword from '../hooks/usePassword';
import { useClientAuth } from '../providers/ClientProvider';

interface IProps {
  forgotPasswordHandler: (state: string) => void;
}

const NewPassword = ({ forgotPasswordHandler }: IProps) => {
  const { completeNewPassword } = useClientAuth();
  const { password, setPassword, renderValidations } = usePassword();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton onClick={() => forgotPasswordHandler('FORGOT')}>
            <ArrowBackIos sx={{ color: 'black', fontSize: 14 }} />
          </IconButton>
          <Typography fontSize={14} className="font-aspekta-600">
            Back
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '400px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography fontSize={17} className="font-aspekta-600">
            New password
          </Typography>
          <Box
            my={3}
            component={'form'}
            onSubmit={e => {
              e.preventDefault();
              completeNewPassword(password);
            }}
          >
            <Box mb={2}>
              <LmTextField
                header="new password"
                type="password"
                value={password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setPassword(inputValue);
                }}
              />
            </Box>
            <Box mt={1} maxWidth={400}>
              {renderValidations()}
            </Box>
            <Stack spacing={2}>
              <VegaButton
                type="submit"
                text="Continue"
                disabled={!password.length}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewPassword;
