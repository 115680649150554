import { createContext, useContext, useEffect, useState } from 'react';
import { NotificationService } from '../Apis/NotificationService';
import { NotificationChannel, VegaNotification } from '../types/notification';
import { NotificationStatus } from '../types/request/notification';
import { PaginatedResponse } from '../utils/api';
import { useClientAuth } from './ClientProvider';

type NotificationContextType = {
  fetchNotifications: (page: number) => void;
  loading: boolean;
  updateNotificationStatus: (notification: VegaNotification) => void;
  notifications: PaginatedResponse<VegaNotification>;
  totalNotifications: number;
};

const NotificationContext = createContext<NotificationContextType | null>(null);

export const useNotification = () =>
  useContext(NotificationContext) as NotificationContextType;

export const NotificationProvider = ({ children }: any) => {
  const { userId } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<
    PaginatedResponse<VegaNotification>
  >({
    records: [],
    pageNumber: 0,
    numberOfItems: 0,
    totalPages: 0,
    totalItems: 0,
  });

  async function fetchNotifications(page: number) {
    setLoading(true);
    setNotifications({
      records: [],
      pageNumber: 0,
      numberOfItems: 0,
      totalPages: 0,
      totalItems: 0,
    });
    const response = await NotificationService.getNotificationList({
      recipientUserIds: [userId],
      channel: NotificationChannel.PUSH,
      status: NotificationStatus.UNREAD,
      size: 20,
      page: page,
    });
    setNotifications(response);
    setLoading(false);
  }

  function _updateNotificationStatus(notification: VegaNotification) {
    NotificationService.updateStatus({
      id: notification.id,
      status: NotificationStatus.READ,
    }).then(() => {
      fetchNotifications(0);
    });
  }

  useEffect(() => {
    if (!userId) return;
    fetchNotifications(0);
  }, [userId]);

  return (
    <NotificationContext.Provider
      value={{
        notifications: notifications,
        totalNotifications: notifications.totalItems,
        updateNotificationStatus: _updateNotificationStatus,
        fetchNotifications: fetchNotifications,
        loading: loading,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
