import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import { BORDER_COLLECTION, COMMON } from '../../../constants/style';
import { fetchLeaveList } from '../../../features/leaveRequestSlice';
import { ROUTES } from '../../../router/routes';
import { RootState, useAppDispatch } from '../../../store';
import { LeaveStatus, LeaveViewModel } from '../../../types/leave';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import { LEAVE_APPROVER_ID } from '../../../utils/EXPENSE_CONSTANTS';
import VegaButton from '../../common/VegaButton';
import VegaFormInputField from '../../common/VegaFormInputField';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';

interface IProps {
  date: string;
}

const LeaveCard = ({ date }: IProps) => {
  const leaves = useSelector<RootState, LeaveViewModel[]>(
    state => state.leaveRequestAction.leaves
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchLeaveList({
        approverId: LEAVE_APPROVER_ID,
        leaveDate: DateUtility.formatStringToYYYYMMDD(date),
      })
    );
  }, [date]);

  return (
    <Box
      sx={{
        borderRadius: '0.5rem',
        border: BORDER_COLLECTION,
        p: '1.5rem',
        bgcolor: 'white',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
      >
        <VegaText text={'Leaves'} fontWeight={600} fontSize={'1rem'} />
        <VegaButton
          variant="text"
          text="View More"
          sx={{
            py: 0,
          }}
          onClick={() => navigate(ROUTES.LEAVE_REQUEST_ACTIONS)}
        />
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        my={'1rem'}
        gap={1}
      >
        {leaves.length ? (
          <>
            <VegaText
              text={`${toLowerCase(leaves[0]?.typesofLeave)} Leave`}
              fontWeight={600}
              fontSize={'1rem'}
            />

            <VegaPill
              text={leaves[0].status}
              backgroundColor={getColorForStatus(leaves[0].status)?.LIGHT}
              key={leaves[0].status}
              textColor={getColorForStatus(leaves[0].status)?.DARK}
            />
          </>
        ) : (
          ''
        )}
      </Stack>

      <Stack mt={'1rem'} gap={0}>
        <VegaFormInputField label={'date'}>
          {leaves.length ? (
            <VegaText
              color={COMMON.balck}
              fontWeight={400}
              fontSize={12}
              text={`${DateUtility.formatStringToDDMMMMYYYY(
                leaves[0]?.startDate
              )}, ${toLowerCase(
                leaves[0]?.startDateHalf
              )} - ${DateUtility.formatStringToDDMMMMYYYY(
                leaves[0]?.endDate
              )}, ${toLowerCase(leaves[0]?.endDateHalf)}`}
              sx={{
                lineHeight: '15.6px',
              }}
            />
          ) : (
            ''
          )}
        </VegaFormInputField>
      </Stack>
    </Box>
  );
};

export default LeaveCard;

const getColorForStatus = (status?: LeaveStatus | string) => {
  if (!status) return;
  switch (status) {
    case LeaveStatus.APPROVED:
      return COLOR.GREEN;
    case LeaveStatus.APPLIED:
      return COLOR.ORANGE;
    case LeaveStatus.REJECTED:
      return COLOR.RED;
  }
};
