import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState } from 'react';
import {
  CreateApplyLeaveFormData,
  HalfOfDay,
  TypeOfLeave,
} from '../../types/leave';
import { DateUtility } from '../../utils/DateUtlility';
import LmButton from '../common/LmButton';
import LmTextField from '../common/LmTextField';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

enum FormField {
  TYPE_OF_LEAVE = 'typeOfLeave',
  START_DATE = 'startDate',
  START_DATE_HALF = 'startDateHalf',
  END_DATE = 'endDate',
  END_DATE_HALF = 'endDateHalf',
  REASON = 'reason',
}

type Props = {
  onSubmit: (formData: Partial<CreateApplyLeaveFormData>) => void;
  loading: boolean;
};
const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 2);
const CreateLeaveForm = ({ onSubmit, loading }: Props) => {
  const [formData, setFormData] = useState<Partial<CreateApplyLeaveFormData>>({
    startDate: TODAY.toISOString(),
    endDate: TOMORROW.toISOString(),
  });

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function onCreateClick() {
    onSubmit(formData);
  }

  const setTypeOfLeaveField = (value: string) => {
    updateFormData(FormField.TYPE_OF_LEAVE, value);
  };
  const setStartDateField = (value: any) => {
    const parsedDate = DateUtility.parseDateFromDatePicker(value);
    var endDate = DateUtility.parseStringToDate(formData.endDate);
    if (endDate && parsedDate) {
      if (DateUtility.isAfter(endDate, parsedDate)) {
        endDate = DateUtility.addDays(parsedDate, 1);
        setFormData(prev => {
          return {
            ...prev,
            startDate: parsedDate?.toISOString(),
            endDate: endDate?.toISOString(),
          };
        });
      }
    }
  };
  const setStartDateHalfField = (value: string) => {
    updateFormData(FormField.START_DATE_HALF, value);
  };
  const setEndDateField = (value: Date) => {
    const parsedDate = DateUtility.parseDateFromDatePicker(value);
    updateFormData(FormField.END_DATE, parsedDate?.toISOString());
  };
  const setEndDateHalfField = (value: string) => {
    updateFormData(FormField.END_DATE_HALF, value);
  };
  const setReasonField = (value: string) => {
    updateFormData(FormField.REASON, value);
  };

  const isInputValid = (formData: Partial<CreateApplyLeaveFormData>) => {
    if (
      !formData.typeOfLeave ||
      !formData.startDate ||
      !formData.startDateHalf ||
      !formData.endDate ||
      !formData.endDateHalf ||
      !formData.reason
    ) {
      return false;
    }
    return true;
  };

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <LmButton
            loading={loading}
            text="Apply"
            disabled={isInputValid(formData) == false}
            onClick={() => {
              onCreateClick();
            }}
          />
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Type of leave">
            <VegaSelect
              options={typeOfLeaveOptions()}
              value={formData.typeOfLeave}
              onChange={e => setTypeOfLeaveField(e.target.value as string)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Start Date">
            <VegaDatePicker
              value={formData.startDate}
              onChange={e => setStartDateField(e)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Select Half">
            <VegaSelect
              options={StartDateHalfOfDay(formData)}
              value={formData.startDateHalf}
              onChange={e => setStartDateHalfField(e.target.value as string)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="End Date">
            <VegaDatePicker
              minDate={formData.startDate}
              value={formData.endDate}
              onChange={e => setEndDateField(e)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Select Half">
            <VegaSelect
              options={EndDateHalfOfDay(formData)}
              value={formData.typeOfLeave}
              onChange={e => setEndDateHalfField(e.target.value as string)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <LmTextField
            placeholder="Enter Reason"
            header="Reason"
            value={formData.reason}
            onChange={e => {
              setReasonField(e.target.value as string);
            }}
          />
        </Grid>
      </Grid>
    </VegaDrawerContent>
  );
};

export default CreateLeaveForm;

const typeOfLeaveOptions = () =>
  Object.values(TypeOfLeave).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const StartDateHalfOfDay = (formData: Partial<CreateApplyLeaveFormData>) => {
  const isEndDateFirstHalf = formData.endDateHalf === HalfOfDay.FIRST_HALF;
  const areStartAndEndDateSame = dayjs(formData.startDate).isSame(
    formData.endDate,
    'day'
  );
  const options = Object.values(HalfOfDay)
    .map(item => {
      if (
        isEndDateFirstHalf &&
        areStartAndEndDateSame &&
        item !== HalfOfDay.FIRST_HALF
      ) {
        return null;
      }
      const option: VegaSelectOption = {
        value: item,
        label: _.startCase(_.toLower(item)),
      };
      return option;
    })
    .filter(option => option !== null);
  return options;
};

const EndDateHalfOfDay = (formData: Partial<CreateApplyLeaveFormData>) => {
  const isStartDateSecondHalf =
    formData.startDateHalf === HalfOfDay.SECOND_HALF;
  const areStartAndEndDateSame = dayjs(formData.startDate).isSame(
    formData.endDate,
    'day'
  );

  return Object.values(HalfOfDay)
    .map(item => {
      if (
        isStartDateSecondHalf &&
        areStartAndEndDateSame &&
        item !== HalfOfDay.SECOND_HALF
      ) {
        return null;
      }

      const option: VegaSelectOption = {
        value: item,
        label: _.startCase(_.toLower(item)),
      };
      return option;
    })
    .filter(Boolean);
};

// const endDateHandler = formData => {
//   return formData.startDateHalf === HalfOfDay.SECOND_HALF
//     ? DateUtility.addDays(formData.startDate, 1)
//     : formData.startDate;
// };
