import axios from 'axios';
import { ApiService } from '../types/api';
import { LoanInformation } from '../types/performanceType';
import { PerformanceRequest } from '../types/request/performanceRequest';

const Endpoint = {
  BASE: '/master/reporting',
  TEST_ATHENA_QUERY_ID: '/execute/athena/:queryId',
};

export class PerformanceServices extends ApiService {
  static async getAthenaQueries(
    request: Partial<PerformanceRequest>
  ): Promise<any[]> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.TEST_ATHENA_QUERY_ID.replace(':queryId', request.queryId);
    const response = await axios.post(endpoint, { params: request.params });
    return response.data;
  }
  static async getAthenaQueriesForLoanQuery(
    request: Partial<PerformanceRequest>
  ): Promise<LoanInformation[]> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.TEST_ATHENA_QUERY_ID.replace(':queryId', request.queryId);
    const response = await axios.post(endpoint, { params: request.params });
    return response.data;
  }
}
