import { AddCircleOutline } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import CustomerBankFilterForm from '../components/CustomerBranchInfo/CustomerBranchFilterForm';
import ListOfCustomerBankInfo from '../components/CustomerBranchInfo/ListOfCustomerBankInfo';
import AddNewBranchInfo from '../components/CustomerBranchInfo/AddNewBranchInfo';
import LmButton from '../components/common/LmButton';
import LmFilterSection from '../components/common/LmFilterSection';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaDrawerContent from '../components/common/VegaDrawerContent';
import VegaPageHeader from '../components/common/VegaPageHeader';
import {
  defaultCustomerBankData,
  useBankCustomer,
} from '../providers/CustomerBankProvider';
import { ICreateCustomerBranchProps } from '../types/customerBank';
import { useDrawer } from '../hooks/useDrawer';
import { useSnackbar } from '../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { getCustomerBranchExportData } from '../Apis/customerBank';
import { StringUtility } from '../utils/StringUtility';
const CustomerBank = () => {
  const {
    loading: loader,
    isAdd,
    customerBankState,
    selectedId,
    toggleAddBankBranchDrawer,
    onAddCustomerBankSubmit,
    onUpdateCustomerBankSubmit,
    setCustomerBankState,
    setSelectedId,
    setSearch,
  } = useBankCustomer();

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const { setSnackbar } = useSnackbar();
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [exporting, setExporting] = useState<boolean>(false);
  const exportMetaData = async request => {
    const url = await getCustomerBranchExportData(request);
    window.open(url, '__blank__');
  };
  const validate = (key: string, value): boolean => {
    if (key === 'micr') return !StringUtility.validationMicrNumber(value);
    return false;
  };
  function hasEmptyOrNullProperty(obj) {
    if (!obj) return true;
    for (const [key, value] of Object.entries(obj)) {
      if (
        value === null ||
        value === undefined ||
        value === '' ||
        validate(key, value)
      )
        return true;
    }
    return false;
  }
  const isDisabled = hasEmptyOrNullProperty(customerBankState);
  const exportReceiptMetaData = async () => {
    const request = {
      page: 0,
      size: 10,
    };
    try {
      setExporting(true);
      await exportMetaData(request);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setExporting(false);
    }
  };
  const checkCondition = (selectedId: string | null) => {
    if (selectedId) {
      return {
        title: 'Update Bank Branch',
        buttonText: 'Update Details',
        buttonClick: () => {
          const newCustomerBankObj = {
            ...customerBankState,
            id: selectedId,
          } as ICreateCustomerBranchProps;

          onUpdateCustomerBankSubmit(newCustomerBankObj);
        },
        loading: loader,
        isEditable: true,
      };
    } else {
      return {
        title: 'Add New Branch Info',
        buttonText: 'Save Details',
        buttonClick: onAddCustomerBankSubmit,
        loading: loader,
        isEditable: false,
      };
    }
  };

  return (
    <>
      <VegaPageHeader
        title="Customer Branch Info"
        renderRightView={() => (
          <Stack direction={'row'} gap={1}>
            <LmButton
              text="Add New Branch Info"
              fontWeight={500}
              startIcon={<AddCircleOutline />}
              onClick={() => {
                setSelectedId(null);
                setCustomerBankState({ ...defaultCustomerBankData });
                toggleAddBankBranchDrawer();
              }}
            />
            <LmButton
              text={exporting ? 'Exporting...' : 'Export'}
              onClick={exportReceiptMetaData}
              loading={exporting}
            />
          </Stack>
        )}
      />
      <Box p={3}>
        <LmFilterSection
          setSearch={setSearch}
          onFilter={() => openFilterDrawer()}
        />
        <Box pt={3}>
          <ListOfCustomerBankInfo />
        </Box>
      </Box>
      <VegaDrawer
        title={checkCondition(selectedId).title}
        open={isAdd}
        onClose={toggleAddBankBranchDrawer}
      >
        <VegaDrawerContent
          renderBottomView={() => (
            <LmButton
              text={checkCondition(selectedId).buttonText}
              disabled={isDisabled}
              fontWeight={500}
              onClick={checkCondition(selectedId).buttonClick}
              loading={checkCondition(selectedId).loading}
            />
          )}
        >
          <AddNewBranchInfo
            isEditable={checkCondition(selectedId).isEditable}
          />
        </VegaDrawerContent>
      </VegaDrawer>
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <CustomerBankFilterForm
          onClose={closeFilterDrawer}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
      </VegaDrawer>
    </>
  );
};

export default CustomerBank;
