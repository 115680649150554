import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { OtsService } from '../../../../Apis/OtsService';
import VegaButton from '../../../../components/common/VegaButton';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFileSelect, {
  VegaFileSelectOption,
  AcceptedExtension,
} from '../../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaText from '../../../../components/common/VegaText';
import { AnimatedInputWrapper } from '../../../../components/ExpenseClaim/CreateClaimForm';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import {
  OtsAuditType,
  OtsReportsStatus,
  Ots,
  OtsRequestAudit,
} from '../../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { COLOR } from '../../../../utils/ColorUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  otsId?: string;
  onSubmit: (otsId: string, data: Partial<SubmitOperationDataFormData>) => void;
};

enum FormField {
  ValuationReport = 'valuationReport',
  StatementOfAccount = 'statementOfAccount',
  ForeclosureLetter = 'foreclosureLetter',
  SanctionLetter = 'sanctionLetter',
}

export type SubmitOperationDataFormData = {
  valuationReport: VegaFileSelectOption;
  statementOfAccount: VegaFileSelectOption;
  foreclosureLetter: VegaFileSelectOption;
  sanctionLetter: VegaFileSelectOption;
};

const ACCEPTED_EXTENSIONS = [
  AcceptedExtension.JPG,
  AcceptedExtension.JPEG,
  AcceptedExtension.PNG,
  AcceptedExtension.PDF,
];
function SubmitOperationReportDrawer({
  otsId,
  open,
  onClose,
  onSubmit,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const [ots, setOts] = useState<Partial<Ots>>({});
  const [audit, setAudit] = useState<Partial<OtsRequestAudit>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<
    Partial<SubmitOperationDataFormData>
  >({});

  async function fetchData() {
    try {
      setLoading(true);
      const response = await OtsService.getOtsById(otsId);
      const auditResponse = await OtsService.getOtsAudit({
        otsId: otsId,
        auditType: OtsAuditType.OPERATION_REPORT,
      });
      if (auditResponse.records.length > 0) {
        const latestAudit = auditResponse.records[0];
        setAudit(latestAudit);
      }
      setOts(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function updateFormData(field: FormField, value: any) {
    if (isPendingApproval()) return;
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function onSubmitClick() {
    if (!otsId) return;
    onSubmit(otsId, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
    setAudit(undefined);
    setOts(undefined);
  }

  const isInputValid = () => {
    if (isPendingApproval()) return false;
    const isValuationReportValid = formData.valuationReport != null;
    const isStatementOfAccountValid = formData.statementOfAccount != null;
    const isForeclosuresValid = formData.foreclosureLetter != null;
    const isSanctionLetter = formData.sanctionLetter != null;
    return (
      isValuationReportValid &&
      isStatementOfAccountValid &&
      isForeclosuresValid &&
      isSanctionLetter
    );
  };

  function prefillData(ots: Partial<Ots>) {
    setFormData(prev => {
      var updatedData = { ...prev };
      const valuationReport = ots?.operationValuationReport;
      const statementOfAccount = ots?.statementOfAccount;
      const foreclosureLetter = ots?.foreclosureLetter;
      const sanctionLetter = ots?.sanctionLetter;
      if (valuationReport) {
        updatedData.valuationReport = {
          id: valuationReport,
          label: 'Valuation Report',
        };
      }

      if (statementOfAccount) {
        updatedData.statementOfAccount = {
          id: statementOfAccount,
          label: 'Statement Of Account',
        };
      }

      if (foreclosureLetter) {
        updatedData.foreclosureLetter = {
          id: foreclosureLetter,
          label: 'Foreclosure Letter',
        };
      }

      if (sanctionLetter) {
        updatedData.sanctionLetter = {
          id: sanctionLetter,
          label: 'Sanction Letter',
        };
      }

      return updatedData;
    });
  }

  const wasRejected = () => {
    return ots?.operationReportStatus == OtsReportsStatus.REJECTED;
  };

  const isPendingApproval = () => {
    return ots?.operationReportStatus == OtsReportsStatus.PENDING;
  };

  useEffect(() => {
    if (open && otsId) {
      fetchData();
    }
  }, [open, otsId]);

  useEffect(() => {
    if (ots) {
      prefillData(ots);
    }
  }, [ots]);

  return (
    <VegaDrawer
      open={open}
      title={
        wasRejected() ? 'Update Operation detals' : 'Add Operation details'
      }
      onClose={handleClose}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={wasRejected() ? 'Resubmit' : 'Submit'}
              disabled={isInputValid() == false}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <AnimatedInputWrapper show={wasRejected()} marginTop={'0.25rem'}>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: COLOR.RED.LIGHT,
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                }}
              >
                <VegaText
                  text={audit?.description ?? '-'}
                  color={COLOR.RED.DARK}
                />
              </div>
            </Grid>
          </AnimatedInputWrapper>

          <Grid item xs={12}>
            <VegaFormInputField label={'Valuation Report'}>
              <VegaFileSelect
                disabled={isPendingApproval()}
                value={formData.valuationReport}
                acceptedExtension={ACCEPTED_EXTENSIONS}
                placeholder={'Upload valuation report'}
                onFileSelect={file => {
                  updateFormData(FormField.ValuationReport, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.ValuationReport, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Statement Of Account'}>
              <VegaFileSelect
                disabled={isPendingApproval()}
                value={formData.statementOfAccount}
                acceptedExtension={ACCEPTED_EXTENSIONS}
                placeholder={'Upload Statement of account'}
                onFileSelect={file => {
                  updateFormData(FormField.StatementOfAccount, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.StatementOfAccount, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Foreclosure Letter'}>
              <VegaFileSelect
                disabled={isPendingApproval()}
                value={formData.foreclosureLetter}
                acceptedExtension={ACCEPTED_EXTENSIONS}
                placeholder={'Upload foreclosure letter'}
                onFileSelect={file => {
                  updateFormData(FormField.ForeclosureLetter, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.ForeclosureLetter, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Sanction Letter'}>
              <VegaFileSelect
                disabled={isPendingApproval()}
                value={formData.sanctionLetter}
                acceptedExtension={ACCEPTED_EXTENSIONS}
                placeholder={'Upload sanction letter'}
                onFileSelect={file => {
                  updateFormData(FormField.SanctionLetter, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.SanctionLetter, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default SubmitOperationReportDrawer;
