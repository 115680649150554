import { Box, Stack } from '@mui/material';
import {
  getIncentivesGamificationsState,
  initailIncentiveProposed,
  initialPosWaiverRange,
  setPolicyDetailsState,
  setWriteOffruleState,
} from '../../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { isWriteOfEmptyOrUndefined } from '../../../../pages/Configurations/Configurations';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ParameterDefinitionType } from '../../../../types/incentives';
import {
  IncentiveWriteOffRule,
  IncentiveWriteOffRulesRequest,
  LanPayoutRange,
  PosWaiverRange,
} from '../../../../types/request/incentives';
import { getOrdinalNumber } from '../../../../utils/DateUtlility';
import { PlusCircleIcon } from '../../../Icons/Icons';
import VegaButton from '../../../common/VegaButton';
import RulesIncentiveProposed from './RulesIncentiveProposed';
import { IncentiveServices } from '../../../../Apis/IncentivesService';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const IncentiveProposedComponent = () => {
  const { policyId } = useParams();
  const { writeOffruleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmitProposedIncentive = async () => {
    setLoading(true);
    try {
      const response = await IncentiveServices.addWriteOffPolicy({
        ...writeOffruleState,
        incentiveBasePolicyId: policyId,
      });
      dispatch(setPolicyDetailsState(response));
      setSnackbar('Add incentive proposed successfully !!!');
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  const handleWriteOffPolicyRule = (
    key: keyof IncentiveWriteOffRulesRequest,
    value: any
  ) => {
    const writeOffPolicRuleObj = { ...writeOffruleState, [key]: value };
    dispatch(setWriteOffruleState(writeOffPolicRuleObj));
  };

  const onAddProposedIncentive = () => {
    const updatedPosWaiverObj = {
      ...initialPosWaiverRange,
      parameterMin:
        writeOffruleState.rules[writeOffruleState.rules?.length - 1]
          .posWaiverRange.parameterMax + 1,
    } as PosWaiverRange;
    const updatedProposedIncentive = {
      ...initailIncentiveProposed,
      posWaiverRange: updatedPosWaiverObj,
    } as IncentiveWriteOffRule;
    handleWriteOffPolicyRule('rules', [
      ...writeOffruleState.rules,
      updatedProposedIncentive,
    ]);
  };

  const onWriteOffRulesIncentive = (index: number) => {
    const spreadRules = [...writeOffruleState.rules];
    spreadRules.splice(index, 1);
    handleWriteOffPolicyRule('rules', spreadRules);
  };

  const getPosMinMaxValue = () => {
    if (writeOffruleState?.rules) {
      if (writeOffruleState?.rules?.length) {
        const range =
          writeOffruleState?.rules[writeOffruleState?.rules?.length - 1];
        return (
          isWriteOfEmptyOrUndefined(range?.posWaiverRange?.parameterMin) ||
          isWriteOfEmptyOrUndefined(range?.posWaiverRange?.parameterMax)
        );
      }
    }
  };
  const getDisabledButton = () => {
    if (writeOffruleState?.rules) {
      if (writeOffruleState?.rules?.length) {
        const range =
          writeOffruleState?.rules[writeOffruleState?.rules?.length - 1];
        const lanPayOut =
          range?.lanPayoutRange[range.lanPayoutRange?.length - 1];
        if (
          lanPayOut.parameterDefinitionType === ParameterDefinitionType.FIXED
        ) {
          return (
            isWriteOfEmptyOrUndefined(lanPayOut.parameterExact) ||
            isWriteOfEmptyOrUndefined(lanPayOut.payoutAmountValue)
          );
        } else {
          return (
            isWriteOfEmptyOrUndefined(lanPayOut.parameterMin) ||
            isWriteOfEmptyOrUndefined(lanPayOut.parameterMax) ||
            isWriteOfEmptyOrUndefined(lanPayOut.payoutAmountValue)
          );
        }
      }
    }
  };
  const isParameterValue = () => {
    const isEmptyValue =
      writeOffruleState?.rules.length &&
      writeOffruleState?.rules.some((range: IncentiveWriteOffRule) => {
        return (
          isWriteOfEmptyOrUndefined(range?.posWaiverRange?.parameterMin) ||
          isWriteOfEmptyOrUndefined(range?.posWaiverRange?.parameterMax)
        );
      });
    return isEmptyValue;
  };

  const isLanPayoutRangeValue = () => {
    const isEmptyValue =
      writeOffruleState?.rules.length &&
      writeOffruleState?.rules?.every((range: IncentiveWriteOffRule) => {
        return range.lanPayoutRange.some((lanPayoutRange: LanPayoutRange) => {
          if (
            lanPayoutRange?.parameterDefinitionType ===
            ParameterDefinitionType.FIXED
          ) {
            return (
              isWriteOfEmptyOrUndefined(lanPayoutRange.payoutAmountValue) ||
              isWriteOfEmptyOrUndefined(lanPayoutRange.parameterExact)
            );
          } else {
            return (
              isWriteOfEmptyOrUndefined(lanPayoutRange.payoutAmountValue) ||
              isWriteOfEmptyOrUndefined(lanPayoutRange.parameterMax) ||
              isWriteOfEmptyOrUndefined(lanPayoutRange.parameterMin)
            );
          }
        });
      });
    return isEmptyValue;
  };

  const disabled = isParameterValue() || isLanPayoutRangeValue();
  const disabledForAdd = getPosMinMaxValue() || getDisabledButton();
  return (
    <Stack
      gap={2}
      sx={{
        width: '100%',
        p: '1.5rem',
      }}
    >
      {writeOffruleState?.rules &&
        writeOffruleState.rules.map(
          (rules: IncentiveWriteOffRule, index: number) => (
            <RulesIncentiveProposed
              key={index}
              index={index}
              rules={rules}
              onDelete={onWriteOffRulesIncentive}
            />
          )
        )}
      <Box>
        <VegaButton
          variant="text"
          text={`Add ${writeOffruleState?.rules?.length + 1}${getOrdinalNumber(
            writeOffruleState?.rules?.length + 1
          )} parameter`}
          startIcon={<PlusCircleIcon />}
          onClick={onAddProposedIncentive}
          disabled={disabledForAdd}
        />
      </Box>
      <Box textAlign={'end'}>
        <VegaButton
          text={`Save Proposed On Recovery`}
          onClick={onSubmitProposedIncentive}
          disabled={disabled}
          loading={loading}
        />
      </Box>
    </Stack>
  );
};

export default IncentiveProposedComponent;
