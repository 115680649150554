import { Box, Skeleton } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { fetchHolidayTypeById } from '../../Apis/holiday';
import { useHolidayType } from '../../providers/HolidayTypeProvider';
import LmDateField from '../common/LmDateField';
import LmTextField from '../common/LmTextField';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

const AddHolidayType = () => {
  const {
    holidayTypeState,
    handleAddHolidayTypeChange,
    selectedHolidayType,
    setHolidayTypeState,
  } = useHolidayType();
  const [loading, setLoading] = useState<boolean>(false);

  const getHolidayTypeById = async (id: string) => {
    try {
      const response = await fetchHolidayTypeById(id);
      setHolidayTypeState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHolidayType) {
      setLoading(true);
      getHolidayTypeById(selectedHolidayType.id);
    }
  }, [selectedHolidayType]);
  return (
    <>
      <Box mb={2}>
        <VegaFormInputField label="name" isMandatory>
          {loading ? (
            <Skeleton />
          ) : (
            <LmTextField
              loading={loading}
              placeholder="Enter Holiday Type Name"
              value={holidayTypeState?.name}
              onChange={e => handleAddHolidayTypeChange('name', e.target.value)}
              disabled={selectedHolidayType?.id.length > 0}
            />
          )}
        </VegaFormInputField>
      </Box>
      <Box mb={2}>
        <VegaFormInputField label="Description">
          {loading ? (
            <Skeleton />
          ) : (
            <LmTextField
              loading={loading}
              placeholder="Enter Holiday Type Description"
              value={holidayTypeState?.description}
              onChange={e =>
                handleAddHolidayTypeChange('description', e.target.value)
              }
            />
          )}
        </VegaFormInputField>
      </Box>
      <Box mb={2}>
        <VegaFormInputField label="Receipt Issuance">
          {loading ? (
            <Skeleton />
          ) : (
            <VegaSelect
              loading={loading}
              options={filterOption()}
              placeholder="Select Access Level "
              value={holidayTypeState?.receiptIssuance}
              onChange={selected =>
                handleAddHolidayTypeChange(
                  'receiptIssuance',
                  selected.target.value
                )
              }
            />
          )}
        </VegaFormInputField>
      </Box>
      {holidayTypeState?.receiptIssuance === 'NON_RESTRICTED' && (
        <Box mb={2}>
          <LmDateField
            loading={loading}
            header="value date"
            value={holidayTypeState.receiptIssuanceDate}
            onChange={e => {
              handleAddHolidayTypeChange('receiptIssuanceDate', e);
            }}
          />
        </Box>
      )}
      <Box mb={2}>
        <VegaFormInputField label="Receipt Deposition">
          {loading ? (
            <Skeleton />
          ) : (
            <VegaSelect
              loading={loading}
              options={filterOption()}
              placeholder="Select Access Level "
              value={holidayTypeState?.receiptDeposition}
              onChange={selected =>
                handleAddHolidayTypeChange(
                  'receiptDeposition',
                  selected.target.value
                )
              }
            />
          )}
        </VegaFormInputField>
      </Box>

      <Box mb={2}>
        <VegaFormInputField label="Receipt Authorization">
          {loading ? (
            <Skeleton />
          ) : (
            <VegaSelect
              loading={loading}
              options={filterOption()}
              placeholder="Select Access Level "
              value={holidayTypeState?.receiptAuthorization}
              onChange={selected =>
                handleAddHolidayTypeChange(
                  'receiptAuthorization',
                  selected.target.value
                )
              }
            />
          )}
        </VegaFormInputField>
      </Box>
    </>
  );
};

export default AddHolidayType;

const filterOption = () =>
  ['RESTRICTED', 'NON_RESTRICTED'].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
