import { Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import ListOfBucketPerformance from '../../components/Perfomance/ListOfBucketPerformance';
import ListOfPaymentPerformance from '../../components/Perfomance/ListOfPaymentPerformance';
import RiskBasePerformanceFilterDrawer from '../../components/Perfomance/modules/Drawer/RiskBasePerformanceFilterDrawer';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import {
  getPaymentBucketRiskbase,
  getPaymentRiskbase,
  getPerformanceDashboard,
  setFilterRiskBasePerformance,
} from '../../features/performanceDashboardSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../store';

const RiskBasePerformance = () => {
  const {
    filterRiskBasePerformance,
    payments,
    paymentLoading,
    bucketLoading,
    buckets,
  } = useAppSelector(getPerformanceDashboard);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const onClose = () => {
    closeFilterDrawer();
    dispatch(setFilterRiskBasePerformance({}));
  };

  const listOfPaymentPerformance = useMemo(() => {
    return (
      <ListOfPaymentPerformance data={payments} loading={paymentLoading} />
    );
  }, [isFetching]);

  const listOfBucketPerformance = useMemo(() => {
    var updatedBuckets = buckets.filter(i => i.id == 'X');
    return (
      <ListOfBucketPerformance data={updatedBuckets} loading={bucketLoading} />
    );
  }, [isFetching]);

  const fetchData = async (clearAll = false) => {
    setIsFetching(true);
    try {
      if (clearAll) {
        await Promise.all([
          dispatch(getPaymentRiskbase({})),
          dispatch(getPaymentBucketRiskbase({})),
        ]);
      } else {
        await Promise.all([
          dispatch(getPaymentRiskbase(filterRiskBasePerformance)),
          dispatch(getPaymentBucketRiskbase(filterRiskBasePerformance)),
        ]);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const onClear = () => {
    setIsFetching(true);
    onClose();
    fetchData(true);
  };

  const onApply = () => {
    setIsFetching(true);
    fetchData();
    closeFilterDrawer();
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderRightView={() => (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <LmFilterSection
              hideSearch
              onFilter={() => {
                openFilterDrawer();
              }}
            />
          </Stack>
        )}
      />
      {listOfPaymentPerformance}
      {listOfBucketPerformance}
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={onClose}
      >
        <RiskBasePerformanceFilterDrawer onClear={onClear} onApply={onApply} />
      </VegaDrawer>
    </>
  );
};

export default RiskBasePerformance;
