import { CircularProgress, Grid, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { AllocationService } from '../../../../Apis/AllocationService';
import { downloadBulkErrorFile } from '../../../../actions/AllocationActions';
import AllocationFileUploadCard, {
  ViewState,
} from '../../../../components/Allocation/AllocationFileUploadCard';
import { FileUploadIcon } from '../../../../components/Icons/Icons';
import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaPageContent from '../../../../components/common/VegaPageContent';
import VegaText from '../../../../components/common/VegaText';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppDispatch } from '../../../../store';
import {
  BulkAllocationMeta,
  LongRunningTaskStatus,
} from '../../../../types/allocations';
import { StorageKeys, StorageUtility } from '../../../../utils/StorageUtility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { CustomTargetColumn } from '../../columns/CustomTargetColumn';

const getDailyCustomRequestId = StorageUtility.getItem<string>(
  StorageKeys.DAILY_CUSTOM_TARGET_REQUEST_ID
)
  ? StorageUtility.getItem<string>(StorageKeys.DAILY_CUSTOM_TARGET_REQUEST_ID)
  : '';

const DailyCustomTarget = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<VegaFileSelectOption>();
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyTargetData, setDailyTargetData] = useState<BulkAllocationMeta[]>(
    []
  );

  const [dailyCustomTargetRequestId, setDailyCustomTargetRequestId] =
    useState<string>(getDailyCustomRequestId);

  const getDailyCustomTargetStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsUpload(false);
        StorageUtility.removeItem(StorageKeys.DAILY_CUSTOM_TARGET_REQUEST_ID);
        setSnackbar('Successfully Uploaded');
        setDailyCustomTargetRequestId('');
        fetchListOfDailyTarget();
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsUpload(false);
        StorageUtility.removeItem(StorageKeys.DAILY_CUSTOM_TARGET_REQUEST_ID);
        setSnackbar(response.result, 'error');
        setDailyCustomTargetRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const uploadFile = async () => {
    setIsUpload(true);

    try {
      const response = await AllocationService.uploadDailyCustomTarget({
        file: selectedFile.value,
      });
      if (response.status === 'COMPLETED') {
        setIsUpload(false);
        StorageUtility.removeItem(StorageKeys.DAILY_CUSTOM_TARGET_REQUEST_ID);
        setDailyCustomTargetRequestId('');
        setSnackbar('Successfully Uploaded');
        fetchListOfDailyTarget();
      } else {
        StorageUtility.setItem(
          StorageKeys.DAILY_CUSTOM_TARGET_REQUEST_ID,
          response.id
        );
        setDailyCustomTargetRequestId(response.id);

        getDailyCustomTargetStatus(response.id);
      }
      setSelectedFile(null);
      setSnackbar('Successfully Uploaded');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    if (fileInputRef.current != null && selectedFile == undefined) {
      fileInputRef.current.value = '';
    }
  }, [selectedFile]);

  useEffect(() => {
    if (dailyCustomTargetRequestId.length) {
      const uploadingFile = setInterval(() => {
        getDailyCustomTargetStatus(dailyCustomTargetRequestId);
      }, 7000);
      return () => clearInterval(uploadingFile);
    }
  }, [dailyCustomTargetRequestId]);

  const download = async (allocation: BulkAllocationMeta) => {
    try {
      const requestId = allocation.id;
      const url = await dispatch(
        downloadBulkErrorFile({ requestId: requestId })
      ).unwrap();
      window.open(url);
      setSnackbar(' Bulk Error File Downloaded successfully');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const fetchListOfDailyTarget = async () => {
    setLoading(true);
    try {
      const response = await AllocationService.fetchDailyCustomList({
        page,
        size,
      });
      setDailyTargetData(response.records);
      setTotalItems(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListOfDailyTarget();
  }, [page, size]);
  return (
    <>
      <VegaPageContent sx={{ marginTop: '1.5rem' }}>
        <div
          style={{
            borderRadius: '0.5rem',
            border: '1px solid #E1E4EB ',
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            padding: '2rem',
            alignItems: 'start',
            position: 'relative',
          }}
        >
          <Grid container columnGap={'1rem'}>
            <Grid item xs={3}>
              <VegaFormInputField label="Upload File">
                {isUpload ? (
                  <Stack
                    direction="row"
                    sx={{ alignItems: 'center', padding: 2 }}
                    gap={1}
                  >
                    <CircularProgress size="15px" />
                    <VegaText text={'Upload target is in progress'} />
                  </Stack>
                ) : (
                  <VegaFileSelect
                    placeholder={'Upload File'}
                    endIcon={<></>}
                    startIcon={<FileUploadIcon />}
                    acceptedExtension={[AcceptedExtension.XLSX]}
                    onFileSelect={file => {
                      setSelectedFile(file);
                    }}
                    onFileInputRef={ref => {
                      fileInputRef.current = ref;
                    }}
                  />
                )}
              </VegaFormInputField>
            </Grid>
          </Grid>
          {!!selectedFile && (
            <Grid container>
              <Grid item xs={12} style={{ flex: 1, marginTop: '0.8rem' }}>
                <AllocationFileUploadCard
                  fileName={selectedFile?.label}
                  state={isUpload ? ViewState.Uploading : ViewState.Inital}
                  onUploadClick={function (): void {
                    uploadFile();
                  }}
                  onCancelClick={function (): void {
                    setSelectedFile(undefined);
                    fileInputRef.current.value = '';
                  }}
                />
              </Grid>
            </Grid>
          )}
          {}
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            <a
              href={require('../../../../assets/files/pos_Template.xlsx')}
              download
              className="font-aspekta-500"
              style={{
                textDecoration: 'none',
                fontSize: 12,
                color: '#1047DC',
              }}
            >
              Download Templete
            </a>
          </div>
        </div>
      </VegaPageContent>
      <VegaPageContent marginTop={'1.5rem'}>
        <VegaDataGrid
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
          data={dailyTargetData}
          columns={CustomTargetColumn({ download })}
          loading={loading}
          idColumn="id"
        />
      </VegaPageContent>
    </>
  );
};

export default DailyCustomTarget;
