import { Grid, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { AnaltyticsService } from '../../Apis/AnaltyticsService';
import {
  CLProductType,
  DueDateTypes,
  HLProductType,
  VerticalTypes,
} from '../../components/Perfomance/PerformanceFilterSection';
import VegaAutoComplete from '../../components/common/VegaAutoComplete';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaSelectOption } from '../../components/common/VegaSelect';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../constants/style';
import { AnalyticsQueryTypeEnum } from '../../features/analyticsDashboardSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { BucketOption } from '../../types/minVisitsType';
import { AnalyticsQueryRequest } from '../../types/request/analyticsRequest';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import AllBranchSelector from '../MinVisits/modules/AllBranchSelector';
import RegionSelector from '../MinVisits/modules/RegionSelector';
import { ModelParserPreditionOptions } from '../PortFolio/PortFolio';
import DigitalCollectionColumn from './DigitalCollectionColumn';
import UniqueLoanAccountsDialog from './UniqueLoanAccountsDialog';

type FilterType = {
  startDate: string;
  endDate: string;
  segment: string;
  bucket: string;
  product: string;
  branchCode: string;
  cycleDate: string;
  modelParserAllocationType: string;
  region: string;
  actionType: string;
};

enum ProductType {
  HOME_LOAN = 'Home Loan',
  SME_TL = 'SME-TL',
  BUSINESS_LOANS = 'Business Loans',
  PRE_APPROVED_LOANS = 'PreApprovedLoans',
  HOME_EQUITY = 'Home Equity',
  MSME_TL = 'MSME-TL',
  PRE_APPROVED_LOANS_2 = 'Preapproved Loans',
  HOME_EQUITY_2 = 'Home EQuity',
}

enum ActionTypes {
  SMS = 'SMS_SENT',
  WHATSAPP = 'WHATSAPP_SENT',
  IVR = 'IVR_SENT',
}

export interface DigitalCollectionResponse {
  id: string;
  segment: string;
  channel: string;
  product: string;
  actionStatus: string;
  uniqueAccounts: number;
  count: number;
  loanIds: string[];
}

enum ActionType {
  TELECALLING_REFERRALS = 'TELECALLING_REFERRALS',
  IVR = 'IVR',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

function DigitalCollection() {
  const { setSnackbar } = useSnackbar();
  const [
    digitalCommunicationQueryResponse,
    setDigitalCommunicationQueryResponse,
  ] = useState<DigitalCollectionResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<Partial<FilterType>>({
    startDate: TODAY.toISOString(),
    endDate: TOMORROW.toISOString(),
  });
  const {
    open: openViewDetailDialog,
    close: closeViewDetailDialog,
    isOpen: isViewDetailDialogOpen,
    props: viewDetailsDialogProps,
  } = useDrawer();

  const handleParamsChange = (key: keyof FilterType, value: string) => {
    setParams({
      ...params,
      [key]: value,
    });
  };

  function handleSegmentChange(segmentValue: string, productValue: string) {
    const updateFilterData: Partial<FilterType> = {
      ...params,
      segment: segmentValue,
      product: productValue,
    };
    setParams(updateFilterData);
  }
  const handleDateChange = (key: keyof FilterType, date: any) => {
    const dateToString = DateUtility.parseDateFromDatePicker(date);

    if (key === 'startDate') {
      let endDate = DateUtility.parseStringToDate(params?.endDate);
      if (endDate && dateToString) {
        if (DateUtility.isBefore(endDate, dateToString)) {
          endDate = DateUtility.addDays(dateToString, 1);
        }
      }
      setParams({
        ...params,
        startDate: dateToString?.toISOString(),
        endDate: endDate?.toISOString(),
      });
    } else {
      setParams({
        ...params,
        [key]: dateToString?.toISOString(),
      });
    }
  };

  const getProductTypeOptions = () => {
    if (params.segment === VerticalTypes.CGHFL) {
      const options = getOptions(getProductValues(HLProductType));
      return options;
    } else {
      const options = getOptions(getProductValues(CLProductType));
      return options;
    }
  };

  function clearFilter() {
    setParams({
      startDate: TODAY.toISOString(),
      endDate: TOMORROW.toISOString(),
    });
    getDigitalCollection({
      startDate: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      endDate: DateUtility.formatStringToYYYYMMDD(TOMORROW.toISOString()),
    });
  }

  const filteredParams: Partial<FilterType> = useMemo(() => {
    const preParams = { ...params };
    const filteredParams = {};

    Object.keys(preParams).forEach(key => {
      if (preParams && preParams[key]?.length) {
        filteredParams[key] = preParams[key];
      }
    });

    return filteredParams;
  }, [params]);

  const onApplyFilterClick = async () => {
    const paramsRequest = {
      ...filteredParams,
      ...(params?.startDate && {
        startDate: DateUtility.formatStringToYYYYMMDD(params.startDate),
      }),
      ...(params?.endDate && {
        endDate: DateUtility.formatStringToYYYYMMDD(params.endDate),
      }),
    } as FilterType;
    await getDigitalCollection(paramsRequest);
  };

  const getDigitalCollection = async (params: Partial<FilterType>) => {
    setLoading(true);
    try {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.DIGITAL_COLLECTIONS,
        params,
      } as AnalyticsQueryRequest;
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      const formattedResponse: DigitalCollectionResponse[] = response.map(
        value => ({
          ...value,
          id: Math.random().toString(),
        })
      );
      setDigitalCommunicationQueryResponse(formattedResponse);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDigitalCollection({
      startDate: DateUtility.formatStringToYYYYMMDD(TODAY.toISOString()),
      endDate: DateUtility.formatStringToYYYYMMDD(TOMORROW.toISOString()),
    });
  }, []);
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Digital Collections'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '1rem', bgcolor: '' }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{
                border: BORDER_COLLECTION,
                borderRadius: '12px',
                p: 3,
                justifyContent: 'space-between',
              }}
              direction={'row'}
            >
              <Grid container direction={'row'} gap={1} rowGap={2}>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'From'}>
                    <VegaDatePicker
                      value={params?.startDate}
                      onChange={date => handleDateChange('startDate', date)}
                      maxDate={DateUtility.previousDays(
                        new Date(params?.endDate),
                        1
                      )}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'To'}>
                    <VegaDatePicker
                      value={params?.endDate}
                      onChange={date => handleDateChange('endDate', date)}
                      minDate={DateUtility.addDays(
                        new Date(params?.startDate),
                        1
                      )}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Segment'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getSegmentOptions(VerticalTypes)}
                      onClear={() => handleSegmentChange('', '')}
                      onSelect={selected => handleSegmentChange(selected, '')}
                      selected={params?.segment ?? ''}
                      placeHolder="Select Segment"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Action Type'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getActionTypeOptions()}
                      onClear={() => handleParamsChange('actionType', '')}
                      onSelect={selected =>
                        handleParamsChange('actionType', selected)
                      }
                      selected={toLowerCase(params?.actionType) ?? ''}
                      placeHolder="Select Action Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Product'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getProductTypeOptions()}
                      onClear={() => handleParamsChange('product', '')}
                      onSelect={selected =>
                        handleParamsChange('product', selected)
                      }
                      selected={params?.product ?? ''}
                      placeHolder="Select Product"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Branch'}>
                    <AllBranchSelector
                      selected={params?.branchCode ?? ('' as string)}
                      handleChange={selected =>
                        handleParamsChange('branchCode', selected)
                      }
                      onClear={() => handleParamsChange('branchCode', '')}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Bucket'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getBucketOptions()}
                      onClear={() => handleParamsChange('bucket', '')}
                      onSelect={selected =>
                        handleParamsChange('bucket', selected)
                      }
                      selected={params?.bucket ?? ''}
                      placeHolder="Select Bucket"
                      ignoreLodash
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Cycle'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(getDueDateValues())}
                      onClear={() => handleParamsChange('cycleDate', '')}
                      onSelect={selected =>
                        handleParamsChange('cycleDate', selected)
                      }
                      selected={params.cycleDate ?? ''}
                      placeHolder="Select Cycle"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Model Parser Allocation Type'}>
                    <VegaAutoComplete
                      isHideSearch
                      options={getOptions(
                        Object.values(ModelParserPreditionOptions)
                      )}
                      onClear={() =>
                        handleParamsChange('modelParserAllocationType', '')
                      }
                      onSelect={selected =>
                        handleParamsChange(
                          'modelParserAllocationType',
                          selected
                        )
                      }
                      selected={params.modelParserAllocationType ?? ''}
                      placeHolder="Select Model Parser Type"
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <VegaFormInputField label={'Region'}>
                    <RegionSelector
                      selected={params?.region ?? ''}
                      handleChange={selected =>
                        handleParamsChange('region', selected)
                      }
                      onClear={() => handleParamsChange('region', '')}
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
              <Grid
                container
                direction={'row'}
                gap={1}
                sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
              >
                <VegaButton
                  text="Clear Filter"
                  onClick={() => clearFilter()}
                  variant="outlined"
                  sx={{
                    ':hover': {
                      bgcolor: 'transparent',
                      color: '#1047DC',
                    },
                  }}
                />
                <VegaButton text="Apply Filter" onClick={onApplyFilterClick} />
              </Grid>
            </Grid>
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          idColumn="id"
          columns={DigitalCollectionColumn({
            onViewDetailClick: loanId => {
              openViewDetailDialog({ loanId });
            },
          })}
          data={digitalCommunicationQueryResponse}
          loading={loading}
        />
      </VegaPageContent>
      <UniqueLoanAccountsDialog
        closeUniqueLoanAccountDialog={closeViewDetailDialog}
        isUniqueLoanAccountDialogOpen={isViewDetailDialogOpen}
        loanIds={viewDetailsDialogProps.loanId}
      />
    </>
  );
}

export default DigitalCollection;
const getSegmentOptions = (type: typeof VerticalTypes) => {
  const options = Object.keys(type).map(
    item => ({ value: item, label: toLowerCase(item) } as VegaSelectOption)
  );
  return options;
};

const getBucketOptions = () => {
  return Object.entries(BucketOption).map(([value, label]) => ({
    value,
    label,
  }));
};
const getActionTypeOptions = () => {
  return Object.entries(ActionType).map(([value, label]) => ({
    value,
    label,
  }));
};

const getProductValues = (
  enumType: typeof HLProductType | typeof CLProductType | typeof ProductType
) =>
  Object.values(enumType).map(item => {
    return item;
  });
const getOptions = (options: string[]) => {
  const modifyOptions = options.map(
    (option: string) =>
      ({
        label: toLowerCase(option),
        value: option,
      } as VegaSelectOption)
  );
  return modifyOptions;
};
const getDueDateValues = () =>
  Object.values(DueDateTypes).map(item => {
    return item;
  });
