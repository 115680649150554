import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../../Apis/AnaltyticsService';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  AllEnums,
  AllTimePeriodEnums,
  AnalyticsQueryTypeEnum,
  BucketEnumForYearAnalytics,
  CelenderTypeEmun,
  CollectionWiseType,
  OrderByEnum,
  OverallMetricsEnum2,
  ResolutionEnum,
  RoleBasedPerformanceGraphSegmentOptionType,
  TimePeriodEnumForPenal,
  TodayTimePeriodEnum,
  Today_TimePeriodEnum,
  Year_TimePeriodEnum,
} from '../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { AnalyticsQueryRequest } from '../../../types/request/analyticsRequest';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import GraphWrapper from '../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { GraphTypeEnum } from '../AnalyticsDashboard';
import RoleBaseForChannelPerformanceColumn from './Columns/RoleBaseForChannelPerformanceColumn';
import RoleBasePerformanceColumn from './Columns/RoleBasePerformanceColumn';

interface IProps {
  calenderType: string;
  selectedCollectionProfitType: string;
}

const RoleBaseSegmentPerformance = ({
  selectedCollectionProfitType,
  calenderType,
}: IProps) => {
  const { viewType } = useParams();
  const [selectedCollectionWiseType, setSelectedCollectionWiseType] =
    useState<string>(CollectionWiseType.RCM);
  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    BucketEnumForYearAnalytics.OVERALL
  );
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY
      ? TimePeriodEnumForPenal.ONE_DAY
      : calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );
  const [selectedLoanStatus, setSelectedLoanStatus] = useState<string>(
    OverallMetricsEnum2.NONE
  );
  const [orderBy, setOrderBy] = useState<string>(OrderByEnum.OVERALL);
  const [selectedResolution, setSelectedResolution] = useState<string>(
    ResolutionEnum.OVERALL
  );
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<any[]>(
    []
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);

      setAnalyticsQueryResponse(response);
      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoader(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);

    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date()?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.FIVE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(FIVE_DAY?.toISOString()),
          toDate: DateUtility.formatStringToYYYYMMDD(FIRST_DAY?.toISOString()),
        };
      case TimePeriodEnumForPenal.MTD:
        return {
          isMonthToDate: 'true',
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date(FIRST_DAY)?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.YTD:
        return {
          isYearToDate: 'true',
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date(FIRST_DAY)?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4: {
        const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
        };
      }
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER: {
        const monthIndex = getMonthIndex(selectedTimePeriod);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
        };
      }
      default:
        return null;
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    return {
      ...timePeriodParams,
      role: selectedCollectionWiseType,
      ...(selectedLoanStatus !== OverallMetricsEnum2.NONE && {
        loanStatus: selectedLoanStatus,
      }),
      ...(selectedMetrics !== BucketEnumForYearAnalytics.OVERALL && {
        bucket: selectedMetrics,
      }),
      ...(selectedResolution !== ResolutionEnum.OVERALL && {
        resolution: selectedResolution,
      }),
      ...(orderBy !== OrderByEnum.OVERALL && {
        orderBy,
      }),
    };
  };

  const getRequest = () => {
    if (viewType && viewType === GraphTypeEnum.COLLECTION_VOLUME) {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.VOLUME_SEGMENT_PERFORMANCE,
        params: {
          type: selectedCollectionProfitType,
          role: selectedCollectionWiseType,
        },
      } as AnalyticsQueryRequest;

      return request;
    } else {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.SEGMENT_PERFORMANCE,
        params: { ...getParams(), type: selectedCollectionProfitType },
      } as AnalyticsQueryRequest;

      return request;
    }
  };

  const getQueries = () => {
    const request = getRequest();
    getQueriesForEnum(request);
  };

  const filterData = () => {
    if (search.length) {
      const searchLowerCase = search.toLowerCase();
      const filteredResults = analyticsQueryResponse.filter(query => {
        if (search === '') {
          return query;
        } else if (
          query?.managerName?.toLowerCase()?.includes(searchLowerCase)
        ) {
          return query;
        }
      });
      return filteredResults;
    } else {
      return analyticsQueryResponse;
    }
  };

  useEffect(() => {
    selectedCollectionWiseType.length && getQueries();
  }, [
    selectedMetrics,
    selectedTimePeriod,
    selectedCollectionWiseType,
    selectedCollectionProfitType,
    selectedLoanStatus,
    calenderType,
    orderBy,
    selectedResolution,
  ]);

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper title="Role Based Performance">
          {loader ? (
            <LoadingPage height={400} />
          ) : (
            <Stack gap={1}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <LmFilterSection
                    hideFilter
                    search={search}
                    setSearch={setSearch}
                  />
                </Grid>
                <Grid item>
                  <VegaSelect
                    autoWidth
                    ignoreLodash
                    options={getSegmentOptions()}
                    value={selectedCollectionWiseType}
                    onChange={selected =>
                      setSelectedCollectionWiseType(
                        selected.target.value as string
                      )
                    }
                  />
                </Grid>
                {viewType !== GraphTypeEnum.COLLECTION_VOLUME && (
                  <>
                    {calenderType !== CelenderTypeEmun.MONTH && (
                      <Grid item>
                        <VegaSelect
                          autoWidth
                          options={getTimePeriodOptions(
                            calenderType === CelenderTypeEmun.TODAY,
                            calenderType === CelenderTypeEmun.TODAY
                              ? Today_TimePeriodEnum
                              : Year_TimePeriodEnum
                          )}
                          value={selectedTimePeriod}
                          onChange={select =>
                            setSelectedTimePeriod(select.target.value as string)
                          }
                          ignoreLodash
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <VegaSelect
                        autoWidth
                        ignoreLodash
                        options={getViewOptions(OverallMetricsEnum2)}
                        value={selectedLoanStatus}
                        onChange={e =>
                          setSelectedLoanStatus(e.target.value as string)
                        }
                      />
                    </Grid>
                    <Grid item>
                      <VegaSelect
                        autoWidth
                        options={getOptions(ResolutionEnum)}
                        value={selectedResolution}
                        onChange={select =>
                          setSelectedResolution(select.target.value as string)
                        }
                      />
                    </Grid>
                    <Grid item>
                      <VegaSelect
                        options={getOptions(BucketEnumForYearAnalytics)}
                        value={selectedMetrics}
                        onChange={select =>
                          setSelectedMetrics(select.target.value as string)
                        }
                      />
                    </Grid>
                    <Grid item>
                      <VegaSelect
                        autoWidth
                        options={getOptions(OrderByEnum)}
                        value={orderBy}
                        onChange={select =>
                          setOrderBy(select.target.value as string)
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <VegaDataGrid
                // getRowId={index => index}
                data={analyticsQueryResponse ? filterData() : []}
                columns={
                  viewType === GraphTypeEnum.COLLECTION_VOLUME
                    ? RoleBaseForChannelPerformanceColumn()
                    : RoleBasePerformanceColumn({
                        calenderType,
                        selectedTimePeriod,
                        selectedMetrics,
                      })
                }
                idColumn="id"
                paginationMode="client"
                pageSize={5}
              />
            </Stack>
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default RoleBaseSegmentPerformance;

const getTimePeriodOptions = (isToday: boolean, type: AllTimePeriodEnums) => {
  if (isToday) {
    const options = Object.keys(type).map(item => {
      return { value: item, label: toLowerCase(item) } as VegaSelectOption;
    });
    return options;
  }
  const options = Object.keys(type).map(item => {
    const year = new Date().getFullYear();
    const label = `${toLowerCase(item)} (${year})`;
    return { value: item, label: label } as VegaSelectOption;
  });
  return options;
};

const getViewOptions = (type: AllEnums) => {
  const options = Object.keys(type).map(
    item =>
      ({
        value: type[item],
        label: toLowerCase(item),
      } as VegaSelectOption)
  );
  return options;
};
const getOptions = (type: AllEnums) => {
  const options = Object.values(type).map(
    item =>
      ({
        value: item,
        label: item,
      } as VegaSelectOption)
  );
  return options;
};

const getSegmentOptions = () => {
  const options = Object.entries(
    RoleBasedPerformanceGraphSegmentOptionType
  ).map(([key, value]) => ({
    value: value,
    label: toLowerCase(key),
  }));
  return options;
};

export const getMonthIndex = (
  timePeriod: TimePeriodEnumForPenal
): number | null => {
  switch (timePeriod) {
    case TimePeriodEnumForPenal.JANUARY:
      return 1;
    case TimePeriodEnumForPenal.FEBRUARY:
      return 2;
    case TimePeriodEnumForPenal.MARCH:
      return 3;
    case TimePeriodEnumForPenal.APRIL:
      return 4;
    case TimePeriodEnumForPenal.MAY:
      return 5;
    case TimePeriodEnumForPenal.JUNE:
      return 6;
    case TimePeriodEnumForPenal.JULY:
      return 7;
    case TimePeriodEnumForPenal.AUGUST:
      return 8;
    case TimePeriodEnumForPenal.SEPTEMBER:
      return 9;
    case TimePeriodEnumForPenal.OCTOBER:
      return 10;
    case TimePeriodEnumForPenal.NOVEMBER:
      return 11;
    case TimePeriodEnumForPenal.DECEMBER:
      return 12;
    default:
      return null;
  }
};
