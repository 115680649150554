import { CircularProgress, Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { OtsService } from '../../../Apis/OtsService';
import VegaAutoComplete from '../../../components/common/VegaAutoComplete';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { DeleteIcon } from '../../../components/Icons/Icons';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { Ots, OtsStatus } from '../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { COLOR } from '../../../utils/ColorUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (otsRequests: Ots[]) => void;
};

export type AddCasesToAgendaFormData = {
  otsRequests: Ots[];
};

function AddCasesToAgendaDrawer({ open, onClose, onSubmit }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [ots, setOts] = useState<Ots[]>([]);
  const [userSearchQuery, setUserSearchQuery] = useState<string>('');
  const [formData, setFormData] = useState<Partial<AddCasesToAgendaFormData>>(
    {}
  );

  function getOtsForId(userId: string) {
    const filtered = ots.filter(i => i.id == userId) ?? [];
    if (filtered.length > 0) {
      return filtered[0];
    }
  }

  async function fetchRequests() {
    try {
      setLoading(true);
      const response = await OtsService.listOtsRequests({
        status: [OtsStatus.PRE_AGENDA_CREATION].join(','),
      });
      setOts(response.records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function addRequest(id: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingOts = formData.otsRequests ?? [];
      const isAlreadyPresent = existingOts.some(i => i.id == id);
      const ots = getOtsForId(id);
      if (isAlreadyPresent == false && ots) {
        existingOts.push(ots);
      }
      updatedData.otsRequests = existingOts;
      return updatedData;
    });
  }

  function removeRequest(id: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingOts = formData.otsRequests ?? [];
      const isAlreadyPresent = existingOts.some(i => i.id == id);
      if (isAlreadyPresent) {
        const filtered = existingOts.filter(i => i.id != id);
        updatedData.otsRequests = filtered;
      }
      return updatedData;
    });
  }

  async function onSubmitClick() {
    onSubmit(formData.otsRequests);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
  }

  const filteredRequests = () => {
    if (userSearchQuery.length > 0) {
      const filteredUsers = ots.filter(i => {
        const query = userSearchQuery.toLowerCase();
        const fullName = `${i.loanId}`;
        return fullName.toLowerCase().includes(query);
      });
      return filteredUsers.map(i => {
        const option: VegaSelectOption = {
          value: i.id,
          label: `${i.loanId}`,
        };
        return option;
      });
    }

    return ots.map(i => {
      const option: VegaSelectOption = {
        value: i.id,
        label: `${i.loanId}`,
      };
      return option;
    });
  };
  const isInputValid = () => {
    return (formData.otsRequests ?? []).length > 0;
  };

  useEffect(() => {
    if (open) fetchRequests();
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Add members'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Update'}
              disabled={isInputValid() == false}
              onClick={onSubmitClick}
            />
          );
        }}
      >
        {loading == true && <CircularProgress />}
        {loading == false && (
          <Grid container rowGap={'1.5rem'}>
            <Grid item xs={12}>
              <VegaFormInputField label={'Add OTS Cases'}>
                <VegaAutoComplete
                  options={filteredRequests()}
                  handleChange={e => {
                    setUserSearchQuery(e.target.value);
                  }}
                  onSelect={selected => {
                    addRequest(selected);
                  }}
                  onClear={function (): void {
                    setUserSearchQuery('');
                  }}
                  selected={undefined}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'Cases'}>
                <Stack gap={1}>
                  {formData?.otsRequests?.map(i => {
                    return (
                      <div
                        key={i.id}
                        style={{
                          backgroundColor: COLOR.GREEN.LIGHT,
                          paddingLeft: '1rem',
                          paddingRight: '1rem',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <VegaText text={i.loanId} color={COLOR.GREEN.DARK} />
                        <IconButton onClick={() => removeRequest(i.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    );
                  })}
                </Stack>
              </VegaFormInputField>
            </Grid>
          </Grid>
        )}
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default AddCasesToAgendaDrawer;
