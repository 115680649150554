import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import { PRIMARY } from '../../../constants/style';
import { COLOR } from '../../../utils/ColorUtility';
import { ScreenOpenType } from '../UAM';

interface IAddProps {
  onDelete: (resources: VegaSelectOption, action: ScreenOpenType) => void;
  text: string;
}
export const addResource = ({ onDelete, text }: IAddProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'label',
      headerName: 'Name',
      flex: 1,
      minWidth: 250,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      minWidth: 50,
      renderCell: props => {
        // eslint-disable-next-line react/prop-types
        const resources = props.row as VegaSelectOption;
        return (
          <VegaIconButton
            sx={{ bgcolor: COLOR.RED.LIGHT }}
            tooltipTitle={`Remove ${text}`}
            icon={
              <RemoveCircleOutline
                sx={{
                  color: COLOR.RED.DARK,
                  fontSize: 18,
                }}
              />
            }
            onClick={() => onDelete(resources, ScreenOpenType.ADD)}
          />
        );
      },
    },
  ];

  return COL_DEF;
};
export const removeResource = ({ onDelete, text }: IAddProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'label',
      headerName: 'Name',
      flex: 1,
      minWidth: 250,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      minWidth: 50,
      renderCell: props => {
        // eslint-disable-next-line react/prop-types
        const resources = props.row as VegaSelectOption;
        return (
          <VegaIconButton
            sx={{ bgcolor: '#E7EDFC' }}
            tooltipTitle={`Add ${text}`}
            icon={
              <AddCircleOutline
                sx={{
                  color: PRIMARY.darkBlue,
                  fontSize: 18,
                }}
              />
            }
            onClick={() => onDelete(resources, ScreenOpenType.REMOVE)}
          />
        );
      },
    },
  ];

  return COL_DEF;
};
