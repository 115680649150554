import axios from 'axios';

import { ICreateHolidayProps, ICreateHolidayTypeProps } from '../types/holiday';
import {
  HolidayNameRequest,
  HolidayTypeRequest,
} from '../types/request/holidayRequest';

const HOLIDAY = {
  MASTER: '/master',
  HOLIDAY_TYPE: '/holiday/type',
  HOLIDAY_LIST: '/holiday',
};

const holidayType_endpoint = HOLIDAY.MASTER + HOLIDAY.HOLIDAY_TYPE;
const holiday_endpoint = HOLIDAY.MASTER + HOLIDAY.HOLIDAY_LIST;

/////////// Holiday type APIs \\\\\\\\\\

export const fetchHolidayTypeList = async (
  request: Partial<HolidayTypeRequest>
) => {
  const response = await axios.get(`${holidayType_endpoint}/list`, {
    params: request,
  });
  return response.data;
};

export const fetchHolidayTypeById = async (holidayTypeId: string) => {
  const response = await axios.get(`${holidayType_endpoint}/${holidayTypeId}`);
  return response.data;
};

export const createHolidayType = async (
  holidayTypeState: ICreateHolidayTypeProps
) => {
  const response = await axios.post(
    `${holidayType_endpoint}`,
    holidayTypeState
  );
  return response.data;
};

export const updateHolidayType = async (
  holidayTypeState: ICreateHolidayTypeProps
) => {
  const response = await axios.put(`${holidayType_endpoint}`, holidayTypeState);
  return response.data;
};

export const deleteHolidayType = async (holidayTypeId: string) => {
  const response = await axios.delete(
    `${holidayType_endpoint}/${holidayTypeId}`
  );
  return response.data;
};

/////////// Holiday APIs \\\\\\\\\\

export const fetchHolidayList = async (
  request: Partial<HolidayNameRequest>
) => {
  const response = await axios.get(`${holiday_endpoint}/list`, {
    params: request,
  });
  return response.data;
};

export const fetchHolidayById = async (holidayId: string) => {
  const response = await axios.get(`${holiday_endpoint}/${holidayId}`);
  return response.data;
};

export const createNewHoliday = async (holidayState: ICreateHolidayProps) => {
  const response = await axios.post(`${holiday_endpoint}`, holidayState);
  return response.data;
};

export const updateHoliday = async (holidayState: ICreateHolidayProps) => {
  const response = await axios.put(`${holiday_endpoint}`, holidayState);
  return response.data;
};

export const deleteHoliday = async (holidayId: string) => {
  const response = await axios.delete(`${holiday_endpoint}/${holidayId}`);
  return response.data;
};
