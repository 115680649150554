import { Stack } from '@mui/material';
import { useState } from 'react';
import { RED } from '../../constants/style';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import LmTextField from '../common/LmTextField';
import { ReferToFieldDTO } from '../../types/referToField';
import VegaButton from '../common/VegaButton';
import { COLOR } from '../../utils/ColorUtility';

interface RejectReferToFieldDialogProps {
  show: boolean;
  onClose: () => void;
  onReject: (fieldData: ReferToFieldDTO, reason: string) => void;
  fieldData?: ReferToFieldDTO;
}

const RejectReferToFieldDialog = ({
  show,
  fieldData,
  onClose,
  onReject,
}: RejectReferToFieldDialogProps) => {
  const [reason, setReason] = useState<string>('');

  const isInputValid = () => {
    return reason.length > 0;
  };

  function onSubmit() {
    if (!fieldData) return;
    onReject(fieldData, reason);
    handleClose();
  }

  function handleClose() {
    onClose();
    setReason('');
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={handleClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            variant="contained"
            fullWidth
            text="Confirm"
            onClick={onSubmit}
            disabled={isInputValid() === false}
            style={{
              backgroundColor: COLOR.RED.DARK,
              color: COLOR.WHITE[100],
            }}
          />
          <VegaButton
            text="Cancel"
            fullWidth
            variant="outlined"
            style={{
              backgroundColor: COLOR.WHITE[100],
              color: COLOR.BLACK.DARK,
              borderColor: COLOR.WHITE[50],
            }}
            onClick={handleClose}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#F8E8E8'}
        >
          <VegaText
            text="Confirmation"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={RED.red}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem">
        <VegaText
          text="Are you sure you want to reject the request?"
          style={{
            color: 'rgba(103, 107, 118, 1)',
            fontSize: '0.625rem',
            fontWeight: 700,
            lineHeight: '124%',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
          }}
        />

        <LmTextField
          placeholder="Reason"
          minRows={4}
          onChange={e => setReason(e.target.value)}
        />
      </Stack>
    </LmDialog>
  );
};

export default RejectReferToFieldDialog;
