import { Stack } from '@mui/material';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { getTeleCallingMetrics } from '../../features/teleCallingMetricsSlice';
import { useAppSelector } from '../../store';
import CallMetricsSummary from './modules/CallMetricsSummary';
import FilterTeleCallingMetricsSection from './modules/FilterTeleCallingMetricsSection';
import LoadingPage from '../LoadingPage/LoadingPage';

export enum Metrics {
  ASSURED_CALL = 'ASSURED_CALL',
  NOT_ASSURED_CALL = 'NOT_ASSURED_CALL',
  TALK_TIME_METRICS = 'TALK_TIME_METRICS',
  CALL_TIME_METRICS = 'CALL_TIME_METRICS',
  STATE_TIME_METRICS = 'STATE_TIME_METRICS',
}

const TeleCallingMetrics = () => {
  const { agentCallMetrics, loadingSummary } = useAppSelector(
    getTeleCallingMetrics
  );

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Tele-Calling Metrics'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
      />
      <FilterTeleCallingMetricsSection />

      {loadingSummary ? (
        <VegaPageContent mt={'1rem'}>
          <LoadingPage />
        </VegaPageContent>
      ) : (
        agentCallMetrics && (
          <>
            <VegaPageContent mt={'1rem'}>
              <Stack spacing={'2rem'}>
                <VegaText
                  text={'Summary'}
                  fontWeight={600}
                  fontSize={'1.0625rem'}
                  color={'black'}
                />
              </Stack>
            </VegaPageContent>
            <CallMetricsSummary
              agentCallMetricsDto={agentCallMetrics.AnsweredCalls}
              label="Answered Calls"
              loading={loadingSummary}
            />
            <CallMetricsSummary
              agentCallMetricsDto={agentCallMetrics.CallTimeMetrics}
              label="Call Time"
              loading={loadingSummary}
            />
            <CallMetricsSummary
              agentCallMetricsDto={agentCallMetrics.NoAnsweredCalls}
              label="NoAnswered Calls"
              loading={loadingSummary}
            />
            <CallMetricsSummary
              agentCallMetricsDto={agentCallMetrics.StateTimeMetrics}
              label="State Time"
              loading={loadingSummary}
            />
            <CallMetricsSummary
              agentCallMetricsDto={agentCallMetrics.TalkTimeMetrics}
              label="Talk Time"
              loading={loadingSummary}
            />
          </>
        )
      )}
    </>
  );
};

export default TeleCallingMetrics;
