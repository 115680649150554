/* eslint-disable no-empty */

import VegaSelect from '../../../components/common/VegaSelect';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';
import { BucketOption } from '../../../types/minVisitsType';

interface IProps {
  selected: any;
  handleChange: (value: string) => void;
  handleDelete?: (index: number) => void;
  label?: string;
  disabled?: boolean;
  isMultiSelect?: boolean;
}

const BucketSelector = ({
  selected,
  handleChange,
  handleDelete,
  isMultiSelect = false,
  label,
  disabled,
}: IProps) => {
  const onChange = (value: string) => {
    handleChange(value);
  };

  return (
    <>
      {!isMultiSelect ? (
        <VegaSelect
          ignoreLodash
          placeholder={label ?? 'Select Bucket'}
          options={getEnumValuesAndLabels()}
          onChange={e => onChange(e)}
          value={selected}
          disabled={disabled}
        />
      ) : (
        <VegaSelectWithCheckbox
          options={getEnumValuesAndLabels()}
          onSelect={selected => onChange(selected)}
          handleDelete={handleDelete}
          selected={selected}
          disabled={disabled}
          ignoreLodash
          showValueInsteadOfLabel
        />
      )}
    </>
  );
};

export default BucketSelector;
// const getBucketOption = () => {
//   const options = Object.keys(BucketOption).map(
//     (comparison: string, index: number) => {
//       const comparisonType = Object.values(BucketOption)[index];
//       return {
//         label: toLowerCase(comparison),
//         value: comparisonType,
//       } as VegaRadioOption;
//     }
//   );
//   return options;
// };
const getEnumValuesAndLabels = () => {
  return Object.entries(BucketOption).map(([value, label]) => ({
    value,
    label,
  }));
};
