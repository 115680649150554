import { AddTask, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { EscalationsDto } from '../../../types/escalationsType';
import { EscalationStatus } from '../../../types/request/escalationsRequest';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import VegaIconButton from '../../common/VegaIconButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';

interface IColumnProps {
  selectedTab: number;
  onAction?: (escalations: EscalationsDto, action: string) => void;
}

export const RouteDeviationColumn = ({
  selectedTab,
  onAction,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'userName',
      headerName: 'Agent Name',
      flex: 1,
      minWidth: 100,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const userName = escalations?.userName ? escalations?.userName : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'escalationDate',
      headerName: 'Escalation Date',
      flex: 0.7,
      minWidth: 120,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const escalationDate = escalations.triggeredDate
          ? DateUtility.formatStringToDDMMMMYYYY(escalations.triggeredDate)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalationDate}
          />
        );
      },
    },

    {
      field: 'escalationReason',
      headerName: 'Reason / Remark',
      flex: 1,
      minWidth: 250,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const escalationReason = escalations?.escalationReason
          ? escalations.escalationReason
          : '--';

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalationReason}
          />
        );
      },
    },

    {
      field: 'escalationLevel',
      headerName: 'Escalation Level',
      flex: 0.7,
      minWidth: 120,
      renderCell: props => {
        const todayEscalation = props.row as EscalationsDto;
        return (
          <VegaPill
            text={todayEscalation.escalationLevel}
            backgroundColor={
              getColorForLevel(todayEscalation.escalationLevel)?.LIGHT
            }
            textColor={getColorForLevel(todayEscalation.escalationLevel)?.DARK}
            borderColor={
              getColorForLevel(todayEscalation.escalationLevel)?.DARK
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      hide: selectedTab === 1,
      minWidth: 100,
      renderCell: props => {
        const todayEscalation = props.row as EscalationsDto;
        return selectedTab === 0 ? (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="Approve"
              sx={{ bgcolor: '#E7EDFC' }}
              icon={<AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />}
              onClick={() => {
                onAction(todayEscalation, EscalationStatus.RESOLVED);
              }}
            />
            <VegaIconButton
              tooltipTitle="Reject"
              onClick={() => {
                onAction(todayEscalation, EscalationStatus.REJECTED);
              }}
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
            />
          </Stack>
        ) : (
          // <VegaButton variant="text" text="View Details" />
          ''
        );
      },
    },
    {
      field: 'status',
      headerName: 'status',
      flex: 0.5,
      hide: selectedTab === 0,
      minWidth: 100,
      renderCell: props => {
        const todayEscalation = props.row as EscalationsDto;

        return (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaPill
              text={getColorForStatus(todayEscalation.escalationStatus)?.text}
              backgroundColor={
                getColorForStatus(todayEscalation.escalationStatus)?.color
                  ?.LIGHT
              }
              textColor={
                getColorForStatus(todayEscalation.escalationStatus)?.color?.DARK
              }
              borderColor={
                getColorForStatus(todayEscalation.escalationStatus)?.color?.DARK
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getColorForLevel = (status?: string) => {
  if (!status) return;
  switch (status) {
    case 'LEVEL_1':
      return COLOR.GREEN;

    case 'LEVEL_2':
      return COLOR.ORANGE;
    case 'LEVEL_3':
      return COLOR.RED;
  }
};
const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case EscalationStatus.RESOLVED:
      return {
        text: 'APPROVED',
        color: COLOR.GREEN,
      };
    case EscalationStatus.RAISED:
      return {
        text: 'ON-ACTION',
        color: COLOR.GREEN,
      };

    case EscalationStatus.ESCALATED:
      return {
        text: EscalationStatus.ESCALATED,
        color: COLOR.ORANGE,
      };
    case EscalationStatus.REJECTED:
      return {
        text: EscalationStatus.REJECTED,
        color: COLOR.RED,
      };
  }
};
