/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { LoanService } from '../../../../Apis/LoanServices';
import VegaAutoComplete from '../../../../components/common/VegaAutoComplete';
import { VegaSelectOption } from '../../../../components/common/VegaSelect';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { LoanData } from '../../../../types/loan';
import { LoanListRequest } from '../../../../types/request/loan';

interface IProps {
  selected: any;
  onSelectLan: (select: string) => void;
}

const LoanSelector = ({ onSelectLan, selected }: IProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [loanList, setLoanList] = useState<VegaSelectOption[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== loanList.length) {
        setPage(page + 1);
      }
    }
  };

  const getProgramList = async () => {
    try {
      var body = {
        searchPartialLoanId: searchValue,
        page,
        pageSize: 10,
        overrideDefaultFilters: true,
      } as LoanListRequest;

      const response = await LoanService.getLoanList(body);

      const loanSelectOptionList = await response.records.map(
        (loan: LoanData) => {
          const loanId = loan.loanId;
          return {
            value: loanId,
            label: loanId,
          };
        }
      );
      if (page === 0) {
        setLoanList(loanSelectOptionList);
      } else {
        setLoanList([...loanList, ...loanSelectOptionList]);
      }
      setTotalItem(response.totalItems);
    } catch (error) {}
  };

  useEffect(() => {
    const getList = setTimeout(() => {
      getProgramList();
    }, 200);
    return () => clearTimeout(getList);
  }, [page, searchValue]);
  return (
    <VegaAutoComplete
      options={loanList}
      searchValue={searchValue}
      handleChange={e => {
        setSearchValue(e.target.value);
        setPage(0);
      }}
      onClear={() => onSelectLan('')}
      onSelect={selected => onSelectLan(selected)}
      selected={selected}
      placeHolder="Select loan id"
      onScroll={loadMoreItems}
    />
  );
};

export default LoanSelector;
