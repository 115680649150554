import { Stack } from '@mui/material';
import LmDialog from '../../../components/common/LmDialog';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaText from '../../../components/common/VegaText';
import ViewUserDetails from '../components/ViewUserDetails';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ViewUserDetailsDialog = ({ open, onClose }: IProps) => {
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="sm"
        open={open}
        isCloseIcon
        handleClose={onClose}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="View User Details"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <ViewUserDetails />
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ViewUserDetailsDialog;
