import { FilterList, Search } from '@mui/icons-material';
import { Stack } from '@mui/material';

import VegaText from './VegaText';

interface IProps {
  onFilter?: () => void;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  search?: string;
  hideFilter?: boolean;
  hideSearch?: boolean;
  serachByComponent?: JSX.Element;
  searchPlaceHolder?: string;
}

const LmFilterSection = ({
  onFilter,
  search,
  setSearch,
  hideFilter = false,
  hideSearch = false,
  serachByComponent,
  searchPlaceHolder,
}: IProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'end'}
      gap={3}
    >
      {!hideFilter && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={1}
          sx={{ cursor: 'pointer' }}
          onClick={onFilter ? onFilter : () => {}}
        >
          <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
          <VegaText text="Filter" fontSize={'0.75rem'} color={'#676B76'} />
        </Stack>
      )}
      {!hideSearch && (
        <Stack direction={'row'} gap={1}>
          {serachByComponent && serachByComponent}
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
            sx={{
              border: '1px solid #EBEBEB',
              borderRadius: '6px',
              width: 320,
              p: '5px',
              bgcolor: 'white',
              height: '35px',
            }}
          >
            <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
            <form
              style={{
                height: '100%',
                flexGrow: 1,
              }}
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <input
                className="font-aspekta-500"
                style={{
                  fontFamily: `var(--font-Aspekta-500) !important`,
                  border: 'none',
                  width: '100%',
                  height: '100%',
                }}
                type="text"
                value={search}
                placeholder={searchPlaceHolder || 'Search'}
                onChange={e => setSearch?.(e.target.value)}
              />
            </form>
          </Stack>
          {/* <LmOutlineTextField
            fixWidth={320}
            placeholder="Search"
            name="search"
            startAdornment={
              <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
            }
            value={search ? search : ''}
            onChange={e => setSearch?.(e.target.value)}
          /> */}
        </Stack>
      )}
    </Stack>
  );
};

export default LmFilterSection;
