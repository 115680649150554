import { AddTask, Close, VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { VegaPill } from '../../../components/common/VegaPill';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import { TargetListProps, TargetsStatus } from '../../../types/targets';
import { COLOR } from '../../../utils/ColorUtility';

interface IColumnProps {
  selectedTab: number;
  viewDetails: (target: TargetListProps) => void;
  onOpenApproveDialog: (target: TargetListProps) => void;
  onOpenRejectDialog: (target: TargetListProps) => void;
}

export const TargetApproverColumn = ({
  selectedTab,
  viewDetails,
  onOpenApproveDialog,
  onOpenRejectDialog,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'agentId',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const target = props.row as TargetListProps;
        const agentName = target?.agentName
          ? toLowerCase(target.agentName)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={agentName}
          />
        );
      },
    },
    {
      field: 'approverId',
      headerName: 'Approve By',
      flex: 0.7,
      renderCell: props => {
        const target = props.row as TargetListProps;
        const approverName = target?.approverName
          ? toLowerCase(target.approverName)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={approverName}
          />
        );
      },
    },

    {
      field: 'targetStatus',
      headerName: 'Status',
      flex: 0.5,
      renderCell: props => {
        const target = props.row as TargetListProps;
        return (
          <VegaPill
            text={target.targetStatus}
            backgroundColor={getColorForStatus(target.targetStatus)?.LIGHT}
            textColor={getColorForStatus(target.targetStatus)?.DARK}
            borderColor={getColorForStatus(target.targetStatus)?.DARK}
          />
        );
      },
    },
    {
      field: 'targetRaisedDate',
      headerName: 'Target Raised Date',
      flex: 0.7,
      renderCell: props => {
        const target = props.row as TargetListProps;
        const raisedDate = target?.targetDate ?? '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={raisedDate}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'View Details',
      flex: 0.5,
      renderCell: props => {
        const target = props.row as TargetListProps;
        return selectedTab === 1 ? (
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => viewDetails(target)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        ) : (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => viewDetails(target)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
            <VegaIconButton
              tooltipTitle="Approve"
              sx={{ bgcolor: '#E7EDFC' }}
              icon={<AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />}
              onClick={() => onOpenApproveDialog(target)}
            />
            <VegaIconButton
              tooltipTitle="Reject"
              onClick={() => onOpenRejectDialog(target)}
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

export const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case TargetsStatus.APPROVED:
      return COLOR.GREEN;

    case TargetsStatus.RAISED:
      return COLOR.ORANGE;
    case TargetsStatus.REJECTED:
      return COLOR.RED;
  }
};
