import { Stack } from '@mui/material';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import { RejectLeaveDialogCancelIcon } from '../Icons/Icons';
import { ReferToFieldDTO } from '../../types/referToField';
import VegaButton from '../common/VegaButton';
import { COLOR } from '../../utils/ColorUtility';

interface AcceptReferToFieldDialogProps {
  show: boolean;
  onAcceptClick: (fieldData: ReferToFieldDTO) => void;
  onClose: () => void;
  fieldData?: ReferToFieldDTO;
}

const AcceptReferToFieldDialog = ({
  show,
  onAcceptClick,
  fieldData,
  onClose,
}: AcceptReferToFieldDialogProps) => {
  function _onAcceptClick() {
    if (!fieldData) return;
    onAcceptClick(fieldData);
    _onCloseClick();
  }

  function _onCloseClick() {
    onClose();
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={_onCloseClick}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            variant="outlined"
            fullWidth
            text="Cancel"
            onClick={() => {
              _onCloseClick();
            }}
            startIcon={<RejectLeaveDialogCancelIcon />}
            style={{
              backgroundColor: COLOR.WHITE[100],
              color: COLOR.BLACK.DARK,
              borderColor: COLOR.WHITE[50],
            }}
          />
          <VegaButton
            variant="contained"
            fullWidth
            text="Yes,Approve"
            onClick={() => {
              _onAcceptClick();
            }}
            style={{
              backgroundColor: COLOR.BLUE[300],
              color: COLOR.WHITE[100],
            }}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E7EDFC'}
        >
          <VegaText
            text="Confirmation"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'#1B1D22'}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" pt="36px">
        <VegaText
          text={`Are you sure you want to approve this request? `}
          style={{
            minWidth: '328px',
            color: '#1B1D22',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '120%',
            fontStyle: 'normal',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
            fontFamily: 'Aspekta',
          }}
        />
      </Stack>
    </LmDialog>
  );
};
export default AcceptReferToFieldDialog;
