import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { EscalationsServices } from '../../Apis/EscalationsServices';
import { toLowerCase } from '../../constants/commonFunction';
import {
  fetchEscalations,
  getEscalationsState,
} from '../../features/escalationsSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { EscalationStatusType } from '../../pages/Escalations/Escalations';
import { EscalationSubType } from '../../pages/Escalations/modules/Today';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { EscalationsDto } from '../../types/escalationsType';
import {
  EscalationStatus,
  GetEscalationRequest,
  UpadteEscalationRequest,
} from '../../types/request/escalationsRequest';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmFilterSection from '../common/LmFilterSection';
import VegaApproveDialog from '../common/VegaApproveDialog';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaRejectDialog from '../common/VegaRejectDialog';
import VegaTabBar from '../common/VegaTabBar';
import VegaTabBarItem from '../common/VegaTabItem';
import VegaText from '../common/VegaText';
import { TodayEscalationColumn } from './Columns/TodayEscalationColumn';
import { getUserInfo } from './EscalationCommonFunctions';
import EscalationHistoryDialog from './EscalationHistoryDialog';

interface IProps {
  selectedTab: number;
  selectedStatus: number;
  setSelectedStatus: React.Dispatch<React.SetStateAction<number>>;
}

// const dummy = [
//   {
//     managerId: 'Rahul Sharma',
//     lan: '34123451',
//     escalationDate: 'August 10, 2023',
//     bucket: '1-30',
//     pos: '1.3',
//     escalationLevel: 'LEVEL_1',
//   },
//   {
//     managerId: 'Rahul Sharma',
//     lan: '34123452',
//     escalationDate: 'August 10, 2023',
//     bucket: '1-30',
//     pos: '1.3',
//     escalationLevel: 'LEVEL_2',
//   },
//   {
//     managerId: 'Rahul Sharma',
//     lan: '34123453',
//     escalationDate: 'August 10, 2023',
//     bucket: '1-30',
//     pos: '1.3',
//     escalationLevel: 'LEVEL_3',
//   },
// ];

const ListOfTodayEscalations = ({
  selectedTab,
  selectedStatus,
  setSelectedStatus,
}: IProps) => {
  const { loading, error, escalations, totalItems } =
    useAppSelector(getEscalationsState);
  const dispatch = useAppDispatch();
  const { userId } = useClientAuth();

  const { setSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [search, setSearch] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [escalationData, setEscalationData] = useState<Partial<EscalationsDto>>(
    {}
  );

  const {
    open: onApproveOpen,
    close: onApproveClose,
    isOpen: isApproveOpen,
  } = useDrawer();
  const {
    open: onRejectOpen,
    close: onRejectClose,
    isOpen: isRejectOpen,
  } = useDrawer();
  const {
    open: openEscalationHistoryDialog,
    close: closeEscalationHistoryDialog,
    isOpen: isEscalationHistoryDialogOpen,
    props: escalationHistoryDialogProps,
  } = useDrawer();

  const onApproveDialogOpen = async (escalation: EscalationsDto) => {
    onApproveOpen();
    setEscalationData(escalation);
    const userInfo = await getUserInfo(escalation.userId);
    setUserName(userInfo);
  };
  const onRejectDialogOpen = (escalation: EscalationsDto) => {
    onRejectOpen();
    setEscalationData(escalation);
  };

  const onViewDetailsClick = (escalation: EscalationsDto) => {
    openEscalationHistoryDialog();
    setEscalationData(escalation);
  };

  const updateEscalation = (
    escalationStatus: EscalationStatus,
    rejectedReason: string,
    onClose: () => void
  ) => {
    const request = {
      id: escalationData.id,
      escalationStatus,
      ...(rejectedReason.length && { rejectedReason }),
    } as UpadteEscalationRequest;

    EscalationsServices.updateEscalation(request)
      .then(() => {
        setSnackbar(`${escalationStatus} successfully !!!`);
        onClose();
        getEscalationList();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onClose();
      });
  };

  const getEscalationList = () => {
    const request = {
      page,
      size,
      managerId: userId,
      escalationType: getKeyFromTab(selectedTab),
      ...(search.length && { escalationObjectId: search }),
      ...(selectedStatus === 0
        ? {
            escalationStatus: [EscalationStatus.RAISED],
          }
        : {
            escalationStatus: [
              EscalationStatus.RESOLVED,
              EscalationStatus.REJECTED,
              EscalationStatus.ESCALATED,
            ],
          }),
    } as GetEscalationRequest;
    dispatch(fetchEscalations(request));
  };

  useEffect(() => {
    if (search.length) {
      const getEscalation = setTimeout(getEscalationList, 500);
      return () => clearTimeout(getEscalation);
    } else {
      getEscalationList();
    }
  }, [page, size, selectedTab, search, selectedStatus]);

  useEffect(() => {
    if (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);
  return (
    <>
      <VegaPageHeader
        sx={{
          pb: 0,
          bgcolor: 'transparent',
          borderBottom: 'none',
        }}
        renderLeftView={() => {
          return (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <VegaText
                text={getTitleFromTab(selectedTab)}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedStatus}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedStatus(selected);
                }}
              >
                {Object.keys(EscalationStatusType).map((type: string) => (
                  <VegaTabBarItem key={type} label={toLowerCase(type)} />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
        renderRightView={() => {
          return <LmFilterSection setSearch={setSearch} hideFilter={true} />;
        }}
      />
      <VegaPageContent mt={2}>
        <VegaDataGrid
          idColumn="id"
          data={escalations}
          columns={TodayEscalationColumn({
            selectedTab: selectedStatus,
            onApproveDialogOpen,
            onRejectDialogOpen,
            onViewDetailsClick,
          })}
          loading={loading}
          paginationMode="server"
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
        />
      </VegaPageContent>
      <VegaApproveDialog
        header="Are you sure?"
        buttonText="Approve"
        open={isApproveOpen}
        onClose={onApproveClose}
        onApprove={() =>
          updateEscalation(EscalationStatus.RESOLVED, '', onApproveClose)
        }
      >
        <VegaText
          text={`You are approving the request of agent ${userName}`}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaRejectDialog
        text="Reject Request?"
        open={isRejectOpen}
        onClose={onRejectClose}
        onReject={(reason: string) =>
          updateEscalation(EscalationStatus.REJECTED, reason, onRejectClose)
        }
      />
      <EscalationHistoryDialog
        onClose={closeEscalationHistoryDialog}
        open={isEscalationHistoryDialogOpen}
        escalation={escalationData}
      />
    </>
  );
};

export default ListOfTodayEscalations;

const getTitleFromTab = (tab: number) => {
  const value = Object.values(EscalationSubType)[tab];
  return value;
};
const getKeyFromTab = (tab: number) => {
  const value = Object.keys(EscalationSubType)[tab];
  return value;
};
