import { Skeleton, Stack } from '@mui/material';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import { BORDER_COLLECTION } from '../../../constants/style';

interface IProps {
  loading: boolean;
  label: string;
  value: string | number;
}

const MetricsSummaryCards = ({ loading, label, value }: IProps) => {
  return (
    <div>
      <Stack
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          px: 2,
          py: 1.5,
          minHeight: '101px',
          bgcolor: 'white',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <VegaFormInputField label={label}>
          {loading ? (
            <Skeleton />
          ) : (
            <VegaText
              sx={{ mt: '0px !important' }}
              fontSize={'1.5rem'}
              fontWeight={600}
              text={value}
            />
          )}
        </VegaFormInputField>
      </Stack>
    </div>
  );
};

export default MetricsSummaryCards;
