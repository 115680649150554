import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OtsService } from '../../../Apis/OtsService';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaText from '../../../components/common/VegaText';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { VegaUser } from '../../../types/claim';
import {
  getOtsStatusDescription,
  getValueFromRecord,
  Ots,
  OtsMetaDataKey,
  OtsStatus,
} from '../../../types/ots';
import {
  GetOtsMisReportRequest,
  GetOtsRequests,
} from '../../../types/request/ots';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../../utils/api';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../constants/style';

enum Tabs {
  All,
  Pending,
  Approved,
  Rejected,
  Breached,
}

const PENDING_STATUS: OtsStatus[] = Object.values(OtsStatus).filter(i => {
  const isApproved = i == OtsStatus.OTS_APPROVED;
  const isRejected = i == OtsStatus.OTS_REJECTED;
  const isBreached = i == OtsStatus.OTS_BREACHED;
  return isApproved == false && isRejected == false && isBreached == false;
});

const INITIAL_DATA: PaginatedResponse<Ots> = {
  records: [],
  totalItems: 0,
  totalPages: 0,
  pageNumber: 0,
  numberOfItems: 0,
};
function OtsMisPage() {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>(Tabs.All);
  const [loading, setLoading] = useState<boolean>(false);
  const [allData, setAllData] = useState<PaginatedResponse<Ots>>(INITIAL_DATA);
  const [rejectedData, setRejectedData] =
    useState<PaginatedResponse<Ots>>(INITIAL_DATA);
  const [pendingData, setPendingData] =
    useState<PaginatedResponse<Ots>>(INITIAL_DATA);
  const [breachedData, setBreachedData] =
    useState<PaginatedResponse<Ots>>(INITIAL_DATA);
  const [approvedData, setApprovedData] =
    useState<PaginatedResponse<Ots>>(INITIAL_DATA);

  const buildRequest = () => {
    var request: Partial<GetOtsRequests> = {
      pageSize: 10,
      includeUserDetails: true,
      includeLoanDetails: true,
    };
    if (selectedTab == Tabs.All) {
      request.page = allData.pageNumber;
    } else if (selectedTab == Tabs.Approved) {
      request.page = approvedData.pageNumber;
      request.status = `${OtsStatus.OTS_APPROVED}`;
    } else if (selectedTab == Tabs.Pending) {
      request.page = pendingData.pageNumber;
      request.status = PENDING_STATUS.join(',');
    } else if (selectedTab == Tabs.Breached) {
      request.page = breachedData.pageNumber;
      request.status = `${OtsStatus.OTS_BREACHED}`;
    } else if (selectedTab == Tabs.Rejected) {
      request.page = rejectedData.pageNumber;
      request.status = `${OtsStatus.OTS_REJECTED}`;
    }
    return request;
  };

  async function fetchData() {
    try {
      setLoading(true);
      var request: Partial<GetOtsRequests> = buildRequest();
      const response = await OtsService.listOtsRequests(request);
      if (selectedTab == Tabs.All) {
        setAllData(response);
      } else if (selectedTab == Tabs.Approved) {
        setApprovedData(response);
      } else if (selectedTab == Tabs.Pending) {
        setPendingData(response);
      } else if (selectedTab == Tabs.Breached) {
        setBreachedData(response);
      } else if (selectedTab == Tabs.Rejected) {
        setRejectedData(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function getMisExportReport() {
    try {
      setLoading(true);
      const request: Partial<GetOtsMisReportRequest> = {};
      if (selectedTab == Tabs.Approved) {
        request.statuses = `${OtsStatus.OTS_APPROVED}`;
      } else if (selectedTab == Tabs.Pending) {
        request.statuses = PENDING_STATUS.join(',');
      } else if (selectedTab == Tabs.Breached) {
        request.statuses = `${OtsStatus.OTS_BREACHED}`;
      } else if (selectedTab == Tabs.Rejected) {
        request.statuses = `${OtsStatus.OTS_REJECTED}`;
      }
      const response = await OtsService.getMisFile(request);
      const url = response.url;
      if (url) window.open(response.url, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getData = () => {
    if (selectedTab == Tabs.All) {
      return allData;
    } else if (selectedTab == Tabs.Approved) {
      return approvedData;
    } else if (selectedTab == Tabs.Pending) {
      return pendingData;
    } else if (selectedTab == Tabs.Breached) {
      return breachedData;
    } else if (selectedTab == Tabs.Rejected) {
      return rejectedData;
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedTab,
    allData.pageNumber,
    approvedData.pageNumber,
    pendingData.pageNumber,
    breachedData.pageNumber,
    rejectedData.pageNumber,
  ]);

  return (
    <div>
      <VegaPageHeader
        title="MIS"
        renderRightView={() => {
          return (
            <VegaButton
              text={'Export'}
              onClick={() => {
                getMisExportReport();
              }}
            ></VegaButton>
          );
        }}
      />
      <VegaPageHeader
        sx={{
          borderBottom: 'none',
          bgcolor: 'transparent',
        }}
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaTabBar
                value={selectedTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                }}
              >
                <VegaTabBarItem label="All" />
                <VegaTabBarItem label="Pending" />
                <VegaTabBarItem label="Approved" />
                <VegaTabBarItem label="Rejected" />
                <VegaTabBarItem label="Breached" />
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={getData().records}
          loading={loading}
          columns={getColumnDefinition({
            onViewDetailsClick: ots => {
              navigate(ROUTES.OTS_DETAILS.replace(':otsId', ots.id));
            },
          })}
          idColumn="id"
          rowCount={getData().numberOfItems}
          page={getData().pageNumber}
          pageSize={10}
        />
      </VegaPageContent>
    </div>
  );
}

export default OtsMisPage;

const getColumnDefinition = (data: {
  onViewDetailsClick: (ots: Ots) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'dateOfReport',
      headerName: 'Ots Request Date',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const parsedDate = DateUtility.parseStringToDate(ots.createdAt);
        const displayText = DateUtility.formatDateToDDMMYYYY(parsedDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentId',
      headerName: 'Agent Name',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const raisedBy = getValueFromRecord<VegaUser>(
          ots?.metaData,
          OtsMetaDataKey.RAISED_BY
        );
        const displayText = StringUtility.concatenateStrings(
          ' ',
          raisedBy?.firstName,
          raisedBy?.middleName,
          raisedBy?.lastName
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'Loan Id',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = ots.loanId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsStatus',
      headerName: 'Ots Status',
      flex: 0.7,
      renderCell: props => {
        const ots = getOtsForRow(props);
        const displayText = getOtsStatusDescription(ots?.status);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const ots = getOtsForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(ots);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(ots);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getOtsForRow = (props: any) => props.row as Ots;
