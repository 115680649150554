import { Checkbox, CheckboxProps, styled } from '@mui/material';

const StyledCheckbox = styled(Checkbox)({
  color: '#7B8694',

  '&.Mui-checked': {
    color: ' rgba(58, 74, 95, 1)',
  },
  '&.Mui-disabled': {
    color: '#D3D3D3',
  },
  borderRadius: '1.25rem',
});

interface VegaCheckBoxV2Props extends CheckboxProps {
  checked: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  value?: any;
}

function VegaCheckbox({
  checked,
  value,
  onChange,
  ...rest
}: VegaCheckBoxV2Props) {
  return (
    <StyledCheckbox
      indeterminateIcon={<IntermediateIcon />}
      disableRipple
      checked={checked}
      onChange={onChange}
      value={value}
      icon={<UnchekedIcon />}
      checkedIcon={<CheckedIcon />}
      inputProps={{ 'aria-label': 'Checkbox' }}
      {...rest}
    />
  );
}

export default VegaCheckbox;

const CheckedIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.39961 5.39996L5.81985 8.59996L4.59961 7.50917M13.3996 2.99996L13.3996 11C13.3996 12.3255 12.3251 13.4 10.9996 13.4H2.99961C1.67413 13.4 0.599609 12.3255 0.599609 11V2.99996C0.599609 1.67448 1.67413 0.59996 2.99961 0.59996H10.9996C12.3251 0.59996 13.3996 1.67448 13.3996 2.99996Z"
        // stroke="#3A4A5F"
        stroke="rgba(16, 71, 220, 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const UnchekedIcon = styled('span')(() => ({
  width: '16px',
  height: '16px',
  border: '1px solid rgba(16, 71, 220, 1)',
  borderRadius: '3px',

  boxShadow:
    'inset 0 0 0 1px rgba(16, 71, 220, .2), inset 0 -1px 0 rgba(16, 71, 220, .1)',

  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(16, 71, 220, .5)',
  },
}));

const IntermediateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 12H16M21.5999 5.9999L21.5999 18C21.5999 19.9882 19.9881 21.6 17.9999 21.6H5.9999C4.01168 21.6 2.3999 19.9882 2.3999 18V5.9999C2.3999 4.01168 4.01168 2.3999 5.9999 2.3999H17.9999C19.9881 2.3999 21.5999 4.01168 21.5999 5.9999Z"
        stroke="rgba(16, 71, 220, 1)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
