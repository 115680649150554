import { Skeleton, TabProps } from '@mui/material';
import VegaTabBarItem from './VegaTabItem';

interface Props extends TabProps {
  count: number;
  loading?: boolean;
}

const VegaTabBarItemWithNotification = ({ count, loading, ...rest }: Props) => {
  return (
    <VegaTabBarItem
      {...rest}
      iconPosition="end"
      icon={
        loading ? (
          <Skeleton width={50} />
        ) : (
          <div
            style={{
              backgroundColor: 'rgba(231, 237, 252, 1)',
              color: 'rgba(16, 71, 220, 1)',
              borderRadius: '6.25rem',
              padding: '4px 8px',
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: '130%',
            }}
          >
            {count}
          </div>
        )
      }
    />
  );
};

export default VegaTabBarItemWithNotification;
