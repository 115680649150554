import { Grid } from '@mui/material';
import PerformanceCard from '../../components/Perfomance/components/PerformanceCard';
import { StringUtility } from '../../utils/StringUtility';
import { VisitStatistics } from './FieldAppDashboard';

type PropTypes = {
  data: VisitStatistics;
  loading: boolean;
};

function FieldAppDashboardSummary({ data, loading }: PropTypes) {
  const summaries = [
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        data?.noOfVisits ? data.noOfVisits : '0'
      ),
      text: 'No Of Visits',
      loading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        data?.totalAccount ? data.totalAccount : '0'
      ),
      text: 'Total Accounts',
      loading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        data?.contactedCount ? data.contactedCount : '0'
      ),
      text: 'Contacted',
      loading,
    },
    {
      count: StringUtility.formatNumberToIndianNumberFormat(
        data?.totalDistance ? data.totalDistance : '0'
      ),
      text: 'Total Distance (in Km)',
      loading,
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {summaries.map(summary => (
          <Grid key={summary.text} item xs={6} sm={6} md={4} lg={3}>
            <PerformanceCard
              count={summary.count}
              loading={summary.loading}
              text={summary.text}
              bgcolor={'transparant'}
              color={'black'}
              border={'1px solid #E1E4EB'}
              height={'100px'}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default FieldAppDashboardSummary;
