import { Stack } from '@mui/material';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AnaltyticsService } from '../../../Apis/AnaltyticsService';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaIconButton from '../../../components/common/VegaIconButton';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  AllEnums,
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
  CollectionWiseType,
  SegmentOptionType,
  TimePeriodEnumForPenal,
  TimePeriodEnumForPenalMonthly,
  TimePeriodEnumForPenalYearly,
} from '../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  SegmentMonthlyPenalCollectionResultDto,
  SegmentPenalCollectionResultDto,
  SegmentYearlyPenalCollectionResultDto,
} from '../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../types/request/analyticsRequest';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LoadingPage from '../../LoadingPage/LoadingPage';

import { useParams } from 'react-router-dom';
import LmFilterSection from '../../../components/common/LmFilterSection';
import GraphWrapper from '../../AnalyticsDashboard/components/GraphWrapper';
import { TimePeriod } from '../../AnalyticsDashboard/modules/OverAllPerformanceTable';
import { getMonthIndex } from './RoleBaseSegmentPerformance';

const PenalRoleBasedPerformance = () => {
  const { calenderType, profitType } = useParams();
  const [selectedCollectionWiseType, setSelectedCollectionWiseType] =
    useState<string>(CollectionWiseType.RCM);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );

  // const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    | SegmentPenalCollectionResultDto[]
    | SegmentMonthlyPenalCollectionResultDto[]
    | SegmentMonthlyPenalCollectionResultDto[]
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const COL_DEF: GridColumns = [
    {
      field: 'rank',
      headerName: 'Rank',
      flex: 0.3,
      minWidth: 150,
      renderCell: (
        props: GridCellParams<SegmentYearlyPenalCollectionResultDto>
      ) => {
        const response = props.row as SegmentYearlyPenalCollectionResultDto;
        const rank = response?.rank ? response.rank : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={rank}
          />
        );
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentYearlyPenalCollectionResultDto;
        const region = response?.region ? response.region : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={region}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentYearlyPenalCollectionResultDto;
        const managerName = response?.name ? response.name : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={managerName}
          />
        );
      },
    },
    {
      field: 'collected',
      headerName: 'Penal Collection (in crore)',
      flex: 0.7,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriod.MTD ||
        calenderType === CelenderTypeEmun.YEAR,
      renderCell: props => {
        const response = props.row as SegmentPenalCollectionResultDto;
        const pos = response?.penalCollection
          ? response?.penalCollection.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={pos}
          />
        );
      },
    },

    {
      field: 'totalTarget',
      headerName: 'Penal Target (in crore)',
      flex: 0.7,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriod.MTD ||
        calenderType === CelenderTypeEmun.YEAR,
      renderCell: props => {
        const response = props.row as SegmentPenalCollectionResultDto;
        const drr = response?.penalTarget
          ? response.penalTarget?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },

    {
      field: 'mtdResolutionPercentage',
      headerName: 'MTD Collection (in crore)',
      flex: 0.7,
      minWidth: 150,
      hide:
        selectedTimePeriod !== TimePeriod.MTD ||
        calenderType === CelenderTypeEmun.YEAR,
      renderCell: props => {
        const response = props.row as SegmentMonthlyPenalCollectionResultDto;
        const mtdPenalCollection = response?.mtdPenalCollection
          ? response.mtdPenalCollection?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={mtdPenalCollection}
          />
        );
      },
    },
    {
      field: 'lmsdResolutionPercentage',
      headerName: 'LMSD Collection (in crore)',
      flex: 0.7,
      minWidth: 150,
      hide:
        selectedTimePeriod !== TimePeriod.MTD ||
        calenderType === CelenderTypeEmun.YEAR,
      renderCell: props => {
        const response = props.row as SegmentMonthlyPenalCollectionResultDto;
        const lmsdPenalCollection = response?.lmsdPenalCollection
          ? response.lmsdPenalCollection?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={lmsdPenalCollection}
          />
        );
      },
    },
    {
      field: 'ytdResolutionPercentage',
      headerName: 'YTD Collection (in crore)',
      flex: 0.7,
      minWidth: 150,
      hide: calenderType !== CelenderTypeEmun.YEAR,
      renderCell: props => {
        const response = props.row as SegmentYearlyPenalCollectionResultDto;
        const mtdResolutionPercentage = response?.ytdPenalCollection
          ? response.ytdPenalCollection?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={mtdResolutionPercentage}
          />
        );
      },
    },
    {
      field: 'lysdResolutionPercentage',
      headerName: 'LYSD Collection (in crore)',
      flex: 0.7,
      minWidth: 150,
      hide: calenderType !== CelenderTypeEmun.YEAR,
      renderCell: props => {
        const response = props.row as SegmentYearlyPenalCollectionResultDto;
        const drr = response?.lysdPenalCollection
          ? response.lysdPenalCollection?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'percentageChange',
      headerName: '% Change',
      flex: 1,
      minWidth: 150,

      renderCell: props => {
        const response = props.row as SegmentPenalCollectionResultDto;
        const percentage = response?.percentageChange
          ? response.percentageChange?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={percentage}
          />
        );
      },
    },
    {
      field: 'focusBuckets',
      headerName: 'Focus Bucket',
      flex: 1,
      minWidth: 300,
      renderCell: props => {
        const response = props.row as SegmentYearlyPenalCollectionResultDto;
        const focusBuckets = response?.focusBuckets
          ? response.focusBuckets
          : 'NA';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={focusBuckets}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={focusBuckets}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
  ];

  const filterData = () => {
    const queries = analyticsQueryResponse as any;
    if (search.length) {
      const searchLowerCase = search.toLowerCase();
      const filteredResults = queries.filter((query: any) => {
        if (search === '') {
          return query;
        } else if (query.name.toLowerCase().includes(searchLowerCase)) {
          return query;
        }
      });
      return filteredResults;
    } else {
      return queries;
    }
  };

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoader(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoader(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');

      setLoader(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);

    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.ONE_DAY:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
          };
        case TimePeriodEnumForPenal.FIVE_DAY:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              FIVE_DAY?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              FIRST_DAY?.toISOString()
            ),
          };
        case TimePeriodEnumForPenal.MTD:
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              new Date(FIRST_DAY)?.toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              new Date()?.toISOString()
            ),
          };
      }
    }
    if (calenderType === CelenderTypeEmun.YEAR) {
      switch (selectedTimePeriod) {
        case TimePeriodEnumForPenal.QUATER_1:
        case TimePeriodEnumForPenal.QUATER_2:
        case TimePeriodEnumForPenal.QUATER_3:
        case TimePeriodEnumForPenal.QUATER_4: {
          const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfQuarter(
                CURRENT_YEAR,
                quarterNumber
              ).toISOString()
            ),
          };
        }
        case TimePeriodEnumForPenal.JANUARY:
        case TimePeriodEnumForPenal.FEBRUARY:
        case TimePeriodEnumForPenal.MARCH:
        case TimePeriodEnumForPenal.APRIL:
        case TimePeriodEnumForPenal.MAY:
        case TimePeriodEnumForPenal.JUNE:
        case TimePeriodEnumForPenal.JULY:
        case TimePeriodEnumForPenal.AUGUST:
        case TimePeriodEnumForPenal.SEPTEMBER:
        case TimePeriodEnumForPenal.OCTOBER:
        case TimePeriodEnumForPenal.NOVEMBER:
        case TimePeriodEnumForPenal.DECEMBER: {
          const monthIndex = getMonthIndex(selectedTimePeriod);
          return {
            fromDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getStartOfMonth(
                CURRENT_YEAR,
                monthIndex
              ).toISOString()
            ),
            toDate: DateUtility.formatStringToYYYYMMDD(
              DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
            ),
          };
        }
        default:
          return {};
      }
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      if (timePeriodParams !== null) {
        return {
          ...timePeriodParams,
          role: selectedCollectionWiseType,
        };
      }
    } else {
      return {
        ...timePeriodParams,
        role: selectedCollectionWiseType,
      };
    }
  };

  const getQueryType = () => {
    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
      case TimePeriodEnumForPenal.FIVE_DAY:
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4:
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER:
        return AnalyticsQueryTypeEnum.SEGMENT_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.MTD:
        return AnalyticsQueryTypeEnum.SEGMENT_MONTHLY_PENAL_COLLECTION;

      case TimePeriodEnumForPenal.YTD:
        return AnalyticsQueryTypeEnum.SEGMENT_YEARLY_PENAL_COLLECTION;
    }
  };

  const getQueries = () => {
    const analyticsQueryType = getQueryType();
    const request = {
      analyticsQueryType,
      params: { ...getParams(), type: profitType },
    } as AnalyticsQueryRequest;
    getQueriesForEnum(request);
  };

  const getTimePeriodOptions = () => {
    if (
      calenderType === CelenderTypeEmun.TODAY ||
      calenderType === CelenderTypeEmun.MONTH
    ) {
      const options = Object.keys(TimePeriodEnumForPenalMonthly).map(item => {
        return { value: item, label: toLowerCase(item) } as VegaSelectOption;
      });
      return options;
    }
    const options = Object.keys(TimePeriodEnumForPenalYearly).map(item => {
      const year = new Date().getFullYear();
      const label = `${toLowerCase(item)} (${year})`;
      return { value: item, label: label } as VegaSelectOption;
    });
    return options;
  };

  useEffect(() => {
    selectedCollectionWiseType.length && getQueries();
  }, [selectedTimePeriod, selectedCollectionWiseType]);

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper
          title="Role Based Performance"
          rightComponent={
            <Stack direction={'row'} gap={1}>
              <Stack direction={'row'} gap={1}>
                <LmFilterSection
                  hideFilter
                  search={search}
                  setSearch={setSearch}
                />
                <Stack sx={{ minWidth: 150 }}>
                  <VegaSelect
                    ignoreLodash
                    options={getViewOptions(SegmentOptionType)}
                    value={selectedCollectionWiseType}
                    onChange={selected =>
                      setSelectedCollectionWiseType(
                        selected.target.value as string
                      )
                    }
                  />
                </Stack>
              </Stack>
              <Stack sx={{ minWidth: 150 }}>
                <VegaSelect
                  options={getTimePeriodOptions()}
                  value={selectedTimePeriod}
                  onChange={select =>
                    setSelectedTimePeriod(select.target.value as string)
                  }
                  ignoreLodash
                />
              </Stack>
            </Stack>
          }
        >
          {loader ? (
            <LoadingPage height={400} />
          ) : (
            <VegaDataGrid
              data={analyticsQueryResponse ? filterData() : []}
              columns={COL_DEF}
              idColumn="id"
              paginationMode="client"
              pageSize={5}
            />
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default PenalRoleBasedPerformance;
const getViewOptions = (type: AllEnums) => {
  const options = Object.keys(type).map(
    item =>
      ({
        value: type[item],
        label: toLowerCase(item),
      } as VegaSelectOption)
  );
  return options;
};
