import { Grid, Stack } from '@mui/material';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { useAppSelector } from '../../../store';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const LoanDetails = () => {
  const { loanData, loading } = useAppSelector(getTeleAgent);

  const getLmsVariableData = () => {
    if (loanData.length && loanData[0].lmsVariables) {
      const lmsVariableData = loanData[0]?.lmsVariables;
      return {
        bucket: lmsVariableData.bucket,
        cycleDate: lmsVariableData.cycleDate,
        loanId: lmsVariableData.loanId,
        installment: lmsVariableData.installment,
        dpd: lmsVariableData.dpd,
      };
    }
    return {
      bucket: '--',
      cycleDate: '--',
      loanId: '--',
      installment: '--',
      dpd: '--',
    };
  };
  const getMetaDataLoanAllocationDto = () => {
    if (loanData.length && loanData[0].lmsVariables) {
      const lmsVariables = loanData[0]?.lmsVariables;
      return {
        allocationDate: DateUtility.formatStringToDDMMYYYYWithTime(
          lmsVariables?.allocationDate
        ),
      };
    }
    return {
      allocationDate: '--',
    };
  };
  const getLoanOutstandingDetails = () => {
    if (loanData.length && loanData[0].loanOutstandingDetails) {
      const loanOutstandingDetails = loanData[0]?.loanOutstandingDetails;
      return {
        daysPastDue: loanOutstandingDetails.daysPastDue,
      };
    }
    return {
      daysPastDue: '--',
    };
  };
  const getLoanMaster = () => {
    if (loanData.length && loanData[0].loanMaster) {
      const loanMaster = loanData[0]?.loanMaster;
      return {
        emi: StringUtility.formatToINR(loanMaster?.emi),
      };
    }
    return {
      emi: '--',
    };
  };

  return (
    <TeleAgentDetailsCard text="Loan Details" isBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Loan Account Number"
              value={getLmsVariableData().loanId}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="emi"
              value={getLmsVariableData().installment}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Cycle date"
              value={getLmsVariableData().cycleDate}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Bucket"
              value={getLmsVariableData().bucket}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="DPD (Days Past Due)"
              value={getLmsVariableData().dpd}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Allocation date & time"
              value={getMetaDataLoanAllocationDto().allocationDate}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default LoanDetails;
