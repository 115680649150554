import { ComputeNpvFormData } from '../pages/components/OtsNpvCalculations';

export class NpvCalculator {
  private marketValue: number;
  private totalOutstanding: number;
  private applicableRoi: number;
  private realisableValue: number;
  private discountedRoi: number;
  private saleCompletionPeriod: number;
  private principleOutstanding: number;
  private isPropertyDocsAvailable: boolean;

  constructor(data: Partial<ComputeNpvFormData>) {
    const propertyAvailable =
      data.propertyDocsAvailable == 'YES' ? true : false;
    this.marketValue = data.marketValue;
    this.totalOutstanding = data.outstanding;
    this.applicableRoi = data.roi;
    this.realisableValue = data.realisableValue;
    this.discountedRoi = data.discountedRoi;
    this.saleCompletionPeriod = data.saleCompletionPeriod;
    this.principleOutstanding = data.principal;
    this.isPropertyDocsAvailable = propertyAvailable;
  }

  private calculateDiscountedInterestFactor(): number {
    return 1 / Math.pow(1 + this.discountedRoi / 12, this.saleCompletionPeriod);
  }

  private calculateDistressValue(): number {
    if (this.realisableValue === 0 && this.marketValue === 0) {
      return 0.0;
    } else if (this.realisableValue > 0) {
      return this.isPropertyDocsAvailable
        ? this.realisableValue * 0.9
        : this.marketValue * 0.5;
    } else if (this.marketValue > 0) {
      return this.isPropertyDocsAvailable
        ? this.marketValue * 0.8
        : this.marketValue * 0.5;
    }
    return 0.0; // Fallback in case of unexpected input
  }

  private calculateTosBasedMaxValue(): number {
    return (
      this.totalOutstanding +
      this.principleOutstanding *
        ((this.applicableRoi * this.saleCompletionPeriod) / 12)
    );
  }

  private calculatePvOfTosBasedMaxValue(
    discountedInterestFactor: number
  ): number {
    return this.calculateTosBasedMaxValue() * discountedInterestFactor;
  }

  private calculateRealisationCosts(distressValue: number): {
    fixed: number;
    variable: number;
    total: number;
  } {
    const fixedRealisationCost: number = 30000.0;
    const variableRealisationCost: number = distressValue * 0.1;
    const totalRealisationCost: number =
      fixedRealisationCost + variableRealisationCost;

    return {
      fixed: fixedRealisationCost,
      variable: variableRealisationCost,
      total: totalRealisationCost,
    };
  }

  public calculate(): {
    distressValue: number;
    npvOfDistressValue: number;
    tosBasedMaxValue: number;
    pvOfTosBasedMaxValue: number;
    propertyValueRealization: number;
    realisationCosts: {
      fixed: number;
      variable: number;
      total: number;
      pv: number;
    };
  } {
    const distressValue = this.calculateDistressValue();
    const discountedInterestFactor = this.calculateDiscountedInterestFactor();
    const realisationCosts = this.calculateRealisationCosts(distressValue);
    const pvRealisationCost = realisationCosts.total * discountedInterestFactor;
    const npvOfDistressValue =
      distressValue * discountedInterestFactor - pvRealisationCost;
    const tosBasedMaxValue = this.calculateTosBasedMaxValue();
    const pvOfTosBasedMaxValue = this.calculatePvOfTosBasedMaxValue(
      discountedInterestFactor
    );
    const propertyValueRealization = Math.min(
      npvOfDistressValue,
      pvOfTosBasedMaxValue
    );

    return {
      distressValue,
      npvOfDistressValue,
      tosBasedMaxValue,
      pvOfTosBasedMaxValue,
      propertyValueRealization,
      realisationCosts: {
        ...realisationCosts,
        pv: pvRealisationCost,
      },
    };
  }
}
