/* eslint-disable no-undef */
/** @format */

import axios from 'axios';
import { isCapriUATEnv, isCapriProdEnv, isCapriDevEnv } from '../constants/url';

const CAPRI_PROD_API_GATEWAY = 'https://collectxpress-api.capriglobal.in';
const CAPRI_UAT_API_GATEWAY =
  'https://vegapay-collections-uat-api.capriglobal.in';
const CAPRI_DEV_API_GATEWAY = 'https://collectxpressapi-dev.capriglobal.in';
const ALPHA_API_GATEWAY =
  'https://7h8trf44g9.execute-api.ap-south-1.amazonaws.com/collection';

export const API_GATEWAY = `${
  isCapriProdEnv
    ? CAPRI_PROD_API_GATEWAY
    : isCapriUATEnv
    ? CAPRI_UAT_API_GATEWAY
    : isCapriDevEnv
    ? CAPRI_DEV_API_GATEWAY
    : ALPHA_API_GATEWAY
}`;
export const configureAxiosDefaults = () => {
  const BASE_URL = API_GATEWAY;
  axios.defaults.baseURL = BASE_URL;
};

//request interceptor
axios.interceptors.request.use(
  (config: any) => {
    config.headers['Authorization'] = `${localStorage.getItem('accessToken')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      // localStorage.clear();
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
