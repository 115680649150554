import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { getAccountList, getAccountState } from '../../features/accountsSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { LoanData, LoanMaster } from '../../types/loan';
import { LoanListRequest } from '../../types/request/loan';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../components/common/VegaIconButton';
import { PRIMARY } from '../../constants/style';

function TrailPage() {
  const { user } = useClientAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, accounts, totalItems } = useAppSelector(getAccountState);

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [searchLoanId, setSearchLoanId] = useState<string>('');
  function onPageChange(pageNumber: number) {
    if (page == pageNumber) return;
    setPage(pageNumber);
  }

  const fetchData = () => {
    if (searchLoanId.length) {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        restrictedView: true,
        page,
        size,
        agentId: user?.id,
        searchPartialLoanId: searchLoanId.toUpperCase(),
      } as LoanListRequest;

      dispatch(getAccountList(body));
    } else {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        restrictedView: true,
        page,
        size,
        agentId: user?.id,
      } as LoanListRequest;
      dispatch(getAccountList(body));
    }
  };

  useEffect(() => {
    if (user) {
      if (searchLoanId.length) {
        const getLoanData = setTimeout(fetchData, 300);
        return () => clearTimeout(getLoanData);
      } else {
        fetchData();
      }
    }
  }, [user, page, size, searchLoanId]);

  return (
    <>
      <VegaPageHeader title="Trail"></VegaPageHeader>
      <VegaPageHeader
        title="My Accounts"
        renderRightView={() => {
          return (
            <LmFilterSection
              hideFilter
              search={searchLoanId}
              setSearch={setSearchLoanId}
            />
          );
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaPageContent>
        <VegaDataGrid
          page={page}
          pageSize={size}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          onPageSizeChange={size => {
            setSize(size);
          }}
          paginationMode="server"
          data={accounts}
          columns={getColumnDefinition({
            onViewDetailsClick: loan => {
              navigate(
                ROUTES.Trail_Detail_Page.replace(':loanAccountId', loan?.loanId)
              );
            },
          })}
          loading={loading}
          idColumn="loanId"
        />
      </VegaPageContent>
    </>
  );
}

export default TrailPage;
const getColumnDefinition = (data: {
  onViewDetailsClick?: (loan: LoanData) => void;
}) => {
  const COLUMN_DEF: GridColumns<LoanData> = [
    {
      field: 'Name',
      headerName: 'Name',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.customerName) {
            displayText = getCustomerName.customerName;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'loanId',
      headerName: 'LAN',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'ContactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.mobileNo) {
            displayText = getCustomerName.mobileNo;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Product',
      headerName: 'Product',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const loan = props.row as LoanData;
        const loanMaster = loan.loanMaster as LoanMaster;
        let displayText = '';
        if (loanMaster?.loanProduct) {
          displayText = loanMaster?.loanProduct;
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 0.6,
      minWidth: 119,

      renderCell: props => {
        const loan = props.row as LoanData;
        return (
          // <VegaButton
          //   text="View Details"
          //   onClick={() => {
          //     data.onViewDetailsClick(loan);
          //   }}
          //   variant="text"
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => data.onViewDetailsClick(loan)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
