import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import { AnalyticsQueryTypeEnum } from '../../../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import { GraphTypeEnum } from '../../../AnalyticsDashboard';
import BarChart from '../../../components/BarChart';
import VegaText from '../../../../../components/common/VegaText';

const ChannelPerformance = () => {
  const { viewType, profitType } = useParams();
  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<any[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const series = [
    {
      name: 'DRR Target',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value =>
            parseFloat(value?.target?.toFixed(2))
          )
        : [],
    },
    {
      name: 'Today',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (viewType === GraphTypeEnum.COLLECTION_VOLUME) {
              return parseFloat(value?.receipts?.toFixed(2));
            }
            return parseFloat(value?.achieved?.toFixed(2));
          })
        : [],
    },
  ];

  const channelCategories = analyticsQueryResponse?.length
    ? analyticsQueryResponse.map(value => value?.channel)
    : [];

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoading(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      const dummyObj = {} as any;
      setAnalyticsQueryResponse([dummyObj]);
      setLoading(false);
    }
  };
  const getRequest = () => {
    if (viewType === GraphTypeEnum.COLLECTION_VALUE) {
      const request = {
        analyticsQueryType:
          AnalyticsQueryTypeEnum.TODAY_COLLECTION_FIRST_CLICK_CHANNEL,
        params: {
          type: profitType,
        },
      } as AnalyticsQueryRequest;
      return request;
    } else if (viewType === GraphTypeEnum.COLLECTION_VOLUME) {
      const request = {
        analyticsQueryType: AnalyticsQueryTypeEnum.VOLUME_CHANNEL_PERFORMANCE,
        params: {
          type: profitType,
        },
      } as AnalyticsQueryRequest;
      return request;
    }
  };
  const getQueries = () => {
    const request = getRequest();
    getQueriesForEnum(request);
  };
  useEffect(() => {
    getQueries();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingPage height={400} />
      ) : (
        <GraphWrapper title="Channel Based Performance">
          <Stack
            sx={{
              p: 3,
              bgcolor: 'white',
              borderRadius: '12px',
              border: BORDER_COLLECTION,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: -25,
                  height: 50,
                  width: '91%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  {analyticsQueryResponse.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1', textAlign: 'center' }}>
                          {getPercentageValue(val)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <BarChart
                categories={channelCategories}
                series={series}
                yAxisTitle={
                  viewType === GraphTypeEnum.COLLECTION_VOLUME
                    ? 'No of Cases'
                    : 'Rs. Crore'
                }
              />
            </Box>
          </Stack>
        </GraphWrapper>
      )}
    </>
  );
};

export default ChannelPerformance;
const getPercentageValue = query => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
