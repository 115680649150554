import axios from 'axios';
import { ResourceDtoObj, VegaUser } from '../types/claim';
import {
  AddAccessScreen,
  AssignRoleProps,
  GetRoleAccessList,
  GetUsersFromRoleNamesRequest,
  RoleAccess,
  RoleDto,
  RoleResourceDtoList,
  RolesProps,
} from '../types/roleType';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  RBAC: '/rbac',
  LIST: '/list',
  RESOURCE: '/resource',
  RESOURCES: '/resources',
  ROLE: '/role',
  ROLES: '/roles',
  CLIENT: '/client',
  USER: '/user',
  USERS: '/users',
  CAPRI: '/capri',
  ASSIGN_ROLE: '/assign-role',
  UNASSIGN_ROLE: '/unassign-role',
  ACCESS_ADD: '/access/add',
  ACCESS_REMOVE: '/access/remove',
};
const ROLE_URL =
  Endpoint.RBAC + Endpoint.ROLE + Endpoint.CLIENT + Endpoint.CAPRI;
const ROLE_ACCESS_URL = Endpoint.RBAC + Endpoint.ROLE + Endpoint.RESOURCES;

const USERS_URL = Endpoint.RBAC + Endpoint.USERS;
const ASSIGN_ROLE_URL = Endpoint.RBAC + Endpoint.USER + Endpoint.ASSIGN_ROLE;
const UNASSIGN_ROLE_URL =
  Endpoint.RBAC + Endpoint.USER + Endpoint.UNASSIGN_ROLE;
const GET_ROLE_FOR_USER = Endpoint.RBAC + Endpoint.USER + Endpoint.ROLES;
const RESOURCES_LIST_URL = Endpoint.RBAC + Endpoint.RESOURCE + Endpoint.LIST;
const ACCESS_ADD_URL = Endpoint.RBAC + Endpoint.RESOURCE + Endpoint.ACCESS_ADD;
const ACCESS_REMOVE_URL =
  Endpoint.RBAC + Endpoint.RESOURCE + Endpoint.ACCESS_REMOVE;

export class RoleServices {
  static async getRoles(): Promise<RolesProps[]> {
    const endpoint = ROLE_URL;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getRolesAccess(
    request: Partial<GetRoleAccessList>
  ): Promise<RoleResourceDtoList> {
    const endpoint = ROLE_ACCESS_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getRolesByUserId(userId: string): Promise<RoleDto[]> {
    const endpoint = GET_ROLE_FOR_USER;
    const response = await axios.get(endpoint, { params: { userId } });
    return response.data;
  }
  static async getResources(
    request: Partial<RoleAccess>
  ): Promise<ResourceDtoObj[]> {
    const endpoint = RESOURCES_LIST_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getUsersFromRoleName(
    request: Partial<GetUsersFromRoleNamesRequest>
  ): Promise<PaginatedResponse<VegaUser>> {
    const endpoint = USERS_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async assignRole(
    request: Partial<AssignRoleProps>
  ): Promise<RolesProps[]> {
    const endpoint = ASSIGN_ROLE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async unAssignRole(
    request: Partial<AssignRoleProps>
  ): Promise<RolesProps[]> {
    const endpoint = UNASSIGN_ROLE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async addAccessScreen(
    request: Partial<AddAccessScreen>
  ): Promise<RolesProps[]> {
    const endpoint = ACCESS_ADD_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async removeAccessScreen(
    request: Partial<AddAccessScreen>
  ): Promise<RolesProps[]> {
    const endpoint = ACCESS_REMOVE_URL;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
}
