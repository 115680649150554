import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ConfigurationDto,
  ConfigurationType,
  ConfigurationsListProps,
} from '../types/configurationType';
import { ConfigListRequest } from '../types/request/cofigurationRequest';
import { ConfigurationServices } from '../Apis/ConfigurationServices';
import { DateUtility } from '../utils/DateUtlility';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

export const initialConfigDto = {
  approvalDeadline: 0,
  escalationDeadline: 0,
};
export const initialLeaveRequestConfigDto = {
  leaveRequestDeadline: 0,
  reminderTimePeriod: 0,
  approvalDeadline: 0,
  escalationDeadline: 0,
};
export const initialMinMaxVisitsConfigDto = {
  minVisits: 0,
  maxVisits: 0,
};
export const initialRouteDeviationConfigDto = {
  routeDeviationCount: 0,
  breachReasonDeadline: 0,
  escalationDeadline: 0,
};
export const initialAgentAlertConfigDto = {
  startTime: TODAY.toISOString(),
  endTime: TOMORROW.toISOString(),
  targetEntryDeadline: TODAY.toISOString(),
  targetReasonDeadline: TODAY.toISOString(),
  reminderPeriod: 0,
};

export const initialSupervisorConfigDto = {
  expenseApprovalDeadline: TODAY.toISOString(),
  targetDeviationClosureDeadline: TODAY.toISOString(),
  minVisitBreachDeadline: TODAY.toISOString(),
};
export const initialAcrConfigDto = {
  fieldAcrLimit: 0,
  teleAcrLimit: 0,
};

export const initialNpaConfigDto = {
  agentId: '',
  agentName: '',
};

export const initialLeaveRequestConfig = {
  name: ConfigurationType.LEAVE_REQUEST,
  config: initialLeaveRequestConfigDto,
} as ConfigurationDto;

export const initialMinMaxVisitsConfig = {
  name: ConfigurationType.MIN_MAX_VISITS,
  type: '',
  subtypes: [],
  config: initialMinMaxVisitsConfigDto,
} as ConfigurationDto;

export const initialGeoVerifyConfig = {
  name: ConfigurationType.GEO_VERIFICATION,
  config: initialConfigDto,
} as ConfigurationDto;

export const initialExpenseManagConfig = {
  name: ConfigurationType.EXPENSE_MANAGEMENT,
  config: initialConfigDto,
} as ConfigurationDto;

export const initialRouteDeviationConfig = {
  name: ConfigurationType.ROUTE_DEVIATION,
  config: initialRouteDeviationConfigDto,
} as ConfigurationDto;

export const initialAgentAlertNotifyConfig = {
  name: ConfigurationType.ALERT_NOTIFICATIONS_AGENT,
  config: initialAgentAlertConfigDto,
} as ConfigurationDto;

export const initialSupervisorAlertNotifyConfig = {
  name: ConfigurationType.ALERT_NOTIFICATIONS_SUPERVISOR,
  config: initialSupervisorConfigDto,
} as ConfigurationDto;

export const initialNpaDpdNcmConfig = {
  name: ConfigurationType.NCM_DPD,
  type: '',
  subtypes: [],
  config: initialNpaConfigDto,
} as ConfigurationDto;

export const initialAcrConfig = {
  name: ConfigurationType.ALLOCATION_ACR,
  config: initialAcrConfigDto,
} as ConfigurationDto;

export const initialOtsConfig = {
  name: ConfigurationType.OTS,
  config: {
    maxResubmissions: 0,
    ncmTatDays: 0,
    rcmTatDays: 0,
    hocTatDays: 0,
    mdTatDays: 0,
    chairTatDays: 0,
    opsTatDays: 0,
    techTatDays: 0,
    customerAckTatDays: 0,
    notificationFrequencyDays: 0,
  },
} as ConfigurationDto;

export interface ConfigurationSliceProps {
  configurations: Partial<ConfigurationDto>;
  selectedConfigurationsTab: number;
  configs: ConfigurationsListProps[];
  totalItems: number;
  loading: boolean;
  configActive: boolean;
  configStatus: string;
  configSearch: string;
  configSearchBy: string;
  error: string | null;
}

const initialState: ConfigurationSliceProps = {
  configurations: {},
  selectedConfigurationsTab: 0,
  configs: [],
  totalItems: 0,
  loading: false,
  configActive: false,
  configStatus: '',
  configSearch: '',
  configSearchBy: '',
  error: null,
};

export const fetchConfigListSlice = createAsyncThunk(
  'configuration/config/list',
  async (request: Partial<ConfigListRequest>) => {
    const response = await ConfigurationServices.getConfigList(request);
    return response;
  }
);

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfigurations: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.configurations = payload;
    },
    setSelectedConfigurationsTab: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.selectedConfigurationsTab = payload;
    },
    setConfigs: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.configs = payload;
    },
    setConfigSearch: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.configSearch = payload;
    },
    setConfigSearchBy: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.configSearchBy = payload;
    },
    setConfigStatus: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.configStatus = payload;
    },
    setConfigActive: (state: ConfigurationSliceProps, action) => {
      const { payload } = action;
      state.configActive = payload;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(fetchConfigListSlice.pending, state => {
        state.loading = true;
        state.configs = [];
        state.error = null;
      })
      .addCase(fetchConfigListSlice.fulfilled, (state, action) => {
        const configs = [...action.payload.records];

        state.loading = false;
        state.configs = configs;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchConfigListSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});
export const {
  setConfigurations,
  setSelectedConfigurationsTab,
  setConfigs,
  setConfigActive,
  setConfigSearch,
  setConfigStatus,
  setConfigSearchBy,
} = configurationSlice.actions;
export const getConfigurationState = (state: RootState) => state.configuration;
export default configurationSlice;
