import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import VegaPageContent from '../../../components/common/VegaPageContent';
import {
  CallTimeMetrics,
  InOutBoundMetrics,
  StateTimeMetrics,
} from '../../../types/teleCallingMetricsType';
import MetricsSummaryCards from './MetricsSummaryCards';
import VegaText from '../../../components/common/VegaText';

interface IProps {
  agentCallMetricsDto: InOutBoundMetrics | CallTimeMetrics | StateTimeMetrics;
  loading: boolean;
  label: string;
}

const CallMetricsSummary = ({
  agentCallMetricsDto,
  loading,
  label,
}: IProps) => {
  return (
    <div>
      <VegaPageContent mt={'1rem'}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <VegaText text={label} />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {Object.keys(agentCallMetricsDto).map(
                (answerCall: string, index: number) => {
                  const label = answerCall;
                  const value = agentCallMetricsDto[answerCall];
                  return (
                    <Grid key={index} item xs={12} sm={6} md={2.4} lg={2.4}>
                      <MetricsSummaryCards
                        key={index}
                        loading={loading}
                        label={label}
                        value={value}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </VegaPageContent>
    </div>
  );
};

export default CallMetricsSummary;
