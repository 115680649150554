/* eslint-disable react/prop-types */
import { Close, VisibilityOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { GREY, PRIMARY, RED } from '../../constants/style';
import { useBankCustomer } from '../../providers/CustomerBankProvider';
import {
  ICreateCustomerBranchProps,
  ICustomerBankListProps,
} from '../../types/customerBank';
import { VegaUserStatus } from '../../types/user';
import { EditIcon, InfoIcon, TrashIcon } from '../Icons/Icons';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import { VegaSwitch } from '../common/VegaSwitch';
import VegaText from '../common/VegaText';
import ViewCustomerBranchDetails from '../CustomerBranchInfo/ViewCustomerBranchDetails';
import VegaIconButton from '../common/VegaIconButton';

const ListOfCustomerBankInfo = () => {
  const {
    page,
    size,
    bankList,
    totalItems,
    bankListLoading,
    setSelectedId,
    toggleAddBankBranchDrawer,
    onDeleteCustomerBank,
    setPage,
    setSize,
    onUpdateCustomerBankStatus,
  } = useBankCustomer();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [customerBankName, setCustomerBankName] = useState<string>('');
  const toggleDeleteModal = () => {
    setIsDelete(!isDelete);
  };
  const toggleViewDetailsModal = () => {
    setIsView(!isView);
  };

  const COLUMN_DEF: GridColumns = [
    {
      field: 'bankName',
      headerName: 'Bank Name',
      flex: 1,
      minWidth: 75,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'BranchName',
      headerName: 'Branch Name',
      flex: 1,
      minWidth: 200,
      renderCell: props => {
        const bankCustomer = props.row as ICustomerBankListProps;
        return (
          <VegaText
            text={bankCustomer?.branchName}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    // {
    //   field: 'branchCode',
    //   headerName: 'Branch Code',
    //   flex: 0.7,
    //   minWidth: 150,
    // },
    // {
    //   field: 'ifsc',
    //   headerName: 'IFSC',
    //   flex: 0.7,
    //   minWidth: 150,
    // },
    {
      field: 'micr',
      headerName: 'MICR',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const bankCustomer = props.row as ICustomerBankListProps;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <VegaText text={toLowerCase(bankCustomer?.status)} />
            <VegaSwitch
              checked={bankCustomer?.status === VegaUserStatus.ACTIVE}
              onClick={() => updateStatusForBankStatus(bankCustomer)}
            />
          </Stack>
        );
      },
    },

    {
      field: 'typeDetails',
      headerName: 'Branch Details',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const bankCustomer = props.row as ICustomerBankListProps;
        return (
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              viewDetails(bankCustomer?.micr);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const bankCustomer = props.row as ICustomerBankListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text=""
              startIcon={<EditIcon />}
              sx={{
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
                ':hover': {
                  bgcolor: '#E7EDFC',
                  color: PRIMARY.darkBlue,
                },
              }}
              onClick={() => {
                setSelectedId(bankCustomer?.micr);
                toggleAddBankBranchDrawer();
              }}
            />
            <VegaButton
              text=""
              startIcon={<TrashIcon />}
              sx={{
                bgcolor: '#F8E8E8',
                color: RED.red,
                ':hover': {
                  bgcolor: '#F8E8E8',
                  color: RED.red,
                },
              }}
              onClick={() => {
                setCustomerBankName(
                  bankCustomer?.bankName + bankCustomer?.micr
                );
                setSelectedId(bankCustomer?.micr);
                setIsDelete(true);
              }}
            />
          </Stack>
        );
      },
    },
  ];

  const updateStatusForBankStatus = (bankCustomer: ICustomerBankListProps) => {
    const bankCustomerObj = {
      micr: bankCustomer?.micr,
      status:
        bankCustomer?.status === VegaUserStatus.ACTIVE
          ? VegaUserStatus.INACTIVE
          : VegaUserStatus.ACTIVE,
    } as ICreateCustomerBranchProps;
    onUpdateCustomerBankStatus(bankCustomerObj);
  };

  const viewDetails = (micr: string | undefined) => {
    if (micr) {
      setSelectedId(micr);
      toggleViewDetailsModal();
    }
  };
  return (
    <>
      <VegaDataGrid
        data={bankList}
        columns={COLUMN_DEF}
        idColumn="micr"
        loading={bankListLoading}
        paginationMode="server"
        page={page}
        pageSize={size}
        rowCount={totalItems}
        onPageChange={page => setPage(page)}
        onPageSizeChange={size => setSize(size)}
      />
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={isDelete}
        handleClose={toggleDeleteModal}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <LmButton
              fullWidth
              text="Delete"
              bgColor={RED.red}
              startIcon={<TrashIcon strokeColor={'white'} />}
              onClick={() => {
                onDeleteCustomerBank();
                toggleDeleteModal();
              }}
            />
            <LmButton
              text="Cancel"
              fullWidth
              variant="outlined"
              bgColor=""
              textColor={GREY.dark}
              onClick={() => {
                setSelectedId(null);
                toggleDeleteModal();
              }}
              startIcon={<Close sx={{ color: GREY.dark }} />}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <InfoIcon />
            <VegaText
              text="Confirmation"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <Box p={4}>
          <VegaText
            text="Are you sure you want to delete this customer branch?"
            fontWeight={600}
            fontSize={17}
          />
          {/* <VegaText
            fontWeight={400}
            fontColor={'#999DA8'}
            // <Typography
            //   className="font-aspekta-400"
            //   color={'#999DA8'}
            fontSize={'0.8125rem'}
            lineHeight={'15.6px'}
            mt={1}
            // >
            text={`This action cannot be undone. This will permanently delete the ${customerBankName} instance.`}
          />*/}
          <Typography
            className="font-aspekta-400"
            color={'#999DA8'}
            fontSize={'0.8125rem'}
            lineHeight={'15.6px'}
            mt={1}
          >
            This action cannot be undone. This will permanently delete the
            {'   '}
            <b style={{ color: '#1B1D22' }}>{customerBankName} </b> {'   '}{' '}
            instance.
          </Typography>
        </Box>
      </LmDialog>

      <LmDialog
        fullWidth
        maxWidth="sm"
        open={isView}
        isCloseIcon
        handleClose={toggleViewDetailsModal}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="View Customer Branch Details"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <Box px={4} pt={3} pb={4}>
          <ViewCustomerBranchDetails />
        </Box>
      </LmDialog>
    </>
  );
};

export default ListOfCustomerBankInfo;
