import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnaltyticsService } from '../../../../../Apis/AnaltyticsService';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../../components/common/VegaSelect';
import VegaText from '../../../../../components/common/VegaText';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../../../../constants/style';
import {
  AllEnums,
  AllTimePeriodEnums,
  AnalyticsQueryTypeEnum,
  BucketEnumForYearAnalytics,
  CelenderTypeEmun,
  OverallMetricsEnum2,
  RegionPerformanceRoleEnum,
  TimePeriodEnumForPenal,
  Today_TimePeriodEnum,
  Year_TimePeriodEnum,
} from '../../../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import {
  RegionPerformanceResultDto,
  TeleAgentPerformanceResultDto,
} from '../../../../../types/analytics';
import { AnalyticsQueryRequest } from '../../../../../types/request/analyticsRequest';
import { DateUtility } from '../../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import GraphWrapper from '../../../../AnalyticsDashboard/components/GraphWrapper';
import { getMonthIndex } from '../../../../AnalyticsDashboard/modules/RoleBasedPerformance';
import LoadingPage from '../../../../LoadingPage/LoadingPage';
import { GraphTypeEnum } from '../../../AnalyticsDashboard';
import BarChart from '../../../components/BarChart';

const TeleAgentPerformance = () => {
  const { viewType, calenderType, profitType } = useParams();

  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    BucketEnumForYearAnalytics.OVERALL
  );
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    calenderType === CelenderTypeEmun.TODAY
      ? TimePeriodEnumForPenal.ONE_DAY
      : calenderType === CelenderTypeEmun.MONTH
      ? TimePeriodEnumForPenal.MTD
      : TimePeriodEnumForPenal.YTD
  );
  const [selectedResolution, setSelectedResolution] = useState<string>(
    OverallMetricsEnum2.NONE
  );

  const { setSnackbar } = useSnackbar();
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    TeleAgentPerformanceResultDto[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);

  const series = [
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'MTD'
          : selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'YTD'
          : 'Target',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (selectedTimePeriod === TimePeriodEnumForPenal.MTD) {
              return parseFloat(value?.mtdAchieved?.toFixed(2));
            } else if (selectedTimePeriod === TimePeriodEnumForPenal.YTD) {
              return parseFloat(value?.ytdAchieved?.toFixed(2));
            } else {
              return parseFloat(value?.target?.toFixed(2));
            }
          })
        : [],
    },
    {
      name:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? 'LMSD'
          : selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? 'LYSD'
          : 'Achieved',
      data: analyticsQueryResponse?.length
        ? analyticsQueryResponse.map(value => {
            if (selectedTimePeriod === TimePeriodEnumForPenal.MTD)
              return parseFloat(value?.lmsdAchieved?.toFixed(2));
            else if (selectedTimePeriod === TimePeriodEnumForPenal.YTD)
              return parseFloat(value?.lysdAchieved?.toFixed(2));
            else {
              return parseFloat(value?.achieved?.toFixed(2));
            }
          })
        : [],
    },
  ];

  const regionCategories = analyticsQueryResponse?.length
    ? analyticsQueryResponse.map(value => value?.name)
    : [];

  const getQueriesForEnum = async (request: AnalyticsQueryRequest) => {
    setLoading(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);
      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      const dummyObj = {} as any;
      setAnalyticsQueryResponse([dummyObj]);
      setLoading(false);
    }
  };

  const getParamsForTimePeriod = () => {
    const FIRST_DAY = new Date();
    const CURRENT_YEAR = FIRST_DAY.getFullYear();
    const FIVE_DAY = DateUtility.subtractDays(FIRST_DAY, 4);

    switch (selectedTimePeriod) {
      case TimePeriodEnumForPenal.ONE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date()?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
        };
      case TimePeriodEnumForPenal.FIVE_DAY:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(FIVE_DAY?.toISOString()),
          toDate: DateUtility.formatStringToYYYYMMDD(FIRST_DAY?.toISOString()),
        };
      case TimePeriodEnumForPenal.MTD:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date(FIRST_DAY)?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
          isMonthToDate: 'true',
        };
      case TimePeriodEnumForPenal.YTD:
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            new Date(FIRST_DAY)?.toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(new Date()?.toISOString()),
          isYearToDate: 'true',
        };
      case TimePeriodEnumForPenal.QUATER_1:
      case TimePeriodEnumForPenal.QUATER_2:
      case TimePeriodEnumForPenal.QUATER_3:
      case TimePeriodEnumForPenal.QUATER_4: {
        const quarterNumber = parseInt(selectedTimePeriod.split('_')[1]);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfQuarter(
              CURRENT_YEAR,
              quarterNumber
            ).toISOString()
          ),
        };
      }
      case TimePeriodEnumForPenal.JANUARY:
      case TimePeriodEnumForPenal.FEBRUARY:
      case TimePeriodEnumForPenal.MARCH:
      case TimePeriodEnumForPenal.APRIL:
      case TimePeriodEnumForPenal.MAY:
      case TimePeriodEnumForPenal.JUNE:
      case TimePeriodEnumForPenal.JULY:
      case TimePeriodEnumForPenal.AUGUST:
      case TimePeriodEnumForPenal.SEPTEMBER:
      case TimePeriodEnumForPenal.OCTOBER:
      case TimePeriodEnumForPenal.NOVEMBER:
      case TimePeriodEnumForPenal.DECEMBER: {
        const monthIndex = getMonthIndex(selectedTimePeriod);
        return {
          fromDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getStartOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
          toDate: DateUtility.formatStringToYYYYMMDD(
            DateUtility.getEndOfMonth(CURRENT_YEAR, monthIndex).toISOString()
          ),
        };
      }
      default:
        return null;
    }
  };

  const getParams = () => {
    const timePeriodParams = getParamsForTimePeriod();
    return {
      ...timePeriodParams,
      ...(selectedResolution !== OverallMetricsEnum2.NONE && {
        loanStatus: selectedResolution,
      }),
      ...(selectedMetrics !== BucketEnumForYearAnalytics.OVERALL && {
        bucket: selectedMetrics,
      }),
      type: profitType,
      role: RegionPerformanceRoleEnum.TELE_COLLECTION_EXECUTIVE,
    };
  };
  const getRequest = () => {
    const params = getParams();

    const request = {
      analyticsQueryType:
        selectedTimePeriod === TimePeriodEnumForPenal.YTD
          ? AnalyticsQueryTypeEnum.TELE_AGENT_YEAR_TO_DATE_PERFORMANCE
          : selectedTimePeriod === TimePeriodEnumForPenal.MTD
          ? AnalyticsQueryTypeEnum.TELE_AGENT_MONTH_TO_DATE_PERFORMANCE
          : AnalyticsQueryTypeEnum.TELE_AGENT_PERFORMANCE,
      params,
    } as AnalyticsQueryRequest;
    return request;
  };
  const getQueries = () => {
    const request = getRequest();

    getQueriesForEnum(request);
  };
  useEffect(() => {
    getQueries();
  }, [
    selectedMetrics,
    selectedTimePeriod,
    profitType,
    selectedResolution,
    calenderType,
  ]);

  const rightDowndowns = (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        {calenderType !== CelenderTypeEmun.MONTH && (
          <Grid item>
            <VegaSelect
              autoWidth
              options={getTimePeriodOptions(
                calenderType === CelenderTypeEmun.TODAY,
                calenderType === CelenderTypeEmun.TODAY
                  ? Today_TimePeriodEnum
                  : Year_TimePeriodEnum
              )}
              value={selectedTimePeriod}
              onChange={select =>
                setSelectedTimePeriod(select.target.value as string)
              }
              ignoreLodash
            />
          </Grid>
        )}
        <Grid item>
          <VegaSelect
            ignoreLodash
            autoWidth
            options={getViewOptions(OverallMetricsEnum2)}
            value={selectedResolution}
            onChange={e => setSelectedResolution(e.target.value as string)}
          />
        </Grid>
        <Grid item>
          <VegaSelect
            autoWidth
            options={getOptions(BucketEnumForYearAnalytics)}
            value={selectedMetrics}
            onChange={select =>
              setSelectedMetrics(select.target.value as string)
            }
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Stack gap={1}>
        <GraphWrapper
          title="Tele Agent Performance"
          rightComponent={
            viewType !== GraphTypeEnum.COLLECTION_VOLUME &&
            viewType !== GraphTypeEnum.COLLECTION_VALUE
              ? rightDowndowns
              : null
          }
        >
          {loading ? (
            <LoadingPage height={400} />
          ) : (
            <Stack
              sx={{
                p: 3,
                bgcolor: 'white',
                borderRadius: '12px',
                border: BORDER_COLLECTION,
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -25,
                    height: 50,
                    width: '96.5%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                    }}
                  >
                    {analyticsQueryResponse.map((val, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ flex: '1', textAlign: 'center' }}>
                            {getPercentageValue(val)}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <BarChart
                  categories={regionCategories}
                  series={series}
                  yAxisTitle={
                    calenderType === CelenderTypeEmun.YEAR
                      ? 'In Percentage'
                      : selectedTimePeriod === TimePeriodEnumForPenal.MTD
                      ? 'In Percentage'
                      : 'Rs. Crore'
                  }
                />
              </Box>
            </Stack>
          )}
        </GraphWrapper>
      </Stack>
    </>
  );
};

export default TeleAgentPerformance;
const getViewOptions = (type: AllEnums) => {
  const options = Object.keys(type).map(
    item =>
      ({
        value: type[item],
        label: toLowerCase(item),
      } as VegaSelectOption)
  );
  return options;
};
const getOptions = (type: AllEnums) => {
  const options = Object.values(type).map(
    item =>
      ({
        value: item,
        label: item,
      } as VegaSelectOption)
  );
  return options;
};
const getTimePeriodOptions = (isToday: boolean, type: AllTimePeriodEnums) => {
  if (isToday) {
    const options = Object.keys(type).map(item => {
      return { value: item, label: toLowerCase(item) } as VegaSelectOption;
    });
    return options;
  }
  const options = Object.keys(type).map(item => {
    const year = new Date().getFullYear();
    const label = `${toLowerCase(item)} (${year})`;
    return { value: item, label: label } as VegaSelectOption;
  });
  return options;
};

const getPercentageValue = (query: TeleAgentPerformanceResultDto) => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
        sx={{
          transform: 'rotate(90deg)',
        }}
      />
    );
  }
};
