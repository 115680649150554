import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CollectionType } from '../../../types/incentives';
import {
  BasePolicyRangeProps,
  IncentivePolicyRulesRequest,
} from '../../../types/request/incentives';
import { BucketEnum } from '../../../types/targets';
import { DeleteIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';

interface IBaseIncentiveProps {
  baseIncentive: BasePolicyRangeProps;
  index: number;
  onDelete?: (index: number) => void;
}

const BaseIncentive = ({
  baseIncentive,
  index,
  onDelete,
}: IBaseIncentiveProps) => {
  const { policyRuleState, policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const { policyId, edit } = useParams();

  const handleBaseIncentiveChange = (
    key: keyof BasePolicyRangeProps,
    value: string | number
  ) => {
    const spreadBaseIncentive = [...policyRuleState.basePayoutRange];
    const baseIncentiveObj = {
      ...baseIncentive,
      [key]: value,
    } as BasePolicyRangeProps;
    spreadBaseIncentive.splice(index, 1, baseIncentiveObj);
    const basePoliciyRule = {
      ...policyRuleState,
      basePayoutRange: spreadBaseIncentive,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(basePoliciyRule));
  };
  return (
    <Stack sx={{ borderBottom: BORDER_COLLECTION, py: '1rem' }} gap={2}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && index !== 0 && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
        {edit && index !== 0 && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Stack direction={'row'} gap={1}>
            {policyRuleState.collectionType === CollectionType.RESOLUTION ? (
              <Stack direction={'row'} gap={1}>
                <Stack sx={{ width: '50%' }}>
                  <VegaFormInputField label={'Min Value'}>
                    <LmTextField
                      type="number"
                      placeholder="Min"
                      value={baseIncentive.parameterMin}
                      onChange={e => {
                        const value =
                          e.target.value && parseFloat(e.target.value);
                        handleBaseIncentiveChange('parameterMin', value);
                      }}
                    />
                  </VegaFormInputField>
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <VegaFormInputField label={'Max Value'}>
                    <LmTextField
                      type="number"
                      placeholder="Max"
                      value={baseIncentive.parameterMax}
                      onChange={e => {
                        const value =
                          e.target.value && parseFloat(e.target.value);
                        handleBaseIncentiveChange('parameterMax', value);
                      }}
                    />
                  </VegaFormInputField>
                </Stack>
              </Stack>
            ) : policyRuleState.collectionType ===
                CollectionType.NO_OF_CASES_NORMALIZED &&
              policyDetailsState?.classType === BucketEnum.NPA ? (
              <Stack direction={'row'} gap={1} width={'100%'}>
                <Stack sx={{ width: '33.33%' }}>
                  <VegaFormInputField label={'parameter type'}>
                    <VegaSelect
                      options={parameterTypeOption()}
                      value={baseIncentive.parameterDefinitionType}
                      onChange={e => {
                        const value = e.target.value as string;
                        handleBaseIncentiveChange(
                          'parameterDefinitionType',
                          value
                        );
                      }}
                    />
                  </VegaFormInputField>
                </Stack>

                <Stack
                  sx={{
                    width: '66.66%',
                    display:
                      baseIncentive.parameterDefinitionType !== 'FIXED' &&
                      'none',
                  }}
                >
                  <VegaFormInputField label={'value'}>
                    <LmTextField
                      type="number"
                      placeholder="Max"
                      value={baseIncentive?.parameterExact ?? 0}
                      onChange={e => {
                        const value =
                          e.target.value && parseFloat(e.target.value);
                        handleBaseIncentiveChange('parameterExact', value);
                      }}
                    />
                  </VegaFormInputField>
                </Stack>

                <Stack
                  sx={{
                    width: '66.66%',
                    display:
                      baseIncentive.parameterDefinitionType !== 'RANGE' &&
                      'none',
                  }}
                  direction={'row'}
                  gap={1}
                >
                  <Stack sx={{ width: '50%' }}>
                    <VegaFormInputField label={'min value'}>
                      <LmTextField
                        type="number"
                        placeholder="Min"
                        value={baseIncentive.parameterMin}
                        onChange={e => {
                          const value =
                            e.target.value && parseFloat(e.target.value);
                          handleBaseIncentiveChange('parameterMin', value);
                        }}
                      />
                    </VegaFormInputField>
                  </Stack>
                  <Stack sx={{ width: '50%' }}>
                    <VegaFormInputField label={'max value'}>
                      <LmTextField
                        type="number"
                        placeholder="Max"
                        value={baseIncentive.parameterMax}
                        onChange={e => {
                          const value =
                            e.target.value && parseFloat(e.target.value);
                          handleBaseIncentiveChange('parameterMax', value);
                        }}
                      />
                    </VegaFormInputField>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack direction={'row'} gap={1}>
                <Stack sx={{ width: '50%' }}>
                  <VegaFormInputField label={'Min Value'}>
                    <LmTextField
                      type="number"
                      placeholder="Min"
                      value={baseIncentive?.parameterMin ?? 0}
                      onChange={e => {
                        const value =
                          e.target.value && parseFloat(e.target.value);
                        handleBaseIncentiveChange('parameterMin', value);
                      }}
                    />
                  </VegaFormInputField>
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <VegaFormInputField label={'Max Value'}>
                    <LmTextField
                      type="number"
                      placeholder="Max"
                      value={baseIncentive?.parameterMax ?? 0}
                      onChange={e => {
                        const value =
                          e.target.value && parseFloat(e.target.value);
                        handleBaseIncentiveChange('parameterMax', value);
                      }}
                    />
                  </VegaFormInputField>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField
            label={
              policyRuleState.collectionType ===
                CollectionType.NO_OF_CASES_NORMALIZED &&
              policyDetailsState?.classType === BucketEnum.NPA
                ? '% of pos'
                : 'Incentive Amount'
            }
          >
            <LmTextField
              type="number"
              value={baseIncentive.payoutAmountValue}
              onChange={e => {
                const value = e.target.value && parseFloat(e.target.value);
                handleBaseIncentiveChange('payoutAmountValue', value);
              }}
            />
          </VegaFormInputField>
        </Grid>
        {policyDetailsState?.classType === BucketEnum.NPA && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <VegaFormInputField label={'payout Min'}>
                  <LmTextField
                    type="number"
                    placeholder="Min"
                    value={baseIncentive.payoutAmountMin}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBaseIncentiveChange('payoutAmountMin', value);
                    }}
                  />
                </VegaFormInputField>
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <VegaFormInputField label={'payout Max'}>
                  <LmTextField
                    type="number"
                    placeholder="Max"
                    value={baseIncentive.payoutAmountMax}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBaseIncentiveChange('payoutAmountMax', value);
                    }}
                  />
                </VegaFormInputField>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default BaseIncentive;

const parameterTypeOption = () => {
  const options: VegaSelectOption[] = ['FIXED', 'RANGE'].map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};
