import { GridColumns } from '@mui/x-data-grid';
import VegaButton from '../../../../components/common/VegaButton';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import VegaText from '../../../../components/common/VegaText';
import { COLOR } from '../../../../utils/ColorUtility';
import { WriteOffPolicyPayoutTabs } from '../Admin/WriteOffPolicyPayout';
import { VegaPill } from '../../../../components/common/VegaPill';
import { WriteOffRewardRecordsResponseDto } from '../../../../types/request/incentives';
import { StringUtility } from '../../../../utils/StringUtility';

export enum HocWriteOffPolicyPayoutStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export const HocWriteOffPolicyPayoutColumn = (
  selectedTab: number,
  onAction: (data: WriteOffRewardRecordsResponseDto) => void
) => {
  const COLUMN_DEF: GridColumns<WriteOffRewardRecordsResponseDto> = [
    {
      field: 'lan',
      headerName: 'LAN',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={loan?.loanId ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={loan?.loanId ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={loan.customerName ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={loan.customerName ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'incentiveEarned',
      headerName: 'Incentive Earned',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={StringUtility.formatNumberToIndianNumberFormat(
              loan?.totalLoanIncentiveAmount?.toString() ?? '-'
            )}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={StringUtility.formatNumberToIndianNumberFormat(
                  loan?.totalLoanIncentiveAmount ?? '-'
                )}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'payout',
      headerName: 'Payout',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={StringUtility.formatNumberToIndianNumberFormat(
              loan?.finalLoanIncentiveAmount?.toString() ?? '-'
            )}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={StringUtility.formatNumberToIndianNumberFormat(
                  loan?.finalLoanIncentiveAmount ?? '-'
                )}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      hide: selectedTab === 0,
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaPill
            backgroundColor={getColorForStatus(loan.approvalStatus)?.LIGHT}
            textColor={getColorForStatus(loan.approvalStatus)?.DARK}
            text={loan?.approvalStatus ?? '-'}
            borderColor={getColorForStatus(loan.approvalStatus)?.DARK}
          />
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={loan.remarks ?? '-'}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={loan.remarks ?? '-'}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 150,
      flex: 1,
      renderCell: props => {
        const loan = props.row as WriteOffRewardRecordsResponseDto;
        return (
          <VegaButton
            text={'View Split'}
            variant="text"
            onClick={() => onAction(loan)}
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case WriteOffPolicyPayoutTabs.DRAFT:
      return COLOR.GREY;
    case WriteOffPolicyPayoutTabs.REJECTED:
      return COLOR.RED;
    case WriteOffPolicyPayoutTabs.PENDING:
      return COLOR.ORANGE;
    case 'APPROVED':
      return COLOR.GREEN;
  }
};
