import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { EscalationsDto } from '../../types/escalationsType';
import { LmsVariable } from '../../types/loan';
import { DateUtility } from '../../utils/DateUtlility';
import LmDialog from '../common/LmDialog';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaDialogItem from '../common/VegaDialogItem';
import VegaText from '../common/VegaText';
import { StringUtility } from '../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  escalation?: Partial<EscalationsDto>;
};
function EscalationHistoryDialog({ open, onClose, escalation }: Props) {
  const escalationObj = escalation.escalationObject as LmsVariable;
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => onClose()}
      isCloseIcon
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <div>
          <Grid container gap="115px">
            <Grid item xs={12}>
              <Stack>
                <VegaDialogItem
                  heading={'LAN'}
                  value={escalation?.escalationObjectId}
                />

                <VegaDialogItem
                  heading="Escalation Date"
                  value={DateUtility.formatStringToDDMMYYYY(
                    escalation?.triggeredDate
                  )}
                />
                <VegaDialogItem
                  heading="Bucket"
                  value={escalationObj?.bucket ?? '-'}
                />
                <VegaDialogItem
                  heading="POS"
                  value={StringUtility.formatToINR(
                    escalationObj?.overdueAmount ?? '0'
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <VegaDialogItem
                  heading="Remark"
                  value={escalation?.escalationReason ?? '-'}
                />
                <VegaDialogItem
                  heading="Escalation Level"
                  value={escalation?.escalationLevel ?? '-'}
                />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default EscalationHistoryDialog;
