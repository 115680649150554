import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { toLowerCase } from '../../constants/commonFunction';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { DispositionCodeDataType } from './FieldAppDashboard';

type PropTypes = {
  data: DispositionCodeDataType[];
  loading: boolean;
};

const NoOfVisitsPerDispositionGroup = ({ data, loading }: PropTypes) => {
  const options: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      title: {
        text: 'Disposition Code',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      categories: data.map(item => {
        if (item.dispositionCode === 'INVALID_SWITCH_OFF') {
          return ' Switch Off Invalid';
        }
        return toLowerCase(item.dispositionCode);
      }),
    },
    colors: dispositionColors,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '8px',
        fontWeight: '300',
      },
      formatter: function (val: number) {
        return val;
      },
    },
    yaxis: {
      title: {
        text: 'No of Visits',
        style: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
  };

  const series = [
    {
      name: 'Count',
      data: data.map(item => ({
        x: item.dispositionCode,
        y: item.count,
      })),
    },
  ];

  return (
    <GraphWrapper title="No of Visits per Disposition Group">
      {loading ? (
        <LoadingPage height={350} />
      ) : (
        <Stack sx={{ height: '21.875rem' }}>
          <Chart options={options} series={series} type="line" height={350} />
        </Stack>
      )}
    </GraphWrapper>
  );
};

export default NoOfVisitsPerDispositionGroup;

const dispositionColors: string[] = [
  '#FF5733', // Orange Red
  '#33FF57', // Lime Green
  '#FFD933', // Sandy Brown
  '#3366FF', // Royal Blue
  '#FF33FF', // Hot Pink
  '#00FFFF', // Cyan
  '#FFFFFF', // White
  '#B30000', // Crimson Red
  '#006600', // Dark Green
  '#00B38F', // Dark Turquoise
  '#000066', // Navy Blue
  '#660066', // Medium Purple
  '#666666', // Gray
  '#800000', // Maroon
  '#008080', // Teal
  '#800080', // Purple
  '#004d00', // Forest Green
  '#4d2600', // Chocolate
  '#990000', // Dark Red
  '#C2C2C2', // Silver
  'gold', // Gold
  'blueviolet', // Blue Violet
  '#f00', // Red
  '#0f0', // Green
  '#00f', // Blue
  '#f0f', // Magenta
  '#ff0', // Yellow
  '#FFC0CB', // Light Pink
  '#D2B48C', // Tan
  '#A020F0', // Deep Purple
  '#40E0D0', // Turquoise
  '#F08080', // Light Coral
];
