import { Box, Grid, Stack } from '@mui/material';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import { COMMON } from '../../../constants/style';
import {
  TargetByBucketsProps,
  TargetListProps,
  TargetsStatus,
} from '../../../types/targets';
import TargetBucketStatusComponent from './TargetBucketStatusComponent';
import { ReactNode } from 'react';
import TargetCard from './TargetCard';

interface IBucketCardProps {
  isBucketStatus?: boolean;
  date?: string | Date | null;
  user?: string;
  bucketData: TargetListProps[];
  renderButtons?: (bucket: TargetListProps) => ReactNode;
}

const TargetBucketCard = ({
  isBucketStatus,
  date,
  user,
  bucketData,
  renderButtons,
}: IBucketCardProps) => {
  return (
    <>
      {bucketData.map((bucket: TargetListProps) => (
        <TargetCard key={bucket.id}>
          <>
            {isBucketStatus && (
              <TargetBucketStatusComponent
                date={date}
                user={user}
                status={bucket.targetStatus}
              />
            )}
            <Grid container spacing={2}>
              {bucket.targetByBuckets.map(
                (targetBucket: TargetByBucketsProps, index: number) => (
                  <Grid item xs={12} sm={12} md={6} key={targetBucket.id}>
                    <Box
                      sx={{
                        ...(bucket.targetByBuckets.length !== index + 1 && {
                          borderBottom: '1px solid #E1E4EB',
                        }),
                        pb: '0.5rem',
                      }}
                    >
                      <Stack gap={2}>
                        <VegaText
                          text={targetBucket.bucket}
                          fontWeight={600}
                          fontSize={'0.875rem'}
                          color={COMMON.balck}
                        />
                        <Grid container>
                          <Grid item xs={6} sm={6} md={6} lg={4}>
                            <VegaFormInputField label="POS (in INR cr.)">
                              <VegaText text={targetBucket.posValue} />
                            </VegaFormInputField>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={4}>
                            <VegaFormInputField label="no. of cases">
                              <VegaText text={targetBucket.noOfCases} />
                            </VegaFormInputField>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  </Grid>
                )
              )}
              {(bucket.modifyReason || bucket.rejectedReason) && (
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <Stack gap={2}>
                      <Grid item xs={6} sm={6} md={6} lg={4}>
                        <Stack rowGap={1}>
                          {bucket.modifyReason && (
                            <>
                              <VegaText
                                text={'Modify Target Reason'}
                                fontWeight={600}
                                fontSize={'0.875rem'}
                                color={COMMON.balck}
                              />
                              <VegaText text={bucket.modifyReason} />
                            </>
                          )}
                          {bucket.rejectedReason && (
                            <>
                              <VegaText
                                text={'Rejected Reason'}
                                fontWeight={600}
                                fontSize={'0.875rem'}
                                color={COMMON.balck}
                              />
                              <VegaText text={bucket.rejectedReason} />
                            </>
                          )}
                        </Stack>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              )}
            </Grid>

            {(bucket.targetStatus === TargetsStatus.RAISED ||
              bucket.targetStatus === TargetsStatus.REJECTED) &&
              renderButtons?.(bucket) !== null &&
              renderButtons?.(bucket)}
          </>
        </TargetCard>
      ))}
    </>
  );
};

export default TargetBucketCard;
