import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchCustomerBankById } from '../../Apis/customerBank';
import { useBankCustomer } from '../../providers/CustomerBankProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaDialogDisplayItem from '../common/VegaDialogItem';

const ViewCustomerBranchDetails = () => {
  const { setSnackbar } = useSnackbar();
  const { selectedId, setCustomerBankState, customerBankState } =
    useBankCustomer();
  const [loading, setLoading] = useState<boolean>(false);

  const getCustomerBankDetailsById = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchCustomerBankById(id);
      setCustomerBankState(response);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(
          error,
          'Failed to fetch customer bank details'
        ),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      getCustomerBankDetailsById(selectedId);
    }
  }, [selectedId]);

  return (
    <div>
      <Stack gap={1}>
        <VegaDialogDisplayItem
          heading="Bank Name"
          value={customerBankState?.bankName ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Customer Branch Name"
          value={customerBankState?.branchName ?? ''}
          loading={loading}
        />
        {/* <VegaDialogDisplayItem
          heading="Branch Code"
          value={customerBankState?.branchCode ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="IFSC"
          value={customerBankState?.ifsc ?? ''}
          loading={loading}
        /> */}
        <VegaDialogDisplayItem
          heading="MICR"
          value={customerBankState?.micr ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Created At"
          value={customerBankState?.createdAt ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Last Modified At"
          value={customerBankState?.updatedAt ?? ''}
          loading={loading}
        />
      </Stack>
    </div>
  );
};

export default ViewCustomerBranchDetails;
