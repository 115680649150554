import React, { useEffect, useMemo, useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { Grid, Stack } from '@mui/material';
import { BORDER_COLLECTION } from '../../constants/style';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import { DateUtility } from '../../utils/DateUtlility';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import VegaButton from '../../components/common/VegaButton';
import ListOfRollForwardReport from '../../components/Perfomance/ListOfRollForwardReport';
import {
  FetchLoanListSnapshotRequest,
  LoanListRequest,
} from '../../types/request/loan';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { LoanData } from '../../types/loan';
import { LoanService } from '../../Apis/LoanServices';
import { AllocationTabType2 } from '../LoanAccount/LoanAccounts';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useDebounce } from '../../hooks/useDebounce';
import VegaPageContent from '../../components/common/VegaPageContent';
import LmFilterSection from '../../components/common/LmFilterSection';

type ParamsType = {
  startTime: string;
  endTime: string;
};

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const RollForwardReport = () => {
  const { setSnackbar } = useSnackbar();
  const [loanList, setLoanList] = useState<LoanData[]>([]);
  const [snapshotloanList, setSnapshotLoanList] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [params, setParams] = useState<ParamsType>({
    startTime: TODAY.toISOString(),
    endTime: TOMORROW.toISOString(),
  });

  const debouncedSearch = useDebounce(search);

  const handleDateChange = (key: keyof ParamsType, date: any) => {
    const dateToString = DateUtility.parseDateFromDatePicker(date);

    if (key === 'startTime') {
      let endTime = DateUtility.parseStringToDate(params?.endTime);
      if (endTime && dateToString) {
        if (DateUtility.isBefore(endTime, dateToString)) {
          endTime = DateUtility.addDays(dateToString, 1);
        }
      }
      setParams({
        ...params,
        startTime: dateToString?.toISOString(),
        endTime: endTime?.toISOString(),
      });
    } else {
      setParams({
        ...params,
        [key]: dateToString?.toISOString(),
      });
    }
  };
  const clearFilter = () => {
    setParams({
      startTime: TODAY.toISOString(),
      endTime: TOMORROW.toISOString(),
    });
    getLoanList();
  };

  const listOfRollForwardReport = useMemo(() => {
    return (
      <VegaPageContent>
        <Stack sx={{ marginBottom: '1.5rem' }}>
          <LmFilterSection hideFilter setSearch={setSearch} search={search} />
        </Stack>
        <ListOfRollForwardReport
          page={page}
          size={size}
          setPage={setPage}
          setSize={setSize}
          loanList={loanList}
          totalItems={totalItems}
          loading={loading}
        />
      </VegaPageContent>
    );
  }, [loading, search]);

  const onApplyFilterClick = async () => {
    await getSnapshotLoanList();
    if (page === 0 && size === 10) {
      getLoanList();
    } else {
      setPage(0);
      setSize(10);
    }
  };

  const getSnapshotLoanList = async () => {
    const request: Partial<FetchLoanListSnapshotRequest> = {
      ...params,
      status: 'ROLL_FORWARD',
    };
    const snapshotLoanList = await LoanService.getSnapshotLoanList(request);
    setSnapshotLoanList(snapshotLoanList.loanIds);
  };

  const getLoanList = async () => {
    setLoading(true);
    try {
      const requestForLoan: Partial<LoanListRequest> = {
        loanIdList: snapshotloanList,
        includeCustomerDetails: true,
        page,
        size,
        loanStatus: AllocationTabType2.ROLL_FORWARD,
        ...(search.length && { searchPartialLoanId: debouncedSearch }),
      };
      const response = await LoanService.getLoanList(requestForLoan);
      setLoanList(response.records);
      setTotalItems(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSnapshotLoanList();
  }, []);

  useEffect(() => {
    getLoanList();
  }, [page, size, debouncedSearch]);
  return (
    <>
      <VegaPageHeader title="Roll Forward Report" />
      <VegaPageHeader
        sx={{
          border: 'none',
          marginBottom: '1rem',
        }}
        renderLeftView={() => {
          return (
            <Grid
              container
              sx={{
                border: BORDER_COLLECTION,
                borderRadius: '12px',
                p: 3,
                justifyContent: 'space-between',
              }}
              direction={'row'}
            >
              <Grid container direction={'row'} gap={1} rowGap={2}>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'From'}>
                    <VegaDatePicker
                      value={params?.startTime}
                      onChange={date => handleDateChange('startTime', date)}
                      maxDate={DateUtility.previousDays(
                        new Date(params?.endTime),
                        1
                      )}
                    />
                  </VegaFormInputField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2.4}>
                  <VegaFormInputField label={'To'}>
                    <VegaDatePicker
                      value={params?.endTime}
                      onChange={date => handleDateChange('endTime', date)}
                      minDate={DateUtility.addDays(
                        new Date(params?.startTime),
                        1
                      )}
                    />
                  </VegaFormInputField>
                </Grid>
              </Grid>
              <Grid
                container
                direction={'row'}
                gap={1}
                sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}
              >
                <VegaButton
                  text="Clear Filter"
                  onClick={clearFilter}
                  variant="outlined"
                  sx={{
                    ':hover': {
                      bgcolor: 'transparent',
                      color: '#1047DC',
                    },
                  }}
                />
                <VegaButton text="Apply Filter" onClick={onApplyFilterClick} />
              </Grid>
            </Grid>
          );
        }}
      />
      {listOfRollForwardReport}
    </>
  );
};

export default RollForwardReport;
