/* eslint-disable no-undef */
import axios from 'axios';
import {
  FeedbackRequest,
  GetCallDisposeRequest,
} from '../types/request/webhook';
import { FeedbackResponse, TeleAgentAudit } from '../types/trail';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/webhook',
  CALL_DISPOSE: '/callDispose',
  FEEDBACK: '/feedback',
  LIST: '/list',
};

const CALL_DISPOSE_URL = Endpoint.BASE + Endpoint.CALL_DISPOSE + Endpoint.LIST;
const FEEDBACK_URL = Endpoint.BASE + Endpoint.CALL_DISPOSE + Endpoint.FEEDBACK;

export class WebHookServices {
  static async getCallDisposeList(
    request: Partial<GetCallDisposeRequest>
  ): Promise<PaginatedResponse<TeleAgentAudit>> {
    const endpoint = CALL_DISPOSE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async postFeedback(
    request: Partial<FeedbackRequest>
  ): Promise<PaginatedResponse<TeleAgentAudit>> {
    const endpoint = FEEDBACK_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getFeedbackList(
    request: Partial<FeedbackRequest>
  ): Promise<PaginatedResponse<FeedbackResponse>> {
    const endpoint = FEEDBACK_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
