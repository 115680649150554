import { VegaFileSelectOption } from '../components/common/VegaFileSelect';
import { COLOR } from '../utils/ColorUtility';

export enum ReceiptStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_DEPOSIT = 'PENDING_DEPOSIT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  RAISED = 'RAISED',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  APPROVED_NOT_RECONCILED = 'APPROVED_NOT_RECONCILED',
  APPROVED_AND_RECONCILED = 'APPROVED_AND_RECONCILED',
}
export enum ReceiptCancellationCases {
  DUPLICATE_RECEIPT = 'Duplicate receipt',
  INCORRECT_DETAILS_ENTERED = 'Incorrect details entered',
  RECEIPT_WRONGLY_ISSUED = 'Receipt wrongly issued',
  OTHER = 'Other reasons',
}
export enum ReceiptAction {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  CANCEL = 'CANCEL',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  EDIT = 'EDIT',
}
export enum Relationship {
  FATHER = 'FATHER',
  BROTHER = 'BROTHER',
  SISTER = 'SISTER',
  MOTHER = 'MOTHER',
  SELF = 'SELF',
  STAFF = 'STAFF',
  FRIEND = 'FRIEND',
  RELATIVE = 'RELATIVE',
  SERVANT = 'SERVANT',
  OTHER = 'OTHER',
}
export enum PaymentMode {
  CASH = 'CASH',
  DD = 'DD',
  NEFT = 'NEFT',
  CHEQUE = 'CHEQUE',
  ELECTRONIC_FUND_TRANSFER = 'ELECTRONIC_FUND_TRANSFER',
  // PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  CASH_IN_HAND = 'CASH_IN_HAND',
}
export enum PaymentType {
  EMI = 'EMI',
}

export type ReceiptManagmentViewModel = {
  id: string;
  depositId?: string;
  status: ReceiptStatus;
  receiptDate: string;
  receiptId?: string | null;
  agentName: string;
  lan: string;
  bankNameCode: string;
  paymentMode: string | null;
  proof: string[];
  receiptAmountBreakUp: ReceiptAmountBreakUpDto | null;
};
export type AccountAgentViewModel = {
  id: string;
  name: string;
  email: string;
  product: string;
  emi: number;
  overDue: number;
  total: number;
  allowedAmount: number;
  lan: string;
  pos: number | string;
  ptp: number;
  bounceCharge: number;
  penaltyCharge: number;
  legalCharge: number;
  other: number;
  bucket: string;
  contactNumber: string;
  allocationDate: string;
  address: string;
  segment: string;
};
export type ReceiptsListProps = {
  createdAt: string;
  updatedAt: string;
  id: string;
  agentId: string;
  loanId: string;
  locationImageUrl: string;
  documentImageUrl: string;
  payeeName: string;
  relationshipWithCustomer: string;
  totalAmount: number;
  paymentMeta: {
    paymentMode: string;
    refNumber: string;
    refDate: string;
  };
  receiptAmountBreakUp: ReceiptAmountBreakUpDto | null;
  panCard: string;
  remarks: string;
  status: ReceiptStatus;
  depositId: string | null;
  dateOfDeposit: string | null;
  dateOfApproval: string | null;
  dateOfReceipt: string;
};
interface PaymentMetaBase {
  paymentMode: string;
}
export interface ChequePaymentMode extends PaymentMetaBase {
  chequeNumber: string;
  chequeDate: string;
  // ifscCode: string;
  micr: string;
  bankName: string;
  branchName: string;
}

export interface DemandDraftPaymentMode extends PaymentMetaBase {
  micr: string;
  ddNumber: string;
  ddDate: string;
  // ifscCode: string;
  bankName: string;
  branchName: string;
}

export interface NeftPaymentMode extends PaymentMetaBase {
  refNumber: string;
  refDate: string;
}

export interface ElectronicPaymentMode extends PaymentMetaBase {
  refNumber: string;
  refDate: string;
}

export interface PaymentGatewayPaymentMode extends PaymentMetaBase {
  orderId: string;
  paymentType: CapriPaymentModes;
}

enum CapriPaymentModes {
  PAYMENT_LINK = 'PAYMENT_LINK',
  QR_CODE = 'QR_CODE',
}

type paymentMeta =
  | ChequePaymentMode
  | DemandDraftPaymentMode
  | NeftPaymentMode
  | PaymentGatewayPaymentMode
  | ElectronicPaymentMode;

export type DetailedReceiptsListProps = {
  receiptId: string;
  receiptNumber: string | null;
  agentId: string;
  payeeName: string;
  totalAmount: number;
  paymentMode: string;
  paymentMeta: paymentMeta;
  receiptAmountBreakUp: ReceiptAmountBreakUpDto | null;
  remarks: string;
  rejectionRemarks: string | null;
  status: ReceiptStatus;
  depositRefId: string;
  branch: string;
  region: string;
  agentName: string;
  depositCentre: string;
  depositDate: string;
  receiptDate: string;
  loanId: string;
  depositId: string;
  depositType?: string;
  approvalDate: string;
  approverName: string;
  approverCode: string;
  approverId: string;
  rejectedName: string;
  rejectedBy: string;
};

export interface SendPaymentResponse {
  dateOfReceipt: string;
  updatedAt: string;
  id: string;
  agentId: string;
  loanId: string;
  locationImageUrl: string | null;
  documentImageUrl: string | null;
  payeeName: string;
  relationshipWithCustomer: string;
  totalAmount: number;
  paymentMeta: {
    paymentMode: string;
    orderId: string;
    txnResponse: {
      head: {
        version: string | null;
        timestamp: string;
        channelId: string | null;
        tokenType: string;
        clientId: string | null;
      };
      body: {
        linkId: number;
        linkType: string;
        longUrl: string;
        shortUrl: string;
        amount: number;
        expiryDate: string;
        isActive: boolean;
        merchantHtml: string;
        createdDate: string;
        notificationDetails: {
          customerName: string;
          contact: string;
          notifyStatus: string;
          timestamp: string;
        }[];
        resultInfo: {
          resultStatus: string;
          resultCode: string;
          resultMessage: string;
        };
      };
    };
    paymentType: string;
    id: string;
  };
  paymentMode: string;
  panCard: string | null;
  remarks: string;
  status: string;
  depositId: string | null;
  orderId: string;
  dateOfDeposit: string | null;
  dateOfApproval: string | null;
  mobileNumber: string;
  email: string | null;
  isAutoGenerated: boolean;
}

export interface ReceiptObjDto {
  dateOfReceipt: string;
  updatedAt: string;
  id: string;
  agentId: string;
  agentName: string | null;
  loanId: string;
  locationImageUrl: string | null;
  documentImageUrl: string | null;
  payeeName: string;
  relationshipWithCustomer: string;
  totalAmount: number;
  rejectionReason: string;
  paymentMeta: {
    cashPaymentMeta: {
      denomination: number;
      count: number;
    }[];
    paymentMode: string;
  };
  receiptAmountBreakUp: ReceiptAmountBreakUpDto | null;
  paymentMode: string;
  panCard: string;
  remarks: string;
  status: string;
  depositId: string | null;
  orderId: string | null;
  dateOfDeposit: string | null;
  dateOfApproval: string | null;
  mobileNumber: string | null;
  email: string | null;
  isAutoGenerated: boolean;
}

export interface DepositDto {
  id: string;
  receiptId: string;
  depositCentre: string;
  depositRefId: number;
  depositDate: string;
  depositProofUrls: string[] | null;
  status: string;
  remarks: null;
}

export type DepositReceiptProps = {
  id: string;
  receiptId: string;
  depositCentre: string;
  depositRefId: string;
  depositDate: string;
  depositProofUrl: string;
  status: string;
  remarks: string;
};

export type ElectronicPayment = {
  paymentMode: string;
  refNumber: string;
  refDate: string;
};
export type CashPaymentMeta = {
  denomination: number;
  count: number;
};
export type CashPayment = {
  cashPaymentMeta: CashPaymentMeta[];
  paymentMode: string;
};
export type ChequePayment = {
  chequeNumber: string;
  chequeDate: string;
  // ifscCode: string;
  micr: string;
  bankName: string;
  branchName: string;
  paymentMode: string;
};
export type DDPayment = {
  ddNumber: string;
  ddDate: string;
  // ifscCode: string;
  micr: string;
  bankName: string;
  branchName: string;
  paymentMode: string;
};

export type AnyPaymentType =
  | ElectronicPayment
  | CashPayment
  | ChequePayment
  | DDPayment;

export interface ReceiptAmountBreakUpDto {
  overDueAmount: number;
  bounceCharges: number;
  penalCharges: number;
  legalCharges: number;
  otherCharges: number;
}

export type IssueReceiptProps = {
  agentId: string;
  loanId: string;
  mobileNumber?: string;
  email?: string;
  refNumber?: string;
  documentImage: VegaFileSelectOption | null;
  payeeName: string;
  relationshipWithCustomer: string;
  totalAmount: number;
  paymentMeta: any;
  receiptAmountBreakUp: ReceiptAmountBreakUpDto;
  panCard: string;
  remarks: string;
};
export type ReceiptCancelListProps = {
  receiptNumber: number;
  id: string;
  receiptId: string;
  loanId: string;
  agentId: string;
  approverId: string;
  agentRemark: string;
  approverRemark: string | null;
  requestDate: string;
  status: string;
};

export const getPillColorForStatus = (status: string) => {
  if (!status) return COLOR.ORANGE;
  switch (status) {
    case ReceiptStatus.APPROVED:
    case ReceiptStatus.RAISED:
    case ReceiptStatus.APPROVED_AND_RECONCILED:
      return COLOR.GREEN;
    case ReceiptStatus.PENDING_APPROVAL:
    case ReceiptStatus.APPROVED_NOT_RECONCILED:
    case ReceiptStatus.PENDING_DEPOSIT:
    case ReceiptStatus.PENDING_PAYMENT:
      return COLOR.ORANGE;
    case ReceiptStatus.REJECTED:
    case ReceiptStatus.CANCELLED:
    case ReceiptStatus.CANCELLATION_REQUESTED:
      return COLOR.RED;
  }
};
