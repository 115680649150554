import { Grid, Stack } from '@mui/material';
import { IncentiveSimulatorFinalResponseDto } from '../../../types/incentiveScorecardType';
import { BucketValueDto } from '../../../types/incentives';
import GraphWrapper from '../../IncentivesGamifications/IncentivesGamificationsForMonth/components/GraphWrapper';
import SimulatorGraph from '../components/SimulatorGraph';
export enum SimulateRateType {
  PERCENTAGE = 'Percentage',
  AMOUNT = 'Amount',
}

interface IProps {
  simulatorData: Partial<IncentiveSimulatorFinalResponseDto>;
}

const SimulatorGraphsContainer = ({ simulatorData }: IProps) => {
  const getCheckData = (simulatorDto: BucketValueDto[]) => {
    if (!simulatorData) {
      return [];
    }
    if (!simulatorDto) {
      return [];
    }
    return simulatorDto?.map(policy => policy.value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Stack
            gap={1}
            sx={{
              p: 1,
            }}
          >
            <GraphWrapper title="Overall Coverage">
              <SimulatorGraph
                yAxisTitle={SimulateRateType.PERCENTAGE}
                categories={[
                  'Normal',
                  'X',
                  '1 to 30',
                  '31 to 60',
                  '61 to 90',
                  'NPA',
                ]}
                series={[
                  {
                    name: 'Policy One',
                    data: getCheckData(
                      simulatorData?.overallCoverage?.policyOne
                    ),
                  },
                  {
                    name: 'Policy Two',
                    data: getCheckData(
                      simulatorData?.overallCoverage?.policyTwo
                    ),
                  },
                ]}
              />
            </GraphWrapper>
            <GraphWrapper title="Bumper Coverage">
              <SimulatorGraph
                yAxisTitle={SimulateRateType.PERCENTAGE}
                categories={[
                  'Normal',
                  'X',
                  '1 to 30',
                  '31 to 60',
                  '61 to 90',
                  'NPA',
                ]}
                series={[
                  {
                    name: 'Policy One',
                    data: getCheckData(
                      simulatorData?.bumperCoverage?.policyOne
                    ),
                  },
                  {
                    name: 'Policy Two',
                    data: getCheckData(
                      simulatorData?.bumperCoverage?.policyTwo
                    ),
                  },
                ]}
              />
            </GraphWrapper>
            <GraphWrapper title="Penalty Coverage">
              <SimulatorGraph
                yAxisTitle={SimulateRateType.PERCENTAGE}
                categories={[
                  'Normal',
                  'X',
                  '1 to 30',
                  '31 to 60',
                  '61 to 90',
                  'NPA',
                ]}
                series={[
                  {
                    name: 'Policy One',
                    data: getCheckData(
                      simulatorData?.penaltyCoverage?.policyOne
                    ),
                  },
                  {
                    name: 'Policy Two',
                    data: getCheckData(
                      simulatorData?.penaltyCoverage?.policyTwo
                    ),
                  },
                ]}
              />
            </GraphWrapper>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack
            gap={1}
            sx={{
              p: 1,
            }}
          >
            <GraphWrapper title="Overall Payout">
              <SimulatorGraph
                yAxisTitle={SimulateRateType.AMOUNT}
                categories={[
                  'Normal',
                  'X',
                  '1 to 30',
                  '31 to 60',
                  '61 to 90',
                  'NPA',
                ]}
                series={[
                  {
                    name: 'Policy One',
                    data: getCheckData(simulatorData?.overallPayout?.policyOne),
                  },
                  {
                    name: 'Policy Two',
                    data: getCheckData(simulatorData?.overallPayout?.policyTwo),
                  },
                ]}
              />
            </GraphWrapper>
            <GraphWrapper title="Bumper Payout">
              <SimulatorGraph
                yAxisTitle={SimulateRateType.AMOUNT}
                categories={[
                  'Normal',
                  'X',
                  '1 to 30',
                  '31 to 60',
                  '61 to 90',
                  'NPA',
                ]}
                series={[
                  {
                    name: 'Policy One',
                    data: getCheckData(simulatorData?.bumperPayout?.policyOne),
                  },
                  {
                    name: 'Policy Two',
                    data: getCheckData(simulatorData?.bumperPayout?.policyTwo),
                  },
                ]}
              />
            </GraphWrapper>
            <GraphWrapper title="Penalty Payout">
              <SimulatorGraph
                yAxisTitle={SimulateRateType.AMOUNT}
                categories={[
                  'Normal',
                  'X',
                  '1 to 30',
                  '31 to 60',
                  '61 to 90',
                  'NPA',
                ]}
                series={[
                  {
                    name: 'Policy One',
                    data: getCheckData(simulatorData?.penaltyPayout?.policyOne),
                  },
                  {
                    name: 'Policy Two',
                    data: getCheckData(simulatorData?.penaltyPayout?.policyTwo),
                  },
                ]}
              />
            </GraphWrapper>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default SimulatorGraphsContainer;
