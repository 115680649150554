import { Box, Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import VegaText from '../../../../components/common/VegaText';
import { BORDER_COLLECTION, COMMON } from '../../../../constants/style';
import {
  AnalyticsQueryTypeEnum,
  CelenderTypeEmun,
} from '../../../../features/analyticsDashboardSlice';
import {
  AnalyticsQueryParamsProps,
  AnalyticsQueryRequest,
} from '../../../../types/request/analyticsRequest';
import { GraphTypeEnum, ProfitTypeProps } from '../../AnalyticsDashboard';
import CollectionTeamGraph from '../../components/CollectionTeamGraph';
import RoleBaseSegmentPerformance from '../../components/RoleBaseSegmentPerformance';
import TeleCallersGraph from '../../components/TeleCallersGraph';
import MTDAccountMovmentGraph from './modules/MTDAccountMovmentGraph';
import PenalChargeCollectionGraph from './modules/PenalChargeCollectionGraph';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../router/routes';

interface IProps extends ProfitTypeProps {}

const MonthToDateContainer = ({ selectedCollectionProfitType }: IProps) => {
  const navigate = useNavigate();
  const request = {
    analyticsQueryType: AnalyticsQueryTypeEnum.BUCKET_PERFORMANCE,
    params: {
      isMonthToDate: 'true',
      isYearToDate: 'false',
      type: selectedCollectionProfitType,
    },
  } as AnalyticsQueryRequest;

  const teleCallersGraph = useMemo(() => {
    const updatedParams = {
      ...request.params,
      role: AnalyticsQueryTypeEnum.TELE_COLLECTION_EXECUTIVE,
    } as AnalyticsQueryParamsProps;
    const requestUpdate = {
      ...request,
      params: updatedParams,
    } as AnalyticsQueryRequest;
    return (
      <Box
        sx={{
          transition: 'all 0.3s ease-in-out',
          cursor: 'pointer',
          ':hover': {
            transform: 'scale(1.01)',
          },
        }}
        onDoubleClick={() =>
          navigate(
            ROUTES.ANALYTICS_PARAMS.replace(
              ':viewType',
              GraphTypeEnum.TELE_CALLERS
            )
              .replace(':calenderType', CelenderTypeEmun.MONTH)
              .replace(':profitType', selectedCollectionProfitType)
          )
        }
      >
        <TeleCallersGraph
          request={requestUpdate}
          calenderType={CelenderTypeEmun.MONTH}
          selectedCollectionProfitType={selectedCollectionProfitType}
        />
      </Box>
    );
  }, [selectedCollectionProfitType]);

  const collectionTeamGraph = useMemo(() => {
    const updatedParams = {
      ...request.params,
      role: AnalyticsQueryTypeEnum.CE,
    } as AnalyticsQueryParamsProps;
    const requestUpdate = {
      ...request,
      params: updatedParams,
    } as AnalyticsQueryRequest;
    return (
      <Box
        sx={{
          transition: 'all 0.3s ease-in-out',
          cursor: 'pointer',
          ':hover': {
            transform: 'scale(1.01)',
          },
        }}
        onDoubleClick={() =>
          navigate(
            ROUTES.ANALYTICS_PARAMS.replace(
              ':viewType',
              GraphTypeEnum.COLLECTION_TEAM
            )
              .replace(':calenderType', CelenderTypeEmun.MONTH)
              .replace(':profitType', selectedCollectionProfitType)
          )
        }
      >
        <CollectionTeamGraph
          request={requestUpdate}
          calenderType={CelenderTypeEmun.MONTH}
          selectedCollectionProfitType={selectedCollectionProfitType}
          headerText="Collection Team (in %)"
        />
      </Box>
    );
  }, [selectedCollectionProfitType]);

  const segmentPerformace = useMemo(() => {
    return (
      <Box
        sx={{
          transition: 'all 0.3s ease-in-out',
          cursor: 'pointer',
        }}
        onDoubleClick={() =>
          navigate(
            ROUTES.ANALYTICS_PARAMS.replace(
              ':viewType',
              GraphTypeEnum.ROLE_BASE
            )
              .replace(':calenderType', CelenderTypeEmun.MONTH)
              .replace(':profitType', selectedCollectionProfitType)
          )
        }
      >
        <RoleBaseSegmentPerformance
          calenderType={CelenderTypeEmun.MONTH}
          selectedCollectionProfitType={selectedCollectionProfitType}
        />
      </Box>
    );
  }, [selectedCollectionProfitType]);

  const accountMovmentForMtd = useMemo(() => {
    return (
      <Box
        sx={{
          transition: 'all 0.3s ease-in-out',
          cursor: 'pointer',
          ':hover': {
            transform: 'scale(1.01)',
          },
        }}
        onDoubleClick={() =>
          navigate(
            ROUTES.ANALYTICS_PARAMS.replace(
              ':viewType',
              GraphTypeEnum.ACCOUNT_MOVEMENT
            )
              .replace(':calenderType', CelenderTypeEmun.MONTH)
              .replace(':profitType', selectedCollectionProfitType)
          )
        }
      >
        <MTDAccountMovmentGraph
          selectedCollectionProfitType={selectedCollectionProfitType}
        />
      </Box>
    );
  }, [selectedCollectionProfitType]);

  const penalChargeCollectionGraph = useMemo(() => {
    return (
      <Box
        sx={{
          transition: 'all 0.3s ease-in-out',
          cursor: 'pointer',
          ':hover': {
            transform: 'scale(1.01)',
          },
        }}
        onDoubleClick={() =>
          navigate(
            ROUTES.ANALYTICS_PARAMS.replace(
              ':viewType',
              GraphTypeEnum.PENAL_COLLECTION
            )
              .replace(':calenderType', CelenderTypeEmun.MONTH)
              .replace(':profitType', selectedCollectionProfitType)
          )
        }
      >
        <PenalChargeCollectionGraph
          selectedCollectionProfitType={selectedCollectionProfitType}
        />
      </Box>
    );
  }, [selectedCollectionProfitType]);

  return (
    <>
      <Stack
        sx={{
          p: 3,
          bgcolor: 'white',
          borderRadius: '12px',
          border: BORDER_COLLECTION,
        }}
        gap={2}
      >
        <VegaText
          text={'Month To Date'}
          fontSize={17}
          fontWeight={600}
          color={COMMON.balck}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Stack gap={1}>
              {accountMovmentForMtd}
              {penalChargeCollectionGraph}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4.5}>
            {collectionTeamGraph}
          </Grid>
          <Grid item xs={12} sm={6} md={4.5}>
            {teleCallersGraph}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {segmentPerformace}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default MonthToDateContainer;
