import { Skeleton, Stack } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import { IncentiveServices } from '../../Apis/IncentivesService';
import BucketComponent from '../../components/IncentivesGamifications/modules/BucketComponent';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../components/common/VegaSelect';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import {
  DEFAULT_RULE,
  PayoutPolicyType,
  getIncentivesGamificationsState,
  setPolicyRuleState,
  setSelectedPayoutPolicyType,
  setSelectedTab,
  setTemplatesRules,
} from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  ChargeCollectionType,
  CollectionType,
  PayoutConfiguration,
  TempleteListProps,
} from '../../types/incentives';
import {
  BasePolicyRangeProps,
  IncentivePolicyRulesRequest,
} from '../../types/request/incentives';
import {
  BucketEnum,
  BucketEnumForNPA,
  BucketEnumForNonNPA,
} from '../../types/targets';
import { getErrorMessageFromErrorObj } from '../../utils/api';

interface IProps {
  scrollToTopRef: RefObject<HTMLDivElement>;
}

const BucketRule = ({ scrollToTopRef }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [chargeCollecedTemplate, setChargeCollecedTemplate] = useState<
    BasePolicyRangeProps[]
  >([]);
  const { policyDetailsState, selectedTab, templates, templatesRules } =
    useAppSelector(getIncentivesGamificationsState);

  const dispatch = useAppDispatch();

  const scrollToTop = () => {
    scrollToTopRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const filterBucketRule = (
    rules: Partial<PayoutConfiguration>,
    bucket: string,
    selected: number
  ) => {
    const selectedBucketTab = getKeyFromTab(
      policyDetailsState?.classType,
      selected
    );
    const defaultpolicyRule = {
      ...DEFAULT_RULE,
      collectionType:
        selectedBucketTab === BucketEnumForNonNPA.CHARGES_COLLECTION
          ? ChargeCollectionType.CHARGES_COLLECTED
          : CollectionType.RESOLUTION,
      payoutMetric: 'PER_CASE',
    } as IncentivePolicyRulesRequest;
    if (Object.keys(rules).length !== 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (rules.hasOwnProperty(bucket)) {
        dispatch(setPolicyRuleState(rules[bucket]));
      } else {
        dispatch(setPolicyRuleState(defaultpolicyRule));
      }
    } else {
      dispatch(setPolicyRuleState(defaultpolicyRule));
    }
  };

  const filteredChargeCollectedRule = (
    rules: BasePolicyRangeProps[],
    selected: number
  ) => {
    const selectedBucketTab = getKeyFromTab(
      policyDetailsState?.classType,
      selected
    );
    const defaultpolicyRule = {
      ...DEFAULT_RULE,
      collectionType:
        selectedBucketTab === BucketEnumForNonNPA.CHARGES_COLLECTION
          ? ChargeCollectionType.CHARGES_COLLECTED
          : CollectionType.RESOLUTION,
      payoutMetric: 'PER_CASE',
    } as IncentivePolicyRulesRequest;
    if (rules.length !== 0) {
      dispatch(
        setPolicyRuleState({
          ...defaultpolicyRule,
          chargesCollectedPayoutRange: rules,
        })
      );
    } else {
      dispatch(setPolicyRuleState(defaultpolicyRule));
    }
  };

  const onSelectBucket = (selected: number) => {
    dispatch(setSelectedTab(selected));
    if (templatesRules) {
      if (policyDetailsState?.classType === BucketEnum.NPA) {
        if (selected === 0) {
          filterBucketRule(templatesRules, BucketEnum.NPA, selected);
        } else {
          filteredChargeCollectedRule(chargeCollecedTemplate, selected);
        }
      } else {
        if (selected === 1) {
          filterBucketRule(templatesRules, BucketEnum.BUCKET1, selected);
        } else if (selected === 2) {
          filterBucketRule(templatesRules, BucketEnum.BUCKET2, selected);
        } else if (selected === 3) {
          filterBucketRule(templatesRules, BucketEnum.BUCKET3, selected);
        } else if (selected === 4) {
          filteredChargeCollectedRule(chargeCollecedTemplate, selected);
        } else {
          filterBucketRule(templatesRules, BucketEnum.BUCKETX, selected);
        }
      }
    } else {
      if (policyDetailsState?.classType === BucketEnum.NPA) {
        if (selected === 0) {
          filterBucketRule(policyDetailsState.rules, BucketEnum.NPA, selected);
        } else {
          filteredChargeCollectedRule(
            policyDetailsState.chargeCollectedRules,
            selected
          );
        }
      } else {
        if (selected === 1) {
          filterBucketRule(
            policyDetailsState.rules,
            BucketEnum.BUCKET1,
            selected
          );
        } else if (selected === 2) {
          filterBucketRule(
            policyDetailsState.rules,
            BucketEnum.BUCKET2,
            selected
          );
        } else if (selected === 3) {
          filterBucketRule(
            policyDetailsState.rules,
            BucketEnum.BUCKET3,
            selected
          );
        } else if (selected === 4) {
          filteredChargeCollectedRule(
            policyDetailsState.chargeCollectedRules,
            selected
          );
        } else {
          filterBucketRule(
            policyDetailsState.rules,
            BucketEnum.BUCKETX,
            selected
          );
        }
      }
    }
  };

  const getBucket = () => {
    if (policyDetailsState.classType === BucketEnum.NPA) {
      return BucketEnum.NPA;
    } else {
      if (selectedTab === 1) {
        return BucketEnum.BUCKET1;
      } else if (selectedTab === 2) {
        return BucketEnum.BUCKET2;
      } else if (selectedTab === 3) {
        return BucketEnum.BUCKET3;
      } else {
        return BucketEnum.BUCKETX;
      }
    }
  };

  const getTemplateByTemplateId = () => {
    setTemplateLoading(true);
    IncentiveServices.getTemplate(selectedTemplate)
      .then(res => {
        const rules = res?.rules;
        const chargeCollectedRules = res?.chargeCollectedRules ?? [];
        setChargeCollecedTemplate(chargeCollectedRules);
        if (rules && Object.keys(rules).length) {
          dispatch(setTemplatesRules(rules));
          filterBucketRule(rules, getBucket(), selectedTab);
        }
        setTemplateLoading(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        setTemplateLoading(false);
      });
  };
  useEffect(() => {
    selectedTemplate.length && getTemplateByTemplateId();
  }, [selectedTemplate]);

  return (
    <>
      <VegaPageHeader
        sx={{ paddingTop: 0, borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <Stack
              direction={'row'}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <Stack>
                <VegaTabBar
                  value={selectedTab}
                  onChange={(e, selected) => {
                    onSelectBucket(selected);
                    dispatch(
                      setSelectedPayoutPolicyType(PayoutPolicyType.BASE)
                    );
                  }}
                >
                  {policyDetailsState?.classType === BucketEnum.NPA
                    ? Object.keys(BucketEnumForNPA).map((bucket: string) => (
                        <VegaTabBarItem
                          key={bucket}
                          label={`${getBucketLabel(bucket)}`}
                        />
                      ))
                    : Object.keys(BucketEnumForNonNPA).map((bucket: string) => (
                        <VegaTabBarItem
                          key={bucket}
                          label={`${getBucketLabel(bucket)}`}
                        />
                      ))}
                </VegaTabBar>
              </Stack>
              <Stack width={200}>
                <VegaSelect
                  placeholder="Select template"
                  options={getTempleteOptions(templates)}
                  value={selectedTemplate}
                  onChange={e => setSelectedTemplate(e.target.value as string)}
                />
              </Stack>
            </Stack>
          );
        }}
      />
      {templateLoading ? (
        <VegaPageContent>
          <Skeleton height={500} />
        </VegaPageContent>
      ) : (
        <BucketComponent scrollTop={scrollToTop} />
      )}
    </>
  );
};

export default BucketRule;

const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketEnum.BUCKET1:
      return 'Bucket 1-30';
    case BucketEnum.BUCKET2:
      return 'Bucket 31-60';
    case BucketEnum.BUCKET3:
      return 'Bucket 61-90';
    case BucketEnum.NPA:
      return 'Bucket NPA';
    case 'CHARGES_COLLECTION':
      return 'Charges Collection';

    default:
      return 'Bucket X';
  }
};

const getTempleteOptions = (templates: TempleteListProps[]) => {
  const options = templates.map(
    (template: TempleteListProps) =>
      ({
        label: template.templateName,
        value: template.templateId?.toString(),
      } as VegaSelectOption)
  );
  return options;
};
const getKeyFromTab = (classType: string, tab: number) => {
  if (classType) {
    if (classType === BucketEnum.NPA) {
      const value = Object.keys(BucketEnumForNPA)[tab];
      return value;
    } else {
      const value = Object.keys(BucketEnumForNonNPA)[tab];
      return value;
    }
  }
};
