import { Stack, Grid } from '@mui/material';
import { useOts } from '../../../../providers/OtsProvider';
import { StringUtility } from '../../../../utils/StringUtility';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsAccountDetails() {
  const { ots, loading, loan, linkedAccounts } = useOts();

  const getCustomerName = () => {
    var displayText = '-';
    if (loan?.customerDetails?.length > 0) {
      const firstDetails = loan?.customerDetails[0];
      displayText = StringUtility.concatenateStrings(firstDetails.customerName);
    }
    return displayText;
  };

  return (
    <OtsDetailsWrapper text="Account Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Customer Name"
              value={getCustomerName()}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Lan"
              value={ots?.loanId ?? '-'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Veritical"
              value={loan?.lmsVariables?.segment ?? '-'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Loan Approval Date"
              value={'-'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Tenure Of loan (Months)"
              value={loan?.lmsVariables?.termInMonths ?? '-'}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Disbursal Amount"
              value={StringUtility.formatToINR(
                loan?.loanMaster?.totalDisbursalFixed ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Disbursal Value"
              value={StringUtility.formatToINR(
                loan?.loanMaster?.totalDisbursalMade ?? 0
              )}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Current Valuation"
              value={StringUtility.formatToINR(ots?.valuationAmount)}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Distressed Value"
              value={StringUtility.formatToINR(ots?.distressValue)}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="ROI"
              value={loan?.loanMaster?.roi ?? '-'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Property Type"
              value={'-'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay text="LTV" value={'NA'} loading={loading} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Other Linked Accounts"
              value={linkedAccounts.length > 0 ? 'Yes' : 'No'}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsAccountDetails;
