/* eslint-disable react/jsx-key */
import { Box, Stack, Grid } from '@mui/material';
import LmDialog from '../../../../components/common/LmDialog';
import VegaText from '../../../../components/common/VegaText';
import VegaDialogItem from '../../../../components/common/VegaDialogItem';
import {
  getPropertiesState,
  setOpenPropertyDetailsModal,
} from '../../../../features/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';

const PropertyDetailsModal = () => {
  const { openPropertyDetailsModal, propertyDetails } =
    useAppSelector(getPropertiesState);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setOpenPropertyDetailsModal(false));
  };

  const details = [
    {
      name: 'name',
      value: propertyDetails?.id,
      sm: 7,
    },
    {
      name: 'Property Type',
      value: propertyDetails?.type,
      sm: 5,
    },
    {
      name: 'Property Address',
      value: propertyDetails?.address,
      sm: 7,
    },
  ];
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={openPropertyDetailsModal}
      isCloseIcon
      handleClose={handleClose}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Property details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <Box px={4} pt={3} pb={4}>
        <>
          <VegaText
            text={'Details'}
            fontSize={'0.875rem'}
            fontWeight={600}
            color={'#222'}
            mb={2}
          />
          <Grid container spacing={1}>
            {details.map(detail => {
              return (
                <Grid item xs={12} sm={detail.sm}>
                  <VegaDialogItem heading={detail.name} value={detail.value} />
                </Grid>
              );
            })}
          </Grid>
        </>
        <>
          <VegaText
            text={'Bid Status'}
            fontSize={'0.875rem'}
            fontWeight={600}
            color={'#222'}
            mt={4}
            mb={2}
          />
          <Stack gap="0.5rem">
            <VegaDialogItem
              heading={'Auction Status'}
              value={propertyDetails?.status}
            />
            <VegaDialogItem
              heading={'Total Bidder'}
              value={propertyDetails?.totalBidder}
            />
            <VegaDialogItem
              heading={'Min Raise'}
              value={propertyDetails?.minIncrement}
            />
          </Stack>
        </>
      </Box>
    </LmDialog>
  );
};

export default PropertyDetailsModal;
