import { Box, IconButton, Stack } from '@mui/material';
import React from 'react';
import { VegaPill } from '../../common/VegaPill';
import { COLOR } from '../../../utils/ColorUtility';
import VegaTitleCard from '../../common/VegaTitleCard';
import PolicyDetailsForm from './PolicyDetailsForm';
import NewPolicyForm from './NewPolicyForm';
import { EditIcon } from '../../Icons/Icons';
import { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';

interface IProps {
  selectedPolicies: VegaSelectOption[];
  selectedUsers: string[];
  show: boolean;
  loading: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPolicies: React.Dispatch<React.SetStateAction<VegaSelectOption[]>>;
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

const NewPolicyContainer = ({
  show,
  loading,
  setShow,
  selectedPolicies,
  selectedUsers,
  setSelectedPolicies,
  setSelectedUsers,
}: IProps) => {
  return (
    <>
      {show ? (
        <>
          <Box
            sx={{
              border: '1px solid #E4E4E4',
              borderRadius: '8px',
              marginBottom: '1rem',
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                px: '1.5rem',
                py: '1rem',
                bgcolor: 'white',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
            >
              <VegaText
                text={'Policies that are being replaced'}
                fontWeight={600}
                fontSize={'0.875rem'}
                color={'#676B76'}
              />

              <IconButton onClick={() => setShow(false)}>
                <EditIcon />
              </IconButton>
            </Stack>
            <Box
              sx={{
                p: '1.5rem',
                bgcolor: 'white',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
            >
              <Stack direction={'row'} gap={1}>
                {selectedPolicies.map((policy: VegaSelectOption) => (
                  <VegaPill
                    key={policy.value}
                    text={policy.label}
                    backgroundColor={COLOR.GREEN.LIGHT}
                    textColor={COLOR.GREEN.DARK}
                  />
                ))}
              </Stack>
            </Box>
          </Box>
          <VegaTitleCard title="Policy Details">
            <PolicyDetailsForm loading={loading} />
          </VegaTitleCard>
        </>
      ) : (
        <NewPolicyForm
          selectedUsers={selectedUsers}
          selectedPolicies={selectedPolicies}
          setSelectedPolicies={setSelectedPolicies}
          setSelectedUsers={setSelectedUsers}
        />
      )}
    </>
  );
};

export default NewPolicyContainer;
