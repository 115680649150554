import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AllocationService } from '../../Apis/AllocationService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ExportPenalAndOverallResponseHistoryDto } from '../../types/allocations';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import { useClientAuth } from '../../providers/ClientProvider';
import { DateUtility } from '../../utils/DateUtlility';

const ListOfPenalAndRollBackExecutionHistory = () => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [penalAndRollbackExecutionList, setPenalAndRollbackExecutionList] =
    useState<ExportPenalAndOverallResponseHistoryDto[]>([]);

  const fetchpenalAndRollbackExecutionHistoryData = async () => {
    setLoading(true);
    try {
      const response =
        await AllocationService.getPenalAndRollbackExecutionHistory();
      setPenalAndRollbackExecutionList(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchpenalAndRollbackExecutionHistoryData();
  }, []);

  return (
    <>
      <VegaPageContent>
        <VegaDataGrid
          data={penalAndRollbackExecutionList}
          loading={loading}
          columns={getExecutionColumnDefinition()}
          idColumn="id"
          paginationMode="client"
          pageSize={10}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfPenalAndRollBackExecutionHistory;

const getExecutionColumnDefinition = () => {
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const COL_DEF: GridColumns = [
    {
      field: 'id',
      headerName: 'id',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as ExportPenalAndOverallResponseHistoryDto;
        const display = executionHistory?.id ?? 'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'executeBy',
      headerName: 'Executed By',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as ExportPenalAndOverallResponseHistoryDto;
        const display = getUserName(executionHistory?.executeBy) ?? 'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as ExportPenalAndOverallResponseHistoryDto;
        const display = executionHistory?.status ?? 'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'allocatedLoanCount',
      headerName: 'Allocation Loan Count',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as ExportPenalAndOverallResponseHistoryDto;
        const display = executionHistory?.allocatedLoanCount ?? '0';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as ExportPenalAndOverallResponseHistoryDto;
        const display =
          DateUtility.formatStringToYYYYMMDD(executionHistory?.createdAt) ??
          'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
};
