/* eslint-disable react/prop-types */
import { Close, VisibilityOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { GREY, PRIMARY, RED } from '../../constants/style';
import { useHolidayType } from '../../providers/HolidayTypeProvider';
import { AccessType, IHolidayTypeListProps } from '../../types/holiday';
import { COLOR } from '../../utils/ColorUtility';
import { getDateString } from '../../utils/dayjsUtils';
import { EditIcon, InfoIcon, TrashIcon } from '../Icons/Icons';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaDataGrid from '../common/VegaDataGrid';
import { VegaPill } from '../common/VegaPill';
import VegaText from '../common/VegaText';
import ViewHolidayTypeDetails from './ViewHolidayTypeDetails';
import VegaIconButton from '../common/VegaIconButton';

const ListOfHolidayType = () => {
  const {
    page,
    size,
    listLoading,
    totalItems,
    setPage,
    setSize,
    holidayTypeListState,
    setSelectedHolidayType,
    selectedHolidayType,
    toggleAddHolidayTypeDrawer,
    onDeleteHolidayType,
  } = useHolidayType();

  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);

  const toggleDeleteModal = () => {
    setIsDelete(!isDelete);
  };
  const toggleViewDetailsModal = () => {
    setIsView(!isView);
  };

  const COLUMN_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'createdAt',
      headerName: 'Created On',
      flex: 0.6,
      minWidth: 100,
      renderCell: props => {
        const holidayType = props.row as IHolidayTypeListProps;
        return (
          <VegaText
            text={getDateString(holidayType.createdAt)}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'receiptAuthorization',
      headerName: 'Authorization',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const holidayType = props.row as IHolidayTypeListProps;
        return (
          <VegaPill
            text={holidayType.receiptAuthorization}
            backgroundColor={
              getColorForStatus(holidayType?.receiptAuthorization)?.LIGHT
            }
            key={holidayType.receiptAuthorization}
            textColor={
              getColorForStatus(holidayType?.receiptAuthorization)?.DARK
            }
          />
        );
      },
    },
    {
      field: 'receiptDeposition',
      headerName: 'Deposition',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const holidayType = props.row as IHolidayTypeListProps;
        return (
          <VegaPill
            text={holidayType.receiptDeposition}
            backgroundColor={
              getColorForStatus(holidayType?.receiptDeposition)?.LIGHT
            }
            key={holidayType.receiptDeposition}
            textColor={getColorForStatus(holidayType?.receiptDeposition)?.DARK}
          />
        );
      },
    },
    {
      field: 'receiptIssuance',
      headerName: 'Issuance',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const holidayType = props.row as IHolidayTypeListProps;
        return (
          <VegaPill
            text={holidayType.receiptIssuance}
            backgroundColor={
              getColorForStatus(holidayType?.receiptIssuance)?.LIGHT
            }
            key={holidayType.receiptIssuance}
            textColor={getColorForStatus(holidayType?.receiptIssuance)?.DARK}
          />
        );
      },
    },
    {
      field: 'typeDetails',
      headerName: 'Type Details',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const holidayType = props.row as IHolidayTypeListProps;
        return (
          // <LmButton
          //   text="View Details"
          //   variant="text"
          //   textColor={PRIMARY.darkBlue}
          //   fontWeight={500}
          //   sx={{
          //     px: 0,
          //   }}
          //   fontSize={'0.875rem'}
          //   bgColor="transparant"
          //   onClick={() => viewDetails(holidayType)}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => viewDetails(holidayType)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const holidayType = props.row as IHolidayTypeListProps;

        return (
          <Stack direction={'row'} gap={1}>
            {}
            <LmButton
              text=""
              startIcon={<EditIcon />}
              bgColor="#E7EDFC"
              textColor={PRIMARY.darkBlue}
              onClick={() => {
                setSelectedHolidayType(holidayType);
                toggleAddHolidayTypeDrawer();
              }}
            />
            <LmButton
              text=""
              startIcon={<TrashIcon />}
              onClick={() => {
                setSelectedHolidayType(holidayType);
                setIsDelete(true);
              }}
              bgColor="#F8E8E8"
              textColor={RED.red}
            />
          </Stack>
        );
      },
    },
  ];

  const viewDetails = (id: IHolidayTypeListProps | undefined) => {
    if (id) {
      setSelectedHolidayType(id);
      toggleViewDetailsModal();
    }
  };

  return (
    <>
      <VegaDataGrid
        data={holidayTypeListState.records}
        loading={listLoading}
        columns={COLUMN_DEF}
        idColumn="id"
        paginationMode="server"
        page={page}
        pageSize={size}
        rowCount={totalItems}
        onPageChange={page => setPage(page)}
        onPageSizeChange={size => setSize(size)}
      />
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={isDelete}
        handleClose={toggleDeleteModal}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <LmButton
              fullWidth
              text="Delete"
              bgColor={RED.red}
              startIcon={<TrashIcon strokeColor={'white'} />}
              onClick={async () => {
                await onDeleteHolidayType();
                toggleDeleteModal();
              }}
            />
            <LmButton
              text="Cancel"
              fullWidth
              variant="outlined"
              bgColor=""
              textColor={GREY.dark}
              onClick={() => {
                setSelectedHolidayType(null);
                toggleDeleteModal();
              }}
              startIcon={<Close sx={{ color: GREY.dark }} />}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <InfoIcon />
            <VegaText
              text="Confirmation"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <Box p={4}>
          <VegaText
            text="Are you sure you want to delete this holiday type?"
            fontWeight={600}
            fontSize={17}
          />
          <Typography
            className="font-aspekta-400"
            color={'#999DA8'}
            fontSize={'0.8125rem'}
            lineHeight={'15.6px'}
            mt={1}
          >
            This action cannot be undone. This will permanently delete the{' '}
            <b style={{ color: '#1B1D22' }}>{selectedHolidayType?.name} </b>{' '}
            instance.
          </Typography>
        </Box>
      </LmDialog>

      <LmDialog
        fullWidth
        maxWidth="sm"
        open={isView}
        isCloseIcon
        handleClose={toggleViewDetailsModal}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="View Holiday Type Details"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <Box px={4} pt={3} pb={4}>
          <ViewHolidayTypeDetails />
        </Box>
      </LmDialog>
    </>
  );
};

export default ListOfHolidayType;

const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case AccessType.NON_RESTRICTED:
      return COLOR.GREEN;
    case AccessType.RESTRICTED:
      return COLOR.RED;
  }
};
