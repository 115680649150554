import { ThemeProvider } from '@emotion/react';
import { ReactNode, Suspense } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Login from '../Auth/Login';
import ViewAutoAllocation from '../components/AutoAllocationPolicy/ViewAutoAllocation';
import {
  AgendaCreatorIcon,
  AuctionIcon,
  CalendarSidebarIcon,
  CentralDashboardsIcon,
  ChairPersonIcon,
  ConfigurationIcon,
  EscalationsIcon,
  ExpenseIcon,
  GeoVerificationSidebarIcon,
  HeadphoneIcon,
  HomeIcon,
  IncentivesIcon,
  LeaveManagementIcon,
  LoanIcon,
  MasterIcon,
  NewOtsIcon,
  PerformanceIcon,
  ProgramIcon,
  ReceiptIcon,
  SimulatorIcon,
  UAMIcon,
} from '../components/Icons/Icons';
import ReferToFieldDetailPage from '../components/ReferToField/ReferToFieldDetailPage';
import { getAuth } from '../features/authSlice';
import LmLayout from '../layout/LmLayout';
import AccountDetails from '../pages/Accounts/AccountDetails';
import Accounts from '../pages/Accounts/Accounts';
import AutoAllocationPolicy from '../pages/Allocation/AutoAllocationPolicy';
import BulkAllocation from '../pages/Allocation/BulkAllocation';

import AuctionUsers from '../pages/Auction/AuctionUsers';
import LiveAuctionDetails from '../pages/Auction/LiveAuctionDetails';
import LiveAuctions from '../pages/Auction/LiveAuctions';
import AuctionPossessions from '../pages/Auction/Possession/AuctionPossessions';
import Configurations from '../pages/Configurations/Configurations';
import DepositBank from '../pages/DepositBank';
import Escalations from '../pages/Escalations/Escalations';
import DailyTarget from '../pages/Escalations/modules/DailyTarget';
import ExpenseManagement from '../pages/Escalations/modules/ExpenseManagement';
import LeaveRequest from '../pages/Escalations/modules/LeaveRequest';
import MinimumVisits from '../pages/Escalations/modules/MinimumVisits';
import Ots from '../pages/Escalations/modules/Ots';
import RouteDeviation from '../pages/Escalations/modules/RouteDeviation';
import RouteDeviationViewDetails from '../pages/Escalations/modules/RouteDeviationViewDetails';
import Today from '../pages/Escalations/modules/Today';
import GeoVarification from '../pages/GeoVarification/GeoVarification';
import AdminHomePage from '../pages/Homepage/modules/AdminHomePage';
import AgentHomePage from '../pages/Homepage/modules/AgentHomePage';
import AddOnPolicy from '../pages/IncentivesGamifications/AddOnPolicy';
import IncentivesGamifications from '../pages/IncentivesGamifications/IncentivesGamifications';
import IncentivesGamificationsForMonth from '../pages/IncentivesGamifications/IncentivesGamificationsForMonth/IncentivesGamificationsForMonth';
import LeaderBoard from '../pages/IncentivesGamifications/LeaderBoard/LeaderBoard';
import ScoreCard from '../pages/IncentivesGamifications/ScoreCard/ScoreCard';
import ViewPolicy from '../pages/IncentivesGamifications/ViewPolicy';
import ViewTemplates from '../pages/IncentivesGamifications/ViewTemplates';
import ApplyLeave from '../pages/Leave/ApplyLeave';
import LeaveRequestActions from '../pages/Leave/LeaveRequestActions';
import LmHolidayName from '../pages/LmHolidayName';
import LmHolidayType from '../pages/LmHolidayType';
import LoanAccounts from '../pages/LoanAccount/LoanAccounts';
import MinVisits from '../pages/MinVisits/MinVisits';
import NoAccessPage from '../pages/NoAccess/NoAccessPage';
import Performance from '../pages/Performance/Performance';
import PerformanceSummaryGraph from '../pages/Performance/PerformanceSummaryGraph';
import ReceiptAgent from '../pages/ReceiptManagment/ReceiptAgent';
import ReceiptApprover from '../pages/ReceiptManagment/ReceiptApprover';
import ReceiptCancellation from '../pages/ReceiptManagment/ReceiptCancellation';
import ReceiptIssue from '../pages/ReceiptManagment/ReceiptIssue';
import ReferToFieldPage from '../pages/ReferToField/ReferToFieldPage';
import IncentiveSimulator from '../pages/Simulator/IncentiveSimulator';
import SystemCollection from '../pages/SystemCollection/SystemCollection';
import TargetAdmin from '../pages/Targets/TargetAdmin';
import TargetAgent from '../pages/Targets/TargetAgent';
import TeleAgentAccountDetails from '../pages/TeleAgent/TeleAgentAccountDetails';
import TeleAgentAuto from '../pages/TeleAgent/TeleAgentAuto';
import TeleAgentManual from '../pages/TeleAgent/TeleAgentManual';
import TeleAgentAuditPage from '../pages/TeleAgentAudit/TeleAgentAuditPage';
import TeleBulkAllocation from '../pages/TeleAllocation/TeleBulkAllocation';
import TeleCallingMetrics from '../pages/TeleCallingMetrics/TeleCallingMetrics';
import ViewCallMetricsDetails from '../pages/TeleCallingMetrics/modules/ViewCallMetricsDetails';
import TrailDetailPage from '../pages/Trail/TrailDetailPage';
import TrailPage from '../pages/Trail/TrailPage';
import UAM from '../pages/UAM/UAM';
import UpdateContact from '../pages/UpdateContact/UpdateContact';
import Calender from '../pages/calender/Calender';
import ConveyancePolicy from '../pages/expense_claim/ConveyancePolicy';
import DraftExpenseReport from '../pages/expense_claim/DraftExpenseReport';
import ExpenseClaims from '../pages/expense_claim/ExpenseClaims';
import ExpenseReportHistory from '../pages/expense_claim/ExpenseReportHistory';
import OngoingExpenseReport from '../pages/expense_claim/OngoingExpenseReport';
import SubmittedExpenseReport from '../pages/expense_claim/SubmittedExpenseReport';
import OtsHomePage from '../pages/ots/OtsHomePage';
import AgendaCreatorOtsPage from '../pages/ots/modules/AgendaCreatorOtsPage';
import AgendaDetailsPage from '../pages/ots/modules/AgendaDetailsPage';
import AgentOtsPage from '../pages/ots/modules/AgentOtsPage';
import CommitteChairOtsPage from '../pages/ots/modules/CommitteChairOtsPage';
import CommitteeOtsPage from '../pages/ots/modules/CommitteeOtsPage';
import HocOtsPage from '../pages/ots/modules/HocOtsPage';
import MdOtsPage from '../pages/ots/modules/MdOtsPage';
import NcmOtsPage from '../pages/ots/modules/NcmOtsPage';
import OperationTeamOtsPage from '../pages/ots/modules/OperationTeamOtsPage';
import OtsCustomerAckPage from '../pages/ots/modules/OtsCustomerAckPage';
import OtsMisPage from '../pages/ots/modules/OtsMisPage';
import RcmOtsPage from '../pages/ots/modules/RcmOtsPage';
import TechnicalTeamOtsPage from '../pages/ots/modules/TechnicalTeamOtsPage';
import AgendaCaseDetailsPage from '../pages/ots/pages/AgendaCaseDetailsPage';
import OtsDetailsPage from '../pages/ots/pages/OtsDetailsPage';
import BankDepositProvider from '../providers/BankDepositProvider';
import ClientAuthProvider from '../providers/ClientProvider';
import { ExpenseModuleProvider } from '../providers/ExpenseModuleProvider';
import HolidayNameProvider from '../providers/HolidayNameProvider';
import HolidayTypeProvider from '../providers/HolidayTypeProvider';
import { NotificationProvider } from '../providers/NotificationProvider';
import { OtsProvider } from '../providers/OtsProvider';
import SnackbarProvider from '../providers/SnackbarProvider';
import { TeleAgentCallEventsProvider } from '../providers/TeleAgentCallEventsProvider';
import { useAppSelector } from '../store';
import { appTheme } from '../themes/theme';
import { COLOR } from '../utils/ColorUtility';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { AUTH_ROUTES, ROUTES, SUB_ROUTES, UiModule } from './routes';
import CustomerBank from '../pages/CustomerBank';
import CustomerBankProvider from '../providers/CustomerBankProvider';
import ViewAgentLevel from '../pages/IncentivesGamifications/ViewAgentLevel';
import CustomTarget from '../pages/Targets/CustomTarget';
import PortFolio from '../pages/PortFolio/PortFolio';
import DigitalCollection from '../pages/DigitalCollection/DigitalCollection';
import FieldAppDashboard from '../pages/FieldAppDashboard/FieldAppDashboard';
import CommunicationDetails from '../pages/CommunicationDetails/CommunicationDetails';
import TeleAgents from '../pages/TeleAgent/TeleAgents';
import RollForwardReport from '../pages/Performance/RollForwardReport';
import AnalyticsDashboard from '../pages/AnalyticsDashboard_New/AnalyticsDashboard';
import AnalyticsDetailsPage from '../pages/AnalyticsDashboard_New/AnalyticsDetailsPage';
import BucketViewPolicy_new from '../pages/IncentivesGamifications/BucketViewPolicy_new';
import WriteOffPolicyPayout from '../pages/IncentivesGamifications/WriteOffPolicyPayout/Admin/WriteOffPolicyPayout';
import HocWriteoffPolicyPayout from '../pages/IncentivesGamifications/WriteOffPolicyPayout/Hoc/HocWriteoffPolicyPayout';
import MaxRolePayout from '../pages/IncentivesGamifications/MaxRolePayout/MaxRolePayout';
import OverallPayout from '../pages/IncentivesGamifications/OverallPayout/OverallPayout';
import LocationTracking from '../pages/LocationTracking/LocationTracking';
import ViewTrajectoryDetails from '../pages/LocationTracking/ViewTrajectoryDetails';
export interface LmSidebarListItemType {
  path: string;
  label: string;
  component?: JSX.Element;
  icon?: string | ReactNode;
  activIcon?: string | ReactNode;
  children?: LmSidebarListItemType[];
  routes?: string[];
  module?: UiModule;
}

export const LM_ROUTES: LmSidebarListItemType[] = [
  {
    path: ROUTES.ADMIN_HOME,
    label: 'Home',
    component: <AdminHomePage />,
    icon: <HomeIcon />,
    activIcon: <HomeIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.ADMIN_HOME,
  },
  {
    path: ROUTES.AGENT_HOME,
    label: 'Home',
    component: <AgentHomePage />,
    icon: <HomeIcon />,
    activIcon: <HomeIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.AGENT_HOME,
  },
  {
    path: '',
    label: 'Central Dashboards',
    component: <Outlet />,
    icon: <CentralDashboardsIcon />,
    activIcon: <CentralDashboardsIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.CENTRAL_DASHBOARDS,
    routes: [
      ROUTES.ANALYTICS,
      ROUTES.PORTFOLIO,
      ROUTES.DIGITAL_COLLECTION,
      ROUTES.FIELD_APP_DASHBOARD,
      ROUTES.COMMUNICATION_DETAILS,
    ],
    children: [
      {
        path: ROUTES.ANALYTICS,
        label: 'Analytics',
        component: <AnalyticsDashboard />,
        module: UiModule.ANALYTICS,
      },
      {
        path: ROUTES.PORTFOLIO,
        label: 'Portfolio',
        component: <PortFolio />,
        module: UiModule.PORTFOLIO,
      },
      {
        path: ROUTES.DIGITAL_COLLECTION,
        label: 'Digital Collection',
        component: <DigitalCollection />,
        module: UiModule.DIGITAL_COLLECTION,
      },
      {
        path: ROUTES.FIELD_APP_DASHBOARD,
        label: 'Field App Dashboard',
        component: <FieldAppDashboard />,
        module: UiModule.FIELD_APP_DASHBOARD,
      },
      {
        path: ROUTES.COMMUNICATION_DETAILS,
        label: 'Communication Details',
        component: <CommunicationDetails />,
        module: UiModule.COMMUNICATION_DETAILS,
      },
      {
        path: ROUTES.LOCATION_TRACKING,
        label: 'Location Tracking',
        component: <LocationTracking />,
        module: UiModule.MIN_VISIT_TRACKER,
      },
      {
        path: ROUTES.VIEW_TRAJECTORY_DETAILS,
        label: '',
        component: <ViewTrajectoryDetails />,
        module: UiModule.MIN_VISIT_TRACKER,
      },
    ],
  },
  {
    path: '',
    label: 'Loans',
    component: <Outlet />,
    icon: <LoanIcon />,
    activIcon: <LoanIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.LOANS,
    routes: [
      ROUTES.ACCOUNT,
      ROUTES.ALLOCATION_BULK_ASSIGN,
      ROUTES.ALLOCATION_POLICY,
      ROUTES.TELE_ALLCATION_BULK,
      ROUTES.Trail_Page,
      ROUTES.Trail_Page,
      ROUTES.ACCOUNTS,
      ROUTES.REFER_TO_FIELD,
      ROUTES.SYSTEM_COLLECTION,
    ],
    children: [
      {
        path: ROUTES.ACCOUNT,
        label: 'Loan Accounts',
        component: <LoanAccounts />,
        module: UiModule.ACCOUNT,
      },
      {
        path: ROUTES.ALLOCATION_BULK_ASSIGN,
        label: 'Bulk Allocation',
        component: <BulkAllocation />,
        module: UiModule.ALLOCATION_BULK_ASSIGN,
      },
      {
        path: ROUTES.ALLOCATION_POLICY,
        label: 'Auto Allocation Policy',
        component: <AutoAllocationPolicy />,
        module: UiModule.ALLOCATION_POLICY,
      },
      {
        path: ROUTES.TELE_ALLCATION_BULK,
        label: 'Tele Configuration',
        component: <TeleBulkAllocation />,
        module: UiModule.TELE_ALLCATION_BULK,
      },
      {
        path: ROUTES.Trail_Page,
        label: 'Trail',
        component: <TrailPage />,
        module: UiModule.Trail_Page,
      },
      {
        path: ROUTES.REFER_TO_FIELD,
        label: 'Field Reference',
        component: <ReferToFieldPage />,
        module: UiModule.REFER_TO_FIELD,
      },
      {
        path: ROUTES.Refer_To_Field_Detail_Page,
        label: '',
        component: <ReferToFieldDetailPage />,
        module: UiModule.REFER_TO_FIELD,
      },
      {
        path: ROUTES.ACCOUNTS,
        label: 'Accounts',
        component: <Accounts />,
        module: UiModule.ACCOUNTS,
      },
      {
        path: ROUTES.SYSTEM_COLLECTION,
        label: 'System Collection',
        component: <SystemCollection />,
        module: UiModule.SYSTEM_COLLECTION,
      },
      {
        path: ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID,
        label: '',
        component: <AccountDetails />,
        module: UiModule.ACCOUNT,
      },
      {
        path: ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID,
        label: '',
        component: <AccountDetails />,
        module: UiModule.ACCOUNTS,
      },
      {
        path: ROUTES.ALLOCATION_POLICY_VIEW,
        label: '',
        component: <ViewAutoAllocation />,
        module: UiModule.ALLOCATION_POLICY,
      },
    ],
  },
  {
    path: '',
    label: 'Master',
    component: <Outlet />,
    icon: <MasterIcon />,
    activIcon: <MasterIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.MASTER,
    routes: [
      SUB_ROUTES.HOLIDAY_TYPE,
      SUB_ROUTES.HOLIDAY_NAME,
      ROUTES.CUSTOMER_BRANCH_INFO,
      ROUTES.DEPOSIT_BANK,
      ROUTES.MIN_NO_OF_VISITS,
    ],
    children: [
      {
        path: SUB_ROUTES.HOLIDAY_TYPE,
        label: 'Holiday Type',
        module: UiModule.HOLIDAY_TYPE,
        component: (
          <HolidayTypeProvider>
            <LmHolidayType />
          </HolidayTypeProvider>
        ),
      },
      {
        path: SUB_ROUTES.HOLIDAY_NAME,
        label: 'Holiday Name',
        module: UiModule.HOLIDAY_NAME,
        component: (
          <HolidayTypeProvider>
            <HolidayNameProvider>
              <LmHolidayName />
            </HolidayNameProvider>
          </HolidayTypeProvider>
        ),
      },
      {
        path: ROUTES.CUSTOMER_BRANCH_INFO,
        label: 'Customer Branch Info',
        module: UiModule.CUSTOMER_BRANCH_INFO,
        component: (
          <CustomerBankProvider>
            <CustomerBank />
          </CustomerBankProvider>
        ),
      },
      {
        path: ROUTES.DEPOSIT_BANK,
        label: 'Bank Deposit',
        module: UiModule.DEPOSIT_BANK,
        component: (
          <BankDepositProvider>
            <DepositBank />
          </BankDepositProvider>
        ),
      },
      {
        path: ROUTES.MIN_NO_OF_VISITS,
        module: UiModule.MIN_NO_OF_VISITS,
        label: 'Min. no. of Visits',
        component: <MinVisits />,
      },
    ],
  },
  {
    path: '',
    label: 'Leave Management',
    component: <Outlet />,
    icon: <LeaveManagementIcon />,
    activIcon: <LeaveManagementIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    routes: [ROUTES.LEAVE_APPLY, ROUTES.LEAVE_REQUEST_ACTIONS],
    module: UiModule.LEAVE_MANAGEMENT,
    children: [
      {
        path: ROUTES.LEAVE_APPLY,
        label: 'Apply Leave',
        component: <ApplyLeave />,
        module: UiModule.LEAVE_APPLY,
      },
      {
        path: ROUTES.LEAVE_REQUEST_ACTIONS,
        label: 'Approve Leave',
        component: <LeaveRequestActions />,
        module: UiModule.LEAVE_REQUEST_ACTIONS,
      },
    ],
  },
  {
    path: '',
    label: 'Program Dashboard',
    component: <Outlet />,
    icon: <ProgramIcon />,
    activIcon: <ProgramIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    routes: [ROUTES.MONTHLY_PROGRESS, ROUTES.TARGETS],
    module: UiModule.PROGRAM_DASHBORAD,
    children: [
      {
        path: ROUTES.TARGETS,
        label: 'Targets',
        component: <TargetAgent />,
        module: UiModule.TARGETS,
      },
      {
        path: ROUTES.TARGETS_ADMIN,
        label: 'Targets Approve',
        component: <TargetAdmin />,
        module: UiModule.TARGETS_ADMIN,
      },
      {
        path: ROUTES.CUSTOM_TARGETS,
        label: 'Custom Targets',
        component: <CustomTarget />,
        module: UiModule.TARGETS_ADMIN,
      },
    ],
  },
  {
    path: '',
    label: 'Expense Claims Agent',
    module: UiModule.EXPENSE_CLAIM_AGENT,
    component: (
      <ExpenseModuleProvider>
        <Outlet />
      </ExpenseModuleProvider>
    ),
    icon: <ExpenseIcon />,
    activIcon: <ExpenseIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    children: [
      {
        path: SUB_ROUTES.EXPENSE_REPORT_DRAFT,
        label: 'Draft Report',
        component: <DraftExpenseReport />,
        module: UiModule.EXPENSE_REPORT_DRAFT,
      },
      {
        path: SUB_ROUTES.EXPENSE_REPORT_SUBMITTED,
        label: 'Submitted Report',
        component: <SubmittedExpenseReport />,
        module: UiModule.EXPENSE_REPORT_SUBMITTED,
      },
    ],
  },
  {
    path: SUB_ROUTES.EXPENSE_REPORT_HISTORY_BY_ID,
    label: '',
    component: <ExpenseClaims />,
    module: UiModule.EXPENSE_CLAIM_AGENT,
  },
  {
    path: '',
    label: 'Expense Claims Admin',
    module: UiModule.EXPENSE_CLAIM_ADMIN,
    component: (
      <ExpenseModuleProvider>
        <Outlet />
      </ExpenseModuleProvider>
    ),
    icon: <ExpenseIcon />,
    activIcon: <ExpenseIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    children: [
      {
        path: SUB_ROUTES.EXPENSE_REPORT_ONGOING,
        label: 'Ongoing',
        component: <OngoingExpenseReport />,
        module: UiModule.EXPENSE_REPORT_ONGOING,
      },
      {
        path: SUB_ROUTES.EXPENSE_REPORT_HISTORY,
        label: 'History',
        component: <ExpenseReportHistory />,
        module: UiModule.EXPENSE_REPORT_HISTORY,
      },

      // <Route path={ROUTES.CLAIMS} element={<ExpenseClaims />} />
    ],
  },
  {
    path: SUB_ROUTES.EXPENSE_REPORT_HISTORY_BY_ID,
    label: '',
    component: <ExpenseClaims />,
    module: UiModule.EXPENSE_CLAIM_ADMIN,
  },
  {
    path: ROUTES.CONVEYANCE_POLICY,
    label: '',
    component: <ConveyancePolicy />,
    module: UiModule.EXPENSE_CLAIM_ADMIN,
  },
  //   <Route
  //   path={ROUTES.CONVEYANCE_POLICY}
  //   element={<ConveyancePolicy />}
  // />
  {
    path: '',
    label: 'Auction',
    component: <Outlet />,
    icon: <AuctionIcon strokeColor={COLOR.SIDEBAR.DEFAULT} />,
    activIcon: <AuctionIcon strokeColor={COLOR.SIDEBAR.DEFAULT} />,
    module: UiModule.AUCTION,
    children: [
      {
        path: ROUTES.AUCTION_USERS,
        label: 'Users',
        component: <AuctionUsers />,
        module: UiModule.AUCTION_USERS,
      },
      {
        path: ROUTES.AUCTION_PROPERTIES,
        label: 'Auction Properties',
        component: <AuctionPossessions />,
        module: UiModule.AUCTION_PROPERTIES,
      },
      {
        path: ROUTES.LIVE_AUCTIONS,
        label: 'Live Auctions',
        component: <LiveAuctions />,
        module: UiModule.LIVE_AUCTIONS,
      },
    ],
  },
  {
    path: '',
    label: 'Receipt Management',
    component: <Outlet />,
    icon: <ReceiptIcon />,
    activIcon: <ReceiptIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.RECEIPT_MANAGEMENT,
    routes: [
      ROUTES.RECEIPT_APPROVER,
      ROUTES.RECEIPT_AGENT,
      ROUTES.RECEIPT_CANCELLATION,
    ],
    children: [
      {
        path: ROUTES.RECEIPT_APPROVER,
        label: 'Receipt Approver',
        component: <ReceiptApprover />,
        module: UiModule.RECEIPT_APPROVER,
      },
      {
        path: ROUTES.RECEIPT_AGENT,
        label: 'Receipt Agent',
        component: <ReceiptAgent />,
        module: UiModule.RECEIPT_AGENT,
      },
      {
        path: ROUTES.RECEIPT_CANCELLATION,
        label: 'Receipt Cancellation',
        component: <ReceiptCancellation />,
        module: UiModule.RECEIPT_CANCELLATION,
      },
      {
        path: ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT,
        label: '',
        component: <ReceiptIssue />,
        module: UiModule.RECEIPT_AGENT,
      },
    ],
  },
  {
    path: ROUTES.CONFIGURATION,
    label: 'Configurations',
    component: <Configurations />,
    icon: <ConfigurationIcon />,
    activIcon: <ConfigurationIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.CONFIGURATIONS,
  },
  {
    path: '',
    label: 'Tele Agent',
    component: <Outlet />,
    icon: <HeadphoneIcon />,
    module: UiModule.TELE_AGENT,
    activIcon: <HeadphoneIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    routes: [
      ROUTES.TELE_AGENT_MANUAL,
      ROUTES.TELE_AGENT_AUTO,
      ROUTES.TELE_CALLING_METRICS,
      ROUTES.TELE_AGENTS_LIST,
    ],
    children: [
      {
        path: ROUTES.TELE_AGENTS_LIST,
        label: 'Create Agent',
        component: <TeleAgents />,
        module: UiModule.TELE_AGENT_CREATE,
      },
      {
        path: ROUTES.TELE_AGENT_AUDIT,
        label: 'Audit',
        component: <TeleAgentAuditPage />,
        module: UiModule.TELE_AGENT_AUDIT,
      },
      {
        path: ROUTES.TELE_AGENT_AUDIT_HISTORY,
        label: '',
        component: <TeleAgentAuditPage />,
        module: UiModule.TELE_AGENT_AUDIT_HISTORY,
      },
      {
        path: ROUTES.TELE_AGENT_MANUAL,
        label: 'Manual Call',
        component: <TeleAgentManual />,
        module: UiModule.TELE_AGENT_MANUAL,
      },
      {
        path: ROUTES.TELE_AGENT_AUTO,
        label: 'Auto Call',
        component: <TeleAgentAuto />,
        module: UiModule.TELE_AGENT_AUTO,
      },
      {
        path: ROUTES.TELE_CALLING_METRICS,
        label: 'Tele Calling Metrics',
        component: <TeleCallingMetrics />,
        module: UiModule.TELE_CALLING_METRICS,
      },
      {
        path: ROUTES.TELE_CALLING_METRICS_VIEW,
        label: '',
        component: <ViewCallMetricsDetails />,
        module: UiModule.TELE_CALLING_METRICS,
      },
    ],
  },
  {
    path: ROUTES.TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID,
    label: '',
    component: <TeleAgentAccountDetails />,
    module: UiModule.TELE_AGENT,
  },
  {
    path: ROUTES.UAM,
    label: 'UAM',
    component: <UAM />,
    icon: <UAMIcon />,
    activIcon: <UAMIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.UAM,
  },
  {
    path: '',
    label: 'Incentives & Gamifications',
    icon: <IncentivesIcon />,
    component: <Outlet />,
    activIcon: <IncentivesIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.INCENTIVES_GAMIFICATIONS,
    routes: [
      ROUTES.INCENTIVES_GAMIFICATIONS_POLICY,
      ROUTES.INCENTIVES_GAMIFICATIONS_ADD,
      ROUTES.INCENTIVES_GAMIFICATIONS_EDIT,
      ROUTES.INCENTIVES_GAMIFICATIONS_VIEW,
      ROUTES.TEMPLATE_VIEW,
      ROUTES.INCENTIVES_GAMIFICATIONS_FOR_MONTH,
      ROUTES.INCENTIVES_GAMIFICATIONS_LEADERBOARD,
      ROUTES.INCENTIVES_GAMIFICATIONS_SCORE_CARD,
    ],
    children: [
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_POLICY,
        label: 'Incentive Policy',
        component: <IncentivesGamifications />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_FOR_MONTH,
        label: 'Incentive For Month',
        component: <IncentivesGamificationsForMonth />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_LEADERBOARD,
        label: 'Leaderboard',
        component: <LeaderBoard />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_SCORE_CARD,
        label: 'Score Card',
        component: <ScoreCard />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVE_WRITEOFF_PAYOUT_ADMIN,
        label: 'Write-off Policy Payout',
        component: <WriteOffPolicyPayout />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVE_WRITEOFF_PAYOUT_HOC,
        label: 'Write-off Policy Payout',
        component: <HocWriteoffPolicyPayout />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVE_MAX_ROLE_PAYOUT,
        label: 'Role Max Payout',
        component: <MaxRolePayout />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVE_OVERALL_PAYOUT_ADMIN,
        label: 'Over All Payout',
        component: <OverallPayout isHocView={false} />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVE_OVERALL_PAYOUT_HOC,
        label: 'Over All Payout (HOC)',
        component: <OverallPayout isHocView={true} />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_ADD,
        label: '',
        component: <AddOnPolicy />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_EDIT,
        label: '',
        component: <AddOnPolicy />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_VIEW,
        label: '',
        component: <ViewPolicy />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.TEMPLATE_VIEW,
        label: '',
        component: <ViewTemplates />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
      {
        path: ROUTES.INCENTIVES_GAMIFICATIONS_VIEW_AGENT_LEVEL,
        label: '',
        component: <ViewAgentLevel />,
        module: UiModule.INCENTIVES_GAMIFICATIONS,
      },
    ],
  },
  {
    path: '',
    label: 'Performance ',
    component: <Outlet />,

    icon: <PerformanceIcon />,
    module: UiModule.PERFOMANCE,
    activIcon: <PerformanceIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    routes: [ROUTES.PERFOMANCE, ROUTES.PERFOMANCE_SUMMARY],
    children: [
      {
        path: ROUTES.PERFOMANCE,
        label: 'Dashboard',
        component: <Performance />,
        module: UiModule.PERFOMANCE,
      },

      {
        path: ROUTES.PERFOMANCE_SUMMARY,
        label: 'Summary',
        component: <PerformanceSummaryGraph />,
        module: UiModule.PERFOMANCE_SUMMARY,
      },
      {
        path: ROUTES.ROLL_FORWARD_REPORT,
        label: 'Roll Forward Report',
        component: <RollForwardReport />,
        module: UiModule.PERFOMANCE,
      },
    ],
  },

  {
    path: ROUTES.CALENDER,
    label: 'Calendar',
    component: <Calender />,
    icon: <CalendarSidebarIcon />,
    activIcon: <CalendarSidebarIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.CALENDER,
  },
  {
    path: ROUTES.GEO_VERIFICATION,
    label: 'Geo Verification',
    component: <GeoVarification />,
    icon: <GeoVerificationSidebarIcon />,
    activIcon: (
      <GeoVerificationSidebarIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />
    ),
    module: UiModule.GEO_VERIFICATION,
  },

  {
    path: ROUTES.ESCALATIONS,
    label: 'Escalations',
    component: <Escalations />,
    module: UiModule.ESCALATIONS,
    icon: <EscalationsIcon />,
    activIcon: <EscalationsIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    routes: [
      ROUTES.ESCALATIONS_TODAY,
      ROUTES.ESCALATIONS_LEAVE_REQUEST,
      ROUTES.ESCALATIONS_DAILY_TARGET,
      ROUTES.ESCALATIONS_MINIMUM_VISITS,
      ROUTES.ESCALATIONS_ROUTE_DEVIATION,
      ROUTES.ESCALATIONS_EXPENSE_MANAGEMENT,
      ROUTES.ESCALATIONS_OTS,
    ],
  },
  {
    path: ROUTES.ESCALATIONS_TODAY,
    label: '',
    component: <Today />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ESCALATIONS_LEAVE_REQUEST,
    label: '',
    component: <LeaveRequest />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ESCALATIONS_DAILY_TARGET,
    label: '',
    component: <DailyTarget />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ESCALATIONS_MINIMUM_VISITS,
    label: '',
    component: <MinimumVisits />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ESCALATIONS_ROUTE_DEVIATION,
    label: '',
    component: <RouteDeviation />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ESCALATIONS_EXPENSE_MANAGEMENT,
    label: '',
    component: <ExpenseManagement />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ESCALATIONS_OTS,
    label: '',
    component: <Ots />,
    module: UiModule.ESCALATIONS,
  },

  {
    path: ROUTES.ESCALATIONS_ROUTE_DEVIATION_VIEW_BY_ID,
    label: '',
    component: <RouteDeviationViewDetails />,
    module: UiModule.ESCALATIONS,
  },
  {
    path: ROUTES.ANALYTICS_PARAMS,
    label: '',
    component: <AnalyticsDetailsPage />,
    module: UiModule.ANALYTICS,
  },

  {
    path: '',
    label: 'OTS',
    component: <Outlet />,
    icon: <NewOtsIcon />,
    activIcon: <NewOtsIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.OTS,
    routes: [
      ROUTES.OTS_HOME,
      SUB_ROUTES.OTS_AGENT,
      SUB_ROUTES.OTS_RCM,
      SUB_ROUTES.OTS_NCM,
      SUB_ROUTES.OTS_HOC,
      SUB_ROUTES.OTS_OPERATION_TEAM,
      SUB_ROUTES.OTS_TECHNICAL_TEAM,
    ],
    children: [
      {
        path: ROUTES.OTS_HOME,
        label: 'Home',
        component: <OtsHomePage />,
        module: UiModule.OTS_HOME,
      },
      {
        path: SUB_ROUTES.OTS_AGENT,
        label: 'Agent',
        component: <AgentOtsPage />,
        module: UiModule.OTS_AGENT,
      },
      {
        path: SUB_ROUTES.OTS_RCM,
        label: 'RCM',
        component: <RcmOtsPage />,
        module: UiModule.OTS_RCM,
      },
      {
        path: SUB_ROUTES.OTS_NCM,
        label: 'NCM',
        component: <NcmOtsPage />,
        module: UiModule.OTS_NCM,
      },
      {
        path: SUB_ROUTES.OTS_HOC,
        label: 'HOC',
        component: <HocOtsPage />,
        module: UiModule.OTS_HOC,
      },
      {
        path: SUB_ROUTES.OTS_OPERATION_TEAM,
        label: 'Operation Team',
        component: <OperationTeamOtsPage />,
        module: UiModule.OTS_OPERATION_TEAM,
      },
      {
        path: SUB_ROUTES.OTS_TECHNICAL_TEAM,
        label: 'Technical Team',
        component: <TechnicalTeamOtsPage />,
        module: UiModule.OTS_TECHNICAL_TEAM,
      },
      {
        path: SUB_ROUTES.OTS_MD,
        label: 'MD',
        component: <MdOtsPage />,
        module: UiModule.OTS_MD,
      },
      {
        path: SUB_ROUTES.OTS_MIS,
        label: 'MIS',
        component: <OtsMisPage />,
        module: UiModule.OTS_MIS,
      },
    ],
  },
  {
    path: '',
    label: 'Agenda Creator',
    component: <Outlet />,
    icon: <AgendaCreatorIcon />,
    module: UiModule.AGENDA_CREATOR,
    activIcon: <AgendaCreatorIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    children: [
      {
        path: ROUTES.OTS_AGENDA_CREATOR_PENDING,
        label: 'Pending Agenda',
        component: <AgendaCreatorOtsPage />,
        module: UiModule.OTS_AGENDA_CREATOR_PENDING,
      },
      {
        path: ROUTES.OTS_AGENDA_CREATOR_COMMITTEE,
        label: 'Committee',
        component: <CommitteeOtsPage />,
        module: UiModule.OTS_AGENDA_CREATOR_COMMITTEE,
      },
    ],
  },
  {
    path: ROUTES.OTS_AGENDA_CREATOR_COMMITTEE_AGENT_ID,
    label: '',
    component: <AgendaDetailsPage />,
    module: UiModule.AGENDA_CREATOR,
  },
  {
    path: ROUTES.OTS_CHAIR,
    label: 'Chair',
    component: <CommitteChairOtsPage />,
    icon: <ChairPersonIcon />,
    activIcon: <ChairPersonIcon strokeColor={COLOR.SIDEBAR.ACTIVE} />,
    module: UiModule.OTS_CHAIR,
  },
  {
    path: ROUTES.OTS_DETAILS,
    label: '',
    module: UiModule.OTS_DETAILS,
    component: (
      <OtsProvider>
        <OtsDetailsPage />
      </OtsProvider>
    ),
  },
  {
    path: ROUTES.AGENDA_CASE_DETAILS,
    label: '',
    module: UiModule.AGENDA_CASE_DETAILS,
    component: (
      <OtsProvider>
        <AgendaCaseDetailsPage />
      </OtsProvider>
    ),
  },
  {
    activIcon: <SimulatorIcon />,
    path: ROUTES.INCENTIVE_SIMULATOR,
    label: 'Simulator',
    component: <IncentiveSimulator />,
    icon: <SimulatorIcon />,
    module: UiModule.INCENTIVE_SIMULATOR,
  },
];

export const AUTHENTICATION_ROUTES: LmSidebarListItemType[] = [
  {
    path: AUTH_ROUTES.LOGIN,
    label: 'Login',
    component: <Login />,
    module: UiModule.LOGIN,
  },
];

const AppRouter: React.FC = () => {
  const { routes } = useAppSelector(getAuth);

  return (
    <LmLayout>
      <Suspense fallback={<h1>loading....</h1>}>
        <Routes>
          {/* <Route path={ROUTES.CLAIMS} element={<ExpenseClaims />} /> */}
          {/* <Route path={ROUTES.AUCTION_USERS} element={<AuctionUsers />} /> */}
          <Route
            path={ROUTES.LIVE_AUCTION_DETAILS}
            element={<LiveAuctionDetails />}
          />
          <Route
            path={ROUTES.Trail_Detail_Page}
            element={<TrailDetailPage />}
          />

          {routes.map(
            (route: LmSidebarListItemType) =>
              route.component && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                >
                  {route.children &&
                    route.children.map(
                      childRoute =>
                        childRoute.component && (
                          <Route
                            key={childRoute.path}
                            path={childRoute.path}
                            element={childRoute.component}
                          />
                        )
                    )}
                </Route>
              )
          )}
          <Route path={ROUTES.NO_ACCESS} element={<NoAccessPage />} />
          <Route path={'*'} element={<NoAccessPage />} />
        </Routes>
      </Suspense>
    </LmLayout>
  );
};

const AuthRouter: React.FC = () => {
  return (
    <Suspense fallback={<h1>loading....</h1>}>
      <Routes>
        <Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
      </Routes>
    </Suspense>
  );
};
const UpdateContactRouter: React.FC = () => {
  return (
    <Suspense fallback={<h1>loading....</h1>}>
      <Routes>
        <Route path={'/updateContact'} element={<UpdateContact />} />
        <Route path={'/ack-letter'} element={<OtsCustomerAckPage />} />
        <Route path={'/incentive-view'} element={<BucketViewPolicy_new />} />
      </Routes>
    </Suspense>
  );
};

const Router: React.FC = () => {
  const pathname = window.location.pathname;
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <ClientAuthProvider>
          <NotificationProvider>
            <TeleAgentCallEventsProvider>
              <ThemeProvider theme={appTheme}>
                {pathname === '/updateContact' ||
                pathname == '/ack-letter' ||
                pathname == '/incentive-view' ? (
                  <UpdateContactRouter />
                ) : (
                  <>
                    <PublicRoutes>
                      <AuthRouter />
                    </PublicRoutes>
                    <PrivateRoutes>
                      <AppRouter />
                    </PrivateRoutes>
                  </>
                )}
              </ThemeProvider>
            </TeleAgentCallEventsProvider>
          </NotificationProvider>
        </ClientAuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default Router;
