import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Collapse, Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { PossessionService } from '../../Apis/PossessionService';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmDialog from './LmDialog';
import VegaDialogContent from './VegaDialogContent';
import VegaImagePreview, {
  VegaImagePreviewViewState,
} from './VegaImagePreview';
import VegaText from './VegaText';

type Props = {
  open: boolean;
  onClose: () => void;
  images: string[];
  lan: string;
};

function VegaImagePreviewDialog({ open, onClose, lan }: Props) {
  const { setSnackbar } = useSnackbar();
  const [images, setImages] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewState, setViewState] = useState<VegaImagePreviewViewState>(
    VegaImagePreviewViewState.ImageList
  );

  function onImageClick(index: number) {
    setSelectedImageIndex(index);
    setViewState(VegaImagePreviewViewState.SingleImage);
  }

  function onNextClick() {
    setSelectedImageIndex(prev => prev + 1);
  }

  function onPrevClick() {
    setSelectedImageIndex(prev => prev - 1);
  }

  const getImagesByLoanId = async (loanId: string) => {
    setLoading(true);
    try {
      const images = await PossessionService.fetchImagesByLoanId(loanId);
      setImages(images.imageUrls);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    lan && getImagesByLoanId(lan);
  }, [lan]);

  return (
    <LmDialog
      fullWidth
      handleClose={onClose}
      open={open}
      isCloseIcon
      maxWidth="sm"
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <Collapse in={viewState == VegaImagePreviewViewState.SingleImage}>
            <IconButton
              onClick={() => setViewState(VegaImagePreviewViewState.ImageList)}
              style={{
                display:
                  viewState == VegaImagePreviewViewState.ImageList
                    ? 'none'
                    : 'inherit',
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Collapse>
          <VegaText
            text="Image Preview"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        {loading ? (
          <LoadingPage height={500} />
        ) : images.length ? (
          <Stack spacing={'1rem'}>
            <Grid container alignItems="center">
              <Collapse in={viewState == VegaImagePreviewViewState.SingleImage}>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      onPrevClick();
                    }}
                    disabled={selectedImageIndex <= 0}
                  >
                    <ChevronLeft />
                  </IconButton>
                </Grid>
              </Collapse>
              <Grid
                item
                xs={viewState == VegaImagePreviewViewState.ImageList ? 12 : 10}
              >
                <VegaImagePreview
                  images={images}
                  onImageSelect={function (index: number): void {
                    onImageClick(index);
                  }}
                  currentIndex={selectedImageIndex}
                  viewState={viewState}
                />
              </Grid>
              <Collapse in={viewState == VegaImagePreviewViewState.SingleImage}>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      onNextClick();
                    }}
                    disabled={selectedImageIndex >= images.length - 1}
                  >
                    <ChevronRight />
                  </IconButton>
                </Grid>
              </Collapse>
            </Grid>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent={'flex-end'}
            >
              <VegaText text={'Total Images ' + images.length} />
            </Stack>
          </Stack>
        ) : (
          <Stack
            gap={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <VegaText
              text={'No Images !!'}
              fontSize={20}
              fontColor="black"
              fontWeight={600}
            />
          </Stack>
        )}
      </VegaDialogContent>
    </LmDialog>
  );
}

export default VegaImagePreviewDialog;
