import React, { useState } from 'react';
import LmDialog from '../common/LmDialog';
import { Grid, Stack } from '@mui/material';
import VegaButton from '../common/VegaButton';
import VegaText from '../common/VegaText';
import VegaDialogContent from '../common/VegaDialogContent';
import {
  AgentBelowAcrLimitType,
  ExecuteCriteriaResponse,
} from '../../types/allocations';
import { AllocationService } from '../../Apis/AllocationService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';

type IProps = {
  open: boolean;
  onClose: () => void;
  executedCriteriaData: ExecuteCriteriaResponse;
};

const ExecutedCriteriaDialog = ({
  open,
  onClose,
  executedCriteriaData,
}: IProps) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const onResolve = async () => {
    setLoading(true);
    try {
      await AllocationService.resolveAllocation({
        acrResponse: executedCriteriaData.acrResponse,
        agentBelowAcrLimit: executedCriteriaData.agentBelowAcrLimit,
        unAllocatedLoans: executedCriteriaData.unAllocatedLoans,
      });
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  return (
    <>
      <LmDialog
        fullWidth
        maxWidth="md"
        open={open}
        handleClose={onClose}
        buttonComponent={() => (
          <>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'end'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="Resolve"
                onClick={onResolve}
                loading={loading}
              />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Conflict has occurred"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          {executedCriteriaData ? (
            <>
              <Stack gap={2}>
                <VegaText
                  text={`List Of LAN`}
                  fontWeight={600}
                  fontSize={'1.0625rem'}
                  color={'black'}
                />
                <Stack
                  sx={{
                    py: 2,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{ height: 200, overflow: 'auto' }}
                  >
                    {executedCriteriaData.unAllocatedLoans?.length
                      ? executedCriteriaData.unAllocatedLoans.map(
                          (unAllocatedLoan: string, index: number) => (
                            <Grid key={unAllocatedLoan} item xs={3}>
                              <Stack direction={'row'} gap={1}>
                                <VegaText
                                  text={`${index + 1}.`}
                                  fontWeight={400}
                                  fontSize={'14px'}
                                  color={'black'}
                                />
                                <VegaText
                                  text={unAllocatedLoan}
                                  fontWeight={400}
                                  fontSize={'14px'}
                                  color={'black'}
                                />
                              </Stack>
                            </Grid>
                          )
                        )
                      : []}
                  </Grid>
                </Stack>
              </Stack>
              <Stack gap={2}>
                <VegaText
                  text={`Alternate Agent`}
                  fontWeight={600}
                  fontSize={'1.0625rem'}
                  color={'black'}
                />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <VegaText
                      text={'Agent Name'}
                      fontWeight={600}
                      fontSize={'14px'}
                      color={'black'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <VegaText
                      text={'ACR'}
                      fontWeight={600}
                      fontSize={'14px'}
                      color={'black'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {executedCriteriaData?.agentBelowAcrLimit?.length &&
                    executedCriteriaData?.agentBelowAcrLimit.map(
                      (item: AgentBelowAcrLimitType) => (
                        <>
                          <Grid item xs={4}>
                            <VegaText
                              text={item.agentName}
                              fontWeight={400}
                              fontSize={'14px'}
                              color={'black'}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <VegaText
                              text={item.count}
                              fontWeight={400}
                              fontSize={'14px'}
                              color={'black'}
                            />
                          </Grid>
                        </>
                      )
                    )}
                </Grid>
              </Stack>
            </>
          ) : (
            []
          )}
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default ExecutedCriteriaDialog;
