import { AddTask, Close, VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { PRIMARY } from '../../../constants/style';
import { EscalationsDto } from '../../../types/escalationsType';
import { LmsVariable } from '../../../types/loan';
import { EscalationStatus } from '../../../types/request/escalationsRequest';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import VegaIconButton from '../../common/VegaIconButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';

interface IColumnProps {
  selectedTab: number;
  onApproveDialogOpen?: (escalations: EscalationsDto) => void;
  onRejectDialogOpen?: (escalations: EscalationsDto) => void;
  onViewDetailsClick?: (escalations: EscalationsDto) => void;
}

export const TodayEscalationColumn = ({
  selectedTab,
  onApproveDialogOpen,
  onRejectDialogOpen,
  onViewDetailsClick,
}: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'userName',
      headerName: 'Agent Name',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const userName = escalations?.userName ? escalations?.userName : '--';
        return (
          <VegaText text={userName} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'lan',
      headerName: 'Loan Account Number',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const loanId = escalations.escalationObjectId ?? '--';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
              justifyContent: 'flex-start',
            }}
            tooltipTitle={loanId}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={loanId}
              />
            }
          />
        );
      },
    },
    {
      field: 'escalationDate',
      headerName: 'Escalation Date',
      flex: 0.7,
      minWidth: 120,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const escalationDate = escalations.triggeredDate
          ? DateUtility.formatStringToDDMMMMYYYY(escalations.triggeredDate)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={escalationDate}
          />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.5,
      minWidth: 100,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        var diplayText = '--';
        const escalationObj = escalations.escalationObject as LmsVariable;
        if (escalationObj) {
          diplayText = escalationObj.bucket;
        }

        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={diplayText}
          />
        );
      },
    },
    {
      field: 'pos',
      headerName: 'POS',
      flex: 0.5,
      minWidth: 100,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        var diplayText = '--';
        const escalationObj = escalations.escalationObject as LmsVariable;
        //TODO: TO use Principal Outstanding when available
        if (escalationObj) {
          diplayText = StringUtility.formatToINR(
            escalationObj?.outstandingAmount ?? 0
          );
        }
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={diplayText}
          />
        );
      },
    },
    {
      field: 'ptpDate',
      headerName: 'Revisit Date',
      flex: 0.5,
      minWidth: 150,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        var diplayText = '--';
        const escalationObj = escalations.escalationObject as LmsVariable;
        if (escalationObj) {
          diplayText = escalationObj?.nextActionDate
            ? DateUtility.formatStringToDDMMMMYYYY(escalationObj.nextActionDate)
            : '--';
        }
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={diplayText}
          />
        );
      },
    },
    {
      field: 'escalationReason',
      headerName: 'Reason / Remark',
      flex: 0.5,
      minWidth: 200,
      renderCell: props => {
        const escalations = props.row as EscalationsDto;
        const escalationReason = escalations?.escalationReason
          ? escalations.escalationReason
          : '--';

        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={escalationReason}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={escalationReason}
              />
            }
          />
        );
      },
    },

    {
      field: 'escalationLevel',
      headerName: 'Escalation Level',
      flex: 0.7,
      minWidth: 120,
      renderCell: props => {
        const todayEscalation = props.row as EscalationsDto;
        return (
          <VegaPill
            text={todayEscalation.escalationLevel}
            backgroundColor={
              getColorForLevel(todayEscalation.escalationLevel)?.LIGHT
            }
            textColor={getColorForLevel(todayEscalation.escalationLevel)?.DARK}
            borderColor={
              getColorForLevel(todayEscalation.escalationLevel)?.DARK
            }
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      hide: selectedTab === 1,
      minWidth: selectedTab === 0 ? 250 : 100,
      renderCell: props => {
        const todayEscalation = props.row as EscalationsDto;
        return selectedTab === 0 ? (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="Approve"
              sx={{ bgcolor: '#E7EDFC' }}
              icon={<AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />}
              onClick={() => {
                onApproveDialogOpen(todayEscalation);
              }}
            />
            <VegaIconButton
              tooltipTitle="Reject"
              onClick={() => {
                onRejectDialogOpen(todayEscalation);
              }}
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
            />
          </Stack>
        ) : (
          // <VegaButton variant="text" text="View Details" />
          ''
        );
      },
    },
    {
      field: 'status',
      headerName: 'status',
      flex: 1,
      hide: selectedTab === 0,
      renderCell: props => {
        const todayEscalation = props.row as EscalationsDto;

        return (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <VegaPill
              text={getColorForStatus(todayEscalation.escalationStatus)?.text}
              backgroundColor={
                getColorForStatus(todayEscalation.escalationStatus)?.color
                  ?.LIGHT
              }
              textColor={
                getColorForStatus(todayEscalation.escalationStatus)?.color?.DARK
              }
              borderColor={
                getColorForStatus(todayEscalation.escalationStatus)?.color?.DARK
              }
            />
            {/* <VegaButton
              variant="text"
              text="View Details"
              onClick={() => onViewDetailsClick(todayEscalation)}
            /> */}
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onViewDetailsClick(todayEscalation)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getColorForLevel = (status?: string) => {
  if (!status) return;
  switch (status) {
    case 'LEVEL_1':
      return COLOR.GREEN;

    case 'LEVEL_2':
      return COLOR.ORANGE;
    case 'LEVEL_3':
      return COLOR.RED;
  }
};
const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case EscalationStatus.RESOLVED:
      return {
        text: 'APPROVED',
        color: COLOR.GREEN,
      };
    case EscalationStatus.RAISED:
      return {
        text: 'ON-ACTION',
        color: COLOR.GREEN,
      };

    case EscalationStatus.ESCALATED:
      return {
        text: EscalationStatus.ESCALATED,
        color: COLOR.ORANGE,
      };
    case EscalationStatus.REJECTED:
      return {
        text: EscalationStatus.REJECTED,
        color: COLOR.RED,
      };
  }
};
