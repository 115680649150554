/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { fetchHolidayTypeList } from '../../Apis/holiday';
import { IHolidayTypeListProps } from '../../types/holiday';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

interface IProps {
  selected: string | number;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
}

const HolidayTypeSelector = ({
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const [holidayTypeList, setHolidayTypeList] = useState<VegaSelectOption[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== holidayTypeList.length) {
        setPage(page + 1);
      }
    }
  };

  const getAreaBranchList = async () => {
    try {
      const response = await fetchHolidayTypeList({ page, size: 10 });
      const newArr = response?.records?.map(
        (holidayType: IHolidayTypeListProps) => {
          return {
            value: holidayType.id,
            label: holidayType.name,
          } as VegaSelectOption;
        }
      );

      if (page === 0) {
        setHolidayTypeList(newArr);
      } else {
        setHolidayTypeList([...holidayTypeList, ...newArr]);
      }
      setTotalItem(response.totalItems);
    } catch (error) {}
  };

  useEffect(() => {
    getAreaBranchList();
  }, [page]);
  return (
    <>
      <VegaSelect
        ignoreLodash
        placeholder={label ?? 'Select Branch'}
        options={holidayTypeList}
        onChange={e => handleChange(e.target.value)}
        onScroll={loadMoreItems}
        value={selected}
        disabled={disabled}
      />
    </>
  );
};

export default HolidayTypeSelector;
