import dayjs from 'dayjs';
import { toLowerCase } from '../../constants/commonFunction';
import {
  IncentivePolicyProps,
  ViewIncentiesGamificationsModal,
} from '../../types/incentives';
import {
  agentLevelList,
  getPolicies,
  listOfTempletes,
  policyEnums,
} from './incentivesAsyncThunk';

export const incentivesExtraReducers = builder => {
  builder

    .addCase(getPolicies.pending, state => {
      state.loading = true;
      state.policies = [];
      state.error = null;
    })
    .addCase(getPolicies.fulfilled, (state, action) => {
      const policies = [...action.payload.records];
      const policiesViewModal = policies.map(
        (policy: IncentivePolicyProps) =>
          ({
            id: policy.id,
            policyName: policy.policyName,
            vertical: policy.vertical,
            channelType: toLowerCase(policy.channelType),
            userType: policy.userType,
            classType: policy?.classType,
            payoutFrequency: toLowerCase(policy.payoutFrequency),
            startDate: dayjs(policy.policyStartDate).format('D MMMM YYYY'),
            endDate: dayjs(policy.policyEndDate).format('D MMMM YYYY'),
            status: policy.status,
            approvalStatus: policy?.approvalStatus,
            remarks: policy?.remarks,
            reason: policy?.reason,
          } as ViewIncentiesGamificationsModal)
      );
      state.loading = false;
      state.policies = policiesViewModal;
      state.totalItems = action.payload.totalItems;
    })
    .addCase(getPolicies.rejected, (state, action) => {
      state.loading = false;
      state.policies = [];
      state.totalItems = 0;
      state.error = action.error.message;
    })
    .addCase(listOfTempletes.pending, state => {
      state.loading = true;
      state.templates = [];
      state.error = null;
    })
    .addCase(listOfTempletes.fulfilled, (state, action) => {
      const templates = [...action.payload];

      state.loading = false;
      state.templates = templates;
    })
    .addCase(listOfTempletes.rejected, (state, action) => {
      state.loading = false;
      state.templates = [];
      state.error = action.error.message;
    })
    .addCase(policyEnums.pending, state => {
      state.loading = true;
      state.policyEnumValues = {};
      state.error = null;
    })
    .addCase(policyEnums.fulfilled, (state, action) => {
      const policyEnumValues = { ...action.payload };

      state.loading = false;
      state.policyEnumValues = policyEnumValues;
    })
    .addCase(policyEnums.rejected, (state, action) => {
      state.loading = false;
      state.policyEnumValues = {};
      state.error = action.error.message;
    })
    .addCase(agentLevelList.pending, state => {
      state.loadingForViewAgentLevel = true;
      state.viewAgentLevelData = [];
      state.errorForViewAgentLevel = null;
    })
    .addCase(agentLevelList.fulfilled, (state, action) => {
      const viewAgentLevelData = action.payload;
      const response = viewAgentLevelData.records.length
        ? viewAgentLevelData.records.map((data, index) => ({
            ...data,
            id: index,
          }))
        : [];

      state.loadingForViewAgentLevel = false;
      state.viewAgentLevelData = response;
      state.totalItemsForViewAgentLevel = viewAgentLevelData.totalItems;
    })
    .addCase(agentLevelList.rejected, (state, action) => {
      state.loadingForViewAgentLevel = false;
      state.viewAgentLevelData = [];
      state.errorForViewAgentLevel = action.error.message;
    });
};
