import axios from 'axios';
import {
  DepositDto,
  DepositReceiptProps,
  DetailedReceiptsListProps,
  IssueReceiptProps,
  ReceiptCancelListProps,
  ReceiptManagmentViewModel,
  ReceiptObjDto,
  ReceiptsListProps,
  SendPaymentResponse,
} from '../types/receiptManagment';
import {
  CancelReceiptRequest,
  CraeteDepositRequest,
  GetReceiptsCancelListRequest,
  GetReceiptsCancelStatusRequest,
  GetReceiptsListRequest,
  SendPaymentLinkRequest,
  UpdateReceiptCancellationStatusRequest,
  UpdateReceiptParams,
} from '../types/request/receiptManagment';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/receipting',
  RECEIPT_BY_ID: '/receipting/:receiptId',
  DEPOSIT_BY_ID: '/deposit/:depositId',
  RECEIPT: '/receipt/:receiptId',
  LIST: '/list',
  DETAILED_LIST: '/list/detailed',
  DEPOSIT_STATUS: '/deposit/status',
  PAYMENT_LINK: '/payments/link',
  PAYMENT_STATUS: '/payments/status/orderId',
  CANCEL_REQUEST: '/cancel/request',
  CANCEL_STATUS: '/approve',
  CREATE_DEPOSIT: '/deposit',
  CANCEL_RECEIPT: '/cancel/request',
  EXPORT_RECEIPT: '/export',
  DEPOSIT: '/deposit',
  CASH_IN_HAND: '/cih',
  RECEIPT_TAB_COUNT: '/count',
};

export class ReceiptManagmentService {
  static async getReceiptsList(
    request: Partial<GetReceiptsListRequest>
  ): Promise<PaginatedResponse<ReceiptsListProps>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getReceiptByReceiptId(
    receiptId: string
  ): Promise<ReceiptObjDto> {
    const endpoint = Endpoint.RECEIPT_BY_ID.replace(':receiptId', receiptId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getDepositByDepositId(depositId: string): Promise<DepositDto> {
    const endpoint =
      Endpoint.BASE + Endpoint.DEPOSIT_BY_ID.replace(':depositId', depositId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getDetailedReceiptsList(
    request: Partial<GetReceiptsListRequest>
  ): Promise<PaginatedResponse<DetailedReceiptsListProps>> {
    const endpoint = Endpoint.BASE + Endpoint.DETAILED_LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async createSendPaymentLink(
    request: Partial<SendPaymentLinkRequest>
  ): Promise<SendPaymentResponse> {
    const endpoint = Endpoint.BASE + Endpoint.PAYMENT_LINK;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getPaymentStatusByOrderId(orderId: string): Promise<string> {
    const endpoint =
      Endpoint.BASE + Endpoint.PAYMENT_STATUS.replace('orderId', orderId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async createReceipt(
    request: Partial<IssueReceiptProps>
  ): Promise<DepositReceiptProps> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const endpoint = Endpoint.BASE;
    const amountString = request.totalAmount.toString();
    const parsePaymentMeta = JSON.stringify(request.paymentMeta);
    const parseReceiptAmountBreakUp = JSON.stringify(
      request.receiptAmountBreakUp
    );
    const formData = new FormData();
    if (request.email) {
      formData.append('email', request.email);
    }
    if (request.documentImage) {
      formData.append('documentImage', request.documentImage.value);
    }
    if (request.refNumber) {
      formData.append('refNumber', request.refNumber);
    }
    formData.append('agentId', request.agentId);
    formData.append('loanId', request.loanId);
    formData.append('mobileNumber', request.mobileNumber);

    formData.append('payeeName', request.payeeName);
    formData.append(
      'relationshipWithCustomer',
      request.relationshipWithCustomer
    );
    formData.append('totalAmount', amountString);
    formData.append('paymentMeta', parsePaymentMeta);
    formData.append('receiptAmountBreakUp', parseReceiptAmountBreakUp);
    formData.append('panCard', request.panCard);
    formData.append('remarks', request.remarks);
    const response = await axios.post(endpoint, formData, config);
    return response.data;
  }

  static async updateStatusForReceipt(
    request: Partial<UpdateReceiptParams>
  ): Promise<DepositReceiptProps> {
    const endpoint = Endpoint.BASE + Endpoint.DEPOSIT_STATUS;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
  static async updateReceiptCancellationStatus(
    request: Partial<UpdateReceiptCancellationStatusRequest>
  ): Promise<DepositReceiptProps> {
    const endpoint =
      Endpoint.BASE + Endpoint.CANCEL_REQUEST + Endpoint.CANCEL_STATUS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createDeposit(
    request: Partial<CraeteDepositRequest>
  ): Promise<DepositReceiptProps> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const endpoint = Endpoint.BASE + Endpoint.CREATE_DEPOSIT;
    const formData = new FormData();

    if (request.depositProof) {
      formData.append('depositProof', request.depositProof);
    }
    if (request.depositCentre) {
      formData.append('depositCentre', request.depositCentre);
    }
    formData.append('receiptId', request.receiptId);
    if (request.depositRefId) {
      formData.append('depositRefId', request.depositRefId);
    }
    if (request.depositType) {
      formData.append('depositType', request.depositType);
    }

    const response = await axios.post(endpoint, formData, config);
    return response.data;
  }

  static async cancelReceipt(request: Partial<CancelReceiptRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.CANCEL_RECEIPT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async downloadReceiptPDF(receiptId: string): Promise<string> {
    const endpoint =
      Endpoint.BASE + Endpoint.RECEIPT.replace(':receiptId', receiptId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  //////////////// receipt cancellation \\\\\\\\\\\\\\\\
  static async getReceiptsCancellationList(
    request: Partial<GetReceiptsCancelListRequest>
  ): Promise<PaginatedResponse<ReceiptCancelListProps>> {
    const endpoint = Endpoint.BASE + Endpoint.CANCEL_REQUEST + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateStatusReceiptCancellation(
    request: Partial<GetReceiptsCancelStatusRequest>
  ): Promise<PaginatedResponse<ReceiptCancelListProps>> {
    const endpoint =
      Endpoint.BASE + Endpoint.CANCEL_REQUEST + Endpoint.CANCEL_STATUS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getReceiptExportData(request: Partial<GetReceiptsListRequest>) {
    const endPoint =
      Endpoint.BASE + Endpoint.DETAILED_LIST + Endpoint.EXPORT_RECEIPT;
    const response = await axios.get(endPoint, { params: request });
    const { data } = response;
    return data;
  }
  static async updateStatus(request: Partial<CraeteDepositRequest>) {
    const endPoint = Endpoint.BASE + Endpoint.DEPOSIT + Endpoint.CASH_IN_HAND;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    if (request.depositProof) {
      formData.append('depositProof', request.depositProof);
    }
    if (request.depositCentre) {
      formData.append('depositCentre', request.depositCentre);
    }
    if (request.depositId) {
      formData.append('depositId', request.depositId);
    }
    const response = await axios.put(endPoint, formData, config);
    return response.data;
  }
  static async getReceiptTabCount() {
    const endpoint = Endpoint.BASE + Endpoint.RECEIPT_TAB_COUNT;
    const response = await axios.post(endpoint);
    return response.data;
  }
}
