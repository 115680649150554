/* eslint-disable prefer-const */
import { Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import GraphWrapper from '../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../LoadingPage/LoadingPage';
import { ComunicationDetailsType } from './CommunicationDetails';

interface IProps {
  comunicationDetailsQueryResponse: ComunicationDetailsType[];
  loading: boolean;
}

const ExecutedDistributionGraph = ({
  comunicationDetailsQueryResponse,
  loading,
}: IProps) => {
  const yearSeries = [
    {
      name: 'IVR',
      data: comunicationDetailsQueryResponse.map(item => item.channelCount.IVR),
    },
    {
      name: 'WhatsApp',
      data: comunicationDetailsQueryResponse.map(
        item => item.channelCount.WHATSAPP
      ),
    },
    {
      name: 'SMS',
      data: comunicationDetailsQueryResponse.map(item => item.channelCount.SMS),
    },
    {
      name: 'Email',
      data: comunicationDetailsQueryResponse.map(
        item => item.channelCount.EMAIL
      ),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
    },
    xaxis: {
      categories: comunicationDetailsQueryResponse.map(item => item.date),
    },
    yaxis: {
      title: {
        text: 'Actions',
      },
    },
    legend: {
      position: 'top',
    },
  };

  return (
    <GraphWrapper title="Executed Distribution">
      {loading ? (
        <LoadingPage height={350} />
      ) : (
        <Stack sx={{ height: '21.875rem' }}>
          <Chart
            options={options}
            series={yearSeries}
            type="bar"
            height={'100%'}
          />
        </Stack>
      )}
    </GraphWrapper>
  );
};

export default ExecutedDistributionGraph;
