import { Stack, Grid } from '@mui/material';
import React from 'react';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import { LegalBaseResponse } from '../../../types/legal';

type Props = {
  item?: Partial<LegalBaseResponse>;
  isLoading?: boolean;
};

function SecDetails({ item, isLoading = false }: Props) {
  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            text="Respondent/Opp. Party"
            value={item?.respondentOrOppParty ?? '-'}
            loading={isLoading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Cheque/ECS"
            value={item?.chequeECS ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Cheque/ECS Number"
            value={item?.chequeECSNumber ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Bank Name"
            value={item?.bankName ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Cheque/ECS Date"
            value={item?.chequeECSDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Cheque/ECS Amount"
            value={item?.chequeECSAmount ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Bouncing/Failed Reason"
            value={item?.bouncingFailedReason ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Cheque/ECS Bouncing/Failed Date"
            value={item?.chequeECSBouncingOrFailedDate ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Memo Date"
            value={item?.memoDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Notice Date"
            value={item?.noticeDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Notice Sent Date"
            value={item?.noticeSentDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Sec 138/25"
            value={item?.sec138Or25 ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Complaint Filing Date"
            value={item?.complaintFilingDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Original Case No."
            value={item?.originalCaseNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="CNR No."
            value={item?.cnrNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Court"
            value={item?.court ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Pre Date"
            value={item?.preDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Last Date"
            value={item?.lastDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Last Stage"
            value={item?.lastStage ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Next Date"
            value={item?.nextDate ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Final Stage"
            value={item?.finalStage ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Stage"
            value={item?.stage ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Advocate Name & Mobile No."
            value={item?.advocateNameAndMobileNumber ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Internal Legal Person Name & Mobile No."
            value={item?.internalLegalPersonNameAndMobileNumber ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Mobile No"
            value={item.mobileNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Email Id"
            value={item?.emailId ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Address"
            value={item?.address ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Warrants Received Date"
            value={item?.warrantsReceivedDate ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Received Month"
            value={item?.receivedMonth ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Received Year"
            value={item?.receivedYear ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Remarks,if any"
            value={item?.remarksIfany ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Bucket Latest"
            value={item?.bucketLatest ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Loan Account status"
            value={item?.loanAccountStatus ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Total Poss"
            value={item?.totalPos ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Status "
            value={item?.status ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Arbitraion NextStage "
            value={'-'}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default SecDetails;
