import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { TeleAgentService } from '../../Apis/TeleAgentServices';
import CreateTeleAgentForm from '../../components/TeleAgent/components/CreateTeleAgentForm';
import ListOfTeleAgents from '../../components/TeleAgent/components/ListOfTeleAgents';
import VegaButton from '../../components/common/VegaButton';
import VegaDrawer from '../../components/common/VegaDrawer';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { useDrawer } from '../../hooks/useDrawer';
import { CreateTeleAgentRequest } from '../../types/request/teleAgent';

function TeleAgentsList() {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    close: closeCreateTeleAgentDrawer,
    isOpen: isCreateTeleAgentDrawerOpen,
    open: openCreateTeleAgentDrawer,
  } = useDrawer(false);

  async function onCreateTeleAgentClick(
    formData: Partial<CreateTeleAgentRequest>
  ) {
    try {
      setLoading(true);
      const request: Partial<CreateTeleAgentRequest> = {
        crmId: formData?.crmId,
        firstname: formData?.firstname,
        lastname: formData?.lastname,
        password: formData?.password,
        remoteAgentNumber: formData?.remoteAgentNumber,
        employeeCode: formData?.employeeCode,
        userId: formData?.userId,
      };

      await TeleAgentService.createTeleAgent(request);
      closeCreateTeleAgentDrawer();
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  function closeTeleAgentDrawer() {
    closeCreateTeleAgentDrawer();
  }

  return (
    <>
      <VegaPageHeader
        title="Tele Agents"
        renderRightView={() => (
          <VegaButton
            text="Create Tele Agent"
            startIcon={<AddCircleOutline />}
            onClick={openCreateTeleAgentDrawer}
          />
        )}
      />
      <Box pt={3}>
        <ListOfTeleAgents />
      </Box>
      <VegaDrawer
        open={isCreateTeleAgentDrawerOpen}
        onClose={closeTeleAgentDrawer}
        title="Create Tele Agent"
      >
        <CreateTeleAgentForm
          onSubmit={onCreateTeleAgentClick}
          loading={loading}
        />
      </VegaDrawer>
    </>
  );
}

export default TeleAgentsList;
