import { Box, Stack } from '@mui/material';
import VegaPageContent from '../../../components/common/VegaPageContent';
import {
  CelenderViewType,
  CollectionProfitType,
} from '../../../features/analyticsDashboardSlice';
import TodayContainer from './TodayContainer/TodayContainer';
import MonthToDateContainer from './MTDContainer/MonthToDateContainer';
import MonthOnMonthContainer from './MOMContainer/MonthOnMonthContainer';
import YearToDateContainer from './YTDContainer/YearToDateContainer';

interface IProps {
  selectedCollectionProfitType: CollectionProfitType;
  selectedCalenderViewType: CelenderViewType;
}

const AnalyticsContainer = ({
  selectedCollectionProfitType,
  selectedCalenderViewType,
}: IProps) => {
  return (
    <Box
      sx={{
        height: 'calc(100% - 76.49px)',
        overflow: 'auto',
      }}
    >
      <VegaPageContent my={'1rem'}>
        {selectedCalenderViewType === CelenderViewType.THIS_YEAR ? (
          <Stack gap={1}>
            <MonthOnMonthContainer
              selectedCollectionProfitType={selectedCollectionProfitType}
            />
            <YearToDateContainer
              selectedCollectionProfitType={selectedCollectionProfitType}
            />
          </Stack>
        ) : (
          <Stack gap={1}>
            <TodayContainer
              selectedCollectionProfitType={selectedCollectionProfitType}
            />
            <MonthToDateContainer
              selectedCollectionProfitType={selectedCollectionProfitType}
            />
          </Stack>
        )}
      </VegaPageContent>
    </Box>
  );
};

export default AnalyticsContainer;
