import { Stack } from '@mui/material';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AnaltyticsService } from '../../../Apis/AnaltyticsService';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  AnalyticsQueryTypeEnum,
  CelenderViewType,
  getAnalyticsDashboardState,
} from '../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../store';
import { AnalyticsQueryRequest } from '../../../types/request/analyticsRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { DateUtility } from '../../../utils/DateUtlility';
import LmFilterSection from '../../../components/common/LmFilterSection';

enum BucketForOverAllPerformance {
  BucketX = 'X',
  Bucket1 = '1-30',
  Bucket2 = '31-60',
  Bucket3 = '61-90',
  BucketNpa = 'Npa',
  BucketNormal = 'Normal',
}
interface OverallPerformanceResultDto {
  brnRegion: string;
  totalOutstanding: number;
  totalTargetValue: number;
  totalResolutionPercentage: number;
  totalMtdResolutionPercentage: number;
  totalLmsdResolutionPercentage: number;
  totalYtdResolutionPercentage: number;
  totalLysdResolutionPercentage: number;
  totalOutstandingBucketNormal: number;
  targetBucketNormal: number;
  resolutionPercentageBucketNormal: number;
  mtdResolutionPercentageBucketNormal: number;
  lmsdResolutionPercentageBucketNormal: number;
  ytdResolutionPercentageBucketNormal: number;
  lysdResolutionPercentageBucketNormal: number;
  totalOutstandingBucketX: number;
  targetBucketX: number;
  resolutionPercentageBucketX: number;
  mtdResolutionPercentageBucketX: number;
  lmsdResolutionPercentageBucketX: number;
  ytdResolutionPercentageBucketX: number;
  lysdResolutionPercentageBucketX: number;
  totalOutstandingBucket1: number;
  targetBucket1: number;
  resolutionPercentageBucket1: number;
  mtdResolutionPercentageBucket1: number;
  lmsdResolutionPercentageBucket1: number;
  ytdResolutionPercentageBucket1: number;
  lysdResolutionPercentageBucket1: number;
  totalOutstandingBucket2: number;
  targetBucket2: number;
  resolutionPercentageBucket2: number;
  mtdResolutionPercentageBucket2: number;
  lmsdResolutionPercentageBucket2: number;
  ytdResolutionPercentageBucket2: number;
  lysdResolutionPercentageBucket2: number;
  totalOutstandingBucket3: number;
  targetBucket3: number;
  resolutionPercentageBucket3: number;
  mtdResolutionPercentageBucket3: number;
  lmsdResolutionPercentageBucket3: number;
  ytdResolutionPercentageBucket3: number;
  lysdResolutionPercentageBucket3: number;
  totalOutstandingBucketNpa: number;
  targetBucketNpa: number;
  resolutionPercentageBucketNpa: number;
  mtdResolutionPercentageBucketNpa: number;
  lmsdResolutionPercentageBucketNpa: number;
  ytdResolutionPercentageBucketNpa: number;
  lysdResolutionPercentageBucketNpa: number;
}

export enum TimePeriod {
  ONE_DAY = 'ONE_DAY',
  FIVE_DAY = 'FIVE_DAY',
  MTD = 'MTD',
}

function OverAllPerformanceTable() {
  const { selectedCollectionProfitType } = useAppSelector(
    getAnalyticsDashboardState
  );

  const { setSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    OverallPerformanceResultDto[]
  >([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    TimePeriod.ONE_DAY
  );
  const [search, setSearch] = useState<string>('');
  const { selectedCalenderViewType } = useAppSelector(
    getAnalyticsDashboardState
  );

  function buildAnalyticsQueryRequest(): Partial<AnalyticsQueryRequest> {
    const queryType: Partial<AnalyticsQueryRequest> = {};

    if (selectedCalenderViewType === CelenderViewType.TODAY) {
      if (selectedTimePeriod === TimePeriod.MTD) {
        queryType.analyticsQueryType =
          AnalyticsQueryTypeEnum.OVERALL_MONTH_TO_DATE_PERFORMANCE;
        queryType.params = {
          type: selectedCollectionProfitType,
        };
      } else if (selectedTimePeriod === TimePeriod.ONE_DAY) {
        const currentDate = DateUtility.formatStringToYYYYMMDD(
          new Date()?.toISOString()
        );
        queryType.analyticsQueryType =
          AnalyticsQueryTypeEnum.OVERALL_PERFORMANCE;
        queryType.params = {
          fromDate: currentDate,
          toDate: currentDate,
          type: selectedCollectionProfitType,
        };
      } else if (selectedTimePeriod === TimePeriod.FIVE_DAY) {
        const currentDate = new Date();
        const formattedCurrentDate = DateUtility.formatStringToYYYYMMDD(
          currentDate?.toISOString()
        );
        const fiveDaysBefore = DateUtility.formatStringToYYYYMMDD(
          DateUtility.subtractDays(new Date(), 4).toISOString()
        );
        queryType.analyticsQueryType =
          AnalyticsQueryTypeEnum.OVERALL_PERFORMANCE;
        queryType.params = {
          fromDate: fiveDaysBefore,
          toDate: formattedCurrentDate,
          type: selectedCollectionProfitType,
        };
      }
    } else {
      queryType.analyticsQueryType =
        AnalyticsQueryTypeEnum.OVERALL_YEAR_TO_DATE_PERFORMANCE;
      queryType.params = {
        type: selectedCollectionProfitType,
      };
    }

    return queryType;
  }

  const getQueriesForEnum = async () => {
    setLoading(true);
    try {
      const request = buildAnalyticsQueryRequest();

      const response = await AnaltyticsService.getAnalyticsQueries(request);

      setAnalyticsQueryResponse(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  function getFirstColumnName(value: string) {
    if (selectedCalenderViewType === CelenderViewType.TODAY) {
      if (selectedTimePeriod === TimePeriod.MTD) {
        return `${value} MTD Res%`;
      } else {
        return `${value} Target`;
      }
    } else {
      return `${value} YTD Res%`;
    }
  }

  function getSecondColumnName(value: string) {
    if (selectedCalenderViewType === CelenderViewType.TODAY) {
      if (selectedTimePeriod === TimePeriod.MTD) {
        return `${value} LMSD%`;
      } else {
        return `${value} Actual`;
      }
    } else {
      return `${value} LYSD%`;
    }
  }

  function columnToDisplay() {
    if (selectedCalenderViewType === CelenderViewType.TODAY) {
      if (selectedTimePeriod === TimePeriod.MTD) {
        return MTD_OverAllColumn_DEF;
      } else {
        return TODAY_AND_FIVE_DAY_OVERALLCOLUMN_DEF;
      }
    } else {
      return Year_OVERALL_COL_DEF;
    }
  }

  const getMtdColumns = (bucket: string): GridColumns => {
    return [
      {
        field: `mtdResolutionPercentage${bucket}`,
        headerName: getFirstColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`mtdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          ).toString();

          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `lmsdResolutionPercentage${bucket}`,
        headerName: getSecondColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`lmsdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `percentageChange${bucket}`,
        headerName: `Bucket-${BucketForOverAllPerformance[bucket]}-% Change`,
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`percentageChange${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
    ];
  };

  const getYearColumn = (bucket: string): GridColumns => {
    return [
      {
        field: `ytdResolutionPercentage${bucket}`,
        headerName: getFirstColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`ytdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `lysdResolutionPercentage${bucket}`,
        headerName: getSecondColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`lysdResolutionPercentage${bucket}`]?.toFixed(
              2
            ) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `percentageChange${bucket}`,
        headerName: `Bucket-${BucketForOverAllPerformance[bucket]}-% Change`,
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`percentageChange${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
    ];
  };

  const getTodayOrFiveDayColumns = (bucket: string): GridColumns => {
    return [
      {
        field: `target${bucket}`,
        headerName: getFirstColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`target${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `totalOutstanding${bucket}`,
        headerName: getSecondColumnName(
          `Bucket-${BucketForOverAllPerformance[bucket]}`
        ),
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`totalOutstanding${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
      {
        field: `percentageChange${bucket}`,
        headerName: `Bucket-${BucketForOverAllPerformance[bucket]}-% Change`,
        flex: 1,
        minWidth: 200,
        renderCell: props => {
          const overallPerformance = props.row as OverallPerformanceResultDto;
          const display = parseFloat(
            overallPerformance[`percentageChange${bucket}`]?.toFixed(2) ?? 0
          );
          return (
            <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
          );
        },
      },
    ];
  };

  const MTD_OverAllColumn_DEF: GridColumns = [
    {
      field: 'brnRegion',
      headerName: 'Regions',
      flex: 1,
      minWidth: 200,
      renderCell: (props: GridCellParams<OverallPerformanceResultDto>) => {
        const overallPerformance = props.row as OverallPerformanceResultDto;
        const display = overallPerformance?.brnRegion ?? '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    ...getMtdColumns('BucketX'),
    ...getMtdColumns('Bucket1'),
    ...getMtdColumns('Bucket2'),
    ...getMtdColumns('Bucket3'),
    ...getMtdColumns('BucketNpa'),
    ...getMtdColumns('BucketNormal'),
  ];

  const TODAY_AND_FIVE_DAY_OVERALLCOLUMN_DEF: GridColumns = [
    {
      field: 'brnRegion',
      headerName: 'Regions',
      flex: 1,
      minWidth: 200,
      renderCell: (props: GridCellParams<OverallPerformanceResultDto>) => {
        const overallPerformance = props.row as OverallPerformanceResultDto;
        const display = overallPerformance?.brnRegion ?? '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    ...getTodayOrFiveDayColumns('BucketX'),
    ...getTodayOrFiveDayColumns('Bucket1'),
    ...getTodayOrFiveDayColumns('Bucket2'),
    ...getTodayOrFiveDayColumns('Bucket3'),
    ...getTodayOrFiveDayColumns('BucketNpa'),
    ...getTodayOrFiveDayColumns('BucketNormal'),
  ];

  const Year_OVERALL_COL_DEF: GridColumns = [
    {
      field: 'brnRegion',
      headerName: 'Regions',
      flex: 1,
      minWidth: 200,
      renderCell: (props: GridCellParams<OverallPerformanceResultDto>) => {
        const overallPerformance = props.row as OverallPerformanceResultDto;
        const display = overallPerformance?.brnRegion ?? '';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    ...getYearColumn('BucketX'),
    ...getYearColumn('Bucket1'),
    ...getYearColumn('Bucket2'),
    ...getYearColumn('Bucket3'),
    ...getYearColumn('BucketNpa'),
    ...getYearColumn('BucketNormal'),
  ];

  const filterData = () => {
    if (search.length) {
      const searchLowerCase = search.toLowerCase();
      const filteredResults = analyticsQueryResponse.filter(query => {
        if (search === '') {
          return query;
        } else if (query.brnRegion.toLowerCase().includes(searchLowerCase)) {
          return query;
        }
      });
      return filteredResults;
    } else {
      return analyticsQueryResponse;
    }
  };

  useEffect(() => {
    getQueriesForEnum();
  }, [
    selectedCalenderViewType,
    selectedTimePeriod,
    selectedCollectionProfitType,
  ]);

  return (
    <>
      <Stack
        gap={2}
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          p: 2,

          bgcolor: 'white',
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <VegaText
            text={'Overall Performance'}
            fontWeight={600}
            fontSize={17}
            color={'black'}
          />
          <Stack direction={'row'} gap={1}>
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
            {selectedCalenderViewType === CelenderViewType.TODAY && (
              <Stack minWidth={150}>
                <VegaSelect
                  value={selectedTimePeriod}
                  options={getPeriodOptions()}
                  onChange={select => {
                    setSelectedTimePeriod(select.target.value);
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
        <VegaDataGrid
          idColumn="brnRegion"
          data={filterData()}
          columns={columnToDisplay()}
          loading={loading}
          paginationMode="client"
          pageSize={5}
        />
      </Stack>
    </>
  );
}

export default OverAllPerformanceTable;
function getPeriodOptions() {
  const options = Object.values(TimePeriod)
    .map(item => {
      return {
        value: item,
        label: item,
      } as VegaSelectOption;
    })
    .filter(item => item !== null);
  return options;
}
