import axios from 'axios';
import { ApiService } from '../types/api';
import { GetSaarthiListRequest } from '../types/request/systemCollectionRequest';

const Endpoint = {
  BASE: '/master',
  SAARTHI: '/saarthi',
  UPLOAD: '/pushData',
  STATUS: '/status',
};

const SAARTHI_BASE_URL = Endpoint.BASE + Endpoint.SAARTHI;

export class SystemCollectionServices extends ApiService {
  static async getSaarthiList(request: Partial<GetSaarthiListRequest>) {
    const endpoint = SAARTHI_BASE_URL + Endpoint.UPLOAD + Endpoint.STATUS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async pushdataToSaarthi(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const endpoint = SAARTHI_BASE_URL + Endpoint.UPLOAD;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await axios.post(endpoint, formData, config);
    return response.data;
  }
}
