import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AllocationService } from '../../Apis/AllocationService';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { RepeatLastMonthAllocationHistoryDto } from '../../types/allocations';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import { DateUtility } from '../../utils/DateUtlility';

const ListOfLastMonthAllocationHistory = () => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [lastMonthAllocationHistoryList, setLastMonthAllocationHistoryList] =
    useState<RepeatLastMonthAllocationHistoryDto[]>([]);

  const fetchLastMonthAllocationHistoryData = async () => {
    setLoading(true);
    try {
      const response =
        await AllocationService.getRepeatLastMonthAllocationHistory();
      setLastMonthAllocationHistoryList(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLastMonthAllocationHistoryData();
  }, []);

  return (
    <>
      <VegaPageContent>
        <VegaDataGrid
          data={lastMonthAllocationHistoryList}
          loading={loading}
          columns={getExecutionColumnDefinition()}
          idColumn="requestId"
          paginationMode="client"
          pageSize={10}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfLastMonthAllocationHistory;

const getExecutionColumnDefinition = () => {
  const { userForId } = useClientAuth();

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const COL_DEF: GridColumns = [
    {
      field: 'requestId',
      headerName: 'id',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as RepeatLastMonthAllocationHistoryDto;
        const display = executionHistory?.requestId ?? 'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'allocatedBy',
      headerName: 'Allocated By',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as RepeatLastMonthAllocationHistoryDto;
        const display = getUserName(executionHistory?.allocatedBy) ?? 'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as RepeatLastMonthAllocationHistoryDto;
        const display = executionHistory?.status ?? 'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'allocationCount',
      headerName: 'Allocation Count',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as RepeatLastMonthAllocationHistoryDto;
        const display = executionHistory?.allocationCount ?? '0';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0.7,
      renderCell: props => {
        const executionHistory =
          props.row as RepeatLastMonthAllocationHistoryDto;
        const display =
          DateUtility.formatStringToYYYYMMDD(executionHistory?.createdAt) ??
          'NA';
        return (
          <VegaText text={display} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
};
