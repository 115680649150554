import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import ListOfReceiptCancellation from './module/ListOfReceiptCancellation';
import { UiModule } from '../../router/routes';

export enum TabType {
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
}

const ReceiptCancellation = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onTabChange = (value: number) => {
    setSelectedTab(value);
  };

  return (
    <>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Receipt Cancellation'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  onTabChange(selected);
                }}
              >
                <VegaTabBarItem label={toLowerCase(TabType.PENDING)} />
                <VegaTabBarItem label={toLowerCase(TabType.HISTORY)} />
              </VegaTabBar>
            </Stack>
          );
        }}
      />

      <ListOfReceiptCancellation
        selectedTab={selectedTab}
        module={UiModule.RECEIPT_CANCELLATION}
      />
    </>
  );
};

export default ReceiptCancellation;
