import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { AnimatedInputWrapper } from '../../../components/ExpenseClaim/CreateClaimForm';
import { useOts } from '../../../providers/OtsProvider';
import OtsAccountDetails from './components/OtsAccountDetails';
import OtsActionView from './components/OtsActionView';
import OtsDeliquencyDetails from './components/OtsDeliquencyDetails';
import OtsLoanApproverDetails from './components/OtsLoanApproverDetails';
import OtsTechnicalDetails from './components/OtsTechnicalDetails';
import OtsOutstandingDetails from './components/OtsOutstandingDetails';
import OtsOperationDetails from './components/OtsOperationDetails';
import OtsWavierDetails from './components/OtsWavierDetails';
import {
  OtsReportsStatus,
  OtsStatus,
  OtsUserRole,
  TimeUnit,
} from '../../../types/ots';
import { useClientAuth } from '../../../providers/ClientProvider';
import OtsAuditView from './components/OtsAuditView';
import { useDrawer } from '../../../hooks/useDrawer';
import EditOtsDrawer, { UpdateOtsFormData } from '../modules/EditOtsDrawer';
import { OtsService } from '../../../Apis/OtsService';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import OtsNpvDetails from './components/OtsNpvDetails';
import OtsCustomerAckLetter from './components/OtsCustomerAckLetter';
import { getEnumValueFromString } from '../../../types/trail';
import OtsCommentsModal from './components/OtsCommentsModal';
import OtsDocumentsModal from './components/OtsDocumentsModal';
import VegaButton from '../../../components/common/VegaButton';
import OtsRCMCommentView from './components/OtsRCMCommentView';
import OtsHelperTextView from './components/OtsHelperTextView';
import OtsCommitteeDetails from './components/OtsCommitteeDetails';
import OtsHistoryModal from './components/OtsHistoryModal';
import OtsCollectionsHierarchy from './components/OtsCollectionsHierarchy';
import OtsTriggerDelayModal from './components/OtsTriggerDelayModal';


const ROLE_STATUS_MAP: Map<string, OtsStatus[]> = new Map();
ROLE_STATUS_MAP.set(OtsUserRole.CE, [
  OtsStatus.POST_RCM_REJECT,
  OtsStatus.POST_CUSTOMER_ACKNOWLEDGEMENT_APPROVE,
]);
ROLE_STATUS_MAP.set(OtsUserRole.AGENDA_CREATOR, [
  OtsStatus.PRE_AGENDA_CREATION,
]);
ROLE_STATUS_MAP.set(OtsUserRole.RCM, [
  OtsStatus.PRE_RCM_APPROVAL,
  OtsStatus.POST_RCM_APPROVAL,
  OtsStatus.PRE_RCM_REJECT,
  OtsStatus.PRE_RCM_ON_HOLD,
  OtsStatus.POST_NCM_REJECT,
  OtsStatus.POST_HOC_REJECT,
  OtsStatus.PRE_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL,
  OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_APPROVAL,
  OtsStatus.POST_RCM_CUSTOMER_ACKNOWLEDGEMENT_REJECT,
  OtsStatus.OTS_BREACHED,
]);
ROLE_STATUS_MAP.set(OtsUserRole.NCM, [
  OtsStatus.PRE_NCM_APPROVAL,
  OtsStatus.POST_NCM_APPROVAL,
  OtsStatus.PRE_NCM_REJECT,
  OtsStatus.POST_HOC_REJECT,
  OtsStatus.POST_HOC_REJECT,
  OtsStatus.PRE_NCM_ON_HOLD,
  OtsStatus.MD_REJECT,
  OtsStatus.POST_RCM_ON_HOLD,
]);
ROLE_STATUS_MAP.set(OtsUserRole.GROUP_COLLECTIONS_HEAD, [
  OtsStatus.PRE_HOC_APPROVAL,
  OtsStatus.POST_HOC_APPROVAL,
  OtsStatus.PRE_HOC_REJECT,
  OtsStatus.POST_NCM_ON_HOLD,
  OtsStatus.PRE_HOC_POST_COMMITTEE_APPROVAL,
  OtsStatus.POST_HOC_POST_COMMITTEE_APPROVAL,
  OtsStatus.HOC_POST_COMMITTEE_REJECT,
  OtsStatus.MD_REJECT,
  OtsStatus.POST_RCM_ON_HOLD,
  OtsStatus.POST_NCM_ON_HOLD,
  OtsStatus.PRE_HOC_ON_HOLD,
  OtsStatus.POST_HOC_ON_HOLD,
]);
ROLE_STATUS_MAP.set(OtsUserRole.MANAGING_DIRECTOR, [
  OtsStatus.PRE_MD_APPROVAL,
  OtsStatus.POST_MD_APPROVAL_APPROVE,
  OtsStatus.POST_MD_APPROVAL_REJECT,
  OtsStatus.POST_RCM_ON_HOLD,
  OtsStatus.POST_NCM_ON_HOLD,
]);

function OtsDetailsPage() {
  const { setSnackbar } = useSnackbar();
  const { user, roles } = useClientAuth();
  const { fetchOts, ots, loan, downloadFile } = useOts();
  const { otsId } = useParams();
  const {
    open: openEditDrawer,
    close: closeEditDrawer,
    isOpen: isEditDrawerOpen,
  } = useDrawer();
  const {
    open: openCommentsModal,
    close: closeCommentsModal,
    isOpen: isCommentsModalOpen,
    props: commentsModalProps,
  } = useDrawer();
  const {
    open: openHistoryModal,
    close: closeHistoryModal,
    isOpen: isHistoryModalOpen,
    props: historyModalProps,
  } = useDrawer();
  const {
    open: openDocumentsModal,
    close: closeDocumentsModal,
    isOpen: isDocumentsModalOpen,
    props: documentsModalProps,
  } = useDrawer();
  const {
    open: openDelayModal,
    close: closeDelayModal,
    isOpen: isDelayModalOpen,
    props: delayModalProps,
  } = useDrawer();

  const canShowTechnicalDetails = () => {
    return ots?.technicalReportsStatus != null;
  };
  const canShowOperationDetails = () => {
    return ots?.operationReportStatus != null;
  };
  const canShowNpvData = () => {
    return ots?.npvCalculated == true;
  };
  const canShowCommitteeDetails = () => {
    return ots?.agendaCaseId != null;
  };
  const canShowAckLetterData = () => {
    return ots?.ackLetter != null;
  };

  const isStatusPostRcmApproval = ots =>
    ots?.status === OtsStatus.POST_RCM_APPROVAL;
  const isReportsApproved = ots =>
    ots?.operationReportStatus === OtsReportsStatus.APPROVED &&
    ots?.technicalReportsStatus === OtsReportsStatus.APPROVED;

  const mapRoles = roles =>
    roles
      .map(i => getEnumValueFromString(OtsUserRole, i.roleDto.name))
      .filter(i => i !== undefined);

  const isRoleAllowed = (mappedRoles, ots) =>
    mappedRoles.some(role => {
      const statusesForRole = ROLE_STATUS_MAP.get(role);
      return statusesForRole ? statusesForRole.includes(ots?.status) : false;
    });

  const canShowActionview = () => {
    if (isStatusPostRcmApproval(ots)) {
      return isReportsApproved(ots);
    }

    const mappedRoles = mapRoles(roles);
    const hasComments = (ots?.commentIds ?? []).length > 0;
    return (
      isRoleAllowed(mappedRoles, ots) &&
      ots?.principle != null &&
      ots?.interest != null &&
      ots?.others != null &&
      hasComments
    );
  };

  const canShowRcmCommentView = () => {
    const isRcmApprovalPending = ots?.status == OtsStatus.PRE_RCM_APPROVAL;
    if (isRcmApprovalPending == false) return false;

    const mappedRoles = mapRoles(roles);

    const isRCM = mappedRoles.some(role => {
      return role == OtsUserRole.RCM;
    });
    const hasNoComments = (ots?.commentIds ?? []).length == 0;
    return isRCM && hasNoComments;
  };

  const canEditWavierDetails = () => {
    if (isStatusPostRcmApproval(ots)) {
      return isReportsApproved(ots);
    }

    const mappedRoles = mapRoles(roles);
    const isRoleSuitableForEdit = role =>
      [
        OtsUserRole.GROUP_COLLECTIONS_HEAD,
        OtsUserRole.NCM,
        OtsUserRole.RCM,
        OtsUserRole.AGENDA_CREATOR,
      ].includes(role);

    return (
      isRoleAllowed(mappedRoles, ots) && mappedRoles.some(isRoleSuitableForEdit)
    );
  };

  async function updateOts(formData: Partial<UpdateOtsFormData>) {
    try {
      const loanId = loan.loanId;
      await OtsService.updateOtsRequest({
        id: ots.id,
        loanId: loanId,
        userId: user?.id,
        wavierAmount: formData.wavierAmount,
        timeUnit: TimeUnit.MONTH,
        principle: formData.principal,
        others: formData.others,
        interest: formData.interest,
        updatingSettlement: true,
      });
      fetchOts(ots.id);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function triggerDelay(triggerDate: Date) {
    try {
      await OtsService.triggerOtsDelay({
        eventType: 'OTS_ACTION_DELAY_EVENT',
        otsId: ots?.id,
        eventTriggeredAt: triggerDate.toISOString(),
      });
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  useEffect(() => {
    if (!otsId) return;
    fetchOts(otsId);
  }, [otsId]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[{ label: 'OTS', link: -1 }, { label: 'View Details' }]}
            />
          );
        }}
      />
      <VegaPageContent>
        <Box
          sx={{
            p: '1rem',
            bgcolor: 'white',
            borderRadius: '0.75rem',
            border: '1px solid #E1E4EB',
            my: '1rem',
          }}
        >
          <Stack gap={2}>
            <OtsHelperTextView />
            <OtsAccountDetails />
            <OtsOutstandingDetails />
            <OtsWavierDetails
              canEdit={canEditWavierDetails()}
              onEditClick={function (): void {
                openEditDrawer();
              }}
            />
            <OtsDeliquencyDetails />
            <OtsLoanApproverDetails />
            <OtsCollectionsHierarchy />
            <AnimatedInputWrapper
              show={canShowOperationDetails()}
              marginTop={'.5rem'}
            >
              <OtsOperationDetails />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={canShowTechnicalDetails()}
              marginTop={'.5rem'}
            >
              <OtsTechnicalDetails />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper show={canShowNpvData()} marginTop={'.5rem'}>
              <OtsNpvDetails />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={canShowCommitteeDetails()}
              marginTop={'.5rem'}
            >
              <OtsCommitteeDetails />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={canShowAckLetterData()}
              marginTop={'.5rem'}
            >
              <OtsCustomerAckLetter />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={canShowRcmCommentView()}
              marginTop={'.5rem'}
            >
              <OtsRCMCommentView />
            </AnimatedInputWrapper>
            <AnimatedInputWrapper
              show={canShowActionview()}
              marginTop={'.5rem'}
            >
              <OtsActionView />
            </AnimatedInputWrapper>
          </Stack>
        </Box>
        <Stack
          direction={'row'}
          marginBottom={2}
          gap={1}
          justifyContent="flex-end"
        >
          {/* <VegaButton
            variant="text"
            text="Trigger Delay notification"
            onClick={() => openDelayModal()}
          ></VegaButton> */}
          <VegaButton
            variant="text"
            text="View OTS History"
            onClick={() => openHistoryModal({ ots: ots })}
          ></VegaButton>
          <VegaButton
            variant="text"
            text="View Comments"
            onClick={() => openCommentsModal({ ots: ots })}
          ></VegaButton>
          <VegaButton
            variant="text"
            text="View Additional Documents"
            onClick={() => openDocumentsModal({ ots: ots })}
          ></VegaButton>
        </Stack>
        <OtsAuditView />
      </VegaPageContent>
      <EditOtsDrawer
        open={isEditDrawerOpen}
        onClose={function (): void {
          closeEditDrawer();
        }}
        onSubmit={updateOts}
      />
      <OtsCommentsModal
        open={isCommentsModalOpen}
        ots={commentsModalProps.ots}
        onClose={function (): void {
          closeCommentsModal();
        }}
      />
      <OtsDocumentsModal
        open={isDocumentsModalOpen}
        ots={documentsModalProps.ots}
        onClose={function (): void {
          closeDocumentsModal();
        }}
        onDownload={function (url: string): void {
          downloadFile(url);
        }}
      />
      <OtsHistoryModal
        open={isHistoryModalOpen}
        ots={historyModalProps.ots}
        onClose={function (): void {
          closeHistoryModal();
        }}
      />
      <OtsTriggerDelayModal
        open={isDelayModalOpen}
        onClose={function (): void {
          closeDelayModal();
        }}
        onTriggerClick={triggerDelay}
      />
    </div>
  );
}

export default OtsDetailsPage;
