import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../../../Apis/ReceiptManagmentService';
import LmDialog from '../../../../components/common/LmDialog';
import VegaDialogContent from '../../../../components/common/VegaDialogContent';
import VegaKeyValueDisplay from '../../../../components/common/VegaKeyValueDisplay';
import VegaText from '../../../../components/common/VegaText';
import { receiptState } from '../../../../features/receiptManagmentSlice';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../../store';
import {
  ReceiptObjDto,
  getPillColorForStatus,
} from '../../../../types/receiptManagment';
import { StringUtility } from '../../../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';

interface IProps {
  open: boolean;
  toggleDialog: () => void;
}

const ViewDetailsReceiptCancellation = ({ open, toggleDialog }: IProps) => {
  const { userForId } = useClientAuth();
  const { cancelReceiptData } = useAppSelector(receiptState);
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [receiptData, setReceiptData] = useState<ReceiptObjDto | null>(null);

  const getReceiptData = () => {
    if (cancelReceiptData) {
      return {
        lan: cancelReceiptData.loanId ? cancelReceiptData.loanId : 'NA',
        receiptId: cancelReceiptData.receiptId
          ? cancelReceiptData.receiptId
          : 'NA',
        receiptDate: cancelReceiptData.requestDate
          ? cancelReceiptData.requestDate
          : 'NA',
        agentId: cancelReceiptData.agentId ? cancelReceiptData.agentId : 'NA',
        approverId: cancelReceiptData.approverId
          ? cancelReceiptData.approverId
          : 'NA',
        approverRemark: cancelReceiptData.approverRemark
          ? cancelReceiptData.approverRemark
          : 'NA',
        agentRemark: cancelReceiptData.agentRemark
          ? cancelReceiptData.agentRemark
          : 'NA',
        status: cancelReceiptData.status ? cancelReceiptData.status : 'NA',
      };
    } else {
      return {
        payeeName: 'NA',
        lan: 'NA',
        receiptId: 'NA',
        receiptDate: 'NA',
        agentId: 'NA',
        approverId: 'NA',
        approverRemark: 'NA',
        agentRemark: 'NA',
        status: 'NA',
      };
    }
  };

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };

  const getReceiptAmountBreckUp = () => {
    const receiptAmountDto = receiptData?.receiptAmountBreakUp;
    if (receiptData && receiptAmountDto) {
      return {
        overDueAmount: receiptAmountDto.overDueAmount
          ? StringUtility.formatToINR(receiptAmountDto.overDueAmount)
          : StringUtility.formatToINR(0),
        bounceCharges: receiptAmountDto.bounceCharges
          ? StringUtility.formatToINR(receiptAmountDto.bounceCharges)
          : StringUtility.formatToINR(0),
        penalCharges: receiptAmountDto.penalCharges
          ? StringUtility.formatToINR(receiptAmountDto.penalCharges)
          : StringUtility.formatToINR(0),
        legalCharges: receiptAmountDto.legalCharges
          ? StringUtility.formatToINR(receiptAmountDto.legalCharges)
          : StringUtility.formatToINR(0),
        otherCharges: receiptAmountDto.otherCharges
          ? StringUtility.formatToINR(receiptAmountDto.otherCharges)
          : StringUtility.formatToINR(0),
        payeeName: receiptData.payeeName ? receiptData.payeeName : 'NA',
      };
    } else {
      return {
        overDueAmount: StringUtility.formatToINR(0),
        bounceCharges: StringUtility.formatToINR(0),
        penalCharges: StringUtility.formatToINR(0),
        legalCharges: StringUtility.formatToINR(0),
        otherCharges: StringUtility.formatToINR(0),
        payeeName: 'NA',
      };
    }
  };

  const getReceipt = async (receiptId: string) => {
    setLoading(true);
    try {
      const response = await ReceiptManagmentService.getReceiptByReceiptId(
        receiptId
      );
      setReceiptData(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && cancelReceiptData.receiptId) {
      getReceipt(cancelReceiptData.receiptId);
    }
  }, [open, cancelReceiptData]);

  return (
    <LmDialog
      fullWidth
      maxWidth="md"
      open={open}
      isCloseIcon
      handleClose={toggleDialog}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="View Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={12} marginBottom="0.75rem">
            <VegaText
              text={'Cancel Receipt Details'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '120%',
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <VegaKeyValueDisplay heading="LAN" value={getReceiptData().lan} />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Receipt ID"
              loading={loading}
              value={getReceiptData().receiptId}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Receipt Date"
              loading={loading}
              value={getReceiptData().receiptDate}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Agent Name"
              loading={loading}
              value={getUserName(getReceiptData()?.agentId)}
            />
          </Grid>

          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Approve Name"
              loading={loading}
              value={getUserName(getReceiptData()?.approverId)}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Agent Remark"
              loading={loading}
              value={getReceiptData().agentRemark}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Approve Remark"
              loading={loading}
              value={getReceiptData().approverRemark}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Approve Remark"
              loading={loading}
              value={getReceiptData().approverRemark}
            />
          </Grid>
          {cancelReceiptData && (
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="Status"
                loading={loading}
                value={cancelReceiptData.status}
                renderValue={() => {
                  const color = getPillColorForStatus(cancelReceiptData.status);
                  return (
                    <VegaText
                      text={cancelReceiptData.status}
                      fontWeight={500}
                      fontSize={'0.8125rem'}
                      color={color.DARK}
                    />
                  );
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid container rowGap={'0.75rem'} my={'2rem'}>
          <Grid item xs={12} marginBottom="0.75rem">
            <VegaText
              text={'Receipt Amount'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '120%',
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="OverDue Amount"
              loading={loading}
              value={getReceiptAmountBreckUp().overDueAmount}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Bounce Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().bounceCharges}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Legal Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().legalCharges}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Penal Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().penalCharges}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Other Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().otherCharges}
            />
          </Grid>
        </Grid>
        {receiptData &&
          (receiptData.documentImageUrl || receiptData.locationImageUrl) && (
            <Box>
              <VegaText
                text={'Proof'}
                sx={{
                  mt: '2rem',
                  mb: '1rem',
                  color: '#222',
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  lineHeight: '120%',
                }}
              />

              <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {receiptData.documentImageUrl && (
                  <Box sx={{ maxWidth: '100px', maxHeight: '100px' }}>
                    <img
                      src={receiptData.documentImageUrl}
                      alt=""
                      width={'100%'}
                      height={'100%'}
                      style={{ borderRadius: '6px' }}
                    />
                  </Box>
                )}
                {receiptData.locationImageUrl && (
                  <Box sx={{ maxWidth: '100px', maxHeight: '100px' }}>
                    <img
                      src={receiptData.locationImageUrl}
                      alt=""
                      width={'100%'}
                      height={'100%'}
                      style={{ borderRadius: '6px' }}
                    />
                  </Box>
                )}
              </Stack>
            </Box>
          )}
      </VegaDialogContent>
    </LmDialog>
  );
};

export default ViewDetailsReceiptCancellation;
