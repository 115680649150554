import { VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import VegaButton from '../../../components/common/VegaButton';
import VegaIconButton from '../../../components/common/VegaIconButton';
import VegaText from '../../../components/common/VegaText';
import { PRIMARY } from '../../../constants/style';
import { VegaUser } from '../../../types/claim';
import { ScreenOpenType } from '../UAM';

interface IColumnProps {
  onAction: (user: VegaUser, openType: ScreenOpenType) => void;
}

export const UserListColumn = ({ onAction }: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      minWidth: 200,
      renderCell: props => {
        const user = props.row as VegaUser;
        const firstName = user.firstName ?? '';
        const middleName = user.middleName ?? '';
        const lastName = user.lastName ?? '';
        const fullName = firstName + ' ' + middleName + ' ' + lastName;
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={fullName}
          />
        );
      },
    },
    {
      field: 'employeeCode',
      headerName: 'User ID',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const user = props.row as VegaUser;
        // const id = user.id ? StringUtility.extractLastNDigits(6, user.id) : '';
        const employeeCode = user.employeeCode ? user.employeeCode : '';
        return (
          <VegaIconButton
            tooltipTitle={user.employeeCode}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={`${employeeCode}`}
              />
            }
          />
        );
      },
    },
    {
      field: 'email',
      headerName: 'email',
      flex: 0.7,
      minWidth: 200,
      renderCell: props => {
        const user = props.row as VegaUser;
        const email = user.email ?? '';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={email}
          />
        );
      },
    },
    {
      field: 'designation',
      headerName: 'Functional Designation',
      flex: 0.7,
      minWidth: 200,
      renderCell: props => {
        const user = props.row as VegaUser;
        const designation = user.designation ?? '';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={designation}
          />
        );
      },
    },
    // {
    //   field: 'rm1Code',
    //   headerName: 'Manager',
    //   flex: 0.7,
    //   minWidth: 200,
    // },
    {
      field: 'rm1Name',
      headerName: 'Manager',
      flex: 0.7,
      minWidth: 200,
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 0.7,
      minWidth: 100,
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const user = props.row as VegaUser;
        return (
          // <VegaButton
          //   variant="text"
          //   text="View Details"
          //   onClick={() => onAction(user, ScreenOpenType.VIEW)}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => onAction(user, ScreenOpenType.VIEW)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 180,
      renderCell: props => {
        const user = props.row as VegaUser;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text="Assign Role"
              onClick={() => onAction(user, ScreenOpenType.ADD)}
              variant="text"
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
