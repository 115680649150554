import VegaDataGrid from '../../../../components/common/VegaDataGrid';
import { getIncentiveScoreCard } from '../../../../features/IncentivesGamificationsSlice/incentiveScoreCardSlice';
import { useAppSelector } from '../../../../store';
import RankInRegionColumn from '../columns/RankInRegionColumn';

const ListOfAgentRegionRank = () => {
  const { scoreCardDetails } = useAppSelector(getIncentiveScoreCard);

  return (
    <>
      <VegaDataGrid
        idColumn="regionName"
        columns={RankInRegionColumn()}
        data={
          scoreCardDetails?.overallPerformanceDetails?.regionRankDetails ?? []
        }
        loading={false}
        paginationMode="client"
        pageSize={5}
        hideFooter
      />
    </>
  );
};

export default ListOfAgentRegionRank;
