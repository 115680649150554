import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgendaService } from '../../../Apis/AgendaService';
import { OtsService } from '../../../Apis/OtsService';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaText from '../../../components/common/VegaText';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { LoanData } from '../../../types/loan';
import {
  AgendaCase,
  AgendaCaseMetaDataKeys,
  AgendaCaseStatus,
  getValueFromRecord,
  OtsAction,
} from '../../../types/ots';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../../utils/api';
import { COLOR } from '../../../utils/ColorUtility';
import { StringUtility } from '../../../utils/StringUtility';
import RejectOtsDialog from '../pages/components/RejectOtsDialog';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../constants/style';

const VALID_STATUS: AgendaCaseStatus[] = [
  AgendaCaseStatus.PENDING_CHAIR_APPROVAL,
];
function CommitteChairOtsPage() {
  const { setSnackbar } = useSnackbar();
  const { user } = useClientAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [caseData, setCaseData] = useState<PaginatedResponse<AgendaCase>>({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });
  const [pendingCaseData, setPendingCaseData] = useState<
    PaginatedResponse<AgendaCase>
  >({
    records: [],
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    numberOfItems: 0,
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    open: openRejectionDialog,
    close: closeRejectionDialog,
    isOpen: isRejectionDialogOpen,
    props: rejectionDialogProps,
  } = useDrawer();

  async function fetchData() {
    if (selectedTab == 0) {
      fetchPendingApprovalCases();
    } else if (selectedTab == 1) {
      fetchCommitteeDiscussedCases();
    }
  }

  async function fetchPendingApprovalCases() {
    try {
      setLoading(true);
      const rejectedCases = await AgendaService.getAgendaCaseList({
        includeLoanDetails: true,
        meetingStatus: AgendaCaseStatus.PENDING_CHAIR_APPROVAL,
      });
      setPendingCaseData(rejectedCases);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function updateCase(data: {
    action: OtsAction;
    reason?: string;
    agendaCase?: AgendaCase;
  }) {
    try {
      if (!data.agendaCase) return;
      await AgendaService.takeActionOnPendingCases({
        caseId: data.agendaCase.id,
        action: data.action,
        description: data.reason,
        userId: user?.id,
      });
      fetchData();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchCommitteeDiscussedCases() {
    try {
      setLoading(true);
      const response = await AgendaService.getAgendaCaseList({
        page: caseData.pageNumber,
        pageSize: 10,
        otsChairDecisionStatus: VALID_STATUS.join(','),
      });
      setCaseData(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getData = () => {
    if (selectedTab == 0) {
      return pendingCaseData;
    } else if (selectedTab == 1) {
      return caseData;
    }
  };

  const getColumnDefinition = () => {
    if (selectedTab == 0) {
      return getPendingApprovalColumnDefinition({
        onViewDetailsClick: function (item: AgendaCase): void {
          navigate(ROUTES.OTS_DETAILS.replace(':otsId', item.otsId));
        },
        onAcceptClick: function (item: AgendaCase): void {
          updateCase({
            action: OtsAction.APPROVE,
            agendaCase: item,
          });
        },
        onRejectClick: function (item: AgendaCase): void {
          openRejectionDialog({ agendaCase: item });
        },
      });
    } else if (selectedTab == 1) {
      return getDiscussedColumnDefinition({
        onViewDetailsClick: agendaCase => {
          navigate(
            ROUTES.AGENDA_CASE_DETAILS.replace(':caseId', agendaCase.id)
          );
        },
        onNoteClick: agendaCase => {
          downloadCaseNote(agendaCase);
        },
      });
    }
  };

  async function downloadCaseNote(agendaCase: AgendaCase) {
    try {
      setLoading(true);
      const ots = await OtsService.getOtsById(agendaCase.otsId);
      const url = await OtsService.getPublicUrl({
        url: ots.note,
      });
      if (url) {
        window.open(url, '__blank');
      } else {
        setSnackbar('Case Note not found', 'error');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [caseData.pageNumber, pendingCaseData.pageNumber, selectedTab]);

  return (
    <div>
      <VegaPageHeader title="Committee Chair OTS" />
      <VegaPageHeader
        sx={{
          borderBottom: 'none',
          bgcolor: 'transparent',
        }}
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaTabBar
                value={selectedTab}
                variant="scrollable"
                scrollButtons={false}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                }}
              >
                <VegaTabBarItem label="Pending Approval" />
                <VegaTabBarItem label="Discussed in Committee" />
              </VegaTabBar>
            </Stack>
          );
        }}
      />

      <VegaPageContent paddingBottom={4}>
        <VegaDataGrid
          data={getData().records}
          loading={loading}
          columns={getColumnDefinition()}
          idColumn="id"
          rowCount={getData().numberOfItems}
          page={getData().pageNumber}
          pageSize={10}
        />
      </VegaPageContent>

      <RejectOtsDialog
        show={isRejectionDialogOpen}
        onClose={function (): void {
          closeRejectionDialog();
        }}
        onReject={function (reason: string): void {
          updateCase({
            action: OtsAction.REJECT,
            reason: reason,
            agendaCase: rejectionDialogProps.agendaCase,
          });
        }}
      />
    </div>
  );
}

export default CommitteChairOtsPage;

const getDiscussedColumnDefinition = (data: {
  onViewDetailsClick: (item: AgendaCase) => void;
  onNoteClick: (item: AgendaCase) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'meetingStatus',
      headerName: 'Meeting Status',
      flex: 0.7,
      renderCell: props => {
        const item = getCaseForRow(props);
        const displayText = item.meetingStatus ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'meetingExclusionReason',
      headerName: 'Meeting Exclusion Reason',
      flex: 0.7,
      renderCell: props => {
        const item = getCaseForRow(props);
        const displayText = item.meetingExclusionReason ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'committeeDecisionStatus',
      headerName: 'Committee Decision',
      flex: 0.7,
      renderCell: props => {
        const item = getCaseForRow(props);
        const displayText = item.committeeDecisionStatus;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'repaymentDay',
      headerName: 'Repayment Day',
      flex: 0.7,
      renderCell: props => {
        const item = getCaseForRow(props);
        const displayText = item.dayOfRepayment ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'comment',
      headerName: 'Committee Comment',
      flex: 0.7,
      renderCell: props => {
        const item = getCaseForRow(props);
        const displayText = item.committeeComment ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'generatedNote',
      headerName: 'Note',
      flex: 0.7,
      renderCell: props => {
        const item = getCaseForRow(props);
        return (
          <VegaText
            onClick={() => data.onNoteClick(item)}
            text={'Note'}
            style={{ color: COLOR.BLUE[300], cursor: 'pointer' }}
          ></VegaText>
        );
      },
    },

    {
      field: 'otsDetails',
      headerName: 'Ots',
      flex: 0.6,
      renderCell: props => {
        const item = getCaseForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(item);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(item);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getPendingApprovalColumnDefinition = (data: {
  onViewDetailsClick: (item: AgendaCase) => void;
  onAcceptClick: (item: AgendaCase) => void;
  onRejectClick: (item: AgendaCase) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'loanNo',
      headerName: 'LAN',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = loan?.loanId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'veritical',
      headerName: 'Veritical',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = loan?.lmsVariables?.segment;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = loan?.lmsVariables?.bucket;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'outstanding',
      headerName: 'Total Outstanding',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = StringUtility.formatToINR(
          loan?.lmsVariables?.outStandingTotal
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'otsDetails',
      headerName: 'Ots',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(item);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(item);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 200,
      renderCell: props => {
        const item = getCaseForRow(props);
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text="Accept"
              variant="text"
              sx={{ px: 0.5, color: COLOR.GREEN.DARK }}
              onClick={() => {
                data.onAcceptClick(item);
              }}
            />
            <VegaButton
              text="Reject"
              variant="text"
              sx={{ px: 0.5, color: COLOR.RED.DARK }}
              onClick={() => {
                data.onRejectClick(item);
              }}
            />
          </Stack>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getCaseForRow = (props: any) => props.row as AgendaCase;
