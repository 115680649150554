import axios from 'axios';
import { PaginatedResponse, objectToFormData } from '../utils/api';
import { GetTrailDetailRequest } from '../types/request/trail';
import { VisitTrailDto } from '../types/trail';
import { CreateTrailFormData } from '../components/Trail/UpdateTrailDrawer';

const Endpoint = {
  BASE: '/allocation',
  GET: '/loanVisitTrail/list',
  UPDATE: '/loanVisitTrail',
};

const BASE_URL = Endpoint.BASE;
export class TrailService {
  static async getTrailList(
    request: Partial<GetTrailDetailRequest>
  ): Promise<PaginatedResponse<VisitTrailDto>> {
    const endpoint = BASE_URL + Endpoint.GET;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async updateTrail(request: Partial<CreateTrailFormData>) {
    const endpoint = BASE_URL + Endpoint.UPDATE;
    const trailObj = {
      ...request,
    };
    delete trailObj?.customerImageFile;
    const formData = objectToFormData<Partial<CreateTrailFormData>>(trailObj);
    if (request?.customerImageFile) {
      formData.append('customerImageFile', request.customerImageFile.value);
    }

    // const response = await axios.post(endpoint, request);
    const response = await axios.post(endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  }
}
