import { Stack, Grid } from '@mui/material';
import { useOts } from '../../../../providers/OtsProvider';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';
import OtsFileDisplay from './OtsFileDisplay';

function OtsCustomerAckLetter() {
  const { ots, loading, downloadFile } = useOts();

  return (
    <OtsDetailsWrapper text="Customer Ack Letter" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Ack Letter"
              renderValue={() => {
                return (
                  <OtsFileDisplay
                    value={'Ack Letter'}
                    onClick={() => {
                      downloadFile(ots?.ackLetter);
                    }}
                  />
                );
              }}
              loading={loading}
            />
          </Grid>
          {ots?.generatedLetter && (
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <OtsKeyValueDisplay
                text="OTS Letter"
                renderValue={() => {
                  return (
                    <OtsFileDisplay
                      value={'OTS Letter'}
                      onClick={() => {
                        downloadFile(ots?.generatedLetter);
                      }}
                    />
                  );
                }}
                loading={loading}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsCustomerAckLetter;
