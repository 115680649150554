/* eslint-disable no-undef */
import axios from 'axios';
import {
  AgentStatsDto,
  Allocation,
  AllocationMetaData,
  AllocationMetaDataExport,
  AllocationRule,
  BulkAllocationMeta,
  ExecuteCriteriaResponse,
  ExecutionAllocations,
  ExportPenalAndOverallResponse,
  ExportPenalAndOverallResponseHistoryDto,
  LongRunningTask,
  RepeatLastMonthAllocationHistoryDto,
  RepeatLastMonthAllocationsDto,
  TeleAllocateBulk,
  TeleAllocationList,
} from '../types/allocations';
import {
  AllocateLoanAccountsRequest,
  BulkAllocateOrDeAllocateStatusRequest,
  BulkAllocateRequest,
  CreateRuleRequest,
  CreateTeleAllocateRequest,
  DeAllocateAllocationsRequest,
  DownloadBulkAllocateFileRequest,
  DownloadBulkAllocationSampleFileRequest,
  ExecuteCriteriaRequest,
  ExportPenalAndOverallRequest,
  GetAgentDeatilsRequest,
  GetAllocationMetaDataRequest,
  GetAllocationsListRequest,
  GetAutoAllocationCriteriaListRequest,
  GetAutoAllocationExecutionListRequest,
  GetBulkAllocationListMetaRequest,
  GetBulkDeAllocationListMetaRequest,
  GetExportAllocationMetaDataRequest,
  GetTeleAllocationsListRequest,
  ListOfDailyTargetRequest,
  ListOfMonthlyTargetRequest,
  PenalAndRollbackExecutionStatusRequest,
  ReallocateAllocationsRequest,
  ResolveAllocationRequest,
  UpdateAutoAllocationCriteriaRequest,
  UpdateRuleRequest,
  UploadMonthlyTargetRequest,
} from '../types/request/allocation';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/allocation',
  LIST_ALLOCATIONS: '/list',
  ALLOCATE_TELE: '/allocate',
  ALLOCATE_FIELD: '/allocate',
  RE_ALLOCATE: '/reallocate',
  DE_ALLOCATE: '/deallocate',
  UPDATE: '/update',
  AUTO_CRITERIA: '/auto/criteria',
  AUTO_CRITERIA_BY_ID: '/auto/criteria/:criteriaId',
  LIST_AUTO_ALLOCATION_ATTRIBUTES: '/auto/attributes',
  EXECUTE_CRITERIA: '/auto/execute',
  LIST_BULK_ALLOCATION: '/allocate/bulk/meta/list',
  LIST_BULK_DE_ALLOCATION: '/deallocate/bulk/meta/list',
  BULK_ALLOCATE: '/allocate/bulk',
  BULK_DEALLOCATE: '/deallocate/bulk',
  DOWNLOAD_BULK_DEALLOCATION_SAMPLE: '/deallocate/bulk/sample',
  DOWNLOAD_BULK_ALLOCATION_SAMPLE: '/allocate/bulk/sample',
  ALLOCATE_TELE_LIST: '/allocate/tele/list',
  ALLOCATE_TELE_BULK: '/allocate/tele/bulk',
  LIST_ALLOCATION_METADATA: '/metadata',
  LIST_ALLOCATION_METADATA_EXPORT: '/loan/export',
  DOWNLOAD_BULK_ERROR_FILE: '/allocate/bulk/meta/errorfile',
  AGENT_STATS: '/loanVisitTrail/dashboard',
  RESOLVE: '/allocate/resolve',
  EXPORT_BY_REQUESTID: '/loan/export/:requestId',
  EXPORT_CRITERIA: '/auto/criteria/list/export',
  EXPORT_PENAL_AND_OVERALL: '/auto/execute/tele/custom',
  EXPORT_PENAL_AND_OVERALL_STATUS: '/auto/execute/tele/custom/{requestId}',
  EXPORT_PENAL_AND_OVERALL_HISTORY: '/auto/execute/tele/custom/history',
  REPEAT_LAST_MONTH_ALLOCATION: '/auto/execute/allocation/previous',
  REPEAT_LAST_MONTH_ALLOCATION_STATUS:
    '/auto/execute/allocation/previous/{requestId}',
  REPEAT_LAST_MONTH_ALLOCATION_HISTORY:
    '/auto/execute/allocation/previous/history',
  UPLOAD_MONTLY_TARGET: '/target/upload/bulk',
  UPLOAD_DAILY_CUSTOM_TARGET: '/target/upload/daily/bulk',
  BULK_ALLOCATION_DEALLOCATION_STATUS: '/allocation/task/{taskId}',
  CUSTOM_DAILY_TARGET: '/allocate/bulk/daily-target/meta/list',
  CUSTOM_MONTHLY_TARGET:'/allocate/bulk/monthly-target/meta/list'
};

const BASE_URL = Endpoint.BASE;

const LIST_AUTO_CRITERIA_URL =
  Endpoint.BASE + Endpoint.AUTO_CRITERIA + Endpoint.LIST_ALLOCATIONS;

const LIST_EXECUTE_CRITERIA_URL =
  Endpoint.BASE + Endpoint.EXECUTE_CRITERIA + Endpoint.LIST_ALLOCATIONS;

const UPDATE_AUTO_CRITERIA_URL =
  Endpoint.BASE + Endpoint.AUTO_CRITERIA + Endpoint.UPDATE;

const CREATE_RULE = Endpoint.BASE + Endpoint.AUTO_CRITERIA;
const LIST_OF_CUSTOM_DAILY_TARGET =
  Endpoint.BASE + Endpoint.CUSTOM_DAILY_TARGET;
  const LIST_OF_CUSTOM_MONTHLY_TARGET =
  Endpoint.BASE + Endpoint.CUSTOM_MONTHLY_TARGET;

export class AllocationService {
  static async getAllocations(
    request: Partial<GetAllocationsListRequest>
  ): Promise<Allocation[]> {
    const endpoint = BASE_URL + Endpoint.LIST_ALLOCATIONS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getTeleAllocations(
    request: Partial<GetTeleAllocationsListRequest>
  ): Promise<PaginatedResponse<TeleAllocationList>> {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_TELE_LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async createTeleAllocations(
    request: Partial<CreateTeleAllocateRequest>
  ): Promise<TeleAllocateBulk> {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_TELE_BULK;
    const processId =
      typeof request.processId === 'number'
        ? request.processId.toString()
        : request.processId;
    const leadsetId =
      typeof request.leadsetId === 'number'
        ? request.leadsetId.toString()
        : request.leadsetId;
    const formData = new FormData();
    formData.append('file', request.file.value);
    formData.append('processId', processId);
    formData.append('teleAllocationType ', request.teleAllocationType);
    if (leadsetId) {
      formData.append('leadsetId', leadsetId);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async allocateTele(request: Partial<AllocateLoanAccountsRequest>) {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_TELE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async allocateField(request: Partial<AllocateLoanAccountsRequest>) {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_FIELD;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async reallocate(request: Partial<ReallocateAllocationsRequest>) {
    const endpoint = BASE_URL + Endpoint.RE_ALLOCATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async deallocate(request: Partial<DeAllocateAllocationsRequest>) {
    const endpoint = BASE_URL + Endpoint.DE_ALLOCATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getAutoAllocationCriteria(
    request: Partial<GetAutoAllocationCriteriaListRequest>
  ): Promise<PaginatedResponse<AllocationRule>> {
    const endpoint = LIST_AUTO_CRITERIA_URL;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getAutoAllocationCriteriaByCriteriaId(
    criteriaId: string
  ): Promise<AllocationRule> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.AUTO_CRITERIA_BY_ID.replace(':criteriaId', criteriaId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getAutoAllocationExecution(
    request: Partial<GetAutoAllocationExecutionListRequest>
  ): Promise<PaginatedResponse<ExecutionAllocations>> {
    const endpoint = LIST_EXECUTE_CRITERIA_URL;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async updateAutoAllocationCriteria(
    request: Partial<UpdateAutoAllocationCriteriaRequest>
  ): Promise<PaginatedResponse<AllocationRule>> {
    const endpoint = UPDATE_AUTO_CRITERIA_URL;
    const response = await axios.patch(endpoint, request);
    return response.data;
  }

  static async createRule(request: Partial<CreateRuleRequest>) {
    const endpoint = CREATE_RULE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateRule(request: Partial<UpdateRuleRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.AUTO_CRITERIA;
    const response = await axios.put(endpoint, request);
    return response.data;
  }

  static async getAutoAllocationAttributes() {
    const endpoint = BASE_URL + Endpoint.LIST_AUTO_ALLOCATION_ATTRIBUTES;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async executeCriteria(
    request: Partial<ExecuteCriteriaRequest>
  ): Promise<ExecuteCriteriaResponse> {
    const endpoint = BASE_URL + Endpoint.EXECUTE_CRITERIA;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getBulkAllocationListMeta(
    request: Partial<GetBulkAllocationListMetaRequest>
  ): Promise<PaginatedResponse<BulkAllocationMeta>> {
    const endpoint = BASE_URL + Endpoint.LIST_BULK_ALLOCATION;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async bulkAllocate(
    request: Partial<BulkAllocateRequest>
  ): Promise<LongRunningTask> {
    const endpoint = BASE_URL + Endpoint.BULK_ALLOCATE;
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
      formData.append('allocationType', request.allocationType);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async getBulkAllocateOrDeAllocateStatus(
    request: Partial<BulkAllocateOrDeAllocateStatusRequest>
  ): Promise<LongRunningTask> {
    const endpoint = Endpoint.BULK_ALLOCATION_DEALLOCATION_STATUS.replace(
      '{taskId}',
      request.requestId
    );
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getBulkDeAllocationListMeta(
    request: Partial<GetBulkDeAllocationListMetaRequest>
  ): Promise<PaginatedResponse<BulkAllocationMeta>> {
    const endpoint = BASE_URL + Endpoint.LIST_BULK_DE_ALLOCATION;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async bulkDeAllocate(
    request: Partial<BulkAllocateRequest>
  ): Promise<LongRunningTask> {
    const endpoint = BASE_URL + Endpoint.BULK_DEALLOCATE;
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async downloadBulkErrorFile(
    request: Partial<DownloadBulkAllocateFileRequest>
  ) {
    const endpoint =
      BASE_URL + Endpoint.DOWNLOAD_BULK_ERROR_FILE + `/${request.requestId}`;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async downloadBulkAllocationSampleFile(
    request: Partial<DownloadBulkAllocationSampleFileRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_BULK_ALLOCATION_SAMPLE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async downloadBulkDeallocationSampleFile(
    request: Partial<DownloadBulkAllocationSampleFileRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_BULK_DEALLOCATION_SAMPLE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getAllocationMetaData(
    request: Partial<GetAllocationMetaDataRequest>
  ): Promise<PaginatedResponse<AllocationMetaData>> {
    const endpoint = BASE_URL + Endpoint.LIST_ALLOCATION_METADATA;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getAgentStats(
    request: Partial<GetAgentDeatilsRequest>
  ): Promise<AgentStatsDto> {
    const endpoint = BASE_URL + Endpoint.AGENT_STATS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async exportAllocationMetaData(
    request: Partial<GetExportAllocationMetaDataRequest>
  ): Promise<AllocationMetaDataExport> {
    const endpoint = Endpoint.LIST_ALLOCATION_METADATA_EXPORT;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async exportByRequestId(requestId: string) {
    const endpoint = Endpoint.EXPORT_BY_REQUESTID.replace(
      ':requestId',
      requestId
    );
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async resolveAllocation(request: Partial<ResolveAllocationRequest>) {
    const endpoint = BASE_URL + Endpoint.RESOLVE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async exportCriteria() {
    const endpoint = BASE_URL + Endpoint.EXPORT_CRITERIA;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async exportPenalAndRollback(
    request: Partial<ExportPenalAndOverallRequest>
  ): Promise<Partial<ExportPenalAndOverallResponse>> {
    const endpoint = BASE_URL + Endpoint.EXPORT_PENAL_AND_OVERALL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getPenalAndRollbackExecutionStatus(
    request: Partial<PenalAndRollbackExecutionStatusRequest>
  ): Promise<Partial<ExportPenalAndOverallResponse>> {
    const endpoint =
      BASE_URL +
      Endpoint.EXPORT_PENAL_AND_OVERALL_STATUS.replace(
        '{requestId}',
        request.requestId
      );
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getPenalAndRollbackExecutionHistory(): Promise<
    ExportPenalAndOverallResponseHistoryDto[]
  > {
    const endpoint = BASE_URL + Endpoint.EXPORT_PENAL_AND_OVERALL_HISTORY;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async repeatLastMonthAllocations(): Promise<
    Partial<RepeatLastMonthAllocationsDto>
  > {
    const endpoint = BASE_URL + Endpoint.REPEAT_LAST_MONTH_ALLOCATION;
    const response = await axios.post(endpoint);
    return response.data;
  }

  static async getRepeatLastMonthAllocationsStatus(
    request: Partial<PenalAndRollbackExecutionStatusRequest>
  ): Promise<Partial<RepeatLastMonthAllocationsDto>> {
    const endpoint =
      BASE_URL +
      Endpoint.REPEAT_LAST_MONTH_ALLOCATION_STATUS.replace(
        '{requestId}',
        request.requestId
      );
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getRepeatLastMonthAllocationHistory(): Promise<
    RepeatLastMonthAllocationHistoryDto[]
  > {
    const endpoint = BASE_URL + Endpoint.REPEAT_LAST_MONTH_ALLOCATION_HISTORY;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async uploadMonthlyTarget(
    request: Partial<UploadMonthlyTargetRequest>
  ): Promise<any> {
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
    }
    const endpoint = BASE_URL + Endpoint.UPLOAD_MONTLY_TARGET;
    const response = await axios.post(endpoint, formData);
    return response.data;
  }
  static async uploadDailyCustomTarget(
    request: Partial<UploadMonthlyTargetRequest>
  ): Promise<any> {
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
    }
    const endpoint = BASE_URL + Endpoint.UPLOAD_DAILY_CUSTOM_TARGET;
    const response = await axios.post(endpoint, formData);
    return response.data;
  }
  static async fetchDailyCustomList(
    request: Partial<ListOfDailyTargetRequest>
  ): Promise<PaginatedResponse<BulkAllocationMeta>> {
    const endpoint = LIST_OF_CUSTOM_DAILY_TARGET;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
    static async fetchMonthlyCustomList(
    request: Partial<ListOfMonthlyTargetRequest>
  ): Promise<PaginatedResponse<BulkAllocationMeta>> {
    const endpoint = LIST_OF_CUSTOM_MONTHLY_TARGET;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
}
