import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  BumperCondition,
  BumperPolicyRangeProps,
} from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  bumperIncentive: BumperPolicyRangeProps;
  index: number;
}

const ViewBumperIncentive = ({ loading, bumperIncentive, index }: IProps) => {
  return (
    <Stack
      gap={1}
      key={index}
      sx={{
        borderRadius: '12px',
        p: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        mb: 1,
      }}
    >
      <VegaText
        text={`Perameter ${index + 1}`}
        fontWeight={500}
        fontSize={'0.75rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'amount type'}>
            {getTextLoadingWrapper(
              toLowerCase(bumperIncentive.amountType),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'amount value'}>
            {getTextLoadingWrapper(`${bumperIncentive.amountValue}`, loading)}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {bumperIncentive?.condition.length
        ? bumperIncentive.condition.map(
            (condition: BumperCondition, index: number) => (
              <Stack
                key={index}
                sx={{
                  borderRadius: '12px',
                  p: 2,
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
                  mb: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'condition type'}>
                      {getTextLoadingWrapper(
                        `${toLowerCase(condition.conditionType)}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'condition value'}>
                      {getTextLoadingWrapper(
                        `${condition.conditionValue}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'cut off day of month'}>
                      {getTextLoadingWrapper(
                        `${condition.conditionCutoffDayOfMonth}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                </Grid>
              </Stack>
            )
          )
        : ''}
    </Stack>
  );
};

export default ViewBumperIncentive;

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};
