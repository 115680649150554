import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { RoleMaxPayoutDto } from '../../../types/incentives';

type Props = {
  open: boolean;
  onClose: () => void;
  payoutDetails: RoleMaxPayoutDto;
  onSubmitClick: (payoutDetails: RoleMaxPayoutDto, amount: string) => void;
};

type SetMaxPayoutFormData = {
  role: string;
  amount: string;
};

enum FormField {
  Role = 'role',
  Amount = 'amount',
}
function SetMaxPayoutDrawer({
  open,
  onClose,
  payoutDetails,
  onSubmitClick,
}: Props) {
  const [formData, setFormData] = useState<Partial<SetMaxPayoutFormData>>({
    amount: '0',
  });

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  const onClick = () => {
    onSubmitClick(payoutDetails, formData.amount);
    setFormData({ amount: '0' });
  };

  function onCloseDrawer() {
    onClose();
    setFormData({ amount: '0' });
  }

  useEffect(() => {
    if (open && payoutDetails) {
      setFormData(prev => ({
        ...prev,
        amount: payoutDetails?.maxCapping?.toString(),
      }));
    }
  }, [open, payoutDetails]);

  return (
    <div>
      <VegaDrawer open={open} title={'Set Max Payout'} onClose={onCloseDrawer}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <VegaButton
                text={'Save Details'}
                disabled={+formData.amount == payoutDetails?.maxCapping}
                onClick={onClick}
                // loading={loading}
              />
            );
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Role">
                <LmTextField disabled value={payoutDetails?.role} />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <VegaFormInputField label="Amount">
                <LmTextField
                  type="number"
                  value={formData?.amount}
                  onChange={e =>
                    updateFormData(FormField.Amount, e.target.value)
                  }
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </div>
  );
}

export default SetMaxPayoutDrawer;
