/* eslint-disable react/prop-types */
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AllocationService } from '../../../Apis/AllocationService';
import { fetchLeadsetList } from '../../../actions/TeleAllocationAction';
import { PRIMARY } from '../../../constants/style';
import { getTeleAllocation } from '../../../features/teleAllocationSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GetLeadsetListApiRequest } from '../../../types/request/teleAllocation';
import {
  ProcessType,
  TeleAllocationLeadsetViewModal,
} from '../../../types/teleAllocation';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmFilterSection from '../../common/LmFilterSection';
import VegaBulkUploadModal from '../../common/VegaBulkUploadModal';
import VegaDataGrid from '../../common/VegaDataGrid';
import { VegaFileSelectOption } from '../../common/VegaFileSelect';
import VegaIconButton from '../../common/VegaIconButton';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';
import UploadIcon from '@mui/icons-material/Upload';

const ListOfLeadsetCreation = () => {
  const { leadsets, leadsetTotalItems, loading } =
    useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const [uploading, setUploading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [leadsetData, setLeadsetData] = useState<
    Partial<TeleAllocationLeadsetViewModal>
  >({});
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const {
    isOpen: isUploadModalOpen,
    open: openUploadModal,
    close: closeUploadModal,
  } = useDrawer();

  async function uploadFile(selectedFile: File) {
    try {
      setUploading(true);
      const fileObj: VegaFileSelectOption = {
        value: selectedFile,
        label: selectedFile.name,
        id: selectedFile.name,
      };
      await AllocationService.createTeleAllocations({
        processId: leadsetData.processId,
        file: fileObj,
        leadsetId: leadsetData.leadsetId,
        teleAllocationType: ProcessType.AUTO,
      });
      setSnackbar('Allocate Successfully !!!');
      dispatch(fetchLeadsetList({ page, size }));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setUploading(false);
    }
  }

  async function downloadSampleFile() {
    try {
      const url = await AllocationService.downloadBulkAllocationSampleFile({
        bulkUploadActionType: 'BULK_PUSH_LEADS',
      });
      window.open(url, '__blank__');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const getLeadsetList = () => {
    const request = {
      page,
      size,
    } as GetLeadsetListApiRequest;
    if (search.length) {
      request.leadsetName = search;
    }

    dispatch(fetchLeadsetList(request));
  };

  useEffect(() => {
    if (search.length) {
      const getLoanData = setTimeout(getLeadsetList, 300);
      return () => clearTimeout(getLoanData);
    } else {
      getLeadsetList();
    }
  }, [dispatch, page, size, search]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Leadset '}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={leadsets}
          idColumn="id"
          columns={getColumnDefinition({
            onUploadClick: leadset => {
              setLeadsetData(leadset);
              openUploadModal();
            },
          })}
          paginationMode="server"
          loading={loading || uploading}
          rowCount={leadsetTotalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setSize(size)}
          page={page}
          pageSize={size}
        />
      </VegaPageContent>
      <VegaBulkUploadModal
        open={isUploadModalOpen}
        title={'Upload'}
        onClose={function (): void {
          closeUploadModal();
        }}
        onSampleDownloadClick={downloadSampleFile}
        onUploadClick={uploadFile}
      />
    </>
  );
};

export default ListOfLeadsetCreation;

const getColumnDefinition = (data: {
  onUploadClick: (leadset: TeleAllocationLeadsetViewModal) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'leadsetId',
      headerName: 'Leadset ID',
      flex: 0.5,
    },
    {
      field: 'leadsetName',
      headerName: 'Leadset Name',
      flex: 1,
    },

    {
      field: 'processId',
      headerName: 'Process ID',
      flex: 0.7,
    },
    {
      field: 'action',
      headerName: 'Bulk Push Lead',
      flex: 0.7,
      renderCell: props => {
        const leadset = props.row as TeleAllocationLeadsetViewModal;
        return (
          // <VegaButton
          //   text={'Upload'}
          //   sx={{
          //     bgcolor: '#E7EDFC',
          //     color: PRIMARY.darkBlue,
          //     ':hover': {
          //       bgcolor: '#E7EDFC',
          //       color: PRIMARY.darkBlue,
          //     },
          //   }}
          //   onClick={() => data.onUploadClick(leadset)}
          // />
          <VegaIconButton
            tooltipTitle="Upload"
            onClick={() => data.onUploadClick(leadset)}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={<UploadIcon sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
