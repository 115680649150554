import { Stack, Divider, Grid } from '@mui/material';
import React from 'react';
import VegaText from '../../components/common/VegaText';
import VegaButton from '../../components/common/VegaButton';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import { openMakeBidDialog } from '../../features/liveAuctionDetailsSlice';
import { useAppDispatch } from '../../store';
import { Auction, AuctionStatus, Bid } from '../../types/auction';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';

type Props = { auction?: Auction; latestBid?: Bid };
function AuctionMakeBidCard({ auction, latestBid }: Props) {
  const dispatch = useAppDispatch();

  const canEnableMakeBid = () => {
    const isInProgress = auction?.status == AuctionStatus.IN_PROGRESS;
    return isInProgress;
  };

  return (
    <div
      style={{
        borderRadius: '0.5rem',
        border: '1px solid #E1E4EB',
        marginBottom: '1rem',
        marginTop: '1rem',
        padding: '1rem',
      }}
    >
      <Stack direction={'row'} spacing="1rem">
        <VegaText
          text={`Auction ends ${DateUtility.formatStringToDDMMYYYYWithTime(
            auction?.endDateTime
          )}`}
        />
      </Stack>
      <Divider sx={{ marginY: '1rem' }} />
      <Grid
        container
        alignItems={'center'}
        justifyContent="space-between"
        marginBottom={'1rem'}
      >
        <Grid item>
          <VegaFormInputField label="Current Bid Price">
            <VegaText text={StringUtility.formatToINR(latestBid?.bidAmount)} />
          </VegaFormInputField>
        </Grid>
        <Grid item>
          <VegaFormInputField label="Reserve Price">
            <VegaText text={StringUtility.formatToINR(auction?.reservePrice)} />
          </VegaFormInputField>
        </Grid>
      </Grid>
      <Stack>
        <VegaButton
          text="Make Bid"
          disabled={canEnableMakeBid() == false}
          onClick={() => {
            dispatch(openMakeBidDialog());
          }}
        />
      </Stack>
    </div>
  );
}

export default AuctionMakeBidCard;
