import { Grid, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { OtsService } from '../../../../Apis/OtsService';
import LmDialog from '../../../../components/common/LmDialog';
import VegaButton from '../../../../components/common/VegaButton';
import VegaText from '../../../../components/common/VegaText';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { Ots, OtsUserComment } from '../../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { COLOR } from '../../../../utils/ColorUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  ots?: Ots;
};

function OtsCommentsModal({ open, onClose, ots }: Props) {
  const { setSnackbar } = useSnackbar();
  const [comments, setComments] = useState<OtsUserComment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function fetchComments() {
    try {
      const otsId = ots?.id;
      if (!otsId) return;
      setLoading(true);
      const response = await OtsService.getComments(otsId);
      setComments(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    onClose();
  }

  useEffect(() => {
    if (open == true && ots) {
      fetchComments();
    }
  }, [ots, open]);
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => {
        handleClose();
      }}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'end'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            variant="outlined"
            fullWidth
            text="Close"
            onClick={() => handleClose()}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText text="Comments" fontWeight={600} fontSize={'1.0625rem'} />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" gap={'1rem'}>
        <Grid container gap={2}>
          {comments.map((i, index) => {
            return (
              <Grid item xs={12} key={index}>
                <div
                  style={{
                    padding: '0.5rem',
                    borderRadius: '4px',
                    border: '1px solid ' + COLOR.GREY[100],
                  }}
                >
                  <VegaText text={i.comment}></VegaText>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </LmDialog>
  );
}

export default OtsCommentsModal;
