import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Collapse, MenuItem, Stack } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { COMMON } from '../../constants/style';
import VegaCheckbox from './VegaCheckbox';
import { VegaSelectOption } from './VegaSelect';
import VegaText from './VegaText';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';

export enum VegaSelectorOptionTypeEnum {
  value = 'value',
  label = 'label',
}
type Props = {
  searchValue?: string;
  placeHolder?: string;
  options: VegaSelectOption[];
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (selected: string) => void;
  handleDelete: (selected: number) => void;
  onScroll?: (e: any) => void;
  selected: string[];
  disabled?: boolean;
  loading?: boolean;
  isIdSelector?: VegaSelectorOptionTypeEnum;
  ignoreLodash?: boolean;
  showValueInsteadOfLabel?: boolean;
};

function VegaSelectWithCheckbox({
  searchValue,
  handleChange,
  onSelect,
  onScroll,
  options,
  selected,
  placeHolder,
  disabled,
  loading,
  handleDelete,
  isIdSelector = VegaSelectorOptionTypeEnum.value,
  ignoreLodash = false,
  showValueInsteadOfLabel = false,
}: Props) {
  const [show, setShow] = useState<boolean>(false);
  const onChange = (selectValue: string | VegaSelectOption) => {
    if (!disabled) {
      // setShow(!show);
      if (typeof selectValue === 'string') {
        onSelect(selectValue);
      } else {
        onSelect(selectValue.value);
      }
    }
  };

  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <div ref={domNode} style={{ position: 'relative' }}>
      <Stack
        sx={{
          borderRadius: '9px',
          border: '1px solid #C5C8D0',
          pl: 2,
          pr: 1,
          transition: 'all 0.3s ease-in-out',
          height: 'auto',
          ...(!disabled && { cursor: 'pointer' }),
        }}
        onClick={disabled ? () => {} : () => setShow(!show)}
      >
        <Stack
          direction={'row'}
          minHeight={32}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack
            direction={'row'}
            gap={1}
            sx={{
              flexWrap: 'wrap',
              py: 0.5,
              // ...(selected?.length > 4 && { flexWrap: 'wrap', py: 1 }),
            }}
          >
            {selected?.length ? (
              selected.map((value: string, index: number) => {
                return (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    key={value}
                    sx={{
                      bgcolor: '#3966E2',
                      color: 'white',
                      borderRadius: '12px',

                      px: 1.5,
                      py: 0.5,
                      fontFamily: `var(--font-Aspekta-600) !important`,
                      fontSize: '0.625rem',
                    }}
                  >
                    {ignoreLodash
                      ? showValueInsteadOfLabel
                        ? findLabelFromValue(options, value)
                        : value
                      : showValueInsteadOfLabel
                      ? toLowerCase(findLabelFromValue(options, value))
                      : value}
                    <Close
                      sx={{
                        fontSize: '0.75rem',
                        ...(!disabled && { cursor: 'pointer' }),
                      }}
                      onClick={
                        disabled
                          ? () => {}
                          : () => {
                              handleDelete(index);
                            }
                      }
                    />
                  </Stack>
                );
              })
            ) : (
              <VegaText text={placeHolder} color={'#C5C8D0'} />
            )}
          </Stack>

          <KeyboardArrowDown
            onClick={disabled ? () => {} : () => setShow(!show)}
            sx={{
              fontSize: '1rem',
              color: 'rgba(0, 0, 0, 0.54)',
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',

              ...(show ? { rotate: '180deg' } : ''),
            }}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bgcolor: 'white',
          zIndex: 9999,
          width: '100%',
        }}
      >
        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: '6px',
          }}
          in={show}
        >
          {loading ? (
            <LoadingPage height={200} />
          ) : (
            <Box sx={{ py: 1 }}>
              {handleChange && (
                <Box
                  sx={{
                    px: 1,
                  }}
                >
                  {' '}
                  <input
                    className="VegaInput"
                    placeholder="search..."
                    type="text"
                    style={{
                      height: '35px',
                      width: '100%',
                      borderRadius: '9px',
                      border: '1px solid #C5C8D0',
                      paddingLeft: '10px',
                    }}
                    value={searchValue}
                    onChange={handleChange}
                  />
                </Box>
              )}
              <div
                onScroll={onScroll}
                style={{
                  maxHeight: 200,
                  padding: '10px',
                  overflow: 'auto',
                }}
                className=""
              >
                {(options?.length ?? 0) > 0 ? (
                  options?.map((option, index) => {
                    const isChecked = selected?.length
                      ? selected?.includes(
                          typeof option === 'string'
                            ? option
                            : option[isIdSelector]
                        )
                      : false;
                    return (
                      <MenuItem
                        key={index}
                        value={
                          typeof option === 'string' ? option : option.value
                        }
                        onClick={() =>
                          onChange(
                            typeof option === 'string' ? option : option.value
                          )
                        }
                        sx={{
                          p: 0,
                        }}
                      >
                        <Stack
                          direction={'row'}
                          gap={2}
                          color={COMMON.gray}
                          alignItems="center"
                          className="font-aspekta-400"
                        >
                          <VegaCheckbox checked={isChecked} />
                          {ignoreLodash
                            ? typeof option === 'string'
                              ? option
                              : option.label
                            : typeof option === 'string'
                            ? _.startCase(_.toLower(option))
                            : _.startCase(_.toLower(option.label))}
                        </Stack>
                      </MenuItem>
                    );
                  })
                ) : (
                  <VegaText text={'No Data'} />
                )}
              </div>
            </Box>
          )}
        </Collapse>
      </Box>
    </div>
  );
}

export default VegaSelectWithCheckbox;

const useClickOutSide = (handler: any) => {
  const domNode = useRef<any>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return domNode;
};
const findLabelFromValue = (
  options: VegaSelectOption[],
  valueToFind: any
): string => {
  const option = options.find(option => option.value === valueToFind);
  return option ? option.label : valueToFind;
};
