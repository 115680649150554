import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../../Apis/ReceiptManagmentService';
import LmDialog from '../../../components/common/LmDialog';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaDialogDisplayItem from '../../../components/common/VegaDialogItem';
import VegaText from '../../../components/common/VegaText';
import { receiptState } from '../../../features/receiptManagmentSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppSelector } from '../../../store';
import {
  DepositDto,
  DetailedReceiptsListProps,
  PaymentMode,
  ReceiptObjDto,
  ReceiptStatus,
  getPillColorForStatus,
} from '../../../types/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { toLowerCase } from '../../../constants/commonFunction';
import _ from 'lodash';
import { useClientAuth } from '../../../providers/ClientProvider';
import VegaButton from '../../../components/common/VegaButton';
import { useDrawer } from '../../../hooks/useDrawer';
import ReceiptApproveDialog from './Dialog/ReceiptApproveDialog';
import ReceiptRejectDialog from './Dialog/ReceiptRejectDialog';
import { COLOR } from '../../../utils/ColorUtility';

interface IProps {
  isAgent?: boolean;
  closeDialog?: () => void;
  isApprover?: boolean;
}

const ReceiptDetails = ({
  isAgent,
  closeDialog,
  isApprover = false,
}: IProps) => {
  const { receiptDetailedData } = useAppSelector(receiptState);
  const { userForId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [receiptData, setReceiptData] = useState<ReceiptObjDto | null>(null);
  const [depositData, setDepositData] = useState<DepositDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openImg, setOpenImg] = useState<boolean>(false);
  const [img, setImg] = useState<string>('');
  const {
    isOpen: isRejectDialogOpen,
    open: openRejectDialog,
    close: closeRejectDialog,
    props: rejectDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isApproveDialogOpen,
    open: openApproveDialog,
    close: closeApproveDialog,
    props: approveDialogProps,
  } = useDrawer(false);

  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  function getInstrumentDetails(receipt:  Partial<DetailedReceiptsListProps>) {
    switch (receipt?.paymentMeta?.paymentMode) {
      case PaymentMode.CHEQUE:
        if ('chequeNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.chequeNumber;
        }
        break;
      case PaymentMode.DD:
        if ('ddNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.ddNumber;
        }
        break;
      case 'PAYMENT_GATEWAY':
        if ('orderId' in receipt.paymentMeta) {
          return receipt.paymentMeta.orderId;
        }
        break;
      case PaymentMode.NEFT:
        if ('refNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.refNumber;
        }
        break;
      case PaymentMode.ELECTRONIC_FUND_TRANSFER:
        if ('refNumber' in receipt.paymentMeta) {
          return receipt.paymentMeta.refNumber;
        }
        break;
      default:
        return '--';
    }
  }
  const getReceipt = async () => {
    setLoading(true);
    try {
      const response = await ReceiptManagmentService.getReceiptByReceiptId(
        receiptDetailedData.receiptId
      );
      setReceiptData(response);
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  const getDeposit = async depositId => {
    try {
      const response = await ReceiptManagmentService.getDepositByDepositId(
        depositId
      );
      setDepositData(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getReceiptAmountBreckUp = () => {
    const receiptAmountDto = receiptData?.receiptAmountBreakUp;
    if (receiptData && receiptAmountDto) {
      return {
        overDueAmount: receiptAmountDto.overDueAmount
          ? StringUtility.formatToINR(receiptAmountDto.overDueAmount)
          : StringUtility.formatToINR(0),
        bounceCharges: receiptAmountDto.bounceCharges
          ? StringUtility.formatToINR(receiptAmountDto.bounceCharges)
          : StringUtility.formatToINR(0),
        penalCharges: receiptAmountDto.penalCharges
          ? StringUtility.formatToINR(receiptAmountDto.penalCharges)
          : StringUtility.formatToINR(0),
        legalCharges: receiptAmountDto.legalCharges
          ? StringUtility.formatToINR(receiptAmountDto.legalCharges)
          : StringUtility.formatToINR(0),
        otherCharges: receiptAmountDto.otherCharges
          ? StringUtility.formatToINR(receiptAmountDto.otherCharges)
          : StringUtility.formatToINR(0),
      };
    } else {
      return {
        overDueAmount: StringUtility.formatToINR(0),
        bounceCharges: StringUtility.formatToINR(0),
        penalCharges: StringUtility.formatToINR(0),
        legalCharges: StringUtility.formatToINR(0),
        otherCharges: StringUtility.formatToINR(0),
      };
    }
  };
  const getReceiptData = () => {
    if (receiptData) {
      return {
        agentName: getUserName(receiptData.agentId),
        payeeName: receiptData.payeeName
          ? toLowerCase(receiptData.payeeName)
          : 'NA',
        lan: receiptData.loanId ? receiptData.loanId : 'NA',
        receiptNumber: receiptDetailedData?.receiptNumber
          ? receiptDetailedData.receiptNumber
          : 'NA',
        receiptDate: receiptData.dateOfReceipt
          ? DateUtility.formatStringToDDMMMMYYYY(receiptData.dateOfReceipt)
          : 'NA',
        paymentMode: receiptData.paymentMode ? receiptData.paymentMode : 'NA',
      };
    } else {
      return {
        agentName: 'NA',
        payeeName: 'NA',
        lan: 'NA',
        receiptId: 'NA',
        receiptDate: 'NA',
        paymentMode: 'NA',
      };
    }
  };

  useEffect(() => {
    if (receiptDetailedData) {
      getReceipt();
      if (receiptDetailedData?.depositId) {
        getDeposit(receiptDetailedData.depositId);
      }
    }
  }, [receiptDetailedData]);
  return (
    <>
      <VegaDialogContent>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={12} marginBottom="0.75rem">
            <VegaText
              text={'Details'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '120%',
              }}
            />
          </Grid>
          {isAgent && (
            <Grid item xs={12}>
              <VegaDialogDisplayItem
                loading={loading}
                heading={'Agent Name'}
                value={getReceiptData().agentName}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Customer Name"
              loading={loading}
              value={getReceiptData().payeeName}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="LAN"
              loading={loading}
              value={getReceiptData().lan}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Receipt Number"
              loading={loading}
              value={getReceiptData().receiptNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Receipt Date"
              loading={loading}
              value={getReceiptData().receiptDate}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Deposit Center"
              loading={loading}
              value={depositData?.depositCentre ?? '--'}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Payment mode"
              loading={loading}
              value={getReceiptData().paymentMode}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Deposit Type"
              loading={loading}
              value={_.startCase(
                _.toLower(receiptDetailedData?.depositType) ?? 'NA'
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Deposit Reference Id"
              loading={loading}
              value={receiptDetailedData?.depositRefId ?? 'NA'}
            />
          </Grid> */}
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Instrument Number"
              loading={loading}
              value={getInstrumentDetails(receiptDetailedData)}
            />
          </Grid>
          {receiptData && (
            <Grid item xs={12}>
              <VegaDialogDisplayItem
                heading="Status"
                loading={loading}
                renderValue={() => {
                  const color = getPillColorForStatus(receiptData.status);
                  return (
                    <VegaText
                      text={receiptData.status}
                      fontWeight={500}
                      fontSize={'0.8125rem'}
                      color={color.DARK}
                    />
                  );
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Remarks"
              loading={loading}
              value={receiptData?.remarks ?? 'NA'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                receiptData?.status != ReceiptStatus.REJECTED ? 'none' : '',
            }}
          >
            <VegaDialogDisplayItem
              heading="Rejection Reason"
              loading={loading}
              value={receiptData?.rejectionReason ?? 'NA'}
            />
          </Grid>
        </Grid>

        <Grid container rowGap={'0.75rem'} my={'2rem'}>
          <Grid item xs={12} marginBottom="0.75rem">
            <VegaText
              text={'Receipt Amount'}
              style={{
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '120%',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="OverDue Amount"
              loading={loading}
              value={getReceiptAmountBreckUp().overDueAmount}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Bounce Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().bounceCharges}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Legal Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().legalCharges}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Penal Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().penalCharges}
            />
          </Grid>
          <Grid item xs={12}>
            <VegaDialogDisplayItem
              heading="Other Charges"
              loading={loading}
              value={getReceiptAmountBreckUp().otherCharges}
            />
          </Grid>
        </Grid>
        {receiptData &&
          (receiptData.documentImageUrl || receiptData.locationImageUrl) && (
            <Box>
              <VegaText
                text={'Receipt Proof'}
                sx={{
                  mt: '2rem',
                  mb: '1rem',
                  color: '#222',
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  lineHeight: '120%',
                }}
              />

              <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {receiptData.documentImageUrl && (
                  <Box
                    sx={{
                      maxWidth: '100px',
                      maxHeight: '100px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setImg(receiptData.documentImageUrl);
                      setOpenImg(true);
                    }}
                  >
                    <img
                      src={receiptData.documentImageUrl}
                      alt=""
                      width={'100%'}
                      height={'100%'}
                      style={{ borderRadius: '6px' }}
                    />
                  </Box>
                )}
                {receiptData.locationImageUrl && (
                  <Box
                    sx={{
                      maxWidth: '100px',
                      maxHeight: '100px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setImg(receiptData.locationImageUrl);
                      setOpenImg(true);
                    }}
                  >
                    <img
                      src={receiptData.locationImageUrl}
                      alt=""
                      width={'100%'}
                      height={'100%'}
                      style={{ borderRadius: '6px' }}
                    />
                  </Box>
                )}
              </Stack>
            </Box>
          )}
        {depositData && depositData.depositProofUrls.length ? (
          <Box>
            <VegaText
              text={'Deposit Proof'}
              sx={{
                mt: '2rem',
                mb: '1rem',
                color: '#222',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '120%',
              }}
            />

            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
              {depositData.depositProofUrls.map((img: string) => (
                <Box
                  key={img}
                  sx={{
                    maxWidth: '100px',
                    maxHeight: '100px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setImg(img);
                    setOpenImg(true);
                  }}
                >
                  <img
                    src={img}
                    alt=""
                    width={'100%'}
                    height={'100%'}
                    style={{ borderRadius: '6px' }}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        ) : (
          ''
        )}
        {isApprover &&
          receiptData?.status === ReceiptStatus.PENDING_APPROVAL && (
            <Stack direction={'row'} marginTop={5} gap={2}>
              <VegaButton
                text="Reject"
                variant="outlined"
                sx={{
                  color: COLOR.RED.DARK,
                  backgroundColor: COLOR.RED.LIGHT,
                  borderColor: COLOR.RED.DARK,
                  '&:hover': {
                    color: COLOR.RED.DARK,
                    backgroundColor: COLOR.RED.LIGHT,
                    borderColor: COLOR.RED.DARK,
                  },
                }}
                onClick={() => {
                  openRejectDialog({ depositId: receiptData.depositId });
                }}
              />
              <VegaButton
                text="Approve"
                variant="outlined"
                sx={{
                  color: COLOR.GREEN.DARK,
                  backgroundColor: COLOR.GREEN.LIGHT,
                  borderColor: COLOR.GREEN.DARK,
                  '&:hover': {
                    backgroundColor: COLOR.GREEN.LIGHT,
                    color: COLOR.GREEN.DARK,
                    borderColor: COLOR.GREEN.DARK,
                  },
                }}
                onClick={() => {
                  openApproveDialog({
                    depositId: receiptData.depositId,
                    receiptNumber: receiptDetailedData.receiptNumber,
                  });
                }}
              />
            </Stack>
          )}
      </VegaDialogContent>
      <LmDialog
        fullWidth
        maxWidth="sm"
        open={openImg}
        isCloseIcon
        handleClose={() => {
          setOpenImg(false);
          setImg('');
        }}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="View Proof"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <Box>
            <img
              src={img}
              alt=""
              width={'100%'}
              height={'100%'}
              style={{ borderRadius: '6px' }}
            />
          </Box>
        </VegaDialogContent>
      </LmDialog>
      <ReceiptApproveDialog
        open={isApproveDialogOpen}
        depositId={approveDialogProps.depositId}
        receiptNumber={approveDialogProps.receiptNumber}
        onClose={function (): void {
          closeApproveDialog();
        }}
        onApproveClose={() => closeDialog()}
      />
      <ReceiptRejectDialog
        open={isRejectDialogOpen}
        depositId={rejectDialogProps.depositId}
        onRejectClose={() => closeDialog()}
        onClose={function (): void {
          closeRejectDialog();
        }}
      />
    </>
  );
};

export default ReceiptDetails;
