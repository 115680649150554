import { Tab, TabProps } from '@mui/material';
interface LosTabBarItemProps extends TabProps {}

function VegaTabBarItem({ ...rest }: LosTabBarItemProps) {
  const { color = 'rgba(16, 71, 220, 1)' } = rest;

  return (
    <Tab
      className={`font-aspekta-400`}
      sx={{
        '&.Mui-selected': {
          color: { color },
        },
        color: '#3A4A5F',
        fontSize: '.8125rem',
        fontWeight: 700,
        textTransform: 'none',
        minHeight: 'auto',
        paddingBottom: '8px',
        paddingInline: '4px',
        minWidth: 'auto',
        marginRight: '20px',
      }}
      {...rest}
    />
  );
}

export default VegaTabBarItem;
