import { Box, Stack } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AnaltyticsService } from '../../../Apis/AnaltyticsService';
import VegaText from '../../../components/common/VegaText';
import {
  BucketEnumForYearAnalytics,
  CelenderTypeEmun,
} from '../../../features/analyticsDashboardSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  AnalyticsQueryForBucketWiseResponse,
  BucketQueryProps,
} from '../../../types/analytics';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import GraphWrapper from '../../AnalyticsDashboard/components/GraphWrapper';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { CollectionCommonProps } from '../AnalyticsDashboard';

interface IProps extends CollectionCommonProps {
  calenderType: CelenderTypeEmun;
  selectedCollectionProfitType: string;
}

const TeleCallersGraph = ({
  request,
  calenderType,
  selectedCollectionProfitType,
}: IProps) => {
  const { setSnackbar } = useSnackbar();

  const [analyticsQueryResponse, setAnalyticsQueryResponse] = useState<
    Partial<AnalyticsQueryForBucketWiseResponse>
  >({});
  const [loading, setLoading] = useState<boolean>(false);

  const modifyAnalyticsQuery = [
    'OVERALL',
    'BUCKETX',
    'BUCKET1',
    'BUCKET2',
    'BUCKET3',
    'NPA',
  ].map(value => ({
    categories: BucketEnumForYearAnalytics[value],
    series: analyticsQueryResponse[value],
  }));

  const toDayseries = [
    {
      name: 'Achieved',
      data: modifyAnalyticsQuery?.map(value => value?.series?.pos ?? 0),
    },
    {
      name: 'Target',
      data: modifyAnalyticsQuery?.map(value => value?.series?.target ?? 0),
    },
  ];
  const monthSeries = [
    {
      name: 'MTD',
      data: modifyAnalyticsQuery?.map(
        value => value?.series?.monthToDateDrr ?? 0
      ),
    },
    {
      name: 'LMSD',
      data: modifyAnalyticsQuery?.map(
        value => value?.series?.lastMonthSameDateDrr ?? 0
      ),
    },
  ];
  const yearSeries = [
    {
      name: 'YTD',
      data: modifyAnalyticsQuery?.map(
        value => value?.series?.yearToDateDrr ?? 0
      ),
    },
    {
      name: 'LYSD',
      data: modifyAnalyticsQuery?.map(
        value => value?.series?.lastYearSameDateDrr ?? 0
      ),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      formatter: function (val: number) {
        return val.toFixed(2);
      },
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      //   console.log('series', series, w);
      //   return (
      //     '<div class="custom-tooltip">' +
      //     '<span>' +
      //     w.globals.seriesNames[0] +
      //     '</span>' +
      //     '<span>' +
      //     w.globals.seriesNames[1] +
      //     '</span>' +
      //     '<span>' +
      //     series[seriesIndex][dataPointIndex] +
      //     '</span>' +
      //     '</div>'
      //   );
      // },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      markers: {},
    },
    xaxis: {
      categories: modifyAnalyticsQuery?.map(value => value.categories),
    },
  };

  const getTeleCallerQueries = async () => {
    setLoading(true);
    try {
      const response = await AnaltyticsService.getAnalyticsQueries(request);

      if (response) {
        setAnalyticsQueryResponse(response);
      } else {
        // setAnalyticsQueryResponse(defaultBucketQueries);
      }
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      // setAnalyticsQueryResponse(defaultBucketQueries);
      setLoading(false);
    }
  };
  function getTitletext() {
    return calenderType === CelenderTypeEmun.MONTH
      ? 'Tele callers (in %)'
      : calenderType === CelenderTypeEmun.YEAR
      ? 'Tele callers (in %)'
      : 'Tele callers (in crores)';
  }

  useEffect(() => {
    getTeleCallerQueries();
  }, [selectedCollectionProfitType]);

  return (
    <>
      <GraphWrapper title={getTitletext()} onClick={() => {}}>
        {loading ? (
          <LoadingPage height={350} />
        ) : (
          <Stack sx={{ height: '21.875rem' }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: -8,
                  top: 30,
                  height: '76%',
                  width: 40,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {modifyAnalyticsQuery.map((val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: '1',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flex: '1' }}>
                          {getPercentageValue(val.series)}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Chart
                options={options}
                series={
                  calenderType === CelenderTypeEmun.MONTH
                    ? monthSeries
                    : calenderType === CelenderTypeEmun.YEAR
                    ? yearSeries
                    : toDayseries
                }
                type="bar"
                height={'100%'}
              />
            </Box>
          </Stack>
        )}
      </GraphWrapper>
    </>
  );
};

export default TeleCallersGraph;

const getPercentageValue = (query: BucketQueryProps) => {
  const percentage = query?.percentageChange
    ? Number(query.percentageChange.toFixed(2))
    : 0;
  if (percentage > 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'green'}
        text={` ${percentage} %`}
      />
    );
  } else if (percentage === 0) {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        text={` ${percentage} %`}
      />
    );
  } else {
    return (
      <VegaText
        fontWeight={'600'}
        fontSize={'0.6rem'}
        color={'red'}
        text={` ${Math.abs(percentage)} %`}
      />
    );
  }
};
