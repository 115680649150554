import { Stack } from '@mui/material';
import _ from 'lodash';
import { fetchProcessList } from '../../../actions/TeleAllocationAction';
import { useAppDispatch } from '../../../store';
import { GetProcessListApiRequest } from '../../../types/request/teleAllocation';
import { ProcessType } from '../../../types/teleAllocation';
import VegaButton from '../../common/VegaButton';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaRadioGroup, { VegaRadioOption } from '../../common/VegaRadioGroup';

interface IProps {
  onClose: () => void;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  selectedStatus: string;
  page: number;
  size: number;
}

const ProcessFilterForm = ({
  onClose,
  selectedStatus,
  page,
  size,
  setSelectedStatus,
}: IProps) => {
  const dispatch = useAppDispatch();
  const onClearClick = () => {
    onClose();
    setSelectedStatus('');
    const request = {
      page,
      size,
    } as GetProcessListApiRequest;

    dispatch(fetchProcessList(request));
  };
  const onSelectClick = () => {
    const request = {
      page,
      size,
      callingMode: selectedStatus === ProcessType.AUTO ? 3 : 1,
    } as GetProcessListApiRequest;

    dispatch(fetchProcessList(request));
    onClose();
  };
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClearClick}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton
              text="Apply Filter"
              onClick={onSelectClick}
              disabled={!selectedStatus.length}
            />
          </Stack>
        );
      }}
    >
      <Stack gap={2}>
        <VegaFormInputField label={'Status'}>
          <VegaRadioGroup
            options={getStatusOption()}
            value={selectedStatus}
            onChange={e => {
              setSelectedStatus(e.target.value);
            }}
          />
        </VegaFormInputField>
      </Stack>
    </VegaDrawerContent>
  );
};

export default ProcessFilterForm;

const getStatusOption = () =>
  Object.values(ProcessType).map(item => {
    const option: VegaRadioOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
