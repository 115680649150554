import { createContext, useContext, useState } from 'react';
import { ExpenseReport } from '../types/claim';

type ExpenseModuleContextType = {
  selectedReport: ExpenseReport | null;
  selectReport: (report: ExpenseReport | null) => void;
};

const ExpenseModuleContext = createContext<ExpenseModuleContextType | null>(
  null
);

export const useExpenseModule = () =>
  useContext(ExpenseModuleContext) as ExpenseModuleContextType;

export const ExpenseModuleProvider = ({ children }: any) => {
  const [selectedReport, setSelectedReport] = useState<ExpenseReport | null>(
    null
  );

  function _selectReport(report: ExpenseReport | null) {
    setSelectedReport(report);
  }

  return (
    <ExpenseModuleContext.Provider
      value={{
        selectedReport: selectedReport,
        selectReport: _selectReport,
      }}
    >
      {children}
    </ExpenseModuleContext.Provider>
  );
};
