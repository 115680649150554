import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AmountType, ConditionType } from '../../../types/incentives';
import {
  BumperCondition,
  BumperPolicyRangeProps,
  IncentivePolicyRulesRequest,
} from '../../../types/request/incentives';
import { DeleteIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';
import { conditionObj } from './BumperIncentiveComponent';

interface IBumperProps {
  bumperInsentive: BumperPolicyRangeProps;
  index: number;
  onDelete: (index: number) => void;
}

const BumperIncentive = ({
  bumperInsentive,
  index,
  onDelete,
}: IBumperProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId, edit } = useParams();

  const handleBumperIncentiveChange = (
    key: keyof BumperPolicyRangeProps,
    value: any
  ) => {
    const spreadBumperIncentivePolicy = [...policyRuleState.bumperPayoutRange];
    const bumperIncentiveObj = {
      ...bumperInsentive,
      [key]: value,
    };
    spreadBumperIncentivePolicy.splice(index, 1, bumperIncentiveObj);
    const basePoliciyRule = {
      ...policyRuleState,
      bumperPayoutRange: spreadBumperIncentivePolicy,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(basePoliciyRule));
  };

  const handleConditionChange = (
    key: keyof BumperCondition,
    value: string | number,
    index: number
  ) => {
    const conditions = [...bumperInsentive.condition];
    const existingCodition = { ...bumperInsentive.condition[index] };
    const updatedCondition = {
      ...existingCodition,
      [key]: value,
    };
    conditions.splice(index, 1, updatedCondition);
    handleBumperIncentiveChange('condition', conditions);
  };

  const addCondition = () => {
    const updateCondition = [...bumperInsentive.condition, conditionObj];
    handleBumperIncentiveChange('condition', updateCondition);
  };
  const onDeleteCondition = (index: number) => {
    const existingConditions = [...bumperInsentive.condition];
    existingConditions.splice(index, 1);
    handleBumperIncentiveChange('condition', existingConditions);
  };
  return (
    <Stack
      sx={{
        borderRadius: '12px',
        p: 2,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
        mb: 1,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
        {edit && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Stack mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <VegaFormInputField label={'amount type'}>
              <VegaSelect
                options={amountTypeOption()}
                value={bumperInsentive.amountType}
                onChange={e => {
                  const value = e.target.value as string;
                  handleBumperIncentiveChange('amountType', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <VegaFormInputField label={'amount value'}>
              <LmTextField
                type="number"
                placeholder="Min"
                value={bumperInsentive.amountValue}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handleBumperIncentiveChange('amountValue', value);
                }}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </Stack>
      {bumperInsentive.condition.map((condition: BumperCondition, index) => (
        <Stack
          key={index}
          sx={{
            borderRadius: '12px',
            p: 2,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 0px 7px 0px',
            mb: 1,
          }}
        >
          <>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={1}
            >
              <VegaText
                text={`Condition ${index + 1}`}
                fontWeight={500}
                fontSize={13}
                color={'#202020'}
              />
              {!policyId && index !== 0 && (
                <IconButton onClick={() => onDeleteCondition(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
              {edit && index !== 0 && (
                <IconButton onClick={() => onDeleteCondition(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <VegaFormInputField label={'condition type'}>
                  <VegaSelect
                    options={conditionTypeOption()}
                    value={condition.conditionType}
                    onChange={e => {
                      const value = e.target.value as string;
                      handleConditionChange('conditionType', value, index);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <VegaFormInputField label={'value'}>
                  <LmTextField
                    type="number"
                    placeholder="Value"
                    value={condition.conditionValue}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleConditionChange('conditionValue', value, index);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <VegaFormInputField label={'cut of day of month'}>
                  <LmTextField
                    type="number"
                    placeholder="Value"
                    value={condition.conditionCutoffDayOfMonth}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleConditionChange(
                        'conditionCutoffDayOfMonth',
                        value,
                        index
                      );
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'start'}>
                  <VegaButton text="Add condition" onClick={addCondition} />
                </Stack>
              </Grid>
            </Grid>
          </>
        </Stack>
      ))}
    </Stack>
  );
};

export default BumperIncentive;

const conditionTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(ConditionType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const amountTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(AmountType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};
