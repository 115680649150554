import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchLoanAccountList } from '../../../actions/TeleAgentAction';
import { OtsService } from '../../../Apis/OtsService';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import {
  fetchSarfaesiMisDeatils,
  fetchArbitationDeatils,
  fetchSecDeatils,
} from '../../../features/legalSlice';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { LoanData } from '../../../types/loan';
import { TimeUnit } from '../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
};

enum FormField {
  SettlementAmount = 'settlementAmount',
  Tenure = 'tenure',
  RequestLetter = 'requestLetter',
}

export type RaiseOtsFormData = {
  settlementAmount: number;
  tenure: number;
  requestLetter: VegaFileSelectOption;
};

function RaiseOtsDrawer({ open, onClose }: Props) {
  const { loanData } = useAppSelector(getTeleAgent);
  const [loan, setLoan] = useState<LoanData>();
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [formData, setFormData] = useState<Partial<RaiseOtsFormData>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  async function onRaiseClick() {
    try {
      if (loanData.length <= 0) return;
      setLoading(true);
      const loanId = loanData[0].loanId;
      await OtsService.raiseOtsRequest({
        loanId: loanId,
        userId: userId,
        settlementAmount: formData.settlementAmount,
        tenure: formData.tenure,
        requestLetter: formData.requestLetter.value,
        wavierAmount: getWavierAmount(),
        timeUnit: TimeUnit.MONTH,
      });
      setSnackbar('Ots Raised');

      dispatch(
        fetchLoanAccountList({
          loanId: loanId,
          includeCustomerDetails: true,
          includeLmsVariables: true,
          includeLoanOutstandingDetails: true,
          includeLoanTransactions: true,
          includeLoanPdcDetails: true,
        })
      );
      dispatch(fetchSarfaesiMisDeatils({ caseTypeId: '1', lanNo: loanId }));
      dispatch(fetchArbitationDeatils({ caseTypeId: '5', lanNo: loanId }));
      dispatch(fetchSecDeatils({ caseTypeId: '4', lanNo: loanId }));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
      handleClose();
    }
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
  }

  const isInputValid = () => {
    const isTenureValid = (formData.tenure ?? 0) > 0;
    const isAmountValid = (formData.settlementAmount ?? 0) > 0;
    const isAmountLessThanOutstandingAmount =
      (formData.settlementAmount ?? 0) <
        loan?.lmsVariables?.outStandingTotal ?? 0;
    const isRequestLetterValid = formData?.requestLetter?.value != null;
    return (
      isTenureValid &&
      isAmountValid &&
      isRequestLetterValid &&
      isAmountLessThanOutstandingAmount
    );
  };

  const parseNumberValue = (value: any) => {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    return isValidNumber ? parsedNumber : undefined;
  };

  const getWavierAmount = () => {
    return (
      (loan?.lmsVariables?.outStandingTotal ?? 0) -
      (formData.settlementAmount ?? 0)
    );
  };

  useEffect(() => {
    if (loanData.length > 0) {
      const firstLoan = loanData[0];
      if (firstLoan?.loanId != loan?.loanId) {
        setLoan(firstLoan);
      }
    }
  }, [loanData]);

  return (
    <VegaDrawer open={open} title={'Raise OTS Request'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Raise OTS'}
              disabled={isInputValid() == false}
              onClick={onRaiseClick}
              loading={loading}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                gap: '10px',
                borderRadius: '8px',
                backgroundColor: '#F7F7F7',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems={'space-between'}
              >
                <VegaText text={'Total Outstanding Amount'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.outStandingTotal ?? 0
                  )}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="space-between">
                <VegaText text={'Current Principal Amount'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.outstandingAmount ?? 0
                  )}
                />
              </Stack>
              <Stack direction={'row'} justifyContent="space-between">
                <VegaText text={'Total Overdue Amount'} />
                <VegaText
                  text={StringUtility.formatToINR(
                    loan?.lmsVariables?.overdueAmount ?? 0
                  )}
                />
              </Stack>
            </div>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Settlement Amount'}>
              <LmTextField
                value={formData.settlementAmount ?? ''}
                onChange={e => {
                  updateFormData(FormField.SettlementAmount, e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Tenure (In Months)'}>
              <LmTextField
                value={formData.tenure ?? ''}
                onChange={e => {
                  updateFormData(
                    FormField.Tenure,
                    parseNumberValue(e.target.value)
                  );
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Request Letter'}>
              <VegaFileSelect
                value={formData.requestLetter}
                acceptedExtension={[
                  AcceptedExtension.JPG,
                  AcceptedExtension.JPEG,
                  AcceptedExtension.PNG,
                  AcceptedExtension.PDF,
                ]}
                placeholder={'Upload request letter'}
                onFileSelect={file => {
                  updateFormData(FormField.RequestLetter, file);
                }}
                onDeleteClick={() => {
                  updateFormData(FormField.RequestLetter, undefined);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                gap: '10px',
                borderRadius: '8px',
                backgroundColor: '#EBF4EC',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems={'space-between'}
              >
                <VegaText text={'Total waiver amount will be'} />
                <VegaText text={StringUtility.formatToINR(getWavierAmount())} />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default RaiseOtsDrawer;
