import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import VegaText from '../../../../components/common/VegaText';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { FeedbackResponse } from '../../../../types/trail';
import { DateUtility } from '../../../../utils/DateUtlility';

const FeedbackColumn = () => {
  const { userForId } = useClientAuth();
  const getUserName = (userId: string) => {
    if (!userId) return 'NA';
    const user = userForId(userId);
    if (!user) return 'NA';
    return user;
  };
  const COLUMN_DEF: GridColumns = [
    {
      field: 'managerId',
      headerName: 'Manager',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const feedbacks = props.row as FeedbackResponse;
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={getUserName(feedbacks.managerId)}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={getUserName(feedbacks.managerId)}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const feedbacks = props.row as FeedbackResponse;
        const comment = feedbacks.comment ? feedbacks.comment : '--';
        return (
          <VegaIconButton
            sx={{ width: '100%', textAlign: 'start' }}
            tooltipTitle={comment}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={comment}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'rating',
      headerName: 'Rating',
      flex: 0.6,
      minWidth: 109,
    },

    {
      field: 'createdAt',
      headerName: 'Date & Time ',
      flex: 1,
      minWidth: 129,
      renderCell: props => {
        const feedbacks = props.row as FeedbackResponse;
        const date = feedbacks.createdAt
          ? DateUtility.formatStringToDDMMYYYYWithTime(feedbacks.createdAt)
          : '--';
        return (
          <VegaIconButton
            tooltipTitle={date}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={date}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

export default FeedbackColumn;
