import { Stack } from '@mui/material';
import ListOfMinimumVisits from '../../../components/Escalations/ListOfMinimumVisits';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { ROUTES } from '../../../router/routes';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { EscalationsServices } from '../../../Apis/EscalationsServices';
import { EscalationTriggerType } from '../../../types/request/escalationsRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import VegaButton from '../../../components/common/VegaButton';
export enum MinimumVisitsType {
  MINIMUM_VISIT_UNFULFILLED = 'Min Visits Unfulfilled',
}

const MinimumVisits = () => {
  const { setSnackbar } = useSnackbar();

  const triggerEscalation = () => {
    EscalationsServices.triggerEscalation({
      triggerType: EscalationTriggerType.MIN_VISIT,
    })
      .then(res => {})
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <VegaPageHeader
        sx={{
          pb: 0,
        }}
        renderLeftView={() => {
          return (
            <Stack gap={1} width={'100%'}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <VegaBreadCrumb
                  items={[
                    { label: 'Escalation', link: ROUTES.ESCALATIONS },
                    { label: `Minimum Visits Unfulfilled` },
                  ]}
                />
                <VegaButton
                  text="Trigger Escalation"
                  onClick={triggerEscalation}
                />
              </Stack>
            </Stack>
          );
        }}
      />
      <ListOfMinimumVisits />
    </>
  );
};

export default MinimumVisits;
