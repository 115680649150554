import { Box, Skeleton, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { COMMON } from '../../constants/style';
import VegaText from './VegaText';

type Props = {
  heading: string;
  value?: string | number;
  renderValue?: () => ReactNode;
  loading?: boolean;
  direction?: 'vertical' | 'horizontal';
};
function VegaKeyValueDisplay({
  heading,
  value,
  renderValue,
  loading = false,
  direction = 'horizontal',
}: Props) {
  const [show, setShow] = useState(false);
  return (
    <Stack
      direction={direction == 'horizontal' ? 'row' : 'column'}
      sx={{ alignItems: 'flex-end' }}
      gap="1rem"
    >
      <VegaText
        text={heading}
        fontWeight={450}
        fontSize={'0.8125rem'}
        sx={{ mt: '0.25rem !important' }}
        color={COMMON.light}
      />

      {loading == true && <Skeleton width={'100%'} />}

      {renderValue == null && (
        <Box width={'60%'} sx={{ position: 'relative' }}>
          <VegaText
            text={value ?? '-'}
            fontWeight={500}
            fontSize={'0.8125rem'}
            color={COMMON.balck}
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              cursor: 'pointer',
            }}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          />
          {/* {show && (
            <Box
              sx={{
                position: 'absolute',
                top: 15,
                bgcolor: 'black',
                p: 1,
                borderRadius: '6px',
              }}
            >
              <VegaText
                text={value ?? '-'}
                fontWeight={500}
                fontSize={'0.8125rem'}
                color={'white'}
              />
            </Box>
          )} */}
        </Box>
      )}

      {renderValue?.()}
    </Stack>
  );
}

export default VegaKeyValueDisplay;
