import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_GATEWAY } from '../Apis/axisConfig';
import { Possession } from '../types/auction';
import { GetPossessionListApiRequest } from '../types/request/possession';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/repossession',
  LIST: '/list',
  AUCTION: '/auction',
};

export const propertiesApi = createApi({
  reducerPath: 'propertiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Properties'],
  endpoints: builder => ({
    fetchPropertiesList: builder.query({
      query: (request: Partial<GetPossessionListApiRequest>) => {
        const bodyReq = { ...request };
        for (const key in bodyReq) {
          if (typeof bodyReq[key] === 'string') {
            if (bodyReq[key] === '') {
              delete bodyReq[key];
            }
          }
        }
        return {
          url: Endpoint.BASE + Endpoint.LIST,
          method: 'POST',
          body: bodyReq,
        };
      },
      transformResponse: (response: PaginatedResponse<Possession>) => {
        return {
          records: response.records.length
            ? response.records.map((propertie: Possession) => ({
                id: propertie.id,
                auctionId: propertie.auctionId,
                propertyName: propertie.id,
                type: propertie.type,
                images: propertie.images,
                lan: propertie.lan,
                reservePrice: propertie.metaData
                  ? propertie.metaData.auction.reservePrice
                  : 0,
                address: propertie.address,
                totalBidder: propertie.numberOfBidders || 0,
                status: propertie.metaData
                  ? propertie.metaData.auction.status
                  : '',
                minIncrement: propertie.metaData
                  ? propertie.metaData.auction.minIncrement
                  : 0,
              }))
            : [],
          pageNumber: response.pageNumber,
          numberOfItems: response.numberOfItems,
          totalPages: response.totalPages,
          totalItems: response.totalItems,
        };
      },
      providesTags: ['Properties'],
    }),

    addNewPropertise: builder.mutation({
      query: data => {
        const formValue = new FormData();
        formValue.append('address', data.address);
        formValue.append('lan', data.lan);
        formValue.append('type', data.type);
        formValue.append('possessionDateTime', data.possessionDateTime);
        return {
          url: Endpoint.BASE,
          method: 'POST',

          body: formValue,
        };
      },
      invalidatesTags: ['Properties'],
    }),
    addAuctionPropertise: builder.mutation({
      query: data => ({
        url: Endpoint.AUCTION,
        method: 'POST',

        body: data,
      }),
      invalidatesTags: ['Properties'],
    }),
    updateAuctionPropertise: builder.mutation({
      query: data => ({
        url: Endpoint.AUCTION,
        method: 'PUT',

        body: data,
      }),
      invalidatesTags: ['Properties'],
    }),
  }),
});

export const {
  useFetchPropertiesListQuery,
  useAddNewPropertiseMutation,
  useAddAuctionPropertiseMutation,
  useUpdateAuctionPropertiseMutation,
} = propertiesApi;
