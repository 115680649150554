import React from 'react';
import { Criteria } from '../../types/allocations';
import VegaFormInputField from '../common/VegaFormInputField';
import { Box, Grid, Skeleton } from '@mui/material';
import VegaText from '../common/VegaText';
import _ from 'lodash';
import { StringUtility } from '../../utils/StringUtility';

interface IProps {
  criteria: Criteria;
  loading: boolean;
}

const AttributeDetails = ({ criteria, loading }: IProps) => {
  return (
    <Box mb={'1rem'}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'attribute Name'}>
            {getTextLoadingWrapper(
              StringUtility.camelCaseToTitle(
                getCriteriaValue(criteria?.attributeName)
              ),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'op'}>
            {getTextLoadingWrapper(getCriteriaValue(criteria?.op), loading)}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'value'}>
            {getTextLoadingWrapper(
              getCriteriaValue(criteria?.value as string | number),
              loading
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttributeDetails;

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return (
      <VegaText
        text={_.startCase(_.toLower(value))}
        fontWeight={500}
        fontSize={'0.875rem'}
      />
    );
  }
};

const getCriteriaValue = (value: string | number) => {
  if (!value) return '--';
  const formattedValue = typeof value === 'number' ? value.toString() : value;
  if (formattedValue.length) {
    return formattedValue;
  } else {
    return '--';
  }
};
