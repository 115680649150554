import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
} from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IncentivePolicyProps } from '../../../types/incentives';
import { VegaSelectOption } from '../../common/VegaSelect';
import VegaSelectWithCheckbox from '../../common/VegaSelectWithCheckbox';

const AccountTypeSelect = () => {
  const { policyDetailsState, policyEnumValues } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();

  const handlePolicyChange = (
    key: keyof IncentivePolicyProps,
    value: string | number | string[]
  ) => {
    const policyDetails = {
      ...policyDetailsState,
      [key]: value,
    } as IncentivePolicyProps;
    dispatch(setPolicyDetailsState(policyDetails));
  };

  const onSelectAccountType = (type: string) => {
    const accountType = policyDetailsState?.accountType ?? [];
    if (accountType?.includes(type)) {
      const filteredAccountType = accountType.filter(
        (selectedUser: string) => selectedUser !== type
      );

      handlePolicyChange('accountType', filteredAccountType);
    } else {
      handlePolicyChange('accountType', [...accountType, type]);
    }
  };
  const onDeleteAccountType = (index: number) => {
    const accountType = policyDetailsState?.accountType ?? [];
    const communications = [...accountType];
    communications.splice(index);
    handlePolicyChange('accountType', communications);
  };
  return (
    <>
      {policyEnumValues?.ACCOUNT_TYPES?.length && (
        <VegaSelectWithCheckbox
          options={accountTypeOption(policyEnumValues?.ACCOUNT_TYPES)}
          onSelect={selected => onSelectAccountType(selected)}
          handleDelete={selectedIndex => onDeleteAccountType(selectedIndex)}
          selected={policyDetailsState?.accountType}
          placeHolder="Select Account type"
        />
      )}
    </>
  );
};

export default AccountTypeSelect;

const accountTypeOption = (accountTypeEnums: string[]) => {
  if (accountTypeEnums) {
    const options: VegaSelectOption[] = accountTypeEnums.map(
      (item: string) => ({
        value: item,
        label: item,
      })
    );
    return options;
  }
};
