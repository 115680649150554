export interface AreaListProps {
  id: string;
  branch: string;
  region: string;
}
export interface CreateMinVisitRuleProps {
  branch: string;
  region: string;
  minVisits: number;
}
export interface CreateVisits {
  agentId: string;
  loanId: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

export interface RescheduleVisit {
  id: string;
  agentId: string;
  loanId: string;
  location: {
    latitude: number;
    longitude: number;
  };
  agentRemarks: string;
  supervisorRemarks: string | null;
  visitStatus: string;
  scheduledDate: string;
  visitDate: string;
  loanTrailId: string;
  receiptId: string;
  locationTagType: string;
  locationApprovalStatus: string;
  createdAt: string;
  updatedAt: string;
  reVisitReferenceId: string;
}

export enum BucketType {
  BUCKETX = 'BUCKET_X',
  BUCKET1 = 'BUCKET_1_30',
  BUCKET2 = 'BUCKET_31_60',
  BUCKET3 = 'BUCKET_61_90',
  NPA = 'BUCKET_NPA',
}
export enum BucketOption {
  NORMAL = 'NORMAL',
  BUCKETX = 'Bucket-X',
  BUCKET1 = 'Bucket 1-30',
  BUCKET2 = 'Bucket 31-60',
  BUCKET3 = 'Bucket 61-90',
  NPA = 'NPA',
}
