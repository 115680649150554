import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoanService } from '../Apis/LoanServices';
import { ReceiptManagmentService } from '../Apis/ReceiptManagmentService';
import { RootState } from '../store';
import { LoanData } from '../types/loan';
import {
  AccountAgentViewModel,
  DetailedReceiptsListProps,
  IssueReceiptProps,
  ReceiptCancelListProps,
  ReceiptManagmentViewModel,
  ReceiptsListProps,
} from '../types/receiptManagment';
import { LoanListRequest } from '../types/request/loan';
import {
  GetReceiptsCancelListRequest,
  GetReceiptsListRequest,
} from '../types/request/receiptManagment';
import { DateUtility } from '../utils/DateUtlility';
import { StringUtility } from '../utils/StringUtility';
import { DateRangeFilter } from '../components/ExpenseClaim/FilterInput';

const issueReceiptData = {
  agentId: '',
  loanId: '',
  mobileNumber: '',
  email: '',
  documentImage: null,
  payeeName: '',
  relationshipWithCustomer: '',
  totalAmount: 0,
  paymentMeta: {},
  receiptAmountBreakUp: {
    overDueAmount: 0,
    bounceCharges: 0,
    penalCharges: 0,
    legalCharges: 0,
    otherCharges: 0,
  },
  panCard: '',
  remarks: '',
} as IssueReceiptProps;

export enum PaymentStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface IErrorProps {
  email: string;
  mobileNumber: string;
  chequeNumber: string;
  ifscCode: string;
  bankName: string;
  branchName: string;
  ddNumber: string;
  refNumber: string;
}

export interface IErrorStateProps {
  error: IErrorProps;
  isValid: boolean;
}

const errorState = {
  error: {
    mobileNumber: '',
    email: '',
    chequeNumber: '',
    ifscCode: '',
    bankName: '',
    branchName: '',
    ddNumber: '',
    refNumber: '',
  },
  isValid: false,
} as IErrorStateProps;

export interface ReceiptState {
  receipts: ReceiptManagmentViewModel[];
  receiptsDetailed: DetailedReceiptsListProps[];
  loanAccounts: AccountAgentViewModel[];
  issueReceipt: IssueReceiptProps;
  cancelReceipts: ReceiptCancelListProps[];
  receiptData: Partial<ReceiptManagmentViewModel>;
  receiptDetailedData: Partial<DetailedReceiptsListProps>;
  cancelReceiptData: Partial<ReceiptCancelListProps>;
  paymentStatusData: string;
  orderId: string;
  loanId: string;
  page: number;
  errorState: IErrorStateProps;
  totalItems: number;
  totalItemsOfReceiptCancel: number;
  totalItemsOfReceiptDetailed: number;
  pageSize: number;
  openApproval: boolean;
  openViewProof: boolean;
  openReject: boolean;
  loading: boolean;
  loanAccountloading: boolean;
  loadingOfReceiptCancel: boolean;
  loadingOfReceiptDetailed: boolean;
  paymentStatusLoading: boolean;
  error: string | null;
  errorOfReceiptCancel: string | null;
  errorOfReceiptDetailed: string | null;
  paymentStatusError: string | null;
  historyReceiptManagementFilter: {
    dateFilter: DateRangeFilter;
    statusFilter: string;
  };
}

const getLoanIdFromLocalstrorage = localStorage.getItem('loanId')
  ? localStorage.getItem('loanId')
  : '';
const getOrderIdFromLocalstrorage = localStorage.getItem('orderId')
  ? localStorage.getItem('orderId')
  : '';

const initialState: ReceiptState = {
  receipts: [],
  receiptsDetailed: [],
  cancelReceipts: [],
  loanAccounts: [],
  receiptData: {},
  receiptDetailedData: {},
  cancelReceiptData: {},
  orderId: getOrderIdFromLocalstrorage,
  loanId: getLoanIdFromLocalstrorage,
  paymentStatusData: '',
  errorState: errorState,
  issueReceipt: issueReceiptData,
  loading: false,
  loanAccountloading: false,
  loadingOfReceiptCancel: false,
  loadingOfReceiptDetailed: false,
  paymentStatusLoading: false,
  openApproval: false,
  openReject: false,
  openViewProof: false,
  error: null,
  errorOfReceiptCancel: null,
  errorOfReceiptDetailed: null,
  paymentStatusError: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
  totalItemsOfReceiptCancel: 0,
  totalItemsOfReceiptDetailed: 0,
  historyReceiptManagementFilter: {
    dateFilter: { endDate: '', startDate: '' },
    statusFilter: '',
  },
};

export const getReceiptsList = createAsyncThunk(
  'receipt/list',
  async (data: Partial<GetReceiptsListRequest>) => {
    const response = await ReceiptManagmentService.getReceiptsList(data);
    return response;
  }
);

export const getDetailedReceiptsList = createAsyncThunk(
  'receipt/list/detailed',
  async (data: Partial<GetReceiptsListRequest>) => {
    const response = await ReceiptManagmentService.getDetailedReceiptsList(
      data
    );
    return response;
  }
);

export const getLoanAccountList = createAsyncThunk(
  'receipt/loan/list',
  async (data: LoanListRequest) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);
export const getPaymentStatus = createAsyncThunk(
  'receipt/paymentStatus',
  async (orderId: string) => {
    const response = await ReceiptManagmentService.getPaymentStatusByOrderId(
      orderId
    );
    return response;
  }
);
export const getReceiptCancelList = createAsyncThunk(
  'receipt/cancel',
  async (data: Partial<GetReceiptsCancelListRequest>) => {
    const response = await ReceiptManagmentService.getReceiptsCancellationList(
      data
    );
    return response;
  }
);

const receiptManagmentSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    setErrorState: (state: ReceiptState, action) => {
      const { payload } = action;
      state.errorState = payload;
    },
    setReceiptData: (state: ReceiptState, action) => {
      const { payload } = action;
      state.receiptData = payload;
    },
    setReceiptDetailedData: (state: ReceiptState, action) => {
      const { payload } = action;
      state.receiptDetailedData = payload;
    },
    setCancelReceiptData: (state: ReceiptState, action) => {
      const { payload } = action;
      state.cancelReceiptData = payload;
    },
    setIssueReceipt: (state: ReceiptState, action) => {
      const { payload } = action;
      state.issueReceipt = payload;
    },
    setOpenApproval: (state: ReceiptState, action) => {
      const { payload } = action;
      state.openApproval = payload;
    },
    setOpenReject: (state: ReceiptState, action) => {
      const { payload } = action;
      state.openReject = payload;
    },
    setOpenViewProof: (state: ReceiptState, action) => {
      const { payload } = action;
      state.openViewProof = payload;
    },
    setOrderId: (state: ReceiptState, action) => {
      const { payload } = action;
      state.orderId = payload;
      localStorage.setItem('orderId', payload);
    },
    setLoanId: (state: ReceiptState, action) => {
      const { payload } = action;
      state.loanId = payload;
      localStorage.setItem('loanId', payload);
    },
      setHistoryReceiptManagementFilter: (state: ReceiptState, action) => {
      const { payload } = action;
      state.historyReceiptManagementFilter = payload;
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getPaymentStatus.pending, state => {
        state.paymentStatusLoading = true;
        state.paymentStatusError = null;
      })
      .addCase(getPaymentStatus.fulfilled, (state, action) => {
        const paymentStatus = action.payload;

        state.paymentStatusLoading = false;
        state.paymentStatusData = paymentStatus;
      })
      .addCase(getPaymentStatus.rejected, (state, action) => {
        state.paymentStatusLoading = false;
        state.paymentStatusData = '';
        state.totalItems = 0;
        state.paymentStatusError =
          action.error.message || 'Failed to create bidder';
      })
      .addCase(getReceiptsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReceiptsList.fulfilled, (state, action) => {
        const receiptData = [...action.payload.records];
        const receiptViewModal: ReceiptManagmentViewModel[] = receiptData.map(
          (receipt: ReceiptsListProps) => ({
            receiptId: receipt.id,
            agentName: receipt.payeeName,
            lan: receipt.loanId,
            receiptNumber: 'receipt',
            bankNameCode: receipt.paymentMeta?.refNumber,
            paymentMode: receipt.paymentMeta?.paymentMode,
            id: receipt.id,
            depositId: receipt.depositId,
            status: receipt.status,
            proof: [receipt.locationImageUrl, receipt.documentImageUrl],
            receiptDate: receipt.dateOfReceipt,
            receiptAmountBreakUp: receipt?.receiptAmountBreakUp,
          })
        );
        state.loading = false;
        state.receipts = receiptViewModal;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getReceiptsList.rejected, (state, action) => {
        state.loading = false;
        state.receipts = [];
        state.totalItems = 0;
        state.error = action.error.message || 'Failed to create bidder';
      })
      .addCase(getDetailedReceiptsList.pending, state => {
        state.loadingOfReceiptDetailed = true;
        state.errorOfReceiptDetailed = null;
        state.receiptsDetailed = [];
      })
      .addCase(getDetailedReceiptsList.fulfilled, (state, action) => {
        const receiptData = [...action.payload.records];

        state.loadingOfReceiptDetailed = false;
        state.receiptsDetailed = receiptData;
        state.totalItemsOfReceiptDetailed = action.payload.totalItems;
      })
      .addCase(getDetailedReceiptsList.rejected, (state, action) => {
        state.loadingOfReceiptDetailed = false;
        state.receiptsDetailed = [];
        state.errorOfReceiptDetailed =
          action.error.message || 'failed to load Receipts';
      })
      .addCase(getLoanAccountList.pending, state => {
        state.loanAccountloading = true;
        state.error = null;
      })
      .addCase(getLoanAccountList.fulfilled, (state, action) => {
        const loanAccountList = [...action.payload.records];
        const loanViewModal: AccountAgentViewModel[] = loanAccountList.map(
          (loan: LoanData) => {
            const id = loan.loanId;
            const name = loan.customerDetails
              ? loan.customerDetails[0]?.customerName
              : '-';
            const email = loan.customerDetails
              ? loan.customerDetails[0]?.email
              : '-';
            const product = loan.loanMaster
              ? loan.loanMaster?.loanProductType
              : '-';
            const emi = loan.loanMaster ? loan.loanMaster?.emi : 0;
            const overDue = loan.lmsVariables
              ? loan.lmsVariables?.overdueAmount ?? 0
              : 0;
            const total = loan.lmsVariables
              ? loan.lmsVariables?.chargeDue ?? 0
              : 0;
            const allowedAmount = loan.lmsVariables
              ? loan.lmsVariables?.overdueAmount ?? 0
              : 0;
            const lan = loan.loanId;
            const pos = loan.lmsVariables
              ? loan.lmsVariables?.outstandingAmount ?? 0
              : 0;
            const ptp = 0;
            const bounceCharge = 0;
            const penaltyCharge = 0;
            const legalCharge = 0;
            const other = 0;
            const bucket = loan.lmsVariables
              ? loan.lmsVariables?.bucket ?? '-'
              : '-';
            const contactNumber = loan.customerDetails
              ? loan.customerDetails[0]?.mobileNo
              : '-';
            const allocationDate = loan.lmsVariables
              ? loan.lmsVariables?.allocationDate
                ? DateUtility.formatStringToDDMMYYYY(
                    loan.lmsVariables?.allocationDate
                  )
                : '--'
              : '--';
            const address = loan.customerDetails
              ? loan.customerDetails[0]?.customerAddress
              : '-';
            const segment = loan.lmsVariables?.segment ?? '';
            return {
              id,
              name,
              email,
              product,
              emi,
              overDue,
              total,
              allowedAmount,
              lan,
              pos: StringUtility.formatToINR(pos),
              ptp,
              bounceCharge,
              penaltyCharge,
              legalCharge,
              other,
              bucket,
              contactNumber,
              allocationDate,
              address,
              segment,
            };
          }
        );
        state.loanAccountloading = false;
        state.loanAccounts = loanViewModal;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getLoanAccountList.rejected, (state, action) => {
        state.loanAccountloading = false;
        state.error = action.error.message || 'Failed to create bidder';
      })
      /////// receipt cancel \\\\\\\\\\\
      .addCase(getReceiptCancelList.pending, state => {
        state.loadingOfReceiptCancel = true;
        state.cancelReceipts = [];
      })
      .addCase(getReceiptCancelList.fulfilled, (state, action) => {
        const receiptCancelData = [...action.payload.records];
        const receiptCancelViewModal: ReceiptCancelListProps[] =
          receiptCancelData.map((receiptCancel: ReceiptCancelListProps) => ({
            ...receiptCancel,
            receiptDate: receiptCancel.requestDate
              ? DateUtility.formatStringToDDMMMMYYYY(receiptCancel.requestDate)
              : '--',
          }));
        state.loadingOfReceiptCancel = false;
        state.cancelReceipts = receiptCancelViewModal;
        state.totalItemsOfReceiptCancel = action.payload.totalItems;
      })
      .addCase(getReceiptCancelList.rejected, (state, action) => {
        state.loadingOfReceiptCancel = false;
        state.cancelReceipts = [];
        state.totalItemsOfReceiptCancel = 0;
        state.errorOfReceiptCancel =
          action.error.message || 'Failed to fetch receipt cancel list';
      });
  },
});
export const {
  setReceiptData,
  setReceiptDetailedData,
  setCancelReceiptData,
  setIssueReceipt,
  setOpenApproval,
  setOpenViewProof,
  setOpenReject,
  setErrorState,
  setOrderId,
  setLoanId,
  setHistoryReceiptManagementFilter
} = receiptManagmentSlice.actions;
export const receiptState = (state: RootState) => state.receipt;
export default receiptManagmentSlice;
