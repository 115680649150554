import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { VegaPill } from '../../../components/common/VegaPill';
import VegaText from '../../../components/common/VegaText';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import { ResourceDtoObj } from '../../../types/claim';
import { RoleResourceDto } from '../../../types/roleType';
import { COLOR } from '../../../utils/ColorUtility';
import { ScreenOpenType } from '../UAM';

interface IColumnProps {
  openScreenDrawer: (role: RoleResourceDto, openType: ScreenOpenType) => void;
}

export const RoleAccessListColumn = ({ openScreenDrawer }: IColumnProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 0.5,
      minWidth: 80,
      renderCell: props => {
        const role = props.row as RoleResourceDto;
        const roleName = role.roleDto?.name ? role.roleDto?.name : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={roleName}
          />
        );
      },
    },
    {
      field: 'screens',
      headerName: 'Screens',
      flex: 2,
      minWidth: 400,
      renderCell: props => {
        const role = props.row as RoleResourceDto;
        const resourceDtos = role.resourceDtos.length ? role.resourceDtos : [];
        return (
          <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
            {resourceDtos.length
              ? resourceDtos.map((resource: ResourceDtoObj) => {
                  const resourceName = `${toLowerCase(
                    resource.module
                  )} -> ${toLowerCase(resource.subModule)}`;
                  return (
                    <VegaPill
                      key={resource.id}
                      textColor={COLOR.GREEN.DARK}
                      backgroundColor={COLOR.GREEN.LIGHT}
                      text={resourceName}
                    />
                  );
                })
              : '--'}
          </Stack>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      minWidth: 50,
      renderCell: props => {
        const role = props.row as RoleResourceDto;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              sx={{ bgcolor: '#E7EDFC' }}
              tooltipTitle="Add screen"
              icon={
                <AddCircleOutline
                  sx={{
                    color: PRIMARY.darkBlue,
                    fontSize: 18,
                  }}
                />
              }
              onClick={() => openScreenDrawer(role, ScreenOpenType.ADD)}
            />
            <VegaIconButton
              sx={{ bgcolor: COLOR.RED.LIGHT }}
              tooltipTitle="Remove screen"
              icon={
                <RemoveCircleOutline
                  sx={{
                    color: COLOR.RED.DARK,
                    fontSize: 18,
                  }}
                />
              }
              onClick={() => openScreenDrawer(role, ScreenOpenType.REMOVE)}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
