import { CircularProgress, Grid, IconButton, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AgendaService } from '../../../Apis/AgendaService';
import { OtsService } from '../../../Apis/OtsService';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDatePicker from '../../../components/common/VegaDatePicker';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import {
  MinusCircleIcon,
  PlusCircleIcon,
} from '../../../components/Icons/Icons';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  AgendaCase,
  AgendaCaseStatus,
  Ots,
  OtsInstallmentPlan,
} from '../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  agendaCase?: AgendaCase;
  onSubmit: (
    agendaCase: AgendaCase,
    formData: Partial<CommitteeDecisionFormData>
  ) => void;
};

enum FormField {
  Comment = 'comment',
  Status = 'status',
  DayOfRepayment = 'dayOfRepayment',
  Installments = 'installments',
}

export type CommitteeDecisionFormData = {
  comment: string;
  status: string;
  dayOfRepayment: number;
  installments: number;
  installmentPlans: Partial<OtsInstallmentPlan>[];
  deletedInstallmentPlans: Partial<OtsInstallmentPlan>[];
};

function UpdateCommitteeDecisionDrawer({
  open,
  onClose,
  onSubmit,
  agendaCase,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const [formData, setFormData] = useState<Partial<CommitteeDecisionFormData>>(
    {}
  );
  const [installmentPlan, setInstallmentPlan] = useState<
    Partial<OtsInstallmentPlan>[]
  >([]);
  const [deletedInstallmentPlan, setDeletedInstallmentPlan] = useState<
    Partial<OtsInstallmentPlan>[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPlans, setLoadingPlans] = useState<boolean>(false);
  const [ots, setOts] = useState<Ots>();

  async function fetchOts() {
    try {
      const otsId = agendaCase?.otsId;
      if (!otsId) return;
      setLoading(true);
      const response = await OtsService.getOtsById(otsId);
      setOts(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchPlans() {
    try {
      const agendaCaseId = agendaCase?.id;
      if (!agendaCaseId) return;
      setLoadingPlans(true);
      const response = await AgendaService.getInstallmentPlan({
        id: agendaCaseId,
      });
      setInstallmentPlan(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingPlans(false);
    }
  }

  function prefillValues() {
    if (agendaCase) {
      setFormData(() => {
        return {
          dayOfRepayment: agendaCase.dayOfRepayment,
          installments: agendaCase.installments,
          comment: agendaCase.committeeComment,
          status: agendaCase.committeeDecisionStatus ?? '',
        };
      });
    }
  }

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function addPlan() {
    setInstallmentPlan(prev => [
      ...prev,
      {
        date: new Date().toISOString(),
      },
    ]);
  }

  function removePlan() {
    setInstallmentPlan(prev => {
      const updatedPlans = [...prev];
      const deletedItem = updatedPlans.pop();
      if (deletedItem.id != undefined) {
        setDeletedInstallmentPlan(prev => [...prev, deletedItem]);
      }
      return updatedPlans;
    });
  }

  async function onUpdateClick() {
    if (!agendaCase) return;
    formData.installments = installmentPlan.length;
    formData.installmentPlans = installmentPlan;
    formData.deletedInstallmentPlans = deletedInstallmentPlan;
    onSubmit(agendaCase, formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanup();
  }

  function cleanup() {
    setFormData({});
    setInstallmentPlan([]);
  }

  function onDateChange(index: number, date: any) {
    setInstallmentPlan(prev => {
      const updatedData = prev.map((item, idx) => {
        const updatedValue = { ...item };
        if (idx == index) {
          updatedValue.date =
            DateUtility.parseDateFromDatePicker(date).toISOString();
        }
        return updatedValue;
      });
      return updatedData;
    });
  }

  function onAmountChange(index: number, amount: string) {
    setInstallmentPlan(prev => {
      const updatedData = prev.map((item, idx) => {
        const updatedValue = { ...item };
        if (idx == index) {
          updatedValue.amount = amount;
        }
        return updatedValue;
      });
      return updatedData;
    });
  }

  const isInputValid = () => {
    const isCommentValid = (formData.comment ?? '').length > 0;
    const isStatusValid = (formData.status ?? '').length > 0;
    return isCommentValid && isStatusValid;
  };

  const parseNumberValue = (value: any) => {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    return isValidNumber ? parsedNumber : undefined;
  };

  useEffect(() => {
    if (open == true) {
      prefillValues();
      fetchOts();
      fetchPlans();
    }
  }, [open]);

  return (
    <VegaDrawer
      open={open}
      title={'Add Committee Decision'}
      onClose={handleClose}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Update'}
              disabled={isInputValid() == false}
              onClick={onUpdateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                gap: '10px',
                borderRadius: '8px',
                backgroundColor: '#F7F7F7',
              }}
            >
              {loading == false && (
                <Stack
                  direction={'row'}
                  justifyContent="space-between"
                  alignItems={'space-between'}
                >
                  <VegaText text={'Settlement Amount'} />
                  <VegaText
                    text={StringUtility.formatToINR(ots?.settlementAmount ?? 0)}
                  />
                </Stack>
              )}
              {loading == true && <CircularProgress />}
            </div>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Day Of Repayment'}>
              <LmTextField
                value={formData.dayOfRepayment ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  if (StringUtility.isNumber(value)) {
                    updateFormData(
                      FormField.DayOfRepayment,
                      parseNumberValue(e.target.value)
                    );
                  }
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Installments'}>
              <LmTextField
                value={installmentPlan.length ?? ''}
                endAdornment={
                  <Stack direction={'row'} spacing="0.2rem">
                    <IconButton
                      onClick={() => {
                        removePlan();
                      }}
                    >
                      <MinusCircleIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        addPlan();
                      }}
                    >
                      <PlusCircleIcon />
                    </IconButton>
                  </Stack>
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Installments Details'}>
              {loadingPlans == true && <CircularProgress />}
              {loadingPlans == false &&
                installmentPlan.map((i, index) => {
                  return (
                    <Grid container key={index} columnGap={'0.8rem'}>
                      <Grid item xs={5.8}>
                        <LmTextField
                          value={i.amount ?? ''}
                          onChange={e => {
                            const value = e.target.value;
                            if (StringUtility.isNumber(value)) {
                              onAmountChange(index, e.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={5.8}>
                        <VegaDatePicker
                          value={i.date}
                          onChange={e => {
                            onDateChange(index, e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Comment'}>
              <LmTextField
                value={formData.comment ?? ''}
                onChange={e => {
                  updateFormData(FormField.Comment, e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Status'}>
              <VegaSelect
                options={statusOptions()}
                onChange={e =>
                  updateFormData(FormField.Status, e.target.value as string)
                }
                value={formData.status ?? ''}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default UpdateCommitteeDecisionDrawer;

const statusOptions = () =>
  [
    AgendaCaseStatus.APPROVED,
    AgendaCaseStatus.DEFERRED,
    AgendaCaseStatus.REJECTED,
  ].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
