import { Stack } from '@mui/material';
import { useState } from 'react';
import { GREY, RED } from '../../constants/style';
import LmDialog from './LmDialog';
import LmTextField from './LmTextField';
import VegaButton from './VegaButton';
import VegaDialogContent from './VegaDialogContent';
import VegaFormInputField from './VegaFormInputField';
import VegaText from './VegaText';

interface IRejectDialogProps {
  text: string;
  open: boolean;
  isHide?: boolean;
  onClose: () => void;
  onReject: (reason: string) => void;
}

const VegaRejectDialog = ({
  open,
  text,
  isHide,
  onClose,
  onReject,
}: IRejectDialogProps) => {
  const [reason, setReason] = useState<string>('');

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={open}
      handleClose={onClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            fullWidth
            text="Reject"
            sx={{
              color: 'white',
              bgcolor: RED.red,
              ':hover': {
                color: 'white',
                bgcolor: RED.red,
              },
            }}
            disabled={!reason.length}
            onClick={() => onReject(reason)}
          />
          <VegaButton
            text="Cancel"
            fullWidth
            variant="outlined"
            sx={{
              color: GREY.dark,
              borderColor: GREY.dark,
              bgcolor: 'white',
              ':hover': {
                color: GREY.dark,
                borderColor: GREY.dark,
                bgcolor: 'white',
              },
            }}
            onClick={onClose}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#F8E8E8'}
        >
          <VegaText
            text={text}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={RED.red}
          />
        </Stack>
      )}
    >
      {!isHide && (
        <VegaDialogContent>
          <VegaFormInputField label="What is your reason for rejection?">
            <LmTextField
              isTextArea
              rows={2}
              multiline
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
          </VegaFormInputField>
        </VegaDialogContent>
      )}
    </LmDialog>
  );
};

export default VegaRejectDialog;
