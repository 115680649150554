import { Stack } from '@mui/material';
import { ConfigurationServices } from '../../../Apis/ConfigurationServices';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import {
  fetchConfigListSlice,
  getConfigurationState,
  initialNpaDpdNcmConfig,
  setConfigActive,
  setConfigSearch,
  setConfigSearchBy,
  setConfigStatus,
  setConfigurations,
} from '../../../features/configurationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ConfigurationType } from '../../../types/configurationType';
import { ConfigListRequest } from '../../../types/request/cofigurationRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IConfigActionProps, isEmptyOrUndefined } from '../Configurations';
import NcmDpdForm from '../components/Forms/NcmDpdForm';
import { NcmDpdSearchFilterType } from './ListOfConfiguration';

interface IProps extends IConfigActionProps {}

const NcmDpdConfig = ({ onClose }: IProps) => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      await ConfigurationServices.addConfig(configurations);
      setSnackbar('Add Config Successfully');
      const request = {
        page: 0,
        size: 10,
        names: [ConfigurationType.NCM_DPD],
      } as ConfigListRequest;
      dispatch(setConfigSearch(''));
      dispatch(setConfigStatus(''));
      dispatch(setConfigActive(false));
      dispatch(setConfigSearchBy(NcmDpdSearchFilterType.ALL));
      dispatch(fetchConfigListSlice(request));
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const onClear = () => {
    dispatch(setConfigurations(initialNpaDpdNcmConfig));
  };
  const disabled =
    !configurations?.type?.length ||
    !configurations?.subtypes?.length ||
    isEmptyOrUndefined(configurations?.config?.agentId) ||
    isEmptyOrUndefined(configurations?.config?.agentName);
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton text="Submit" onClick={onSubmit} disabled={disabled} />
          </Stack>
        );
      }}
    >
      <NcmDpdForm />
    </VegaDrawerContent>
  );
};

export default NcmDpdConfig;
