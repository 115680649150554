import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchDepositBankById } from '../../Apis/depositBank';
import { useBankDeposit } from '../../providers/BankDepositProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { VegaUserStatus } from '../../types/user';
import { COLOR } from '../../utils/ColorUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaText from '../common/VegaText';
import VegaDialogDisplayItem from '../common/VegaDialogItem';

const ViewBankDepositDetails = () => {
  const { setSnackbar } = useSnackbar();
  const { selectedId, setDepositeBankState, depositeBankState } =
    useBankDeposit();
  const [loading, setLoading] = useState<boolean>(false);

  const getDepositBankDetailsById = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchDepositBankById(id);
      setDepositeBankState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbar(
        getErrorMessageFromErrorObj(
          error,
          'Failed to fetch deposit bank details'
        ),
        'error'
      );
    }
  };

  useEffect(() => {
    if (selectedId) {
      getDepositBankDetailsById(selectedId);
    }
  }, [selectedId]);

  return (
    <div>
      <Stack gap={1}>
        <VegaDialogDisplayItem
          heading="Bank Name"
          value={depositeBankState?.bankName ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Deposit Bank Name"
          value={depositeBankState?.bankName + depositeBankState?.accountNumber}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Deposit Account Number"
          value={depositeBankState?.accountNumber ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Deposit Bank Code"
          value={depositeBankState?.bankCode ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Branch Name"
          value={depositeBankState?.branchName ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Branch Code"
          value={depositeBankState?.branchCode ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="IFSC"
          value={depositeBankState?.ifsc ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="MICR"
          value={depositeBankState?.micr ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Segment"
          value={depositeBankState?.segment ?? ''}
          loading={loading}
        />
        <VegaDialogDisplayItem
          heading="Status"
          renderValue={() => {
            const color = getColorForStatus(depositeBankState?.status);
            return (
              <VegaText
                text={depositeBankState?.status ?? ''}
                fontWeight={500}
                fontSize={'0.8125rem'}
                color={color?.DARK}
              />
            );
          }}
        />
      </Stack>
    </div>
  );
};

export default ViewBankDepositDetails;

const getColorForStatus = (status?: VegaUserStatus) => {
  if (!status) return;
  switch (status) {
    case VegaUserStatus.ACTIVE:
      return COLOR.GREEN;

    case VegaUserStatus.INACTIVE:
      return COLOR.RED;
  }
};
