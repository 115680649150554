import { isCapriUATEnv, isCapriProdEnv, isCapriDevEnv } from './url';

export const ACCOUNTS =
  isCapriUATEnv || isCapriDevEnv
    ? 'ae0deaac-0fea-48fc-8846-0b3a38561c4f'
    : isCapriProdEnv
    ? '7d69a239-9fb5-4757-8fba-ed48dd2125a8'
    : 'e58eb744-c9fa-4bbd-a480-c463ceba142e';
export const ACCOUNTS_VISITS =
  isCapriUATEnv || isCapriDevEnv
    ? '6c2bb2b9-d826-48c1-a315-7bdd815c1f45'
    : isCapriProdEnv
    ? '41eed050-fb65-44f9-ac62-4e5db1f0ae4e'
    : 'f0e2dd66-5bbb-4f73-a769-140c33f902e7';
export const NO_OF_RECEIPTS =
  isCapriUATEnv || isCapriDevEnv
    ? 'f90a7010-e147-4130-abbd-f1ef08688669'
    : isCapriProdEnv
    ? '232da789-5d63-4193-81f8-333b5811efe6'
    : '49f2f232-fde5-42fb-b226-2d3a82615fa3';
export const RECEIPTS_AMOUNT =
  isCapriUATEnv || isCapriDevEnv
    ? '144b1839-dfd4-4331-931d-5c8a05fd8324'
    : isCapriProdEnv
    ? '1e753867-e74e-4b08-a613-842e6282fb29'
    : '1b699f13-6ac0-4e8f-b89d-b1faf55c7fca';
export const NO_OF_VISITS =
  isCapriUATEnv || isCapriDevEnv
    ? 'f90a7010-e147-4130-abbd-f1ef08688669'
    : isCapriProdEnv
    ? 'b6626658-d2ed-455d-a195-70819872a165'
    : 'e99f026f-e91b-4280-933e-bb7cc1e6eb2f';
export const NO_OF_ALLOCATED_AGENCY =
  isCapriUATEnv || isCapriDevEnv
    ? 'a1a44159-32cd-4ca7-984b-b868ac3ec2db'
    : isCapriProdEnv
    ? 'a037a07b-9b37-4802-9fb2-46d16549284f'
    : '347580eb-f0a1-46f0-8c09-d4cc756e5a47';
export const NO_OF_UNALLOCATED_AGENCY =
  isCapriUATEnv || isCapriDevEnv
    ? 'f6c7b8a2-b951-4bbf-9a75-0953f582004c'
    : isCapriProdEnv
    ? '4852b77c-e07e-4117-a917-a655457f8089'
    : '3792f3cf-5e03-4887-a511-6d6b1b35906a';
export const NO_OF_ACCOUNTS_CALLED =
  isCapriUATEnv || isCapriDevEnv
    ? 'bac383ba-2b58-40ef-baf7-6d456d813c89'
    : isCapriProdEnv
    ? '1f27b69f-f817-4f5b-b1c0-59a7d78927da'
    : '18cc69c2-8694-4278-bab3-2445685f203b';
export const NO_OF_ACCOUNTS_WITH_RECEIPT_ISSUE =
  isCapriUATEnv || isCapriDevEnv
    ? 'b2407bb1-04e1-46d8-b78e-5cbebed45fdb'
    : isCapriProdEnv
    ? 'e2e451ee-eeaa-43d9-adec-8569e4c517fd'
    : '3b69c802-3fc9-492b-8ecb-5593013910b8';
export const NO_OF_ACCOUNTS_WITH_NO_ACTION =
  isCapriUATEnv || isCapriDevEnv
    ? 'ec33089f-c4b2-47ac-b641-dcc031db7ec3'
    : isCapriProdEnv
    ? 'b5a08168-8be2-4cb1-ae73-018afaf6a9ae'
    : 'a02dad86-27c1-40bb-b196-2bd896dd7dbc';
export const NO_OF_AVG_VISIT_PER_CUSTOMER =
  isCapriUATEnv || isCapriDevEnv
    ? 'afc62e1f-8822-440f-b645-a8a94e44b783'
    : isCapriProdEnv
    ? '46be4b27-0fd4-40ad-aece-aad829396df9'
    : 'd4d422d5-86d7-45fc-8511-59bc09179369';
export const NO__OF_CALLERS =
  isCapriUATEnv || isCapriDevEnv
    ? '3f4934ba-ca5d-47fe-90df-cec2439d4a48'
    : isCapriProdEnv
    ? 'b755f5d4-b02a-4754-ac43-ecc725263a0f'
    : '9ef63f7b-dd18-46fc-b114-d27f4c88ccdd';
export const NO_OF_AVG_CALL_PER_CUSTOMER =
  isCapriUATEnv || isCapriDevEnv
    ? '7e50da5f-bfb6-41f9-a5ef-54b3012bec7b'
    : isCapriProdEnv
    ? 'c0fbbfce-f5fe-44df-b6eb-63ef86e8b14b'
    : 'e3e0871c-3b15-47ed-ad96-7667dda3aac7';
export const TOTAL_RECEIPT_ISSUED_COUNT =
  isCapriUATEnv || isCapriDevEnv
    ? '0f5de366-32cf-494f-a5c7-4f12283db3c7'
    : isCapriProdEnv
    ? 'a431bcc4-8aac-472d-b897-0121d0ed224c'
    : '5b1385f1-ebab-4e93-b2d6-33311a51f842';
export const TOTAL_RECEIPT_ISSUED_AMOUNT =
  isCapriUATEnv || isCapriDevEnv ? '' : '';
export const VISITS =
  isCapriUATEnv || isCapriDevEnv
    ? '2b9a54be-0bf5-4420-9dc8-81fbf8cace96'
    : isCapriProdEnv
    ? '038f5b07-9f10-4ce3-9dcf-f49cf9626a0c'
    : 'da9a2e2e-182a-412e-869c-94111461981e';
export const TOTAL_CITIES =
  isCapriUATEnv || isCapriDevEnv
    ? 'a30d3376-d7b6-4e43-b246-5ac7f724255b'
    : isCapriProdEnv
    ? 'f02fa260-6fcb-4022-9789-0011a8bc1414'
    : 'e30befc5-11a5-48c4-ad50-a9fad0965dbe';
export const TOTAL_BRANCH =
  isCapriUATEnv || isCapriDevEnv
    ? '5e584b4a-87e1-42be-bbdf-b4ca9e4bea36'
    : isCapriProdEnv
    ? '8e1a8eb3-a490-4a2b-bfcb-37ad79ba50ee'
    : '28bea521-113d-4195-aa9c-a8c02c4a70ee';
export const TOTAL_DISPOSITION_COUNT =
  isCapriUATEnv || isCapriDevEnv
    ? '583f8440-6c81-4186-ad0b-68b3159c4de8'
    : isCapriProdEnv
    ? '771d43cb-ede6-4362-ae2c-5017000e13f5'
    : 'ca96a9c8-d50e-4477-8e18-5b64c70c110a';
export const PRE_POST_BOUNCE_POS_AMOUNTS =
  isCapriUATEnv || isCapriDevEnv
    ? '53dcdd36-ede7-416c-910c-ff5ce30ddecb'
    : isCapriProdEnv
    ? 'ed2e3560-9357-4a8a-bf6a-fdd6a4f6c3a1'
    : '';
export const COLLECTED_COLLECTIBLE_DATA =
  isCapriUATEnv || isCapriDevEnv
    ? '3c46515c-e5ba-47a0-8dbe-d818adfe49f4'
    : isCapriProdEnv
    ? '31bd439c-f0b2-4498-a301-2fa41365d631'
    : '';
export const MORE_DETAILS_TABLE_DATA =
  isCapriUATEnv || isCapriDevEnv
    ? '42fcfdfb-cb1f-4d99-bea9-a79945a1aba0'
    : isCapriProdEnv
    ? 'e4aa91d7-8cc8-4739-8c32-cd169f6f5e89'
    : '';
export const BUCKET_MOVEMENT =
  isCapriUATEnv || isCapriDevEnv
    ? 'b79a4fbb-78b8-4b7c-acfe-fc5f03333f85'
    : isCapriProdEnv
    ? 'aceac5fa-185c-46f3-ac22-175bdec1bf84'
    : '';
export const TOTAL_STATES =
  isCapriUATEnv || isCapriDevEnv
    ? '55bd72c9-12b6-418e-b057-4ea779171d18'
    : isCapriProdEnv
    ? ''
    : 'a67bf692-a21f-4b4b-a072-63f9d3b890ea';
export const BRANCH_CODE =
  isCapriUATEnv || isCapriDevEnv
    ? 'c9b59a0c-d01a-4f9b-9fe9-4622a21a3ef2'
    : isCapriProdEnv
    ? ''
    : '';
