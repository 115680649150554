import { Box, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import VegaFormInputField from '../../../common/VegaFormInputField';
import LmTextField from '../../../common/LmTextField';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setWriteOffruleState,
} from '../../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import VegaButton from '../../../common/VegaButton';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { IncentiveServices } from '../../../../Apis/IncentivesService';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { useParams } from 'react-router-dom';

const MaxPayoutComponent = () => {
  const { policyId } = useParams();
  const { writeOffruleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitMaxPayoutIncentive = async () => {
    setLoading(true);
    try {
      const response = await IncentiveServices.addWriteOffPolicy({
        ...writeOffruleState,
        incentiveBasePolicyId: policyId,
      });
      dispatch(setPolicyDetailsState(response));
      setSnackbar('Add max payout successfully !!!');
      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setLoading(false);
    }
  };
  const disabled = writeOffruleState?.maxPolicyPayoutAmt <= 0;
  return (
    <Stack
      gap={2}
      sx={{
        width: '100%',
        p: '1.5rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <VegaFormInputField label={'Max Policy Payout Amount'}>
            <LmTextField
              type="number"
              placeholder="Enter Max Policy Payout Amount"
              value={writeOffruleState?.maxPolicyPayoutAmt}
              onChange={e => {
                const value = e.target.value && parseFloat(e.target.value);
                dispatch(
                  setWriteOffruleState({
                    ...writeOffruleState,
                    maxPolicyPayoutAmt: value,
                  })
                );
              }}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
      <Box textAlign={'end'}>
        <VegaButton
          text={`Save Max Payout`}
          onClick={onSubmitMaxPayoutIncentive}
          disabled={disabled}
          loading={loading}
        />
      </Box>
    </Stack>
  );
};

export default MaxPayoutComponent;
