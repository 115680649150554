import { Grid, Stack } from '@mui/material';
import { useOts } from '../../../../providers/OtsProvider';
import OtsKeyValueDisplay from '../../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from './OtsDetailsWrapper';

function OtsDeliquencyDetails() {
  const { loan, loading } = useOts();

  const isNPA = () => {
    const npaFlag = loan?.lmsVariables.npaFlag;
    if (npaFlag != null) {
      return npaFlag;
    }
    return 'NA';
  };

  return (
    <OtsDetailsWrapper text="Delinquency Details" showBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="MOB"
              value={loan?.lmsVariables.mob ?? 'NA'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Current Month Bucket"
              value={loan?.lmsVariables?.bucket}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Current DPD"
              value={loan?.loanOutstandingDetails?.daysPastDue}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Default Start Month"
              value={'Na'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="NPA Month"
              value={'Na'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay text="NPA" value={isNPA()} loading={loading} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="EMI's Paid"
              value={'Na'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay text="Bounces" value={'NA'} loading={loading} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Non Starter Case"
              value={'Na'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Early Delinquency Case"
              value={'Na'}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <OtsKeyValueDisplay
              text="Amount Collected Post NPA"
              value={'Na'}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </OtsDetailsWrapper>
  );
}

export default OtsDeliquencyDetails;
