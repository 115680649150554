import React, { useState } from 'react';
import LmDialog from '../../../components/common/LmDialog';
import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getTargetList,
  getTargetState,
  setOpenRejectTargetDialog,
} from '../../../features/targetSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { TargetSevices } from '../../../Apis/TargetSevices';
import { TargetsStatus } from '../../../types/targets';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { GREY, RED } from '../../../constants/style';
import VegaButton from '../../../components/common/VegaButton';
import VegaText from '../../../components/common/VegaText';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import LmTextField from '../../../components/common/LmTextField';
import { useClientAuth } from '../../../providers/ClientProvider';

const RejectTargetDialog = () => {
  const { user } = useClientAuth();
  const [rejectedReason, setRejectedReason] = useState<string>('');
  const { openRejectTargetDialog, bucketDetails } =
    useAppSelector(getTargetState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onClose = () => {
    dispatch(setOpenRejectTargetDialog(false));
  };
  const onRejectTarget = () => {
    TargetSevices.updateTargetStatus({
      id: bucketDetails.id,
      newStatus: TargetsStatus.REJECTED,
      rejectedReason: rejectedReason,
    })
      .then(() => {
        setSnackbar('Reject Successfully !!!');
        dispatch(
          getTargetList({
            approverId: user?.id,
            listOfTargetStatus: [TargetsStatus.RAISED],
          })
        );
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={openRejectTargetDialog}
        handleClose={onClose}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <VegaButton
              fullWidth
              text="Reject"
              sx={{
                bgcolor: RED.red,
                ':hover': {
                  bgcolor: RED.red,
                },
              }}
              onClick={onRejectTarget}
              disabled={rejectedReason === ''}
            />
            <VegaButton
              text="Cancel"
              fullWidth
              variant="outlined"
              sx={{
                color: GREY.dark,
                borderColor: GREY.dark,
                ':hover': {
                  color: GREY.dark,
                  bgcolor: 'transparent',
                  borderColor: GREY.dark,
                },
              }}
              onClick={onClose}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <VegaText
              text="Reject Target?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaFormInputField label="What is your reason for rejection?">
            <LmTextField
              isTextArea
              rows={2}
              multiline
              value={rejectedReason}
              onChange={e => setRejectedReason(e.target.value)}
            />
          </VegaFormInputField>
        </VegaDialogContent>
      </LmDialog>
    </>
  );
};

export default RejectTargetDialog;
