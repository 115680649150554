/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { IncentiveServices } from '../../Apis/IncentivesService';
import { AnimatedInputWrapper } from '../../components/ExpenseClaim/CreateClaimForm';
import VegaButton from '../../components/common/VegaButton';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../components/common/VegaSelect';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import {
  SimulateViewState,
  getIncentiveSimulatorState,
  initialCriteriaData,
  initialSimulatorFormData,
  setSimulateFormData,
  setSimulateViewState,
} from '../../features/IncentivesGamificationsSlice/incentiveSimulatorSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  SimulateFormData,
  SimulatorCriteriaData,
} from '../../types/incentiveSimulatorType';
import { IncentivePolicyProps } from '../../types/incentives';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import CriteriaContainer from './modules/CriteriaContainer';
import UploadFileContainer from './modules/UploadFileContainer';
import SimulatorGraphsContainer from './modules/SimulatorGraphsContainer';
import VegaDatePicker from '../../components/common/VegaDatePicker';
import { DateUtility } from '../../utils/DateUtlility';
import { IncentiveSimulatorFinalResponseDto } from '../../types/incentiveScorecardType';

export enum ActionType {
  UPLOAD_FILE = 'UPLOAD_FILE',
  DATE = 'DATE',
}

const initialSimulator = {
  overallCoverage: {
    policyTwo: [
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 300 },
      { bucket: 'NPA', value: 400 },
    ],
    policyOne: [
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 100 },
      { bucket: 'NPA', value: 200 },
    ],
  },
  overallPayout: {
    policyTwo: [
      { bucket: 'Total', value: 300 },
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 300 },
      { bucket: 'NPA', value: 400 },
    ],
    policyOne: [
      { bucket: 'Total', value: 250 },
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 100 },
      { bucket: 'NPA', value: 200 },
    ],
  },
  bumperCoverage: {
    policyTwo: [
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 300 },
      { bucket: 'NPA', value: 400 },
    ],
    policyOne: [
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 100 },
      { bucket: 'NPA', value: 200 },
    ],
  },
  bumperPayout: {
    policyTwo: [
      { bucket: 'Total', value: 300 },
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 300 },
      { bucket: 'NPA', value: 400 },
    ],
    policyOne: [
      { bucket: 'Total', value: 250 },
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 100 },
      { bucket: 'NPA', value: 200 },
    ],
  },
  penaltyCoverage: {
    policyTwo: [
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 300 },
      { bucket: 'NPA', value: 400 },
    ],
    policyOne: [
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 100 },
      { bucket: 'NPA', value: 200 },
    ],
  },
  penaltyPayout: {
    policyTwo: [
      { bucket: 'Total', value: 300 },
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 300 },
      { bucket: 'NPA', value: 400 },
    ],
    policyOne: [
      { bucket: 'Total', value: 250 },
      { bucket: '1-30', value: 100 },
      { bucket: '31-60', value: 200 },
      { bucket: '61-90', value: 100 },
      { bucket: 'NPA', value: 200 },
    ],
  },
} as IncentiveSimulatorFinalResponseDto;

function IncentiveSimulator() {
  const { simulateFormData, simulateViewState } = useAppSelector(
    getIncentiveSimulatorState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const [policy, setPolicy] = useState<IncentivePolicyProps[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loadingPolicy, setLoadingPolicy] = useState<boolean>(false);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [simulating, setSimulating] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>(ActionType.DATE);
  const [simulatorData, setSimulatorData] = useState<
    Partial<IncentiveSimulatorFinalResponseDto>
  >({});

  const handleSimulatorChange = (
    key: keyof SimulateFormData,
    value: string
  ) => {
    dispatch(setSimulateFormData({ ...simulateFormData, [key]: value }));
  };

  async function fetchData() {
    try {
      setLoadingPolicy(true);
      const response = await IncentiveServices.getPolicies({
        size: 10000,
      });
      setPolicy(response.records ?? []);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingPolicy(false);
    }
  }

  const isActionTypeEmpty = () => {
    if (actionType === ActionType.DATE) {
      return (
        !simulateFormData.startDate.length || !simulateFormData.endDate.length
      );
    } else {
      return !simulateFormData.file;
    }
  };

  const disabled =
    !simulateFormData.policyId1.length ||
    !simulateFormData.policyId2.length ||
    !simulateFormData.simulatorCriteria.incentiveCollectionType.length ||
    !simulateFormData.simulatorCriteria.condition.length ||
    !simulateFormData.simulatorCriteria.value.length ||
    !simulateFormData.simulatorCriteria.metricType.length ||
    isActionTypeEmpty();

  function cleanUp() {
    // dispatch(setSimulateFormData(initialSimulatorFormData));
  }

  const onSimulateClick = async () => {
    try {
      dispatch(setSimulateViewState(SimulateViewState.Simulating));

      const formData = new FormData();
      formData.append('policyId1', simulateFormData.policyId1);
      formData.append('policyId2', simulateFormData.policyId2);
      formData.append(
        'simulatorCriteria',
        JSON.stringify(simulateFormData.simulatorCriteria)
      );
      if (actionType === ActionType.DATE) {
        formData.append(
          'startDate',
          DateUtility.formatStringToYYYYMMDD(simulateFormData.startDate)
        );
        formData.append(
          'endDate',
          DateUtility.formatStringToYYYYMMDD(simulateFormData.endDate)
        );
      } else {
        formData.append('file', simulateFormData.file.value);
        fileInputRef.current.value = '';
      }
      const response = await IncentiveServices.simultateIncentive(formData);
      if (response) {
        setSimulatorData(response);
      } else {
        setSimulatorData(initialSimulator);
      }
      setTimeout(() => {
        dispatch(setSimulateViewState(SimulateViewState.Simulated));
      }, 300);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      setTimeout(() => {
        dispatch(setSimulateViewState(SimulateViewState.Simulated));
      }, 300);
    }
  };

  const downloadSampleFile = async () => {
    try {
      const url = await IncentiveServices.sampleSimulateFormat();
      window.open(url, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (simulateViewState == SimulateViewState.Simulated) cleanUp();
  }, [simulateViewState]);

  useEffect(() => {
    dispatch(setSimulateViewState(SimulateViewState.Initial));
  }, []);

  return (
    <>
      <VegaPageHeader title="Simulator" sx={{ marginBottom: '1rem' }} />
      <VegaPageContent>
        <Stack gap="1rem">
          <VegaTitleCard title="Policy">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={2}>
                <VegaFormInputField label="Policy 1" isMandatory>
                  <VegaSelect
                    value={simulateFormData.policyId1}
                    onChange={e =>
                      handleSimulatorChange(
                        'policyId1',
                        e.target.value as string
                      )
                    }
                    options={getPolicyOptions(policy)}
                    loading={loadingPolicy}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <VegaFormInputField label="Policy 2" isMandatory>
                  <VegaSelect
                    value={simulateFormData.policyId2}
                    onChange={e =>
                      handleSimulatorChange(
                        'policyId2',
                        e.target.value as string
                      )
                    }
                    options={getPolicyOptions(policy)}
                    loading={loadingPolicy}
                  />
                </VegaFormInputField>
              </Grid>
            </Grid>
          </VegaTitleCard>
          <VegaTitleCard title="Criteria">
            <Stack gap="1rem">
              <CriteriaContainer />
            </Stack>
          </VegaTitleCard>
          <VegaTitleCard title="Actions">
            <Stack gap={2}>
              <VegaFormInputField label="Action Type" isMandatory>
                <Stack width={200}>
                  <VegaSelect
                    value={actionType}
                    onChange={e => setActionType(e.target.value as ActionType)}
                    options={options}
                    loading={loadingPolicy}
                  />
                </Stack>
              </VegaFormInputField>
              <Grid container spacing={2} alignItems="end">
                {actionType === ActionType.DATE ? (
                  <>
                    <Grid item xs={12} sm={3}>
                      <VegaDatePicker
                        value={simulateFormData?.startDate}
                        onChange={e => {
                          const parsedDate =
                            DateUtility.parseDateFromDatePicker(e);
                          const endDate = DateUtility.parseStringToDate(
                            simulateFormData?.endDate
                          );
                          if (DateUtility.isAfter(endDate, parsedDate)) {
                            dispatch(
                              setSimulateFormData({
                                ...simulateFormData,
                                startDate: parsedDate?.toISOString(),
                                endDate: DateUtility.addDays(
                                  parsedDate,
                                  1
                                )?.toISOString(),
                              })
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <VegaDatePicker
                        value={simulateFormData?.endDate}
                        minDate={simulateFormData?.startDate}
                        onChange={e => {
                          const parsedDate =
                            DateUtility.parseDateFromDatePicker(e);
                          dispatch(
                            setSimulateFormData({
                              ...simulateFormData,
                              endDate: parsedDate?.toISOString(),
                            })
                          );
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <UploadFileContainer fileInputRef={fileInputRef} />
                )}
                <Grid
                  item
                  xs={3}
                  style={{
                    display:
                      simulateViewState == SimulateViewState.Verified ||
                      (simulateFormData.startDate.length &&
                        simulateFormData.endDate.length) ||
                      simulateViewState == SimulateViewState.Simulating
                        ? ''
                        : 'none',
                  }}
                >
                  <VegaButton
                    text="Simulate"
                    onClick={onSimulateClick}
                    loading={simulateViewState == SimulateViewState.Simulating}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              {actionType !== ActionType.DATE && (
                <Box>
                  <VegaButton
                    text="Download Sample File"
                    variant="text"
                    onClick={downloadSampleFile}
                  />
                </Box>
              )}
            </Stack>
          </VegaTitleCard>
          <AnimatedInputWrapper
            show={simulateViewState == SimulateViewState.Simulated}
          >
            <SimulatorGraphsContainer simulatorData={simulatorData} />
          </AnimatedInputWrapper>
        </Stack>
      </VegaPageContent>
    </>
  );
}

export default IncentiveSimulator;

const getPolicyOptions = (policies: IncentivePolicyProps[]) => {
  return policies.map(i => {
    const option: VegaSelectOption = {
      value: i.id,
      label: _.startCase(_.toLower(i.policyName)),
    };
    return option;
  });
};

const options = Object.keys(ActionType).map(
  value => ({ label: _.startCase(_.toLower(value)), value } as VegaSelectOption)
);
