import { GetPaginatedApiRequest } from '../api';

export enum EscalationStatus {
  RAISED = 'RAISED',
  REJECTED = 'REJECTED',
  RESOLVED = 'RESOLVED',
  ESCALATED = 'ESCALATED',
}

export enum EscalationType {
  TODAY = 'TODAY',
  LEAVE_REQUEST = 'LEAVE_REQUEST',
  MINIMUM_VISIT_UNFULFILLED = 'MINIMUM_VISIT_UNFULFILLED',
  ROUTE_DEVIATION = 'ROUTE_DEVIATION',
  TARGET_REVIEW = 'TARGET_REVIEW',
  EXPENSE_REIMBURSEMENT = 'EXPENSE_REIMBURSEMENT',
  OTS = 'OTS',
}
export enum EscalationTriggerType {
  TODAY = 'TODAY',
  LEAVE = 'LEAVE',
  MIN_VISIT = 'MIN_VISIT',
  ROUTE_DEVIATION = 'ROUTE_DEVIATION',
  TARGET = 'TARGET',
  EXPENSE = 'EXPENSE',
  OTS = 'OTS',
}

export interface GetEscalationRequest extends GetPaginatedApiRequest {
  id: string;
  parentEscalationId: string;
  userId: string;
  managerId: string;
  triggeredDate: string;
  escalationReason: string;
  escalationStatus: string[];
  escalationType: string;
  expiryTime: string;
  escalationObjectId: string;
  userName: string;
  fromDate: string;
  toDate: string;
}
export interface UpadteEscalationRequest {
  id: string;
  escalationStatus: EscalationStatus;
  escalationReason: string;
  rejectedReason: string;
  approverId: string;
}
export interface TriggerEscalationRequest {
  triggerType: string;
}
