import { Grid, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgendaService } from '../../../Apis/AgendaService';
import { OtsService } from '../../../Apis/OtsService';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { VegaPill } from '../../../components/common/VegaPill';
import VegaText from '../../../components/common/VegaText';
import { useDrawer } from '../../../hooks/useDrawer';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { ROUTES } from '../../../router/routes';
import { VegaUser } from '../../../types/claim';
import { LoanData } from '../../../types/loan';
import {
  Agenda,
  AgendaCase,
  AgendaCaseMetaDataKeys,
  AgendaCaseStatus,
  AgendaMetaDataKeys,
  AgendaStatus,
  getValueFromRecord,
  Ots,
} from '../../../types/ots';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { COLOR } from '../../../utils/ColorUtility';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import OtsKeyValueDisplay from '../components/OtsKeyValueDisplay';
import OtsDetailsWrapper from '../pages/components/OtsDetailsWrapper';
import AddCasesToAgendaDrawer from './AddCasesToAgendaDrawer';
import UpdateCommitteeDecisionDrawer, {
  CommitteeDecisionFormData,
} from './UpdateCommitteeDecisionDrawer';
import { VisibilityOutlined } from '@mui/icons-material';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { PRIMARY } from '../../../constants/style';

function AgendaDetailsPage() {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { agendaId } = useParams();
  const { user } = useClientAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPendingCases, setLoadingPendingCases] =
    useState<boolean>(false);
  const [loadingRejectedCases, setLoadingRejectedCases] =
    useState<boolean>(false);
  const [agenda, setAgenda] = useState<Agenda>();
  const [cases, setCases] = useState<AgendaCase[]>([]);
  const [pendingApprovalCases, setPendingApprovalCases] = useState<
    AgendaCase[]
  >([]);
  const [rejectedCases, setRejectedCases] = useState<AgendaCase[]>([]);

  const {
    open: openCommitteeDrawer,
    close: closeCommitteeDrawer,
    isOpen: isCommitteeDrawerOpen,
    props: committeeDrawerProps,
  } = useDrawer();

  const {
    open: openAddCasesDrawer,
    close: closeAddCasesDrawer,
    isOpen: isAddCasesDrawerOpen,
  } = useDrawer();

  async function fetchAgenda(id: string) {
    try {
      setLoading(true);
      const response = await AgendaService.getAgenda(id);
      fetchCases(id);
      setAgenda(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function startDiscssion(agendaCase: AgendaCase) {
    try {
      setLoading(true);
      await AgendaService.takeActionOnCase({
        id: agendaCase.id,
        discussionStarted: true,
      });
      fetchApprovedCases(agendaCase.agendaId);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function downloadCaseNote(agendaCase: AgendaCase) {
    try {
      setLoading(true);
      const ots = await OtsService.getOtsById(agendaCase.otsId);
      const url = await OtsService.getPublicUrl({
        url: ots.note,
      });
      if (url) {
        window.open(url, '__blank');
      } else {
        setSnackbar('Case Note not found', 'error');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function updateDecision(
    agendaCase: AgendaCase,
    formData: Partial<CommitteeDecisionFormData>
  ) {
    try {
      setLoading(true);
      const deletedInstallmentPlan = (
        formData.deletedInstallmentPlans ?? []
      ).map(i => {
        const updatedItem = { ...i };
        updatedItem.date = DateUtility.parseToLocalDate(updatedItem.date);
        return updatedItem;
      });
      const newlyCreatedInstallmentPlan = (
        (formData.installmentPlans ?? []).filter(i => i.id == undefined) ?? []
      ).map(i => {
        const updatedItem = { ...i };
        updatedItem.date = DateUtility.parseToLocalDate(updatedItem.date);
        return updatedItem;
      });
      const updatedInstallmentPlan = (
        (formData.installmentPlans ?? []).filter(i => i.id != undefined) ?? []
      ).map(i => {
        const updatedItem = { ...i };
        updatedItem.date = DateUtility.parseToLocalDate(updatedItem.date);
        return updatedItem;
      });
      if (
        deletedInstallmentPlan.length > 0 ||
        newlyCreatedInstallmentPlan.length > 0 ||
        updatedInstallmentPlan.length > 0
      ) {
        await AgendaService.updateInstallmentPlan({
          caseId: agendaCase.id,
          createPlans: newlyCreatedInstallmentPlan,
          updatePlans: updatedInstallmentPlan,
          deletePlans: deletedInstallmentPlan,
        });
      }

      await AgendaService.takeActionOnCase({
        id: agendaCase.id,
        committeeComment: formData.comment,
        committeeDecisionStatus: formData.status,
        dayOfRepayment: formData.dayOfRepayment,
        installments: formData.installments,
        discussionStarted: true,
      });
      fetchApprovedCases(agendaCase.agendaId);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchCases(id: string) {
    fetchApprovedCases(id);
    fetchRejectedCases(id);
    fetchPendingApprovalCases(id);
  }

  async function fetchApprovedCases(id: string) {
    try {
      setLoading(true);
      setCases([]);
      const approvedCases = await AgendaService.getAgendaCaseList({
        agendaId: id,
        includeLoanDetails: true,
        meetingStatus: AgendaCaseStatus.APPROVED,
      });
      setCases(approvedCases.records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }
  async function fetchRejectedCases(id: string) {
    try {
      setLoadingRejectedCases(true);
      setRejectedCases([]);
      const rejectedCases = await AgendaService.getAgendaCaseList({
        agendaId: id,
        includeLoanDetails: true,
        meetingStatus: AgendaCaseStatus.REJECTED,
      });
      setRejectedCases(rejectedCases.records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingRejectedCases(false);
    }
  }
  async function fetchPendingApprovalCases(id: string) {
    try {
      setLoadingPendingCases(true);
      setPendingApprovalCases([]);
      const pendingApprovalCases = await AgendaService.getAgendaCaseList({
        agendaId: id,
        includeLoanDetails: true,
        meetingStatus: AgendaCaseStatus.PENDING_CHAIR_APPROVAL,
      });
      setPendingApprovalCases(pendingApprovalCases.records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingPendingCases(false);
    }
  }

  async function submitAgendaToChair() {
    try {
      if (!agendaId) return;
      setLoading(true);
      await AgendaService.submitToChair(agendaId);
      fetchAgenda(agendaId);
      fetchCases(agendaId);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const isSubmittedToChair = () => {
    return agenda?.status == AgendaStatus.PENDING_CHAIR_APPROVAL;
  };

  const canSubmitToChair = () => {
    if (!agendaId) return false;
    if (isSubmittedToChair()) return false;
    const approvedCasesForMeeting =
      cases.filter(i => i.meetingStatus == AgendaCaseStatus.APPROVED) ?? [];
    const casesWithDiscussionDone =
      cases.filter(i => i.committeeDecisionStatus != null) ?? [];
    return approvedCasesForMeeting.length == casesWithDiscussionDone.length;
  };

  async function addOtsRequestsToAgenda(requests: Ots[]) {
    try {
      const userId = user?.id;
      const agendaId = agenda.id;
      if (!userId || !agendaId) return;
      setLoading(true);
      await AgendaService.addCasesToAgenda({
        agendaId: agendaId,
        otsIds: requests.map(i => i.id),
        userId: userId,
      });
      fetchApprovedCases(agendaId);
      fetchPendingApprovalCases(agendaId);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getMembers = () => {
    const users = getValueFromRecord<VegaUser[]>(
      agenda?.metaData,
      AgendaMetaDataKeys.MEMBERS
    );
    if (!users || users.length === 0) {
      return 'No users';
    }

    const names = users.slice(0, 3).map(user => user.firstName);
    const additionalCount = users.length > 3 ? `+${users.length - 3}` : '';

    return (
      names.join(', ') + (additionalCount ? ` and ${additionalCount} more` : '')
    );
  };

  useEffect(() => {
    if (agendaId) fetchAgenda(agendaId);
  }, [agendaId]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[{ label: 'Agenda', link: -1 }, { label: 'View Details' }]}
            />
          );
        }}
        renderRightView={() => {
          if (isSubmittedToChair() == true) {
            return (
              <VegaPill
                text={'Submitted to chair for approval'}
                backgroundColor={COLOR.GREEN.LIGHT}
                textColor={COLOR.GREEN.DARK}
              />
            );
          }
          return (
            <Stack direction={'row'} gap={1}>
              <VegaButton
                variant="outlined"
                text="Add Cases"
                onClick={() => {
                  openAddCasesDrawer();
                }}
              ></VegaButton>
              <VegaButton
                text="Submit to chair"
                disabled={canSubmitToChair() == false}
                onClick={submitAgendaToChair}
              ></VegaButton>
            </Stack>
          );
        }}
      />

      <VegaPageContent paddingBottom={4} paddingTop={8}>
        <OtsDetailsWrapper>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2}>
                <OtsKeyValueDisplay
                  text="Date"
                  value={DateUtility.formatStringToDDMMYYYYWithTime(
                    agenda?.scheduledDateTime
                  )}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2}>
                <OtsKeyValueDisplay
                  text="Committee number"
                  value={agenda?.committeeNumber}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2}>
                <OtsKeyValueDisplay
                  text="Agenda Creator"
                  value={
                    getValueFromRecord<VegaUser>(
                      agenda?.metaData,
                      AgendaMetaDataKeys.CREATOR
                    )?.firstName ?? '-'
                  }
                  loading={loading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2}>
                <OtsKeyValueDisplay
                  text="Chair person"
                  value={
                    getValueFromRecord<VegaUser>(
                      agenda?.metaData,
                      AgendaMetaDataKeys.CHAIR
                    )?.firstName ?? '-'
                  }
                  loading={loading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2}>
                <OtsKeyValueDisplay
                  text="Members"
                  value={getMembers()}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={1} lg={1}>
                <OtsKeyValueDisplay
                  text="Status"
                  loading={loading}
                  renderValue={() => (
                    <VegaPill
                      text={_.startCase(_.toLower(agenda?.status))}
                      backgroundColor={getColorForStatus(agenda?.status)?.LIGHT}
                      textColor={getColorForStatus(agenda?.status)?.DARK}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </OtsDetailsWrapper>
      </VegaPageContent>
      <VegaPageContent>
        <VegaDataGrid
          data={cases}
          loading={loading}
          columns={getColumnDefinition({
            agenda: agenda,
            onViewDetailsClick: item => {
              navigate(ROUTES.OTS_DETAILS.replace(':otsId', item.otsId));
            },
            onUpdateDecisionClick: function (item: AgendaCase): void {
              openCommitteeDrawer({ agendaCase: item });
            },
            onNoteClick: function (item: AgendaCase): void {
              downloadCaseNote(item);
            },
            onStartDiscussionClick: item => {
              startDiscssion(item);
            },
            hideRejectionReason: true,
          })}
          idColumn="id"
          rowCount={cases.length}
        />
      </VegaPageContent>

      <VegaPageContent>
        <OtsDetailsWrapper text="Pending Chair Approval">
          <VegaDataGrid
            data={pendingApprovalCases}
            loading={loadingPendingCases}
            columns={getColumnDefinition({
              agenda: agenda,
              onViewDetailsClick: item => {
                navigate(ROUTES.OTS_DETAILS.replace(':otsId', item.otsId));
              },
              hideStartDiscussion: true,
              hideCommitteeDiscussion: true,
              hideNote: true,
              hideRejectionReason: true,
            })}
            idColumn="id"
            rowCount={pendingApprovalCases.length}
          />
        </OtsDetailsWrapper>
      </VegaPageContent>

      <VegaPageContent>
        <OtsDetailsWrapper text="Rejected">
          <VegaDataGrid
            data={rejectedCases}
            loading={loadingRejectedCases}
            columns={getColumnDefinition({
              agenda: agenda,
              onViewDetailsClick: item => {
                navigate(ROUTES.OTS_DETAILS.replace(':otsId', item.otsId));
              },
              hideStartDiscussion: true,
              hideCommitteeDiscussion: true,
              hideNote: true,
            })}
            idColumn="id"
            rowCount={rejectedCases.length}
          />
        </OtsDetailsWrapper>
      </VegaPageContent>

      <UpdateCommitteeDecisionDrawer
        open={isCommitteeDrawerOpen}
        onClose={function (): void {
          closeCommitteeDrawer();
        }}
        agendaCase={committeeDrawerProps.agendaCase}
        onSubmit={updateDecision}
      />

      <AddCasesToAgendaDrawer
        open={isAddCasesDrawerOpen}
        onClose={function (): void {
          closeAddCasesDrawer();
        }}
        onSubmit={addOtsRequestsToAgenda}
      />
    </div>
  );
}

export default AgendaDetailsPage;

const getColumnDefinition = (data: {
  onViewDetailsClick: (item: AgendaCase) => void;
  onUpdateDecisionClick?: (item: AgendaCase) => void;
  onStartDiscussionClick?: (item: AgendaCase) => void;
  onNoteClick?: (item: AgendaCase) => void;
  agenda?: Agenda;
  hideStartDiscussion?: boolean;
  hideNote?: boolean;
  hideCommitteeDiscussion?: boolean;
  hideRejectionReason?: boolean;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'loanNo',
      headerName: 'LAN',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = loan?.loanId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'veritical',
      headerName: 'Veritical',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = loan?.lmsVariables?.segment;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = loan?.lmsVariables?.bucket;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'outstanding',
      headerName: 'Total Outstanding',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        const loan = getValueFromRecord<LoanData>(
          item?.metaData,
          AgendaCaseMetaDataKeys.Loan
        );
        const displayText = StringUtility.formatToINR(
          loan?.lmsVariables?.outStandingTotal
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'generatedNote',
      headerName: 'Note',
      flex: 0.7,
      minWidth: 150,
      hide: data.hideNote,
      renderCell: props => {
        const item = getCaseForRow(props);
        return (
          <VegaText
            onClick={() => data.onNoteClick?.(item)}
            text={'Note'}
            style={{ color: COLOR.BLUE[300], cursor: 'pointer' }}
          ></VegaText>
        );
      },
    },

    {
      field: 'otsDetails',
      headerName: 'Ots',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const item = getCaseForRow(props);
        return (
          // <VegaButton
          //   text="View Details"
          //   variant="text"
          //   sx={{ px: 0.5 }}
          //   onClick={() => {
          //     data.onViewDetailsClick(item);
          //   }}
          // />
          <VegaIconButton
            tooltipTitle="View Details"
            onClick={() => {
              data.onViewDetailsClick(item);
            }}
            sx={{ bgcolor: '#E7EDFC' }}
            icon={
              <VisibilityOutlined
                sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
              />
            }
          />
        );
      },
    },

    {
      field: 'discussion',
      headerName: 'Discussion',
      flex: 0.6,
      minWidth: 150,
      hide: data.hideStartDiscussion,
      renderCell: props => {
        const item = getCaseForRow(props);
        const decisionDone = item.committeeDecisionStatus != null;
        const discussionStarted = item.discussionStarted;
        const isAgendaPublished =
          data?.agenda?.status == AgendaStatus.PUBLISHED;
        if (decisionDone) {
          return (
            <VegaPill
              text={'Discussed'}
              backgroundColor={COLOR.GREEN.LIGHT}
              textColor={COLOR.GREEN.DARK}
            />
          );
        }

        if (discussionStarted == true) {
          return (
            <VegaPill
              text={'In Progress'}
              backgroundColor={COLOR.ORANGE.LIGHT}
              textColor={COLOR.ORANGE.DARK}
            />
          );
        }

        return (
          <VegaButton
            variant="outlined"
            text="Start"
            disabled={isAgendaPublished == false}
            sx={{ px: 0.5 }}
            onClick={() => {
              data.onStartDiscussionClick?.(item);
            }}
          />
        );
      },
    },
    {
      field: 'update',
      headerName: 'Committe Descision',
      flex: 0.6,
      minWidth: 150,
      hide: data.hideCommitteeDiscussion,
      renderCell: props => {
        const item = getCaseForRow(props);
        const isApprovedForMeeting =
          item.meetingStatus == AgendaCaseStatus.APPROVED;
        const isAgendaPublished =
          data?.agenda?.status == AgendaStatus.PUBLISHED;
        const discussionStarted = item.discussionStarted ?? false;
        const shouldDisableButton =
          isAgendaPublished == false ||
          isApprovedForMeeting == false ||
          discussionStarted == false;
        return (
          <VegaButton
            text="Update"
            disabled={shouldDisableButton}
            sx={{ px: 0.5 }}
            onClick={() => {
              data.onUpdateDecisionClick?.(item);
            }}
          />
        );
      },
    },
    {
      field: 'reason',
      headerName: 'Reason For Rejection',
      flex: 0.6,
      minWidth: 150,
      hide: data.hideRejectionReason,
      renderCell: props => {
        const item = getCaseForRow(props);
        return <VegaText text={item?.meetingExclusionReason ?? '-'} />;
      },
    },
  ];
  return COLUMN_DEF;
};

const getCaseForRow = (props: any) => props.row as AgendaCase;

const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case AgendaStatus.DRAFT:
      return COLOR.ORANGE;
    case AgendaStatus.PUBLISHED:
      return COLOR.GREEN;
  }
};
