import React, { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';

import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';

import { Stack } from '@mui/material';
import VegaText from '../../components/common/VegaText';
import LeaveHistory from '../../components/Leave/LeaveHistory';
import PendingLeaveRequest from '../../components/Leave/PendingLeaveRequest';
import { TabHeading } from '../../types/leave';

enum TabType {
  Pending,
  History,
}

function LeaveRequestActions() {
  const [selectedTab, setSelectedTab] = useState<number>(TabType.Pending);
  const [tabHeading, setTabHeading] = useState<string>(TabHeading.PENDING);

  function _onTabChange(value: number) {
    setSelectedTab(value);
  }

  const tabItems = [
    { label: TabHeading.PENDING, tabType: TabType.Pending },
    { label: TabHeading.HISTORY, tabType: TabType.History },
  ];
  function getTabComponent(tabType: TabType, tabHeading: string) {
    switch (tabType) {
      case TabType.History:
        return <LeaveHistory tabHeading={tabHeading} />;
      case TabType.Pending:
        return <PendingLeaveRequest tabHeading={tabHeading} />;
      default:
        return null;
    }
  }
  return (
    <div>
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'1rem'}>
              <VegaText
                text={'Leave Management'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  const selectedTabItem = tabItems.find(
                    item => item.tabType === selected
                  );
                  setTabHeading(selectedTabItem.label);
                  _onTabChange(selectedTabItem.tabType);
                }}
              >
                {tabItems.map(item => (
                  <VegaTabBarItem key={item.tabType} label={item.label} />
                ))}
              </VegaTabBar>
            </Stack>
          );
        }}
      />

      {getTabComponent(selectedTab, tabHeading)}
    </div>
  );
}

export default LeaveRequestActions;
