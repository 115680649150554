import { GridColumns } from '@mui/x-data-grid';
import {
  ConfigurationStatusType,
  ConfigurationsListProps,
} from '../../../../types/configurationType';
import { DateUtility } from '../../../../utils/DateUtlility';
import VegaText from '../../../../components/common/VegaText';
import { COLOR } from '../../../../utils/ColorUtility';
import { VegaPill } from '../../../../components/common/VegaPill';
import { Stack } from '@mui/material';
import { VegaSwitch } from '../../../../components/common/VegaSwitch';
import VegaIconButton from '../../../../components/common/VegaIconButton';
import { EditIcon } from '../../../../components/Icons/Icons';

interface IProps {
  updateStatusForConfig: (
    config: ConfigurationsListProps,
    status: string
  ) => void;
  editConfig: (config: ConfigurationsListProps) => void;
}

export const ConfigurationColumn = ({
  updateStatusForConfig,
  editConfig,
}: IProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 0.5,
      minWidth: 100,
      renderCell: props => {
        const config = props.row as ConfigurationsListProps;
        const createdAt = config.createdAt
          ? DateUtility.formatStringToDDMMYYYY(config.createdAt)
          : '--';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={createdAt}
          />
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.7,
      minWidth: 150,
    },

    {
      field: 'subtype',
      headerName: 'Sub Type',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const config = props.row as ConfigurationsListProps;
        const status = config.status;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <VegaPill
              text={status}
              backgroundColor={getStatus(status)?.color?.LIGHT}
              textColor={getStatus(status)?.color?.DARK}
              borderColor={getStatus(status)?.color?.DARK}
            />
            <VegaSwitch
              checked={getStatus(status).isActive}
              onClick={() =>
                updateStatusForConfig(config, getStatus(status).updateStatus)
              }
            />
          </Stack>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      minWidth: 150,
      renderCell: props => {
        const config = props.row as ConfigurationsListProps;
        const status = config.status;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {status === 'ACTIVE' && (
              <VegaIconButton
                sx={{ bgcolor: '#E7EDFC' }}
                tooltipTitle="Edit config"
                icon={<EditIcon />}
                onClick={() => editConfig(config)}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getStatus = (status: string) => {
  if (!status) return;
  switch (status) {
    case ConfigurationStatusType.ACTIVE:
      return {
        isActive: true,
        text: ConfigurationStatusType.ACTIVE,
        color: COLOR.GREEN,
        updateStatus: ConfigurationStatusType.INACTIVE,
      };
    case ConfigurationStatusType.INACTIVE:
      return {
        isActive: false,
        text: ConfigurationStatusType.INACTIVE,
        color: COLOR.RED,
        updateStatus: ConfigurationStatusType.ACTIVE,
      };
  }
};
