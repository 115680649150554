import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { RoleServices } from '../../../Apis/RoleServices';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import VegaText from '../../../components/common/VegaText';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { GetRoleAccessList } from '../../../types/roleType';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import PayoutForMonth from './modules/PayoutForMonth';
import PayoutForMonthOnMonth from './modules/PayoutForMonthOnMonth';
import VegaMultiSelect from '../../../components/common/VegaMultiSelect';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';

const IncentivesGamificationsForMonth = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [roles, setRoles] = useState<VegaSelectOption[]>([]);
  const { setSnackbar } = useSnackbar();

  const getRolesList = async () => {
    setLoading(true);
    try {
      const request = { clientId: 'capri', type: 'UI' } as GetRoleAccessList;
      const response = await RoleServices.getRolesAccess(request);
      if (response) {
        const rolesList = response.roleResourceDtoList.map(
          role =>
            ({
              label: role.roleDto.name,
              value: role.roleDto.name,
            } as VegaSelectOption)
        );
        setRoles(rolesList);
        setSelectedRole([rolesList[0]?.label]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const handleDelete = (index: number) => {
    const updatedSelectedRoles = [...selectedRole];
    updatedSelectedRoles.splice(index, 1);
    setSelectedRole(updatedSelectedRoles);
  };

  const handleChange = (selected: string) => {
    if (selectedRole.includes(selected)) {
      const updatedSelectedRoles = selectedRole.filter(
        role => role !== selected
      );
      setSelectedRole(updatedSelectedRoles);
    } else {
      setSelectedRole([...selectedRole, selected]);
    }
  };

  useEffect(() => {
    getRolesList();
  }, []);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Incentives For Month'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />

      <VegaPageHeader
        sx={{ borderBottom: 'none', justifyContent: 'end' }}
        renderRightView={() => {
          return (
            <Stack width={400}>
              <VegaFormInputField label={'Select role'}>
                <VegaSelectWithCheckbox
                  loading={loading}
                  options={roles}
                  onSelect={handleChange}
                  handleDelete={handleDelete}
                  selected={selectedRole}
                />
              </VegaFormInputField>
            </Stack>
          );
        }}
      />

      <VegaPageContent
        sx={{
          height: 'calc(100% - 78px)',
          overflow: 'auto',
        }}
        py={'1rem'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PayoutForMonth selectedRole={selectedRole} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PayoutForMonthOnMonth selectedRole={selectedRole} roles={roles} />
          </Grid>
        </Grid>
      </VegaPageContent>
    </>
  );
};

export default IncentivesGamificationsForMonth;
