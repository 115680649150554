import { useEffect, useState } from 'react';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { ProcessListProps } from '../../../types/teleAllocation';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';

interface IProps {
  selected: string;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
}

const ProcessSelector = ({
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [users, setProcess] = useState<VegaSelectOption[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + 1 + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== users.length) {
        setPage(page + 1);
      }
    }
  };

  const getProcessList = async () => {
    TeleAllocationService.getProcessList({ page, size: 10 }).then(res => {
      const newArr = res?.records?.map((process: ProcessListProps) => {
        return {
          value: process.slashRtcProcessId,
          label: process.name,
        } as VegaSelectOption;
      });
      if (page === 0) {
        setProcess(newArr);
      } else {
        setProcess([...users, ...newArr]);
      }
      setTotalItem(res.totalItems);
    });
  };

  useEffect(() => {
    getProcessList();
  }, [page]);
  return (
    <VegaSelect
      placeholder={label ?? 'Select Process Id'}
      size="small"
      value={selected ?? ''}
      onChange={e => handleChange(e.target.value)}
      options={users}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default ProcessSelector;
