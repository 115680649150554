import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCriteriaByCriteriaId } from '../../actions/AllocationActions';
import { getAllocations } from '../../features/allocationSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { Criteria } from '../../types/allocations';
import VegaBreadCrumb from '../common/VegaBreadCrumb';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import VegaText from '../common/VegaText';
import VegaTitleCard from '../common/VegaTitleCard';
import AttributeDetails from './AttributeDetails';
import CriteriaDetails from './CriteriaDetails';
import ListOfLoanAccount from './ListOfLoanAccount';

const ViewAutoAllocation = () => {
  const { criteriaId } = useParams();
  const { criteriaData, loadingOfViewCriteria } =
    useAppSelector(getAllocations);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCriteriaByCriteriaId(criteriaId));
  }, [criteriaId]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: `Criteria`,
                  link: -1,
                },
                {
                  label: 'View Criteria',
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
        <VegaTitleCard title="Criteria Details">
          <CriteriaDetails />
        </VegaTitleCard>
      </VegaPageContent>
      <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
        <VegaTitleCard title="Attribute Details">
          <>
            {criteriaData?.criteriaList && criteriaData.criteriaList.length
              ? criteriaData.criteriaList.map(
                  (criteria: Criteria, index: number) => (
                    <>
                      <Stack gap={2}>
                        <VegaText
                          text={`Filter ${index + 1}`}
                          fontWeight={600}
                          fontSize={'1rem'}
                        />

                        <AttributeDetails
                          criteria={criteria}
                          loading={loadingOfViewCriteria}
                        />
                      </Stack>
                    </>
                  )
                )
              : ''}
          </>
        </VegaTitleCard>
      </VegaPageContent>
      <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
        <ListOfLoanAccount />
      </VegaPageContent>
    </>
  );
};

export default ViewAutoAllocation;
