import { useEffect, useState } from 'react';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import { RoleAction, getRoleAccess } from '../../../features/roleAccessSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GetRoleAccessList, RoleResourceDto } from '../../../types/roleType';
import { ScreenOpenType } from '../UAM';
import { RoleAccessListColumn } from '../columns/RoleAccessListColumn';
import AddScreenDrawer from './AddScreenDrawer';
import RemoveScreenDrawer from './RemoveScreenDrawer';

const ListOfRoleAccess = () => {
  const { roles, loading } = useAppSelector(getRoleAccess);
  const dispatch = useAppDispatch();

  const [openAddScreenDrawer, setOpenAddScreenDrawer] =
    useState<boolean>(false);
  const [openEditScreenDrawer, setOpenEditScreenDrawer] =
    useState<boolean>(false);
  const [roleDetails, setRoleDetails] = useState<RoleResourceDto | null>(null);

  const toggleAddScrrenDrawer = () => {
    setOpenAddScreenDrawer(!openAddScreenDrawer);
  };
  const toggleEditScrrenDrawer = () => {
    setOpenEditScreenDrawer(!openEditScreenDrawer);
  };

  const openScreenDrawer = (
    role: RoleResourceDto,
    openType: ScreenOpenType
  ) => {
    setRoleDetails(role);
    if (openType === ScreenOpenType.ADD) {
      toggleAddScrrenDrawer();
    } else {
      toggleEditScrrenDrawer();
    }
  };

  const getRolesList = () => {
    const request = { clientId: 'capri', type: 'UI' } as GetRoleAccessList;
    dispatch(RoleAction.fetchRoles(request));
  };

  useEffect(() => {
    getRolesList();
  }, []);

  const getRowHeight = params => {
    const lineHeight = 15;
    const numberOfLines = Math.ceil(params.model.resourceDtos.length);
    return 40 + numberOfLines * lineHeight;
  };
  return (
    <>
      <VegaPageContent my={'1rem'}>
        <VegaDataGrid
          hideFooter
          idColumn="id"
          data={roles}
          loading={loading}
          columns={RoleAccessListColumn({ openScreenDrawer })}
          getRowHeight={getRowHeight}
        />
      </VegaPageContent>

      <AddScreenDrawer
        open={openAddScreenDrawer}
        onClose={toggleAddScrrenDrawer}
        roleDetails={roleDetails}
      />
      <RemoveScreenDrawer
        open={openEditScreenDrawer}
        onClose={toggleEditScrrenDrawer}
        roleDetails={roleDetails}
      />
    </>
  );
};

export default ListOfRoleAccess;
