import { Stack } from '@mui/material';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import { toLowerCase } from '../../../../constants/commonFunction';
import {
  getConfigurationState,
  setConfigurations,
} from '../../../../features/configurationSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ConfigurationDto } from '../../../../types/configurationType';
import UserSelect from '../Selector/UserSelect';

export enum NcmDpdFilterType {
  NCM_DPD_240_PLUS = 'NCM_DPD_240_PLUS',
  NCM_DPD_240_MINUS = 'NCM_DPD_240_MINUS',
}

const NcmDpdForm = () => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  return (
    <Stack gap={2}>
      <VegaFormInputField label={'select filter'}>
        <VegaSelect
          value={configurations.type}
          options={getFilterType()}
          onChange={select => {
            handleConfigChange('type', select.target.value);
          }}
        />
      </VegaFormInputField>
      <VegaFormInputField label={'select user'}>
        <UserSelect />
      </VegaFormInputField>
    </Stack>
  );
};

export default NcmDpdForm;

const getFilterType = () => {
  const options = Object.values(NcmDpdFilterType).map(
    (value: string) =>
      ({
        label: toLowerCase(value),
        value,
      } as VegaSelectOption)
  );
  return options;
};
