import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import React from 'react';
import { SegmentDto } from '../../../../types/analytics';
import VegaText from '../../../../components/common/VegaText';
import {
  BucketEnumForYearAnalytics,
  CelenderTypeEmun,
  TimePeriodEnum,
  TimePeriodEnumForPenal,
} from '../../../../features/analyticsDashboardSlice';
import VegaIconButton from '../../../../components/common/VegaIconButton';

interface IProps {
  selectedTimePeriod: string;
  calenderType: string;
  selectedMetrics: string;
}

const RoleBasePerformanceColumn = ({
  selectedTimePeriod,
  calenderType,
  selectedMetrics,
}: IProps) => {
  const COL_DEF: GridColumns = [
    {
      field: 'rank',
      headerName: 'Rank',
      flex: 1,
      minWidth: 150,
      renderCell: (props: GridCellParams<SegmentDto>) => {
        const response = props.row as SegmentDto;
        const rank = response?.rank ? response.rank : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={rank}
          />
        );
      },
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const region = response?.region ? response.region : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={region}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const managerName = response?.managerName ? response.managerName : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={managerName}
          />
        );
      },
    },
    {
      field: 'collected',
      headerName: 'POS Collected',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD ||
        selectedTimePeriod === TimePeriodEnumForPenal.YTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const pos = response?.pos ? response.pos?.toFixed(2) : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={pos}
          />
        );
      },
    },
    {
      field: 'totalTarget',
      headerName: 'Target',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD ||
        selectedTimePeriod === TimePeriodEnumForPenal.YTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.totalTarget
          ? response.totalTarget?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'mtdResolutionPercentage',
      headerName: 'MTD Resolution %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.YTD ||
        selectedTimePeriod !== TimePeriodEnumForPenal.MTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const mtdResolutionPercentage = response?.mtdResolutionPercentage
          ? response.mtdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={mtdResolutionPercentage}
          />
        );
      },
    },
    {
      field: 'lmsdResolutionPercentage',
      headerName: 'LMSD Resolution %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.YTD ||
        selectedTimePeriod !== TimePeriodEnumForPenal.MTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.lmsdResolutionPercentage
          ? response.lmsdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'ytdResolutionPercentage',
      headerName: 'YTD Resolution %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD ||
        selectedTimePeriod !== TimePeriodEnumForPenal.YTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const mtdResolutionPercentage = response?.ytdResolutionPercentage
          ? response.ytdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={mtdResolutionPercentage}
          />
        );
      },
    },
    {
      field: 'lysdResolutionPercentage',
      headerName: 'LYSD Resolution %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD ||
        selectedTimePeriod !== TimePeriodEnumForPenal.YTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.lysdResolutionPercentage
          ? response.lysdResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'drr',
      headerName: 'DRR %',
      flex: 1,
      minWidth: 150,
      hide:
        selectedTimePeriod === TimePeriodEnumForPenal.MTD ||
        selectedTimePeriod === TimePeriodEnumForPenal.YTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response.drr ? response.drr?.toFixed(2) : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'percentage',
      headerName: '% Change',
      flex: 1,
      minWidth: 150,

      renderCell: props => {
        const response = props.row as SegmentDto;
        const percentage = response?.percentageChange
          ? response.percentageChange?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={percentage}
          />
        );
      },
    },
    {
      field: 'bestResolutionPercentage',
      headerName: 'Best Res % (Last 12 months)',
      flex: 1,
      minWidth: 150,
      hide: !(
        selectedTimePeriod === TimePeriodEnumForPenal.MTD ||
        selectedTimePeriod === TimePeriodEnumForPenal.YTD
      ),
      renderCell: props => {
        const response = props.row as SegmentDto;
        const drr = response?.bestResolutionPercentage
          ? response.bestResolutionPercentage?.toFixed(2)
          : '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={drr}
          />
        );
      },
    },
    {
      field: 'focusBuckets',
      headerName: 'Focus Bucket',
      flex: 1,
      minWidth: 300,
      hide:
        selectedMetrics !== BucketEnumForYearAnalytics.OVERALL ||
        selectedTimePeriod === TimePeriodEnumForPenal.MTD,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const focusBuckets = response?.focusBuckets
          ? response.focusBuckets
          : 'NA';
        return (
          <VegaIconButton
            sx={{
              width: '100%',
              textAlign: 'start',
            }}
            tooltipTitle={focusBuckets}
            icon={
              <VegaText
                color={'#1B1D22'}
                fontSize={14}
                fontWeight={500}
                text={focusBuckets}
                sx={{
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            }
          />
        );
      },
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      flex: 1,
      minWidth: 300,
      renderCell: props => {
        const response = props.row as SegmentDto;
        const lastActivity = response?.lastActivity
          ? response.lastActivity
          : 'NA';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={lastActivity}
            sx={{
              width: '90%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
export default RoleBasePerformanceColumn;
