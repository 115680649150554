import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AllocationService } from '../../Apis/AllocationService';
import CreateRuleDrawer from '../../components/Allocation/CreateRuleDrawer';
import ExecuteAllocationRuleDrawer, {
  ExecuteAllocationFormData,
} from '../../components/Allocation/ExecuteAllocationRuleDrawer';
import ExecutedCriteriaDialog from '../../components/Allocation/ExecutedCriteriaDialog';
import ExportPenalAndRollbackDrawer, {
  ExportPenalAndOverallFormData,
} from '../../components/Allocation/ExportPenalAndRollbackDrawer';
import ListOfCriteria from '../../components/AutoAllocationPolicy/ListOfCriteria';
import ListOfExecutionHistory from '../../components/AutoAllocationPolicy/ListOfExecutionHistory';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import {
  getAllocations,
  setSelectedRows,
} from '../../features/allocationSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { AppDispatch, useAppSelector } from '../../store';
import {
  AllocationTypes,
  ExecuteCriteriaResponse,
} from '../../types/allocations';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import ListOfPenalAndRollBackExecutionHistory from '../../components/AutoAllocationPolicy/ListOfPenalAndRollBackExecutionHistory';
import ListOfLastMonthAllocationHistory from '../../components/AutoAllocationPolicy/ListOfLastMonthAllocationHistory';

function AutoAllocationPolicy() {
  const { user } = useClientAuth();
  const dispatch = useDispatch<AppDispatch>();
  const { setSnackbar } = useSnackbar();
  const { selectedRows, error } = useAppSelector(getAllocations);
  const getRequestIdFromStorage = localStorage.getItem(
    'requestIdforPenalAndRollback'
  )
    ? localStorage.getItem('requestIdforPenalAndRollback')
    : '';
  const getlastMonthAllocationRequestIdFromStorage = localStorage.getItem(
    'lastMonthAllocationRequestId'
  )
    ? localStorage.getItem('lastMonthAllocationRequestId')
    : '';
  const [requestId, setRequestId] = useState<string>(getRequestIdFromStorage);
  const [lastMonthAllocationRequestId, setLastMonthAllocationRequestId] =
    useState<string>(getlastMonthAllocationRequestIdFromStorage);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [criteriaSearch, setCriteriaSearch] = useState<string>('');
  const [executedCriteriaData, setExecutedCriteriaData] =
    useState<ExecuteCriteriaResponse | null>(null);
  const [executionHistorySearch, setExecutionHistorySearch] =
    useState<string>('');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const [repeatingLastMonthAllcoations, setRepeatingLastMonthAllcoations] =
    useState<boolean>(false);
  const {
    open: openCreateRuleDrawer,
    close: closeCreateRuleDrawer,
    isOpen: isCreateRuleDrawer,
  } = useDrawer();

  const {
    open: openExecuteRulesDrawer,
    close: closeExecuteRulesDrawer,
    isOpen: isExecuteRulesDrawerOpen,
  } = useDrawer(false);
  const {
    open: openACRDialog,
    close: closeACRsDialog,
    isOpen: isACRDialog,
  } = useDrawer(false);

  const {
    open: openExportPenalAndOverallDrawer,
    close: closeExportPenalAndOverallDrawer,
    isOpen: isExportPenalAndOverallDrawerOpen,
  } = useDrawer(false);

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  async function onAllocateClick(formData: Partial<ExecuteAllocationFormData>) {
    setLoading(true);
    try {
      const selectedRules = [...selectedRows];
      if (selectedRows.length == 1) {
        const rule = selectedRules[0];
        const executedCriteria = await AllocationService.executeCriteria({
          criteriaId: rule,
          agentId: formData.agentId,
          allocationTypes: formData.allocationType,
          expiresAt: formData.expiryDate,
        });
        if (executedCriteria.acrResponse) {
          openACRDialog();
          dispatch(setSelectedRows([]));
          setExecutedCriteriaData(executedCriteria);
        }
      }

      setSnackbar('Rule Executed On Loan Accounts.');

      setLoading(false);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');

      setLoading(false);
    } finally {
      dispatch(setSelectedRows([]));
      closeExecuteRulesDrawer();
      setLoading(false);
    }
  }

  async function onExportClick() {
    const exportCriteriaResponse = await AllocationService.exportCriteria();
    window.open(exportCriteriaResponse, '__blank');
  }

  async function onExportPenalAndRollBackClick(
    formData: Partial<ExportPenalAndOverallFormData>
  ) {
    closeExportPenalAndOverallDrawer();
    try {
      const response = await AllocationService.exportPenalAndRollback({
        agentIdList: formData.agentId,
        allocatedBy: user.id,
        allocationType: AllocationTypes.TELE,
        expiresAt: formData.expiryDate,
      });
      if (response.status === 'COMPLETED') {
        setExporting(false);
        localStorage.removeItem('requestIdforPenalAndRollback');
        setRequestId('');
        setSnackbar('Successfully executed Penal And Rollback Tele Rule');
      } else {
        localStorage.setItem('requestIdforPenalAndRollback', response.id);
        setRequestId(response.id);
        setExporting(true);
        getPenalAndRollbackExecutionStatus(response.id);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setExporting(false);
    }
  }

  async function onRepeatLastMonthAllocationClick() {
    try {
      const response = await AllocationService.repeatLastMonthAllocations();
      if (response.status === 'COMPLETED') {
        setRepeatingLastMonthAllcoations(false);
        localStorage.removeItem('lastMonthAllocationRequestId');
        setRequestId('');
        setSnackbar('Successfully executed Penal And Rollback Tele Rule');
      } else {
        localStorage.setItem(
          'lastMonthAllocationRequestId',
          response.requestId
        );
        setLastMonthAllocationRequestId(response.requestId);
        setRepeatingLastMonthAllcoations(true);
        getRepeatLastMonthAllocationStatus(response.requestId);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setRepeatingLastMonthAllcoations(false);
    }
  }

  const getRepeatLastMonthAllocationStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getRepeatLastMonthAllocationsStatus({
          requestId,
        });
      if (response.status === 'COMPLETED') {
        setRepeatingLastMonthAllcoations(false);
        localStorage.removeItem('lastMonthAllocationRequestId');
        setSnackbar('Successfully Repeated Last Month Allocation');
        setLastMonthAllocationRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getPenalAndRollbackExecutionStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getPenalAndRollbackExecutionStatus({
          requestId,
        });
      if (response.status === 'COMPLETED') {
        setExporting(false);
        localStorage.removeItem('requestIdforPenalAndRollback');
        if (response.allocatedLoanCount === 0) {
          setSnackbar(`Execution Completed.No Loans Were Allocated`, 'warning');
        } else {
          setSnackbar(
            `Successfully executed Penal And Rollback Tele Rule . Allocated ${response.allocatedLoanCount} Loans`
          );
        }
        setRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const canEnableExecuteButton = () => {
    return selectedRows.length == 1;
  };
  useEffect(() => {
    if (error?.length) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);

  useEffect(() => {
    if (requestId.length) {
      const exportingFile = setInterval(() => {
        getPenalAndRollbackExecutionStatus(requestId);
      }, 7000);
      return () => clearInterval(exportingFile);
    }
  }, [requestId]);

  useEffect(() => {
    if (lastMonthAllocationRequestId.length) {
      const exportingFile = setInterval(() => {
        getRepeatLastMonthAllocationStatus(lastMonthAllocationRequestId);
      }, 7000);
      return () => clearInterval(exportingFile);
    }
  }, [lastMonthAllocationRequestId]);

  return (
    <div>
      <VegaPageHeader
        title="Auto Allocation Policy"
        renderRightView={() => {
          return selectedTab === 0 ? (
            <Stack direction={'row'} spacing="0.5rem">
              <VegaButton
                text={
                  exporting
                    ? 'Executing...'
                    : 'Execute Penal And Rollback Tele Rule'
                }
                onClick={() => openExportPenalAndOverallDrawer()}
                variant="outlined"
                loading={exporting || !!requestId}
              />
              <VegaButton
                text={
                  exporting ? 'Executing...' : 'Repeat Last Month Allocations'
                }
                onClick={onRepeatLastMonthAllocationClick}
                variant="outlined"
                loading={
                  repeatingLastMonthAllcoations ||
                  !!lastMonthAllocationRequestId
                }
              />
              <VegaButton
                text={'Export'}
                onClick={onExportClick}
                variant="outlined"
              />
              <VegaButton
                text={'Execute'}
                variant="outlined"
                disabled={canEnableExecuteButton() == false}
                onClick={() => openExecuteRulesDrawer()}
              />
              <VegaButton
                text={'Create Rule'}
                onClick={() => openCreateRuleDrawer()}
              />
            </Stack>
          ) : (
            <></>
          );
        }}
      />
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedTab}
              onChange={(e, selected) => {
                setSelectedTab(selected);
                setCriteriaSearch('');
                setExecutionHistorySearch('');
              }}
            >
              <VegaTabBarItem label="Criteria" />
              <VegaTabBarItem label="Executions History " />
              <VegaTabBarItem label="Penal And Rollback Tele Rule History" />
              <VegaTabBarItem label="Repeat Last Month Allocation History" />
            </VegaTabBar>
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection
              hideFilter
              search={
                selectedTab === 0 ? criteriaSearch : executionHistorySearch
              }
              setSearch={value => {
                if (selectedTab === 0) {
                  setCriteriaSearch(value);
                } else {
                  setExecutionHistorySearch(value);
                }
              }}
            />
          );
        }}
      />
      {selectedTab === 0 ? (
        <ListOfCriteria search={criteriaSearch} />
      ) : selectedTab === 1 ? (
        <ListOfExecutionHistory
          search={executionHistorySearch}
          open={isFilterDrawerOpen}
          toggleFilterDrawer={toggleFilterDrawer}
        />
      ) : selectedTab === 2 ? (
        <ListOfPenalAndRollBackExecutionHistory />
      ) : (
        <ListOfLastMonthAllocationHistory />
      )}

      <ExecuteAllocationRuleDrawer
        onAllocateClick={onAllocateClick}
        onClose={closeExecuteRulesDrawer}
        open={isExecuteRulesDrawerOpen}
        loading={loading}
      />
      <ExecutedCriteriaDialog
        onClose={closeACRsDialog}
        open={isACRDialog}
        executedCriteriaData={executedCriteriaData}
      />
      <ExportPenalAndRollbackDrawer
        onAllocateClick={onExportPenalAndRollBackClick}
        onClose={closeExportPenalAndOverallDrawer}
        open={isExportPenalAndOverallDrawerOpen}
        loading={loading}
      />

      <CreateRuleDrawer
        open={isCreateRuleDrawer}
        onClose={function (): void {
          closeCreateRuleDrawer();
        }}
      />
    </div>
  );
}

export default AutoAllocationPolicy;
