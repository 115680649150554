import React from 'react';
import { Stack } from '@mui/material';
import VegaText from '../../../../components/common/VegaText';
import { DownloadIcon } from '../../../../components/Icons/Icons';

type Props = {
  value: string;
  onClick?: () => void;
};
function OtsFileDisplay({ onClick, value }: Props) {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <Stack direction={'row'} gap={1}>
        <DownloadIcon />
        <VegaText text={value} />
      </Stack>
    </div>
  );
}

export default OtsFileDisplay;
