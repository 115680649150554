import { Stack } from '@mui/material';
import React from 'react';
import { ConfigurationServices } from '../../../Apis/ConfigurationServices';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  fetchConfigListSlice,
  getConfigurationState,
  initialOtsConfig,
  setConfigActive,
  setConfigSearch,
  setConfigSearchBy,
  setConfigStatus,
  setConfigurations,
} from '../../../features/configurationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ConfigurationDto,
  ConfigurationType,
  ConfigValueProps,
} from '../../../types/configurationType';
import { ConfigListRequest } from '../../../types/request/cofigurationRequest';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { IConfigActionProps, isEmptyOrUndefined } from '../Configurations';
import { MinMaxSearchFilterType } from './ListOfConfiguration';

interface IProps extends IConfigActionProps {}

const OtsConfig = ({ onClose }: IProps) => {
  const { configurations } = useAppSelector(getConfigurationState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      await ConfigurationServices.addConfig(configurations);
      setSnackbar('Add Config Successfully');
      const request = {
        page: 0,
        size: 10,
        names: [ConfigurationType.OTS],
      } as ConfigListRequest;
      dispatch(setConfigSearch(''));
      dispatch(setConfigStatus(''));
      dispatch(setConfigActive(false));
      dispatch(fetchConfigListSlice(request));
      onClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const onClear = () => {
    dispatch(setConfigurations(initialOtsConfig));
  };

  const handleConfigChange = (key: keyof ConfigurationDto, value: any) => {
    const updateConfig = { ...configurations, [key]: value };
    dispatch(setConfigurations(updateConfig));
  };

  const handlehange = (key: keyof ConfigValueProps, value: number | string) => {
    const updateConfigValueObj = { ...configurations.config, [key]: value };
    handleConfigChange('config', updateConfigValueObj);
  };

  const isInputValid = () => {
    const isAgentTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isRcmTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isNCMTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isHOCTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isChairTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isMdTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isCustomerAckTatValid =
      (configurations.config?.agentTatDays ?? 0) > 0;
    const isMaxResubmissionTatValid =
      (configurations.config?.agentTatDays ?? 0) > 0;
    const isNotificationFreqValid =
      (configurations.config?.agentTatDays ?? 0) > 0;
    const isOpsTatValid = (configurations.config?.agentTatDays ?? 0) > 0;
    const isTechTatValid = (configurations.config?.agentTatDays ?? 0) > 0;

    return (
      isAgentTatValid &&
      isRcmTatValid &&
      isNCMTatValid &&
      isHOCTatValid &&
      isChairTatValid &&
      isMdTatValid &&
      isMaxResubmissionTatValid &&
      isNotificationFreqValid &&
      isOpsTatValid &&
      isTechTatValid &&
      isCustomerAckTatValid
    );
  };

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <VegaButton
              text="Clear All"
              onClick={onClear}
              variant="text"
              sx={{
                color: '#1047DC',
              }}
            />
            <VegaButton
              text="Submit"
              onClick={onSubmit}
              disabled={isInputValid() == false}
            />
          </Stack>
        );
      }}
    >
      <Stack gap={2}>
        <VegaFormInputField label={'Agent Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.agentTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('agentTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'RCM Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.rcmTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('rcmTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'NCM Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.ncmTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('ncmTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'HOC Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.hocTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('hocTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Md Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.mdTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('mdTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Chair Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.chairTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('chairTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Ops Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.opsTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('opsTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Tech Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.techTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('techTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Customer Acknowledgement Tat Days'}>
          <LmTextField
            type="number"
            value={configurations.config?.customerAckTatDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('customerAckTatDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Notification Frequency'}>
          <LmTextField
            type="number"
            value={configurations.config?.notificationFrequencyDays ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('notificationFrequencyDays', value);
            }}
          />
        </VegaFormInputField>
        <VegaFormInputField label={'Max Resubmission'}>
          <LmTextField
            type="number"
            value={configurations.config?.maxResubmissions ?? 0}
            onChange={e => {
              const value = e.target.value && parseFloat(e.target.value);
              handlehange('maxResubmissions', value);
            }}
          />
        </VegaFormInputField>
      </Stack>
    </VegaDrawerContent>
  );
};

export default OtsConfig;
