/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  AuctionIcon,
  DeleteIcon,
  RupeeIcon,
} from '../../../../components/Icons/Icons';
import LmButton from '../../../../components/common/LmButton';
import LmTextField from '../../../../components/common/LmTextField';
import VegaDateTimePicker from '../../../../components/common/VegaDateTimePicker';
import VegaDrawer from '../../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAddAuctionPropertiseMutation } from '../../../../services/properties';
import { DateUtility } from '../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { AuctionService } from '../../../../Apis/AuctionService';
import { Auction, Bidder } from '../../../../types/auction';
import { BidderService } from '../../../../Apis/BidderService';
import VegaAutoComplete from '../../../../components/common/VegaAutoComplete';
import VegaText from '../../../../components/common/VegaText';
import { COLOR } from '../../../../utils/ColorUtility';
import { VegaSelectOption } from '../../../../components/common/VegaSelect';
import dayjs from 'dayjs';

export type StartAuctionFormData = {
  auctionItemId: string;
  reservePrice: number;
  minIncrement: number;
  startDateTime: string;
  endDateTime: string;
  bidders: Bidder[];
};

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

type Props = {
  auctionItemId?: string;
  open: boolean;
  onClose: () => void;
};
const StartAuctionDrawer = ({ auctionItemId, open, onClose }: Props) => {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [bidders, setBidders] = useState<Bidder[]>([]);
  const [formData, setFormData] = useState<Partial<StartAuctionFormData>>({
    startDateTime: TOMORROW.toISOString(),
    endDateTime: DateUtility.addDays(TODAY, 2).toISOString(),
  });
  const [userSearchQuery, setUserSearchQuery] = useState<string>('');

  const isInputValid = () => {
    const isStartDateValid = formData.startDateTime != null;
    const isEndDateValid = formData.endDateTime != null;
    const isReservePriceValid =
      formData.reservePrice != null && formData.reservePrice > 0;
    const isMinIncrementValid =
      formData.minIncrement != null && formData.minIncrement > 0;
    const isMembersValid = (formData?.bidders ?? []).length > 0;
    return (
      isStartDateValid &&
      isEndDateValid &&
      isReservePriceValid &&
      isMinIncrementValid &&
      isMembersValid
    );
  };

  function getUserForId(userId: string) {
    const filtered = bidders.filter(i => i.id == userId) ?? [];
    if (filtered.length > 0) {
      return filtered[0];
    }
  }

  async function fetchBidders() {
    const response = await BidderService.getBidders({});
    setBidders(response.records);
  }

  function addMember(userId: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingUsers = formData.bidders ?? [];
      const isAlreadyPresent = existingUsers.some(i => i.id == userId);
      const user = getUserForId(userId);
      if (isAlreadyPresent == false && user) {
        existingUsers.push(user);
      }
      updatedData.bidders = existingUsers;
      return updatedData;
    });
  }

  function removeMember(userId: string) {
    setFormData(prev => {
      const updatedData = { ...prev };
      const existingUsers = formData.bidders ?? [];
      const isAlreadyPresent = existingUsers.some(i => i.id == userId);
      if (isAlreadyPresent) {
        const filteredUsers = existingUsers.filter(i => i.id != userId);
        updatedData.bidders = filteredUsers;
      }
      return updatedData;
    });
  }

  function onStartDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);

    var endDateTime = DateUtility.parseStringToDate(formData.endDateTime);

    if (endDateTime && parsedDate) {
      if (DateUtility.isBefore(endDateTime, parsedDate)) {
        endDateTime = DateUtility.addDays(parsedDate, 1);
      }
    }

    setFormData(prev => {
      return {
        ...prev,
        startDateTime: parsedDate?.toISOString(),
        endDateTime: endDateTime?.toISOString(),
      };
    });
  }

  function onEndDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    const startDateTime = DateUtility.parseStringToDate(formData.startDateTime);

    if (
      parsedDate &&
      startDateTime &&
      DateUtility.isBefore(parsedDate, startDateTime)
    ) {
      return;
    }

    setFormData(prev => {
      return {
        ...prev,
        endDateTime: parsedDate?.toISOString(),
      };
    });
  }
  const onCreateClick = async () => {
    try {
      if (!auctionItemId) return;
      setLoading(true);

      const request = {
        auctionItemId: auctionItemId,
        minIncrement: formData.minIncrement,
        reservePrice: formData.reservePrice,
        bidders: formData.bidders?.map(i => i.id),
        startDateTime: DateUtility.addAsiaKolkataTimeZoneDifference(
          formData.startDateTime
        )?.toISOString(),
        endDateTime: DateUtility.addAsiaKolkataTimeZoneDifference(
          formData.endDateTime
        )?.toISOString(),
      };
      await AuctionService.createAuction(request);
      handleClose();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = () => {
    if (userSearchQuery.length > 0) {
      const filteredUsers = bidders.filter(i => {
        const query = userSearchQuery.toLowerCase();
        const fullName = `${i.userName} (${i.mobile})`;
        return fullName.toLowerCase().includes(query);
      });
      return filteredUsers.map(i => {
        const fullName = i.userName;
        const option: VegaSelectOption = {
          value: i.id,
          label: `${fullName} (${i.mobile})`,
        };
        return option;
      });
    }

    return bidders.map(i => {
      const fullName = i.userName;
      const option: VegaSelectOption = {
        value: i.id,
        label: `${fullName} (${i.mobile})`,
      };
      return option;
    });
  };

  function handleClose() {
    onClose();
    setFormData({
      startDateTime: TODAY.toISOString(),
      endDateTime: TOMORROW.toISOString(),
    });
  }

  useEffect(() => {
    if (open) {
      fetchBidders();
    }
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Start Auction'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text={'Start Auction'}
              startIcon={<AuctionIcon strokeColor="white" />}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
              loading={loading}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Reserve Price'}>
              <LmTextField
                type="number"
                startAdornment={<RupeeIcon />}
                value={formData.reservePrice}
                onChange={e => {
                  const value = e.target.value && parseInt(e.target.value);
                  setFormData(prev => {
                    return {
                      ...prev,
                      reservePrice: value,
                    };
                  });
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Min Raise'}>
              <LmTextField
                type="number"
                startAdornment={<RupeeIcon />}
                value={formData.minIncrement}
                onChange={e => {
                  const value = e.target.value && parseInt(e.target.value);
                  setFormData(prev => {
                    return {
                      ...prev,
                      minIncrement: value,
                    };
                  });
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Auction period'}>
              <Grid container columnGap={'1rem'} rowGap="1.5rem" columns={12}>
                <Grid item xs={12}>
                  <VegaDateTimePicker
                    minDate={TOMORROW}
                    onChange={onStartDateChange}
                    value={formData.startDateTime}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VegaDateTimePicker
                    onChange={onEndDateChange}
                    value={formData.endDateTime}
                    minDate={formData.startDateTime}
                    minDateTime={dayjs(formData.startDateTime)}
                  />
                </Grid>
              </Grid>
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Add Members'}>
              <VegaAutoComplete
                options={filteredUsers()}
                handleChange={e => {
                  setUserSearchQuery(e.target.value);
                }}
                onSelect={selected => {
                  addMember(selected);
                }}
                onClear={function (): void {
                  setUserSearchQuery('');
                }}
                selected={undefined}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Members'}>
              <Stack gap={1}>
                {formData?.bidders?.map(i => {
                  return (
                    <div
                      key={i.id}
                      style={{
                        backgroundColor: COLOR.GREEN.LIGHT,
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <VegaText text={i.userName} color={COLOR.GREEN.DARK} />
                      <IconButton onClick={() => removeMember(i.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                })}
              </Stack>
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default StartAuctionDrawer;
