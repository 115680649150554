import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RoleServices } from '../Apis/RoleServices';
import { UserService } from '../Apis/UserService';
import { RootState } from '../store';
import { VegaUser } from '../types/claim';
import { GetUserListApiRequest } from '../types/request/user';
import { RoleDto } from '../types/roleType';

export interface UserState {
  loading: boolean;
  loadingOfRoles: boolean;
  users: VegaUser[];
  roles: RoleDto[];
  userDetails: VegaUser | null;
  page: number;
  totalItems: number;
  pageSize: number;
  error: string | null;
}

const initialState: UserState = {
  users: [],
  roles: [],
  userDetails: null,
  loading: false,
  loadingOfRoles: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

export class UserActions {
  static fetchUsers = createAsyncThunk(
    'uam/fetch',
    async (request: Partial<GetUserListApiRequest>) => {
      const response = await UserService.getUsers(request);
      return response;
    }
  );
  static fetchRoleForUsers = createAsyncThunk(
    'uam/fetchRoleForUsers',
    async (userId: string) => {
      const response = await RoleServices.getRolesByUserId(userId);
      return response;
    }
  );
}

const UAMSlice = createSlice({
  name: 'uam',
  initialState,
  reducers: {
    setUserDetails: (state: UserState, action) => {
      const { payload } = action;
      state.userDetails = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(UserActions.fetchUsers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserActions.fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = [...action.payload.records];
        state.totalItems = action.payload.totalItems;
      })
      .addCase(UserActions.fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch users';
      })
      .addCase(UserActions.fetchRoleForUsers.pending, state => {
        state.loadingOfRoles = true;
        state.error = null;
        state.roles = [];
      })
      .addCase(UserActions.fetchRoleForUsers.fulfilled, (state, action) => {
        state.loadingOfRoles = false;
        state.roles = [...action.payload];
      })
      .addCase(UserActions.fetchRoleForUsers.rejected, (state, action) => {
        state.loadingOfRoles = false;
        state.error = action.error.message || 'Failed to fetch users';
      });
  },
});

export default UAMSlice;
export const { setUserDetails } = UAMSlice.actions;
export const getUAM = (state: RootState) => state.uam;
