import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DownloadIcon,
  InformationCircleIcon,
} from '../../../components/Icons/Icons';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaApproveDialog from '../../../components/common/VegaApproveDialog';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { VegaPill } from '../../../components/common/VegaPill';
import VegaRejectDialog from '../../../components/common/VegaRejectDialog';
import VegaTabBar from '../../../components/common/VegaTabBar';
import VegaTabBarItem from '../../../components/common/VegaTabItem';
import VegaText from '../../../components/common/VegaText';
import { useDrawer } from '../../../hooks/useDrawer';
import { COLOR } from '../../../utils/ColorUtility';
import {
  IncentiveApprovalStatus,
  OverallAgentIncentiveDetailsRequestDto,
} from '../../../types/request/incentives';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import {
  OverallAgentIncentiveDetails,
  OverallPayoutStatusResponse,
} from '../../../types/incentives';
import { StorageKeys, StorageUtility } from '../../../utils/StorageUtility';
import { AllocationService } from '../../../Apis/AllocationService';
import { LongRunningTaskStatus } from '../../../types/allocations';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import ViewPolicyRejectedReasonDialog from '../../../components/IncentivesGamifications/components/ViewPolicyRejectedReasonDialog';
import VegaIconButton from '../../../components/common/VegaIconButton';
import { OverallPayoutColumn } from './OverallPayoutColumn';

export enum OverallPayoutTabType {
  Previous_Cycle,
  Current_Cycle,
}

type Props = {
  isHocView?: boolean;
};

const getRequestId = StorageUtility.getItem<string>(
  StorageKeys.OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID
)
  ? StorageUtility.getItem<string>(
      StorageKeys.OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID
    )
  : '';

function OverallPayout({ isHocView = false }: Props) {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [search, setSearch] = useState<string>('');
  const { setSnackbar } = useSnackbar();
  const [overallPayoutList, setOverallPayoutList] = useState<
    OverallAgentIncentiveDetails[]
  >([]);
  const [isOverallPayoutListLoading, setIsOverallPayoutListLoading] =
    useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>(getRequestId);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] =
    useState<OverallPayoutStatusResponse>(null);
  const [selectedTab, setSelectedTab] = useState<number>(
    OverallPayoutTabType.Previous_Cycle
  );
  const {
    open: onApproveOpen,
    close: onApproveClose,
    isOpen: isApproveOpen,
  } = useDrawer();
  const {
    open: onRejectOpen,
    close: onRejectClose,
    isOpen: isRejectOpen,
  } = useDrawer();
  const {
    open: onRejectionReasonOpen,
    close: onRejectionReasonClose,
    isOpen: isRejectionReasonOpen,
    props: rejectionReasonProps,
  } = useDrawer();

  function _onTabChange(value: number): void {
    setSelectedTab(value);
  }

  async function fetchData() {
    try {
      if (isHocView) {
        if (selectedTab == OverallPayoutTabType.Previous_Cycle) {
          setIsOverallPayoutListLoading(true);
          const request: Partial<OverallAgentIncentiveDetailsRequestDto> = {
            page,
            size,
            isPreviousCycle: true,
            status: [
              IncentiveApprovalStatus.APPROVED,
              IncentiveApprovalStatus.REJECTED,
              IncentiveApprovalStatus.IN_REVIEW,
            ],
          };
          if (search.length) {
            request.agentName = search;
          }
          const response = await IncentiveServices.getOverallPayoutList(
            request
          );
          setIsOverallPayoutListLoading(false);
          setOverallPayoutList(response?.records);
        } else if (selectedTab == OverallPayoutTabType.Current_Cycle) {
          setIsOverallPayoutListLoading(true);
          const request: Partial<OverallAgentIncentiveDetailsRequestDto> = {
            page,
            size,
            isPreviousCycle: false,
            status: [
              IncentiveApprovalStatus.APPROVED,
              IncentiveApprovalStatus.REJECTED,
              IncentiveApprovalStatus.IN_REVIEW,
            ],
          };
          if (search.length) {
            request.agentName = search;
          }
          const response = await IncentiveServices.getOverallPayoutList(
            request
          );
          setIsOverallPayoutListLoading(false);
          setOverallPayoutList(response?.records);
        }
      } else {
        if (selectedTab == OverallPayoutTabType.Previous_Cycle) {
          setIsOverallPayoutListLoading(true);
          const request: Partial<OverallAgentIncentiveDetailsRequestDto> = {
            page,
            size,
            isPreviousCycle: true,
          };
          if (search.length) {
            request.agentName = search;
          }
          const response = await IncentiveServices.getOverallPayoutList(
            request
          );

          setIsOverallPayoutListLoading(false);
          setOverallPayoutList(response?.records);
        } else if (selectedTab == OverallPayoutTabType.Current_Cycle) {
          setIsOverallPayoutListLoading(true);
          const request: Partial<OverallAgentIncentiveDetailsRequestDto> = {
            page,
            size,
            isPreviousCycle: false,
          };
          if (search.length) {
            request.agentName = search;
          }
          const response = await IncentiveServices.getOverallPayoutList(
            request
          );
          setIsOverallPayoutListLoading(false);
          setOverallPayoutList(response?.records);
        }
      }
    } catch (error) {
      setIsOverallPayoutListLoading(false);
    }
  }

  const getStatus = async () => {
    try {
      const status = await IncentiveServices.getOverAllPayoutPrevCycleStatus();
      setSelectedStatus(status);
    } catch (error) {}
  };

  const onDownloadClick = async () => {
    setDownloadInProgress(true);
    try {
      const response = await IncentiveServices.downloadOverallPayoutPrevCycle({
        isPreviousCycle: selectedTab === 0 ? true : false,
      });
      if (response.status === 'COMPLETED') {
        setDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID
        );
        setRequestId('');
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
      } else {
        StorageUtility.setItem(
          StorageKeys.OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID,
          response.id
        );
        setRequestId(response.id);
        setDownloadInProgress(true);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getDownloadStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID
        );
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
        setRequestId('');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.OVERALL_PAYOUT_PREV_CYCLE_REQUEST_ID
        );
        setSnackbar(response.result, 'error');
        setRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const onSubmitClick = async (
    status: IncentiveApprovalStatus,
    reason?: string
  ) => {
    try {
      if (reason) {
        await IncentiveServices.submitOverallPayoutPrevCycle({
          status,
          remarks: reason,
        });
        getStatus();
      } else {
        await IncentiveServices.submitOverallPayoutPrevCycle({ status });
        getStatus();
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  function onApproveDialogSubmitClick() {
    try {
      onApproveClose();
      onSubmitClick(IncentiveApprovalStatus.APPROVED);
      fetchData();
      getStatus();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function onRejectDialogSubmitClick(reason: string) {
    try {
      onRejectClose();
      await onSubmitClick(IncentiveApprovalStatus.REJECTED, reason);
      await fetchData();
      // await getStatus();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }
  const renderRightView = () => {
    const isPreviousCycle = selectedTab === OverallPayoutTabType.Previous_Cycle;

    if (!selectedStatus?.status || !isPreviousCycle) return null;

    return (
      <Grid container direction="row" gap={1}>
        <Grid item>
          <VegaPill
            text={selectedStatus?.status}
            backgroundColor={getColorForStatus(selectedStatus?.status).LIGHT}
            textColor={getColorForStatus(selectedStatus?.status).DARK}
            borderColor={getColorForStatus(selectedStatus?.status).DARK}
          />
        </Grid>
        {selectedStatus?.status === IncentiveApprovalStatus.REJECTED && (
          <Grid item>
            <VegaIconButton
              icon={
                <InformationCircleIcon
                  strokeColor="rgba(0, 0, 0, 1)"
                  width="12"
                  height="12"
                />
              }
              tooltipTitle="Rejection Reason"
              onClick={() =>
                onRejectionReasonOpen({
                  remarks: selectedStatus?.remarks ?? '',
                })
              }
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const renderActionButtons = () => {
    if (selectedTab === OverallPayoutTabType.Previous_Cycle) {
      if (!isHocView) {
        return (
          <Grid item>
            <VegaButton
              text={
                selectedStatus?.status === IncentiveApprovalStatus.REJECTED
                  ? 'Resubmit'
                  : 'Submit'
              }
              disabled={
                selectedStatus?.status !== IncentiveApprovalStatus.DRAFT &&
                selectedStatus?.status !== IncentiveApprovalStatus.REJECTED
              }
              onClick={() => onSubmitClick(IncentiveApprovalStatus.IN_REVIEW)}
            />
          </Grid>
        );
      } else if (
        isHocView &&
        selectedStatus?.status === IncentiveApprovalStatus.IN_REVIEW
      ) {
        return (
          <>
            <Grid item>
              <VegaButton
                variant="outlined"
                text="Reject"
                onClick={onRejectOpen}
                sx={{
                  backgroundColor: COLOR.RED.LIGHT,
                  borderColor: COLOR.RED.DARK,
                  color: COLOR.RED.DARK,
                  ':hover': {
                    backgroundColor: COLOR.RED.LIGHT,
                    borderColor: COLOR.RED.DARK,
                    color: COLOR.RED.DARK,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <VegaButton
                variant="outlined"
                text="Approve"
                onClick={onApproveOpen}
                sx={{
                  backgroundColor: COLOR.GREEN.LIGHT,
                  borderColor: COLOR.GREEN.DARK,
                  color: COLOR.GREEN.DARK,
                  ':hover': {
                    backgroundColor: COLOR.GREEN.LIGHT,
                    borderColor: COLOR.GREEN.DARK,
                    color: COLOR.GREEN.DARK,
                  },
                }}
              />
            </Grid>
          </>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    // fetchData();
    // getStatus();
    setPage(0);
    setSize(10);
    setSearch('');
  }, [selectedTab]);

  useEffect(() => {
    if (search.length) {
      const getData = setTimeout(() => {
        fetchData();
        getStatus();
      }, 300);
      return () => clearTimeout(getData);
    } else {
      fetchData();
      getStatus();
    }
  }, [page, size, search]);

  useEffect(() => {
    if (requestId.length) {
      const downloadingFile = setInterval(() => {
        getDownloadStatus(requestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [requestId]);

  useEffect(() => {
    setSelectedTab(OverallPayoutTabType.Previous_Cycle);
  }, []);

  return (
    <div>
      <VegaPageHeader
        title="Overall Payout"
        sx={{
          pb: 0,
          bgcolor: 'transparent',
          borderBottom: 'none',
        }}
        renderRightView={() => (
          <Grid container gap={1} direction={'row'}>
            <Grid item>
              <VegaButton
                text="Download"
                variant="outlined"
                startIcon={<DownloadIcon />}
                sx={{
                  ':hover': {
                    backgroundColor: COLOR.WHITE[100],
                    borderColor: COLOR.BLUE[300],
                    color: COLOR.BLUE[300],
                  },
                }}
                loading={downloadInProgress}
                onClick={onDownloadClick}
              />
            </Grid>
            {renderActionButtons()}
          </Grid>
        )}
      />
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  _onTabChange(selected);
                }}
              >
                <VegaTabBarItem label="Previous Cycle" />
                <VegaTabBarItem label="Current Cycle" />
              </VegaTabBar>
            </Stack>
          );
        }}
        renderRightView={renderRightView}
      />
      <VegaPageHeader
        renderRightView={() => (
          <LmFilterSection
            hideFilter
            setSearch={setSearch}
            search={search}
            searchPlaceHolder="Search By Agent Name"
          />
        )}
        sx={{
          pb: 0,
          bgcolor: 'transparent',
          borderBottom: 'none',
        }}
      />
      <VegaPageContent>
        <Box pt={2}>
          <VegaDataGrid
            data={overallPayoutList}
            columns={OverallPayoutColumn}
            idColumn={'id'}
            loading={isOverallPayoutListLoading}
            paginationMode="server"
            onPageChange={page => setPage(page)}
            onPageSizeChange={size => setSize(size)}
            page={page}
            pageSize={size}
            rowCount={overallPayoutList.length}
          />
        </Box>
      </VegaPageContent>
      <VegaApproveDialog
        open={isApproveOpen}
        onClose={onApproveClose}
        onApprove={onApproveDialogSubmitClick}
        header={`Approve Overall Payout Request`}
        buttonText="Yes, Approve"
      >
        <VegaText
          text={`Are you sure you want to approve this overall payout request?`}
          fontWeight={600}
          fontSize={'1.0625rem'}
          color={'black'}
        />
      </VegaApproveDialog>
      <VegaRejectDialog
        text="Reject overall payout request?"
        open={isRejectOpen}
        onClose={onRejectClose}
        onReject={onRejectDialogSubmitClick}
      />
      <ViewPolicyRejectedReasonDialog
        onClose={onRejectionReasonClose}
        rejectionReason={rejectionReasonProps?.remarks}
        show={isRejectionReasonOpen}
      />
    </div>
  );
}

export default OverallPayout;
const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case IncentiveApprovalStatus.APPROVED:
      return COLOR.GREEN;

    case IncentiveApprovalStatus.REJECTED:
      return COLOR.RED;
    case IncentiveApprovalStatus.IN_REVIEW:
      return COLOR.ORANGE;
    case IncentiveApprovalStatus.DRAFT:
      return COLOR.GREY;
  }
};
