import React from 'react';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import {
  getTargetState,
  setBucketDetails,
  setOpenApproveTargetDialog,
  setOpenRejectTargetDialog,
  setViewTargetOpenDialog,
} from '../../../features/targetSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TargetListProps } from '../../../types/targets';
import { TargetApproverColumn } from '../columns/TargetApproverColumn';
import ApproveTargetDialog from './ApproveTargetDialog';
import RejectTargetDialog from './RejectTargetDialog';
import ViewTargetDialog from './ViewTargetDialog';

interface IProps {
  selectedTab: number;
  page: number;
  size: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
}

const ListOfTargetList = ({ selectedTab, page, setPage, setSize }: IProps) => {
  const { targets, loading, totalItems } = useAppSelector(getTargetState);
  const dispatch = useAppDispatch();

  const viewDetails = (target: TargetListProps) => {
    dispatch(setViewTargetOpenDialog(true));
    dispatch(setBucketDetails(target));
  };

  const onOpenApproveDialog = (target: TargetListProps) => {
    dispatch(setOpenApproveTargetDialog(true));
    dispatch(setBucketDetails(target));
  };

  const onOpenRejectDialog = (target: TargetListProps) => {
    dispatch(setOpenRejectTargetDialog(true));
    dispatch(setBucketDetails(target));
  };
  return (
    <>
      <VegaDataGrid
        page={page}
        pageSize={10}
        rowCount={totalItems}
        onPageChange={page => setPage(page)}
        onPageSizeChange={size => setSize(size)}
        data={targets}
        columns={TargetApproverColumn({
          selectedTab,
          viewDetails,
          onOpenApproveDialog,
          onOpenRejectDialog,
        })}
        loading={loading}
        idColumn="id"
      />
      <ApproveTargetDialog />
      <RejectTargetDialog />
      <ViewTargetDialog selectedTab={selectedTab} />
    </>
  );
};

export default ListOfTargetList;
