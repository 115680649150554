export const getErrorMessageFromErrorObj = (
  error: any,
  defaultMessage = 'Something went wrong'
) => {
  const errorResp = error?.response?.data?.apierror;
  if (errorResp) {
    if (errorResp?.status === 'FAILED') {
      return defaultMessage;
    } else {
      return errorResp?.debugMessage || errorResp?.subErrors || defaultMessage;
    }
  } else {
    return defaultMessage;
  }
};

export interface PaginatedResponse<T> {
  records: T[];
  pageNumber: number;
  numberOfItems: number;
  totalPages: number;
  totalItems: number;
}
export interface CountResponse {
  ALLOCATED: number;
  NORMALISED: number;
  Null: number;
  STABILISED: number;
  UNALLOCATED: number;
  COLLECTED: number;
  ROLL_BACK: number;
  ROLL_FORWARD: number;
  OVERDUE: number;
  CANCELLED: number;
  All: number;
  ALL?: number;
}
export interface EscalationCountResponse {
  TODAY: number;
  MINIMUM_VISIT: number;
  ROUTE: number;
  RECEIPT: number;
  TARGET: number;
  EXPENSE: number;
  LEAVE: number;
  OTS: number;
}

export function objectToFormData<T>(obj: T): FormData {
  const formData = new FormData();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          // Handle arrays (e.g., file arrays)
          value.forEach((item, index) => {
            if (item instanceof File) {
              // If the item is a File object, append it with a unique key
              formData.append(`${key}[${index}]`, item);
            } else {
              // For non-File array items, convert them to strings and append
              formData.append(`${key}[${index}]`, String(item));
            }
          });
        } else if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, String(value));
        }
      }
    }
  }

  return formData;
}

export const getURLParameter = (
  paramName: string,
  URLString = window.location.href
): string => {
  const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`);
  const results = regex.exec(URLString);
  if (results && results.length > 0) {
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  return '';
};
export default getURLParameter;
