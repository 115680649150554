import { Box, Collapse, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTeleAgentAllocation } from '../../actions/TeleAgentAction';
import LoanAccountDetails from '../../components/TeleAgent/components/LoanAccountDetails';
import LoanAccountDetailsModal from '../../components/TeleAgent/components/LoanAccountDetailsModal';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { getTeleAgent } from '../../features/teleAgentSlice';
import {
  TeleAgentCallStatus,
  useTeleAgentCallEvents,
} from '../../providers/TeleAgentCallEventsProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { GetTeleAllocationsListRequest } from '../../types/request/allocation';
import { TeleAgentManualAccountViewModel } from '../../types/teleAgent';
import SlashRtcView from './SlashRtcView';

const TeleAgentAuto = () => {
  const { allocations, totalItems } = useAppSelector(getTeleAgent);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const { callEvent } = useTeleAgentCallEvents();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const getTeleAllocation = () => {
    const request = {
      page,
      pageSize: 10,
      teleAllocatonType: 'AUTO',
      teleCallStatus: 'DIALED',
    } as GetTeleAllocationsListRequest;
    if (search.length) {
      request.loanId = search;
    }
    dispatch(fetchTeleAgentAllocation(request));
  };

  useEffect(() => {
    getTeleAllocation();
  }, [dispatch, page, search]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Auto Call'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmFilterSection hideFilter search={search} setSearch={setSearch} />
          );
        }}
      />

      <VegaPageContent>
        <Stack
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '2rem',
          }}
        >
          <Stack>
            <Collapse
              in={callEvent?.status == TeleAgentCallStatus.STARTED}
              timeout={300}
            >
              <LoanAccountDetails loanId={callEvent?.loanId} />
            </Collapse>
            <Collapse
              in={callEvent?.status == TeleAgentCallStatus.ENDED}
              timeout={300}
            >
              <Box
                sx={{
                  p: '1rem',
                  bgcolor: 'white',
                  borderRadius: '0.75rem',
                  border: '1px solid #E1E4EB',
                  my: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack>
                  <VegaText text={'LAN'}></VegaText>
                  <VegaText text={callEvent?.loanId}></VegaText>
                </Stack>
                <VegaButton
                  text={'View Details'}
                  onClick={() => setShowModal(true)}
                ></VegaButton>
              </Box>
            </Collapse>
            <VegaDataGrid
              data={allocations}
              idColumn="id"
              paginationMode="server"
              onPageChange={page => setPage(page)}
              rowCount={totalItems}
              columns={getColumnDefinition({
                onViewDetails: account => {
                  navigate(
                    ROUTES.TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID.replace(
                      ':loanAccountId',
                      account.lan
                    )
                  );
                },
              })}
            />
          </Stack>
          <div style={{ flexBasis: '35%', height: '70vh' }}>
            <SlashRtcView />
          </div>
        </Stack>
        <LoanAccountDetailsModal
          open={showModal}
          loanId={callEvent?.loanId}
          onClose={function (): void {
            setShowModal(false);
          }}
        />
      </VegaPageContent>
    </div>
  );
};

export default TeleAgentAuto;

const getColumnDefinition = (data: {
  onViewDetails: (account: TeleAgentManualAccountViewModel) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'lan',
      headerName: 'Loan A/c No.',
      flex: 0.7,
    },

    {
      field: 'teleAllocatonType',
      headerName: 'Allocation Type',
      flex: 0.7,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
    },
    {
      field: 'allocationDate',
      headerName: 'Allocation Date',
      flex: 0.7,
    },

    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const account = props.row as TeleAgentManualAccountViewModel;
        return (
          <VegaButton
            text={'View Details'}
            variant="text"
            onClick={() => data.onViewDetails(account)}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
