import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LmButton from '../../../../components/common/LmButton';
import LmTextField from '../../../../components/common/LmTextField';
import VegaDateTimePicker from '../../../../components/common/VegaDateTimePicker';
import VegaDrawerContent from '../../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../../components/common/VegaSelect';
import { setOpenAddPropertyDrawer } from '../../../../features/propertiesSlice';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { useAddNewPropertiseMutation } from '../../../../services/properties';
import { useAppDispatch } from '../../../../store';
import { AddPropertiesProps } from '../../../../types/properties';
import { DateUtility } from '../../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import LoanSelector from './LoanSelector';

enum FormField {
  Lan = 'lan',
  Type = 'type',
  Address = 'address',
  PossessionDateTime = 'possessionDateTime',
}

type Props = {
  onPossessionCreate: (formData: Partial<AddPropertiesProps>) => void;
};

const AddNewPossession = ({ onPossessionCreate }: Props) => {
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<Partial<AddPropertiesProps>>({
    possessionDateTime: new Date().toISOString(),
  });

  const [addNewPropertise, { isError, isSuccess, error, isLoading }] =
    useAddNewPropertiseMutation();

  const isInputValid = () => {
    const isDateValid = formData.possessionDateTime != null;
    const isLanValid = formData.lan != null && formData.lan.length > 0;
    const isAddressValid =
      formData.address != null && formData.address.length > 0;
    const isTypeValid = formData.type != null && formData.type.length > 0;
    return isDateValid && isLanValid && isAddressValid && isTypeValid;
  };

  const handleChange = (key: FormField, value: any) => {
    setFormData(prev => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  function handlePossessionDatetime(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    handleChange(FormField.PossessionDateTime, parsedDate.toISOString());
  }
  function onSelectLan(selected: string) {
    handleChange(FormField.Lan, selected);
  }
  async function onSaveClick() {
    await addNewPropertise(formData);
    dispatch(setOpenAddPropertyDrawer(false));
    onPossessionCreate(formData);
  }

  useEffect(() => {
    if (isSuccess) {
      setSnackbar('Add Property Successfully');
    }
    if (isError) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [isError, isSuccess]);

  return (
    <>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Save Details"
              disabled={isInputValid() == false}
              onClick={onSaveClick}
              loading={isLoading}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <LmTextField
              placeholder="Enter Address"
              header="Mortgage property address"
              value={formData.address}
              onChange={e => {
                const value = e.target.value;
                handleChange(FormField.Address, value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label={'select loan id'}>
              <LoanSelector selected={formData.lan} onSelectLan={onSelectLan} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Type Of Property">
              <VegaSelect
                options={propertyTypeOptions()}
                value={formData.type ?? ''}
                onChange={e => {
                  handleChange(FormField.Type, e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label={'select time'}>
              <VegaDateTimePicker
                onChange={handlePossessionDatetime}
                value={formData.possessionDateTime}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </>
  );
};

export default AddNewPossession;

const propertyTypeOptions = () =>
  ['PROPERTY'].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
