import axios from 'axios';
import { Agenda, AgendaCase, OtsInstallmentPlan } from '../types/ots';
import {
  AddCaseToAgendaRequest,
  AgendaCaseActionRequest,
  CreateAgendaRequest,
  CreateCaseInstallmentPlanRequest,
  DeleteInstallmentPlanRequest,
  GetInstallmentPlanRequest,
  ListAgendaCaseRequest,
  ListAgendaRequest,
  SubmitCaseToHocRequest,
  TakeActionOnAgendaCaseRequest,
  UpdateCaseInstallmentPlanRequest,
} from '../types/request/agenda';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/agenda',
  CREATE: '/create',
  LIST_AGENDA: '/list',
  LIST_AGENDA_CASE: '/case/list',
  PUBLISH_AGENDA: '/publish/:id',
  UPDATE_COMMITTEE_DECISION: '/case/committee',
  GET_BY_ID: '/:id',
  TAKE_ACTION_ON_CASE: '/case/committee',
  SUBMIT_TO_CHAIR: '/submit/:id',
  GET_CASE_BY_ID: '/case/:id',
  TAKE_ACTION_ON_CASE_BY_CHAIR: '/case/chair/action',
  SUBMIT_CASE_TO_HOC: '/submit/hoc',
  ADD_CASES_TO_AGENDA: '/add/cases',
  TAKE_ACTION_ON_PENDING_CASE: '/case/action',
  CREATE_INSTALLMENT_PLAN: '/case/installment',
  UPDATE_INSTALLMENT_PLAN: '/case/installment',
  GET_INSTALLMENT_PLAN: '/case/installment',
  DELETE_INSTALLMENT_PLAN: '/case/delete-installment',
};

export class AgendaService {
  static async createAgenda(request: Partial<CreateAgendaRequest>) {
    const endpoint = Endpoint.BASE + Endpoint.CREATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async getAgendaList(
    request: Partial<ListAgendaRequest>
  ): Promise<PaginatedResponse<Agenda>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST_AGENDA;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getAgendaCaseList(
    request: Partial<ListAgendaCaseRequest>
  ): Promise<PaginatedResponse<AgendaCase>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST_AGENDA_CASE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getAgendaCase(id: string): Promise<AgendaCase> {
    const endpoint = Endpoint.BASE + Endpoint.GET_CASE_BY_ID.replace(':id', id);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async takeActionOnCase(
    request: Partial<TakeActionOnAgendaCaseRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.TAKE_ACTION_ON_CASE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async submitCaseToHoc(request: SubmitCaseToHocRequest) {
    const endpoint = Endpoint.BASE + Endpoint.SUBMIT_CASE_TO_HOC;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async takeActionOnCaseByChair(
    request: Partial<AgendaCaseActionRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.TAKE_ACTION_ON_CASE_BY_CHAIR;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async publishAgenda(agendaId: string) {
    const endpoint =
      Endpoint.BASE + Endpoint.PUBLISH_AGENDA.replace(':id', agendaId);
    const response = await axios.post(endpoint);
    return response.data;
  }

  static async getAgenda(agendaId: string) {
    const endpoint =
      Endpoint.BASE + Endpoint.GET_BY_ID.replace(':id', agendaId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async submitToChair(agendaId: string) {
    const endpoint =
      Endpoint.BASE + Endpoint.SUBMIT_TO_CHAIR.replace(':id', agendaId);
    const response = await axios.post(endpoint);
    return response.data;
  }

  static async addCasesToAgenda(data: AddCaseToAgendaRequest) {
    const endpoint = Endpoint.BASE + Endpoint.ADD_CASES_TO_AGENDA;
    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async takeActionOnPendingCases(
    data: Partial<AgendaCaseActionRequest>
  ) {
    const endpoint = Endpoint.BASE + Endpoint.TAKE_ACTION_ON_PENDING_CASE;
    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async createInstallmentPlan(
    data: CreateCaseInstallmentPlanRequest
  ): Promise<OtsInstallmentPlan[]> {
    const endpoint = Endpoint.BASE + Endpoint.CREATE_INSTALLMENT_PLAN;
    const response = await axios.post(endpoint, data);
    return response.data;
  }

  static async updateInstallmentPlan(
    data: UpdateCaseInstallmentPlanRequest
  ): Promise<OtsInstallmentPlan[]> {
    const endpoint = Endpoint.BASE + Endpoint.UPDATE_INSTALLMENT_PLAN;
    const response = await axios.put(endpoint, data);
    return response.data;
  }

  static async getInstallmentPlan(
    data: GetInstallmentPlanRequest
  ): Promise<OtsInstallmentPlan[]> {
    const endpoint = Endpoint.BASE + Endpoint.GET_INSTALLMENT_PLAN;
    const response = await axios.get(endpoint, { params: data });
    return response.data;
  }

  static async deleteInstallmentPlan(
    data: DeleteInstallmentPlanRequest
  ): Promise<OtsInstallmentPlan[]> {
    const endpoint = Endpoint.BASE + Endpoint.DELETE_INSTALLMENT_PLAN;
    const response = await axios.post(endpoint, { data: data });
    return response.data;
  }
}
