import { VisibilityOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import VegaIconButton from '../../components/common/VegaIconButton';
import PerformanceCard from '../../components/Perfomance/components/PerformanceCard';
import { PRIMARY } from '../../constants/style';
import { useDrawer } from '../../hooks/useDrawer';
import {
  Channel,
  ChannelCount,
  CommunicationFilterType,
} from './CommunicationDetails';
import CommunicationLoanDetails from './modules/CommunicationLoanDetails';

interface IProps {
  channelCount: Partial<ChannelCount>;
  loading: boolean;
  filterData: Partial<CommunicationFilterType>;
}

function CommunicationDetailsSummary({
  channelCount,
  loading,
  filterData,
}: IProps) {
  const { open, close, isOpen, props } = useDrawer();
  const getCountValue = (countType: number) => {
    if (countType) {
      return countType;
    } else {
      return 0;
    }
  };

  const summaries = [
    {
      count: channelCount ? getCountValue(channelCount.EMAIL) : 0,
      text: 'Emails',
      channel: Channel.EMAIL,
      loading,
    },
    {
      count: channelCount ? getCountValue(channelCount.SMS) : 0,
      text: 'SMS',
      channel: Channel.SMS,
      loading,
    },
    {
      count: channelCount ? getCountValue(channelCount.IVR) : 0,
      text: 'IVR',
      channel: Channel.IVR,
      loading,
    },
    {
      count: channelCount ? getCountValue(channelCount.WHATSAPP) : 0,
      text: 'WhatsApp',
      channel: Channel.WHATSAPP,
      loading,
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {summaries.map(summary => (
          <Grid key={summary.text} item xs={6} sm={6} md={4} lg={3}>
            <PerformanceCard
              count={summary.count}
              loading={summary.loading}
              text={summary.text}
              bgcolor={'transparant'}
              color={'black'}
              border={'1px solid #E1E4EB'}
              height={'100px'}
              icon={
                <VegaIconButton
                  tooltipTitle="View Loans"
                  onClick={() => {
                    open({ channel: summary.channel });
                  }}
                  sx={{ bgcolor: '#E7EDFC' }}
                  icon={
                    <VisibilityOutlined
                      sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                    />
                  }
                />
              }
            />
          </Grid>
        ))}
      </Grid>
      <CommunicationLoanDetails
        open={isOpen}
        channel={props.channel}
        filterData={filterData}
        onClose={function (): void {
          close();
        }}
      />
    </>
  );
}

export default CommunicationDetailsSummary;
