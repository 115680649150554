import { Stack } from '@mui/material';
import EscalationHistory from '../../../components/Escalations/RouteDeviation/EscalationHistory';
import RouteDeviationDetailsContainer from '../../../components/Escalations/RouteDeviation/RouteDeviationDetailsContainer';
import VegaBreadCrumb from '../../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';

const RouteDeviationViewDetails = () => {
  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none', bgcolor: 'transparent' }}
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Route Deviation', link: -1 },
                { label: `View Details` },
              ]}
            />
          );
        }}
      />
      <RouteDeviationDetailsContainer />
      <VegaPageContent sx={{ pt: '1rem' }}>
        <Stack gap={2} mt={'1rem'}>
          <EscalationHistory />
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default RouteDeviationViewDetails;
