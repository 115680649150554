import { AddCircleOutline } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import AddNewBankDeposit from '../components/BankDeposit/AddNewBankDeposit';
import ListOfBankDeposit from '../components/BankDeposit/ListOfBankDeposit';
import ListOfPastUploads from '../components/BankDeposit/ListOfPastUploads';
import LmBulkUpload from '../components/common/LmBulkUpload';
import LmButton from '../components/common/LmButton';
import LmDialog from '../components/common/LmDialog';
import LmFilterSection from '../components/common/LmFilterSection';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaDrawerContent from '../components/common/VegaDrawerContent';
import VegaPageHeader from '../components/common/VegaPageHeader';
import VegaText from '../components/common/VegaText';
import { PRIMARY } from '../constants/style';
import {
  defaultDepositBankData,
  useBankDeposit,
} from '../providers/BankDepositProvider';
import { ICreateDepositBankProps } from '../types/depositBank';
import { useDrawer } from '../hooks/useDrawer';
import BankDepositFilterForm from '../components/BankDeposit/BankDepositFilterForm';

const DepositBank = () => {
  const {
    loading: loader,
    isAdd,
    isBulkUpload,
    isPastUploadView,
    depositeBankState,
    selectedId,
    togglePastUploadViewModal,
    toggleAddBankDepositDrawer,
    toggleBulkUploadModal,
    onAddDepositBankSubmit,
    onUpdateDepositBankSubmit,
    onUploadBulkBankDeposit,
    setDepositeBankState,
    setSelectedId,
    setSearch,
  } = useBankDeposit();

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (
        fileType === 'text/csv' ||
        fileType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileSize <= 2) {
          let uploadedSize = 0;
          setLoading(true);
          const interval = setInterval(() => {
            uploadedSize += 10000;
            const progress = (uploadedSize / file.size) * 100;
            setUploadProgress(progress);

            if (uploadedSize >= file.size) {
              clearInterval(interval);
              setLoading(false);
            }
          }, 1000);
          setSelectedFile(file || null);
        } else {
          setError('File size exceeds 2MB. Please upload a smaller file.');
        }
      } else {
        setError('Please upload only CSV or XLSX files.');
      }
    }
  };

  const bulkBankDepositUpload = () => {
    onUploadBulkBankDeposit(selectedFile);
  };

  function hasEmptyOrNullProperty(
    obj: ICreateDepositBankProps | null
  ): boolean {
    if (!obj) return true;
    return Object.values(obj).some(
      value => value === null || value === undefined || value === ''
    );
  }
  const isDisabled = hasEmptyOrNullProperty(depositeBankState);
  const checkCondition = (selectedId: string | null) => {
    if (selectedId) {
      return {
        title: 'Update Bank Deposit',
        buttonText: 'Update Details',
        buttonClick: () => {
          const newDepositBankObj = {
            ...depositeBankState,
            id: selectedId,
          } as ICreateDepositBankProps;

          onUpdateDepositBankSubmit(newDepositBankObj);
        },
        loading: loader,
      };
    } else {
      return {
        title: 'Add New Bank Deposit',
        buttonText: 'Save Details',
        buttonClick: onAddDepositBankSubmit,
        loading: loader,
      };
    }
  };

  return (
    <>
      <VegaPageHeader
        title="Bank Deposit"
        renderRightView={() => (
          <Stack direction={'row'} gap={1}>
            <LmButton
              variant="text"
              bgColor=""
              textColor={PRIMARY.darkBlue}
              text="Past Uploads"
              fontWeight={500}
              onClick={togglePastUploadViewModal}
              sx={{ px: 0 }}
            />
            <LmButton
              variant="outlined"
              bgColor=""
              textColor={PRIMARY.darkBlue}
              text="Bulk Upload"
              fontWeight={500}
              onClick={toggleBulkUploadModal}
            />
            <LmButton
              text="Add New Bank Deposit"
              fontWeight={500}
              startIcon={<AddCircleOutline />}
              onClick={() => {
                setSelectedId(null);
                setDepositeBankState({ ...defaultDepositBankData });
                toggleAddBankDepositDrawer();
              }}
            />
          </Stack>
        )}
      />
      <Box p={3}>
        <LmFilterSection
          setSearch={setSearch}
          onFilter={() => openFilterDrawer()}
        />
        <Box pt={3}>
          <ListOfBankDeposit />
        </Box>
      </Box>
      <VegaDrawer
        title={checkCondition(selectedId).title}
        open={isAdd}
        onClose={toggleAddBankDepositDrawer}
      >
        <VegaDrawerContent
          renderBottomView={() => (
            <LmButton
              text={checkCondition(selectedId).buttonText}
              disabled={isDisabled}
              fontWeight={500}
              onClick={checkCondition(selectedId).buttonClick}
              loading={checkCondition(selectedId).loading}
            />
          )}
        >
          <AddNewBankDeposit />
        </VegaDrawerContent>
      </VegaDrawer>
      <LmDialog
        fullWidth
        maxWidth="sm"
        open={isBulkUpload}
        isCloseIcon
        handleClose={() => {
          setSelectedFile(null);
          setUploadProgress(0);
          toggleBulkUploadModal();
        }}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            borderTop={'1px solid #E1E4EB'}
            justifyContent={'space-between'}
            px={4}
            py={3}
            gap={1}
          >
            <a
              href={require('../assets/files/bulkBankDeposit.xlsx')}
              download
              className="font-aspekta-400"
              style={{
                textDecoration: 'none',
                fontSize: 12,
                color: '#1047DC',
              }}
            >
              Download sample report
            </a>

            <LmButton
              text="Upload"
              disabled={!selectedFile || loading}
              onClick={bulkBankDepositUpload}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Bulk Upload"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <Box px={4} pt={3} pb={4}>
          <LmBulkUpload
            handleFileChange={handleFileChange}
            selectedFile={selectedFile}
            error={error}
            uploadProgress={uploadProgress}
            loading={loading}
          />
        </Box>
      </LmDialog>
      <LmDialog
        fullWidth
        maxWidth="md"
        open={isPastUploadView}
        isCloseIcon
        handleClose={() => {
          togglePastUploadViewModal();
        }}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Past Uploads"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <Box px={4} pt={3} pb={4}>
          <ListOfPastUploads />
        </Box>
      </LmDialog>
      <VegaDrawer
        title={'Filter By'}
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <BankDepositFilterForm
          onClose={closeFilterDrawer}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
      </VegaDrawer>
    </>
  );
};

export default DepositBank;
