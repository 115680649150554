import { Dialog } from '@mui/material';
import LoanAccountDetails from './LoanAccountDetails';

type Props = {
  open: boolean;
  onClose: () => void;
  loanId?: string;
};

function LoanAccountDetailsModal({ open, onClose, loanId }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
    >
      <div style={{ paddingTop: '2rem' }}>
        <LoanAccountDetails
          loanId={loanId}
          onViewTrailClick={() => {
            onClose();
          }}
          onCreateReceiptClick={() => {
            onClose();
          }}
        />
      </div>
    </Dialog>
  );
}

export default LoanAccountDetailsModal;
