import { CircularProgress, Grid, Stack } from '@mui/material';
import ListOfViewAgentLevel from '../../components/IncentivesGamifications/ListOfViewAgentLevel';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { ROUTES } from '../../router/routes';
import { FileUploadIcon } from '../../components/Icons/Icons';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../components/common/VegaFileSelect';
import AllocationFileUploadCard, {
  ViewState,
} from '../../components/Allocation/AllocationFileUploadCard';
import VegaFormInputField from '../../components/common/VegaFormInputField';
import { useEffect, useRef, useState } from 'react';
import VegaButton from '../../components/common/VegaButton';
import { StorageKeys, StorageUtility } from '../../utils/StorageUtility';
import { IncentiveServices } from '../../Apis/IncentivesService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { useParams } from 'react-router-dom';
import { AllocationService } from '../../Apis/AllocationService';
import { LongRunningTaskStatus } from '../../types/allocations';
import VegaText from '../../components/common/VegaText';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import { OverallPayoutTabType } from './OverallPayout/OverallPayout';

const getIncentiveAgentLevelFileRequestId = StorageUtility.getItem<string>(
  StorageKeys.INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
)
  ? StorageUtility.getItem<string>(
      StorageKeys.INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
    )
  : '';
const getUploadAgentLevelFileRequestId = StorageUtility.getItem<string>(
  StorageKeys.UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
)
  ? StorageUtility.getItem<string>(
      StorageKeys.UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
    )
  : '';

const ViewAgentLevel = () => {
  const { policyId } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<VegaFileSelectOption>();
  const { setSnackbar } = useSnackbar();
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(
    OverallPayoutTabType.Previous_Cycle
  );
  const [
    isIncentiveAgentLevelFileDownloadInProgress,
    setIsIncentiveAgentLevelFileDownloadInProgress,
  ] = useState<boolean>(false);
  const [
    isUploadIncentiveAgentLevelFileDownloadInProgress,
    setIsUploadIncentiveAgentLevelFileDownloadInProgress,
  ] = useState<boolean>(false);
  const [
    incentiveAgentLevelFileRequestId,
    setIncentiveAgentLevelFileRequestId,
  ] = useState<string>(getIncentiveAgentLevelFileRequestId);
  const [
    uploadIncentiveAgentLevelFileRequestId,
    setUploadIncentiveAgentLevelFileRequestId,
  ] = useState<string>(getUploadAgentLevelFileRequestId);

  async function uploadFile() {
    try {
      const response = await IncentiveServices.uploadAgentlevelFile({
        file: selectedFile.value,
        policyId: policyId,
      });
      if (response.status === 'COMPLETED') {
        setIsUploadIncentiveAgentLevelFileDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
        );
        setUploadIncentiveAgentLevelFileRequestId('');
        setSelectedFile(null);
        setSnackbar('Uploaded Successfully');
      } else {
        StorageUtility.setItem(
          StorageKeys.UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID,
          response.id
        );
        setUploadIncentiveAgentLevelFileRequestId(response.id);
        setIsUploadIncentiveAgentLevelFileDownloadInProgress(true);
        // getDownloadAgentLevelFileStatus(response.id);
        getUploadAgentLevelFileStatus(response.id);
      }
    } catch (error) {
      setSnackbar(error, 'error');
    }
  }

  function _onTabChange(value: number): void {
    setSelectedTab(value);
  }
  // const uploadFile = () => {};
  // const onDownloadSampleFile = () => {};

  const onDownloadAgentLevelFileClick = async () => {
    setIsIncentiveAgentLevelFileDownloadInProgress(true);
    try {
      const response = await IncentiveServices.downloadAgentLevelFile(policyId);
      if (response.status === 'COMPLETED') {
        setIsIncentiveAgentLevelFileDownloadInProgress(false);
        StorageUtility.removeItem(StorageKeys.SCORECARD_REQUEST_ID);
        setIncentiveAgentLevelFileRequestId('');
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
      } else {
        StorageUtility.setItem(StorageKeys.SCORECARD_REQUEST_ID, response.id);
        setIncentiveAgentLevelFileRequestId(response.id);
        setIsIncentiveAgentLevelFileDownloadInProgress(true);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getDownloadAgentLevelFileStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsIncentiveAgentLevelFileDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
        );
        const url = response.result;
        if (url) {
          window.open(url, '__blank__');
        }
        setIncentiveAgentLevelFileRequestId('');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsIncentiveAgentLevelFileDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
        );
        setSnackbar(response.result, 'error');
        setIncentiveAgentLevelFileRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const getUploadAgentLevelFileStatus = async (requestId: string) => {
    try {
      const response =
        await AllocationService.getBulkAllocateOrDeAllocateStatus({
          requestId,
        });
      if (response.status === LongRunningTaskStatus.COMPLETED) {
        setIsUploadIncentiveAgentLevelFileDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
        );
        // const url = response.result;
        // if (url) {
        //   window.open(url, '__blank__');
        // }
        setSelectedFile(null);
        setUploadIncentiveAgentLevelFileRequestId('');
        setSnackbar('Successfully Uploaded');
      } else if (response.status === LongRunningTaskStatus.FAILED) {
        setIsUploadIncentiveAgentLevelFileDownloadInProgress(false);
        StorageUtility.removeItem(
          StorageKeys.UPLOAD_INCENTIVE_AGENT_LEVEL_FILE_REQUEST_ID
        );
        setSnackbar(response.result, 'error');
        setUploadIncentiveAgentLevelFileRequestId('');
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  useEffect(() => {
    if (incentiveAgentLevelFileRequestId.length) {
      const downloadingFile = setInterval(() => {
        getDownloadAgentLevelFileStatus(incentiveAgentLevelFileRequestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [incentiveAgentLevelFileRequestId]);

  useEffect(() => {
    if (uploadIncentiveAgentLevelFileRequestId.length) {
      const downloadingFile = setInterval(() => {
        getUploadAgentLevelFileStatus(uploadIncentiveAgentLevelFileRequestId);
      }, 7000);
      return () => clearInterval(downloadingFile);
    }
  }, [uploadIncentiveAgentLevelFileRequestId]);

  useEffect(() => {
    if (fileInputRef.current != null && selectedFile == undefined) {
      fileInputRef.current.value = '';
    }
  }, [selectedFile]);
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: 'Live Policy',
                  link: ROUTES.INCENTIVES_GAMIFICATIONS_POLICY,
                },
                {
                  label: 'View Agent Level',
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <VegaPageHeader
        isTabPresent
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  _onTabChange(selected);
                }}
              >
                <VegaTabBarItem label="Previous Cycle" />
                <VegaTabBarItem label="Current Cycle" />
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      {selectedTab === OverallPayoutTabType.Previous_Cycle && (
        <VegaPageContent sx={{ marginTop: '1.5rem' }}>
          <div
            style={{
              borderRadius: '0.5rem',
              border: '1px solid #E1E4EB ',
              backgroundColor: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              padding: '2rem',
              alignItems: 'start',
              position: 'relative',
            }}
          >
            <Grid container columnGap={'1rem'}>
              <Grid item xs={3}>
                <VegaFormInputField label="Upload File">
                  {isUploadIncentiveAgentLevelFileDownloadInProgress ? (
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center', padding: 2 }}
                      gap={1}
                    >
                      <CircularProgress size="15px" />
                      <VegaText text={'Upload is in progress'} />
                    </Stack>
                  ) : (
                    <VegaFileSelect
                      placeholder={'Upload File'}
                      endIcon={<></>}
                      startIcon={<FileUploadIcon />}
                      acceptedExtension={[AcceptedExtension.XLSX]}
                      onFileSelect={file => {
                        setSelectedFile(file);
                      }}
                      onFileInputRef={ref => {
                        fileInputRef.current = ref;
                      }}
                    />
                  )}
                </VegaFormInputField>
              </Grid>
            </Grid>

            {!!selectedFile && (
              <Grid container>
                <Grid item xs={12} style={{ flex: 1, marginTop: '0.8rem' }}>
                  <AllocationFileUploadCard
                    fileName={selectedFile?.label}
                    state={isUpload ? ViewState.Uploading : ViewState.Inital}
                    onUploadClick={function (): void {
                      uploadFile();
                    }}
                    onCancelClick={function (): void {
                      setSelectedFile(undefined);
                      fileInputRef.current.value = '';
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              {isIncentiveAgentLevelFileDownloadInProgress ? (
                <Stack
                  direction="row"
                  sx={{ alignItems: 'center', padding: 2 }}
                  gap={1}
                >
                  <CircularProgress size="15px" />
                  <VegaText text={'Download Agent Level File Is In Progress'} />
                </Stack>
              ) : (
                <VegaButton
                  text="Download Agent Level File"
                  variant="text"
                  onClick={onDownloadAgentLevelFileClick}
                  loading={isIncentiveAgentLevelFileDownloadInProgress}
                />
              )}
            </div>
          </div>
        </VegaPageContent>
      )}

      <VegaPageContent mt={'1.5rem'}>
        <ListOfViewAgentLevel selectedTab={selectedTab} />
      </VegaPageContent>
    </>
  );
};

export default ViewAgentLevel;
