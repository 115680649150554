import { Grid, Stack } from '@mui/material';
import React from 'react';
import { BORDER_COLLECTION } from '../../../constants/style';
import VegaText from '../../common/VegaText';
import { ROUTES, SUB_ROUTES } from '../../../router/routes';
import {
  AllocationIcon,
  CalenderIcon,
  RupeeIcon,
  TargetsIcon,
} from '../../Icons/Icons';
import { useNavigate } from 'react-router-dom';

const quickAccess = [
  {
    label: 'Allocate Acc',
    path: ROUTES.ACCOUNT,
    icon: <AllocationIcon width="30" height="30" strokeColor="#1047DC" />,
  },
  {
    label: 'Expense',
    path: SUB_ROUTES.EXPENSE_REPORT_ONGOING,
    icon: <RupeeIcon width="30" height="30" strokeColor="#1047DC" />,
  },
  {
    label: 'Leave',
    path: ROUTES.LEAVE_APPLY,
    icon: <CalenderIcon width="30" height="30" strokeColor="#1047DC" />,
  },
  {
    label: 'Target',
    path: ROUTES.TARGETS,
    icon: <TargetsIcon width="30" height="30" strokeColor="#1047DC" />,
  },
  {
    label: 'Escalation',
    path: ROUTES.ESCALATIONS,
    icon: <AllocationIcon width="30" height="30" strokeColor="#1047DC" />,
  },
];

const QuickAccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          px: 2,
          py: 1.5,
          bgcolor: 'white',
          gap: 1,
        }}
      >
        <VegaText text={'Quick Access'} fontWeight={500} fontSize={14} />
        <Grid container spacing={2}>
          {quickAccess.map(route => (
            <Grid key={route.path} item xs={12} sm={6} md={4} lg={2.4} xl={2}>
              <Stack
                sx={{
                  border: BORDER_COLLECTION,
                  borderRadius: '0.75rem',
                  px: 2,
                  py: 2,
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                  transition: 'all 0.5s easy-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
                onClick={() => navigate(route.path)}
              >
                {route.icon}
                <VegaText
                  text={route.label}
                  fontWeight={450}
                  fontColor="#676B76"
                  fontSize={13}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
};

export default QuickAccess;
