import { GridColDef } from '@mui/x-data-grid';
import VegaText from '../../common/VegaText';
import { Stack } from '@mui/material';
import { AgentLevelIncentiveDetailsResponseDto } from '../../../types/incentives';

const ViewAgentLevelColumn = () => {
  const columns: GridColDef[] = [
    {
      field: 'agentCode',
      flex: 1,
      minWidth: 80,
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Agent'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Code'}
            />
          </Stack>
        );
      },
    },
    {
      field: 'agentName',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Agent'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Name'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'agentDesignation',
      headerName: 'Designation',
      minWidth: 100,
      flex: 1,
    },
    // { field: 'agentRegion', headerName: 'Region', minWidth: 100, flex: 1 },
    {
      field: 'allIndiaRank',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'All India'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Rank'}
            />
          </Stack>
        );
      },
      minWidth: 100,
      flex: 1,
    },

    {
      field: 'earned',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Total Incentive'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Earned'}
            />
          </Stack>
        );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'collected',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Charge'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Collection'}
            />
          </Stack>
        );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'overall_normal',
      minWidth: 80,
      flex: 1,
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'overall'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Normal'}
            />
          </Stack>
        );
      },
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.earnedAmountBucketNormal ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'overall_bucketX',
      minWidth: 80,
      flex: 1,
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'overall'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket X'}
            />
          </Stack>
        );
      },
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue = viewAgentLevelDetails?.earnedAmountBucketX ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'overall_bucket1',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'overall'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 1'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue = viewAgentLevelDetails?.earnedAmountBucket1 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'overall_bucket2',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'overall'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 2'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue = viewAgentLevelDetails?.earnedAmountBucket2 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'overall_bucket3',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'overall'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 3'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue = viewAgentLevelDetails?.earnedAmountBucket3 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'overall_bucketNPA',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'overall'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'NPA'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.earnedAmountBucketNormal ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'penalty_normal',
      minWidth: 80,
      flex: 1,
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'penalty'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Normal'}
            />
          </Stack>
        );
      },
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.penaltyAmountDeductedBucketNormal ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'penalty_bucketX',
      minWidth: 80,
      flex: 1,
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'penalty'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket X'}
            />
          </Stack>
        );
      },
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.penaltyAmountDeductedBucketX ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'penalty_bucket1',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'penalty'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 1'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.penaltyAmountDeductedBucket1 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'penalty_bucket2',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'penalty'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 2'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.penaltyAmountDeductedBucket2 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'penalty_bucket3',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'penalty'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 3'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.penaltyAmountDeductedBucket3 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'penalty_bucketNPA',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'penalty'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'NPA'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.penaltyAmountDeductedBucketNpa ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'others_normal',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bumper'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Normal'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.bumperAmountDeductedBucketNormal ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'others_bucketX',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bumper'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket X'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.bumperAmountDeductedBucketX ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'others_bucket1',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bumper'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 1'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.bumperAmountDeductedBucket1 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'others_bucket2',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bumper'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 2'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.bumperAmountDeductedBucket2 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'others_bucket3',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bumper'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bucket 3'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.bumperAmountDeductedBucket3 ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
    {
      field: 'others_bucketNPA',
      renderHeader: () => {
        return (
          <Stack gap={0.5}>
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'Bumper'}
            />
            <VegaText
              sx={{
                fontFamily: `var(--font-Aspekta-700) !important`,
                fontSize: '13px',
                textTransform: 'capitalize',
                color: '#676B76',
              }}
              text={'NPA'}
            />
          </Stack>
        );
      },
      minWidth: 80,
      flex: 1,
      renderCell: props => {
        const viewAgentLevelDetails =
          props.row as AgentLevelIncentiveDetailsResponseDto;
        const bucketValue =
          viewAgentLevelDetails?.bumperAmountDeductedBucketNpa ?? '0';
        return (
          <VegaText
            color={'#1B1D22'}
            fontSize={14}
            fontWeight={500}
            text={bucketValue}
          />
        );
      },
    },
  ];
  return columns;
};

export default ViewAgentLevelColumn;
