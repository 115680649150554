import { Call } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import LoanAccountDetailsModal from '../components/TeleAgent/components/LoanAccountDetailsModal';
import { BUTTON_COLOR } from '../components/common/VegaButton';
import { setSelectedPolicyTab } from '../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import {
  getAuth,
  setFirstRoute,
  setIsPathnameMatch,
} from '../features/authSlice';
import LoadingPage from '../pages/LoadingPage/LoadingPage';
import {
  TeleAgentCallStatus,
  useTeleAgentCallEvents,
} from '../providers/TeleAgentCallEventsProvider';
import { LmSidebarListItemType } from '../router';
import { useAppDispatch, useAppSelector } from '../store';
import LmMainHeader from './LmMainHeader';
import LmSubMenu from './LmSubMenu';

interface IProps {
  children: ReactNode;
}

const LmLayout = ({ children }: IProps) => {
  const dispatch = useAppDispatch();
  const { routes, loader } = useAppSelector(getAuth);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { callEvent } = useTeleAgentCallEvents();

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',

      transition: {
        duration: 0.5,
      },
    },
  };

  const pathMatch = (pathname: string, routes: LmSidebarListItemType[]) => {
    const isPath = routes.some(
      item =>
        item.path === pathname ||
        (item.children && item.children.some(child => child.path === pathname))
    );
    return isPath;
  };

  useEffect(() => {
    const firstPath = pathname.split('/')[1];
    const isPathnameMatch = pathMatch(`/${firstPath}`, routes);
    dispatch(setIsPathnameMatch(isPathnameMatch));
    if (routes?.length) {
      dispatch(setFirstRoute(routes[0]));
    }
  }, []);

  return (
    <div className="main-container">
      <>
        <motion.div
          animate={{
            width: isOpen ? '250px' : '45px',
            transition: {
              duration: 0.3,
            },
          }}
          style={{
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          }}
          className={`sidebar`}
          onMouseLeave={() => setIsOpen(false)}
          onMouseEnter={() => setIsOpen(true)}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                ></motion.h1>
              )}
            </AnimatePresence>
          </div>

          <section className="routes">
            {routes
              .filter(
                (route: LmSidebarListItemType) => route.label.length !== 0
              )
              .map((route: LmSidebarListItemType, index) => {
                if (route.children) {
                  return (
                    <LmSubMenu
                      key={index}
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    >
                      {route.children}
                    </LmSubMenu>
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link "
                    onClick={() => {
                      dispatch(setSelectedPolicyTab(0));
                    }}
                  >
                    <div className="icon">
                      {route.path === pathname ||
                      (route.routes && route.routes.includes(pathname))
                        ? route.activIcon
                        : route.icon}
                    </div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="font-aspekta-500 link_text"
                          style={{ fontSize: 13 }}
                        >
                          {route.label}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
          </section>
        </motion.div>
        <Box
          component={'main'}
          sx={{
            bgcolor: '#F7F7F7',
            height: '100%',
            width: '100%',
          }}
        >
          <LmMainHeader />
          <Box
            sx={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: 'calc(100% - 64px)',
              width: '100%',
            }}
          >
            {loader ? <LoadingPage height={'100%'} /> : children}
          </Box>
          <IconButton
            style={{
              display:
                callEvent?.status == TeleAgentCallStatus.STARTED ? '' : 'none',
              position: 'absolute',
              bottom: 20,
              right: 20,
              backgroundColor: BUTTON_COLOR.PRIMARY,
            }}
            onClick={() => setShowModal(true)}
          >
            <Call style={{ color: 'white' }} />
          </IconButton>
        </Box>
        <LoanAccountDetailsModal
          open={showModal}
          loanId={callEvent?.loanId}
          onClose={function (): void {
            setShowModal(false);
          }}
        />
      </>
    </div>
  );
};

export default LmLayout;
