import {
  AddTask,
  Block,
  Close,
  Download,
  RemoveCircle,
  Save,
  Send,
  SupportAgent,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';
import { IncentiveStatus } from '../../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { IncentivesActionEnum } from '../../../pages/IncentivesGamifications/IncentivesGamifications';
import {
  IncentivePolicyReviewStatus,
  ViewIncentiesGamificationsModal,
} from '../../../types/incentives';
import { COLOR } from '../../../utils/ColorUtility';
import { DeleteIcon, EditIcon } from '../../Icons/Icons';
import VegaIconButton from '../../common/VegaIconButton';
import { VegaPill } from '../../common/VegaPill';
import VegaText from '../../common/VegaText';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router/routes';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface IColumnProps {
  selectedTab: number;

  onAction: (
    policy: ViewIncentiesGamificationsModal,
    action: IncentivesActionEnum
  ) => void;
}

export const IncentiveColumn = ({ selectedTab, onAction }: IColumnProps) => {
  const navigate = useNavigate();
  const COL_DEF: GridColumns = [
    {
      field: 'policyName',
      headerName: 'Policy Name',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'vertical',
      headerName: 'Vertical',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'channelType',
      headerName: 'Channel Type',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'userType',
      headerName: 'User Type',
      flex: 1,
      minWidth: 100,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;
        const userType = policy.userType;
        const users = userType.filter((value: string) => userType[0] !== value);
        return (
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <VegaPill
              text={userType[0]}
              borderColor={PRIMARY.darkBlue}
              textColor={PRIMARY.darkBlue}
              backgroundColor={'#E7EDFC'}
              isDot={false}
            />
            {userType && userType?.length > 2 && (
              <VegaIconButton
                tooltipTitle={users?.join(', ')}
                icon={
                  <VegaText
                    text={` + ${users.length}`}
                    sx={{ cursor: 'pointer' }}
                  />
                }
              />
            )}
          </Stack>
        );
      },
    },
    {
      field: 'payoutFrequency',
      headerName: 'Payout Frequency',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'details',
      headerName: 'Details',
      flex: 1,
      minWidth: 100,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaIconButton
              tooltipTitle="View Details"
              onClick={() => onAction(policy, IncentivesActionEnum.VIEW)}
              sx={{ bgcolor: '#E7EDFC' }}
              icon={
                <VisibilityOutlined
                  sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                />
              }
            />
            {selectedTab === 0 && (
              <VegaIconButton
                tooltipTitle="View Agent Level"
                onClick={() =>
                  navigate(
                    ROUTES.INCENTIVES_GAMIFICATIONS_VIEW_AGENT_LEVEL.replace(
                      ':policyId',
                      policy.id
                    )
                  )
                }
                sx={{ bgcolor: '#E7EDFC' }}
                icon={
                  <SupportAgent
                    sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                  />
                }
              />
            )}
          </Stack>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      hide: selectedTab !== 1 && selectedTab !== 3 && selectedTab !== 4,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;
        return (
          <VegaPill
            text={toLowerCase(policy.status)}
            backgroundColor={getColorForStatus(policy.status)?.LIGHT}
            textColor={getColorForStatus(policy.status)?.DARK}
          />
        );
      },
    },
    {
      field: 'approvalStatus',
      headerName: 'Approval Status',
      flex: 1,
      minWidth: 100,
      hide: selectedTab !== 1 && selectedTab !== 3 && selectedTab !== 4,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;
        return (
          <VegaPill
            text={toLowerCase(policy.approvalStatus)}
            backgroundColor={
              getColorForApprovalStatus(policy.approvalStatus)?.LIGHT
            }
            textColor={getColorForApprovalStatus(policy.approvalStatus)?.DARK}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 200,
      hide: selectedTab !== 2 && selectedTab !== 4,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;

        return (
          <Stack direction={'row'} gap={1}>
            {selectedTab === 2 ? (
              <>
                <VegaIconButton
                  tooltipTitle="Approve"
                  sx={{ bgcolor: '#E7EDFC' }}
                  icon={
                    <AddTask sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }} />
                  }
                  onClick={() => {
                    onAction(policy, IncentivesActionEnum.APPROVE);
                  }}
                />
                <VegaIconButton
                  tooltipTitle="Reject"
                  onClick={() => {
                    onAction(policy, IncentivesActionEnum.REJECT);
                  }}
                  sx={{ bgcolor: COLOR.RED.LIGHT }}
                  icon={<Close sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />}
                />
                {policy.reason && (
                  <VegaIconButton
                    tooltipTitle="View Resubmision Reason"
                    onClick={() =>
                      onAction(
                        policy,
                        IncentivesActionEnum.VIEW_RESUBMISSION_REASON
                      )
                    }
                    sx={{ bgcolor: '#E7EDFC' }}
                    icon={
                      <VisibilityOutlined
                        sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                      />
                    }
                  />
                )}
              </>
            ) : (
              <Stack direction={'row'} gap={1}>
                {(policy.status === IncentivePolicyReviewStatus.DRAFT ||
                  policy.approvalStatus === IncentiveStatus.REJECTED) && (
                  <>
                    {policy.approvalStatus !== IncentiveStatus.REJECTED ? (
                      <VegaIconButton
                        tooltipTitle="Edit"
                        onClick={() =>
                          onAction(policy, IncentivesActionEnum.EDIT)
                        }
                        sx={{ bgcolor: '#E7EDFC' }}
                        icon={<EditIcon />}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: '30px',
                          height: '30px',
                        }}
                      ></Box>
                    )}
                    <Stack direction={'row'} gap={1}>
                      <VegaIconButton
                        tooltipTitle="Delete"
                        onClick={() =>
                          onAction(policy, IncentivesActionEnum.DELETE)
                        }
                        sx={{ bgcolor: COLOR.RED.LIGHT }}
                        icon={<DeleteIcon />}
                      />
                      <VegaIconButton
                        tooltipTitle="View Details"
                        onClick={() =>
                          onAction(
                            policy,
                            IncentivesActionEnum.VIEW_REJECTED_REASON
                          )
                        }
                        sx={{ bgcolor: '#E7EDFC' }}
                        icon={
                          <VisibilityOutlined
                            sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                          />
                        }
                      />
                    </Stack>
                  </>
                )}
                {policy.status === IncentivePolicyReviewStatus.INACTIVE &&
                  policy.approvalStatus === IncentiveStatus.APPROVED && (
                    <VegaIconButton
                      tooltipTitle="Activate"
                      onClick={() => {
                        onAction(policy, IncentivesActionEnum.ACTIVATE);
                      }}
                      sx={{ bgcolor: COLOR.GREEN.LIGHT }}
                      icon={
                        <RemoveCircle
                          sx={{ color: COLOR.GREEN.DARK, fontSize: 18 }}
                        />
                      }
                    />
                  )}
                {policy.status === IncentivePolicyReviewStatus.ACTIVE &&
                  policy.approvalStatus === IncentiveStatus.APPROVED && (
                    <VegaIconButton
                      tooltipTitle="Deactivate"
                      onClick={() => {
                        onAction(policy, IncentivesActionEnum.DEACTIVATE);
                      }}
                      sx={{ bgcolor: COLOR.RED.LIGHT }}
                      icon={
                        <Block sx={{ color: COLOR.RED.DARK, fontSize: 18 }} />
                      }
                    />
                  )}
                {policy.approvalStatus === IncentiveStatus.APPROVED && (
                  <Stack direction={'row'} gap={1}>
                    <VegaIconButton
                      tooltipTitle="Save as template"
                      onClick={() =>
                        onAction(policy, IncentivesActionEnum.SAVE_TEMPLATE)
                      }
                      sx={{ bgcolor: '#E7EDFC' }}
                      icon={
                        <Save sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />
                      }
                    />
                    <VegaIconButton
                      tooltipTitle="Download Policy"
                      onClick={() =>
                        onAction(policy, IncentivesActionEnum.DOWNLOAD)
                      }
                      sx={{ bgcolor: '#E7EDFC' }}
                      icon={
                        <Download
                          sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                        />
                      }
                    />
                  </Stack>
                )}
                {policy.status === IncentivePolicyReviewStatus.DRAFT && (
                  <VegaIconButton
                    tooltipTitle="Send for review"
                    onClick={() => {
                      onAction(policy, IncentivesActionEnum.IN_REVIEW);
                    }}
                    sx={{ bgcolor: '#E7EDFC' }}
                    icon={
                      <Send sx={{ color: PRIMARY.darkBlue, fontSize: 18 }} />
                    }
                  />
                )}
                {policy.approvalStatus === IncentiveStatus.REJECTED && (
                  <VegaIconButton
                    tooltipTitle="re-sumit"
                    onClick={() => {
                      onAction(policy, IncentivesActionEnum.RE_SUBMIT);
                    }}
                    sx={{ bgcolor: '#E7EDFC' }}
                    icon={
                      <RestartAltIcon
                        sx={{ color: PRIMARY.darkBlue, fontSize: 18 }}
                      />
                    }
                  />
                )}
              </Stack>
            )}
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};

const getColorForStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case IncentivePolicyReviewStatus.ACTIVE:
      return COLOR.GREEN;

    case IncentivePolicyReviewStatus.DELETED:
    case IncentivePolicyReviewStatus.INACTIVE:
      return COLOR.RED;
    case IncentivePolicyReviewStatus.IN_REVIEW:
      return COLOR.ORANGE;
    case IncentivePolicyReviewStatus.DRAFT:
      return COLOR.ORANGE;
  }
};

const getColorForApprovalStatus = (status?: string) => {
  if (!status) return;
  switch (status) {
    case IncentiveStatus.APPROVED:
      return COLOR.GREEN;

    case IncentiveStatus.REJECTED:
      return COLOR.RED;
    case 'DRAFT':
      return COLOR.ORANGE;
  }
};
