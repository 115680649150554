import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import { toLowerCase } from '../../../constants/commonFunction';
import { ResourceDtoObj } from '../../../types/claim';
import { RoleResourceDto } from '../../../types/roleType';
import {
  addResource,
  removeResource,
} from '../columns/AddRemoveResourcesColumn';
import { ScreenOpenType } from '../UAM';

interface IProps {
  removedResources: VegaSelectOption[];
  setRemovedResources: React.Dispatch<React.SetStateAction<VegaSelectOption[]>>;
  roleDetails: RoleResourceDto;
}

const RemoveScreenForm = ({
  roleDetails,
  removedResources,
  setRemovedResources,
}: IProps) => {
  const [assignedResources, setAssignedResources] = useState<
    VegaSelectOption[]
  >([]);

  useEffect(() => {
    if (roleDetails.resourceDtos.length) {
      const resources = getFitteredResources(roleDetails.resourceDtos);
      setAssignedResources(resources);
    }
  }, [roleDetails]);

  const onDelete = (
    selectedResource: VegaSelectOption,
    action: ScreenOpenType
  ) => {
    if (action === ScreenOpenType.ADD) {
      const filtered = assignedResources.filter(
        (resource: VegaSelectOption) =>
          resource.value !== selectedResource.value
      );
      const selectedFiltered = assignedResources.filter(
        (resource: VegaSelectOption) =>
          resource.value === selectedResource.value
      );

      setAssignedResources(filtered);
      setRemovedResources([...removedResources, ...selectedFiltered]);
    } else {
      const filtered = removedResources.filter(
        (resource: VegaSelectOption) =>
          resource.value !== selectedResource.value
      );
      const selectedFiltered = removedResources.filter(
        (resource: VegaSelectOption) =>
          resource.value === selectedResource.value
      );

      setRemovedResources(filtered);
      setAssignedResources([...assignedResources, ...selectedFiltered]);
    }
  };

  return (
    <>
      <Grid container spacing={'1.25rem'}>
        <Grid item xs={12}>
          <VegaFormInputField label={'Assigned Resources'}>
            <VegaDataGrid
              columns={addResource({ onDelete, text: 'Screen' })}
              idColumn="value"
              data={assignedResources}
              paginationMode="client"
              pageSize={5}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12}>
          <VegaFormInputField label={'Selected Remove Resources'}>
            <VegaDataGrid
              columns={removeResource({ onDelete, text: 'Screen' })}
              idColumn="value"
              data={removedResources}
              paginationMode="client"
              pageSize={5}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </>
  );
};

export default RemoveScreenForm;

const getFitteredResources = (selectedResourceDtos: ResourceDtoObj[]) => {
  const matchingLabels = selectedResourceDtos.map(
    (selectedResource: ResourceDtoObj) => {
      const label = `${toLowerCase(selectedResource.module)} -> ${toLowerCase(
        selectedResource.subModule
      )}`;
      return {
        label,
        value: selectedResource.id,
      };
    }
  );

  return matchingLabels;
};
