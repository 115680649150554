import { Stack, Grid } from '@mui/material';
import React from 'react';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import { LegalBaseResponse } from '../../../types/legal';

type Props = {
  item?: Partial<LegalBaseResponse>;
  isLoading?: boolean;
};

function ArbitrationDetails({ item, isLoading = false }: Props) {
  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            text="Case Status Live/Closed"
            value={item?.caseStatus ?? '-'}
            loading={isLoading}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Loan Recall Notice Date"
            value={item?.loanRecallNoticeDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="LR Notice Sent Date"
            value={item?.lrNoticeSentDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Advocate Appointed (Name)"
            value={item?.advocateAppointedName ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Contact No."
            value={item?.contactNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Claim Amount"
            value={item?.claimAmount ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Name of the Arbitrator"
            value={item?.nameoftheArbitrator ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Place of Arbitration"
            value={item?.placeOfArbitration ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Case No."
            value={item?.caseNo ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="POA Available (Yes/No)"
            value={item?.poaAvailable ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Date of Reference (Start Date)"
            value={item?.dateofReferenceStartDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Reference Given By"
            value={item?.referenceGivenBy ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Contested (Yes/No)"
            value={item?.contested ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Award Passed (Y/N)"
            value={item?.awardPassed ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Award Date"
            value={item?.awardDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Award Amount"
            value={item?.awardAmount ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="1st Hearing Date"
            value={item?.firstHearingDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Previous Date of Hearing"
            value={item?.previousDateofHearing ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Last Date"
            value={item?.lastDate ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Next Date of Hearing"
            value={item?.nextDateofHearing ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Final Stage"
            value={item?.finalStage ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Arbitration Next Stage"
            value={item?.arbitrationNextStage ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Remarks"
            value={item?.remarks ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Our Remarks"
            value={item?.ourRemarks ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Authorised Representative Name"
            value={item?.authorisedRepresentativeName ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="If Advocate Appointed to Contest (Name)"
            value={item?.ifAdvocateAppointedtoContestName ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Mobile No."
            value={'-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Loan Account Status"
            value={item?.loanAccountStatus ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TeleAgentTextWrapper
            loading={isLoading}
            text="Status"
            value={item?.status ?? '-'}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ArbitrationDetails;
