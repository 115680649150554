import { Box, Stack } from '@mui/material';
import React from 'react';
import ViewBaseIncentiveForChargeCollected from '../../components/IncentivesGamifications/components/ViewBaseIncentiveForChargeCollected';
import ViewBucketComponent from '../../components/IncentivesGamifications/modules/ViewBucketComponent';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaText from '../../components/common/VegaText';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import { toLowerCase } from '../../constants/commonFunction';
import { getIncentivesGamificationsState } from '../../features/IncentivesGamificationsSlice/IncentivesGamificationsSlice';
import { useAppSelector } from '../../store';
import { BucketEnum, BucketEnumForNonNPA } from '../../types/targets';

interface IProps {
  selectedBucket: string;
  setSelectedBucket: React.Dispatch<React.SetStateAction<string>>;
}

const BucketViewPolicy = ({ selectedBucket, setSelectedBucket }: IProps) => {
  const { policyDetailsState } = useAppSelector(
    getIncentivesGamificationsState
  );

  return (
    <>
      <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
        <Stack direction={'row'} gap={2}>
          {policyDetailsState?.rules &&
          Object.keys(policyDetailsState?.rules)?.length
            ? Object.keys(policyDetailsState?.rules).map((bucket: string) => (
                <Box
                  key={bucket}
                  sx={{
                    px: 1,
                    pb: 1,
                    cursor: 'pointer',
                    borderBottom:
                      selectedBucket === bucket
                        ? `1px solid #1047DC`
                        : '1px solid transparant',
                  }}
                  onClick={() => setSelectedBucket(bucket)}
                >
                  <VegaText
                    color={selectedBucket === bucket ? '#1047DC' : '#676B76'}
                    fontSize={12}
                    fontWeight={500}
                    text={getBucketLabel(bucket)}
                  />
                </Box>
              ))
            : ''}
          {policyDetailsState?.chargeCollectedRules &&
          policyDetailsState?.chargeCollectedRules?.length ? (
            <Box
              sx={{
                px: 1,
                pb: 1,
                cursor: 'pointer',
                borderBottom:
                  selectedBucket === BucketEnumForNonNPA.CHARGES_COLLECTION
                    ? `1px solid #1047DC`
                    : '1px solid transparant',
              }}
              onClick={() =>
                setSelectedBucket(BucketEnumForNonNPA.CHARGES_COLLECTION)
              }
            >
              <VegaText
                color={
                  selectedBucket === BucketEnumForNonNPA.CHARGES_COLLECTION
                    ? '#1047DC'
                    : '#676B76'
                }
                fontSize={12}
                fontWeight={500}
                text={toLowerCase(BucketEnumForNonNPA.CHARGES_COLLECTION)}
              />
            </Box>
          ) : (
            ''
          )}
        </Stack>
      </VegaPageContent>
      {policyDetailsState?.rules &&
        policyDetailsState?.rules[selectedBucket] && (
          <ViewBucketComponent
            rules={policyDetailsState?.rules[selectedBucket]}
          />
        )}
      {selectedBucket === BucketEnumForNonNPA.CHARGES_COLLECTION && (
        <>
          <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
            <VegaTitleCard title="Base Incentive">
              <Stack gap={2}>
                <ViewBaseIncentiveForChargeCollected
                  rules={policyDetailsState?.chargeCollectedRules}
                />
              </Stack>
            </VegaTitleCard>
          </VegaPageContent>
        </>
      )}
    </>
  );
};

export default BucketViewPolicy;

const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketEnum.BUCKET1:
      return 'Bucket 1-30';
    case BucketEnum.BUCKET2:
      return 'Bucket 31-60';
    case BucketEnum.BUCKET3:
      return 'Bucket 61-90';
    case BucketEnum.NPA:
      return 'Bucket NPA';
    case 'CHARGES_COLLECTION':
      return 'Charges Collection';

    default:
      return 'Bucket X';
  }
};
