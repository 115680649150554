import { ReactNode, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LM_ROUTES } from '.';
import { getAuth } from '../features/authSlice';
import LoadingPage from '../pages/LoadingPage/LoadingPage';
import { useClientAuth } from '../providers/ClientProvider';
import { useAppSelector } from '../store';
import { AUTH_ROUTES, ROUTES } from './routes';

interface IProps {
  children: ReactNode;
}

const PublicRoutes = ({ children }: IProps) => {
  const { hasAccessToModuleOrSubmodule, allModules } = useClientAuth();
  const { isAuth, isPathnameMatch, routes, loader } = useAppSelector(getAuth);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getFirstRoute = () => {
    const firstAccessibleModule = LM_ROUTES.find(route => {
      return hasAccessToModuleOrSubmodule(route.module);
    });
    const submodules =
      firstAccessibleModule?.children?.filter(item => item.module) ?? [];
    if (submodules.length > 0) {
      const firstAccessibleSubModule = submodules.find(i =>
        hasAccessToModuleOrSubmodule(i.module)
      );
      if (firstAccessibleSubModule) {
        return firstAccessibleSubModule;
      }
    }
    return firstAccessibleModule;
  };

  useEffect(() => {
    if (isAuth && allModules.size > 0) {
      if (pathname === AUTH_ROUTES.LOGIN) {
        const firstRoute = getFirstRoute();
        if (firstRoute) {
          if (routes.length) {
            if (routes[0].path.length) {
              navigate(routes[0].path);
            } else {
              navigate(routes[0].children[0]?.path);
            }
          } else {
            navigate(ROUTES.NO_ACCESS);
          }
        } else {
          navigate(ROUTES.NO_ACCESS);
        }
      } else if (pathname === '/') {
        const firstRoute = getFirstRoute();
        if (firstRoute) {
          if (routes.length) {
            if (routes[0].path.length) {
              navigate(routes[0].path);
            } else {
              navigate(routes[0].children[0]?.path);
            }
          } else {
            navigate(ROUTES.NO_ACCESS);
          }
        } else {
          navigate(ROUTES.NO_ACCESS);
        }
      }
    }
  }, [isAuth, allModules]);

  useEffect(() => {
    if (isPathnameMatch === true) {
      navigate(pathname);
    }
  }, [isPathnameMatch]);

  if (!isAuth) return <>{children}</>;
  return loader ? <LoadingPage height={'100vh'} /> : <Outlet />;
};

export default PublicRoutes;
